import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  className?: string;
  password?: string;
  strengthText: string;
}

const StrengthProgress: FC<IProps> = ({ className, password, strengthText }) => {
  const passwordStrength = useMemo(() => {
    if (!password || !/^\S*$/.test(password)) {
      return;
    }

    if (password.length < 8) {
      return 'weak';
    } else {
      return /^(?=.{10})(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z])/.test(password) ? 'strong' : 'fair';
    }
  }, [password]);
  const cn = useMemo(() => {
    return classNames('StrengthProgress', className, {
      [`StrengthProgress--${passwordStrength}`]: Boolean(passwordStrength),
    });
  }, [className, passwordStrength]);

  return (
    <div className={cn}>
      <div className="StrengthProgress-progress">
        <div className="StrengthProgress-section" />
        <div className="StrengthProgress-section" />
        <div className="StrengthProgress-section" />
      </div>
      <p className="StrengthProgress-strength">{passwordStrength ? passwordStrength : strengthText}</p>
    </div>
  );
};

export default StrengthProgress;
