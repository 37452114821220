import React, { Component } from 'react';
import { observer } from 'mobx-react';

import ReportTableByGroupRowEventCellLink from '../ReportTableByGroupRowEventCellLink';
import ReportTableByGroupRowEventCellSimple from '../ReportTableByGroupRowEventCellSimple';
import ReportTableByGroupRowEventCellHTML from '../ReportTableByGroupRowEventCellHTML';
import ReportTableByGroupRowEventCellMaintenanceLink from '../ReportTableByGroupRowEventCellMaintenanceLink';

interface IProps {
  item: {
    value: string;
    sortValue?: number;
    mapLink: { lat: number; lon: number };
    color?: string;
    type: string;
    maintenanceId?: string;
  };
  className: string;
}

@observer
class ReportTableByGroupRowEventCell extends Component<IProps> {
  render() {
    const { item, className } = this.props;

    if (item?.type === 'link' && item.maintenanceId) {
      return <ReportTableByGroupRowEventCellMaintenanceLink item={item} className={className} />;
    }

    if (item?.mapLink) {
      return <ReportTableByGroupRowEventCellLink item={item} className={className} />;
    }

    if (item?.type === 'HTML') {
      return <ReportTableByGroupRowEventCellHTML item={item} className={className} />;
    }

    return <ReportTableByGroupRowEventCellSimple item={item} className={className} />;
  }
}

export default ReportTableByGroupRowEventCell;
