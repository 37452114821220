import React, { useRef, useEffect, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import screenfull, { Screenfull } from 'screenfull';

import { FullscreenIcon } from 'assets';

import './styles.scss';

interface IProps {
  url: string;
  mainVideo?: boolean;
  playing: boolean;
  seekTime?: number;
  setProgress?: (second: number) => void;
}

const VideoPlayer: React.FC<IProps & ReactPlayerProps> = ({
  playing = false,
  url,
  muted = false,
  mainVideo = false,
  seekTime,
  setProgress = () => void 0,
  ...props
}) => {
  const player = useRef(null);
  const cn = classNames('VideoPlayer', {
    'VideoPlayer--main': mainVideo,
  });

  const [controls, showControls] = useState(false);

  const setFullScreen = () => {
    // @ts-ignore
    (screenfull as Screenfull).request(findDOMNode(player.current).firstChild);
  };

  useEffect(() => {
    player.current.seekTo(seekTime);
  }, [seekTime]);

  useEffect(() => {
    (screenfull as Screenfull).on('change', () => {
      showControls((screenfull as Screenfull).isFullscreen);
    });
  }, []);

  const handleProgress = ({ playedSeconds }) => {
    let second = Math.trunc(playedSeconds);
    second = second < 1 ? second : second;

    setProgress(second);
  };

  return (
    <div className={cn}>
      <ReactPlayer
        controls={controls || mainVideo}
        ref={player}
        muted={muted}
        playing={playing}
        className="VideoPlayer-player"
        url={url}
        width={mainVideo ? 'auto' : '100%'}
        height={mainVideo ? '100%' : 'auto'}
        onProgress={handleProgress}
        {...props}
      />
      {!mainVideo && (
        <button className="VideoPlayer-fullscreenButton" onClick={setFullScreen}>
          <FullscreenIcon width="12px" height="12px" />
        </button>
      )}
    </div>
  );
};

export default VideoPlayer;
