import React from 'react';
import { inject, Observer } from 'mobx-react';

import { REPORTS_STORE } from 'config';

import type { ReportsStore } from 'stores';

import Notification from 'components/Notification';

import './styles.scss';

interface IProps {
  reportsStore?: ReportsStore;
}
const ReportTableByGroupNotification: React.FC<IProps> = ({ reportsStore }) => {
  const handleClose = () => {
    reportsStore?.asyncReportLoadedWithoutError.toggle(true);
  };

  const handleRetry = () => {
    reportsStore?.refreshLatestReport();
  };
  return (
    <>
      <Observer
        render={() => {
          return (
            !reportsStore.asyncReportLoadedWithoutError.value && (
              <Notification
                autoClose={false}
                type="error"
                text={
                  <>
                    <span className="ReportTableByGroupNotification-text">The complete data set failed to load.</span>
                    <span className="ReportTableByGroupNotification-retry" onClick={handleRetry}>
                      Retry
                    </span>
                  </>
                }
                title="Report Timed Out"
                onClose={handleClose}
              />
            )
          );
        }}
      />
    </>
  );
};

export default inject(REPORTS_STORE)(ReportTableByGroupNotification);
