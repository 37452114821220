class PersonAccessRoleModel {
  id: number;
  name: string;
  description: string;

  constructor(role) {
    this.id = role.id;
    this.name = role.name;
    this.description = role.description;
  }

  get data() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
    };
  }
}

export default PersonAccessRoleModel;
