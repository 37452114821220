import { HISTORY_MODE } from 'config';
import { observable, action, computed } from 'mobx';

interface IMapHistoryMode {
  id: string;
  title: string;
}

class MapHistory {
  @observable show: boolean = false;
  @observable modeId: string = HISTORY_MODE.MOST_RECENT_ACTIVITY;
  @observable modes: IMapHistoryMode[] = [
    { id: HISTORY_MODE.MOST_RECENT_ACTIVITY, title: 'Most Recent Activity' },
    { id: HISTORY_MODE.TIME_RANGE, title: 'Time Range' },
    { id: HISTORY_MODE.POINT_IN_TIME, title: 'Point in Time' },
  ];

  findModeById = (modeId: string) => {
    return this.modes.find(({ id }) => modeId === id);
  };

  @computed get activeMode() {
    return this.findModeById(this.modeId);
  }

  @action setMode = (id: string) => {
    const mode = this.findModeById(id);
    if (mode) {
      this.modeId = id;
    }
  };

  @action toggleShow = () => {
    this.setShow(!this.show);
  };

  @action setShow = (value: boolean) => {
    this.show = value;
  };
}

export default MapHistory;
