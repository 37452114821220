export default ({ color, isHover }) => {
  return isHover
    ? `<svg width="26" height="33" viewBox="0 0 26 33" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.14286 9.54737C5.14286 4.828 8.66173 1 13 1C17.3383 1 20.8571 4.828 20.8571 9.54737C20.8571 15.9579 13 25.4211 13 25.4211C13 25.4211 5.14286 15.9579 5.14286 9.54737ZM10.1939 9.54737C10.1939 11.2324 11.451 12.6 13 12.6C14.549 12.6 15.8061 11.2324 15.8061 9.54737C15.8061 7.86232 14.549 6.49474 13 6.49474C11.451 6.49474 10.1939 7.86232 10.1939 9.54737Z" fill="${color}"/>
          <path d="M2 24.8984C2 28.2125 7.66754 30 13 30C18.3325 30 24 28.2125 24 24.8984C24 23.2556 22.5734 21.8316 19.9833 20.8893C19.5408 20.7299 19.061 20.9873 18.9112 21.4662C18.7623 21.946 19.0018 22.4643 19.4443 22.6264C21.5572 23.3938 22.3077 24.3306 22.3077 24.8984C22.3077 26.2317 18.6819 28.1685 13 28.1685C7.31808 28.1685 3.69231 26.2317 3.69231 24.8984C3.69231 24.3306 4.442 23.3938 6.55485 22.6273C6.99739 22.4662 7.236 21.946 7.08708 21.4671C6.939 20.9873 6.45923 20.7318 6.01585 20.8902C3.42662 21.8316 2 23.2546 2 24.8984Z" fill="${color}"/>
        </g>
      </svg>
      `
    : `<svg width="18" height="23" viewBox="0 0 18 23" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M4 6.6C4 3.508 6.23929 1 9 1C11.7607 1 14 3.508 14 6.6C14 10.8 9 17 9 17C9 17 4 10.8 4 6.6ZM7.21429 6.6C7.21429 7.704 8.01429 8.6 9 8.6C9.98571 8.6 10.7857 7.704 10.7857 6.6C10.7857 5.496 9.98571 4.6 9 4.6C8.01429 4.6 7.21429 5.496 7.21429 6.6Z"
                fill="${color}"/>
          <path d="M2 16.6576C2 18.8289 5.60662 20 9 20C12.3934 20 16 18.8289 16 16.6576C16 15.5812 15.0922 14.6483 13.4439 14.0309C13.1623 13.9265 12.857 14.0951 12.7617 14.4089C12.6669 14.7233 12.8193 15.0628 13.1009 15.169C14.4455 15.6718 14.9231 16.2856 14.9231 16.6576C14.9231 17.5311 12.6158 18.8001 9 18.8001C5.38423 18.8001 3.07692 17.5311 3.07692 16.6576C3.07692 16.2856 3.554 15.6718 4.89854 15.1696C5.18015 15.064 5.332 14.7233 5.23723 14.4095C5.143 14.0951 4.83769 13.9277 4.55554 14.0315C2.90785 14.6483 2 15.5806 2 16.6576Z"
            fill="${color}"/>
        </g>
      </svg>
    `;
};
