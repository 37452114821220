import React from 'react';
import DashcamStatus from 'components/Admin/Dashcams/DashcamStatus';
import DashcamStatusModel, { DASHCAM_STATUS } from 'models/Dashcam/DashcamStatus';
import InfoTooltip from 'components/InfoTooltip';

import './styles.scss';

interface IProps {
  name: string;
  dashcamPing: DashcamStatusModel;
}

const MediaManagerDetails: React.FC<IProps> = ({ name, dashcamPing }) => {
  const isOnline = dashcamPing.status.value === DASHCAM_STATUS.ONLINE;
  const isOffline = dashcamPing.status.value === DASHCAM_STATUS.OFFLINE;
  const isBusy = dashcamPing.cameraDetails.isBusy.value;

  return (
    <div className="MediaManagerDetails">
      <div className="MediaManagerDetails-vehicleName">{name}</div>
      <div className="MediaManagerDetails-status">
        <DashcamStatus dashcamPing={dashcamPing} />
      </div>
      {(isOnline || isOffline) && (
        <div className="MediaManagerDetails-statusTooltip">
          <InfoTooltip>
            {isBusy
              ? 'The camera is busy. Your media request will be queued and will process when the camera comes back online.'
              : isOnline
              ? 'The camera is online. Your media request should be processed quickly'
              : 'The camera is offline. Your media request will be queued and \n' +
                'will process when the camera comes back online.'}
          </InfoTooltip>
        </div>
      )}
    </div>
  );
};

export default MediaManagerDetails;
