import React, { FC } from 'react';
import classNames from 'classnames';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as Yup from 'yup';
import { get } from 'lodash';

import Button from 'components/Button';
import CloseButton from 'components/Button/CloseButton';
import EmailPhoneRadioGroupField from '../EmailPhoneRadioGroupField';
import ExpireTime from '../ExpireTime';
import GoogleMapsLocationSearchField from 'components/GoogleMapsLocationSearch/GoogleMapsLocationSearchField';
import InfoTooltip from 'components/InfoTooltip';
import Input from 'components/Input';
import PhoneNumberInputField from '../PhoneNumberInputField';
import Textarea from 'components/Textarea';
import { emptyLocation } from 'components/GoogleMapsLocationSearch';
import { stripHtml, getNowTimestampForTimezone } from 'utils';

import './styles.scss';

export interface ICreateLinkValues {
  destination: {
    address: string;
    latitude: number;
    longitude: number;
  };
  emailPhone: string;
  email: string;
  expiration: number;
  name: string;
  notes: string;
  phoneNumber: string;
}

interface IProps {
  className?: string;
  currentMapBounds: google.maps.LatLngBounds;
  initialValues?: ICreateLinkValues;
  loading?: boolean;
  onCancel?: (dirty?: boolean) => void;
  onSubmit: (values: ICreateLinkValues) => void;
  timeZone?: string;
}

const LinkFormSchema = Yup.object().shape({
  name: Yup.string('Invalid Link Name')
    .required('Link Name is a required field')
    .max(35, 'Link Name is too long'),
  destination: Yup.object().shape({
    address: Yup.string('Invalid address'),
    latitude: Yup.number('Invalid address'),
    longitude: Yup.number('Invalid address'),
  }),
  emailPhone: Yup.string(),
  email: Yup.string().when('emailPhone', {
    is: (emailPhone) => emailPhone === 'email',
    then: Yup.string()
      .email('Invalid email address')
      .required('Email address is a required field'),
  }),
  phoneNumber: Yup.string().when('emailPhone', {
    is: (emailPhone) => emailPhone === 'phoneNumber',
    then: Yup.string()
      .matches(/(^\+\d{11}$)/gm, 'Invalid phone number')
      .required('Invalid phone number'),
  }),
  expiration: Yup.number()
    .nullable()
    .required('Prior to current time'),
  notes: Yup.string().when('emailPhone', {
    is: (emailPhone) => emailPhone === 'phoneNumber',
    then: Yup.string().max(200, 'Text note is too long'),
  }),
});

const LinkForm: FC<IProps> = ({
  className,
  currentMapBounds,
  initialValues,
  loading,
  onCancel,
  onSubmit,
  timeZone,
}) => {
  const cn = classNames('LinkForm', className);
  const nowTimestamp = getNowTimestampForTimezone(timeZone);
  const timestampInOneHour = nowTimestamp + 3600 * 1000 - (nowTimestamp % 60000);
  const name = stripHtml(get(initialValues, 'name', ''));
  const destination = get(initialValues, 'destination', emptyLocation);
  const emailPhone = get(initialValues, 'emailPhone', 'email');
  const email = get(initialValues, 'email', '');
  const phoneNumber = get(initialValues, 'phoneNumber', '');
  const expiration = get(initialValues, 'expiration', timestampInOneHour);
  const notes = get(initialValues, 'notes', '');
  const handleClose = (dirty?: boolean) => {
    onCancel(dirty);
  };

  return (
    <div className={cn}>
      <Formik
        validationSchema={LinkFormSchema}
        onSubmit={onSubmit}
        initialValues={{ name, destination, emailPhone, email, phoneNumber, expiration, notes }}
        render={({ dirty, errors, touched, isValid, values, isSubmitting }: FormikProps<ICreateLinkValues>) => {
          return (
            <>
              <div className="LinkForm-closeButton">
                <CloseButton onClick={() => handleClose(dirty)} />
              </div>
              <Form className="LinkForm-form">
                <div className="LinkForm-fields">
                  <div className="LinkForm-field">
                    <p className="LinkForm-fieldLabel">Link Name</p>
                    <div className="LinkForm-fieldContent">
                      <Field
                        component={Input}
                        error={Boolean(errors.name && touched.name) ? errors.name : ''}
                        name="name"
                        placeholder="Name"
                        maxLength={35}
                      />
                    </div>
                  </div>
                  <div className="LinkForm-field">
                    <p className="LinkForm-fieldLabel">Vehicle Destination</p>
                    <div className="LinkForm-fieldContent">
                      <Field
                        bodyClassName="CreateClearshareLinkForm"
                        component={GoogleMapsLocationSearchField}
                        error={
                          Boolean(errors.destination && touched.destination)
                            ? errors.destination.latitude || errors.destination.address
                            : ''
                        }
                        fieldName="destination"
                        mapBounds={currentMapBounds}
                        name="destination.address"
                        required={false}
                      />
                    </div>
                  </div>
                  <div className="LinkForm-field">
                    <p className="LinkForm-fieldLabel">Expire Link</p>
                    <div className="LinkForm-fieldContent LinkForm-fieldContent--expire">
                      <Field
                        component={ExpireTime}
                        error={Boolean(errors.expiration && touched.expiration) ? errors.expiration : ''}
                        name="expiration"
                        timestamp={expiration}
                        timeZone={timeZone}
                      />
                    </div>
                  </div>
                  <div className="LinkForm-field LinkForm-field--sendTo">
                    <p className="LinkForm-fieldLabel">Send Link To</p>
                    <div className="LinkForm-fieldContent LinkForm-fieldContent--sendTo">
                      <Field component={EmailPhoneRadioGroupField} name="emailPhone" checkedValue={values.emailPhone} />
                    </div>
                  </div>
                  {values.emailPhone === 'email' ? (
                    <div className="LinkForm-field">
                      <p className="LinkForm-fieldLabel" />
                      <div className="LinkForm-fieldContent LinkForm-fieldContent--email">
                        <Field
                          component={Input}
                          error={Boolean(errors.email && touched.email) ? errors.email : ''}
                          name="email"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="LinkForm-field">
                      <p className="LinkForm-fieldLabel" />
                      <div className="LinkForm-fieldContent LinkForm-fieldContent--phoneNumber">
                        <div className="LinkForm-phoneInput">
                          <Field
                            component={PhoneNumberInputField}
                            error={Boolean(errors.phoneNumber && touched.phoneNumber) ? errors.phoneNumber : ''}
                            name="phoneNumber"
                            value={values.phoneNumber}
                          />
                        </div>
                        <div className="LinkForm-phoneTooltip">
                          <InfoTooltip>
                            Standard messaging and data rates apply. US mobile phone numbers only.
                          </InfoTooltip>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="LinkForm-field">
                    <div className="LinkForm-fieldLabelWrapper">
                      <p className="LinkForm-fieldLabel LinkForm-fieldLabel--note">Note To Recipient</p>
                      {values.emailPhone === 'phoneNumber' && (
                        <div className="LinkForm-noteTooltip">
                          <InfoTooltip>
                            Messages with more than 160 characters will be split into multiple texts.
                          </InfoTooltip>
                        </div>
                      )}
                    </div>
                    <div className="LinkForm-fieldContent LinkForm-fieldContent--note">
                      <Field
                        component={Textarea}
                        counter
                        error={Boolean(errors.notes && touched.notes) ? errors.notes : ''}
                        maxLength={values.emailPhone === 'email' ? 500 : 200}
                        name="notes"
                        placeholder="Note"
                        className="TextArea--createLink"
                      />
                    </div>
                  </div>
                </div>
                <div className="LinkForm-buttons">
                  <div className="LinkForm-button LinkForm-button--cancel">
                    <Button
                      className="Button--cancel Button--cancelColorLynch"
                      onClick={() => handleClose(dirty)}
                      title="Cancel"
                      type="button"
                    />
                  </div>
                  <div className="LinkForm-button LinkForm-button--submit">
                    <Button
                      className="Button--apply"
                      disabled={!isValid || isSubmitting}
                      sending={loading}
                      title="Send"
                      type="submit"
                    />
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      />
    </div>
  );
};

export default LinkForm;
