import React from 'react';
import { observer } from 'mobx-react';

import type { SimpleField } from 'models/Fields';

import Input from 'components/Input';
import Note from 'components/Note';

import './styles.scss';

interface IProps {
  emailSubject?: SimpleField<string>;
  emailText?: SimpleField<string>;
}

const SUBJECT_EMAIL_MAX = 160;

const AlertNotifyEmail: React.FC<IProps> = ({ emailSubject, emailText }) => {
  const handleSubjectChange = (e) => {
    if (e?.target?.value?.length <= SUBJECT_EMAIL_MAX) {
      emailSubject.set(e?.target?.value ?? '');
    }
  };

  const handleTextChange = (value) => {
    emailText.set(value);
  };

  return (
    <div className="AlertNotifyEmail-container">
      <p className="AlertNotifyEmail-title">Email Message</p>

      <Input
        value={emailSubject.value}
        placeholder="Enter Email Subject"
        onChange={handleSubjectChange}
        className="AlertNotifyEmail-subject"
      />
      <Note
        isEditing
        hideControls
        note={emailText.value}
        onChange={handleTextChange}
        placeholder="Enter Email Message"
        className="AlertNotifyEmail-text"
        countCharactersEntered
      />
    </div>
  );
};

export default observer(AlertNotifyEmail);
