import { ActivationState, Client, StompHeaders, StompSubscription } from '@stomp/stompjs';
import find from 'lodash/find';

import { getCookie } from 'utils';
import { TOKEN_COOKIE_NAME, USER_ACCOUNT_ID } from 'config/cookie';

import type WSState from './WebSocketState';
import WSConnectState from './WebSocketConnectState';
import WSDisconnectState from './WebSocketDisconnectState';

import EventsBus from 'services/EventsBus/eventsBus';
import { DEFAULT_FALLBACK_STRATEGY, IFallbackStrategy, IFallbackStrategyPolling } from './WebSocketConfig';

const REFRESH_INTERVAL = 40000;
const WS_CLOSE_ERROR_CODES = [1002, 1006];

class WS {
  public url: string;
  public client: Client;
  public state: WSState;
  public wsName: string;
  public headers: StompHeaders;
  public reconnectDelay: number;
  private fallbackCallback: () => void;
  private fallbackPolling: IFallbackStrategyPolling;
  private subscriptions: StompSubscription[];
  private pollingInterval: number;
  private connectionIndex: number;

  constructor(urlRoot: string, wsName: string, fallbackStrategy: IFallbackStrategy) {
    this.url = `${urlRoot}/${wsName}`;
    this.wsName = wsName;
    this.fallbackCallback = fallbackStrategy.callback || DEFAULT_FALLBACK_STRATEGY.callback;
    this.fallbackPolling = fallbackStrategy.polling || DEFAULT_FALLBACK_STRATEGY.polling;
    this.subscriptions = [];
    this.headers = {};
    this.reconnectDelay = 0;
    this.pollingInterval = null;
    this.connectionIndex = 0;
  }

  public initialize = () => {
    if (this.client && this.client.connected) {
      return;
    }

    const token = getCookie(TOKEN_COOKIE_NAME);
    const accountId = getCookie(USER_ACCOUNT_ID);

    this.client = new Client({
      brokerURL: `${this.url}?Authorization=${token || ''}&accountId=${accountId || ''}`,
      connectHeaders: this.headers,
      reconnectDelay: this.reconnectDelay,
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,
    });

    this.client.onConnect = (res) => this.onConnect(res);
    this.client.onStompError = (error) => this.onErrorConnect(error);
    this.client.onDisconnect = () => this.onDisconnect();
    this.client.onWebSocketClose = (event) => this.onWebSocketClose(event);

    return this;
  };

  public updateUrl = (token) => {
    const accountId = getCookie(USER_ACCOUNT_ID);

    if (!token) {
      return;
    }

    this.client.configure({ brokerURL: `${this.url}?Authorization=${token}&accountId=${accountId || ''}` });

    return this;
  };

  public setOptions = (options: { headers: StompHeaders; reconnectDelay: number }) => {
    this.headers = options.headers;
    this.reconnectDelay = options.reconnectDelay || 0;

    return this;
  };

  public setState = (state: WSState) => {
    this.state = state;
    this.state.setContext(this);
    this.state.activate();
  };

  public connect = () => {
    if (this.client.state === ActivationState.INACTIVE) {
      this.setState(new WSConnectState());
    } else {
      const timer = setInterval(() => {
        if (this.client.state === ActivationState.INACTIVE) {
          this.setState(new WSConnectState());
          clearInterval(timer);
        }
      }, 500);
    }
  };

  public disconnect = () => {
    this.setState(new WSDisconnectState());
  };

  public runCommand = (destination: string, body: string) => {
    if (this.client) {
      this.client.publish({ destination, body });
    }
  };

  public get isConnected() {
    return this.state instanceof WSConnectState;
  }

  public on = (path: string, callback) => {
    if (this.isConnected) {
      this.updatePolling();

      const subscription = this.client.subscribe(
        path,
        (res) => {
          if (Boolean(res.body)) {
            this.updatePolling();
          }

          callback(res);
        },
        this.headers
      );
      this.subscriptions.push(subscription);
    }
  };

  public off = (path: string) => {
    const subscription = find(this.subscriptions, { path });

    if (subscription) {
      this.client.unsubscribe(subscription.id);
    }
  };

  public reset = () => {
    this.subscriptions.forEach(({ id }) => this.client?.unsubscribe(id));
    this.subscriptions = [];
  };

  private onConnect = async (res) => {
    if (this.connectionIndex !== 0 && this.fallbackPolling.reconnect) {
      await this.fallbackCallback();
    }

    EventsBus.get().trigger(`ws:connect:${this.wsName}`, res);

    this.connectionIndex += 1;
  };

  private onDisconnect = () => {
    EventsBus.get().trigger(`ws:disconnect:${this.wsName}`);
  };

  private onErrorConnect = (error) => {
    EventsBus.get().trigger(`ws:error:${this.wsName}`, error);

    if (!error?.body.includes('401') && !this.pollingInterval) {
      this.startPolling();
    }
  };

  private onWebSocketClose = (event) => {
    if (WS_CLOSE_ERROR_CODES.includes(event.code) && !this.pollingInterval) {
      this.startPolling();
    }
  };

  private updatePolling = () => {
    this.clearPolling();
    this.startPolling();
  };

  private startPolling = () => {
    if (this.fallbackPolling.repeat) {
      this.pollingInterval = setInterval(() => {
        this.fallbackCallback();
      }, REFRESH_INTERVAL);
    }
  };

  public clearPolling = () => {
    clearInterval(this.pollingInterval);
  };
}

export default WS;
