import TableDownload from 'models/Tables/TableDownload';
import type { DashCamsAdmin } from 'stores/Admin/Dashcams';
import timeStore from 'stores/TimeStore';
import { DATE_TIME_FORMATS } from 'config';
import { getFormattedTime } from 'utils';

class DashCamsDownload extends TableDownload<DashCamsAdmin> {
  private mapNameHeader = {
    name: 'Name',
    dashcamId: 'Dashcam ID',
    serialNumber: 'Serial #',
    uniqueId: 'Activation #',
    vehicleName: 'Assigned Vehicle',
    vehicleId: 'Assigned CP #',
    vehicleDescription: 'Assigned Vehicle Description',
    lastCommunication: 'Last Comm' + (timeStore.userTimezone ? ` (${timeStore.userTimezone})` : ''),
    updatedBy: 'Modified By',
    lastUpdateTime: 'Last Modified',
  };

  private timeFields = {
    lastUpdateTime: {
      get(value) {
        return value ? getFormattedTime(value, DATE_TIME_FORMATS.adminVehicleSettings, timeStore.sessionTimezone) : '-';
      },
    },
  };

  protected getFile = async (): Promise<Blob> => {
    const ReportFile = await import('../../Report/ReportDownload/ReportFile');
    const reportFile = new ReportFile.default(this.format.value, {
      title: 'Dashcams Admin',
      description: '',
      tables: [{ ...this.getData(), title: null }],
    });

    const file = await reportFile.create();

    return file;
  };

  protected get fileName() {
    return 'Dashcams Admin';
  }

  private getData = () => {
    const columnsIds = this.context.columns.filter((column) => column.isSelected).map((column) => column.value);

    const rows = this.context.tableSource.map((dashcam) => {
      return columnsIds.map((column) => {
        if (this.timeFields.hasOwnProperty(column)) {
          return this.timeFields[column].get(dashcam[column]);
        }

        return dashcam[column];
      });
    });

    return {
      columns: columnsIds.map((columnId) => this.mapNameHeader[columnId]),
      rows,
      total: [],
    };
  };
}

export default DashCamsDownload;
