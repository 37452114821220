import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { SimpleField, ToggleField } from '../../Fields';
import InspectionApplyTo from './InspectionApplyTo';
import { repositoryService } from 'services';
import InspectionFormView from './InspectionFormView';
import InspectionFormItems from './InspectionFormItems';

class InspectionForm {
  id: number;
  name: SimpleField<string>;
  appliesTo: InspectionApplyTo;
  items: InspectionFormItems;
  isActive: ToggleField;
  requireUpdatingOdometer: ToggleField;
  requireUpdatingEngHours: ToggleField;
  repository: IEntityRepository;
  repositoryForm: IEntityRepository;
  view: InspectionFormView;

  constructor(
    {
      id = null,
      name = '',
      assets = [],
      allAssets = false,
      items = [],
      isActive = false,
      requireUpdatingOdometer = false,
      requireUpdatingEngHours = false,
    },
    isValid = false
  ) {
    this.id = id;
    this.name = new SimpleField(name);
    this.appliesTo = new InspectionApplyTo({ allAssets, items: assets });
    this.items = new InspectionFormItems(items);
    this.isActive = new ToggleField(isActive);
    this.requireUpdatingOdometer = new ToggleField(requireUpdatingOdometer);
    this.requireUpdatingEngHours = new ToggleField(requireUpdatingEngHours);
    this.repository = repositoryService.get('dvir').entity('template');
    this.repositoryForm = this.repository.entity(String(this.id));
    this.view = new InspectionFormView();

    if (id || isValid) {
      if (!this.name.isEmpty) {
        this.name.setIsValid(true);
      }
      this.items.list.value.forEach((item) => {
        if (!item.value.name.isEmpty) {
          item.value.name.setIsValid(true);
        }
      });
    }
  }

  fetch = () => {
    //
  };

  create = async () => {
    await this.repository.create(this.data);
  };

  edit = async () => {
    await this.repositoryForm.patch(this.data);
  };

  delete = async () => {
    await this.repositoryForm.delete();
  };

  get isValid() {
    return (
      this.name.isValid && this.items.list.value.every((item) => item.value.name.isValid) && this.appliesTo.isValid
    );
  }

  get isUpdated() {
    return (
      this.name.isUpdated ||
      this.isActive.isUpdated ||
      this.requireUpdatingOdometer.isUpdated ||
      this.requireUpdatingEngHours.isUpdated ||
      this.items.list.value.some((item) => item.value.isUpdated)
    );
  }

  get data() {
    return {
      allAssets: this.appliesTo.allAssets.value,
      name: this.name.value,
      assetIds: this.appliesTo.allAssets.value ? [] : this.assetIds,
      requireUpdatingOdometer: this.requireUpdatingOdometer.value,
      requireUpdatingEngHours: this.requireUpdatingEngHours.value,
      isActive: this.isActive.value,
      items: this.items.list.toArray().map((item) => item.data),
    };
  }

  get serverData() {
    return {
      allAssets: this.appliesTo.allAssets.value,
      assets: this.appliesTo.allAssets.value
        ? []
        : this.appliesTo.items.toArray().map((item) => ({ id: item.id.value, name: item.name.value })),
      id: this.id,
      isActive: this.isActive.value,
      name: this.name.value,
      requireUpdatingEngHours: this.requireUpdatingEngHours.value,
      requireUpdatingOdometer: this.requireUpdatingOdometer.value,
      items: this.items.list.toArray().map((item) => item.data),
    };
  }

  private get assetIds() {
    return this.appliesTo.items
      .toArray()
      .filter((item) => item.id.value !== 'all')
      .map((item) => Number(item.id.value));
  }
}

export default InspectionForm;
