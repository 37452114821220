import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Observer } from 'mobx-react';
import Draggable from 'react-draggable';

import { DATE_TIME_FORMATS } from 'config';
import { getFormattedTime } from 'utils';

import './styles.scss';

interface IProps {
  lineIntervals?: number;
  timezone: string;
  timeLineStart: number;
  timeLineEnd: number;
  segmentStartTime: number;
  scrubber: number;
  duration: number;
  onChangeSegmentStartTime: (value: number) => void;
}

const SCRUBBER_SHIFT = -3;
const SEGMENT_SHIFT = -1;
const SEGMENT_WIDTH_SHIFT = 2;

const MediaManagerPlayerProgressBar: React.FC<IProps> = ({
  lineIntervals = 4,
  timezone,
  timeLineEnd,
  timeLineStart,
  segmentStartTime,
  scrubber,
  duration,
  onChangeSegmentStartTime,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [rightBound, setRightBounds] = useState(0);
  const timePeriod = useMemo(() => {
    return timeLineEnd - timeLineStart;
  }, [timeLineEnd, timeLineStart]);
  const timeInPx = useMemo(() => (ref && ref.current ? timePeriod / ref.current.offsetWidth : 1), [
    ref,
    ref.current,
    timePeriod,
  ]);

  const segmentWidth = useMemo(() => {
    return ref && ref.current ? duration / timeInPx + SEGMENT_WIDTH_SHIFT : 0;
  }, [duration, timeInPx, ref, ref.current]);

  const segmentPosition = useMemo(() => {
    return ref && ref.current ? (segmentStartTime - timeLineStart) / timeInPx + SEGMENT_SHIFT : SEGMENT_SHIFT;
  }, [ref, ref.current, segmentStartTime, timeLineStart, timeInPx]);

  const scrubberPosition = useMemo(() => {
    return ref && ref.current
      ? (scrubber - timeLineStart) / timeInPx + SCRUBBER_SHIFT - segmentPosition
      : SCRUBBER_SHIFT;
  }, [ref, ref.current, segmentPosition, scrubber, timeLineStart, timeInPx]);

  const labels = useMemo(() => {
    if (!timePeriod) {
      return [];
    }

    const delta = Math.round(timePeriod / lineIntervals);
    const timestamps = [];

    for (let i = 1; i < lineIntervals; i++) {
      timestamps.push(timeLineStart + delta * i);
    }

    timestamps.unshift(timeLineStart);
    timestamps.push(timeLineEnd);

    return timestamps.map((timestamp) => getFormattedTime(timestamp, DATE_TIME_FORMATS.hoursMinutesAm, timezone));
  }, [timeLineStart, timeLineEnd, timezone]);

  const handleLineClick = useCallback(
    (e) => {
      if (e.target.dataset.attribute === 'segment') {
        return;
      }

      const maxPosition = ref.current.offsetWidth - segmentWidth;
      const currentPosition = e.nativeEvent.offsetX + SEGMENT_SHIFT;
      const timestamp = Math.round(
        maxPosition < currentPosition
          ? maxPosition * timeInPx + timeLineStart
          : currentPosition * timeInPx + timeLineStart
      );

      onChangeSegmentStartTime(timestamp);
    },
    [ref, ref.current, segmentWidth, timeInPx]
  );

  const handleDrag = useCallback(
    (_, ui) => {
      const timestamp = Math.round(ui.lastX * timeInPx + timeLineStart);

      onChangeSegmentStartTime(timestamp);
    },
    [timeInPx, timeLineStart]
  );

  const getLabelWidth = useCallback(
    (i) => {
      const width = Math.round(100 / lineIntervals);
      return i === 0 || i === labels.length - 1 ? width / 2 : width;
    },
    [lineIntervals]
  );

  useEffect(() => {
    setRightBounds(ref.current.offsetWidth - segmentWidth);
  }, [segmentWidth]);

  return (
    <Observer
      render={() => (
        <div className="MediaManagerPlayerProgressBar">
          <div className="MediaManagerPlayerProgressBar-line" ref={ref} onClick={handleLineClick}>
            <Draggable
              position={{ x: segmentPosition, y: 0 }}
              axis="x"
              bounds={{ top: 0, left: 0, right: rightBound, bottom: 0 }}
              onDrag={handleDrag}
            >
              <div
                data-attribute="segment"
                className="MediaManagerPlayerProgressBar-segment"
                style={{ width: `${segmentWidth}px` }}
              >
                <div className="MediaManagerPlayerProgressBar-scrubber" style={{ left: `${scrubberPosition}px` }} />
              </div>
            </Draggable>
          </div>
          <div className="MediaManagerPlayerProgressBar-labels">
            <ul className="MediaManagerPlayerProgressBar-labels--container">
              {labels.map((label, i) => (
                <li
                  className="MediaManagerPlayerProgressBar-labels--item"
                  key={i}
                  style={{
                    width: `${getLabelWidth(i)}%`,
                  }}
                >
                  {label}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    />
  );
};

export default MediaManagerPlayerProgressBar;
