import React from 'react';
import { observer } from 'mobx-react';

import type AlertIntervalModel from 'models/Alerts/AlertInterval';

import AlertPreferencesOptionItemFabric from '../AlertPreferencesOptionItemFabric';

import './styles.scss';

interface IProps {
  interval: AlertIntervalModel;
  fields: Alerts.Modal.Options.Type[];
}

const AlertPreferencesMinimumInterval: React.FC<IProps> = ({ fields = [], interval }) => {
  return (
    <div className="AlertPreference-line">
      <div className="AlertPreference-label AlertPreferencesMinimumInterval-label">Minimum interval</div>
      <div className="AlertPreference-value">
        <div className="AlertPreferencesMinimumInterval-counter">
          {fields.map((type) => (
            <AlertPreferencesOptionItemFabric key={type} type={type} field={interval[type]} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(AlertPreferencesMinimumInterval);
