import React, { FC } from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const MediaLibraryPanelSectionTitle: FC<IProps> = ({ children, className }) => {
  const cn = classNames('MediaLibraryPanelSectionTitle', className);

  return <p className={cn}>{children}</p>;
};

export default MediaLibraryPanelSectionTitle;
