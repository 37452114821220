import React from 'react';

import './styles.scss';

interface IProps {
  name: string;
}

const ReportTableScheduledReportName: React.FC<IProps> = ({ name }) => {
  return (
    <div className="ReportTableScheduledReportName">
      <p>{name}</p>
    </div>
  );
};

export default ReportTableScheduledReportName;
