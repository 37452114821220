import React, { useEffect } from 'react';

import { ALERTS_TYPE, LINKS } from 'config';
import classnames from 'classnames';

import AlertTileAfterFabric from '../../AlertTiles/AlertTileFabric';

import './styles.scss';

interface IAlertType {
  name: ALERTS_TYPE;
  pro?: boolean;
}

interface IProps {
  selected?: ALERTS_TYPE;
  types: IAlertType[];
  onClick?: (value: ALERTS_TYPE) => void;
  setIsActiveNextButton: (value: boolean) => void;
  isProAcc?: boolean;
}

const AlertPreferenceSelectAlert: React.FC<IProps> = ({
  types,
  selected,
  onClick,
  setIsActiveNextButton,
  isProAcc,
}) => {
  const proTypes = types.filter(({ name, pro }) => pro && name !== ALERTS_TYPE.CUSTOM);
  const customTypes = types.filter(({ name }) => name === ALERTS_TYPE.CUSTOM);
  const regularTypes = types.filter(({ name, pro }) => !pro && name !== ALERTS_TYPE.CUSTOM);

  const onChooseAlert = (type: ALERTS_TYPE) => {
    onClick(type);
    setIsActiveNextButton(true);
  };

  const cnCustom = classnames('AlertPreferenceSelectAlert-items AlertPreferenceSelectAlert-items--custom', {
    'AlertPreferenceSelectAlert-items--hidden': !customTypes.length,
  });

  const cnPro = classnames('AlertPreferenceSelectAlert-items AlertPreferenceSelectAlert-items--pro', {
    'AlertPreferenceSelectAlert-items--hidden': !proTypes.length,
  });

  useEffect(() => {
    if (selected) {
      setIsActiveNextButton(true);
    } else if (regularTypes.length === 1) {
      onChooseAlert(regularTypes[0].name);
    }
  }, []);

  const openTab = () => {
    window.open(LINKS.upgradeToPro, '_blank');
  };

  return (
    <div>
      <div className="AlertPreferenceSelectAlert-items">
        {regularTypes.map(({ name, pro }) => {
          const handleClick = () => {
            onChooseAlert(name);
          };

          return (
            <AlertTileAfterFabric
              key={name}
              type={name}
              requiredPro={pro}
              isActive={selected === name}
              onClick={handleClick}
              isProAcc={isProAcc}
            />
          );
        })}
      </div>
      <div className={cnCustom}>
        {customTypes.map(({ name, pro }) => {
          const handleClick = () => {
            if (!pro) {
              onChooseAlert(name);
            } else {
              openTab();
            }
          };

          return (
            <AlertTileAfterFabric
              key={name}
              type={name}
              requiredPro={pro}
              isActive={selected === name}
              onClick={handleClick}
              isProAcc={isProAcc}
            />
          );
        })}
      </div>
      <div className={cnPro}>
        {proTypes.map(({ name, pro }) => (
          <AlertTileAfterFabric
            key={name}
            type={name}
            requiredPro={pro}
            isActive={selected === name}
            onClick={openTab}
            isProAcc={isProAcc}
          />
        ))}
      </div>
    </div>
  );
};

export default AlertPreferenceSelectAlert;
