import React, { useCallback, useMemo } from 'react';

import {
  deviceMediaMarkerHistory,
  deviceMediaMarkerHistoryActive,
  deviceMediaMarker,
  deviceMediaMarkerActive,
} from 'assets';
import { MAP_MARKERS } from 'config';

import Marker from 'components/Map/Marker';

interface IProps {
  lat: number;
  lng: number;
  update: (value: string) => void;
  interaction: string;
  showHistory: boolean;
}

const DeviceMediaMarkerItem: React.FC<IProps> = ({ lat, lng, interaction, update, showHistory }) => {
  const handleMouseEnter = useCallback(() => {
    update('hover');
  }, [update]);

  const handleMouseLeave = useCallback(() => {
    update('default');
  }, [update]);

  const handleClick = useCallback(() => {
    update('active');
  }, [update]);

  const icon = useMemo(() => {
    if (showHistory) {
      return interaction === 'hover' ? deviceMediaMarkerHistoryActive : deviceMediaMarkerHistory;
    } else {
      return interaction === 'hover' ? deviceMediaMarkerActive : deviceMediaMarker;
    }
  }, [showHistory, interaction]);

  return (
    <Marker
      anchor={new google.maps.Point(12, 12)}
      icon={icon}
      zIndex={
        interaction === 'active' ? MAP_MARKERS.ACTIVE : interaction === 'hover' ? MAP_MARKERS.HOVER : MAP_MARKERS.BASE
      }
      position={{ lat, lng }}
      onClick={handleClick}
      onMouseOver={handleMouseEnter}
      onMouseOut={handleMouseLeave}
    />
  );
};

export default DeviceMediaMarkerItem;
