import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import classnames from 'classnames';
import { some, noop } from 'lodash';

import type { RouterStore, Inspections } from 'stores';
import type { VehiclesAdmin } from 'stores/Admin/Vehicles';
import {
  INSPECTIONS_FORMS_TABLE_COLUMNS,
  INSPECTIONS_VIEW,
  INSPECTIONS_STORE,
  ROUTER_STORE,
  STORAGE_ITEMS,
} from 'config';
import { getJSONItemFromStorage, setJSONItemToStorage } from 'utils';

import AssetCellWithPopup from 'components/Table/AssetCellWithPopup';
import Button from 'components/Button';
import CustomTable from 'components/Table/CustomTable';
import TableActions from 'components/Table/Actions';
import InspectionToggleTable from 'components/Inspections/ToggleTable';
import type InspectionApplyTo from 'models/Inspections/Models/InspectionApplyTo';
import ConfirmDeleteFormModal from 'components/Inspections/DeleteFormModal';
import InspectionsFormPanel from 'components/Inspections/InspectionsFormPanel';

import './styles.scss';

interface IProps {
  [INSPECTIONS_STORE]?: Inspections;
  [ROUTER_STORE]?: RouterStore;
  vehiclesAdmin?: VehiclesAdmin;
}

interface IState {
  isFilterOpen: boolean;
  isEditInspectionPanelOpen: boolean;
  isDuplicateInspectionPanelOpen: boolean;
  isConfirmDeleteModalOpen: boolean;
}

@inject(({ inspectionsStore, adminStore: { vehiclesAdmin }, routerStore }) => ({
  inspectionsStore,
  vehiclesAdmin,
  routerStore,
}))
@observer
class InspectionFormsTable extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isFilterOpen: getJSONItemFromStorage(STORAGE_ITEMS.inspections.forms.filterOpenStatus, true),
      isEditInspectionPanelOpen: false,
      isDuplicateInspectionPanelOpen: false,
      isConfirmDeleteModalOpen: false,
    };
  }

  get isSearchApplied() {
    const {
      inspectionsStore: {
        forms: {
          table: { searchData },
        },
      },
    } = this.props;

    return some(searchData, (data) => Boolean(data));
  }

  get emptyText() {
    return this.isSearchApplied ? (
      ''
    ) : (
      <div className="InspectionFormsTable-emptyText">
        <span className="InspectionFormsTable-emptyText--bold">Inspections Forms</span> will appear here. Click{' '}
        <span className="InspectionFormsTable-emptyText--bold">Create New Form</span> to get started.
      </div>
    );
  }

  get isAdminView() {
    return this.props.inspectionsStore.view.value === INSPECTIONS_VIEW.ADMIN;
  }

  get tableHeight() {
    return {
      [INSPECTIONS_VIEW.DEFAULT]: {
        default: 'calc(100vh - 300px)',
        withOpenFilters: 'calc(100vh - 330px)',
      },
      [INSPECTIONS_VIEW.ADMIN]: {
        default: 'calc(100vh - 330px)',
        withOpenFilters: 'calc(100vh - 360px)',
      },
    };
  }

  get editColumnsMap() {
    return {
      [INSPECTIONS_FORMS_TABLE_COLUMNS.INSPECTION_FORM]: {
        text: 'Inspection Form',
        isDisabled: true,
      },
      [INSPECTIONS_FORMS_TABLE_COLUMNS.APPLIES_TO]: {
        text: 'Applies To',
        isDisabled: false,
      },
      [INSPECTIONS_FORMS_TABLE_COLUMNS.TOTAL_ITEMS]: {
        text: 'Total Items',
        isDisabled: false,
      },
      [INSPECTIONS_FORMS_TABLE_COLUMNS.TOTAL_REQUIRED_ITEMS]: {
        text: 'Total Required Items',
        isDisabled: false,
      },
      [INSPECTIONS_FORMS_TABLE_COLUMNS.ACTIVE]: {
        text: 'Active',
        isDisabled: false,
      },
    };
  }

  fetch = (params) => {
    const {
      vehiclesAdmin: { selectedVehicle },
      inspectionsStore: {
        forms: { fetch },
      },
    } = this.props;

    if (this.isAdminView && selectedVehicle) {
      fetch(params, selectedVehicle.assetId);
    } else {
      fetch(params);
    }
  };

  getColumns = () => {
    const {
      inspectionsStore: {
        forms: {
          table: {
            columns,
            sortedColumn: { field, order },
          },
        },
      },
    } = this.props;

    const allColumns = [
      {
        title: 'Inspection Form',
        dataIndex: INSPECTIONS_FORMS_TABLE_COLUMNS.INSPECTION_FORM,
        defaultSortOrder: field === INSPECTIONS_FORMS_TABLE_COLUMNS.INSPECTION_FORM ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
        render: (value, form) => (
          <div className="InspectionFormsTable-title">
            <Button className="Button--link" title={value} onClick={() => this.openEditInspectionFormPanel(form.key)} />
          </div>
        ),
        fixed: 'left',
      },
      {
        title: 'Applies To',
        dataIndex: INSPECTIONS_FORMS_TABLE_COLUMNS.APPLIES_TO,
        width: 180,
        hiddenSearch: true,
        render: (appliesTo: InspectionApplyTo) =>
          appliesTo.allAssets.value ? 'All Vehicles' : <AssetCellWithPopup assets={appliesTo.items.toArray()} />,
      },
      {
        title: 'Total Items',
        dataIndex: INSPECTIONS_FORMS_TABLE_COLUMNS.TOTAL_ITEMS,
        width: 180,
        hiddenSearch: true,
      },
      {
        title: 'Total Required Items',
        dataIndex: INSPECTIONS_FORMS_TABLE_COLUMNS.TOTAL_REQUIRED_ITEMS,
        width: 180,
        hiddenSearch: true,
      },
      {
        title: 'Active',
        dataIndex: INSPECTIONS_FORMS_TABLE_COLUMNS.ACTIVE,
        width: 180,
        hiddenSearch: true,
        render: (_, { active, inspectionForm, isUpdateLoading, appliesTo, update }) => (
          <InspectionToggleTable
            isActive={active}
            inspectionName={inspectionForm}
            loading={isUpdateLoading}
            allAssets={appliesTo.allAssets.value}
            assets={appliesTo.items.toArray().map(({ name }) => name.value)}
            save={update}
          />
        ),
      },
    ];

    const filteredColumns = [];

    columns.forEach((column) => {
      if (column.isSelected) {
        filteredColumns.push(allColumns.find((col) => col.dataIndex === column.value));
      }
    });

    return [
      ...filteredColumns.filter((col) => !col.hidden),
      {
        title: 'Actions',
        dataIndex: 'actions',
        width: 200,
        className: 'ant-table-cell--actions',
        render: (_, form) => {
          const items = [
            {
              text: 'Edit Form',
              onClick: () => this.openEditInspectionFormPanel(form.key),
            },
            {
              text: 'Duplicate Form',
              onClick: () => this.openDuplicateInspectionFormPanel(form.key),
            },
            {
              text: 'Delete Form',
              onClick: () => this.openConfirmDeleteFormModal(form.key),
            },
          ];

          return (
            <div className="InspectionFormsTable-actions">
              <TableActions items={items} />
            </div>
          );
        },
      },
    ];
  };

  handleOpenFilter = (isFilterOpen: boolean) => {
    this.setState({ isFilterOpen });
    setJSONItemToStorage(STORAGE_ITEMS.inspections.forms.filterOpenStatus, isFilterOpen);
  };

  openConfirmDeleteFormModal = async (id: number) => {
    const {
      inspectionsStore: { forms },
    } = this.props;

    const formToDelete = forms.findById(id);
    forms.form.set(formToDelete.value);

    if (formToDelete) {
      this.setState({ isConfirmDeleteModalOpen: true });
    }
  };

  closeConfirmDeleteFormModal = () => {
    this.setState({ isConfirmDeleteModalOpen: false });
  };

  deleteForm = async () => {
    const {
      inspectionsStore: {
        forms: { form },
      },
    } = this.props;

    await form.value.delete();
    if (form.value.repositoryForm.deleteState.success) {
      this.closeConfirmDeleteFormModal();
      form.reset();
    }
  };

  openEditInspectionFormPanel = (id: number) => {
    const {
      inspectionsStore: { forms },
    } = this.props;

    forms.edit(id);

    if (forms.form.value) {
      this.setState({ isEditInspectionPanelOpen: true });
    }
  };

  closeEditInspectionFormPanel = () => {
    this.setState({ isEditInspectionPanelOpen: false });
  };

  openDuplicateInspectionFormPanel = (id: number) => {
    const {
      inspectionsStore: { forms },
    } = this.props;

    forms.duplicate(id);

    if (forms.form.value) {
      this.setState({ isDuplicateInspectionPanelOpen: true });
    }
  };

  closeDuplicateInspectionFormPanel = () => {
    this.setState({ isDuplicateInspectionPanelOpen: false });
  };

  render() {
    const {
      inspectionsStore: {
        forms,
        forms: {
          table: {
            columns,
            pagination,
            searchColumns,
            searchData,
            setColumns,
            setPagination,
            setSearchColumn,
            setSortedColumn,
            sortData,
            source,
          },
          total,
          repository: { getState },
          form,
        },
        view,
      },
    } = this.props;
    const { isFilterOpen, isEditInspectionPanelOpen, isDuplicateInspectionPanelOpen, isConfirmDeleteModalOpen } =
      this.state;
    const cn = classnames('InspectionFormsTable', {
      'InspectionFormsTable--withOpenFilters': isFilterOpen,
      [`InspectionFormsTable--${view.value}`]: Boolean(view.value),
    });
    const tableHeight = isFilterOpen
      ? this.tableHeight[view.value].withOpenFilters
      : this.tableHeight[view.value].default;

    return (
      <div className={cn}>
        <CustomTable
          columns={this.getColumns()}
          dataSource={source}
          editColumnsMap={this.editColumnsMap}
          getData={this.fetch}
          isFilterActive={isFilterOpen}
          loading={getState.loading}
          triggerUpdate={
            form.value?.repositoryForm.deleteState.success ||
            form.value?.repository.createState.success ||
            form.value?.repositoryForm.patchState.success
          }
          onColumnsChange={setColumns}
          onColumnSort={setSortedColumn}
          onColumnsSearch={setSearchColumn}
          onFilterOpen={this.handleOpenFilter}
          onPaginationChange={setPagination}
          pagination={pagination}
          searchColumns={searchColumns}
          searchParams={searchData}
          selectedColumns={toJS(columns)}
          sortedColumn={sortData}
          tableHeight={tableHeight}
          total={total.value}
          totalTitle={total.value && `${total.value} Inspection Forms`}
          withActions
          emptyText={this.emptyText}
        />
        <ConfirmDeleteFormModal
          open={isConfirmDeleteModalOpen}
          name={form.value?.name.value}
          onCancel={this.closeConfirmDeleteFormModal}
          onSubmit={this.deleteForm}
          loading={form.value?.repositoryForm.deleteState.loading}
        />
        <InspectionsFormPanel
          isPanelOpen={isEditInspectionPanelOpen}
          inspectionForm={forms.form.value}
          onSave={forms.form.value?.edit}
          loading={forms.form.value?.repositoryForm.patchState.loading}
          canDelete
          closePanel={this.closeEditInspectionFormPanel}
          title="Edit Inspection Form"
        />
        <InspectionsFormPanel
          isPanelOpen={isDuplicateInspectionPanelOpen}
          inspectionForm={forms.form.value}
          onSave={forms.form.value?.create}
          loading={forms.form.value?.repository.createState.loading}
          closePanel={this.closeDuplicateInspectionFormPanel}
          title="Duplicate Inspection Form"
        />
      </div>
    );
  }
}

export default InspectionFormsTable;
