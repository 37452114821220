import React from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  Icon: React.ElementType;
  title: string;
  onClick: () => void;
  isSelected: boolean;
  className?: string;
}

const MapLayerButton: React.FC<IProps> = ({ Icon, title, isSelected, onClick, className }) => {
  const cn = classNames('MapLayerButton', className, { 'MapLayerButton--selected': isSelected });

  return (
    <div className={cn} onClick={onClick}>
      <div className="MapLayerButton-container">
        <div className="MapLayerButton-icon">
          <Icon />
        </div>
        <div className="MapLayerButton-title">{title}</div>
      </div>
    </div>
  );
};

export default MapLayerButton;
