import { computed, observable } from 'mobx';

export class ReportGroup implements Report.IReportGroup {
  id: string;
  name: string;
  @observable collapsed: boolean = false;
  @observable reports: Report.IReport[] = [];

  constructor({ id, name }) {
    this.id = id;
    this.name = name;
  }

  @computed get pinnedCount() {
    return this.reports.filter(({ pinned }) => pinned).length;
  }
}

export default ReportGroup;
