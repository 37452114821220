import { action, computed, observable } from 'mobx';
import { ADMIN_DASHCAMS_TABLE_COLUMNS, STORAGE_ITEMS } from 'config';
import TableBase from 'stores/TableBase';

import type { IEditColumns } from 'models/Tables/IEditColumns';
import type { ISortColumn } from 'models/Tables/ISortColumn';
import Dashcam from 'models/Dashcam';
import { repositoryService } from 'services';
import type Repository from 'services/RepositoryService/Repository';
import DashCamsDownload from 'models/Admin/DashCams/DashCamsDownload';

const initialColumns: Array<IEditColumns<ADMIN_DASHCAMS_TABLE_COLUMNS>> = [
  { value: ADMIN_DASHCAMS_TABLE_COLUMNS.NAME, isSelected: true },
  { value: ADMIN_DASHCAMS_TABLE_COLUMNS.SERIAL_NUMBER, isSelected: true },
  { value: ADMIN_DASHCAMS_TABLE_COLUMNS.UNIQUE_ID, isSelected: false },
  { value: ADMIN_DASHCAMS_TABLE_COLUMNS.VEHICLE_NAME, isSelected: true },
  { value: ADMIN_DASHCAMS_TABLE_COLUMNS.VEHICLE_DESCRIPTION, isSelected: true },
  { value: ADMIN_DASHCAMS_TABLE_COLUMNS.VEHICLE_ID, isSelected: false },
  { value: ADMIN_DASHCAMS_TABLE_COLUMNS.DASHCAM_ID, isSelected: false },
  { value: ADMIN_DASHCAMS_TABLE_COLUMNS.LAST_COMMUNICATION, isSelected: true },
  { value: ADMIN_DASHCAMS_TABLE_COLUMNS.UPDATED_BY, isSelected: false },
  { value: ADMIN_DASHCAMS_TABLE_COLUMNS.LAST_UPDATE_TIME, isSelected: false },
];

const initialSortedColumn: ISortColumn = {
  field: 'name',
  order: 'ascend',
};

const storageNames = {
  columns: STORAGE_ITEMS.admin.dashcams.columns,
  sortedColumn: STORAGE_ITEMS.admin.dashcams.sortedColumn,
  pagination: STORAGE_ITEMS.admin.dashcams.pagination,
  searchInColumn: STORAGE_ITEMS.admin.dashcams.searchInColumn,
};

export interface ITableDashcam {
  id: string;
  name: string;
  uniqueId: string;
  serialNumber: string;
  dashcamId: string;
  vehicleId: string;
  vehicleName: string;
  lastCommunication: string;
  vehicleDescription: string;
  key: string;
  updatedBy: string;
  lastUpdateTime: number;
  assetId: number;
}

export interface IDashCamsSearchParams {
  name?: string;
  uniqueId?: string;
  serialNumber?: string;
  dashcamId?: string;
  vehicleId?: string;
  vehicleName?: string;
  vehicleDescription?: string;
  updatedBy?: string;
  lastUpdateTime?: string;
}

export enum DashcamSearchMap {
  name = 'name',
  uniqueId = 'uniqueId',
  serialNumber = 'serialNumber',
  dashcamId = 'dashcamId',
  vehicleId = 'vehicleId',
  vehicleName = 'vehicleName',
  vehicleDescription = 'vehicleDescription',
  lastCommunication = 'lastCommunication',
  updatedBy = 'updatedBy',
  lastUpdateTime = 'lastUpdateTime',
}

export class DashCamsAdmin extends TableBase<IEditColumns<ADMIN_DASHCAMS_TABLE_COLUMNS>> {
  @observable dashcamsList: Dashcam[] = [];
  @observable dashcamsListTotal: number = 0;
  @observable selectedDashcam: Dashcam = new Dashcam({});
  @observable downloadDashcams: DashCamsDownload;
  repositoryDashcams: Repository;

  constructor() {
    super({ columns: initialColumns, sortedColumn: initialSortedColumn, storageNames });

    this.repositoryDashcams = repositoryService.get('dashcams');
    this.downloadDashcams = new DashCamsDownload(
      repositoryService
        .get('notifications')
        .entity('email')
        .entity('document')
    ).initialize(this);
  }

  @computed get tableSource(): ITableDashcam[] {
    return this.dashcamsList.map(
      ({
        id,
        name,
        uniqueId,
        serialNumber,
        dashcamId,
        vehicleId,
        vehicleName,
        lastCommunication,
        vehicleDescription,
        updatedBy,
        lastUpdateTime,
        assetId,
      }: Dashcam) => {
        return {
          id: id.value || null,
          name: name.value || '-',
          uniqueId: uniqueId.value,
          serialNumber: serialNumber.value,
          dashcamId: dashcamId.value,
          vehicleId: vehicleId.value || '-',
          vehicleName: vehicleName.value || '-',
          lastCommunication: lastCommunication.value ? lastCommunication.userDate : '-',
          lastEventTimestamp: lastCommunication.value ? lastCommunication.value : 0,
          vehicleDescription: vehicleDescription.value || '-',
          key: `${id.value}_${uniqueId.value}`,
          updatedBy: updatedBy.value || '-',
          lastUpdateTime: lastUpdateTime.value || 0,
          assetId: assetId.value,
        };
      }
    );
  }

  @computed get searchData() {
    return this.selectedSearchColumns.reduce(
      (acc, cur) => ({
        ...acc,
        [DashcamSearchMap[cur.column]]: cur.value,
      }),
      {}
    );
  }

  @computed get sortData() {
    return {
      sortBy: DashcamSearchMap[this.sortedColumn.field],
      order: this.sortedColumn.order?.replace('end', '').toUpperCase(),
    };
  }

  @action getAllDashcams = async ({
    order,
    page,
    search,
    pageSize,
    sortBy,
  }: {
    order?: string;
    page?: number;
    pageSize?: number;
    search?: IDashCamsSearchParams;
    sortBy?: string;
  }) => {
    const response = await this.repositoryDashcams.get({ order, page, ...search, pageSize, sortBy });

    this.dashcamsList = response.items.map((item) => new Dashcam(item));
    this.dashcamsListTotal = response.total;
  };
}

export default new DashCamsAdmin();
