import { repositoryService } from '../services';

export const initPendo = ({ visitorId, accountId }) => {
  window.pendo?.initialize?.({
    visitor: {
      id: visitorId,
      email: visitorId,
    },
    account: {
      id: accountId,
    },
  });
};

const requestToken = () => {
  const repository = repositoryService.get('zendesk-tokens');
  let request;
  let token;

  return () => {
    if (token !== undefined) {
      return Promise.resolve(token);
    }
    if (request) {
      return request;
    }
    request = repository.get().then((json) => {
      request = undefined;
      token = json && json.token !== 'Error' ? json.token : '';
      return token;
    });
    return request;
  };
};

export const initZendesk = () => {
  const getToken = requestToken();

  if (window && window.zE) {
    let isChatInitComplete = false;

    // Init the callback handler when the Zendesk chat widget has connected to server
    window.zE('webWidget:on', 'open', () => {
      window?.pendo?.events?.on('guidesLoaded', validatePendoChatTimeOuted);
    });

    window.zE('webWidget:on', 'chat:connected', () => {
      getToken().then((token) => {
        if (token) {
          if (!isChatInitComplete) {
            window.$zopim(() => {
              window.$zopim.livechat.authenticate({
                jwtFn: (callback) => {
                  isChatInitComplete = true;
                  callback(token);
                },
              });
            });
          }
        } else {
          isChatInitComplete = true;
        }
      });
      if (!isChatInitComplete) {
        window.zE('webWidget', 'chat:reauthenticate');
      }
    });
  }
};

export const validatePendoChatTimeOuted = () => {
  setTimeout(validatePendoChat, 0);
};

export const validatePendoChat = () => {
  const guideName = 'Live Chat';
  if (window.pendo && window.pendo.findGuideByName(guideName).isInProgress()) {
    window.pendo.showGuideById(window.pendo.findGuideByName(guideName).id);
  }
};
