import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { reaction, IReactionDisposer } from 'mobx';
import classNames from 'classnames';
import find from 'lodash/find';
import isNull from 'lodash/isNull';

import { GeozonesAdmin, geozoneColors } from 'stores/Admin/Geozones';
import { getNumber, validateGeozoneName } from 'utils';
import validateAccessLevel from 'stores/acl/validator';
import { ADMIN_GEOZONES_UPDATE, UNITS } from 'config';

import AdminGeozoneMap from '../AdminGeozoneMap';
import InfoTooltip from 'components/InfoTooltip';
import Input from 'components/Input';
import GeozoneTypeRadio from 'components/GeozoneTypeRadio';
import ColorPicker from 'components/ColorPicker';
import DispatchInputGeozone from 'components/Admin/DispatchInputGeozone';
import ZoneMarkersList from 'components/Admin/ZoneMarkersList';
import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';
import NumberInputWithDecimalAndThousands from 'components/Input/NumberInputWithDecimalAndThousands';
import type { IChangeParams } from '../SelectedGeozone';

import './styles.scss';

interface IProps {
  className?: string;
  geozonesAdmin?: GeozonesAdmin;
  onChange: <T extends IChangeParams>(field: T | T[]) => void;
}

@inject(({ adminStore: { geozonesAdmin } }) => ({
  geozonesAdmin,
}))
@observer
class AdminGeozoneDetails extends Component<IProps, any> {
  typeWatcherDisposer: IReactionDisposer;

  get currentUnits() {
    const { units } = this.props.geozonesAdmin.selectedGeozone;
    const currentUnit = find(UNITS, { label: units });
    return { label: currentUnit.label, value: currentUnit.label };
  }

  get units() {
    return UNITS.map(({ label }) => ({ label, value: label }));
  }

  handleNameChange = (e) => {
    const {
      onChange,
      geozonesAdmin: {
        selectedGeozone: { setGeozoneName },
      },
    } = this.props;
    const name = e.target.value;

    setGeozoneName(name);
    onChange({ name: 'name', value: name });
  };

  handleShapeChange = (shape) => {
    const {
      onChange,
      geozonesAdmin: {
        selectedGeozone: { setGeozoneShape },
      },
    } = this.props;

    setGeozoneShape(shape);
    onChange({ name: 'type', value: shape });
  };

  handleColorChange = (color) => {
    const {
      onChange,
      geozonesAdmin: {
        selectedGeozone: { setGeozoneColor },
      },
    } = this.props;

    setGeozoneColor(color);
    onChange({ name: 'color', value: color });
  };

  handleRadiusChange = (value: string) => {
    const {
      onChange,
      geozonesAdmin: {
        selectedGeozone: { setRadius, radius },
      },
    } = this.props;

    setRadius(getNumber(value));
    onChange({ name: 'radius', value: radius });
  };

  handleUnitChange = ({ label }) => {
    const {
      onChange,
      geozonesAdmin: {
        selectedGeozone: { setUnits },
      },
    } = this.props;

    setUnits(label);
    onChange({ name: 'units', value: label });
  };

  handleDispatchResults = ({
    address,
    latitude,
    longitude,
  }: {
    address?: string;
    latitude?: number;
    longitude?: number;
  }) => {
    const {
      onChange,
      geozonesAdmin: {
        selectedGeozone: { setDispatchMarkerAddress, setDispatchMarkerLatitude, setDispatchMarkerLongitude },
      },
    } = this.props;

    setDispatchMarkerAddress(address);
    setDispatchMarkerLatitude(latitude);
    setDispatchMarkerLongitude(longitude);

    onChange([
      { name: 'dispatchMarkerAddress', value: address },
      { name: 'dispatchMarkerLatitude', value: latitude },
      { name: 'dispatchMarkerLongitude', value: longitude },
    ]);
  };

  createTypeWatcher = () => {
    this.typeWatcherDisposer = reaction(
      () => {
        const {
          geozonesAdmin: { selectedGeozone },
        } = this.props;
        return selectedGeozone?.coordinates;
      },
      (coordinates) => {
        this.props.onChange({ name: 'coordinates', value: coordinates });
      }
    );
  };

  componentDidMount() {
    this.createTypeWatcher();
  }

  componentWillUnmount() {
    this.typeWatcherDisposer();
  }

  render() {
    const {
      className,
      geozonesAdmin: {
        selectedGeozone: {
          name,
          type,
          color,
          dispatchMarkerPositionModel,
          dispatchMarkerPositionString,
          formatterCoordinates,
          formattedRadius,
          hoveredMarkerIndex,
          circleMarker,
          setHoveredMarkerIndex,
          setCoordinate,
          removeMarker,
          addPoint,
          hoveredDispatchMarker,
        },
      },
    } = this.props;
    const cn = classNames('AdminGeozoneDetails', className);
    const geozoneColor = color || geozoneColors.find((color) => color.id === 3);
    const isEditable = validateAccessLevel([ADMIN_GEOZONES_UPDATE]);

    return (
      <div className={cn}>
        <div className="AdminGeozoneDetails-data">
          <div className="AdminGeozoneDetails-section AdminGeozoneDetails-section--name">
            <div className="AdminGeozoneDetails-content AdminGeozoneDetails-content--name">
              <Input
                className="Input--admin"
                disabled={!isEditable}
                label="Name"
                placeholder="Geozone Name"
                onChange={this.handleNameChange}
                value={name}
                maxLength={40}
                error={!validateGeozoneName(name) ? 'Geozone Name is a required field' : ''}
              />
            </div>
          </div>
          <div className="AdminGeozoneDetails-section AdminGeozoneDetails-section--shapeColor">
            <div className="AdminGeozoneDetails-title AdminGeozoneDetails-title--shapeColor">
              <div className="AdminGeozoneDetails-titleText AdminGeozoneDetails-titleText--shapeColor">Shape/Color</div>
              <div className="AdminGeozoneDetails-tooltip AdminGeozoneDetails-tooltip--shapeColor">
                <InfoTooltip>
                  When changing the shape of a Geozone, the points chosen will be discarded and a new shape will be
                  drawn.
                </InfoTooltip>
              </div>
            </div>
            <div className="AdminGeozoneDetails-content AdminGeozoneDetails-content--shapeColor">
              <div className="AdminGeozoneDetails-shape">
                <GeozoneTypeRadio disabled={!isEditable} onChange={this.handleShapeChange} activeType={type} />
              </div>
              <div className="AdminGeozoneDetails-color">
                <ColorPicker
                  disabled={!isEditable}
                  colors={geozoneColors}
                  onChange={this.handleColorChange}
                  activeColor={geozoneColor}
                />
              </div>
            </div>
          </div>
          <div className="AdminGeozoneDetails-section AdminGeozoneDetails-section--dispatchMarker">
            <div className="AdminGeozoneDetails-title AdminGeozoneDetails-title--dispatchMarker">
              <div className="AdminGeozoneDetails-titleText AdminGeozoneDetails-titleText--dispatchMarker">
                dispatch Marker
              </div>
              <div className="AdminGeozoneDetails-tooltip AdminGeozoneDetails-tooltip--dispatchMarker">
                <InfoTooltip>
                  This point is used to give route directions to the Geozone, and can be used to specify where the best
                  entrypoint to the Geozone is located.
                </InfoTooltip>
              </div>
            </div>
            <div className="AdminGeozoneDetails-content AdminGeozoneDetails-content--dispatchMarker">
              <DispatchInputGeozone
                disabled={!isEditable}
                dimmed={!isNull(hoveredMarkerIndex)}
                color={color}
                onChange={this.handleDispatchResults}
                selectedAddress={dispatchMarkerPositionModel}
                searchValue={dispatchMarkerPositionString}
              />
            </div>
          </div>
          {type.toLowerCase() === 'circle' && (
            <div className="AdminGeozoneDetails-section AdminGeozoneDetails-section--zoneRadius">
              <div className="AdminGeozoneDetails-title AdminGeozoneDetails-title--zoneRadius">
                <div className="AdminGeozoneDetails-titleText AdminGeozoneDetails-titleText--zoneRadius">Radius</div>
              </div>
              <div className="AdminGeozoneDetails-content AdminGeozoneDetails-content--zoneMarkers">
                <NumberInputWithDecimalAndThousands
                  key={`${type.toLowerCase()}-${this.currentUnits?.value}`}
                  disabled={!isEditable}
                  className="AdminGeozoneDetails-zoneRadiusInput"
                  value={formattedRadius}
                  onValueChange={this.handleRadiusChange}
                />
                <div className="AdminGeozoneDetails-zoneUnitsSelect">
                  <SearchableStaticSelect
                    disabled={!isEditable}
                    isSearchable={false}
                    value={this.currentUnits}
                    values={this.units}
                    handleChange={this.handleUnitChange}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="AdminGeozoneDetails-section AdminGeozoneDetails-section--zoneMarkers">
            <div className="AdminGeozoneDetails-title AdminGeozoneDetails-title--zoneMarkers">
              <div className="AdminGeozoneDetails-titleText AdminGeozoneDetails-titleText--zoneMarkers">
                Zone Markers
              </div>
            </div>
            <div className="AdminGeozoneDetails-content AdminGeozoneDetails-content--zoneMarkers">
              <ZoneMarkersList
                dimmed={hoveredDispatchMarker}
                disabled={!isEditable}
                center={formatterCoordinates[0]}
                type={type}
                color={color}
                circlePathMarkerPosition={circleMarker}
                hoverIndex={hoveredMarkerIndex}
                coordinates={formatterCoordinates}
                onHover={setHoveredMarkerIndex}
                updateMarker={setCoordinate}
                removeMarker={removeMarker}
                addMarker={addPoint}
              />
            </div>
          </div>
        </div>
        <div className="AdminGeozoneDetails-map">
          <AdminGeozoneMap onChange={this.props.onChange} />
        </div>
      </div>
    );
  }
}

export default AdminGeozoneDetails;
