import type { PLAYER_ERROR_TYPE } from 'config';
import PlayerState from './PlayerState';

class ErrorPlayerState extends PlayerState {
  type: PLAYER_ERROR_TYPE;

  constructor(type: PLAYER_ERROR_TYPE) {
    super();
    this.type = type;
  }

  activate = async () => {
    clearInterval(this.context.timer);
    this.context.isLoading.toggle(false);
  };

  deactivate = () => {
    this.type = null;
  };
}

export default ErrorPlayerState;
