import { observable } from 'mobx';
import { ALERTS_TYPE } from 'config';
import isNumber from 'lodash/isNumber';
import AlertWithIntervalModel from './AlertWithInterval';
import { SimpleField } from 'models/Fields';

class MaintenanceDueAlertModel extends AlertWithIntervalModel {
  @observable subType: SimpleField<Alerts.Modal.MaintenanceDue.Type>;
  @observable dueValue: SimpleField<number>;

  constructor(item) {
    super({
      ...item,
      isIntervalActive: isNumber(item.interval) ? Boolean(item.interval) : true,
      interval: isNumber(item.interval) ? item.interval : 7 * 24 * 60 * 60 * 1000,
    });

    this.subType = new SimpleField(item.subType || 'MILEAGE_1');
    this.dueValue = new SimpleField(item.dueValue || 0);
    this.intervalFields = ['weeks', 'days'];
    this.urlType = 'maintenance-due';
    this.description = 'Our system compares Vehicle Maintenance vs the scheduled maintenance once daily.';
    this.type.reInitialize(ALERTS_TYPE.MAINTENANCE_DUE);
  }

  get model() {
    return { ...super.model, subType: this.subType.value, dueValue: this.dueValue.value };
  }

  isUpdated(): boolean {
    return super.isUpdated() || this.subType.isUpdated || this.dueValue.isUpdated;
  }
}

export default MaintenanceDueAlertModel;
