import React, { CSSProperties } from 'react';
import classNames from 'classnames';

import Icon from 'components/Icon';

import { SendingSpinnner, CheckIcon } from 'assets';
import './styles.scss';

interface IProps {
  title: string;
  styles?: CSSProperties;
  IconComponent?: React.ReactNode;
  icon?: {
    src: string;
    style?: {
      width: string;
      height: string;
    };
  };
  className?: string;
  onClick?: (value?: any) => void;
  disabled?: boolean;
  inline?: boolean;
  type?: 'button' | 'submit' | 'reset';
  sending?: boolean;
  sent?: boolean;
  form?: string;
}

const Button: React.FC<IProps> = ({
  title,
  IconComponent,
  icon,
  className,
  onClick,
  disabled,
  inline,
  styles,
  type,
  sending,
  sent,
  form,
  ...props
}) => {
  const cn = classNames('Button', className, {
    'Button--disabled': disabled,
    'Button--inline': inline,
    'Button--sending': sending,
    'Button--sent': sent,
  });

  return (
    <button
      className={cn}
      style={styles}
      form={form}
      {...props}
      type={type}
      onClick={onClick}
      disabled={disabled || sending}
    >
      {Boolean(IconComponent) && <span className="Button-icon">{IconComponent}</span>}
      {Boolean(icon) && (
        <span className="Button-icon">
          <Icon src={icon.src} srcStyles={icon.style} />
        </span>
      )}
      {Boolean(title && !sending && !sent) && <span className="Button-title">{title}</span>}
      {Boolean(sending) && <SendingSpinnner className="Button-sendingIcon" />}
      {Boolean(!sending && sent) && <CheckIcon fill="#fff" width={18} height={14} className="Button-sentIcon" />}
    </button>
  );
};

export default Button;
