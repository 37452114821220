import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import type { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';

import { DATE_TIME_FORMATS, PATHS } from 'config';
import { DASHCAM_STATUS } from 'models/Dashcam/DashcamStatus';
import { getEndOfDayByDate, getFormattedTime, getStartOfDayByDate } from 'utils';
import type { MediaManager, TimeStore } from 'stores';

import MediaManagerWrapper from 'components/DashCams/MediaManagerWrapper';
import MediaRequestReview from 'components/DashCams/MediaRequestReview';

import './styles.scss';

interface ISearchParams {
  assetId?: string;
  startTimestamp?: string;
  duration?: string;
}

interface IProps extends RouteComponentProps {
  mediaManagerStore?: MediaManager;
  timeStore?: TimeStore;
}

@inject(({ mediaManagerStore, timeStore }) => ({ mediaManagerStore, timeStore }))
@observer
class RequestMediaStep3 extends Component<IProps> {
  async componentDidMount() {
    const {
      mediaManagerStore: { checkout },
      history,
    } = this.props;

    if (!this.startTimestamp || !this.duration) {
      history.push(PATHS.DASHCAMS.MEDIA_MANAGER);
    }

    await checkout.fetch(this.assetId);

    checkout
      .preSelectCameraJournalByTimestamp(this.startTimestamp)
      .preSelectRequestMediaCameraType(false)
      .preSelectRequestMediaStartTime(this.startTimestamp)
      .preSelectRequestMediaDuration(this.duration);
  }

  get assetId() {
    const {
      history: {
        location: { search },
      },
    } = this.props;

    const { assetId } = queryString.parse(search) as ISearchParams;

    return assetId;
  }

  get startTimestamp() {
    const {
      history: {
        location: { search },
      },
    } = this.props;

    const { startTimestamp } = queryString.parse(search) as ISearchParams;

    return parseInt(startTimestamp, 10);
  }

  get duration() {
    const {
      history: {
        location: { search },
      },
    } = this.props;

    const { duration } = queryString.parse(search) as ISearchParams;

    return parseInt(duration, 10);
  }

  redirectToMediaManager = () => {
    const {
      mediaManagerStore: {
        checkout: { repositoryMediaRequest },
      },
      history: { push },
      timeStore: { sessionTimezone },
    } = this.props;
    const from = getStartOfDayByDate(this.startTimestamp, sessionTimezone);
    const to = getEndOfDayByDate(this.startTimestamp, sessionTimezone);

    if (repositoryMediaRequest?.createState.success) {
      push(`${PATHS.DASHCAMS.MEDIA_MANAGER}?assetId=${this.assetId}&from=${from}&to=${to}`);
    }
  };

  requestMedia = async () => {
    const {
      mediaManagerStore: {
        checkout: { request },
      },
    } = this.props;

    await request();
    this.redirectToMediaManager();
  };

  get isOffline() {
    const {
      mediaManagerStore: {
        checkout: { dashcamStatus },
      },
    } = this.props;

    return dashcamStatus.status.value === DASHCAM_STATUS.OFFLINE || dashcamStatus.cameraDetails.isBusy.value;
  }

  get secondStepPath() {
    const {
      mediaManagerStore: {
        checkout: { findSelectedInterval },
      },
    } = this.props;
    const selectedCameraInterval = findSelectedInterval();

    return `${PATHS.DASHCAMS.MEDIA_REQUEST.INDEX}/step-2?assetId=${this.assetId}&startTimestamp=${selectedCameraInterval?.startTimestamp}&endTimestamp=${selectedCameraInterval?.endTimestamp}`;
  }

  get firstStepPath() {
    return `${PATHS.DASHCAMS.MEDIA_REQUEST.INDEX}/step-1?assetId=${this.assetId}`;
  }

  get requestDisabled() {
    const {
      mediaManagerStore: {
        checkout: {
          repositoryMediaRequest,
          requestMedia: {
            cameraType: { cameras },
          },
        },
      },
    } = this.props;
    const activeCameras = cameras.some((camera) => camera.isAvailable.value && camera.media.isActive.value);

    return repositoryMediaRequest?.createState.loading || !activeCameras;
  }

  render(): React.ReactNode {
    const {
      mediaManagerStore: {
        checkout: {
          isLoading,
          vehicle,
          requestMedia: {
            timeSegment: {
              duration: { active },
              startTime,
            },
            cameraType,
            clipName,
          },
          repositoryMediaRequest,
        },
      },
      timeStore: { sessionTimezone },
    } = this.props;

    return (
      <MediaManagerWrapper
        isLoading={isLoading.value}
        isRequestLoading={repositoryMediaRequest?.createState.loading}
        disabledSteps={this.isOffline ? [2] : []}
        currentStep={3}
        onSubmit={this.requestMedia}
        nextDisabled={this.requestDisabled}
        prevPath={this.isOffline ? this.firstStepPath : this.secondStepPath}
      >
        <div className="RequestMediaStep3">
          <div className="RequestMediaStep3-header dimmed-grey">Review and submit your request</div>
          <div className="RequestMediaStep3-description dimmed-grey">
            The media will be downloaded and stored in your Dashcam Media page once it is retrieved from the Dashcam.
          </div>

          <div className="RequestMediaStep3-details dimmed-grey">
            <MediaRequestReview
              vehicle={vehicle?.details?.displayName || vehicle?.details?.deviceId || ''}
              time={getFormattedTime(
                startTime.value || 0,
                DATE_TIME_FORMATS.fullMonthComaDateYearHoursMinutesSecondsSpaceAM,
                sessionTimezone
              )}
              length={active.value / 1000}
              cameraType={cameraType}
              clipName={clipName}
            />
          </div>
        </div>
      </MediaManagerWrapper>
    );
  }
}

export default RequestMediaStep3;
