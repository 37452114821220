import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import type { FieldProps } from 'formik';

import { getFormattedTime, getNowTimestampForTimezone, getTimestampForTimezone, isTomorrow } from 'utils';
import RadioGroup from 'components/RadioGroup';
import TimePicker from 'components/TimeRange/TimePicker';

import './styles.scss';

interface IProps extends FieldProps {
  className?: string;
  error?: string;
  timestamp?: number;
  timeZone?: string;
}

const days = [
  {
    id: 'today',
    label: 'Today',
    name: 'expiryTime',
    value: 'today',
  },
  {
    id: 'tomorrow',
    label: 'Tomorrow',
    name: 'expiryTime',
    value: 'tomorrow',
  },
];
const isInFuture = (timestamp: number, timeZone: string) => timestamp > getNowTimestampForTimezone(timeZone);

const ExpireTime: FC<IProps> = ({ className, error, timestamp = null, field, form, timeZone }) => {
  const cn = classNames('ExpireTime', className);
  const initialDay = isTomorrow(timestamp, timeZone) ? 'tomorrow' : 'today';
  const nowTimestamp = getNowTimestampForTimezone(timeZone);
  const timestampInOneHour = nowTimestamp + 3600 * 1000;
  const initialTime = timestamp
    ? getFormattedTime(timestamp, 'hh:mm a', timeZone)
    : getFormattedTime(timestampInOneHour, 'hh:mm a', timeZone);
  const [selectedDay, setSelectedDay] = useState(initialDay);
  const [selectedTime, setSelectedTime] = useState(initialTime);

  const handleDayChange = (value) => {
    setSelectedDay(value);
  };
  const handleTimeChange = (value) => {
    setSelectedTime(value);
  };
  useEffect(() => {
    const { setFieldTouched } = form;
    const tzNowTimestamp = getNowTimestampForTimezone(timeZone);
    const selectedDate =
      selectedDay === 'today'
        ? getFormattedTime(tzNowTimestamp, 'YYYY MM DD', timeZone)
        : getFormattedTime(tzNowTimestamp + 3600 * 1000 * 24, 'YYYY MM DD', timeZone);
    const timestamp = getTimestampForTimezone(`${selectedDate} ${selectedTime}`, 'YYYY MM DD hh:mm a', timeZone);

    if (field) {
      field.onChange({
        target: {
          name: field.name,
          value: isInFuture(timestamp, timeZone) ? timestamp : null,
        },
      });

      setFieldTouched(field.name);
    }
  }, [selectedDay, selectedTime, timeZone]);

  return (
    <div className={cn}>
      <div className="ExpireTime-controls">
        <div className="ExpireTime-day">
          <RadioGroup onChange={handleDayChange} radios={days} checkedValue={selectedDay} tabs size="small" />
        </div>
        <span className="ExpireTime-at">at</span>
        <div className="ExpireTime-time">
          <TimePicker
            className="TimePicker--createClearshare"
            after={selectedDay === 'today' ? getFormattedTime(nowTimestamp, 'hh:mm a', timeZone) : undefined}
            onSelect={handleTimeChange}
            value={selectedTime}
            type="input"
          />
        </div>
        <span className="ExpireTime-timezone">{timeZone}</span>
      </div>
      {Boolean(error) && <p className="ExpireTime-error">{error}</p>}
    </div>
  );
};

export default ExpireTime;
