import React from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  disabled?: boolean;
  error?: boolean;
  className?: string;
  onChange: () => void;
  checked: boolean;
}

const Toggle: React.FC<IProps> = ({ className, disabled = false, error = false, onChange, checked }) => {
  const cn = classNames('Toggle', className, {
    'Toggle--disabled': disabled,
    'Toggle--error': error,
  });

  const handleChange = () => {
    if (!disabled) {
      onChange();
    }
  };

  return <input disabled={disabled} className={cn} type="checkbox" onChange={handleChange} checked={checked} />;
};

Toggle.defaultProps = {
  onChange: () => true,
  checked: false,
};

export default Toggle;
