import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import SearchableSelect from 'components/Select/SearchableSelect';

import type { AlertsTableStore } from 'stores';

interface IProps {
  emptySearchResults?: string;
  handleChange?: (option: Select.ISelectOption) => void;
  handleMenuClose?: () => void;
  isMenuOpen?: boolean;
  isSearchable?: boolean;
  menuShouldBlockScroll?: boolean;
  persistChange?: boolean;
  alertsTableStore?: AlertsTableStore;
  placeholder?: string;
  searchPlaceholder?: string;
  value?: Select.ISelectOption;
  withAll?: boolean;
}

@inject(({ alertsTableStore }) => ({ alertsTableStore }))
@observer
class SearchableCronOptionsSelect extends Component<IProps> {
  getData = () => {
    return Promise.resolve({
      options: this.props.alertsTableStore.cronOptions.values.map(option => {
        return {
          value: option.name,
          label: option.description,
        }
      }),
      totalPages: 1,
    });
  };

  handleChange = (option: Select.ISelectOption) => {
    this.props.handleChange(option);
  };

  render() {
    return (
      <SearchableSelect
        {...this.props}
        value={this.props.value?.value ? this.props.value : {value: 'no', label: 'No'}}
        isSearchable={Boolean(false)}
        getData={this.getData}
        handleChange={this.handleChange}
        isMenuOpen={this.props.isMenuOpen}
        onMenuClose={this.props.handleMenuClose}
        menuShouldBlockScroll={this.props.menuShouldBlockScroll}
      />
    );
  }
}

export default SearchableCronOptionsSelect;
