import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

export const validate12hTime = (time: string): string[] => {
  const timeRegExp = new RegExp(/((1[0-2]|0?[1-9]):?([0-5][0-9])?([AaPp][Mm]))/gm);

  return time.match(timeRegExp);
};

export const validate12hTimeWithSeconds = (time: string): string[] => {
  const timeRegExp = new RegExp(/((1[0-2]|0?[1-9]):([0-5][0-9]):([0-5][0-9])([AaPp][Mm]))/gm);

  return time.match(timeRegExp);
};

export const validateGroupName = (name: string) => {
  return /^(?=.*[a-zA-Z0-9])[\w\s&()#'_-]+$/.test(name);
};

export const getValidGroupName = (name: string) => {
  return name.replace(/[^\w\s&()#'_-]/g, '');
};

export const validateNonBreakingSpace = (value: string): string => {
  return value.replace(/\u00A0/g, '');
};

export const getValidVehicleName = (name: string) => {
  return validateNonBreakingSpace(name);
};

export const validateGeozoneName = (name) => /\S+/.test(name);

export const getValidName = (name: string) => {
  const validName = name.replace(/[^\w\s&()#'_-]/g, '');
  return validateNonBreakingSpace(validName);
};

export const getValidMaintenanceName = (name: string) => {
  const validName = name.replace(/[^\w\s\/\\&()#,+$~.'”:*<>_-]/g, '');
  return validateNonBreakingSpace(validName);
};

export const validateName = (name) => /\S+/.test(name);

export const validateDigitsOnly = (value) => /^\d+$/.test(value);

export const validateEmail = (email: string) => {
  try {
    return Yup.string()
      .email()
      .validateSync(email);
  } catch (_) {
    return false;
  }
};

export const validatePhoneNumber = (phoneNumber: string) => {
  return /^[0-9\(\)\ \-]{16,16}$/.test(phoneNumber);
};

export const getValidPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/[^0-9]/g, '');
};

export const formatPhoneNumber = (phoneNumber: string) => {
  let result = '';

  if (phoneNumber && phoneNumber.length > 2) {
    result = `(${phoneNumber.substr(0, 3)}) `;

    if (phoneNumber.length > 5) {
      result += `${phoneNumber.substr(3, 3)} - ${phoneNumber.substr(6)}`;
    } else {
      result += phoneNumber.substr(3);
    }

    return result;
  } else {
    return phoneNumber;
  }
};

export const getValidUSPhoneNumber = (num: string) => {
  const isUSCountryCode = /^\+1/.test(num);
  const digits = num.replace(/[^0-9]/g, '');
  const rest = isUSCountryCode ? digits.substr(1) : digits;
  const isRestStartedWithZero = /^0/.test(rest);
  const localPhoneNumberLength = 10;
  let phoneNumber = '';

  if (!isRestStartedWithZero) {
    if (isUSCountryCode && rest.length === localPhoneNumberLength) {
      phoneNumber = `+${digits}`;
    } else if (!isUSCountryCode && digits.length === localPhoneNumberLength) {
      phoneNumber = `+1${digits}`;
    }
  }

  return isPossiblePhoneNumber(phoneNumber) ? phoneNumber : '';
};

export const getValidTankCapacity = (value: number) => `${value}`.match(/(\.)?(\d{0,3}\.\d{0,2})|(\d{0,3})/g)[0];
