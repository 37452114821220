import React, { FC } from 'react';
import type { FieldProps } from 'formik';

import GoogleMapsLocationSearch from './index';

import { Search2Icon } from 'assets';

interface IProps extends FieldProps {
  fieldName?: string;
  mapBounds?: google.maps.LatLngBounds;
  required?: boolean;
}

const GoogleMapsLocationSearchField: FC<IProps> = ({ field, fieldName, mapBounds, required }) => {
  const handleChange = (value) => {
    field.onChange({
      target: {
        name: fieldName ? fieldName : field.name,
        value,
      },
    });
  };

  return (
    <GoogleMapsLocationSearch
      googleMapURL={process.env.REACT_APP_GOOGLE_MAP_URL}
      icon={<Search2Icon fill="#919EB8" display="block" />}
      loadingElement={<div style={{ height: `100%` }} />}
      mapBounds={mapBounds}
      onChange={handleChange}
      placeholder="Search Location"
      required={required}
      withIcon
    />
  );
};

export default GoogleMapsLocationSearchField;
