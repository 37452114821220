import { computed, observable } from 'mobx';
import TableBase from 'stores/TableBase';
import type { Inspections } from 'stores';
import type { IEditColumns } from 'models/Tables/IEditColumns';
import type { ISortColumn } from 'models/Tables/ISortColumn';
import {
  INSPECTIONS_PERFORMED_TABLE_COLUMNS,
  INSPECTIONS_SEARCH_PARAMS,
  INSPECTIONS_VIEW,
  STORAGE_ITEMS,
} from 'config';
import Filters from './Filters';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import PerformedTableDownload from './PerformedTableDownload';
import { getMinutesSecondsText } from 'utils';
import { repositoryService } from 'services';

const getInitialColumns = (view: INSPECTIONS_VIEW): Array<IEditColumns<INSPECTIONS_PERFORMED_TABLE_COLUMNS>> => {
  switch (view) {
    case INSPECTIONS_VIEW.ADMIN:
      return [
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.START_DATE_TIME, isSelected: true },
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.RESULTS, isSelected: true },
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.INSPECTION_FORM, isSelected: true },
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.COMPLETION_DATE_TIME, isSelected: true },
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.ELAPSED_TIME, isSelected: true },
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.INSPECTION_BY, isSelected: true },
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.LOCATION, isSelected: true },
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.ODOMETER_AT_INSPECTION, isSelected: true },
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.ENGINE_HOURS_AT_INSPECTION, isSelected: true },
      ];
    case INSPECTIONS_VIEW.DEFAULT:
    default:
      return [
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.START_DATE_TIME, isSelected: true },
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.VEHICLE, isSelected: true },
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.RESULTS, isSelected: true },
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.GROUPS, isSelected: true },
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.INSPECTION_FORM, isSelected: true },
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.COMPLETION_DATE_TIME, isSelected: true },
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.ELAPSED_TIME, isSelected: true },
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.INSPECTION_BY, isSelected: true },
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.LOCATION, isSelected: true },
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.ODOMETER_AT_INSPECTION, isSelected: true },
        { value: INSPECTIONS_PERFORMED_TABLE_COLUMNS.ENGINE_HOURS_AT_INSPECTION, isSelected: true },
      ];
  }
};

const initialSortedColumn: ISortColumn = {
  field: INSPECTIONS_PERFORMED_TABLE_COLUMNS.START_DATE_TIME,
  order: 'descend',
};

const storageNames = {
  [INSPECTIONS_VIEW.DEFAULT]: {
    columns: STORAGE_ITEMS.inspections.performed.columns,
    sortedColumn: STORAGE_ITEMS.inspections.performed.sortedColumn,
    pagination: STORAGE_ITEMS.inspections.performed.pagination,
    searchInColumn: STORAGE_ITEMS.inspections.performed.searchInColumn,
  },
  [INSPECTIONS_VIEW.ADMIN]: {
    columns: STORAGE_ITEMS.admin.inspections.performed.columns,
    sortedColumn: STORAGE_ITEMS.admin.inspections.performed.sortedColumn,
    pagination: STORAGE_ITEMS.admin.inspections.performed.pagination,
    searchInColumn: STORAGE_ITEMS.admin.inspections.performed.searchInColumn,
  },
};

const inspectionsSearchMap = {
  [INSPECTIONS_PERFORMED_TABLE_COLUMNS.START_DATE_TIME]: INSPECTIONS_SEARCH_PARAMS.CREATION_TIME,
  [INSPECTIONS_PERFORMED_TABLE_COLUMNS.VEHICLE]: INSPECTIONS_SEARCH_PARAMS.ASSET_NAME,
  [INSPECTIONS_PERFORMED_TABLE_COLUMNS.RESULTS]: INSPECTIONS_SEARCH_PARAMS.STATUS,
  [INSPECTIONS_PERFORMED_TABLE_COLUMNS.GROUPS]: INSPECTIONS_SEARCH_PARAMS.GROUPS,
  [INSPECTIONS_PERFORMED_TABLE_COLUMNS.INSPECTION_FORM]: INSPECTIONS_SEARCH_PARAMS.TEMPLATE_NAME,
  [INSPECTIONS_PERFORMED_TABLE_COLUMNS.COMPLETION_DATE_TIME]: INSPECTIONS_SEARCH_PARAMS.PERFORMED_ON,
  [INSPECTIONS_PERFORMED_TABLE_COLUMNS.ELAPSED_TIME]: INSPECTIONS_SEARCH_PARAMS.ELAPSED_TIME,
  [INSPECTIONS_PERFORMED_TABLE_COLUMNS.INSPECTION_BY]: INSPECTIONS_SEARCH_PARAMS.PERFORMED_BY,
  [INSPECTIONS_PERFORMED_TABLE_COLUMNS.LOCATION]: INSPECTIONS_SEARCH_PARAMS.ADDRESS,
  [INSPECTIONS_PERFORMED_TABLE_COLUMNS.ODOMETER_AT_INSPECTION]: INSPECTIONS_SEARCH_PARAMS.ODOMETER_KM,
  [INSPECTIONS_PERFORMED_TABLE_COLUMNS.ENGINE_HOURS_AT_INSPECTION]: INSPECTIONS_SEARCH_PARAMS.ENGINE_HOURS,
};

export default class InspectionsPerformedTable extends TableBase<IEditColumns<INSPECTIONS_PERFORMED_TABLE_COLUMNS>> {
  @observable downloadTable: PerformedTableDownload;
  filters: Filters;
  context: Inspections;
  repositoryNotifications: IRepository;

  constructor(context: Inspections) {
    super({
      columns: getInitialColumns(context.view.value),
      sortedColumn: initialSortedColumn,
      storageNames: storageNames[context.view.value],
    });

    this.repositoryNotifications = repositoryService.get('notifications');
    this.context = context;
    this.filters = new Filters({ results: this.resultsColumnInitialValues });
    this.downloadTable = new PerformedTableDownload(
      this.repositoryNotifications.entity('email').entity('document')
    ).initialize(this);
  }

  @computed get source() {
    return this.context.performed.items
      .toArray()
      .map(
        ({
          asset,
          loggerInfo,
          results,
          form,
          completedTime,
          elapsedTime,
          inspectionBy,
          location,
          service,
          id,
          groups,
          pdf,
        }) => {
          return {
            [INSPECTIONS_PERFORMED_TABLE_COLUMNS.START_DATE_TIME]: loggerInfo.creationTime.date,
            [INSPECTIONS_PERFORMED_TABLE_COLUMNS.VEHICLE]: asset.name.value,
            [INSPECTIONS_PERFORMED_TABLE_COLUMNS.RESULTS]: results.results,
            [INSPECTIONS_PERFORMED_TABLE_COLUMNS.GROUPS]: groups,
            [INSPECTIONS_PERFORMED_TABLE_COLUMNS.INSPECTION_FORM]: form.name.value,
            [INSPECTIONS_PERFORMED_TABLE_COLUMNS.COMPLETION_DATE_TIME]: Boolean(completedTime.value)
              ? completedTime.date
              : '-',
            [INSPECTIONS_PERFORMED_TABLE_COLUMNS.ELAPSED_TIME]: elapsedTime ? getMinutesSecondsText(elapsedTime) : '-',
            [INSPECTIONS_PERFORMED_TABLE_COLUMNS.INSPECTION_BY]: inspectionBy,
            [INSPECTIONS_PERFORMED_TABLE_COLUMNS.LOCATION]: location.address.value || '-',
            [INSPECTIONS_PERFORMED_TABLE_COLUMNS.ODOMETER_AT_INSPECTION]: service.odometer
              ? `${service.odometer?.toLocaleString('en-US', { maximumFractionDigits: 3 })} mi`
              : '-',
            [INSPECTIONS_PERFORMED_TABLE_COLUMNS.ENGINE_HOURS_AT_INSPECTION]: service.engineHours
              ? `${service.engineHours?.toLocaleString('en-US', { maximumFractionDigits: 1 })} hrs`
              : '-',
            pdf,
            key: id,
          };
        }
      );
  }

  private get resultsColumnInitialValues() {
    return (
      this.selectedSearchColumns.find((col) => col.column === INSPECTIONS_PERFORMED_TABLE_COLUMNS.RESULTS)?.value || ''
    );
  }

  @computed get searchData() {
    return this.selectedSearchColumns.reduce((acc, cur) => {
      return {
        ...acc,
        [inspectionsSearchMap[cur.column]]: cur.value,
      };
    }, {});
  }

  @computed get sortData() {
    return {
      sortBy: inspectionsSearchMap[this.sortedColumn.field],
      order: this.sortedColumn.order?.replace('end', '').toUpperCase(),
    };
  }
}
