import { action } from 'mobx';
import { repositoryService } from 'services';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { SimpleField } from 'models/Fields';
import DashcamStatusCameraDetails from './DashcamStatusCameraDetails';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

export enum DASHCAM_STATUS {
  UNKNOWN = 'unknown',
  ONLINE = 'online',
  OFFLINE = 'offline',
  PINGING = 'pinging',
}

class DashcamStatusModel {
  status: SimpleField<DASHCAM_STATUS>;
  cameraDetails: DashcamStatusCameraDetails;
  private timerDelay = 15000;
  repositoryDashcamsPing: IEntityRepository;

  constructor() {
    this.status = new SimpleField(DASHCAM_STATUS.UNKNOWN);
    this.cameraDetails = new DashcamStatusCameraDetails();
    this.repositoryDashcamsPing = repositoryService.get('media', 'v2.0').entity('cameras');
  }

  @action fetch = async (id: string) => {
    this.status.set(DASHCAM_STATUS.PINGING);

    if (!id) {
      return;
    }

    try {
      const timer = setTimeout(() => (this.status.value = DASHCAM_STATUS.UNKNOWN), this.timerDelay);
      const response = await this.repositoryDashcamsPing
        .entity(id)
        .entity('ping')
        .get();

      clearTimeout(timer);
      this.status.set(response.status);

      if (response.cameraDetails) {
        response.cameraDetails.enabledCameraTypes.forEach((type) => this.cameraDetails.availableCameraTypes.add(type));
        this.cameraDetails.isBusy.toggle(response.cameraDetails.isBusy);
        this.cameraDetails.audioEnabled.toggle(response.cameraDetails.audioEnabled);
      }

      EventsBus.get().trigger(APP_EVENTS.DASHCAM_STATUS, response.status);
    } catch (_) {
      this.status.set(DASHCAM_STATUS.UNKNOWN);
      EventsBus.get().trigger(APP_EVENTS.DASHCAM_STATUS, DASHCAM_STATUS.UNKNOWN);
    }
  };

  get isOnline() {
    return this.status.value === DASHCAM_STATUS.ONLINE;
  }

  clear = () => {
    this.status.set(DASHCAM_STATUS.UNKNOWN);
  };
}

export default DashcamStatusModel;
