import React from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  icon: JSX.Element;
  title: string;
  isActive?: boolean;
  requiredPro?: boolean;
  description: string;
  onClick?: () => void;
  integrationNote?: string;
  className?: {
    icon?: string;
    title?: string;
    description?: string;
  };
  isProAcc?: boolean;
}

const AlertTile: React.FC<IProps> = ({
  icon,
  title,
  description,
  onClick = () => true,
  isActive,
  requiredPro = false,
  integrationNote = '',
  className = {},
  isProAcc,
}) => {
  const cn = classNames('AlertTile', {
    'AlertTile--active': isActive && !requiredPro,
    'AlertTile--disabled': requiredPro && isProAcc,
    'AlertTile-pro': requiredPro,
  });

  return (
    <div className={cn} onClick={onClick}>
      <div className={classNames('AlertTile-icon', className.icon)}>{icon}</div>
      <div className="AlertTile-content">
        <div className={classNames('AlertTile-title', className.title)}>{title}</div>
        <div className={classNames('AlertTile-description', className.description)}>{description}</div>
        {integrationNote && <div className="AlertTile-integrationNote">{integrationNote}</div>}
      </div>
      {requiredPro && !isProAcc && <div className="AlertTile-pro-title">Pro</div>}
    </div>
  );
};

export default AlertTile;
