import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';

import Popup from 'components/Popup';
import ColorPickerTrigger from './ColorPickerTrigger';
import ColorPickerList from './ColorPickerList';

import './styles.scss';

interface IProps {
  className?: string;
  disabled?: boolean;
  activeColor: Geozone.IGeozoneColor;
  colors: Geozone.IGeozoneColor[];
  onChange: (color: Geozone.IGeozoneColor) => void;
  size?: 'small';
}

const ColorPicker: FC<IProps> = ({ className, disabled = false, activeColor, colors, onChange, size }) => {
  const [isOpen, setOpen] = useState(false);
  const cn = classNames('ColorPicker', className, {
    [`ColorPicker--${size}`]: Boolean(size),
    'ColorPicker--disabled': disabled,
  });
  const handleColorChange = (color: Geozone.IGeozoneColor) => {
    setOpen(false);
    onChange(color);
  };

  const handleOpen = useCallback(
    (isOpen) => {
      if (!disabled) {
        setOpen(isOpen);
      }
    },
    [disabled]
  );

  return (
    <div className={cn}>
      <Popup
        disabled={disabled}
        isOpen={isOpen}
        triggerElement={<ColorPickerTrigger color={activeColor} />}
        children={
          <ColorPickerList
            activeColor={activeColor}
            className="ColorPickerList--geozoneColors"
            colors={colors}
            onChange={handleColorChange}
          />
        }
        onVisibleChange={handleOpen}
      />
    </div>
  );
};

export default ColorPicker;
