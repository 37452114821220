import { observable, action, computed, reaction } from 'mobx';
import { sortBy, orderBy, uniqBy } from 'lodash';

import GeozonesFilters from './Filters';
import PinnedListBase from '../PinnedListBase';
import { repositoryService } from 'services';
import { Geozone } from 'models';
import type Repository from 'services/RepositoryService/Repository';
import { RequestType } from 'services/RepositoryService/RequestsType';

const colorFilterOrder = [2, 12, 5, 1, 4, 3, 6, 8, 11, 7, 10, 9];

export class GeozonesStore extends PinnedListBase {
  @observable selectedGeozoneId: string = '';
  @observable selectedGeozone: Geozone = null;
  @observable geozonesList: Geozone[] = [];
  @observable filters: GeozonesFilters = new GeozonesFilters();
  repositoryGeozone: Repository;

  constructor() {
    super();

    reaction(
      () => this.selectedGeozoneId,
      (id) => this.setSelectedGeozone(id)
    );

    this.repositoryGeozone = repositoryService.get('geozones');
  }

  @computed get filteredGeozonesList() {
    const filteredGeozones = this.geozonesList.filter(({ name, tag, color }: Geozone) => {
      return (
        (name.toLowerCase().includes(this.filters.query.toLowerCase()) ||
          tag.toLowerCase().includes(this.filters.query.toLowerCase())) &&
        (this.filters.selectedColorIds.length ? this.filters.selectedColorIds.includes(color.id) : true)
      );
    });
    const alphabetizedGeozones = orderBy(filteredGeozones, ({ name }: Geozone) => name.toLowerCase(), ['asc']);

    return sortBy(alphabetizedGeozones, [
      ({ id }: Geozone) => {
        const index = this.pinnedGeozonesIdsList.indexOf(id.toString());
        return index !== -1 ? index : Infinity;
      },
    ]);
  }

  @computed get uniqGeozoneColors(): Geozone.IGeozoneColor[] {
    const uniqColors = uniqBy(this.geozonesList, 'color.id').map((geozone) => geozone.color);

    return uniqColors.sort((a, b) => colorFilterOrder.indexOf(a.id) - colorFilterOrder.indexOf(b.id));
  }

  @action setSelectedGeozoneId = (geozoneId: string) => (this.selectedGeozoneId = geozoneId);

  @action setSelectedGeozone = async (geozoneId) => {
    const geozone = await this.repositoryGeozone.do(geozoneId, {}, { requestType: RequestType.GET });

    if (geozone?.isActive) {
      this.selectedGeozone = new Geozone(geozone);
    }
  };

  @action getGeozonesList = async () => {
    const geozonesList = await this.repositoryGeozone.get({ mappable: true, isActive: true });

    this.geozonesList = sortBy(geozonesList.items, ['name'])
      .map(
        (geozone: Geozone.IServerGeozone): Geozone =>
          ['polygon', 'circle'].includes(geozone.type.toLowerCase()) && new Geozone(geozone)
      )
      .filter(Boolean)
      .filter(({ formatterCoordinates }) => formatterCoordinates.length);
  };

  @action reset = (saveList?: boolean, savePinned?: boolean) => {
    this.repositoryGeozone.getState.reset();
    this.repositoryGeozone.doState.reset();

    this.filters.reset();
    if (!saveList) {
      this.geozonesList = [];
    }
    if (!savePinned) {
      this.resetPinnedGeozonesIds();
    }
    this.selectedGeozoneId = '';
    this.selectedGeozone = null;

    return this;
  };

  @computed get geoZonesListCount(): number {
    return this.geozonesList.length;
  }
}

export default new GeozonesStore();
