import React, { FC, useState } from 'react';
import TimePicker, { TimePickerProps, TimePickerValue } from 'react-time-picker';
import classNames from 'classnames';

import './styles.scss';

interface IProps extends TimePickerProps {
  amPmStatic?: boolean;
  className?: string;
}

const timeToNumber = (time: string) =>
  time
    .split(':')
    .reverse()
    .map((val, index) => Math.pow(60, index) * Number(val))
    .reduce((acc, val) => acc + val, 0);

const timeStringToDate = (time) =>
  new Date(
    // @ts-ignore // ts can't parse this however slice will create an array of maximum 4 elements + 3 in reduce = which is equal to max of 7
    ...time
      .split(':')
      .slice(0, 4)
      .reduce((acc, val) => [...acc, Number(val)], [1970, 0, 1])
  );
const getRandomInt = (max) => Math.floor(Math.random() * max);

const TimePickerInput: FC<IProps> = ({
  amPmStatic = true,
  className,
  clearIcon = null,
  format = 'hh:mm:ss a',
  maxDetail = 'second',
  onChange = () => void 0,
  value,
  minTime,
  maxTime,
  ...props
}) => {
  const cn = classNames('TimePickerInput', className, {
    'TimePickerInput--amPmStatic': amPmStatic,
  });
  const [ms, setMs] = useState(0);

  const handleChange = (time: TimePickerValue) => {
    setMs(getRandomInt(999));

    if (typeof time === 'string') {
      const timeInNumber = timeToNumber(time);

      if (typeof minTime === 'string') {
        const minTimeNumber = timeToNumber(minTime);
        if (timeInNumber < minTimeNumber) {
          return onChange(value);
        }
      }

      if (typeof maxTime === 'string') {
        const maxTimeNumber = timeToNumber(maxTime);
        if (timeInNumber > maxTimeNumber) {
          return onChange(value);
        }
      }
      return onChange(time);
    }
  };

  return (
    <div className={cn}>
      <TimePicker
        clearIcon={clearIcon}
        disableClock
        format={format}
        maxDetail={maxDetail}
        value={value && timeStringToDate(`${value}:${ms}`)}
        {...props}
        onChange={handleChange}
      />
    </div>
  );
};

export default TimePickerInput;
