import Asset from 'models/Assets/Asset';
import { isArray } from 'lodash';
import { ListField, SimpleField, ToggleField } from 'models/Fields';

class InspectionApplyTo {
  allAssets: ToggleField;
  items: ListField<Asset>;

  constructor({ allAssets, items }) {
    this.allAssets = new ToggleField(allAssets);
    this.items = new ListField(items.map((item) => new Asset({ id: item.id.toString(), name: item.name })));
  }

  findById = (id: string): SimpleField<Asset> => {
    return this.items.value.find((item) => item.value.id.value === id);
  };

  select = (option: Select.ISelectOption | Select.ISelectOption[], isAllSelected: boolean) => {
    if (isArray(option)) {
      const multiplyOptions = option as Select.ISelectOption[];

      this.multiSelect(multiplyOptions);
    } else {
      const singleOption = option as Select.ISelectOption;

      this.singleSelect(singleOption);
    }

    this.allAssets.toggle(isAllSelected);
  };

  get isValid() {
    return this.allAssets.value || Boolean(this.items.value.length);
  }

  get isUpdated() {
    return false;
  }

  private multiSelect = (options: Select.ISelectOption[]) => {
    if (Boolean(options.length)) {
      const items = options.map((option) => new Asset({ id: option.value, name: option.label }));

      this.items.replace(items);
    } else {
      this.items.replace([]);
    }
  };

  private singleSelect = (option: Select.ISelectOption) => {
    const item = this.findById(option.value);

    if (Boolean(item)) {
      this.items.remove(item);
    } else {
      this.items.add(new Asset({ id: option.value, name: option.label }));
    }
  };
}

export default InspectionApplyTo;
