import React from 'react';
import { inject } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router-dom';

import type { UserStore } from 'stores';
import { DASHCAM_REQUEST_MEDIA, DASHCAM_VIEW_MEDIA, PATHS, USER_STORE } from 'config';
import DashCamsAdPage from 'components/DashCams/AdPage';
import DashCamsRootPage from './index';
import validateAccessLevel from '../../stores/acl/validator';
import ControlledRoute from '../../components/ControlledRoute';

interface IProps {
  userStore?: UserStore;
}

const ProtectedRoute: React.FC<IProps> = () => {
  return (
    <Switch>
      {/* {!hasRoles && (
        <Route path={PATHS.DASHCAMS.INDEX} exact>
          <Redirect from={PATHS.DASHCAMS.INDEX} to={PATHS.DASHCAMS.AD} />
        </Route>
      )}*/}
      <Route path={PATHS.DASHCAMS.AD} component={DashCamsAdPage} />
      <ControlledRoute
        path={PATHS.DASHCAMS.INDEX}
        rule={validateAccessLevel([DASHCAM_VIEW_MEDIA, DASHCAM_REQUEST_MEDIA])}
        component={DashCamsRootPage}
      />
      <Redirect from="*" to={PATHS.NOT_FOUND} />
    </Switch>
  );
};

export default inject(USER_STORE)(ProtectedRoute);
