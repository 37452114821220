import React from 'react';
import { observer } from 'mobx-react';

import { CloseIcon } from 'assets';

import { RECIPIENT_TYPE } from 'config';
import { validateEmail, validatePhoneNumber, getValidPhoneNumber, formatPhoneNumber } from 'utils';
import type { SimpleField } from 'models/Fields';

import RecipientInput from 'components/Recipients/RecipientInput';
import Button from 'components/Button';

import './styles.scss';

interface IPropr {
  type: RECIPIENT_TYPE;
  item: SimpleField<string>;
  onRemove: (item: SimpleField<string>) => void;
  isEnabledCloseButton: boolean;
  hitNext: boolean;
  cancelHitNextNotify: () => void;
  hitNextErrorEmpty: string;
  isEmailFocus?: boolean;
  isAllItemsEmpty?: boolean;
  characterLimitLeft?: number;
}

const RecipientItem: React.FC<IPropr> = observer(
  ({
    type,
    item,
    onRemove,
    isEnabledCloseButton,
    hitNext,
    cancelHitNextNotify,
    hitNextErrorEmpty,
    isEmailFocus = true,
    isAllItemsEmpty = false,
    characterLimitLeft = Infinity,
  }) => {
    const handleChange = (value: string) => {
      item.set(value);
    };

    const handleError = (value: boolean) => {
      item.setIsValid(!value);
    };

    const handleButtonClick = () => {
      isEnabledCloseButton ? onRemove(item) : item.set('');
    };

    const renderInput = (type) => {
      switch (type) {
        case RECIPIENT_TYPE.EMAIL:
          return (
            <RecipientInput
              value={item.value}
              onChange={handleChange}
              onError={handleError}
              isFocus={isEmailFocus}
              error="Invalid email address"
              placeholder="Email address"
              validate={validateEmail}
              hitNext={hitNext}
              isEmpty={item.isEmpty}
              isValid={item.isValid}
              cancelHitNextNotify={cancelHitNextNotify}
              hitNextErrorEmpty={hitNextErrorEmpty}
              isAllItemsEmpty={isAllItemsEmpty}
              characterLimitLeft={characterLimitLeft}
            />
          );
        case RECIPIENT_TYPE.TEXT:
          return (
            <RecipientInput
              value={item.value}
              onChange={handleChange}
              onError={handleError}
              isFocus={isEnabledCloseButton}
              error="Invalid phone number"
              placeholder="(   )   -    "
              validate={validatePhoneNumber}
              getValidValue={getValidPhoneNumber}
              formatValue={formatPhoneNumber}
              hitNext={hitNext}
              isEmpty={item.isEmpty}
              isValid={item.isValid}
              cancelHitNextNotify={cancelHitNextNotify}
              hitNextErrorEmpty={hitNextErrorEmpty}
              isAllItemsEmpty={isAllItemsEmpty}
              characterLimitLeft={characterLimitLeft}
            />
          );
      }
    };

    return (
      <li className="RecipientItem">
        {renderInput(type)}
        {(isEnabledCloseButton || item.value) && (
          <Button
            title=""
            className="RecipientItem-remove Button--link"
            IconComponent={<CloseIcon />}
            onClick={handleButtonClick}
          />
        )}
      </li>
    );
  }
);

export default RecipientItem;
