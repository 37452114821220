import { observable } from 'mobx';
import { repositoryService } from 'services';
import { CAMERA_JOURNAL_STATE, CAMERA_TYPE } from 'config';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import CameraDates from './CamerasJournal/CameraDates';
import JournalItem from './CamerasJournal/JournalItem';
import DashcamStatusCameraDetails from '../DashcamStatusCameraDetails';
import type MediaRequestCheckout from 'models/Dashcam/MediaRequestCheckout';
import CameraCustomInterval from './CamerasJournal/CameraCustomInterval';

class CamerasJournal {
  @observable dates: CameraDates;
  customInterval: CameraCustomInterval;
  initialList: JournalItem[] = [];
  cameraDetails: DashcamStatusCameraDetails;
  repository: IEntityRepository;
  context: MediaRequestCheckout;
  private timestampShift: number;

  constructor(context) {
    this.timestampShift = 1000;
    this.repository = repositoryService.get('media', 'v2.0').entity('cameras');
    this.dates = new CameraDates();
    this.customInterval = new CameraCustomInterval(this);
    this.cameraDetails = new DashcamStatusCameraDetails();
    this.context = context;
  }

  fetch = async () => {
    const id = this.context.vehicle.details.camera.id;

    if (!id) {
      return;
    }

    const repositoryJournal = this.repository.entity(id).entity('journal');
    const response = await repositoryJournal.get(this.params, { ignoreError: true });

    if (repositoryJournal.getState.success) {
      this.initialList = response.items.map((item) => new JournalItem(item));
      this.cameraDetails.isBusy.toggle(response.cameraDetails.isBusy);
      this.dates.setModel(this.initialList, [CAMERA_JOURNAL_STATE.IN_OUT, CAMERA_JOURNAL_STATE.OUT]);
      this.dates.select(this.dates.list[this.dates.list.length - 1]);
    } else {
      this.initialList = [];
      this.dates.reset();
    }
  };

  selectByInterval = (startTimestamp: number, endTimestamp: number) => {
    this.dates.list.forEach((key) => {
      const selectedInterval = this.dates.model[key].intervals.find(
        (interval) => interval.startTimestamp === startTimestamp && interval.endTimestamp === endTimestamp
      );

      if (selectedInterval) {
        this.dates.model[key].isSelected.toggle(true);
        selectedInterval.isSelected.toggle(true);
      }
    });
  };

  isIntervalSelected = () => {
    return this.dates.list.find((key) => this.dates.model[key].intervals.find((interval) => interval.isSelected.value));
  };

  selectByTimestamp = (timestamp: number) => {
    return this.dates.list.find((key) => {
      const selectedInterval = this.dates.model[key].intervals.find(
        (interval) =>
          interval.startTimestamp <= timestamp + this.timestampShift &&
          interval.endTimestamp >= timestamp + this.timestampShift
      );

      if (selectedInterval) {
        this.dates.select(key);
        selectedInterval.isSelected.toggle(true);
        return true;
      }

      return false;
    });
  };

  getSelectedInterval = () => {
    return this.dates.active
      ? this.dates.model[this.dates.active].intervals.find((interval) => interval.isSelected.value)
      : null;
  };

  private get params() {
    const provider = this.context.vehicle.details.camera.provider.value;
    return provider === CAMERA_TYPE.SMARTWITNESS ? { useTrackerData: true } : null;
  }
}

export default CamerasJournal;
