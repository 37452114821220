import get from 'lodash/get';
import debounce from 'lodash/debounce';

import { removeCookie } from './storage';
import { TOKEN_COOKIE_NAME } from 'config/cookie';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

const triggerSessionExpired = debounce(() => EventsBus.get().trigger(APP_EVENTS.SESSION_EXPIRED));

export const handleErrors = (err) => {
  const error = get(err, 'response.data.error', '');
  const errorStatus = get(err, 'response.data.status', '').toString().toLowerCase();
  const isUnauthorized = errorStatus === 'unauthorized';
  const is401 = get(err, 'response.status', '') === 401;
  const is423 = get(err, 'response.status', '') === 423;
  const isPasswordRecovering = get(err, 'request.responseURL', '').includes('password-recovery');
  const isPasswordChange = get(err, 'request.responseURL', '').includes('/users/password');

  if (!isPasswordRecovering && !isPasswordChange) {
    if ((error && error.indexOf('JWT expired') !== -1) || isUnauthorized || is401 || is423) {
      removeCookie(TOKEN_COOKIE_NAME);

      triggerSessionExpired();
    }
  }

  throw new Error(error);
};
