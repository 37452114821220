import React from 'react';

import { WEB_MAP_TEXT_LOCATION_CREATE, MAP_CREATE_GEOZONE_CREATE } from 'config';
import { UserAccessLevelComponent } from 'stores';
import PanelAction from 'components/SubNav/PanelAction';

import { AddGeozoneIcon, DirectionEventIcon, GeozoneCancelIcon, PhoneIcon } from 'assets';
import './styles.scss';

interface IProps {
  createGeozone?: boolean;
  onDirectionsClick?(): void;
  onTextLocationClick?(): void;
  onCreateGeozoneClick?(): void;
}

const LocationPanelActions: React.FC<IProps> = ({
  createGeozone,
  onDirectionsClick = () => void 0,
  onTextLocationClick = () => void 0,
  onCreateGeozoneClick = () => void 0,
}) => {
  return (
    <div className="LocationPanelActions">
      <div className="LocationPanelActions-action">
        <PanelAction icon={<DirectionEventIcon />} onClick={onDirectionsClick}>
          Directions
        </PanelAction>
      </div>
      <UserAccessLevelComponent requiredAccessLevel={[WEB_MAP_TEXT_LOCATION_CREATE]}>
        <div className="LocationPanelActions-action">
          <PanelAction icon={<PhoneIcon />} onClick={onTextLocationClick}>
            <span>
              Text
              <br />
              Location
            </span>
          </PanelAction>
        </div>
      </UserAccessLevelComponent>
      <UserAccessLevelComponent requiredAccessLevel={[MAP_CREATE_GEOZONE_CREATE]}>
        <div className="LocationPanelActions-action">
          <PanelAction
            icon={createGeozone ? <GeozoneCancelIcon /> : <AddGeozoneIcon />}
            onClick={onCreateGeozoneClick}
            selected={createGeozone}
          >
            {createGeozone ? 'Cancel' : 'Create Geozone'}
          </PanelAction>
        </div>
      </UserAccessLevelComponent>
    </div>
  );
};

export default LocationPanelActions;
