import type { History } from 'history';

import {
  ADMIN_STORE,
  CLEARSHARE_STORE,
  DASH_CAMS_STORE,
  DEVICES_MAP_STORE,
  DISPATCH_STORE,
  GEOZONES_STORE,
  ROUTER_STORE,
  SEARCH_STORE,
  UI_STATE_STORE,
  USER_STORE,
  REPORTS_STORE,
  PERSISTENCE_STORE,
  TIME_STORE,
  EVENTS_REGISTRY_STORE,
  ALERTS_TABLE_STORE,
  SCHEDULED_REPORTS_STORE,
  INTEGRATIONS_STORE,
  CUSTOMERS_STORE,
  WEX_CARDS_STORE,
  MEDIA_MANAGER_STORE,
  MAINTENANCES_STORE,
  INSPECTIONS_STORE,
} from 'config';

import adminStore from './Admin';
import clearShareStore from './Clearshare';
import dashCamsStore from './DashCams';
import devicesMapStore from './Map';
import dispatchStore from './Dispatch';
import geoZones from './Geozones';
import RouterStore from './RouterStore';
import searchStore from './Search';
import uiStore from './UIStore';
import userStore from './UserStore';
import reportsStore from './Reports';
import persistenceStore from './Persistence';
import timeStore from './TimeStore';
import eventsRegistryStore from './EventsRegistryStore';
import alertsTableStore from './Alerts/Table';
import scheduledReportsStore from './ScheduledReports';
import wexCardAssociationsTableStore from './Integrations/WEXCardAssociations/Table';
import mediaManagerStore from './MediaManager';
import maintenancesStore from './Maintenances';
import inspectionsStore from './Inspections';

import integrationsStore from './Integrations';
import customersStore from './Customers/CustomersStore';

export function createStores(history: History) {
  const routerStore = new RouterStore(history);

  return {
    [ROUTER_STORE]: routerStore,
    [USER_STORE]: userStore,
    [TIME_STORE]: timeStore,
    [DEVICES_MAP_STORE]: devicesMapStore,
    [UI_STATE_STORE]: uiStore,
    [SEARCH_STORE]: searchStore,
    [DASH_CAMS_STORE]: dashCamsStore,
    [GEOZONES_STORE]: geoZones,
    [CLEARSHARE_STORE]: clearShareStore,
    [DISPATCH_STORE]: dispatchStore,
    [ADMIN_STORE]: adminStore,
    [REPORTS_STORE]: reportsStore,
    [PERSISTENCE_STORE]: persistenceStore,
    [EVENTS_REGISTRY_STORE]: eventsRegistryStore,
    [ALERTS_TABLE_STORE]: alertsTableStore,
    [SCHEDULED_REPORTS_STORE]: scheduledReportsStore,
    [INTEGRATIONS_STORE]: integrationsStore,
    [CUSTOMERS_STORE]: customersStore,
    [WEX_CARDS_STORE]: wexCardAssociationsTableStore,
    [MEDIA_MANAGER_STORE]: mediaManagerStore,
    [MAINTENANCES_STORE]: maintenancesStore,
    [INSPECTIONS_STORE]: inspectionsStore,
  };
}
