import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import moment from 'moment';

import { getFormattedTime } from 'utils';
import { ACL, DATE_TIME_FORMATS, INTERVAL_UNIT, MAINTENANCE_INTERVAL_OPTIONS } from 'config';
import timeStore from 'stores/TimeStore';
import { validateAccessLevel } from 'stores';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

import type MaintenanceConfig from 'models/Maintenances/Models/MaintenanceConfig';
import type MaintenanceModalDueEveryUI from 'models/Maintenances/UI/MaintenanceModalUI/MaintenanceModalDueEveryUI';

import Checkbox from 'components/Checkbox';
import InputNumber from 'components/InputNumber';
import InfoTooltip from 'components/InfoTooltip';
import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';

import './styles.scss';

const DEFAULT_VALUES = {
  DATE: '6',
  INTERVAL_UNIT: INTERVAL_UNIT.MONTHS,
};

type MomentDuration = 'month' | 'week' | 'day' | 'year';

interface IProps {
  serviceTime: MaintenanceConfig;
  ui: MaintenanceModalDueEveryUI;
  showNext?: boolean;
}

const MaintenancePreferenceDueEveryDate: React.FC<IProps> = ({ serviceTime, ui, showNext = true }) => {
  let dateValue = DEFAULT_VALUES.DATE;
  let intervalUnitValue = DEFAULT_VALUES.INTERVAL_UNIT;
  const edit = validateAccessLevel([ACL.MAINTENANCE.BASIC.UPDATE]);

  useEffect(() => {
    dateValue = serviceTime.interval.value ? serviceTime.interval.value : DEFAULT_VALUES.DATE;
    intervalUnitValue = serviceTime.intervalUnit.value ? serviceTime.intervalUnit.value : DEFAULT_VALUES.INTERVAL_UNIT;

    if (!ui.dateCheckbox.value) {
      resetTime();
    } else {
      const nextValue = getNextTime(serviceTime.last.value, serviceTime.interval.value, serviceTime.intervalUnit.value);
      serviceTime.next.reInitialize(nextValue);
    }

    serviceTime.intervalUnit.reInitialize(intervalUnitValue);
    EventsBus.get().on(APP_EVENTS.MAINTENANCE.TASK.ASSET.CHANGED, resetTime);

    return () => {
      resetTime();
      EventsBus.get().off(APP_EVENTS.MAINTENANCE.TASK.ASSET.CHANGED, resetTime);
    };
  }, []);

  const resetTime = () => {
    serviceTime.last.reInitialize('');
    serviceTime.interval.reInitialize('');
    serviceTime.next.reInitialize('');
  };

  const toggleDate = () => {
    if (ui.dateCheckbox.value) {
      serviceTime.intervalUnit.set(INTERVAL_UNIT.MONTHS);
      serviceTime.remove();
    } else {
      serviceTime.interval.set(dateValue);
      serviceTime.last.set(Date.now().toString());
      serviceTime.next.set(Date.now().toString());

      const nextValue = getNextTime(serviceTime.last.value, dateValue, serviceTime.intervalUnit.value);

      serviceTime.next.set(nextValue);
    }

    ui.isError.toggle(false);
    ui.dateCheckbox.toggle();
  };

  const changeInterval = (value) => {
    const interval = value ? value.toString().replace(/\,|\./gi, '') : value;
    const nextValue = getNextTime(serviceTime.last.value, interval, serviceTime.intervalUnit.value);

    serviceTime.interval.set(interval);
    serviceTime.next.set(nextValue);
  };

  const changeIntervalUnit = ({ value }) => {
    const nextValue = getNextTime(serviceTime.last.value, serviceTime.interval.value, value);

    serviceTime.intervalUnit.set(value as INTERVAL_UNIT);
    serviceTime.next.set(nextValue);
  };

  const getNextTime = (last, interval, unit) => {
    const timestamp = Number(last);

    return moment(timestamp)
      .add(Number(interval), unit.toLowerCase() as MomentDuration)
      .valueOf()
      .toString();
  };

  return (
    <Observer
      render={() => (
        <div className="MaintenancePreferenceDueEveryDate">
          <div className="MaintenancePreferenceDueEveryDate-edit">
            <Checkbox
              checked={ui.dateCheckbox.value}
              onChange={toggleDate}
              className={`${ui.isError.value ? 'MaintenancePreferenceDueEveryDate-checkbox--error' : ''}`}
              disabled={!edit}
            />
            <InputNumber
              className="MaintenancePreferenceDueEveryDate-editDateInput"
              disabled={!ui.dateCheckbox.value || !edit}
              value={serviceTime.interval.value}
              onChange={changeInterval}
              max="999"
              min="1"
              controls={false}
            />

            <div className="MaintenancePreferenceDueEveryDate-editDateSelect">
              <SearchableStaticSelect
                isSearchable={false}
                disabled={!ui.dateCheckbox.value || !edit}
                value={{
                  label: '',
                  value: serviceTime.intervalUnit.value,
                }}
                values={MAINTENANCE_INTERVAL_OPTIONS}
                handleChange={changeIntervalUnit}
              />
            </div>
            <InfoTooltip className="MaintenancePreferenceDueEveryDate-editDateTooltip">
              This task will trigger as 'due' when either the Date or the Mileage/Hours is met, whichever comes first.
            </InfoTooltip>
          </div>
          {showNext && (
            <div className="MaintenancePreferenceDueEveryDate-label">
              <span className="MaintenancePreferenceDueEveryDate-labelTitle">Next</span>
              {ui.dateCheckbox.value && (
                <span className="MaintenancePreferenceDueEveryDate-labelValue">
                  {getFormattedTime(
                    Number(serviceTime.next.value),
                    DATE_TIME_FORMATS.monthDatYearFull,
                    timeStore.sessionTimezone
                  )}
                </span>
              )}
            </div>
          )}
        </div>
      )}
    />
  );
};

export default MaintenancePreferenceDueEveryDate;
