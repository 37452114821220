import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import { formatDecimalNumber, getNumber } from 'utils';

import MaintenanceModal, {
  MaintenanceModalLabel,
  MaintenanceModalRow,
  MaintenanceModalValue,
  MaintenanceModalInput,
  MaintenanceModalUnits,
} from '../MaintenanceModal';
import InfoTooltip from 'components/InfoTooltip';
import Input from 'components/Input';

interface IProps {
  actualLabel?: string;
  isOpen: boolean;
  lastPerformed?: string;
  lastPerformedLabel?: string;
  onCancel: () => void;
  onSubmit: (value: number) => void;
  title: string;
  units: string;
  infoText?: string;
}

const UpdateLastModal: FC<IProps> = ({
  isOpen = false,
  actualLabel = 'Last service performed',
  lastPerformed,
  lastPerformedLabel = 'reported Last service',
  onCancel,
  onSubmit,
  title,
  units,
  infoText,
}) => {
  const [value, setValue] = useState('');
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const rowCN = classNames({ 'MaintenanceModal-modalRow--single': !lastPerformed });

  const handleChange = (e) => {
    const { value } = e.target;
    if (value === '' || value === '0' || /(\.|\.\d)$/.test(value)) {
      setValue(value);
    } else {
      const valueNumber = getNumber(value);
      if (
        valueNumber &&
        /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(valueNumber.toString()) &&
        valueNumber.toString().length <= 10
      ) {
        setValue(formatDecimalNumber(valueNumber, units === 'hrs' ? 2 : 1));
      }
    }
  };
  const handleSubmit = async () => {
    await onSubmit(getNumber(value));
    setValue('');
  };

  useEffect(() => {
    setSubmitDisabled(!Boolean(value));
  }, [value]);

  return (
    <MaintenanceModal
      isOpen={isOpen}
      isSubmitDisabled={isSubmitDisabled}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      title={title}
    >
      {Boolean(lastPerformed) && (
        <MaintenanceModalRow>
          <MaintenanceModalLabel>{lastPerformedLabel}</MaintenanceModalLabel>
          <MaintenanceModalValue>{lastPerformed}</MaintenanceModalValue>
        </MaintenanceModalRow>
      )}
      <MaintenanceModalRow className={rowCN}>
        <MaintenanceModalLabel>{actualLabel}</MaintenanceModalLabel>
        <MaintenanceModalInput>
          <Input value={value} onChange={handleChange} />
          <MaintenanceModalUnits>{units}</MaintenanceModalUnits>
          {Boolean(infoText) && <InfoTooltip>{infoText}</InfoTooltip>}
        </MaintenanceModalInput>
      </MaintenanceModalRow>
    </MaintenanceModal>
  );
};

export default UpdateLastModal;
