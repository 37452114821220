import React, { FC, useState } from 'react';
import classnames from 'classnames';

import MediaPanelFilterButton from './MediaPanelFilterButton';
import MediaLibraryCheckbox from 'components/DashCams/MediaLibrary/MediaLibraryCheckbox';
import type MediaTypeToggleFilter from 'models/Dashcam/MediaLibrary/MediaType/MediaTypeToggleFilter';
import Popup from 'components/Popup';

import { FilterIcon } from 'assets';
import './styles.scss';

interface IProps {
  filters: MediaTypeToggleFilter[];
}

const MediaPanelFilter: FC<IProps> = ({ filters }) => {
  const [isOpen, setOpen] = useState(false);
  const handleOpen = (isOpen) => setOpen(isOpen);
  const selected = filters.some((filter) => filter.isSelected.value);

  return (
    <Popup
      isOpen={isOpen}
      onVisibleChange={handleOpen}
      triggerElement={<MediaPanelFilterButton open={isOpen} selected={selected} icon={<FilterIcon />} />}
    >
      <div className="MediaPanelFilter">
        {filters.map((filter) => {
          const cn = classnames('MediaPanelFilter-item', {
            [`MediaPanelFilter-item--${filter.value?.toLowerCase()}`]: Boolean(filter.value),
          });
          return (
            <div className={cn} key={filter.value}>
              <MediaLibraryCheckbox option={filter} />
            </div>
          );
        })}
      </div>
    </Popup>
  );
};

export default MediaPanelFilter;
