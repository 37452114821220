import React, { FC } from 'react';
import classNames from 'classnames';

import { CompasIcon } from 'assets';
import { getCompassDirectionTextByHeading } from 'utils';

import './styles.scss';

interface IProps {
  heading: number;
  className?: string;
}

const CompassDirectionLabel: FC<IProps> = ({ heading, className }) => {
  const text = getCompassDirectionTextByHeading(heading);

  const cn = classNames('CompassDirectionLabel', className);

  return (
    <div className={cn}>
      <CompasIcon className="CompassDirectionLabel-icon" />
      <span className="CompassDirectionLabel-text">{text}</span>
    </div>
  );
};

export default CompassDirectionLabel;
