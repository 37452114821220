import React, { useCallback } from 'react';
import { Observer } from 'mobx-react';

import type { Report } from 'models';

import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';
import ScheduledReportPreferences from 'containers/Reports/ScheduledReports/ScheduledReportPreferences';

import './styles.scss';

interface IProps {
  list: Report[];
  selectedReport: Report;
  onSelectReportsGroup(id: string): void;
  onSelectReport(id: string): void;
  selectedReportId: string;
  locked?: boolean;
}

const EXCLUDED_REPORTS = ['upcoming_maintenances'];

const ScheduledReportsOptions: React.FC<IProps> = ({
  locked,
  list,
  selectedReport,
  onSelectReportsGroup,
  selectedReportId,
}) => {
  const items = list
    .map((item) => ({
      label: item.title,
      value: item.id,
    }))
    .filter((item) => !EXCLUDED_REPORTS.includes(item.value));

  const handleChange = useCallback((option) => {
    onSelectReportsGroup(option.value);
  }, []);

  return (
    <Observer
      render={() => {
        return (
          <div className="ScheduledReportOptions">
            <p className="ScheduledReportOptions-title">Report Options</p>
            <div className="ScheduledReportOptions-content">
              <div className="ScheduledReportOptions-dropdown">
                <SearchableStaticSelect
                  value={selectedReport && { value: selectedReport.id, label: selectedReport.title }}
                  values={items}
                  handleChange={handleChange}
                  placeholder="Select Report"
                  emptySearchResults="No Results Found"
                  menuPortalTarget={null}
                  disabled={locked}
                />
              </div>

              {selectedReport && <ScheduledReportPreferences reportId={selectedReportId} />}
            </div>
          </div>
        );
      }}
    />
  );
};

export default ScheduledReportsOptions;
