import React, { Component } from 'react';
import { observer } from 'mobx-react';

import SearchableSelect from 'components/Select/SearchableSelect';

import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';

interface IProps {
  value?: Select.ISelectOption[];
  handleChange?: (option: Select.ISelectOption | Select.ISelectOption[], isAllSelected: boolean) => void;
  placeholder?: string;
  searchPlaceholder?: string;
  emptySearchResults?: string;
  isSearchable?: boolean;
  disabled?: boolean;
  withNone?: boolean;
  alignRight?: boolean;
  selectFirst?: boolean;
  valueSource?: 'id' | 'assetId';
  timeout?: number;
  multiple?: boolean;
  selectedAll?: boolean;
}

@observer
class SearchableVehiclesSelect extends Component<IProps> {
  static defaultProps = {
    placeholder: 'Select Vehicle',
    searchPlaceholder: 'Search Vehicles',
    emptySearchResults: 'No Vehicles Found',
    valueSource: 'id',
  };

  static allOption = { label: 'Select all', value: 'all' };
  static noneOption = { label: 'None', value: 'none' };
  repositoryVehiclesIds: IEntityRepository;

  constructor(props) {
    super(props);

    this.repositoryVehiclesIds = repositoryService.get('vehicles').entity('ids');
  }

  getData = (props: { page: number; pageSize: number; filter: string }) => {
    const data = {
      includeInactive: true,
      mediaEnabled: false,
      page: 0,
      pageSize: 5000,
    };
    const { valueSource } = this.props;

    return this.repositoryVehiclesIds.get(data).then((data) => {
      const totalPages = data.totalPages;
      const options = data.items.map((item) => {
        return {
          value: item[valueSource].toString(),
          label: item.displayName,
          // badge: item.cpNumber,
        };
      });

      if (!props.filter) {
        options.unshift(SearchableVehiclesSelect.allOption);
      }

      if (!props.filter && this.props.withNone && props.page === 0) {
        options.unshift(SearchableVehiclesSelect.noneOption);
      }

      if (this.props.selectFirst && options.length && !this.props.value[0]?.value) {
        this.props.handleChange(options[0], false);
      }

      return {
        totalPages,
        options,
      };
    });
  };

  updateOptions = (options, option) => {
    options.push({ ...option });
  };

  handleMultipleChange = (option: Select.ISelectOption[], isAllSelected) => {
    this.props.handleChange(option, isAllSelected);
  };

  handleChange = (option: Select.ISelectOption, isAllSelected) => {
    this.props.handleChange(option, isAllSelected);
  };

  render() {
    return (
      <SearchableSelect
        {...this.props}
        value={this.props.value}
        getData={this.getData}
        handleMultipleChange={this.handleMultipleChange}
        handleChange={this.handleChange}
        multiple
        alignRight={this.props.alignRight}
        multipleText={{
          singular: 'Vehicle',
          plural: 'Vehicles',
          allSelect: 'All Vehicles',
        }}
      />
    );
  }
}

export default SearchableVehiclesSelect;
