import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { parse } from 'query-string';

import { PATHS } from 'config';

import './styles.scss';

interface IQueryParams {
  accountName?: string;
  accountPlan?: string;
  email?: string;
}

const WelcomeEmailMulti: FC = () => {
  let { accountName, accountPlan, email } = useMemo(
    () => ({ accountName, accountPlan, email } = parse(window.location.search) as IQueryParams),
    []
  );

  return (
    <div className="WelcomeEmailMulti">
      <div className="WelcomeEmailMulti-wrapper">
        <div className="WelcomeEmailMulti-header">
          <div className="WelcomeEmailMulti-headerLogo">
            <img src="https://public-data.clearpathgps.com/logo-dark-bg.svg" alt="Header logo" />
          </div>
          <img
            className="WelcomeEmailMulti-headerImage"
            src="https://public-data.clearpathgps.com/welcome.png"
            alt="Map image"
          />
        </div>
        <div className="WelcomeEmailMulti-content">
          <p className="WelcomeEmailMulti-text WelcomeEmailMulti-text--dark">
            You've been granted access to an additional account in the ClearpathGPS portal.
          </p>
          <p className="WelcomeEmailMulti-text WelcomeEmailMulti-text--accountAccess WelcomeEmailMulti-text--medium WelcomeEmailMulti-text--lightGrey">
            New account access
          </p>
          <div className="WelcomeEmailMulti-account">
            <p className="WelcomeEmailMulti-accountName">{accountName}</p>
            <p className={`WelcomeEmailMulti-accountPlan WelcomeEmailMulti-accountPlan--${accountPlan}`}>
              {accountPlan}
            </p>
          </div>
          <div>
            <Link className="WelcomeEmailMulti-button" to={PATHS.AUTH.LOGIN} style={{ color: '#fff' }}>
              Log In to Access
            </Link>
          </div>
          <p className="WelcomeEmailMulti-text WelcomeEmailMulti-text--medium WelcomeEmailMulti-text--switchToAcc">
            You will be asked to choose which account to load each time you login. To change accounts after logging in,
            access the User Menu and select 'Switch to another Account'
          </p>
          <p className="WelcomeEmailMulti-text WelcomeEmailMulti-text--small WelcomeEmailMulti-text--emailInfo WelcomeEmailMulti-text--padding WelcomeEmailMulti-text--lightGrey">
            This email was sent to {email} from the ClearPathGPS online portal. If you received this message in error,
            please contact <span className="WelcomeEmailMulti-link">support@clearpathgps.com</span> |{' '}
            <span className="WelcomeEmailMulti-link">(805) 979 3442</span>
          </p>
          <div className="WelcomeEmailMulti-footer">
            <img
              src="https://public-data.clearpathgps.com/logo-white-bg.svg"
              alt=""
              className="WelcomeEmailMulti-logo"
            />
            <p className="WelcomeEmailMulti-text WelcomeEmailMulti-text--lightGrey WelcomeEmailMulti-text--xsmall">
              © 2022 ClearPathGPS, Inc. | All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeEmailMulti;
