import React from 'react';
import { Observer } from 'mobx-react';
import classnames from 'classnames';

import type { ToggleField } from 'models/Fields';
import InputNumber from 'components/InputNumber';
import InfoTooltip from 'components/InfoTooltip';

import './styles.scss';

interface IProps {
  title: string;
  value: number;
  isEdit: ToggleField;
  isShowTooltip?: boolean;
  onChange?: (value: number) => void;
  disabled?: boolean;
  unit?: string;
  isError?: boolean;
}

const MaintenanceCompletedTaskPanelSummaryInputNumber: React.FC<IProps> = ({
  title,
  value,
  isEdit,
  isShowTooltip = false,
  onChange = () => true,
  disabled = false,
  unit,
  isError = false,
}) => {
  const handleChange = (value: string) => {
    const currentValue = unit === 'mi' ? Math.round(Number(value)) : Math.round(Number(value) * 10) / 10;

    onChange(currentValue);
  };

  return (
    <Observer
      render={() => (
        <Observer
          render={() => (
            <div className="MaintenanceCompletedTaskPanelContent-SummaryLine">
              <div
                className={classnames('MaintenanceCompletedTaskPanelContent-SummaryLineLabel', {
                  'MaintenanceCompletedTaskPanelContent-CustomLabel': isEdit.value,
                })}
              >
                {title}
              </div>
              <div className="MaintenanceCompletedTaskPanelContent-SummaryLineValue">
                {isEdit.value ? (
                  <>
                    <div className="MaintenanceCompletedTaskPanelContent-InputNumber">
                      <InputNumber
                        value={value?.toLocaleString('en-US', { maximumFractionDigits: unit === 'mi' ? 0 : 1 })}
                        disabled={disabled}
                        onChange={handleChange}
                        min="0"
                        controls={false}
                        error={isError}
                      />
                      {isError && (
                        <p className="MaintenanceCompletedTaskPanelContent-error">Value must be bigger than 0</p>
                      )}
                    </div>
                    {isShowTooltip && (
                      <div
                        className={classnames('MaintenanceCompletedTaskPanelContent-InfoTooltip', {
                          'MaintenanceCompletedTaskPanelContent-CustomTooltip': isEdit.value,
                        })}
                      >
                        <InfoTooltip>
                          This is a repeating Task so updating this field will change when the next Task is due.
                        </InfoTooltip>
                      </div>
                    )}
                  </>
                ) : (
                  <div>{`${value?.toLocaleString('en-US', { maximumFractionDigits: 0 })} ${unit}` || '-'}</div>
                )}
              </div>
            </div>
          )}
        />
      )}
    />
  );
};

export default MaintenanceCompletedTaskPanelSummaryInputNumber;
