import { observable } from 'mobx';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import { repositoryService } from 'services';
import VehicleModel from './VehicleDetails/VehicleModel';
import { ToggleField } from 'models/Fields';

class VehicleDetails {
  @observable details: VehicleModel;
  @observable isExist: ToggleField;
  repository: IRepository;

  constructor() {
    this.repository = repositoryService.get('assets');
    this.details = new VehicleModel();
    this.isExist = new ToggleField(true);
  }

  set model(vehicle) {
    this.details.model = vehicle || {};
  }

  fetch = async (id: string) => {
    const vehicleRepository = this.repository.entity(id);
    const vehicle = await vehicleRepository.get();

    if (vehicleRepository.getState.success) {
      this.isExist.toggle(true);
      this.saveModel(vehicle);
    } else {
      this.isExist.toggle(false);
    }
  };

  saveModel = (vehicle) => {
    const camera = vehicle.cameras[0];

    if (camera) {
      this.model = {
        deviceId: camera.vehicleId,
        displayName: camera.vehicleName,
        assetId: vehicle.id,
        camera,
      };
    }
  };
}

export default VehicleDetails;
