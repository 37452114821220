import React, { useCallback, useEffect, useState } from 'react';
import { inject, Observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import type { EventsRegistryStore } from 'stores';
import ClosestVehiclesStore from 'stores/Map/ClosestVehicles';

import { EVENTS_REGISTRY_STORE, PATHS } from 'config';
import { formatDecimalNumber } from 'utils';

import Button from 'components/Button';

import './styles.scss';

interface IProps {
  eventsRegistryStore?: EventsRegistryStore;
  latitude: string;
  longitude: string;
  timestamp?: number;
  excludedDevices?: string[];
  step?: number;
  interval?: number;
}

const ClosestVehicles: React.FC<IProps> = ({
  latitude,
  longitude,
  timestamp = null,
  eventsRegistryStore,
  excludedDevices = [],
  step = 5,
  interval = 30000,
}) => {
  const [limit, setLimit] = useState(step);
  const closestVehicles = ClosestVehiclesStore.get()
    .setExcludedDeviceIds(excludedDevices)
    .setLocation({ latitude, longitude, timestamp });

  const getColor = (status: number) => eventsRegistryStore.getColorById(status);

  const handleMoreClick = useCallback(() => {
    setLimit(limit + step);
  }, [limit, setLimit]);

  const loadNearestVehicle = useCallback(
    async (indicateLoading = false) => {
      if (closestVehicles.loading && !indicateLoading) {
        return false;
      }
      await closestVehicles.fetch(indicateLoading);
    },
    [latitude, longitude, setLimit]
  );

  useEffect(() => {
    const id = setInterval(loadNearestVehicle, interval);
    return () => {
      clearInterval(id);
    };
  }, [loadNearestVehicle, latitude, longitude, timestamp]);

  useEffect(() => {
    loadNearestVehicle(true)
      .catch(_ => void 0)
  }, [loadNearestVehicle, latitude, longitude, timestamp]);

  useEffect(() => {
    closestVehicles.setLocation({ latitude, longitude, timestamp });
  }, [latitude, longitude, timestamp]);

  return (
    <Observer
      render={() => {
        const hiddenElements = closestVehicles.vehicles.length - limit;

        return (
          <div>
            {!closestVehicles.loading && !closestVehicles.vehicles.length ? '--' : null}
            {closestVehicles.loading && 'Loading...'}
            {!closestVehicles.loading && closestVehicles.vehicles.length
              ? closestVehicles.vehicles.slice(0, limit).map((vehicle) => {
                return (
                  <div className="NearestVehicle-Item" key={vehicle.deviceId}>
                    <div className="NearestVehicle-ItemCode">
                      <span
                        style={{
                          background: getColor(vehicle.statusCode),
                        }}
                        className="NearestVehicle-ItemCode--icon"
                      />
                    </div>
                    <div className="NearestVehicle-ItemDescription">
                      <Link
                        className="NearestVehicle-ItemName"
                        to={PATHS.MAP.VEHICLE.replace(':vehicleId', vehicle.deviceId)}
                      >
                        {vehicle.deviceDisplayName}
                      </Link>
                      <span className="NearestVehicle-ItemAddress">{vehicle.driverName}</span>
                    </div>
                    <div className="NearestVehicle-ItemDistance">
                      {formatDecimalNumber(vehicle.distanceInMiles, 1)} mi
                    </div>
                  </div>
                );
              })
              : null}
            {!closestVehicles.loading && closestVehicles.vehicles.length && closestVehicles.vehicles.length > limit ? (
              <Button
                title={`Load ${hiddenElements > step ? step : hiddenElements} More`}
                className="NearestVehicle-Link"
                onClick={handleMoreClick}
              />
            ) : null}
          </div>
        )
      }}
    />
  );
};

export default inject(EVENTS_REGISTRY_STORE)(ClosestVehicles);
