import React, { FC } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import type { TooltipPlacement } from 'antd/lib/tooltip';

import { InfoIcon } from 'assets';

import 'antd/dist/antd.css';
import './styles.scss';

interface IProps {
  className?: string;
  icon?: React.ReactNode;
  overlayClassName?: string;
  placement?: TooltipPlacement;
  width?: number;
  height?: number;
}

const InfoTooltip: FC<IProps> = ({
  className,
  children,
  placement = 'bottomLeft',
  icon = null,
  overlayClassName,
  width = 16,
  height = 16,
}) => {
  const cn = classNames('InfoTooltip', className);
  const overlayCn = classNames('InfoTooltip-overlay', overlayClassName);

  return (
    <div className={cn}>
      <Tooltip
        overlayClassName={overlayCn}
        arrowPointAtCenter
        title={children}
        placement={placement}
        color="white"
        overlayInnerStyle={{ color: 'black' }}
      >
        {icon ? icon : <InfoIcon className="InfoTooltip-tooltipIcon" width={width} height={height} />}
      </Tooltip>
    </div>
  );
};

export default InfoTooltip;
