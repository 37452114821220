import { computed, observable, action } from 'mobx';

import TableBase from '../TableBase';
import type { IEditColumns } from 'models/Tables/IEditColumns';
import type { ISortColumn } from 'models/Tables/ISortColumn';
import type { IAlert } from 'models/Alerts/IAlert';
import { IAlertsSearchParams, AlertsSearchMap } from 'models/Alerts/IAlertSearch';
import AlertFactory from 'models/Alerts/AlertFactory';
import { SimpleField, ToggleField } from 'models/Fields';

import { ALERTS_TABLE_COLUMNS, STORAGE_ITEMS } from 'config';
import { repositoryService } from 'services';
import type Repository from 'services/RepositoryService/Repository';

import CronOptionsModel from './CronOptionsModel';

const initialColumns: Array<IEditColumns<ALERTS_TABLE_COLUMNS>> = [
  { value: ALERTS_TABLE_COLUMNS.NAME, isSelected: true },
  { value: ALERTS_TABLE_COLUMNS.TYPE, isSelected: true },
  { value: ALERTS_TABLE_COLUMNS.RECIPIENTS, isSelected: true },
  { value: ALERTS_TABLE_COLUMNS.INTERVAL_RULE, isSelected: false },
  { value: ALERTS_TABLE_COLUMNS.RULE_ID, isSelected: false },
  { value: ALERTS_TABLE_COLUMNS.ACTIVE, isSelected: true },
  { value: ALERTS_TABLE_COLUMNS.CREATED_BY, isSelected: false },
  { value: ALERTS_TABLE_COLUMNS.CREATION_TIME, isSelected: false },
  { value: ALERTS_TABLE_COLUMNS.UPDATED_BY, isSelected: false },
  { value: ALERTS_TABLE_COLUMNS.LAST_UPDATE_TIME, isSelected: false },
];

const initialSortedColumn: ISortColumn = {
  field: 'displayName',
  order: 'ascend',
};

const storageNames = {
  columns: STORAGE_ITEMS.alerts.columns,
  sortedColumn: STORAGE_ITEMS.alerts.sortedColumn,
  pagination: STORAGE_ITEMS.alerts.pagination,
  searchInColumn: STORAGE_ITEMS.alerts.searchInColumn,
};

export class AlertsTableStore extends TableBase<IEditColumns<ALERTS_TABLE_COLUMNS>> {
  @observable alertItems: Array<IAlert & { isValidSelector?: ToggleField }> = [];
  @observable alert: IAlert & { isValidSelector?: ToggleField } = null;
  @observable alertItemsTotal: number = 0;
  @observable cronOptions: CronOptionsModel = new CronOptionsModel();
  repository: Repository;
  isSuccessSave: ToggleField;
  isErrorSave: ToggleField;
  isSuccessDelete: ToggleField;
  isErrorDelete: ToggleField;
  saveErrorTitle: SimpleField<string>;
  saveError: SimpleField<string>;
  isModalOpen: ToggleField;
  savedStep: SimpleField<number>;

  constructor() {
    super({ columns: initialColumns, sortedColumn: initialSortedColumn, storageNames });

    this.isSuccessSave = new ToggleField(false);
    this.isErrorSave = new ToggleField(false);
    this.isSuccessDelete = new ToggleField(false);
    this.isErrorDelete = new ToggleField(false);
    this.repository = repositoryService.get('alerts');
    this.saveErrorTitle = new SimpleField('Save Alert Failed');
    this.saveError = new SimpleField('Something went wrong. Try again.');
    this.isModalOpen = new ToggleField(false);
    this.savedStep = new SimpleField<number>(0);
  }

  @computed get alertsTableSource() {
    return this.alertItems.map(
      ({
        displayName,
        type,
        notifyEmails,
        isActive,
        isLegacy,
        id,
        metadata,
        notifyMobileNumbers,
        cronOption,
        update,
        destroy,
        convertToCustom,
        repositoryCustom,
        repositoryAlert,
        isValidSelector,
        createdBy,
        updatedBy,
        creationTime,
        lastUpdateTime,
      }) => ({
        displayName: displayName.value,
        type: type.value,
        notifyEmails: notifyEmails.toArray(),
        isActive: isActive.value,
        isLegacy: isLegacy.value,
        toggleIsActive: isActive.toggle,
        groupId: metadata.assetGroup.id.value,
        deviceId: metadata.asset.id.value,
        notifyMobileNumbers: notifyMobileNumbers.toArray(),
        cronOption: cronOption.id.value,
        isValidSelector: isValidSelector?.value,
        id,
        key: id,
        update,
        destroy,
        convertToCustom,
        repositoryCustom,
        repositoryAlert,
        createdBy: createdBy.value || '-',
        updatedBy: updatedBy.value || '-',
        creationTime: creationTime.value || 0,
        lastUpdateTime: lastUpdateTime.value || 0,
      })
    );
  }

  @computed get sortData() {
    return {
      sortBy: AlertsSearchMap[this.sortedColumn.field],
      order: this.sortedColumn.order?.replace('end', '').toUpperCase(),
    };
  }

  @computed get searchData() {
    return this.searchColumns.reduce(
      (acc, cur) => ({
        ...acc,
        [AlertsSearchMap[cur.column]]: cur.value,
      }),
      {}
    );
  }

  @action getAllAlerts = async ({
    order,
    page,
    search,
    pageSize,
    sortBy,
  }: {
    order?: string;
    page?: number;
    pageSize?: number;
    search?: IAlertsSearchParams;
    sortBy?: string;
  }) => {
    await this.cronOptions.getInitialCronOptions();

    const response = await this.repository.get({ order, page, pageSize, sortBy, ...search });

    this.alertItems = response.items.map((alertItem) => new AlertFactory(alertItem).get(this));
    this.alertItemsTotal = response.total;
  };

  @action createAlert = (type, options = {}) => {
    this.alert = new AlertFactory({ type, ...options }).get(this);
  };

  @action setAlert = (alert: IAlert) => {
    this.alert = alert ? new AlertFactory(alert.model).get(this) : null;
  };

  @action resetAlert = () => {
    this.alert = null;
  };

  @action onDestroyAlert = (isShowSuccessMessage: boolean = true) => {
    if (isShowSuccessMessage) {
      this.isSuccessDelete.toggle(true);
    }
  };

  @action onCreateAlert = () => {
    this.isSuccessSave.toggle(true);
  };

  @action onUpdateAlert = () => {
    this.isSuccessSave.toggle(true);
  };

  onError = (error?: string) => {
    this.isErrorSave.toggle(true);
    let saveErrorTitle = 'Save Alert Failed';
    let errorText = 'Something went wrong. Try again.';

    if (error === 'selector: Invalid selector') {
      errorText = 'Please correct the syntax and try again';
      saveErrorTitle = 'Syntax Error in Rule Selector.';
    }

    this.saveError.set(errorText);
    this.saveErrorTitle.set(saveErrorTitle);
    setTimeout(() => {
      this.isErrorSave.toggle(false);
      this.saveError.reset();
      this.saveErrorTitle.reset();
    }, 5000);
  };

  findById = (id) => {
    return this.alertItems.find((item) => item.id === id);
  };
}

export default new AlertsTableStore();
