import React from 'react';
import classNames from 'classnames';

import InfoTooltip from 'components/InfoTooltip';
import MapVehiclePanelEventBase from '../MapVehiclePanelEventBase';

import './styles.scss';

interface IProps {
  time: string;
  isOldEvent?: boolean;
  showHistory: boolean;
}

const MapVehiclePanelEventTime: React.FC<IProps> = ({ time, isOldEvent = false, showHistory }) => {
  const mapVehicleEventTimeClassName = classNames('MapVehiclePanelEventTime', {
    'MapVehiclePanelEventTime-oldEvent': isOldEvent && !showHistory,
  });

  return (
    <MapVehiclePanelEventBase className={mapVehicleEventTimeClassName}>
      {time && (
        <>
          <div className="MapVehiclePanelEventTime-time">{time}</div>
          {isOldEvent && !showHistory && (
            <InfoTooltip className="MapVehiclePanelEventTime-info" width={12} height={12}>
              The timestamp for this event is greater than 48 hours
            </InfoTooltip>
          )}
        </>
      )}
    </MapVehiclePanelEventBase>
  );
};

export default MapVehiclePanelEventTime;
