import React from 'react';

import type { SimpleField } from 'models/Fields';

import AlertPreferenceFinalizeSimpleLine from '../AlertPreferenceFinalizeSimpleLine';

interface IProps {
  label: string;
  type: SimpleField<Alerts.Modal.Maintenance.Type>;
  dueValue: SimpleField<number>;
}

const AlertPreferenceFinalizeMaintenanceDueLine: React.FC<IProps> = ({ label, type, dueValue }) => {
  const getValue = () => {
    switch (type.value) {
      case 'MILEAGE_1':
        return `Mileage: ${
          dueValue.value ? `Maintenance 1 is ${dueValue.value} miles before due` : 'Maintenance 1 is due'
        }`;
      case 'MILEAGE_2':
        return `Mileage: ${
          dueValue.value ? `Maintenance 2 is ${dueValue.value} miles before due` : 'Maintenance 2 is due'
        }`;
      case 'DATE':
        return `Date: ${dueValue.value ? `is ${dueValue.value} days before due` : 'is due'}`;
      case 'ENGINE_HOURS':
        return `Engine Hours: ${dueValue.value ? `is ${dueValue.value} hours before due` : 'is due'}`;
    }
  };

  return <AlertPreferenceFinalizeSimpleLine label={label} value={getValue()} />;
};

export default AlertPreferenceFinalizeMaintenanceDueLine;
