import React, { useRef, useEffect, useCallback } from 'react';

import type { Device } from 'models';
import { scrollToSelectedItem } from 'utils';
import type { timezoneNames, mapLabelSizeType } from 'stores';

import DraggableCard from 'components/DragableCard';
import VehicleItem from 'components/SubNav/VehicleItem';

import './styles.scss';

interface IProps {
  devices: Device[];
  dragPinnedDevices?: (dragIndex: number, hoverIndex: number) => void;
  groupId?: string;
  itemMode?: UI.panelMode;
  itemSize?: mapLabelSizeType;
  mapHeading?: number;
  onItemPin?: (arg: string) => void;
  onSelectedClick?: () => void;
  pinnedDevicesIdsList?: string[];
  selectedDeviceId?: string;
  setHistoryMode?: (id: string) => void;
  showDirectionalArrows?: boolean;
  showHistory?: boolean;
  timezone: timezoneNames | string;
  toDate?: number;
  vehicleItemGeozoneClick?: (geozoneId: string) => any;
}

const VehicleItemsList: React.FC<IProps> = ({
  devices,
  dragPinnedDevices,
  groupId,
  itemMode = 'full',
  itemSize,
  mapHeading,
  onItemPin,
  onSelectedClick,
  pinnedDevicesIdsList,
  selectedDeviceId,
  showDirectionalArrows,
  setHistoryMode,
  showHistory,
  timezone,
  toDate,
  vehicleItemGeozoneClick,
}) => {
  const vehiclesListRef: React.RefObject<HTMLDivElement> = useRef(null);
  const selectedVehicleItemRef: React.RefObject<HTMLLIElement> = useRef(null);
  const handleScrollToSelectedVehicle = useCallback(() => {
    scrollToSelectedItem(vehiclesListRef, selectedVehicleItemRef);
  }, [vehiclesListRef, selectedVehicleItemRef]);

  useEffect(handleScrollToSelectedVehicle, [vehiclesListRef, selectedVehicleItemRef, selectedDeviceId]);

  return (
    <div className="VehicleItemsList" ref={vehiclesListRef}>
      {devices.length ? (
        <ul className="VehicleItemsList-list">
          {devices.map(({ data, latestEvent }) => {
            if (!latestEvent || !latestEvent.metadata) {
              return null;
            }

            const {
              deviceId,
              driverDescription,
              hasActiveClearShareLink,
              mediaEnabled,
              shortName,
              isPowerDisconnected,
              isCameraPowerDisconnected,
            } = data;
            const {
              address,
              speedMph,
              timestamp,
              geozone,
              getTimeDiff,
              isUpdateTime,
              metadata: {
                attributes: {
                  arrowColor,
                  calculateTotalIdle,
                  calculateTotalIgnitionOff,
                  eventStatusType,
                  heading,
                  iconColor,
                  label,
                  showDriver,
                },
              },
            } = latestEvent;
            const pinnedDeviceIndex = pinnedDevicesIdsList.indexOf(deviceId);
            const Vehicle = (
              <VehicleItem
                address={address}
                calculateTotalIdle={calculateTotalIdle}
                calculateTotalIgnitionOff={calculateTotalIgnitionOff}
                currentSpeed={speedMph}
                deviceId={deviceId}
                driverName={driverDescription}
                eventStatusType={eventStatusType}
                eventType={label}
                geozone={geozone}
                getTimeDiff={getTimeDiff}
                groupId={groupId}
                hasActiveClearShareLink={hasActiveClearShareLink}
                heading={showDirectionalArrows && heading && latestEvent.heading - mapHeading}
                headingColor={arrowColor}
                isUpdateTime={isUpdateTime}
                label={label}
                mediaEnabled={mediaEnabled}
                mode={itemMode}
                name={shortName}
                onGeozoneClick={vehicleItemGeozoneClick}
                onItemPin={onItemPin}
                onSelectedClick={onSelectedClick}
                pinnedDevicesIdsList={pinnedDevicesIdsList}
                setHistoryMode={setHistoryMode}
                showDriver={showDriver}
                showHistory={showHistory}
                size={itemSize}
                statusColor={iconColor}
                timestamp={timestamp}
                timezone={timezone}
                toDate={toDate}
                isPowerDisconnected={isPowerDisconnected}
                isCameraPowerDisconnected={isCameraPowerDisconnected}
              />
            );

            return (
              <li
                className={`VehicleItemsList-item VehicleItemsList-item--${itemMode} VehicleItemsList-item--${itemSize}`}
                key={deviceId}
                ref={selectedDeviceId === deviceId ? selectedVehicleItemRef : null}
              >
                {pinnedDeviceIndex !== -1 ? (
                  <DraggableCard id={deviceId} index={pinnedDeviceIndex} moveCard={dragPinnedDevices} key={deviceId}>
                    {Vehicle}
                  </DraggableCard>
                ) : (
                  Vehicle
                )}
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="VehicleItemsList-noResults">No results found</div>
      )}
    </div>
  );
};

export default React.memo(VehicleItemsList);
