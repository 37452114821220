import { observable } from 'mobx';

import { ToggleField } from 'models/Fields';
import { TIME24 } from 'config';

import ScheduleWeekdaysModel from './ScheduleWeekdays';
import ScheduleTime from './ScheduleTime';

class ScheduleModel {
  @observable allDay: ToggleField;
  @observable weekdays: ScheduleWeekdaysModel;
  @observable from: ScheduleTime;
  @observable to: ScheduleTime;

  constructor({ weekdays = [], from = TIME24.beggingOfTheDay, to = TIME24.endOfTheDay, allDay = false }) {
    this.allDay = new ToggleField(allDay);
    this.weekdays = new ScheduleWeekdaysModel(weekdays);
    this.from = new ScheduleTime(from);
    this.to = new ScheduleTime(to);
  }
}

export default ScheduleModel;
