class InspectionService {
  odometer: number;
  engineHours: number;
  requireUpdatingOdometer: boolean;
  requireUpdatingEngHours: boolean;

  constructor({ odometer, engineHours, requireUpdatingOdometer, requireUpdatingEngHours }, rate = 1) {
    this.odometer = odometer * rate;
    this.engineHours = engineHours;
    this.requireUpdatingOdometer = requireUpdatingOdometer;
    this.requireUpdatingEngHours = requireUpdatingEngHours;
  }
}

export default InspectionService;
