import React from 'react';
import { NavLink } from 'react-router-dom';

import './styles.scss';

interface IProps {
  item: {
    renderIcon: (className?: string, isActive?: boolean) => JSX.Element;
    notificationIcon?: (pathname: string) => JSX.Element;
    title: string;
    to: string;
  };
  onClick?: () => void;
  pathname?: string;
}

const NavigationItem = ({ item: { renderIcon, notificationIcon, to, title }, onClick, pathname }: IProps) => {
  const isActiveMap = pathname.includes('/map') ? true : undefined;

  return (
    <div className="NavigationItem">
      <NavLink to={to} className="NavigationItem-link" activeClassName="isActive" onClick={onClick}>
        <div className="NavigationItem-content">
          {renderIcon('NavigationItem-icon', isActiveMap)}
          <p className="NavigationItem-title">{title}</p>
        </div>
      </NavLink>
      {notificationIcon?.(pathname)}
    </div>
  );
};

export default NavigationItem;
