const darkMarker = ({ color }) => {
  return `
<svg width="20" height="21" viewBox="0 0 20 21" fill="${color}" xmlns="http://www.w3.org/2000/svg">
  <circle cx="10" cy="10" r="10" />
  <circle cx="10" cy="10" r="9" stroke-opacity="0.3" stroke="black" stroke-width="2" />
  <circle cx="10" cy="10" r="5"  stroke="white" stroke-width="2" />
</svg>
`;
};

const lightMarker = ({ color }) => {
  return `
<svg width="20" height="21" viewBox="0 0 20 21" fill="${color}" xmlns="http://www.w3.org/2000/svg">
  <circle cx="10" cy="10" r="10" />
  <circle cx="10" cy="10" r="9" stroke-opacity="0.3" stroke="black" stroke-width="2" />
  <circle cx="10" cy="10" r="5"  stroke="#454E63" stroke-width="2" />
</svg>
`;
};

export default ({ color, colorName }) =>
  colorName.toLowerCase() === 'yellow' || colorName.toLowerCase() === 'white'
    ? lightMarker({ color })
    : darkMarker({ color });
