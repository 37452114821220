import TableDownload from 'models/Tables/TableDownload';
import timeStore from 'stores/TimeStore';
import type PeopleAdminTable from './index';
import { DATE_TIME_FORMATS, PEOPLE_ADMIN_TABLE_COLUMNS } from 'config';
import { getFormattedTime } from 'utils';

class PeopleTableDownload extends TableDownload<PeopleAdminTable> {
  private mapNameHeader = {
    [PEOPLE_ADMIN_TABLE_COLUMNS.NAME]: 'Name',
    [PEOPLE_ADMIN_TABLE_COLUMNS.LOGIN_EMAIL]: 'Login Email',
    [PEOPLE_ADMIN_TABLE_COLUMNS.JOB_TITLE]: 'Job Title',
    [PEOPLE_ADMIN_TABLE_COLUMNS.CONTACT_NUMBER]: 'Contact Number',
    [PEOPLE_ADMIN_TABLE_COLUMNS.USER_ACCESS]: 'User Access',
    [PEOPLE_ADMIN_TABLE_COLUMNS.USER_ROLE]: 'User Role',
    [PEOPLE_ADMIN_TABLE_COLUMNS.PERMISSIONS]: 'Permissions',
    [PEOPLE_ADMIN_TABLE_COLUMNS.ASSIGNED_VEHICLE]: 'Assigned Vehicle',
    [PEOPLE_ADMIN_TABLE_COLUMNS.EMPLOYEE_ID]: 'Employee ID',
    [PEOPLE_ADMIN_TABLE_COLUMNS.ADDRESS]: 'Address',
    [PEOPLE_ADMIN_TABLE_COLUMNS.TIME_ZONE]: 'Time Zone',
    [PEOPLE_ADMIN_TABLE_COLUMNS.LICENCE_EXPIRATION]: 'Licence Expiration',
    [PEOPLE_ADMIN_TABLE_COLUMNS.FOB_ID]: 'Fob #',
    [PEOPLE_ADMIN_TABLE_COLUMNS.LAST_WEB_LOGIN]: 'Last Web Login',
    [PEOPLE_ADMIN_TABLE_COLUMNS.LAST_MOBILE_APP_LOGIN]: 'Last Mobile App Login',
    [PEOPLE_ADMIN_TABLE_COLUMNS.CREATED_BY]: 'Created By',
    [PEOPLE_ADMIN_TABLE_COLUMNS.CREATED_ON]: 'Created On',
    [PEOPLE_ADMIN_TABLE_COLUMNS.MODIFIED_BY]: 'Modified By',
    [PEOPLE_ADMIN_TABLE_COLUMNS.MODIFIED_ON]: 'Modified On',
  };

  protected getFile = async (): Promise<Blob> => {
    const ReportFile = await import('../../../Report/ReportDownload/ReportFile');
    const reportFile = new ReportFile.default(this.format.value, {
      title: 'People Admin',
      description: '',
      tables: [{ ...this.getData(), title: null }],
    });

    const file = await reportFile.create();

    return file;
  };

  protected get fileName() {
    return 'People Admin';
  }

  private timeFields = {
    [PEOPLE_ADMIN_TABLE_COLUMNS.LICENCE_EXPIRATION]: {
      get(value) {
        return value ? getFormattedTime(value, DATE_TIME_FORMATS.fullFormatDate, timeStore.sessionTimezone) : '-';
      },
    },
  };

  private getTextFromObj(obj: { [key: string]: { value: boolean; tooltip: string } }) {
    return (
      Object.entries(obj)
        .filter((value) => value[1].value)
        .map((value) => value[0])
        .join(', ') || '-'
    );
  }

  private getData = () => {
    const columnsIds = this.context.columns.filter((column) => column.isSelected).map((column) => column.value);

    const rows = this.context.source.map((inspectionPerformed) => {
      return columnsIds.map((column) => {
        if (column === PEOPLE_ADMIN_TABLE_COLUMNS.USER_ACCESS || column === PEOPLE_ADMIN_TABLE_COLUMNS.PERMISSIONS) {
          return this.getTextFromObj(inspectionPerformed[column]);
        } else if (column === PEOPLE_ADMIN_TABLE_COLUMNS.USER_ROLE) {
          return inspectionPerformed[column];
        } else if (this.timeFields.hasOwnProperty(column)) {
          return this.timeFields[column].get(inspectionPerformed[column]);
        } else {
          return inspectionPerformed[column];
        }
      });
    });

    return {
      columns: columnsIds.map((columnId) => this.mapNameHeader[columnId]),
      rows,
      total: [],
    };
  };
}

export default PeopleTableDownload;
