import React from 'react';
import { observer } from 'mobx-react';
import { InputNumber } from 'antd';

import type { SimpleField } from 'models/Fields';

import './styles.scss';

interface IProps {
  speed: SimpleField<number>;
}

const AlertPreferencesSpeeding: React.FC<IProps> = ({ speed }) => {
  const handleChangeType = (value) => {
    speed.set(value);
  };

  return (
    <div className="AlertPreference-line">
      <div className="AlertPreference-label">Speeds over</div>
      <div className="AlertPreference-value AlertPreferencesSpeeding-value">
        <div className="AlertPreferencesSpeeding-input">
          <InputNumber value={speed.value} min={1} max={300} onChange={handleChangeType} />
        </div>
        <div className="AlertPreferencesSpeeding-title">mph</div>
      </div>
    </div>
  );
};

export default observer(AlertPreferencesSpeeding);
