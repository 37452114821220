import { action, computed, observable } from 'mobx';
import { uniqBy, sortBy } from 'lodash';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import MediaVehicle from './MediaVehicle';
import type { ISelectOptions } from 'interfaces/models/Select/ISelect';
import { repositoryService } from 'services';
import { SimpleField } from 'models/Fields';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';

export interface IServerAsset {
  id: number;
  cpNumber: string;
  displayName: string;
}

export default class MediaVehicleList implements ISelectOptions<MediaVehicle, IServerAsset> {
  @observable items: MediaVehicle[];
  @observable selectedAssetId: SimpleField<string>;
  @observable totalPages: number;
  repository: IEntityRepository;
  repositoryId: IRepository;

  constructor() {
    this.items = [];
    this.selectedAssetId = new SimpleField<string>(null);
    this.repository = repositoryService.get('assets').entity('ids');
    this.repositoryId = repositoryService.get('assets');
  }

  init = (assetId) => {
    if (assetId) {
      this.selectedAssetId.set(assetId);
    }
  };

  @computed get selected() {
    return this.items.find((option) => option.assetId === this.selectedAssetId.value);
  }

  @computed get params() {
    return this.selectedAssetId && { assetId: this.selectedAssetId.value };
  }

  @action fetch = async (data, { clear, sortId, sortLabel }) => {
    try {
      const { filter, ...fields } = data;
      const response = await this.repository.get({ ...fields, searchValue: filter, withDashcamsOnly: true });
      const options = response?.items.map((item) => new MediaVehicle(item));
      const nextOptions = clear ? [...options] : [...this.items, ...options];
      const uniqueOptions = uniqBy(nextOptions, sortId);
      const sortOptions = sortBy(uniqueOptions, [(option) => option[sortLabel].toLowerCase()]);

      this.items = sortOptions;
      this.totalPages = response?.totalPages;
    } catch (e) {
      return e;
    }
  };

  @action addDefaultOption = () => void 0;

  @action addOption = async (option) => {
    const currentOption = this.getOptionById(option.value.toString());

    if (!currentOption) {
      const response = await this.repositoryId.entity(option.value).get();
      this.items.push(new MediaVehicle({ id: response.id, displayName: response.name, cpNumber: '' }));
    }
  };

  getOptionById = (assetId: string): MediaVehicle => {
    return this.items.find((item) => item.assetId === assetId);
  };
}
