import React, { useCallback } from 'react';

import './styles.scss';

export interface IAppSwitcherItemProps {
  id: 'clearpath' | 'field-service';
  children: React.ReactNode;
  onClick: (id: 'clearpath' | 'field-service') => void;
}

export const AppSwitcherItem = ({ children, id, onClick }: IAppSwitcherItemProps) => {
  const handleClick = useCallback(() => {
    onClick(id);
  }, [id]);
  return (
    <div className="AppSwitcherItem-root" onClick={handleClick}>
      {children}
    </div>
  );
};
