import type { IAppFileModel } from './IAppFileModel';
import { SimpleField } from 'models/Fields';

class AppFileModel implements IAppFileModel {
  fileName: string;
  displayName: string;
  id: number;
  type: string;
  url: SimpleField<string>;
  source: SimpleField<string>;

  constructor(model) {
    this.url = new SimpleField('');
    this.source = new SimpleField('');
    this.set(model);
  }

  set = ({ fileName, displayName, id, url, type }) => {
    this.fileName = fileName;
    this.displayName = displayName;
    this.id = id;
    this.type = type || '';
    this.url.set(url);
    this.source.set(url);
  };

  get data() {
    return {
      fileName: this.fileName,
      displayName: this.displayName,
      id: this.id,
      url: this.url.value,
      type: this.type,
    };
  }
}

export default AppFileModel;
