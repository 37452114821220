import { SimpleField } from 'models/Fields';

class MaintenanceCompleteCostsModel {
  parts: SimpleField<number>;
  labor: SimpleField<number>;
  other: SimpleField<number>;
  total: SimpleField<number>;

  constructor({ partsCost = 0, laborCost = 0, otherCost = 0, totalCost = 0 }) {
    this.parts = new SimpleField(partsCost);
    this.labor = new SimpleField(laborCost);
    this.other = new SimpleField(otherCost);
    this.total = new SimpleField(totalCost);
  }

  reset = () => {
    this.parts.reset();
    this.labor.reset();
    this.other.reset();
    this.total.reset();
  };

  get isUpdated() {
    return this.parts.isUpdated || this.labor.isUpdated || this.other.isUpdated || this.total.isUpdated;
  }

  get data() {
    return {
      partsCost: this.parts.value,
      laborCost: this.labor.value,
      otherCost: this.other.value,
      totalCost: this.total.value,
    };
  }
}

export default MaintenanceCompleteCostsModel;
