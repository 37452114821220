import React, { FC, useEffect, useRef } from 'react';

import { CloseIcon, SearchIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  placeholder?: string;
  value?: string;
  focused?: boolean;
}

const setFocus = (element) => {
  element.selectionStart = element.selectionEnd = element.value.length;
  element.focus();
};

const SearchInput: FC<IProps> = ({
  onBlur = () => true,
  onChange = () => true,
  placeholder,
  value = '',
  focused = false,
}) => {
  const inputRef: React.RefObject<HTMLInputElement> = useRef(null);

  useEffect(() => {
    const input = inputRef.current;
    if (focused) {
      setFocus(input);
    }
  }, []);

  return (
    <div className="SearchInput-container">
      <div className="SearchInput-icon">
        <SearchIcon className="SearchInput-iconImg" width="14px" height="14px" />
      </div>
      <input
        className="SearchInput-input"
        onBlur={onBlur}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        ref={inputRef}
        type="text"
        value={value}
      />
      {Boolean(value.length) ? (
        <div className="SearchInput-closeIcon" onMouseDown={() => onChange('')}>
          <CloseIcon className="SearchList-closeIconImg" width="10px" height="10px" />
        </div>
      ) : null}
    </div>
  );
};

export default SearchInput;
