import axios from 'axios';
import heic2any from 'heic2any';
import { reaction } from 'mobx';
import AppFileModel from './AppFileModel';
import { ToggleField } from 'models/Fields';

class AppFileImageModel extends AppFileModel {
  initialized: ToggleField;

  constructor(model) {
    super(model);
    this.initialized = new ToggleField(false);
    this.setSource();

    reaction(
      () => this.url.value,
      () => this.setSource(),
      { name: 'Convert heic image on url change' }
    );
  }

  get isHeicImage() {
    return this.type.includes('heic');
  }

  private getHeicUrl = async () => {
    const response = await axios.get(this.url.value, {
      responseType: 'blob',
      headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' },
    });
    const conversionResult = await heic2any({ blob: response.data });

    return URL.createObjectURL(conversionResult);
  };

  private setSource = async () => {
    this.initialized.toggle(false);

    if (this.isHeicImage && this.url.value) {
      const heicUrl = await this.getHeicUrl();
      this.source.set(heicUrl);
    }

    this.initialized.toggle(true);
  };
}

export default AppFileImageModel;
