import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import './styles.scss';

interface ISearchResultsItemWithLink extends Search.ISearchResultsItem {
  Icon: React.ElementType;
  link: string;
  onClick?: () => void;
  searchQuery: string;
}

const SearchResultsItem: React.FC<ISearchResultsItemWithLink> = ({
  description,
  name,
  id,
  link,
  onClick,
  searchQuery,
  type,
}) => {
  const cn = classNames('SearchResultsItem', {
    'SearchResultsItem--disabled': !Boolean(link),
  });
  const handleClick = useCallback(() => {
    onClick();
  }, [onClick]);

  function withSearchHighlightedQuery() {
    const pattern = new RegExp(searchQuery, 'gi');
    let nameToShow = id;

    if (name) {
      nameToShow = name;
    } else if (description) {
      nameToShow = description;
    }

    if (type === 'group' || type === 'driver') {
      nameToShow = description;
    }

    return { __html: nameToShow.replace(pattern, (str) => '<b>' + str + '</b>') };
  }

  return (
    <div className={cn}>
      <Link to={link} className="SearchResultsItem-link" onClick={handleClick}>
        {/*<div className="SearchResultsItem-icon">
          <Icon width="15px" height="15px" />
        </div>*/}
        <span className="SearchResultsItem-text" dangerouslySetInnerHTML={withSearchHighlightedQuery()} />
        {type === 'device' && <span className="SearchResultsItem-id">{id}</span>}
      </Link>
    </div>
  );
};

export default SearchResultsItem;
