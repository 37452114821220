import { computed } from 'mobx';
import { WEEKDAYS } from 'config';
import { ListField } from 'models/Fields';

class ScheduleWeekdaysModel extends ListField<WEEKDAYS> {
  @computed get isMonday() {
    return this.value.some((item) => item.value === WEEKDAYS.MONDAY);
  }

  @computed get isTuesday() {
    return this.value.some((item) => item.value === WEEKDAYS.TUESDAY);
  }

  @computed get isWednesday() {
    return this.value.some((item) => item.value === WEEKDAYS.WEDNESDAY);
  }

  @computed get isThursday() {
    return this.value.some((item) => item.value === WEEKDAYS.THURSDAY);
  }

  @computed get isFriday() {
    return this.value.some((item) => item.value === WEEKDAYS.FRIDAY);
  }

  @computed get isSaturday() {
    return this.value.some((item) => item.value === WEEKDAYS.SATURDAY);
  }

  @computed get isSunday() {
    return this.value.some((item) => item.value === WEEKDAYS.SUNDAY);
  }
}

export default ScheduleWeekdaysModel;
