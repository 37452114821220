import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import update from 'immutability-helper';
import { cloneDeep } from 'lodash';

import type { IEditColumns } from 'models/Tables/IEditColumns';

import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Modal from 'components/Modal';
import DraggableCard from 'components/DragableCard';

import { ReorderIcon } from 'assets';

import './styles.scss';

interface IProps<T> {
  className?: string;
  columns?: Array<IEditColumns<T>>;
  columnsMap?: any;
  isOpen: boolean;
  onCancel?: () => void;
  onOpen?: () => void;
  onSubmit?: (value: Array<IEditColumns<T>>) => void;
}

function EditColumnsModal<T>({
  className,
  isOpen,
  onCancel,
  onSubmit,
  onOpen,
  columns,
  columnsMap,
}: IProps<T>): React.ReactElement {
  const cn = classNames('EditColumnsModal', className);
  const [selectedColumns, setSelectedColumns] = useState(cloneDeep(columns));

  useEffect(() => {
    setSelectedColumns(cloneDeep(columns));
  }, [columns]);

  const handleSelectColumns = useCallback(
    (value) => {
      const checkedColumn = selectedColumns.find((column) => column.value === value);

      if (checkedColumn) {
        checkedColumn.isSelected = !checkedColumn.isSelected;
        setSelectedColumns(selectedColumns);
      }
    },
    [selectedColumns]
  );

  const handleMoveColumns = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const movedColumn = selectedColumns[dragIndex];
      const updatedColumns = update(selectedColumns, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, movedColumn],
        ],
      });

      setSelectedColumns(updatedColumns);
    },
    [selectedColumns]
  );

  const handleSaveColumns = useCallback(() => {
    onSubmit(selectedColumns);
    onCancel();
  }, [selectedColumns, onSubmit, onCancel]);

  const handleCancelColumns = useCallback(() => {
    setSelectedColumns(cloneDeep(columns));
    onCancel();
  }, [columns, onCancel]);

  return (
    <Modal className="Modal--editVehiclesColumns" isModalOpen={isOpen} onClose={handleCancelColumns} onOpen={onOpen}>
      <div className={cn}>
        <p className="EditColumnsModal-title">Edit Table Columns</p>
        <div className="EditColumnsModal-content">
          <div className="EditColumnsModal-columns">
            <ul className="EditColumnsModal-columnsList">
              {selectedColumns.map(({ value, isSelected }, index) => {
                const columnViewData = columnsMap[value];
                const cn = classNames('EditColumnsModal-columnsItem', {
                  'EditColumnsModal-columnsItem--disabled': columnViewData?.isDisabled,
                });

                return !columnViewData?.isVisible || columnViewData?.isVisible() ? (
                  <DraggableCard
                    className={columnViewData?.isDisabled && 'DraggableCard--disabled'}
                    id={value}
                    index={index}
                    key={value}
                    moveCard={handleMoveColumns}
                  >
                    <li className={cn}>
                      <div className="EditColumnsModal-columnsItemIcon">
                        <ReorderIcon className="EditColumnsModal-columnsItemIconImg" />
                      </div>
                      <div className="EditColumnsModal-columnsItemCheckbox">
                        <Checkbox
                          checked={isSelected}
                          label={columnViewData?.text}
                          onChange={handleSelectColumns}
                          value={value}
                        />
                      </div>
                    </li>
                  </DraggableCard>
                ) : null;
              })}
            </ul>
          </div>
        </div>
        <div className="EditColumnsModal-buttons">
          <div className="EditColumnsModal-cancel">
            <Button className="Button--cancel Button--cancelColorLynch" title="Cancel" onClick={handleCancelColumns} />
          </div>
          <div className="EditColumnsModal-submit">
            <Button className="Button--apply" title="Save" onClick={handleSaveColumns} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EditColumnsModal;
