import { ToggleField } from 'models/Fields';

class PersonPermissionsDashcamMediaAccess {
  active: ToggleField;

  constructor(mediaAccess) {
    this.active = new ToggleField(Boolean(mediaAccess));
  }

  get data() {
    return this.active.value ? {} : null;
  }

  get isUpdated() {
    return this.active.isUpdated;
  }

  reset = () => {
    this.active.reset();
  };
}

export default PersonPermissionsDashcamMediaAccess;
