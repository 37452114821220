import { APP_EVENTS } from 'services/EventsBus/appEvents';
import EventsBus from 'services/EventsBus/eventsBus';
import WS from './WebSocket';
import {
  DEFAULT_FALLBACK_STRATEGY,
  DEFAULT_VERSION,
  IFallbackStrategy,
  WS_CONFIG,
  WS_OPTIONS,
} from './WebSocketConfig';
export class WebSocketService {
  private urlRoot: string;
  private websockets: { [wsName: string]: WS };

  constructor(urlRoot: string) {
    this.urlRoot = urlRoot;
    this.websockets = {};

    EventsBus.get().on(APP_EVENTS.USER.LOGOUT, () => {
      Object.keys(this.websockets).forEach((wsName) => this.websockets[wsName]?.disconnect());
    });
  }

  public get = (
    wsName: string,
    wsKey: WS_OPTIONS,
    fallBackStrategy: IFallbackStrategy = DEFAULT_FALLBACK_STRATEGY,
    version: string = DEFAULT_VERSION
  ): WS => {
    if (!this.websockets.hasOwnProperty(wsName)) {
      this.websockets[wsName] = new WS(`${this.urlRoot}/${version}`, wsName, fallBackStrategy)
        .setOptions(WS_CONFIG[wsKey]())
        .initialize();
    }

    return this.websockets[wsName];
  };
}

export const webSocketService = new WebSocketService(`${process.env.REACT_APP_WS_HOST}`);
