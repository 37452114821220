import React, { FC } from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import type RequestMediaCamerasType from 'models/Dashcam/MediaRequestCheckout/RequestMedia/RequestMediaCamerasType';
import RadioGroup from 'components/RadioGroup';

import './styles.scss';

interface IProps {
  className?: string;
  cameraType: RequestMediaCamerasType;
  onSwitch?: () => void;
  isLoading: boolean;
}

const MediaManagerPlayerCameraTypeSwitcher: FC<IProps> = ({ cameraType, className, isLoading, onSwitch }) => {
  const cn = classNames('MediaManagerPlayerCameraTypeSwitcher', className);

  const handleSwitch = (value) => {
    cameraType.type.set(value);

    if (onSwitch) {
      onSwitch();
    }
  };

  return (
    <Observer
      render={() =>
        Boolean(cameraType.type) && (
          <div className={cn}>
            <RadioGroup
              className="RadioGroup--mediaManagerPlayerCameraTypeSwitcher"
              disabled={isLoading}
              radios={cameraType.cameras
                .filter((option) => option.isAvailable.value)
                .map((option) => ({
                  id: option.value,
                  label: option.label,
                  name: 'mediaManagerPlayerCameraTypeSwitcher',
                  value: option.value,
                }))}
              size="medium"
              tabs
              onChange={handleSwitch}
              checkedValue={cameraType.type.value}
            />
          </div>
        )
      }
    />
  );
};

export default MediaManagerPlayerCameraTypeSwitcher;
