import { APIStoreBase } from 'stores/APIStoreBase';
import isEmpty from 'lodash/isEmpty';
import type { IRequestCallbacks, IRequestOptions } from 'interfaces/services/RepositoryService/IHttpRequest';
import type IRepositoryBase from 'interfaces/services/RepositoryService/IRepositoryBase';
import HttpRequest from './HttpRequest';

import { RequestType } from './RequestsType';

class RepositoryBase implements IRepositoryBase {
  private callbacks: IRequestCallbacks = {};
  protected url: string = '';
  public createState: APIStoreBase = new APIStoreBase();
  public getState: APIStoreBase = new APIStoreBase();
  public putState: APIStoreBase = new APIStoreBase();
  public patchState: APIStoreBase = new APIStoreBase();
  public doState: APIStoreBase = new APIStoreBase();

  constructor(url: string) {
    this.url = url;
  }

  public create = async (data: object = {}, options: IRequestOptions = {}, queries: object = {}): Promise<any> => {
    const url = isEmpty(queries)
      ? this.url
      : `${this.url}?${Object.keys(queries)
          .map((key) => `${key}=${queries[key]}`)
          .join('&')}`;

    this.createState.reset().setLoading(true);

    try {
      const response = await new HttpRequest().post(url).send(data, options);

      this.createState.setSuccess(true);
      this.createState.setLoading(false);
      this.trigger('success:create');

      return response;
    } catch (err) {
      this.createState.setError(err.message || err);
      this.createState.setLoading(false);
      this.trigger('error:create');

      Error(`Can not create "${this.url}". Details: ${err}`);
    }
  };

  public get = async (params: object = {}, options: IRequestOptions = {}): Promise<any> => {
    this.getState.reset().setLoading(true);

    try {
      const response = await new HttpRequest().get(this.url).send(params, options);

      this.getState.setSuccess(true);
      this.getState.setLoading(false);
      this.trigger('success:get');

      return response;
    } catch (err) {
      this.getState.setError(err);
      this.getState.setLoading(false);
      this.trigger('error:get');

      Error(`Can not fetch "${this.url}". Details: ${err}`);
    }
  };

  public put = async (data: object = {}): Promise<any> => {
    this.putState.reset().setLoading(true);

    try {
      const response = await new HttpRequest().put(this.url).send(data);

      this.putState.setSuccess(true);
      this.putState.setLoading(false);
      this.trigger('success:put');

      return response;
    } catch (err) {
      this.putState.setError(err);
      this.putState.setLoading(false);
      this.trigger('error:put');

      Error(`Can not put "${this.url}". Details: ${err}`);
    }
  };

  public patch = async (data: object = {}): Promise<any> => {
    this.patchState.reset().setLoading(true);

    try {
      const response = await new HttpRequest().patch(this.url).send(data);

      this.patchState.setSuccess(true);
      this.patchState.setLoading(false);
      this.trigger('success:patch');

      return response;
    } catch (err) {
      this.patchState.setError(err);
      this.patchState.setLoading(false);
      this.trigger('error:patch');

      Error(`Can not update "${this.url}" entity. Details: ${err}`);
    }
  };

  public do = async (
    action: string,
    data: object,
    options: { requestType: RequestType } = { requestType: RequestType.POST },
    requireAuth = true
  ): Promise<any> => {
    this.doState.reset().setLoading(true);

    try {
      const response = await new HttpRequest()
        [options.requestType.toLowerCase()](`${this.url}/${action}`)
        .send(data, { requireAuth });

      this.doState.setSuccess(true);
      this.doState.setLoading(false);
      this.trigger('success:do');

      return response;
    } catch (err) {
      this.doState.setError(err);
      this.doState.setLoading(false);
      this.trigger('error:do');

      Error(`Can not perform "${action}" for the "${this.url}" entity. Details: ${err}`);
    }
  };

  public listenTo = (command: string, callback: () => void) => {
    if (this.callbacks.hasOwnProperty(command)) {
      this.callbacks[command].push(callback);
    } else {
      this.callbacks[command] = [];
      this.callbacks[command].push(callback);
    }
  };

  protected trigger = (command: string) => {
    if (this.callbacks.hasOwnProperty(command)) {
      this.callbacks[command].forEach((callback) => callback());
    }
  };
}

export default RepositoryBase;
