import React, { FC, useState, useEffect, useMemo } from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import { getTimeInTimeZone, getDayStartDate, getDayStartValue, getDayEndValue } from 'utils';
import Button from 'components/Button';
import Calendar from 'components/Calendar';
import type MediaDateOptionRange from 'models/Dashcam/MediaLibrary/MediaSource/MediaDateOptionRange';

import './styles.scss';

interface IProps {
  className?: string;
  initialDateRange: { from: number; to: number };
  onApply: () => void;
  onCancel: () => void;
  option: MediaDateOptionRange;
  timezone: string;
}

const MediaLibrarySourceCustomRange: FC<IProps> = ({
  className,
  initialDateRange: { from, to },
  onApply,
  onCancel,
  option,
  timezone,
}) => {
  const [dateRange, setDateRange] = useState({ from, to });
  const cn = classNames('MediaLibrarySourceCustomRange', className);
  const fromDate = useMemo(() => getDayStartDate(dateRange.from, timezone), [dateRange.from, timezone]);
  const toDate = useMemo(() => getDayStartDate(dateRange.to, timezone), [dateRange.to, timezone]);
  const todayDate = getTimeInTimeZone(new Date().valueOf(), timezone).toDate();
  const changeDateRange = (range) => {
    setDateRange({
      from: getDayStartValue(range.from, timezone),
      to: getDayEndValue(range.to, timezone),
    });
  };
  const applyDate = () => {
    onApply();
    option.isSelected.toggle(true);
    option.range.set({ from: dateRange.from, to: dateRange.to });
  };
  const cancelDate = () => {
    onCancel();
    setDateRange({ from, to });
  };

  useEffect(() => {
    setDateRange({ from, to });
  }, [from, to]);

  return (
    <Observer
      render={() => {
        return (
          option.isSelected.value && (
            <div className={cn}>
              <div className="MediaLibrarySourceCustomRange-calendar">
                <Calendar
                  disabledDays={[{ before: undefined, after: todayDate }]}
                  from={fromDate}
                  numberOfMonths={2}
                  onClick={changeDateRange}
                  to={toDate}
                  toMonth={todayDate}
                  withMonthYearForm
                  withoutOutside
                  withRange
                />
              </div>
              <div className="MediaLibrarySourceCustomRange-controls">
                <div className="MediaLibrarySourceCustomRange-button MediaLibrarySourceCustomRange-button--cancel">
                  <Button title="Cancel" className="Button--cancel Button--medium" onClick={cancelDate} />
                </div>
                <div className="MediaLibrarySourceCustomRange-button MediaLibrarySourceCustomRange-button--apply">
                  <Button
                    title="Apply"
                    className="Button--apply Button--orange Button--medium"
                    disabled={!Boolean(dateRange.from && dateRange.to)}
                    onClick={applyDate}
                  />
                </div>
              </div>
            </div>
          )
        );
      }}
    />
  );
};

export default MediaLibrarySourceCustomRange;
