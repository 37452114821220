import type ICustomerEstimateItem from 'interfaces/stores/Customers/ICustomerEstimateItem';

export class CustomerEstimateItem implements ICustomerEstimateItem {
  name: string;
  quantity: number;
  totalPrice: number;
  unitPrice: number;

  constructor(props) {
    this.name = props.name;
    this.quantity = props.quantity;
    this.totalPrice = props.totalPrice;
    this.unitPrice = props.unitPrice;
  }
}
