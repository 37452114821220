import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

import { REPORTS_STORE, STORAGE_ITEMS } from 'config';

import { getJSONItemFromStorage, printBlob } from 'utils';

import type ReportTable from 'models/Report/ReportTable';
import type { ISortColumn } from 'models/Tables/ISortColumn';

import type { ReportsStore } from 'stores';

import TableEmailModal from 'components/Table/TableEmailModal';
import TableDownloadModal from 'components/Table/TableDownloadModal';
import ReportTableByGroupRow from './ReportTableByGroupRow';
import ReportGroupedTableHeader from './ReportGroupedTableHeader';
import ReportGroupedTableActions from './ReportGroupedTableActions';
import ReportGroupedTableSticky from './ReportGroupedTableSticky';
import ReportTableByGroupHeader from './ReportTableByGroupHeader';
import ReportTableByGroupNotification from './ReportTableByGroupNotification';

import './styles.scss';

interface IProps {
  getColumns?: (table: ReportTable) => any;
  reportsStore?: ReportsStore;
}

interface IState {
  isFilterOpen: boolean;
  expanded: boolean;
  isDownloadModalOpen: boolean;
  isEmailModalOpen: boolean;
}

@inject(REPORTS_STORE)
@observer
class ReportTableByGroupVirtualized extends Component<IProps, IState> {
  rootElement: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.rootElement = React.createRef();
    this.state = {
      isFilterOpen: getJSONItemFromStorage(STORAGE_ITEMS.reports.filterOpenStatus, true),
      expanded: true,
      isDownloadModalOpen: false,
      isEmailModalOpen: false,
    };
  }

  setSortedColumn = (column: ISortColumn) => {
    const {
      reportsStore: { reportTableGroup },
    } = this.props;

    reportTableGroup?.filter.sort.set(column);
  };

  openDownloadModal = () => {
    this.setState({ isDownloadModalOpen: true });
  };

  openEmailModal = () => {
    this.setState({ isEmailModalOpen: true });
  };

  closeDownloadModal = () => {
    this.setState({ isDownloadModalOpen: false });
  };

  closeEmailModal = () => {
    this.setState({ isEmailModalOpen: false });
  };

  toggleGroups = () => {
    const {
      reportsStore: { reportTableGroup },
    } = this.props;

    reportTableGroup.toggleAllVehicleOpen();
  };

  Row = ({ index, style }) => <ReportTableByGroupRow index={index} style={style} />;

  handleScroll = (prop) => {
    this.props.reportsStore.reportTableGroup.updateCurrentTopIndex(Math.floor(prop.scrollOffset / 35));
  };

  handleBodyScroll = () => {
    const list = document.querySelector('.List');
    if (list) {
      this.props.reportsStore.reportTableGroup.updateScrollLeft(list.scrollLeft);
    }
  };

  handleFilterToggle = () => {
    this.setState(
      {
        isFilterOpen: !this.state.isFilterOpen,
      },
      () => {
        if (!this.state.isFilterOpen) {
          this.props.reportsStore.reportTableGroup.clearHeaderSearches();
        }
      }
    );
  };

  onPrint = () => {
    const {
      reportsStore: { downloadReport },
    } = this.props;
    downloadReport.format.set('html');
    downloadReport.print().then(printBlob);
  };

  render() {
    const {
      reportsStore: {
        downloadReport,
        reportTableGroup,
        reportTableGroup: { rowsCount, singleVehicle },
        getReportsTableRequestStatus,
      },
    } = this.props;

    const { isDownloadModalOpen, isEmailModalOpen, isFilterOpen } = this.state;

    return (
      <div className="ReportGroupedTable" ref={this.rootElement}>
        <div className="ReportGroupedTable-header">
          <div className="TableHeader-title">
            <div className="TableHeader-left">
              <ReportTableByGroupHeader
                withControls={!singleVehicle}
                expanded={!reportTableGroup.isAllVehiclesClosed}
                onChange={this.toggleGroups}
                columns={[]}
                filter={reportTableGroup.filter}
                isFilterOpen={isFilterOpen}
                hasLimitError={singleVehicle && rowsCount >= 2000}
              />
            </div>
            <div className="TableHeader-right">
              <ReportGroupedTableActions
                isFilterOpen={isFilterOpen}
                handleFilterToggle={this.handleFilterToggle}
                onDownloadModalOpen={this.openDownloadModal}
                onEmailModalOpen={this.openEmailModal}
                onPrint={this.onPrint}
              />
            </div>
          </div>
        </div>
        <div className="ReportGroupedTable-content">
          <div className="ReportGroupedTable-headers">
            <ReportGroupedTableHeader isFilterOpen={isFilterOpen} />
          </div>
          <div className="ReportGroupedTable-body" onScroll={this.handleBodyScroll}>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  className="List"
                  height={height}
                  width={width}
                  itemCount={rowsCount}
                  itemSize={35}
                  onScroll={this.handleScroll}
                >
                  {this.Row}
                </List>
              )}
            </AutoSizer>
            {!singleVehicle && <ReportGroupedTableSticky />}
            {rowsCount === 0 && singleVehicle && getReportsTableRequestStatus.success && (
              <div className="ReportGroupedTable-results-list--empty">No Results Found</div>
            )}
          </div>
        </div>
        <TableDownloadModal<ReportsStore>
          isOpen={isDownloadModalOpen}
          onClose={this.closeDownloadModal}
          tableDownload={downloadReport}
        />
        <TableEmailModal<ReportsStore>
          isOpen={isEmailModalOpen}
          onClose={this.closeEmailModal}
          tableDownload={downloadReport}
        />
        <ReportTableByGroupNotification />
      </div>
    );
  }
}

export default ReportTableByGroupVirtualized;
