import TableToggleFilter from 'models/Tables/TableToggleFilter';
import { INSPECTION_RESULTS } from 'config';

export default class ResultsFilter {
  list: TableToggleFilter[];

  constructor() {
    this.list = [
      { value: INSPECTION_RESULTS.FAILED, text: 'Failed with Defects' },
      { value: INSPECTION_RESULTS.PASSED_WITH_DEFECTS, text: 'Passed with Defects' },
      { value: INSPECTION_RESULTS.PASSED, text: 'Passed' },
      { value: INSPECTION_RESULTS.IN_PROGRESS, text: 'In Progress' },
      { value: INSPECTION_RESULTS.ABANDONED, text: 'Abandoned' },
    ].map((item) => new TableToggleFilter({ isActive: false, ...item }));
  }
}
