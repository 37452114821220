import React, { FC, useState } from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';
import { DASHCAM_MEDIA_SOURCE } from 'config';
import type MediaGroup from 'models/Dashcam/MediaLibrary/MediaSource/MediaGroup';
import MediaLibraryPanelSectionTitle from '../PanelSectionTitle';
import type MediaVehicle from 'models/Dashcam/MediaLibrary/MediaSource/MediaVehicle';
import type { IServerAsset, default as MediaVehicleList } from 'models/Dashcam/MediaLibrary/MediaSource/MediaVehicleList';
import type { IServerGroup, default as MediaGroupList } from 'models/Dashcam/MediaLibrary/MediaSource/MediaGroupList';
import RadioGroup from 'components/RadioGroup';
import SearchableSelectAsync from 'components/Select/SearchableSelectAsync';

import './styles.scss';

interface IProps {
  className?: string;
  groups: MediaGroupList;
  initialSource?: DASHCAM_MEDIA_SOURCE;
  vehicles: MediaVehicleList;
}

const MediaLibrarySourceSwitcherSelect: FC<IProps> = ({ className, groups, vehicles }) => {
  const [source, setSource] = useState<DASHCAM_MEDIA_SOURCE>(
    vehicles.selectedAssetId.value ? DASHCAM_MEDIA_SOURCE.VEHICLE : DASHCAM_MEDIA_SOURCE.GROUP
  );
  const cn = classNames('MediaLibrarySourceSwitcherSelect', className);
  const changeMediaSource = (source: DASHCAM_MEDIA_SOURCE) => {
    if (source === DASHCAM_MEDIA_SOURCE.VEHICLE) {
      groups.selectedAssetGroupId.set(null);
    } else {
      vehicles.selectedAssetId.set(null);
    }
    setSource(source);
  };
  const isVehicle = source === DASHCAM_MEDIA_SOURCE.VEHICLE;
  const changeVehicle = (option: Select.ISelectOption) => vehicles.selectedAssetId.set(option.value);
  const changeGroup = (option: Select.ISelectOption) => groups.selectedAssetGroupId.set(option.value);

  return (
    <Observer
      render={() => (
        <div className={cn}>
          <div className="MediaLibrarySourceSwitcherSelect-section MediaLibrarySourceSwitcherSelect-section--source">
            <div className="MediaLibrarySourceSwitcherSelect-sectionContent">
              <RadioGroup
                checkedValue={source}
                onChange={changeMediaSource}
                radios={[
                  {
                    id: DASHCAM_MEDIA_SOURCE.VEHICLE,
                    label: 'Vehicle',
                    value: DASHCAM_MEDIA_SOURCE.VEHICLE,
                    name: 'dashCamMediaSource',
                  },
                  {
                    id: DASHCAM_MEDIA_SOURCE.GROUP,
                    label: 'Group',
                    value: DASHCAM_MEDIA_SOURCE.GROUP,
                    name: 'dashCamMediaSource',
                  },
                ]}
                size="medium"
                tabs
              />
            </div>
          </div>
          <div className="MediaLibrarySourceSwitcherSelect-section MediaLibrarySourceSwitcherSelect-section--select">
            <div className="MediaLibrarySourceSwitcherSelect-sectionTitle">
              <MediaLibraryPanelSectionTitle>{isVehicle ? 'Vehicle' : 'Group'}</MediaLibraryPanelSectionTitle>
            </div>
            <div className="MediaLibrarySourceSwitcherSelect-sectionContent">
              {isVehicle ? (
                <SearchableSelectAsync<MediaVehicle, IServerAsset>
                  handleChange={changeVehicle}
                  key={source}
                  keys={{ value: 'assetId', label: 'displayName' }}
                  options={vehicles}
                  value={{
                    value: vehicles.selectedAssetId.value,
                    label: vehicles.selected?.displayName,
                  }}
                  selectFirst
                />
              ) : (
                <SearchableSelectAsync<MediaGroup, IServerGroup>
                  handleChange={changeGroup}
                  key={source}
                  keys={{ value: 'assetGroupId', label: 'description' }}
                  options={groups}
                  value={{
                    value: groups.selectedAssetGroupId.value || '',
                    label: groups.selected?.displayName || 'All Vehicles',
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default MediaLibrarySourceSwitcherSelect;
