import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import type { FieldProps } from 'formik';

import { checkboxUncheckedIconSrc, checkboxCheckedIconSrc } from 'assets';
import './styles.scss';

interface IProps {
  disabled?: boolean;
  checked?: boolean;
  className?: string;
  fieldProps?: FieldProps;
  label?: string | React.ReactNode;
  icon?: string;
  onChange?: (value?: any) => void;
  value?: any;
}

const Checkbox: React.FC<IProps> = ({
  checked = false,
  disabled = false,
  className,
  fieldProps,
  label = '',
  onChange = () => null,
  value,
  icon,
}) => {
  const cn = classNames('Checkbox', className, {
    'Checkbox--disabled': disabled,
  });
  const [isChecked, setCheck] = useState(checked);

  const handleCheck = () => {
    if (!disabled) {
      setCheck(!isChecked);
      onChange(value ? value : !isChecked);
    }
  };

  useEffect(() => {
    if (fieldProps) {
      fieldProps.field.onChange({
        target: {
          name: fieldProps.field.name,
          value: isChecked,
        },
      });
    }
  }, [isChecked]);

  useEffect(() => {
    setCheck(checked);
  }, [checked]);

  const image = useMemo(() => {
    if (icon) {
      return icon;
    } else {
      return isChecked ? checkboxCheckedIconSrc : checkboxUncheckedIconSrc;
    }
  }, [icon, isChecked]);

  return (
    <div className={cn}>
      <div className="Checkbox-icon" onClick={handleCheck}>
        <img src={image} />
      </div>
      <input
        type="checkbox"
        disabled={disabled}
        className="Checkbox-hidden"
        checked={isChecked}
        value={value}
        readOnly
      />
      <label className="Checkbox-label" onClick={handleCheck}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
