import React, { FC } from 'react';
import classNames from 'classnames';

import InfoTooltip from 'components/InfoTooltip';
import { InfoTransparentIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
}

const TimeHintTooltip: FC<IProps> = ({ className }) => {
  const cn = classNames('TimeHintTooltip', className);

  return (
    <div className={cn}>
      <InfoTooltip icon={<InfoTransparentIcon className="TimeHintTooltip-tooltipIcon" />}>
        The time selector for a <b>group of vehicles</b> will show the vehicle locations at a{' '}
        <b>single point in time</b>.
        <br />
        <br />
        The time selector for a <b>single vehicle</b> will show the path for that vehicle for a <b>range of time</b>.
      </InfoTooltip>
    </div>
  );
};

export default TimeHintTooltip;
