import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { REPORTS_STORE } from 'config';
import type { ReportsStore } from 'stores';

import type ReportTable from 'models/Report/ReportTable';

import ReportGroupedTableHeaderSorter from './ReportGroupedTableHeaderSorter';
import ReportGroupedTableHeaderFilter from './ReportGroupedTableHeaderFilter';

import './styles.scss';

interface IProps {
  getColumns?: (table: ReportTable) => any;
  reportsStore?: ReportsStore;
  isFilterOpen: boolean;
}

@inject(REPORTS_STORE)
@observer
class ReportGroupedTableHeader extends Component<IProps> {
  render() {
    const {
      reportsStore: { reportTableGroup },
      isFilterOpen,
    } = this.props;

    let css = '';

    const total = reportTableGroup.sortedHeaders.length;
    reportTableGroup.sortedHeaders.forEach(({ width, key }, index) => {
      const indexFromEnd = total - index - 1;
      css += `
      .ReportGroupedTable-headers > div > div:nth-child(${total}n-${indexFromEnd}),
      .ReportTableByGroupRowEvent > div:nth-child(${total}n-${indexFromEnd}) {
        width: ${width}px !important;
        min-width: ${width}px !important;
      }
      .ReportTableByGroupRowSummary--${key},
      .ReportTableByGroupRowEvent--${key} {
        width: ${width}px !important;
        min-width: ${width}px !important;
      }
      `;
    });

    return (
      <div className="ReportGroupedTableHeader" style={{ left: reportTableGroup.scrollLeft * -1 }}>
        {reportTableGroup.sortedHeaders.map((header) => {
          return (
            <div key={header.key}>
              <ReportGroupedTableHeaderSorter
                header={header}
                toggle={reportTableGroup.toggleSortDirectionByHeaderKey}
              />
              {isFilterOpen && <ReportGroupedTableHeaderFilter header={header} />}
            </div>
          );
        })}
        <style dangerouslySetInnerHTML={{ __html: css }} />
      </div>
    );
  }
}

export default ReportGroupedTableHeader;
