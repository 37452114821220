import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { ADMIN_GROUPS_TABLE_COLUMNS } from 'config';
import type { GroupsAdmin } from 'stores/Admin/Groups';

import GroupTableVehiclesAdd from 'components/Admin/GroupTableVehicles/GroupTableVehiclesButton/GroupTableVehiclesAdd';
import GroupTableVehiclesAllEmpty from 'components/Admin/GroupTableVehicles/GroupTableVehiclesAllEmpty';
import GroupTableVehiclesAllHeader from './GroupTableVehiclesAllHeader';
import Table from 'components/Table';

import './styles.scss';

interface IProps {
  className?: string;
  groupsAdmin?: GroupsAdmin;
  name: string;
  onChange: (id?: string | string[]) => void;
}

@inject(({ adminStore: { groupsAdmin } }) => ({
  groupsAdmin,
}))
@observer
class GroupTableVehiclesAll extends Component<IProps> {
  get columns() {
    return [
      {
        title: 'Vehicle Name',
        dataIndex: ADMIN_GROUPS_TABLE_COLUMNS.NAME,
        defaultSortOrder: 'ascend',
        render: (_, { name }) => <>{name}</>,
        fixed: true,
        width: '40%',
      },
      {
        title: 'CP #',
        dataIndex: ADMIN_GROUPS_TABLE_COLUMNS.CP,
      },
      {
        title: 'Add',
        width: 60,
        render: (_, { selected, id }) => {
          return <GroupTableVehiclesAdd disabled={selected} onClick={this.handleAdd} id={id} />;
        },
      },
    ];
  }

  handleAdd = (id: string) => {
    const {
      groupsAdmin: { assignVehicleToSelectedGroup },
      onChange,
    } = this.props;

    assignVehicleToSelectedGroup(id);
    onChange(id);
  };

  handleTableChange() {
    return void 0;
  }

  render() {
    const {
      groupsAdmin: {
        repositoryVehicles: { getState },
        filteredVehiclesList,
      },
      onChange,
    } = this.props;

    const cn = classNames('GroupTableVehiclesAll', {
      'GroupTableVehiclesAll--empty': Boolean(!filteredVehiclesList.length),
    });

    return (
      <div className={cn}>
        <Table
          rowClassName={(record) => (record.selected ? 'GroupTableVehiclesAll-selected' : '')}
          className={this.props.className}
          // @ts-ignore
          columns={this.columns}
          dataSource={filteredVehiclesList}
          showHeader={Boolean(false)}
          loading={getState.loading}
          onChange={this.handleTableChange}
          scroll={{
            y: 'calc(100vh - 317px)',
          }}
          showSorterTooltip={false}
          pagination={false}
          title={() => <GroupTableVehiclesAllHeader onAdd={onChange} />}
          locale={{
            emptyText: <GroupTableVehiclesAllEmpty />,
          }}
        />
      </div>
    );
  }
}

export default GroupTableVehiclesAll;
