import React from 'react';
import { Observer } from 'mobx-react';
import CalendarWithInput from 'components/CalendarWithInput';
import type { SimpleField } from 'models/Fields';

import './styles.scss';

interface IProps {
  date: SimpleField<number>;
}

const MaintenanceMarkAsCompleteDate: React.FC<IProps> = ({ date }) => {
  return (
    <Observer
      render={() => (
        <div className="MaintenancePreference-line MaintenanceMarkAsCompleteDate">
          <div className="MaintenancePreference-label">Date Completed</div>
          <div className="MaintenancePreference-value MaintenanceMarkAsCompleteDate-value">
            <CalendarWithInput withTodayLabel value={date.value} onSubmit={date.set} isInputEditable={false} />
          </div>
        </div>
      )}
    />
  );
};

export default MaintenanceMarkAsCompleteDate;
