import { SimpleField, ToggleField } from 'models/Fields';
import type CamerasJournal from '../CamerasJournal';

class CameraCustomInterval {
  isSelected: ToggleField;
  startTimestamp: SimpleField<number>;
  endTimestamp: SimpleField<number>;
  context: CamerasJournal;
  private halfHourGap: number;

  constructor(context: CamerasJournal) {
    this.context = context;
    this.isSelected = new ToggleField(false);
    this.startTimestamp = new SimpleField(null);
    this.endTimestamp = new SimpleField(null);
    this.halfHourGap = 30 * 60 * 1000;
  }

  set = (timestamp: number, min: number, max: number) => {
    this.context.dates.selected()?.intervals.forEach((interval) => interval.isSelected.toggle(false));
    this.isSelected.toggle(true);
    this.context.context.requestMedia.timeSegment.startTime.set(timestamp);

    if (min < timestamp - this.halfHourGap) {
      this.startTimestamp.set(timestamp - this.halfHourGap);
    } else {
      this.startTimestamp.set(min);
      this.endTimestamp.set(min + 2 * this.halfHourGap);

      return;
    }

    if (max > timestamp + this.halfHourGap) {
      this.endTimestamp.set(timestamp + this.halfHourGap);
    } else {
      this.endTimestamp.set(max);
      this.startTimestamp.set(max - 2 * this.halfHourGap);
    }
  };

  get activeTimestamp() {
    return this.context.context.requestMedia.timeSegment.startTime;
  }

  reset = () => {
    this.isSelected.toggle(false);
  };
}

export default CameraCustomInterval;
