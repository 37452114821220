import TableToggleFilter from 'models/Tables/TableToggleFilter';
import { PEOPLE_ADMIN_PERMISSIONS } from 'config';
import { CELL_ICONS } from '../index';

export default class PermissionsFilter {
  list: TableToggleFilter[];

  constructor() {
    this.list = [
      { value: PEOPLE_ADMIN_PERMISSIONS.DRIVER, text: 'Driver', icon: CELL_ICONS.DRIVER },
      { value: PEOPLE_ADMIN_PERMISSIONS.INSPECTIONS, text: 'Vehicle Inspector', icon: CELL_ICONS.VEHICLE },
      { value: PEOPLE_ADMIN_PERMISSIONS.MEDIA, text: 'Dashcam Media Access', icon: CELL_ICONS.DASHCAM },
      { value: PEOPLE_ADMIN_PERMISSIONS.GROUP_RESTRICTIONS, text: 'Group Restrictions', icon: CELL_ICONS.GROUP },
    ].map((item) => new TableToggleFilter({ isActive: false, ...item }));
  }
}
