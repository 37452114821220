import PlayerState from './PlayerState';

class PauseImagePlayerState extends PlayerState {
  activate = async () => {
    this.context.video.url.set(null);
    this.context.isLoading.toggle(true);
    await this.context.image.fetch();
  };

  deactivate = () => {
    //
  };
}

export default PauseImagePlayerState;
