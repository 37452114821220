import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { sortByAlphabet } from 'utils';

import SearchableSelect from 'components/Select/SearchableSelect';

import type { PersistenceStore } from 'stores';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';

interface IProps {
  emptySearchResults?: string;
  handleChange?: (option: Select.ISelectOption) => void;
  handleMenuClose?: () => void;
  isMenuOpen?: boolean;
  isSearchable?: boolean;
  menuShouldBlockScroll?: boolean;
  persistChange?: boolean;
  persistenceStore?: PersistenceStore;
  placeholder?: string;
  searchPlaceholder?: string;
  value?: Select.ISelectOption;
  withAll?: boolean;
}

@inject(({ persistenceStore }) => ({ persistenceStore }))
@observer
class SearchableEventMetaDataSelect extends Component<IProps> {
  static defaultProps = {
    placeholder: 'Select',
    emptySearchResults: 'No Code Found',
  };

  static allOption = { label: 'All Codes', value: '0' };

  repositoryEventsMetadata: IEntityRepository;

  constructor(props) {
    super(props);

    if (props.persistChange && props.persistenceStore.selectedUser && !props.value) {
      this.props.handleChange(props.persistenceStore.selectedUser);
    } else if (props.withAll && !props.value?.value) {
      this.props.handleChange(SearchableEventMetaDataSelect.allOption);
    }

    this.repositoryEventsMetadata = repositoryService.get('events').entity('metadata');
  }

  getData = (props: { page: number; pageSize: number; filter: string }) => {
    const data = { page: props.page || 0, pageSize: props.pageSize || 50, order: 'ASC' };

    return this.repositoryEventsMetadata.get(data).then((data) => {
      const totalPages = data.totalPages;
      const options = data.items
        .filter(({ attributes }) => attributes.alertable)
        .map((item) => {
          return {
            value: item.code,
            label: item.attributes.label,
          };
        })
        .filter(({ label }) => Boolean(label))
        .sort((a, b) => sortByAlphabet(a.label.toLowerCase(), b.label.toLowerCase()));

      if (!props.filter && this.props.withAll && props.page === 0) {
        options.unshift(SearchableEventMetaDataSelect.allOption);
      }

      const option = options.find(({ value }) => value === this.props.value?.value);

      if (!this.props.value.label && option) {
        this.props.handleChange(option);
      }

      return {
        totalPages,
        options,
      };
    });
  };

  handleChange = (option: Select.ISelectOption) => {
    this.props.handleChange(option);
    if (this.props.persistChange) {
      this.props.persistenceStore.updateSelectedUser({ ...option });
    }
  };

  render() {
    return (
      <SearchableSelect
        {...this.props}
        getData={this.getData}
        handleChange={this.handleChange}
        isMenuOpen={this.props.isMenuOpen}
        onMenuClose={this.props.handleMenuClose}
        menuShouldBlockScroll={this.props.menuShouldBlockScroll}
      />
    );
  }
}

export default SearchableEventMetaDataSelect;
