import { action, computed, observable } from 'mobx';
import noop from 'lodash/noop';
import sortBy from 'lodash/sortBy';

import { repositoryService } from 'services';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';
import { NOTIFICATION_EVENT_TYPES } from 'services/Notification/NotificationEventTypes';

import type { Maintenances } from 'stores';

import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

import { ToggleField, SimpleField } from 'models/Fields';

import MaintenanceRequest from './MaintenanceRequest';
import type IMaintenanceRequestServerDto from './IMaintenanceRequestServerDto';
import type MaintenanceModel from '../MaintenanceModel';

class MaintenanceRequests {
  @observable items: MaintenanceRequest[] = [];
  context: Maintenances;
  repository: IRepository;
  repositoryRequests: IEntityRepository;
  repositoryRequestsId: IEntityRepository;
  @observable status: 'IDLE' | 'WORKING' | 'COMPLETED' | 'ERROR' = 'IDLE';
  totalPages: number;
  hideNotification: ToggleField = new ToggleField(false);
  ignoreRequest: SimpleField<number>;

  constructor(context: Maintenances) {
    this.context = context;
    this.repository = repositoryService.get('maintenances');
    this.repositoryRequests = this.repository.entity('requests');
    this.repositoryRequestsId = null;
    this.ignoreRequest = new SimpleField(null);
    EventsBus.get().on(NOTIFICATION_EVENT_TYPES.MAINTENANCE_REQUEST, this.handleWSEvents);
  }

  @action
  fetch = async (force: boolean = false) => {
    try {
      if (force || this.status === 'IDLE') {
        this.status = 'WORKING';
        const data = await this.repositoryRequests.get(
          { withDocuments: true, orderBy: 'requestedTime' },
          { ignoreError: true }
        );
        this.items = sortBy(data.items, 'requestedTime').map(
          (item) => new MaintenanceRequest(MaintenanceRequest.convertServerToClientDto(item))
        );
        this.totalPages = data.totalPages;
        this.status = 'COMPLETED';
      }
    } catch (error) {
      this.status = 'ERROR';
    }
  };

  @computed get totalCount() {
    return this.items.length;
  }

  @computed get latestRequest() {
    return this.items[this.items.length - 1];
  }

  @action deleteById = async (id: number) => {
    const index = this.items.findIndex((item) => item.id === id);
    if (index !== -1) {
      this.repositoryRequestsId = this.repositoryRequests.entity(String(id));
      try {
        await this.repositoryRequestsId.delete();
        this.items.splice(index, 1);
      } catch (error) {
        noop();
      }
    }
  };

  @action removeById = (id: number) => {
    const index = this.items.findIndex((item) => item.id === id);
    if (index !== -1) {
      this.items.splice(index, 1);
    }
  };

  @action convertToMaintenance = async (model: MaintenanceModel) => {
    const index = this.items.findIndex((item) => item.id === model.requestId);
    if (index !== -1) {
      try {
        const { id } = await this.repositoryRequests.patch({ ...model.data, id: model.requestId });
        if (id) {
          this.removeById(id);
          this.context.isUpdate.toggle(true);
          EventsBus.get().trigger(APP_EVENTS.MAINTENANCE.APPLY_TASKS.TASK.CONVERTED, id);
        }
      } catch (_) {
        noop();
      }
    }
  };

  @action
  handleWSEvents = (data: IMaintenanceRequestServerDto) => {
    this.items.push(new MaintenanceRequest(MaintenanceRequest.convertServerToClientDto(data)));
    this.hideNotification.toggle(false);
  };

  findById = (id: number) => {
    return this.items.find((item) => item.id === id);
  };
}

export default MaintenanceRequests;
