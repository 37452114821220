import React from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  className?: string;
  direction?: string;
  onClick?: () => void;
  style?: { [key: string]: string };
}

const Arrow = ({ className, direction, onClick, style }: IProps) => {
  const cn = classNames('CarouselArrow', className, {
    [`CarouselArrow--${direction}`]: Boolean(direction),
  });

  return (
    <div className={cn} style={{ ...style }} onClick={onClick}>
      {direction === 'left' ? '❮' : '❯'}
    </div>
  );
};

export default Arrow;
