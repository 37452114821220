import React from 'react';

import { ALERT_STEP_INDEX } from 'config';

import Button from '../../Button';
import AlertModalStepListItem from '../AlertModalStepListItem';

import './styles.scss';

interface IProps {
  steps: Alerts.Modal.IStep[];
  activeStepIndex: number;
  title: string;
  hasNextStep?: boolean;
  nextStepTitle?: string;
  nextButtonEnabled?: boolean;
  onNextClick?: () => void;
  hasPreviousStep?: boolean;
  onPreviousClick?: () => void;
  blockSteps: number[];
  loading?: boolean;
}

const AlertModal: React.FC<IProps> = ({
  steps,
  title: headerTitle,
  activeStepIndex: _activeStepIndex,
  nextButtonEnabled = true,
  loading = false,
  blockSteps,
  onNextClick = () => null,
  onPreviousClick = () => null,
  children,
}) => {
  const activeStepIndex = steps.length <= _activeStepIndex ? ALERT_STEP_INDEX.STEP_1 : _activeStepIndex;
  const activeStep = steps[activeStepIndex];
  const { nextStepTitle = 'Next', hasNextStep = false, hasPreviousStep = false } = activeStep;

  return (
    <div className="AlertModal-root">
      <div className="AlertModal-header">
        <h3 className="AlertModal-title">{headerTitle}</h3>
      </div>
      <div className="AlertModal-body">
        <div className="AlertModal-steps">
          {steps.map(({ title }, index) => {
            return (
              <AlertModalStepListItem
                key={title}
                title={title}
                index={index}
                isActive={index === activeStepIndex}
                isCompleted={index < activeStepIndex}
                isBlocked={blockSteps.includes(index)}
              />
            );
          })}
        </div>
        <div className="AlertModal-step">{children}</div>
      </div>
      <div className="AlertModal-footer">
        <div className="AlertModal-navigation">
          <div className="AlertModal-navigation-previous">
            {hasPreviousStep && (
              <Button
                disabled={blockSteps.includes(activeStepIndex - 1)}
                title={'Go Back'}
                onClick={onPreviousClick}
                inline
                className="AlertModal-navigation-button AlertModal-navigation-button--previous"
              />
            )}
          </div>
          <div className="AlertModal-navigation-next">
            {hasNextStep && (
              <Button
                disabled={!nextButtonEnabled}
                title={nextStepTitle}
                onClick={onNextClick}
                inline
                sending={loading}
                className="AlertModal-navigation-button  AlertModal-navigation-button--next"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
