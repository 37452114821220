import React, { FC } from 'react';
import classnames from 'classnames';

import type InspectionTemplate from 'models/Inspections/Models/InspectionTemplate';
import InspectionFormTile from './FormTIle';

import './styles.scss';

interface IProps {
  className?: string;
  list: InspectionTemplate[];
}

const InspectionFormTiles: FC<IProps> = ({ className, list }) => {
  const cn = classnames('InspectionFormTiles', className);

  return (
    <div className={cn}>
      <div className="InspectionFormTiles-list">
        {list.map((item) => (
          <div className="InspectionFormTiles-item" key={item.id}>
            <InspectionFormTile>{item.name.value}</InspectionFormTile>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InspectionFormTiles;
