import React, { useState, useEffect } from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import type { CAMERA_TYPE } from 'config';
import type { MapStore } from 'stores';
import type MediaClips from 'models/Dashcam/MediaLibrary/MediaClips';
import type MediaTypeCategories from 'models/Dashcam/MediaLibrary/MediaType/MediaTypeCategories';

import InfiniteScroll from 'components/InfiniteScroll';
import MediaLibraryListItems from './MediaLibraryListItems';

import { LoaderIcon } from 'assets';
import './styles.scss';

interface IProps {
  clips: MediaClips;
  categories: MediaTypeCategories;
  map: MapStore;
  cameraProvider?: CAMERA_TYPE;
}

const MediaLibraryList: React.FC<IProps> = ({ clips, map, categories, cameraProvider }) => {
  const [hasMore, setHasMore] = useState(clips.currentPage.value < clips.totalPages.value);
  const [loadingMore, setLoadingMore] = useState(false);
  const cn = classNames('MediaLibraryList', {
    [`MediaLibraryList--${clips.view}`]: Boolean(clips.view),
  });

  const handleLoadMoreItems = async (updatedPage) => {
    if (
      updatedPage < clips.totalPages.value &&
      clips.repository.getState.success &&
      !clips.repository.getState.loading
    ) {
      setHasMore(false);
      setLoadingMore(true);

      clips.currentPage.set(updatedPage);
      await clips.fetch(false);

      setHasMore(clips.currentPage.value < clips.totalPages.value);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    setHasMore(clips.currentPage.value < clips.totalPages.value);
  }, [clips.currentPage.value, clips.totalPages.value]);

  return (
    <Observer
      render={() => (
        <div className={cn}>
          <InfiniteScroll
            hasMore={hasMore}
            loadMore={handleLoadMoreItems}
            useWindow={false}
            key={clips.totalPages.value}
            scrollToElement
          >
            <MediaLibraryListItems clips={clips} map={map} categories={categories} cameraProvider={cameraProvider} />
            {loadingMore && (
              <div key={0} className="MediaLibraryList-loader">
                <LoaderIcon />
              </div>
            )}
          </InfiniteScroll>
        </div>
      )}
    />
  );
};

export default MediaLibraryList;
