import { action, observable, reaction } from 'mobx';
import { ToggleField } from 'models/Fields';
import PlayerState from './PlayerState';
import PlayingPlayerState from './PlayingPlayerState';
import PauseVideoPlayerState from './PauseVideoPlayerState';
import PauseImagePlayerState from './PauseImagePlayerState';
import PlayerImage from './PlayerImage';
import PlayerVideo from './PlayerVideo';
import ErrorPlayerState from './ErrorPlayerState';
import { CAMERA_PLAYER_EVENTS, CAMERA_TYPE, PLAYER_ERROR_TYPE } from 'config';
import type RequestMediaCamerasType from '../RequestMedia/RequestMediaCamerasType';
import type RequestMediaTimeSegment from '../RequestMedia/RequestMediaTimeSegment';
import type CameraModel from '../VehicleDetails/CameraModel';
import EventsBus from 'services/EventsBus/eventsBus';
import type { IPlayerVideo } from 'interfaces/models/Dashcams/IPlayerVideo';
import PlayerVideoRestAPI from './PlayerVideoRestAPI';
import BasePlayerState from './BasePlayerState';

class PlayerViewModel {
  @observable public state: PlayerState;
  public isMuted: ToggleField;
  public isAudioEnabled: ToggleField;
  public isLoading: ToggleField;
  public isEndOfSegment: ToggleField;
  public image: PlayerImage;
  public video: IPlayerVideo;
  public timer: number;

  public cameraType: RequestMediaCamerasType;
  public timeSegment: RequestMediaTimeSegment;
  public cameraModel: CameraModel;
  public liveMode: boolean = false;
  private delay: number = 200;

  constructor(
    cameraType: RequestMediaCamerasType,
    timeSegment: RequestMediaTimeSegment,
    cameraModel: CameraModel,
    options = { liveMode: false }
  ) {
    this.cameraType = cameraType;
    this.timeSegment = timeSegment;
    this.cameraModel = cameraModel;
    this.liveMode = options.liveMode;
    this.timer = null;
    this.isMuted = new ToggleField(true);
    this.isAudioEnabled = new ToggleField(!this.isXirgoProvider);
    this.isLoading = new ToggleField(false);
    this.isEndOfSegment = new ToggleField(false);
    this.image = new PlayerImage(this);
    this.video = this.liveMode && !this.isXirgoProvider ? new PlayerVideoRestAPI(this) : new PlayerVideo(this);

    if (!this.liveMode) {
      reaction(
        () => this.cameraType.type.value,
        (type) => {
          if (type) {
            this.onChangeCameraType();
          }
        }
      );

      EventsBus.get().on(CAMERA_PLAYER_EVENTS.PLAY_ONSTART, this.onStart);
    }
  }

  @action public setState = async (state: PlayerState) => {
    if (this.state) {
      await this.state.deactivate();
    }

    this.state = state;
    this.state?.setContext(this);
    this.state?.activate();
  };

  public onStart = () => {
    const timeSegment = this.timeSegment;

    timeSegment.scrubber.set(timeSegment.startTime.value);
    this.isEndOfSegment.toggle(false);
  };

  public onError = (type: PLAYER_ERROR_TYPE) => {
    this.setState(new ErrorPlayerState(type));
  };

  public onBaseState = () => {
    this.setState(new BasePlayerState());
  };

  public play = () => {
    const timeSegment = this.timeSegment;

    if (this.isEndOfSegment.value) {
      timeSegment.startTime.set(timeSegment.startTime.value + timeSegment.duration.active.value);
      this.onStart();
    }

    this.setState(new PlayingPlayerState());
  };

  public pause = () => {
    this.setState(new PauseVideoPlayerState());
  };

  public pauseStatic = () => {
    this.setState(new PauseImagePlayerState());
  };

  public replay = () => {
    this.onStart();
    this.setState(new PlayingPlayerState());
  };

  public stop = () => {
    this.state.deactivate();
    this.state = null;
  };

  public startTimer = () => {
    const timeSegment = this.timeSegment;
    const startTime = timeSegment.startTime.value;

    this.isLoading.toggle(false);

    this.timer = setInterval(() => {
      const total = timeSegment.scrubber.value + this.delay;

      timeSegment.scrubber.set(total);

      if (total >= startTime + timeSegment.duration.active.value) {
        this.isEndOfSegment.toggle(true);
        this.pause();
      }
    }, this.delay);
  };

  public changeCameraTypeOnline = () => {
    this.video.switchCameraOnline();
  };

  private get isXirgoProvider() {
    return this.cameraModel.provider.value === CAMERA_TYPE.XIRGO;
  }

  private onChangeCameraType = () => {
    if (!this.timeSegment.startTime.value) {
      return;
    }

    if (!this.state && this.isXirgoProvider) {
      this.pauseStatic();
      return;
    }

    if (this.state instanceof PlayingPlayerState) {
      this.setState(new PlayingPlayerState());
    } else {
      if (this.isXirgoProvider) {
        this.state?.activate();
      } else {
        this.onBaseState();
      }
    }
  };
}

export default PlayerViewModel;
