import React, { FC } from 'react';
import type FrequencyCellModel from 'models/Maintenances/Representation/FrequencyCell';

import './styles.scss';

interface IProps {
  cell: FrequencyCellModel;
}

const FrequencyCell: FC<IProps> = ({ cell }) => {
  return (
    <div className="FrequencyCell">
      <p className="FrequencyCell-type">{cell.isRepeat ? 'Repeat' : 'One time'}</p>
      {Boolean(cell.isRepeat && cell.text) && <p className="FrequencyCell-text">{cell.text}</p>}
    </div>
  );
};

export default FrequencyCell;
