import React from 'react';
import { observer } from 'mobx-react';

import { AudioOffIcon, AudioOnIcon, InfoIcon } from 'assets';

import MediaManagerPlayerCountDown from '../MediaManagerPlayerCountDown';
import MediaManagerPlayerCameraTypeSwitcher from '../MediaManagerPlayerCameraTypeSwitcher';
import InfoTooltip from 'components/InfoTooltip';
import type RequestMediaCamerasType from 'models/Dashcam/MediaRequestCheckout/RequestMedia/RequestMediaCamerasType';
import type PlayerViewModel from 'models/Dashcam/MediaRequestCheckout/Player/Player';
import PlayingPlayerState from 'models/Dashcam/MediaRequestCheckout/Player/PlayingPlayerState';
import type RequestMediaTimeSegment from 'models/Dashcam/MediaRequestCheckout/RequestMedia/RequestMediaTimeSegment';

import './styles.scss';

interface IProps {
  player: PlayerViewModel;
  cameraType: RequestMediaCamerasType;
  timeSegment: RequestMediaTimeSegment;
}

const MediaManagerPlayerHeader: React.FC<IProps> = ({ player, cameraType, timeSegment }) => {
  const timeLeft = timeSegment.startTime.value + timeSegment.duration.active.value - timeSegment.scrubber.value;

  return (
    <div className="MediaManagerPlayerHeader">
      <div className="MediaManagerPlayerHeader-section MediaManagerPlayerHeader-section--left">
        <div className="MediaManagerPlayerHeader-cameraTypeSwitcher">
          <MediaManagerPlayerCameraTypeSwitcher cameraType={cameraType} isLoading={player.isLoading.value} />
        </div>
        <div className="MediaManagerPlayerHeader-infoNote">
          <div className="MediaManagerPlayerHeader-infoNoteIcon">
            <InfoIcon width={16} height={16} />
          </div>
          <p className="MediaManagerPlayerHeader-infoNoteText">
            Video preview is streaming directly from the camera and may take some time to load or respond
          </p>
        </div>
      </div>
      <div className="MediaManagerPlayerHeader-section MediaManagerPlayerHeader-section--right">
        <div className="MediaManagerPlayerHeader-streamCountDown">
          <MediaManagerPlayerCountDown
            isEnd={player.isEndOfSegment.value}
            replay={player.replay}
            isPlaying={player.state instanceof PlayingPlayerState}
            timeLeft={timeLeft}
          />
        </div>
        <div className="MediaManagerPlayerHeader-audio" onClick={() => player.isMuted.toggle()}>
          {player.isAudioEnabled.value ? (
            player.isMuted.value ? (
              <AudioOffIcon className="MediaManagerPlayerHeader-audioIcon" />
            ) : (
              <AudioOnIcon className="MediaManagerPlayerHeader-audioIcon" />
            )
          ) : (
            <InfoTooltip className="MediaManagerPlayerHeader-audioUnavailable" icon={<AudioOffIcon />}>
              Sound not available for this clip
            </InfoTooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(MediaManagerPlayerHeader);
