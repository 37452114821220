import { computed } from 'mobx';

import type MediaLibrary from '..';
import MediaClipsItem from './MediaClipsItem';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';
import { ListField, SimpleField } from 'models/Fields';
import { MEDIA_CLIP_STATUS, MEDIA_CLIP_TYPE, MEDIA_CLIPS_VIEW } from 'config';
import MediaDelivery from './MediaDelivery';

class MediaClips {
  items: ListField<MediaClipsItem>;
  delivery: MediaDelivery;
  totalPages: SimpleField<number>;
  currentPage: SimpleField<number>;
  repository: IEntityRepository;
  context: MediaLibrary;
  view: MEDIA_CLIPS_VIEW;

  constructor(context, view) {
    this.context = context;
    this.delivery = new MediaDelivery(this);
    this.items = new ListField();
    this.totalPages = new SimpleField(null);
    this.currentPage = new SimpleField(0);
    this.view = view;
    this.repository = repositoryService.get('media', 'v2.0').entity('data');
  }

  fetch = async (reset: boolean = true) => {
    const response = await this.repository.get({ ...this.context.params, page: this.currentPage.value });

    if (reset) {
      this.currentPage.set(0);
      this.items.replace([]);
    }

    if (response) {
      response.items.forEach((item) => this.items.add(new MediaClipsItem(item, this.context)));
      this.totalPages.set(response.totalPages);
    }
  };

  update = (items) => {
    this.items.replace(items.map((item) => new MediaClipsItem(item, this.context)));
  };

  get itemsByDate() {
    const result = {};

    this.items.value.forEach((item) => {
      const date = item.value.date.getLabel(this.view);

      if (!result.hasOwnProperty(date)) {
        result[date] = [];
      }

      result[date].push(item.value);
    });

    return result;
  }

  @computed get typeTotal() {
    return this.items.value.reduce(
      (obj, current) => {
        if (current.value.media.front.type === MEDIA_CLIP_TYPE.VIDEO) {
          obj.video = obj.video += 1;
        }
        if (current.value.media.front.type === MEDIA_CLIP_TYPE.IMAGE) {
          obj.images = obj.images += 1;
        }

        return obj;
      },
      { video: 0, images: 0 }
    );
  }

  @computed get isClipExist() {
    return this.items.value.find((item) => item.value.date.value.toString() === this.context.redirect.timestamp.value);
  }

  @computed get itemsCoordinates() {
    return this.items.value
      .map((item) => {
        const coordinates = item.value.coordinates;
        if (coordinates && item.value.media.front.status.value === MEDIA_CLIP_STATUS.AVAILABLE) {
          return {
            interaction: item.value.interaction,
            ...coordinates,
          };
        }
        return null;
      })
      .filter(Boolean);
  }
}

export default MediaClips;
