import type { CAMERA_JOURNAL_STATE } from 'config';
import { ToggleField } from 'models/Fields';
import type CameraDate from './CameraDate';

class CameraInterval {
  state: CAMERA_JOURNAL_STATE;
  startTimestamp: number;
  endTimestamp: number;
  isSelected: ToggleField;
  context: CameraDate;

  constructor(interval) {
    this.state = interval.state;
    this.startTimestamp = interval.startTimestamp;
    this.endTimestamp = interval.endTimestamp;
    this.isSelected = new ToggleField(false);
  }

  initialize = (context: CameraDate) => {
    this.context = context;
    return this;
  };

  select = () => {
    this.context.reset();
    this.isSelected.toggle(true);
  };
}

export default CameraInterval;
