import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import classnames from 'classnames';
import { some, noop } from 'lodash';

import type { RouterStore, Inspections } from 'stores';
import { validateAccessLevel } from 'stores/acl';
import type { VehiclesAdmin } from 'stores/Admin/Vehicles';
import {
  INSPECTIONS_PERFORMED_TABLE_COLUMNS,
  INSPECTIONS_VIEW,
  INSPECTIONS_STORE,
  ROUTER_STORE,
  STORAGE_ITEMS,
  ACL,
} from 'config';
import { getJSONItemFromStorage, setJSONItemToStorage } from 'utils';

import type InspectionPerformedPDF from 'models/Inspections/Models/InspectionPerformedPDF';
import type PerformedTable from 'models/Inspections/Tables/PerformedTable';

import AssetCellWithPopup from 'components/Table/AssetCellWithPopup';
import Button from 'components/Button';
import CustomTable from 'components/Table/CustomTable';
import InspectionDownloadModal from 'components/Inspections/InspectionDownloadModal';
import InspectionEmailModal from 'components/Inspections/InspectionEmailModal';
import InspectionPerformedPanel from '../InspectionPerformedPanel';
import InspectionPrintModal from 'components/Inspections/InspectionPrintModal';
import InspectionResultsCell from 'components/Inspections/ResultsCell';
import Loader from 'components/Loader';
import TableActions from 'components/Table/Actions';
import TableDownloadModal from 'components/Table/TableDownloadModal';
import TableEmailModal from 'components/Table/TableEmailModal';

import './styles.scss';

interface IProps {
  [INSPECTIONS_STORE]?: Inspections;
  [ROUTER_STORE]?: RouterStore;
  vehiclesAdmin?: VehiclesAdmin;
}

interface IState {
  downloadPdf: InspectionPerformedPDF;
  emailPdf: InspectionPerformedPDF;
  isDownloadModalOpen: boolean;
  isEmailModalOpen: boolean;
  isFilterOpen: boolean;
  isSubmitted: boolean;
  pdfLink: string;
}

@inject(({ inspectionsStore, adminStore: { vehiclesAdmin }, routerStore }) => ({
  inspectionsStore,
  vehiclesAdmin,
  routerStore,
}))
@observer
class InspectionPerformedTable extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      downloadPdf: null,
      emailPdf: null,
      isDownloadModalOpen: false,
      isEmailModalOpen: false,
      isFilterOpen: getJSONItemFromStorage(STORAGE_ITEMS.inspections.performed.filterOpenStatus, true),
      isSubmitted: false,
      pdfLink: null,
    };
  }

  get isSearchApplied() {
    const {
      inspectionsStore: { performed },
    } = this.props;

    return some(performed.table.searchData, (data) => Boolean(data));
  }

  get emptyText() {
    return this.isSearchApplied ? (
      ''
    ) : (
      <div className="InspectionPerformedTable-emptyText">
        <span className="InspectionPerformedTable-emptyText--bold">Performed Inspections</span> will appear here.
        Navigate to
        <span className="InspectionPerformedTable-emptyText--bold">
          {this.isAdminView ? ' Inspections section ' : ''}'Forms Tab'
        </span>{' '}
        and click <span className="InspectionPerformedTable-emptyText--bold">Create New Form</span> to get started.
      </div>
    );
  }

  get isAdminView() {
    return this.props.inspectionsStore.view.value === INSPECTIONS_VIEW.ADMIN;
  }

  get tableHeight() {
    return {
      [INSPECTIONS_VIEW.DEFAULT]: {
        default: 'calc(100vh - 300px)',
        withOpenFilters: 'calc(100vh - 330px)',
      },
      [INSPECTIONS_VIEW.ADMIN]: {
        default: 'calc(100vh - 330px)',
        withOpenFilters: 'calc(100vh - 360px)',
      },
    };
  }

  get editColumnsMap() {
    return {
      [INSPECTIONS_PERFORMED_TABLE_COLUMNS.START_DATE_TIME]: {
        text: 'Start Date/Time',
        isDisabled: true,
      },
      [INSPECTIONS_PERFORMED_TABLE_COLUMNS.VEHICLE]: {
        text: 'Vehicle',
        isDisabled: true,
        isVisible: () => !this.isAdminView,
      },
      [INSPECTIONS_PERFORMED_TABLE_COLUMNS.RESULTS]: {
        text: 'Results',
        isDisabled: false,
      },
      [INSPECTIONS_PERFORMED_TABLE_COLUMNS.GROUPS]: {
        text: 'Groups',
        isDisabled: false,
        isVisible: () => !this.isAdminView,
      },
      [INSPECTIONS_PERFORMED_TABLE_COLUMNS.INSPECTION_FORM]: {
        text: 'Inspection Form',
        isDisabled: false,
      },
      [INSPECTIONS_PERFORMED_TABLE_COLUMNS.COMPLETION_DATE_TIME]: {
        text: 'Completion Date/Time',
        isDisabled: false,
      },
      [INSPECTIONS_PERFORMED_TABLE_COLUMNS.ELAPSED_TIME]: {
        text: 'Elapsed Time',
        isDisabled: false,
      },
      [INSPECTIONS_PERFORMED_TABLE_COLUMNS.INSPECTION_BY]: {
        text: 'Inspection By',
        isDisabled: false,
      },
      [INSPECTIONS_PERFORMED_TABLE_COLUMNS.LOCATION]: {
        text: 'Location',
        isDisabled: false,
      },
      [INSPECTIONS_PERFORMED_TABLE_COLUMNS.ODOMETER_AT_INSPECTION]: {
        text: 'Odometer At Inspection',
        isDisabled: false,
      },
      [INSPECTIONS_PERFORMED_TABLE_COLUMNS.ENGINE_HOURS_AT_INSPECTION]: {
        text: 'Engine Hours At Inspection',
        isDisabled: false,
      },
    };
  }

  get validAccess() {
    return validateAccessLevel([ACL.INSPECTIONS.BASIC.UPDATE]);
  }

  fetch = (params) => {
    const {
      vehiclesAdmin: { selectedVehicle },
      inspectionsStore: { performed },
    } = this.props;

    if (this.isAdminView && selectedVehicle) {
      performed.fetch(params, selectedVehicle.assetId);
    } else {
      performed.fetch(params);
    }
  };

  printInspectionPDF = async (pdf) => {
    this.setState({ isSubmitted: true });
    const link = await pdf.getLink();

    this.setState({ pdfLink: link, isSubmitted: false });
  };

  closePDFPreview = () => {
    this.setState({ pdfLink: null });
  };

  openDownloadModalPdf = (pdf) => {
    this.setState({ downloadPdf: pdf });
  };

  closeDownloadModalPdf = () => {
    this.setState({ downloadPdf: null });
  };

  openEmailModalPdf = (pdf) => {
    this.setState({ emailPdf: pdf });
  };

  closeEmailModalPdf = () => {
    this.setState({ emailPdf: null });
  };

  closeDownloadModal = () => {
    this.setState({ isDownloadModalOpen: false });
  };

  openDownloadModal = () => {
    this.setState({ isDownloadModalOpen: true });
  };

  closeEmailModal = () => {
    this.setState({ isEmailModalOpen: false });
  };

  openEmailModal = () => {
    this.setState({ isEmailModalOpen: true });
  };

  getColumns = () => {
    const {
      inspectionsStore: {
        performed: {
          table: {
            columns,
            sortedColumn: { field, order },
          },
        },
      },
    } = this.props;

    const allColumns = [
      {
        title: 'Start Date/Time',
        dataIndex: INSPECTIONS_PERFORMED_TABLE_COLUMNS.START_DATE_TIME,
        width: 180,
        defaultSortOrder: field === INSPECTIONS_PERFORMED_TABLE_COLUMNS.START_DATE_TIME ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        hiddenSearch: true,
        render: (value, row) =>
          this.validAccess ? (
            <Button className="Button--link" title={value} onClick={() => this.openPanel(row.key)} />
          ) : (
            value
          ),
        fixed: 'left',
      },
      {
        title: 'Vehicle',
        dataIndex: INSPECTIONS_PERFORMED_TABLE_COLUMNS.VEHICLE,
        defaultSortOrder: field === INSPECTIONS_PERFORMED_TABLE_COLUMNS.VEHICLE ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
        hidden: this.isAdminView,
        fixed: 'left',
      },
      {
        title: 'Results',
        dataIndex: INSPECTIONS_PERFORMED_TABLE_COLUMNS.RESULTS,
        hiddenSearch: true,
        width: 180,
        render: (value) => <InspectionResultsCell {...value} />,
      },
      {
        title: 'Groups',
        dataIndex: INSPECTIONS_PERFORMED_TABLE_COLUMNS.GROUPS,
        width: 180,
        hiddenSearch: true,
        render: (groups) => (groups.length ? <AssetCellWithPopup assets={groups} /> : '-'),
        hidden: this.isAdminView,
      },
      {
        title: 'Inspection Form',
        dataIndex: INSPECTIONS_PERFORMED_TABLE_COLUMNS.INSPECTION_FORM,
        defaultSortOrder: field === INSPECTIONS_PERFORMED_TABLE_COLUMNS.INSPECTION_FORM ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Completion Date/Time',
        dataIndex: INSPECTIONS_PERFORMED_TABLE_COLUMNS.COMPLETION_DATE_TIME,
        defaultSortOrder: field === INSPECTIONS_PERFORMED_TABLE_COLUMNS.COMPLETION_DATE_TIME ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        hiddenSearch: true,
        sorter: noop,
        width: 200,
      },
      {
        title: 'Elapsed Time',
        dataIndex: INSPECTIONS_PERFORMED_TABLE_COLUMNS.ELAPSED_TIME,
        defaultSortOrder: field === INSPECTIONS_PERFORMED_TABLE_COLUMNS.ELAPSED_TIME ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        hiddenSearch: true,
        width: 180,
      },
      {
        title: 'Inspection By',
        dataIndex: INSPECTIONS_PERFORMED_TABLE_COLUMNS.INSPECTION_BY,
        defaultSortOrder: field === INSPECTIONS_PERFORMED_TABLE_COLUMNS.INSPECTION_BY ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 190,
      },
      {
        title: 'Location',
        dataIndex: INSPECTIONS_PERFORMED_TABLE_COLUMNS.LOCATION,
        defaultSortOrder: field === INSPECTIONS_PERFORMED_TABLE_COLUMNS.START_DATE_TIME ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Odometer At Inspection',
        dataIndex: INSPECTIONS_PERFORMED_TABLE_COLUMNS.ODOMETER_AT_INSPECTION,
        defaultSortOrder: field === INSPECTIONS_PERFORMED_TABLE_COLUMNS.START_DATE_TIME ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 220,
        hiddenSearch: true,
      },
      {
        title: 'Engine Hours At Inspection',
        dataIndex: INSPECTIONS_PERFORMED_TABLE_COLUMNS.ENGINE_HOURS_AT_INSPECTION,
        defaultSortOrder: field === INSPECTIONS_PERFORMED_TABLE_COLUMNS.START_DATE_TIME ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 220,
        hiddenSearch: true,
      },
    ];

    const filteredColumns = [];

    columns.forEach((column) => {
      if (column.isSelected) {
        filteredColumns.push(allColumns.find((col) => col.dataIndex === column.value));
      }
    });

    return [
      ...filteredColumns.filter((col) => !col.hidden),
      ...(this.validAccess
        ? [
            {
              title: 'Actions',
              dataIndex: 'actions',
              width: 200,
              className: 'ant-table-cell--actions',
              fixed: 'right',
              render: (_, { pdf }) => {
                const items = [
                  {
                    text: 'Print Inspection PDF',
                    onClick: () => this.printInspectionPDF(pdf),
                  },
                  {
                    text: 'Download Inspection PDF',
                    onClick: () => this.openDownloadModalPdf(pdf),
                  },
                  {
                    text: 'Email Inspection PDF',
                    onClick: () => this.openEmailModalPdf(pdf),
                  },
                ];

                return (
                  <div className="InspectionPerformedTable-actions">
                    <TableActions items={items} />
                  </div>
                );
              },
            },
          ]
        : []),
    ];
  };

  handleOpenFilter = (isFilterOpen: boolean) => {
    this.setState({ isFilterOpen });
    setJSONItemToStorage(STORAGE_ITEMS.inspections.performed.filterOpenStatus, isFilterOpen);
  };

  openPanel = (id: number) => {
    const {
      inspectionsStore: { performed },
    } = this.props;
    const selected = performed.findItem(id);

    performed.selected.set(selected.value);
    performed.selected.value.fetch();
  };

  closePanel = () => {
    const {
      inspectionsStore: { performed },
    } = this.props;

    performed.selected.set(null);
  };

  render() {
    const {
      inspectionsStore: {
        performed: {
          table: {
            columns,
            filters,
            pagination,
            searchColumns,
            searchData,
            setColumns,
            setPagination,
            setSearchColumn,
            setSortedColumn,
            sortData,
            source,
            downloadTable,
          },
          repository: { getState },
          total,
          selected,
        },
        view,
      },
      vehiclesAdmin: { selectedVehicleId },
    } = this.props;
    const { downloadPdf, emailPdf, isDownloadModalOpen, isEmailModalOpen, isFilterOpen, isSubmitted, pdfLink } =
      this.state;
    const cn = classnames('InspectionPerformedTable', {
      'InspectionPerformedTable--withOpenFilters': isFilterOpen,
      [`InspectionPerformedTable--${view.value}`]: Boolean(view.value),
    });
    const tableHeight = isFilterOpen
      ? this.tableHeight[view.value].withOpenFilters
      : this.tableHeight[view.value].default;
    const validAccess = this.validAccess;

    return (
      <>
        <div className={cn}>
          <CustomTable
            columns={this.getColumns()}
            dataSource={source}
            editColumnsMap={this.editColumnsMap}
            emptyText={this.emptyText}
            filters={{
              [INSPECTIONS_PERFORMED_TABLE_COLUMNS.RESULTS]: filters.results.list,
            }}
            getData={this.fetch}
            isFilterActive={isFilterOpen}
            loading={getState.loading}
            onColumnSort={setSortedColumn}
            onColumnsChange={setColumns}
            onColumnsSearch={setSearchColumn}
            onFilterOpen={this.handleOpenFilter}
            onPaginationChange={setPagination}
            openDownloadModal={this.openDownloadModal}
            openEmailModal={this.openEmailModal}
            pagination={pagination}
            searchColumns={searchColumns}
            searchParams={searchData}
            selectedColumns={toJS(columns)}
            sortedColumn={sortData}
            tableHeight={tableHeight}
            total={total.value}
            triggerUpdate={false}
            withActions={validAccess}
            withDownload={validAccess}
            withEmail={validAccess}
            key={selectedVehicleId}
          />
        </div>
        <InspectionPerformedPanel isOpen={Boolean(selected.value)} onClose={this.closePanel} />
        {isSubmitted && <Loader color="blue" />}
        <InspectionPrintModal isOpen={Boolean(pdfLink)} link={pdfLink} onClose={this.closePDFPreview} />
        <InspectionDownloadModal isOpen={Boolean(downloadPdf)} onClose={this.closeDownloadModalPdf} pdf={downloadPdf} />
        {Boolean(emailPdf) && (
          <InspectionEmailModal isOpen={Boolean(emailPdf)} onClose={this.closeEmailModalPdf} pdf={emailPdf} />
        )}
        <TableDownloadModal<PerformedTable>
          isOpen={isDownloadModalOpen}
          onClose={this.closeDownloadModal}
          tableDownload={downloadTable}
        />
        <TableEmailModal<PerformedTable>
          isOpen={isEmailModalOpen}
          onClose={this.closeEmailModal}
          tableDownload={downloadTable}
        />
      </>
    );
  }
}

export default InspectionPerformedTable;
