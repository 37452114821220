import React, { FC, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import get from 'lodash/get';
import { Radio } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import { ACL, PEOPLE_ADMIN_ROLES } from 'config';
import { validateAccessLevel } from 'stores';

import Input from 'components/Input';
import LabelValue from 'components/LabelValue';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';

import './styles.scss';

interface IProps {
  className?: string;
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (value: IPersonValues) => Promise<void>;
  initialValues?: IPersonValues;
}

interface IPersonValues {
  name: string;
  loginEmail: string;
  jobTitle: string;
  role: PEOPLE_ADMIN_ROLES;
  welcomeEmail: boolean;
}

const CreatePersonModal: FC<IProps> = ({ isOpen, onCancel, onSubmit, initialValues }) => {
  const name = get(initialValues, 'name', '');
  const jobTitle = get(initialValues, 'jobTitle', '');
  const loginEmail = get(initialValues, 'loginEmail', '');
  const formikRef = React.useRef(null);
  const [role, setRole] = useState<PEOPLE_ADMIN_ROLES>(get(initialValues, 'role', PEOPLE_ADMIN_ROLES.USER));
  const [welcomeEmail, setWelcomeEmail] = useState<boolean>(true);
  const createUserSchema = useMemo(() => {
    const yupEmail = Yup.string().email('Email address is invalid');

    return Yup.object().shape({
      name: Yup.string('Invalid person name').required('Person name is required'),
      loginEmail: role !== PEOPLE_ADMIN_ROLES.DRIVER ? yupEmail.required('Email address is required') : yupEmail,
      jobTitle: Yup.string('Invalid job title').required('Job title is required'),
    });
  }, [role]);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.validateForm();
    }
  }, [createUserSchema]);

  return (
    <Modal closeOutside={false} className="Modal--createUser" isModalOpen={isOpen} onClose={onCancel}>
      <div className="CreatePersonModal">
        <p className="CreatePersonModal-title">Add New Person</p>
        <Formik
          ref={formikRef}
          validationSchema={createUserSchema}
          onSubmit={(values) => onSubmit({ ...values, role, welcomeEmail })}
          initialValues={{ name, loginEmail, jobTitle, role, welcomeEmail }}
          render={({ errors, touched, isValid, isSubmitting }: FormikProps<IPersonValues>) => {
            return (
              <>
                <Form className="CreatePersonModal-form" id="CreatePersonModal-form">
                  <div className="CreatePersonModal-fields">
                    <div className="CreatePersonModal-field">
                      {validateAccessLevel([ACL.MANAGEMENT.DRIVERS.READ]) && (
                        <LabelValue
                          label="Type"
                          value={
                            <Radio.Group
                              value={role}
                              onChange={(e) => setRole(e.target.value)}
                              className="CreatePersonModal-switch"
                            >
                              <Radio.Button value={PEOPLE_ADMIN_ROLES.USER} className="CreatePersonModal-switchButton">
                                User
                              </Radio.Button>
                              <Radio.Button
                                value={PEOPLE_ADMIN_ROLES.DRIVER}
                                className="CreatePersonModal-switchButton"
                              >
                                Driver
                              </Radio.Button>
                              <Radio.Button value={PEOPLE_ADMIN_ROLES.BOTH} className="CreatePersonModal-switchButton">
                                Both
                              </Radio.Button>
                            </Radio.Group>
                          }
                          valueStyle={{ width: '240px' }}
                        />
                      )}
                    </div>
                    <div className="CreatePersonModal-field">
                      <LabelValue
                        label="Name*"
                        value={
                          <Field
                            component={Input}
                            name="name"
                            placeholder="Full Name"
                            error={Boolean(errors.name && touched.name) ? errors.name : ''}
                            maxLength={64}
                          />
                        }
                        valueStyle={{ width: '240px' }}
                      />
                    </div>
                    <div className="CreatePersonModal-field">
                      <LabelValue
                        label="Job Title*"
                        value={
                          <Field
                            component={Input}
                            name="jobTitle"
                            placeholder="Fleet Manager"
                            error={Boolean(errors.jobTitle && touched.jobTitle) ? errors.jobTitle : ''}
                            maxLength={128}
                          />
                        }
                        valueStyle={{ width: '240px' }}
                      />
                    </div>
                    <div className="CreatePersonModal-field">
                      <LabelValue
                        label={`Login Email${role !== PEOPLE_ADMIN_ROLES.DRIVER ? '*' : ''}`}
                        value={
                          <Field
                            component={Input}
                            name="loginEmail"
                            placeholder="email@company.com"
                            error={Boolean(errors.loginEmail && touched.loginEmail) ? errors.loginEmail : ''}
                            maxLength={64}
                          />
                        }
                        valueStyle={{ width: '240px' }}
                      />
                    </div>
                    {role !== PEOPLE_ADMIN_ROLES.DRIVER && (
                      <div className="CreatePersonModal-field">
                        <LabelValue
                          label=""
                          value={
                            <Checkbox
                              label="Send Welcome Email"
                              checked={welcomeEmail}
                              onChange={() => setWelcomeEmail(!welcomeEmail)}
                            />
                          }
                          valueStyle={{ width: '240px' }}
                        />
                      </div>
                    )}
                  </div>
                </Form>
                <div className="CreatePersonModal-buttons">
                  <div className="CreatePersonModal-button CreatePersonModal-button--cancel">
                    <Button className="Button--cancel Button--cancelColorLynch" onClick={onCancel} title="Cancel" />
                  </div>
                  <div className="CreatePersonModal-button CreatePersonModal-button--submit">
                    <Button
                      className="Button--apply"
                      disabled={!isValid}
                      sending={isSubmitting}
                      title="Save"
                      type="submit"
                      form="CreatePersonModal-form"
                    />
                  </div>
                </div>
              </>
            );
          }}
        />
      </div>
    </Modal>
  );
};

export default CreatePersonModal;
