import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Drawer } from 'antd';
import classnames from 'classnames';

import { KEY_TYPE } from 'config';
import { DASHCAM_STATUS } from 'models/Dashcam/DashcamStatus';
import { getTodayEndDate, getTodayStartDate } from 'utils';
import { KeyboardShortcutsManager } from 'tools';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';
import type { DevicesStore, MapStore, TimeStore } from 'stores';
import MapVehiclePanelLiveView from 'components/MapVehiclePanel/MapVehiclePanelLiveView';
import MediaLibraryList from 'components/DashCams/MediaLibrary/MediaLibraryList';
import Modal from 'components/Modal';
import SelectedVehicleMediaPanelHeader from 'components/DashCams/MediaPanelHeader';

import './styles.scss';

interface IProps {
  devicesStore?: DevicesStore;
  timeStore?: TimeStore;
  mapStore?: MapStore;
}

interface IState {
  isLiveViewModalOpen: boolean;
}

@inject(({ devicesMapStore: { devicesStore, mapStore }, timeStore }) => ({
  devicesStore,
  mapStore,
  timeStore,
}))
@observer
class SelectedVehicleMediaPanel extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isLiveViewModalOpen: false,
    };
  }

  componentDidMount() {
    const {
      devicesStore: {
        selectedDevice: { media },
      },
    } = this.props;

    this.initMediaLibrary();
    if (media.dashcamStatus.status.value === DASHCAM_STATUS.UNKNOWN) {
      this.pingCamera();
    }

    KeyboardShortcutsManager.get().add(this.listenEscKeyPress);
    EventsBus.get().on(APP_EVENTS.DEVICES.DEVICE.DETAILS.GET, this.pingCamera);
    EventsBus.get().on(APP_EVENTS.DEVICES.DEVICE.CHANGED, this.initMediaLibrary);
  }

  componentWillUnmount() {
    KeyboardShortcutsManager.get().remove(this.listenEscKeyPress);
    EventsBus.get().off(APP_EVENTS.DEVICES.DEVICE.DETAILS.GET, this.pingCamera);
    EventsBus.get().off(APP_EVENTS.DEVICES.DEVICE.CHANGED, this.initMediaLibrary);
  }

  pingCamera = () => {
    const {
      devicesStore: {
        selectedDevice: { media },
      },
    } = this.props;

    media.fetch(false);
  };

  initMediaLibrary = () => {
    const {
      devicesStore: {
        filters: {
          date: { from, to },
        },
        selectedDevice: { data, media },
      },
      timeStore: { sessionTimezone },
    } = this.props;

    media.library.init({
      assetId: String(data.assetId),
      from: String(from || getTodayStartDate(sessionTimezone).valueOf()),
      to: String(to || getTodayEndDate(sessionTimezone).valueOf()),
    });
  };

  listenEscKeyPress = (e) => {
    if (e.key === KEY_TYPE.ESCAPE) {
      this.closeMediaPanel();
    }
  };

  closeMediaPanel = () => {
    const {
      devicesStore: { toggleMediaPanel, selectedDevice },
    } = this.props;

    toggleMediaPanel(false);
    selectedDevice.media.destroy();
  };

  openLiveViewModal = () => {
    const { mapStore } = this.props;

    mapStore.setLocked();
    this.setState({ isLiveViewModalOpen: true });
  };

  closeLiveViewModal = () => {
    const { mapStore } = this.props;

    mapStore.setUnlocked();
    this.setState({ isLiveViewModalOpen: false });
  };

  render() {
    const {
      devicesStore: {
        showHistory,
        mediaPanel,
        selectedDevice: { media, data },
      },
      mapStore,
    } = this.props;
    const { isLiveViewModalOpen } = this.state;
    const cn = classnames('SelectedVehicleMediaPanel', { 'SelectedVehicleMediaPanel--showHistory': showHistory });

    return (
      <Drawer
        bodyStyle={{ padding: 0 }}
        closable={false}
        mask={false}
        onClose={this.closeMediaPanel}
        placement="right"
        visible={mediaPanel.value}
        width={400}
        zIndex={10}
        style={showHistory ? { top: 50, height: 'calc(100% - 50px)' } : undefined}
      >
        <div className={cn}>
          <div className="SelectedVehicleMediaPanel-header">
            <SelectedVehicleMediaPanelHeader
              assetId={data.assetId.toString()}
              historyMode={showHistory}
              liveSteamAllowed={media.dashcamStatus.isOnline}
              onClose={this.closeMediaPanel}
              onViewLiveStream={this.openLiveViewModal}
            />
          </div>
          <div className="SelectedVehicleMediaPanel-mediaList">
            <MediaLibraryList
              clips={media.library.clips}
              map={mapStore}
              categories={media.library.type.categories}
              cameraProvider={media.cameraModel?.provider.value}
            />
          </div>
          <Modal isModalOpen={isLiveViewModalOpen} onClose={this.closeLiveViewModal}>
            <MapVehiclePanelLiveView media={media} />
          </Modal>
        </div>
      </Drawer>
    );
  }
}

export default SelectedVehicleMediaPanel;
