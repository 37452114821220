export const STYLES = {
  menuPortal: (base) => ({ ...base, zIndex: 9999, left: base.left + window.scrollX }),
  valueContainer: (base) => {
    return {
      ...base,
      padding: '0 8px',
    };
  },
  control: (base, state) => {
    const searchablePadding = state.selectProps.isSearchable ? 20 : 0;
    const paddingLeft = state.isFocused ? searchablePadding : base.marginLeft;

    return {
      ...base,
      borderColor: state.isDisabled ? '#B6C6E6' : '#b6c6e5',
      padding: '2px 0 2px',
      height: '36px',
      minHeight: '36px',
      borderRadius: '4px',
      cursor: 'pointer',
      paddingLeft,
      '&:hover': {
        borderColor: '#b6c6e5',
      },
      boxShadow: 'none',
      backgroundColor: state.isDisabled ? '#eff1f5' : '#fff',
    };
  },
  singleValue: (base, state) => {
    return {
      ...base,
      borderColor: '#b6c6e5',
      color: state.isDisabled ? '#6B7A99' : '#2b2e41',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
      textOverflow: 'ellipsis',
      textTransform: 'none',
      overflow: 'hidden',
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isFocused ? '#EFF1F5' : state.isSelected ? '#FFF' : provided.backgroundColor,
      color: state.isSelected ? 'inherit' : provided.color,
      cursor: 'pointer',
      fontWeight: 600,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: 12,
    };
  },

  placeholder: (base) => {
    return {
      ...base,
      top: 'calc(50%)',
      fontSize: 12,
      fontWeight: 500,
      color: '#929eb8',
    };
  },
};
