import React, { Fragment, useMemo } from 'react';
import Marker from '../Marker';
import markerIcon from './AddPointMarkerIcon';

const AddPointMarker = ({ lat, lng, onClick }) => {
  const icon = useMemo(() => {
    return `data:image/svg+xml;charset=utf-8,${escape(markerIcon())}`;
  }, []);
  return (
    <Fragment>
      <Marker icon={icon} anchor={new google.maps.Point(10, 10)} position={{ lat, lng }} onClick={onClick} />
    </Fragment>
  );
};

export default AddPointMarker;
