import type MaintenanceAccountConfig from '../MaintenanceAccountConfig';
import type MaintenanceModel from '../Models/MaintenanceModel';
import DueInCell from './DueInCell';
import FrequencyCell from './FrequencyCell';
import ApplyTaskCard from './ApplyTaskCard';

class MaintenanceRepresentation {
  dueInCell: DueInCell;
  frequencyCell: FrequencyCell;
  applyTaskCard: ApplyTaskCard;

  constructor(model: MaintenanceModel, accountConfig: MaintenanceAccountConfig) {
    this.dueInCell = new DueInCell({
      asset: model.asset,
      engine: model.engine,
      odometer: model.odometer,
      service: model.service,
      accountConfig,
    });
    this.frequencyCell = new FrequencyCell({
      engineConfig: model.engine.config,
      odometerConfig: model.odometer.config,
      serviceConfig: model.service.config,
    });
    this.applyTaskCard = new ApplyTaskCard(model);
  }
}

export default MaintenanceRepresentation;
