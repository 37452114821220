import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Prompt, RouteComponentProps, withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { stringify } from 'query-string';

import { INSPECTIONS_STORE, INSPECTIONS_VIEW, MAINTENANCE_VIEW, MAINTENANCES_STORE, PATHS, ACL } from 'config';
import type { VehiclesAdmin } from 'stores/Admin/Vehicles';
import type { Inspections, Maintenances as MaintenancesStore, PersistenceStore, UserStore } from 'stores';
import { validateAccessLevel } from 'stores/acl';

import AdminVehicleDetails from './AdminVehicleDetails';
import Button from 'components/Button';
import BackButton from 'components/BackButton';
import ErrorNotification from 'components/ErrorNotification';
import TabsMenu from 'components/TabsMenu';
import VehicleMaintenance from './VehicleMaintenance';
import VehicleSettings from './VehicleSettings';
import SearchableVehicleSelect from 'containers/Select/SearchableVehicleSelect';
import VehicleInspection from './VehicleInspection';

import './styles.scss';

const initialFieldsState = {
  displayName: false,
  maximumSpeed: false,
  description: false,
  equipmentType: false,
  vehicleID: false,
  vehicleMake: false,
  vehicleModel: false,
  vehicleYear: false,
  licensePlate: false,
  licenseExpirationMonth: false,
  licenseExpirationYear: false,
  licenseState: false,
  notes: false,
  maintenanceNotes: false,
  driverId: false,
  groups: { assignedGroupsIds: [], unassignedGroupsIds: [] },
  fuelType: false,
  tankCapacity: false,
};

interface IMatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  className?: string;
  vehiclesAdmin?: VehiclesAdmin;
  persistenceStore?: PersistenceStore;
  userStore?: UserStore;
  initialVehicleData: {
    displayName: string;
    maximumSpeed: number;
    description: string;
    equipmentType: string;
    vehicleID: string;
    vehicleMake: string;
    vehicleModel: string;
    vehicleYear: string;
    licensePlate: string;
    licenseExpirationMonth: string;
    licenseExpirationYear: string;
    licenseState: string;
    notes: string;
    maintenanceNotes: string;
    driverId: string;
    groups: Vehicle.IVehicleMembershipGroup[];
    fuelType: string;
    tankCapacity: string;
  };
  updatedFields: {
    displayName: boolean;
    maximumSpeed: boolean;
    description: boolean;
    equipmentType: boolean;
    vehicleID: boolean;
    vehicleMake: boolean;
    vehicleModel: boolean;
    vehicleYear: boolean;
    licensePlate: boolean;
    licenseExpirationMonth: boolean;
    licenseExpirationYear: boolean;
    licenseState: boolean;
    notes: boolean;
    maintenanceNotes: boolean;
    driverId: boolean;
    groups: {
      assignedGroupsIds: string[];
      unassignedGroupsIds: string[];
    };
    fuelType: string;
    tankCapacity: string;
  };
  isSent?: boolean;
  [MAINTENANCES_STORE]?: MaintenancesStore;
  [INSPECTIONS_STORE]?: Inspections;
}

@inject(({ adminStore: { vehiclesAdmin }, persistenceStore, maintenancesStore, inspectionsStore, userStore }) => ({
  vehiclesAdmin,
  persistenceStore,
  maintenancesStore,
  inspectionsStore,
  userStore,
}))
@observer
class SelectedVehicle extends Component<IProps> {
  state = {
    initialVehicleData: {
      displayName: '',
      maximumSpeed: null,
      description: '',
      equipmentType: '',
      vehicleID: '',
      vehicleMake: '',
      vehicleModel: '',
      vehicleYear: '',
      licensePlate: '',
      licenseExpirationMonth: '',
      licenseExpirationYear: '',
      licenseState: '',
      notes: '',
      maintenanceNotes: '',
      driverId: '',
      groups: [],
      fuelType: '',
      tankCapacity: '',
    },
    updatedFields: initialFieldsState,
    isSent: false,
  };

  onUnload = (e) => {
    if (this.checkVehicleDataChanges()) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  async componentDidMount() {
    const { maintenancesStore, inspectionsStore } = this.props;

    maintenancesStore.view.set(MAINTENANCE_VIEW.ADMIN);
    inspectionsStore.view.set(INSPECTIONS_VIEW.ADMIN);
    await this.setVehicle();
    this.preSelectTab();

    window.addEventListener('beforeunload', this.onUnload);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.vehicleId) {
      this.setVehicle();
    }
  }

  componentWillUnmount() {
    const {
      vehiclesAdmin: { resetSelectedVehicle },
      maintenancesStore,
      inspectionsStore,
    } = this.props;

    maintenancesStore.view.set(MAINTENANCE_VIEW.DEFAULT);
    inspectionsStore.view.set(INSPECTIONS_VIEW.DEFAULT);
    resetSelectedVehicle();
    window.removeEventListener('beforeunload', this.onUnload);
  }

  get vehicleId() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    return id;
  }

  get tabs() {
    return [
      { name: 'Vehicle Details', value: 'vehicle-details' },
      { name: 'Maintenance', value: 'maintenance' },
      ...(validateAccessLevel([ACL.INSPECTIONS.BASIC.READ]) ? [{ name: 'Inspections', value: 'inspections' }] : []),
      { name: 'Settings/Device', value: 'settings-device' },
    ];
  }

  preSelectTab = () => {
    const {
      vehiclesAdmin: { setSelectedVehicleEditTab },
      history,
    } = this.props;

    const params = new URLSearchParams(history.location.search);
    const tabValue = params.get('tab');

    if (tabValue) {
      const index = this.tabs.findIndex(({ value }) => value === tabValue);

      if (index >= 0) {
        setSelectedVehicleEditTab(index);
      }
    }
  };

  setInitialVehicleData = () => {
    const {
      vehiclesAdmin: { selectedVehicle },
    } = this.props;
    const {
      name,
      maximumSpeed,
      description,
      equipmentType,
      vin,
      make,
      model,
      plate,
      licenseExpirationMonth,
      licenseExpirationYear,
      licenseState,
      note,
      groups,
      maintenanceNotes,
      driverId,
      vehicleYear,
      fuelDetails,
    } = selectedVehicle;
    const initialVehicleData = {
      displayName: name,
      maximumSpeed,
      description,
      equipmentType,
      vehicleID: vin,
      vehicleMake: make,
      vehicleModel: model,
      licensePlate: plate,
      licenseExpirationMonth,
      licenseExpirationYear,
      licenseState,
      notes: note,
      maintenanceNotes,
      driverId,
      vehicleYear,
      fuelType: fuelDetails.fuelType.value,
      tankCapacity: fuelDetails.tankCapacity.value,
      groups: groups.slice(),
    };
    this.setState({ initialVehicleData });
  };

  setInitialUpdatedFields = () => this.setState({ updatedFields: initialFieldsState });

  setVehicle = async () => {
    const {
      vehiclesAdmin,
      vehiclesAdmin: { setSelectedVehicle },
      persistenceStore: { updateSelectedVehicle },
      history: { push },
    } = this.props;

    try {
      await setSelectedVehicle(this.vehicleId);
      this.setInitialVehicleData();
      this.setInitialUpdatedFields();

      const selectedVehicle = vehiclesAdmin.selectedVehicle;
      if (selectedVehicle) {
        updateSelectedVehicle({ value: selectedVehicle.id, label: selectedVehicle.name });
      }
    } catch (e) {
      push({ pathname: PATHS.NOT_FOUND, state: { from: PATHS.ADMIN.TABLES.VEHICLES.INDEX } });
    }
  };

  setSuccessSend = () => {
    this.setState({ isSent: true });
    setTimeout(() => {
      this.setState({ isSent: false });
    }, 2000);
  };

  handleGroupsChange = () => {
    this.setState({
      updatedFields: {
        ...this.state.updatedFields,
        groups: this.getAssignedAndUnassignedGroups(),
      },
    });
  };

  getAssignedAndUnassignedGroups = () => {
    const {
      initialVehicleData: { groups },
    } = this.state;
    const {
      vehiclesAdmin: { selectedVehicle },
    } = this.props;
    const initialGroupVehiclesIds = groups.map((group) => group.groupId);
    const selectedGroupVehiclesIds = selectedVehicle.groups.map((group) => group.groupId);
    const assignedGroupsIds = selectedGroupVehiclesIds.filter(
      (selectedVehicleId) => !initialGroupVehiclesIds.includes(selectedVehicleId)
    );
    const unassignedGroupsIds = initialGroupVehiclesIds.filter(
      (initialVehicleId) => !selectedGroupVehiclesIds.includes(initialVehicleId)
    );

    return {
      assignedGroupsIds,
      unassignedGroupsIds,
    };
  };

  resetInitialGroups = () => {
    const {
      vehiclesAdmin: {
        selectedVehicle: { assignGroup, unassignGroup },
      },
    } = this.props;
    const {
      initialVehicleData: { groups },
      updatedFields: {
        groups: { assignedGroupsIds },
      },
    } = this.state;

    const initialGroupVehiclesIds = groups.map((group) => group.groupId);
    /* assign back removed groups */
    groups.forEach((group) => {
      assignGroup({ value: group.groupId, label: group.description, badge: group.displayName });
    });
    /* unassign back added groups */
    assignedGroupsIds.forEach((id) => {
      if (!initialGroupVehiclesIds.includes(id)) {
        unassignGroup(id);
      }
    });
  };

  handleVehicleDataChange = (field: { name: string; value: string | number | boolean }) => {
    const { initialVehicleData } = this.state;
    const initialFieldValue = initialVehicleData[field.name];

    if (field.name === 'groups') {
      this.handleGroupsChange();
      return;
    }

    if (initialFieldValue !== field.value) {
      this.setState({
        updatedFields: {
          ...this.state.updatedFields,
          [field.name]: true,
        },
      });
    } else {
      this.setState({
        updatedFields: {
          ...this.state.updatedFields,
          [field.name]: false,
        },
      });
    }
  };

  checkVehicleDataChanges = (checkSingleExpiration?: boolean) => {
    const { updatedFields } = this.state;
    const {
      vehiclesAdmin: { selectedVehicle },
    } = this.props;
    const licenseExpirationValue = get(selectedVehicle, 'licenseExpirationValue', null);
    const displayName = get(selectedVehicle, 'name', '');

    return Object.keys(updatedFields).some((field) => {
      if (field === 'displayName' && updatedFields[field]) {
        return /\S+/.test(displayName) || !displayName.trim().length;
      }
      if (field === 'groups') {
        return updatedFields[field].assignedGroupsIds.length || updatedFields[field].unassignedGroupsIds.length;
      }
      if (field === 'licenseExpirationMonth' || field === 'licenseExpirationYear') {
        return checkSingleExpiration || licenseExpirationValue
          ? updatedFields.licenseExpirationMonth || updatedFields.licenseExpirationYear
          : updatedFields.licenseExpirationMonth && updatedFields.licenseExpirationYear;
      }
      return updatedFields[field];
    });
  };

  findUpdatedFields = () => {
    const { updatedFields } = this.state;

    return Object.keys(updatedFields).filter((field) => Boolean(updatedFields[field]));
  };

  getAdjustedUpdatedFields = (fields: string[]) => {
    if (fields.includes('licenseExpirationMonth') || fields.includes('licenseExpirationYear')) {
      const monthIndex = fields.indexOf('licenseExpirationMonth');
      fields.splice(monthIndex, 1);

      const yearIndex = fields.indexOf('licenseExpirationYear');
      fields.splice(yearIndex, 1);

      fields.push('licenseExpiration');
    }

    if (fields.includes('groups')) {
      const groupsIndex = fields.indexOf('groups');

      fields.splice(groupsIndex, 1);
    }

    return fields;
  };

  handleClickOnCancel = () => {
    const {
      vehiclesAdmin: {
        selectedVehicle: {
          updateName,
          updateMaximumSpeed,
          updateDescription,
          updateEquipmentType,
          updateVIN,
          updateMake,
          updateModel,
          updatePlate,
          updateLicenseExpirationMonth,
          updateLicenseExpirationYear,
          updateVehicleNote,
          updateMaintenanceNotes,
          updateDriverId,
          updateVehicleYear,
          updateLicenseState,
          fuelDetails,
        },
      },
    } = this.props;
    const {
      initialVehicleData: {
        displayName,
        maximumSpeed,
        description,
        equipmentType,
        vehicleID,
        vehicleMake,
        vehicleModel,
        licensePlate,
        licenseExpirationMonth,
        licenseExpirationYear,
        notes,
        maintenanceNotes,
        driverId,
        vehicleYear,
        licenseState,
      },
    } = this.state;

    updateName(displayName);
    updateMaximumSpeed(maximumSpeed);
    updateDescription(description);
    updateEquipmentType(equipmentType);
    updateVIN(vehicleID);
    updateMake(vehicleMake);
    updateModel(vehicleModel);
    updatePlate(licensePlate);
    updateLicenseExpirationMonth(licenseExpirationMonth);
    updateLicenseExpirationYear(licenseExpirationYear);
    updateVehicleNote(notes);
    updateMaintenanceNotes(maintenanceNotes);
    updateDriverId(driverId);
    updateVehicleYear(vehicleYear);
    updateLicenseState(licenseState);

    fuelDetails.fuelType.reset();
    fuelDetails.tankCapacity.reset();

    this.setInitialUpdatedFields();
    this.resetInitialGroups();
    this.handleClickOnCloseError();
  };

  handleClickOnSave = async () => {
    const fieldsToUpdate = this.findUpdatedFields();
    const adjustedFields = this.getAdjustedUpdatedFields(fieldsToUpdate.slice());

    const {
      updatedFields: {
        groups: { assignedGroupsIds, unassignedGroupsIds },
        driverId,
      },
    } = this.state;

    const {
      vehiclesAdmin: {
        selectedVehicle: { saveFields, saveAssignedVehiclesToGroup, saveUnassignedVehiclesToGroup },
      },
      userStore: { getUserData },
    } = this.props;

    try {
      if (adjustedFields.length) {
        await saveFields(adjustedFields);
      }
      if (unassignedGroupsIds.length) {
        await saveUnassignedVehiclesToGroup(unassignedGroupsIds);
      }
      if (assignedGroupsIds.length) {
        await saveAssignedVehiclesToGroup(assignedGroupsIds);
      }
      if (driverId) {
        getUserData();
      }
      this.setSuccessSend();
      this.setInitialVehicleData();
      this.setInitialUpdatedFields();
    } catch (e) {
      return;
    }
  };

  handleClickOnCloseError = () => {
    const {
      vehiclesAdmin: {
        selectedVehicle: { saveFieldsRequestStatus, repositoryGroupsAssignDevice, repositoryGroupsUnAssignDevice },
      },
    } = this.props;

    saveFieldsRequestStatus.setError(null);
    repositoryGroupsAssignDevice.putState.setError(null);
    repositoryGroupsUnAssignDevice.putState.setError(null);
  };

  handleVehicleChange = ({ value }) => {
    const { push } = this.props.history;
    push(PATHS.ADMIN.TABLES.VEHICLES.VEHICLE.replace(':id', value));
  };

  changeTab = (index) => {
    const {
      vehiclesAdmin: { setSelectedVehicleEditTab },
      history,
    } = this.props;

    setSelectedVehicleEditTab(index);

    history.push({ search: stringify({ tab: this.tabs[index].value }) });
  };

  get tabsPanel() {
    const panels = [
      <AdminVehicleDetails onChange={this.handleVehicleDataChange} key="0" />,
      <VehicleMaintenance onChange={this.handleVehicleDataChange} key="1" />,
    ];

    if (validateAccessLevel([ACL.INSPECTIONS.BASIC.READ])) {
      panels.push(<VehicleInspection key="2" />);
    }

    panels.push(<VehicleSettings {...this.props} key="3" />);

    return panels;
  }

  render() {
    const { isSent } = this.state;
    const {
      vehiclesAdmin: { selectedVehicle, selectedVehicleEditTab },
    } = this.props;
    const isError =
      selectedVehicle &&
      (selectedVehicle.saveFieldsRequestStatus.error ||
        selectedVehicle.repositoryGroupsAssignDevice.putState.error ||
        selectedVehicle.repositoryGroupsUnAssignDevice.putState.error);
    const isSending =
      selectedVehicle &&
      (selectedVehicle.saveFieldsRequestStatus.loading ||
        selectedVehicle.repositoryGroupsAssignDevice.putState.loading ||
        selectedVehicle.repositoryGroupsUnAssignDevice.putState.loading);
    const disabled = !selectedVehicle?.name.trim().length || !this.checkVehicleDataChanges() || isSending;
    const cancelDisabled = !this.checkVehicleDataChanges(true) || isSending;

    return (
      Boolean(selectedVehicle) && (
        <div className="SelectedVehicle">
          <div className="SelectedVehicle-container">
            <div className="SelectedVehicle-header">
              <div className="SelectedVehicle-backButton">
                <BackButton link={PATHS.ADMIN.TABLES.INDEX}>Back to Vehicle List</BackButton>
              </div>
              <div className="SelectedVehicle-selectVehicle">
                <span className="SelectedVehicle-selectVehicleCopy">View/Edit Vehicle</span>
                <div className="SelectedVehicle-selectVehicleDropdown">
                  <SearchableVehicleSelect
                    value={{ label: selectedVehicle.name, value: this.vehicleId }}
                    handleChange={this.handleVehicleChange}
                    alignRight
                  />
                </div>
              </div>
            </div>
            <div className="SelectedVehicle-content">
              {isError && (
                <div className="SelectedVehicle-errorNotification">
                  <ErrorNotification name={selectedVehicle.name} onClose={this.handleClickOnCloseError} />
                </div>
              )}
              <div className="SelectedVehicle-changesButtons">
                <div className="SelectedVehicle-changeButton SelectedVehicle-changeButton--cancel">
                  <Button
                    className="Button--cancel Button--cancelColorLynch"
                    disabled={cancelDisabled}
                    inline
                    onClick={this.handleClickOnCancel}
                    title="Cancel"
                  />
                </div>
                <div className="SelectedVehicle-changeButton SelectedVehicle-changeButton--save">
                  <Button
                    className="Button--apply"
                    disabled={disabled}
                    inline
                    onClick={this.handleClickOnSave}
                    title="Save"
                    sending={isSending}
                    sent={isSent}
                  />
                </div>
              </div>
              <TabsMenu
                className="SelectedVehicle-tabsMenu"
                selectedTab={selectedVehicleEditTab}
                onSelect={this.changeTab}
                tabs={this.tabs}
              >
                {this.tabsPanel}
              </TabsMenu>
            </div>
          </div>
          <Prompt when={this.checkVehicleDataChanges()} message="Changes that you made may not be saved." />
        </div>
      )
    );
  }
}

export default withRouter(SelectedVehicle);
