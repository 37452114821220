import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Observer } from 'mobx-react';

import Input from 'components/Input';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  value?: string;
  error?: string;
  className?: string;
  placeholder?: string;
  isFocus?: boolean;
  hitNext: boolean;
  isEmpty: boolean;
  isValid: boolean;
  onChange: (value: string) => void;
  onError?: (value: boolean) => void;
  validate: (value: string) => boolean;
  getValidValue?: (value: string) => string;
  formatValue?: (value: string) => string;
  cancelHitNextNotify: () => void;
  hitNextErrorEmpty: string;
  isAllItemsEmpty: boolean;
  characterLimitLeft: number;
}

const RecipientInput: React.FC<IProps> = ({
  value,
  onChange = () => true,
  onError = () => true,
  error = '',
  placeholder = '',
  isFocus = true,
  hitNext,
  isEmpty,
  isValid,
  className,
  validate,
  getValidValue,
  formatValue,
  cancelHitNextNotify,
  hitNextErrorEmpty,
  isAllItemsEmpty,
  characterLimitLeft,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [isError, setIsError] = useState(false);
  const [isShowError, setIsShowError] = useState(false);

  const handleChange = useCallback(
    (e) => {
      let inputLocalValue = e.currentTarget.value;
      const addCharacter = inputLocalValue.length > inputValue.length;
      const diffValue = addCharacter ? inputLocalValue.length - inputValue.length : inputLocalValue;

      if (!characterLimitLeft && addCharacter) {
        return;
      }

      if (characterLimitLeft < diffValue) {
        inputLocalValue = inputLocalValue.substr(0, characterLimitLeft);
      }

      if (getValidValue) {
        let validValue = getValidValue(inputLocalValue);
        const isRemoving = inputLocalValue.length < value.length;
        const isSymbolNotNumber = /[^0-9]/gi.test(value[value.length - 1]);

        if (isRemoving && isSymbolNotNumber) {
          validValue = validValue.substr(0, validValue.length - 1);
        }

        const formattedValue = formatValue ? formatValue(validValue) : validValue;

        setInputValue(formattedValue);
        onChange(formattedValue);
      } else {
        setInputValue(inputLocalValue);
        onChange(inputLocalValue);
      }
    },
    [onChange, getValidValue, formatValue, characterLimitLeft]
  );

  const handleBlur = useCallback(() => {
    if (!inputValue || validate(inputValue)) {
      setIsError(false);
    } else {
      setIsError(true);
    }
  }, [inputValue, validate]);

  const handleFocus = useCallback(() => {
    setIsError(false);
    cancelHitNextNotify();
  }, [cancelHitNextNotify]);

  const errorMessage = useMemo(() => {
    return hitNext && isAllItemsEmpty ? hitNextErrorEmpty : error;
  }, [hitNext, isEmpty, isValid, isError, error]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const isShow = (hitNext && (isEmpty || !isValid)) || isError;
      setIsShowError(isShow);
    }, 333);

    return () => clearTimeout(timeout);
  }, [hitNext, isEmpty, isValid, isError]);

  useEffect(() => {
    if (!inputValue || validate(inputValue)) {
      setIsError(false);
    } else {
      setIsError(true);
    }
  }, []);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (isError) {
      onError(true);
    } else {
      onError(false);
    }
  }, [onError, isError]);

  const cn = classNames('RecipientInput', className);
  const inputCn = classNames('RecipientInput-input', {
    'RecipientInput-input--error': isShowError,
    'RecipientInput-input--empty': isEmpty,
  });

  return (
    <Observer
      render={() => (
        <div className={cn}>
          <Input
            isFocus={isFocus}
            value={inputValue}
            placeholder={placeholder}
            onChange={handleChange}
            onFocus={handleFocus}
            className={inputCn}
            onBlur={handleBlur}
          />
          {isShowError && <p className="RecipientInput-error">{errorMessage}</p>}
        </div>
      )}
    />
  );
};

export default RecipientInput;
