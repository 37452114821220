import { KM_TO_MI } from 'config';
import GPSItem from './GPSItem';

class GPS {
  items: GPSItem[];

  constructor(items = []) {
    this.set(items);
  }

  set = (items = []) => {
    this.items = items.map((item) => new GPSItem(item));
  };

  get route() {
    return this.items.map((item) => ({ lat: item.latitude, lng: item.longitude }));
  }

  get chart() {
    return this.items.map(({ speed }) => Math.trunc(speed * KM_TO_MI));
  }

  get start() {
    return { lat: this.items[0]?.latitude, lng: this.items[0]?.longitude };
  }

  get isAvailable() {
    return Boolean(this.items[0]?.latitude);
  }

  getCoordinate = (index: number) => {
    const i = index < this.items.length ? index : this.items.length - 1;

    return { lat: this.items[i]?.latitude, lng: this.items[i]?.longitude };
  };
}

export default GPS;
