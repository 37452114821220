import { computed } from 'mobx';
import debounce from 'lodash/debounce';
import TableBase from 'stores/TableBase';
import type { IEditColumns } from 'models/Tables/IEditColumns';
import type { ISortColumn } from 'models/Tables/ISortColumn';
import {
  MAINTENANCE_SEARCH_PARAMS,
  MAINTENANCE_COMPLETED_TABLE_COLUMNS,
  STORAGE_ITEMS,
  MAINTENANCE_VIEW,
} from 'config';
import type { Maintenances } from 'stores';
import type MaintenanceCompleteRecordModel from '../Models/MaintenanceCompleteRecordModel';
import MaintenanceCompletedDownload from '../Download/CompletedDownload';
import { repositoryService } from 'services';
import MaintenanceFilters from './Filters';
import type IEntityRepository from '../../../interfaces/services/RepositoryService/IEntityRepository';
import { SimpleField } from 'models/Fields';

const getInitialColumns = (view: MAINTENANCE_VIEW): Array<IEditColumns<MAINTENANCE_COMPLETED_TABLE_COLUMNS>> => {
  switch (view) {
    case MAINTENANCE_VIEW.ADMIN:
      return [
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.TASK_NAME, isSelected: true },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.SERVICED_ON, isSelected: true },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.ODO_AT_SERVICE, isSelected: true },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.ENG_HOURS_AT_SERVICE, isSelected: true },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.FREQUENCY, isSelected: true },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.SERVICE_TYPE, isSelected: true },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.TOTAL_COST, isSelected: true },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.MARK_AS_COMPLETED_ON, isSelected: false },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.MARK_AS_COMPLETED_BY, isSelected: false },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.MODIFIED_ON, isSelected: false },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.MODIFIED_BY, isSelected: false },
      ];
    case MAINTENANCE_VIEW.DEFAULT:
    default:
      return [
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.TASK_NAME, isSelected: true },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.VEHICLE_NAME, isSelected: true },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.SERVICED_ON, isSelected: true },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.ODO_AT_SERVICE, isSelected: true },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.ENG_HOURS_AT_SERVICE, isSelected: true },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.FREQUENCY, isSelected: true },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.SERVICE_TYPE, isSelected: true },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.TOTAL_COST, isSelected: true },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.MARK_AS_COMPLETED_ON, isSelected: false },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.MARK_AS_COMPLETED_BY, isSelected: false },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.MODIFIED_ON, isSelected: false },
        { value: MAINTENANCE_COMPLETED_TABLE_COLUMNS.MODIFIED_BY, isSelected: false },
      ];
  }
};

const initialSortedColumn: ISortColumn = {
  field: MAINTENANCE_COMPLETED_TABLE_COLUMNS.SERVICED_ON,
  order: 'descend',
};

const storageNames = {
  [MAINTENANCE_VIEW.DEFAULT]: {
    columns: STORAGE_ITEMS.maintenance.completed.columns,
    sortedColumn: STORAGE_ITEMS.maintenance.completed.sortedColumn,
    pagination: STORAGE_ITEMS.maintenance.completed.pagination,
    searchInColumn: STORAGE_ITEMS.maintenance.completed.searchInColumn,
  },
  [MAINTENANCE_VIEW.ADMIN]: {
    columns: STORAGE_ITEMS.admin.maintenance.completed.columns,
    sortedColumn: STORAGE_ITEMS.admin.maintenance.completed.sortedColumn,
    pagination: STORAGE_ITEMS.admin.maintenance.completed.pagination,
    searchInColumn: STORAGE_ITEMS.admin.maintenance.completed.searchInColumn,
  },
};

const maintenanceSearchMap = {
  [MAINTENANCE_COMPLETED_TABLE_COLUMNS.TASK_NAME]: MAINTENANCE_SEARCH_PARAMS.HISTORY_NAME,
  [MAINTENANCE_COMPLETED_TABLE_COLUMNS.VEHICLE_NAME]: MAINTENANCE_SEARCH_PARAMS.ASSET_NAME,
  [MAINTENANCE_COMPLETED_TABLE_COLUMNS.SERVICED_ON]: MAINTENANCE_SEARCH_PARAMS.LAST_HISTORY_SERVICE_TIME,
  [MAINTENANCE_COMPLETED_TABLE_COLUMNS.ODO_AT_SERVICE]: MAINTENANCE_SEARCH_PARAMS.LAST_PERFORMED_ODOMETER,
  [MAINTENANCE_COMPLETED_TABLE_COLUMNS.ENG_HOURS_AT_SERVICE]: MAINTENANCE_SEARCH_PARAMS.LAST_PERFORMED_ENGINE_HOURS,
  [MAINTENANCE_COMPLETED_TABLE_COLUMNS.FREQUENCY]: MAINTENANCE_SEARCH_PARAMS.FREQUENCY,
  [MAINTENANCE_COMPLETED_TABLE_COLUMNS.SERVICE_TYPE]: MAINTENANCE_SEARCH_PARAMS.SERVICE_TYPE_NAME,
  [MAINTENANCE_COMPLETED_TABLE_COLUMNS.TOTAL_COST]: MAINTENANCE_SEARCH_PARAMS.LAST_HISTORY_TOTAL_COST,
  [MAINTENANCE_COMPLETED_TABLE_COLUMNS.MARK_AS_COMPLETED_ON]: MAINTENANCE_SEARCH_PARAMS.LAST_HISTORY_CREATED,
  [MAINTENANCE_COMPLETED_TABLE_COLUMNS.MARK_AS_COMPLETED_BY]: MAINTENANCE_SEARCH_PARAMS.LAST_HISTORY_CREATED_BY,
  [MAINTENANCE_COMPLETED_TABLE_COLUMNS.MODIFIED_ON]: MAINTENANCE_SEARCH_PARAMS.LAST_HISTORY_UPDATED,
  [MAINTENANCE_COMPLETED_TABLE_COLUMNS.MODIFIED_BY]: MAINTENANCE_SEARCH_PARAMS.LAST_HISTORY_UPDATED_BY,
};

export default class MaintenanceCompletedTable extends TableBase<IEditColumns<MAINTENANCE_COMPLETED_TABLE_COLUMNS>> {
  context: Maintenances;
  filters: MaintenanceFilters;
  download: MaintenanceCompletedDownload;
  totalCost: SimpleField<number>;
  repositoryTotalCost: IEntityRepository;
  debouncedFetchTotalCost: (params: { [key: string]: string }) => void;

  constructor(context: Maintenances) {
    super({
      columns: getInitialColumns(context.view.value),
      sortedColumn: initialSortedColumn,
      storageNames: storageNames[context.view.value],
    });

    this.context = context;
    this.filters = new MaintenanceFilters({ frequency: this.frequencyInitialValues });
    this.download = new MaintenanceCompletedDownload(
      repositoryService.get('notifications').entity('email').entity('document')
    ).initialize(this);
    this.totalCost = new SimpleField(null);
    this.repositoryTotalCost = this.context.repositoryHistory.entity('total-cost');
    this.debouncedFetchTotalCost = debounce(this.fetchTotalCost, 500);
  }

  private get frequencyInitialValues() {
    return (
      this.selectedSearchColumns.find((col) => col.column === MAINTENANCE_COMPLETED_TABLE_COLUMNS.FREQUENCY)?.value ||
      ''
    );
  }

  private getHistoryRecordSource = (
    { info, values, serviceType, loggerInfo, costs }: MaintenanceCompleteRecordModel,
    params
  ) => {
    return {
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.TASK_NAME]: info.taskName.value || '-',
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.VEHICLE_NAME]: info.vehicleName || '-',
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.SERVICED_ON]: values.serviceTime.value,
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.ODO_AT_SERVICE]:
        `${values.odometer.value?.toLocaleString('en-US', { maximumFractionDigits: 0 })} mi` || '-',
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.ENG_HOURS_AT_SERVICE]:
        `${values.engHours.value?.toLocaleString('en-US', { maximumFractionDigits: 1 })} hrs` || '-',
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.FREQUENCY]: params.frequencyCell,
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.SERVICE_TYPE]: serviceType.name || '-',
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.TOTAL_COST]: costs.total.value,
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.MARK_AS_COMPLETED_ON]: loggerInfo.creationTime,
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.MARK_AS_COMPLETED_BY]: loggerInfo.createdBy || '-',
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.MODIFIED_ON]: loggerInfo.lastUpdateTime,
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.MODIFIED_BY]: loggerInfo.updatedBy || '-',
      id: params.id,
      key: params.id,
      cpNumber: params.cpNumber,
      className: params.className,
    };
  };

  @computed get source() {
    return this.context.tasks
      .toArray()
      .filter((task) => task.model.historyRecords.length)
      .map(({ model: { asset, historyRecords }, representation: { frequencyCell } }, index) => {
        const firstRecord = historyRecords[0];
        const restRecords = historyRecords.slice(1);
        const evenOddRowClassName = index % 2 === 0 ? 'ant-table-row--even' : 'ant-table-row--odd';
        const parentClassName = restRecords.length ? 'ant-table-row--parent' : '';

        return {
          ...this.getHistoryRecordSource(firstRecord, {
            cpNumber: asset.cpNumber.value,
            id: firstRecord.id,
            frequencyCell,
            className: `${evenOddRowClassName} ${parentClassName}`.trim(),
          }),
          children: restRecords.length
            ? restRecords.map((record) => {
                return {
                  ...this.getHistoryRecordSource(record, {
                    cpNumber: asset.cpNumber.value,
                    id: record.id,
                    frequencyCell,
                    className: `${evenOddRowClassName} ant-table-row--child`,
                  }),
                };
              })
            : undefined,
        };
      });
  }

  @computed get searchData() {
    return this.selectedSearchColumns.reduce((acc, cur) => {
      return {
        ...acc,
        ...(cur.value && { [maintenanceSearchMap[cur.column]]: cur.value }),
      };
    }, {});
  }

  @computed get sortData() {
    return {
      sortBy: maintenanceSearchMap[this.sortedColumn.field],
      order: this.sortedColumn.order?.replace('end', '').toUpperCase(),
    };
  }

  fetchTotalCost = async (params: { [key: string]: string }) => {
    try {
      const totalCost = await this.repositoryTotalCost.get(params);
      this.totalCost.set(totalCost);
    } catch (e) {
      return e;
    }
  };
}
