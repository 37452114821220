import React from 'react';
import { observer } from 'mobx-react';

import type AlertIntervalModel from 'models/Alerts/AlertInterval';
import type { ToggleField } from 'models/Fields';

import Checkbox from 'components/Checkbox';
import InfoTooltip from 'components/InfoTooltip';
import AlertPreferencesOptionItemFabric from '../AlertPreferencesOptionItemFabric';

import './styles.scss';

interface IProps {
  title?: string;
  description?: string;
  interval: AlertIntervalModel;
  option: ToggleField;
  fields: Alerts.Modal.Options.Type[];
}

const AlertPreferencesOptions: React.FC<IProps> = ({
  title = 'Options',
  description,
  interval,
  option,
  fields = [],
}) => {
  const handleCheckboxChange = () => {
    option.toggle();
  };

  return (
    <div className="AlertPreference-line">
      <div className="AlertPreference-label AlertPreferencesOptions-label">{title}</div>
      <div className="AlertPreference-value">
        <div className="AlertPreferencesOptions-title">
          <div className="AlertPreferencesOptions-info">
            <Checkbox
              label="After alerting, disable this alert for"
              checked={option.value}
              onChange={handleCheckboxChange}
            />
          </div>
          <div>{description && <InfoTooltip>{description}</InfoTooltip>}</div>
        </div>
        <div className="AlertPreferencesOptions-counter">
          {fields.map((type) => (
            <AlertPreferencesOptionItemFabric key={type} type={type} disabled={!option.value} field={interval[type]} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(AlertPreferencesOptions);
