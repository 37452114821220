import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { CaretDownOutlined } from '@ant-design/icons';
import Popup from 'components/Popup';

import ReportOptionSearchList from './ReportOptionSearchList';

import './styles.scss';

interface IProps {
  options: Report.IServerOption[];
  value: string;
  skipInitialSet?: boolean;

  onSelect?(value: [string, string]): void;
  onRedirect?(value: string): void;
}

interface IState {
  isOpen: boolean;
  searchValue: string;
}

@observer
class ReportOptionSelector extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      searchValue: '',
    };
  }

  handleOpen = () => this.setState({ isOpen: true });

  handleClose = () => this.setState({ isOpen: false });

  handleSearchChange = (searchValue: string) => this.setState({ searchValue });

  handleOptionChange = (_selectedOption: Report.IServerOption) => {
    this.handleClose();
    this.selectOption(_selectedOption);
  };

  selectOption = (_selectedOption: Report.IServerOption) => {
    if (_selectedOption.report) {
      if (this.props.onRedirect) {
        this.props.onRedirect(_selectedOption.report.reportId);
      }
    } else {
      if (this.props.onSelect) {
        this.props.onSelect([_selectedOption.name, _selectedOption.displayName]);
      }
    }
  };

  setFirstOptionAsSelected = () => {
    const [first] = this.props.options;
    const selected = this.props.options.find((item) => item.name && item.name === this.props.value);
    this.selectOption(selected || first);
  };

  handlePopupClose = () => {
    this.handleClose();
  };

  get filteredList() {
    const { searchValue } = this.state;
    return this.props.options.filter(({ displayName, name }) =>
      (displayName || name || '').toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  renderTrigger = (selectedOptionName: string) => {
    const name = this.props.options.find(
      (item) => item.name === selectedOptionName || (item.displayName && item.displayName === selectedOptionName)
    );

    return (
      <div className="ReportOptionSelector-trigger">
        <div className="ReportOptionSelector-optionTrigger">
          <span className="ReportOptionSelector-optionName">{name ? name.displayName : ''}</span>
          <div className="ReportOptionSelector-optionArrow">
            <CaretDownOutlined className="ReportOptionSelector-optionArrowImg" />
          </div>
        </div>
      </div>
    );
  };

  componentDidMount() {
    if (this.props.skipInitialSet) {
      return;
    }
    this.setFirstOptionAsSelected();
  }

  render() {
    const { isOpen } = this.state;
    const { value } = this.props;

    return (
      <div className="ReportOptionSelector ReportOptionSelector--inline ReportOptionSelector--rightAligned">
        <Popup
          className="ReportOptionSelector-popup"
          onOpen={this.handleOpen}
          isOpen={isOpen}
          triggerElement={this.renderTrigger(value)}
        >
          <div className="ReportOptionSelector-popupContent">
            <div className="ReportOptionSelector-list">
              <ReportOptionSearchList
                list={this.filteredList}
                onChange={this.handleOptionChange}
                selectedItemName={''}
              />
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

export default ReportOptionSelector;
