import React, { FC } from 'react';
import { Observer } from 'mobx-react';
import classnames from 'classnames';

import type Asset from 'models/Assets/Asset';
import type SimpleField from 'models/Fields/SimpleField';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';

import { Search2Icon, LoaderIcon } from 'assets';
import './styles.scss';

interface IProps {
  assets: Asset[];
  disabled?: boolean;
  withSearch?: boolean;
  search?: SimpleField<string>;
  loading?: boolean;
  noAssetsMessage?: string;
  mainTaskAssetId?: string;
}

const AssetsList: FC<IProps> = ({
  assets,
  disabled,
  search,
  withSearch,
  loading,
  noAssetsMessage,
  mainTaskAssetId,
}) => {
  const cn = classnames('AssetsList', { 'AssetsList--withSearch': withSearch });

  return (
    <Observer
      render={() => (
        <div className={cn}>
          {withSearch && (
            <div className="AssetsList-search">
              <Input
                className="Input--noBorder"
                disabled={disabled}
                icon={<Search2Icon width={14} height={14} fill="#919EB8" />}
                iconPosition="left"
                onChange={(e) => search.set(e.target.value)}
                placeholder="Search"
                value={search.value}
              />
            </div>
          )}

          <div className="AssetsList-list">
            {Boolean(noAssetsMessage) && <div className="AssetsList-noResults">{noAssetsMessage}</div>}
            {loading ? (
              <div className="AssetsList-loading">
                <LoaderIcon />
              </div>
            ) : Boolean(assets.length) ? (
              assets.map((asset) => {
                return (
                  <div className="AssetsList-item" key={asset.id.value}>
                    <Checkbox
                      disabled={asset.trackerDetails.repository.getState.loading || mainTaskAssetId === asset.id.value}
                      checked={asset.selected.value}
                      onChange={() => asset.toggle(!asset.selected.value, false)}
                      label={asset.name.value}
                    />
                  </div>
                );
              })
            ) : (
              Boolean(search.value) && <div className="AssetsList-noResults">No Results</div>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default AssetsList;
