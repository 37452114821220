import React from 'react';
import { observer } from 'mobx-react';
import type { SimpleField } from 'models/Fields';
import { InputNumber } from 'antd';
import { getValidTankCapacity } from 'utils';

import './styles.scss';

interface IProps {
  controls?: boolean;
  disabled: boolean;
  label?: string;
  onChange?: (value: number) => void;
  placeholder?: string;
  tankCapacity: SimpleField<number>;
}

const WEXCardAssociationsTankCapacity: React.FC<IProps> = ({
  controls,
  disabled,
  label = 'GAL',
  onChange,
  placeholder,
  tankCapacity,
}) => {
  const handleChange = (value: number) => {
    tankCapacity.set(value);

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className="WEXCardAssociationsTankCapacity">
      <InputNumber
        className="WEXCardAssociationsTankCapacity-input"
        formatter={getValidTankCapacity}
        controls={controls}
        disabled={disabled}
        min={0}
        onChange={handleChange}
        placeholder={placeholder}
        value={tankCapacity.value}
      />
      {label && <span className="WEXCardAssociationsTankCapacity-label">{label}</span>}
    </div>
  );
};

export default observer(WEXCardAssociationsTankCapacity);
