import React from 'react';
import classnames from 'classnames';

import type MediaClipsItem from 'models/Dashcam/MediaLibrary/MediaClips/MediaClipsItem';

import './styles.scss';

interface IProps {
  clip: MediaClipsItem;
}

const MediaLibraryVideoInfo: React.FC<IProps> = ({ clip }) => {
  const cnClipTitle = classnames('MediaLibraryVideoInfo-clipTitle', {
    'MediaLibraryVideoInfo-clipTitle--untitled': !clip.media.front.title.isSet.value,
  });

  return (
    <div className="MediaLibraryVideoInfo">
      <div className="MediaLibraryVideoInfo-row">
        <span className="MediaLibraryVideoInfo-vehicleName">{clip.displayName}</span>
        <span className="MediaLibraryVideoInfo-driverName">{clip.driver.displayName}</span>
      </div>
      <div className="MediaLibraryVideoInfo-row MediaLibraryVideoInfo-row--clipInfo">
        <span className={cnClipTitle}>{clip.media.front.title.display.value}</span>
        {Boolean(clip.media.front.duration.active.value) && (
          <span className="MediaLibraryVideoInfo-duration">{`${clip.media.front.duration.active.value}s`}</span>
        )}
      </div>

      <div className="MediaLibraryVideoInfo-row">
        <span className="MediaLibraryVideoInfo-time">{clip.media.front.date.mediaClip}</span>
      </div>
      <div className="MediaLibraryVideoInfo-row">
        <span className="MediaLibraryVideoInfo-address">{clip.address}</span>
      </div>
      <div className="MediaLibraryVideoInfo-eventTypesWrapper">
        <ul className="MediaLibraryVideoInfo-eventTypesList">
          {clip.eventTypes.value.map((eventType) => (
            <li key={eventType.value} className="MediaLibraryVideoInfo-eventTypesListItem">
              {eventType.value}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MediaLibraryVideoInfo;
