import React, { FC, useMemo, useRef, memo, useCallback, useState, Fragment } from 'react';
import { Polygon } from 'react-google-maps';
import isEqual from 'lodash/isEqual';

import { geozoneOptions, getMidPoint, pathToCoords } from 'utils';
import AddPointMarker from '../GeozonePathMarker/AddPointMarker';
import NewMarkerHoverArea from './NewMarkerHoverArea';

interface IProps {
  draggable?: boolean;
  coordinates: Locations.ILocation[];
  color: string;
  onCoordsChange?: (coords: Geozone.IGeozoneCoordinates) => void;
  addNewMarker: (order: number, point: Locations.ILocation) => void;
  onMouseMove: (e: google.maps.MouseEvent) => void;
}

const initialLineCoords = [
  { lat: 0, lng: 0 },
  { lat: 0, lng: 0 },
];

const EditablePolygonGeozone: FC<IProps> = ({
  color,
  coordinates,
  draggable = true,
  onCoordsChange,
  addNewMarker,
  onMouseMove,
}) => {
  const [hover, setHover] = useState(false);
  const [newMarkerOrder, setNewMarkerOrder] = useState(0);
  const options = useMemo(() => geozoneOptions(color), [color]);
  const [hoveredLine, setHoveredCoordinates] = useState(initialLineCoords);
  const polygonRef = useRef(null);

  const handleDrag = useCallback(() => {
    const newPath = pathToCoords(polygonRef.current.getPath().getArray());
    onCoordsChange(newPath);
  }, [onCoordsChange]);

  const newMarkerPosition = useMemo(() => {
    const length = hoveredLine.length;
    const start = hoveredLine[0];
    const end = hoveredLine[length - 1];
    const { lat, lng } = getMidPoint(start, end);
    return { lat, lng };
  }, [hoveredLine]);

  const canAddPoints = useMemo(() => coordinates.length < 10 && draggable, [coordinates, draggable]);

  const hoverLines = useMemo(() => {
    return coordinates.reduce((acc, _value, index, arr) => {
      const res = arr.slice(index, index + 2);
      if (index === arr.length - 1) {
        res.push(arr[0]);
      }
      acc.push(res);
      return acc;
    }, []);
  }, [coordinates]);

  const handleLineMouseOver = useCallback(
    (order, linePath) => {
      setHoveredCoordinates(linePath);
      setHover(true);
      setNewMarkerOrder(order);
    },
    [setHover, setNewMarkerOrder, setHoveredCoordinates]
  );
  const handleLineMouseOut = useCallback(() => {
    setHoveredCoordinates(initialLineCoords);
    setHover(false);
  }, [setHover]);

  const handleClick = useCallback(() => {
    addNewMarker(newMarkerOrder, newMarkerPosition);
    setHover(false);
  }, [addNewMarker, setHover, newMarkerOrder, newMarkerPosition]);

  return (
    <Fragment>
      {canAddPoints &&
        hoverLines.map((linePath, index) => {
          return (
            <NewMarkerHoverArea
              key={index + 2}
              orderNumber={index + 2}
              path={linePath}
              onMouseOver={handleLineMouseOver}
              onMouseOut={handleLineMouseOut}
            />
          );
        })}
      <Polygon
        ref={polygonRef}
        draggable={draggable}
        path={coordinates}
        options={{ ...options, strokeWeight: 5, strokeOpacity: 1, zIndex: 25 }}
        onDrag={handleDrag}
        onMouseMove={onMouseMove}
      />

      {hover && canAddPoints && (
        <AddPointMarker lat={newMarkerPosition.lat} lng={newMarkerPosition.lng} onClick={handleClick} />
      )}
    </Fragment>
  );
};

export default memo(EditablePolygonGeozone, isEqual);
