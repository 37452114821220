import React from 'react';
import { Observer } from 'mobx-react';
import type { SimpleField } from 'models/Fields';
import SearchableSelect from 'components/Select/SearchableSelect';
import type { IFuelType } from 'interfaces/stores/Assets/IFuelTypes';

interface IProps {
  fuelType: SimpleField<string>;
  onChange?: (option: Select.ISelectOption) => void;
  options: IFuelType[];
  disabled: boolean;
}

const WEXCardAssociationsFuelType: React.FC<IProps> = ({ fuelType, onChange, options, disabled }) => {
  const handleChange = (option: Select.ISelectOption) => {
    fuelType.set(option.value);

    if (onChange) {
      onChange(option);
    }
  };

  return (
    <Observer
      render={() => (
        <SearchableSelect
          disabled={disabled}
          value={{ value: fuelType.value, label: fuelType.value }}
          handleChange={handleChange}
          isSearchable={false}
          isStatic
          values={[
            { value: 'none', label: '-' },
            ...options.map((option) => ({ value: option.fuelType, label: option.name })),
          ]}
        />
      )}
    />
  );
};

export default WEXCardAssociationsFuelType;
