import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { PATHS } from 'config';

import QBError from './QB/Error';
import QBSuccess from './QB/Success';

import './styles.scss';

class IntegrationsConnecting extends Component {
  render() {

    return (
      <div className="IntegrationsConnecting-root">
        <Switch>
          <Route path={PATHS.ADMIN.INTEGRATIONS.CONNECTING.QB.SUCCESS} component={QBSuccess} />
          <Route path={PATHS.ADMIN.INTEGRATIONS.CONNECTING.QB.ERROR} component={QBError} />
        </Switch>
      </div>
    );
  }
}

export default IntegrationsConnecting;

