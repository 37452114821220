import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { parse } from 'query-string';

import { DISPATCH_STORE, PATHS } from 'config';
import type { DispatchStore } from 'stores/Dispatch';
import DispatchesTable from './DispatchesTable';
import EditDispatch from './EditDispatch';
import SendDispatch from './SendDispatch';
import WelcomeScreen from 'components/Dispatch/WelcomeScreen';

import './styles.scss';

interface IProps extends RouteComponentProps {
  className?: string;
  [DISPATCH_STORE]: DispatchStore;
}

interface IState {
  prevLocation: string;
}

export interface IDispatchModalSearchParams {
  dispatchModal?: 'send' | 'edit';
  dispatchId?: string;
}

@inject(DISPATCH_STORE)
@observer
class Dispatch extends Component<IProps, IState> {
  componentDidMount() {
    const {
      dispatchStore: { getAllDrivers, getAllDispatches },
    } = this.props;

    getAllDrivers();
    getAllDispatches();
  }

  render() {
    const {
      className,
      dispatchStore: {
        dispatchesList,
        repositoryDrivers: { getState },
        filteredDriversList,
      },
      location: { search },
    } = this.props;
    const cn = classNames('Dispatch', className);
    const { dispatchModal, dispatchId }: IDispatchModalSearchParams = parse(search);

    return (
      <div className={cn}>
        <h1 className="Dispatch-header">Dispatches</h1>
        <p className="Dispatch-subHeader">
          Send your drivers a list of locations with notes. Drivers will be able to mark their stops as complete, and
          leave notes in reply.
        </p>
        {getState.success && (
          <div className="Dispatch-content">
            <Switch>
              {Boolean(dispatchesList.length) && (
                <Route path={PATHS.DISPATCH.INDEX} exact>
                  <Redirect from={PATHS.DISPATCH.INDEX} to={PATHS.DISPATCH.TABLE} />
                </Route>
              )}
              <Route
                path={PATHS.DISPATCH.INDEX}
                exact
                render={(props) => <WelcomeScreen driversList={filteredDriversList} {...props} />}
              />
              <Route path={PATHS.DISPATCH.TABLE} component={DispatchesTable} />
              <Redirect from="*" to={PATHS.NOT_FOUND} />
            </Switch>
            <SendDispatch isOpen={dispatchModal === 'send'} {...this.props} />
            {dispatchModal === 'edit' && dispatchId && <EditDispatch {...this.props} />}
          </div>
        )}
      </div>
    );
  }
}

export default Dispatch;
