import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { some, noop } from 'lodash';
import classnames from 'classnames';

import { Maintenances as MaintenancesStore, RouterStore, TimeStore, validateAccessLevel } from 'stores';
import type { VehiclesAdmin } from 'stores/Admin/Vehicles';
import {
  DATE_TIME_FORMATS,
  MAINTENANCE_COMPLETED_TABLE_COLUMNS,
  MAINTENANCE_VIEW,
  MAINTENANCES_STORE,
  PATHS,
  ROUTER_STORE,
  STORAGE_ITEMS,
  TIME_STORE,
  ACL,
} from 'config';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';
import { getFormattedTime, getJSONItemFromStorage, setJSONItemToStorage } from 'utils';
import type MaintenanceCompletedTableModel from 'models/Maintenances/Tables/CompletedTable';
import type { ITableSearchField } from 'models/Tables/ITableSearchField';
import Button from 'components/Button';
import CustomTable from 'components/Table/CustomTable';
import TableActions from 'components/Table/Actions';
import Modal from 'components/Modal';
import MaintenanceTaskDeleteModal from 'components/Maintenance/DeleteModal';
import MaintenanceCompletedTaskPanel from 'components/Maintenance/CompletedTaskPanel';
import TableDownloadModal from 'components/Table/TableDownloadModal';
import TableEmailModal from 'components/Table/TableEmailModal';
import FrequencyCell from 'components/Maintenance/Table/FrequencyCell';

import './styles.scss';

interface IProps {
  [MAINTENANCES_STORE]?: MaintenancesStore;
  [TIME_STORE]?: TimeStore;
  [ROUTER_STORE]?: RouterStore;
  vehiclesAdmin?: VehiclesAdmin;
}

interface IState {
  isFilterOpen: boolean;
  isDeleteModalOpen: boolean;
  isCompletedTaskPanelOpen: boolean;
  isDownloadModalOpen: boolean;
  isEmailModalOpen: boolean;
}

@inject(({ maintenancesStore, timeStore, adminStore: { vehiclesAdmin }, routerStore }) => ({
  maintenancesStore,
  timeStore,
  vehiclesAdmin,
  routerStore,
}))
@observer
class MaintenanceCompletedTable extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isFilterOpen: getJSONItemFromStorage(STORAGE_ITEMS.maintenance.completed.filterOpenStatus, true),
      isDeleteModalOpen: false,
      isCompletedTaskPanelOpen: false,
      isDownloadModalOpen: false,
      isEmailModalOpen: false,
    };
  }

  componentDidMount() {
    this.fetchTotalCost();

    EventsBus.get().on(APP_EVENTS.MAINTENANCE.COMPLETED.UPDATED, this.fetchTotalCost);
    EventsBus.get().on(APP_EVENTS.MAINTENANCE.COMPLETED.DELETED, this.fetchTotalCost);
  }

  componentWillUnmount() {
    EventsBus.get().off(APP_EVENTS.MAINTENANCE.COMPLETED.UPDATED, this.fetchTotalCost);
    EventsBus.get().off(APP_EVENTS.MAINTENANCE.COMPLETED.DELETED, this.fetchTotalCost);
  }

  get isSearchApplied() {
    const {
      maintenancesStore: {
        completedTable: { searchData },
      },
    } = this.props;

    return some(searchData, (data) => Boolean(data));
  }

  get emptyText() {
    return this.isSearchApplied ? (
      ''
    ) : (
      <div className="MaintenanceUpcomingTable-emptyText">
        <span className="MaintenanceUpcomingTable-emptyText--bold">Completed Maintenance Tasks</span> will appear here.
        Navigate to <span className="MaintenanceUpcomingTable-emptyText--bold">‘Upcoming Tab’</span> and click{' '}
        <span className="MaintenanceUpcomingTable-emptyText--bold">Create New Task</span> to get started.
      </div>
    );
  }

  get isAdminView() {
    return this.props.maintenancesStore.view.value === MAINTENANCE_VIEW.ADMIN;
  }

  get tableHeight() {
    return {
      [MAINTENANCE_VIEW.DEFAULT]: {
        default: 'calc(100vh - 340px)',
        withOpenFilters: 'calc(100vh - 375px)',
      },
      [MAINTENANCE_VIEW.ADMIN]: {
        default: 'calc(100vh - 370px)',
        withOpenFilters: 'calc(100vh - 405px)',
      },
    };
  }

  get editColumnsMap() {
    return {
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.TASK_NAME]: {
        text: 'Task Name',
        isDisabled: true,
      },
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.VEHICLE_NAME]: {
        text: 'Vehicle/Asset',
        isDisabled: false,
        isVisible: () => !this.isAdminView,
      },
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.SERVICED_ON]: {
        text: 'Serviced On Date',
        isDisabled: true,
      },
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.ODO_AT_SERVICE]: {
        text: 'Odometer at Service',
        isDisabled: false,
      },
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.ENG_HOURS_AT_SERVICE]: {
        text: 'Engine Hours at Service',
        isDisabled: false,
      },
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.FREQUENCY]: {
        text: 'Frequency',
        isDisabled: false,
      },
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.SERVICE_TYPE]: {
        text: 'Service Type',
        isDisabled: false,
      },
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.TOTAL_COST]: {
        text: 'Total Cost',
        isDisabled: false,
      },
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.MARK_AS_COMPLETED_ON]: {
        text: 'Marked as Complete On',
        isDisabled: false,
      },
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.MARK_AS_COMPLETED_BY]: {
        text: 'Marked as Complete By',
        isDisabled: false,
      },
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.MODIFIED_ON]: {
        text: 'Modified On',
        isDisabled: false,
      },
      [MAINTENANCE_COMPLETED_TABLE_COLUMNS.MODIFIED_BY]: {
        text: 'Modified By',
        isDisabled: false,
      },
    };
  }

  fetch = (params) => {
    const {
      vehiclesAdmin: { selectedVehicle },
      maintenancesStore: { fetch },
    } = this.props;

    if (this.isAdminView && selectedVehicle) {
      fetch({ ...params, hasHistoryRecords: true, withHistoryRecords: true }, selectedVehicle.assetId);
    } else {
      fetch({ ...params, hasHistoryRecords: true, withHistoryRecords: true });
    }
  };

  fetchTotalCost = () => {
    const {
      maintenancesStore: {
        completedTable: { fetchTotalCost, searchData },
      },
      vehiclesAdmin: { selectedVehicle },
    } = this.props;

    fetchTotalCost({
      ...(this.isAdminView && selectedVehicle && { assetId: selectedVehicle.assetId?.toString() }),
      ...searchData,
    });
  };

  handleSearch = (searchColumns: ITableSearchField[]) => {
    const {
      maintenancesStore: { completedTable },
      vehiclesAdmin: { selectedVehicle },
    } = this.props;

    completedTable.setSearchColumn(searchColumns);
    completedTable.debouncedFetchTotalCost({
      ...completedTable.searchData,
      ...(this.isAdminView && selectedVehicle && { assetId: selectedVehicle.assetId?.toString() }),
    });
  };

  getColumns = () => {
    const {
      maintenancesStore: {
        completedTable: {
          columns,
          sortedColumn: { field, order },
        },
      },
      timeStore: { sessionTimezone },
    } = this.props;

    const allColumns = [
      {
        title: 'Task Name',
        dataIndex: MAINTENANCE_COMPLETED_TABLE_COLUMNS.TASK_NAME,
        width: 180,
        fixed: 'left',
        className: 'MaintenanceCompletedTable-taskName',
        render: (value, task) => (
          <Button className="Button--link" title={value} onClick={() => this.openCompletedTaskPanel(task.key)} />
        ),
      },
      {
        title: 'Vehicle/Asset',
        dataIndex: MAINTENANCE_COMPLETED_TABLE_COLUMNS.VEHICLE_NAME,
        width: 180,
        hidden: this.isAdminView,
      },
      {
        title: 'Serviced On',
        dataIndex: MAINTENANCE_COMPLETED_TABLE_COLUMNS.SERVICED_ON,
        defaultSortOrder: field === MAINTENANCE_COMPLETED_TABLE_COLUMNS.SERVICED_ON ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
        hiddenSearch: true,
        render: (value) => {
          return <>{value ? getFormattedTime(value, DATE_TIME_FORMATS.adminVehicleSettings, sessionTimezone) : '-'}</>;
        },
      },
      {
        title: 'Odometer At Service',
        dataIndex: MAINTENANCE_COMPLETED_TABLE_COLUMNS.ODO_AT_SERVICE,
        width: 180,
        hiddenSearch: true,
      },
      {
        title: 'Engine Hours At Service',
        dataIndex: MAINTENANCE_COMPLETED_TABLE_COLUMNS.ENG_HOURS_AT_SERVICE,
        width: 200,
        hiddenSearch: true,
      },
      {
        title: 'Frequency',
        dataIndex: MAINTENANCE_COMPLETED_TABLE_COLUMNS.FREQUENCY,
        width: 180,
        hiddenSearch: true,
        render: (cell) => (cell ? <FrequencyCell cell={cell} /> : '-'),
      },
      {
        title: 'Service Type',
        dataIndex: MAINTENANCE_COMPLETED_TABLE_COLUMNS.SERVICE_TYPE,
        width: 180,
      },
      {
        title: 'Total Cost',
        dataIndex: MAINTENANCE_COMPLETED_TABLE_COLUMNS.TOTAL_COST,
        width: 180,
        defaultSortOrder: field === MAINTENANCE_COMPLETED_TABLE_COLUMNS.TOTAL_COST ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        render: (value: number) => (
          <>
            {value
              ? `${value.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}`
              : '-'}
          </>
        ),
        hiddenSearch: true,
      },
      {
        title: 'Marked as Complete On',
        dataIndex: MAINTENANCE_COMPLETED_TABLE_COLUMNS.MARK_AS_COMPLETED_ON,
        width: 200,
        hiddenSearch: true,
        render: (value) => (
          <>{value ? getFormattedTime(value, DATE_TIME_FORMATS.adminVehicleSettings, sessionTimezone) : '-'}</>
        ),
      },
      {
        title: 'Marked as Complete By',
        dataIndex: MAINTENANCE_COMPLETED_TABLE_COLUMNS.MARK_AS_COMPLETED_BY,
        width: 190,
        hiddenSearch: true,
      },
      {
        title: 'Modified On',
        dataIndex: MAINTENANCE_COMPLETED_TABLE_COLUMNS.MODIFIED_ON,
        width: 180,
        hiddenSearch: true,
        render: (value) => (
          <>{value ? getFormattedTime(value, DATE_TIME_FORMATS.adminVehicleSettings, sessionTimezone) : '-'}</>
        ),
      },
      {
        title: 'Modified By',
        dataIndex: MAINTENANCE_COMPLETED_TABLE_COLUMNS.MODIFIED_BY,
        width: 180,
        hiddenSearch: true,
      },
    ];

    const filteredColumns = [];

    columns.forEach((column) => {
      if (column.isSelected) {
        filteredColumns.push(allColumns.find((col) => col.dataIndex === column.value));
      }
    });

    return [
      ...filteredColumns.filter((col) => !col.hidden),
      {
        title: 'Actions',
        dataIndex: 'actions',
        width: 190,
        className: 'ant-table-cell--actions',
        fixed: 'right',
        render: (_, task) => {
          const items = [
            {
              text: 'View Completed Task',
              onClick: () => this.openCompletedTaskPanel(task.key),
            },
            {
              text: 'View this Vehicle’s Maintenance',
              onClick: () => this.redirectToAdmin(task.cpNumber),
              hidden: this.isAdminView,
            },
            ...(validateAccessLevel([ACL.MAINTENANCE.BASIC.DELETE])
              ? [
                  {
                    text: 'Delete Task Record',
                    onClick: () => this.onDeleteMaintenanceTask(task.key),
                  },
                ]
              : []),
          ];

          return (
            <div className="MaintenanceCompletedTable-Actions">
              <TableActions items={items} />
            </div>
          );
        },
      },
    ];
  };

  handleOpenFilter = (isFilterOpen: boolean) => {
    this.setState({ isFilterOpen });
    setJSONItemToStorage(STORAGE_ITEMS.maintenance.upcoming.filterOpenStatus, isFilterOpen);
  };

  onDeleteMaintenanceTask = (taskId: number) => {
    const { maintenancesStore } = this.props;

    maintenancesStore.selectCompleted(taskId);
    this.setState({ isDeleteModalOpen: true });
  };

  closeDeleteModal = () => {
    this.setState({ isDeleteModalOpen: false });
  };

  redirectToAdmin = (cpNumber: string) => {
    const {
      vehiclesAdmin: { setSelectedVehicleEditTab },
      routerStore: {
        history: { push },
      },
    } = this.props;

    setSelectedVehicleEditTab(1);
    push(`${PATHS.ADMIN.TABLES.VEHICLES.VEHICLE.replace(':id', cpNumber)}?tab=completed`);
  };

  openCompletedTaskPanel = (taskId: number) => {
    const { maintenancesStore } = this.props;

    maintenancesStore.selectCompleted(taskId);
    this.setState({ isCompletedTaskPanelOpen: true });
  };

  closeCompletedTaskPanel = () => {
    this.setState({ isCompletedTaskPanelOpen: false });
  };

  closeDownloadModal = () => {
    this.setState({ isDownloadModalOpen: false });
  };

  openDownloadModal = () => {
    this.setState({ isDownloadModalOpen: true });
  };

  closeEmailModal = () => {
    this.setState({ isEmailModalOpen: false });
  };

  openEmailModal = () => {
    this.setState({ isEmailModalOpen: true });
  };

  renderTableTitle = () => {
    const {
      maintenancesStore: {
        completedTable: { totalCost },
      },
    } = this.props;

    return Boolean(totalCost.value) ? (
      <div className="MaintenanceCompletedTable-title">
        <span className="MaintenanceCompletedTable-titleText">Total Maintenance Costs: </span>
        <span className="MaintenanceCompletedTable-titleCost">
          {totalCost.value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </span>
      </div>
    ) : null;
  };

  render() {
    const {
      maintenancesStore: {
        total,
        task,
        isUpdate,
        repository: { getState },
        completedTable: {
          columns,
          pagination,
          searchColumns,
          searchData,
          setColumns,
          setPagination,
          setSortedColumn,
          sortData,
          source,
          filters,
          download,
        },
        view,
      },
    } = this.props;
    const { isFilterOpen, isDeleteModalOpen, isCompletedTaskPanelOpen, isDownloadModalOpen, isEmailModalOpen } =
      this.state;
    const cn = classnames('MaintenanceCompletedTable', {
      'MaintenanceCompletedTable--withOpenFilters': isFilterOpen,
      [`MaintenanceCompletedTable--${view.value}`]: Boolean(view.value),
    });
    const tableHeight = isFilterOpen
      ? this.tableHeight[view.value].withOpenFilters
      : this.tableHeight[view.value].default;

    return (
      <div className={cn}>
        <CustomTable
          columns={this.getColumns()}
          dataSource={source}
          filters={{
            [MAINTENANCE_COMPLETED_TABLE_COLUMNS.FREQUENCY]: filters.frequency.list,
          }}
          editColumnsMap={this.editColumnsMap}
          getData={this.fetch}
          isFilterActive={isFilterOpen}
          loading={getState.loading}
          triggerUpdate={isUpdate.value}
          onColumnsChange={setColumns}
          onColumnSort={setSortedColumn}
          onColumnsSearch={this.handleSearch}
          onFilterOpen={this.handleOpenFilter}
          onPaginationChange={setPagination}
          pagination={pagination}
          searchColumns={searchColumns}
          searchParams={searchData}
          selectedColumns={toJS(columns)}
          sortedColumn={sortData}
          tableHeight={tableHeight}
          total={total.value}
          totalTitle={this.renderTableTitle()}
          withActions
          withDownload
          withEmail
          openDownloadModal={this.openDownloadModal}
          openEmailModal={this.openEmailModal}
          emptyText={this.emptyText}
          rowClassName={(record) => record.className}
        />
        <Modal
          isModalOpen={isDeleteModalOpen}
          onClose={this.closeDeleteModal}
          className="MaintenanceCompletedTable-deleteModal"
        >
          {task && task.completed && (
            <MaintenanceTaskDeleteModal
              taskName={task.completed.info.taskName.value}
              type="complete"
              onClose={this.closeDeleteModal}
              deleteTask={task.completed.delete}
              sending={task.completed.repositoryModel.deleteState.loading}
            />
          )}
        </Modal>
        <MaintenanceCompletedTaskPanel
          isPanelOpen={isCompletedTaskPanelOpen}
          onClosePanel={this.closeCompletedTaskPanel}
          task={task}
          redirectToAdmin={this.redirectToAdmin}
        />
        <TableDownloadModal<MaintenanceCompletedTableModel>
          isOpen={isDownloadModalOpen}
          onClose={this.closeDownloadModal}
          tableDownload={download}
        />
        <TableEmailModal<MaintenanceCompletedTableModel>
          isOpen={isEmailModalOpen}
          onClose={this.closeEmailModal}
          tableDownload={download}
        />
      </div>
    );
  }
}

export default MaintenanceCompletedTable;
