import React, { FC, CSSProperties } from 'react';
import classnames from 'classnames';

import './styles.scss';

interface IProps {
  className?: string;
  label: React.ReactNode;
  labelStyle?: CSSProperties;
  value: React.ReactNode;
  valueStyle?: CSSProperties;
}

const LabelValue: FC<IProps> = ({ className, label, labelStyle, value, valueStyle }) => {
  const cn = classnames('LabelValue', className);

  return (
    <div className={cn}>
      <div className="LabelValue-label" style={labelStyle}>
        {label}
      </div>
      <div className="LabelValue-value" style={valueStyle}>
        {value}
      </div>
    </div>
  );
};

export default LabelValue;
