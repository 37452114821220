import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { parse, stringify } from 'query-string';
import classnames from 'classnames';

import { INSPECTIONS_STORE, ROUTER_STORE, ACL } from 'config';
import { Inspections as InspectionsStore, RouterStore, UserAccessLevelComponent, validateAccessLevel } from 'stores';

import Button from 'components/Button';
import TabsMenu from 'components/TabsMenu';
import PerformedTable from './PerformedTable';
import FormsTable from './FormsTable';
import InspectionsFormPanel from 'components/Inspections/InspectionsFormPanel';

import './styles.scss';

interface IProps {
  [INSPECTIONS_STORE]?: InspectionsStore;
  [ROUTER_STORE]?: RouterStore;
}

interface IState {
  tab: number;
  isInspectionPanelOpen: boolean;
}

interface ISearchParams {
  tab?: string;
}

@inject(INSPECTIONS_STORE, ROUTER_STORE)
@observer
class Inspections extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isInspectionPanelOpen: false,
      tab: this.initialSelectedTab,
    };
  }

  get initialSelectedTab() {
    const { tab } = parse(this.props.routerStore.history.location.search) as ISearchParams;

    return tab === 'forms' ? 1 : 0;
  }

  get isPerformedTabSelected() {
    return this.state.tab === 0;
  }

  openInspectionPanel = () => {
    const { inspectionsStore } = this.props;

    inspectionsStore.forms.create();
    this.setState({ isInspectionPanelOpen: true });
  };

  closeInspectionPanel = () => this.setState({ isInspectionPanelOpen: false });

  setSelectedTab = (tab: number) => {
    this.setState({ tab }, this.setQueryParam);
  };

  setQueryParam = () => {
    const search = stringify({ tab: this.state.tab ? 'forms' : 'performed' });
    this.props.routerStore.history.push({ search });
  };

  render() {
    const { inspectionsStore } = this.props;
    const { tab, isInspectionPanelOpen } = this.state;
    const cn = classnames('Inspections', {
      [`Inspections--${inspectionsStore.view.value}`]: Boolean(inspectionsStore.view.value),
    });
    return (
      <div className={cn}>
        <div className="Inspections-header">
          <div className="Inspections-title">
            <p className="Inspections-mainTitle">Inspections</p>
            <p className="Inspections-subTitle">Create, edit, and manage inspections for all Assets in your fleet.</p>
          </div>
          <UserAccessLevelComponent requiredAccessLevel={[ACL.INSPECTIONS.BASIC.CREATE]}>
            <div className="Inspections-create">
              <Button
                className="Button--apply"
                disabled={this.isPerformedTabSelected}
                onClick={this.openInspectionPanel}
                title="Create New Form"
              />
            </div>
          </UserAccessLevelComponent>
        </div>
        <div className="Inspections-content">
          <TabsMenu
            tabs={[
              { name: 'Performed' },
              ...(validateAccessLevel([ACL.INSPECTIONS.TEMPLATES.DELETE]) ? [{ name: 'Forms' }] : []),
            ]}
            onSelect={this.setSelectedTab}
            rounded
            selectedTab={tab}
          >
            <div className="Inspections-table Inspections-table--performed">
              <PerformedTable />
            </div>
            {validateAccessLevel([ACL.INSPECTIONS.TEMPLATES.DELETE]) && (
              <div className="Inspections-table Inspections-table--forms">
                <FormsTable />
              </div>
            )}
          </TabsMenu>
        </div>
        <InspectionsFormPanel
          isPanelOpen={isInspectionPanelOpen}
          inspectionForm={inspectionsStore.forms.form.value}
          onSave={inspectionsStore.forms.form.value?.create}
          loading={inspectionsStore.forms.form.value?.repository.createState.loading}
          closePanel={this.closeInspectionPanel}
          title="Create Inspection Form"
        />
      </div>
    );
  }
}

export default Inspections;
