import React, { useCallback } from 'react';
import { Observer } from 'mobx-react';
import { Checkbox } from 'antd';
import classNames from 'classnames';

import type ScheduleModel from 'models/Alerts/Schedule';

import TimePicker from 'components/TimeRange/TimePicker';
import Toggle from 'components/Toggle';

import { TIME, WEEKDAYS } from 'config';

import './styles.scss';

interface IProps {
  schedule: ScheduleModel;
  onChangeSchedule: (value: WEEKDAYS[]) => void;
}

const weekOptions = [
  { label: 'S', value: WEEKDAYS.SUNDAY },
  { label: 'M', value: WEEKDAYS.MONDAY },
  { label: 'T', value: WEEKDAYS.TUESDAY },
  { label: 'W', value: WEEKDAYS.WEDNESDAY },
  { label: 'T', value: WEEKDAYS.THURSDAY },
  { label: 'F', value: WEEKDAYS.FRIDAY },
  { label: 'S', value: WEEKDAYS.SATURDAY },
];

const AlertPreferencesCalendarItem: React.FC<IProps> = ({ schedule, onChangeSchedule }) => {
  const { weekdays, from, to, allDay } = schedule;

  const handleChangeWeekdays = (value) => {
    onChangeSchedule(value);
  };

  const handleChangeAllDay = () => {
    if (!allDay.value) {
      from.time.set(TIME.beggingOfTheDay);
      to.time.set(TIME.endOfTheDay);
    }

    allDay.toggle();
  };

  const handleChangeTimeFrom = (value) => {
    from.time.set(value);
  };

  const handleChangeTimeTo = (value) => {
    to.time.set(value);
  };

  const cn = useCallback(() => {
    return classNames('AlertPreferencesCalendarItem-weekDays', {
      'AlertPreferencesCalendarItem-weekDays--error': weekdays.isEmpty,
    });
  }, []);

  return (
    <Observer
      render={() => (
        <div className="AlertPreferencesCalendarItem-item">
          <div className="AlertPreferencesCalendarItem-line">
            <div className="AlertPreferencesCalendarItem-label AlertPreferencesCalendarItem-label--top">on</div>
            <div className="AlertPreferencesCalendarItem-value">
              <div className={cn()}>
                <Checkbox.Group options={weekOptions} value={weekdays.toArray()} onChange={handleChangeWeekdays} />
                {weekdays.isEmpty && (
                  <span className="AlertPreferencesCalendarItem-error">At least one day is required</span>
                )}
              </div>
            </div>
          </div>
          <div className="AlertPreferencesCalendarItem-line AlertPreferencesCalendarItem-line--double">
            <div className="AlertPreferencesCalendarItem-label">from</div>
            <div className="AlertPreferencesCalendarItem-value">
              <TimePicker
                onSelect={handleChangeTimeFrom}
                disabled={allDay.value}
                before={to.time.value}
                value={from.time.value}
                placeholder={TIME.beggingOfTheDay}
              />
            </div>
            <div className="AlertPreferencesCalendarItem-label">to</div>
            <div className="AlertPreferencesCalendarItem-value">
              <TimePicker
                onSelect={handleChangeTimeTo}
                disabled={allDay.value}
                after={from.time.value}
                value={to.time.value}
                placeholder={TIME.endOfTheDay}
              />
            </div>
          </div>
          <div className="AlertPreferencesCalendarItem-line AlertPreferencesCalendarItem-line--end">
            <span className="AlertPreferencesCalendarItem-allDay">All Day</span>
            <Toggle checked={allDay.value} onChange={handleChangeAllDay} />
          </div>
        </div>
      )}
    />
  );
};

export default AlertPreferencesCalendarItem;
