import InspectionsPerformedTable from 'models/Inspections/Tables/PerformedTable';
import type { Inspections } from 'stores';
import { ListField, SimpleField } from '../Fields';
import InspectionPerformed from './Models/InspectionPerformed';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import { repositoryService } from 'services';

class InspectionsPerformed {
  context: Inspections;
  table: InspectionsPerformedTable;
  items: ListField<InspectionPerformed>;
  total: SimpleField<number>;
  selected: SimpleField<InspectionPerformed>;
  repository: IRepository;

  constructor(context: Inspections) {
    this.context = context;
    this.table = new InspectionsPerformedTable(this.context);
    this.items = new ListField([]);
    this.total = new SimpleField(0);
    this.selected = new SimpleField<InspectionPerformed>(null);
    this.repository = repositoryService.get('dvir');
  }

  fetch = async (
    {
      order,
      page,
      pageSize,
      search,
      sortBy,
    }: {
      order?: string;
      page?: number;
      pageSize?: number;
      search?: { [key: string]: string };
      sortBy?: string;
    },
    assetId?: number
  ) => {
    this.reset();
    const params = {
      order,
      page,
      pageSize,
      ...search,
      sortBy,
      withAssetGroups: true,
      assetId,
    };
    const response = await this.repository.get(params);
    const items = response?.items?.map((item) => new InspectionPerformed(item));

    this.items.replace(items);
    this.total.set(response?.total);
  };

  findItem = (id: number) => this.items.value.find((item) => item.value.id === id);

  setSelectedByIdAsync = async (id: number) => {
    if (id && this.selected.value?.id !== id) {
      const response = await this.repository.entity(id.toString()).get();

      this.selected.set(new InspectionPerformed(response));
    }
  };

  reset = () => {
    this.items.reset();
    this.total.reset();
  };
}

export default InspectionsPerformed;
