import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import InspectionForm from 'models/Inspections/Models/InspectionForm';
import InspectionsFormsTable from './Tables/FormsTable';
import type { Inspections } from 'stores';
import { ListField, SimpleField } from 'models/Fields';
import { repositoryService } from 'services';
import isFinite from 'lodash/isFinite';

class InspectionsForms {
  context: Inspections;
  form: SimpleField<InspectionForm>;
  items: ListField<InspectionForm>;
  total: SimpleField<number>;
  repository: IEntityRepository;
  table: InspectionsFormsTable;

  constructor(context: Inspections) {
    this.context = context;
    this.form = new SimpleField(null);
    this.items = new ListField([]);
    this.total = new SimpleField(0);
    this.repository = repositoryService.get('dvir').entity('template');
    this.table = new InspectionsFormsTable(this.context);
  }

  fetch = async (
    {
      order,
      page,
      pageSize,
      search,
      sortBy,
    }: {
      order?: string;
      page?: number;
      pageSize?: number;
      search?: { [key: string]: string };
      sortBy?: string;
    },
    assetId?: number
  ) => {
    this.reset();
    const params = {
      order,
      page,
      pageSize,
      ...search,
      sortBy,
      withAssetsInfo: true,
    };
    const response = assetId
      ? await this.repository.entity(String(assetId)).get(params)
      : await this.repository.get(params);

    const items = response?.items?.map((item) => new InspectionForm(item));

    this.items.replace(items);
    this.total.set(response?.total);
  };

  create = () => {
    this.form.set(new InspectionForm({ isActive: true }));
  };

  edit = (id: number) => {
    const formToEdit = this.findById(id);

    this.form.set(new InspectionForm(formToEdit.value.serverData));
  };

  duplicate = (id: number) => {
    const formToDuplicate = this.findById(id);
    const nameParts = formToDuplicate.value.serverData.name.split('-');

    if (nameParts.length > 1 && isFinite(Number(nameParts[nameParts.length - 1]))) {
      nameParts[nameParts.length - 1] = (Number(nameParts[nameParts.length - 1]) + 1).toString();
    } else {
      nameParts.push('1');
    }

    this.form.set(
      new InspectionForm(
        {
          ...formToDuplicate.value.serverData,
          name: nameParts.join('-'),
          id: null,
        },
        true
      )
    );
  };

  findById = (id) => {
    return this.items.value.find((item) => item.value.id === id);
  };

  reset = () => {
    this.items.reset();
    this.total.reset();
  };
}

export default InspectionsForms;
