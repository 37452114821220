import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { MAINTENANCES_STORE } from 'config';
import type { Maintenances } from 'stores';

import MaintenanceCompletedTaskPanel from 'components/Maintenance/CompletedTaskPanel';

interface IProps {
  maintenancesStore?: Maintenances;
}

@inject(MAINTENANCES_STORE)
@observer
class ReportCompletedTaskPanelContainer extends Component<IProps, null> {
  handleClosePanel = () => {
    this.resetTask();
  };

  resetTask = () => {
    const {
      maintenancesStore: { resetTask },
    } = this.props;

    resetTask();
  };

  componentDidMount() {
    this.resetTask();
  }

  render() {
    const {
      maintenancesStore: { task },
    } = this.props;

    const isPanelOpen = task !== null;
    if (!isPanelOpen) {
      return null;
    }

    return (
      <div>
        <MaintenanceCompletedTaskPanel
          isPanelOpen={isPanelOpen}
          onClosePanel={this.handleClosePanel}
          task={task}
          isEdit={false}
        />
      </div>
    );
  }
}

export default ReportCompletedTaskPanelContainer;
