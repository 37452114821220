import React, { FC } from 'react';
import Modal from 'components/Modal';
import Button from 'components/Button';

import './styles.scss';

interface IProps {
  name: string;
  onCancel: () => void;
  onSubmit: () => void;
  open: boolean;
  loading: boolean;
}

const ConfirmDeleteFormModal: FC<IProps> = ({ name, open, onCancel, onSubmit, loading }) => {
  return (
    <Modal className="Modal--confirmDelete" isModalOpen={open} onClose={onCancel} withoutCloseIcon>
      <div className="ConfirmDeleteFormModal">
        <p className="ConfirmDeleteFormModal-title">Delete {name}?</p>
        <p className="ConfirmDeleteFormModal-text">
          Deleting this form will permanently remove it from the system and it will no longer be accessible.
          <br />
          <br />
          Do you want to continue?
        </p>
        <div className="ConfirmDeleteFormModal-buttons">
          <div className="ConfirmDeleteFormModal-button ConfirmDeleteFormModal-button--cancel">
            <Button className="Button Button--cancel" title="No, Cancel" onClick={onCancel} />
          </div>
          <div className="ConfirmDeleteFormModal-button ConfirmDeleteFormModal-button--submit">
            <Button
              disabled={loading}
              className="Button Button--apply"
              title="Yes, Continue"
              onClick={onSubmit}
              sending={loading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteFormModal;
