import React, { FC } from 'react';
import type { FieldProps } from 'formik';

import RadioGroup from 'components/RadioGroup';

interface IProps extends FieldProps {
  checkedValue: string;
}

const EmailPhoneRadioGroupField: FC<IProps> = ({ field, checkedValue }) => {
  const handleChange = (value: string) => {
    field.onChange({
      target: {
        name: field.name,
        value,
      },
    });
  };

  return (
    <RadioGroup
      onChange={handleChange}
      radios={[
        { id: 'email', label: 'Email', name: 'emailPhone', value: 'email' },
        { id: 'phoneNumber', label: 'Phone', name: 'emailPhone', value: 'phoneNumber' },
      ]}
      checkedValue={checkedValue}
      tabs
      size="small"
    />
  );
};

export default EmailPhoneRadioGroupField;
