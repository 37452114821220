import React, { FC, Fragment } from 'react';
import classNames from 'classnames';

import type { mapLabelSizeType } from 'stores';
import { EVENT_STATUS_TYPE } from 'config';

import './styles.scss';

interface IProps {
  className?: string;
  currentSpeed: number;
  size?: mapLabelSizeType;
  status: string;
  statusCodeText: string;
  timeDiff?: string;
  eventStatusType?: EVENT_STATUS_TYPE;
}

const EventStatusText: FC<IProps> = ({
  currentSpeed,
  status,
  className,
  statusCodeText,
  size,
  timeDiff,
  eventStatusType,
}) => {
  const statusCN = classNames('StatusText', `StatusText--${status}`, className, {
    [`StatusText--${size}`]: Boolean(size),
  });

  return (
    <Fragment>
      {eventStatusType === EVENT_STATUS_TYPE.SPEED && (
        <div className={statusCN}>
          {statusCodeText} @ {currentSpeed}mph
        </div>
      )}
      {eventStatusType === EVENT_STATUS_TYPE.TIME_DIFF && (
        <div className={statusCN}>
          {statusCodeText}: {timeDiff}
        </div>
      )}
      {eventStatusType === EVENT_STATUS_TYPE.NONE && <div className={statusCN}>{statusCodeText}</div>}
    </Fragment>
  );
};

export default EventStatusText;
