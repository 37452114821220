import type TableToggleFilter from 'models/Tables/TableToggleFilter';
import ResultsFilter from './ResultsFilter';

export default class Filters {
  results: ResultsFilter;

  constructor({ results }: { results?: string }) {
    this.results = new ResultsFilter();
    this.init(this.results.list, results);
  }

  init = (filters: TableToggleFilter[] = [], savedValues: string = '') => {
    const arr = savedValues.split(',');

    filters.forEach((filter) => {
      if (arr.includes(filter.value)) {
        filter.isActive.toggle(true);
      }
    });
  };
}
