import React, { FC, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import copy from 'copy-to-clipboard';

import { stripHtml, getExpiration } from 'utils';
import TableActions from 'components/Table/Actions';
import { WEB_MAP_CLEARSHARE_BASIC_READ, WEB_MAP_CLEARSHARE_BASIC_DELETE } from 'config';
import validateAccessLevel from 'stores/acl/validator';

import { CheckInCircleIconSrc } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  links: Clearshare.ILink[];
  onExpireLink?: (linkId: string) => Promise<void>;
}

const MINUTES_TO_EXPIRE = 60;
const isExpiring = (timestamp: number) => moment(timestamp).diff(moment(), 'minutes') < MINUTES_TO_EXPIRE;

const LinksTable: FC<IProps> = ({ className, links, onExpireLink }) => {
  const [copiedUrl, setCopiedUrl] = useState('');
  const [expiredLinkId, setExpiredLinkId] = useState('');
  const cn = classNames('LinksTable', className);
  const copyUrl = (url) => {
    setCopiedUrl(url);
    setTimeout(() => setCopiedUrl(''), 2000);
    copy(url);
  };
  const expireLink = async (linkId: string) => {
    await onExpireLink(linkId);
    setExpiredLinkId(linkId);
    setTimeout(() => setExpiredLinkId(''), 2000);
  };

  return (
    <table className={cn}>
      <thead className="LinksTable-head">
        <tr className="LinksTable-headRow">
          <th className="LinksTable-headCol LinksTable-headCol--name">Name</th>
          <th className="LinksTable-headCol LinksTable-headCol--expiry">Expires</th>
          <th className="LinksTable-headCol LinksTable-headCol--actions" />
        </tr>
      </thead>
      <tbody className="LinksTable-body">
        {links.map((link, i) => {
          const { name, expiration, id, url } = link;
          const expiryCn = classNames('LinksTable-bodyCol LinksTable-bodyCol--expiry', {
            'LinksTable-bodyCol--expiring': isExpiring(expiration),
          });

          return (
            <tr key={id} className="LinksTable-bodyRow">
              <td className="LinksTable-bodyCol LinksTable-bodyCol--name">{name ? stripHtml(name) : `Link #${i}`}</td>
              <td className={expiryCn}>{getExpiration(expiration - Date.now())}</td>
              <td className="LinksTable-bodyCol LinksTable-bodyCol--actions">
                <TableActions
                  items={[
                    validateAccessLevel([WEB_MAP_CLEARSHARE_BASIC_READ]) && {
                      text: 'Launch ClearShare',
                      onClick: () => window.open(url, '_blank').focus(),
                    },
                    validateAccessLevel([WEB_MAP_CLEARSHARE_BASIC_READ]) && {
                      text: 'Copy Link',
                      onClick: () => copyUrl(url),
                    },
                    ...(validateAccessLevel([WEB_MAP_CLEARSHARE_BASIC_DELETE])
                      ? [
                          {
                            text: 'Expire Link',
                            onClick: () => expireLink(String(id)),
                          },
                        ]
                      : []),
                  ]}
                  iconText={null}
                  popupContainer={() => document?.querySelector?.('.MapVehiclePanel-container')}
                />
              </td>
              {Boolean(copiedUrl && copiedUrl === url) && (
                <td className="LinksTable-notification">
                  <CheckInCircleIconSrc className="LinksTable-notificationImg" />
                  Link Copied!
                </td>
              )}
              {Boolean(expiredLinkId && expiredLinkId === String(id)) && (
                <td className="LinksTable-notification">
                  <CheckInCircleIconSrc className="LinksTable-notificationImg" />
                  Link Expired!
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default LinksTable;
