import { ToggleField } from 'models/Fields';
import CameraInterval from './CameraInterval';

class CameraDate {
  isSelected: ToggleField;
  intervals: CameraInterval[];

  constructor() {
    this.isSelected = new ToggleField(false);
    this.intervals = [];
  }

  addInterval = (interval) => {
    this.intervals.push(new CameraInterval(interval).initialize(this));
    return this;
  };

  findSelectedInterval = () => {
    return (
      this.intervals.find((interval) => interval.isSelected.value) ||
      new CameraInterval({
        state: '',
        startTimestamp: null,
        endTimestamp: null,
        isSelected: new ToggleField(false),
      })
    );
  };

  reset = () => {
    this.intervals.forEach((interval) => interval.isSelected.toggle(false));
  };
}

export default CameraDate;
