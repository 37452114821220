import { observable } from 'mobx';

export class Driver {
  @observable driverId: string = '';
  @observable driverName: string = '';
  @observable driverPhone: string = '';
  @observable deviceId: string = '';

  constructor({ driverId, description, contactPhone, deviceId }: Driver.IServerDriver) {
    this.driverId = driverId;
    this.driverName = description;
    this.driverPhone = contactPhone;
    this.deviceId = deviceId;
  }
}

export default Driver;
