import React, { FC, SVGProps, useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';

import RadioGroup from 'components/RadioGroup';
import { CircleIcon, ShapeIcon } from 'assets';

import './styles.scss';

interface IProps {
  disabled?: boolean;
  activeType: string;
  className?: string;
  onChange: (type: string) => void;
  types?: Array<{
    id: string;
    label: string;
    name: string;
    value: string;
    Icon?: FC<SVGProps<SVGSVGElement>>;
  }>;
}

const GeozoneTypeRadio: FC<IProps> = ({
  activeType = 'POLYGON',
  className,
  disabled = false,
  onChange,
  types = [
    { id: 'polygon', label: 'Polygon', name: 'geozone-shape', value: 'polygon', Icon: ShapeIcon },
    { id: 'circle', label: 'Circle', name: 'geozone-shape', value: 'circle', Icon: CircleIcon },
  ],
}) => {
  const [type, setType] = useState(activeType);
  const cn = classNames('GeozoneTypeRadio', className);
  const handleTagChange = useCallback(
    (type: string) => {
      setType(type);
      onChange(type);
    },
    [onChange]
  );

  useEffect(() => setType(activeType), [activeType]);

  return (
    <div className={cn}>
      <RadioGroup disabled={disabled} onChange={handleTagChange} radios={types} tabs checkedValue={type} size="small" />
    </div>
  );
};

export default GeozoneTypeRadio;
