import { action, computed, observable } from 'mobx';
import { Customer } from './Customer';
import type { ICustomersStore } from 'interfaces/stores/Customers/ICustomersStore';
import type { IntegrationDomain } from 'interfaces/stores/Integrations/IIntegration';

import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';
import type ICustomer from 'interfaces/stores/Customers/ICustomer';

export class CustomersStore implements ICustomersStore {
  @observable customers: Customer[] = [];
  @observable selectedCustomerId: string = null;
  @observable filterValue: string = '';
  @observable filterDomains: IntegrationDomain[] = [];

  @observable currentPage: number = 0;
  @observable total: number = 0;
  @observable totalPages: number = 0;
  @observable pageSize: number = 30;

  repositoryCustomers: IRepository;
  repositoryNotifications: IEntityRepository;
  repositoryEntityCustomer: IEntityRepository;
  repositoryEntityCustomers: IEntityRepository;

  constructor() {
    this.repositoryCustomers = repositoryService.get('integrations');
    this.repositoryEntityCustomer = this.repositoryCustomers.entity('customers');
    this.repositoryEntityCustomers = this.repositoryCustomers.entity('customers');
    this.repositoryNotifications = repositoryService.get('notifications').entity('sms');
  }

  textLocation = async ({ phone, message }: { async?: boolean; phone: string; message: string }) => {
    await this.repositoryNotifications.create({ async: false, phone, message });
  };

  @computed
  get filteredCustomers() {
    return this.customers.filter(({ companyName }) =>
      (companyName || '').toLowerCase().includes(this.filterValue.toLowerCase())
    );
  }

  @computed
  get selectedCustomer(): ICustomer | null {
    return this.customers.find((customer) => String(customer?.id) === String(this.selectedCustomerId));
  }

  @action setFilterValue = (value: string) => {
    this.filterValue = value;
  };

  @action toggleFilterDomain = (value: IntegrationDomain) => {
    const index = this.filterDomains.findIndex(domain => domain === value);
    if (index !== -1) {
      this.filterDomains.splice(index, 1);
    } else {
      this.filterDomains.push(value);
    }
  };

  @action resetDomains = () => {
    this.filterDomains = [];
  }

  @action setSelectedCustomerId = (id: string) => {
    this.selectedCustomerId = id;
  };

  @action clearSelectedCustomerId = () => {
    this.setSelectedCustomerId(null);
  };

  @action loadAllCustomers = async () => {
    const response = await this.repositoryEntityCustomers.get({
      includeInvoices: false,
      sortBy: 'companyName',
      includeEstimates: false,
      companyName: undefined,
      page: 0,
      pageSize: 100,
    });

    if (this.repositoryEntityCustomers.getState.success) {
      const nextItems = [...response.items.filter((customer) => this.filterDomains.length === 0 || this.filterDomains.includes(customer.domain)).map((customer) => new Customer(customer))];
      this.customers = [
        ...nextItems,
        ...new Array(this.total - 100).fill(null),
      ];
      this.total = response.total - (response.items.length - nextItems.length);
      this.totalPages = response.totalPages;
    }
  };

  @action loadMoreCustomers = async (page: number, clear: boolean = false) => {
    this.repositoryEntityCustomers.getState.reset();

    const response = await this.repositoryEntityCustomers.get({
      includeInvoices: false,
      includeEstimates: false,
      sortBy: 'companyName',
      companyName: this.filterValue ? this.filterValue : undefined,
      // filter by domain is not working now, we will try to implement this on GUI
      // domain: this.filterDomains.length ? this.filterDomains.join(',') : undefined,
      page,
      pageSize: 100,
    });

    if (this.repositoryEntityCustomers.getState.success) {
      if (clear) {
        this.customers = [];
      }
      const nextItems = [...response.items.filter((customer) => this.filterDomains.length === 0 || this.filterDomains.includes(customer.domain)).map((customer) => new Customer(customer))]
      this.customers = [
        ...this.customers.slice(0, page * 100),
        ...nextItems,
        ...this.customers.slice((page + 1) * 100),
      ];
      this.total = response.total - (response.items.length - nextItems.length);
      this.totalPages = response.totalPages;
    }
  };

  @action loadCustomerDetails = async () => {
    const response = await this.repositoryEntityCustomer.entity(this.selectedCustomerId).get({
      includeInvoices: true,
      includeEstimates: true,
    });

    const index = this.customers.findIndex(({ id }) => response.id === id);
    const customer = new Customer({...response, full: true});
    if (index !== -1) {
      this.customers[index] = customer;
    } else {
      this.customers.push(customer);
    }
    await customer.loadJobs();
  };
}

export default new CustomersStore();
