import React, { FC } from 'react';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';

import './styles.scss';

interface IProps {
  children: React.ReactNode;
  position: { lat: number; lng: number };
}

const MapInfoBox: FC<IProps> = ({ children, position }) => {
  return (
    <InfoBox
      position={new google.maps.LatLng(position?.lat, position?.lng)}
      options={{
        closeBoxURL: ``,
        disableAutoPan: true,
        enableEventPropagation: true,
        boxClass: 'MapInfoBox',
        pixelOffset: new google.maps.Size(0, 25),
      }}
    >
      {children}
    </InfoBox>
  );
};

export default MapInfoBox;
