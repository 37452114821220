import { action, observable, computed } from 'mobx';
import isEqual from 'lodash/isEqual';
import { SimpleField } from '.';

class ListField<T> {
  @observable value: Array<SimpleField<T>>;
  initialValue: Array<SimpleField<T>>;

  constructor(value: T[] = []) {
    this.replace(value);
    this.initialValue = [...this.value];
  }

  @computed get isUpdated() {
    return !isEqual(this.value, this.initialValue) || this.value.some((item) => item.isUpdated);
  }

  @computed get isEmpty(): boolean {
    return !this.value.length;
  }

  @computed get isItemsSomeNotEmpty() {
    return this.value.some((item) => !item.isEmpty);
  }

  @computed get isItemsNotEmpty() {
    return this.value.every((item) => !item.isEmpty);
  }

  @computed get isItemsValid() {
    return this.value.every((item) => item.isValid);
  }

  @computed get isItemsNotEmptyAndValid() {
    return this.isItemsNotEmpty && this.isItemsValid;
  }

  @action add = (newItem: T) => {
    this.value = this.value || [];

    if (!this.value.some((item) => item.value === newItem)) {
      this.value.push(new SimpleField(newItem));
    }
  };

  @action remove = (removedItem: SimpleField<T>) => {
    const index = this.value.findIndex((item) => item === removedItem);
    this.value.splice(index, 1);
  };

  @action replace = (value: T[]) => (this.value = value.map((item) => new SimpleField(item)));

  @action reset = () => (this.value = [...this.initialValue]);

  toArray = (): T[] => {
    return this.value.map((item) => item.value).filter((item) => item);
  };
}

export default ListField;
