import { action, computed, observable, reaction } from 'mobx';
import { Driver, Report, ReportGroup, Vehicle } from 'models';
import { difference, find, get, orderBy, remove, sortBy } from 'lodash';
import update from 'immutability-helper';
import { DATE_TIME_FORMATS, STORAGE_ITEMS, WEB_DASHCAM_REPORTS_UPDATE } from 'config';
import { getFormattedTime, getStorageItem, setStorageItem, sortByAlphabet } from 'utils';
import { validateAccessLevel } from 'stores/acl';
import { repositoryService } from 'services';
import APIStoreBase from '../APIStoreBase';
import devicesMapStore from 'stores/Map';
import ReportTable from 'models/Report/ReportTable';
import ReportTableGroup from 'models/Report/ReportTableGroup';
import SubNavigation from 'models/SubNavigation';
import ReportDownload from 'models/Report/ReportDownload/ReportDownload';
import type Repository from 'services/RepositoryService/Repository';
import type EntityRepository from 'services/RepositoryService/EntityRepository';
import { ListField, ToggleField } from 'models/Fields';
import EventsBus from 'services/EventsBus/eventsBus';
import { NOTIFICATION_EVENT_TYPES } from 'services/Notification/NotificationEventTypes';
import Groups from 'models/Devices/Groups/Groups';

const REPORT_VALIDATIONS = [
  {
    id: 'time_since_last_media_event',
    isShow: () => validateAccessLevel([WEB_DASHCAM_REPORTS_UPDATE]),
  },
];

export class ReportsStore {
  constructor() {
    this.devicesGroups = Groups.get();
    this.repositoryVehicles = repositoryService.get('vehicles');
    this.repositoryDrivers = repositoryService.get('drivers');
    this.repositoryGroups = repositoryService.get('groups');
    this.repositoryReports = repositoryService.get('reports');
    this.repositoryEventsFleetLatest = repositoryService
      .get('events')
      .entity('fleet')
      .entity('latest');
    this.repositoryReportsGroupsDescribe = this.repositoryReports.entity('groups').entity('describe');
    this.repositoryVehicles.listenTo('error:get', () => this.resetVehiclesList());
    this.repositoryDrivers.listenTo('error:get', () => this.devicesGroups.reset());
    this.repositoryGroups.listenTo('error:get', () => this.devicesGroups.reset());
    this.downloadReport = new ReportDownload(this.repositoryReports.entity('email')).initialize(this);
    EventsBus.get().on(NOTIFICATION_EVENT_TYPES.REPORT, this.handleEvents);

    reaction(
      () => devicesMapStore.devicesStore.selectedDeviceId,
      async (selectedDeviceId) => {
        if (selectedDeviceId) {
          let vehicle;
          if (this.vehiclesList?.length) {
            vehicle = find(this.vehiclesList, ['id', selectedDeviceId]);
          } else {
            await this.getAllVehicles({});
            vehicle = find(this.vehiclesList, ['id', selectedDeviceId]);
          }
          this.setSelectedVehicle(vehicle);
        }
      }
    );

    reaction(
      () => devicesMapStore.devicesStore.filters.date,
      (date) => {
        if (date.from && date.to) {
          this.setTimeRange(date);
        }
      },
      { name: 'Update time range on date change on map' }
    );
  }

  @observable subNavigation: SubNavigation = new SubNavigation('reports');
  @observable list: Report[] = [];
  @observable groups: ReportGroup[] = [];
  @observable pinned: string[] = [];
  @observable collapsed: string[] = [];
  @observable latestEvents: Array<{ deviceId: string; latestEvent: number }> = [];
  @observable selectedReport: Report | null;
  @observable selectedReportId: string | null;
  @observable searchQuery: string = '';
  @observable timeRange: { from?: number; to?: number } = { from: null, to: null };
  @observable getReportsListRequestStatus: APIStoreBase = new APIStoreBase();
  @observable getReportsTableRequestStatus: APIStoreBase = new APIStoreBase();
  @observable getLatestEventsRequestStatus: APIStoreBase = new APIStoreBase();
  isReportExist: ToggleField = new ToggleField(true);

  /*Vehicles start*/
  @observable vehiclesList: Vehicle[] = [];
  @observable selectedVehicle: Vehicle = null;
  @observable asyncReportLoading: boolean = false;
  asyncReportLoadedWithoutError: ToggleField = new ToggleField(true);
  asyncReportLoadingTimeout: number;
  asyncReportTimeoutTimeLimit: number = 60000;
  @observable reportTaskId: string = null;
  totalTasks: number = 0;
  @observable totalTasksLeft: number = 0;
  @observable reportTable: ReportTable = null;
  @observable reportTableGroup: ReportTableGroup = null;
  @observable latestReportPreferences: Array<Array<string | boolean | number | [string, string]>> = [];
  @observable latestReportId: string = '';
  @observable requestId: number;
  @observable downloadReport: ReportDownload;
  @observable mapLinkMapType: string = 'roadmap';
  @observable vehiclesIdsWithCamera: ListField<string> = new ListField<string>([]);
  repositoryVehicles: Repository;
  repositoryDrivers: Repository;
  repositoryGroups: Repository;
  repositoryReports: Repository;
  repositoryEventsFleetLatest: EntityRepository;
  repositoryReportsGroupsDescribe: EntityRepository;

  @computed get filteredVehiclesListWithNonEmptyNames() {
    return orderBy(this.vehiclesList, ({ name }: Vehicle) => (!!name ? name.toLowerCase() : undefined), ['asc']);
  }

  @computed get reportHeaderSubOptionsText() {
    let selectedReportOptionsTitle = '';

    const selectedReportOptions = get(this.selectedReport, ['selectedReport', 'options'], []);
    if (selectedReportOptions.length && this.selectedReportId) {
      const selectedReportOption = selectedReportOptions.find(
        (item) => item.report && item.report.reportId === this.selectedReportId
      );
      if (selectedReportOption) {
        selectedReportOptionsTitle = `${selectedReportOption.displayName}`;
      }
    }

    return selectedReportOptionsTitle;
  }

  @computed get reportHeaderOptionsText() {
    const optionsKeysList = [
      'deviceId',
      'groupId',
      'option',
      'geozoneId',
      'tagId',
      'driverId',
      'maintenanceServiceType',
    ];
    const options = this.latestReportPreferences.map((option) => {
      const optionKey = get(option, [0], '');

      if (optionKey && optionsKeysList.includes(optionKey)) {
        return get(option, '[1][1]', '');
      }
    });

    options.splice(1, 0, this.reportHeaderSubOptionsText);

    return options.filter((option) => Boolean(option)).join(' | ');
  }

  @computed get reportHeaderTimeRangeText() {
    if (!(this.timeFrom && this.timeTo)) {
      return '';
    }

    return `${this.timeFrom} - ${this.timeTo} (${this.timeZone})`;
  }

  @computed get timeFrom() {
    const from = (this.latestReportPreferences.find(([key]) => key === 'from') || [])[1] || '';
    return from
      ? `${getFormattedTime((from as number) * 1000, DATE_TIME_FORMATS.monthDateYearTimeA, String(this.timeZone))}`
      : '';
  }

  @computed get timeTo() {
    const to = (this.latestReportPreferences.find(([key]) => key === 'to') || [])[1] || Math.round(Date.now() / 1000);
    return to
      ? `${getFormattedTime((to as number) * 1000, DATE_TIME_FORMATS.monthDateYearTimeA, String(this.timeZone))}`
      : '';
  }

  @computed get timeZone() {
    return (this.latestReportPreferences.find(([key]) => key === 'timeZone') || [])[1];
  }

  @action setSelectedVehicle = (vehicle: Vehicle) => (this.selectedVehicle = vehicle);

  @action setTimeRange = (timeRange: { from?: number; to?: number }) => (this.timeRange = timeRange);

  @action getAllVehicles = async ({ pageSize, page }: { pageSize?: number; page?: number }) => {
    const response = await this.repositoryVehicles.get({ pageSize, page, includeInactive: true });
    if (response) {
      const serverVehiclesList = response.items;
      this.vehiclesList = serverVehiclesList.map((serverVehicle) => new Vehicle(serverVehicle));
    }
  };

  @action getVehiclesIdsWithCameras = async () => {
    const response = await this.repositoryVehicles.get({ mediaEnabled: true });

    if (response) {
      const idsList = response.items.map((serverVehicle) => serverVehicle.deviceId);

      this.vehiclesIdsWithCamera = new ListField<string>(idsList);
    }
  };

  @action resetVehiclesList = () => (this.vehiclesList = []);
  /*Vehicles end*/

  /*Groups start*/
  devicesGroups: Groups;
  @observable excludeInactive: boolean = true;

  @action setExcludeInactive = (status: boolean) => (this.excludeInactive = status);

  /*Groups end*/

  /*Drivers start*/
  @observable driversList: Driver[] = [];
  @observable selectedDriver: Driver = null;

  @action setSelectedDriver = (driver: Driver) => (this.selectedDriver = driver);

  @computed get filteredDriversList() {
    return orderBy(
      this.driversList,
      ({ driverName, driverId }: Driver) =>
        driverName ? driverName.toLowerCase() : driverId ? driverId.toLowerCase() : undefined,
      ['asc']
    );
  }

  @action getAllDrivers = async () => {
    const serverDriversList = await this.repositoryDrivers.get();

    this.driversList = serverDriversList.items
      .map((serverGroup) => new Driver(serverGroup))
      .filter(({ driverId }) => driverId !== 'all');
  };

  @action resetDriversList = () => (this.driversList = []);

  /*Drivers end*/

  @computed get filteredList(): Report[] {
    return this.list.filter(({ title, id }) => {
      const rule = REPORT_VALIDATIONS.find((validationItem) => validationItem.id === id);
      let isValid = true;

      if (rule) {
        isValid = rule.isShow();
      }

      return title.toLowerCase().includes(this.searchQuery.toLowerCase()) && isValid;
    });
  }

  @action reOrderPinnedList = (dragIndex: number, hoverIndex: number) => {
    const dragDeviceId = this.pinned[dragIndex];
    this.pinned = update(this.pinned, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragDeviceId],
      ],
    });

    this.savePinnedList(this.pinned);
  };

  @computed get pinnedList(): Array<Report & { index: number }> {
    return sortBy(
      this.filteredList
        .filter(({ pinned }) => pinned)
        .map((item: Report) => {
          return {
            ...item,
            index: this.pinned.findIndex((id: string) => id === item.id),
          };
        }),
      ['index']
    );
  }

  @computed get listByGroups(): ReportGroup[] {
    return Object.values(
      this.filteredList.reduce((acc, next) => {
        if (!acc[next.groupId]) {
          acc[next.groupId] = { ...this.groups.find(({ id }) => next.groupId === id), reports: [] };
        }
        if (acc[next.groupId]) {
          acc[next.groupId].reports.push({
            ...next,
          });
        }
        return acc;
      }, {})
    );
  }
  @computed get listAllReports(): Report[] {
    return this.listByGroups.reduce((acc, item) => {
      return [...acc, ...item.reports];
    }, []);
  }

  @computed get listAllReportsSorted(): Report[] {
    return this.listAllReports.sort((a, b) => sortByAlphabet(a.title, b.title));
  }

  @action setSearchQuery = (query: string) => (this.searchQuery = query);
  @action resetSearchQuery = () => (this.searchQuery = '');

  findReportById = (reportId): Report | undefined => {
    return this.list.find(({ id }) => id === reportId);
  };

  @action collapseReportsById = (ids: string[]) => {
    this.collapsed = difference(this.reportGroups, ids);
    this.saveCollapsedList();
  };

  @computed
  get reportGroups() {
    return ['-0', ...this.listByGroups.map((group: ReportGroup) => group.id)];
  }

  @computed
  get activeGroups() {
    if (this.searchQuery.length) {
      return this.reportGroups;
    }
    return difference(this.reportGroups, this.collapsed);
  }

  @action pinReportById = (reportId: string, isSave = true) => {
    const report = this.findReportById(reportId);
    if (report) {
      if (report.pinned) {
        this.removeFromPinned(report);
      } else {
        this.addToPinned(report);
      }

      if (isSave) {
        this.savePinnedList();
      }
    }
  };

  @action addToPinned = (report: Report) => {
    report.setPin(true);

    if (!this.pinned.includes(report.id)) {
      this.pinned.push(report.id);
    }
  };

  @action removeFromPinned = (report: Report) => {
    report.setPin(false);
    remove(this.pinned, (id) => id === report.id);
  };

  @action processReportsList = (serverReportsList) => {
    this.resetReportsList();

    serverReportsList.forEach((group) => {
      const groupId = Report.createIdFromName(group.name);

      this.groups.push(new ReportGroup({ id: groupId, name: group.name }));
      group.subgroups.forEach((subGroup) => {
        const report = new Report({
          id: Report.createIdFromName(subGroup.name),
          pinned: false,
          title: subGroup.name,
          description: subGroup.description,
          deprecated: subGroup.deprecated,
          groupId,
          reports: subGroup.reports,
        });
        this.list.push(report);
      });
    });

    const pinned = this.getPinnedList();
    this.collapsed = this.getCollapsedList();
    pinned.forEach((id: string) => {
      this.pinReportById(id, false);
    });
  };

  @action getReportsList = async () => {
    this.getReportsListRequestStatus.reset().setLoading(true);

    try {
      const serverReportsList = await this.repositoryReportsGroupsDescribe.get();
      this.processReportsList(serverReportsList);
      this.getReportsListRequestStatus.setSuccess(true);
    } catch (error) {
      this.getReportsListRequestStatus.setError(error);
      this.resetReportsList();
    }
    this.getReportsListRequestStatus.setLoading(false);

    this.getLatestEvents();
  };

  @action resetReportsList = () => {
    if (this.reportTableGroup) {
      this.reportTableGroup.disposer();
      this.reportTableGroup.disposerFilter();
    }
    this.list = [];
  };

  @action setSelectedReport = (reportId: string) => {
    this.selectedReport = this.findReportById(reportId) || null;
    if (this.selectedReport === null) {
      this.resetReportTable();
      this.getReportsTableRequestStatus.setLoading(false);
    }
  };

  @action resetSelectedReport = () => {
    this.selectedReport = null;
  };

  @action setSelectedReportId = async (reportId: string) => {
    if (reportId) {
      this.selectedReportId = !reportId ? null : reportId;
      const report = this.list.find((report) => report.getReportById(reportId));

      if (report) {
        this.isReportExist.toggle(true);
        this.selectedReport = report;

        const selectedType = report.getReportTypeById(reportId);

        if (selectedType) {
          this.selectedReport.selectedType = selectedType;
        }
      } else {
        this.isReportExist.toggle(false);
      }
    } else {
      this.setSelectedReport(null);
    }
  };

  savePinnedList = (list?: string[]): void => {
    const pinnedList = list || this.pinned;
    setStorageItem(STORAGE_ITEMS.reports.pinnedReports, JSON.stringify(pinnedList));
  };

  getPinnedList = (): string[] => {
    return JSON.parse(getStorageItem(STORAGE_ITEMS.reports.pinnedReports)) || [];
  };

  saveCollapsedList = (list?: string[]): void => {
    const collapsedList = list || this.collapsed;
    setStorageItem(STORAGE_ITEMS.reports.collapsedGroups, JSON.stringify(collapsedList));
  };

  getCollapsedList = (): string[] => {
    return JSON.parse(getStorageItem(STORAGE_ITEMS.reports.collapsedGroups)) || [];
  };

  getReportSubGroupDefaultReport = (subGroupId: string) => {
    return this.list.find((report) => report.id === subGroupId).selectedTypeId;
  };

  @action resetReportTable = () => {
    this.asyncReportLoading = false;
    this.asyncReportLoadedWithoutError.toggle(true);
    this.reportTaskId = null;
    this.totalTasks = 0;
    this.totalTasksLeft = 0;
    this.reportTable = null;
    this.reportTableGroup = null;
    this.setRequestId(null);
  };

  @action setRequestId = (value: number) => {
    this.requestId = value;
  };

  @action setMapLinkMapType = (type: string) => (this.mapLinkMapType = type);

  isEqualToCurrent = (expected) => {
    return this.requestId !== null && this.requestId === expected;
  };

  resetLatestEvents = () => {
    this.latestEvents = [];
  };

  getLatestEventsByDeviceId = (id: string) => {
    return this.latestEvents.find(({ deviceId }) => deviceId === id);
  };

  @action getLatestEvents = async () => {
    this.getLatestEventsRequestStatus.reset().setLoading(true);
    const dateThreeMonthBefore = Date.now() - 3 * 31 * 24 * 60 * 60;

    return this.repositoryEventsFleetLatest
      .get({ to: dateThreeMonthBefore })
      .then((data) => {
        this.latestEvents = data.items
          .map((item): { deviceId: string; latestEvent: number } => {
            const latestEvent = get(item, ['eventData', '0', 'timestamp'], 0);
            return {
              deviceId: item.id,
              latestEvent,
            };
          })
          .filter((item) => item.latestEvent);

        this.getLatestEventsRequestStatus.setSuccess(true);
      })
      .catch((error) => {
        this.getLatestEventsRequestStatus.setError(error);
      })
      .then(() => {
        this.getLatestEventsRequestStatus.setLoading(false);
      });
  };

  getTail = (params: Array<Array<string | boolean | number | [string, string]>>) => {
    return params
      .map(
        ([key, value]) =>
          `${key}=${Array.isArray(value) ? value[0] : key === 'to' && !value ? Math.round(Date.now() / 1000) : value}`
      )
      .join('&');
  };

  getDefaultHeadersList = (params: Array<Array<string | boolean | number | [string, string]>>, reportType) => {
    const selectedOptionName = get(
      params.find((param) => param[0] === 'option'),
      '[1][1]',
      ''
    );

    return get(
      this.selectedReport[reportType].options.find((option) => option.displayName === selectedOptionName),
      'defaultHeaders',
      []
    );
  };

  refreshLatestReport = () => {
    if (this.latestReportId) {
      const latestReportGroup = this.getReportById(this.latestReportId);
      const asyncEnabled = latestReportGroup?.[latestReportGroup?.selectedType]?.asyncEnabled || false;
      this.loadReportTable(this.latestReportId, this.latestReportPreferences, asyncEnabled);
    }
  };

  @action
  loadReportTable = (
    id: string,
    parameters: Array<Array<string | boolean | number | [string, string]>>,
    async: boolean = false
  ) => {
    this.getReportsTableRequestStatus.reset().setLoading(true);

    const tail = this.getTail([...parameters, ['async', async]]);

    this.resetReportTable();

    const requestId = Date.now();
    this.setRequestId(requestId);

    this.repositoryReports
      .entity(`${id}?${tail}`)
      .get()
      .then((data) => {
        if (!this.isEqualToCurrent(requestId)) {
          return;
        }
        this.latestReportPreferences = parameters;
        this.latestReportId = id;

        const [timeZone] = parameters.filter(([key]) => key === 'timeZone').map(([_, value]) => value);

        if (data.length) {
          this.reportTableGroup = new ReportTableGroup(
            id,
            data,
            data[0].Definition.Subtitle,
            timeZone,
            this.excludeInactive,
            this.selectedReport?.settings(id)?.mapLinks,
            this.getDefaultHeadersList(parameters, this.selectedReport.selectedType)
          );
        } else {
          if (data && data.reportTaskId && data.totalTasks) {
            this.reportTaskId = data.reportTaskId;
            this.totalTasks = data.totalTasks;
            this.totalTasksLeft = data.totalTasks;
            this.asyncReportLoading = true;
            this.asyncReportLoadedWithoutError.toggle(true);
          } else {
            this.reportTableGroup = new ReportTableGroup(
              id,
              [data],
              data.Definition.Subtitle,
              timeZone,
              this.excludeInactive,
              this.selectedReport?.settings(id)?.mapLinks,
              this.getDefaultHeadersList(parameters, this.selectedReport.selectedType),
              true
            );
          }
        }
        this.getReportsTableRequestStatus.setSuccess(true);
      })
      .catch((error) => {
        if (this.isEqualToCurrent(requestId)) {
          this.getReportsTableRequestStatus.setError(error);
        }
      })
      .then(() => {
        this.getReportsTableRequestStatus.setLoading(false);
      });
  };

  handleEvents = (data) => {
    if (this.reportTaskId === data.reportTaskId && this.asyncReportLoading) {
      this.totalTasksLeft--;
      const reportData = JSON.parse(data.reportData || '{}');
      if (!this.reportTableGroup) {
        const [timeZone] = this.latestReportPreferences
          .filter(([key]) => key === 'timeZone')
          .map(([_, value]) => value);
        this.reportTableGroup = new ReportTableGroup(
          this.latestReportId,
          [reportData],
          reportData.Definition.Subtitle,
          timeZone,
          this.excludeInactive,
          this.selectedReport?.settings(this.latestReportId)?.mapLinks,
          this.getDefaultHeadersList(this.latestReportPreferences, this.selectedReport.selectedType)
        );
      } else {
        if (!this.excludeInactive || (reportData.Body?.length && this.excludeInactive)) {
          this.reportTableGroup.addItem(reportData);
        }
      }
      clearTimeout(this.asyncReportLoadingTimeout);

      if (this.asyncReportLoading && this.totalTasksLeft === 0) {
        this.asyncReportLoading = false;
        this.asyncReportLoadedWithoutError.toggle(true);
      } else if (this.asyncReportLoading) {
        this.asyncReportLoadingTimeout = setTimeout(() => {
          this.asyncReportLoading = false;
          this.asyncReportLoadedWithoutError.toggle(false);
        }, this.asyncReportTimeoutTimeLimit);
      }
    }
  };

  @computed get asyncReportCompleted(): string {
    return `${(100 - (this.totalTasksLeft / this.totalTasks) * 100).toFixed()}%`;
  }

  getReportById = (reportId: string) => {
    return this.list.find((report) => report.getReportById(reportId));
  };
}

export default new ReportsStore();
