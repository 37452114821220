import MaintenanceBaseTracker from './Base';
import { KM_TO_MI } from 'config';

class MaintenanceOdometerTracker extends MaintenanceBaseTracker {
  dueInDays: number;

  constructor({ odometerConfig = {}, odometerDueInDays = 0 }) {
    super({ config: odometerConfig }, KM_TO_MI);

    this.dueInDays = odometerDueInDays;
  }
}

export default MaintenanceOdometerTracker;
