import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import queryString from 'query-string';

import { PATHS } from 'config';

import Button from 'components/Button';
import BackButton from 'components/BackButton';

import './styles.scss';

interface IProps {
  children: React.ReactNode;
  disabledSteps?: number[];
  currentStep: 1 | 2 | 3;
  nextDisabled?: boolean;
  nextPath?: string;
  onSubmit?: () => void;
  prevPath?: string;
  isLoading?: boolean;
  isRequestLoading?: boolean;
  isShowSidebar?: boolean;
  className?: string;
}

const MediaManagerWrapper: React.FC<IProps> = ({
  children,
  currentStep,
  prevPath,
  nextDisabled,
  nextPath,
  isLoading = false,
  isRequestLoading = false,
  isShowSidebar = true,
  disabledSteps = [],
  onSubmit,
  className = '',
}) => {
  const nextStepCn = (curStep, stepNum) => {
    return classNames('MediaManagerWrapper-navigationStepLink', {
      'MediaManagerWrapper-navigationStepLink--nextStep': curStep < stepNum,
      'MediaManagerWrapper-navigationStepLink--prevStep': curStep > stepNum,
      'MediaManagerWrapper-navigationStepLink--active': curStep === stepNum,
    });
  };
  const stepCn = (stepNum) => {
    return classNames('MediaManagerWrapper-navigationStep', {
      disabled: disabledSteps.includes(stepNum),
    });
  };

  const mediaManagerWrapperCn = classNames('MediaManagerWrapper', className, {
    loading: isLoading,
  });
  const nextButtonCn = classNames('MediaManagerWrapper-actions--next MediaManagerWrapper-actions--button', {
    'MediaManagerWrapper-actions--disabled': nextDisabled,
  });
  const getMediaManagerLink = () => {
    const assetId = queryString.parse(window.location.search).assetId;
    return assetId ? `${PATHS.DASHCAMS.MEDIA_MANAGER}?assetId=${assetId}` : PATHS.DASHCAMS.MEDIA_MANAGER;
  };

  return (
    <div className={mediaManagerWrapperCn}>
      {isShowSidebar && (
        <div className="MediaManagerWrapper-sidebar">
          <p className="MediaManagerWrapper-sidebarHeader">Request media</p>
          <p className="MediaManagerWrapper-sidebarSubHeader">Request media in three easy steps</p>
          <nav className="MediaManagerWrapper-navigation">
            <ul className="MediaManagerWrapper-navigationSteps">
              <li className={stepCn(1)}>
                <NavLink
                  className={nextStepCn(currentStep, 1)}
                  to={`${PATHS.DASHCAMS.MEDIA_REQUEST.STEP_1}${queryString.exclude(window.location.search, [
                    'timestamp',
                  ])}`}
                >
                  <span className="MediaManagerWrapper-navigationStepLinkIcon">1</span>
                  <span className="MediaManagerWrapper-navigationStepLinkText">Date & Media Journal</span>
                </NavLink>
              </li>
              <li className={stepCn(2)}>
                <NavLink
                  className={nextStepCn(currentStep, 2)}
                  to={`${PATHS.DASHCAMS.MEDIA_REQUEST.STEP_2}${window.location.search}`}
                >
                  <span className="MediaManagerWrapper-navigationStepLinkIcon">2</span>
                  <span className="MediaManagerWrapper-navigationStepLinkText">Preview & Select Clip</span>
                </NavLink>
              </li>
              <li className={stepCn(3)}>
                <NavLink
                  className={nextStepCn(currentStep, 3)}
                  to={`${PATHS.DASHCAMS.MEDIA_REQUEST.STEP_3}${window.location.search}`}
                >
                  <span className="MediaManagerWrapper-navigationStepLinkIcon">3</span>
                  <span className="MediaManagerWrapper-navigationStepLinkText">Finalize</span>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      )}
      <div className="MediaManagerWrapper-container">
        <div className="MediaManagerWrapper-back">
          <BackButton link={getMediaManagerLink()}>Back to Dashcam Media</BackButton>
        </div>
        <div className="MediaManagerWrapper-content">{children}</div>
        <div className="MediaManagerWrapper-actions">
          <div className="MediaManagerWrapper-actions--prev MediaManagerWrapper-actions--button">
            {prevPath && (
              <NavLink to={prevPath}>
                <Button title="Go Back" className="Button--inline" />
              </NavLink>
            )}
          </div>
          <div className={nextButtonCn}>
            {nextPath ? (
              <NavLink to={nextPath}>
                <Button title="Next" className="Button--inline" disabled={nextDisabled} />
              </NavLink>
            ) : (
              <Button
                title="Request Media"
                className="Button--inline"
                onClick={onSubmit}
                disabled={nextDisabled}
                sending={isRequestLoading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaManagerWrapper;
