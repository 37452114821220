import React from 'react';

import { ReportType } from 'models/Report/Report';

import RadioSwitcher from '../RadioSwitcher';
import GroupIdSelector from '../GroupIdSelector';
import SearchableVehicleSelect from 'containers/Select/SearchableVehicleSelect';

import './styles.scss';

interface IProps {
  byGroup: Report.IServerReportByGroup;
  byVehicle: Report.IServerReportByVehicle;
  excludeInactive?: boolean;
  hasExclude?: boolean;
  onExcludeChange?: (status: boolean) => void;
  onGroupChange?: (option: Select.ISelectOption) => void;
  onVehicleChange?: (option: Select.ISelectOption) => void;
  redirectToReportPageById: (id: string) => void;
  selectedGroup?: Select.ISelectOption;
  selectedType?: ReportType;
  selectedVehicle?: Select.ISelectOption;
  selectFirst?: boolean;
  radioName?: string;
}

const getId = (items: Report.IServerReportByVehicle | Report.IServerReportByGroup) => {
  return items.reportId || (items.options && items.options.length && items.options[0].report.reportId) || null;
};

const VehicleAndGroupSwitcher: React.FC<IProps> = ({
  byGroup,
  byVehicle,
  children,
  excludeInactive = true,
  hasExclude = true,
  onExcludeChange = () => true,
  onGroupChange,
  onVehicleChange,
  redirectToReportPageById,
  selectedGroup,
  selectedType = ReportType.noSelection,
  selectedVehicle,
  selectFirst,
  radioName,
}) => {
  const handleChange = (type: string) => {
    if (type === ReportType.byVehicle) {
      redirectToReportPageById(getId(byVehicle));
    } else if (type === ReportType.byGroup) {
      redirectToReportPageById(getId(byGroup));
    }
  };

  return (
    <div className="ReportPreferences-row">
      <RadioSwitcher
        onChange={handleChange}
        checkedValue={selectedType}
        radioGroupName={radioName}
        byVehicle={
          <>
            <div>
              <div className="ReportPreferences-label">Vehicle</div>
              <SearchableVehicleSelect
                value={selectedVehicle}
                handleChange={onVehicleChange}
                persistChange
                selectFirst={selectFirst}
              />
            </div>
            <div>{children}</div>
          </>
        }
        byGroup={
          <>
            <div>
              <div className="ReportPreferences-label">Group</div>
              <GroupIdSelector
                excludeInactive={excludeInactive}
                hasExclude={hasExclude}
                onExcludeChange={onExcludeChange}
                onGroupChange={onGroupChange}
                value={selectedGroup}
              />
            </div>
            <div>{children}</div>
          </>
        }
      />
    </div>
  );
};

export default VehicleAndGroupSwitcher;
