import type { PersonPermissionsDriverError } from 'interfaces/models/IPeopleAdmin/IPerson';
import Asset from 'models/Assets/Asset';
import { SimpleField, ToggleField } from 'models/Fields';
import StateModel from 'models/General/StateModel';

class PersonPermissionsDriverModel {
  active: ToggleField;
  licenseType: SimpleField<string>;
  licenseState: SimpleField<StateModel>;
  licenseNumber: SimpleField<string>;
  licenseExpiration: SimpleField<number>;
  assignedVehicle: Asset;
  keyFob: SimpleField<{ keyFob: string; id: string }>;
  restrictUserAccess: ToggleField;
  errorField: SimpleField<PersonPermissionsDriverError>;
  isShowErrorPopup: ToggleField;

  constructor(driverDetails) {
    const details = driverDetails || {};

    this.active = new ToggleField(Boolean(driverDetails));
    this.licenseType = new SimpleField(details.licenseType || '');
    this.licenseState = new SimpleField(new StateModel(details.licenseState));
    this.licenseNumber = new SimpleField(details.licenseNumber || '');
    this.licenseExpiration = new SimpleField(details.licenseExpiration);
    this.assignedVehicle = new Asset(details.asset || {});
    this.keyFob = new SimpleField(details.keyFob || { keyFob: '-', id: 'none' });
    this.restrictUserAccess = new ToggleField(Boolean(details.accessRestrictedToAssignedAssets));
    this.errorField = new SimpleField(null);
    this.isShowErrorPopup = new ToggleField(false);
  }

  get data() {
    return this.active.value
      ? {
          licenseType: this.licenseType.value,
          licenseState: {
            state: this.licenseState.value.state,
            code: this.licenseState.value.code === '--' ? '' : this.licenseState.value.code.toLowerCase(),
          },
          licenseNumber: this.licenseNumber.value,
          licenseExpiration: this.licenseExpiration.value,
          asset: this.assignedVehicle.data,
          keyFob: this.keyFob.value.id === 'none' ? { keyFob: '', id: null } : this.keyFob.value,
          accessRestrictedToAssignedAssets: this.restrictUserAccess.value,
        }
      : null;
  }

  get isUpdated() {
    return (
      this.active.isUpdated ||
      this.licenseType.isUpdated ||
      this.licenseState.isUpdated ||
      this.licenseNumber.isUpdated ||
      this.licenseExpiration.isUpdated ||
      this.assignedVehicle.id.isUpdated ||
      this.keyFob.isUpdated ||
      this.restrictUserAccess.isUpdated
    );
  }

  get isAssignedVehicleUpdated() {
    return this.assignedVehicle.id.isUpdated || this.restrictUserAccess.isUpdated;
  }

  reset = () => {
    this.active.reset();
    this.licenseType.reset();
    this.licenseState.reset();
    this.licenseNumber.reset();
    this.licenseExpiration.reset();
    this.assignedVehicle.id.reset();
    this.assignedVehicle.name.reset();
    this.keyFob.reset();
    this.restrictUserAccess.reset();
  };
}

export default PersonPermissionsDriverModel;
