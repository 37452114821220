import React, { FC } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import type { IDashcamSettingsField } from 'interfaces/models/Dashcams/IDashcamSettingsField';
import type { ToggleField } from 'models/Fields';

import InfoTooltip from 'components/InfoTooltip';
import Toggle from 'components/Toggle';

import { EmptySvgIcon } from 'assets';

import './styles.scss';

interface IProps {
  item: IDashcamSettingsField<ToggleField>;
  disabled?: boolean;
  availableCameraTypes?: string[];
}

const CameraSettingsItemBoolean: FC<IProps> = ({ item, disabled = false, availableCameraTypes = [] }) => {
  const isCameraTypesMissed =
    item.cameraType && availableCameraTypes.length && !availableCameraTypes.includes(item.cameraType);

  const handleChange = () => {
    item.value.toggle();
    item.context.updateLinkSettings(item.linkedSettings, item.value.value, item.name);
  };

  return (
    <div
      className={classnames('CameraSettingsItem CameraSettingsItemBoolean', {
        'CameraSettingsItem--disabled CameraSettingsItemBoolean--disabled': disabled,
      })}
    >
      <div className="CameraSettingsItem-info CameraSettingsItemBoolean-info">
        <div className="CameraSettingsItem-name CameraSettingsItemBoolean-name">{item.name}</div>
        {item.description && (
          <InfoTooltip className="CameraSettingsItem-infoTooltip CameraSettingsItemBoolean-infoTooltip">
            {item.description}
          </InfoTooltip>
        )}
        {isCameraTypesMissed && (
          <InfoTooltip className="CameraSettingsItem-infoTooltip CameraSettingsItemBoolean-infoTooltip">
            This setting can only be adjusted while the camera is online and a Driver Facing cam is installed.
          </InfoTooltip>
        )}
      </div>
      <div className="CameraSettingsItem-action CameraSettingsItemBoolean-action">
        <Toggle
          disabled={disabled || isCameraTypesMissed}
          className="CameraSettingsItem-toggle CameraSettingsItemBoolean-toggle"
          checked={disabled || isCameraTypesMissed ? false : item.value.value}
          onChange={handleChange}
        />
        {disabled && (
          <InfoTooltip
            icon={<EmptySvgIcon />}
            className="CameraSettingsItem-actionTooltip CameraSettingsItemBoolean-actionTooltip"
          >
            Enable {item.parent} to access this control
          </InfoTooltip>
        )}
      </div>
    </div>
  );
};

export default observer(CameraSettingsItemBoolean);
