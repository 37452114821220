import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { CustomersStore } from 'stores';

interface IProps {
  customersStore?: CustomersStore;
}


@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class SecondaryPanelEstimates extends Component<IProps> {
  render() {
    return <div className="SecondaryPanelJobEstimates">
      <div className="SecondaryPanelJobEstimates-header">
        <div>Estimate</div>
        <div>Date</div>
        <div>Total</div>
      </div>
      <div className="SecondaryPanelJobEstimates-list">
        <span>-</span>
      </div>
    </div>;
  }
}

export default SecondaryPanelEstimates;
