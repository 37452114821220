import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { CloseIcon, HistoryEventIcon } from 'assets';

import { HISTORY_MODE } from 'config';

import Icon from 'components/Icon';
import InfoTooltip from 'components/InfoTooltip';
import HistoryToolBarCalendar from './HistoryToolBarCalendar';
import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';

import { getDayStartValue, getNowTimestampForTimezone } from 'utils';
import type { DevicesStore, MapStore, PersistenceStore, TimeStore } from 'stores';

import './styles.scss';

interface IProps {
  devicesStore?: DevicesStore;
  mapStore?: MapStore;
  timeStore?: TimeStore;
  persistenceStore?: PersistenceStore;
}

@inject(({ devicesMapStore: { devicesStore, mapStore }, timeStore, persistenceStore }) => ({
  devicesStore,
  mapStore,
  timeStore,
  persistenceStore,
}))
@observer
class HistoryToolBar extends Component<IProps> {
  handleCloseClick = () => {
    const {
      devicesStore: { setHistory, filters },
    } = this.props;

    filters.resetDate();
    setHistory(false);
  };

  get selectedDevice() {
    const {
      devicesStore: { selectedDevice },
    } = this.props;
    return selectedDevice;
  }

  get isDeviceSelected() {
    return Boolean(this.selectedDevice);
  }

  handleModeChange = async ({ value }) => {
    const {
      devicesStore: {
        history: { setMode, activeMode },
        filters: { applyDate, applyTempDate, date },
      },
      mapStore: { timezone },
    } = this.props;
    const previousMode = activeMode.id;

    setMode(value);
    if (previousMode === HISTORY_MODE.POINT_IN_TIME && value === HISTORY_MODE.TIME_RANGE) {
      if (date && date.to) {
        const to = date.to;
        const from = getDayStartValue(to, timezone);
        await applyDate({ from, to });
        await applyTempDate({ from, to });
      }
    } else if (value === HISTORY_MODE.MOST_RECENT_ACTIVITY) {
      const to = getNowTimestampForTimezone(timezone);
      let from = this.lastEvent;
      if (date && date.from) {
        from = date.from;
      }
      await applyDate({ from, to });
      await applyTempDate({ from, to });
    }
  };

  handleApplyDate = async ({ from, to }) => {
    const {
      devicesStore: {
        history: { activeMode },
        filters: { applyDate, applyTempDate },
      },
    } = this.props;

    if (activeMode.id === HISTORY_MODE.TIME_RANGE || activeMode.id === HISTORY_MODE.MOST_RECENT_ACTIVITY) {
      this.props.persistenceStore.updateSelectedTimeRange(from, to);
    }

    await applyDate({ from, to });
    await applyTempDate({ from, to });
  };

  get lastEvent() {
    return this.selectedDevice?.latestPossibleEvent?.timestamp;
  }

  get latestRegisteredEvent() {
    return this.selectedDevice?.latestRegisteredEventTimestamp;
  }

  componentDidMount() {
    const {
      devicesStore: {
        history: { activeMode, setMode },
      },
    } = this.props;

    if (!this.isDeviceSelected && activeMode.id !== HISTORY_MODE.POINT_IN_TIME) {
      setMode(HISTORY_MODE.POINT_IN_TIME);
    }
  }

  render() {
    const isDeviceSelected = this.isDeviceSelected;
    const {
      devicesStore: {
        filters,
        history: { modes, activeMode },
      },
      mapStore: { timezone },
    } = this.props;

    const withToField = isDeviceSelected && activeMode.id !== HISTORY_MODE.POINT_IN_TIME;

    let from = withToField ? Date.now() : undefined;
    let to = Date.now();

    if (activeMode.id === HISTORY_MODE.TIME_RANGE && isDeviceSelected && filters.date.to) {
      to = filters.date.to;
      from = filters.date.from;
    }

    const containerCN = classNames('HistoryToolBar-container', {
      'HistoryToolBar-container--withDevice': isDeviceSelected,
    });

    return (
      <div className={containerCN}>
        <div className="HistoryToolBar-info">
          <Icon Icon={HistoryEventIcon} size={20} iconClassName="HistoryToolBar-infoIcon" />
          <span className="HistoryToolBar-info-title">Map History</span>
          <InfoTooltip className="HistoryToolBar-info-tooltip">
            <div className="HistoryToolBar-info-tooltipContent">
              <p>
                The history available for a group of vehicles will show the vehicle locations at a single point in time.
              </p>
              <p>The history available for a single vehicle will show the path for that vehicle for a range of time.</p>
            </div>
          </InfoTooltip>
        </div>
        {isDeviceSelected ? (
          <div className="HistoryToolBar-mode">
            <SearchableStaticSelect
              isSearchable={false}
              value={{ label: activeMode.title, value: activeMode.id }}
              values={[
                ...modes.map(({ id, title }) => {
                  return { label: title, value: id };
                }),
              ]}
              handleChange={this.handleModeChange}
            />
          </div>
        ) : null}

        <div className="HistoryToolBar-calendar">
          <HistoryToolBarCalendar
            from={from}
            to={to}
            timezone={timezone}
            onApplyDate={this.handleApplyDate}
            lastEvent={this.latestRegisteredEvent}
            firstEvent={this.selectedDevice?.firstEventTimestamp}
            fromMonth={this.selectedDevice?.details?.creationDate}
            deviceId={this.selectedDevice?.data?.deviceId}
            mode={activeMode.id}
          />
        </div>
        <div className="HistoryToolBar-close">
          <button onClick={this.handleCloseClick} className={'HistoryToolBar-closeButton'}>
            <span className="HistoryToolBar-closeButton-title">Back to Live</span>
            <CloseIcon />
          </button>
        </div>
      </div>
    );
  }
}

export default HistoryToolBar;
