import React, { useCallback } from 'react';
import classNames from 'classnames';
import { InputNumber as AntInputNumber } from 'antd';

import './styles.scss';

interface IProps {
  className?: string;
  controls?: boolean;
  disabled?: boolean;
  error?: boolean;
  label?: string;
  max?: string;
  min?: string;
  step?: string;
  name?: string;
  prefix?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  placeholder?: string;
  value?: string;
  size?: 'small' | 'middle' | 'large' | undefined;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const InputNumber: React.FC<IProps> = ({
  className,
  controls,
  disabled = false,
  error = false,
  label,
  max,
  min,
  name,
  prefix,
  onChange,
  onBlur = () => void 0,
  placeholder,
  value,
  size,
  step,
  inputRef = null,
}) => {
  const cn = classNames('InputNumber', className, {
    'InputNumber--disabled': disabled,
    'InputNumber--error': error,
  });

  const handleBlur = useCallback(() => {
    onBlur?.(value);
  }, [onBlur, value]);

  return (
    <div className={cn}>
      {label && <div className="InputNumber-label">{label}</div>}
      <div className="InputNumber-input">
        <AntInputNumber
          ref={inputRef}
          disabled={disabled}
          max={max}
          min={min}
          name={name}
          prefix={prefix}
          onChange={onChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          value={value}
          controls={controls}
          size={size}
          step={step}
        />
      </div>
    </div>
  );
};

export default InputNumber;
