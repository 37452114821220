import React from 'react';
import { observer } from 'mobx-react';
import { PauseIcon, PlayArrowIcon } from 'assets';
import classnames from 'classnames';
import type PlayerState from 'models/Dashcam/MediaRequestCheckout/Player/PlayerState';
import PlayingPlayerState from 'models/Dashcam/MediaRequestCheckout/Player/PlayingPlayerState';

import './styles.scss';

interface IProps {
  state: PlayerState;
  isDisabled: boolean;
  onClick: () => void;
}

const MediaManagerPlayerControlButton: React.FC<IProps> = ({ onClick, state, isDisabled }) => {
  const pauseCn = classnames('MediaManagerControlButton-pause', {
    disabled: isDisabled,
  });

  const playCn = classnames('MediaManagerControlButton-play', {
    disabled: isDisabled,
  });

  return (
    <div onClick={onClick} className="MediaManagerControlButton">
      {state instanceof PlayingPlayerState ? (
        <PauseIcon className={pauseCn} />
      ) : (
        <PlayArrowIcon className={playCn} width={11} height={14} />
      )}
    </div>
  );
};

export default observer(MediaManagerPlayerControlButton);
