import { IReactionDisposer, reaction } from 'mobx';
import { ToggleField } from 'models/Fields';
import type { PERSIST_FILTER_NAMES } from './MediaTypeCategories';
import { getJSONItemFromStorage, setJSONItemToStorage } from 'utils';

export default class MediaTypeToggleFilter {
  count: number;
  icon: boolean;
  isSelected: ToggleField;
  name: string;
  value: string;
  persistName: PERSIST_FILTER_NAMES;
  isSelectedDisposer: IReactionDisposer;

  constructor({ count, icon, isSelected, name, value }, persistName) {
    this.count = count;
    this.icon = icon;
    this.isSelected = new ToggleField(this.defaultSelectValue(persistName, isSelected));
    this.name = name;
    this.value = value;
    this.persistName = persistName;

    this.isSelectedDisposer = reaction(
      () => this.isSelected.value,
      (value) => {
        setJSONItemToStorage(this.persistName, value);
      },
      { name: 'Persist filter value' }
    );
  }

  destroy = () => {
    this.isSelectedDisposer();
  };

  private defaultSelectValue = (persistName, isSelected) => {
    return persistName ? getJSONItemFromStorage(persistName, isSelected) : isSelected;
  };
}
