import React from 'react';
import classNames from 'classnames';
import { ClockIcon, Vehicle2Icon } from 'assets';

import type ApplyTaskCard from 'models/Maintenances/Representation/ApplyTaskCard';

import './styles.scss';

interface IProps {
  task: ApplyTaskCard;
  direction?: 'row' | 'column';
}

const TaskToApplyDueInOnMetrics: React.FC<IProps> = ({ task, direction = 'column' }) => {
  const cn = classNames('TaskToApplyDueInOnMetrics', `TaskToApplyDueInOnMetrics--${direction}`);

  return (
    <div className={cn}>
      {task.rows.map((row, index) => (
        <div className="TaskToApplyDueInOnMetrics-row" key={index}>
          <div className="TaskToApplyDueInOnMetrics-rowIcon">
            {row.icon === 'car' ? <Vehicle2Icon /> : <ClockIcon />}
          </div>
          <div className="TaskToApplyDueInOnMetrics-rowText">{row.text}</div>
        </div>
      ))}
    </div>
  );
};

export default TaskToApplyDueInOnMetrics;
