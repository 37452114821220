class Location {
  lng: number;
  lat: number;

  constructor(location) {
    this.set(location);
  }

  set = ({ lng, lat } = { lng: 0, lat: 0 }) => {
    this.lng = lng;
    this.lat = lat;
  };

  get isAvailable() {
    return Boolean(this.lng) && Boolean(this.lat);
  }
}

export default Location;
