import React from 'react';
import { observer } from 'mobx-react';

import './styles.scss';

interface IProps {
  imageData: string;
  onClick: () => void;
}

const MediaManagerPlayerImage: React.FC<IProps> = ({ imageData }) => {
  return (
    <div className="MediaManagerPlayerDisplay">{imageData && <img src={`data:image/jpeg;base64,${imageData}`} />}</div>
  );
};

export default observer(MediaManagerPlayerImage);
