export const APP_EVENTS = {
  DEVICES: {
    GET: 'devices:get',
    FILTER: 'devices:filter',
    SEARCH: 'devices:search',
    PINNED: 'devices:pinned',
    DEVICE: {
      EVENT: {
        UPDATE: 'devices:device:event:update',
      },
      CHANGED: 'devices:device:changed',
      DETAILS: {
        GET: 'device:details:get',
      },
    },
  },
  VEHICLES: {
    LIST: {
      GET: 'vehicles:list:get',
    },
  },
  GROUPS: {
    UPDATED: 'groups:updated',
    GROUP: {
      CHANGED: 'groups:group:changed',
      ASSETS: {
        CHANGED: 'groups:group:assets:changed',
      },
    },
  },
  SESSION_EXPIRED: 'session:expired',
  UPDATE_TOKEN: 'update:token',
  ACL_UPDATED: 'acl:updated',
  DASHCAM_STATUS: 'dashcam:status',
  MAINTENANCE: {
    COMPLETED: {
      UPDATED: 'maintenance:completed:updated',
      DELETED: 'maintenance:completed:deleted',
    },
    UPCOMING: {
      GROUP: { CHANGED: 'maintenance:upcoming:group:changed' },
    },
    TASK: {
      ASSET: {
        CHANGED: 'maintenance:task:asset:changed',
      },
    },
    APPLY_TASKS: {
      ALL: {
        ADDED: 'maintenance:apply:tasks:all:added',
      },
      ADDED: 'maintenance:apply:tasks:added',
      REMOVED: 'maintenance:apply:tasks:removed',
      SORT: 'maintenance:apply:tasks:sort',
      TASK: {
        LOADING: 'maintenance:apply:tasks:task:loading',
        CREATED: 'maintenance:apply:tasks:task:created',
        CONVERTED: 'maintenance:apply:tasks:task:converted',
        ERROR: {
          CREATED: 'maintenance:apply:tasks:task:error:created',
          CONVERTED: 'maintenance:apply:tasks:task:error:converted',
        },
      },
    },
  },
  USER: {
    LOGOUT: 'user:logout',
    DATA: {
      GET: 'user:data:get',
    },
  },
  ERROR: {
    UNEXPECTED: 'error:unexpected',
  },
};
