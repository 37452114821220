import React, { FC } from 'react';
import classNames from 'classnames';

import './styles.scss';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';

interface IProps {
  className?: string;
  reportName: string;
  reportOptions: string;
  reportTimeRange: string;
  withControls?: boolean;
  expanded?: boolean;
  onChange?: () => void;
}

const ReportTableHeaderTitle: FC<IProps> = ({
  className,
  reportName,
  reportOptions,
  reportTimeRange,
  withControls,
  expanded,
  onChange,
}) => {
  const cn = classNames('ReportTableHeaderTitle', className);

  return (
    <div className={cn}>
      {withControls && (
        <div className="ReportTableHeaderTitle-section ReportTableHeaderTitle-section--toggle" onClick={onChange}>
          {expanded ? (
            <MinusSquareOutlined className="ReportTableHeaderTitle-rowExpandIcon ReportTableHeaderTitle-rowExpandIcon--minus" />
          ) : (
            <PlusSquareOutlined className="ReportTableHeaderTitle-rowExpandIcon ReportTableHeaderTitle-rowExpandIcon--plus" />
          )}
        </div>
      )}
      <div className="ReportTableHeaderTitle-section ReportTableHeaderTitle-section--reportName">
        <h4 className="ReportTableHeaderTitle-reportName">{reportName} Report</h4>
      </div>
      {Boolean(reportOptions) && (
        <div className="ReportTableHeaderTitle-section ReportTableHeaderTitle-section--options">
          <p className="ReportTableHeaderTitle-sectionTitle">Options</p>
          <p className="ReportTableHeaderTitle-sectionText">{reportOptions}</p>
        </div>
      )}
      {Boolean(reportTimeRange) && (
        <div className="ReportTableHeaderTitle-section ReportTableHeaderTitle-section--timeRange">
          <p className="ReportTableHeaderTitle-sectionTitle">Time Range</p>
          <p className="ReportTableHeaderTitle-sectionText">{reportTimeRange}</p>
        </div>
      )}
    </div>
  );
};

export default ReportTableHeaderTitle;
