import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { getFormattedTime } from 'utils';

import type { CustomersStore } from 'stores';

import SecondaryPanelAttributesRow from '../../../SecondaryPanelAttributes/SecondaryPanelAttributesRow';
import SecondaryPanelAttributesCol from '../../../SecondaryPanelAttributes/SecondaryPanelAttributesCol';

interface IProps {
  customersStore?: CustomersStore;
}


@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class SecondaryPanelEstimateDetails extends Component<IProps> {

  render() {
    const customer = this.props.customersStore.selectedCustomer;
    const estimate = customer.selectedEstimate;

    return <div className="SecondaryPanelEstimateDetails">
      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Date</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{estimate.transactionDate ? getFormattedTime(estimate.transactionDate, 'MM/DD/YYYY') : '-'}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>
      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Status</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{estimate.status || '-'}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>
    </div>;
  }
}

export default SecondaryPanelEstimateDetails;
