import { ListField, SimpleField } from 'models/Fields';
import PersonAccessRoleModel from './PersonAccess/PersonAccessRoleModel';
import PersonAccessAppModel from './PersonAccess/PersonAccessAppModel';

enum APP_ACCESS_TYPE {
  WEB = 'web',
  MOBILE = 'managerApp',
}

class PersonAccessModel {
  webPortalAccess: PersonAccessAppModel;
  mobileAppAccess: PersonAccessAppModel;
  roles: ListField<PersonAccessRoleModel>;
  timeZone: SimpleField<string>;

  constructor(item) {
    const appAccesses = item.appAccesses || [];

    this.webPortalAccess = new PersonAccessAppModel(
      appAccesses.find((access) => access.name === APP_ACCESS_TYPE.WEB) || {
        id: null,
        lastLoginTime: 0,
        name: APP_ACCESS_TYPE.WEB,
      }
    );
    this.mobileAppAccess = new PersonAccessAppModel(
      appAccesses.find((access) => access.name === APP_ACCESS_TYPE.MOBILE) || {
        id: null,
        lastLoginTime: 0,
        name: APP_ACCESS_TYPE.MOBILE,
      }
    );
    this.roles = new ListField(item.roles?.map((role) => new PersonAccessRoleModel(role)));
    this.timeZone = new SimpleField(item.timeZone);
  }

  get data() {
    const appAccesses = [];

    if (this.webPortalAccess.active.value) {
      appAccesses.push(this.webPortalAccess.data);
    }

    if (this.mobileAppAccess.active.value) {
      appAccesses.push(this.mobileAppAccess.data);
    }

    return {
      appAccesses,
      roles: this.roles.toArray().map((role) => role.data),
      timeZone: this.timeZone.value,
    };
  }

  get isUpdated() {
    return (
      this.webPortalAccess.isUpdated ||
      this.mobileAppAccess.isUpdated ||
      this.roles.isUpdated ||
      this.timeZone.isUpdated
    );
  }

  reset = () => {
    this.webPortalAccess.reset();
    this.mobileAppAccess.reset();
    this.roles.reset();
    this.timeZone.reset();
  };
}

export default PersonAccessModel;
