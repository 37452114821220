import type { RouteReplaySpeedOption } from './IRouteReplay';
import RouteReplaySpeed from './RouteReplaySpeed';
import RouteReplayState from './RouteReplayState';
import type DevicesFilters from 'stores/Map/DevicesFilters';

export class RouteReplay {
  state: RouteReplayState;
  speed: RouteReplaySpeed;

  constructor({
    initialPlayState,
    initialSpeed,
    speedOptions,
    context,
    mapFilters,
  }: {
    initialPlayState?: boolean;
    initialSpeed?: RouteReplaySpeedOption;
    speedOptions?: RouteReplaySpeedOption[];
    context;
    mapFilters?: DevicesFilters;
  }) {
    this.speed = new RouteReplaySpeed(initialSpeed, speedOptions);
    this.state = new RouteReplayState(initialPlayState, this.speed, context, mapFilters);
  }

  reset = () => {
    this.state.reset();
    this.speed.reset();
  };
}

export default RouteReplay;
