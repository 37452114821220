import type WS from './WebSocket';

abstract class WSState {
  context: WS;

  public setContext = (context: WS) => {
    this.context = context;
  };

  abstract activate(): void;
}

export default WSState;
