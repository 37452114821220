import 'react-app-polyfill/ie11';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import smoothscroll from 'smoothscroll-polyfill';

import { createStores } from 'stores';

import App from './App';
import ErrorPage from './components/ErrorPage';

// import * as serviceWorker from './serviceWorker';

import './index.scss';
import ErrorBoundary from './components/ErrorBoundary';

// prepare MobX stores
const history = createBrowserHistory({ basename: '/web' });
const rootStore = createStores(history);

if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'develop') {
  // @ts-ignore
  window.rootStore = rootStore;
}

smoothscroll.polyfill();

// render react DOM
ReactDOM.render(
  <Provider {...rootStore}>
    <ErrorBoundary fallback={<ErrorPage />}>
      <App history={history} />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
