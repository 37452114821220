import { observable, action, computed } from 'mobx';
import { DATE_TIME_FORMATS } from 'config';
import timeStore from 'stores/TimeStore';
import { getTimeInTimeZone } from 'utils';
import isEqual from 'lodash/isEqual';

class TimeField {
  @observable value: number = 0;
  initialValue: number;
  private format: DATE_TIME_FORMATS = DATE_TIME_FORMATS.fullFormatDate;

  constructor(value: number) {
    this.value = value;
    this.initialValue = value;
  }

  @action set = (value) => (this.value = value);

  setFormat = (format: DATE_TIME_FORMATS) => {
    this.format = format;
    return this;
  };

  @action reset = () => (this.value = this.initialValue);

  @action reInitialize = (value: number) => {
    this.initialValue = value;
    this.value = value;
  };

  @computed get isUpdated(): boolean {
    return !isEqual(this.value, this.initialValue);
  }

  @computed get date(): string {
    return this.value ? getTimeInTimeZone(this.value, timeStore.sessionTimezone).format(this.format) : '';
  }

  @computed get userDate(): string {
    return this.value ? getTimeInTimeZone(this.value, timeStore.userTimezone).format(this.format) : '';
  }
}

export default TimeField;
