import React from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  className?: string;
  children: React.ReactNode;
}

const LoginSlide: React.FC<IProps> = ({ className, children }) => {
  const cn = classNames('LoginSlide', className);

  return <div className={cn}>{children}</div>;
};

export default LoginSlide;
