import React from 'react';
import classnames from 'classnames';

import './styles.scss';

interface IProps {
  title: string;
  children: React.ReactNode;
  alignTitle?: 'top' | 'center' | 'bottom';
}

const InspectionsFormPanelLine: React.FC<IProps> = ({ title, children, alignTitle = 'center' }) => {
  const cnTitle = classnames('InspectionsFormPanelLine-title', `InspectionsFormPanelLine-title--${alignTitle}`);

  return (
    <div className="InspectionsFormPanelLine">
      <div className={cnTitle}>{title}</div>
      <div className="InspectionsFormPanelLine-content">{children}</div>
    </div>
  );
};

export default InspectionsFormPanelLine;
