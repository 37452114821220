import React, { FC } from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  align?: 'left' | 'right' | 'center';
  className?: string;
  color?: string;
  forId?: string;
  inline?: boolean;
  rounded?: boolean;
  size?: 'medium' | 'large' | 'small';
  text: string | React.ReactElement;
  type?: string;
}

const Label: FC<IProps> = (
  {
    className,
    color = 'grey',
    forId,
    size = 'medium',
    text,
    type,
    rounded = false,
    align,
    inline = false,
  }
) => {
  const cn = classNames('Label', className, {
    [`Label--${size}`]: Boolean(size),
    [`Label--${color}`]: Boolean(color),
    [`Label--${type}`]: Boolean(type),
    [`Label--align-${align}`]: Boolean(align),
    [`Label--rounded`]: Boolean(rounded),
    [`Label--inline`]: Boolean(inline),
  });

  return (
    <label htmlFor={forId} className={cn}>
      {text}
    </label>
  );
};

export default Label;
