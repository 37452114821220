import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import SearchableSelect from 'components/Select/SearchableSelect';

import type { GeozonesAdmin } from 'stores/Admin/Geozones';
import type { PersistenceStore } from 'stores';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';

interface IProps {
  disabled?: boolean;
  emptySearchResults?: string;
  geozonesAdmin?: GeozonesAdmin;
  handleChange?: (option: Select.ISelectOption) => void;
  isSearchable?: boolean;
  persistChange?: boolean;
  persistenceStore?: PersistenceStore;
  placeholder?: string;
  searchPlaceholder?: string;
  value?: Select.ISelectOption;
  withAll?: boolean;
  timeout?: number;
  selectFirst?: boolean;
  disableCreate?: boolean;
}

@inject(({ adminStore: { geozonesAdmin }, persistenceStore }) => ({ geozonesAdmin, persistenceStore }))
@observer
class SearchableGeoZoneTagSelect extends Component<IProps> {
  static defaultProps = {
    placeholder: 'Search or Create Category',
    searchPlaceholder: 'Search or Create Category',
    emptySearchResults: 'No Results Found',
  };

  static allOption = { label: 'All Categories', value: 'all' };

  repositoryGeozoneTags: IEntityRepository;

  constructor(props) {
    super(props);

    if (props.persistChange && props.persistenceStore && props.persistenceStore.selectedGeoZoneTag && !props.value) {
      this.props.handleChange(props.persistenceStore.selectedGeoZoneTag);
    } else if (props.withAll && !props.value?.value) {
      this.props.handleChange(SearchableGeoZoneTagSelect.allOption);
    }

    this.repositoryGeozoneTags = repositoryService.get('geozones').entity('tags');
  }

  getData = (props: { page: number; pageSize: number; filter: string }) => {
    const data = { page: props.page || 0, pageSize: props.pageSize || 50, searchValue: props.filter };

    return this.repositoryGeozoneTags.get(data).then((data) => {
      const totalPages = data.totalPages;
      const options = data.items
        .map((item) => {
          return {
            value: item,
            label: item,
          };
        })
        .filter(({ value }) => value.trim());

      if (!props.filter && this.props.withAll && props.page === 0) {
        options.unshift(SearchableGeoZoneTagSelect.allOption);
      }

      if (this.props.selectFirst && options.length && !this.props.value?.value) {
        this.props.handleChange(options[0]);
      }

      return {
        totalPages,
        options,
      };
    });
  };

  handleChange = (option: Select.ISelectOption) => {
    this.props.handleChange(option);
    if (this.props.persistChange) {
      this.props.persistenceStore.updateSelectedGeoZoneTag({ ...option });
    }
  };

  handleCreateTag = async (tag: string) => {
    const {
      geozonesAdmin: { selectedGeozone },
    } = this.props;

    selectedGeozone?.setGeozoneTag(tag);
    try {
      await selectedGeozone?.updateGeozone(['tag']);
    } catch (e) {
      return e;
    }
  };

  render() {
    const { disableCreate } = this.props;

    return (
      <SearchableSelect
        {...this.props}
        getData={this.getData}
        handleChange={this.handleChange}
        addOption={disableCreate ? null : { text: 'Create New Category', onCreate: this.handleCreateTag }}
      />
    );
  }
}

export default SearchableGeoZoneTagSelect;
