import React from 'react';

import type { SimpleField } from 'models/Fields';
import type AlertIDModel from 'models/Alerts/AlertID';

import AlertPreferenceFinalizeSimpleLine from '../AlertPreferenceFinalizeSimpleLine';

interface IProps {
  label: string;
  actionType: SimpleField<Alerts.Modal.GeoZone.Action>;
  id: AlertIDModel<string>;
}

const AlertPreferenceFinalizeGeozoneLine: React.FC<IProps> = ({ label, actionType, id }) => {
  const getValue = () => {
    switch (actionType.value) {
      case 'ARRIVE':
        return `Enters ${id.name.value}`;
      case 'DEPART':
        return `Exits ${id.name.value}`;
      case 'ARRIVE_DEPART':
        return `Enters/Exits ${id.name.value}`;
    }
  };

  return <AlertPreferenceFinalizeSimpleLine label={label} value={getValue()} />;
};

export default AlertPreferenceFinalizeGeozoneLine;
