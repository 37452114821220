import { observable } from 'mobx';
import { ALERTS_TYPE } from 'config';
import AlertWithIntervalModel from './AlertWithInterval';
import { SimpleField } from 'models/Fields';
import isNumber from 'lodash/isNumber';

class SpeedOverAlertModel extends AlertWithIntervalModel {
  @observable speed: SimpleField<number>;

  constructor(item) {
    super({
      ...item,
      isIntervalActive: isNumber(item.interval) ? Boolean(item.interval) : true,
      interval: isNumber(item.interval) ? item.interval : 5 * 60 * 1000,
    });

    this.speed = new SimpleField(item.speed || 75);
    this.urlType = 'speeding-over';
    this.description = 'Receive an alert when a vehicle passes the selected speed.';
    this.type.reInitialize(ALERTS_TYPE.SPEEDS_OVER);
  }

  get model() {
    return { ...super.model, speed: this.speed.value };
  }

  isUpdated(): boolean {
    return super.isUpdated() || this.speed.isUpdated;
  }
}

export default SpeedOverAlertModel;
