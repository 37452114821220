import { SimpleField } from 'models/Fields';

class UserAccountDetailsModel {
  accountId: SimpleField<number>;
  accountIdCP: SimpleField<string>;
  exp: SimpleField<number>;
  userId: SimpleField<string>;

  constructor() {
    this.accountId = new SimpleField<number>(null);
    this.accountIdCP = new SimpleField<string>('');
    this.exp = new SimpleField<number>(null);
    this.userId = new SimpleField<string>('');
  }

  set = (data?: User.IUserContract) => {
    this.accountId.set(Number(data?.accountId) || null);
    this.accountIdCP.set(data?.accountIdCP || null);
    this.exp.set(data?.exp || null);
    this.userId.set(data?.userId || '');
  };

  get contract(): User.IUserContract {
    return {
      accountId: this.accountId.value?.toString(),
      accountIdCP: this.accountIdCP.value,
      exp: this.exp.value,
      userId: this.userId.value,
    };
  }
}

export default UserAccountDetailsModel;
