import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';

import type { GeozonesAdmin } from 'stores/Admin/Geozones';
import type { MapStore } from 'stores';
import validateAccessLevel from 'stores/acl/validator';
import { ADMIN_GEOZONES_UPDATE } from 'config';

import EditableCircleGeozone from 'components/Map/EditableCircleGeozone';
import EditablePolygonGeozone from 'components/Map/EditablePolygonGeozone';
import GeozoneCircleCenterMarker from 'components/Map/GeozonePathMarker/GeozoneCircleCenterMarker';
import GeozoneCirclePathMarker from 'components/Map/GeozonePathMarker/GeozoneCirclePathMarker';
import GeozonePathMarker from 'components/Map/GeozonePathMarker/GeozonePathMarker';
import type { IChangeParams } from '../SelectedGeozone';

interface IProps {
  className?: string;
  onChange: <T extends IChangeParams>(field: T | T[]) => void;
  geozonesAdmin?: GeozonesAdmin;
  mapStore?: MapStore;
  onMouseMove: (e: google.maps.MouseEvent) => void;
}

@inject(({ adminStore: { geozonesAdmin } }) => ({
  geozonesAdmin,
}))
@observer
class EditGeozonePath extends Component<IProps> {
  catchRadiusChangedDisposer: () => void;
  catchCoordinatesChangedDisposer: () => void;

  catchRadiusChanged = () => {
    this.catchRadiusChangedDisposer = reaction(
      () => this.props.geozonesAdmin.selectedGeozone?.radius,
      (radius) => {
        this.props.onChange({ name: 'radius', value: radius });
      }
    );
  };

  catchCoordinatesChanged = () => {
    this.catchCoordinatesChangedDisposer = reaction(
      () => this.props.geozonesAdmin.selectedGeozone?.coordinates,
      (coordinates) => {
        this.props.onChange({ name: 'coordinates', value: coordinates });
      }
    );
  };

  componentDidMount() {
    this.catchRadiusChanged();
    this.catchCoordinatesChanged();
  }

  componentWillUnmount() {
    this.catchRadiusChangedDisposer();
    this.catchCoordinatesChangedDisposer();
  }

  render() {
    const {
      selectedGeozone: {
        type,
        formatterCoordinates,
        coordinates,
        color,
        radius,
        circleMarker,
        hoveredMarkerIndex,
        setCoordinate,
        setCoordinates,
        handleRadiusChange,
        handleCenterMarkerDrag,
        addPointByNumber,
        setHoveredMarkerIndex,
        hoveredDispatchMarker,
      },
    } = this.props.geozonesAdmin;
    const { onMouseMove } = this.props;
    const isEditable = validateAccessLevel([ADMIN_GEOZONES_UPDATE]);

    return (
      <Fragment>
        {type.toLowerCase() === 'polygon' && formatterCoordinates?.length && (
          <Fragment>
            <EditablePolygonGeozone
              draggable={isEditable}
              coordinates={formatterCoordinates}
              color={color.hex}
              onCoordsChange={setCoordinates}
              addNewMarker={addPointByNumber}
              onMouseMove={onMouseMove}
            />
            {formatterCoordinates.map(({ lat, lng }, i) => (
              <GeozonePathMarker
                color={color.hex}
                colorName={color.name}
                dimmed={hoveredDispatchMarker}
                draggable={isEditable}
                hoveredIndex={hoveredMarkerIndex}
                key={i}
                onDrag={setCoordinate}
                onHover={setHoveredMarkerIndex}
                orderNumber={i}
                position={{ lat, lng }}
              />
            ))}
          </Fragment>
        )}

        {type.toLowerCase() === 'circle' && formatterCoordinates?.length && (
          <Fragment>
            <EditableCircleGeozone
              draggable={isEditable}
              position={coordinates[0]}
              color={color.hex}
              radius={radius}
              setPosition={setCoordinate}
              onMouseMove={onMouseMove}
            />
            <GeozoneCirclePathMarker
              draggable={isEditable}
              color={color.hex}
              colorName={color.name}
              position={circleMarker}
              onDrag={handleRadiusChange}
            />
            <GeozoneCircleCenterMarker
              draggable={isEditable}
              color={color.hex}
              colorName={color.name}
              position={coordinates[0]}
              onDrag={handleCenterMarkerDrag}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default EditGeozonePath;
