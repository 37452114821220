class KeyboardShortcuts {
  private static instance: KeyboardShortcuts;
  private static keys: string[];

  private constructor() {}

  public static get(): KeyboardShortcuts {
    if (!KeyboardShortcuts.instance) {
      KeyboardShortcuts.instance = new KeyboardShortcuts();
    }

    return KeyboardShortcuts.instance;
  }

  public setKeys(keys: string[]): KeyboardShortcuts {
    KeyboardShortcuts.keys = keys;
    return KeyboardShortcuts.instance;
  }

  public blockKey(): KeyboardShortcuts {
    window.addEventListener('keydown', this.keyDownCallback, true);

    return KeyboardShortcuts.instance;
  }

  public unblockKey(): KeyboardShortcuts {
    window.removeEventListener('keydown', this.keyDownCallback, true);
    return KeyboardShortcuts.instance;
  }

  private keyDownCallback(event) {
    if (KeyboardShortcuts.keys.includes(event.key)) {
      event.stopPropagation();
    }
  }
}

export { KeyboardShortcuts };
