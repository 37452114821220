import React, { useRef, useEffect } from 'react';
import { defer } from 'lodash';
import { Observer } from 'mobx-react';
import { Radio } from 'antd';
import classNames from 'classnames';
import { METRICS_SWITCH_TYPE, ACL } from 'config';
import { validateAccessLevel } from 'stores';

import type MaintenanceConfig from 'models/Maintenances/Models/MaintenanceConfig';
import type MaintenanceAsset from 'models/Maintenances/Models/MaintenanceAsset';
import type MaintenanceModalDueAtUI from 'models/Maintenances/UI/MaintenanceModalUI/MaintenanceModalDueAtUI';

import Checkbox from 'components/Checkbox';
import CalendarWithInput from 'components/CalendarWithInput';
import InputNumber from 'components/InputNumber';
import InfoTooltip from 'components/InfoTooltip';

import './styles.scss';

interface IProps {
  odometer: MaintenanceConfig;
  engHours: MaintenanceConfig;
  serviceTime: MaintenanceConfig;
  asset: MaintenanceAsset;
  assetId: string;
  ui: MaintenanceModalDueAtUI;
}

const MaintenancePreferenceDue: React.FC<IProps> = ({ odometer, engHours, serviceTime, asset, assetId, ui }) => {
  const inputRef = useRef(null);
  const edit = validateAccessLevel([ACL.MAINTENANCE.BASIC.UPDATE]);

  useEffect(() => {
    if (!serviceTime.next.value && ui.dateCheckbox.value) {
      serviceTime.next.set(Date.now().toString());
    }
  }, [assetId]);

  const switchType = (e) => {
    if (e.target.value === METRICS_SWITCH_TYPE.MILES) {
      odometer.next.set(asset.details.odometer.value.toString());
      engHours.next.set('');
    } else {
      odometer.next.set('');
      engHours.next.set(asset.details.engHours.value.toString());
    }

    ui.metricsSwitch.set(e.target.value);
    inputRef.current.focus();
  };

  const toggleMetrics = () => {
    if (ui.metricsCheckbox.value) {
      odometer.next.set('');
      engHours.next.set('');
    } else {
      if (ui.metricsSwitch.value === METRICS_SWITCH_TYPE.MILES) {
        odometer.next.set(asset.details.odometer.value.toString());
      } else {
        engHours.next.set(asset.details.engHours.value.toString());
      }

      defer(() => inputRef.current.focus());
    }

    ui.isError.toggle(false);
    ui.metricsCheckbox.toggle();
  };

  const toggleDate = () => {
    if (ui.dateCheckbox.value) {
      serviceTime.next.set(null);
    } else {
      serviceTime.next.set(Date.now().toString());
    }

    ui.isError.toggle(false);
    ui.dateCheckbox.toggle();
  };

  const onMetricsChange = (value) => {
    if (ui.metricsSwitch.value === METRICS_SWITCH_TYPE.MILES) {
      odometer.next.set(value);
    } else {
      engHours.next.set(value);
    }
  };

  return (
    <Observer
      render={() => {
        const switchCN = classNames('MaintenancePreferenceDue-contentSwitch', {
          'MaintenancePreferenceDue-contentSwitch--disabled': !ui.metricsCheckbox.value || !edit,
        });

        return (
          <div className="MaintenancePreference-line MaintenancePreferenceDue">
            <div className="MaintenancePreference-label MaintenancePreference-label--top MaintenancePreferenceDue-label">
              Due at/on
            </div>
            <div className="MaintenancePreference-value MaintenancePreferenceDue-content">
              <div className="MaintenancePreferenceDue-contentLine">
                <Checkbox
                  checked={ui.metricsCheckbox.value}
                  onChange={toggleMetrics}
                  className={`${ui.isError.value ? 'MaintenancePreferenceDue-contentCheckbox--error' : ''}`}
                  disabled={!edit}
                />
                <InputNumber
                  inputRef={inputRef}
                  value={
                    ui.metricsCheckbox.value
                      ? ui.metricsSwitch.value === METRICS_SWITCH_TYPE.MILES
                        ? Number(odometer.next.value)?.toLocaleString('en-US', { maximumFractionDigits: 0 })
                        : Number(engHours.next.value)?.toLocaleString('en-US', { maximumFractionDigits: 1 })
                      : ''
                  }
                  className="MaintenancePreferenceDue-contentMetrics"
                  disabled={!ui.metricsCheckbox.value || !edit}
                  onChange={onMetricsChange}
                  min="0"
                  controls={false}
                />
                <Radio.Group
                  className={switchCN}
                  value={ui.metricsSwitch.value}
                  onChange={switchType}
                  disabled={!ui.metricsCheckbox.value || !edit}
                >
                  <Radio.Button
                    className="MaintenancePreferenceDue-contentSwitchButton"
                    value={METRICS_SWITCH_TYPE.MILES}
                  >
                    Miles
                  </Radio.Button>
                  <Radio.Button
                    className="MaintenancePreferenceDue-contentSwitchButton"
                    value={METRICS_SWITCH_TYPE.HOURS}
                  >
                    Hours
                  </Radio.Button>
                </Radio.Group>
              </div>
              <div className="MaintenancePreferenceDue-contentLine">
                <Checkbox
                  checked={ui.dateCheckbox.value}
                  onChange={toggleDate}
                  className={`${ui.isError.value ? 'MaintenancePreferenceDue-contentCheckbox--error' : ''}`}
                  disabled={!edit}
                />
                <div className="MaintenancePreferenceDue-contentDate">
                  <CalendarWithInput
                    disabled={!ui.dateCheckbox.value || !edit}
                    onlyFuture
                    withTodayLabel
                    isInputEditable={false}
                    value={Number(serviceTime.next.value)}
                    onSubmit={(value) => serviceTime.next.set(value.toString())}
                  />
                </div>
                <InfoTooltip className="MaintenancePreferenceDue-contentInfoTooltip">
                  This task will trigger as 'due' when either the Date or the Mileage/Hours is met, whichever comes
                  first.
                </InfoTooltip>
              </div>
              <div className="MaintenancePreferenceDue-contentError">
                {ui.isError.value && (
                  <span className="MaintenancePreferenceDue-contentErrorText">
                    At least one option must be selected
                  </span>
                )}
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default MaintenancePreferenceDue;
