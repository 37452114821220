import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import type { PeopleAdmin } from 'stores/Admin/People';
import type { UserStore } from 'stores/UserStore';
import PersonAccess from 'components/Admin/People/PersonAccess';
import PersonPermissions from 'components/Admin/People/PersonPermissions';

interface IProps {
  peopleAdmin?: PeopleAdmin;
  userStore?: UserStore;
}

@inject(({ adminStore: { peopleAdmin }, userStore }) => ({
  peopleAdmin,
  userStore,
}))
@observer
class AdminAccessPermissions extends Component<IProps> {
  render(): React.ReactNode {
    const {
      peopleAdmin: { item, sendResetPasswordEmail },
      userStore: { userPlan },
    } = this.props;

    return (
      <div className="AdminAccessPermissions">
        <PersonAccess
          access={item.value.access}
          details={item.value.details}
          resetPasswordEmail={sendResetPasswordEmail}
        />
        <PersonPermissions permissions={item.value.permissions} access={item.value.access} userPlan={userPlan} />
      </div>
    );
  }
}

export default AdminAccessPermissions;
