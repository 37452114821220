import React, { Component } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import find from 'lodash/find';
import moment from 'moment';

import { timeZoneOffset } from 'utils';

import { TIME_OPTIONS_ARRAY, DATE_TIME_FORMATS, TIME } from 'config';

import Calendar from 'components/Calendar';
import DateField from 'components/DateField';
import TimePicker from 'components/TimePicker';
import Button from 'components/Button';
import TimeHintTooltip from '../TimeHintTooltip';

import './styles.scss';

interface IProps {
  className?: string;
  date?: Filters.IDateFilterOption;
  onApplyDate: (date: Filters.IDateFilterOption) => void;
  pathToBack: string;
  timezone: string;
}

interface IState {
  selectedDate: Date;
  selectedTime: string;
}

class CustomTimeFilter extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    const { date } = this.props;

    this.state = {
      selectedDate: (date.to && new Date(date.to)) || new Date(),
      selectedTime: (date.to && moment(date.to).format(DATE_TIME_FORMATS.hoursMinutesSpaceAm)) || TIME.endOfTheDay,
    };
  }

  setDate = (selectedDate) => {
    this.setState({ selectedDate });
  };

  setTime = (selectedTime) => {
    this.setState({ selectedTime });
  };

  handleApplyDate = () => {
    const { selectedDate, selectedTime } = this.state;
    const { onApplyDate, timezone } = this.props;
    const to =
      moment(
        `${moment(selectedDate).format(DATE_TIME_FORMATS.fullMonthComaDateYear)} ${selectedTime} +00:00`
      ).valueOf() +
      timeZoneOffset(timezone) +
      59 * 1000;

    onApplyDate({ from: null, to });
  };

  render() {
    const { selectedDate, selectedTime } = this.state;
    const { className, pathToBack } = this.props;
    const cn = classNames('CustomTimeFilter', className);
    const todayDate = new Date();
    const fromMonth = new Date(new Date().getFullYear() - 4, 0);
    const isSelectedTimeInTimeOptions = find(TIME_OPTIONS_ARRAY, { value: selectedTime });
    const timeOptions = TIME_OPTIONS_ARRAY.slice();

    if (!isSelectedTimeInTimeOptions) {
      timeOptions.push({ key: selectedTime, value: selectedTime, text: selectedTime.replace(' ', '') });
    }

    return (
      <div className={cn}>
        <div className="CustomTimeFilter-content">
          <div className="CustomTimeFilter-header">
            <NavLink className="CustomTimeFilter-backButton" to={pathToBack} />
            <div className="CustomTimeFilter-title">Select date & time</div>
            <div className="CustomTimeFilter-tooltip">
              <TimeHintTooltip />
            </div>
          </div>
          <div className="CustomTimeFilter-calendar">
            <Calendar
              withMonthYearForm
              selectedDate={selectedDate}
              fromMonth={fromMonth}
              toMonth={todayDate}
              onClick={this.setDate}
              month={selectedDate}
              disabledDays={[{ before: undefined, after: todayDate }]}
            />
          </div>
          <div className="CustomTimeFilter-time">
            <p className="CustomTimeFilter-timeTitle">Time</p>
            <div className="CustomTimeFilter-dateField">
              <DateField date={selectedDate} />
            </div>
            <div className="CustomTimeFilter-timeDropdown">
              <TimePicker
                placeholder="Select time"
                options={timeOptions}
                onChange={this.setTime}
                value={selectedTime}
                fluid
              />
            </div>
          </div>
        </div>

        <div className="CustomTimeFilter-buttons">
          <div className="CustomTimeFilter-button CustomTimeFilter-button--cancel">
            <NavLink className="Button Button--cancel" to={pathToBack}>
              Cancel
            </NavLink>
          </div>
          <div className="CustomTimeFilter-button CustomTimeFilter-button--apply">
            <Button title="Apply" className="Button--apply" onClick={this.handleApplyDate} />
          </div>
        </div>
      </div>
    );
  }
}

export default CustomTimeFilter;
