import { observable } from 'mobx';
import { ALERTS_TYPE } from 'config';
import AlertModel from './Alert';
import { SimpleField } from 'models/Fields';

class ExcessiveIdlingAlertModel extends AlertModel {
  @observable idleMinutes: SimpleField<number>;

  constructor(item) {
    super(item);

    this.idleMinutes = new SimpleField(item.idleMinutes || 30);
    this.urlType = 'excessive-idling';
    this.description = 'Idling occurs when the engine is on but no movement is detected';
    this.type.reInitialize(ALERTS_TYPE.EXCESSIVE_IDLING);
  }

  get model() {
    return { ...super.model, idleMinutes: this.idleMinutes.value };
  }

  isUpdated(): boolean {
    return super.isUpdated() || this.idleMinutes.isUpdated;
  }
}

export default ExcessiveIdlingAlertModel;
