import type { SimpleField } from 'models/Fields';
import type DueValue from './DueValue';
import MaintenanceAlertTaskModel from './MaintenanceAlertTaskModel';
import MaintenanceAlertSpecificTaskConfigModel from './MaintenanceAlertSpecificTaskConfigModel';

class MaintenanceAlertSpecificTaskModel {
  maintenance: MaintenanceAlertTaskModel;
  when: SimpleField<Alerts.Modal.Maintenance.When>;
  dueValue: DueValue;
  config: MaintenanceAlertSpecificTaskConfigModel;

  constructor({
    when,
    dueValue,
    config,
  }: {
    when: SimpleField<Alerts.Modal.Maintenance.When>;
    dueValue: DueValue;
    config: { type: string; value: number; taskId: number };
  }) {
    this.maintenance = new MaintenanceAlertTaskModel({
      id: config ? config.taskId : null,
      type: config ? config.type : null,
    });
    this.when = when;
    this.dueValue = dueValue;
    this.config = config ? new MaintenanceAlertSpecificTaskConfigModel(config) : null;
    this.setup();
  }

  get model() {
    const config = {
      type: this.maintenance.type.value,
      value: this.maintenance.value.value,
      taskId: this.maintenance.task.item?.id || this.maintenance.id.value,
    };

    if (this.when.value === 0) {
      config.type = 'IS_DUE';
      config.value = null;
    } else if (this.when.value === 1) {
      if (this.maintenance.task.item?.hasDateOnly) {
        config.value = this.dueValue.days.value;
        config.type = 'DAYS_BEFORE_IS_DUE';
      } else if (this.maintenance.task.item?.hasMilesOnly) {
        config.value = this.dueValue.avgDaysBefore.value;
        config.type = 'DAYS_BEFORE_IS_DUE_AVERAGE';
      } else if (this.maintenance.task.item?.hasHoursOnly) {
        config.value = this.dueValue.avgDaysBefore.value;
        config.type = 'DAYS_BEFORE_IS_DUE_AVERAGE';
      } else if (this.maintenance.task.item?.hasMilesAndDate) {
        config.value = this.dueValue.avgDaysBefore.value;
        config.type = 'DAYS_BEFORE_IS_DUE_AVERAGE';
      } else if (this.maintenance.task.item?.hasHoursAndDate) {
        config.value = this.dueValue.avgDaysBefore.value;
        config.type = 'DAYS_BEFORE_IS_DUE_AVERAGE';
      }
    } else if (this.when.value === 2) {
      if (this.maintenance.task.item?.hasMilesOnly) {
        config.value = this.dueValue.miles.value;
        config.type = 'MILES_BEFORE_IS_DUE';
      } else if (this.maintenance.task.item?.hasHoursOnly) {
        config.value = this.dueValue.hours.value;
        config.type = 'ENGINE_HOURS_BEFORE_IS_DUE';
      } else if (this.maintenance.task.item?.hasMilesAndDate) {
        config.value = this.dueValue.miles.value;
        config.type = 'MILES_BEFORE_IS_DUE';
      } else if (this.maintenance.task.item?.hasHoursAndDate) {
        config.value = this.dueValue.hours.value;
        config.type = 'ENGINE_HOURS_BEFORE_IS_DUE';
      }
    }

    return config;
  }

  get text() {
    const basedOn = 'Service Task: ' + this.maintenance.task.item.name;
    let when = '';

    if (this.maintenance.task.item?.hasDateOnly) {
      if (this.when.value === 1) {
        when = `${this.dueValue.days.value} days before Task is due`;
      }
    } else if (this.maintenance.task.item?.hasMilesOnly) {
      if (this.when.value === 1) {
        when = `${this.dueValue.avgDaysBefore.value} days before Task is due <span>(based on Average Daily Usage)</span>`;
      } else if (this.when.value === 2) {
        when = `${this.dueValue.miles.value} miles before Task is due`;
      }
    } else if (this.maintenance.task.item?.hasHoursOnly) {
      if (this.when.value === 1) {
        when = `${this.dueValue.avgDaysBefore.value} days before Task is due <span>(based on Average Daily Usage)</span>`;
      } else if (this.when.value === 2) {
        when = `${this.dueValue.hours.value} engine hours before Task is due`;
      }
    } else if (this.maintenance.task.item?.hasMilesAndDate) {
      if (this.when.value === 1) {
        when = `${this.dueValue.avgDaysBefore.value} days before Task is due <span>(based on Average Daily Usage)</span>`;
      } else if (this.when.value === 2) {
        when = `${this.dueValue.miles.value} miles or ${this.dueValue.days.value} days before Task is due`;
      }
    } else if (this.maintenance.task.item?.hasHoursAndDate) {
      if (this.when.value === 1) {
        when = `${this.dueValue.avgDaysBefore.value} days before Task is due <span>(based on Average Daily Usage)</span>`;
      } else if (this.when.value === 2) {
        when = `${this.dueValue.hours.value} engine hours or ${this.dueValue.days.value} days before Task is due`;
      }
    }

    return { basedOn, when };
  }

  private setup = () => {
    if (!this.config) {
      return;
    }

    switch (this.config.type.value) {
      case 'DAYS_BEFORE_IS_DUE_AVERAGE':
        this.when.set(1);
        this.dueValue.avgDaysBefore.reInitialize(this.config.value.value);
        this.maintenance.value.set(this.config.value.value);
        break;
      case 'DAYS_BEFORE_IS_DUE':
        this.when.set(1);
        this.dueValue.days.reInitialize(this.config.value.value);
        this.maintenance.value.set(this.config.value.value);
        break;
      case 'MILES_BEFORE_IS_DUE':
        this.when.set(2);
        this.dueValue.miles.reInitialize(this.config.value.value);
        this.maintenance.value.set(this.config.value.value);
        break;
      case 'ENGINE_HOURS_BEFORE_IS_DUE':
        this.when.set(2);
        this.dueValue.hours.reInitialize(this.config.value.value);
        this.maintenance.value.set(this.config.value.value);
        break;
    }
  };
}

export default MaintenanceAlertSpecificTaskModel;
