import { DATE_TIME_FORMATS } from 'config';
import { TimeField } from 'models/Fields';

export default class MediaDate {
  from: TimeField;
  to: TimeField;

  constructor({ from, to }) {
    this.from = new TimeField(from).setFormat(DATE_TIME_FORMATS.monthDatYearFull);
    this.to = new TimeField(to).setFormat(DATE_TIME_FORMATS.monthDatYearFull);
  }

  set = ({ from, to }) => {
    this.from.set(from);
    this.to.set(to);
  };
}
