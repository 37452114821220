import React, { FC } from 'react';
import Modal from 'components/Modal';
import Button from 'components/Button';

import './styles.scss';

interface IProps {
  isOpen: boolean;
  showEventsLimitModal: (value: boolean) => void;
}

const EventsLimitModal: FC<IProps> = ({ isOpen = false, showEventsLimitModal }) => {
  const handleClose = () => {
    showEventsLimitModal(false);
  };

  return (
    <Modal isModalOpen={isOpen} onClose={handleClose} className="Modal--withoutCloseIcon Modal--eventsLimit">
      <div className="EventsLimit">
        <p className="EventsLimit-title">Max Data Limit Reached</p>
        <p className="EventsLimit-text">
          The maximum number of allowed trackpoints has been exceeded. Not all trackpoints may be displayed on the map.
          Adjust the calendar to see remaining trackpoints.
        </p>
        <div className="EventsLimit-buttonContainer">
          <Button className="EventsLimit-button" title="Ok" onClick={handleClose} />
        </div>
      </div>
    </Modal>
  );
};

export default EventsLimitModal;
