import React, { FC, Fragment, useCallback, useMemo, useState } from 'react';

import type { mapLabelSizeType } from 'stores';
import GeozoneMarkerIcon from './GeozoneMarkerIcon';
import MapLabel from '../MapLabel';
import Marker from '../Marker';
import { MAP_MARKERS } from 'config';

interface IProps {
  color?: string;
  hoverIcon?: string;
  icon?: string;
  label: string;
  labelSize?: mapLabelSizeType;
  onClick?: () => void;
  position: Locations.ILocation | google.maps.LatLng;
}

const getPixelPositionOffset = () => ({
  x: 16,
  y: -22,
});

const BASE_GEO_ZONE_MARKER = MAP_MARKERS.BASE - 1; // To be behind markers
const GeozoneMarkerWithLabel: FC<IProps> = ({ color, label, labelSize, onClick, position }) => {
  const [showLabel, setShowLabel] = useState(false);
  const anchor = showLabel ? new google.maps.Point(11, 24) : new google.maps.Point(7, 16);
  const onMouseOut = useCallback(() => setShowLabel(false), []);
  const onMouseOver = useCallback(() => setShowLabel(true), []);
  const markerIcon = useMemo(() => {
    return `data:image/svg+xml;charset=utf-8,${escape(GeozoneMarkerIcon({ color, isHover: showLabel }))}`;
  }, [color, showLabel]);
  return (
    <Fragment>
      <Marker
        icon={markerIcon}
        position={position}
        anchor={anchor}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
        onClick={onClick}
        zIndex={showLabel ? MAP_MARKERS.HOVER : BASE_GEO_ZONE_MARKER}
      />
      {showLabel && (
        <MapLabel label={label} size={labelSize} position={position} getPixelPositionOffset={getPixelPositionOffset} />
      )}
    </Fragment>
  );
};

export default GeozoneMarkerWithLabel;
