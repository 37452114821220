import { SimpleField } from 'models/Fields';
import ServiceType from 'models/Maintenances/Models/Service/ServiceType';

class MaintenanceAlertServiceModel {
  type: ServiceType;
  id: SimpleField<number>;

  constructor(id) {
    this.type = new ServiceType(id, '', true);
    this.id = new SimpleField(id);
  }
}

export default MaintenanceAlertServiceModel;
