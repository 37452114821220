import React, { FC, useCallback, useEffect, useState } from 'react';
import { Observer } from 'mobx-react';

import { KEY_TYPE } from 'config';

import { KeyboardShortcutsManager } from 'tools';

import type { IDashcamSettingsField } from 'interfaces/models/Dashcams/IDashcamSettingsField';

import CameraSettingsItem from './CameraSettingsItem';
import CameraSettingsHeader from './CameraSettingsHeader';
import CameraSettingsFooter from './CameraSettingsFooter';
import ConfirmationModal from '../../ConfirmationModal';

import './styles.scss';

interface IProps {
  settings: Array<IDashcamSettingsField<any>>;
  show: boolean;
  sending: boolean;
  onClose: () => void;
  onSubmit: () => void;
  changed: boolean;
  hasSettingsError: boolean;
  availableCameraTypes: string[];
}

const CameraSettings: FC<IProps> = ({
  settings,
  onClose,
  onSubmit,
  hasSettingsError,
  sending,
  changed,
  availableCameraTypes,
}) => {
  const [isNavigateAway, setIsNavigateAway] = useState(false);
  const handleClose = useCallback(() => {
    if (changed) {
      setIsNavigateAway(true);
    } else {
      onClose();
    }
  }, [setIsNavigateAway, onClose, changed]);

  const submitNavigationAway = useCallback(() => {
    onClose();
    setIsNavigateAway(false);
  }, [onClose, setIsNavigateAway]);

  const closeNavigateAway = useCallback(() => {
    setIsNavigateAway(false);
  }, [setIsNavigateAway]);

  const listenEscKeyPress = (e) => {
    if (e.key === KEY_TYPE.ESCAPE) {
      handleClose();
    }
  };

  useEffect(() => {
    KeyboardShortcutsManager.get().add(listenEscKeyPress);

    return () => {
      KeyboardShortcutsManager.get().remove(listenEscKeyPress);
    };
  }, [listenEscKeyPress]);

  return (
    <Observer
      render={() => (
        <div className="CameraSettings">
          <CameraSettingsHeader onClose={handleClose} />
          <div className="CameraSettings-body">
            {settings.map((item, index) => {
              return (
                <CameraSettingsItem
                  key={item.id || String(index)}
                  item={item}
                  availableCameraTypes={availableCameraTypes}
                  disabled={hasSettingsError}
                />
              );
            })}
          </div>
          <CameraSettingsFooter onClose={handleClose} onSubmit={onSubmit} sending={sending} changed={changed} />
          <ConfirmationModal
            className="CameraSettings-navigationAway"
            title="Continue without Saving?"
            isOpen={isNavigateAway}
            onSubmit={submitNavigationAway}
            onCancel={closeNavigateAway}
            applyButtonTitle="Yes, Continue"
            cancelButtonTitle="No, Cancel"
          >
            Changes made to this dashcam’s settings will not be applied.
          </ConfirmationModal>
        </div>
      )}
    />
  );
};

export default CameraSettings;
