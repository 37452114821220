import { inject } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';

import { EVENTS_REGISTRY_STORE, PATHS, USER_STORE } from 'config';
import type { UserStore, EventsRegistryStore } from 'stores';
import { withRouter, RouteComponentProps } from 'react-router';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';
import { getCookie, tokenManager } from 'utils';
import { TOKEN_COOKIE_NAME } from 'config/cookie';
import { notificationService } from 'services/Notification/NotificationService';
import { noop } from 'lodash';

interface IProps extends RouteComponentProps {
  userStore?: UserStore;
  eventsRegistryStore?: EventsRegistryStore;
  children: React.ReactNode;
  renderUnauthorized: (props: IProps) => React.ReactNode;
}

const ProtectedRoute: React.FC<IProps> = (props) => {
  const [canRender, setCanRender] = useState(false);
  const [showPopup, setPopupVisible] = useState(false);
  const { children, renderUnauthorized } = props;

  const handleSessionExpired = async () => {
    const {
      userStore,
      history: { push },
    } = props;

    if (userStore.otpToken) {
      return;
    }

    if (!canRender) {
      push(PATHS.AUTH.LOGIN);
    }

    if (!getCookie(TOKEN_COOKIE_NAME)) {
      await userStore.logout();
      setPopupVisible(true);
    }
  };

  const initialize = async () => {
    const {
      userStore: { setCurrentUserACLs, repositoryPermissionsUsers },
      eventsRegistryStore: { fetch },
    } = props;

    if (!repositoryPermissionsUsers.getState.success) {
      const setACLs = async () => {
        try {
          await Promise.all([setCurrentUserACLs(), fetch()]);
        } catch {
          noop();
        }
        setCanRender(true);
      };
      setACLs();
    } else {
      setCanRender(true);
    }
  };

  useEffect(() => {
    const {
      userStore: { jwToken, otpToken, setJWToken, resetOPT, updateUserDetailsFromJWT },
    } = props;

    notificationService.subscribe();
    EventsBus.get().once(APP_EVENTS.SESSION_EXPIRED, handleSessionExpired);
    if (otpToken) {
      tokenManager
        .opt(otpToken)
        .then((accessToken) => {
          setJWToken(accessToken);
          resetOPT();
          updateUserDetailsFromJWT();
          void initialize();
        })
        .catch(() => {
          resetOPT();
          void initialize();
        });
    } else if (!jwToken) {
      tokenManager.refresh(async () => {
        await initialize();
      });
    } else {
      initialize();
    }

    return () => {
      notificationService.unsubscribe();
      EventsBus.get().off(APP_EVENTS.SESSION_EXPIRED, handleSessionExpired);
    };
  }, []);

  return (
    canRender && (
      <Fragment>
        {children}
        {showPopup && renderUnauthorized(props)}
      </Fragment>
    )
  );
};

export default withRouter(inject(USER_STORE, EVENTS_REGISTRY_STORE)(ProtectedRoute));
