import TableToggleFilter from 'models/Tables/TableToggleFilter';
import { MAINTENANCE_FREQUENCY_TYPES } from 'config';

export default class Frequency {
  list: TableToggleFilter[];

  constructor() {
    this.list = [
      { value: MAINTENANCE_FREQUENCY_TYPES.REPEAT, text: 'Repeat' },
      { value: MAINTENANCE_FREQUENCY_TYPES.ONE_TIME, text: 'One Time' },
    ].map((item) => new TableToggleFilter({ isActive: false, ...item }));
  }
}
