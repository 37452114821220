import { action, computed, observable } from 'mobx';

import { INTEGRATION_CUSTOMER_EXTERNAL_LINK } from 'config';

import { repositoryService } from 'services';

import type { IntegrationDomain } from 'interfaces/stores/Integrations/IIntegration';
import type ICustomer from 'interfaces/stores/Customers/ICustomer';
import type ICustomerInvoice from 'interfaces/stores/Customers/ICustomerInvoice';
import type ICustomerEstimate from 'interfaces/stores/Customers/ICustomerEstimate';
import type ICustomerAddress from 'interfaces/stores/Customers/ICustomerAddress';
import type ICustomerJob from 'interfaces/stores/Customers/ICustomerJob';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

import CustomerJob from './CustomerJob';

import { SimpleField } from 'models/Fields';
import { CustomerInvoice } from './CustomerInvoice';
import { CustomerEstimate } from './CustomerEstimate';

const isDomainWithAJob = (value: IntegrationDomain) => ['SERVICE_TITAN'].includes(value);

export class Customer implements ICustomer {
  id: string;
  full: boolean = false;
  address?: ICustomerAddress;
  notes: string;
  taxable: boolean;
  companyName: string;
  active: boolean;
  description: string;
  integrationId: string;
  balance: number;
  invoices: ICustomerInvoice[];
  estimates: ICustomerEstimate[];
  domain: IntegrationDomain;
  familyName: string;
  overdueBalance: number;
  phoneNumber: string;
  name: string;
  externalLink: string;

  job: boolean;
  hasJobs: boolean;
  @observable jobs: ICustomerJob[] | null;
  @observable jobDetails: ICustomerJob | null;

  email: string;
  selectedJobId: SimpleField<string | null> = null;
  selectedInvoiceId: SimpleField<string | null> = null;
  selectedEstimateId: SimpleField<string | null> = null;
  repositoryJobs: IRepository;
  repositoryJobsList: IEntityRepository;
  repositoryJobDetails: IEntityRepository;

  constructor(props) {
    this.repositoryJobs = repositoryService.get('integrations');

    this.full = props.full || false;
    this.notes = props.notes;
    this.taxable = props.taxable;
    this.companyName = (props.domain === 'SERVICE_TITAN' && !props.companyName ? props.name : props.companyName) || '';
    this.description = props.description;
    this.integrationId = props.integrationId;
    this.balance = props.balance;
    this.invoices = (props.invoices || []).sort((a, b) => new Date(a.dueDate) > new Date(b.dueDate) ? -1 : 1).map(invoice => new CustomerInvoice(invoice));
    this.estimates = (props.estimates || []).map(estimate => new CustomerEstimate(estimate));
    this.domain = props.domain;
    this.familyName = props.familyName;
    this.overdueBalance = props.overdueBalance;
    this.phoneNumber = props.phoneNumber;
    this.name = props.name;
    this.id = props.id;
    this.job = props.job;
    this.email = props.email;
    this.address = props.address;
    this.jobs = props.jobs || null;
    this.hasJobs = isDomainWithAJob(props.domain);
    this.selectedJobId = new SimpleField(null);
    this.selectedEstimateId = new SimpleField(null);
    this.selectedInvoiceId = new SimpleField(null);
    this.externalLink = (INTEGRATION_CUSTOMER_EXTERNAL_LINK[props.domain] || '').replace('%integrationId', props.integrationId);
  }

  @action loadJobs = async () => {
    if (!this.hasJobs) {
      return void 0;
    }
    this.repositoryJobsList = this.repositoryJobs.entity('jobs');

    const response = await this.repositoryJobsList.get({
      customerId: this.id,
      includeInvoices: true,
      includeEstimates: true,
      sortBy: 'transactionDate',
      statuses: ['InProgress', 'Scheduled', 'OnHold'],
    });
    this.jobs = response?.items?.map(job => new CustomerJob(job)) || [];
  }

  @computed get upcomingJobs () {
    return this.jobs?.filter?.((job) => !['completed', 'canceled'].includes(job.status.toLowerCase())) || null;
  }

  @action loadJobDetails = async () => {
    this.jobDetails = null;
    this.repositoryJobDetails = this.repositoryJobs.entity('jobs').entity(this.selectedJobId.value);

    const response = await this.repositoryJobDetails.get({
      includeInvoices: true,
      includeEstimates: true,
    });

    this.jobDetails = new CustomerJob(response);
  };

  @computed
  get selectedEstimate(): ICustomerEstimate {
    return this.estimates.find((estimate) => String(estimate?.id) === String(this.selectedEstimateId.value));
  }

  @computed
  get selectedInvoice(): ICustomerInvoice {
    return this.invoices.find((invoice) => String(invoice?.id) === String(this.selectedInvoiceId.value));
  }

  getJobById(id: number) {
    return this.jobs.find((job) => job.id === id);
  }
}
