import React, { Component } from 'react';
import { observer } from 'mobx-react';

import SearchableSelect from 'components/Select/SearchableSelect';

import type { PersistenceStore } from 'stores';

interface IProps {
  persistenceStore?: PersistenceStore;
  value: Select.ISelectOption;
  values?: Select.ISelectOption[];
  handleChange?: (option: Select.ISelectOption) => void;
  placeholder?: string;
  searchPlaceholder?: string;
  emptySearchResults?: string;
  isSearchable?: boolean;
  persistChange?: boolean;
  disabled?: boolean;
  withAll?: boolean;
  alignRight?: boolean;
  onMenuClose?: () => void;
  autoFocus?: boolean;
  isMenuOpen?: boolean;
  sortOptions?: boolean;
  menuPortalTarget?: HTMLElement | null | undefined;
}

@observer
class SearchableStaticSelect extends Component<IProps> {
  static defaultProps = {
    placeholder: 'Select',
    searchPlaceholder: 'Search',
    emptySearchResults: 'No Found',
    values: [],
    handleChange: () => null,
  };

  handleChange = (option: Select.ISelectOption) => {
    this.props.handleChange(option);
  };

  render() {
    return <SearchableSelect {...this.props} isStatic handleChange={this.handleChange} values={this.props.values} />;
  }
}

export default SearchableStaticSelect;
