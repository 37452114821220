import React, { useState, useEffect } from 'react';
import { Observer } from 'mobx-react';

import type PersonPermissionsVehicleInspectorModel from 'models/Admin/People/PersonPermissions/PersonPermissionsVehicleInspectorModel';

import InfoTooltip from 'components/InfoTooltip';
import Toggle from 'components/Toggle';

import './styles.scss';

interface IProps {
  vehicleInspector: PersonPermissionsVehicleInspectorModel;
  isMobileAccessActive: boolean;
}

const PersonPermissionsVehicleInspector: React.FC<IProps> = ({ vehicleInspector, isMobileAccessActive }) => {
  const [isToggleError, setIsToggleError] = useState(false);

  const toggleVehicleInspector = () => {
    setIsToggleError(false);
    vehicleInspector.active.toggle();

    if (!isMobileAccessActive && vehicleInspector.active.value) {
      setIsToggleError(true);
      vehicleInspector.active.toggle(false);
    }
  };

  useEffect(() => {
    if (isMobileAccessActive) {
      setIsToggleError(false);
    } else if (vehicleInspector.active.value) {
      vehicleInspector.active.toggle(false);
      setIsToggleError(true);
    }
  }, [isMobileAccessActive]);

  return (
    <Observer
      render={() => (
        <div className="PersonPermissionsVehicleInspector">
          <div className="PersonPermissionsVehicleInspector-activation">
            <Toggle checked={vehicleInspector.active.value} onChange={toggleVehicleInspector} error={isToggleError} />
            <p className="PersonPermissionsVehicleInspector-activationName">Vehicle Inspector</p>
            <InfoTooltip>
              Mobile App Access Required to inspect Vehicles. When enabled, this user will be allowed to inspect all
              Vehicles they have access to. All Drivers are allowed to inspect the Vehicle they are assigned to.
            </InfoTooltip>
            {isToggleError && (
              <p className="PersonPermissionsVehicleInspector-activationError">Mobile App Access required</p>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default PersonPermissionsVehicleInspector;
