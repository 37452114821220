import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { PATHS } from 'config';

import type { MapStore, CustomersStore } from 'stores';

import withCollapseStorage from 'hocs/withCollapseStorage';

import CloseButton from 'components/Button/CloseButton';
import CustomerDomain from '../../CustomerDomain';
import SecondaryPanelJobSummary from './SecondaryPanelJobSummary';
import SecondaryPanelJobDetails from './SecondaryPanelJobDetails';
import SecondaryPanelJobServiceAddress from './SecondaryPanelJobServiceAddress';
import SecondaryPanelJobEstimates from './SecondaryPanelJobEstimates';

import { Collapse } from 'antd';
import { ChevronIcon } from 'assets';

import './styles.scss';
import SecondaryPanelJobInvoices from './SecondaryPanelJobInvoices';

enum collapsePanels {
  Summary = 'summary',
  JobDetails = 'job details',
  ServiceAddress = 'service-address',
  Estimates = 'estimates',
  Invoices = 'invoices',
}

interface IMatchParams {
  jobId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  customersStore: CustomersStore;
  mapStore?: MapStore;
  collapseKeys: string[];

  onCollapseChange(values: string | string[]): void;
}

@inject(({ customersStore, devicesMapStore: { mapStore } }) => ({
  customersStore,
  mapStore,
}))
@observer
class SecondaryPanelJob extends Component<IProps> {
  get selectedCustomer() {
    const {
      customersStore: { selectedCustomer },
    } = this.props;
    return selectedCustomer;
  }

  loadJobDetails = (jobId: string) => {
    this.selectedCustomer?.selectedJobId?.set?.(jobId);
    return this.selectedCustomer?.loadJobDetails?.();
  };

  componentDidMount() {
    const {
      match: {
        params: { jobId },
      },
    } = this.props;
    return this.loadJobDetails(jobId);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { jobId },
      },
    } = this.props;
    if (jobId !== prevProps?.match?.params?.jobId) {
      return this.loadJobDetails(jobId);
    }
  }

  componentWillUnmount() {
    this.selectedCustomer?.selectedJobId?.reset?.();
  }

  handleCloseClick = () => {
    const {
      history: { push },
      customersStore: { selectedCustomerId },
    } = this.props;

    push(PATHS.MAP.CUSTOMER.replace(':customerId', selectedCustomerId));
  };

  get title() {
    const jobDetails = this.selectedCustomer?.jobDetails;
    if (jobDetails) {
      const name = jobDetails.name;
      const city = jobDetails.address.city;

      return `${name}${city ? ` in ${city}` : ''}`;
    }
    return '';
  }

  render() {
    const { collapseKeys, onCollapseChange } = this.props;

    const selectedCustomer = this.selectedCustomer;

    if (
      !selectedCustomer.selectedJobId ||
      (!selectedCustomer.repositoryJobDetails?.getState?.loading && !selectedCustomer.jobDetails)
    ) {
      return null;
    }

    if (selectedCustomer.selectedJobId && selectedCustomer.repositoryJobDetails.getState.loading) {
      return (
        <div className="SecondaryPanelJob">
          <div className="SecondaryPanelJob-loading">Loading...</div>
        </div>
      );
    }

    return (
      <div className="SecondaryPanelJob">
        <div className="SecondaryPanelJob-header">
          <div className="SecondaryPanelJob-name">
            <div className="SecondaryPanelJob-integration">Service Titan Job</div>
            <div className="SecondaryPanelJob-basic">
              <div className="SecondaryPanelJob-icon">
                <CustomerDomain domain={selectedCustomer.domain} size={24} />
              </div>
              <div className="SecondaryPanelJob-info">
                <div className="SecondaryPanelJob-id">{this.title || selectedCustomer.jobDetails.id}</div>
              </div>
            </div>
          </div>
          <div className="SecondaryPanelJob-close">
            <CloseButton onClick={this.handleCloseClick} />
          </div>
        </div>

        <div className="SecondaryPanelJob-content">
          <Collapse
            activeKey={collapseKeys}
            expandIcon={() => (
              <span className="anticon anticon-right ant-collapse-arrow">
                <ChevronIcon />
              </span>
            )}
            expandIconPosition={'left'}
            onChange={onCollapseChange}
          >
            <Collapse.Panel header={'Summary'} key={collapsePanels.Summary} className="SecondaryPanelJob-Block">
              <SecondaryPanelJobSummary />
            </Collapse.Panel>
            <Collapse.Panel header={'Job Details'} key={collapsePanels.JobDetails} className="SecondaryPanelJob-Block">
              <SecondaryPanelJobDetails />
            </Collapse.Panel>
            <Collapse.Panel
              header={'Service Address'}
              key={collapsePanels.ServiceAddress}
              className="SecondaryPanelJob-Block"
            >
              <SecondaryPanelJobServiceAddress />
            </Collapse.Panel>
            <Collapse.Panel header={'Estimates'} key={collapsePanels.Estimates} className="SecondaryPanelJob-Block">
              <SecondaryPanelJobEstimates />
            </Collapse.Panel>
            <Collapse.Panel header={'Invoices'} key={collapsePanels.Invoices} className="SecondaryPanelJob-Block">
              <SecondaryPanelJobInvoices />
            </Collapse.Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}

export default withRouter(
  withCollapseStorage(SecondaryPanelJob, 'map-secondary-panel-jobs-collapse', Object.values(collapsePanels))
);
