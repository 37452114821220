import React, { FC } from 'react';
import classNames from 'classnames';

import Carousel from 'components/Auth/Login/Carousel';

import './styles.scss';

interface IProps {
  className?: string;
  children: React.ReactNode;
}

const AuthPage: FC<IProps> = ({ children, className }) => {
  const cn = classNames('AuthPage', className);

  return (
    <div className={cn}>
      <div className="AuthPage-left">{children}</div>
      <div className="AuthPage-right">
        <div className="AuthPage-carousel">
          <Carousel />
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
