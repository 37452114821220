import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Collapse } from 'antd';
import { ChevronIcon } from 'assets';

import { getFormattedTime, getNowInFormat, isTimeBefore, isToday, timeZoneOffset, isDateBefore } from 'utils';

import { DATE_TIME_FORMATS, TIME, TIME_ZONE_INFO_TEXT } from 'config';

import Calendar from '../Calendar';
import Checkbox from '../Checkbox';
import TimePicker from './TimePicker';
import InfoTooltip from 'components/InfoTooltip';
import TimezoneSelect from 'containers/Select/TimezoneSelect';
import DatePicker from './DatePicker';
import withCollapseStorage from 'hocs/withCollapseStorage';

import './styles.scss';

const { Panel } = Collapse;

interface IProps {
  className?: string;
  lastEvent?: Date;
  disableLastEvent?: boolean;
  date?: Filters.IDateFilterOption;
  timeZone?: string;
  collapseKeys: string[];

  onCollapseChange(values: string | string[]): void;

  onChange?(from: number, to: number): void;
  quickLinks: Array<'today' | 'yesterday' | 'last-event' | 'this-month' | 'this-week' | 'last-month'>;
}

interface IState {
  calendarKey: number;
  current: boolean;
  isFromSelected: boolean;
  date: {
    from: Date;
    to: Date;
  };
  time: {
    from: string;
    to: string;
  };
  today: string;
}

enum collapsePanels {
  TimeZone = 'reports-timezone',
}

const ONE_HOUR_IN_MILLISECONDS = 60 * 60 * 1000;

const isSameDay = (left, right) => {
  return (
    moment(left).format(DATE_TIME_FORMATS.monthDatYearFull) === moment(right).format(DATE_TIME_FORMATS.monthDatYearFull)
  );
};

class TimeRange extends React.Component<IProps, IState> {
  public static defaultProps = {
    quickLinks: ['today', 'yesterday', 'last-event'],
  };

  constructor(props) {
    super(props);
    const { date, timeZone } = this.props;
    let fromDate;
    if (date) {
      if (date.from && new Date(date.from)) {
        fromDate = new Date(new Date(date.from).toLocaleString('en-US', { timeZone }));
      } else if (date.to) {
        fromDate = new Date(Number(moment(moment(date.to).format('MM/DD/YYYY')).format('x')));
      }
    }

    this.state = {
      date: {
        from: fromDate || new Date(),
        to: (date && date.to && new Date(new Date(date.to).toLocaleString('en-US', { timeZone }))) || new Date(),
      },
      time: {
        from:
          (date.from &&
            moment(date.from)
              .tz(props.timeZone)
              .format(DATE_TIME_FORMATS.hoursFullMinutesSpaceAm)) ||
          TIME.beggingOfTheDay,
        to:
          (date &&
            date.to &&
            moment(date.to)
              .tz(props.timeZone)
              .format(DATE_TIME_FORMATS.hoursFullMinutesSpaceAm)) ||
          TIME.beggingOfTheDay,
      },
      today: moment()
        .tz(props.timeZone)
        .format(DATE_TIME_FORMATS.monthDatYearFull),
      current: !date.to,
      isFromSelected: false,
      calendarKey: 0,
    };

    this.triggerChange();
  }

  triggerChange = (tz?: string) => {
    const { onChange, timeZone } = this.props;
    const { date, time, current } = this.state;

    let from = Number(
      moment(`${moment(date.from).format('MM/DD/YYYY')} ${time.from} +00:00`, 'MM/DD/YYYY hh:mm A Z').format('x')
    );
    let to = Number(
      moment(`${moment(date.to).format('MM/DD/YYYY')} ${time.to} +00:00`, 'MM/DD/YYYY hh:mm A Z').format('x')
    );

    const offset = this.getDSTOffset(from, to);

    from += timeZoneOffset(tz || timeZone) + offset.dstFrom;
    to += timeZoneOffset(tz || timeZone) + 59 * 1000 + offset.dstTo;

    if (onChange) {
      onChange(from, current ? null : to);
    }
  };

  getDSTOffset = (from, to) => {
    const { timeZone } = this.props;
    let dstFrom = 0;
    let dstTo = 0;

    const isSummerTime = moment.tz(timeZone).isDST();
    const isFromSummerTime = moment.tz(from, timeZone).isDST();
    const isToSummerTime = moment.tz(to, timeZone).isDST();

    if (isSummerTime) {
      if (!isFromSummerTime) {
        dstFrom = ONE_HOUR_IN_MILLISECONDS;
      }

      if (!isToSummerTime) {
        dstTo = ONE_HOUR_IN_MILLISECONDS;
      }
    } else {
      if (isFromSummerTime) {
        dstFrom = -ONE_HOUR_IN_MILLISECONDS;
      }

      if (isToSummerTime) {
        dstTo = -ONE_HOUR_IN_MILLISECONDS;
      }
    }

    return { dstFrom, dstTo };
  };

  setDate = (range) => {
    const { current, date, time, isFromSelected } = this.state;
    const timeSelection = {
      isFromSelected,
      current,
    };

    if (current && !isFromSelected) {
      time.from = TIME.beggingOfTheDay;
      if (!isSameDay(range.to, date.to)) {
        range.from = range.to;
      }
      range.to = date.to;
      timeSelection.isFromSelected = true;
    } else {
      if (isFromSelected) {
        if (!isToday(range.to.getTime(), this.props.timeZone)) {
          timeSelection.current = false;
        }
        timeSelection.isFromSelected = false;
      }
      if (!isSameDay(range.to, date.to)) {
        time.to = TIME.endOfTheDay;
      } else if (!isSameDay(range.from, date.from)) {
        time.from = TIME.beggingOfTheDay;
      }
    }

    if (isSameDay(range.to, range.from) && isTimeBefore(time.from, time.to)) {
      time.from = TIME.beggingOfTheDay;
    }

    this.setState({ date: range, time, ...timeSelection }, this.triggerChange);
  };

  setTime = (time, period) => {
    this.setState(
      {
        time: {
          ...this.state.time,
          [period]: time,
        },
      },
      this.triggerChange
    );
  };

  setDay = (date, period) => {
    if (period === 'from') {
      if (!isDateBefore(date, this.state.date.to)) {
        this.triggerChange();
        return;
      }
    } else if (period === 'to') {
      if (!isDateBefore(this.state.date.from, date)) {
        return;
      }
    }

    this.setState(
      {
        date: {
          ...this.state.date,
          [period]: date,
        },
        time: {
          ...this.state.time,
          [period]: period === 'from' ? TIME.beggingOfTheDay : TIME.endOfTheDay,
        },
        calendarKey: this.state.calendarKey + 1,
      },
      this.triggerChange
    );
  };

  onSelectFromDay = (day: Date) => {
    this.setDay(day, 'from');
  };

  onSelectToDay = (day: Date) => {
    this.setDay(day, 'to');
  };

  onSelectFrom = (time: string) => {
    this.setTime(time, 'from');
  };

  onSelectTo = (time: string) => {
    this.setTime(time, 'to');
  };

  setYesterday = () => {
    const day = 24 * 60 * 60 * 1000;
    this.setState(
      {
        date: {
          from: new Date(Date.now() - day),
          to: new Date(Date.now() - day),
        },
        time: {
          from: TIME.beggingOfTheDay,
          to: TIME.endOfTheDay,
        },
        calendarKey: this.state.calendarKey + 1,
        current: false,
      },
      this.triggerChange
    );
  };

  setLastEvent = () => {
    const { lastEvent } = this.props;
    if (!lastEvent) {
      return;
    }
    this.setState(
      {
        date: {
          from: lastEvent,
          to: lastEvent,
        },
        time: {
          from: TIME.beggingOfTheDay,
          to: TIME.endOfTheDay,
        },
        calendarKey: this.state.calendarKey + 1,
        current: false,
      },
      this.triggerChange
    );
  };

  setToday = () => {
    this.setState(
      {
        date: {
          from: new Date(),
          to: new Date(),
        },
        time: {
          from: TIME.beggingOfTheDay,
          to: moment().format(DATE_TIME_FORMATS.hoursFullMinutesSpaceAm),
        },
        calendarKey: this.state.calendarKey + 1,
        current: true,
      },
      this.triggerChange
    );
  };

  setLastMonth = () => {
    const beginningOfTheLastMonth = new Date();
    beginningOfTheLastMonth.setDate(1);
    beginningOfTheLastMonth.setMonth(beginningOfTheLastMonth.getMonth() - 1);
    const endOfTheLastMonth = new Date();
    endOfTheLastMonth.setDate(0);

    this.setState(
      {
        date: {
          from: beginningOfTheLastMonth,
          to: endOfTheLastMonth,
        },
        time: {
          from: TIME.beggingOfTheDay,
          to: moment().format(DATE_TIME_FORMATS.hoursFullMinutesSpaceAm),
        },
        calendarKey: this.state.calendarKey + 1,
        current: false,
      },
      this.triggerChange
    );
  };
  setThisMonth = () => {
    const beginningOfTheMonth = new Date();
    beginningOfTheMonth.setDate(1);

    this.setState(
      {
        date: {
          from: beginningOfTheMonth,
          to: new Date(),
        },
        time: {
          from: TIME.beggingOfTheDay,
          to: moment().format(DATE_TIME_FORMATS.hoursFullMinutesSpaceAm),
        },
        calendarKey: this.state.calendarKey + 1,
        current: false,
      },
      this.triggerChange
    );
  };
  setThisWeek = () => {
    const day = 24 * 60 * 60 * 1000;
    const beginningOfTheWeek = new Date(new Date().getTime() - new Date().getDay() * day);
    beginningOfTheWeek.setHours(0, 0, 0, 0);

    this.setState(
      {
        date: {
          from: beginningOfTheWeek,
          to: new Date(),
        },
        time: {
          from: TIME.beggingOfTheDay,
          to: moment().format(DATE_TIME_FORMATS.hoursFullMinutesSpaceAm),
        },
        calendarKey: this.state.calendarKey + 1,
        current: false,
      },
      this.triggerChange
    );
  };

  handleChange = () => {
    if (!this.state.current) {
      this.setState(
        {
          date: {
            from: this.state.date.from,
            to: new Date(Date.now()),
          },
          time: {
            from: this.state.time.from,
            to: moment().format(DATE_TIME_FORMATS.hoursFullMinutesSpaceAm),
          },
          calendarKey: this.state.calendarKey + 1,
          current: true,
        },
        this.triggerChange
      );
    } else {
      this.setState(
        {
          ...this.state,
          time: {
            ...this.state.time,
            to: TIME.endOfTheDay,
          },
          current: false,
        },
        this.triggerChange
      );
    }
  };

  get disabledDays() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    return [
      {
        after: new Date(currentYear, currentMonth, new Date().getDate()),
        before: new Date(currentYear + 1, currentMonth + 1, 1),
      },
    ];
  }

  getValue(date) {
    return moment(date).format(DATE_TIME_FORMATS.monthDatYearFull);
  }

  get currentTimeTo() {
    return this.state.current
      ? getFormattedTime(new Date().getTime(), DATE_TIME_FORMATS.hoursFullMinutesSpaceAm, this.props.timeZone)
      : undefined;
  }

  render() {
    const { date, time, calendarKey, current } = this.state;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const fromMonth = new Date(2013, 0);
    const toMonth = new Date(currentYear, currentMonth);

    const toClassName = classNames('TimeRange-timeSelectorItem-body', {
      'TimeRange-timeSelectorItem--disabled': Boolean(current),
    });

    let before;
    let after;
    if (isSameDay(date.to, date.from)) {
      before = time.to;
      after = time.from;

      if (isToday(date.from.getTime(), this.props.timeZone)) {
        before = getNowInFormat(DATE_TIME_FORMATS.hoursFullMinutesSpaceAm, this.props.timeZone);
      }
    }

    const fromValue = this.getValue(date.from);
    const toValue = this.getValue(date.to);
    const { collapseKeys, onCollapseChange, quickLinks } = this.props;

    return (
      <div className="TimeRange-root">
        <div className="TimeRange-title">Time range</div>
        <div className="TimeRange-body">
          <div className="TimeRange-DateSelector">
            <Calendar
              key={calendarKey}
              withMonthYearForm
              fromMonth={fromMonth}
              toMonth={toMonth}
              withRange
              month={date.to}
              from={date.from}
              to={date.to}
              disabledDays={this.disabledDays}
              onClick={this.setDate}
              ignoreNull
            />
          </div>
          <div className="TimeRange-timeSelector">
            <div className="TimeRange-timeSelectorItem">
              <div className="TimeRange-timeSelectorItem-header">From</div>
              <div className="TimeRange-timeSelectorItem-body">
                <div className="TimeRange-Selector-Date TimeRange-Selector-DateFrom">
                  <DatePicker value={fromValue} onChange={this.onSelectFromDay} />
                </div>
                <div className="TimeRange-Selector-Time">
                  <TimePicker onSelect={this.onSelectFrom} value={time.from} before={before} />
                </div>
              </div>
            </div>
            <div className="TimeRange-timeSelectorItem">
              <div className="TimeRange-timeSelectorItem-header">To</div>
              <div className="TimeRange-timeSelectorItem-current">
                <Checkbox label={`Current Time`} checked={this.state.current} onChange={this.handleChange} />
              </div>
              <div className={toClassName}>
                <div className="TimeRange-Selector-Date TimeRange-Selector-DateTo">
                  <DatePicker value={toValue} onChange={this.onSelectToDay} />
                </div>
                <div className="TimeRange-Selector-Time">
                  <TimePicker onSelect={this.onSelectTo} value={this.currentTimeTo || time.to} after={after} />
                </div>
              </div>
            </div>
            <div className="TimeRange-timeSelectorLinks">
              <div className="TimeRange-timeSelectorLinks-header">Quick links</div>
              <div className="TimeRange-timeSelectorLinks-body">
                {quickLinks.includes('today') && <button onClick={this.setToday}>Today</button>}
                {quickLinks.includes('yesterday') && <button onClick={this.setYesterday}>Yesterday</button>}
                {quickLinks.includes('last-event') && (
                  <button onClick={this.setLastEvent} disabled={this.props.disableLastEvent}>
                    Last Event
                  </button>
                )}

                {quickLinks.includes('this-week') && <button onClick={this.setThisWeek}>This Week</button>}
                {quickLinks.includes('this-month') && <button onClick={this.setThisMonth}>This Month</button>}
                {quickLinks.includes('last-month') && <button onClick={this.setLastMonth}>Last Month</button>}
              </div>
            </div>
          </div>
        </div>

        <Collapse
          defaultActiveKey={collapseKeys}
          expandIconPosition={'left'}
          onChange={onCollapseChange}
          expandIcon={() => (
            <span className="anticon anticon-right ant-collapse-arrow">
              <ChevronIcon />
            </span>
          )}
        >
          <Panel
            header="Advanced options"
            key={collapsePanels.TimeZone}
            className="TimeRange-title TimeRange-titleAdvanced"
          >
            <div className="TimeRange-advancedHeader">
              <div className="TimeRange-advancedHeaderTitle">Time Zone</div>
              <div className="TimeRange-advancedHeaderInfo">
                <InfoTooltip>{TIME_ZONE_INFO_TEXT}</InfoTooltip>
              </div>
            </div>
            <div className="TimeRange-body TimeRange-advancedBody">
              <TimezoneSelect onChange={this.triggerChange} />
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default withCollapseStorage(TimeRange, 'report-panel-collapse', Object.values(collapsePanels));
