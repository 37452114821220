export const PATHS = {
  OLD_VERSION: {
    LOGIN: '/track/Track?page=login',
  },
  ADMINISTRATION: { INDEX: '/administration' },
  ALERTS: { INDEX: '/alerts' },
  AUTH: {
    INDEX: '/auth/',
    RESET_PASSWORD: '/auth/reset-password',
    SIGN_IN: '/auth/sign-in',
    SIGN_UP: '/auth/sign-up',
    LOGIN: '/auth/login',
    WELCOME_EMAIL: '/auth/welcome-email',
    WELCOME_EMAIL_MULTI: '/auth/welcome-email-multi',
    CREATE_USER: '/auth/create-user',
    UPDATE_PASSWORD: '/auth/update-password',
    UPDATE_PASSWORD_EMAIL: '/auth/update-password-email',
    FORGOT_PASSWORD: '/auth/forgot-password',
  },
  CONTACT_US: '/contact-us',
  FAQ: '/faq',
  GEOZONES: { INDEX: '/geozones' },
  GROUPS: { INDEX: '/groups' },
  HOME: '/',
  MAP: {
    INDEX: '/map',
    VEHICLES: '/map/vehicles',
    VEHICLES_TIME_FILTER: '/map/vehicles/filters/time',
    VEHICLE: '/map/vehicles/:vehicleId',
    VEHICLE_PREDEFINED_TIME_FILTER: '/map/vehicles/:vehicleId/filters/predefined-time',
    VEHICLE_TIME_FILTER: '/map/vehicles/:vehicleId/filters/time',
    GROUPS: '/map/groups',
    GROUPS_TIME_FILTER: '/map/groups/:groupId/filters/time',
    GROUP: '/map/groups/:groupId',
    GROUP_VEHICLE: '/map/groups/:groupId/:vehicleId',
    GROUP_VEHICLE_PREDEFINED_TIME_FILTER: '/map/groups/:groupId/:vehicleId/filters/predefined-time',
    GROUP_VEHICLE_TIME_FILTER: '/map/groups/:groupId/:vehicleId/filters/time',
    GEOZONES: '/map/geozones',
    GEOZONES_TIME_FILTER: '/map/geozones/filters/time',
    GEOZONE: '/map/geozones/:geozoneId',
    BASIC: '/map/basic',
    CUSTOMERS: '/map/customers/',
    CUSTOMER: '/map/customers/:customerId',
    CUSTOMER_ITEM: '/map/customers/:customerId/:type/:jobId',
    CUSTOMER_JOB: '/map/customers/:customerId/job/:jobId',
    CUSTOMER_INVOICE: '/map/customers/:customerId/invoice/:invoiceId',
    CUSTOMER_ESTIMATE: '/map/customers/:customerId/estimate/:estimateId',
  },
  REPORTS: {
    INDEX: '/reports',
    SCHEDULE: '/reports/schedule',
    REPORT: '/reports/:reportId/:optionId?',
    EVENT_DETAILS: '/reports/EventDetail',
  },
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  USERS: { INDEX: '/users' },
  VEHICLES: {
    INDEX: '/vehicles',
  },
  DASHCAMS: {
    INDEX: '/dashcams',
    MEDIA_MANAGER: '/dashcams/media-library',
    MEDIA_PLAYER: '/dashcams/media-player',
    MEDIA_REQUEST: {
      INDEX: '/dashcams/media-request',
      STEP_1: '/dashcams/media-request/step-1',
      STEP_2: '/dashcams/media-request/step-2',
      STEP_3: '/dashcams/media-request/step-3',
    },
    AD: '/dashcams/ad',
  },
  DISPATCH: {
    INDEX: '/dispatch',
    SEND_DISPATCH: '/dispatch/send',
    EDIT_DISPATCH: '/dispatch/:id/edit',
    TABLE: '/dispatch/table',
  },
  NOT_FOUND: '/404',
  ADMIN: {
    INDEX: '/admin',
    TABLES: {
      INDEX: '/admin/tables',
      DASHCAMS: {
        INDEX: '/admin/tables/dashcams',
        DASHCAM: '/admin/tables/dashcams/:id',
      },
      DEVICES: '/admin/tables/devices',
      DRIVERS: {
        INDEX: '/admin/tables/drivers',
        DRIVER: '/admin/tables/drivers/:id',
      },
      GEOZONES: {
        INDEX: '/admin/tables/geozones',
        GEOZONE: '/admin/tables/geozones/:id',
      },
      GROUPS: {
        INDEX: '/admin/tables/groups',
        GROUP: '/admin/tables/groups/:id',
      },
      USERS: {
        INDEX: '/admin/tables/users',
        USER: '/admin/tables/users/:id',
      },
      PEOPLE: {
        INDEX: '/admin/tables/people',
        PERSON: '/admin/tables/people/:id',
      },
      VEHICLES: {
        INDEX: '/admin/tables/vehicles',
        VEHICLE: '/admin/tables/vehicles/:id',
      },
      ACCOUNT: '/admin/tables/account',
    },
    INTEGRATIONS: {
      INDEX: '/admin/integrations',
      CONNECTING: {
        INDEX: '/admin/integrations/connecting',
        QB: {
          ERROR: '/admin/integrations/connecting/qb/error',
          SUCCESS: '/admin/integrations/connecting/qb/success',
        },
      },
    },
  },
  MAINTENANCE: {
    INDEX: '/maintenance',
    APPLY_TASKS: {
      INDEX: '/maintenance/apply-tasks',
      TASK: '/maintenance/apply-tasks/:id',
    },
  },
  INSPECTIONS: {
    INDEX: '/inspections',
  },
};
