import { observable, action, computed, reaction } from 'mobx';
import { merge, uniqBy, cloneDeep } from 'lodash';
import userStore from 'stores/UserStore';
import {
  getSessionStorageItem,
  setSessionStorageItem,
  removeSessionStorageItem,
  getStorageItem,
  setStorageItem,
  removeStorageItem,
} from 'utils';
import type { ISortColumn } from 'models/Tables/ISortColumn';
import type { ITablePagination } from 'models/Tables/ITablePagination';
import type { ITableSearchField } from 'models/Tables/ITableSearchField';
import type { IInitialTableData } from 'models/Tables/IInitialTableData';
import { USER_DATA_STATUS, USER_DATA_STATUS_STORAGE } from 'config';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

const initialPagination = {
  page: 1,
  pageSize: 50,
  showAll: false,
};

interface ITableData {
  value: string;
  isSelected: boolean;
}

class TableBase<T extends ITableData> {
  @observable initialTableData: IInitialTableData<T> = null;
  @observable columns: T[] = [];
  @observable sortedColumn: ISortColumn;
  @observable searchQuery: string = '';
  @observable pagination: ITablePagination = null;
  @observable searchColumns: ITableSearchField[] = [];
  @observable isSearchChanged: boolean = false;

  constructor(data: IInitialTableData<T>) {
    this.init(data);

    reaction(
      () => userStore.userData,
      (userData) => {
        if (!userData) {
          this.resetSortedColumn().resetPagination();
        }
      }
    );
  }

  private init(data: IInitialTableData<T>) {
    this.setInitialTable(data);
    this.setInitialColumns();
    this.setInitialSortedColumn();
    this.setInitialPagination();
    this.setInitialSearches();
    this.listenAppVersion();
  }

  @computed get selectedSearchColumns() {
    return this.searchColumns.filter((col) => {
      const column = this.columns.find((column) => column.value === col.column);

      return column && column.isSelected;
    });
  }

  @computed get hasActiveSearch() {
    return this.selectedSearchColumns.some((column) => column.value);
  }

  @action setInitialTable = (data: IInitialTableData<T>) => (this.initialTableData = data);

  @action setInitialColumns() {
    const storedColumns = this.initialTableData.storageNames.columns
      ? JSON.parse(getStorageItem(this.initialTableData.storageNames.columns)) || []
      : [];

    this.columns = this.getAdaptedColumns(storedColumns);
  }

  @action setInitialSortedColumn() {
    this.sortedColumn =
      JSON.parse(getSessionStorageItem(this.initialTableData.storageNames.sortedColumn)) ||
      this.initialTableData.sortedColumn;
  }

  @action setInitialPagination = () => {
    this.pagination =
      JSON.parse(getSessionStorageItem(this.initialTableData.storageNames.pagination)) || initialPagination;
  };

  @action setInitialSearches = () => {
    this.searchColumns = JSON.parse(getSessionStorageItem(this.initialTableData.storageNames.searchInColumn)) || [];
  };

  @action setColumns = (columns: T[]) => {
    this.columns = columns;
    setStorageItem(this.initialTableData.storageNames.columns, JSON.stringify(columns));
  };

  @action setSortedColumn = (sortedColumn: ISortColumn) => {
    this.sortedColumn = sortedColumn;
    setSessionStorageItem(this.initialTableData.storageNames.sortedColumn, JSON.stringify(sortedColumn));
  };

  @action setSearchColumn = (searchColumns: ITableSearchField[]) => {
    this.isSearchChanged = true;
    this.searchColumns = searchColumns;
    setSessionStorageItem(this.initialTableData.storageNames.searchInColumn, JSON.stringify(searchColumns));
  };

  @action setSearchQuery = (query: string) => (this.searchQuery = query);

  @action setPagination = (pagination: ITablePagination) => {
    this.pagination = pagination;
    setSessionStorageItem(this.initialTableData.storageNames.pagination, JSON.stringify(pagination));
  };

  @action resetColumns = () => {
    removeStorageItem(this.initialTableData.storageNames.columns);

    this.columns = this.getAdaptedColumns();

    return this;
  };

  @action resetSortedColumn = () => {
    this.sortedColumn = this.initialTableData.sortedColumn;
    removeSessionStorageItem(this.initialTableData.storageNames.sortedColumn);

    return this;
  };

  @action resetPagination = () => {
    this.pagination = initialPagination;
    removeSessionStorageItem(this.initialTableData.storageNames.pagination);

    return this;
  };

  private getAdaptedColumns = (storedColumns = []) => {
    const availableColumns = this.initialTableData.columns.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.value]: true,
      }),
      {}
    );

    const columns = storedColumns.filter((column) => {
      return availableColumns[column.value];
    });

    return uniqBy(merge(cloneDeep(this.initialTableData.columns), columns), 'value');
  };

  private listenAppVersion = () => {
    const userStatus = getStorageItem(USER_DATA_STATUS_STORAGE);

    if (userStatus === USER_DATA_STATUS.LOADING) {
      EventsBus.get().once(APP_EVENTS.USER.DATA.GET, ({ status }) => this.manageStorages(status));
    } else {
      this.manageStorages(userStatus);
    }
  };

  private manageStorages = (userStatus) => {
    if (userStatus === USER_DATA_STATUS.UPDATED) {
      this.resetColumns().resetSortedColumn().resetPagination();
    }
  };
}

export default TableBase;
