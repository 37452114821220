import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { inject, Observer } from 'mobx-react';
import noop from 'lodash/noop';

import { ACL, MAINTENANCES_STORE, PATHS, USER_STORE } from 'config';

import validateAccessLevel from 'stores/acl/validator';

import type { Maintenances as MaintenancesStore, UserStore } from 'stores';

import Notification from 'components/Notification';

import { WrenchIcon } from 'assets';

import './styles.scss';


interface IProps {
  [MAINTENANCES_STORE]?: MaintenancesStore;
  [USER_STORE]?: UserStore;
  pathname: string;
}

const MaintenanceRequestsNotification: React.FC<IProps> = ({maintenancesStore, userStore, pathname}) => {
  const validatePage = useCallback(() => {
    if (pathname.startsWith(PATHS.MAINTENANCE.INDEX)) {
      maintenancesStore.requests.hideNotification.toggle(true);
    }
  }, [pathname])

  useEffect(() => {
    if (maintenancesStore.requests.status === 'IDLE') {
      if (validateAccessLevel([ACL.MAINTENANCE.REQUESTS.READ])) {
        maintenancesStore.requests.fetch().catch(noop);
      }
    }
    validatePage();
  }, []);

  useEffect(() => {
    validatePage();
  }, [validatePage])

  const onCloseDisconnectNotification = () => {
    maintenancesStore.requests.hideNotification.toggle(true);
    userStore?.updateLatestSessionStarted();
  }

  return <Observer
    render={() => {
      const latestRequests = maintenancesStore.requests?.items.filter((request) => request.requestReceived > userStore?.latestSessionOpened);
      const isMaintenancePage = pathname.startsWith(PATHS.MAINTENANCE.INDEX)

      return (latestRequests.length > 0 && userStore?.latestSessionOpened ? <>
        <span className="MaintenanceRequestsNotification-icon"/>
        {!maintenancesStore.requests.hideNotification.value && !isMaintenancePage && (<div className="MaintenanceRequestsNotification-list">

          {latestRequests.slice(-2).map((request) => {
            return (<Notification
              key={request.id}
              type="custom"
              autoClose={false}
              icon={<WrenchIcon className="MaintenanceRequestsNotification-iconImage" />}
              text={(
                <>
              <span className="MaintenanceRequestsNotification-text">
                {request.vehicleName}: <span className="MaintenanceRequestsNotification-requestName">{request.requestName}</span>
              </span>
                  <Link to={PATHS.MAINTENANCE.INDEX}>
                    <span className="MaintenanceRequestsNotification-action">View Maintenance Requests</span>
                  </Link>
                </>
              )}
              className="MaintenanceRequestsNotification-notification"
              title="New Maintenance Request Submitted"
              onClose={onCloseDisconnectNotification}
            />)
          })}
        </div>)}
      </> : null)
    }}
  />;
};

export default inject(MAINTENANCES_STORE, USER_STORE)(MaintenanceRequestsNotification);
