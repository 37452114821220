import React, { useEffect, useRef, useState } from 'react';
import { Observer } from 'mobx-react';
import classnames from 'classnames';
import { ChevronIcon, ChevronDoubleIcon } from 'assets';
import { KEY_TYPE } from 'config';

import type MediaClipsItem from 'models/Dashcam/MediaLibrary/MediaClips/MediaClipsItem';
import MediaLibraryClipImage from '../../MediaLibraryClipImage';

import './styles.scss';

interface IProps {
  clips: MediaClipsItem[];
  active: MediaClipsItem;
  onChange: (clip: MediaClipsItem) => void;
}

const MediaLibrarySlider: React.FC<IProps> = ({ clips, active, onChange }) => {
  const listRef = useRef(null);
  const activeItemRef = useRef(null);
  const [hasScroll, setScroll] = useState(false);
  const scrollCoefficient = 0.25;
  const activeClipIndex = clips.indexOf(active);

  const setPosition = (left) => {
    listRef.current.scroll({ left, behavior: 'smooth' });
  };

  const scrollLeft = () => {
    const leftPosition = listRef.current.scrollLeft - listRef.current.offsetWidth * scrollCoefficient;
    setPosition(leftPosition > 0 ? leftPosition : 0);
  };

  const scrollRight = () => {
    const containerWidth = listRef.current.offsetWidth;
    const scrollWidth = listRef.current.scrollWidth;
    const leftPosition = listRef.current.scrollLeft + containerWidth * scrollCoefficient;

    setPosition(leftPosition + containerWidth < scrollWidth ? leftPosition : scrollWidth - containerWidth);
  };

  const selectPrevClip = () => {
    if (activeClipIndex !== 0) {
      onChange(clips[activeClipIndex - 1]);
    }
  };

  const selectNextClip = () => {
    if (activeClipIndex + 1 !== clips.length) {
      onChange(clips[activeClipIndex + 1]);
    }
  };

  const selectClipsOnArrowsClick = (event) => {
    if (event.key === KEY_TYPE.LEFT_ARROW) {
      selectPrevClip();
    }

    if (event.key === KEY_TYPE.RIGHT_ARROW) {
      selectNextClip();
    }
  };

  const cnClip = (clip: MediaClipsItem) => {
    return classnames('MediaLibrarySlider-listClips', { 'MediaLibrarySlider-listClips--active': clip === active });
  };

  useEffect(() => {
    window.addEventListener('keyup', selectClipsOnArrowsClick);

    return () => {
      window.removeEventListener('keyup', selectClipsOnArrowsClick);
    };
  }, [selectClipsOnArrowsClick]);

  useEffect(() => {
    if (listRef.current.offsetWidth < activeItemRef.current.offsetLeft + activeItemRef.current.offsetWidth) {
      setPosition(activeItemRef.current.offsetLeft - listRef.current.offsetWidth + activeItemRef.current.offsetWidth);
    }

    if (listRef.current.scrollLeft > activeItemRef.current.offsetLeft - activeItemRef.current.offsetWidth) {
      setPosition(activeItemRef.current.offsetLeft - activeItemRef.current.offsetWidth);
    }
  }, [active]);

  useEffect(() => {
    if (listRef.current?.scrollWidth > listRef.current?.clientWidth) {
      setScroll(true);
    }
  }, []);

  return (
    <Observer
      render={() => {
        const controlChangeCn = classnames('MediaLibrarySlider-controlChange', {
          'MediaLibrarySlider-controlChange--disabled': clips.length < 2,
        });
        const controlScrollCn = classnames('MediaLibrarySlider-controlScroll', {
          'MediaLibrarySlider-controlScroll--disabled': !hasScroll,
        });

        return (
          <div className="MediaLibrarySlider">
            <div className="MediaLibrarySlider-control MediaLibrarySlider-control--left">
              <div className={controlChangeCn} onClick={selectPrevClip}>
                <ChevronIcon className="MediaLibrarySlider-controlIcon MediaLibrarySlider-controlIcon--change" />
              </div>
              <div className={controlScrollCn} onClick={scrollLeft}>
                <ChevronDoubleIcon className="MediaLibrarySlider-controlIcon MediaLibrarySlider-controlIcon--scroll" />
              </div>
            </div>
            <div className="MediaLibrarySlider-list" ref={listRef}>
              {clips.map((clip, i) => (
                <div key={i} className={cnClip(clip)} ref={clip === active ? activeItemRef : null}>
                  <div className="MediaLibrarySlider-listClipsMedia">
                    <div className="MediaLibrarySlider-listClipsMediaImage">
                      <MediaLibraryClipImage infoItem={clip.media.front} onClick={() => onChange(clip)} />
                    </div>
                    <p className="MediaLibrarySlider-listClipsMediaName">{clip.displayName}</p>
                    <p className="MediaLibrarySlider-listClipsMediaTime">{clip.media.front.date.hourMinuteSecondAm}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="MediaLibrarySlider-control MediaLibrarySlider-control--right">
              <div className={controlChangeCn} onClick={selectNextClip}>
                <ChevronIcon className="MediaLibrarySlider-controlIcon MediaLibrarySlider-controlIcon--change" />
              </div>
              <div className={controlScrollCn} onClick={scrollRight}>
                <ChevronDoubleIcon className="MediaLibrarySlider-controlIcon MediaLibrarySlider-controlIcon--scroll" />
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default MediaLibrarySlider;
