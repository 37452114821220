import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { GroupsAdmin } from 'stores/Admin/Groups';
import ConfirmationModal from 'components/Admin/ConfirmationModal';

import './styles.scss';

interface IProps {
  className?: string;
  groupId: string;
  groupName: string;
  groupsAdmin?: GroupsAdmin;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}

@inject(({ adminStore: { groupsAdmin } }) => ({
  groupsAdmin,
}))
@observer
class DeleteGroupModal extends Component<IProps> {
  componentWillUnmount() {
    const {
      groupsAdmin: { deleteGroupAPIRequestStatus },
    } = this.props;

    deleteGroupAPIRequestStatus.reset();
  }

  handleDeleteGroup = async () => {
    const {
      groupId,
      groupsAdmin: { deleteGroup, deleteGroupAPIRequestStatus },
      onSuccess,
    } = this.props;

    await deleteGroup(groupId);

    if (deleteGroupAPIRequestStatus.success) {
      onSuccess();
    }
  };

  render() {
    const {
      isOpen,
      onCancel,
      groupName,
      groupId,
      groupsAdmin: { deleteGroupAPIRequestStatus },
    } = this.props;

    return (
      <ConfirmationModal
        applyButtonTitle="Yes, Delete"
        cancelButtonStyles={{ border: 'none' }}
        contentStyles={{ padding: '0 30px', backgroundColor: '#fff' }}
        isOpen={isOpen}
        isSubmitDisabled={deleteGroupAPIRequestStatus.loading}
        onCancel={onCancel}
        onSubmit={this.handleDeleteGroup}
        title={`Delete ${groupName || groupId} Group?`}
        withoutCloseIcon
      >
        <div className="DeleteGroupModal">
          <p className="DeleteGroupModal-text DeleteGroupModal-text--top">
            Deleting this group will permanently remove it from the system and it will no longer be accessible.
          </p>
          <p className="DeleteGroupModal-text DeleteGroupModal-text--bottom">Do you want to continue?</p>
        </div>
      </ConfirmationModal>
    );
  }
}

export default DeleteGroupModal;
