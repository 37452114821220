import React, { FC } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import type MediaTypeToggleFilter from 'models/Dashcam/MediaLibrary/MediaType/MediaTypeToggleFilter';

import { CloseIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  filter: MediaTypeToggleFilter;
}

const MediaLibraryFilterLabel: FC<IProps> = ({ className, filter: { name, isSelected } }) => {
  const cn = classNames('MediaLibraryFilterLabel', className);
  const unselect = () => isSelected.toggle(false);

  return (
    <div className={cn} onClick={unselect}>
      <div className="MediaLibraryFilterLabel-name">{name}</div>
      <div className="MediaLibraryFilterLabel-close">
        <CloseIcon />
      </div>
    </div>
  );
};

export default observer(MediaLibraryFilterLabel);
