import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import SearchableSelect from 'components/Select/SearchableSelect';

import type { PersistenceStore } from 'stores';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

interface IProps {
  persistenceStore?: PersistenceStore;
  value?: Select.ISelectOption;
  handleChange?: (option: Select.ISelectOption, system?: boolean) => void;
  placeholder?: string;
  searchPlaceholder?: string;
  emptySearchResults?: string;
  isSearchable?: boolean;
  persistChange?: boolean;
  disabled?: boolean;
  withAll?: boolean;
  withNone?: boolean;
  alignRight?: boolean;
  selectFirst?: boolean;
  valueSource?: 'id' | 'assetId';
  timeout?: number;
  multiple?: boolean;
}

@inject(({ persistenceStore }) => ({ persistenceStore }))
@observer
class SearchableVehicleSelect extends Component<IProps> {
  static defaultProps = {
    placeholder: 'Select Vehicle',
    searchPlaceholder: 'Search Vehicles',
    emptySearchResults: 'No Vehicles Found',
    valueSource: 'id',
  };

  static allOption = { label: 'All Vehicles', value: 'all' };
  static noneOption = { label: 'None', value: 'none' };
  repositoryVehiclesIds: IEntityRepository;

  constructor(props) {
    super(props);

    if (props.persistChange && props.persistenceStore.selectedVehicle && !props.value) {
      this.props.handleChange(props.persistenceStore.selectedVehicle);
    } else if (props.withAll && !props.value?.value) {
      this.props.handleChange(SearchableVehicleSelect.allOption);
    }

    this.repositoryVehiclesIds = repositoryService.get('vehicles').entity('ids');
  }

  getData = (props: { page: number; pageSize: number; filter: string }) => {
    const data = {
      includeInactive: true,
      mediaEnabled: false,
      page: props.page,
      pageSize: props.pageSize,
      searchValue: props.filter,
    };
    const { valueSource } = this.props;

    return this.repositoryVehiclesIds.get(data).then((data) => {
      const totalPages = data.totalPages;
      const options = data.items.map((item) => {
        return {
          value: item[valueSource].toString(),
          label: item.displayName,
          badge: item.cpNumber,
        };
      });

      if (!props.filter && this.props.withAll && props.page === 0) {
        options.unshift(SearchableVehicleSelect.allOption);
      }

      if (!props.filter && this.props.withNone && props.page === 0) {
        options.unshift(SearchableVehicleSelect.noneOption);
      }

      if (this.isOptionNotInOptions(props, options, this.props.value, this.props.value?.value)) {
        this.updateOptions(options, this.props.value);
      }

      if (
        this.isOptionNotInOptions(
          props,
          options,
          this.props.persistChange,
          this.props.persistenceStore?.selectedVehicle
        )
      ) {
        this.updateOptions(options, this.props.persistenceStore.selectedVehicle);
      }

      if (this.props.selectFirst && options.length && !this.props.value?.value) {
        this.props.handleChange(options[0], true);
      }

      EventsBus.get().trigger(APP_EVENTS.VEHICLES.LIST.GET);

      return {
        totalPages,
        options,
      };
    });
  };

  updateOptions = (options, option) => {
    options.push({ ...option });
  };

  isOptionNotInOptions = (props, options, option, optionValue) => {
    return (
      !props.filter && props.page === 0 && option && optionValue && !options.find((item) => item.value === optionValue)
    );
  };

  handleChange = (option: Select.ISelectOption) => {
    this.props.handleChange(option);
    if (this.props.persistChange) {
      this.props.persistenceStore.updateSelectedVehicle({ ...option });
    }
  };

  render() {
    return (
      <SearchableSelect
        {...this.props}
        getData={this.getData}
        handleChange={this.handleChange}
        hasBadge
        alignRight={this.props.alignRight}
      />
    );
  }
}

export default SearchableVehicleSelect;
