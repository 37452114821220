import React, { useCallback } from 'react';
import classNames from 'classnames';

import InfoTooltip from 'components/InfoTooltip';

import { LockIcon, SelectedIcon } from 'assets';

import './styles.scss';

interface IProps {
  id: number;
  name: string;
  type: string;
  disabled?: boolean;
  locked?: boolean;
  current?: boolean;
  selected?: boolean;
  onSelect:(value: number) => void;
}

const AccountListItem: React.FC<IProps> = ({ id, name, type, disabled = false, locked = false, current = false, selected = false, onSelect}) => {
  const cn = classNames('AccountListItem', {
    'AccountListItem--disabled': disabled || locked || current,
    'AccountListItem--selected': selected,
  });

  const typeCN = classNames('AccountListItem-type', {
    'AccountListItem-type--pro': type === 'Pro',
  });

  const handleSelect = useCallback(() => {
    onSelect(id);
  }, [onSelect, id]);

  return <div className={cn} onClick={!(locked || disabled || current) && handleSelect}>
    <div className="AccountListItem-name">{name}</div>
    <div className="AccountListItem-info">
      {type && <div className={typeCN}>{type}</div>}
      {locked && <div className="AccountListItem-locked">
        <span className="AccountListItem-lockedInfo"><LockIcon /></span>
        <span className="AccountListItem-lockedText">Account Locked</span>
        <span className="AccountListItem-lockedToolTip">
          <InfoTooltip>
            Please have an Admin user login to update payment information or contact ClearPathGPS’ Billing Team at (805) 979-3442 Option 3
          </InfoTooltip>
        </span>
      </div>}
      {current && <div className="AccountListItem-current">
        <span className="AccountListItem-currentIcon"><SelectedIcon /></span>
        <span className="AccountListItem-currentText">Current Account</span>
      </div>}
    </div>
  </div>;
};

export default AccountListItem;
