import { SimpleField } from 'models/Fields';
import type { INTERVAL_UNIT } from 'config';

class MaintenanceConfig {
  next: SimpleField<string>;
  last: SimpleField<string>;
  interval: SimpleField<string>;
  intervalUnit: SimpleField<INTERVAL_UNIT>;
  dueIn: SimpleField<string>;
  unit: { short: string; full: string; maximumFractionDigits: number };
  private coefficient: number = 1;

  constructor(
    {
      next = null,
      last = null,
      interval = null,
      dueIn = null,
      intervalUnit = null,
      unit = { short: '', full: '', maximumFractionDigits: 0 },
    },
    coefficient
  ) {
    this.coefficient = coefficient;
    this.next = new SimpleField(this.convertValue(next));
    this.last = new SimpleField(this.convertValue(last));
    this.interval = new SimpleField(this.convertValue(interval));
    this.intervalUnit = new SimpleField(intervalUnit);
    this.dueIn = new SimpleField(this.convertValue(dueIn));
    this.unit = unit;
  }

  get isUpdated() {
    return this.next.isUpdated || this.last.isUpdated || this.interval.isUpdated || this.intervalUnit.isUpdated;
  }

  reset = () => {
    this.next.reset();
    this.last.reset();
    this.interval.reset();
    this.intervalUnit.reset();
    this.dueIn.reset();
  };

  remove = () => {
    this.next.set('');
    this.last.set('');
    this.interval.set('');
  };

  updateLast = (value: number) => {
    this.last.set(value.toFixed(1));
    this.next.set((value + Number(this.interval.value)).toFixed(1));
  };

  get model() {
    const config = {
      next: Number((Number(this.next.value) / this.coefficient).toFixed(1)),
      last: Number((Number(this.last.value) / this.coefficient).toFixed(1)),
      interval: Number((Number(this.interval.value) / this.coefficient).toFixed(1)),
    };

    if (this.intervalUnit.value) {
      const key = 'intervalUnit';
      config[key] = this.intervalUnit.value;
    }

    return config;
  }

  private convertValue = (value: number) => {
    return value ? (value * this.coefficient).toFixed(1) : '';
  };
}

export default MaintenanceConfig;
