import { computed } from 'mobx';
import TableBase from 'stores/TableBase';
import type { IEditColumns } from 'models/Tables/IEditColumns';
import type { ISortColumn } from 'models/Tables/ISortColumn';
import { MAINTENANCE_SEARCH_PARAMS, MAINTENANCE_UPCOMING_TABLE_COLUMNS, MAINTENANCE_VIEW, STORAGE_ITEMS } from 'config';
import type { Maintenances } from 'stores';
import MaintenanceFilters from './Filters';
import MaintenanceUpcomingDownload from '../Download/UpcomingDownload';
import { repositoryService } from 'services';
import { SimpleField } from 'models/Fields';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

const getInitialColumns = (view: MAINTENANCE_VIEW): Array<IEditColumns<MAINTENANCE_UPCOMING_TABLE_COLUMNS>> => {
  switch (view) {
    case MAINTENANCE_VIEW.ADMIN:
      return [
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.TASK_NAME, isSelected: true },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.DUE_IN, isSelected: true },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.FREQUENCY, isSelected: true },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.SERVICE_TYPE, isSelected: true },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.DRIVER_NAME, isSelected: false },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.CREATED_ON, isSelected: false },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.CREATED_BY, isSelected: false },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.MODIFIED_ON, isSelected: false },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.MODIFIED_BY, isSelected: false },
      ];
    case MAINTENANCE_VIEW.DEFAULT:
    default:
      return [
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.TASK_NAME, isSelected: true },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.VEHICLE_NAME, isSelected: true },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.DUE_IN, isSelected: true },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.FREQUENCY, isSelected: true },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.SERVICE_TYPE, isSelected: true },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.CURRENT_ODOMETER, isSelected: true },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.CURRENT_ENGINE_HOURS, isSelected: true },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.DRIVER_NAME, isSelected: false },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.CREATED_ON, isSelected: false },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.CREATED_BY, isSelected: false },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.MODIFIED_ON, isSelected: false },
        { value: MAINTENANCE_UPCOMING_TABLE_COLUMNS.MODIFIED_BY, isSelected: false },
      ];
  }
};

const initialSortedColumn: ISortColumn = {
  field: MAINTENANCE_UPCOMING_TABLE_COLUMNS.DUE_IN,
  order: 'ascend',
};

const storageNames = {
  [MAINTENANCE_VIEW.DEFAULT]: {
    columns: STORAGE_ITEMS.maintenance.upcoming.columns,
    sortedColumn: STORAGE_ITEMS.maintenance.upcoming.sortedColumn,
    pagination: STORAGE_ITEMS.maintenance.upcoming.pagination,
    searchInColumn: STORAGE_ITEMS.maintenance.upcoming.searchInColumn,
  },
  [MAINTENANCE_VIEW.ADMIN]: {
    columns: STORAGE_ITEMS.admin.maintenance.upcoming.columns,
    sortedColumn: STORAGE_ITEMS.admin.maintenance.upcoming.sortedColumn,
    pagination: STORAGE_ITEMS.admin.maintenance.upcoming.pagination,
    searchInColumn: STORAGE_ITEMS.admin.maintenance.upcoming.searchInColumn,
  },
};

const maintenanceSearchMap = {
  [MAINTENANCE_UPCOMING_TABLE_COLUMNS.TASK_NAME]: MAINTENANCE_SEARCH_PARAMS.NAME,
  [MAINTENANCE_UPCOMING_TABLE_COLUMNS.VEHICLE_NAME]: MAINTENANCE_SEARCH_PARAMS.ASSET_NAME,
  [MAINTENANCE_UPCOMING_TABLE_COLUMNS.DUE_IN]: MAINTENANCE_SEARCH_PARAMS.DUE_IN_DAYS,
  [MAINTENANCE_UPCOMING_TABLE_COLUMNS.FREQUENCY]: MAINTENANCE_SEARCH_PARAMS.FREQUENCY,
  [MAINTENANCE_UPCOMING_TABLE_COLUMNS.SERVICE_TYPE]: MAINTENANCE_SEARCH_PARAMS.SERVICE_TYPE_NAME,
  [MAINTENANCE_UPCOMING_TABLE_COLUMNS.CURRENT_ODOMETER]: MAINTENANCE_SEARCH_PARAMS.ODOMETER_DUE_IN,
  [MAINTENANCE_UPCOMING_TABLE_COLUMNS.CURRENT_ENGINE_HOURS]: MAINTENANCE_SEARCH_PARAMS.ENG_HOURS_DUE_IN,
  [MAINTENANCE_UPCOMING_TABLE_COLUMNS.DRIVER_NAME]: MAINTENANCE_SEARCH_PARAMS.DRIVER_NAME,
  [MAINTENANCE_UPCOMING_TABLE_COLUMNS.CREATED_ON]: MAINTENANCE_SEARCH_PARAMS.CREATION_TIME,
  [MAINTENANCE_UPCOMING_TABLE_COLUMNS.CREATED_BY]: MAINTENANCE_SEARCH_PARAMS.CREATED_BY,
  [MAINTENANCE_UPCOMING_TABLE_COLUMNS.MODIFIED_ON]: MAINTENANCE_SEARCH_PARAMS.LAST_UPDATE_TIME,
  [MAINTENANCE_UPCOMING_TABLE_COLUMNS.MODIFIED_BY]: MAINTENANCE_SEARCH_PARAMS.UPDATED_BY,
};

export default class MaintenanceUpcomingTable extends TableBase<IEditColumns<MAINTENANCE_UPCOMING_TABLE_COLUMNS>> {
  context: Maintenances;
  filters: MaintenanceFilters;
  download: MaintenanceUpcomingDownload;
  selectedGroup: SimpleField<Select.ISelectOption>;

  constructor(context: Maintenances) {
    super({
      columns: getInitialColumns(context.view.value),
      sortedColumn: initialSortedColumn,
      storageNames: storageNames[context.view.value],
    });

    this.context = context;
    this.filters = new MaintenanceFilters({ dueIn: this.dueInInitialValues, frequency: this.frequencyInitialValues });
    this.download = new MaintenanceUpcomingDownload(
      repositoryService
        .get('notifications')
        .entity('email')
        .entity('document')
    ).initialize(this);
    this.selectedGroup = new SimpleField({ value: 'all', label: 'All Vehicles' });
  }

  @computed get source() {
    return this.context.tasks.toArray().map(
      ({
        model: {
          name,
          asset,
          service,
          loggerInfo: { creationTime, createdBy, lastUpdateTime, updatedBy },
          id,
          validConfig,
          driverName,
        },
        representation: { dueInCell, frequencyCell },
      }) => {
        return {
          [MAINTENANCE_UPCOMING_TABLE_COLUMNS.TASK_NAME]: name.value,
          [MAINTENANCE_UPCOMING_TABLE_COLUMNS.VEHICLE_NAME]: asset.name.value || '-',
          [MAINTENANCE_UPCOMING_TABLE_COLUMNS.DUE_IN]: validConfig ? dueInCell : null,
          [MAINTENANCE_UPCOMING_TABLE_COLUMNS.FREQUENCY]: frequencyCell,
          [MAINTENANCE_UPCOMING_TABLE_COLUMNS.SERVICE_TYPE]: service.type.item.name || '-',
          [MAINTENANCE_UPCOMING_TABLE_COLUMNS.CURRENT_ODOMETER]:
            `${asset.details.odometer.value?.toLocaleString('en-US', { maximumFractionDigits: 0 })} mi` || '-',
          [MAINTENANCE_UPCOMING_TABLE_COLUMNS.CURRENT_ENGINE_HOURS]:
            `${asset.details.engHours.value?.toLocaleString('en-US', { maximumFractionDigits: 1 })} hrs` || '-',
          [MAINTENANCE_UPCOMING_TABLE_COLUMNS.DRIVER_NAME]: driverName,
          [MAINTENANCE_UPCOMING_TABLE_COLUMNS.CREATED_ON]: creationTime,
          [MAINTENANCE_UPCOMING_TABLE_COLUMNS.CREATED_BY]: createdBy || '-',
          [MAINTENANCE_UPCOMING_TABLE_COLUMNS.MODIFIED_ON]: lastUpdateTime,
          [MAINTENANCE_UPCOMING_TABLE_COLUMNS.MODIFIED_BY]: updatedBy || '-',
          key: id.value,
          cpNumber: asset.cpNumber.value,
          vehicleId: asset.id.value,
        };
      }
    );
  }

  private get dueInInitialValues() {
    return (
      this.selectedSearchColumns.find((col) => col.column === MAINTENANCE_UPCOMING_TABLE_COLUMNS.DUE_IN)?.value || ''
    );
  }

  private get frequencyInitialValues() {
    return (
      this.selectedSearchColumns.find((col) => col.column === MAINTENANCE_UPCOMING_TABLE_COLUMNS.FREQUENCY)?.value || ''
    );
  }

  selectGroup = (group: Select.ISelectOption) => {
    this.selectedGroup.set(group);
    EventsBus.get().trigger(APP_EVENTS.MAINTENANCE.UPCOMING.GROUP.CHANGED, group);
  };

  @computed get searchData() {
    return this.selectedSearchColumns.reduce((acc, cur) => {
      if (cur.column === MAINTENANCE_UPCOMING_TABLE_COLUMNS.DUE_IN) {
        return { ...acc, ...this.filters.dueIn.params };
      } else {
        return {
          ...acc,
          [maintenanceSearchMap[cur.column]]: cur.value,
        };
      }
    }, {});
  }

  @computed get sortData() {
    return {
      sortBy: maintenanceSearchMap[this.sortedColumn.field],
      order: this.sortedColumn.order?.replace('end', '').toUpperCase(),
    };
  }
}
