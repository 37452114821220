import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import moment from 'moment';
import { getFormattedTime } from 'utils';
import { ACL, DATE_TIME_FORMATS, METRICS_SWITCH_TYPE } from 'config';
import timeStore from 'stores/TimeStore';
import { validateAccessLevel } from 'stores';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

import type MaintenanceConfig from 'models/Maintenances/Models/MaintenanceConfig';
import type MaintenanceAsset from 'models/Maintenances/Models/MaintenanceAsset';
import type MaintenanceModalDueEveryUI from 'models/Maintenances/UI/MaintenanceModalUI/MaintenanceModalDueEveryUI';

import Button from 'components/Button';
import InputNumber from 'components/InputNumber';
import CalendarWithInput from 'components/CalendarWithInput';

import './styles.scss';

type MomentDuration = 'month' | 'week' | 'day' | 'year';

interface IProps {
  odometer: MaintenanceConfig;
  engHours: MaintenanceConfig;
  asset: MaintenanceAsset;
  serviceTime: MaintenanceConfig;
  ui: MaintenanceModalDueEveryUI;
  isLastPerformed: boolean;
  onEditLastPerformed: () => void;
}

const MaintenancePreferenceDueEveryLastPerformed: React.FC<IProps> = ({
  ui,
  odometer,
  engHours,
  asset,
  serviceTime,
  isLastPerformed,
  onEditLastPerformed,
}) => {
  const edit = validateAccessLevel([ACL.MAINTENANCE.BASIC.UPDATE]);

  useEffect(() => {
    EventsBus.get().on(APP_EVENTS.MAINTENANCE.TASK.ASSET.CHANGED, onChangeAsset);

    if (!odometer.last.value) {
      onChangeAsset();
    }

    return () => {
      odometer.last.reInitialize('');
      EventsBus.get().off(APP_EVENTS.MAINTENANCE.TASK.ASSET.CHANGED, onChangeAsset);
    };
  }, []);

  const onChangeAsset = () => {
    if (ui.metricsCheckbox.value && ui.metricsSwitch.value === METRICS_SWITCH_TYPE.MILES) {
      odometer.last.reInitialize(asset.details.odometer.value.toString());
    }
  };

  const editLastPerformed = () => {
    if (isLastPerformed) {
      onEditLastPerformed();
    } else {
      ui.lastPerformedEdit.toggle(true);
    }
  };

  const changeLastPerformedOdometer = (value) => {
    const odometerValue = Math.round(Number(value));

    odometer.last.set(odometerValue.toString());
    ui.isLastPerformedError.toggle(false);

    if (ui.metricsCheckbox.value && ui.metricsSwitch.value === METRICS_SWITCH_TYPE.MILES) {
      const next = odometerValue + Number(odometer.interval.value);
      odometer.next.set(next.toString());
    }
  };

  const changeLastPerformedEngHours = (value) => {
    const engHoursValue = Math.round(Number(value) * 10) / 10;

    engHours.last.set(engHoursValue.toString());
    ui.isLastPerformedError.toggle(false);

    if (ui.metricsCheckbox.value && ui.metricsSwitch.value === METRICS_SWITCH_TYPE.HOURS) {
      const next = engHoursValue + Number(engHours.interval.value);
      engHours.next.set(next.toString());
    }
  };

  const changeLastPerformedDate = (value) => {
    serviceTime.last.set(value.toString());

    if (ui.dateCheckbox.value) {
      const time = moment(value)
        .add(Number(serviceTime.interval.value), serviceTime.intervalUnit.value.toLowerCase() as MomentDuration)
        .valueOf();
      serviceTime.next.set(time.toString());
    }
  };

  return (
    <Observer
      render={() => (
        <div className="MaintenancePreferenceDueEveryLastPerformed">
          <div className="MaintenancePreferenceDueEveryLastPerformed-header">
            <span className="MaintenancePreferenceDueEveryLastPerformed-headerTitle">Last Performed</span>
            {!ui.lastPerformedEdit.value && (
              <Button
                title={isLastPerformed ? 'Edit Completed Task' : 'Edit'}
                className="Button--link"
                onClick={editLastPerformed}
                disabled={!edit}
              />
            )}
          </div>
          <div className="MaintenancePreferenceDueEveryLastPerformed-content">
            {ui.lastPerformedEdit.value ? (
              <div className="MaintenancePreferenceDueEveryLastPerformed-contentEdit">
                {ui.metricsCheckbox.value && ui.metricsSwitch.value === METRICS_SWITCH_TYPE.MILES && (
                  <InputNumber
                    className="MaintenancePreferenceDueEveryLastPerformed-contentEditMiles"
                    value={Number(odometer.last.value)?.toLocaleString('en-US', { maximumFractionDigits: 0 })}
                    onChange={changeLastPerformedOdometer}
                    min="0"
                    disabled={!edit}
                    error={ui.isLastPerformedError.value}
                    controls={false}
                  />
                )}
                {ui.metricsCheckbox.value && ui.metricsSwitch.value === METRICS_SWITCH_TYPE.HOURS && (
                  <InputNumber
                    className="MaintenancePreferenceDueEveryLastPerformed-contentEditEngHours"
                    value={Number(engHours.last.value)?.toLocaleString('en-US', { maximumFractionDigits: 1 })}
                    onChange={changeLastPerformedEngHours}
                    min="0"
                    disabled={!edit}
                    error={ui.isLastPerformedError.value}
                    controls={false}
                  />
                )}
                {ui.dateCheckbox.value && (
                  <div className="MaintenancePreferenceDueEveryLastPerformed-contentEditDate">
                    <CalendarWithInput
                      withTodayLabel
                      isInputEditable={false}
                      value={Number(serviceTime.last.value)}
                      onSubmit={changeLastPerformedDate}
                      disabled={!edit}
                      disabledRange={[
                        {
                          after: new Date(new Date().getFullYear(), 11, 31),
                          before: new Date(new Date().getFullYear() - 10, 0, 1),
                        },
                      ]}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="MaintenancePreferenceDueEveryLastPerformed-contentInfo">
                {ui.metricsCheckbox.value && ui.metricsSwitch.value === METRICS_SWITCH_TYPE.MILES && (
                  <span
                    className={classNames('MaintenancePreferenceDueEveryLastPerformed-contentInfoMiles', {
                      'MaintenancePreferenceDueEveryLastPerformed-contentInfoMiles--error':
                        ui.isLastPerformedError.value,
                    })}
                  >
                    {Number(odometer.last.value)?.toLocaleString('en-US', { maximumFractionDigits: 0 })} mi
                  </span>
                )}
                {ui.metricsCheckbox.value && ui.metricsSwitch.value === METRICS_SWITCH_TYPE.HOURS && (
                  <span
                    className={classNames('MaintenancePreferenceDueEveryLastPerformed-contentInfoEngHours', {
                      'MaintenancePreferenceDueEveryLastPerformed-contentInfoEngHours--error':
                        ui.isLastPerformedError.value,
                    })}
                  >
                    {Number(engHours.last.value)?.toLocaleString('en-US', { maximumFractionDigits: 1 })} hrs
                  </span>
                )}
                {ui.dateCheckbox.value && (
                  <span className="MaintenancePreferenceDueEveryLastPerformed-contentInfoServiceTime">
                    {getFormattedTime(
                      Number(serviceTime.last.value),
                      DATE_TIME_FORMATS.monthDatYearFull,
                      timeStore.sessionTimezone
                    )}
                  </span>
                )}
              </div>
            )}
            {ui.isLastPerformedError.value && (
              <p className="MaintenancePreferenceDueEveryLastPerformed-contentError">Value must be bigger than 0</p>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default MaintenancePreferenceDueEveryLastPerformed;
