import React, { useCallback, useMemo } from 'react';
import { Observer } from 'mobx-react';
import moment from 'moment';
import classNames from 'classnames';

import type MediaDuration from 'models/Dashcam/MediaCommon/MediaDuration';
import type RequestMediaTimeSegment from 'models/Dashcam/MediaRequestCheckout/RequestMedia/RequestMediaTimeSegment';
import MediaManagerRequestMediaSettings from '../MediaManagerRequestMediaSettings';
import Button from 'components/Button';
import Calendar from 'components/Calendar';

import './styles.scss';

type dateInString = string;

interface IProps {
  className?: string;
  firstMediaDate?: dateInString;
  selectedDay?: dateInString | null;
  onSelectDay: (date: dateInString) => void;
  daysWithMediaItems: dateInString[];
  offline: boolean;
  duration: MediaDuration;
  segment: RequestMediaTimeSegment;
  timezone: string;
  showOldestDate?: boolean;
  isShowMediaSettings?: boolean;
}

const convertToDate = (value: dateInString): Date => moment(value, 'MM/DD/YYYY').toDate();
const convertToDateInString = (value: Date, format: dateInString = 'MM/DD/YYYY'): dateInString =>
  moment(value).format(format);

const RequestMediaCalendar = ({
  className = '',
  firstMediaDate,
  onSelectDay,
  daysWithMediaItems,
  selectedDay = null,
  offline = true,
  duration,
  timezone,
  segment,
  showOldestDate = true,
  isShowMediaSettings = true,
}: IProps) => {
  const handleClick = useCallback(
    (value: Date) => {
      onSelectDay(convertToDateInString(value));
    },
    [onSelectDay]
  );

  const handleOldestClick = useCallback(() => {
    onSelectDay(firstMediaDate);
  }, [onSelectDay, firstMediaDate]);

  const disabledDays = useCallback(
    (day: Date): boolean => {
      return daysWithMediaItems && !daysWithMediaItems.includes(convertToDateInString(day));
    },
    [daysWithMediaItems]
  );

  const oldestMediaDate = useMemo(() => convertToDateInString(convertToDate(firstMediaDate), 'MMMM D, YYYY'), [
    firstMediaDate,
  ]);

  return (
    <Observer
      render={() => (
        <div className={classNames('RequestMediaCalendar', className)}>
          <div className="RequestMediaCalendar-date dimmed-hidden">
            <div className="RequestMediaCalendar-title">Date</div>
            <div
              className={classNames('RequestMediaCalendar-datePicker', {
                'RequestMediaCalendar-datePicker--online': !offline,
              })}
            >
              <Calendar
                disabledDays={offline ? [{ before: undefined, after: new Date() }] : disabledDays}
                fromMonth={firstMediaDate ? convertToDate(firstMediaDate) : new Date(2013, 1)}
                ignoreNull
                key="RequestMediaCalendar"
                month={selectedDay && convertToDate(selectedDay)}
                onClick={handleClick}
                selectedDate={selectedDay && convertToDate(selectedDay)}
                toMonth={new Date()}
                withMonthYearForm
              />
            </div>
          </div>
          <div className="RequestMediaCalendar-controls">
            {showOldestDate && (
              <>
                <div className="RequestMediaCalendar-title">Oldest available Media</div>
                <div className="RequestMediaCalendar-controlValue">
                  {firstMediaDate ? (
                    <Button
                      onClick={handleOldestClick}
                      title={oldestMediaDate}
                      className="RequestMediaCalendar-button"
                      inline
                    />
                  ) : (
                    '-'
                  )}
                </div>
              </>
            )}
            {isShowMediaSettings && offline && (
              <>
                <MediaManagerRequestMediaSettings
                  duration={duration}
                  segment={segment}
                  timezone={timezone}
                  horizontal
                />
              </>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default RequestMediaCalendar;
