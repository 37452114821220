import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import { PingingIcon, PlayArrowIcon } from 'assets';

import type DeviceMedia from 'models/Devices/DeviceMedia';
import ErrorPlayerState from 'models/Dashcam/MediaRequestCheckout/Player/ErrorPlayerState';
import PlayingPlayerState from 'models/Dashcam/MediaRequestCheckout/Player/PlayingPlayerState';
import PauseVideoPlayerState from 'models/Dashcam/MediaRequestCheckout/Player/PauseVideoPlayerState';
import MediaManagerPlayerVideo from 'components/DashCams/MediaManagerPlayer/MediaManagerPlayerVideo';
import Button from 'components/Button';
import MapVehiclePanelLiveViewHeader from './MapVehiclePanleLiveViewHeader';

import './styles.scss';

interface IProps {
  media: DeviceMedia;
}

const MapVehiclePanelLiveView: React.FC<IProps> = ({ media }) => {
  useEffect(() => {
    media.fetch();
  }, [media]);

  useEffect(() => {
    media.reset();

    return () => {
      media.player.pause();
    };
  }, [media]);

  const isError = () => media.player.state instanceof ErrorPlayerState;

  return (
    <Observer
      render={() => {
        return (
          <div className="MapVehiclePanelLiveView">
            <MapVehiclePanelLiveViewHeader
              player={media.player}
              cameraType={media.cameraType}
              timeSegment={media.timeSegment}
              dashcam={media.dashcamStatus}
            />
            <div className="MapVehiclePanelLiveView-video">
              {media.player.isLoading.value && !media.isCameraUnavailable ? (
                <div className="MediaManagerPlayer-loading">
                  <PingingIcon width={30} height={30} />
                </div>
              ) : null}
              {media.isCameraUnavailable ? (
                <div className="MapVehiclePanelLiveView-unavailable">Connection to Live Stream Unavailable</div>
              ) : isError() ? (
                <div className="MapVehiclePanelLiveView-streamError">
                  <div className="MapVehiclePanelLiveView-streamError--container">
                    <p>Connection to Camera Live Stream Failed</p>
                    <Button className="Button--link" title="Try Again" onClick={media.player.play} />
                  </div>
                </div>
              ) : (
                <div className="MapVehiclePanelLiveView-player">
                  {media.player.state instanceof PauseVideoPlayerState && (
                    <div className="MapVehiclePanelLiveView-play" onClick={media.player.play}>
                      <PlayArrowIcon className="MapVehiclePanelLiveView-play--icon" width={28} height={22} />
                      <div className="MapVehiclePanelLiveView-play--button" />
                    </div>
                  )}
                  <MediaManagerPlayerVideo
                    url={media.player.video.url.value}
                    isPlaying={media.player.state instanceof PlayingPlayerState}
                    isMuted={media.player.isMuted.value}
                    onClick={media.player.pause}
                    isShowPauseLayout={false}
                  />
                </div>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export default MapVehiclePanelLiveView;
