import React from 'react';
import Slider, { Settings } from 'react-slick';
import classNames from 'classnames';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

interface IProps {
  className?: string;
  children: React.ReactNode;
  settings: Settings;
}

const Carousel: React.FC<IProps> = ({ className, children, settings }) => {
  const cn = classNames('Carousel', className);

  return (
    <Slider className={cn} {...settings}>
      {children}
    </Slider>
  );
};

export default Carousel;
