import { computed } from 'mobx';
import type MediaLibrary from './index';
import { SimpleField } from 'models/Fields';

export default class Redirect {
  assetId: SimpleField<string>;
  timestamp: SimpleField<string>;
  context: MediaLibrary;

  constructor(context) {
    this.assetId = new SimpleField(null);
    this.timestamp = new SimpleField(null);
    this.context = context;
  }

  init = (params) => {
    if (params.assetId && params.timestamp) {
      this.assetId.set(params.assetId);
      this.timestamp.set(params.timestamp);
      this.context.source.dateRange.selectCustomDateOption(params.timestamp);
    }
  };

  reset = () => {
    this.assetId.set(null);
    this.timestamp.set(null);
  };

  @computed get params() {
    return { timestamp: this.timestamp.value };
  }
}
