import React from 'react';
import CompassDirectionLabel from 'components/Map/DirectionTextLabel';

import './styles.scss';

interface IProps {
  heading?: number;
  className?: string;
}

const MapVehiclePanelEventDirection: React.FC<IProps> = ({ heading, className }) => {
  return (
    <div className="MapVehiclePanelEventBase-container">
      <CompassDirectionLabel className={className} heading={heading} />
    </div>
  );
};

export default MapVehiclePanelEventDirection;
