import React, { FC, useState, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import classNames from 'classnames';

import Button from 'components/Button';
import Input from 'components/Input';
import StrengthProgress from 'components/StrengthProgress';
import InfoTooltip from 'components/InfoTooltip';

import { EyeCrossedIcon, EyeIcon, InfoIcon, ConfirmIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  initialValues?: any;
  onSubmit?: (password: string) => Promise<void>;
  sending?: boolean;
  submitText?: string;
}

const ConfirmPasswordFormSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required field')
    .matches(/^\S*$/, 'Password is incorrect')
    .min(8, 'Seems a bit short...')
    .max(30, 'Please try a shorter password')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/\d/, 'Password must contain at least one digit')
    .matches(/[!@#$%^&*=(),.?":{}|<>-]/, 'Password must contain at least one special character'),
  confirmPassword: Yup.string().test('passwords-match', `New passwords don't match`, function (value) {
    // @ts-ignore
    return this.parent.password === value;
  }),
});

const ConfirmPasswordForm: FC<IProps> = ({ className, initialValues, onSubmit, sending, submitText }) => {
  const cn = classNames('ConfirmPasswordForm', className);
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const password = get(initialValues, 'password', '');
  const confirmPassword = get(initialValues, 'confirmPassword', '');
  const renderIcon = () => {
    return isPasswordVisible ? (
      <EyeIcon fill="#6B7A99" cursor="pointer" onClick={() => setPasswordVisible(false)} />
    ) : (
      <EyeCrossedIcon fill="#6B7A99" cursor="pointer" onClick={() => setPasswordVisible(true)} />
    );
  };
  const handleSubmit = useCallback(
    ({ password }) => {
      onSubmit(password);
    },
    [onSubmit]
  );

  return (
    <div className={cn}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ password, confirmPassword }}
        validationSchema={ConfirmPasswordFormSchema}
        render={({ errors, touched, isValid, isSubmitting, values }) => {
          const isPasswordMatched =
            !errors.password && !errors.confirmPassword && touched.confirmPassword && values.confirmPassword;

          return (
            <Form className="ConfirmPasswordForm-form">
              <div className="ConfirmPasswordForm-formContent">
                <div className="ConfirmPasswordForm-fields">
                  <div className="ConfirmPasswordForm-field ConfirmPasswordForm-field--password">
                    <div className="ConfirmPasswordForm-tooltip">
                      <InfoTooltip>Passwords must be at least 8 characters long.</InfoTooltip>
                    </div>
                    <div className="ConfirmPasswordForm-input ConfirmPasswordForm-input--password">
                      <Field
                        component={Input}
                        error={Boolean(errors.password && touched.password) ? errors.password : ''}
                        icon={renderIcon()}
                        iconPosition="right"
                        name="password"
                        placeholder="New Password"
                        type={isPasswordVisible ? 'text' : 'password'}
                      />
                    </div>
                    <div className="ConfirmPasswordForm-statusIcon ConfirmPasswordForm-statusIcon--password">
                      {isPasswordMatched && <ConfirmIcon fill="#4CAF50" />}
                    </div>
                  </div>
                  <div className="ConfirmPasswordForm-field ConfirmPasswordForm-field--confirmPassword">
                    <div className="ConfirmPasswordForm-input ConfirmPasswordForm-input--confirmPassword">
                      <Field
                        component={Input}
                        error={Boolean(errors.confirmPassword && touched.confirmPassword) ? errors.confirmPassword : ''}
                        icon={renderIcon()}
                        iconPosition="right"
                        name="confirmPassword"
                        placeholder="Confirm New Password"
                        type={isPasswordVisible ? 'text' : 'password'}
                      />
                    </div>
                    <div className="ConfirmPasswordForm-statusIcon ConfirmPasswordForm-statusIcon--confirmPassword">
                      {isPasswordMatched && <ConfirmIcon fill="#4CAF50" />}
                      {Boolean(errors.confirmPassword && touched.confirmPassword) && <InfoIcon fill="#D92424" />}
                    </div>
                  </div>
                  <div className="ConfirmPasswordForm-passwordStrength">
                    <StrengthProgress strengthText="Password Strength" password={values.password} />
                  </div>
                </div>
              </div>

              <div className="ConfirmPasswordForm-buttons">
                <div className="ConfirmPasswordForm-submit">
                  <Button
                    className="Button--apply"
                    disabled={!isValid || isSubmitting}
                    sending={sending}
                    title={submitText}
                    type="submit"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
};

export default ConfirmPasswordForm;
