import React, { FC } from 'react';
import classNames from 'classnames';

import { Table as AntTable } from 'antd';
import type { TableProps } from 'antd/es/table';

import './styles.scss';

interface IProps extends TableProps<any> {}

const Table: FC<IProps> = ({ className, ...props }) => {
  const cn = classNames('Table', className);

  return <AntTable className={cn} {...props} />;
};

export default Table;
