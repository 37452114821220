import { observable, action } from 'mobx';

export default class MediaVehicle {
  @observable assetId: string;
  @observable cpNumber: string;
  @observable displayName: string;

  constructor({ id, cpNumber, displayName } = { id: '', cpNumber: '', displayName: '' }) {
    this.assetId = id.toString();
    this.cpNumber = cpNumber;
    this.displayName = displayName;
  }

  @action set = (vehicle: MediaVehicle) => {
    this.assetId = vehicle.assetId;
    this.cpNumber = vehicle.cpNumber;
    this.displayName = vehicle.displayName;
  };

  @action reset = () => {
    this.assetId = null;
    this.cpNumber = '';
    this.displayName = '';
  };
}
