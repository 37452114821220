import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Drawer } from 'antd';

import TimelineDetailsTitle from './Title';

import './styles.scss';

interface IProps {
  children?: ReactNode;
  className?: string;
  height?: number;
  maxHeight?: number;
  minHeight?: number;
  onResize?: (size: number) => void;
  visible?: boolean;
}

const TimelineDetailsDrawer: FC<IProps> = ({
  children,
  className,
  height,
  maxHeight,
  minHeight,
  onResize,
  visible,
}) => {
  const cn = classNames('TimelineDetailsDrawer', className);

  return (
    <Drawer
      destroyOnClose
      bodyStyle={{ padding: 0 }}
      className={cn}
      closable={false}
      forceRender
      height={height}
      mask={false}
      placement="bottom"
      style={{ left: 410, position: 'absolute', width: 'calc(100vw - 410px)' }}
      title={
        visible && (
          <TimelineDetailsTitle height={height} maxHeight={maxHeight} minHeight={minHeight} onResize={onResize} />
        )
      }
      visible={visible}
      zIndex={20}
    >
      <div className="TimelineDetailsDrawer-content">{children}</div>
    </Drawer>
  );
};

export default TimelineDetailsDrawer;
