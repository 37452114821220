import React from 'react';
import type { IWEXCard } from 'interfaces/stores/Integrations/IWEXCardAssociations';
import WEXFuelCardVehicleAdmin from '../WEXFuelCardVehicleAdmin';

interface IProps {
  items: IWEXCard[];
}

const WEXFuelCardsVehicleAdmin: React.FC<IProps> = ({ items }) => {
  return (
    <div className="WEXFuelCardsVehicleAdmin">
      <ul className="WEXFuelCardsVehicleAdmin-list">
        {items.map((item) => (
          <WEXFuelCardVehicleAdmin key={item.id} fuelCard={item} />
        ))}
      </ul>
    </div>
  );
};

export default WEXFuelCardsVehicleAdmin;
