import React, { FC } from 'react';
import classNames from 'classnames';

import type RouteReplayState from 'models/Timeline/RouteReplayState';
import type RouteReplaySpeed from 'models/Timeline/RouteReplaySpeed';
import PlayButton from '../PlayButton';
import SpeedButton from '../SpeedButton';

import './styles.scss';

interface IProps {
  className?: string;
  disabled?: boolean;
  onPlay: (play: boolean) => void;
  speed: RouteReplaySpeed;
  state: RouteReplayState;
}

const RouteReplay: FC<IProps> = ({ className, disabled, onPlay, speed, state }) => {
  const cn = classNames('RouteReplay', className, {
    'RouteReplay--disabled': disabled,
  });

  return (
    <div className={cn}>
      <div className="RouteReplay-button RouteReplay-button--play">
        <PlayButton state={state} onPlay={onPlay} />
      </div>
      <div className="RouteReplay-separator" />
      <div className="RouteReplay-button RouteReplay-button--speed">
        <SpeedButton speed={speed} />
      </div>
    </div>
  );
};

export default RouteReplay;
