import React from 'react';
import { observer } from 'mobx-react';

import type { SimpleField } from 'models/Fields';

import Note from 'components/Note';

import './styles.scss';

interface IProps {
  selector: SimpleField<string>;
}

const AlertPreferenceRuleSelector: React.FC<IProps> = ({ selector }) => {
  const handleChange = (value) => {
    selector.set(value);
  };

  return (
    <div className="AlertPreference-line">
      <div className="AlertPreference-label">Rule selector</div>
      <div className="AlertPreference-value">
        <Note
          isEditing
          hideControls
          note={selector.value}
          onChange={handleChange}
          placeholder=""
          className="AlertPreference-note"
          countCharactersEntered
        />
      </div>
    </div>
  );
};

export default observer(AlertPreferenceRuleSelector);
