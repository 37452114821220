import React, { useState } from 'react';
import Map from 'containers/Map/Map';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { UnlockMapArrowIcon } from 'assets';

import './styles.scss';

const MOUSE_OFFSET = 10;
const OVERLAY_VISIBLE_TIME = 1500;

interface IProps {
  locked: boolean;
}

const MapWrapper: React.FC<IProps> = ({ locked = false }) => {
  const [isShowOverlay, setShowOverlay] = useState(false);
  const [titleOverlay, setTitleOverlay] = useState('Unlock to Move Map');
  let mousePosition = {
    x: null,
    y: null,
  };

  const handleMapCustomerEvents = ({ isEventTakePlace, title }) => {
    if (locked && isEventTakePlace) {
      setTitleOverlay(title);
      setShowOverlay(true);

      setTimeout(() => {
        setShowOverlay(false);
      }, OVERLAY_VISIBLE_TIME);
    }
  };

  const handleZoom = () => {
    handleMapCustomerEvents({
      isEventTakePlace: true,
      title: 'Unlock to Zoom Map',
    });
  };

  const handleKeyUp = (e) => {
    handleMapCustomerEvents({
      isEventTakePlace: e.key === '-' || e.key === '=' || e.key === '+',
      title: 'Unlock to Zoom Map',
    });
  };

  const handleMouseUp = (e) => {
    handleMapCustomerEvents({
      isEventTakePlace:
        Math.abs(e.screenX - mousePosition.x) > MOUSE_OFFSET || Math.abs(e.screenY - mousePosition.y) > MOUSE_OFFSET,
      title: 'Unlock to Move Map',
    });
  };

  const handleMouseDown = (e) => {
    mousePosition = { x: e.screenX, y: e.screenY };
  };

  return (
    <div
      className="MapWrapper"
      onWheel={handleZoom}
      onKeyUp={handleKeyUp}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onDoubleClick={handleZoom}
    >
      <Map />
      <TransitionGroup component={null}>
        {isShowOverlay && (
          <CSSTransition timeout={222} classNames="MapWrapper-dialog">
            <div className="MapWrapper-lockOverlay">
              <div className="MapWrapper-iconArrow">
                <UnlockMapArrowIcon />
              </div>
              <div className="MapWrapper-overlayContent">
                <p>{titleOverlay}</p>
              </div>
              <div className="MapWrapper-overlayBackground" />
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default MapWrapper;
