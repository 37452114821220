import { ToggleField } from 'models/Fields';
import type { CELL_ICONS } from 'models/Admin/People/Tables';

export default class TableToggleFilter {
  isActive: ToggleField;
  value: string;
  text: string;
  param: string;
  icon: CELL_ICONS;

  constructor({
    isActive,
    value,
    text,
    param,
    icon,
  }: {
    isActive: boolean;
    value: string;
    text: string;
    param?: string;
    icon?: CELL_ICONS;
  }) {
    this.isActive = new ToggleField(isActive);
    this.value = value;
    this.text = text;
    this.param = param;
    this.icon = icon;
  }
}
