import React from 'react';

import './styles.scss';

interface IProps {
  appliesTo: string;
}

const ReportTableScheduledAppliesTo: React.FC<IProps> = ({ appliesTo }) => {
  return <div className="ReportTableScheduledAppliesTo">{appliesTo}</div>;
};

export default ReportTableScheduledAppliesTo;
