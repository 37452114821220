import { APP_VERSION, isDevelop, isProduction, isStaging } from './constants';

const FEATURES = {
  alerts: isDevelop || APP_VERSION >= '3.3.0',
  dashcamsAdmin: isDevelop || APP_VERSION >= '3.2.0',
  driversAdmin: isDevelop || APP_VERSION >= '3.2.0',
  usersAdmin: isDevelop || APP_VERSION >= '3.3.0',
  downloadEmailReport: isDevelop || APP_VERSION >= '3.4.0',
  accountAdmin: isDevelop,
  admin: isDevelop || isStaging || isProduction,
  changePassword: isDevelop || isStaging || isProduction,
  clearShare: isDevelop,
  dashcams: isDevelop || isStaging || isProduction,
  devicesAdmin: isDevelop,
  dispatch: false,
  geozonesAdmin: isDevelop || isStaging || isProduction,
  groupsAdmin: isDevelop || isStaging || isProduction,
  reports: isDevelop || isStaging || isProduction,
  serviceInfo: isDevelop || isStaging,
  vehiclesAdmin: isDevelop || isStaging || isProduction,
  reportMapLinks: isDevelop || APP_VERSION >= '3.5.0',
  customers: isDevelop,
  nextVersion: true,
};

export { FEATURES };
