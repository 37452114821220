import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import validateAccessLevel from 'stores/acl/validator';
import { WEB_DASHCAM_BASIC_READ, WEB_MAP_CLEARSHARE_BASIC_READ } from 'config';
import { CameraWithFlashIcon, ClearshareIcon } from 'assets';
import InfoTooltip from 'components/InfoTooltip';

import './styles.scss';

interface IProps {
  mediaEnabled?: boolean;
  hasActiveClearShareLink?: boolean;
  isShowCamera?: boolean;
  mediaLink?: string;
}

const VehicleItemIcons: FC<IProps> = ({ mediaEnabled, hasActiveClearShareLink, isShowCamera, mediaLink = null }) => {
  return (
    <div className="VehicleItemIcons-icons">
      {validateAccessLevel([WEB_DASHCAM_BASIC_READ]) && mediaEnabled && isShowCamera && (
        <div className="VehicleItemIcons-mediaIcon VehicleItemIcons-mediaIcon--media">
          {mediaLink ? (
            <InfoTooltip
              icon={
                <Link to={mediaLink}>
                  <CameraWithFlashIcon height="14px" width="14px" />
                </Link>
              }
              placement="top"
            >
              Go to Dashcam Media
            </InfoTooltip>
          ) : (
            <CameraWithFlashIcon height="14px" width="14px" />
          )}
        </div>
      )}
      {validateAccessLevel([WEB_MAP_CLEARSHARE_BASIC_READ]) && hasActiveClearShareLink && (
        <div className="VehicleItemIcons-mediaIcon VehicleItemIcons-mediaIcon--clearshare">
          <ClearshareIcon height="14px" width="14px" />
        </div>
      )}
    </div>
  );
};

export default VehicleItemIcons;
