import React from 'react';
import { observer } from 'mobx-react';

import { LoaderIcon } from 'assets';

import type { IAppFileList } from 'models/Files/IAppFileList';

import FileManager from 'components/FileManager';

import './styles.scss';

interface IProps {
  documents: IAppFileList;
}

const MaintenancePreferenceAttachments: React.FC<IProps> = ({ documents }) => {
  return (
    <div className="MaintenancePreference-line MaintenancePreferenceAttachments">
      <div className="MaintenancePreference-label MaintenancePreference-label--top MaintenancePreferenceAttachments-label">
        Attachments
      </div>
      <div className="MaintenancePreference-value">
        {documents.repositoryDocuments.getState.loading ? (
          <div className="MaintenancePreference-loading">
            <LoaderIcon className="MaintenancePreference-loadingIcon" />
          </div>
        ) : (
          <div className="MaintenancePreferenceAttachments-fileManager">
            <FileManager
              id="maintenance-task"
              files={documents.list}
              multiple
              onAdd={(file) => {
                documents.add(file);
              }}
              onDownload={() => true}
              onPreview={() => true}
              onRemove={(file) => {
                documents.remove(file);
              }}
              onUpload={() => true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(MaintenancePreferenceAttachments);
