import React  from 'react';
import { Link } from 'react-router-dom';

import { PATHS } from 'config';

import { getFormattedTime } from 'utils';


interface IProps {
  id: number;
  date: number;
  total: number;
  customer: string;
  limited?: boolean;
}

const SecondaryPanelJobInvoice: React.FC<IProps> = ({ id, date, total, customer, limited}) => {

  const link = `${PATHS.MAP.CUSTOMER_INVOICE.replace(':customerId', customer).replace(':invoiceId', String(id))}`

  return <>
    <div><Link to={link}>{id}</Link></div>
    <div>{date ? getFormattedTime(date, 'MM/DD/YYYY'): '-'}</div>
    {!limited && <div>${total}</div>}
  </>;
};

export default SecondaryPanelJobInvoice;
