import React, { useCallback, FC, SVGProps } from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  disabled?: boolean;
  checkedValue?: string;
  className?: string;
  onChange: (value: any) => void;
  tabs?: boolean;
  radios: Array<{
    id: string;
    label: string;
    name: string;
    value: string;
    Icon?: FC<SVGProps<SVGSVGElement>>;
  }>;
  showRadios?: boolean;
  size?: 'xsmall' | 'small' | 'medium' | 'large';
}

const RadioGroup: React.FC<IProps> = ({
  checkedValue,
  className,
  disabled = false,
  tabs,
  radios,
  onChange,
  showRadios,
  size,
}) => {
  const cn = classNames('RadioGroup', className, {
    'RadioGroup--tabs': Boolean(tabs),
    'RadioGroup--showRadios': Boolean(showRadios),
    [`RadioGroup--${size}`]: Boolean(size),
    'RadioGroup--disabled': disabled,
  });
  const handleChange = useCallback((e) => !disabled && onChange(e.target.value), [disabled, onChange]);

  return (
    <div className={cn}>
      {radios.map(({ id, label, name, value, Icon }) => (
        <div className={`RadioGroup-item RadioGroup-item--${id}`} key={id}>
          <input
            disabled={disabled}
            checked={checkedValue === value}
            className="RadioGroup-radio"
            id={`${name}-${id}`}
            name={name}
            onChange={handleChange}
            type="radio"
            value={value}
          />
          <label className="RadioGroup-label" htmlFor={`${name}-${id}`}>
            {Icon && <Icon className="RadioGroup-labelIcon" />}
            <span className="RadioGroup-labelText">{label}</span>
          </label>
        </div>
      ))}
    </div>
  );
};

RadioGroup.defaultProps = {
  showRadios: false,
};

export default RadioGroup;
