import APIStoreBase from '../../APIStoreBase';
import { action, computed, observable, reaction } from 'mobx';
import { repositoryService } from 'services';
import { filter, find, sortBy } from 'lodash';
import MediaEventModel from 'models/Media';
import { DATE_TIME_FORMATS, MEDIA_EVENTS_STATUS_TEXTS, PIXEL_TO_SECOND } from 'config';
import { getFormattedTime, getTimeInTimeZone, getTimeRange } from 'utils';
import timeStore from 'stores/TimeStore';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

interface IMarkerPosition {
  lat: number;
  lng: number;
}

interface ISelectedMediaData {
  displayName: string;
  outsideMedia: string;
  insideMedia: string;
  markerPosition: IMarkerPosition;
  route: IMarkerPosition[];
  address: string;
  eventName: string;
  date: string;
  time: string;
}

export default class MediaRequestStore {
  constructor() {
    this.repositoryDevices = repositoryService.get('devices');
    this.repositoryMedia = repositoryService.get('media', 'v2.0');
    this.repositoryMediaList = this.repositoryMedia.entity('list');
    this.repositoryMediaRequest = this.repositoryMedia.entity('request');
    this.repositoryMediaCameras = this.repositoryMedia.entity('cameras');
    this.repositoryMediaPing = this.repositoryMedia.entity('ping');
    this.repositoryDevices.listenTo('error:get', () => {
      this.devicesList = [];
    });

    reaction(
      () => this.listOfMediaRequestParams,
      () => {
        if (this.selectedDeviceId) {
          this.getListOfMedia();
        }
      },
      {
        name: 'Update media list within the timeline on change timeline range start/end',
      }
    );

    reaction(
      () => this.selectedMediaTimestamp,
      () => {
        if (this.selectedMediaTimestamp) {
          const event = find(this.timelineMediaItemsList, ['eventTimestamp', this.selectedMediaTimestamp]);

          this.setSelectedMediaData(event);
        }
      },
      { name: 'Update media data on select a media item within the timeline' }
    );

    reaction(
      () => this.mediaRequestTimestamp,
      (newTimeStamp) => {
        // Convert ms to seconds in order ms gap does not change range on drag
        const timestamp = Math.floor(newTimeStamp / 1000);
        const startTime = Math.floor(this.currentRangeStart / 1000);
        const endTime = Math.floor(this.currentRangeEnd / 1000);

        // update only when a timestamp is out of timeline range
        if (timestamp < startTime || timestamp > endTime) {
          this.setCurrentRangeStart(
            getTimeInTimeZone(newTimeStamp, timeStore.sessionTimezone)
              .subtract(2, 'minutes')
              .valueOf()
          );
        }
      },
      { name: 'Update timeline range start/end on media request timestamp change' }
    );

    reaction(
      () => this.currentRangeStart,
      () => {
        this.resetMediaData();
        this.setMediaRequestTimestamp(
          getTimeInTimeZone(this.currentRangeStart, timeStore.sessionTimezone)
            .add(2, 'minutes')
            .valueOf()
        );
      },
      {
        name: 'Update media request timestamp on change timeline range start/end',
      }
    );

    reaction(
      () => this.selectedDeviceId,
      () => {
        if (this.selectedDeviceId) {
          this.pingCamera();
        }
      },
      { name: 'Ping camera on change device' }
    );
  }

  @observable selectedDeviceId: string = '';
  @observable devicesList: Device.IServerDevice[] = [];
  @observable query: string = '';
  @observable mediaRequestOptions: Media.IMediaRequestOptions = {
    outsideCam: false,
    insideCam: false,
    outsideType: 'video',
    insideType: 'video',
  };
  @observable cameraPingStatus: string = undefined;
  @observable currentRangeStart: number = getTimeInTimeZone(new Date().getTime(), timeStore.sessionTimezone)
    .subtract(2, 'minutes')
    .valueOf();
  @observable mediaRequestTimestamp: number = new Date().getTime();
  @observable timelineMediaItemsList: MediaEventModel[] = [];
  @observable selectedMediaTimestamp: number = null;
  @observable selectedMediaData: ISelectedMediaData = null;
  @observable requestStatus: string = '';
  @observable cameraPingRequestStatus: APIStoreBase = new APIStoreBase();
  @observable getLstOfMediaRequestStatus: APIStoreBase = new APIStoreBase();
  @observable sendMediaRequestStatus: APIStoreBase = new APIStoreBase();
  repositoryDevices: IRepository;
  repositoryMedia: IRepository;
  repositoryMediaList: IEntityRepository;
  repositoryMediaRequest: IEntityRepository;
  repositoryMediaPing: IEntityRepository;
  repositoryMediaCameras: IEntityRepository;

  @computed get currentPositionMediaSelector() {
    return ((this.mediaRequestTimestamp - this.currentRangeStart) / 1000) * PIXEL_TO_SECOND;
  }

  @computed get filteredDevicesList() {
    const reducedDevicesList = this.devicesList.map(({ deviceId, displayName }) => ({ deviceId, displayName }));
    const sortedDeviceList = sortBy(reducedDevicesList, ({ displayName }) => displayName.toLowerCase());

    return filter(sortedDeviceList, ({ displayName }) => displayName.toLowerCase().includes(this.query.toLowerCase()));
  }

  @computed get selectedTimeRangeLabel() {
    return getTimeRange(
      this.mediaRequestTimestamp,
      20,
      'seconds',
      DATE_TIME_FORMATS.hoursMinutesSecondsAm,
      timeStore.sessionTimezone
    );
  }

  @computed get currentRangeEnd() {
    return getTimeInTimeZone(this.currentRangeStart, timeStore.sessionTimezone)
      .add(4, 'minutes')
      .valueOf();
  }

  @computed get currentRangeStartTime() {
    return getFormattedTime(this.currentRangeStart, DATE_TIME_FORMATS.hoursMinutesAm, timeStore.sessionTimezone);
  }

  @computed get currentRangeEndTime() {
    return getFormattedTime(this.currentRangeEnd, DATE_TIME_FORMATS.hoursMinutesAm, timeStore.sessionTimezone);
  }

  @computed get appliedDate() {
    return getFormattedTime(
      this.mediaRequestTimestamp,
      DATE_TIME_FORMATS.fullMonthComaDateYear,
      timeStore.sessionTimezone
    );
  }

  @computed get appliedDateTimeResult() {
    return getFormattedTime(
      this.mediaRequestTimestamp,
      DATE_TIME_FORMATS.fullMonthComaDateYearHoursMinutesSecondsSpaceAM,
      timeStore.sessionTimezone
    );
  }

  @computed get mediaRequestTime() {
    return getFormattedTime(
      this.mediaRequestTimestamp,
      DATE_TIME_FORMATS.fullHoursMinutesSecondsAm,
      timeStore.sessionTimezone
    );
  }

  @computed get mediaRequestDate() {
    return getFormattedTime(
      this.mediaRequestTimestamp,
      DATE_TIME_FORMATS.fullMonthComaDateYear,
      timeStore.sessionTimezone
    );
  }

  @computed get listOfMediaRequestParams() {
    return {
      from: this.currentRangeStart,
      to: this.currentRangeEnd,
    };
  }

  @computed get mediaVideosItemsList(): MediaEventModel[] {
    return this.timelineMediaItemsList.filter((event) => event.outsideCameraVideo || event.insideCameraVideo);
  }
  @computed get mediaImagesItemsList(): MediaEventModel[] {
    return this.timelineMediaItemsList.filter((event) => event.outsideCameraImage || event.insideCameraImage);
  }

  @action handleDrag = (_, ui) => {
    const currentTimestamp = getTimeInTimeZone(this.currentRangeStart, timeStore.sessionTimezone)
      .add((this.currentPositionMediaSelector + ui.deltaX) / PIXEL_TO_SECOND, 'seconds')
      .valueOf();

    this.setMediaRequestTimestamp(currentTimestamp);
  };

  @action getAllDevices = async () => {
    this.devicesList = await this.repositoryDevices.get({ mediaEnabled: true });
  };

  @action setQuery = (query: string) => (this.query = query);

  @action setSelectedDeviceId = (selectedDeviceId: string) => (this.selectedDeviceId = selectedDeviceId);

  @action pingCamera = async () => {
    this.cameraPingRequestStatus.reset().setLoading(true);

    try {
      const pingResponse: { status: string } = await this.repositoryMediaPing.entity(this.selectedDeviceId).get();

      this.cameraPingStatus = pingResponse.status;
      this.cameraPingRequestStatus.setSuccess(true);
    } catch (err) {
      this.cameraPingRequestStatus.setError(err);
      this.cameraPingRequestStatus.setSuccess(false);
      this.cameraPingStatus = undefined;
    }

    this.cameraPingRequestStatus.setLoading(false);
  };

  @action getListOfMedia = async () => {
    this.getLstOfMediaRequestStatus.reset().setLoading(true);

    try {
      const mediaManagerData = await this.repositoryMediaList
        .entity(this.selectedDeviceId)
        .get({ ...this.listOfMediaRequestParams, page: '0' });

      this.timelineMediaItemsList = mediaManagerData.pageData
        .filter((event: Media.IServerMediaEvent) => event.statusCodeText === MEDIA_EVENTS_STATUS_TEXTS.available)
        .map((availableEvent) => new MediaEventModel(availableEvent));

      this.getLstOfMediaRequestStatus.setSuccess(true);
    } catch (err) {
      this.timelineMediaItemsList = [];
      this.repositoryDevices.getState.setError(err);
      this.getLstOfMediaRequestStatus.setSuccess(false);
    }

    this.getLstOfMediaRequestStatus.setLoading(false);
  };

  @action setCurrentRangeStart = (timestamp: number) => (this.currentRangeStart = timestamp);

  @action setMediaRequestOptions = (mediaRequestOptions: Media.IMediaRequestOptions) =>
    (this.mediaRequestOptions = mediaRequestOptions);

  @action setMediaRequestTimestamp = (timestamp: number) => (this.mediaRequestTimestamp = timestamp);

  @action sendMediaRequest = async (cameraId?: number, timestamp?: number, cameraTypes?: string[]) => {
    this.sendMediaRequestStatus.reset().setLoading(true);
    this.repositoryMediaRequest = this.repositoryMediaCameras.entity(cameraId?.toString()).entity('request-media');

    try {
      await this.repositoryMediaRequest.create({ cameraTypes, timestamp });

      this.sendMediaRequestStatus.setSuccess(true);
    } catch (err) {
      this.sendMediaRequestStatus.setError(err);
      this.requestStatus = '';
    }

    this.sendMediaRequestStatus.setLoading(false);
  };

  @action setSelectedMediaTimestamp = (timestamp: number) => (this.selectedMediaTimestamp = timestamp);

  @action resetMediaData = () => {
    this.selectedMediaTimestamp = null;
    this.selectedMediaData = null;
    this.timelineMediaItemsList = [];
  };

  @action setSelectedMediaData = ({
    displayName,
    address,
    eventTimestamp,
    outsideCameraImage,
    insideCameraImage,
    location,
    route,
    eventName,
  }: MediaEventModel) => {
    this.selectedMediaData = {
      outsideMedia: outsideCameraImage,
      insideMedia: insideCameraImage,
      markerPosition: location,
      route,
      displayName,
      address,
      date: getFormattedTime(eventTimestamp, DATE_TIME_FORMATS.maintSchedule, timeStore.sessionTimezone),
      time: getFormattedTime(eventTimestamp, DATE_TIME_FORMATS.hoursMinutesSecondsAm, timeStore.sessionTimezone),
      eventName,
    };
  };

  @action resetMediaRequestStore = () => {
    this.resetMediaData();
    this.selectedDeviceId = '';
    this.devicesList = [];
    this.query = '';
    this.mediaRequestOptions = {
      outsideCam: false,
      insideCam: false,
      outsideType: 'video',
      insideType: 'video',
    };
    this.cameraPingStatus = undefined;
    this.currentRangeStart = getTimeInTimeZone(new Date().getTime(), timeStore.sessionTimezone)
      .subtract(2, 'minutes')
      .valueOf();
    this.mediaRequestTimestamp = new Date().getTime();
    this.selectedMediaTimestamp = null;
    this.selectedMediaData = null;
    this.requestStatus = '';
    this.repositoryDevices.getState.reset();
    this.cameraPingRequestStatus.reset();
    this.getLstOfMediaRequestStatus.reset();
    this.sendMediaRequestStatus.reset();

    return this;
  };
}
