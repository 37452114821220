import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { repositoryService } from 'services';
import { DEFAULT_GEOZONE_RADIUS } from 'config';
import { getPolygonFromPoint, latLngToServerCoordinates } from 'utils';
import type { GeozonesAdmin } from 'stores/Admin/Geozones';
import type { RouterStore } from 'stores/RouterStore';

import CreateGeozoneModal from 'components/CreateGeozoneModal';
import type Repository from 'services/RepositoryService/Repository';

interface IProps {
  className?: string;
  geozonesAdmin?: GeozonesAdmin;
  routerStore?: RouterStore;
  onSubmit?: () => void;
}

interface IState {
  isOpen: boolean;
}

@inject(({ adminStore: { geozonesAdmin }, routerStore }) => ({
  geozonesAdmin,
  routerStore,
}))
@observer
class EditGeozone extends Component<IProps, IState> {
  repositoryGeozone: Repository;

  constructor(props) {
    super(props);

    this.state = {
      isOpen: this.isOpen,
    };

    this.repositoryGeozone = repositoryService.get('geozones');
  }

  get selectedGeozone() {
    const {
      geozonesAdmin: { selectedGeozone },
    } = this.props;

    return selectedGeozone;
  }

  get isOpen() {
    const minLength = this.selectedGeozone?.type === 'circle' ? 1 : 3;

    return Object.values(this.selectedGeozone?.coordinates).length < minLength;
  }

  get geozoneId() {
    return this.selectedGeozone?.id;
  }

  get geozoneInitialData() {
    return {
      geozoneColor: this.selectedGeozone?.color,
      geozoneId: this.selectedGeozone?.id,
      geozoneLocation: {
        address: this.selectedGeozone?.dispatchMarkerAddress,
        latitude: this.selectedGeozone?.dispatchMarkerLatitude,
        longitude: this.selectedGeozone?.dispatchMarkerLongitude,
      },
      geozoneName: this.selectedGeozone?.name,
      geozoneType: this.selectedGeozone?.type || 'polygon',
    };
  }

  getServerGeozone = (geozoneForm) => {
    const coordinates =
      geozoneForm.type === 'circle'
        ? [
            {
              index: 0,
              latitude: geozoneForm.location.latitude,
              longitude: geozoneForm.location.longitude,
            },
          ]
        : latLngToServerCoordinates(
            getPolygonFromPoint(
              { lat: geozoneForm.location.latitude, lng: geozoneForm.location.longitude },
              DEFAULT_GEOZONE_RADIUS
            )
          );

    return [
      { key: 'name', value: geozoneForm.name },
      { key: 'type', value: geozoneForm.type.toUpperCase() },
      { key: 'colorId', value: geozoneForm.color.id },
      { key: 'radius', value: geozoneForm.type === 'circle' ? DEFAULT_GEOZONE_RADIUS : 0 },
      { key: 'dispatchMarkerAddress', value: geozoneForm.location.address },
      { key: 'dispatchMarkerLatitude', value: geozoneForm.location.latitude },
      { key: 'dispatchMarkerLongitude', value: geozoneForm.location.longitude },
      { key: 'coordinates', value: coordinates },
    ];
  };

  getData = (geozoneForm) => {
    const fields = this.getServerGeozone(geozoneForm);

    return {
      id: this.geozoneId,
      ...{
        ...fields.reduce((prev, next) => {
          return {
            ...prev,
            [next.key]: next.value,
          };
        }, {}),
      },
    };
  };

  handleSubmit = async (geozoneForm) => {
    try {
      await this.repositoryGeozone.patch(this.getData(geozoneForm));

      this.setState({ isOpen: false });
      this.props.onSubmit();
    } catch (e) {
      return e;
    }
  };

  render() {
    const {
      geozonesAdmin: { selectedGeozone },
    } = this.props;
    const { isOpen } = this.state;

    return (
      <CreateGeozoneModal
        {...this.geozoneInitialData}
        isDisabled={selectedGeozone?.repositoryGeozone.patchState.loading}
        isOpen={isOpen}
        onCancel={this.props.routerStore.goBack}
        onSubmit={this.handleSubmit}
        modal="edit"
        locationTouched
      />
    );
  }
}

export default EditGeozone;
