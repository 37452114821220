import { observable } from 'mobx';
import { ToggleField } from 'models/Fields';

class RequestMediaType {
  isActive: ToggleField;
  @observable selectedType: 'image' | 'video';

  constructor() {
    this.isActive = new ToggleField(true);
    this.selectedType = 'video';
  }
}

export default RequestMediaType;
