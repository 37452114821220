import React from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  title: string;
  icon: string;
  isSelected: boolean;
  onClick: () => void;
  className?: string;
}

const MapTypeButton: React.FC<IProps> = ({ title, icon, isSelected, onClick, className }) => {
  const cn = classNames('MapTypeButton', className, { isSelected });

  return (
    <div className={cn} onClick={onClick}>
      <div className="MapTypeButton-container">
        <div className="MapTypeButton-icon">
          <img src={icon} alt={title} />
        </div>
        <div className="MapTypeButton-title">{title}</div>
      </div>
    </div>
  );
};

export default MapTypeButton;
