import React, { memo, useCallback } from 'react';

import MarkersList from '../MarkersList';
import type { mapLabelSizeType, IDeviceMarker } from 'stores/Map/MapStore';
import DeviceMarker from '../DeviceMarker';

interface IProps {
  clusteringSize: number;
  deviceMarkerSize: mapLabelSizeType;
  isClustered: boolean;
  isVisible: boolean;
  mapHeading?: number;
  markers: IDeviceMarker[];
  onClick: (arg: string) => void;
  showDirectionalArrows?: boolean;
}

const DevicesMarkersList: React.FC<IProps> = ({
  showDirectionalArrows,
  clusteringSize,
  deviceMarkerSize,
  isClustered,
  isVisible,
  mapHeading,
  markers,
  onClick,
}) => {
  const handleClick = useCallback((deviceId) => {
    onClick(deviceId);
  }, []);

  return (
    <MarkersList clusteringSize={clusteringSize} isClustered={isClustered} isVisible={isVisible}>
      {markers.map(({ deviceId, event, shortName }) => (
        <DeviceMarker
          key={deviceId}
          size={deviceMarkerSize}
          statusColor={event.metadata.attributes.iconColor}
          name={shortName}
          position={{ lat: Number(event.latitude), lng: Number(event.longitude) }}
          onClick={handleClick}
          deviceId={deviceId}
          arrowColor={event.metadata.attributes.arrowColor}
          heading={showDirectionalArrows && event.metadata.attributes.heading && event.heading - mapHeading}
        />
      ))}
    </MarkersList>
  );
};

export default memo(DevicesMarkersList);
