import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { REPORTS_STORE } from 'config';

import type { ReportsStore } from 'stores';

import ReportTableByGroupRowVehicle from '../../ReportTableByGroupRow/ReportTableByGroupRowVehicle';

interface IProps {
  reportsStore?: ReportsStore;
}

@inject(REPORTS_STORE)
@observer
class ReportGroupedTableStickyItem extends Component<IProps> {
  render() {
    const {
      reportsStore: {
        reportTableGroup: { vehicleRowIndexes, currentTopIndex },
      },
    } = this.props;

    const index = vehicleRowIndexes.find((value) => value <= currentTopIndex);

    return <ReportTableByGroupRowVehicle style={{}} index={index} />;
  }
}

export default ReportGroupedTableStickyItem;
