import { SimpleField } from 'models/Fields';

class MaintenanceAlertSpecificTaskConfigModel {
  type: SimpleField<string>;
  value: SimpleField<number>;

  constructor({ type, value } = { type: '', value: null }) {
    this.type = new SimpleField(type);
    this.value = new SimpleField(value);
  }
}

export default MaintenanceAlertSpecificTaskConfigModel;
