import { observable, computed } from 'mobx';

import { SimpleField } from 'models/Fields';

const MILLISECONDS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const DAYS_IN_WEEK = 7;

class AlertIntervalModel {
  @observable weeks: SimpleField<number>;
  @observable days: SimpleField<number>;
  @observable hours: SimpleField<number>;
  @observable minutes: SimpleField<number>;
  @observable seconds: SimpleField<number>;
  initialValue: number;

  constructor(interval = 0, fields) {
    const intervalConverted = this.disassembleInterval(interval, fields);

    this.initialValue = interval;
    this.weeks = new SimpleField(intervalConverted.weeks);
    this.days = new SimpleField(intervalConverted.days);
    this.hours = new SimpleField(intervalConverted.hours);
    this.minutes = new SimpleField(intervalConverted.minutes);
    this.seconds = new SimpleField(intervalConverted.seconds);
  }

  disassembleInterval = (interval, fields) => {
    const intervalInSeconds = interval / MILLISECONDS_IN_SECOND;
    const secondsInHour = SECONDS_IN_MINUTE * MINUTES_IN_HOUR;
    const secondsInDay = secondsInHour * HOURS_IN_DAY;
    const secondsInWeek = secondsInDay * DAYS_IN_WEEK;

    let weeks = Math.trunc(intervalInSeconds / secondsInWeek);
    const weeksLeft = intervalInSeconds % secondsInWeek;

    let days = Math.trunc(weeksLeft / secondsInDay);
    const daysLeft = weeksLeft % secondsInDay;

    let hours = Math.trunc(daysLeft / secondsInHour);
    const hoursLeft = daysLeft % secondsInHour;

    let minutes = Math.trunc(hoursLeft / SECONDS_IN_MINUTE);
    let seconds = hoursLeft % SECONDS_IN_MINUTE;

    if (!fields.includes('weeks')) {
      days += weeks * DAYS_IN_WEEK;
      weeks = 0;
    }

    if (!fields.includes('days')) {
      hours += days * HOURS_IN_DAY;
      days = 0;
    }

    if (!fields.includes('hours')) {
      minutes += hours * MINUTES_IN_HOUR;
      hours = 0;
    }

    if (!fields.includes('minutes')) {
      seconds += minutes * SECONDS_IN_MINUTE;
      minutes = 0;
    }

    if (!fields.includes('seconds')) {
      seconds = 0;
    }

    return {
      seconds,
      minutes,
      hours,
      days,
      weeks,
    };
  };

  @computed get value() {
    return (
      MILLISECONDS_IN_SECOND *
      (this.seconds.value +
        SECONDS_IN_MINUTE *
          (this.minutes.value +
            MINUTES_IN_HOUR * (this.hours.value + HOURS_IN_DAY * (this.days.value + DAYS_IN_WEEK * this.weeks.value))))
    );
  }

  @computed get isUpdated(): boolean {
    return (
      this.weeks.isUpdated ||
      this.days.isUpdated ||
      this.hours.isUpdated ||
      this.minutes.isUpdated ||
      this.seconds.isUpdated
    );
  }
}

export default AlertIntervalModel;
