import React, { useState } from 'react';
import { Observer } from 'mobx-react';

import { EyeCrossedIcon, EyeIcon, WEXIcon } from 'assets';

import { ADMIN_INTEGRATIONS_DELETE } from 'config';

import Input from 'components/Input';
import { Input as InputAntd } from 'antd';
import type WEXIntegrationModel from 'stores/Integrations/WEXIntegration';
import IntegrationItem from '../IntegrationItem';
import ConfirmationModal from 'components/Admin/ConfirmationModal';
import Notification from 'components/Notification';
import WEXCardAssociationsTable from 'containers/Admin/Integrations/WEX/WEXCardAssociationsTable';
import EditCardAssociations from './WEXIntegrationEditCardAssociations';

import './styles.scss';

interface IProps {
  integration: WEXIntegrationModel;
}

const WEXIntegration: React.FC<IProps> = ({ integration }) => {
  const [isOpenConnectModal, setIsOpenConnectModal] = useState(false);
  const [isOpenDisconnectModal, setIsOpenDisconnectModal] = useState(false);
  const [isEditCardOpen, setIsEditCardOpen] = useState(false);

  const closeConnectModal = () => {
    setIsOpenConnectModal(false);
  };

  const closeDisconnectModal = () => {
    setIsOpenDisconnectModal(false);
  };

  const handleConnect = () => {
    setIsOpenConnectModal(true);
  };

  const handleDisconnect = () => {
    setIsOpenDisconnectModal(true);
  };

  const submitConnect = async () => {
    await integration.connect();

    closeConnectModal();

    if (integration.repositoryEntityAuthConnect.createState.success) {
      await integration.update();
    }
  };

  const submitDisconnect = async () => {
    await integration.disconnect();

    closeDisconnectModal();

    if (integration.repositoryEntityAuthDisconnect.deleteState.success) {
      await integration.update();
    }
  };

  const handleChangeHost = (e) => {
    integration.host.set(e.target.value);
  };

  const handleChangeUserName = (e) => {
    integration.username.set(e.target.value);
  };

  const handleChangePassword = (e) => {
    integration.password.set(e.target.value);
  };

  const openEditCardAssociations = () => {
    setIsEditCardOpen(true);
  };

  const closeEditCardAssociations = () => {
    setIsEditCardOpen(false);
  };

  const onCloseConnectNotification = () => {
    integration.repositoryEntityAuthConnect.createState.reset();
  };

  const onCloseDisconnectNotification = () => {
    integration.repositoryEntityAuthDisconnect.deleteState.reset();
  };

  return (
    <Observer
      render={() => (
        <>
          <IntegrationItem
            className="WEXIntegration"
            integration={integration}
            onConnect={handleConnect}
            onReconnect={handleConnect}
            onDisconnect={handleDisconnect}
            editElem={<EditCardAssociations onClick={openEditCardAssociations} />}
            icon={<WEXIcon width={40} height={40} />}
            isBeta
            accessLevelToDisconnect={[ADMIN_INTEGRATIONS_DELETE]}
          />
          <WEXCardAssociationsTable
            closeEditCardAssociations={closeEditCardAssociations}
            integration={integration}
            isEditCardOpen={isEditCardOpen}
          />
          <ConfirmationModal
            className="WEXIntegrationConnectModal"
            title="Connect WEX Fuel Cards"
            applyButtonTitle="Connect"
            isOpen={isOpenConnectModal}
            onSubmit={submitConnect}
            onCancel={closeConnectModal}
          >
            <p className="WEXIntegrationConnectModal-text">Enter your Wex FTP Credentials:</p>
            <div className="WEXIntegrationConnectModal-Host">
              <span className="WEXIntegrationConnectModal-Username--text">Host</span>
              <Input
                className="WEXIntegrationConnectModal-Host--input"
                placeholder="Host"
                value={integration.host.value}
                onChange={handleChangeHost}
                name="host"
              />
            </div>
            <div className="WEXIntegrationConnectModal-Username">
              <span className="WEXIntegrationConnectModal-Username--text">Username</span>
              <Input
                className="WEXIntegrationConnectModal-Username--input"
                placeholder="Username"
                value={integration.username.value}
                onChange={handleChangeUserName}
                name="username"
              />
            </div>
            <div className="WEXIntegrationConnectModal-Password">
              <span className="WEXIntegrationConnectModal-Password--text">Password</span>
              <InputAntd.Password
                className="WEXIntegrationConnectModal-Password--input"
                placeholder="Password"
                value={integration.password.value}
                onChange={handleChangePassword}
                name="password"
                iconRender={(visible) => (visible ? <EyeIcon fill="#6B7A99" /> : <EyeCrossedIcon fill="#6B7A99" />)}
              />
            </div>
          </ConfirmationModal>
          <ConfirmationModal
            className="WEXIntegrationDisconnectModal IntegrationDisconnectModal"
            title="Disconnect Wex Fuel Card Integration?"
            applyButtonTitle="Yes, Disconnect"
            cancelButtonTitle="No, Cancel"
            isOpen={isOpenDisconnectModal}
            onSubmit={submitDisconnect}
            onCancel={closeDisconnectModal}
            withoutCloseIcon
          >
            <p className="WEXIntegrationDisconnectModal-top">
              Disconnecting this Wex Fuel Card Integration will remove all the Wex data (Acct: {integration.accountName}
              ) from ClearpathGPS and any associated fueling data will no longer be accessible.
            </p>
            <p className="WEXIntegrationDisconnectModal-bottom">Do you want to continue?</p>
          </ConfirmationModal>
          {integration.repositoryEntityAuthConnect.createState.success && (
            <Notification
              type="success"
              text="Wex Fuel Cards data successfully connected."
              title="Success!"
              onClose={onCloseConnectNotification}
            />
          )}
          {integration.repositoryEntityAuthConnect.createState.error && (
            <Notification
              type="error"
              text="Something went wrong. Please try again."
              title="Connection Failed"
              onClose={onCloseConnectNotification}
            />
          )}
          {integration.repositoryEntityAuthDisconnect.deleteState.success && (
            <Notification
              type="success"
              text="Wex Fuel Cards data successfully disconnected."
              title="Success!"
              onClose={onCloseDisconnectNotification}
            />
          )}
          {integration.repositoryEntityAuthDisconnect.deleteState.error && (
            <Notification
              type="error"
              text="Something went wrong. Please try again."
              title="Disconnection Failed"
              onClose={onCloseDisconnectNotification}
            />
          )}
        </>
      )}
    />
  );
};

export default WEXIntegration;
