import { observable } from 'mobx';

import { repositoryService } from 'services';

import TimeField from 'models/Fields/TimeField';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

const FINANCE_ACCESS_APPROVED = 'APPROVED';
const FEATURE_NAME = 'finance';

class FinanceAccess {
  repositoryFeatureAccess: IRepository;
  repositoryFeatureAccessRequest: IEntityRepository;

  @observable time: TimeField;

  constructor() {
    this.repositoryFeatureAccess = repositoryService.get('feature-access-requests');
    this.repositoryFeatureAccessRequest = this.repositoryFeatureAccess.entity('support').entity('finance');
    this.time = new TimeField(null);
  }

  get isRequested () {
    return this.repositoryFeatureAccess.getState.success || this.repositoryFeatureAccess.getState.error;
  }

  get isFailed () {
    return !!this.repositoryFeatureAccess.getState.error;
  }

  get isLoading () {
    return !!this.repositoryFeatureAccess.getState.loading
  }

  validateStatus = async () => {
    return this.repositoryFeatureAccess.get({
      featureName: FEATURE_NAME,
    }).then(data => {
      if (data) {
        const item = data.items.filter(item => item.status === FINANCE_ACCESS_APPROVED && item.toDate > Date.now());
        this.time.value = item && item.length ? item[0].toDate : null;
      }
    });
  }

  grandAccess = () => {
    return this.repositoryFeatureAccessRequest.create({}).then(() => {
      return this.validateStatus();
    })
  }

  revokeAccess = () => {
    return this.repositoryFeatureAccessRequest.delete().then(() => {
      return this.validateStatus();
    });
  }
}


export default FinanceAccess;
