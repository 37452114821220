import React, { FC } from 'react';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom';

import { ACL, PATHS } from 'config';
import ControlledRoute from 'components/ControlledRoute';
import MaintenanceApplyTasks from './ApplyTasks';
import MaintenanceTables from './Tables';

const Maintenance: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Route path={PATHS.MAINTENANCE.INDEX} exact component={MaintenanceTables} />
      <ControlledRoute
        acls={[ACL.MAINTENANCE.BASIC.UPDATE]}
        path={PATHS.MAINTENANCE.APPLY_TASKS.TASK}
        component={MaintenanceApplyTasks}
      />
      <Redirect from="*" to={PATHS.NOT_FOUND} />
    </Switch>
  );
};

export default Maintenance;
