import React from 'react';
import { observer } from 'mobx-react';

import type { SimpleField } from 'models/Fields';

import Note from 'components/Note';

import './styles.scss';

interface IProps {
  smsText?: SimpleField<string>;
}

const AlertNotifySMS: React.FC<IProps> = ({ smsText }) => {
  const handleTextChange = (value) => {
    smsText.set(value);
  };

  return (
    <div className="AlertNotifySMS-container">
      <p className="AlertNotifySMS-title">Text Message</p>

      <Note
        isEditing
        hideControls
        note={smsText.value}
        onChange={handleTextChange}
        placeholder="Text Message"
        className="AlertNotifySMS-text"
        maxLength={160}
        countCharactersEntered
      />
    </div>
  );
};

export default observer(AlertNotifySMS);
