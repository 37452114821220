import React from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  renderSideBar: () => React.ReactNode;
  children: React.ReactNode;
  isLayoutContentScrollable?: boolean;
  isSideBarOpen?: boolean;
}

const Layout: React.FC<IProps> = ({ renderSideBar, children, isLayoutContentScrollable, isSideBarOpen = false }) => {
  const contentCn = classNames('Layout-content', {
    'Layout-content--scrollable': isLayoutContentScrollable,
    'Layout-content--SideBar-open': isSideBarOpen,
  });

  return (
    <div className="Layout">
      <div className="Layout-sideBar">{renderSideBar()}</div>
      <div className={contentCn}>{children}</div>
    </div>
  );
};

export default Layout;
