import moment, { DurationInputArg1, DurationInputArg2, Moment } from 'moment';
import momenttz from 'moment-timezone';
import type { DATE_TIME_FORMATS } from 'config';
import type { timezoneNames } from '../stores';
import { pluralize } from './helpers';

const getMoment = (timezone) => {
  if (timezone) {
    return (...args) => moment(...args).tz(timezone);
  }
  return (...args) => moment(...args);
};

/**
 * get current date and time
 * @name getCurrentDateAndTime
 * @returns {string} current date and time in the following format 'MMM DD, YYYY @ h:mma'
 * @example
 * getCurrentDateAndTime()) // => Apr 18, 2019 @ 10:12pm
 */
export const getCurrentDateAndTime = (): string => moment().format('MMM DD, YYYY @ h:mma');

/**
 * get string of time from now
 * @name getTimeFromNow
 * @returns {string} how much time from now in the following formatgetTodayCustomDate "4 months ago"
 * @example
 * getTimeFromNow(1545968867000) // => 4 months ago
 */
export const getTimeFromNow = (timems) => moment(timems).fromNow();

/**
 * get string of time or 'now'
 * @name getEventTime
 * @returns {string} of current time or now if difference with current time is less than 30 sec
 * @example
 * getEventTime(1545968867000) // => "5:47am"
 */
export const getEventTime = (timestamp: number): string => {
  const diff = moment().diff(timestamp);
  if (diff < 60 * 1000) {
    return 'now';
  }
  return moment(timestamp).format('h:mma');
};

/**
 * get string of time
 * @name getTimePickerFormat
 * @returns {string} of moment format string depends on time string length
 * @example
 * getEventTime("1am") // => "ha"
 */
export const getTimePickerFormat = (time: string) => {
  switch (time.length) {
    case 3:
      return 'ha';
    case 4:
      return 'hha';
    case 5:
      return 'hmma';
    default:
      return 'hhmma';
  }
};

export const getStartOfDayByDate = (timestamp: number, timezone?: timezoneNames): number => {
  const properMoment = getMoment(timezone);

  return properMoment(timestamp)
    .startOf('day')
    .valueOf();
};

export const getEndOfDayByDate = (timestamp: number, timezone?: timezoneNames): number => {
  const properMoment = getMoment(timezone);

  return properMoment(timestamp)
    .endOf('day')
    .valueOf();
};

export const getTodayCustomDate = ({
  hour,
  minute,
  second,
}: {
  hour?: number;
  minute?: number;
  second?: number;
}): Moment => {
  return moment({ hour, minute, second });
};

export const getTodayStartDate = (timezone?: timezoneNames): Moment => {
  const properMoment = getMoment(timezone);
  return properMoment().startOf('day');
};

export const getTodayEndDate = (timezone?: timezoneNames): Moment => {
  const properMoment = getMoment(timezone);
  return timezone ? properMoment().endOf('day') : properMoment({ hour: 23, minute: 59, second: 59 });
};

export const getPastDay = (numberOfDays, fromDate: Moment = getTodayStartDate()) => {
  return moment(fromDate)
    .subtract(numberOfDays, 'days')
    .valueOf();
};

export const getFormattedTime = (
  timeStamp: number,
  format: DATE_TIME_FORMATS | string,
  timezone?: timezoneNames | string
): string => {
  const properMoment = getMoment(timezone);
  return timeStamp ? properMoment(timeStamp).format(format) : '-';
};

export const getNowTimestampForTimezone = (timezone: string) => {
  const properMoment = getMoment(timezone);
  return properMoment().valueOf();
};

export const getTimestampForTimezone = (date, format, timezone) => moment.tz(date, format, timezone).valueOf();

export const getTimeInTimeZone = (time: number, timezone: string) =>
  timezone ? moment(time).tz(timezone) : moment(time);

export const getTimeRange = (
  startTime: number,
  rangeDuration: DurationInputArg1,
  rangeUnit: DurationInputArg2,
  format: DATE_TIME_FORMATS,
  timezone: timezoneNames
) => {
  const properMoment = getMoment(timezone);
  return `${properMoment(startTime).format(format)} - ${properMoment(startTime)
    .add(rangeDuration, rangeUnit)
    .format(format)}`;
};

/**
 * reformat time string that comes from server("130d 16h 57m")
 * @name reformatTimeString
 * @param {string} timeString e.g: "130d 16h 57m"
 * @returns {string} formatted time
 * @example
 * reformatTimeString("130d 16h 57m")) // => 130 days
 * reformatTimeString("0d 16h 57m")) // => 16h 57m
 * reformatTimeString("0d 0h 57m")) // => 57 minutes
 */
export const reformatTimeString = (timeString: string): string => {
  if (!timeString) {
    return timeString;
  }
  let res: string;

  const arr: string[] = timeString.split(' ');

  const days: number = Number((arr[0] || '').slice(0, -1));
  const hours: number = Number((arr[1] || '').slice(0, -1));
  const minutes: number = Number((arr[2] || '').slice(0, -1));

  if (days) {
    res = days > 1 ? `${days} days` : `${days} day`;
  } else if (hours) {
    res = `${hours}h ${minutes}m`;
  } else if (minutes) {
    res = minutes > 1 ? `${minutes} minutes` : `${minutes} minute`;
  } else {
    res = timeString;
  }

  return res;
};

export const getDiffFromNow = (timestamp: number, nowTime?: number): string => {
  const diff = (nowTime ? moment(nowTime) : moment()).diff(timestamp);
  return convertMillisecondsToFullDate(diff);
};

export const convertMillisecondsToFullDate = (timestamp: number): string => {
  const duration = moment.duration(timestamp);
  const days = duration.asDays() >= 1 ? Math.floor(duration.asDays()) : 0;
  const hours = duration.hours();
  const minutes = duration.minutes();

  if (days) {
    return `${days}d ${hours}h ${minutes}m`;
  } else if (hours) {
    return `${hours}h ${minutes}m`;
  } else if (minutes) {
    return `${minutes}m`;
  } else {
    return 'less than a minute';
  }
};

export const convertMillisecondsToFields = (timestamp: number) => {
  const duration = moment.duration(timestamp);

  return {
    weeks: duration.weeks(),
    days: duration.days(),
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
  };
};

export const getDiffMin = (timestamp: number): number => moment().diff(timestamp);

export const getDiffInDays = (start: number, end: number, timezone?: string) => {
  const properMoment = getMoment(timezone);
  return properMoment(start).diff(end, 'd');
};

export const isToday = (timestamp: number, timezone?: string): boolean => {
  if (!timestamp) {
    return false;
  }
  const properMoment = getMoment(timezone);
  const today = properMoment().startOf('day');
  const value = properMoment(timestamp);

  return value.isSame(today, 'd');
};

export const isTomorrow = (timestamp: number, timezone?: string): boolean => {
  const properMoment = getMoment(timezone);
  const tomorrow = properMoment()
    .add(1, 'days')
    .startOf('day');

  return properMoment(timestamp).isSame(tomorrow, 'd');
};

export const isYesterday = (timestamp: number, timezone?: string): boolean => {
  const properMoment = getMoment(timezone);
  const yesterday = properMoment()
    .subtract(1, 'days')
    .startOf('day');

  return properMoment(timestamp).isSame(yesterday, 'd');
};

export const getDateOrName = (timestamp: number, timezone?: timezoneNames | string): string => {
  const properMoment = getMoment(timezone);

  const mtimestamp = properMoment(timestamp);

  switch (true) {
    case isToday(timestamp, timezone):
      return 'Today';
    case isTomorrow(timestamp, timezone):
      return `Tomorrow`;
    case isYesterday(timestamp, timezone):
      return `Yesterday`;
    default:
      return `${mtimestamp.format('M/D/YYYY')}`;
  }
};

export const getDayName = (timestamp: number): string => {
  const mtimestamp = moment(timestamp);
  const dateName = getDateOrName(timestamp);

  switch (true) {
    case isToday(timestamp):
    case isTomorrow(timestamp):
    case isYesterday(timestamp):
      return `${dateName} @ ${mtimestamp.format('h:mmA')}`;
    default:
      return `${dateName} ${mtimestamp.format('h:mmA')}`;
  }
};

export const getCurrentTimezoneName = () => momenttz.tz.guess();

export const getTodayDate = (format: DATE_TIME_FORMATS) => moment().format(format);

export const getYesterdayDate = (format: DATE_TIME_FORMATS) =>
  moment()
    .subtract(1, 'day')
    .format(format);

export const getWeekAgo = (format: DATE_TIME_FORMATS) =>
  moment()
    .subtract(1, 'week')
    .format(format);

export const getWeekBeforeYesterday = (format: DATE_TIME_FORMATS) =>
  moment()
    .subtract(1, 'day')
    .subtract(1, 'week')
    .format(format);

const regExp12ClickTime = /^(0?[1-9]|1[0-2]):([0-5]\d)\s?(AM|PM)?$/i;
const regExp24ClickTime = /^(0?[0-9]|1[0-9]|2[0-3]):([0-5]\d)$/i;

export const isValidTime = (time: string): boolean => isValid12ClockTime(time) || isValid24ClockTime(time);

export const isValid12ClockTime = (time: string): boolean => regExp12ClickTime.test(time);

export const isValid24ClockTime = (time: string): boolean => regExp24ClickTime.test(time);

const parseTime12Clock = (value: string): [number, number, string] => {
  const [, h, m, half] = regExp12ClickTime.exec(value);
  return [Number(h), Number(m), half ? half : 'am'];
};

const parseTime24Clock = (value: string): [number, number, string] => {
  const [, h24, m] = regExp24ClickTime.exec(value);
  let hNumber = Number(h24);
  const half = hNumber > 11 ? 'pm' : 'am';
  if (hNumber === 0) {
    hNumber = 12;
  } else if (hNumber > 11) {
    hNumber -= 12;
  }
  return [hNumber, Number(m), half];
};

const withLeadZero = (num: number) => {
  return ('0' + num).slice(-2);
};

export const parseTime = (time: string): [number, number, number] => {
  if (isValid12ClockTime(time)) {
    const [h, m, half] = parseTime12Clock(time);
    return [h, m, half === 'am' ? 0 : 1];
  } else if (isValid24ClockTime(time)) {
    const [h, m, half] = parseTime24Clock(time);
    return [h, m, half === 'am' ? 0 : 1];
  } else {
    return [-1, -1, -1];
  }
};

export const normalizeTime = (time: string): string => {
  if (isValid12ClockTime(time)) {
    const [h, m, half] = parseTime12Clock(time);
    return `${withLeadZero(h)}:${withLeadZero(m)} ${half.toLowerCase()}`;
  } else if (isValid24ClockTime(time)) {
    const [h, m, half] = parseTime24Clock(time);
    return `${withLeadZero(h)}:${withLeadZero(m)} ${half.toLowerCase()}`;
  } else {
    return '';
  }
};

export const getDateTimeString = (timestamp: number) => {
  if (!timestamp) {
    return 'No date';
  }

  let when;

  if (isToday(timestamp)) {
    when = 'Today';
  } else if (isYesterday(timestamp)) {
    when = 'Yesterday';
  } else {
    const today = moment().valueOf();
    const difference = getDiffInDays(today, timestamp);
    when = `${pluralize(difference, 'day')} ago`;
  }

  return `${when} | ${moment(timestamp).format('dddd MMMM D, YYYY h:mmA')}`;
};

export const timeZoneOffset = (timezone: string): number =>
  moment.tz.zone(timezone).utcOffset(Number(moment.utc().format('x'))) * 60 * 1000;

export const getBeginningOfTheDay = (time): number => {
  return Number(
    time
      .hour(0)
      .minute(0)
      .second(0)
      .format('x')
  );
};

export const getEndOfTheDay = (time): number => {
  return Number(
    time
      .hour(23)
      .minute(59)
      .second(59)
      .format('x')
  );
};

export const getTimeFromDayAndTime = (date: number, time: string, timezone: string) => {
  return moment
    .tz(
      `${moment(date)
        .tz(timezone)
        .format('M/D/YY')} @ ${time}`,
      'M/D/YY @ hh:mm A',
      timezone
    )
    .endOf('minute')
    .valueOf();
};

export const convertTZ = (date, tzString) => {
  return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString }));
};

export const validateFirst3Digits = (value) => {
  const [h, mB, mS] = value.split('');
  if (Number(h) > 2) {
    const valueWithLeadingZero = `0${h}:${mB}${mS}`;
    if (isValidTime(valueWithLeadingZero) || isValid24ClockTime(valueWithLeadingZero)) {
      value = valueWithLeadingZero;
    }
  }
  return value;
};

export const validateFirst4Digits = (value) => {
  const valueWithSeparator = value.replace(/(\d{2})(\d{2})/, '$1:$2');
  if (isValidTime(valueWithSeparator)) {
    value = valueWithSeparator;
  } else if (isValid24ClockTime(valueWithSeparator)) {
    value = isValid24ClockTime(valueWithSeparator);
  }
  return value;
};

export const isSameDay = (left: number, right: number, timezone?: string) => {
  if (timezone) {
    return moment(left)
      .tz(timezone)
      .isSame(moment(right).tz(timezone), 'd');
  } else {
    return moment(left).isSame(moment(right), 'd');
  }
};

export const subtractMonths = (date: Date, value: number) => {
  return moment(date)
    .subtract(value, 'months')
    .toDate();
};

export const isTimeBefore = (left, right) => {
  return moment(right, 'hh:mm a').isBefore(moment(left, 'hh:mm a'));
};

export const isDateBefore = (left, right) => {
  return moment(left).isBefore(moment(right));
};

export const getNowInFormat = (format, timeZone) => {
  return getFormattedTime(Date.now(), format, timeZone);
};

export const convert24toAMPM = (time: string) => {
  return moment(time, ['HH:mm']).format('hh:mm a');
};

export const convertAMPMto24 = (time: string) => {
  return moment(time, ['hh:mm a']).format('HH:mm');
};

export const getExpiration = (time: number): string => {
  const duration = moment.duration(time);
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  return `${days ? `${days}d` : ''} ${hours ? `${hours}h` : ''} ${minutes ? `${minutes}m` : ''}`.trim();
};

export const isFirstEventBeforeDate = (firstEvent: number, dateValue: number, timeString: string, timezone: string) => {
  if (isSameDay(dateValue, firstEvent, timezone)) {
    const firstEventTimeToMinute =
      getTimeInTimeZone(firstEvent, timezone)
        .endOf('minute')
        .valueOf() - 1000;
    const selectedTime = getTimeFromDayAndTime(dateValue, timeString, timezone);
    return firstEventTimeToMinute > selectedTime;
  }
  return false;
};

export const getDayStartDate = (timestamp: number, timezone: string): Date => {
  return getTimeInTimeZone(timestamp, timezone)
    .startOf('day')
    .toDate();
};

export const getDayEndDate = (timestamp: number, timezone: string): Date => {
  return getTimeInTimeZone(timestamp, timezone)
    .endOf('day')
    .toDate();
};

export const getDayStartValue = (timestamp: number, timezone: string): number => {
  return getTimeInTimeZone(timestamp, timezone)
    .startOf('day')
    .valueOf();
};

export const getDayEndValue = (timestamp: number, timezone: string): number => {
  return getTimeInTimeZone(timestamp, timezone)
    .endOf('day')
    .valueOf();
};

export function secondsToTimeString(seconds) {
  const totalSeconds = Math.floor(seconds);
  const hours = Math.floor(totalSeconds / Math.pow(60, 2));
  const minutes = Math.floor((totalSeconds % Math.pow(60, 2)) / 60);
  const remainingSeconds = totalSeconds % 60;

  const paddedMinutes = (minutes < 10 ? '0' : '') + minutes;
  const paddedSeconds = (remainingSeconds < 10 ? '0' : '') + remainingSeconds;

  return `${hours}:${paddedMinutes}:${paddedSeconds}`;
}

export function timeToNumber(time: string) {
  return time
    .split(':')
    .reverse()
    .map((val, index) => Math.pow(60, index) * Number(val))
    .reduce((acc, val) => acc + val, 0);
}

export function dateToNumber(time: string) {
  return moment(time).valueOf() / 1000;
}

export const getMinutesSecondsText = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);

  return `${minutes ? `${minutes}m ` : ''}${seconds - minutes * 60}s`;
};
