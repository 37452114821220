import { APIStoreBase } from 'stores/APIStoreBase';
import HttpRequest from './HttpRequest';
import RepositoryBase from './RepositoryBase';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

class EntityRepository extends RepositoryBase implements IEntityRepository {
  public deleteState: APIStoreBase = new APIStoreBase();

  public delete = async (params: object = {}): Promise<any> => {
    this.deleteState.reset().setLoading(true);

    try {
      const response = await new HttpRequest().delete(this.url).send(params);

      this.deleteState.setSuccess(true);
      this.deleteState.setLoading(false);
      this.trigger('success:delete');

      return response;
    } catch (err) {
      this.deleteState.setError(err);
      this.deleteState.setLoading(false);
      this.trigger('error:delete');

      Error(`Can not delete "${this.url}" entity. Details: ${err}`);
    }
  };

  public entity = (id: string): EntityRepository => {
    const url = id ? `${this.url}/${id}` : this.url;
    return new EntityRepository(url);
  };
}

export default EntityRepository;
