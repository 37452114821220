import { observable } from 'mobx';
import { ToggleField } from 'models/Fields';
import type { AlertsTableStore } from 'stores';
import AlertModel from './Alert';
import AlertIntervalModel from './AlertInterval';

abstract class AlertWithIntervalModel extends AlertModel {
  @observable interval: AlertIntervalModel;
  @observable intervalOption: ToggleField;
  intervalDescription: string;
  intervalFields: Alerts.Modal.Options.Type[];

  private intervalMilliseconds: number;

  constructor(item) {
    super(item);

    this.intervalMilliseconds = item.interval;
    this.intervalFields = ['hours', 'minutes'];
    this.intervalOption = new ToggleField(item.isIntervalActive);
    this.intervalDescription = 'If you are receiving alerts too frequently, we recommend increasing this interval.';
  }

  initialize(context: AlertsTableStore): this {
    super.initialize(context);

    this.interval = new AlertIntervalModel(this.intervalMilliseconds, this.intervalFields);

    return this;
  }

  get model() {
    return { ...super.model, interval: this.intervalOption.value ? this.interval.value : 0 };
  }

  isUpdated(): boolean {
    return super.isUpdated() || this.intervalOption.isUpdated || this.interval.isUpdated;
  }
}

export default AlertWithIntervalModel;
