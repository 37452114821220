import React from 'react';
import { observer } from 'mobx-react';

import type MediaClips from 'models/Dashcam/MediaLibrary/MediaClips';
import DeviceMediaMarkerItem from './DeviceMediaMarkerItem';

interface IProps {
  clips: MediaClips;
  showHistory: boolean;
}

const DeviceMediaMarkerList: React.FC<IProps> = ({ clips, showHistory }) => {
  return (
    <>
      {clips.itemsCoordinates.map(({ lat, lng, interaction }, key) => (
        <DeviceMediaMarkerItem
          key={key}
          lat={lat}
          lng={lng}
          interaction={interaction.value}
          update={interaction.set}
          showHistory={showHistory}
        />
      ))}
    </>
  );
};

export default observer(DeviceMediaMarkerList);
