import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { REPORTS_STORE } from 'config';

import type { ReportsStore } from 'stores';

import ReportTableContainer from '../ReportTableContainer';

interface IProps {
  className?: string;
  reportsStore?: ReportsStore;
}

@inject(REPORTS_STORE)
@observer
class ReportsPageIndex extends Component<IProps, null> {
  render() {
    return (
      <>
        <div className="ReportPage-header">
          <h4 className="ReportPage-title">Reports</h4>
          <p className="ReportPage-description">
            Use reports to see historic trends in your fleet across vehicles, groups of vehicles, drivers and geozones.
          </p>
        </div>
        <div className="ReportPage-table">
          <ReportTableContainer empty />
        </div>
      </>
    );
  }
}

export default ReportsPageIndex;
