import React, { Component, createRef } from 'react';
import { inject, observer } from 'mobx-react';
import type { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { get } from 'lodash';

import SideBarLogo from 'components/SideBar/Logo';
import SideBarNavigation from 'components/SideBar/Navigation';
import SideBarProfile from 'components/SideBar/Profile';
import Search from 'containers/Search';
import ChangePasswordModal from 'containers/Auth/ChangePassword';
import SelectAccountModal from 'containers/Auth/SelectAccountModal';

import validateAccessLevel from 'stores/acl/validator';
import { UserStore, SearchStore, MapStore, UserAccessLevelComponent } from 'stores';
import { FEATURES, PATHS, GENERAL_GLOBAL_SEARCH_READ, LINKS } from 'config';

import { PendoIcon } from 'assets';
import './styles.scss';
import { AppSwitcher } from '../../components/AppSwitcher';

interface IProps {
  userStore: UserStore;
  searchStore: SearchStore;
  mapStore: MapStore;
}

interface IState {
  isChangePasswordModalOpen: boolean;
  isChangeAccountModalOpen: boolean;
  isAppSwitcherOpen: boolean;
}

@inject(({ userStore, searchStore, devicesMapStore: { mapStore } }) => {
  return {
    userStore,
    searchStore,
    mapStore,
  };
})
@observer
class SideBarContainer extends Component<IProps & RouteComponentProps, IState> {
  private sideBarRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);

    this.state = {
      isChangePasswordModalOpen: false,
      isChangeAccountModalOpen: false,
      isAppSwitcherOpen: false,
    };
    this.sideBarRef = createRef();
  }

  componentDidMount(): void {
    const {
      userStore: { getUserData },
    } = this.props;

    getUserData();
  }

  handleLogout = async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
    e.preventDefault();

    const {
      userStore: { logout },
      // history: { push },
    } = this.props;

    await logout();
    window.location.href = `/web${PATHS.AUTH.LOGIN}`;
    // push(PATHS.OLD_VERSION.LOGIN);
  };

  handleChangeAccount = () => this.setState({ isChangeAccountModalOpen: !this.state.isChangeAccountModalOpen });

  handleCloseChangeAccount = () => this.setState({ isChangeAccountModalOpen: false });

  handleOpenChangePasswordModal = () => this.setState({ isChangePasswordModalOpen: true });

  handleCloseChangePasswordModal = () => this.setState({ isChangePasswordModalOpen: false });

  handleAppSwitcherClick = () => {
    this.setState({ isAppSwitcherOpen: !this.state.isAppSwitcherOpen });
  };

  render() {
    const {
      userStore: { userData, userRoles, multiAccount, userAvatarSrc },
      searchStore: { isSearchActive },
      history: {
        location: { pathname },
      },
    } = this.props;
    const { isChangePasswordModalOpen, isChangeAccountModalOpen } = this.state;

    const searchPermissions = validateAccessLevel([GENERAL_GLOBAL_SEARCH_READ]);

    const cn = classNames('SideBar SideBar--collapsed', {
      'SideBar--withActiveSearch': isSearchActive,
    });
    const logoCN = classNames('SideBar-logo', {
      'SideBar-logo--withSearch': searchPermissions,
    });
    const userName = get(userData, 'contactName', '');
    const userEmail = get(userData, 'loginEmail', '');
    const userPlan = get(userData, 'plan.description', '').toLowerCase();
    const userAccountName = get(userData, 'account.description', get(userData, 'account.idGts', ''));
    const customerRole = get(userData, 'roles[0].name', '');

    return (
      <div className={cn} ref={this.sideBarRef}>
        <div className="SideBar-group SideBar-group--top">
          <div className="SideBar-header">
            <div className={logoCN} onClick={this.handleAppSwitcherClick}>
              <SideBarLogo />
              {this.state.isAppSwitcherOpen && (
                <AppSwitcher className="SideBar-switcher" onClick={this.handleAppSwitcherClick} />
              )}
            </div>
            <UserAccessLevelComponent requiredAccessLevel={[GENERAL_GLOBAL_SEARCH_READ]}>
              <div className="SideBar-search">
                <Search />
              </div>
            </UserAccessLevelComponent>
          </div>
          <div className="SideBar-divider" />
          <div className="SideBar-navigation">
            <SideBarNavigation pathname={pathname} customerRole={customerRole} />
          </div>
        </div>
        <div className="SideBar-group SideBar-group--bottom">
          {userPlan === 'standard' && (
            <div className="SideBar-upgrade">
              <a className="SideBar-upgradeButton" href={LINKS.upgradeToPro} target="_blank">
                Upgrade to Pro
              </a>
            </div>
          )}
          <div className="SideBar-pendo">
            <div className="SideBar-pendoIcon" id="sidebar-pendo-icon">
              <PendoIcon />
            </div>
            <p className="SideBar-pendoText">Help</p>
          </div>
          <div className="SideBar-divider" />
          <div className="SideBar-profile">
            <SideBarProfile
              accountName={userAccountName}
              accountPlan={userPlan}
              userName={userName}
              userEmail={userEmail}
              userRoles={userRoles}
              onLogout={this.handleLogout}
              onOpenChangePasswordModal={this.handleOpenChangePasswordModal}
              multiAccount={multiAccount}
              onChangeAccount={this.handleChangeAccount}
              profileImage={userAvatarSrc}
            />
          </div>
          {FEATURES.changePassword && (
            <div className="SideBar-changePasswordModal">
              <ChangePasswordModal
                onClose={this.handleCloseChangePasswordModal}
                isModalOpen={isChangePasswordModalOpen}
              />
            </div>
          )}
          {multiAccount && (
            <SelectAccountModal open={isChangeAccountModalOpen} onClose={this.handleCloseChangeAccount} />
          )}
        </div>
      </div>
    );
  }
}

export default SideBarContainer;
