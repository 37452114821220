import { action, computed, observable } from 'mobx';

import { repositoryService } from 'services';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { RequestType } from '../services/RepositoryService/RequestsType';

export interface IClearshareSearchParams {
  active?: boolean;
  assetId?: number;
  deviceId?: string;
  email?: string;
  expired?: boolean;
  phoneNumber?: string;
}

export class ClearshareStore {
  @observable links: Clearshare.ILink[] = [];
  @observable linkToEdit: Clearshare.ILink = null;
  repositoryClearshare: IRepository;
  repositoryClearshareExpire: IEntityRepository;

  constructor() {
    this.repositoryClearshare = repositoryService.get('clearshare');
    this.repositoryClearshareExpire = this.repositoryClearshare.entity('expire');
    this.repositoryClearshare.listenTo('error:get', () => this.resetLinks());
  }

  @computed get sortedLinks() {
    return this.links.sort((prevLink, nextLink) => prevLink.expiration - nextLink.expiration);
  }

  @action getLinks = async ({
    order,
    page,
    pageSize,
    search,
    sortBy,
  }: {
    order?: string;
    page?: number;
    pageSize?: number;
    search?: IClearshareSearchParams;
    sortBy?: string;
  }) => {
    const response = await this.repositoryClearshare.get({ order, page, pageSize, ...search, sortBy });
    this.links = response.items;
  };

  @action getEditLink = async (linkId: string) => {
    this.linkToEdit = await this.repositoryClearshare.entity(linkId).get();
  };

  @action createLink = async (values: Clearshare.ICreateLinkData) => {
    await this.repositoryClearshare.create(values);
  };

  @action editLink = async (linkId: number, values: Clearshare.ICreateLinkData) => {
    await this.repositoryClearshare.put({ id: linkId, ...values });
  };

  @action expireLink = async (linkId: string) => {
    await this.repositoryClearshareExpire.do(linkId, {}, { requestType: RequestType.PUT });
  };

  @action resetLinks = () => (this.links = []);

  @action resetLinkToEdit = () => (this.linkToEdit = null);

  @action reset = () => {
    this.resetLinks();
    this.repositoryClearshare.getState.reset();
    this.repositoryClearshare.createState.reset();
    this.repositoryClearshareExpire.doState.reset();
  };
}

export default new ClearshareStore();
