import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import type { MediaManager, TimeStore } from 'stores';

import MediaLibrarySourceSwitcherSelect from 'components/DashCams/MediaLibrary/SourceSwitcherAndSelect';
import MediaLibraryPanelSectionTitle from 'components/DashCams/MediaLibrary/PanelSectionTitle';
import MediaLibrarySourceDateRange from 'components/DashCams/MediaLibrary/DateRange';
import MediaLibraryMediaTypeSwitcher from 'components/DashCams/MediaLibrary/MediaTypeSwitcher';
import MediaLibraryCheckbox from 'components/DashCams/MediaLibrary/MediaLibraryCheckbox';

import './styles.scss';

interface IProps {
  className?: string;
  mediaManagerStore?: MediaManager;
  timeStore?: TimeStore;
}

@inject(({ mediaManagerStore, timeStore }) => ({ mediaManagerStore, timeStore }))
@observer
class DashCamsMediaSource extends Component<IProps> {
  render() {
    const {
      className,
      mediaManagerStore: {
        library: {
          source: { vehicles, groups, dateRange, mediaType },
          type: { categories },
        },
      },
      timeStore: { sessionTimezone },
    } = this.props;
    const cn = classNames('DashCamsMediaSource', className);

    return (
      <div className={cn}>
        <div className="DashCamsMediaSource-section DashCamsMediaSource-section--source">
          <div className="DashCamsMediaSource-sectionContent">
            <MediaLibrarySourceSwitcherSelect groups={groups} vehicles={vehicles} />
          </div>
        </div>
        <div className="DashCamsMediaSource-section DashCamsMediaSource-section--dateRange">
          <div className="DashCamsMediaSource-sectionTitle">
            <MediaLibraryPanelSectionTitle>Date Range</MediaLibraryPanelSectionTitle>
          </div>
          <div className="DashCamsMediaSource-sectionContent">
            <MediaLibrarySourceDateRange dateRange={dateRange} timezone={sessionTimezone} />
          </div>
        </div>
        <div className="DashCamsMediaSource-section DashCamsMediaSource-section--mediaType">
          <div className="DashCamsMediaSource-sectionTitle">
            <MediaLibraryPanelSectionTitle>Media Type</MediaLibraryPanelSectionTitle>
          </div>
          <div className="DashCamsMediaSource-sectionContent">
            <MediaLibraryMediaTypeSwitcher mediaType={mediaType} />
          </div>
        </div>
        {Boolean(categories.hideBumpEventsOption) && (
          <div className="DashCamsMediaSource-section DashCamsMediaSource-section--bump">
            <div className="DashCamsMediaSource-sectionContent">
              <MediaLibraryCheckbox option={categories.hideBumpEventsOption} />
            </div>
          </div>
        )}
        {Boolean(categories.hideGlassBreakOption) && (
          <div className="DashCamsMediaSource-section DashCamsMediaSource-section--glassBreak">
            <div className="DashCamsMediaSource-sectionContent">
              <MediaLibraryCheckbox option={categories.hideGlassBreakOption} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DashCamsMediaSource;
