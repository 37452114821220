import React from 'react';
import { Observer } from 'mobx-react';

import type RequestMediaTimeSegment from 'models/Dashcam/MediaRequestCheckout/RequestMedia/RequestMediaTimeSegment';
import type PlayerViewModel from 'models/Dashcam/MediaRequestCheckout/Player/Player';
import PlayingPlayerState from 'models/Dashcam/MediaRequestCheckout/Player/PlayingPlayerState';

import MediaManagerPlayerControlButton from '../MediaManagerPlayerControlButton';
import MediaManagerPlayerProgressBar from '../MediaManagerPlayerProgressBar';

import './styles.scss';

interface IProps {
  timeSegment: RequestMediaTimeSegment;
  timezone: string;
  player: PlayerViewModel;
}

const MediaManagerPlayerControls: React.FC<IProps> = ({ timezone, timeSegment, player }) => {
  const handleControl = () => {
    if (player.isLoading.value) {
      return;
    }

    if (player.state instanceof PlayingPlayerState) {
      player.pause();
    } else {
      player.play();
    }
  };

  const handleChangeStartTime = (value: number) => {
    timeSegment.startTime.set(value);
    player.pauseStatic();
  };

  return (
    <Observer
      render={() => (
        <div className="MediaManagerPlayerControls">
          <MediaManagerPlayerControlButton
            state={player.state}
            onClick={handleControl}
            isDisabled={player.isLoading.value}
          />
          <div className="MediaManagerPlayerControls-controlProgressBar">
            <MediaManagerPlayerProgressBar
              timezone={timezone}
              timeLineStart={timeSegment.timeline.start.value}
              timeLineEnd={timeSegment.timeline.end.value}
              segmentStartTime={timeSegment.startTime.value}
              scrubber={timeSegment.scrubber.value}
              duration={timeSegment.duration.active.value}
              onChangeSegmentStartTime={handleChangeStartTime}
            />
          </div>
        </div>
      )}
    />
  );
};

export default MediaManagerPlayerControls;
