import React, { Component } from 'react';
import classNames from 'classnames';
import { withScriptjs } from 'react-google-maps';
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import type SearchBox from 'react-google-maps/lib/components/places/SearchBox';
import type { GoogleMapProps } from 'react-google-maps/lib/components/GoogleMap';
import Input from 'components/Input';
import get from 'lodash/get';
import type { FieldProps } from 'formik';
import { stripHtml } from 'utils';

import './styles.scss';

interface IProps extends GoogleMapProps, FieldProps {
  bodyClassName?: string;
  className?: string;
  error?: string;
  fieldProps?: FieldProps;
  label?: string;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (value: string) => void;
  placeholder?: string;
  value?: string;
}

interface IState {
  address: string;
}

class GoogleMapsSearch extends Component<IProps, IState> {
  searchBox: SearchBox;

  static defaultProps = {
    onChange: () => true,
    onBlur: () => true,
    value: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      address: get(props, 'form.values.destination.address', ''),
    };
  }

  addClassName = () => {
    const body = document.body;
    const { bodyClassName, error } = this.props;

    if (bodyClassName) {
      body.classList.add(bodyClassName);
      if (error) {
        body.classList.add(`${bodyClassName}--error`);
      }
    }
  };

  removeClassName = () => {
    const { bodyClassName } = this.props;
    if (bodyClassName) {
      document.body.classList.remove(bodyClassName, `${bodyClassName}--error`);
    }
  };

  setSearchBoxRefs = (refs) => (this.searchBox = refs);

  onPlacesChanged = () => {
    const { field } = this.props;
    const searchResults = this.searchBox.getPlaces();
    const address = get(searchResults, '[0].formatted_address', '');
    const latitude = get(searchResults, '[0].geometry.location.lat', null)();
    const longitude = get(searchResults, '[0].geometry.location.lng', null)();

    this.setState({ address });
    this.removeClassName();

    if (field) {
      field.onChange({
        target: {
          name: 'destination',
          value: { address, latitude, longitude },
        },
      });
    }
  };

  handleChange = (e) => {
    const { form, field, onChange } = this.props;
    const { address: selectedAddress } = this.state;
    const currentValue = e.target.value;

    onChange(currentValue);
    setTimeout(this.addClassName, 0);

    if (field) {
      const { setFieldError } = form;

      field.onChange({
        target: {
          name: 'destination.address',
          value: currentValue,
        },
      });

      if (selectedAddress && selectedAddress !== currentValue) {
        field.onChange({
          target: {
            name: 'destination.latitude',
            value: undefined,
          },
        });

        field.onChange({
          target: {
            name: 'destination.longitude',
            value: undefined,
          },
        });

        setFieldError('destination.address', 'Invalid address');
        setFieldError('destination.latitude', 'Invalid address');
        setFieldError('destination.longitude', 'Invalid address');
      }
    }
  };

  handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { field, onBlur } = this.props;

    onBlur(e);
    this.removeClassName();

    if (field) {
      field.onBlur(e);
    }
  };

  render() {
    const { className, placeholder, field, value, error = '', label } = this.props;
    const cn = classNames('GoogleMapsSearch', className);

    const inputValue = (field ? field.value : value) || '';

    return (
      <div className={cn}>
        <StandaloneSearchBox ref={this.setSearchBoxRefs} onPlacesChanged={this.onPlacesChanged}>
          <Input
            {...field}
            className="GoogleMapsSearch-input"
            error={error}
            label={label}
            onFocus={this.addClassName}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            placeholder={placeholder}
            value={stripHtml(inputValue)}
          />
        </StandaloneSearchBox>
      </div>
    );
  }
}

export default withScriptjs(GoogleMapsSearch);
