import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { inject, observer } from 'mobx-react';

import { validateAccessLevel, UserAccessLevelComponent } from 'stores';
import type { VehiclesAdmin } from 'stores/Admin/Vehicles';

import Button from 'components/Button';
import GroupMembership from '../VehicleSettings/GroupMemebership';
import InfoTooltip from 'components/InfoTooltip';
import Input from 'components/Input';
import InputNumber from 'components/InputNumber';
import Note from 'components/Note';
import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';
import SearchableDriverSelect from 'containers/Select/SearchableDriverSelect';
import StateSelect from 'containers/Select/StateSelect';
import FuelTypeSelect from 'containers/Admin/Integrations/WEX/WEXCardAssociationsTable/WEXCardAssociationsFuelType';
import TankCapacityInput from 'containers/Admin/Integrations/WEX/WEXCardAssociationsTable/WEXCardAssociationsTankCapacity';
import ResetMetricsModal from 'components/Admin/ResetMetricsModal';

import {
  ADMIN_VEHICLE_NOTES_UPDATE,
  ADMIN_VEHICLES_UPDATE,
  VEHICLE_DRIVER_NAME_READ,
  VEHICLE_DRIVER_NAME_UPDATE,
  WEB_MANAGEMENT_VEHICLE_FUEL_DETAILS_READ,
  WEB_MANAGEMENT_VEHICLE_FUEL_DETAILS_UPDATE,
  ACL,
} from 'config';
import { getValidVehicleName } from 'utils';

import './styles.scss';

const yearsOptions = [
  'blank',
  '2019',
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028',
  '2029',
].map((year) => ({
  label: year === 'blank' ? '-' : year,
  value: year,
}));

const monthOptions = [
  'blank',
  '1 - Jan',
  '2 - Feb',
  '3 - Mar',
  '4 - Apr',
  '5 - May',
  '6 - Jun',
  '7 - Jul',
  '8 - Aug',
  '9 - Sep',
  '10 - Oct',
  '11 - Nov',
  '12 - Dec',
].map((month) => ({
  label: month === 'blank' ? '-' : month,
  value: month,
}));

interface IProps {
  className?: string;
  vehiclesAdmin?: VehiclesAdmin;
  onChange?: (field: { name: string; value: string | number | boolean }) => void;
}

interface IState {
  resetMpgModalOpen: boolean;
}

@inject(({ adminStore: { vehiclesAdmin } }) => ({
  vehiclesAdmin,
}))
@observer
class AdminVehicleDetails extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      resetMpgModalOpen: false,
    };
  }

  componentDidMount(): void {
    const {
      vehiclesAdmin: { fuelTypeOptions },
    } = this.props;

    fuelTypeOptions.fetch();
  }

  handleNameChange = (e) => {
    const { onChange } = this.props;
    const allowedName = getValidVehicleName(e.target.value);
    const {
      vehiclesAdmin: {
        selectedVehicle: { updateName },
      },
    } = this.props;

    if (allowedName.length <= 64) {
      updateName(allowedName);
      onChange({ name: 'displayName', value: allowedName });
    }
  };

  handleDescriptionChange = (e) => {
    const { onChange } = this.props;
    const name = e.target.value;
    const {
      vehiclesAdmin: {
        selectedVehicle: { updateDescription },
      },
    } = this.props;

    if (name.length <= 128) {
      updateDescription(e.target.value);
      onChange({ name: 'description', value: e.target.value });
    }
  };

  handleEquipmentTypeChange = (e) => {
    const { onChange } = this.props;
    const name = e.target.value;
    const {
      vehiclesAdmin: {
        selectedVehicle: { updateEquipmentType },
      },
    } = this.props;

    if (name.length <= 40) {
      updateEquipmentType(e.target.value);
      onChange({ name: 'equipmentType', value: e.target.value });
    }
  };

  handleYearChange = (year) => {
    const { onChange } = this.props;
    const {
      vehiclesAdmin: {
        selectedVehicle: { updateVehicleYear },
      },
    } = this.props;

    updateVehicleYear(year);
    onChange({ name: 'vehicleYear', value: year });
  };

  handleVINChange = (e) => {
    const { onChange } = this.props;
    const name = e.target.value;
    const {
      vehiclesAdmin: {
        selectedVehicle: { updateVIN },
      },
    } = this.props;

    if (name.length <= 24) {
      updateVIN(e.target.value);
      onChange({ name: 'vehicleID', value: e.target.value });
    }
  };

  handleMakeChange = (e) => {
    const { onChange } = this.props;
    const name = e.target.value;
    const {
      vehiclesAdmin: {
        selectedVehicle: { updateMake },
      },
    } = this.props;

    if (name.length <= 40) {
      updateMake(e.target.value);
      onChange({ name: 'vehicleMake', value: e.target.value });
    }
  };

  handleModelChange = (e) => {
    const { onChange } = this.props;
    const name = e.target.value;
    const {
      vehiclesAdmin: {
        selectedVehicle: { updateModel },
      },
    } = this.props;

    if (name.length <= 40) {
      updateModel(e.target.value);
      onChange({ name: 'vehicleModel', value: e.target.value });
    }
  };

  handlePlateChange = (e) => {
    const { onChange } = this.props;
    const name = e.target.value;
    const {
      vehiclesAdmin: {
        selectedVehicle: { updatePlate },
      },
    } = this.props;

    if (name.length <= 10) {
      updatePlate(e.target.value);
      onChange({ name: 'licensePlate', value: e.target.value });
    }
  };

  handleUpdateLicenseExpirationMonth = ({ value }) => {
    const { onChange } = this.props;
    const {
      vehiclesAdmin: {
        selectedVehicle: { updateLicenseExpirationMonth },
      },
    } = this.props;

    updateLicenseExpirationMonth(value);
    onChange({ name: 'licenseExpirationMonth', value });
  };

  handleUpdateLicenseExpirationYear = ({ value }) => {
    const { onChange } = this.props;
    const {
      vehiclesAdmin: {
        selectedVehicle: { updateLicenseExpirationYear },
      },
    } = this.props;

    updateLicenseExpirationYear(value);
    onChange({ name: 'licenseExpirationYear', value });
  };

  handleLicenseStateChange = (option: Select.ISelectOption) => {
    const { onChange } = this.props;
    const {
      vehiclesAdmin: {
        selectedVehicle: { updateLicenseState },
      },
    } = this.props;

    updateLicenseState(option.value);
    onChange({ name: 'licenseState', value: option.value });
  };

  handleDriverChange = (option: Select.ISelectOption) => {
    const {
      vehiclesAdmin: {
        selectedVehicle: { updateDriverById },
      },
      onChange,
    } = this.props;

    onChange({ name: 'driverId', value: option.value });
    updateDriverById({
      id: option.value,
      name: option.label,
    });
  };

  handleDriverRemove = () => {
    const {
      vehiclesAdmin: {
        selectedVehicle: { removeDriver },
      },
      onChange,
    } = this.props;

    onChange({ name: 'driverId', value: '' });
    removeDriver();
  };

  handleMaxSpeedChange = (e) => {
    const speed = e.target.value;
    const { onChange } = this.props;

    const {
      vehiclesAdmin: {
        selectedVehicle: { updateMaximumSpeed },
      },
    } = this.props;

    if ((/^(\d+(\.\d{0,1})?|\.?\d{1})$/.test(speed) && speed.length <= 5) || speed === '') {
      updateMaximumSpeed(speed);
      onChange({ name: 'maximumSpeed', value: speed });
    }
  };

  handleVehicleNoteChange = (note) => {
    const { onChange } = this.props;
    const {
      vehiclesAdmin: {
        selectedVehicle: { updateVehicleNote },
      },
    } = this.props;

    updateVehicleNote(note);
    onChange({ name: 'notes', value: note });
  };

  handleGroupsChange = (groupId) => {
    const { onChange } = this.props;

    onChange({ name: 'groups', value: groupId });
  };

  handleFuelTypeChange = (option: Select.ISelectOption) => {
    const { onChange } = this.props;

    onChange({ name: 'fuelType', value: option.value });
  };

  handleTankCapacityChange = (tankCapacity) => {
    const { onChange } = this.props;

    onChange({ name: 'tankCapacity', value: tankCapacity });
  };

  openResetMpgModal = () => this.setState({ resetMpgModalOpen: true });

  closeResetMpgModal = () => this.setState({ resetMpgModalOpen: false });

  cancelResetMpg = () => {
    const {
      vehiclesAdmin: {
        selectedVehicle: { resetMpgTime },
      },
    } = this.props;

    resetMpgTime.reset();
    this.closeResetMpgModal();
  };

  resetMpg = async () => {
    const {
      vehiclesAdmin: {
        selectedVehicle: { resetMpg },
      },
    } = this.props;

    await resetMpg();
    this.closeResetMpgModal();
  };

  render() {
    const {
      vehiclesAdmin: {
        fuelTypeOptions,
        selectedVehicle: {
          description,
          driverName,
          driverId,
          equipmentType,
          licenseExpirationMonth,
          licenseExpirationYear,
          make,
          maximumSpeed,
          model,
          name,
          note,
          plate,
          vin,
          vehicleYear,
          licenseState,
          fuelDetails,
          milesPerGallon,
          resetMpgTime,
        },
      },
    } = this.props;
    const { resetMpgModalOpen } = this.state;
    const isEditable = validateAccessLevel([ADMIN_VEHICLES_UPDATE]);
    const isNoteEditable = validateAccessLevel([ADMIN_VEHICLE_NOTES_UPDATE]);
    const isDriverNameEditable = validateAccessLevel([VEHICLE_DRIVER_NAME_UPDATE]);
    const isFuelEditable = validateAccessLevel([WEB_MANAGEMENT_VEHICLE_FUEL_DETAILS_UPDATE]);

    return (
      <div className="AdminVehicleDetails">
        <div className="AdminVehicleDetails-container">
          <Row>
            <Col className="AdminVehicleDetails-leftCol" span={16}>
              <div className="AdminVehicleDetails-dataRow AdminVehicleDetails-dataRow--flex AdminVehicleDetails-dataRow--withErrorMessage">
                <div className="AdminVehicleDetails-name">
                  <p className="AdminVehicleDetails-label">vehicle name</p>
                  <Input
                    disabled={!isEditable}
                    className="AdminVehicleDetails-input AdminVehicleDetails-inputName"
                    placeholder="Vehicle Name"
                    onChange={this.handleNameChange}
                    value={name}
                    name={'displayName'}
                    maxLength={40}
                    error={!/\S+/.test(name) ? 'Vehicle Name is a required field' : ''}
                  />
                </div>
                <div className="AdminVehicleDetails-maxSpeed">
                  <div className="AdminVehicleDetails-maxSpeedTitle">
                    <p className="AdminVehicleDetails-label">Max Speed</p>
                    <div className="AdminVehicleDetails-maxSpeedInfo">
                      <InfoTooltip>
                        Vehicle will be flagged as Speeding on the Map and in Reports when the vehicle exceeds this
                        limit.
                      </InfoTooltip>
                    </div>
                  </div>
                  <div className="AdminVehicleDetails-maxSpeedInput">
                    <Input
                      disabled={!isEditable}
                      className="AdminVehicleDetails-input AdminVehicleDetails-input--maxSpeed"
                      onChange={this.handleMaxSpeedChange}
                      placeholder="Max Speed"
                      value={maximumSpeed.toString()}
                      name={'maximumSpeed'}
                    />
                    <span className="AdminVehicleDetails-maxSpeedMph">MPH</span>
                  </div>
                </div>
              </div>
              <div className="AdminVehicleDetails-dataRow">
                <p className="AdminVehicleDetails-label">vehicle description</p>
                <Input
                  disabled={!isEditable}
                  className="AdminVehicleDetails-input AdminVehicleDetails-inputDescription"
                  placeholder="Vehicle Description"
                  onChange={this.handleDescriptionChange}
                  value={description}
                  name={'description'}
                />
              </div>
              <div className="AdminVehicleDetails-dataRow">
                <p className="AdminVehicleDetails-label">Equipment type/Attributes</p>
                <Input
                  disabled={!isEditable}
                  className="AdminVehicleDetails-input AdminVehicleDetails-inputEquipmentType"
                  placeholder="Ex. Bucket Truck"
                  onChange={this.handleEquipmentTypeChange}
                  value={equipmentType}
                  name={'equipmentType'}
                />
              </div>
              <div className="AdminVehicleDetails-dataRow">
                <p className="AdminVehicleDetails-label">Vehicle Information</p>
                <div className="AdminVehicleDetails-dataRowContent AdminVehicleDetails-vehicleInformation">
                  <InputNumber
                    disabled={!isEditable}
                    className="AdminVehicleDetails-input AdminVehicleDetails-inputYear"
                    label="Year"
                    max="2050"
                    min="1950"
                    onChange={this.handleYearChange}
                    placeholder="Year"
                    value={vehicleYear}
                  />
                  <Input
                    disabled={!isEditable}
                    label="Make"
                    className="AdminVehicleDetails-input AdminVehicleDetails-inputMake"
                    placeholder="Make"
                    onChange={this.handleMakeChange}
                    value={make}
                    name={'vehicleMake'}
                  />
                  <Input
                    disabled={!isEditable}
                    label="Model"
                    className="AdminVehicleDetails-input AdminVehicleDetails-inputModel"
                    placeholder="Model"
                    onChange={this.handleModelChange}
                    value={model}
                    name={'vehicleModel'}
                  />
                  <Input
                    disabled={!isEditable}
                    label="VIN"
                    className="AdminVehicleDetails-input AdminVehicleDetails-inputVIN"
                    placeholder="VIN"
                    onChange={this.handleVINChange}
                    value={vin}
                    name={'vehicleID'}
                  />
                </div>
              </div>
              <div className="AdminVehicleDetails-dataRow">
                <p className="AdminVehicleDetails-label">Vehicle Registration</p>
                <div className="AdminVehicleDetails-dataRowContent">
                  <div className="AdminVehicleDetails-dropdownContainer AdminVehicleDetails-state">
                    <p className="AdminVehicleDetails-dropdownLabel">State</p>
                    <StateSelect
                      disabled={!isEditable}
                      handleChange={this.handleLicenseStateChange}
                      value={{ value: licenseState, label: licenseState }}
                      withEmpty
                    />
                  </div>
                  <Input
                    disabled={!isEditable}
                    label="Plate"
                    className="AdminVehicleDetails-input AdminVehicleDetails-inputLicencePlate"
                    placeholder="Plate"
                    onChange={this.handlePlateChange}
                    value={plate}
                    name={'licensePlate'}
                  />
                  <div className="AdminVehicleDetails-dropdownContainer AdminVehicleDetails-expirationMonth">
                    <p className="AdminVehicleDetails-dropdownLabel">Expiration Month</p>
                    <SearchableStaticSelect
                      isSearchable={false}
                      disabled={!isEditable}
                      value={{
                        label: licenseExpirationMonth === 'blank' ? '-' : licenseExpirationMonth,
                        value: licenseExpirationMonth,
                      }}
                      values={monthOptions}
                      handleChange={this.handleUpdateLicenseExpirationMonth}
                    />
                  </div>
                  <div className="AdminVehicleDetails-dropdownContainer AdminVehicleDetails-expirationYear">
                    <p className="AdminVehicleDetails-dropdownLabel">Expiration Year</p>
                    <SearchableStaticSelect
                      isSearchable={false}
                      disabled={!isEditable}
                      value={{
                        label: licenseExpirationYear === 'blank' ? '-' : licenseExpirationYear,
                        value: licenseExpirationYear,
                      }}
                      values={yearsOptions}
                      handleChange={this.handleUpdateLicenseExpirationYear}
                    />
                  </div>
                </div>
              </div>
              <UserAccessLevelComponent requiredAccessLevel={[WEB_MANAGEMENT_VEHICLE_FUEL_DETAILS_READ]}>
                <div className="AdminVehicleDetails-dataRow">
                  <p className="AdminVehicleDetails-label">Fuel Information</p>
                  <div className="AdminVehicleDetails-dataRowContent">
                    <div className="AdminVehicleDetails-dropdownContainer AdminVehicleDetails-fuelType">
                      <p className="AdminVehicleDetails-dropdownLabel">Fuel Type</p>
                      <FuelTypeSelect
                        disabled={!isFuelEditable}
                        fuelType={fuelDetails.fuelType}
                        onChange={this.handleFuelTypeChange}
                        options={fuelTypeOptions.items}
                      />
                    </div>
                    <div className="AdminVehicleDetails-tankCapacity">
                      <p className="AdminVehicleDetails-tankCapacityLabel">Tank Capacity</p>
                      <div className="AdminVehicleDetails-tankCapacityInput">
                        <TankCapacityInput
                          controls={false}
                          disabled={!isFuelEditable}
                          label=""
                          onChange={this.handleTankCapacityChange}
                          placeholder="Tank"
                          tankCapacity={fuelDetails.tankCapacity}
                        />
                        <span className="AdminVehicleDetails-tankCapacityInputInfo">Gal</span>
                      </div>
                    </div>
                    <div className="AdminVehicleDetails-mpg">
                      <div className="AdminVehicleDetails-mpgTitle">
                        <p className="AdminVehicleDetails-label AdminVehicleDetails-label--mpg">MPG</p>
                        <div className="AdminVehicleDetails-mpgInfo">
                          <InfoTooltip>
                            MPG calculated using tank percentage and capacity and may not be precise.
                          </InfoTooltip>
                        </div>
                      </div>
                      <div className="AdminVehicleDetails-mpgInput">
                        <div className="AdminVehicleDetails-mpgInputField">
                          <Input
                            disabled
                            className="AdminVehicleDetails-input AdminVehicleDetails-input--mpg"
                            placeholder="MPG"
                            value={milesPerGallon?.toString()}
                            name="mpg"
                          />
                        </div>
                        <UserAccessLevelComponent requiredAccessLevel={[ACL.MANAGEMENT.RESET_MPG.DELETE]}>
                          <div className="AdminVehicleDetails-mpgInputReset">
                            <Button className="Button--link" title="Reset" onClick={this.openResetMpgModal} />
                          </div>
                        </UserAccessLevelComponent>
                      </div>
                    </div>
                  </div>
                </div>
              </UserAccessLevelComponent>
              <div className="AdminVehicleDetails-dataRow">
                <p className="AdminVehicleDetails-label">Group Membership</p>
                <div className="AdminVehicleDetails-dataRowContent">
                  <GroupMembership disabled={!isEditable} onChange={this.handleGroupsChange} />
                </div>
              </div>
            </Col>
            <Col className="AdminVehicleDetails-rightCol" span={8}>
              <UserAccessLevelComponent requiredAccessLevel={[VEHICLE_DRIVER_NAME_READ]}>
                <div className="AdminVehicleDetails-dataRow">
                  <p className="AdminVehicleDetails-label">Driver Information</p>
                  <div className="AdminVehicleDetails-dataRowContent">
                    <div className="AdminVehicleDetails-DriverLabel">Current Driver</div>
                    <div className="AdminVehicleDetails-DriverSelect">
                      <SearchableDriverSelect
                        noAll
                        disabled={!isDriverNameEditable}
                        handleChange={this.handleDriverChange}
                        placeholder={`No Driver Assigned`}
                        value={driverId ? { label: driverName, value: driverId } : undefined}
                        valueSource="driverFobId"
                      />
                    </div>
                    {driverId && isDriverNameEditable && (
                      <Button className="Button--link" title={'Remove Driver'} onClick={this.handleDriverRemove} />
                    )}
                  </div>
                </div>
              </UserAccessLevelComponent>
              <div className="AdminVehicleDetails-dataRow">
                <p className="AdminVehicleDetails-label">Vehicle note</p>
                <div className="AdminVehicleDetails-note">
                  <Note
                    countCharactersEntered
                    hideControls
                    isEditing
                    note={note}
                    onChange={this.handleVehicleNoteChange}
                    disabled={!isNoteEditable}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <ResetMetricsModal
          title="Select MPG calculation start date"
          onCancel={this.cancelResetMpg}
          onSubmit={this.resetMpg}
          isOpen={resetMpgModalOpen}
          onChange={resetMpgTime.set}
          value={resetMpgTime.value}
        />
      </div>
    );
  }
}

export default AdminVehicleDetails;
