import { action, observable, reaction } from 'mobx';
import type { IFuelDetailsId, IFuelDetailsIds } from 'interfaces/stores/Assets/IFuelDetails';
import type { IWEXCard, IWEXCardServer } from 'interfaces/stores/Integrations/IWEXCardAssociations';
import FuelDetailsIds from 'models/Assets/FuelDetails/FuelDetailsIds';
import { SimpleField } from 'models/Fields';
import type FuelCards from './FuelCards';
import type { IWEXIntegrationModel } from 'interfaces/stores/Integrations/IIntegration';
import WEXIntegrationModel from 'stores/Integrations/WEXIntegration';

class WEXCard implements IWEXCard {
  @observable asset: IFuelDetailsId;
  assetId: SimpleField<string>;
  cardUniqueCode: string;
  name: string;
  driverName: string;
  id: number;
  provider: string;
  fuelDetails: IFuelDetailsIds;
  integration: IWEXIntegrationModel;
  context: FuelCards;

  constructor(item: IWEXCardServer, asset: IFuelDetailsId) {
    this.assetId = new SimpleField(item.assetId ? item.assetId.toString() : '0');
    this.fuelDetails = new FuelDetailsIds();
    this.integration = new WEXIntegrationModel(item.integration || {});

    this.setModel(item, asset);

    reaction(
      () => this.assetId.value,
      (id) => {
        const asset = this.fuelDetails.getOptionById(id);

        this.context.assets.addOption({
          assetId: parseInt(asset.assetId, 10),
          displayName: asset.displayName,
          cpNumber: asset.cpNumber,
          id: asset.id,
          tankCapacity: asset.tankCapacity.value,
          fuelType: asset.fuelType.value,
        });

        this.setAsset(this.context.assets.getOptionById(asset.assetId));
      }
    );
  }

  initialize = (context: FuelCards): IWEXCard => {
    this.context = context;
    return this;
  };

  @action setModel = (item: IWEXCardServer, asset: IFuelDetailsId) => {
    this.asset = asset;
    this.cardUniqueCode = item.cardUniqueCode;
    this.name = item.name;
    this.driverName = item.driverName;
    this.id = item.id;
    this.provider = item.provider;
    this.assetId.set(item.assetId ? item.assetId.toString() : '0');
  };

  @action setAsset = (asset: IFuelDetailsId) => {
    this.asset = asset;
  };

  removeCardAssociation = async () => {
    await this.updateFuelCard(null);
    this.context.onRemove(this);
  };

  private updateFuelCard = async (assetId: number) => {
    return this.context.repositoryFuelCards.patch({
      provider: this.provider,
      assetId,
      cardUniqueCode: this.cardUniqueCode,
      name: this.name,
      id: this.id,
    });
  };
}

export default WEXCard;
