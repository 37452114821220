import MaintenanceBaseTracker from './Base';
import ServiceType from '../Service/ServiceType';

class MaintenanceServiceTracker extends MaintenanceBaseTracker {
  timeDueInDays: number;
  type: ServiceType;

  constructor({ serviceTimeDueInDays = 0, serviceTimeConfig = {}, serviceType = { id: null, name: '' } }) {
    super({ config: serviceTimeConfig });

    this.timeDueInDays = serviceTimeDueInDays;
    this.type = new ServiceType(serviceType.id ? serviceType.id.toString() : null, serviceType.name);
  }
}

export default MaintenanceServiceTracker;
