import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { RECIPIENT_TYPE, SCHEDULED_REPORTS_TYPE_MODAL } from 'config';
import type { IScheduledReport } from 'models/ScheduledReport/IScheduledReport';
import type { ReportsStore, ScheduledReportsStore } from 'stores';

import Button from 'components/Button';
import Modal from 'components/Modal';
import ScheduledReportsOptions from 'components/Reports/ScheduledReportModal/ScheduledReportOptions';
import TableRecipientGroup from 'components/Table/TableRecipientGroup';
import TableDropDownDownloadFormat from 'components/Table/TableDropDownDownloadFormat';
import ScheduledReportModalName from 'components/Reports/ScheduledReportModal/ScheduledReportModalName';
import ScheduledReportModalInterval from 'components/Reports/ScheduledReportModal/ScheduledReportModalInterval';
import Notification from 'components/Notification';

import './styles.scss';

interface IProps {
  isOpen: boolean;
  title: string;
  type: SCHEDULED_REPORTS_TYPE_MODAL;
  onClose: () => void;
  scheduledReport: IScheduledReport;
  scheduledReportsStore?: ScheduledReportsStore;
  reportsStore?: ReportsStore;
  locked?: boolean;
}

interface IState {
  hitNext: boolean;
  sending: boolean;
}

@inject(({ reportsStore, scheduledReportsStore }) => ({ reportsStore, scheduledReportsStore }))
@observer
class ScheduledReportModal extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      hitNext: false,
      sending: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { scheduledReport } = this.props;

    if (prevProps.scheduledReport !== scheduledReport) {
      if (scheduledReport && !scheduledReport.recipients.value.length) {
        scheduledReport.recipients.add('');
      }
    }
  }

  cancelHitNext = () => {
    this.setState({ hitNext: false });
  };

  handleClose = () => {
    const { onClose } = this.props;

    onClose();
    this.setState({ hitNext: false });
  };

  handleSubmit = async () => {
    const {
      type,
      onClose,
      reportsStore: {
        selectedReport: { reportPreferences, title },
        selectedReportId,
        excludeInactive,
      },
      scheduledReport,
      scheduledReport: { appliesTo, reportParameters, create, update },
    } = this.props;

    this.setState({ hitNext: true });

    if (scheduledReport.isValid) {
      appliesTo.set(reportPreferences.group.model, reportPreferences.device.model);

      reportParameters.driver.set(
        reportPreferences.driver.id.value?.split('|')[1] || null,
        reportPreferences.driver.displayName.value
      );
      reportParameters.geozone.set(reportPreferences.geozone.id.value, reportPreferences.geozone.displayName.value);
      reportParameters.hideInactive.toggle(excludeInactive);
      reportParameters.option.set(reportPreferences.option.id.value, reportPreferences.option.displayName.value);
      reportParameters.report.set(selectedReportId, title);
      reportParameters.tag.set(reportPreferences.tag.id.value, reportPreferences.tag.displayName.value);
      this.setState({ sending: true });

      if (type === SCHEDULED_REPORTS_TYPE_MODAL.CREATE || type === SCHEDULED_REPORTS_TYPE_MODAL.DUPLICATE) {
        await create();
      } else if (type === SCHEDULED_REPORTS_TYPE_MODAL.EDIT) {
        await update();
      }

      onClose();
      this.setState({ sending: false, hitNext: false });
    }
  };

  render() {
    const {
      isOpen,
      scheduledReport,
      title,
      locked,
      scheduledReportsStore: { isCreated, isCreatedError, isUpdated, isUpdatedError, isDeleted, isDeletedError },
    } = this.props;
    const { hitNext, sending } = this.state;

    return (
      <>
        <Modal isModalOpen={isOpen} onClose={this.handleClose} className="ScheduledReportModal">
          {scheduledReport && (
            <div className="ScheduledReportModal-modal">
              <div className="ScheduledReportModal-header">
                <h3 className="ScheduledReportModal-title">{title}</h3>
              </div>
              <div className="ScheduledReportModal-body">
                <ScheduledReportsOptions
                  list={this.props.reportsStore.listAllReportsSorted}
                  selectedReport={this.props.reportsStore.selectedReport}
                  selectedReportId={this.props.reportsStore.selectedReportId}
                  onSelectReportsGroup={(id: string) => {
                    const reportId = this.props.reportsStore.getReportSubGroupDefaultReport(id);
                    return this.props.reportsStore.setSelectedReportId(reportId(''));
                  }}
                  onSelectReport={(id: string) => {
                    return this.props.reportsStore.setSelectedReportId(id);
                  }}
                  locked={locked}
                />
                <ScheduledReportModalName name={scheduledReport.scheduledReportName} />
                <ScheduledReportModalInterval interval={scheduledReport.interval} />
                <TableDropDownDownloadFormat
                  format={scheduledReport.format.id}
                  className="ScheduledReportModal-format"
                />
                <TableRecipientGroup
                  items={scheduledReport.recipients}
                  className="ScheduledReportModal-emailList"
                  title="Send report to"
                  linkTitle="+ Add Another Email Address"
                  type={RECIPIENT_TYPE.EMAIL}
                  hitNext={hitNext}
                  cancelHitNextNotify={this.cancelHitNext}
                  isCharacterLimitReached={scheduledReport.isCharacterLimitReached}
                  characterLimitLeft={scheduledReport.characterLimitLeft}
                  characterLimitText="Max character limit reached. Create another Scheduled Report for additional contacts."
                  isEmailFocus={false}
                  isAllItemsEmpty={!scheduledReport.recipients.isItemsSomeNotEmpty}
                />
              </div>
              <div className="ScheduledReportModal-footer">
                <div className="ScheduledReportModal-button--cancel">
                  <Button onClick={this.handleClose} title="Cancel" inline />
                </div>

                <div className="ScheduledReportModal-button--save">
                  <Button onClick={this.handleSubmit} title="Save" sending={sending} inline />
                </div>
              </div>
            </div>
          )}
        </Modal>
        {isCreated.value && (
          <Notification
            onClose={() => isCreated.toggle(false)}
            text="Report has been scheduled."
            title="Success!"
            type="success"
          />
        )}
        {isCreatedError.value && (
          <Notification
            onClose={() => isCreatedError.toggle(false)}
            text="Report failed to schedule. Please try again."
            title="Scheduling Failure!"
            type="error"
          />
        )}
        {isUpdated.value && (
          <Notification
            onClose={() => isUpdated.toggle(false)}
            text="Report has been scheduled."
            title="Success!"
            type="success"
          />
        )}
        {isUpdatedError.value && (
          <Notification
            onClose={() => isUpdatedError.toggle(false)}
            text="Report failed to schedule. Please try again."
            title="Scheduling Failure!"
            type="error"
          />
        )}
        {isDeleted.value && (
          <Notification
            onClose={() => isDeleted.toggle(false)}
            text="The Scheduled Report was successfully deleted."
            title="Success!"
            type="success"
          />
        )}
        {isDeletedError.value && (
          <Notification
            onClose={() => isDeletedError.toggle(false)}
            text="An error occurred while deleting the report. Please try again."
            title="Delete Failed!"
            type="error"
          />
        )}
      </>
    );
  }
}

export default ScheduledReportModal;
