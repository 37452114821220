import EntityRepository from './EntityRepository';
import RepositoryBase from './RepositoryBase';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

class Repository extends RepositoryBase implements IRepository {
  public entity = (id: string): IEntityRepository => {
    const url = id ? `${this.url}/${id}` : this.url;

    return new EntityRepository(url);
  };
}

export default Repository;
