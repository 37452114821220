import { computed } from 'mobx';
import { ALERTS_APPLIES_TO, ALERTS_TYPE } from 'config';
import isNumber from 'lodash/isNumber';
import AlertWithIntervalModel from './AlertWithInterval';
import { SimpleField, ToggleField } from 'models/Fields';
import DueValue from './MaintenanceAlert/DueValue';
import KeyValueField from '../Fields/KeyValueField';
import MaintenanceAlertSpecificTaskModel from './MaintenanceAlert/MaintenanceAlertSpecificTaskModel';
import MaintenanceAlertSpecificTypeModel from './MaintenanceAlert/MaintenanceAlertSpecificTypeModel';

const WHEN_DEFAULT = 0;
const DUE_VALUE_DEFAULT = {
  miles: 500,
  hours: 10,
  days: 10,
  avgDaysBefore: 10,
};

class MaintenanceAlertModel extends AlertWithIntervalModel {
  dueValue: DueValue;
  basedOn: SimpleField<Alerts.Modal.Maintenance.BasedOn>;
  when: SimpleField<Alerts.Modal.Maintenance.When>;
  basedTask: KeyValueField<string, string>;
  specificTask: MaintenanceAlertSpecificTaskModel;
  specificType: MaintenanceAlertSpecificTypeModel;

  includeCurrentVehicleMetrics: ToggleField;
  includeTaskNotes: ToggleField;

  constructor(item) {
    super({
      ...item,
      appliesTo: ALERTS_APPLIES_TO.VEHICLE,
      isIntervalActive: isNumber(item.interval) ? Boolean(item.interval) : true,
      interval: isNumber(item.interval) ? item.interval : 24 * 60 * 60 * 1000,
    });

    this.intervalFields = ['weeks', 'days'];
    this.title = 'Maintenance Due';
    this.urlType = 'maintenance';
    this.description = 'Receive an Alert regarding upcoming Maintenance Tasks.';
    this.type.reInitialize(ALERTS_TYPE.MAINTENANCE);
    this.basedOn = new SimpleField<Alerts.Modal.Maintenance.BasedOn>(
      item.specificTaskConfig?.type !== undefined || !item.id ? 'TASK' : 'TYPE'
    );
    this.dueValue = new DueValue(DUE_VALUE_DEFAULT);
    this.when = new SimpleField<Alerts.Modal.Maintenance.When>(WHEN_DEFAULT);
    this.specificTask = new MaintenanceAlertSpecificTaskModel({
      when: this.when,
      dueValue: this.dueValue,
      config: item.specificTaskConfig,
    });
    this.specificType = new MaintenanceAlertSpecificTypeModel({
      when: this.when,
      dueValue: this.dueValue,
      config: item.serviceTypeConfig,
    });

    this.basedTask = new KeyValueField<string, string>(null, null);
    this.includeCurrentVehicleMetrics = new ToggleField(
      Boolean(item.includeCurrentVehicleMetrics !== undefined ? item.includeCurrentVehicleMetrics : true)
    );
    this.includeTaskNotes = new ToggleField(Boolean(item.includeTaskNote !== undefined ? item.includeTaskNote : false));
  }

  get model() {
    return {
      ...super.model,
      serviceTypeConfig:
        this.basedOn.value === 'TYPE' || this.metadata.appliesTo.value === ALERTS_APPLIES_TO.GROUP
          ? this.specificType.model
          : null,
      specificTaskConfig:
        this.basedOn.value === 'TASK' && this.metadata.appliesTo.value === ALERTS_APPLIES_TO.VEHICLE
          ? this.specificTask.model
          : null,
      includeCurrentVehicleMetrics: this.includeCurrentVehicleMetrics.value,
      includeTaskNote: this.includeTaskNotes.value,
    };
  }

  isUpdated(): boolean {
    return super.isUpdated() || this.basedTask.isUpdated || this.dueValue.isUpdated;
  }

  @computed get text() {
    let basedOn = '';
    let when = '';
    const messageItems = [];

    if (this.basedOn.value === 'TASK' && this.metadata.appliesTo.value === ALERTS_APPLIES_TO.VEHICLE) {
      basedOn = this.specificTask.text.basedOn;
      when = this.specificTask.text.when;
    } else {
      basedOn = this.specificType.text.basedOn;
      when = this.specificType.text.when;
    }

    if (this.when.value === 0) {
      when = 'Task is due';
    }

    if (this.includeCurrentVehicleMetrics.value) {
      messageItems.push('Include Current Vehicle Metrics');
    }
    if (this.includeTaskNotes.value) {
      messageItems.push('Include Task Note');
    }

    return {
      basedOn,
      when,
      message: messageItems.length === 0 ? 'None' : messageItems.join(', '),
    };
  }
}

export default MaintenanceAlertModel;
