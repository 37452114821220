import React, { useState } from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import Input from 'components/Input';
import type { UniqueField } from 'models/Fields';
import { getValidName, validateName } from 'utils';

import './styles.scss';

interface IProps {
  name: UniqueField;
}

const MAX_NAME_LENGTH = 128;

const ScheduledReportModalName: React.FC<IProps> = ({ name }) => {
  const [isValid, setIsValid] = useState(true);

  const handleChange = (e) => {
    const allowedName = getValidName(e.currentTarget.value);

    if (allowedName.length <= MAX_NAME_LENGTH) {
      name.set(allowedName);
      setIsValid(validateName(allowedName));
    }
  };

  const handleBlur = () => {
    name.setTouched(true);
  };

  const handleFocus = () => {
    name.setTouched(true);
  };

  return (
    <Observer
      render={() => {
        const isTouched = name.isValidated && name.touched;
        const isEmpty = isTouched && name.isEmpty && isValid;
        const isNotUnique = isTouched && !name.isValid && !isEmpty && isValid;

        const inputCn = classNames('ScheduledReportModalName-Input', {
          'ScheduledReportModalName-Input--error': isEmpty || isNotUnique || !isValid,
        });

        return (
          <div className="ScheduledReportModalName">
            <p className="ScheduledReportModalName-title">Scheduled report name</p>
            <div className="ScheduledReportModalName-value">
              <Input
                value={name.value}
                onChange={handleChange}
                className={inputCn}
                onBlur={handleBlur}
                onFocus={handleFocus}
                isFocus
              />
              {!isValid && <p className="ScheduledReportModalName-error">Scheduled report name is invalid</p>}
              {isNotUnique && <p className="ScheduledReportModalName-error">Scheduled report name already exists</p>}
              {isEmpty && <p className="ScheduledReportModalName-error">Scheduled report name is a required field</p>}
            </div>
          </div>
        );
      }}
    />
  );
};

export default ScheduledReportModalName;
