import { ALERTS_APPLIES_TO, ALERTS_TYPE } from 'config';
import AlertModel from './Alert';

class PowerDisconnectDashCamAlertModel extends AlertModel {
  constructor(item) {
    super(item);

    this.urlType = 'dashcam-offline';
    this.description = 'Alerts when any vehicle’s dashcam has lost power.';
    this.title = 'Power Disconnect (Dashcam)';
    this.type.reInitialize(ALERTS_TYPE.POWER_DISCONNECT_DASHCAM);
    this.metadata.appliesTo.set(ALERTS_APPLIES_TO.DASHCAM);
  }
}

export default PowerDisconnectDashCamAlertModel;
