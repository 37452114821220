import React, { Component } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import find from 'lodash/find';
import moment from 'moment';
import { MenuOutlined } from '@ant-design/icons';

import Actions from 'components/Dispatch/Actions';
import AssignedVehicle from '../AssignedVehicle';
import Button from 'components/Button';
import DispatchDriversTable from 'components/Dispatch/DispatchTable';
import DispatchLocation from 'components/Dispatch/DispatchLocation';
import DispatchOrder from 'components/Dispatch/DispatchOrder';
import RadioGroup from 'components/RadioGroup';
import { DISPATCH_STORE, DATE_TIME_FORMATS } from 'config';
import type { DispatchStore } from 'stores';
import { isToday, pluralize } from 'utils';

import './styles.scss';

const dispatchesModeOptions = [
  {
    id: 'dispatchesModeOn',
    label: 'Current',
    name: 'dispatchesMode',
    value: 'current',
  },
  {
    id: 'dispatchesModeOff',
    label: 'Historical',
    name: 'dispatchesMode',
    value: 'historical',
  },
];

interface IProps extends RouteComponentProps {
  className?: string;
  [DISPATCH_STORE]?: DispatchStore;
}

@inject(DISPATCH_STORE)
@observer
class DispatchesTable extends Component<IProps> {
  componentDidMount() {
    const {
      dispatchStore: { getAllVehicles },
    } = this.props;

    getAllVehicles();
  }

  handleEditDispatch = (dispatchId: number) => {
    const {
      history: { push },
    } = this.props;

    push(`?dispatchModal=edit&dispatchId=${dispatchId}`);
  };

  render() {
    const {
      [DISPATCH_STORE]: {
        tableFormatDispatches,
        moveDispatch,
        repositoryDispatch: { getState },
        deleteDispatch,
        markCompleted,
        vehiclesList,
        repositoryDevices,
        dispatchesMode,
        toggleDispatchesMode,
      },
      history: { push },
    } = this.props;

    const dispatchColumns = [
      { title: '', key: 'sort', width: 76, render: () => <MenuOutlined style={{ cursor: 'move', color: '#999' }} /> },
      {
        title: 'ORDER',
        dataIndex: 'order',
        key: 'order',
        width: 100,
        render: (order, { status, isNext }) => (
          <DispatchOrder order={order} isCompleted={status.toLowerCase() === 'completed'} isFirst={isNext} />
        ),
      },
      {
        title: 'LOCATION',
        dataIndex: 'address',
        key: 'address',
        width: 300,
        ellipsis: true,
        render: (address, { status, isNext }) => (
          <DispatchLocation location={address} isCompleted={status.toLowerCase() === 'completed'} isFirst={isNext} />
        ),
      },
      {
        title: 'NOTES',
        dataIndex: 'notes',
        key: 'notes',
        ellipsis: true,
        render: (note, { fieldNotes }) =>
          Boolean(note) && (
            <div className="DispatchesTable-notesCell">
              <div className="DispatchesTable-notesCellRow">
                <div className="DispatchesTable-notesCellLabel">dispatch</div>
                <div className="DispatchesTable-notesCellText">
                  <span>{note}</span>
                </div>
              </div>
              {Boolean(fieldNotes) && (
                <div className="DispatchesTable-notesCellRow">
                  <div className="DispatchesTable-notesCellLabel DispatchesTable-notesCellLabel--blue">field</div>
                  <div className="DispatchesTable-notesCellText">
                    <span>{fieldNotes}</span>
                  </div>
                </div>
              )}
            </div>
          ),
      },
      {
        title: 'STATUS',
        key: 'status',
        width: 120,
        render: ({ status, completeTime }) =>
          Boolean(status) && (
            <div className="DispatchesTable-statusCell">
              <div className="DispatchesTable-statusCellText">{status}</div>
              <div className="DispatchesTable-statusCellTime">{completeTime}</div>
            </div>
          ),
      },
      {
        title: 'ACTIONS',
        dataIndex: 'operation',
        key: 'operation',
        width: 90,
        render: (_, row) => (
          <div className="table-operation">
            <Actions
              isCompleted={row.status.toLowerCase() === 'completed'}
              dispatchId={row.dispatchId}
              onComplete={markCompleted}
              onDelete={deleteDispatch}
              onEdit={this.handleEditDispatch}
              canEdit={dispatchesMode === 'current'}
            />
          </div>
        ),
      },
    ];

    const dateColumns = [
      {
        dataIndex: 'date',
        key: 'date',
        render: (date) => {
          const currentDate = moment(date).valueOf();
          const shouldAddToday = isToday(currentDate);
          const formattedDate = moment(currentDate).format(DATE_TIME_FORMATS.dispatchFormatDate);
          return (
            <div className="DispatchesTable-dateDispatchLabel">
              {shouldAddToday && <span className="DispatchesTable-dateDispatchLabelToday">today </span>}
              <span>{formattedDate}</span>
            </div>
          );
        },
      },
      {
        dataIndex: 'dispatchesLength',
        key: 'dispatchesLength',
        render: (dispatchesLength) => (
          <div className="DispatchesTable-dateDispatchAmount">
            <span>{pluralize(dispatchesLength, 'dispatch', 'es')}</span>
          </div>
        ),
      },
    ];

    const driverColumns = [
      { dataIndex: 'name', key: 'name' },
      {
        title: 'Action',
        key: 'operation',
        render: ({ driverId, name }) => {
          const assignedVehicle = find(vehiclesList, { driverId }, null);

          return <AssignedVehicle assignedVehicle={assignedVehicle} driver={{ id: driverId, name }} rightAligned />;
        },
      },
    ];

    return (
      <div className="DispatchesTable">
        <div className="DispatchesTable-container">
          <div className="DispatchesTable-controls">
            <div className="DispatchesTable-modeToggle">
              <RadioGroup
                checkedValue={dispatchesMode}
                onChange={toggleDispatchesMode}
                radios={dispatchesModeOptions}
                tabs
              />
            </div>
            <div className="DispatchesTable-sendButton">
              <Button
                className="Button--apply"
                disabled={false}
                inline
                onClick={() => {
                  push('?dispatchModal=send');
                }}
                title="New Dispatch"
              />
            </div>
          </div>
          {repositoryDevices.getState.success && (
            <DispatchDriversTable
              dispatchColumns={dispatchColumns}
              dateColumns={dateColumns}
              driverColumns={driverColumns}
              data={tableFormatDispatches}
              moveRow={moveDispatch}
              loading={getState.loading}
            />
          )}
        </div>
      </div>
    );
  }
}

export default DispatchesTable;
