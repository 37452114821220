import type ICustomerJob from 'interfaces/stores/Customers/ICustomerJob';
import type ICustomerAddress from '../../interfaces/stores/Customers/ICustomerAddress';
import type ICustomerInvoice from '../../interfaces/stores/Customers/ICustomerInvoice';
import type { IntegrationDomain } from '../../interfaces/stores/Integrations/IIntegration';
import type ICustomerEstimate from '../../interfaces/stores/Customers/ICustomerEstimate';

class CustomerJob implements ICustomerJob {
  technicianNames: string[];
  address: ICustomerAddress;
  jobSiteId: number;
  description: string;
  integrationId: string;
  tax: number;
  transactionDate: number;
  estimatesTotalAmount: number;
  totalAmount: number;
  balance: number;
  invoices: ICustomerInvoice[]
  domain: IntegrationDomain;
  overdueBalance: number;
  customerId: number;
  currency: string;
  id: number;
  estimates: ICustomerEstimate[]
  status: string;
  serviceDate: number;
  name: string;
  customerContacts: {
    name: string;
    email: string;
    phoneNumber: string;
  }

  constructor(props) {
    this.technicianNames = props.technicianNames || [];
    this.address = props.address;
    this.jobSiteId = props.jobSiteId;
    this.description = props.description;
    this.integrationId = props.integrationId;
    this.tax = props.tax;
    this.transactionDate = props.transactionDate;
    this.estimatesTotalAmount = props.estimatesTotalAmount;
    this.totalAmount = props.totalAmount;
    this.balance = props.balance;
    this.invoices = props.invoices || [];
    this.domain = props.domain;
    this.overdueBalance = props.overdueBalance;
    this.customerId = props.customerId;
    this.currency = props.currency;
    this.id = props.id;
    this.estimates = props.estimates || [];
    this.status = props.status;
    this.serviceDate = props.serviceDate;
    this.name = props.name;
    this.customerContacts = props.customerContacts || {};
  }
}

export default CustomerJob;
