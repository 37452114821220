import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router';

import { PATHS, DASHCAM_REQUEST_MEDIA, DASHCAM_VIEW_MEDIA } from 'config';
import validateAccessLevel from 'stores/acl/validator';
import MediaLibrary from './MediaLibrary';
import MediaRequest from './MediaRequest';

interface IProps extends RouteComponentProps {
  className?: string;
}
class DashCams extends Component<IProps> {
  render() {
    const isViewMediaAllowed = validateAccessLevel([DASHCAM_VIEW_MEDIA]);
    const isRequestMediaAllowed = validateAccessLevel([DASHCAM_REQUEST_MEDIA]);

    return (
      <Switch>
        <Redirect exact from={PATHS.DASHCAMS.INDEX} to={PATHS.DASHCAMS.MEDIA_MANAGER} />
        {isViewMediaAllowed && <Route path={PATHS.DASHCAMS.MEDIA_MANAGER} component={MediaLibrary} />}
        {isRequestMediaAllowed && <Route path={PATHS.DASHCAMS.MEDIA_REQUEST.INDEX} component={MediaRequest} />}
        <Redirect from="*" to={PATHS.NOT_FOUND} />
      </Switch>
    );
  }
}

export default withRouter(DashCams);
