import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import type { CustomersStore } from 'stores';
import SecondaryPanelInvoiceQB from './SecondaryPanelInvoiceQB';
import SecondaryPanelInvoiceST from './SecondaryPanelInvoiceST';

import './styles.scss';


interface IMatchParams {
  invoiceId: string;
}

interface IProps extends RouteComponentProps<IMatchParams>{
  customersStore: CustomersStore;
  collapseKeys: string[];

  onCollapseChange(values: string | string[]): void;
}


@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class SecondaryPanelInvoice extends Component<IProps> {

  get selectedCustomer () {
    const {
      customersStore: {
        selectedCustomer,
      },
    } = this.props;
    return selectedCustomer;
  }

  componentDidMount() {
    const {
      match: {
        params: { invoiceId },
      },
    } = this.props;
    this.selectedCustomer?.selectedInvoiceId?.set?.(invoiceId);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { invoiceId },
      },
    } = this.props;
    if (invoiceId !== prevProps?.match?.params?.invoiceId) {
      this.selectedCustomer?.selectedInvoiceId?.set?.(invoiceId);
    }
  }

  componentWillUnmount() {
    this.selectedCustomer?.selectedInvoiceId?.reset?.();
  }

  render() {
    const selectedCustomer = this.selectedCustomer;

    if (!selectedCustomer.selectedInvoice) {
      return null;
    }

    return <>
      {selectedCustomer.domain === 'QUICK_BOOKS' && <SecondaryPanelInvoiceQB />}
      {selectedCustomer.domain === 'SERVICE_TITAN' && <SecondaryPanelInvoiceST />}
    </>;
  }
}

export default withRouter(SecondaryPanelInvoice);
