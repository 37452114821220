import { action } from 'mobx';
import type { IFuelDetailsId, IFuelDetailsIdServer } from 'interfaces/stores/Assets/IFuelDetails';
import { SimpleField } from 'models/Fields';

class FuelDetailsId implements IFuelDetailsId {
  fuelType: SimpleField<string>;
  assetId: string;
  displayName: string;
  cpNumber: string;
  id: string;
  tankCapacity: SimpleField<number>;

  constructor(model: IFuelDetailsIdServer) {
    this.fuelType = new SimpleField(model.fuelType || 'none');
    this.tankCapacity = new SimpleField(model.tankCapacity || 0);
    this.setModel(model);
  }

  @action setModel = ({
    assetId,
    displayName,
    fuelType = '',
    cpNumber = null,
    id = null,
    tankCapacity = null,
  }: IFuelDetailsIdServer) => {
    this.fuelType.set(fuelType || 'none');
    this.assetId = assetId.toString();
    this.displayName = displayName || cpNumber;
    this.cpNumber = cpNumber;
    this.id = id;
    this.tankCapacity.set(tankCapacity || 0);
  };
}

export default FuelDetailsId;
