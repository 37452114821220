import React, { FC, useMemo, useState } from 'react';

import { RangeMinusIcon, RangePlusIcon } from 'assets';
import './styles.scss';

interface IRangeData {
  value: number | string;
  label?: string;
  labelClassName?: string;
}

interface IProps {
  disabled?: boolean;
  controls?: boolean;
  rangeDataList: IRangeData[];
  setValue: (value: number | string) => void;
  selectedValue: string | number;
}

const RangeSelector: FC<IProps> = ({ disabled = false, controls = false, rangeDataList, setValue, selectedValue }) => {
  const getIndex = (range, val) => range.map(({ value }) => value).indexOf(val);

  const [selectorPosition, setSelectorPosition] = useState(getIndex(rangeDataList, selectedValue));

  const handleClick = (index) => {
    if (selectorPosition !== index) {
      const value = rangeDataList[index].value;
      setSelectorPosition(index);
      setValue(value);
    }
  };

  const selectorPositionLeft = useMemo(() => `${(100 / (rangeDataList.length - 1)) * selectorPosition}%`, [
    rangeDataList,
    selectorPosition,
  ]);

  const handleMinusClick = () => {
    const prevIndex = selectorPosition > 1 ? selectorPosition - 1 : 0;
    handleClick(prevIndex);
  };

  const handlePlusClick = () => {
    const nextIndex = selectorPosition + 1 < rangeDataList.length ? selectorPosition + 1 : selectorPosition;
    handleClick(nextIndex);
  };

  return (
    <div className="RangeSelector">
      <div className="RangeSelector-container">
        {disabled && <div className="RangeSelector-overalay" />}
        {controls && (
          <div className="RangeSelector-controls RangeSelector-controls--left" onClick={handleMinusClick}>
            <RangeMinusIcon width={18} height={18} />
          </div>
        )}
        {controls && (
          <div className="RangeSelector-controls RangeSelector-controls--right" onClick={handlePlusClick}>
            <RangePlusIcon width={18} height={18} />
          </div>
        )}
        <div className="RangeSelector-steps">
          {rangeDataList.map(({ label, labelClassName }, index) => {
            const isFirst = index === 0;
            const isLast = index === rangeDataList.length - 1;
            const isCornerItems = isFirst || isLast;
            const baseWidth = 100 / (rangeDataList.length - 1);
            const stepWidth = isCornerItems ? baseWidth + 10 : baseWidth * 2;
            const baseLeft = (100 / (rangeDataList.length - 1)) * index;
            const stepLeft = isFirst ? `calc(${baseLeft}% - 10px)` : `${baseLeft - baseWidth / 2}%`;
            return (
              <div
                key={index}
                className="RangeSelector-step"
                onClick={() => handleClick(index)}
                style={{
                  left: stepLeft,
                  width: `${stepWidth + 1.5}px`,
                }}
              >
                {Boolean(label) && (
                  <div className="RangeSelector-stepLabel">
                    <span className={labelClassName}>{label}</span>
                  </div>
                )}
                <div className="RangeSelector-stepPoint" />
              </div>
            );
          })}
        </div>

        <div
          className="RangeSelector-selector"
          style={{
            left: selectorPositionLeft,
          }}
        />
        <div className="RangeSelector-mainLine" />
      </div>
    </div>
  );
};

export default RangeSelector;
