import React, { useState } from 'react';
import { Observer } from 'mobx-react';

import Input from 'components/Input';
import Button from 'components/Button';
import type MediaInfoItem from 'models/Dashcam/MediaLibrary/MediaClips/MediaClipsItem/MediaInfo/MediaInfoItem';

import './styles.scss';

interface IProps {
  onClose: () => void;
  info: MediaInfoItem;
}

const MediaLibraryClipRename: React.FC<IProps> = ({ onClose, info }) => {
  const [clipName, setClipName] = useState(info.title.display.value);

  const onChangeName = (e) => {
    setClipName(e.target.value);
  };

  const onSubmit = async () => {
    await info.rename(clipName);
    onClose();
  };

  return (
    <Observer
      render={() => (
        <div className="MediaLibraryClipName">
          <div className="MediaLibraryClipName-header">
            <p className="MediaLibraryClipName-headerTitle">Rename Clip</p>
          </div>
          <div className="MediaLibraryClipName-content">
            <label className="MediaLibraryClipName-contentLabel">Clip name</label>
            <Input
              className="MediaLibraryClipName-contentInput"
              placeholder="Clip Name"
              value={clipName}
              onChange={onChangeName}
              error={!/\S+/.test(clipName) ? 'Clip Name is a required field' : ''}
              maxLength={32}
            />
          </div>
          <div className="MediaLibraryClipName-controls">
            <Button
              title="Cancel"
              className="Button--cancel Button--inline MediaLibraryClipName-controlsCancel"
              onClick={onClose}
            />
            <Button
              title="Save"
              disabled={!/\S+/.test(clipName)}
              className="Button--apply Button--inline MediaLibraryClipName-controlsSubmit"
              onClick={onSubmit}
              sending={info.repositoryRename.patchState.loading}
            />
          </div>
        </div>
      )}
    />
  );
};

export default MediaLibraryClipRename;
