import { useState } from 'react';
import { getStorageItem, setStorageItem } from '../utils';

export default function useStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = getStorageItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      setStoredValue(value);
      setStorageItem(key, JSON.stringify(value));
    } catch (_) {
      // silent
    }
  };
  return [storedValue, setValue];
}
