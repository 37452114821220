import React, { useCallback, useState } from 'react';
import classNames from 'classnames';

import StreetViewImage from '../StreetViewImage';

import './styles.scss';

interface IProps {
  address: string;
  latitude: string;
  longitude: string;
  setStreetViewOpened: () => void;
  heading: number;
  showLayer?: boolean;
}

const StreetView: React.FC<IProps> = ({
  latitude,
  longitude,
  heading,
  address,
  setStreetViewOpened,
  showLayer = false,
}) => {
  const [imageError, setImageError] = useState(false);

  const handleOpenStreetView = React.useCallback(() => {
    if (!imageError) {
      setStreetViewOpened();
    }
  }, [imageError]);

  const handleImageLoadingError = useCallback(() => setImageError(true), [setImageError]);
  const handleImageLoadingSuccess = useCallback(() => setImageError(false), [setImageError]);

  const cn = classNames('StreetView', {
    'StreetView-withError': imageError,
  });

  return (
    <div className={cn} onClick={handleOpenStreetView}>
      <StreetViewImage
        className="StreetView-image"
        heading={heading}
        latitude={Number(latitude)}
        longitude={Number(longitude)}
        onAbort={handleImageLoadingError}
        onError={handleImageLoadingError}
        onLoad={handleImageLoadingSuccess}
        width={340}
        height={240}
        alt={address}
      />

      {showLayer && !imageError && (
        <div className="StreetView-layer">
          <p className="StreetView-description">View Full Screen</p>
        </div>
      )}
      <span className="StreetView-errorText">Street View Unavailable</span>
    </div>
  );
};

export default StreetView;
