import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { PATHS } from 'config';

import type { MapStore, CustomersStore } from 'stores';

import withCollapseStorage from 'hocs/withCollapseStorage';

import CloseButton from 'components/Button/CloseButton';
import CustomerDomain from '../../../CustomerDomain';
import SecondaryPanelEstimateCustomerMemo from './SecondaryPanelEstimateCustomerMemo';
import SecondaryPanelEstimateDetails from './SecondaryPanelEstimateDetails';
import SecondaryPanelServiceAddress from './SecondaryPanelServiceAddress';
import SecondaryPanelEstimateCosts from './SecondaryPanelEstimateCosts';

import { Collapse } from 'antd';
import { ChevronIcon } from 'assets';

enum collapsePanels {
  EstimateDetails = 'estimate-details',
  EstimateCosts = 'estimate-costs',
  ServiceAddress = 'service-address',
  Invoices = 'invoices',
  CustomerMemo = 'customer-memo',
}

interface IProps extends RouteComponentProps<null> {
  customersStore: CustomersStore;
  mapStore?: MapStore;
  collapseKeys: string[];

  onCollapseChange(values: string | string[]): void;
}

@inject(({ customersStore, devicesMapStore: { mapStore } }) => ({
  customersStore,
  mapStore,
}))
@observer
class SecondaryPanelEstimateQB extends Component<IProps> {
  get selectedCustomer() {
    const {
      customersStore: { selectedCustomer },
    } = this.props;
    return selectedCustomer;
  }
  handleCloseClick = () => {
    const {
      history: { push },
      customersStore: { selectedCustomerId },
    } = this.props;

    push(PATHS.MAP.CUSTOMER.replace(':customerId', selectedCustomerId));
  };

  render() {
    const { collapseKeys, onCollapseChange } = this.props;

    const selectedCustomer = this.selectedCustomer;

    if (!selectedCustomer.selectedEstimate) {
      return null;
    }

    return (
      <div className="SecondaryPanelJob">
        <div className="SecondaryPanelEstimate-header">
          <div className="SecondaryPanelEstimate-name">
            <div className="SecondaryPanelEstimate-integration">QuickBooks Estimate</div>
            <div className="SecondaryPanelEstimate-basic">
              <div className="SecondaryPanelEstimate-icon">
                <CustomerDomain domain={selectedCustomer.domain} size={24} />
              </div>
              <div className="SecondaryPanelEstimate-info">
                <div className="SecondaryPanelEstimate-id">{selectedCustomer.selectedEstimate.id}</div>
                <div className="SecondaryPanelEstimate-title">{selectedCustomer.selectedEstimate.name}</div>
              </div>
            </div>
          </div>
          <div className="SecondaryPanelEstimate-close">
            <CloseButton onClick={this.handleCloseClick} />
          </div>
        </div>

        <div className="SecondaryPanelJob-content">
          <Collapse
            activeKey={collapseKeys}
            expandIcon={() => (
              <span className="anticon anticon-right ant-collapse-arrow">
                <ChevronIcon />
              </span>
            )}
            expandIconPosition={'left'}
            onChange={onCollapseChange}
          >
            <Collapse.Panel
              header={'Estimate details'}
              key={collapsePanels.EstimateDetails}
              className="SecondaryPanelJob-Block"
            >
              <SecondaryPanelEstimateDetails />
            </Collapse.Panel>
            <Collapse.Panel
              header={'Estimate costs'}
              key={collapsePanels.EstimateCosts}
              className="SecondaryPanelJob-Block"
            >
              <SecondaryPanelEstimateCosts />
            </Collapse.Panel>
            <Collapse.Panel
              header={'Service Address'}
              key={collapsePanels.ServiceAddress}
              className="SecondaryPanelJob-Block"
            >
              <SecondaryPanelServiceAddress />
            </Collapse.Panel>
            <Collapse.Panel header={'Invoices'} key={collapsePanels.Invoices} className="SecondaryPanelJob-Block">
              <div className="MapCustomerPanel-InvoicesList">
                <div className="MapCustomerPanel-InvoicesListHeader">
                  <div>Invoice</div>
                  <div>Date</div>
                  <div>Total</div>
                  <div>Due</div>
                </div>
              </div>
            </Collapse.Panel>
            <Collapse.Panel
              header={'Customer Memo'}
              key={collapsePanels.CustomerMemo}
              className="SecondaryPanelJob-Block"
            >
              <SecondaryPanelEstimateCustomerMemo />
            </Collapse.Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}

export default withRouter(
  withCollapseStorage(
    SecondaryPanelEstimateQB,
    'map-secondary-panel-estimate-qb-collapse',
    Object.values(collapsePanels)
  )
);
