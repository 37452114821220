import React from 'react';
import { Tooltip } from 'antd';
import PanelAction from 'components/SubNav/PanelAction';
import {
  MAP_CREATE_GEOZONE_CREATE,
  MAP_VEHICLE_PANEL_HISTORY_BUTTON,
  MAP_VEHICLE_PANEL_SHOW_TODAYS_TRAIL,
  WEB_DASHCAM_BASIC_READ,
  WEB_MAP_CLEARSHARE_BASIC_CREATE,
  WEB_MAP_TEXT_LOCATION_CREATE,
} from 'config';
import { UserAccessLevelComponent, validateAccessLevel } from 'stores';

import {
  DirectionEventIcon,
  FollowEventIcon,
  HistoryEventIcon,
  TrailEventIcon,
  ZoomInEventIcon,
  AddGeozoneIcon,
  ClearshareIcon,
  PhoneIcon,
  GeozoneCancelIcon,
  CameraWithFlashIcon,
} from 'assets';
import './styles.scss';

interface IProps {
  createGeozone?: boolean;
  name?: string;
  selectedDeviceFollowing: boolean;
  showTrail: boolean;
  showHistory: boolean;
  locked: boolean;
  mediaEnabled: boolean;
  mediaPanelOpen: boolean;
  showTodayTrial: boolean;
  onFollowClick?(): void;
  onDirectionsClick?(): void;
  onZoomInClick?(): void;
  onHistoryClick?(): void;
  onTrailClick?(): void;
  onCreateGeozone?(): void;
  onOpenClearshareLink?(): void;
  onOpenTextLocation?(): void;
  onDaschcamClick?(state: boolean): void;
}

const MapVehiclePanelActions: React.FC<IProps> = ({
  createGeozone,
  selectedDeviceFollowing,
  showTrail,
  mediaEnabled,
  mediaPanelOpen,
  onFollowClick = () => void 0,
  onDirectionsClick = () => void 0,
  onZoomInClick = () => void 0,
  onHistoryClick = () => void 0,
  onTrailClick = () => void 0,
  onCreateGeozone = () => void 0,
  onOpenClearshareLink = () => void 0,
  onOpenTextLocation = () => void 0,
  onDaschcamClick = () => void 0,
  locked = false,
  showHistory = false,
  showTodayTrial = false,
}) => {
  const toggleDashcam = () => {
    onDaschcamClick(!mediaPanelOpen);
  };

  return (
    <div className="MapVehiclePanelActions">
      <div className="MapVehiclePanelActions-action">
        <PanelAction disabled={locked} icon={<ZoomInEventIcon />} onClick={onZoomInClick}>
          Zoom Into
        </PanelAction>
      </div>
      <div className="MapVehiclePanelActions-action">
        <PanelAction icon={<DirectionEventIcon />} onClick={onDirectionsClick}>
          Directions
        </PanelAction>
      </div>
      <div className="MapVehiclePanelActions-action">
        <PanelAction
          disabled={locked || showHistory}
          icon={<FollowEventIcon />}
          onClick={onFollowClick}
          selected={selectedDeviceFollowing}
        >
          Follow
        </PanelAction>
      </div>
      <UserAccessLevelComponent requiredAccessLevel={[MAP_VEHICLE_PANEL_HISTORY_BUTTON]}>
        <div className="MapVehiclePanelActions-action">
          <PanelAction color="orange" icon={<HistoryEventIcon />} onClick={onHistoryClick} selected={showHistory}>
            History
          </PanelAction>
        </div>
      </UserAccessLevelComponent>
      {validateAccessLevel([WEB_DASHCAM_BASIC_READ]) && mediaEnabled ? (
        <div className="MapVehiclePanelActions-action">
          <PanelAction
            color={showHistory ? 'orange' : undefined}
            icon={<CameraWithFlashIcon />}
            onClick={toggleDashcam}
            selected={mediaPanelOpen}
          >
            Dashcam
          </PanelAction>
        </div>
      ) : (
        <div />
      )}
      <UserAccessLevelComponent requiredAccessLevel={[MAP_VEHICLE_PANEL_SHOW_TODAYS_TRAIL]}>
        <>
          {showTodayTrial ? (
            <div className="MapVehiclePanelActions-action">
              <PanelAction
                disabled={showHistory}
                icon={<TrailEventIcon />}
                onClick={onTrailClick}
                selected={showTrail && !showHistory}
              >
                Today’s Trail
              </PanelAction>
            </div>
          ) : (
            <Tooltip
              color="white"
              overlayInnerStyle={{ color: '#2b2e41', fontSize: '12px', fontWeight: 500 }}
              placement="bottomLeft"
              title="This vehicle did not move today"
            >
              <div className="MapVehiclePanelActions-action">
                <PanelAction disabled icon={<TrailEventIcon />}>
                  Today’s Trail
                </PanelAction>
              </div>
            </Tooltip>
          )}
        </>
      </UserAccessLevelComponent>
      <UserAccessLevelComponent requiredAccessLevel={[MAP_CREATE_GEOZONE_CREATE]}>
        <div className="MapVehiclePanelActions-action">
          <PanelAction
            icon={createGeozone ? <GeozoneCancelIcon /> : <AddGeozoneIcon />}
            onClick={onCreateGeozone}
            selected={createGeozone}
          >
            {createGeozone ? 'Cancel' : 'Create Geozone'}
          </PanelAction>
        </div>
      </UserAccessLevelComponent>
      <UserAccessLevelComponent requiredAccessLevel={[WEB_MAP_TEXT_LOCATION_CREATE]}>
        <div className="MapVehiclePanelActions-action">
          <PanelAction icon={<PhoneIcon />} onClick={onOpenTextLocation}>
            <span>
              Text
              <br />
              Location
            </span>
          </PanelAction>
        </div>
      </UserAccessLevelComponent>
      <UserAccessLevelComponent requiredAccessLevel={[WEB_MAP_CLEARSHARE_BASIC_CREATE]}>
        <div className="MapVehiclePanelActions-action">
          <PanelAction icon={<ClearshareIcon />} onClick={onOpenClearshareLink}>
            New ClearShare
          </PanelAction>
        </div>
      </UserAccessLevelComponent>
    </div>
  );
};

export default MapVehiclePanelActions;
