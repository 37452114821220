import { action, observable } from 'mobx';
import { uniqBy, sortBy } from 'lodash';

import type { IFuelDetailsId, IFuelDetailsIds, IFuelDetailsIdServer } from 'interfaces/stores/Assets/IFuelDetails';
import { repositoryService } from 'services';
import type EntityRepository from 'services/RepositoryService/EntityRepository';
import FuelDetailsId from './FuelDetailsId';

class FuelDetailsIds implements IFuelDetailsIds {
  @observable items: IFuelDetailsId[];
  @observable totalPages: number;
  repository: EntityRepository;

  constructor() {
    this.items = [];
    this.repository = repositoryService
      .get('assets')
      .entity('ids')
      .entity('fuel-details');
  }

  @action fetch = async (data, { clear, sortId, sortLabel }) => {
    const response = await this.repository.get(data);
    const options = response.items.map((item) => new FuelDetailsId(item));
    const nextOptions = clear ? [...options] : [...this.items, ...options];
    const uniqueOptions = uniqBy(nextOptions, sortId);
    const sortOptions = sortBy(uniqueOptions, [(option) => option[sortLabel].toLowerCase()]);

    this.items = sortOptions;
    this.totalPages = response.totalPages;
    this.addDefaultOption({ label: 'None', value: '0' }, true);
  };

  @action addDefaultOption = (value: Select.ISelectOption, firstPlace: boolean = false) => {
    const currentOption = this.getOptionById(value.value);

    if (currentOption) {
      const index = this.items.findIndex((item) => item === currentOption);

      if (index) {
        this.items.splice(index, 1);
      }
    }

    if (firstPlace) {
      this.items.unshift({ ...new FuelDetailsId({ displayName: value.label, assetId: parseInt(value.value, 10) }) });
    } else {
      this.addOption({ displayName: value.label, assetId: parseInt(value.value, 10) })
    }
  };

  @action addOption = (option: IFuelDetailsIdServer) => {
    option = option || { assetId: 0, displayName: 'None' };

    const currentOption = this.getOptionById(option.assetId.toString());

    if (!currentOption) {
      this.items.push(new FuelDetailsId(option));
    }
  };

  getOptionById = (assetId: string): IFuelDetailsId => {
    return this.items.find((item) => item.assetId === assetId);
  };

  cancel = () => {
    this.items.forEach((item) => {
      item.fuelType.reset();
      item.tankCapacity.reset();
    });
  };

  resetInitials = () => {
    this.items.forEach((item) => {
      item.tankCapacity.initialValue = item.tankCapacity.value;
      item.fuelType.initialValue = item.fuelType.value;
    });
  };
}

export default FuelDetailsIds;
