import numeral from 'numeral';

/**
 * @name hashCode
 * @param {string} s - string from which to generate hash
 * @returns {number} - hash value
 */
export function hashCode(s: string): number {
  const str = String(s);
  let hash = 0;
  let char;
  if (str.trim().length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char; // tslint:disable-line
    // Convert to 32bit integer
    hash &= hash; // tslint:disable-line
  }
  return Math.abs(hash);
}

/**
 * get initials from string(full name)
 * @name getInitials
 * @param {string} name - name to get initials
 * @returns {string} two first letters from 2 words for english and 1 for non-english
 * @example
 * getInitials('Homer Simpson') // => HS
 * getInitials('Homer') // => H
 */
export function getInitials(name: string): string {
  const nameArr = name.split(' ').slice(0, 2);
  const formatted = nameArr.map((word) => word[0]).join('');
  // display only one letter for non-english because sybols might be wider than expected
  if (!formatted.match(/[a-zA-z1-9]/)) {
    return formatted[0];
  }
  return formatted;
}

export const parseJwt = (token: string) => {
  if (typeof token !== 'string') {
    return;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  try {
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (_) {
    return;
  }
};

export const stripHtml = (html: string): string => {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;

  return tmp.innerText || tmp.textContent || '';
};

export const capitalize = (s: string): string => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const replaceParams = (str: string, obj: any): string => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      str = str.replace(`{${key}}`, obj[key]);
    }
  }
  return str;
};

export const numberWithCommas = (val: string | number = ''): string => {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const element = document.createElement('canvas');
export const getSVGTextWidth = (txt, font) => {
  const context = element.getContext('2d');
  context.font = font;
  return context.measureText(txt).width;
};

export const sortByAlphabet = (a, b) => String(a).localeCompare(String(b));

export const formatNumber = (num: number): string => numeral(Math.abs(num)).format('0,0');

export const formatDecimalNumber = (num: number, decimals: number = 1): string =>
  numeral(Math.abs(num)).format(`0,0[.]${'0'.repeat(decimals)}`);

export const getNumber = (str: string): number => numeral(str).value();

export const getTimeFromTimestampDiff = (timestamp: number) => numeral(timestamp).format('00:00:00');

export const splitAddressStringIntoTwoLines = (str: string) => {
  const details = /^(.+?),(.+)/.exec(str);
  if (details && details.length === 3) {
    return [details[1], details[2]];
  }
  return [undefined, str];
};

export const isValidDate = (str: string): boolean => {
  const regex = /\b(0?[1-9]|1[012])([\/\-])(0?[1-9]|[12]\d|3[01])\2(\d{4})/;
  return regex.test(str);
};

export function removeDoubleSpaces(inputString: string): string {
  return inputString.replace(/\s{2,}/g, ' ');
}

export function extractTextFromHtml(htmlString: string, strict: boolean = false): string {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(`<div id='root'>${htmlString}</div>`, 'text/html');
    const rows = doc.querySelectorAll(
      '#root > div > div:not([style*="display: none"]), #root > div > p:not([style*="display: none"])'
    );
    let result = '';
    rows.forEach((row) => {
      result += row.textContent + ' ';
    });
    result = removeDoubleSpaces(result).trim();
    if (!result && strict) {
      return htmlString;
    }
    return result;
  } catch (_) {
    return htmlString;
  }
}
