import React, { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { CloseIcon, SearchIcon } from 'assets';
import './styles.scss';

interface IProps {
  children: any;
  className?: string;
  inline?: boolean;
  list: any[];
  noResultsMessage?: string;
  onBlur?: () => void;
  onSearchChange?: (value: string) => void;
  placeholder?: string;
  searchValue?: string;
}

const setFocus = (element) => {
  element.selectionStart = element.selectionEnd = element.value.length;
  element.focus();
};

const SearchList: FC<IProps> = ({
  children,
  className,
  inline,
  list,
  noResultsMessage,
  onBlur = () => true,
  onSearchChange = () => true,
  placeholder,
  searchValue,
}) => {
  const inputRef: React.RefObject<HTMLInputElement> = useRef(null);
  const cn = classNames('SearchList', className, {
    'SearchList--inline': Boolean(inline),
  });

  useEffect(() => {
    const input = inputRef.current;
    setFocus(input);
  }, []);

  return (
    <div className={cn}>
      <div className="SearchList-inputField">
        <div className="SearchList-searchIcon">
          <SearchIcon className="SearchList-searchIconImg" width="14px" height="14px" />
        </div>
        <input
          className="SearchList-input"
          onBlur={onBlur}
          onChange={(e) => onSearchChange(e.target.value)}
          placeholder={placeholder}
          ref={inputRef}
          type="text"
          value={searchValue}
        />
        {Boolean(searchValue.length) ? (
          <div className="SearchList-closeIcon" onMouseDown={() => onSearchChange('')}>
            <CloseIcon className="SearchList-closeIconImg" width="10px" height="10px" />
          </div>
        ) : null}
      </div>
      <ul className="SearchList-list">
        {Boolean(list.length) ? (
          children
        ) : (
          <li className="SearchList-item SearchList-item--noResults">
            <span className="SearchList-itemText">{noResultsMessage}</span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default SearchList;
