import EventsBus from 'services/EventsBus/eventsBus';
import { NOTIFICATION_EVENT_TYPES } from 'services/Notification/NotificationEventTypes';
import type MediaClips from '.';

class MediaDelivery {
  context: MediaClips;

  constructor(context: MediaClips) {
    this.context = context;
  }

  activate = () => {
    EventsBus.get().on(NOTIFICATION_EVENT_TYPES.MEDIA, this.handleEvent);
    EventsBus.get().on(NOTIFICATION_EVENT_TYPES.MEDIA_FALLBACK_STRATEGY, this.handleFallbackStrategy);
  };

  deactivate = () => {
    EventsBus.get().off(NOTIFICATION_EVENT_TYPES.MEDIA, this.handleEvent);
    EventsBus.get().off(NOTIFICATION_EVENT_TYPES.MEDIA_FALLBACK_STRATEGY, this.handleFallbackStrategy);
  };

  private handleEvent = async (data) => {
    if (!this.isVehicleExist(data.assetId, data.assetGroupIds, data.timestamp)) {
      return;
    }

    const candidateClip = this.context.items.value.find((clip) => clip.value.date.value === data.timestamp);

    if (!candidateClip) {
      return;
    }

    const response = await this.context.repository.get({
      assetId: data.assetId,
      from: data.timestamp,
      to: data.timestamp + 1000,
    });

    const item = response.items.find((item) => item.assetId === data.assetId);

    candidateClip.value.update(item);
    await this.updateType();
  };

  private handleFallbackStrategy = async () => {
    this.context.fetch();
    await this.updateType();
  };

  private updateType = async () => {
    this.context.context.unsubscribeChanges();
    await this.context.context.type.fetch();
    this.context.context.subscribeChanges();
  };

  private isVehicleExist = (assetId, assetGroupIds, timestamp) => {
    const source = this.context.context.source;
    const range = source.dateRange.params;
    const isAssetExist =
      parseInt(source.vehicles.selectedAssetId.value, 10) === assetId ||
      assetGroupIds.includes(parseInt(source.groups.selectedAssetGroupId.value, 10));
    const isGroupAllSelected = !source.vehicles.selectedAssetId.value && !source.groups.selectedAssetGroupId.value;
    const isTimeSuite = timestamp >= range.from && timestamp <= range.to;

    return (isAssetExist || isGroupAllSelected) && isTimeSuite;
  };
}

export default MediaDelivery;
