import React from 'react';
import { observer } from 'mobx-react';

import classNames from 'classnames';
import { DOWNLOAD_FORMAT } from 'config';
import type { SimpleField } from 'models/Fields';
import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';

import './styles.scss';

interface IProps {
  format: SimpleField<string>;
  className?: string;
}

const dropDownOptions = [
  { label: 'XLSX (Microsoft Excel)', value: DOWNLOAD_FORMAT.XLSX },
  { label: 'CSV (Comma Separated Values)', value: DOWNLOAD_FORMAT.CSV },
  { label: 'HTML (Browser)', value: DOWNLOAD_FORMAT.HTML },
];

const TableDropDownDownloadFormat: React.FC<IProps> = ({ format, className }) => {
  const onChange = ({ value }) => {
    format.set(value);
  };

  const cn = classNames('TableDownloadFormat', className);

  return (
    <div className={cn}>
      <span className="TableDownloadFormat-text">Format</span>
      <div className="TableDownloadFormat-select">
        <SearchableStaticSelect
          isSearchable={false}
          value={dropDownOptions.find((option) => option.value === format.value)}
          handleChange={onChange}
          values={dropDownOptions}
        />
      </div>
    </div>
  );
};

export default observer(TableDropDownDownloadFormat);
