import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { CustomersStore } from 'stores';

import './styles.scss';

interface IProps {
  customersStore?: CustomersStore;
}

@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class SecondaryPanelJobSummary extends Component<IProps> {
  render() {
    const {
      customersStore: {
        selectedCustomer: {
          jobDetails,
        },
      },
    } = this.props;

    return <div className="SecondaryPanelJobSummary">
      {jobDetails.description}
    </div>;
  }
}

export default SecondaryPanelJobSummary;
