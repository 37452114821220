import FormattedDate from './FormattedDate';
import type { DATE_TIME_FORMATS } from 'config';

class LoggerInfo {
  creationTime: FormattedDate;
  createdBy: string;
  lastUpdateTime: FormattedDate;
  updatedBy: string;

  constructor({ creationTime, createdBy, lastUpdateTime, updatedBy }, format?: DATE_TIME_FORMATS) {
    this.creationTime = new FormattedDate(creationTime, format);
    this.createdBy = createdBy;
    this.lastUpdateTime = new FormattedDate(lastUpdateTime, format);
    this.updatedBy = updatedBy;
  }
}

export default LoggerInfo;
