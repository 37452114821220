import { action, computed, observable } from 'mobx';
import { repositoryService } from 'services';

import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

import type { IIntegrationsStore } from '../../interfaces/stores/Integrations/IIntegrationsStore';

import type Integration from './Integration';
import QBIntegrationModel from './QBIntegration';
import STIntegrationModel from './STIntegration';
import WEXIntegrationModel from './WEXIntegration';
import type { IntegrationDomain } from '../../interfaces/stores/Integrations/IIntegration';
import FinanceAccess from 'models/Integrations/FinanceAccess';
import { FEATURES } from 'config';

export class IntegrationsStore implements IIntegrationsStore {
  repositoryIntegrations: IRepository;
  repositoryEntityIntegrationStatuses: IEntityRepository;
  @observable integrations: Integration[] = [];
  @observable hideExpiredNotification: boolean = false;

  financeAccess: FinanceAccess;

  constructor() {
    this.repositoryIntegrations = repositoryService.get('integrations');
    this.repositoryEntityIntegrationStatuses = this.repositoryIntegrations.entity('integration-statuses');
    this.financeAccess = new FinanceAccess();
  }

  @computed get activeIntegrations(): Integration[] {
    return this.integrations.filter(({ status }) => ['CONNECTED', 'EXPIRED'].includes(status));
  }

  @computed get availableIntegrations(): Integration[] {
    return this.integrations.filter(({ status }) => ['DISCONNECTED'].includes(status));
  }

  @computed get expiredIntegrations(): Integration[] {
    return this.integrations.filter(({ status }) => ['EXPIRED'].includes(status));
  }

  @computed get hasExpiredIntegrations(): boolean {
    return this.expiredIntegrations.length > 0;
  }

  @computed get activeIntegrationsWithCustomers(): Integration[] {
    return this.activeIntegrations.filter(({ hasCustomers }) => hasCustomers);
  }

  @computed get hasCustomersIntegration() {
    return this.activeIntegrationsWithCustomers.length > 0;
  }

  @computed get expiredIntegrationsList(): Record<IntegrationDomain, boolean> | {} {
    return this.expiredIntegrations.reduce((acc, { domain }) => {
      return {
        ...acc,
        [domain]: true,
      };
    }, {});
  }

  @computed get expiredIntegrationsTitle(): string {
    return this.expiredIntegrations.reduce((acc, { name }) => [...acc, name], []).join(', ');
  }

  @action
  loadAllIntegrations = async () => {
    const response = await this.repositoryEntityIntegrationStatuses.get();

    if (response) {
      this.integrations = response
        .map((item) => {
          if (item.domain === 'QUICK_BOOKS' && FEATURES.customers) {
            return new QBIntegrationModel(item).initialize(this);
          } else if (item.domain === 'SERVICE_TITAN' && FEATURES.customers) {
            return new STIntegrationModel(item).initialize(this);
          } else if (item.domain === 'WEX') {
            return new WEXIntegrationModel(item).initialize(this);
          }
          return null;
        })
        .filter((item) => item);

      this.addDefaultIntegrations(response);
    }
  };

  @action setHideExpiredNotification = (value: boolean) => {
    this.hideExpiredNotification = value;
  };

  private addDefaultIntegrations = (response) => {
    const wex = response.find((integration) => integration.domain === 'WEX' && integration.status === 'CONNECTED');

    if (wex) {
      this.integrations.push(
        new WEXIntegrationModel({ ...wex, status: 'DISCONNECTED', username: '', host: '' }).initialize(this)
      );
    }
  };
}

export default IntegrationsStore;
