import React from 'react';
import { observer } from 'mobx-react';
import { Radio } from 'antd';
import { intersection, remove } from 'lodash';
import timeStore from 'stores/TimeStore';

import type { SimpleField, ToggleField } from 'models/Fields';
import type ScheduleModel from 'models/Alerts/Schedule';

import { default as CheckboxInline } from 'components/Checkbox';
import InfoTooltip from 'components/InfoTooltip';
import AlertPreferencesCalendarItem from '../AlertPreferencesCalendarItem';

import './styles.scss';

interface IProps {
  subType: SimpleField<Alerts.Modal.WorkHours.Type>;
  schedule: ScheduleModel;
  scheduleOverride: ScheduleModel;
  scheduleOverrideOption: ToggleField;
}

const AlertPreferencesWorkHours: React.FC<IProps> = ({
  subType,
  schedule,
  scheduleOverride,
  scheduleOverrideOption,
}) => {
  const handleChangeType = (e) => {
    subType.set(e.target.value);
  };

  const handleChangeScheduleOne = (value) => {
    const duplicates = intersection(value, scheduleOverride.weekdays.toArray());
    if (duplicates.length) {
      const next = remove(scheduleOverride.weekdays.toArray(), (item) => !duplicates.includes(item));
      scheduleOverride.weekdays.replace(next);
    }

    schedule.weekdays.replace(value);
  };

  const handleChangeScheduleTwo = (value) => {
    const duplicates = intersection(value, schedule.weekdays.toArray());
    if (duplicates.length) {
      const next = remove(schedule.weekdays.toArray(), (item) => !duplicates.includes(item));
      schedule.weekdays.replace(next);
    }

    scheduleOverride.weekdays.replace(value);
  };

  return (
    <>
      <div className="AlertPreference-line">
        <div className="AlertPreference-label">Alert when</div>
        <div className="AlertPreference-value AlertPreferencesWorkHours-value">
          <div className="AlertPreferencesWorkHours-line">
            <Radio.Group onChange={handleChangeType} value={subType.value} className="AlertPreferencesWorkHours-switch">
              <Radio.Button value="IGNITION_ON" className="AlertPreferencesWorkHours-switchButton">
                Ignition On
              </Radio.Button>
              <Radio.Button value="MOVING" className="AlertPreferencesWorkHours-switchButton">
                Moving
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
      </div>
      <div className="AlertPreference-line">
        <div className="AlertPreference-label AlertPreference-label--top">
          <div className="AlertPreferencesWorkHours-label--text">
            <span>Work hours</span>
            <span className="AlertPreferencesWorkHours-label--timeZone">
              <label className="AlertPreferencesWorkHours-label--timeZoneTitle">({timeStore.userTimezone})</label>
              <label className="AlertPreferencesWorkHours-label--timeZoneInfo">
                <InfoTooltip>Time Zone is set based on the Account.</InfoTooltip>
              </label>
            </span>
          </div>
        </div>
        <div className="AlertPreference-value AlertPreferencesWorkHours-Calendar">
          <div className="AlertPreferencesWorkHours-Calendar-container">
            <AlertPreferencesCalendarItem schedule={schedule} onChangeSchedule={handleChangeScheduleOne} />
            <div className="AlertPreferencesWorkHours-Calendar-item">
              <div className="AlertPreferencesWorkHours-Calendar-line AlertPreferencesWorkHours-Calendar-line--single AlertPreferencesWorkHours-Calendar-another">
                <CheckboxInline
                  label="Set a different schedule for some days"
                  onChange={scheduleOverrideOption.toggle}
                  checked={scheduleOverrideOption.value}
                />
              </div>
            </div>
            {scheduleOverrideOption.value && (
              <AlertPreferencesCalendarItem schedule={scheduleOverride} onChangeSchedule={handleChangeScheduleTwo} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(AlertPreferencesWorkHours);
