import { computed, observable } from 'mobx';
import moment from 'moment';

import { CAMERA_JOURNAL_STATE, DATE_TIME_FORMATS } from 'config';
import { getEndOfDayByDate, getFormattedTime, getStartOfDayByDate } from 'utils';
import timeStore from 'stores/TimeStore';
import CameraDate from './CameraDate';
import type JournalItem from './JournalItem';

class CameraDates {
  @observable model: {
    [date: string]: CameraDate;
  };

  constructor() {
    this.model = {};
  }

  setModel = (list: JournalItem[], states: CAMERA_JOURNAL_STATE[]) => {
    this.model = {};
    list.forEach((item) => {
      if (states.includes(item.state)) {
        this.fillModel(item);
      }
    });
  };

  select = (activeDate: string) => {
    this.list.forEach((date) => this.model[date].isSelected.toggle(false));
    this.model[activeDate]?.isSelected.toggle(true);
  };

  selected = (): CameraDate => {
    const selectedDate = this.list.find((date) => this.model[date].isSelected.value);

    return selectedDate ? this.model[selectedDate] : null;
  };

  @computed get active() {
    return this.list.find((date) => this.model[date].isSelected.value);
  }

  reset = () => {
    this.model = {};
  };

  @computed get oldest() {
    return this.list[0];
  }

  @computed get list() {
    return Object.keys(this.model);
  }

  private fillModel = (item: JournalItem) => {
    const startDate = this.getDate(item.startTimestamp);
    const endDate = this.getDate(item.endTimestamp);

    if (startDate !== endDate) {
      for (
        let currentDate = startDate;
        moment(currentDate, DATE_TIME_FORMATS.monthDatYearFull) <= moment(endDate, DATE_TIME_FORMATS.monthDatYearFull);
        currentDate = moment(currentDate, DATE_TIME_FORMATS.monthDatYearFull)
          .add(1, 'days')
          .format(DATE_TIME_FORMATS.monthDatYearFull)
      ) {
        const currentTimestamp = moment
          .tz(currentDate, DATE_TIME_FORMATS.monthDatYearFull, timeStore.sessionTimezone)
          .valueOf();
        const endTimestamp =
          currentDate === endDate ? item.endTimestamp : getEndOfDayByDate(currentTimestamp, timeStore.sessionTimezone);
        const startTimestamp =
          currentDate === startDate
            ? item.startTimestamp
            : getStartOfDayByDate(currentTimestamp, timeStore.sessionTimezone);

        this.fillModelItem({ ...item, endTimestamp, startTimestamp }, currentDate);
      }
    } else {
      this.fillModelItem(item, endDate);
    }
  };

  private fillModelItem = (item: JournalItem, date: string) => {
    if (!this.model.hasOwnProperty(date)) {
      this.model[date] = new CameraDate().addInterval(item);
    } else {
      this.model[date].addInterval(item);
    }
  };

  private getDate = (timestamp) => {
    return getFormattedTime(timestamp, DATE_TIME_FORMATS.monthDatYearFull, timeStore.sessionTimezone);
  };
}

export default CameraDates;
