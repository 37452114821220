import React from 'react';
import { Observer } from 'mobx-react';
import { validateAccessLevel } from 'stores';
import { ACL } from 'config';

import type MaintenanceAsset from 'models/Maintenances/Models/MaintenanceAsset';
import type MaintenanceModalUI from 'models/Maintenances/UI/MaintenanceModalUI/MaintenanceModalUI';

import SearchableVehicleSelect from 'containers/Select/SearchableVehicleSelect';

import './styles.scss';

interface IProps {
  asset: MaintenanceAsset;
  ui: MaintenanceModalUI;
}

const MaintenancePreferenceVehicle: React.FC<IProps> = ({ asset, ui }) => {
  const edit = validateAccessLevel([ACL.MAINTENANCE.BASIC.UPDATE]);
  const vehicleChange = ({ value, label }) => {
    asset.set(value, label);
    ui.reset();
  };

  return (
    <Observer
      render={() => (
        <div className="MaintenancePreferenceVehicle MaintenancePreference-line">
          <div className="MaintenancePreference-label">Vehicle/Asset</div>
          <div className="MaintenancePreference-value">
            <div className="MaintenancePreferenceVehicle-dropdown">
              <SearchableVehicleSelect
                disabled={ui.isVehicleDisabled.value || !edit}
                selectFirst
                handleChange={vehicleChange}
                valueSource="assetId"
                value={{ value: asset.id.value, label: asset.name.value }}
                timeout={555}
              />
            </div>
            <div className="MaintenancePreferenceVehicle-info">
              <div className="MaintenancePreferenceVehicle-infoTitle">Current Vehicle Metrics</div>
              <div className="MaintenancePreferenceVehicle-infoValues">
                <span>
                  {Number(asset.details.odometer.value)?.toLocaleString('en-US', { maximumFractionDigits: 0 })} mi
                </span>
                <span>
                  {Number(asset.details.engHours.value)?.toLocaleString('en-US', { maximumFractionDigits: 1 })} hrs
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default MaintenancePreferenceVehicle;
