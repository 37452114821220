import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { CustomersStore } from 'stores';

interface IProps {
  customersStore?: CustomersStore;
}


@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class SecondaryPanelEstimateCustomerMemo extends Component<IProps> {

  render() {
    const customer = this.props.customersStore.selectedCustomer;
    const estimate = customer.selectedEstimate;

    return <div className="SecondaryPanelEstimateSummary">
      {estimate.description}
    </div>;
  }
}

export default SecondaryPanelEstimateCustomerMemo;
