import type { ListField, SimpleField, ToggleField } from 'models/Fields';
import type { ReportFor, ReportType } from 'models/Report/Report';
import type ReportPreferenceItem from 'models/Report/ReportPreferenceItem';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import type { UniqueField } from '../Fields';

export interface IScheduledReportFormat {
  id: SimpleField<string>;
  value: string;
  adapt: (id: string) => string;
}

export interface IScheduledReportAppliesToItem {
  displayName: SimpleField<string>;
  id: SimpleField<string>;
}

export interface IScheduledReportAppliesTo {
  group: IScheduledReportAppliesToItem;
  device: IScheduledReportAppliesToItem;
  set: (group: { id: string; displayName: string }, device: { id: string; displayName: string }) => void;
  getModel: () => void;
}

export interface IScheduledReportInterval {
  displayName: SimpleField<string>;
  description: SimpleField<string>;
  id: SimpleField<string>;
}

export interface IScheduledReportParameters {
  report: ReportPreferenceItem;
  driver: ReportPreferenceItem;
  tag: ReportPreferenceItem;
  hideInactive: ToggleField;
  geozone: ReportPreferenceItem;
  option: ReportPreferenceItem;
  getModel: () => void;
  reset: () => void;
}

export interface IScheduledReport {
  id: number;
  format: IScheduledReportFormat;
  // scheduledReportName: SimpleField<string>;
  scheduledReportName: UniqueField;
  reportParameters: IScheduledReportParameters;
  interval: IScheduledReportInterval;
  appliesTo: IScheduledReportAppliesTo;
  recipients: ListField<string>;
  isActive: ToggleField;
  isValid: boolean;
  createdBy: SimpleField<string>;
  updatedBy: SimpleField<string>;
  creationTime: SimpleField<number>;
  lastUpdateTime: SimpleField<number>;
  initialModel: IScheduledReportServer;
  selectedTypeOption: ReportType;
  selectedForOption: ReportFor;
  repositoryScheduledReport: IEntityRepository;
  isCharacterLimitReached: boolean;
  characterLimitLeft: number;
  setSelectedTypeOption: (value: ReportType) => void;
  getModel: () => void;
  update: () => void;
  create: () => void;
  destroy: () => void;
}

export interface IScheduledReportFormatServer {
  displayName: string;
  id: string;
}

export interface IScheduledReportIntervalServer {
  displayName: string;
  description: string;
  id: string;
}

export interface IScheduledReportParametersServer {
  driverId: string;
  reportId: string;
  reportName: string;
  tagId: string;
  hideInactive: boolean;
  geozoneId: string;
  option: string;
}

export interface IScheduledReportSourceServer {
  group: IScheduledReportSourceItemServer;
  device: IScheduledReportSourceItemServer;
}

export interface IScheduledReportSourceItemServer {
  displayName: string;
  id: string;
}

export interface IScheduledReportServer {
  recipients: string[];
  format: IScheduledReportFormatServer;
  description: string;
  interval: IScheduledReportIntervalServer;
  reportParameters: IScheduledReportParametersServer;
  id: number;
  source: IScheduledReportSourceServer;
  isActive: boolean;
  createdBy: string;
  updatedBy: string;
  creationTime: number;
  lastUpdateTime: number;
}

export interface IScheduledReportSearchParams {
  interval?: string;
  reportName?: string;
  recipients?: string;
  description?: string;
  createdBy?: string;
  updatedBy?: string;
  creationTime?: string;
  lastUpdateTime?: string;
}

export enum ScheduledReportSearchMap {
  interval = 'interval',
  reportName = 'reportName',
  recipients = 'recipients',
  description = 'description',
  applies = 'source',
  createdBy = 'createdBy',
  updatedBy = 'updatedBy',
  creationTime = 'creationTime',
  lastUpdateTime = 'lastUpdateTime',
}
