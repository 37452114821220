import React from 'react';
import { inject, Observer } from 'mobx-react';

import { MAINTENANCES_STORE } from 'config';

import type { Maintenances as MaintenancesStore } from 'stores';

import Button from 'components/Button';
import Modal from 'components/Modal';

import './styles.scss';

interface IProps {
  [MAINTENANCES_STORE]?: MaintenancesStore;
  onConfirm: () => void;
}

const MaintenanceRequestsModal: React.FC<IProps> = ({ maintenancesStore, onConfirm }) => {

  const handleCancel = () => {
    maintenancesStore.requests?.ignoreRequest.reset();
  };

  const handleConfirm = async () => {
    await maintenancesStore.requests?.deleteById(maintenancesStore.requests?.ignoreRequest.value);
    maintenancesStore.requests?.ignoreRequest.reset();
    maintenancesStore.resetTask();
    onConfirm();
  };

  return <Observer
    render={() => {
      const isModalOpen = !!maintenancesStore.requests?.ignoreRequest.value;

      return (<Modal
        isModalOpen={isModalOpen}
        width={438}
        onClose={handleCancel}
        withoutCloseIcon
        className="MaintenanceRequestsModal"
      >
        <div className="MaintenanceRequestsModal-header">
          <h3 className="MaintenanceRequestsModal-title">Ignore Maintenance Request?</h3>
        </div>
        <div className="MaintenanceRequestsModal-body">
          <p>
            Ignoring this request will permanently remove it from the system and all notes and attachments will be
            deleted.
          </p>
          <p>Do you want to continue?</p>
        </div>
        <div className="MaintenanceRequestsModal-footer">
          <Button
            className="MaintenanceRequestsModal-navigationCancel Button--cancel"
            onClick={handleCancel}
            title="Cancel"
            inline
          />
          <Button
            className="MaintenanceRequestsModal-navigationSave Button--apply"
            onClick={handleConfirm}
            title="Yes, Continue"
            inline
          />
        </div>
      </Modal>)
    }}
  />;
};

export default inject(MAINTENANCES_STORE)(MaintenanceRequestsModal);
