import React from 'react';

import AlertPreferenceFinalizeSimpleLine from '../AlertPreferenceFinalizeSimpleLine';

interface IProps {
  label?: string;
  speed: number;
}

const AlertPreferenceFinalizeSpeedsOverLine: React.FC<IProps> = ({ label, speed }) => {
  const value = `Speed over ${speed} mph`;

  return <AlertPreferenceFinalizeSimpleLine label={label} value={value} />;
};

export default AlertPreferenceFinalizeSpeedsOverLine;
