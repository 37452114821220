import { computed, observable } from 'mobx';
import { saveAs } from 'file-saver';

import { ListField, SimpleField } from 'models/Fields';
import { DOWNLOAD_FORMAT } from 'config';

import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import type ITableDownload from 'interfaces/models/TableDownload/ITableDownload';

const CHARACTER_LIMIT = 512;

class TableDownload<T> implements ITableDownload<T> {
  @observable emails: ListField<string>;
  @observable format: SimpleField<string>;
  context: T;
  repositoryEmail: IEntityRepository;
  id: number;

  constructor(repository: IEntityRepository) {
    this.format = new SimpleField(DOWNLOAD_FORMAT.XLSX);
    this.emails = new ListField();
    this.id = Date.now();
    this.repositoryEmail = repository;
  }

  initialize(context: T) {
    this.context = context;
    return this;
  }

  download = async () => {
    const file = await this.getFile();
    saveAs(file, this.fileName);
  };

  print = async () => {
    return this.getFile();
  };

  send = async () => {
    const file = await this.getFile();
    const data = new FormData();

    data.append('emails', this.emails.toArray().join(','));
    data.append('format', this.format.value);
    data.append('file', file, this.fileName);
    this.addFormData(data);

    const response = await this.repositoryEmail.create(data);

    if (response.status !== 'SENT') {
      this.repositoryEmail.createState.setError('report failed');
    }
  };

  @computed get isCharacterLimitReached() {
    return this.getTotalCharacter() >= CHARACTER_LIMIT;
  }

  @computed get characterLimitLeft() {
    return CHARACTER_LIMIT - this.getTotalCharacter();
  }

  protected addFormData = (data: FormData) => {
    data.append('', '');
  };

  protected get fileName() {
    return 'FileName';
  }

  protected getFile = async () => {
    return new Blob();
  };

  private getTotalCharacter = () => {
    return this.emails.value.reduce((previousValue, currentItem) => previousValue + currentItem.value.length + 1, 0);
  };
}

export default TableDownload;
