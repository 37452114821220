/*global google*/
import React, { memo, useEffect, useMemo } from 'react';
import { GoogleMap, GoogleMapProps, withGoogleMap, withScriptjs } from 'react-google-maps';

import type { IBoundsOffset, ISearchResults } from 'stores';
import { KeyboardShortcuts } from 'tools';
import { KEY_TYPE } from 'config';
import { offsetCenter, getFormattedSelectedAddress } from 'utils';

import './styles.scss';

interface IProps {
  bounds?: google.maps.LatLngBounds;
  boundsOffset?: IBoundsOffset;
  center?: google.maps.LatLng | Locations.ILocation;
  map: any;
  centerOffsetX?: number;
  centerOffsetY?: number;
  zoom?: number;
  clickableIcons?: boolean;
  setStreetViewOpened?: (status: boolean) => void;
  setMapHeading?: (value: number) => void;
  locked?: boolean;
  onClick?: (e: google.maps.MouseEvent | google.maps.IconMouseEvent) => void;
  onPlacesChange?: (location: ISearchResults) => void;
}

let placesService;

const CustomMap: React.FC<GoogleMapProps & IProps> = ({
  bounds,
  boundsOffset,
  center,
  map,
  centerOffsetX = 0,
  centerOffsetY = 0,
  zoom,
  clickableIcons = true,
  children,
  setStreetViewOpened,
  setMapHeading,
  locked = false,
  options,
  onClick = () => true,
  onPlacesChange = () => true,
  ...props
}) => {
  useEffect(() => {
    if (bounds) {
      map.current.fitBounds(bounds, { ...boundsOffset });
    }
  }, [bounds, boundsOffset]);

  useEffect(() => {
    const closeButton = document.createElement('button');
    const controlPosition = google.maps.ControlPosition.TOP_RIGHT;
    const streetView = map.current.getStreetView();
    closeButton.className = 'StreetViewCloseBtn';
    closeButton.innerHTML = '&times;';
    closeButton.onclick = () => {
      setStreetViewOpened(false);
      streetView.setVisible(false);
    };
    streetView.controls[controlPosition].push(closeButton);
    const tempMap = new google.maps.Map(document.createElement('div'));
    placesService = new google.maps.places.PlacesService(tempMap);
  }, []);

  useEffect(() => {
    KeyboardShortcuts.get()
      .setKeys([KEY_TYPE.UP_ARROW, KEY_TYPE.DOWN_ARROW, KEY_TYPE.LEFT_ARROW, KEY_TYPE.RIGHT_ARROW, KEY_TYPE.ESCAPE])
      .blockKey();

    return () => {
      KeyboardShortcuts.get().unblockKey();
    };
  }, []);

  const newCenter = useMemo(() => offsetCenter(center, centerOffsetX, centerOffsetY, zoom, map.current), [
    center,
    centerOffsetX,
    centerOffsetY,
  ]);

  const handleHedaingChange = () => {
    if (setMapHeading) {
      setMapHeading(map.current.getHeading());
    }
  };

  return (
    <GoogleMap
      ref={map}
      defaultZoom={props.defaultZoom}
      defaultCenter={props.defaultCenter}
      center={newCenter}
      zoom={zoom}
      onHeadingChanged={handleHedaingChange}
      options={{
        mapTypeControl: false,
        draggableCursor: 'default',
        scrollwheel: !locked,
        disableDoubleClickZoom: locked,
        clickableIcons,
        styles: [
          {
            featureType: 'poi',
            stylers: [
              {
                visibility: 'on',
              },
            ],
          },
        ],
        draggable: !locked,
        rotateControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        rotateControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
        streetViewControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
        scaleControl: false,
        ...options,
      }}
      {...props}
      onClick={(e: google.maps.MouseEvent | google.maps.IconMouseEvent) => {
        e.stop();

        let placeId = '';
        if ('placeId' in e) {
          placeId = e.placeId;
        }

        if (placeId) {
          placesService.getDetails({ placeId }, (place) => {
            onPlacesChange(getFormattedSelectedAddress(place));
          });
        }

        onClick(e);
      }}
    >
      {children}
    </GoogleMap>
  );
};

CustomMap.defaultProps = {
  defaultZoom: 5,
  defaultCenter: {
    lat: 38.6406812,
    lng: -106.1094301,
  },
};

export default withScriptjs(withGoogleMap(memo(CustomMap)));
