import React, { FC, useCallback } from 'react';
import { CloseIcon } from 'assets';
import Input from 'components/Input';

import './styles.scss';
import classNames from 'classnames';
import isValidCoords, { getValidCoords } from 'is-valid-coords';
import isNull from 'lodash/isNull';

interface IProps {
  canDelete: boolean;
  color: string;
  colorName: string;
  dimmed?: boolean;
  disabled?: boolean;
  hoverIndex?: number;
  isCenter?: boolean;
  isCirclePath?: boolean;
  lat: string;
  lng: string;
  onHover?: (index: number) => void;
  orderNumber: number;
  removeMarker: (index) => void;
  updateMarker: ({ index, lat, lng }) => void;
}

const CoordinatesInput: FC<IProps> = ({
  canDelete,
  color,
  colorName,
  dimmed,
  disabled = false,
  hoverIndex = null,
  isCenter = false,
  isCirclePath = false,
  lat,
  lng,
  onHover = () => void 0,
  orderNumber,
  removeMarker,
  updateMarker,
}) => {
  const handleChangeLat = (e) => {
    const { value } = e.target;
    const validCoord = getValidCoords(value);
    if (validCoord?.length === 2) {
      const [lat, lng] = validCoord;
      updateMarker({ index: orderNumber, lat, lng });
      return;
    } else {
      const numberValue = Number(value);
      const newPosition = { lat: numberValue, lng: Number(lng) };
      if (!isNaN(numberValue) && isValidCoords(newPosition)) {
        updateMarker({ index: orderNumber, ...newPosition });
      }
    }
  };

  const handleChangeLng = (e) => {
    const { value } = e.target;
    const validCoord = getValidCoords(value);
    if (validCoord?.length === 2) {
      const [lat, lng] = validCoord;
      updateMarker({ index: orderNumber, lat, lng });
      return;
    } else {
      const numberValue = Number(value);
      const newPosition = { lat: Number(lat), lng: numberValue };
      if (!isNaN(numberValue) && isValidCoords(newPosition)) {
        updateMarker({ index: orderNumber, ...newPosition });
      }
    }
  };

  const handleRemove = useCallback(() => {
    removeMarker(orderNumber);
  }, [removeMarker, orderNumber]);

  const iconCN = classNames('CoordinatesInput-close', {
    'CoordinatesInput-close--disabled': !canDelete || disabled,
  });

  const centerMarkerCN = classNames('DispatchInputGeozone-iconWrapper', {
    'DispatchInputGeozone-iconWrapper--noIndex': isCenter,
    'DispatchInputGeozone-iconWrapper--circlePath': isCirclePath,
    'DispatchInputGeozone-iconWrapper--invertColor':
      colorName.toLowerCase() === 'white' || colorName.toLowerCase() === 'yellow',
    'DispatchInputGeozone-iconWrapper--dimmed': (hoverIndex !== orderNumber && !isNull(hoverIndex)) || dimmed,
  });

  const iconWrapperCN = classNames('CoordinatesInput-iconWrapper', {
    'CoordinatesInput-iconWrapper--hover': hoverIndex === orderNumber,
    'CoordinatesInput-iconWrapper--disabled': !canDelete,
  });

  const handleMouseOver = () => {
    onHover(orderNumber);
  };

  const handleMouseOut = () => {
    onHover(null);
  };

  return (
    <div className="CoordinatesInput">
      <div className="CoordinatesInput-container">
        <div className="DispatchInputGeozone-iconContainer">
          <div className={centerMarkerCN} style={{ background: color }}>
            {isCenter || isCirclePath ? null : orderNumber + 1}
          </div>
        </div>
        <Input disabled={disabled} className="CoordinatesInput-input" value={lat} onChange={handleChangeLat} />
        <Input disabled={disabled} className="CoordinatesInput-input" value={lng} onChange={handleChangeLng} />
        <div className={iconWrapperCN} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          <CloseIcon className={iconCN} width={16} onClick={handleRemove} />
        </div>
      </div>
    </div>
  );
};

export default CoordinatesInput;
