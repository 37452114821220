import React, { FC } from 'react';
import { observer } from 'mobx-react';

import ConfirmationModal from 'components/Admin/ConfirmationModal';
import InputNumber from 'components/InputNumber';
import type { SimpleField } from 'models/Fields';

import './styles.scss';

interface IProps {
  onSubmit: (value: number) => void;
  onCancel: () => void;
  isOpen: boolean;
  dueSoon: SimpleField<number>;
}

const AdjustDueSoonModal: FC<IProps> = ({ isOpen, onCancel, onSubmit, dueSoon }) => {
  const handleChange = (value: string) => {
    dueSoon.set(Number(value));
  };
  const handleSubmit = () => {
    onSubmit(Number(dueSoon.value));
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      cancelButtonStyles={{ border: 'none' }}
      applyButtonStyles={{ fontWeight: 600 }}
      title="Adjust Due Soon Threshold"
    >
      <div className="AdjustDueSoonModal">
        <span className="AdjustDueSoonModal-label">Due Soon</span>
        <div className="AdjustDueSoonModal-input">
          <InputNumber value={String(dueSoon.value)} min="1" max="999" onChange={handleChange} />
        </div>
        <span className="AdjustDueSoonModal-text">Days</span>
      </div>
    </ConfirmationModal>
  );
};

export default observer(AdjustDueSoonModal);
