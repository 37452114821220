import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import { repositoryService } from 'services';
import type { MediaAdapter } from './MediaAdapter';

export class MediaDeviceAdapter {
  repositoryVehicles: IRepository;
  context: MediaAdapter;

  constructor(context) {
    this.repositoryVehicles = repositoryService.get('vehicles');
    this.context = context;
  }

  getAll = async () => {
    const response = await this.repositoryVehicles.get({ mediaEnabled: true });

    return this.convert(response);
  };

  /*mapIds = () => {
    this.context.ids = {};
  };*/

  private convert = (response): Device.IServerDevice[] => {
    return response;
  };
}
