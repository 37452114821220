import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import update from 'immutability-helper';

import type { ToggleField } from 'models/Fields';
import type InspectionFormItems from 'models/Inspections/Models/InspectionFormItems';

import Checkbox from 'components/Checkbox';
import InfoTooltip from 'components/InfoTooltip';
import DraggableCard from 'components/DragableCard';
import InspectionsFormPanelItem from '../InspectionsFormPanelItem';
import Button from 'components/Button';

import './styles.scss';

interface IProps {
  items: InspectionFormItems;
  isError: ToggleField;
}

const InspectionsFormPanelItems: React.FC<IProps> = ({ items, isError }) => {
  useEffect(() => {
    if (!Boolean(items.list.value.length)) {
      items.addItem();
    }
  }, []);

  const addNewItem = () => {
    items.addItem();
  };

  const deleteItem = (index: number) => {
    items.removeItem(index);
  };

  const handleMoveColumns = (dragIndex: number, hoverIndex: number) => {
    const movedItem = items.list.value[dragIndex];
    const updatedItems = update(items.list.value, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, movedItem],
      ],
    });

    items.list.replace(updatedItems.map((item) => item.value));
  };

  return (
    <Observer
      render={() => (
        <div className="InspectionsFormPanelItems">
          <div className="InspectionsFormPanelItems-checkboxes">
            <div className="InspectionsFormPanelItems-checkboxesPassFail">
              <Checkbox
                label="Require All Pass/Fail"
                checked={items.requireAllPassFail.value}
                onChange={items.toggleRequireAllPassFail}
              />
              <InfoTooltip>
                Requiring a Pass or Fail status removes the ability to mark an item as Non-Applicable.
              </InfoTooltip>
            </div>
            <div className="InspectionsFormPanelItems-checkboxesNotes">
              <Checkbox
                label="Require All Notes"
                checked={items.requireAllNotes.value}
                onChange={items.toggleRequireAllNotes}
              />
            </div>
            <div className="InspectionsFormPanelItems-checkboxesPhotos">
              <Checkbox
                label="Require All Photos"
                checked={items.requireAllPhotos.value}
                onChange={items.toggleRequireAllPhotos}
              />
            </div>
          </div>
          <div className="InspectionsFormPanelItems-items">
            <ul className="InspectionsFormPanelItems-itemsList">
              {items.list.value.map((item, index) => (
                <li key={item.value.dragIndex}>
                  <DraggableCard
                    id={item.value.dragIndex.toString()}
                    index={index}
                    moveCard={handleMoveColumns}
                    key={item.value.dragIndex}
                  >
                    <InspectionsFormPanelItem
                      index={index}
                      item={item.value}
                      onDeleteItem={deleteItem}
                      disabledDelete={Boolean(items.list.value.length <= 1)}
                      isError={isError}
                    />
                  </DraggableCard>
                </li>
              ))}
            </ul>
            <div className="InspectionsFormPanelItems-itemsLink">
              <Button className="Button--link" title="+ Add New Item" onClick={addNewItem} />
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default InspectionsFormPanelItems;
