import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { TrafficLayer } from 'react-google-maps';

import type { EventsRegistryStore, MapStore, DevicesStore, GoogleMapSearchStore, ISearchResults } from 'stores';
import type { SubNavUIStateStore as SubNavUIStateStoreClass } from 'components/SubNav/SubNavUIState.store';
import { HISTORY_MODE, MAP_PARAMS, PATHS } from 'config';
import { reduceDecimals } from 'utils/numbers';
import { KeyboardShortcutsManager } from 'tools';

import DevicesMarkersList from 'components/Map/DevicesMarkersList';
import DeviceMarker from 'components/Map/DeviceMarker';
import Marker from 'components/Map/Marker';
import CustomMap from 'components/Map';
import VehicleRoute from 'components/Map/VehicleRoute';
import StreetViewLayer from 'components/Map/StreetViewLayer';
import Geozone from 'components/Map/Geozone';
import CoordinatesInfoBox from 'components/Map/CoordinatesInfoBox';
import CreateGeozoneConfirmationModal from 'containers/Admin/Geozones/CreateGeozone';
import CustomerMapMarker from '../Customers/CustomerMapMarker';
import DeviceMediaMarkerList from 'components/Map/DeviceMediaMarkerList';

import { addGeozoneCursorIconSrc } from 'assets';
import './styles.scss';

const TIMELINE_HEIGHT_MAX = 500;

interface IProps {
  devicesStore?: DevicesStore;
  eventsRegistryStore?: EventsRegistryStore;
  mapSearchStore?: GoogleMapSearchStore;
  mapStore?: MapStore;
  subNavStore?: SubNavUIStateStoreClass;
}

interface IState {
  createGeozone: boolean;
  cursorPosition: {
    lat: number;
    lng: number;
  };
  moveOverMap: boolean;
}

@inject(
  ({ devicesMapStore: { mapStore, devicesStore, mapSearchStore }, eventsRegistryStore, uiStateStore: { subNav } }) => ({
    devicesStore,
    eventsRegistryStore,
    mapSearchStore,
    mapStore,
    subNavStore: subNav,
  })
)
@observer
class Map extends Component<IProps & RouteComponentProps, IState> {
  private readonly map: React.RefObject<any>;
  private readonly onHandleKeyUp: (event) => void;

  constructor(props) {
    super(props);

    this.map = React.createRef();
    this.state = {
      createGeozone: false,
      cursorPosition: {
        lat: null,
        lng: null,
      },
      moveOverMap: false,
    };

    this.onHandleKeyUp = this.handleKeyUp.bind(this);
  }

  handleKeyUp = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'm') {
      const {
        eventsRegistryStore: {
          settings: { filterItems },
        },
      } = this.props;

      filterItems.Moving?.toggle();
      filterItems.Speeding?.toggle();
    }
  };

  componentDidMount() {
    const {
      mapStore: { setMapContainer },
    } = this.props;

    setMapContainer('main-map');
    KeyboardShortcutsManager.get().add(this.onHandleKeyUp, true);
  }

  componentWillUnmount(): void {
    const {
      subNavStore: { isExpanded, setSubNavMenuState },
      mapStore: { setMapContainer, togglePointGeozoneLocation },
      mapSearchStore: { resetSearch },
    } = this.props;

    if (!isExpanded) {
      setSubNavMenuState();
    }

    resetSearch();
    setMapContainer(null);
    togglePointGeozoneLocation(false);
    this.props.mapStore.updateDragged(false);
    this.props.mapStore.setUnlocked();
    KeyboardShortcutsManager.get().remove(this.onHandleKeyUp, true);
  }

  // select/center and zoom vehicle
  deviceClick = (vehicleId) => {
    const {
      history: { push },
      devicesStore: { groups, selectedDeviceId, resetSelectedEvent, updateManuallySelected },
    } = this.props;

    const groupId = groups.groupId.value;
    const isSelected = vehicleId === selectedDeviceId;
    const path = groupId
      ? PATHS.MAP.GROUP_VEHICLE.replace(':groupId', groupId).replace(':vehicleId', vehicleId)
      : PATHS.MAP.VEHICLE.replace(':vehicleId', vehicleId);

    if (isSelected) {
      resetSelectedEvent();
    } else {
      updateManuallySelected(true);
      push(path);
    }
  };

  handleSelectedDeviceClick = (vehicleId) => {
    const {
      devicesStore: { getDeviceById },
    } = this.props;
    const device = getDeviceById(vehicleId);
    const index = device?.events.length - 1;
    const lastEvent = device?.events[index];
    if (this.showRoute && device) {
      lastEvent.showTrackPointCard();
      this.wayPointClick(index);
    }
  };

  wayPointClick = (index: number) => {
    const {
      devicesStore: { setSelectedEventIndex },
    } = this.props;

    setSelectedEventIndex(index);
  };

  handleDragEnd = () => {
    const {
      devicesStore: { toggleSelectedDeviceFollowing },
      mapStore: { updateDragged },
    } = this.props;

    toggleSelectedDeviceFollowing(false);
    updateDragged(true);
  };

  handleDblClick = () => {
    this.props.mapStore.updateDragged(true);
  };

  handleZoomChanged = () => {
    const {
      mapStore: { setZoom, mapZoom, updateDragged, dragged },
    } = this.props;
    const nextZoom = this.map.current.getZoom();
    if (mapZoom !== nextZoom) {
      if (nextZoom === 5) {
        updateDragged(false);
      } else if (dragged === false) {
        updateDragged(true);
      }
    }

    setZoom(nextZoom);
  };

  onGeozoneClick = (geozoneId) => {
    const {
      history: { push },
    } = this.props;

    push(PATHS.MAP.GEOZONE.replace(':geozoneId', geozoneId));
  };

  get showRoute() {
    const {
      mapStore: { selectedDeviceRoute },
      devicesStore: {
        showTrail,
        enableTrail,
        showHistory,
        history: { activeMode },
        selectedDevice,
      },
    } = this.props;

    return (
      ((showTrail && !showHistory) || (showHistory && activeMode.id !== HISTORY_MODE.POINT_IN_TIME)) &&
      selectedDeviceRoute &&
      enableTrail &&
      selectedDevice?.deviceEventsRequestStatus.success
    );
  }

  setCursorPosition = (e: google.maps.MouseEvent) => {
    this.setState({ cursorPosition: { lat: e.latLng.lat(), lng: e.latLng.lng() } });
  };

  setMoveOver = () => {
    this.setState({ moveOverMap: true });
  };

  setMoveOut = () => {
    this.setState({ moveOverMap: false });
  };

  openCreateGeozoneModal = () => {
    this.setState({ createGeozone: true });
  };

  closeCreateGeozoneModal = () => {
    this.setState({ createGeozone: false });
  };

  handleMouseMove = (e) => {
    const {
      mapStore: { pointGeozoneLocation },
    } = this.props;

    if (pointGeozoneLocation) {
      this.setCursorPosition(e);
      this.setMoveOver();
    }
  };

  handleCloseCreateModal = () => {
    const {
      mapStore: { togglePointGeozoneLocation },
    } = this.props;

    this.closeCreateGeozoneModal();
    togglePointGeozoneLocation(false);
  };

  handleClickOnGeozone = (id: string) => {
    const {
      history: { push },
    } = this.props;

    push(PATHS.MAP.GEOZONE.replace(':geozoneId', id));
  };

  handleClickOnMap = () => {
    const {
      mapStore: { pointGeozoneLocation },
    } = this.props;

    if (pointGeozoneLocation) {
      this.openCreateGeozoneModal();
    }
  };

  handlePlacesChange = (place: ISearchResults) => {
    const {
      mapSearchStore: { setSearchResult, resetSearch },
    } = this.props;

    resetSearch();
    setSearchResult({ ...place, triggerEvent: 'click' });
  };

  handleBoundChanged = () => {
    const {
      mapStore: { setCurrentMapBounds },
    } = this.props;

    if (this.map.current) {
      setCurrentMapBounds(this.map.current.getBounds());
    }
  };

  render() {
    const {
      mapStore: {
        bounds,
        boundsOffset,
        currentClusteringSensitivity,
        currentClusteringState,
        geozonesList,
        locked,
        mapCenter,
        mapGeozonesLayer,
        mapHeading,
        mapLabelSize,
        mapTrafficLayer,
        mapType,
        mapZoom,
        markersByLatestEvent,
        pointGeozoneLocation,
        searchResultMarkers,
        selectedDeviceMarker,
        selectedDeviceRoute,
        selectedGeozone,
        setMapHeading,
        setStreetViewOpened,
        showDirectionalArrows,
        streetViewOpened,
        streetViewPosition,
        streetViewPov,
        timezone,
        wayPoints,
        zoomIntoTrackPoint,
        zoomToGeozoneBounds,
        routeReplay,
        selectedWayPointEvent,
      },
      devicesStore: {
        filters,
        history,
        selectedDevice,
        selectedEventHasDataForTimeRange,
        selectedEventIndex,
        showHistory,
        showTimelineDetails,
        timelineDetailsHeight,
        mediaPanel,
      },
    } = this.props;
    const {
      createGeozone,
      cursorPosition: { lat, lng },
      moveOverMap,
    } = this.state;
    const centerOffsetY = showTimelineDetails.value && timelineDetailsHeight > TIMELINE_HEIGHT_MAX ? 100 : 0;

    return (
      <CustomMap
        bounds={bounds}
        boundsOffset={boundsOffset}
        center={mapCenter}
        centerOffsetY={centerOffsetY}
        containerElement={<div style={{ height: showHistory ? `calc(100vh - 50px)` : '100vh', width: '100%' }} />}
        googleMapURL={process.env.REACT_APP_GOOGLE_MAP_URL}
        loadingElement={<div style={{ height: `100%` }} />}
        map={this.map}
        mapElement={<div style={{ height: `100%` }} />}
        mapTypeId={mapType}
        onZoomChanged={this.handleZoomChanged}
        onBoundsChanged={this.handleBoundChanged}
        zoom={mapZoom}
        setStreetViewOpened={setStreetViewOpened}
        setMapHeading={setMapHeading}
        onDragEnd={this.handleDragEnd}
        onDblClick={this.handleDblClick}
        locked={locked}
        onMouseMove={this.handleMouseMove}
        onMouseOut={this.setMoveOut}
        onClick={this.handleClickOnMap}
        onPlacesChange={this.handlePlacesChange}
        options={{
          draggableCursor: pointGeozoneLocation ? `url(${addGeozoneCursorIconSrc}), default` : 'default',
        }}
      >
        <StreetViewLayer
          visible={streetViewOpened}
          position={streetViewPosition}
          pov={streetViewPov}
          onCloseClick={setStreetViewOpened}
        />
        {/*Render Traffic Layer*/}
        {mapTrafficLayer && <TrafficLayer />}
        {/*Render Route*/}
        {this.showRoute && selectedEventHasDataForTimeRange && (
          <VehicleRoute
            path={selectedDeviceRoute}
            wayPoints={wayPoints}
            timezone={timezone}
            setStreetViewOpened={setStreetViewOpened}
            onZoomIntoClick={zoomIntoTrackPoint}
            locked={locked}
            showHistory={showHistory}
            selectedDevice={selectedDevice}
            onWayPointClick={this.wayPointClick}
            onGeozoneClick={this.onGeozoneClick}
            selectedEventIndex={selectedEventIndex}
            filters={filters}
            mapHeading={mapHeading}
            isHeading={showDirectionalArrows.value}
            routeReplayPlay={routeReplay.state.isPlaying.value}
            selectedWayPointEvent={selectedWayPointEvent}
          />
        )}

        {/*Render Devices*/}

        {!(selectedDeviceMarker && showHistory && history?.activeMode?.id === HISTORY_MODE.TIME_RANGE) && (
          <DevicesMarkersList
            clusteringSize={currentClusteringSensitivity}
            deviceMarkerSize={mapLabelSize}
            isClustered={currentClusteringState === 'on'}
            markers={markersByLatestEvent}
            isVisible={!this.showRoute}
            mapHeading={mapHeading}
            onClick={this.deviceClick}
            showDirectionalArrows={showDirectionalArrows.value}
          />
        )}

        {/*Render Selected Device*/}
        {selectedDeviceMarker && selectedDeviceMarker.event && selectedEventHasDataForTimeRange && (
          <DeviceMarker
            key={selectedDeviceMarker.deviceId}
            deviceId={selectedDeviceMarker.deviceId}
            size={mapLabelSize}
            statusColor={selectedDeviceMarker.event.metadata.attributes.iconColor}
            name={selectedDeviceMarker.shortName}
            position={{
              lat: Number(selectedDeviceMarker.event.latitude),
              lng: Number(selectedDeviceMarker.event.longitude),
            }}
            active
            onClick={this.handleSelectedDeviceClick}
            outline={showHistory ? '#ff8801' : ''}
            arrowColor={selectedDeviceMarker.event.metadata.attributes.arrowColor}
            heading={
              showDirectionalArrows.value &&
              selectedDeviceMarker.event.metadata.attributes.heading &&
              selectedDeviceMarker.event.heading - mapHeading
            }
          />
        )}

        {/* Render Selected Device Media Markers */}
        {selectedDevice && mediaPanel.value && (
          <DeviceMediaMarkerList clips={selectedDevice.media.library.clips} showHistory={showHistory} />
        )}

        {/*Render Search Marker*/}
        {!!searchResultMarkers && (
          <Marker icon={null} position={{ lat: searchResultMarkers.lat, lng: searchResultMarkers.lng }} />
        )}
        {/*Render Geozones*/}
        {mapGeozonesLayer &&
          geozonesList.map(({ name, coordinates, colorName, dispatchMarkerPosition, type, radius, color, id }, key) => {
            const isGeozoneSelected = selectedGeozone?.id === id;
            if (isGeozoneSelected && !selectedGeozone?.isActive) {
              return true;
            }
            return (
              <Geozone
                color={color}
                colorName={colorName}
                dispatchMarkerPosition={dispatchMarkerPosition}
                coordinates={coordinates}
                id={String(id)}
                isSelected={isGeozoneSelected}
                key={key}
                showLabels={mapZoom >= MAP_PARAMS.geozonesBreakpoint}
                label={name}
                labelSize={mapLabelSize}
                onClick={this.handleClickOnGeozone}
                radius={radius}
                type={type}
                zoomToGeozoneBounds={zoomToGeozoneBounds}
                clickable={!pointGeozoneLocation}
              />
            );
          })}
        {selectedGeozone && selectedGeozone.isActive && (
          <Geozone
            isSelected
            showLabels={mapZoom >= MAP_PARAMS.geozonesBreakpoint}
            dispatchMarkerPosition={selectedGeozone.dispatchMarkerPosition}
            coordinates={selectedGeozone.coordinates}
            id={String(selectedGeozone.id)}
            label={selectedGeozone.name}
            onClick={this.handleClickOnGeozone}
            radius={selectedGeozone.radius}
            type={selectedGeozone.type}
            color={selectedGeozone.color}
            colorName={selectedGeozone.colorName}
            zoomToGeozoneBounds={zoomToGeozoneBounds}
            clickable={!pointGeozoneLocation}
          />
        )}

        {pointGeozoneLocation && moveOverMap && <CoordinatesInfoBox position={{ lat, lng }} />}

        <CreateGeozoneConfirmationModal
          isOpen={createGeozone}
          onCancel={this.handleCloseCreateModal}
          geozoneLocation={{
            latitude: reduceDecimals(lat),
            longitude: reduceDecimals(lng),
          }}
        />

        <CustomerMapMarker />
      </CustomMap>
    );
  }
}

export default withRouter(Map);
