import React, { FC, useRef } from 'react';
import { Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import HeaderIcon from '../HeaderIcon';

import { MoreIcon } from 'assets';
import './styles.scss';

interface IProps {
  items: Array<{ text: string; onClick?: () => void; link?: string; disabled?: boolean; hidden?: boolean }>;
  type?: 'table' | 'page';
  iconText?: string;
  disabled?: boolean;
  popupContainer?: () => HTMLElement;
}

const TableActions: FC<IProps> = ({
  items,
  type = 'table',
  iconText = 'More',
  disabled = false,
  popupContainer = (trigger) => trigger.parentElement,
}) => {
  const actionRef = useRef(null);

  const onVisibleChange = (isVisible) => {
    const tableCell = actionRef?.current.closest('.ant-table-cell');

    if (!tableCell) {
      return;
    }

    tableCell.dataset.visible = isVisible ? 'open' : '';
  };

  return (
    <div className="TableActions" ref={actionRef}>
      <Dropdown
        disabled={disabled}
        trigger={['click']}
        getPopupContainer={popupContainer}
        onVisibleChange={onVisibleChange}
        overlay={
          <Menu className="TableActions-list">
            {items.map(({ disabled, hidden, text, onClick = () => void 0, link }, index) => {
              const itemCn = classNames('TableActions-item', {
                'TableActions-item--disabled': disabled,
                'TableActions-item--hidden': hidden,
              });

              const handleItemClick = () => {
                if (disabled) {
                  return;
                }

                onVisibleChange(false);
                onClick();
              };

              return (
                <Menu.Item className={itemCn} onClick={handleItemClick} key={index}>
                  {Boolean(link) ? (
                    <Link to={link} className="TableActions-itemLink">
                      {text}
                    </Link>
                  ) : (
                    text
                  )}
                </Menu.Item>
              );
            })}
          </Menu>
        }
      >
        <HeaderIcon icon={<MoreIcon style={{ width: '16px', height: '16px' }} />} iconText={iconText} type={type} />
      </Dropdown>
    </div>
  );
};

export default TableActions;
