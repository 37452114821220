import { action, observable } from 'mobx';
import { SimpleField, ToggleField } from 'models/Fields';
import MaintenanceEngineTracker from './Trackers/Engine';
import MaintenanceAsset from './MaintenanceAsset';
import MaintenanceOdometerTracker from './Trackers/Odometer';
import MaintenanceServiceTracker from './Trackers/Service';
import MaintenanceMixedTracker from './Trackers/Mixed';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import MaintenanceCompleteRecordModel from './MaintenanceCompleteRecordModel';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import type MaintenanceTask from '../MaintenanceTask';
import MaintenanceLoggerInfo from './MaintenanceLoggerInfo';
import AppFileList from 'models/Files/AppFileList';
import { repositoryService } from 'services';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

const CONFIG_UNIT = {
  ODOMETER: {
    full: 'mile',
    short: 'mi',
    maximumFractionDigits: 0,
  },
  SERVICE_TIME: {
    full: 'day',
    short: 'd',
    maximumFractionDigits: 0,
  },
  ENG_HOURS: {
    full: 'hour',
    short: 'hrs',
    maximumFractionDigits: 1,
  },
};

class MaintenanceModel {
  odometer: MaintenanceOdometerTracker;
  service: MaintenanceServiceTracker;
  engine: MaintenanceEngineTracker;
  mixed: MaintenanceMixedTracker;
  notes: SimpleField<string>;
  documents: AppFileList;
  isCompleted: ToggleField;
  loggerInfo: MaintenanceLoggerInfo;
  name: SimpleField<string>;
  id: SimpleField<number>;
  asset: MaintenanceAsset;
  context: MaintenanceTask;
  repository: IRepository;
  @observable historyRecords: MaintenanceCompleteRecordModel[];
  repositoryModel: IEntityRepository;
  repositoryHistory: IEntityRepository;
  requestId?: number;
  driverName: SimpleField<string>;

  constructor(
    {
      serviceType = { id: null, name: '' },
      notes = '',
      engineHoursOrServiceTimeDueInDays = 0,
      odometerOrEngineHoursDueInDays = 0,
      odometerOrServiceTimeDueInDays = 0,
      odometerOrEngineHoursOrServiceTimeDueInDays = 0,
      documents = [],
      creationTime = null,
      createdBy = '',
      lastUpdateTime = null,
      updatedBy = '',
      engineHoursDueInDays = 0,
      serviceTimeDueInDays = 0,
      odometerDueInDays = 0,
      odometerConfig = {},
      engHoursConfig = {},
      serviceTimeConfig = {},
      isCompleted = false,
      lastPerformedByPosition = '',
      lastPerformedByFullName = '',
      name = '',
      id = null,
      asset = {},
      historyRecords = [],
      requestId = null,
      driverName = '',
    },
    context: MaintenanceTask
  ) {
    this.context = context;
    this.odometer = new MaintenanceOdometerTracker({
      odometerConfig: {
        ...odometerConfig,
        unit: CONFIG_UNIT.ODOMETER,
      },
      odometerDueInDays,
    });
    this.service = new MaintenanceServiceTracker({
      serviceTimeDueInDays,
      serviceTimeConfig: {
        ...serviceTimeConfig,
        unit: CONFIG_UNIT.SERVICE_TIME,
      },
      serviceType,
    });
    this.engine = new MaintenanceEngineTracker({
      engHoursConfig: {
        ...engHoursConfig,
        unit: CONFIG_UNIT.ENG_HOURS,
      },
      engineHoursDueInDays,
    });
    this.mixed = new MaintenanceMixedTracker({
      engineHoursOrServiceTimeDueInDays,
      odometerOrEngineHoursDueInDays,
      odometerOrServiceTimeDueInDays,
      odometerOrEngineHoursOrServiceTimeDueInDays,
      lastPerformedByPosition,
      lastPerformedByFullName,
    });
    this.notes = new SimpleField(notes);
    this.documents = new AppFileList(repositoryService.get('maintenances'), documents, { id, name: 'maintenanceId' });
    this.loggerInfo = new MaintenanceLoggerInfo({ creationTime, createdBy, lastUpdateTime, updatedBy });
    this.isCompleted = new ToggleField(isCompleted);
    this.name = new SimpleField(name);
    this.id = new SimpleField(id);
    this.asset = new MaintenanceAsset(asset);
    this.driverName = new SimpleField(driverName);
    this.historyRecords = historyRecords.map((record) => new MaintenanceCompleteRecordModel(record, this));
    this.repository = repositoryService.get('maintenances');
    this.repositoryModel = this.repository?.entity(id);
    this.repositoryHistory = this.context?.context.repositoryHistory;
    this.requestId = requestId;
  }

  save = async () => {
    const data = await this.repository.create(this.data);

    if (this.repository.createState.success) {
      EventsBus.get().trigger(
        APP_EVENTS.MAINTENANCE.APPLY_TASKS.TASK.CREATED,
        `${this.service.type.item.name}: ${this.asset.name.value}`
      );

      this.id.set(data.id);
      this.onUpdate();
    } else if (this.repository.createState.error) {
      EventsBus.get().trigger(
        APP_EVENTS.MAINTENANCE.APPLY_TASKS.TASK.ERROR.CREATED,
        `${this.service.type.item.name}: ${this.asset.name.value}`
      );
    }
  };

  update = async () => {
    const serverData = await this.repository.patch({ ...this.data, id: this.id.value });
    this.onUpdate();

    return serverData;
  };

  delete = async () => {
    await this.repositoryModel.delete();
    this.onUpdate();
  };

  @action fetchHistoryRecord = async () => {
    const historyRecords = await this.repositoryHistory.get({ maintenanceId: this.id.value, order: 'DESC' });

    if (historyRecords) {
      this.historyRecords = historyRecords.items.map((record) => new MaintenanceCompleteRecordModel(record, this));
    }
  };

  resetHistoryRecord = (id, record) => {
    const recordIndex = this.historyRecords.findIndex((record) => record.id === id);

    if (recordIndex >= 0) {
      this.historyRecords[recordIndex] = new MaintenanceCompleteRecordModel(record, this);

      if (recordIndex === 0) {
        this.updateLatest(this.historyRecords[recordIndex]);
      }
    }
  };

  onUpdate = () => this.context.onUpdate();

  get isRepeat() {
    return Boolean(
      this.odometer.config.interval.value || this.engine.config.interval.value || this.service.config.interval.value
    );
  }

  get isValid() {
    if (this.isRepeat) {
      if (this.odometer.config.interval.value && !Boolean(this.odometer.config.last.value)) {
        return false;
      }

      if (this.engine.config.interval.value && !Boolean(this.engine.config.last.value)) {
        return false;
      }

      if (this.service.config.interval.value && !Boolean(this.service.config.last.value)) {
        return false;
      }
    }

    return true;
  }

  get validConfig() {
    return Boolean(this.odometer.config.next.value || this.engine.config.next.value || this.service.config.next.value);
  }

  get isUpdated() {
    return (
      this.name.isUpdated ||
      this.odometer.config.isUpdated ||
      this.service.config.isUpdated ||
      this.engine.config.isUpdated ||
      this.notes.isUpdated ||
      this.documents.isUpdated.value
    );
  }

  get data() {
    let odometerConfig = null;
    let engHoursConfig = null;
    let serviceTimeConfig = null;

    if (this.isRepeat) {
      odometerConfig = {
        last: this.odometer.config.model.last,
        interval: this.odometer.config.model.interval,
      };
      engHoursConfig = {
        last: this.engine.config.model.last,
        interval: this.engine.config.model.interval,
      };

      serviceTimeConfig = {
        last: this.service.config.model.last,
        interval: this.service.config.model.interval,
        intervalUnit: this.service.config.intervalUnit.value,
      };
    } else {
      odometerConfig = { next: this.odometer.config.model.next };
      engHoursConfig = { next: this.engine.config.model.next };
      serviceTimeConfig = { next: this.service.config.model.next };
    }

    return {
      serviceType: this.service.type.item.model,
      notes: this.notes.value,
      assetId: Number(this.asset.id.value),
      name: this.name.value,
      documentIds: this.documents.model.map((document) => document.id),
      odometerConfig,
      engHoursConfig,
      serviceTimeConfig,
    };
  }

  get serverData() {
    return {
      serviceType: this.service.type.item.model,
      notes: this.notes.value,
      engineHoursOrServiceTimeDueInDays: this.mixed.engineHoursOrServiceTimeDueInDays,
      odometerOrEngineHoursDueInDays: this.mixed.odometerOrEngineHoursDueInDays,
      odometerOrServiceTimeDueInDays: this.mixed.odometerOrServiceTimeDueInDays,
      odometerOrEngineHoursOrServiceTimeDueInDays: this.mixed.odometerOrEngineHoursOrServiceTimeDueInDays,
      documents: this.documents.model,
      created: this.loggerInfo.creationTime,
      createdBy: this.loggerInfo.createdBy,
      lastUpdateTime: this.loggerInfo.lastUpdateTime,
      updatedBy: this.loggerInfo.updatedBy,
      engineHoursDueInDays: this.engine.hoursDueInDays,
      serviceTimeDueInDays: this.service.timeDueInDays,
      odometerDueInDays: this.odometer.dueInDays,
      odometerConfig: this.odometer.config.model,
      engHoursConfig: this.engine.config.model,
      serviceTimeConfig: this.service.config.model,
      isCompleted: this.isCompleted.value,
      lastPerformedByPosition: this.mixed.lastPerformedByPosition,
      lastPerformedByFullName: this.mixed.lastPerformedByFullName,
      name: this.name.value,
      id: this.id.value,
      asset: this.asset.serverModel,
    };
  }

  get completedData() {
    return {
      id: null,
      values: {
        ...this.asset.details.serverModel,
        serviceTime: Date.now(),
      },
      name: this.name.value,
      maintenanceId: this.id.value,
      maintenanceDate: null,
      performedByFullName: '',
      preformedByPosition: '',
      partsCost: 0,
      laborCost: 0,
      otherCost: 0,
      notes: '',
      documents: this.documents.model,
      serviceType: this.service.type.item.model,
    };
  }

  private updateLatest = (record: MaintenanceCompleteRecordModel) => {
    if (record.values.odometer.value && this.odometer.config.interval.value) {
      this.odometer.config.updateLast(record.values.odometer.value);
    }

    if (record.values.engHours.value && this.engine.config.interval.value) {
      this.engine.config.updateLast(record.values.engHours.value);
    }

    if (record.values.serviceTime.value && this.service.config.interval.value) {
      this.service.config.updateLast(record.values.serviceTime.value);
    }
  };
}

export default MaintenanceModel;
