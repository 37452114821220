import React, { FC } from 'react';
import { observer } from 'mobx-react';

import type { SimpleField } from 'models/Fields';
import type { IDashcamSettingsEnumField } from 'interfaces/models/Dashcams/IDashcamSettingsEnumField';
import CameraSettingsItemRadio from '../CameraSettingsItemRadio';
import CameraSettingsItemSelect from '../CameraSettingsItemSelect';

interface IProps {
  item: IDashcamSettingsEnumField<SimpleField<string>>;
  disabled?: boolean;
}

const CameraSettingsItemEnum: FC<IProps> = ({ item, disabled }) => {
  return (
    <>
      {item.showMode === 'select' ? (
        <CameraSettingsItemSelect item={item} disabled={disabled} />
      ) : (
        <CameraSettingsItemRadio item={item} disabled={disabled} />
      )}
    </>
  );
};

export default observer(CameraSettingsItemEnum);
