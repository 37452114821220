import React, { FC, useState } from 'react';
import classnames from 'classnames';

import InspectionDownloadModal from 'components/Inspections/InspectionDownloadModal';
import InspectionEmailModal from 'components/Inspections/InspectionEmailModal';
import type InspectionPerformed from 'models/Inspections/Models/InspectionPerformed';
import type InspectionPerformedPDF from 'models/Inspections/Models/InspectionPerformedPDF';
import InspectionPrintModal from 'components/Inspections/InspectionPrintModal';
import PanelAction from 'components/SubNav/PanelAction';
import Loader from 'components/Loader';

import { PrintIcon, DownloadIcon, EmailIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  inspectionPerformed: InspectionPerformed;
}

const InspectionPerformedPanelActions: FC<IProps> = ({ className, inspectionPerformed }) => {
  const cn = classnames('InspectionPerformedPanelActions', className);
  const [pdfLink, setPdfLink] = useState<string>(null);
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
  const [downloadPdf, setDownloadPdf] = useState<InspectionPerformedPDF>(null);
  const [emailPdf, setEmailPdf] = useState<InspectionPerformedPDF>(null);
  const printPdf = async () => {
    setIsSubmitted(true);
    const link = await inspectionPerformed.pdf.getLink();

    setPdfLink(link);
    setIsSubmitted(false);
  };

  return (
    <div className={cn}>
      <div className="InspectionPerformedPanelActions-list">
        <div className="InspectionPerformedPanelActions-action InspectionPerformedPanelActions-action--print">
          <PanelAction icon={<PrintIcon />} onClick={printPdf}>
            Print
          </PanelAction>
        </div>
        <div className="InspectionPerformedPanelActions-action InspectionPerformedPanelActions-action--download">
          <PanelAction icon={<DownloadIcon />} onClick={() => setDownloadPdf(inspectionPerformed.pdf)}>
            Download
          </PanelAction>
        </div>
        <div className="InspectionPerformedPanelActions-action InspectionPerformedPanelActions-action--email">
          <PanelAction icon={<EmailIcon />} onClick={() => setEmailPdf(inspectionPerformed.pdf)}>
            Email
          </PanelAction>
        </div>
      </div>
      {isSubmitted && <Loader color="blue" />}
      <InspectionPrintModal isOpen={Boolean(pdfLink)} link={pdfLink} onClose={() => setPdfLink(null)} />
      <InspectionDownloadModal isOpen={Boolean(downloadPdf)} onClose={() => setDownloadPdf(null)} pdf={downloadPdf} />
      {Boolean(emailPdf) && (
        <InspectionEmailModal isOpen={Boolean(emailPdf)} onClose={() => setEmailPdf(null)} pdf={emailPdf} />
      )}
    </div>
  );
};

export default InspectionPerformedPanelActions;
