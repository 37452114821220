import React, { useRef, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { isNull } from 'lodash';

import './styles.scss';

interface IProps {
  children: React.ReactNode;
  pageStart?: number;
  loadMore?: (page: number) => void;
  hasMore?: boolean;
  loader?: React.ReactNode;
  useWindow?: boolean;
  customStyles?: any;
  scrollToElement?: boolean;
  elementPosition?: number;
}

const InfiniteScrollWrapper: React.FC<IProps> = ({
  children,
  pageStart,
  loadMore,
  hasMore,
  loader,
  useWindow,
  customStyles,
  scrollToElement,
  elementPosition,
}) => {
  const infiniteScrollWrapperNode: React.RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    const MANAGER_HEADER_HEIGHT = 84;

    if (scrollToElement && !isNull(elementPosition)) {
      infiniteScrollWrapperNode?.current?.scroll?.({
        top: elementPosition - MANAGER_HEADER_HEIGHT,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [elementPosition]);

  return (
    <div
      className="InfiniteScrollWrapper"
      style={{ overflow: 'auto', ...customStyles }}
      ref={infiniteScrollWrapperNode}
    >
      <InfiniteScroll
        initialLoad={false}
        pageStart={pageStart}
        loadMore={loadMore}
        hasMore={hasMore}
        loader={loader}
        useWindow={useWindow}
        threshold={50}
      >
        {children}
      </InfiniteScroll>
    </div>
  );
};

InfiniteScrollWrapper.defaultProps = {
  pageStart: 0,
  loadMore: () => null,
  hasMore: false,
  loader: null,
  useWindow: true,
};

export default InfiniteScrollWrapper;
