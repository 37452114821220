import { action, computed, observable } from 'mobx';

import uniqBy from 'lodash/uniqBy';
import { repositoryService } from 'services';

import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type { IWEXCard, IWEXCardServer } from 'interfaces/stores/Integrations/IWEXCardAssociations';
import type { IFuelDetailsIds } from 'interfaces/stores/Assets/IFuelDetails';
import FuelDetailsIds from 'models/Assets/FuelDetails/FuelDetailsIds';
import WEXCard from './WEXCard';
import { SimpleField } from 'models/Fields';

interface IWEXCardsSearchParams {
  cardUniqueCode?: string;
  name?: string;
}

class FuelCards {
  @observable items: IWEXCard[] = [];
  @observable itemsSummary: IWEXCard[] = [];
  @observable assets: IFuelDetailsIds;
  @observable total: number = 0;
  applyData: SimpleField<number>;

  repositoryIntegrations: IRepository;
  repositoryFuelCards: IEntityRepository;
  repositoryFuelCardsBatch: IEntityRepository;

  constructor() {
    this.assets = new FuelDetailsIds();
    this.applyData = new SimpleField(null);
    this.repositoryIntegrations = repositoryService.get('integrations');
    this.repositoryFuelCards = this.repositoryIntegrations.entity('fuel-cards');
    this.repositoryFuelCardsBatch = this.repositoryFuelCards.entity('batch');
  }

  @action fetch = async ({
    assetId,
    order,
    page,
    search,
    pageSize,
    sortBy,
    integrationId,
  }: {
    assetId?: number;
    order?: string;
    page?: number;
    pageSize?: number;
    search?: IWEXCardsSearchParams;
    sortBy?: string;
    integrationId?: number;
  }) => {
    const response = await this.repositoryFuelCards.get({
      assetId,
      order,
      page,
      pageSize,
      sortBy,
      integrationId,
      ...search,
    });

    if (this.repositoryFuelCards.getState.success) {
      const items = [];

      response.items.forEach((item) => {
        const assetId = this.getAssetId(item);
        const existWexCard = this.itemsSummary.find((itemSummary) => itemSummary.id === item.id);

        this.assets.addOption(item.asset);

        if (existWexCard) {
          items.push(existWexCard);
        } else {
          const wexCard = new WEXCard(item, this.assets.getOptionById(assetId)).initialize(this);

          items.push(wexCard);
          this.itemsSummary.push(wexCard);
        }
      });

      this.items = items;
      this.total = response.total;
    }
  };

  @computed get isVehicleAssignChanged() {
    return this.itemsSummary.some((item) => item.assetId.isUpdated);
  }

  @computed get isAssetsChanged() {
    return this.itemsSummary.some((item) => item.asset.fuelType.isUpdated || item.asset.tankCapacity.isUpdated);
  }

  cancel = () => {
    this.itemsSummary.forEach((item) => item.assetId.reset());
    this.assets.cancel();
    this.itemsSummary = [];
  };

  save = async () => {
    await this.repositoryFuelCardsBatch.patch({
      fuelCards: this.getFuelCardsList(),
      assets: this.getAssetsList(),
      applyDate: this.applyData.value,
    });

    this.itemsSummary = [];
    this.assets.resetInitials();
  };

  onRemove = (model: IWEXCard) => {
    const itemIndex = this.items.findIndex((item) => item.id === model.id);

    this.items.splice(itemIndex, 1);
    this.total -= 1;
  };

  getAssetId = (item: IWEXCardServer) => {
    return item.assetId ? item.assetId.toString() : '0';
  };

  private getFuelCardsList = () => {
    return this.itemsSummary
      .filter((item) => item.assetId.isUpdated)
      .map((item) => {
        return {
          assetId: this.getFormattedAssetId(item.assetId.value),
          id: item.id,
          provider: item.provider,
          cardUniqueCode: item.cardUniqueCode,
        };
      });
  };

  private getAssetsList = () => {
    const assets = this.itemsSummary
      .filter((item) => item.asset.fuelType.isUpdated || item.asset.tankCapacity.isUpdated)
      .map((item) => ({
        id: this.getFormattedAssetId(item.assetId.value),
        fuelType: item.asset.fuelType.value,
        tankCapacity: item.asset.tankCapacity.value,
      }));

    return uniqBy(assets, 'id');
  };

  private getFormattedAssetId = (value: string) => {
    const assetId = parseInt(value, 10);
    return assetId ? assetId : null;
  };
}

export default FuelCards;
