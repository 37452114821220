import React, { useState } from 'react';

import Button from 'components/Button';
import Modal from 'components/Modal';
import MediaLibraryRequestModal from '../MediaLibraryRequestModal';
import type MediaVehicleList from 'models/Dashcam/MediaLibrary/MediaSource/MediaVehicleList';

import './styles.scss';

interface IProps {
  vehicles: MediaVehicleList;
}

const MediaLibraryRequestMedia: React.FC<IProps> = ({ vehicles }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <div className="MediaLibraryRequestMedia">
      <Button title="Request Media" className="Button--apply Button--inline" onClick={() => setModalOpen(true)} />
      <Modal
        className="MediaLibraryRequestMedia-select"
        isModalOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        width={392}
      >
        <MediaLibraryRequestModal onClose={() => setModalOpen(false)} vehicles={vehicles} />
      </Modal>
    </div>
  );
};

export default MediaLibraryRequestMedia;
