import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import noop from 'lodash/noop';

import type { IAlert, ITableAlert } from 'models/Alerts/IAlert';
import type { AlertsTableStore, TimeStore } from 'stores';
import {
  ALERTS_TABLE_COLUMNS,
  ALERTS_TYPE,
  ALERT_MODAL_TYPE,
  STORAGE_ITEMS,
  CREATE_ALERT_BUTTON,
  ALERTS_TABLE,
  ROW_ACTIONS_EDIT_ALERT,
  ROW_ACTIONS_DELETE_ALERT,
  ROW_ACTIONS_CONVERT_TO_CUSTOM,
  DATE_TIME_FORMATS,
} from 'config';
import { setJSONItemToStorage, getJSONItemFromStorage, getFormattedTime } from 'utils';
import validateAccessLevel from 'stores/acl/validator';
import UserAccessLevelComponent from 'stores/acl/UserAccessLevelComponent';

import type { ColumnsType } from 'antd/es/table';
import Button from 'components/Button';
import CustomTable from 'components/Table/CustomTable';
import Toggle from 'components/Toggle';
import TableActions from 'components/Table/Actions';
import TableRecipients from 'components/Table/TableRecipients';
import DeleteAlertModal from 'components/Alert/AlertDeleteModal';
import Modal from 'components/Modal';
import AlertModalContainer from './AlertModalContainer';
import AlertCancelModal from 'components/Alert/AlertCancelModal';
import AlertConvertToCustomModal from 'components/Alert/AlertConvertToCustomModal';
import Notification from 'components/Notification';
import InfoTooltip from 'components/InfoTooltip';

import { CircleErrorIcon } from 'assets';

import './styles.scss';

interface IProps {
  alertsTableStore: AlertsTableStore;
  timeStore?: TimeStore;
}

interface IState {
  isFilterOpen: boolean;
  isModalOpen: boolean;
  isDeleteModalOpen: boolean;
  isCancelModalOpen: boolean;
  isConvertModalOpen: boolean;
  deleteAlert: IAlert;
  convertAlert: IAlert;
  alertModalType: ALERT_MODAL_TYPE;
  savedStep: number;
}

const editColumnsMap = {
  [ALERTS_TABLE_COLUMNS.NAME]: {
    text: 'Alert Name',
    isDisabled: true,
  },
  [ALERTS_TABLE_COLUMNS.TYPE]: {
    text: 'Alert Type',
    isDisabled: false,
  },
  [ALERTS_TABLE_COLUMNS.RECIPIENTS]: {
    text: 'Recipients',
    isDisabled: false,
  },
  [ALERTS_TABLE_COLUMNS.INTERVAL_RULE]: {
    text: 'Interval Rule',
    isDisabled: false,
  },
  [ALERTS_TABLE_COLUMNS.RULE_ID]: {
    text: 'Rule ID',
    isDisabled: false,
  },
  [ALERTS_TABLE_COLUMNS.ACTIVE]: {
    text: 'Active',
    isDisabled: false,
  },
  [ALERTS_TABLE_COLUMNS.CREATED_BY]: {
    text: 'Created By',
    isDisabled: false,
  },
  [ALERTS_TABLE_COLUMNS.CREATION_TIME]: {
    text: 'Time Created',
    isDisabled: false,
  },
  [ALERTS_TABLE_COLUMNS.UPDATED_BY]: {
    text: 'Modified By',
    isDisabled: false,
  },
  [ALERTS_TABLE_COLUMNS.LAST_UPDATE_TIME]: {
    text: 'Last Modified',
    isDisabled: false,
  },
};

@inject(({ alertsTableStore, timeStore }) => ({ alertsTableStore, timeStore }))
@observer
class Alerts extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isFilterOpen: getJSONItemFromStorage(STORAGE_ITEMS.alerts.filterOpenStatus, true),
      alertModalType: ALERT_MODAL_TYPE.CREATE,
      isModalOpen: props.alertsTableStore.isModalOpen.value || false,
      isDeleteModalOpen: false,
      isCancelModalOpen: false,
      isConvertModalOpen: false,
      deleteAlert: null,
      convertAlert: null,
      savedStep: props.alertsTableStore.savedStep.value || null,
    };
  }

  componentWillUnmount() {
    this.props.alertsTableStore.isModalOpen.toggle(false);
  }

  toggleAlert = (toggleIsActive, updateAlert) => {
    toggleIsActive();
    updateAlert();
  };

  getColumns = (): ColumnsType<ITableAlert> => {
    const {
      alertsTableStore: {
        columns,
        cronOptions: { names: cronOptionsNames },
        sortedColumn: { field, order },
      },
      timeStore: { sessionTimezone },
    } = this.props;

    const allColumns = [
      {
        title: 'Alert Name',
        dataIndex: ALERTS_TABLE_COLUMNS.NAME,
        defaultSortOrder: field === 'displayName' ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        render: (_, alert) => {
          const hasError = alert.type === ALERTS_TYPE.CUSTOM && alert.isValidSelector === false;
          return (
            <div className={hasError ? 'AlertsPageTable-title--withError' : 'AlertsPageTable-title'}>
              {hasError && (
                <InfoTooltip placement="bottomLeft" icon={<CircleErrorIcon />} className="AlertsPageTable-toolTip">
                  Syntax Error in Rule Selector
                </InfoTooltip>
              )}
              <Button title={alert.displayName} className="Button--link" onClick={() => this.editAlert(alert)} />
            </div>
          );
        },
        width: 180,
      },
      {
        title: 'Alert type',
        dataIndex: ALERTS_TABLE_COLUMNS.TYPE,
        width: 180,
        sorter: noop,
        className: 'ant-table-cell--no-sorter',
      },
      {
        title: 'Recipients',
        dataIndex: ALERTS_TABLE_COLUMNS.RECIPIENTS,
        render: (_, { notifyEmails, notifyMobileNumbers }) => (
          <TableRecipients items={[...notifyEmails, ...notifyMobileNumbers]} />
        ),
        width: 180,
        className: 'ant-table-cell--no-sorter',
      },
      {
        title: 'Interval Rule',
        dataIndex: ALERTS_TABLE_COLUMNS.INTERVAL_RULE,
        render: (_, { cronOption }) => <p>{cronOption ? cronOptionsNames[cronOption] || cronOption : '- -'}</p>,
        width: 180,
        className: 'ant-table-cell--no-sorter',
      },
      {
        title: 'Rule ID',
        dataIndex: ALERTS_TABLE_COLUMNS.RULE_ID,
        width: 80,
        className: 'ant-table-cell--no-filter',
        hiddenSearch: true,
      },
      {
        title: 'Active',
        dataIndex: ALERTS_TABLE_COLUMNS.ACTIVE,
        render: (_, { isActive, toggleIsActive, update }) => (
          <Toggle checked={isActive} onChange={() => this.toggleAlert(toggleIsActive, update)} />
        ),
        className: 'ant-table-cell--active',
        width: 120,
        hiddenSearch: true,
      },
      {
        title: 'Created By',
        dataIndex: ALERTS_TABLE_COLUMNS.CREATED_BY,
        width: 180,
        className: 'ant-table-cell--no-filter',
        hiddenSearch: true,
      },
      {
        title: 'Time Created',
        dataIndex: ALERTS_TABLE_COLUMNS.CREATION_TIME,
        width: 180,
        hiddenSearch: true,
        className: 'ant-table-cell--no-filter',
        render: (value) => (
          <>{value ? getFormattedTime(value, DATE_TIME_FORMATS.adminVehicleSettings, sessionTimezone) : '-'}</>
        ),
      },
      {
        title: 'Modified By',
        dataIndex: ALERTS_TABLE_COLUMNS.UPDATED_BY,
        width: 180,
        className: 'ant-table-cell--no-filter',
        hiddenSearch: true,
      },
      {
        title: 'Last Modified',
        dataIndex: ALERTS_TABLE_COLUMNS.LAST_UPDATE_TIME,
        width: 180,
        hiddenSearch: true,
        className: 'ant-table-cell--no-filter',
        render: (value) => (
          <>{value ? getFormattedTime(value, DATE_TIME_FORMATS.adminVehicleSettings, sessionTimezone) : '-'}</>
        ),
      },
    ];

    const filteredColumns = [];

    columns.forEach((column) => {
      if (column.isSelected) {
        filteredColumns.push(allColumns.find((col) => col.dataIndex === column.value));
      }
    });

    return [
      ...filteredColumns,
      {
        title: 'Actions',
        dataIndex: 'actions',
        width: 120,
        className: 'ant-table-cell--actions',
        render: (_, alert) => {
          const items = [
            validateAccessLevel([ROW_ACTIONS_EDIT_ALERT]) && {
              text: 'Edit Alert',
              onClick: () => this.editAlert(alert),
            },
            validateAccessLevel([ROW_ACTIONS_DELETE_ALERT]) && {
              text: 'Delete Alert',
              onClick: () => this.deleteAlert(alert),
            },
          ].filter((item) => item);

          if (
            alert.type !== ALERTS_TYPE.CUSTOM &&
            validateAccessLevel([ROW_ACTIONS_CONVERT_TO_CUSTOM]) &&
            alert.isLegacy
          ) {
            items.push({ text: 'Convert to Custom', onClick: () => this.convertAlert(alert) });
          }

          return <TableActions items={items} />;
        },
      },
    ];
  };

  editAlert = (loppedAlert) => {
    const {
      alertsTableStore: { setAlert, findById },
    } = this.props;

    if (validateAccessLevel([ROW_ACTIONS_EDIT_ALERT])) {
      const alert = findById(loppedAlert.key);

      setAlert(alert);

      this.setState({ alertModalType: ALERT_MODAL_TYPE.EDIT });
      this.handleModalOpen();
    }
  };

  deleteAlert = (loppedAlert) => {
    this.setState({ deleteAlert: loppedAlert, isDeleteModalOpen: true });
  };

  convertAlert = (loppedAlert) => {
    this.setState({ convertAlert: loppedAlert, isConvertModalOpen: true });
  };

  handleCloseDeleteModal = () => {
    this.setState({ isDeleteModalOpen: false, deleteAlert: null });
  };

  handleCloseCancelModal = () => {
    this.setState({ isCancelModalOpen: false, isModalOpen: true });
  };

  handleSubmitCancelModal = () => {
    const {
      alertsTableStore: { setAlert },
    } = this.props;

    setAlert(null);
    this.setState({ isCancelModalOpen: false, isModalOpen: false, savedStep: null });
  };

  handleCancelModal = () => {
    const {
      alertsTableStore: { alert, resetAlert },
    } = this.props;

    if (alert && alert.isUpdated()) {
      this.setState({ isCancelModalOpen: true, isModalOpen: false });
    } else {
      this.setState({ isModalOpen: false, savedStep: null });
      resetAlert();
    }
  };

  handleCloseConvertModal = () => {
    this.setState({ isConvertModalOpen: false, convertAlert: null });
  };

  handleOpenFilter = (isFilterOpen: boolean) => {
    this.setState({ isFilterOpen });
    setJSONItemToStorage(STORAGE_ITEMS.alerts.filterOpenStatus, isFilterOpen);
  };

  createNewAlert = () => {
    this.setState({ alertModalType: ALERT_MODAL_TYPE.CREATE });
    this.handleModalOpen();
  };

  handleModalOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleChangeSaveStep = (savedStep) => {
    this.setState({ savedStep });
  };

  onSuccessClose = () => {
    const {
      alertsTableStore: { isSuccessSave },
    } = this.props;

    isSuccessSave.toggle(false);
  };

  onErrorClose = () => {
    const {
      alertsTableStore: { isErrorSave },
    } = this.props;

    isErrorSave.toggle(false);
  };

  render() {
    const {
      alertsTableStore: {
        alertsTableSource,
        getAllAlerts,
        sortData,
        pagination,
        alertItemsTotal,
        setSearchColumn,
        setPagination,
        setSortedColumn,
        setColumns,
        searchColumns,
        searchData,
        columns,
        isSuccessSave,
        isErrorSave,
        repository,
        saveError,
        saveErrorTitle,
        isErrorDelete,
        isSuccessDelete,
      },
    } = this.props;
    const {
      isFilterOpen,
      isDeleteModalOpen,
      isCancelModalOpen,
      deleteAlert,
      convertAlert,
      isModalOpen,
      alertModalType,
      isConvertModalOpen,
      savedStep,
    } = this.state;

    const tableHeight = isFilterOpen ? 'calc(100vh - 300px)' : 'calc(100vh - 270px)';

    return (
      <div className="AlertsPage">
        <div className="AlertsPage-header">
          <div className="AlertsPage-header--title">
            <h3>Alerts</h3>
            <p>Create, edit, and manage alerts and who receives them.</p>
          </div>
          <UserAccessLevelComponent requiredAccessLevel={[CREATE_ALERT_BUTTON]}>
            <div className="AlertsPage-header--cta">
              <Button title="Create Alert" className="Button--apply" onClick={this.createNewAlert} />
            </div>
          </UserAccessLevelComponent>
        </div>
        <div className="AlertsPage-content">
          <UserAccessLevelComponent requiredAccessLevel={[ALERTS_TABLE]}>
            <CustomTable<ALERTS_TABLE_COLUMNS, IAlert>
              editColumnsMap={editColumnsMap}
              columns={this.getColumns()}
              dataSource={alertsTableSource}
              getData={getAllAlerts}
              loading={repository.getState.loading}
              onColumnsChange={setColumns}
              onColumnsSearch={setSearchColumn}
              onColumnSort={setSortedColumn}
              isFilterActive={isFilterOpen}
              onFilterOpen={this.handleOpenFilter}
              sortedColumn={sortData}
              searchColumns={searchColumns}
              searchParams={searchData}
              selectedColumns={toJS(columns)}
              onPaginationChange={setPagination}
              pagination={pagination}
              total={alertItemsTotal}
              totalTitle={`${alertItemsTotal} Alerts`}
              triggerUpdate={isSuccessSave.value || isSuccessDelete.value}
              withActions
              tableHeight={tableHeight}
            />
          </UserAccessLevelComponent>
        </div>
        <Modal isModalOpen={isModalOpen} onClose={this.handleCancelModal} className="AlertsPage-modal">
          {alertModalType && (
            <AlertModalContainer
              modalType={alertModalType}
              onClose={this.handleCloseModal}
              onChangeStep={this.handleChangeSaveStep}
              savedStep={savedStep}
            />
          )}
        </Modal>
        <DeleteAlertModal alert={deleteAlert} isOpen={isDeleteModalOpen} onClose={this.handleCloseDeleteModal} />
        <AlertCancelModal
          isOpen={isCancelModalOpen}
          onClose={this.handleCloseCancelModal}
          onSubmit={this.handleSubmitCancelModal}
        />
        <AlertConvertToCustomModal
          alert={convertAlert}
          isOpen={isConvertModalOpen}
          onClose={this.handleCloseConvertModal}
        />
        {isSuccessSave.value && (
          <Notification
            onClose={this.onSuccessClose}
            text="The alert was successfully saved."
            title="Success!"
            type="success"
          />
        )}
        {isErrorSave.value && (
          <Notification onClose={this.onErrorClose} text={saveError.value} title={saveErrorTitle.value} type="error" />
        )}
        {isSuccessDelete.value && (
          <Notification
            onClose={() => isSuccessDelete.toggle(false)}
            text="The Alert was successfully deleted."
            title="Success!"
            type="success"
          />
        )}
        {isErrorDelete.value && (
          <Notification
            onClose={() => isErrorDelete.toggle(false)}
            text="An error occurred while deleting the alert. Please try again."
            title="Delete Failed!"
            type="error"
          />
        )}
      </div>
    );
  }
}

export default Alerts;
