import { observable } from 'mobx';
import type { ItemRegistry } from 'models';
import { getParsedStorageItem, removeStorageItem, setStringifiedStorageItem } from 'utils';
import FilterItemRegistry from './FilterItemRegistry';
import type { IFilterItems } from './IFilterItems';
import { isEmpty } from 'lodash';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

class FilteredRegistry {
  private initialItems: ItemRegistry[];
  private filterLabel: string;
  private preselectedField: string;
  private storageName: string;
  @observable filterItems: IFilterItems = {};

  constructor(initialItems: ItemRegistry[], filterLabel: string, preselectedField?: string, storageName?: string) {
    this.initialItems = initialItems;
    this.filterLabel = filterLabel;
    this.preselectedField = preselectedField || null;
    this.storageName = storageName || null;
  }

  initialize(): FilteredRegistry {
    const storageData = getParsedStorageItem(this.storageName);

    this.initialItems.forEach((item) => {
      if (item.attributes && item.attributes[this.filterLabel]) {
        const eventName = item.attributes[this.filterLabel];
        const isChecked = isEmpty(storageData) ? false : storageData[eventName];
        this.filterItems[eventName] = this.filterItems[eventName] || new FilterItemRegistry(isChecked, this);
        this.filterItems[eventName].items.push(item);

        if (this.preselectedField && isEmpty(storageData)) {
          this.filterItems[eventName].setIsChecked(item.attributes[this.preselectedField]);
        }
      }
    });

    return this;
  }

  toggle = (eventName: string) => {
    if (this.filterItems.hasOwnProperty(eventName)) {
      this.filterItems[eventName].toggle();

      const storageValue = {};

      Object.keys(this.filterItems).forEach((key) => {
        storageValue[key] = this.filterItems[key].isChecked;
      });

      setStringifiedStorageItem(this.storageName, storageValue);
    }
  };

  reset = () => {
    Object.keys(this.filterItems).forEach((key) => {
      this.filterItems[key].setIsChecked(false);
    });

    removeStorageItem(this.storageName);
    EventsBus.get().trigger(APP_EVENTS.DEVICES.FILTER);
  };

  onChecked = () => {
    EventsBus.get().trigger(APP_EVENTS.DEVICES.FILTER);
  };

  isChecked = (metadata: ItemRegistry): boolean => {
    let checked = false;

    Object.keys(this.filterItems).forEach((key) => {
      if (this.filterItems[key].items.includes(metadata)) {
        checked = this.filterItems[key].isChecked;
      }
    });

    return checked;
  };

  isSomeChecked = () => {
    return Object.keys(this.filterItems).some((key) => this.filterItems[key].isChecked);
  };
}

export default FilteredRegistry;
