import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { pluralize } from 'utils';

import type { CustomersStore } from 'stores';
import { FilterIcon, SearchIcon } from 'assets';

import type { IIntegrationsStore } from 'interfaces/stores/Integrations/IIntegrationsStore';

import Input from 'components/Input';
import Checkbox from 'components/Checkbox';
import Tooltip from 'components/Tooltip';
import CustomerDomain from '../CustomerDomain';
import Label from 'components/Label';

import './styles.scss';


interface IProps {
  customersStore?: CustomersStore;
  integrationsStore?: IIntegrationsStore;
  getMoreItems: () => Promise<void>
}


@inject(({ customersStore, integrationsStore }) => ({
  customersStore,
  integrationsStore,
}))
@observer
class CustomersListSearch extends Component<IProps> {

  handleReset = () => {
    this.props.customersStore.resetDomains();
    return this.props.getMoreItems();
  }

  handleFilterValueChange = (e) => {
    this.props.customersStore.setFilterValue(e.target.value);
    return this.props.getMoreItems();
  }

  handleFilterDomainChange = (value) => {
    this.props.customersStore.toggleFilterDomain(value);
    return this.props.getMoreItems();
  }

  render() {
    const {
      customersStore: {
        filterValue,
        filterDomains,
      },
      integrationsStore: {
        activeIntegrationsWithCustomers,
      },
    } = this.props;

    const integrationsCount = activeIntegrationsWithCustomers.length;
    const hasActiveFilterDomains = filterDomains.length;
    const filterTitle = hasActiveFilterDomains ? pluralize(hasActiveFilterDomains, 'Filter') : '';

    return <div className="CustomersListSearch">
      <div
        className="CustomersListSearch-search"
      >
        <div className="CustomersListSearch-icon">
          <SearchIcon width="14px" height="14px" fill="#6B7A99" />
        </div>
        <Input
          placeholder=""
          value={filterValue}
          onChange={this.handleFilterValueChange}
          className="CustomersListSearch-input"
        />
        <div className="CustomersListSearch-label">
          <Label
            text="Beta"
            color="orange"
            rounded
            inline
            align="center"
            size="small"
          />
        </div>
      </div>
      <div
        className="CustomersListSearch-filter"
      >
        {integrationsCount > 1 && (
          <Tooltip
            className={classNames('CustomersListSearch-filterPupUp', {
              'CustomersListSearch-filterPupUp--active': hasActiveFilterDomains,
              'Tooltip--active': Boolean(hasActiveFilterDomains),
            })}
            position="bottomRight"
            Icon={FilterIcon}
            name={filterTitle}
            onReset={this.handleReset}
            forceClose
            withReset
          >
            <div >
              <ul className="CustomersListSearch-filterList">
                {activeIntegrationsWithCustomers.map((integration) => {
                  return (
                    <li className="CustomersListSearch-filterListItem" key={integration.domain}>
                      <div className="CustomersListSearch-filterCheckbox">
                        <Checkbox
                          value={integration.domain}
                          checked={Boolean(filterDomains.includes(integration.domain))}
                          onChange={this.handleFilterDomainChange}
                          label={
                            <>
                              <CustomerDomain
                                size={20}
                                domain={integration.domain}
                              />
                              <div className="CustomersListSearch-filterName">{integration.name}</div>
                            </>
                          }
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Tooltip>
        )}
      </div>
    </div>;
  }
}

export default CustomersListSearch;
