import React, { FC } from 'react';
import classNames from 'classnames';

import Button from 'components/Button';
import Modal from 'components/Modal';

import './styles.scss';

interface IProps {
  className?: string;
  isOpen: boolean;
  onCancel?: () => void;
  onSubmit: () => void;
  onOpen: () => void;
}

const DeleteModal: FC<IProps> = ({ className, isOpen, onCancel, onSubmit, onOpen }) => {
  const cn = classNames('DeleteModal', className);

  return (
    <Modal
      className="Modal--deleteVehicle Modal--withoutCloseIcon"
      isModalOpen={isOpen}
      onClose={onCancel}
      onOpen={onOpen}
    >
      <div className={cn}>
        <p className="DeleteModal-title">Delete Dispatch?</p>
        <p className="DeleteModal-text">
          This Dispatch link will no longer be visible to your driver and will be removed from the system.
          <br />
          Do you want to continue?
        </p>
        <div className="DeleteModal-buttons">
          <div className="DeleteModal-cancel">
            <Button className="Button--cancel Button--cancelColorLynch" title="Cancel" onClick={onCancel} />
          </div>
          <div className="DeleteModal-submit">
            <Button className="Button--apply" title="Delete now" onClick={onSubmit} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
