import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import Popup from 'components/Popup';

import './styles.scss';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  forceClose?: boolean;
  Icon?: React.ElementType;
  initialOpen?: boolean;
  name?: string;
  onChange?: (status: boolean) => void;
  onReset?: () => void;
  position?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom';
  withReset?: boolean;
}

const Tooltip: React.FC<IProps> = ({
  children,
  className,
  forceClose,
  Icon,
  initialOpen,
  name = 'Filter',
  onChange,
  onReset,
  position,
  withReset,
}) => {
  const [isOpen, setOpen] = useState(initialOpen);
  const cn = classNames('Tooltip', className, {
    'Tooltip--open': isOpen,
    'Tooltip--withReset': withReset,
  });
  const popupCn = classNames('Tooltip-popup', {
    [`Tooltip-popup--${className.replace('Tooltip--', '')}`]: Boolean(className),
  });

  const handleOpen = () => {
    onChange(true);
    setOpen(true);
  };

  const handleClose = () => {
    onChange(false);
    setOpen(false);
  };

  const handleReset = (event) => {
    event.stopPropagation();

    onReset();
    if (forceClose) {
      handleClose();
    }
  };

  const handleVisibleChange = (isVisible) => {
    onChange(isVisible);
    setOpen(isVisible);
  };

  useEffect(() => {
    if (forceClose && initialOpen === false) {
      setOpen(false);
    }
  }, [initialOpen]);

  return (
    <div className={cn}>
      <Popup
        className={popupCn}
        onVisibleChange={handleVisibleChange}
        triggerElement={
          <div className="Tooltip-title">
            {Boolean(Icon) && <Icon className="Tooltip-titleIcon" />}
            <span className="Tooltip-titleText">{name}</span>
            {Boolean(withReset) && <span className="Tooltip-reset" onClick={handleReset} />}
          </div>
        }
        isOpen={isOpen}
        onOpen={handleOpen}
        placement={position}
      >
        <div className="Tooltip-content">{children}</div>
      </Popup>
    </div>
  );
};

Tooltip.defaultProps = {
  name: 'Tooltip title',
  children: 'Tooltip content',
  className: '',
  initialOpen: false,
  onChange: () => null,
  onReset: () => null,
  position: 'bottomLeft',
};

export default Tooltip;
