import React, { useRef, useEffect } from 'react';
import AntInput, { InputProps } from 'antd/lib/input';
import classNames from 'classnames';
import { getIcon } from './helper';

import './styles.scss';

interface IProps extends InputProps {
  autoComplete?: string;
  className?: string;
  disabled?: boolean;
  error?: string;
  field?: any;
  icon?: string | JSX.Element;
  iconPosition?: string;
  label?: string;
  name?: string;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onIconClick?: () => void;
  placeholder?: string;
  size?: 'small' | 'middle';
  type?: string;
  value?: string;
  isFocus?: boolean;
  hideErrorMessage?: boolean;
  loading?: boolean;
}

const Input: React.FC<IProps> = ({
  autoComplete = 'off',
  className,
  disabled = false,
  error,
  field,
  icon,
  iconPosition,
  label,
  maxLength,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  size,
  type,
  value = '',
  isFocus = false,
  hideErrorMessage,
  loading = false,
}) => {
  const hasError = Boolean(error);
  const cn = classNames('Input', className, {
    'Input--error': hasError,
    [`Input--${size}`]: Boolean(size),
    'Input--disabled': disabled,
    'Input--loading': loading,
    [`Input--${iconPosition}Icon`]: Boolean(iconPosition),
  });
  const handlers = field ? field : { value, name, onChange, onBlur };
  const inputRef = useRef(null);

  useEffect(() => {
    if (isFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isFocus]);

  return (
    <div className={cn}>
      {Boolean(label) && <p className="Input-label">{label}</p>}
      <AntInput
        className="Input-input"
        disabled={disabled}
        {...getIcon(icon, iconPosition)}
        maxLength={maxLength}
        onFocus={onFocus}
        placeholder={placeholder}
        type={type}
        {...handlers}
        data-lpignore={autoComplete === 'off' ? 'true' : undefined}
        autoComplete={autoComplete}
        ref={inputRef}
      />
      {hasError && !hideErrorMessage && <p className="Input-error">{error}</p>}
    </div>
  );
};

export default Input;
