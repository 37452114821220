import React from 'react';
import { observer } from 'mobx-react';
import ReactPlayer from 'react-player';
import { PauseIcon } from 'assets';
import EventsBus from 'services/EventsBus/eventsBus';
import { CAMERA_PLAYER_EVENTS } from 'config';

import './styles.scss';

interface IProps {
  isPlaying: boolean;
  isMuted: boolean;
  url: string;
  onClick: () => void;
  isShowPauseLayout?: boolean;
}

const MediaManagerPlayerVideo: React.FC<IProps> = ({ url, isPlaying, isMuted, onClick, isShowPauseLayout = true }) => {
  return (
    <div className="MediaManagerPlayerVideo">
      {isShowPauseLayout && isPlaying && (
        <div className="MediaManagerPlayerVideo-pause" onClick={onClick}>
          <PauseIcon className="MediaManagerPlayerVideo-pause--icon" width={28} height={24} />
          <div className="MediaManagerPlayerVideo-pause--button" />
        </div>
      )}
      <ReactPlayer
        url={url}
        playing={isPlaying}
        muted={isMuted}
        width="100%"
        onStart={() => EventsBus.get().trigger(CAMERA_PLAYER_EVENTS.PLAY_START)}
      />
    </div>
  );
};

export default observer(MediaManagerPlayerVideo);
