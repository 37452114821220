import React, { FC, useState, useCallback, useEffect, useMemo } from 'react';

import Button from 'components/Button';
import ConfirmationModal from 'components/Admin/ConfirmationModal';
import InfoTooltip from 'components/InfoTooltip';
import Label from 'components/Label';
import PhoneNumberInput from 'components/PhoneNumberInput';
import TextArea from 'components/Textarea';

import './styles.scss';

interface IProps {
  className?: string;
  isDisabled?: boolean;
  isOpen: boolean;
  note?: string;
  onCancel: () => void;
  onSubmit: (phone: string, message: string) => void;
  phoneNumber?: string;
  text?: any;
  type: 'Vehicle' | 'Geozone' | '';
}

const MAX_LENGTH = 200;

const TextLocationModal: FC<IProps> = ({
  isOpen,
  note = '',
  onCancel,
  onSubmit,
  phoneNumber = '',
  text = '',
  type = '',
}) => {
  const [isNoteAdded, setNoteAdded] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phone, setPhone] = useState(phoneNumber);
  const [textMessage, setTextMessage] = useState(text);
  const handleClickOnAddNote = useCallback(() => {
    const text = textMessage ? `${textMessage}\n${note}` : note;
    setNoteAdded(true);
    setTextMessage(text.substring(0, MAX_LENGTH));
  }, [textMessage, note]);
  const handleTextMessageChange = useCallback((message) => setTextMessage(message), []);
  const handleShareLocation = useCallback(() => {
    onSubmit(phone, textMessage);
  }, [phone, textMessage, onSubmit]);

  const counter = useMemo(() => {
    switch (true) {
      case textMessage.length <= 160:
        return `${textMessage.length}/160`;
      case textMessage.length <= 306:
        return `(2) ${textMessage.length}/306`;
      case textMessage.length <= 459:
        return `(3) ${textMessage.length}/459`;
      case textMessage.length <= 612:
        return `(4) ${textMessage.length}/612`;
    }
  }, [textMessage]);

  useEffect(() => {
    /*reset modal values on closing*/
    if (!isOpen) {
      setNoteAdded(false);
      setTextMessage(text);
    }
  }, [isOpen, text]);

  return (
    <ConfirmationModal
      applyButtonTitle="Send"
      cancelButtonStyles={{ border: 'none' }}
      cancelButtonTitle="Cancel"
      closeOutside={false}
      isOpen={isOpen}
      isSubmitDisabled={phoneError || !phone || !textMessage}
      onCancel={onCancel}
      onSubmit={handleShareLocation}
      title={`Share ${type} Location`}
    >
      <div className="TextLocationModal">
        <div className="TextLocationModal-section">
          <div className="TextLocationModal-sectionLabel">
            <Label text="Send Text to" size="large" />
          </div>
          <div className="TextLocationModal-sectionContent TextLocationModal-sectionContent--phoneNumber">
            <div className="TextLocationModal-phoneNumberInput">
              <PhoneNumberInput onChange={setPhone} onError={setPhoneError} />
            </div>
            <div className="TextLocationModal-phoneNumberInputTooltip">
              <InfoTooltip>Standard messaging and data rates apply. US mobile phone numbers only.</InfoTooltip>
            </div>
          </div>
        </div>
        <div className="TextLocationModal-section TextLocationModal-section--text">
          <div className="TextLocationModal-sectionLabel TextLocationModal-sectionLabel--text">
            <Label text="Edit Text" size="large" />
          </div>
          <div className="TextLocationModal-sectionContent">
            <div className="TextLocationModal-text">
              <TextArea
                counter={counter}
                error={textMessage ? '' : 'Invalid text message'}
                maxLength={MAX_LENGTH}
                onChange={handleTextMessageChange}
                value={textMessage}
              />
            </div>
            {note && (
              <div
                className={
                  textMessage
                    ? 'TextLocationModal-addNoteButton'
                    : 'TextLocationModal-addNoteButton TextLocationModal-addNoteButton--top'
                }
              >
                <Button
                  className="Button--link"
                  disabled={isNoteAdded}
                  onClick={handleClickOnAddNote}
                  title={`Add ${type} Note`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </ConfirmationModal>
  );
};

export default TextLocationModal;
