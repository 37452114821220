import React from 'react';
import classNames from 'classnames';

import LoginSlide from '../SlideWrapper';

import './styles.scss';

interface IProps {
  className?: string;
}

const LoginSlideTwo: React.FC<IProps> = ({ className }) => {
  const cn = classNames('LoginSlideTwo', className);

  return (
    <div className={cn}>
      <LoginSlide className="LoginSlide--slideTwo">
        <p className="LoginSlide-title">We Love Customer Referrals!</p>
        <p className="LoginSlide-message">
          As a thank you, you'll get a gift card plus up to $1,000 if your referral becomes a customer!
        </p>
        <a
          className="LoginSlide-link"
          href="https://www.clearpathgps.com/refer/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </a>
      </LoginSlide>
    </div>
  );
};

export default LoginSlideTwo;
