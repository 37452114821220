import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';

import { PATHS } from 'config';

import type { CustomersStore } from 'stores';

import SecondaryPanelJob from './SecondaryPanelJob';
import SecondaryPanelInvoice from './SecondaryPanelInvoice';
import SecondaryPanelEstimate from './SecondaryPanelEstimate';

interface IProps {
  customersStore?: CustomersStore;
}

@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class SecondaryPanel extends Component<IProps> {
  render() {
    const {
      customersStore: {
        selectedCustomer,
      },
    } = this.props;

    if (!selectedCustomer) {
      return null;
    }

    return <>
      <Switch>
        <Route path={PATHS.MAP.CUSTOMER_JOB} component={SecondaryPanelJob} />
        <Route path={PATHS.MAP.CUSTOMER_INVOICE} component={SecondaryPanelInvoice} />
        <Route path={PATHS.MAP.CUSTOMER_ESTIMATE} component={SecondaryPanelEstimate} />
      </Switch>
    </>;
  }
}

export default SecondaryPanel;
