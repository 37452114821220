import type { IAppFile } from 'models/Files/IAppFile';
import { SimpleField, ToggleField } from '../../Fields';
import type InspectionFormItems from './InspectionFormItems';
import AppFile from 'models/Files/AppFile';
import { repositoryService } from 'services';
import type { INSPECTION_ITEM_STATUSES } from 'config';

class InspectionItem {
  id: number;
  name: SimpleField<string>;
  instruction: SimpleField<string>;
  isInstructionVisible: ToggleField;
  requireNote: ToggleField;
  requirePhoto: ToggleField;
  requirePassFail: ToggleField;
  context: InspectionFormItems;
  status: INSPECTION_ITEM_STATUSES;
  notes: string;
  attachments: IAppFile[];
  dragIndex: number;

  constructor(
    { id, name, description, requireNote, requirePhoto, requirePassFail, status, notes, attachments } = {
      id: 0,
      name: '',
      description: '',
      requireNote: false,
      requirePhoto: false,
      requirePassFail: false,
      status: null,
      notes: '',
      attachments: [],
    },
    context
  ) {
    this.context = context;
    this.id = id;
    this.name = new SimpleField(name);
    this.instruction = new SimpleField(description);
    this.isInstructionVisible = new ToggleField(Boolean(description?.length));
    this.requireNote = new ToggleField(requireNote);
    this.requirePhoto = new ToggleField(requirePhoto);
    this.requirePassFail = new ToggleField(requirePassFail);
    this.status = status;
    this.notes = notes;
    this.dragIndex = Date.now();

    this.attachments = attachments?.map(
      (attachment) =>
        new AppFile(repositoryService.get('dvir').entity('attachment').entity('item').entity(id.toString()), {
          ...attachment,
          type: attachment.url.includes('.heic') ? 'image/heic' : 'image/*',
        })
    );
  }

  toggleRequireNote = () => {
    if (this.requireNote.value) {
      this.context.requireAllNotes.toggle(false);
    }

    this.requireNote.toggle();
  };

  toggleRequirePhoto = () => {
    if (this.requirePhoto.value) {
      this.context.requireAllPhotos.toggle(false);
    }

    this.requirePhoto.toggle();
  };

  toggleRequirePassFail = () => {
    if (this.requirePassFail.value) {
      this.context.requireAllPassFail.toggle(false);
    }

    this.requirePassFail.toggle();
  };

  get isUpdated() {
    return (
      this.name.isUpdated ||
      this.instruction.isUpdated ||
      this.requirePhoto.isUpdated ||
      this.requireNote.isUpdated ||
      this.requirePassFail.isUpdated
    );
  }

  get data() {
    return {
      ...(this.id ? { id: this.id } : {}),
      name: this.name.value,
      description: this.instruction.value,
      requirePhoto: this.requirePhoto.value,
      requireNote: this.requireNote.value,
      requirePassFail: this.requirePassFail.value,
    };
  }

  get serverData() {
    return { ...this.data, id: this.id };
  }
}

export default InspectionItem;
