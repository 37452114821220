import React, { FC, SVGProps } from 'react';
import { inject, observer } from 'mobx-react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import validateAccessLevel from 'stores/acl/validator';
import { AdminStore, UserAccessLevelComponent } from 'stores';
import type IntegrationsStore from 'stores/Integrations/IntegrationsStore';
import {
  FEATURES,
  PATHS,
  ADMIN_STORE,
  ADMIN_USERS_UPDATE,
  ADMIN_GROUPS_UPDATE,
  ADMIN_GEOZONES_READ,
  WEB_DASHCAM_BASIC_READ,
  ADMIN_NAV_SELECTION,
  ADMIN_INTEGRATIONS_DELETE,
  INTEGRATIONS_STORE,
} from 'config';

import AdminNavigationCollapsibleButton from 'components/Admin/AdminNavigationCollapsibleButton';

import {
  DeviceIcon,
  Geozone2Icon,
  UserIcon,
  Vehicle2Icon,
  AccountIcon,
  GroupsTwoVehiclesIcon,
  MediaNavIcon,
  IntegrationIcon,
} from 'assets';
import { setCookie } from 'utils';

import './styles.scss';

interface IProps extends RouteComponentProps {
  className?: string;
  adminStore?: AdminStore;
  integrationsStore?: IntegrationsStore;
}

interface INavigationItem {
  Icon: FC<SVGProps<SVGSVGElement>>;
  text: string;
  path: string;
  hidden: boolean;
}

@inject(ADMIN_STORE, INTEGRATIONS_STORE)
@observer
class AdminNavigation extends React.Component<IProps> {
  handleCLick = () => {
    this.props.adminStore.subNavigation.toggle();
  };

  handleLinkClick = (event) => {
    setCookie(ADMIN_NAV_SELECTION, event.currentTarget.href.replace(/.+\/web/gi, ''));
  };

  render() {
    const isExpanded = this.props.adminStore.subNavigation.expanded;

    const cn = classNames('AdminNavigation', this.props.className, {
      'AdminNavigation--expanded': isExpanded,
      'AdminNavigation--collapsed': !isExpanded,
    });

    const navigationData: INavigationItem[] = [
      {
        Icon: MediaNavIcon,
        text: 'Dashcams',
        path: PATHS.ADMIN.TABLES.DASHCAMS.INDEX,
        hidden: !validateAccessLevel([WEB_DASHCAM_BASIC_READ]) && FEATURES.dashcamsAdmin,
      },
      {
        Icon: DeviceIcon,
        text: 'Devices',
        path: PATHS.ADMIN.TABLES.DEVICES,
        hidden: true,
      },
      {
        Icon: Geozone2Icon,
        text: 'Geozones',
        path: PATHS.ADMIN.TABLES.GEOZONES.INDEX,
        hidden: !(FEATURES.geozonesAdmin && validateAccessLevel([ADMIN_GEOZONES_READ])),
      },
      {
        Icon: GroupsTwoVehiclesIcon,
        text: 'Groups',
        path: PATHS.ADMIN.TABLES.GROUPS.INDEX,
        hidden: !(FEATURES.groupsAdmin && validateAccessLevel([ADMIN_GROUPS_UPDATE])),
      },
      {
        Icon: UserIcon,
        text: 'People',
        path: PATHS.ADMIN.TABLES.PEOPLE.INDEX,
        hidden: !validateAccessLevel([ADMIN_USERS_UPDATE]),
      },
      {
        Icon: Vehicle2Icon,
        text: 'Vehicles',
        path: PATHS.ADMIN.TABLES.VEHICLES.INDEX,
        hidden: !FEATURES.vehiclesAdmin,
      },
      {
        Icon: AccountIcon,
        text: 'Account',
        path: PATHS.ADMIN.TABLES.ACCOUNT,
        hidden: true,
      },
    ];

    return (
      <div className={cn}>
        <div className="AdminNavigation-header">
          <p className="AdminNavigation-title">Admin</p>
        </div>
        <div className="AdminNavigation-navigation">
          <ul className="AdminNavigation-navigationList">
            {navigationData
              .filter((item) => !item.hidden)
              .map(({ Icon, text, path }) => (
                <li
                  className={`AdminNavigation-navigationItem AdminNavigation-navigationItem--${text.toLowerCase()}`}
                  key={text}
                >
                  <NavLink
                    to={path}
                    className={`AdminNavigation-navigationItemLink AdminNavigation-navigationItemLink--${text.toLowerCase()}`}
                    activeClassName="AdminNavigation-navigationItemLink--active"
                    onClick={this.handleLinkClick}
                  >
                    <span className="AdminNavigation-navigationItemLinkIcon">
                      <Icon className="AdminNavigation-navigationItemLinkIconImage" />
                    </span>
                    <span className="AdminNavigation-navigationItemLinkText">{text}</span>
                  </NavLink>
                </li>
              ))}
          </ul>
          <UserAccessLevelComponent requiredAccessLevel={[ADMIN_INTEGRATIONS_DELETE]}>
            <ul className="AdminNavigation-navigationList AdminNavigation-navigationList--second">
              <li className="AdminNavigation-navigationItem AdminNavigation-navigationItem--integrations">
                <NavLink
                  to={PATHS.ADMIN.INTEGRATIONS.INDEX}
                  className={`AdminNavigation-navigationItemLink AdminNavigation-navigationItemLink--integrations`}
                  activeClassName="AdminNavigation-navigationItemLink--active"
                  onClick={this.handleLinkClick}
                >
                  <span
                    className={classNames('AdminNavigation-navigationItemLinkIcon', {
                      'AdminNavigation-navigationItemLinkIcon--expired': this.props.integrationsStore
                        .hasExpiredIntegrations,
                    })}
                  >
                    <IntegrationIcon className="AdminNavigation-navigationItemLinkIconImage" />
                  </span>
                  <span className="AdminNavigation-navigationItemLinkText">Integrations</span>
                </NavLink>
              </li>
            </ul>
          </UserAccessLevelComponent>
        </div>
        <AdminNavigationCollapsibleButton isExpanded={isExpanded} onClick={this.handleCLick} />
      </div>
    );
  }
}

export default AdminNavigation;
