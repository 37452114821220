import { observable } from 'mobx';

export class Dispatch {
  @observable destination: { address: string; longitude: number; latitude: number };
  @observable dispatchDate: string;
  @observable dispatchId: string = '';
  @observable dispatchNotes: string;
  @observable fieldNotes: string;
  @observable driverContactPhone: string;
  @observable driverId: string;
  @observable driverName: string;
  @observable orderNumber: number;
  @observable status: string;
  @observable completeTime: string;

  constructor({
    destination: { address, longitude, latitude },
    dispatchDate,
    dispatchId,
    dispatchNotes,
    fieldNotes,
    driverContactPhone,
    driverId,
    driverName,
    orderNumber,
    status,
    completeTime,
  }: Dispatch.IServerDispatch) {
    this.completeTime = completeTime;
    this.destination = { address, latitude, longitude };
    this.dispatchDate = dispatchDate;
    this.dispatchDate = dispatchDate;
    this.dispatchId = dispatchId.toString();
    this.dispatchNotes = dispatchNotes;
    this.fieldNotes = fieldNotes;
    this.driverContactPhone = driverContactPhone;
    this.driverId = driverId;
    this.driverName = driverName;
    this.orderNumber = orderNumber;
    this.status = status || '';
  }
}

export default Dispatch;
