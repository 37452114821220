import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Input from 'components/Input';
import type { TimeField } from 'models/Fields';

import './styles.scss';

interface IProps {
  title?: string;
  className?: string;
  isEditable?: boolean;
  field: TimeField;
  userDate?: boolean;
}

const AdminTimeField: React.FC<IProps> = ({ isEditable = false, title = '', className, field, userDate }) => {
  const cn = classnames('AdminTimeField', className);

  return (
    <div className={cn}>
      <p>{title}</p>
      <Input disabled={!isEditable} value={field.value ? (userDate ? field.userDate : field.date) : '-'} />
    </div>
  );
};

export default observer(AdminTimeField);
