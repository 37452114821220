import React, { FC, useState, useMemo } from 'react';
import classNames from 'classnames';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';

import SelectAccountModal from 'containers/Auth/SelectAccountModal';

import Input from 'components/Input';
import Button from 'components/Button';

import { EyeCrossedIcon, EyeIcon } from 'assets';

import './styles.scss';

interface ILoginValues {
  email: string;
  password: string;
  accountId: string;
}

interface IProps {
  className?: string;
  initialValues?: ILoginValues;
  onSubmit: (values: ILoginValues) => void;
  onReset?: () => void;
  sending?: boolean;
  sent?: boolean;
  isMultiLogin?: boolean;
}

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const UNAUTHORIZED = 'Unauthorized';

const LoginForm: FC<IProps> = ({
  className,
  initialValues,
  onSubmit,
  sending,
  sent = false,
  isMultiLogin = false,
  onReset = () => void 0,
}) => {
  const [visiblePassword, setVisiblePassword] = useState(false);
  const cn = classNames('LoginForm', className);
  const email = get(initialValues, 'email', '');
  const password = get(initialValues, 'password', '');
  const renderIcon = useMemo(() => {
    return visiblePassword ? (
      <EyeIcon fill="#6B7A99" cursor="pointer" onClick={() => setVisiblePassword(false)} />
    ) : (
      <EyeCrossedIcon fill="#6B7A99" cursor="pointer" onClick={() => setVisiblePassword(true)} />
    );
  }, [visiblePassword]);
  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    try {
      await onSubmit(values);
    } catch (e) {
      setFieldError('email', UNAUTHORIZED);
      setFieldError('password', UNAUTHORIZED);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={cn}>
      <Formik
        validationSchema={LoginSchema}
        onSubmit={handleSubmit}
        initialValues={{ email, password, accountId: null }}
        render={({ errors, touched, isValid, values }: FormikProps<ILoginValues>) => (
          <Form>
            <div className="LoginForm-field">
              <Field
                autoComplete="email"
                component={Input}
                error={Boolean(errors.email && touched.email) ? errors.email : ''}
                hideErrorMessage={errors.email === UNAUTHORIZED}
                name="email"
                placeholder="Email"
              />
            </div>
            <div className="LoginForm-field">
              <Field
                autoComplete={isMultiLogin ? 'off' : 'password'}
                component={Input}
                error={Boolean(errors.password && touched.password) ? errors.password : ''}
                hideErrorMessage={errors.password === UNAUTHORIZED}
                icon={renderIcon}
                iconPosition="right"
                name="password"
                placeholder="Password"
                type={visiblePassword ? 'text' : 'password'}
              />
            </div>
            {isMultiLogin && (
              <SelectAccountModal
                open={isMultiLogin}
                email={values.email}
                password={values.password}
                onSubmit={onSubmit}
                sending={sending}
                onClose={onReset}
              />
            )}
            <div className="LoginForm-submit">
              <Button
                className="Button--apply"
                title="Login"
                type="submit"
                disabled={!isValid}
                sending={sending && !isMultiLogin}
                sent={(isMultiLogin || sent) && isValid}
              />
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default LoginForm;
