export default ({ color, colorName, orderNumber }) => {
  const fill = colorName.toLowerCase() === 'yellow' || colorName.toLowerCase() === 'white' ? 'black' : 'white';
  return `
<svg width="20" height="21" viewBox="0 0 20 21" fill="${color}" xmlns="http://www.w3.org/2000/svg">
  <circle cx="10" cy="10" r="10" />
  <circle cx="10" cy="10" r="9" stroke-opacity="0.3" stroke="black" stroke-width="2" />
  <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="${fill}" font-size="${
    orderNumber >= 10 ? 11 : 12
  }"
        font-family="Avenir Next, Helvetica, Arial, sans-serif" font-weight="700">${orderNumber}</text>
</svg>
`;
};
