import { getSVGTextWidth } from 'utils';
import { escape } from 'lodash';
import renderHeadingArrow from '../renderHeadingArrow';

const activeBorder = (textWidth, outline) => {
  const centerWidth = textWidth - 4;
  const fillColor = outline ? outline : '#4285f4';

  const left = `<path d=" M 10.625 7 C 9.175 7 8 8.175 8 9.625 L 8 30.625 C 8 32.075 9.175 33.25 10.625 33.25 L 18.261 33.25 L 23.313 42 L 28.364 33.25 L 36 33.25 L 36 31.5 L 27.354 31.5 L 23.313 38.5 L 19.271 31.5 L 10.625 31.5 C 10.142 31.5 9.75 31.108 9.75 30.625 L 9.75 9.625 C 9.75 9.142 10.142 8.75 10.625 8.75 L 36 8.75 L 36 7 L 10.625 7 Z " fill-rule="evenodd" fill="${fillColor}"/>`;
  const right = `<path d=" M 8 31.496 L 14.124 31.496 C 14.607 31.496 14.999 31.105 14.999 30.621 L 14.999 9.625 C 14.999 9.141 14.607 8.75 14.124 8.75 L 8 8.75 L 8 7 L 14.124 7 C 15.574 7 16.749 8.175 16.749 9.625 L 16.749 30.621 C 16.749 32.071 15.574 33.246 14.124 33.246 L 8 33.246 L 8 31.496 Z " fill-rule="evenodd" fill="${fillColor}" transform="translate(${textWidth +
    24} 0)" />`;
  const center = `<g clip-path="url(#_clipPath_EolLdsAjBNzGUGbZiKTelNjTjgLvn9VB)" transform="translate(36, 7)"><path d=" M 0 24.496 L 0 26.246 L ${centerWidth} 26.246 L ${centerWidth} 24.496 L 0 24.496 Z  M ${centerWidth} 1.75 L 0 1.75 L 0 0 L ${centerWidth} 0 L ${centerWidth} 1.75 Z " fill-rule="evenodd" fill="${fillColor}"/></g>`;
  return left + center + right;
};

export default ({ arrowColor, name, statusColor, active = false, outline = '', heading }) => {
  const nameToShow = name.length > 36 ? `${name.substring(0, 35)}...` : name;
  const escapedNameToShow = escape(nameToShow);

  const textWidth = getSVGTextWidth(nameToShow, '600 12px "Avenir Next"') + 5;

  return `<svg fill="none" xmlns="http://www.w3.org/2000/svg" width="${60 + textWidth}" height="50" viewBox="0 0 ${60 +
    textWidth} 50">
          <g filter="url(#filter0_ddd)">
            <path d="M8,30V10c0-1.7,1.3-3,3-3h23v26h-5.9l-4.6,8l-4.6-8H11C9.3,33,8,31.7,8,30z"
                  fill="white"/>
            <rect xmlns="http://www.w3.org/2000/svg" x="33" y="7" width="${textWidth + 1}" height="26" fill="white"/>
            <path d="M40,30V10c0-1.7-1.3-3-3-3h-4v26h4C38.7,33,40,31.7,40,30z" fill="white" transform="translate(${textWidth} 0)"/>
          </g>
          <circle cx="20.5" cy="19.5" r="8" fill="#${statusColor}"/>
          ${heading ? renderHeadingArrow(arrowColor, heading, '0.85', '12 11') : null}
          <text id="Stevens_car" data-name="Stevens car" fill="#2B2E41" transform="translate(34 24)" font-size="12"
                font-family="Avenir Next, Helvetica, Arial, sans-serif" font-weight="600">
            ${escapedNameToShow}
          </text>
          ${active === true ? activeBorder(textWidth, outline) : null}
          <defs>
            <filter id="filter0_ddd" x="0" y="0" width="${49 + textWidth}" height="50" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
              <feOffset dy="1"/>
              <feGaussianBlur stdDeviation="4"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
              <feOffset dy="3"/>
              <feGaussianBlur stdDeviation="2"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"/>
              <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
              <feOffset dy="3"/>
              <feGaussianBlur stdDeviation="1.5"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
              <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
            </filter>
            <clipPath id="_clipPath_EolLdsAjBNzGUGbZiKTelNjTjgLvn9VB">
              <rect  width="${49 + textWidth}" height="50"/>
            </clipPath>
          </defs>
        </svg>
`;
};
