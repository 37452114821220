import React, { FC } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import type MediaTypeToggleFilter from 'models/Dashcam/MediaLibrary/MediaType/MediaTypeToggleFilter';

import { CameraIcon, MediaVideoIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  filter: MediaTypeToggleFilter;
}

const MediaLibraryFilterMediaToggle: FC<IProps> = ({ className, filter: { name, isSelected, count, icon } }) => {
  const cn = classNames('MediaLibraryFilterMediaToggle', className, {
    'MediaLibraryFilterMediaToggle--selected': isSelected.value,
    'MediaLibraryFilterMediaToggle--disabled': !Boolean(count),
  });
  const toggleFilter = () => isSelected.toggle();

  return (
    <div className={cn} onClick={toggleFilter}>
      {icon && (
        <div className="MediaLibraryFilterMediaToggle-icon">
          {name.toLowerCase() === 'image' && <CameraIcon />}
          {name.toLowerCase() === 'video' && <MediaVideoIcon />}
        </div>
      )}
      <div className="MediaLibraryFilterMediaToggle-name">{name}</div>
      <div className="MediaLibraryFilterMediaToggle-count">{count}</div>
    </div>
  );
};

export default observer(MediaLibraryFilterMediaToggle);
