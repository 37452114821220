import TableDownload from 'models/Tables/TableDownload';
import type { GeozonesAdmin } from 'stores/Admin/Geozones';
import { ADMIN_GEOZONES_TABLE_COLUMNS } from 'config';

class GeozonesDownload extends TableDownload<GeozonesAdmin> {
  private mapNameHeader = {
    [ADMIN_GEOZONES_TABLE_COLUMNS.NAME]: 'Name',
    [ADMIN_GEOZONES_TABLE_COLUMNS.CATEGORY]: 'Category',
    [ADMIN_GEOZONES_TABLE_COLUMNS.COLOR]: 'Color',
    [ADMIN_GEOZONES_TABLE_COLUMNS.SHAPE]: 'Shape',
    [ADMIN_GEOZONES_TABLE_COLUMNS.DISPATCH_MARKER]: 'Dispatch Marker',
    [ADMIN_GEOZONES_TABLE_COLUMNS.GROUP]: 'Group',
    [ADMIN_GEOZONES_TABLE_COLUMNS.ADDRESS_OVERRIDE]: 'Address Override',
    [ADMIN_GEOZONES_TABLE_COLUMNS.TRACK_ARRIVALS]: 'Track Arrivals',
    [ADMIN_GEOZONES_TABLE_COLUMNS.TRACK_DEPARTURES]: 'Track Departures',
    [ADMIN_GEOZONES_TABLE_COLUMNS.RADIUS]: 'Radius Meters',
    [ADMIN_GEOZONES_TABLE_COLUMNS.GEOZONE_ID]: 'Geozone ID',
    [ADMIN_GEOZONES_TABLE_COLUMNS.ACTIVE]: 'Active',
    [ADMIN_GEOZONES_TABLE_COLUMNS.CREATED_BY]: 'Created By',
    [ADMIN_GEOZONES_TABLE_COLUMNS.CREATION_TIME]: 'Created On',
    [ADMIN_GEOZONES_TABLE_COLUMNS.UPDATED_BY]: 'Modified By',
    [ADMIN_GEOZONES_TABLE_COLUMNS.LAST_UPDATE_TIME]: 'Modified On',
  };

  protected getFile = async (): Promise<Blob> => {
    const ReportFile = await import('../../Report/ReportDownload/ReportFile');
    const reportFile = new ReportFile.default(this.format.value, {
      title: 'Geozones Admin',
      description: '',
      tables: [{ ...this.getData(), title: null }],
    });

    const file = await reportFile.create();

    return file;
  };

  protected get fileName() {
    return 'Geozones Admin';
  }

  private getData = () => {
    const columnsIds = this.context.columns.filter((column) => column.isSelected).map((column) => column.value);
    const rows = this.context.tableSource.map((vehicle) => {
      return columnsIds.map((column) => vehicle[column]);
    });

    return {
      columns: columnsIds.map((columnId) => this.mapNameHeader[columnId]),
      rows,
      total: [],
    };
  };
}

export default GeozonesDownload;
