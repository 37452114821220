import React, { Component, CSSProperties } from 'react';
import { inject, observer } from 'mobx-react';

import { REPORTS_STORE } from 'config';
import type { ReportsStore } from 'stores';

import ReportTableByGroupRowEvent from './ReportTableByGroupRowEvent';
import ReportTableByGroupRowVehicle from './ReportTableByGroupRowVehicle';
import ReportTableByGroupRowSummary from './ReportTableByGroupRowSummary';

import './styles.scss';

interface IProps {
  index: number;
  reportsStore?: ReportsStore;
  style: CSSProperties;
}

@inject(REPORTS_STORE)
@observer
class ReportTableByGroupRow extends Component<IProps> {
  render() {
    const {
      reportsStore: {
        reportTableGroup: { rows },
      },
      index,
      style,
    } = this.props;

    const type = rows[index]?.[0];

    if (type === 'event' || type === 'event-summary') {
      return <ReportTableByGroupRowEvent style={style} index={index} isSummary={type === 'event-summary'} />;
    } else if (type === 'vehicle') {
      return <ReportTableByGroupRowVehicle style={style} index={index} />;
    } else if (type === 'summary') {
      return <ReportTableByGroupRowSummary style={style} index={index} />;
    } else {
      return null;
    }
  }
}

export default ReportTableByGroupRow;
