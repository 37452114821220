import React, { useMemo, useCallback } from 'react';
import { Observer } from 'mobx-react';

import type { SimpleField } from 'models/Fields';
import MediaRequestReviewItem from './MediaRequestReviewItem';
import MediaRequestReviewCameraItem from './MediaRequestReviewCameraItem';
import type RequestMediaCamerasType from 'models/Dashcam/MediaRequestCheckout/RequestMedia/RequestMediaCamerasType';
import Input from 'components/Input';

import './styles.scss';

interface IProps {
  cameraType: RequestMediaCamerasType;
  clipName: SimpleField<string>;
  length: number;
  time: string;
  vehicle: string;
}

const MediaRequestReview = ({ vehicle, time, length, cameraType, clipName }: IProps) => {
  const additionalLengthValue = useMemo(() => {
    switch (length) {
      case 40:
        return '2 clips (20s ea)';
      case 60:
        return '3 clips (20s ea)';
      case 20:
      default:
        return '';
    }
  }, [length]);
  const changeClipName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => clipName.set(e.target.value), [
    clipName,
  ]);

  return (
    <Observer
      render={() => {
        return (
          <div className="MediaRequestReview">
            <div className="MediaRequestReview-section">
              <MediaRequestReviewItem title="Vehicle">{vehicle}</MediaRequestReviewItem>
            </div>
            <div className="MediaRequestReview-section">
              <MediaRequestReviewItem title="Date & Time">{time}</MediaRequestReviewItem>
            </div>
            <div className="MediaRequestReview-section MediaRequestReview-section--clipName">
              <MediaRequestReviewItem title="Clip Name" name="clipName">
                <Input placeholder="Optional" value={clipName.value} onChange={changeClipName} maxLength={32} />
              </MediaRequestReviewItem>
            </div>
            <div className="MediaRequestReview-section">
              <MediaRequestReviewItem title="Media Type">
                <div className="MediaRequestReview-cameras">
                  {cameraType.cameras.map((camera) => (
                    <div
                      className={`MediaRequestReview-camera MediaRequestReview-camera--${camera.value.toLowerCase()}`}
                      key={camera.value}
                    >
                      <MediaRequestReviewCameraItem
                        checked={camera.media.isActive.value}
                        disabled={!camera.isAvailable.value}
                        onCheck={camera.media.isActive.toggle}
                        type={camera.value === 'OUT' ? 'Road-Facing Camera' : 'Driver-Facing Camera'}
                      />
                    </div>
                  ))}
                </div>
              </MediaRequestReviewItem>
            </div>
            {cameraType.anySelectedVideoType && (
              <div className="MediaRequestReview-section">
                <MediaRequestReviewItem title="Length">
                  {
                    <div className="MediaRequestReviewLength">
                      <span className="MediaRequestReviewLength-main">{length}s</span>
                      <span className="MediaRequestReviewLength-additional">{additionalLengthValue}</span>
                    </div>
                  }
                </MediaRequestReviewItem>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default MediaRequestReview;
