import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { REPORTS_STORE } from 'config';

import type { ReportsStore } from 'stores';

import ReportGroupedTableStickyItem from './ReportGroupedTableStickyItem';

import './styles.scss';

interface IProps {
  reportsStore?: ReportsStore;
}

@inject(REPORTS_STORE)
@observer
class ReportGroupedTableSticky extends Component<IProps> {
  render() {
    const {
      reportsStore: {
        reportTableGroup: { scrollLeft, vehicles, rowsCount },
      },
    } = this.props;

    return vehicles.length && rowsCount ? (
      <div className="ReportGroupedTableSticky">
        <div className="ReportGroupedTableSticky-relative">
          <div className="ReportGroupedTableSticky-absolute" style={{ left: scrollLeft * -1 }}>
            <ReportGroupedTableStickyItem />
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default ReportGroupedTableSticky;
