import React, { FC } from 'react';
import Modal from 'components/Modal';
import Button from 'components/Button';

import './styles.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const FileSizeModal: FC<IProps> = ({ open, onClose }) => {
  return (
    <Modal className="Modal--fileSize" isModalOpen={open} onClose={onClose} withoutCloseIcon>
      <div className="FileSizeModal">
        <p className="FileSizeModal-title">File Size Too Large</p>
        <p className="FileSizeModal-text">Unable to attach. File must be less than 50 MB.</p>
        <div className="FileSizeModal-button">
          <Button className="Button Button--apply Button--inline" title="Ok" onClick={onClose} />
        </div>
      </div>
    </Modal>
  );
};

export default FileSizeModal;
