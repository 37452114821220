import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import SetUpdatePassword from './SetUpdatePassword';
import Login from 'containers/Auth/Login';
import WelcomeEmail from 'components/Auth/WelcomeEmail';
import WelcomeEmailMulti from 'components/Auth/WelcomeEmailMulti';
import UpdatePasswordEmail from 'components/Auth/UpdatePasswordEmail';
import ForgotPassword from './ForgotPassword';

import { PATHS } from 'config';

const AuthRoutes = () => {
  return (
    <Switch>
      <Route path={PATHS.AUTH.LOGIN} exact component={Login} />
      <Route path={PATHS.AUTH.WELCOME_EMAIL} exact component={WelcomeEmail} />
      <Route path={PATHS.AUTH.WELCOME_EMAIL_MULTI} exact component={WelcomeEmailMulti} />
      <Route path={PATHS.AUTH.UPDATE_PASSWORD_EMAIL} exact component={UpdatePasswordEmail} />
      <Route path={PATHS.AUTH.CREATE_USER} exact render={(props) => <SetUpdatePassword {...props} page="set" />} />
      <Route path={PATHS.AUTH.FORGOT_PASSWORD} exact component={ForgotPassword} />
      <Route
        path={PATHS.AUTH.UPDATE_PASSWORD}
        exact
        render={(props) => <SetUpdatePassword {...props} page="update" />}
      />
      <Redirect from="*" to={PATHS.NOT_FOUND} />
    </Switch>
  );
};

export default AuthRoutes;
