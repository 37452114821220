import React from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import { getValidName } from 'utils';

import type { UniqueField } from 'models/Fields';

import Input from 'components/Input';

import './styles.scss';

interface IProps {
  name: UniqueField;
}

const AlertPreferenceName: React.FC<IProps> = ({ name }) => {
  const handleChange = (e) => {
    const allowedName = getValidName(e.currentTarget.value);

    name.set(allowedName);
  };

  const handleBlur = () => {
    name.setTouched(true);
  };

  const inputCn = classNames('AlertPreference-Input', {
    'AlertPreference-Input--error': name.isValidated && !name.isValid,
  });

  return (
    <Observer
      render={() => {
        const isTouched = name.isValidated && name.touched;
        const isEmpty = isTouched && name.isEmpty;
        const isNotUnique = isTouched && !name.isValid && !isEmpty;

        return (
          <div className="AlertPreference-line">
            <div className="AlertPreference-label">Alert Name</div>
            <div className="AlertPreference-value">
              <Input
                isFocus
                className={inputCn}
                value={name.value}
                onChange={handleChange}
                loading={!name.isValidated}
                onBlur={handleBlur}
              />
              {isNotUnique && <p className="AlertPreference-error">Alert already exists</p>}
              {isEmpty && <p className="AlertPreference-error">Alert name is a required field</p>}
            </div>
          </div>
        );
      }}
    />
  );
};

export default AlertPreferenceName;
