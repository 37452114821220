import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { Col, Row } from 'antd';

import type { GeozonesAdmin } from 'stores/Admin/Geozones';
import { validateDigitsOnly } from 'utils';
import validateAccessLevel from 'stores/acl/validator';
import { ADMIN_GEOZONES_UPDATE, MANAGEMENT_GEOZONES_NOTES_DELETE } from 'config';

import InfoTooltip from 'components/InfoTooltip';
import Input from 'components/Input';
import Toggle from 'components/Toggle';
import type { IChangeParams } from '../SelectedGeozone';
import Checkbox from 'components/Checkbox';
import Note from 'components/Note';
import SearchableGeoZoneTagSelect from 'containers/Select/SearchableGeoZoneTagSelect';
import SearchableGroupSelect from 'containers/Select/SearchableGroupSelect';

import './styles.scss';

interface IProps {
  className?: string;
  geozonesAdmin?: GeozonesAdmin;
  onChange: <T extends IChangeParams>(field: T | T[]) => void;
}

@inject(({ adminStore: { geozonesAdmin } }) => ({
  geozonesAdmin,
}))
@observer
class AdminGeozoneSettings extends Component<IProps, any> {
  handleTagChange = (option: Select.ISelectOption) => {
    const {
      onChange,
      geozonesAdmin: {
        selectedGeozone: { setGeozoneTag },
      },
    } = this.props;

    setGeozoneTag(option.value);
    onChange({ name: 'tag', value: option.value });
  };

  handleGroupChange = (option: Select.ISelectOption) => {
    const {
      onChange,
      geozonesAdmin: {
        selectedGeozone: { setGeozoneGroupId },
      },
    } = this.props;

    setGeozoneGroupId(option.value);
    onChange({ name: 'groupIdGts', value: option.value === 'all' ? '' : option.value });
  };

  handleActiveChange = () => {
    const {
      onChange,
      geozonesAdmin: { selectedGeozone },
    } = this.props;

    selectedGeozone.setGeozoneActive();
    onChange({ name: 'isActive', value: selectedGeozone.isActive });
  };

  handleSpeedLimitChange = (e) => {
    const {
      onChange,
      geozonesAdmin: { selectedGeozone },
    } = this.props;
    let speedLimit = e.target.value;

    if (!speedLimit || validateDigitsOnly(speedLimit)) {
      speedLimit = Number(speedLimit) || '';
      selectedGeozone.setGeozoneSpeedLimit(speedLimit);
      onChange({ name: 'speedLimit', value: speedLimit });
    }
  };

  handleOverrideChange = () => {
    const {
      onChange,
      geozonesAdmin: { selectedGeozone },
    } = this.props;

    selectedGeozone.setGeozoneOverride();
    onChange({ name: 'reverseGeocode', value: selectedGeozone.reverseGeocode });
  };

  handleHideOnMapsChange = () => {
    const {
      onChange,
      geozonesAdmin: { selectedGeozone },
    } = this.props;

    selectedGeozone.setGeozoneHideOnMaps();
    onChange({ name: 'hideOnMaps', value: selectedGeozone.hideOnMaps });
  };

  handleArrivalChange = () => {
    const {
      onChange,
      geozonesAdmin: { selectedGeozone },
    } = this.props;

    selectedGeozone.setGeozoneArrival();
    onChange({ name: 'arrivalZone', value: selectedGeozone.arrivalZone });
  };

  handleDepartureChange = () => {
    const {
      onChange,
      geozonesAdmin: { selectedGeozone },
    } = this.props;

    selectedGeozone.setGeozoneDeparture();
    onChange({ name: 'departureZone', value: selectedGeozone.departureZone });
  };

  handleNoteChange = (note) => {
    const {
      onChange,
      geozonesAdmin: { selectedGeozone },
    } = this.props;

    selectedGeozone.setGeozoneNotes(note);
    onChange({ name: 'notes', value: note });
  };

  render() {
    const {
      className,
      geozonesAdmin: { selectedGeozone },
    } = this.props;
    const cn = classNames('AdminGeozoneSettings', className);
    const isEditable = validateAccessLevel([ADMIN_GEOZONES_UPDATE]);
    const isNoteEditable = validateAccessLevel([MANAGEMENT_GEOZONES_NOTES_DELETE]);

    return (
      <div className={cn}>
        <Row>
          <Col className="AdminGeozoneSettings-col AdminGeozoneSettings-col--left" span={16}>
            <div className="AdminGeozoneSettings-row">
              <div className="AdminGeozoneSettings-section AdminGeozoneSettings-section--id">
                <div className="AdminGeozoneSettings-content AdminGeozoneSettings-content--id">
                  <Input
                    className="Input--admin"
                    disabled
                    label="Id"
                    placeholder="Geozone Id"
                    value={selectedGeozone?.geozoneId}
                  />
                </div>
              </div>
              <div className="AdminGeozoneSettings-section AdminGeozoneSettings-section--category">
                <div className="AdminGeozoneSettings-title AdminGeozoneSettings-title--category">
                  <p className="AdminGeozoneSettings-titleText AdminGeozoneSettings-titleText--category">Category</p>
                </div>
                <div className="AdminGeozoneSettings-content AdminGeozoneSettings-content--category">
                  <SearchableGeoZoneTagSelect
                    value={
                      selectedGeozone &&
                      selectedGeozone.tag && {
                        value: selectedGeozone.tag,
                        label: selectedGeozone.tag,
                      }
                    }
                    disabled={!isEditable}
                    handleChange={this.handleTagChange}
                    timeout={500}
                  />
                </div>
              </div>
              <div className="AdminGeozoneSettings-section AdminGeozoneSettings-section--active">
                <div className="AdminGeozoneSettings-title AdminGeozoneSettings-title--active">
                  <p className="AdminGeozoneSettings-titleText AdminGeozoneSettings-titleText--active">Active</p>
                  <div className="AdminGeozoneSettings-tooltip AdminGeozoneSettings-tooltip--active">
                    <InfoTooltip>
                      Inactive Geozones will not be accessible anywhere in the system and will not be tracked.
                    </InfoTooltip>
                  </div>
                </div>
                <div className="AdminGeozoneSettings-content AdminGeozoneSettings-content--active">
                  <Toggle
                    disabled={!isEditable}
                    checked={selectedGeozone?.isActive}
                    onChange={this.handleActiveChange}
                  />
                </div>
              </div>
            </div>
            <div className="AdminGeozoneSettings-row">
              <div className="AdminGeozoneSettings-section AdminGeozoneSettings-section--group">
                <div className="AdminGeozoneSettings-title AdminGeozoneSettings-title--group">
                  <p className="AdminGeozoneSettings-titleText AdminGeozoneSettings-titleText--group">
                    Limit to Vehicle group
                  </p>
                  <div className="AdminGeozoneSettings-tooltip AdminGeozoneSettings-tooltip--group">
                    <InfoTooltip>
                      Choosing a specific group will cause the Geozone to ONLY apply to vehicles inside the chosen
                      Group.
                    </InfoTooltip>
                  </div>
                </div>
                <div className="AdminGeozoneSettings-content AdminGeozoneSettings-content--group">
                  <SearchableGroupSelect
                    withAll
                    disabled={!isEditable}
                    value={
                      selectedGeozone?.groupIdGts && {
                        value: selectedGeozone.groupIdGts,
                        label: selectedGeozone.groupDescription,
                      }
                    }
                    handleChange={this.handleGroupChange}
                    timeout={500}
                  />
                </div>
              </div>
              <div className="AdminGeozoneSettings-section AdminGeozoneSettings-section--speedLimit">
                <div className="AdminGeozoneSettings-title AdminGeozoneSettings-title--speedLimit">
                  <p className="AdminGeozoneSettings-titleText AdminGeozoneSettings-titleText--speedLimit">
                    Speed Limit inside Zone
                  </p>
                  <div className="AdminGeozoneSettings-tooltip AdminGeozoneSettings-tooltip--speedLimit">
                    <InfoTooltip>
                      Any applicable vehicle driving inside the Geozone will record a status of ‘Speeding’ when
                      exceeding this limit.
                    </InfoTooltip>
                  </div>
                </div>
                <div className="AdminGeozoneSettings-content AdminGeozoneSettings-content--speedLimit">
                  <div className="AdminGeozoneSettings-speedLimitInput">
                    <Input
                      maxLength={3}
                      disabled={!isEditable}
                      onChange={this.handleSpeedLimitChange}
                      placeholder=""
                      value={selectedGeozone?.speedLimit ? selectedGeozone?.speedLimit.toString() : ''}
                    />
                  </div>
                  <p className="AdminGeozoneSettings-speedLimitText">MPH</p>
                </div>
              </div>
            </div>
          </Col>
          <Col className="AdminGeozoneSettings-col AdminGeozoneSettings-col--right" span={8}>
            <div className="AdminGeozoneSettings-section AdminGeozoneSettings-section--options">
              <div className="AdminGeozoneSettings-title AdminGeozoneSettings-title--options">
                <p className="AdminGeozoneSettings-titleText AdminGeozoneSettings-titleText--options">Options</p>
              </div>
              <div className="AdminGeozoneSettings-content AdminGeozoneSettings-content--options">
                <div className="AdminGeozoneSettings-option AdminGeozoneSettings-option--override">
                  <div className="AdminGeozoneSettings-optionCheckbox">
                    <Checkbox
                      disabled={!isEditable}
                      checked={selectedGeozone?.reverseGeocode}
                      label="Override Addresses with Geozone name"
                      onChange={this.handleOverrideChange}
                    />
                  </div>
                  <div className="AdminGeozoneSettings-tooltip AdminGeozoneSettings-tooltip--override">
                    <InfoTooltip>
                      By checking this box, the Geozone name will appear in Reports and on the Map instead of the street
                      address.
                    </InfoTooltip>
                  </div>
                </div>
                <div className="AdminGeozoneSettings-option AdminGeozoneSettings-option--hideOnMaps">
                  <div className="AdminGeozoneSettings-optionCheckbox">
                    <Checkbox
                      disabled={!isEditable}
                      checked={selectedGeozone?.hideOnMaps}
                      label="Hide on Maps"
                      onChange={this.handleHideOnMapsChange}
                    />
                  </div>
                </div>
                <div className="AdminGeozoneSettings-option AdminGeozoneSettings-option--trackArrivals">
                  <div className="AdminGeozoneSettings-optionCheckbox">
                    <Checkbox
                      disabled={!isEditable}
                      checked={selectedGeozone?.arrivalZone}
                      label="Track Arrivals"
                      onChange={this.handleArrivalChange}
                    />
                  </div>
                  <div className="AdminGeozoneSettings-tooltip AdminGeozoneSettings-tooltip--trackArrivals">
                    <InfoTooltip>
                      When this box is checked you will see 'Arrive' events as pins on the map and as lines in Reports
                      when a vehicle enters a Geozone.
                    </InfoTooltip>
                  </div>
                </div>
                <div className="AdminGeozoneSettings-option AdminGeozoneSettings-option--trackDepartures">
                  <div className="AdminGeozoneSettings-optionCheckbox">
                    <Checkbox
                      disabled={!isEditable}
                      checked={selectedGeozone?.departureZone}
                      label="Track Departures"
                      onChange={this.handleDepartureChange}
                    />
                  </div>
                  <div className="AdminGeozoneSettings-tooltip AdminGeozoneSettings-tooltip--trackDepartures">
                    <InfoTooltip>
                      When this box is checked you will see 'Depart' events as pins on the map and as lines in Reports
                      when a vehicle exits a Geozone.
                    </InfoTooltip>
                  </div>
                </div>
              </div>
            </div>
            <div className="AdminGeozoneSettings-section AdminGeozoneSettings-section--note">
              <div className="AdminGeozoneSettings-title AdminGeozoneSettings-title--note">
                <p className="AdminGeozoneSettings-titleText AdminGeozoneSettings-titleText--note">Geozone Note</p>
              </div>
              <div className="AdminGeozoneSettings-content AdminGeozoneSettings-content--note">
                <Note
                  countCharactersEntered
                  disabled={!isNoteEditable}
                  hideControls
                  isEditing
                  note={selectedGeozone?.notes}
                  onChange={this.handleNoteChange}
                  placeholder="Use this space to save important details like contact information, gate codes, etc..."
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AdminGeozoneSettings;
