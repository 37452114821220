import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';

import { PATHS } from 'config';
import type { UserStore } from 'stores';

import AuthPage from 'components/Auth/AuthPage';
import ForgotPasswordForm from 'components/Auth/Login/ForgotPasswordForm';
import Logo from 'components/Logo';
import NavLink from 'components/NavLink';

import './styles.scss';

interface IProps {
  className?: string;
  userStore: UserStore;
}

@inject(({ userStore }) => ({
  userStore,
}))
@observer
class ForgotPassword extends Component<IProps> {
  componentWillUnmount() {
    const {
      userStore: { repositoryAuthPasswordRecovery },
    } = this.props;

    repositoryAuthPasswordRecovery.createState.reset();
  }

  handleForgotPassword = async ({ email }) => {
    const {
      userStore: { forgotPass },
    } = this.props;

    forgotPass(email);
  };

  render() {
    const {
      className,
      userStore: { repositoryAuthPasswordRecovery },
    } = this.props;
    const cn = classNames('ForgotPassword', className);
    const errorMessage = new Error(repositoryAuthPasswordRecovery.createState.error).message.replace('Error: ', '');

    return (
      <AuthPage className="AuthPage--forgotPassword">
        <div className={cn}>
          <div className="ForgotPassword-logo">
            <Logo />
          </div>
          <p className="ForgotPassword-text ForgotPassword-text--title">
            {repositoryAuthPasswordRecovery.createState.success
              ? 'A link to reset your password will be sent to the email provided if it exists.'
              : 'Please enter your email address and a password reset link will be sent to you.'}
          </p>
          <div className="ForgotPassword-form">
            <div className="ForgotPassword-login">
              <div className="ForgotPassword-loginForm">
                <ForgotPasswordForm
                  onSubmit={this.handleForgotPassword}
                  sending={repositoryAuthPasswordRecovery.createState.loading}
                  sent={repositoryAuthPasswordRecovery.createState.success}
                />
              </div>
              {repositoryAuthPasswordRecovery.createState.success && (
                <p className="ForgotPassword-text ForgotPassword-text--sentSuccess">You can close this window or</p>
              )}
              <div className="ForgotPassword-link ForgotPassword-link--login">
                <NavLink to={PATHS.AUTH.LOGIN} title="Return to Log In" className="NavLink--medium NavLink--primary" />
              </div>
              {Boolean(repositoryAuthPasswordRecovery.createState.error) && (
                <p className="ForgotPassword-errorMessage">{errorMessage}</p>
              )}
            </div>
          </div>
        </div>
      </AuthPage>
    );
  }
}

export default ForgotPassword;
