import { action, observable, computed, reaction, autorun } from 'mobx';
import { get, find, filter, sortBy, isBoolean, isNull, findIndex } from 'lodash';
import debounce from 'lodash/debounce';

import APIStoreBase from '../APIStoreBase';
import clearshareStore from '../Clearshare';
import DevicesFilters from './DevicesFilters';
import devicesMapStore from '../Map';
import EventsBus from 'services/EventsBus/eventsBus';
import eventsRegistryStore from 'stores/EventsRegistryStore';
import googleMapSearchStore from './GoogleMapSearchStore';
import MapHistory from './MapHistory';
import PinnedListBase from '../PinnedListBase';
import type Repository from 'services/RepositoryService/Repository';
import timeStore from 'stores/TimeStore';
import userStore from '../UserStore';
import { APP_EVENTS } from 'services/EventsBus/appEvents';
import { Device, Group } from 'models';
import {
  getTodayStartDate,
  getStartOfDayByDate,
  sortByAlphabet,
  getJSONItemFromStorage,
  setJSONItemToStorage,
  getTodayEndDate,
  isToday,
} from 'utils';
import { repositoryService } from 'services';
import { STORAGE_ITEMS } from 'config';
import { ToggleField } from '../../models/Fields';
import DeviceDelivery from 'models/Devices/DevicesDelivery';
import MediaDateOptionRange from '../../models/Dashcam/MediaLibrary/MediaSource/MediaDateOptionRange';
import Groups from 'models/Devices/Groups/Groups';

const REFRESH_INTERVAL = 30000; // 30 seconds
const DEFAULT_TIMELINE_HEIGHT = window.innerHeight * 0.5;

export class DevicesStore extends PinnedListBase {
  constructor() {
    super();

    this.groups = Groups.get();
    this.deviceDelivery = new DeviceDelivery(this);
    this.debouncedApplyFilterDate = debounce(this.applyFilterDate.bind(this), 500);
    this.debouncedFetchMediaClips = debounce(this.fetchMediaClips.bind(this), 500);
    this.debounceUpdateEventTrigger = debounce(this.updateEventTrigger.bind(this), 200);
    this.repositoryEvents = repositoryService.get('events');

    this.listenAppEvents();

    reaction(
      () => this.devicesWithEventsList.length,
      (devices) => {
        this.sortDevicesReaction?.();
        if (devices) {
          this.sortDevicesReaction = autorun(() => this.sortDevicesByTimestamp(), {
            name: 'Reorder vehicles list every 30 seconds',
            scheduler: (run) => setInterval(run, REFRESH_INTERVAL),
          });
        }
      }
    );

    reaction(
      () => this.selectedDeviceId,
      () => {
        this.resetSelectedEvent();
        googleMapSearchStore.resetSearch();
        devicesMapStore.mapStore.routeReplay.reset();
      },
      { name: 'Reset selected event, map location search and route replay on device change' }
    );

    reaction(
      () => this.selectedDevice,
      async (selectedDevice) => {
        if (selectedDevice) {
          this.selectedDevice.setMedia();
          this.selectedDevice.media.initialize();
          await this.selectedDevice.getFirstEvent();
          await this.selectedDevice.getLatestRegisteredEvent();
          EventsBus.get().trigger(APP_EVENTS.DEVICES.DEVICE.CHANGED);
        }
      }
    );

    reaction(
      () => this.selectedDeviceId,
      () => {
        const {
          date: { from, to },
          applyDate,
          resetDate,
          resetTempDate,
        } = this.filters;

        if (this.selectedDeviceId) {
          if (Boolean(!from && to)) {
            // when time filter applied for a vehicles list and then user selects a vehicle
            const from = getStartOfDayByDate(to, timeStore.sessionTimezone).valueOf();
            applyDate({ from, to });
          } else if (!Boolean(from && to)) {
            // no time filter applied for a vehicles list and then user selects a vehicle
            this.selectedDevice?.getDetails();
            this.selectedDevice?.getEvents();
          }
        } else {
          const dateChanged = Boolean(from || to);
          if (dateChanged) {
            resetDate();
            resetTempDate();
          }
        }
      },
      { name: 'Set and reset an initial date for a selected device' }
    );

    reaction(
      () => this.filters.date,
      async () => {
        if (this.selectedDevice) {
          this.debouncedFetchMediaClips();
        }
        return this.debouncedApplyFilterDate();
      },
      { name: 'Get device events on date change' }
    );

    reaction(
      () => this.devicesWithEventsList.length,
      async () => {
        if (this.selectedDeviceId) {
          const selectedDeviceIndex = this.getDeviceIndexById(this.selectedDeviceId);
          if (selectedDeviceIndex !== -1) {
            this.selectedDeviceIndex = selectedDeviceIndex;
            if (this.selectedDevice) {
              await this.selectedDevice.getEvents();
              await this.selectedDevice.getDetails();
            }
          }
        } else {
          this.resetSelectedDevice();
        }
      },
      { name: 'Get device events on select a device' }
    );

    reaction(
      () => clearshareStore.links.length,
      (length) => {
        const deviceIndex = findIndex(this.devicesWithEventsList, { data: { deviceId: this.selectedDeviceId } });

        this.selectedDevice.data.hasActiveClearShareLink = Boolean(length);
        this.devicesWithEventsList.splice(deviceIndex, 1, this.selectedDevice);
      },
      { name: 'Update clearshare icon in vehicles list when links created/removed' }
    );

    reaction(
      () => this.showHistory,
      (showHistory) => {
        if (!showHistory) {
          this.selectedDeviceId
            ? this.filters.resetDate({ from: getTodayStartDate(timeStore.sessionTimezone).valueOf(), to: null })
            : this.filters.resetDate();
          this.filters.resetTempDate();

          if (this.wasTrafficActivate) {
            devicesMapStore.mapStore.turnOnMapTrafficLayer();
          }
        } else {
          this.wasTrafficActivate = devicesMapStore.mapStore.mapTrafficLayer;
          devicesMapStore.mapStore.turnOffMapTrafficLayer();
        }
      },
      { name: 'on turn off history mode back to live and clear dates' }
    );

    reaction(
      () => this.selectedDevice?.data.deviceId,
      () => {
        this.setGoToRecentEvent(false);
      },
      { name: 'Reset goToRecentEvent on device change' }
    );

    reaction(
      () => this.showTimelineDetails.value,
      (showTimelineDetails) => {
        if (!showTimelineDetails) {
          this.setGoToRecentEvent(false);
        }
      },
      { name: 'Reset goToRecentEvent on timeline details close' }
    );

    reaction(
      () => ({
        length: this.devicesWithEventsLength,
        list: this.devicesList,
      }),
      () => {
        this.devicesWithEventsList = filter(this.devicesList, ({ latestEvent }) => Boolean(latestEvent));
        this.sortDevicesByTimestamp();
      },
      { name: 'save and sort devices with events separately' }
    );
  }

  private wasTrafficActivate: boolean = false;

  @observable sortDevicesReaction: any;

  @observable devicesList: Device[] = [];
  @observable devicesWithEventsList: Device[] = [];
  @observable filteredDevicesList: Device[] = [];
  @observable filters: DevicesFilters = new DevicesFilters();
  @observable selectedDeviceIndex: number = -1;
  @observable selectedDeviceFollowing: boolean = false;
  @observable selectedDeviceId: string = '';
  @observable selectedPreviousDeviceId: string = '';
  @observable showTrail: boolean = false;
  @observable showHistory: boolean = false;
  @observable showTimelineDetails: ToggleField = new ToggleField();
  @observable mediaPanel: ToggleField = new ToggleField();
  @observable timelineDetailsHeight: number = getJSONItemFromStorage(
    STORAGE_ITEMS.map.timeline.detailsHeight,
    DEFAULT_TIMELINE_HEIGHT
  );
  @observable goToRecentEvent: boolean = false;

  @observable selectedEventIndex: number = null;

  @observable history = new MapHistory();

  @observable isLatestEventsPullingStarted: boolean = false;
  @observable getDevicesRequestStatus = new APIStoreBase();
  @observable getGroupInfoRequestStatus = new APIStoreBase();
  @observable manuallySelected: boolean = false;

  deviceDelivery: DeviceDelivery;
  groups: Groups;
  repositoryEvents: Repository;

  @computed get enableTrail() {
    if (this.selectedDeviceId) {
      const latestEvent = this.devicesList
        .find((device) => device.data.deviceId === this.selectedDeviceId)
        ?.events?.find?.((event) => event.isLastEvent);
      if (latestEvent?.timestamp) {
        return Boolean(latestEvent.timestamp);
      }
    }
    return false;
  }

  @computed get devicesWithEventsLength() {
    return filter(this.devicesList, ({ latestEvent }) => Boolean(latestEvent)).length;
  }

  @action debouncedApplyFilterDate = async () => void 0;

  @action debouncedFetchMediaClips = async () => void 0;

  @action debounceUpdateEventTrigger = async () => void 0;

  @action applyFilterDate = async () => {
    if (this.selectedDeviceId) {
      const selectedDeviceIndex = this.getDeviceIndexById(this.selectedDeviceId);

      if (selectedDeviceIndex !== -1) {
        this.selectedDeviceIndex = selectedDeviceIndex;

        if (this.selectedDevice) {
          await this.getDevices();
          this.toggleSelectedDeviceFollowing(false);
        }

        if (this.selectedDevice) {
          await this.selectedDevice.getDetails();
        }
      }
    } else {
      this.resetSelectedDevice();
      await this.getDevices();
    }
  };

  @action filterDevices = () => {
    const filteredList = filter(
      this.devicesWithEventsList,
      ({
        data: { shortName, driverDescription, isPowerDisconnected, isCameraPowerDisconnected, mediaEnabled },
        latestEvent: { metadata },
      }: Device) => {
        const filteredDevicesByName =
          shortName.toLowerCase().includes(this.filters.query.toLowerCase()) ||
          driverDescription.toLowerCase().includes(this.filters.query.toLowerCase());

        const filteredDevicesByAttributes = this.filters.attributes.isShow({
          isPowerDisconnected: isPowerDisconnected || isCameraPowerDisconnected || this.showHistory,
          mediaEnabled,
        });

        const isStatusFilterNotApplied = !eventsRegistryStore.devices.isSomeChecked();
        const filteredDevicesByStatus = isStatusFilterNotApplied
          ? true
          : eventsRegistryStore.devices.isChecked(metadata);

        return filteredDevicesByName && filteredDevicesByStatus && filteredDevicesByAttributes;
      }
    );

    // sort the list based on pinned one (push pinned items to the top)
    this.filteredDevicesList = sortBy(filteredList, ({ data: { deviceId } }: Device) => {
      const index = this.pinnedDevicesIdsList.indexOf(deviceId);

      return index !== -1 ? index : filteredList.length;
    });
  };

  @computed get filteredGroupsList() {
    const filteredGroups = this.groups.items.filter(
      ({ description }: Group) =>
        Boolean(description) && description.toLowerCase().includes(this.filters.groupsQuery.toLowerCase())
    );

    return sortBy(filteredGroups, ({ groupId }: Group) => {
      const index = this.pinnedGroupsIdsList.indexOf(groupId);

      return index !== -1 ? index : filteredGroups.length;
    });
  }

  @computed get accountId() {
    return get(userStore, 'details.active.accountIdCP.value', null);
  }

  @computed get userId() {
    return get(userStore, 'details.active.userId.value', null);
  }

  @computed get accountIdCP() {
    return get(userStore, 'details.accountIdCP', null);
  }

  @computed get selectedDevice() {
    return this.devicesList.find((device) => device.data?.deviceId === this.selectedDeviceId);
  }

  selectedEvents() {
    return this.selectedDevice
      ? this.selectedDevice.events.filter(({ metadata }) => eventsRegistryStore.settings.isChecked(metadata))
      : null;
  }

  onMoveEvent = () => {
    if (this.showTimelineDetails.value) {
      devicesMapStore.mapStore.zoomIntoTrackPoint(devicesMapStore.mapStore.mapZoom);
    }
  };

  @computed get selectedEvent() {
    const filteredEventsList = this.selectedEvents();

    return filteredEventsList && !isNull(this.selectedEventIndex) ? filteredEventsList[this.selectedEventIndex] : null;
  }

  @computed get selectedEventHasDataForTimeRange() {
    const fromFilterDateTimestamp = this.filters.date?.from;
    const toFilterDateTimestamp = this.filters.date?.to;
    const firstEventTimestamp = this.selectedDevice?.firstEventTimestamp;
    const timestamp = this.selectedDevice?.latestEvent?.timestamp;
    const noFilterApplied = fromFilterDateTimestamp === null && toFilterDateTimestamp === null;

    if (!this.showHistory || noFilterApplied) {
      return true;
    }

    return (
      firstEventTimestamp < fromFilterDateTimestamp ||
      firstEventTimestamp < toFilterDateTimestamp ||
      (fromFilterDateTimestamp < timestamp && toFilterDateTimestamp > timestamp)
    );
  }

  fetchLatestEvents = async (groupId: string, timestamp: number): Promise<Event.IServerEventResponse> => {
    const repositoryEventsFleet = this.repositoryEvents.entity('fleet');
    const params = {
      to: timestamp,
      includeInactive: true,
    };

    return groupId && groupId !== 'all'
      ? repositoryEventsFleet.entity(groupId).entity('latest').get(params)
      : repositoryEventsFleet.entity('latest').get(params);
  };

  createDevices = (latestEvents: Event.IServerEvent[], clearEvents: boolean = false): Device[] => {
    const devices = latestEvents.map((event) => {
      const existDevice = this.devicesList.find((device) => device.data.deviceId === event.id);
      const device = existDevice ? existDevice.setData(event) : new Device().setData(event).initialize(this);

      if (event.eventData.length) {
        device.updateEvent(event.eventData[0], clearEvents);
      } else {
        device.clearEvents();
      }

      return device;
    });

    return devices;
  };

  onEventsDelivery = (res) => {
    if (res) {
      const event = JSON.parse(res.body);
      const device = this.getDeviceById(event.deviceId);
      const shouldUpdate = !Boolean(this.filters.date.from && this.filters.date.to);
      const isSelectedDeviceUpdated = device?.data?.deviceId === this.selectedDevice?.data?.deviceId;

      if (device && shouldUpdate) {
        device.updateEvent(event);
        device.updateDriverName(event);

        if (isSelectedDeviceUpdated && this.showTimelineDetails.value) {
          this.setGoToRecentEvent(true);
        }

        this.debounceUpdateEventTrigger();
      }
    }
  };

  listenAppEvents = () => {
    EventsBus.get().on(APP_EVENTS.DEVICES.GET, this.filterDevices);
    EventsBus.get().on(APP_EVENTS.DEVICES.SEARCH, this.filterDevices);
    EventsBus.get().on(APP_EVENTS.DEVICES.FILTER, this.filterDevices);
    EventsBus.get().on(APP_EVENTS.DEVICES.PINNED, this.filterDevices);
    EventsBus.get().on(APP_EVENTS.DEVICES.DEVICE.EVENT.UPDATE, this.filterDevices);
    EventsBus.get().on(APP_EVENTS.GROUPS.GROUP.CHANGED, this.getDevices);
  };

  @action toggleSelectedDeviceFollowing = (status?: boolean) =>
    (this.selectedDeviceFollowing = isBoolean(status) ? status : !this.selectedDeviceFollowing);

  @action setSelectedEventIndex = (index: number) => (this.selectedEventIndex = index);

  @action getDevices = async (shouldReset = true): Promise<void> => {
    if (shouldReset) {
      this.getDevicesRequestStatus.reset().setLoading(true);
    }
    const timestamp = this.filters.date.to ? this.filters.date.to : null;

    try {
      const latestEventsResponse = await this.fetchLatestEvents(this.groups.groupId.value, timestamp);

      const latestEvents = latestEventsResponse.items;

      this.devicesList = this.createDevices(latestEvents, Boolean(timestamp));

      await this.selectedDevice?.getEvents();

      this.getDevicesRequestStatus.setSuccess(true);

      EventsBus.get().trigger(APP_EVENTS.DEVICES.GET);
    } catch (err) {
      let message = 'Unknown error';

      if (err instanceof Error) {
        message = err.message;
      }

      this.getDevicesRequestStatus.setError(message);
    }

    this.getDevicesRequestStatus.setLoading(false);
  };

  @action sortDevicesByTimestamp = () =>
    (this.devicesWithEventsList = sortBy(
      this.devicesWithEventsList,
      ({ latestEvent: { timestamp } }) => timestamp
    ).reverse());

  @action setSelectedDeviceId = (deviceId: string) => {
    this.selectedDeviceId = deviceId;
    this.selectedPreviousDeviceId = deviceId;
  };

  @action getDeviceById = (deviceId: string) => find(this.devicesList, { data: { deviceId } });
  @action getDeviceIndexById = (deviceId: string) => findIndex(this.devicesWithEventsList, { data: { deviceId } });
  @action getDeviceByIndex = (index: number) => {
    return this.devicesWithEventsList[index];
  };

  @action toggleTrail = (show?: boolean) => {
    this.showTrail = isBoolean(show) ? show : !this.showTrail;
  };

  @action setHistory = (value: boolean) => {
    this.showHistory = value;
  };

  @action toggleHistory = () => {
    this.setHistory(!this.showHistory);
  };

  @action toggleMediaPanel = (state: boolean) => {
    this.mediaPanel.toggle(state);
  };

  @action setGoToRecentEvent = (goTo: boolean) => (this.goToRecentEvent = goTo);

  @action setTimelineDetailsHeight = (height: number) => {
    setJSONItemToStorage(STORAGE_ITEMS.map.timeline.detailsHeight, height);
    this.timelineDetailsHeight = height;
  };

  @action resetDevices = (savePinned?: boolean) => {
    this.getDevicesRequestStatus.reset();
    this.devicesList = [];
    if (!savePinned) {
      this.pinnedDevicesIdsList = [];
      this.pinnedGroupsIdsList = [];
    }
    this.filters.resetDevicesFilters();

    return this;
  };

  @action resetSelectedDevice = () => {
    this.selectedDeviceId = '';
    this.updateManuallySelected(false);
  };

  @computed get devicesListCount(): number {
    return this.devicesWithEventsList.length;
  }

  @action resetSelectedEvent = () => {
    this.selectedEventIndex = null;
  };

  @computed get sortedDevicesList() {
    return [...this.devicesWithEventsList].sort((a, b) =>
      sortByAlphabet(a.data.shortName.toLowerCase(), b.data.shortName.toLowerCase())
    );
  }

  @computed get selectedDeviceIndexInSortedList() {
    return this.sortedDevicesList.findIndex((item) => item.data.deviceId === this.selectedDevice.data.deviceId);
  }

  @computed get previousFromSelectedDevice() {
    return this.getDeviceFromSelectedDeviceByStep(-1);
  }

  @computed get nextFromSelectedDevice() {
    return this.getDeviceFromSelectedDeviceByStep(1);
  }

  getDeviceFromSelectedDeviceByStep = (step: number) => {
    let index = this.selectedDeviceIndexInSortedList + step;
    if (index >= this.sortedDevicesList.length) {
      index = 0;
    } else if (index < 0) {
      index = this.sortedDevicesList.length - 1;
    }
    return this.sortedDevicesList[index];
  };

  @action updateManuallySelected = (value?: boolean) => {
    this.manuallySelected = value === undefined ? !this.manuallySelected : value;
  };

  updateEventTrigger = () => {
    EventsBus.get().trigger(APP_EVENTS.DEVICES.DEVICE.EVENT.UPDATE);
  };

  fetchMediaClips = () => {
    if (!this.selectedDevice || !this.selectedDevice?.data?.mediaEnabled) {
      return;
    }
    const mediaSourceDateRange = this.selectedDevice.media.library.source.dateRange;
    const customDateRangeOption = mediaSourceDateRange.customDateRangeOption;
    const { from, to } = this.filters.date;
    const dateRange =
      this.showHistory && (!from || from >= to)
        ? { from: getStartOfDayByDate(to, timeStore.sessionTimezone), to }
        : this.filters.date;
    const {
      data: { assetId },
    } = this.selectedDevice;
    const mediaSourceVehicles = this.selectedDevice.media.library.source.vehicles;

    mediaSourceVehicles.selectedAssetId.set(String(assetId));
    mediaSourceDateRange.unselectOptions();
    customDateRangeOption.isSelected.toggle(true);

    if (customDateRangeOption instanceof MediaDateOptionRange) {
      customDateRangeOption.range.set({
        from: Number(dateRange.from || getTodayStartDate(timeStore.sessionTimezone).valueOf()),
        to: Number(dateRange.to || getTodayEndDate(timeStore.sessionTimezone).valueOf()),
      });
    }

    this.selectedDevice.media.library.debouncedFetchType();
    this.selectedDevice.media.library.debouncedFetchMedia();
  };

  @computed get selectedDeviceLatestEvent() {
    return (
      this.selectedDeviceId &&
      this.devicesList
        .find((device) => device.data.deviceId === this.selectedDeviceId)
        ?.events?.find?.((event) => event.isLastEvent)
    );
  }

  @computed get isSelectedDeviceLatestEventToday() {
    return isToday(this.selectedDeviceLatestEvent?.timestamp, timeStore.sessionTimezone);
  }
}

export default new DevicesStore();
