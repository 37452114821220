import React, { FC } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import type RouteReplaySpeed from 'models/Timeline/RouteReplaySpeed';
import { RouteReplaySpeedOption } from 'models/Timeline/IRouteReplay';

import { X1Icon, X2Icon, X3Icon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  disabled?: boolean;
  speed: RouteReplaySpeed;
}

const SpeedButton: FC<IProps> = ({ className, disabled, speed }) => {
  const cn = classNames('SpeedButton', className, {
    [`PlayButton--disabled`]: disabled,
  });
  const renderIcon = () => {
    switch (speed.value.value) {
      case RouteReplaySpeedOption.X1:
        return <X1Icon />;
      case RouteReplaySpeedOption.X2:
        return <X2Icon />;
      case RouteReplaySpeedOption.X3:
        return <X3Icon />;
      default:
        return <X1Icon />;
    }
  };

  return (
    <button className={cn} onClick={speed.change}>
      <div className="SpeedButton-icon">{renderIcon()}</div>
    </button>
  );
};

export default observer(SpeedButton);
