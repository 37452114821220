import React from 'react';
import { Observer } from 'mobx-react';
import InputNumber from 'components/InputNumber';
import type { SimpleField } from 'models/Fields';

import './styles.scss';

interface IProps {
  odometer: SimpleField<number>;
}

const MaintenanceMarkAsCompleteOdometer: React.FC<IProps> = ({ odometer }) => {
  const onChange = (value: string) => {
    odometer.set(Math.round(Number(value)));
  };

  return (
    <Observer
      render={() => (
        <div className="MaintenancePreference-line MaintenanceMarkAsCompleteOdometer">
          <div className="MaintenancePreference-label">Odometer at service</div>
          <div className="MaintenanceMarkAsCompleteOdometer-valueWrapper">
            <div className="MaintenancePreference-value MaintenanceMarkAsCompleteOdometer-value">
              <InputNumber
                min="0"
                value={odometer.value?.toLocaleString('en-US', { maximumFractionDigits: 0 })}
                onChange={onChange}
                className="MaintenanceMarkAsCompleteOdometer-valueInput"
                error={odometer.isEmpty}
                controls={false}
              />
              <span className="MaintenanceMarkAsCompleteOdometer-valueLabel">miles</span>
            </div>
            {odometer.isEmpty && <p className="MaintenanceMarkAsCompleteOdometer-error">Value must be bigger than 0</p>}
          </div>
        </div>
      )}
    />
  );
};

export default MaintenanceMarkAsCompleteOdometer;
