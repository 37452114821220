import { ALERTS_TYPE } from 'config';
import AlertWithIntervalModel from './AlertWithInterval';
import isNumber from 'lodash/isNumber';

class LowVehicleBatteryAlertModel extends AlertWithIntervalModel {
  constructor(item) {
    super({
      ...item,
      isIntervalActive: isNumber(item.interval) ? Boolean(item.interval) : true,
      interval: isNumber(item.interval) ? item.interval : 12 * 60 * 60 * 1000,
    });

    this.urlType = 'low-vehicle-battery';
    this.intervalFields = ['days', 'hours', 'minutes'];
    this.description =
      'OBD Plug-Ins, Hardwired, and Asset Trackers wired to the vehicle can detect Low Vehicle Battery and trigger this alert.';
    this.type.reInitialize(ALERTS_TYPE.LOW_VEHICLE_BATTERY);
  }
}

export default LowVehicleBatteryAlertModel;
