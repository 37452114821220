import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'config';
import classNames from 'classnames';

import { formatDecimalNumber, getFormattedTime } from 'utils';

import type CustomerJob from 'stores/Customers/CustomerJob';

interface IProps {
  job: CustomerJob;
  customerId: string;
  limited?: boolean
}

const CustomerUpcomingJobItem: React.FC<IProps> = ({ job, customerId, limited }) => {

  const link = React.useMemo(() => {
    return `${PATHS.MAP.CUSTOMER_JOB.replace(':customerId', customerId).replace(':jobId', String(job.id))}`;
  }, [customerId, job.id]);

  return (
    <div
      className={classNames('CustomerUpcomingJobs-ListItem', {
        'CustomerUpcomingJobs-ListItem--limited': limited,
      })}
    >
      <div><Link to={link}>{job.id}</Link></div>
      <div>{getFormattedTime(job.serviceDate, 'MM/DD/YYYY')}</div>
      <div>{job.status}</div>
      {!limited && <div>${formatDecimalNumber(job.totalAmount, 2)}</div>}
    </div>
  );
};

export default CustomerUpcomingJobItem;
