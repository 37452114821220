import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import type { SimpleField, ToggleField } from 'models/Fields';

import Input from 'components/Input';
import ActiveToggle from 'components/ActiveToggle';

import './styles.scss';

interface IProps {
  name: SimpleField<string>;
  isActive: ToggleField;
  isError: ToggleField;
}

const InspectionsFormPanelNameActive: React.FC<IProps> = ({ name, isActive, isError }) => {
  const changeName = (e) => {
    name.set(e.currentTarget.value);

    if (e.currentTarget.value.trim().length) {
      name.setIsValid(true);
    } else {
      name.setIsValid(false);
    }
  };

  const inputCn = classNames('InspectionsFormPanelNameActive-nameInput', {
    'InspectionsFormPanelNameActive-nameInput--error': isError.value && !name.isValid,
  });

  return (
    <div className="InspectionsFormPanelNameActive">
      <div className="InspectionsFormPanelNameActive-name">
        <Input
          placeholder="Inspection Name"
          value={name.value}
          onChange={changeName}
          isFocus
          maxLength={64}
          className={inputCn}
          onFocus={() => isError.toggle(false)}
        />
        {isError.value && !name.isValid && (
          <p className="InspectionsFormPanelNameActive-error">Inspection Name is a required field</p>
        )}
      </div>
      <div className="InspectionsFormPanelNameActive-toggle">
        <ActiveToggle isActive={isActive} />
      </div>
    </div>
  );
};

export default observer(InspectionsFormPanelNameActive);
