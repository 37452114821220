import { repositoryService } from 'services';
import { PATHS } from 'config';

import Integration from './Integration';

import type { IQBIntegrationModel } from 'interfaces/stores/Integrations/IIntegration';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

class QBIntegrationModel extends Integration implements IQBIntegrationModel {
  repository: IRepository;
  repositoryEntityAuth: IEntityRepository;
  repositoryEntityAuthConnect: IEntityRepository;
  repositoryEntityAuthDisconnect: IEntityRepository;

  hasCustomers: boolean = true;

  constructor(props) {
    super(props);
    this.repository = repositoryService.get('integrations');
    this.repositoryEntityAuth = this.repository
      .entity('finance')
      .entity('quickbooks')
      .entity('oauth2');
    this.repositoryEntityAuthConnect = this.repositoryEntityAuth.entity('connect');
    this.repositoryEntityAuthDisconnect = this.repositoryEntityAuth.entity('disconnect');
  }

  connect = () => {
    const host = window.location.origin + '/web';
    this.repositoryEntityAuthConnect
      .get({
        errorRedirectUrl: host + PATHS.ADMIN.INTEGRATIONS.CONNECTING.QB.ERROR,
        successRedirectUrl: host + PATHS.ADMIN.INTEGRATIONS.CONNECTING.QB.SUCCESS,
      })
      .then(({ redirectUrl }) => {
        window.open(redirectUrl, 'Auth', 'width=600,height=600');
      });
  };

  reconnect = () => {
    this.connect();
  };

  disconnect = async () => {
    await this.repositoryEntityAuthDisconnect.delete();
  };

  update = async () => {
    await this.context?.loadAllIntegrations();
  };
}

export default QBIntegrationModel;
