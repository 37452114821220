import React from 'react';

import type MaintenanceCompleteCostsModel from 'models/Maintenances/Models/CompleteTask/MaintenanceCompleteCostsModel';

import MaintenanceCosts from 'components/Maintenance/MaintenanceCosts';

import './styles.scss';

interface IProps {
  costs: MaintenanceCompleteCostsModel;
}

const MaintenanceMarkAsCompleteCostOfService: React.FC<IProps> = ({ costs }) => {
  return (
    <div className="MaintenancePreference-line MaintenanceMarkAsCompleteCostOfService">
      <div className="MaintenancePreference-label MaintenancePreference-label--top MaintenanceMarkAsCompleteCostOfService-label">
        Cost of service
      </div>
      <div className="MaintenancePreference-value">
        <MaintenanceCosts costs={costs} />
      </div>
    </div>
  );
};

export default MaintenanceMarkAsCompleteCostOfService;
