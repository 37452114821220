import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { INTEGRATIONS_STORE } from 'config';

import type { IIntegrationsStore } from 'interfaces/stores/Integrations/IIntegrationsStore';

import FinanceRequestModal from './FinanceRequestModal';

import { SupportIcon } from 'assets';

import './styles.scss';

interface IProps {
  integrationsStore?: IIntegrationsStore;
}

interface IState {
  open: boolean
}

@inject(INTEGRATIONS_STORE)
@observer
class FinanceRequest extends Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    }
  }

  handleOpenModal = () => {
    this.setState({
      open: true,
    });
  }

  handleCloseModal = () => {
    this.setState({
      open: false,
    });
  }

  render() {
    return (
      <div>
        <div className="FinanceRequest-action">
          <button
            onClick={this.handleOpenModal}
            className="FinanceRequest-actionButton"
          >
            <SupportIcon/>
          </button>
        </div>

        {this.state.open && <FinanceRequestModal onClose={this.handleCloseModal}/>}
      </div>
    );
  }
}

export default FinanceRequest;
