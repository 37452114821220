import React from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';
import { InfoIcon } from 'assets';

import type PersonPermissionsRestrictVehicleAccessModel from 'models/Admin/People/PersonPermissions/PersonPermissionsRestrictVehicleAccessModel';
import type PersonPermissionsDriverModel from 'models/Admin/People/PersonPermissions/PersonPermissionsDriverModel';

import InfoTooltip from 'components/InfoTooltip';
import Toggle from 'components/Toggle';
import GroupsAccess from 'components/GroupsAccess';

import './styles.scss';

interface IProps {
  restrictVehicleAccess: PersonPermissionsRestrictVehicleAccessModel;
  driver: PersonPermissionsDriverModel;
}

const PersonPermissionsRestrictVehicleAccess: React.FC<IProps> = ({ restrictVehicleAccess, driver }) => {
  const addAccessToGroup = (group) => {
    restrictVehicleAccess.groups.add(group);
    driver.errorField.set(null);
  };

  const removeAccessToGroup = (group) => {
    restrictVehicleAccess.groups.remove(group);
    driver.errorField.set(null);
  };

  const toggle = () => {
    if (restrictVehicleAccess.active.value) {
      restrictVehicleAccess.groups.replace([]);
    }

    restrictVehicleAccess.active.toggle();
    driver.errorField.set(null);
  };

  return (
    <Observer
      render={() => {
        const cn = classNames('PersonPermissionsRestrictVehicleAccess', {
          'PersonPermissionsRestrictVehicleAccess--disabled': driver.restrictUserAccess.value,
        });

        return (
          <div className={cn}>
            <div className="PersonPermissionsRestrictVehicleAccess-activation">
              <Toggle
                checked={restrictVehicleAccess.active.value}
                onChange={toggle}
                disabled={driver.restrictUserAccess.value}
              />
              <p className="PersonPermissionsRestrictVehicleAccess-activationName">Restrict Vehicle Access</p>
              {driver.restrictUserAccess.value ? (
                <InfoIcon className="PersonPermissionsRestrictVehicleAccess-activationIcon" width={16} height={16} />
              ) : (
                <InfoTooltip>
                  By default users have access to All Vehicles. When enabled, users will only have access to vehicles in
                  Groups chosen below.
                </InfoTooltip>
              )}
            </div>
            {restrictVehicleAccess.active.value && (
              <div className="PersonPermissionsRestrictVehicleAccess-content">
                <p className="PersonPermissionsRestrictVehicleAccess-contentLabel">Groups this user can access</p>
                <div className="PersonPermissionsRestrictVehicleAccess-contentGroups">
                  <GroupsAccess
                    filterOpen
                    groups={restrictVehicleAccess.groups.value}
                    onAdd={addAccessToGroup}
                    onRemove={removeAccessToGroup}
                    addDisabled={restrictVehicleAccess.groups.value.length >= 10}
                  />
                </div>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default PersonPermissionsRestrictVehicleAccess;
