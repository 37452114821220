export const TOKEN_COOKIE_NAME = `${
  window && window.location && window.location.hostname ? window.location.hostname : ''
}_token`;

export const REFRESH_TOKEN_COOKIE_NAME = `${
  window && window.location && window.location.hostname ? window.location.hostname : ''
}_refresh_token`;

export const LEGACY_TOKEN_NAME = 'token';

export const USER_ACCOUNT_ID = 'user-account-id';

export const USER_ID = 'user-id';
