import React from 'react';
import { Observer } from 'mobx-react';
import { Radio, Space } from 'antd';

import { ALERTS_APPLIES_TO } from 'config';

import type KeyValueField from 'models/Fields/KeyValueField';
import type { SimpleField } from 'models/Fields';
import type AlertIDModel from 'models/Alerts/AlertID';
import type DueValue from 'models/Alerts/MaintenanceAlert/DueValue';
import type ServiceType from 'models/Maintenances/Models/Service/ServiceType';
import type MaintenanceTasks from 'models/Maintenances/Models/MaintenanceTasks/MaintenanceTasks';

import InfoTooltip from 'components/InfoTooltip';
import AlertPreferencesMaintenanceTaskSelect from './AlertPreferencesMaintenanceTaskSelect';
import AlertPreferencesMaintenanceTypeSelect from './AlertPreferencesMaintenanceTypeSelect';

import { IntegerInput as InputNumber } from 'components/Input';

import './styles.scss';

interface IProps {
  basedOn: SimpleField<Alerts.Modal.Maintenance.BasedOn>;
  when: SimpleField<Alerts.Modal.Maintenance.When>;
  basedTask: KeyValueField<string, string>;
  serviceType: ServiceType;
  maintenanceTask: MaintenanceTasks;
  dueValue: DueValue;
  deviceId: AlertIDModel<string>;
  groupId: AlertIDModel<string>;
  appliesTo: SimpleField<ALERTS_APPLIES_TO>;
}

const AlertPreferencesMaintenance: React.FC<IProps> = ({
  serviceType,
  dueValue,
  basedOn,
  basedTask,
  when,
  maintenanceTask,
  deviceId,
  groupId,
  appliesTo,
}) => {
  const handleChangeBasedOn = (e) => {
    const value = e.target.value;

    if (value === 'TYPE' || value === 'TASK') {
      basedOn.set(e.target.value);

      if (value === 'TYPE') {
        basedTask.reset();
      }
    }
  };

  const handleChangeMilesValue = (value) => {
    dueValue.miles.set(value);
  };

  const handleChangeHoursValue = (value) => {
    dueValue.hours.set(value);
  };

  const handleChangeDaysValue = (value) => {
    dueValue.days.set(value);
  };

  const handleChangeAvgDaysBeforeValue = (value) => {
    dueValue.avgDaysBefore.set(value);
  };

  const handleChangeRadio = (e) => {
    const value = e.target.value;
    if (value === 0 || value === 1 || value === 2) {
      when.set(value);
    }
  };

  const handleChangeServiceType = (option: Select.ISelectOption) => {
    serviceType.item.set({ id: option.value, name: option.label });
  };

  const handleChangeMaintenanceTask = (option: Select.ISelectOption) => {
    maintenanceTask.setItemById(option.value);
    maintenanceTask.isInvalid.toggle(false);
  };

  return (
    <Observer
      render={() => {
        const basedOnType = basedOn.value === 'TYPE' || appliesTo.value === ALERTS_APPLIES_TO.GROUP;
        const basedOnTask = basedOn.value === 'TASK' && appliesTo.value === ALERTS_APPLIES_TO.VEHICLE;
        return (
          <>
            {appliesTo.value === 'vehicle' && (
              <div className="AlertPreference-line">
                <div className="AlertPreference-label AlertPreference-label--top">Based On</div>
                <div className="AlertPreference-value AlertPreferencesMaintenance-value">
                  <div className="AlertPreferencesMaintenance-line">
                    <Radio.Group
                      onChange={handleChangeBasedOn}
                      value={basedOn.value}
                      className="AlertPreferencesMaintenance-switch"
                    >
                      <Radio.Button value="TASK" className="AlertPreferencesMaintenance-switchButton">
                        Specific Task
                      </Radio.Button>
                      <Radio.Button value="TYPE" className="AlertPreferencesMaintenance-switchButton">
                        Service Type
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                </div>
              </div>
            )}

            {basedOnType && (
              <div className="AlertPreference-line">
                <div className="AlertPreference-label AlertPreference-label--top">Service Type</div>
                <div className="AlertPreference-value AlertPreferencesMaintenance-value">
                  <div className="AlertPreferencesMaintenance-line AlertPreferencesMaintenance-select">
                    <AlertPreferencesMaintenanceTypeSelect
                      serviceType={serviceType}
                      onChange={handleChangeServiceType}
                      assetId={deviceId.id?.value}
                      assetGroupId={groupId.id?.value}
                    />
                  </div>
                </div>
              </div>
            )}

            {basedOnTask && (
              <div className="AlertPreference-line">
                <div className="AlertPreference-label AlertPreference-label--top">Task</div>
                <div className="AlertPreference-value AlertPreferencesMaintenance-value">
                  <div className="AlertPreferencesMaintenance-line AlertPreferencesMaintenance-select">
                    <AlertPreferencesMaintenanceTaskSelect
                      maintenanceTask={maintenanceTask}
                      deviceId={deviceId.id?.value}
                      onChange={handleChangeMaintenanceTask}
                    />
                    {maintenanceTask?.isInvalid.value ? (
                      <p className="AlertPreference-error">Task is a required field</p>
                    ) : null}
                  </div>
                </div>
              </div>
            )}

            <div className="AlertPreference-line">
              <div className="AlertPreference-label AlertPreference-label--top">Alert when</div>
              <div className="AlertPreference-value AlertPreferencesMaintenance-value AlertPreferencesMaintenance-valueWhen">
                <div className="AlertPreferencesMaintenance-line">
                  <Radio.Group onChange={handleChangeRadio} value={when.value}>
                    <Space direction="vertical">
                      {((basedOnType && serviceType.item?.id !== undefined) ||
                        (basedOnTask && maintenanceTask.item)) && (
                        <Radio value={0} className="AlertPreferencesMaintenance-isDue">
                          <span className="AlertPreferencesMaintenance-isDue--text">Task is due</span>
                        </Radio>
                      )}

                      {basedOnTask && maintenanceTask.item?.hasDateOnly && (
                        <>
                          <Radio value={1}>
                            <InputNumber
                              value={dueValue.days.value}
                              onChange={handleChangeDaysValue}
                              className="AlertPreferencesMaintenance-numberValue"
                              max={999}
                              min={1}
                            />
                            <span className="AlertPreferencesMaintenance-dueText">days before Task is due</span>
                          </Radio>
                        </>
                      )}

                      {basedOnTask && maintenanceTask.item?.hasMilesOnly && (
                        <>
                          <Radio value={1}>
                            <InputNumber
                              value={dueValue.avgDaysBefore.value}
                              onChange={handleChangeAvgDaysBeforeValue}
                              className="AlertPreferencesMaintenance-numberValue"
                              max={999}
                              min={1}
                            />
                            <span className="AlertPreferencesMaintenance-dueText">
                              days before Task is due <span>(based on Average Daily Usage)</span>
                            </span>
                          </Radio>

                          <Radio value={2}>
                            <InputNumber
                              value={dueValue.miles.value}
                              onChange={handleChangeMilesValue}
                              className="AlertPreferencesMaintenance-numberValue"
                              max={99999}
                              min={1}
                            />
                            <span className="AlertPreferencesMaintenance-dueText">miles before Task is due</span>
                          </Radio>
                        </>
                      )}

                      {basedOnTask && maintenanceTask.item?.hasHoursOnly && (
                        <>
                          <Radio value={1}>
                            <InputNumber
                              value={dueValue.avgDaysBefore.value}
                              onChange={handleChangeAvgDaysBeforeValue}
                              className="AlertPreferencesMaintenance-numberValue"
                              max={999}
                              min={1}
                            />
                            <span className="AlertPreferencesMaintenance-dueText">
                              days before Task is due <span>(based on Average Daily Usage)</span>
                            </span>
                          </Radio>
                          <Radio value={2}>
                            <InputNumber
                              value={dueValue.hours.value}
                              onChange={handleChangeHoursValue}
                              className="AlertPreferencesMaintenance-numberValue"
                              max={999}
                              min={1}
                            />
                            <span className="AlertPreferencesMaintenance-dueText">engine hours before Task is due</span>
                          </Radio>
                        </>
                      )}

                      {basedOnTask && maintenanceTask.item?.hasMilesAndDate && (
                        <>
                          <Radio value={1}>
                            <InputNumber
                              value={dueValue.avgDaysBefore.value}
                              onChange={handleChangeAvgDaysBeforeValue}
                              className="AlertPreferencesMaintenance-numberValue"
                              max={999}
                              min={1}
                            />
                            <span className="AlertPreferencesMaintenance-dueText">
                              days before Task is due <span>(based on Average Daily Usage)</span>
                            </span>
                          </Radio>

                          <Radio value={2}>
                            <InputNumber
                              value={dueValue.miles.value}
                              onChange={handleChangeMilesValue}
                              className="AlertPreferencesMaintenance-numberValue"
                              max={99999}
                              min={1}
                            />
                            <span className="AlertPreferencesMaintenance-dueText">miles or</span>
                            <InputNumber
                              value={dueValue.days.value}
                              onChange={handleChangeDaysValue}
                              className="AlertPreferencesMaintenance-numberValue"
                              max={999}
                              min={1}
                            />
                            <span className="AlertPreferencesMaintenance-dueText">days before Task is due</span>
                          </Radio>
                        </>
                      )}

                      {basedOnTask && maintenanceTask.item?.hasHoursAndDate && (
                        <>
                          <Radio value={1}>
                            <InputNumber
                              value={dueValue.avgDaysBefore.value}
                              onChange={handleChangeAvgDaysBeforeValue}
                              className="AlertPreferencesMaintenance-numberValue"
                              max={999}
                              min={1}
                            />
                            <span className="AlertPreferencesMaintenance-dueText">
                              days before Task is due <span>(based on Average Daily Usage)</span>
                            </span>
                          </Radio>

                          <Radio value={2}>
                            <InputNumber
                              value={dueValue.hours.value}
                              onChange={handleChangeHoursValue}
                              className="AlertPreferencesMaintenance-numberValue"
                              max={999}
                              min={1}
                            />
                            <span className="AlertPreferencesMaintenance-dueText">engine hours or</span>
                            <InputNumber
                              value={dueValue.days.value}
                              onChange={handleChangeDaysValue}
                              className="AlertPreferencesMaintenance-numberValue"
                              max={999}
                              min={1}
                            />
                            <span className="AlertPreferencesMaintenance-dueText">days before Task is due</span>
                          </Radio>
                        </>
                      )}

                      {basedOnType && serviceType.item?.id !== undefined && (
                        <>
                          <Radio value={1}>
                            <InputNumber
                              value={dueValue.avgDaysBefore.value}
                              onChange={handleChangeAvgDaysBeforeValue}
                              className="AlertPreferencesMaintenance-numberValue"
                              max={999}
                              min={1}
                            />
                            <span className="AlertPreferencesMaintenance-dueText">
                              days before Task is due <span>(based on Average Daily Usage)</span>
                            </span>
                          </Radio>

                          <Radio value={2}>
                            <InfoTooltip className="AlertPreferencesMaintenance-Tooltip--serviceType">
                              Alerts based on Service Type require all 3 metrics defined since Tasks can be based on any
                              metric.
                            </InfoTooltip>
                            <InputNumber
                              value={dueValue.miles.value}
                              onChange={handleChangeMilesValue}
                              className="AlertPreferencesMaintenance-numberValue AlertPreferencesMaintenance-numberValue--miles"
                              max={99999}
                              min={1}
                            />
                            <span className="AlertPreferencesMaintenance-dueText">miles,</span>
                            <InputNumber
                              value={dueValue.hours.value}
                              onChange={handleChangeHoursValue}
                              className="AlertPreferencesMaintenance-numberValue AlertPreferencesMaintenance-numberValue--hours"
                              max={999}
                              min={1}
                            />
                            <span className="AlertPreferencesMaintenance-dueText">engine hours, or</span>
                            <InputNumber
                              value={dueValue.days.value}
                              onChange={handleChangeDaysValue}
                              className="AlertPreferencesMaintenance-numberValue AlertPreferencesMaintenance-numberValue--days"
                              max={999}
                              min={1}
                            />
                            <span className="AlertPreferencesMaintenance-dueText">days before Task is due</span>
                          </Radio>
                        </>
                      )}
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

export default AlertPreferencesMaintenance;
