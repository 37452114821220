import React, { useState } from 'react';
import { Observer } from 'mobx-react';

import type MaintenanceTask from 'models/Maintenances/MaintenanceTask';

import SideModal from 'components/SideModal';
import MaintenanceCompletedTaskPanelHeader from 'components/Maintenance/CompletedTaskPanel/CompletedTaskPanelHeader';
import MaintenanceCompletedTaskPanelContent from 'components/Maintenance/CompletedTaskPanel/CompletedTaskPanelContent';
import Notification from 'components/Notification';
import ConfirmationModal from 'components/Admin/ConfirmationModal';

import './styles.scss';

interface IProps {
  isPanelOpen: boolean;
  task: MaintenanceTask;
  isEdit?: boolean;
  isShowDelete?: boolean;
  isCancelClosePanel?: boolean;
  onClosePanel: () => void;
  redirectToAdmin?: (value: string) => void;
}

const MaintenanceCompletedTaskPanel: React.FC<IProps> = ({
  isPanelOpen,
  task,
  isEdit = true,
  isShowDelete = true,
  isCancelClosePanel = false,
  onClosePanel,
  redirectToAdmin = () => true,
}) => {
  const [isExitingConfirmationModalOpen, setIsExitingConfirmationModalOpen] = useState(false);
  const [isClosePanel, setIsClosePanel] = useState(false);

  const closeExitingConfirmation = () => setIsExitingConfirmationModalOpen(false);

  const openExitingConfirmation = () => setIsExitingConfirmationModalOpen(true);

  const cancelEditing = () => {
    openExitingConfirmation();
    setIsClosePanel(false);
  };

  const submitExitingConfirmation = () => {
    closeExitingConfirmation();
    task.completed.reset();
    task.ui.panel.isEdit.toggle(false);

    if (isClosePanel || isCancelClosePanel) {
      onClosePanel();
    }
  };

  const closePanel = () => {
    if (task?.ui.panel.isEdit.value && task?.completed.isUpdated) {
      openExitingConfirmation();
      setIsClosePanel(true);
    } else {
      onClosePanel();
    }
  };

  const onResetRepositoryUpdateState = () => {
    task?.completed?.repositoryModel.patchState.reset();
  };

  const onResetRepositoryDeleteState = () => {
    task?.completed?.repositoryModel.deleteState.reset();
  };

  return (
    <Observer
      render={() => (
        <>
          <SideModal
            visible={isPanelOpen}
            setVisible={closePanel}
            title={
              task && task.completed ? (
                <MaintenanceCompletedTaskPanelHeader taskName={task.completed.info.taskName} ui={task.ui.panel} />
              ) : (
                ''
              )
            }
            width={400}
          >
            {task && task.completed && (
              <MaintenanceCompletedTaskPanelContent
                completed={task.completed}
                ui={task.ui.panel}
                redirectToVehicleAdmin={redirectToAdmin}
                onClose={onClosePanel}
                openExitingConfirmation={cancelEditing}
                isEdit={isEdit}
                isShowDelete={isShowDelete}
                isCancelClosePanel={isCancelClosePanel}
              />
            )}
          </SideModal>
          {task && task.completed && task.completed.repositoryModel.patchState.success && (
            <Notification
              onClose={onResetRepositoryUpdateState}
              text="The completed maintenance task was successfully saved."
              title="Success!"
              type="success"
            />
          )}
          {task && task.completed && task.completed.repositoryModel.patchState.error && (
            <Notification
              onClose={onResetRepositoryUpdateState}
              text="Something went wrong. Try again."
              title="Save Completed Task Failed"
              type="error"
            />
          )}
          {task && task.completed && task.completed.repositoryModel.deleteState.success && (
            <Notification
              onClose={onResetRepositoryDeleteState}
              text="The completed maintenance task was successfully deleted."
              title="Success!"
              type="success"
            />
          )}
          {task && task.completed && task.completed.repositoryModel.deleteState.error && (
            <Notification
              onClose={onResetRepositoryDeleteState}
              text="Something went wrong. Try again."
              title="Delete Completed Task Failed"
              type="error"
            />
          )}
          <ConfirmationModal
            className="MaintenanceCompletedTaskPanel-exitingConfirmation"
            title="Continue Without Saving?"
            applyButtonTitle="Yes, Continue"
            cancelButtonTitle="No, Go Back"
            cancelButtonStyles={{ border: 'none' }}
            isOpen={isExitingConfirmationModalOpen}
            onCancel={closeExitingConfirmation}
            onSubmit={submitExitingConfirmation}
          >
            <div className="MaintenanceCompletedTaskPanel-exitingConfirmationContent">
              <p>
                Changes made to {task && task.completed ? task.completed.info.taskName.value : 'task'} will not be
                saved.
              </p>
              <p className="MaintenanceCompletedTaskPanel-exitingConfirmationContentQuestion">
                Do you want to continue?
              </p>
            </div>
          </ConfirmationModal>
        </>
      )}
    />
  );
};

export default MaintenanceCompletedTaskPanel;
