import React from 'react';
import classNames from 'classnames';

import Checkbox from 'components/Checkbox';

import './styles.scss';

interface IProps {
  checked?: boolean;
  disabled?: boolean;
  onCheck: (value: boolean) => void;
  type: string;
}

const MediaRequestReviewCameraItem = ({ disabled, type, checked = false, onCheck }: IProps) => {
  const cn = classNames('MediaRequestReviewCameraItem', { 'MediaRequestReviewCameraItem--disabled': disabled });

  return (
    <div className={cn}>
      <div className="MediaRequestReviewCameraItem-label">
        <Checkbox label={type} onChange={onCheck} checked={!disabled && checked} disabled={disabled} />
      </div>
    </div>
  );
};

export default MediaRequestReviewCameraItem;
