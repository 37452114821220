import React, { Component } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { MAP_PARAMS, PATHS, WEB_MAP_CLOSEST_VEHICLE_READ } from 'config';

import type { EventsRegistryStore, MapStore, CustomersStore, DevicesStore, GoogleMapSearchStore } from 'stores';

import validateAccessLevel from 'stores/acl/validator';

import withCollapseStorage from 'hocs/withCollapseStorage';

import type { IIntegrationsStore } from 'interfaces/stores/Integrations/IIntegrationsStore';

import CloseButton from 'components/Button/CloseButton';
import CustomerDomain from '../CustomerDomain';
import ClosestVehicles from '../../../ClosestVehicles';
import CustomerInvoices from '../CustomerInvoices';
import CustomerPanelActions from './CustomerPanelActions';
import CustomerPanelNote from './CustomerPanelNote';
import InfoTooltip from 'components/InfoTooltip';
import Button from 'components/Button';
import Label from 'components/Label';
import CustomerUpcomingJobs from '../CustomerUpcomingJobs';
import CustomerEstimates from '../CustomerEstimates';

import { Collapse } from 'antd';
import { ChevronIcon, OpenNewWindow, SkeletonCustomer } from 'assets';

import './styles.scss';

enum collapsePanels {
  CustomerInfo = 'map-vehicle-customer-info',
  CustomerAction = 'map-vehicle-customer-action',
  CustomerUpcomingJobs = 'map-vehicle-customer-upcoming-jobs',
  CustomerBilling = 'map-vehicle-customer-billing',
  CustomerNote = 'map-vehicle-customer-note',
  ClosestVehicles = 'map-vehicle-customer-vehicles',
}

interface IMatchParams {
  customerId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  customersStore: CustomersStore;
  integrationsStore?: IIntegrationsStore;
  mapStore?: MapStore;
  devicesStore?: DevicesStore;
  eventsRegistryStore?: EventsRegistryStore;
  collapseKeys: string[];
  mapSearchStore?: GoogleMapSearchStore;

  onCollapseChange(values: string | string[]): void;
}

@inject(
  ({
    customersStore,
    devicesMapStore: { mapStore, devicesStore, mapSearchStore },
    eventsRegistryStore,
    integrationsStore,
  }) => ({
    customersStore,
    mapStore,
    devicesStore,
    eventsRegistryStore,
    integrationsStore,
    mapSearchStore,
  })
)
@observer
class CustomerPanel extends Component<IProps> {
  async componentDidMount() {
    const {
      match: {
        params: { customerId },
      },
      mapStore,
      customersStore,
      customersStore: { loadCustomerDetails, setSelectedCustomerId },
    } = this.props;

    setSelectedCustomerId(customerId);

    await loadCustomerDetails();

    if (customersStore.selectedCustomer?.address?.latitude) {
      mapStore.setMapCenter({
        lat: customersStore.selectedCustomer?.address?.latitude,
        lng: customersStore.selectedCustomer?.address?.longitude,
      });
      mapStore.setZoom(MAP_PARAMS.vehicleDefaultZoom);
    }
  }

  componentWillUnmount() {
    const {
      customersStore: { clearSelectedCustomerId },
    } = this.props;
    clearSelectedCustomerId();
  }

  handleCloseClick = () => {
    const {
      history: { push },
    } = this.props;

    push(PATHS.MAP.CUSTOMERS);
  };

  handleAddressClick = () => {
    const { mapSearchStore } = this.props;

    if (this.address && this.hasLocation) {
      mapSearchStore.setSearchResult({
        address: this.address,
        latitude: this.location?.latitude,
        longitude: this.location?.longitude,
        meta: {
          name: this.address,
          type: 'address',
        },
      });
    }
  };

  get address() {
    const address = this.props.customersStore.selectedCustomer?.address;
    if (address && (address.line1 || address.line2) && address.city) {
      return `${`${address.line1 || ''} ${address.line2 || ''}`.trim()}, ${address.city}, ${
        address.countrySubDivisionCode
      }  ${address.postalCode || ''} ${address.country || ''}`;
    }
    return null;
  }

  get closestVehiclesSectionPermissions() {
    return validateAccessLevel([WEB_MAP_CLOSEST_VEHICLE_READ]);
  }

  get location() {
    const {
      customersStore: { selectedCustomer },
    } = this.props;

    return selectedCustomer.address;
  }

  get hasLocation() {
    return this.location?.latitude !== undefined && this.location?.longitude !== undefined;
  }

  get isExpired() {
    const {
      integrationsStore: { expiredIntegrationsList },
      customersStore: { selectedCustomer },
    } = this.props;

    return expiredIntegrationsList[selectedCustomer.domain];
  }

  render() {
    const {
      collapseKeys,
      onCollapseChange,
      customersStore: { selectedCustomer, repositoryEntityCustomer },
      devicesStore: {
        filters: { date },
      },
    } = this.props;

    if (!selectedCustomer || repositoryEntityCustomer.getState.loading) {
      return (
        <div className="MapCustomerPanel MapCustomerPanel-loading">
          <SkeletonCustomer />
        </div>
      );
    }

    return (
      <div className="MapCustomerPanel">
        <div className="MapCustomerPanel-header">
          <div
            className={classNames('MapCustomerPanel-name', {
              'MapCustomerPanel-nameExpired': this.isExpired,
            })}
          >
            <div className="MapCustomerPanel-icon">
              <CustomerDomain domain={selectedCustomer.domain} size={24} />
            </div>
            {this.isExpired && (
              <InfoTooltip className="MapCustomerPanel-iconExpired">
                This integration has expired or is no longer syncing. The integration needs to be reconnected to show
                updated data.
              </InfoTooltip>
            )}
            <div className="MapCustomerPanel-title">
              <div className="MapCustomerPanel-titleContent">
                <span>{selectedCustomer.companyName ? selectedCustomer.companyName : '-'}</span>
                <a
                  href={selectedCustomer.externalLink}
                  target="_blank"
                  rel="noreferrer"
                  className="MapCustomerPanel-titleLink"
                >
                  <OpenNewWindow />
                </a>
              </div>
            </div>
          </div>
          <div className="MapCustomerPanel-close">
            <CloseButton onClick={this.handleCloseClick} />
          </div>
        </div>

        <div className="MapCustomerPanel-content">
          <Collapse
            activeKey={collapseKeys}
            expandIcon={() => (
              <span className="anticon anticon-right ant-collapse-arrow">
                <ChevronIcon />
              </span>
            )}
            expandIconPosition={'left'}
            onChange={onCollapseChange}
          >
            <Collapse.Panel
              header={'Customer Info'}
              key={collapsePanels.CustomerInfo}
              className="MapCustomerPanel-Block"
            >
              <div>
                <div className="MapCustomerPanel-betaLabel">
                  <Label text="Beta" color="orange" rounded inline align="center" size="small" />
                </div>
                <div className="MapCustomerPanelAttributes">
                  <div className="MapCustomerPanelAttributes-values">
                    <div className="MapCustomerPanelAttributes-row">
                      <div className="MapCustomerPanelAttributes-col MapCustomerPanelAttributes-col-title">Contact</div>
                      <div className="MapCustomerPanelAttributes-col MapCustomerPanelAttributes-col-value">
                        {selectedCustomer.name ? selectedCustomer.name : '-'}
                      </div>
                    </div>

                    <div className="MapCustomerPanelAttributes-row">
                      <div className="MapCustomerPanelAttributes-col MapCustomerPanelAttributes-col-title">Email</div>
                      <div className="MapCustomerPanelAttributes-col MapCustomerPanelAttributes-col-value">
                        {selectedCustomer.email ? (
                          <a href={`mailto:${selectedCustomer.email}`}>{selectedCustomer.email}</a>
                        ) : (
                          '-'
                        )}
                      </div>
                    </div>

                    <div className="MapCustomerPanelAttributes-row">
                      <div className="MapCustomerPanelAttributes-col MapCustomerPanelAttributes-col-title">Address</div>
                      <div className="MapCustomerPanelAttributes-col MapCustomerPanelAttributes-col-value MapCustomerPanelAttributes-col-value--address">
                        {this.address ? (
                          <Button
                            className="Button--link"
                            onClick={this.handleAddressClick}
                            title={this.address}
                            type="button"
                          />
                        ) : (
                          '-'
                        )}
                      </div>
                    </div>

                    {selectedCustomer.domain === 'QUICK_BOOKS' && (
                      <div className="MapCustomerPanelAttributes-row">
                        <div className="MapCustomerPanelAttributes-col MapCustomerPanelAttributes-col-title">Phone</div>
                        <div className="MapCustomerPanelAttributes-col MapCustomerPanelAttributes-col-value">
                          {selectedCustomer.phoneNumber ? selectedCustomer.phoneNumber : '-'}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Collapse.Panel>
            <Collapse.Panel header={'Actions'} key={collapsePanels.CustomerAction} className="MapCustomerPanel-Block">
              <CustomerPanelActions />
            </Collapse.Panel>
            <Collapse.Panel header={'Invoices'} key={collapsePanels.CustomerBilling} className="MapCustomerPanel-Block">
              {selectedCustomer && <CustomerInvoices customer={selectedCustomer} withHeader />}
            </Collapse.Panel>
            <Collapse.Panel
              header={selectedCustomer.domain === 'QUICK_BOOKS' ? 'Estimates' : 'Upcoming Jobs'}
              key={collapsePanels.CustomerUpcomingJobs}
              className="MapCustomerPanel-Block"
            >
              {selectedCustomer && selectedCustomer.domain === 'SERVICE_TITAN' && (
                <CustomerUpcomingJobs customer={selectedCustomer} />
              )}
              {selectedCustomer && selectedCustomer.domain === 'QUICK_BOOKS' && (
                <CustomerEstimates customer={selectedCustomer} />
              )}
            </Collapse.Panel>

            {selectedCustomer && selectedCustomer.domain === 'QUICK_BOOKS' && (
              <Collapse.Panel
                key={collapsePanels.CustomerNote}
                header={'Customer Note'}
                className="MapCustomerPanel-Block"
              >
                <CustomerPanelNote />
              </Collapse.Panel>
            )}
            {this.closestVehiclesSectionPermissions && this.hasLocation && (
              <Collapse.Panel
                header={'Closest Vehicles'}
                key={collapsePanels.ClosestVehicles}
                className="MapCustomerPanel-Block"
              >
                <ClosestVehicles
                  latitude={String(selectedCustomer.address.latitude)}
                  longitude={String(selectedCustomer.address.longitude)}
                  timestamp={date.to}
                />
              </Collapse.Panel>
            )}
          </Collapse>
        </div>
      </div>
    );
  }
}

export default withCollapseStorage(CustomerPanel, 'map-customer-panel-collapse', Object.values(collapsePanels));
