import { action, computed } from 'mobx';
import isEqual from 'lodash/isEqual';
import SimpleField from './SimpleField';

class KeyValueField<T, V> {
  key: SimpleField<T>;
  value: SimpleField<V>;

  constructor(key: T, value: V) {
    this.key = new SimpleField<T>(key);
    this.value = new SimpleField<V>(value);
  }

  @computed get isUpdated(): boolean {
    return !isEqual(this.value.value, this.value.initialValue) || !isEqual(this.key.value, this.key.initialValue);
  }

  @computed get isEmpty(): boolean {
    return !this.value.value;
  }

  @computed get isValid() {
    return this.key.isValid && this.value.isValid;
  }

  @computed get id() {
    return `${this.key.id}-${this.value.id}`;
  }

  @computed get initialValue(): { key: T; value: V } {
    return {
      key: this.key.initialValue,
      value: this.value.initialValue,
    };
  }

  @action set = (key: T, value: V) => {
    this.key.set(key);
    this.value.set(value);
  };

  @action reset = () => {
    this.key.reset();
    this.value.reset();
  };
}

export default KeyValueField;
