import { KM_TO_MI } from 'config';
import { SimpleField } from 'models/Fields';

class MaintenanceCompleteValuesModel {
  engHours: SimpleField<number>;
  odometer: SimpleField<number>;
  serviceTime: SimpleField<number>;

  constructor({ odometer = 0, engHours = 0, serviceTime = 0 }) {
    this.engHours = new SimpleField(engHours);
    this.odometer = new SimpleField(Number((odometer * KM_TO_MI).toFixed(1)));
    this.serviceTime = new SimpleField(serviceTime);
  }

  reset = () => {
    this.engHours.reset();
    this.odometer.reset();
    this.serviceTime.reset();
  };

  get isUpdated() {
    return this.engHours.isUpdated || this.odometer.isUpdated || this.serviceTime.isUpdated;
  }

  get model() {
    return {
      engHours: this.engHours.value,
      odometer: Number((this.odometer.value / KM_TO_MI).toFixed(1)),
      serviceTime: this.serviceTime.value,
    };
  }
}

export default MaintenanceCompleteValuesModel;
