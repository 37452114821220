import React, { FC } from 'react';
import { Observer } from 'mobx-react';
import classnames from 'classnames';

import { getMinutesSecondsText } from 'utils';
import type InspectionPerformed from 'models/Inspections/Models/InspectionPerformed';
import InspectionResultsCell from '../../ResultsCell';
import LabelValue from 'components/LabelValue';
import PhotoTile from 'components/FileManager/PhotoTile';

import './styles.scss';

interface IProps {
  className?: string;
  inspectionPerformed?: InspectionPerformed;
}

const InspectionPerformedPanelSummary: FC<IProps> = ({
  className,
  inspectionPerformed: { results, asset, form, inspectionBy, location, service, attachments, notes, elapsedTime },
}) => {
  const cn = classnames('InspectionPerformedPanelSummary', className);

  return (
    <Observer
      render={() => (
        <div className={cn}>
          <div className="InspectionPerformedPanelSummary-row">
            <LabelValue
              label="Results"
              value={<InspectionResultsCell text={results.results.text} status={results.results.status} />}
            />
          </div>
          <div className="InspectionPerformedPanelSummary-row">
            <LabelValue label="Vehicle" value={asset.name.value} />
          </div>
          <div className="InspectionPerformedPanelSummary-row">
            <LabelValue label="Inspection Form" value={form.name.value} />
          </div>
          <div className="InspectionPerformedPanelSummary-row">
            <LabelValue label="Elapsed Time" value={elapsedTime ? getMinutesSecondsText(elapsedTime) : '-'} />
          </div>
          <div className="InspectionPerformedPanelSummary-row">
            <LabelValue label="Inspection By" value={inspectionBy} />
          </div>
          <div className="InspectionPerformedPanelSummary-row">
            <LabelValue label="Location" value={location.address.value || '-'} />
          </div>
          <div className="InspectionPerformedPanelSummary-row">
            <LabelValue
              label="Odometer At Inspection"
              value={
                service.odometer ? `${service.odometer?.toLocaleString('en-US', { maximumFractionDigits: 3 })} mi` : '-'
              }
            />
          </div>
          <div className="InspectionPerformedPanelSummary-row">
            <LabelValue
              label="Engine Hours At Inspection"
              value={
                service.engineHours
                  ? `${service.engineHours?.toLocaleString('en-US', { maximumFractionDigits: 1 })} hrs`
                  : '-'
              }
            />
          </div>
          <div className="InspectionPerformedPanelSummary-row">
            <LabelValue
              label="Photo"
              value={
                <div className="InspectionPerformedPanelSummary-photoList">
                  {attachments.value.length
                    ? attachments.value.map((attachment, index) => (
                        <div className="InspectionPerformedPanelSummary-photoItem" key={index}>
                          <PhotoTile file={attachment.value} />
                        </div>
                      ))
                    : '-'}
                </div>
              }
            />
          </div>
          <div className="InspectionPerformedPanelSummary-row">
            <LabelValue label="Notes" value={notes || '-'} />
          </div>
        </div>
      )}
    />
  );
};

export default InspectionPerformedPanelSummary;
