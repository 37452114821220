import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { PersistenceStore } from 'stores';

import SearchableSelect from 'components/Select/SearchableSelect';
import type Repository from 'services/RepositoryService/Repository';
import { repositoryService } from 'services';

interface IProps {
  disabled?: boolean;
  emptySearchResults?: string;
  handleChange?: (option: Select.ISelectOption) => void;
  isSearchable?: boolean;
  noAll?: boolean;
  onBlur?: () => void;
  persistChange?: boolean;
  persistenceStore?: PersistenceStore;
  placeholder?: string;
  searchPlaceholder?: string;
  value?: Select.ISelectOption;
}

@inject(({ persistenceStore }) => ({ persistenceStore }))
@observer
class SearchableUserRoleSelect extends Component<IProps> {
  static defaultProps = {
    emptySearchResults: 'No Roles Found',
    placeholder: 'Select User Role',
    searchPlaceholder: 'Search Roles',
  };

  repositoryRoles: Repository;

  constructor(props) {
    super(props);
    if (props.persistChange) {
      if (props.persistenceStore.selectedDriver && !props.value) {
        this.props.handleChange(props.persistenceStore.selectedDriver);
      }
    }

    this.repositoryRoles = repositoryService.get('roles');
  }

  getData = (props: { page: number; pageSize: number; filter: string }) => {
    const data = { page: props.page || 0, pageSize: props.pageSize || 50, searchValue: props.filter || '' };

    return this.repositoryRoles.get(data).then((data) => {
      const totalPages = data.totalPages;

      return {
        totalPages,
        options: data.items
          .map((item) => {
            return {
              value: String(item.id),
              label: item.name,
            };
          })
          .filter((item) => item.label),
      };
    });
  };

  handleChange = (option: Select.ISelectOption) => {
    this.props.handleChange(option);
    if (this.props.persistChange) {
      this.props.persistenceStore.updateSelectedDriver({ ...option });
    }
  };

  render() {
    return (
      <SearchableSelect
        {...this.props}
        getData={this.getData}
        handleChange={this.handleChange}
        onBlur={this.props.onBlur}
      />
    );
  }
}

export default SearchableUserRoleSelect;
