import { action, observable, reaction } from 'mobx';
import { repositoryService } from '../services';
import { getJSONItemFromStorage, removeStorageItem, setJSONItemToStorage } from 'utils';
import { STORAGE_ITEMS } from '../config';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

export type timezoneNames =
  | 'US/Hawaii'
  | 'US/Alaska'
  | 'US/Pacific'
  | 'US/Arizona'
  | 'US/Mountain'
  | 'US/Central'
  | 'US/Eastern'
  | 'Canada/Pacific'
  | 'Canada/Mountain'
  | 'Canada/Central'
  | 'Canada/Eastern'
  | 'Canada/Atlantic'
  | 'Mexico/BajaNorte'
  | 'Mexico/BajaSur'
  | 'Mexico/General';

export class TimeStore {
  constructor() {
    reaction(
      () => this.userTimezone,
      () => {
        if (!this.sessionTimezone) {
          this.setSessionTimeZone(this.userTimezone);
        }
      }
    );

    this.repositoryAdmin = repositoryService.get('admin');
    this.repositoryAdminAccounts = this.repositoryAdmin.entity('accounts');
  }

  @observable accountTimezone: timezoneNames;
  @observable userTimezone: timezoneNames;
  @observable sessionTimezone: timezoneNames = getJSONItemFromStorage(STORAGE_ITEMS.sessionTimezone, null);
  repositoryAdmin: IRepository;
  repositoryAdminAccounts: IEntityRepository;

  @action setAccountTimeZone = (timezone) => (this.accountTimezone = timezone);
  @action setUserTimeZone = (timezone) => (this.userTimezone = timezone);
  @action setSessionTimeZone = (timezone) => {
    this.sessionTimezone = timezone;
    if (timezone) {
      setJSONItemToStorage(STORAGE_ITEMS.sessionTimezone, this.sessionTimezone);
    }
  };

  @action getAccountTimezone = async (accountId) => {
    try {
      const accountDetails = await this.repositoryAdminAccounts.entity(accountId).get();
      this.setAccountTimeZone(accountDetails.timeZone);
    } catch (e) {
      // tslint:disable-next-line:no-console no-unused-expression
      process.env.NODE_ENV === 'development' && console.log('TimeStore', `getAccountTimezone: error: ${e}`);
    }
  };

  @action resetTimezones = () => {
    this.accountTimezone = null;
    this.userTimezone = null;
    this.sessionTimezone = null;
    removeStorageItem(STORAGE_ITEMS.sessionTimezone);
  };
}

export default new TimeStore();
