import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import type { UniqueField } from 'models/Fields';

import Input from 'components/Input';

import './styles.scss';

interface IProps {
  title?: string;
  className?: string;
  isEditable?: boolean;
  showError?: boolean;
  errorMessage?: string;
  placeholder?: string;
  field: UniqueField;
}

function AdminUniqueField({
  isEditable = true,
  title = '',
  showError = false,
  errorMessage = '',
  className,
  field,
  placeholder,
}: IProps) {
  const handleChange = (e) => {
    field.set(e.currentTarget.value);
  };

  const handleBlur = (e) => {
    field.set(e.currentTarget.value.trim());
  };

  const cn = classnames('AdminField', className);

  return (
    <div className={cn}>
      <p>{title}</p>
      <Input
        disabled={!isEditable}
        value={String(field.value)}
        error={showError ? errorMessage : ''}
        onChange={handleChange}
        onBlur={handleBlur}
        loading={!field.isValidated}
        placeholder={placeholder}
      />
    </div>
  );
}

export default observer(AdminUniqueField);
