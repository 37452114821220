import type PlayerViewModel from './Player';

abstract class PlayerState {
  protected context: PlayerViewModel;

  setContext = (context: PlayerViewModel) => {
    this.context = context;
  };

  abstract activate(): void;

  abstract deactivate(): void;
}

export default PlayerState;
