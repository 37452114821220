import React, { FC } from 'react';
import classNames from 'classnames';

import { PlusIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  text: string;
  onClick: () => void;
}

const AddSelectOption: FC<IProps> = ({ className, text, onClick }) => {
  const cn = classNames('AddSelectOption', className);

  return (
    <div className={cn}>
      <div className="AddSelectOption-content" onClick={onClick}>
        <PlusIcon className="AddSelectOption-icon" fill="red" />
        <p className="AddSelectOption-text">{text}</p>
      </div>
    </div>
  );
};

export default AddSelectOption;
