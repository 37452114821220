import React from 'react';

import type ITableDownload from 'interfaces/models/TableDownload/ITableDownload';
import ConfirmationModal from 'components/Admin/ConfirmationModal';
import TableDropDownDownloadFormat from '../TableDropDownDownloadFormat';

import './styles.scss';

interface IProps<T> {
  isOpen: boolean;
  onClose: () => void;
  tableDownload: ITableDownload<T>;
}

function TableDownloadModal<T>({ isOpen, onClose, tableDownload }: IProps<T>): React.ReactElement {
  const handleSubmit = () => {
    tableDownload.download();
    onClose();
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onCancel={onClose}
      onSubmit={handleSubmit}
      applyButtonTitle="Download"
      cancelButtonStyles={{ border: 'none' }}
      applyButtonStyles={{ fontWeight: 600 }}
      title="Download Report"
      className="TableDownload"
    >
      <TableDropDownDownloadFormat format={tableDownload.format} />
    </ConfirmationModal>
  );
}

export default TableDownloadModal;
