import PlayerState from './PlayerState';

class BasePlayerState extends PlayerState {
  activate = () => {
    //
  };

  deactivate = () => {
    //
  };
}

export default BasePlayerState;
