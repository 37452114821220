import { SimpleField, ToggleField } from 'models/Fields';

class MediaInfoItemTitle {
  display: SimpleField<string>;
  isSet: ToggleField;

  constructor(value: string) {
    this.display = new SimpleField(value || 'Untitled Clip');
    this.isSet = new ToggleField(Boolean(value));
  }

  set = (value: string) => {
    this.display.set(value);
    this.isSet.toggle(Boolean(value));
  };
}

export default MediaInfoItemTitle;
