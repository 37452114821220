import { action, observable } from 'mobx';
import { repositoryService } from 'services';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

export type locationType = 'address' | 'coordinates' | 'poi';

export interface IGoogleSearchMeta {
  name?: string;
  additionalAddress?: string;
  hours?: string;
  website?: string;
  phone?: string;
  category?: string;
  type?: locationType;
}

export interface ISearchResults {
  address?: string;
  latitude?: number;
  longitude?: number;
  meta?: IGoogleSearchMeta;
  triggerEvent?: 'input' | 'click';
}

export class GoogleMapSearchStore {
  @observable searchResults: ISearchResults = null;
  @observable searchQuery: string = '';
  repositoryNotifications: IEntityRepository;

  constructor() {
    this.repositoryNotifications = repositoryService.get('notifications').entity('sms');
  }

  @action setSearchQuery = (query: string) => {
    this.searchQuery = query;
    if (!query.length) {
      this.searchResults = null;
    }
  };

  @action setSearchResult = (result: ISearchResults) => {
    return (this.searchResults = result);
  };

  @action shareLocation = async ({ phone, message }: { async?: boolean; phone: string; message: string }) => {
    await this.repositoryNotifications.create({ async: false, phone, message });
  };

  @action resetSearch = () => {
    this.searchResults = null;
    this.searchQuery = '';
  };
}

export default new GoogleMapSearchStore();
