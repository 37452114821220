import React from 'react';
import { observer } from 'mobx-react';
import { AudioOffIcon, AudioOnIcon } from 'assets';
import MediaManagerPlayerCameraTypeSwitcher from 'components/DashCams/MediaManagerPlayer/MediaManagerPlayerCameraTypeSwitcher';
import MediaManagerPlayerCountDown from 'components/DashCams/MediaManagerPlayer/MediaManagerPlayerCountDown';
import InfoTooltip from 'components/InfoTooltip';
import type PlayerViewModel from 'models/Dashcam/MediaRequestCheckout/Player/Player';
import type RequestMediaCamerasType from 'models/Dashcam/MediaRequestCheckout/RequestMedia/RequestMediaCamerasType';
import PlayingPlayerState from 'models/Dashcam/MediaRequestCheckout/Player/PlayingPlayerState';
import DashcamStatus from 'components/Admin/Dashcams/DashcamStatus';
import DashcamStatusModel, { DASHCAM_STATUS } from 'models/Dashcam/DashcamStatus';
import type RequestMediaTimeSegment from 'models/Dashcam/MediaRequestCheckout/RequestMedia/RequestMediaTimeSegment';

import './styles.scss';

interface IProps {
  player: PlayerViewModel;
  cameraType: RequestMediaCamerasType;
  timeSegment: RequestMediaTimeSegment;
  dashcam: DashcamStatusModel;
}

const MapVehiclePanelLiveViewHeader: React.FC<IProps> = ({ player, cameraType, timeSegment, dashcam }) => {
  const timeLeft = timeSegment.startTime.value + timeSegment.duration.active.value - timeSegment.scrubber.value;
  const isOnline = dashcam.status.value === DASHCAM_STATUS.ONLINE;
  const isOffline = dashcam.status.value === DASHCAM_STATUS.OFFLINE;
  const isBusy = dashcam.cameraDetails.isBusy.value;

  return (
    <div className="MapVehiclePanelLiveViewHeader">
      <div className="MapVehiclePanelLiveViewHeader-section MapVehiclePanelLiveViewHeader-section--left">
        <div className="MapVehiclePanelLiveViewHeader-cameraTypeSwitcher">
          <MediaManagerPlayerCameraTypeSwitcher
            cameraType={cameraType}
            isLoading={player.isLoading.value}
            onSwitch={player.changeCameraTypeOnline}
          />
        </div>
        <div className="MapVehiclePanelLiveViewHeader-status">
          <div className="MapVehiclePanelLiveViewHeader-statusText">
            <DashcamStatus dashcamPing={dashcam} />
          </div>
          {(isOnline || isOffline) && (
            <div className="MapVehiclePanelLiveViewHeader-statusTooltip">
              <InfoTooltip>
                {isBusy
                  ? 'The camera is busy. Your media request will be queued and will process when the camera comes back online.'
                  : isOnline
                  ? 'The camera is online. Your media request should be processed quickly'
                  : 'The camera is offline. Your media request will be queued and \n' +
                    'will process when the camera comes back online.'}
              </InfoTooltip>
            </div>
          )}
        </div>
      </div>
      <div className="MediaManagerPlayerHeader-section MediaManagerPlayerHeader-section--right">
        <div className="MediaManagerPlayerHeader-streamCountDown">
          <MediaManagerPlayerCountDown
            isEnd={player.isEndOfSegment.value}
            isLiveMode={player.liveMode}
            replay={player.play}
            isPlaying={player.state instanceof PlayingPlayerState}
            timeLeft={timeLeft}
          />
        </div>

        <div className="MediaManagerPlayerHeader-audio" onClick={() => player.isMuted.toggle()}>
          {player.isAudioEnabled.value && dashcam.cameraDetails.audioEnabled.value ? (
            player.isMuted.value ? (
              <AudioOffIcon className="MediaManagerPlayerHeader-audioIcon" />
            ) : (
              <AudioOnIcon className="MediaManagerPlayerHeader-audioIcon" />
            )
          ) : (
            <InfoTooltip className="MediaManagerPlayerHeader-audioUnavailable" icon={<AudioOffIcon />}>
              Sound not available for this clip
            </InfoTooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(MapVehiclePanelLiveViewHeader);
