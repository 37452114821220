import { computed } from 'mobx';
import { jsPDF } from 'jspdf';
import type InspectionPerformed from './InspectionPerformed';
import InspectionHtmlTemplate from 'components/Inspections/InspectionPrintModal/InspectionHtmlTemplate';
import { ListField } from 'models/Fields';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';

const CHARACTER_LIMIT = 512;

class InspectionPerformedPDF {
  private file: jsPDF;
  private context: InspectionPerformed;
  public emails: ListField<string>;
  public repository: IEntityRepository;

  constructor(context: InspectionPerformed) {
    this.file = null;
    this.context = context;
    this.emails = new ListField();
    this.repository = repositoryService.get('notifications').entity('email').entity('document');
  }

  public getLink = async () => {
    const blb = await this.getBlob();
    const link = window.URL.createObjectURL(blb);

    return link;
  };

  public downloadFile = async () => {
    const file = await this.getFile();

    file.save(`${this.fileName}.pdf`);
  };

  private get subject() {
    const inspection = this.context;
    return `Inspection Result: ${inspection.asset.name.value} ${inspection.results.status} ${inspection.inspectionBy} ${inspection.completedTime.date}`;
  }

  private get description() {
    const inspection = this.context;
    return `Inspection Result: ${inspection.asset.name.value} ${inspection.results.status} ${inspection.inspectionBy} ${inspection.completedTime.date}`;
  }

  public emailFile = async () => {
    const file = await this.getBlob();
    const data = new FormData();

    data.append('emails', this.emails.toArray().join(','));
    data.append('format', 'pdf');
    data.append('file', file, `${this.fileName}.pdf`);
    data.append('subject', this.subject);
    data.append('description', this.description);

    const response = await this.repository.create(data);

    if (response.status !== 'SENT') {
      this.repository.createState.setError('report failed');
    }
  };

  @computed get isCharacterLimitReached() {
    return this.getTotalCharacter() >= CHARACTER_LIMIT;
  }

  @computed get characterLimitLeft() {
    return CHARACTER_LIMIT - this.getTotalCharacter();
  }

  private get fileName() {
    const inspection = this.context;
    return `Inspection.${inspection.asset.name.value}.${inspection.completedTime.date}`;
  }

  private getBlob = async () => {
    const file = await this.getFile();
    const blb = file.output('blob');

    return blb;
  };

  private create = async () => {
    const pdfDoc = new jsPDF({ orientation: 'p', unit: 'px', format: 'a4', hotfixes: ['px_scaling'] });
    const template = await InspectionHtmlTemplate(this.context);

    const getDoc = () => {
      return new Promise((resolve: (value: jsPDF) => void) => {
        pdfDoc.html(template, {
          callback: (doc: jsPDF) => {
            resolve(doc);
          },
        });
      });
    };

    const pdf = await getDoc();

    return pdf;
  };

  private getFile = async () => {
    if (!this.file) {
      await this.context.fetch();
      this.file = await this.create();
    }

    return this.file;
  };

  private getTotalCharacter = () => {
    return this.emails.value.reduce((previousValue, currentItem) => previousValue + currentItem.value.length + 1, 0);
  };
}

export default InspectionPerformedPDF;
