import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import { PATHS } from 'config';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';
import type { RouterStore, DevicesStore, MapStore, EventsRegistryStore, PersistenceStore, UserStore } from 'stores';
import type { SubNavUIStateStore } from 'components/SubNav/SubNavUIState.store';

import GroupsSelector from '../GroupsSelector';
import SubNavFilters from 'components/SubNav/Filters';
import VehicleItemsList from 'components/SubNav/VehicleItemsList';

import './styles.scss';

interface IMatchParams {
  vehicleId: string;
  groupId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  devicesStore?: DevicesStore;
  routerStore?: RouterStore;
  mapStore?: MapStore;
  subNavStore?: SubNavUIStateStore;
  persistenceStore?: PersistenceStore;
  eventsRegistryStore: EventsRegistryStore;
  userStore?: UserStore;
}

@inject(
  ({
    devicesMapStore: { devicesStore, mapStore },
    routerStore,
    persistenceStore,
    uiStateStore: { subNav },
    eventsRegistryStore,
    userStore,
  }) => ({
    devicesStore,
    routerStore,
    mapStore,
    subNavStore: subNav,
    eventsRegistryStore,
    persistenceStore,
    userStore,
  })
)
@observer
class VehiclesContainer extends Component<IProps> {
  constructor(props) {
    super(props);

    const {
      devicesStore: { groups },
      match: {
        params: { groupId },
      },
    } = this.props;

    if (groupId) {
      groups.groupId.set(groupId);
    }
  }

  async componentDidMount() {
    const {
      devicesStore: { getDevices, devicesListCount },
    } = this.props;

    if (!devicesListCount) {
      await getDevices();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      devicesStore: { resetSelectedDevice, groups },
      persistenceStore: { updateSelectedGroup },
      mapStore: { recenterToFleet },
      match: {
        params: { vehicleId, groupId },
      },
    } = this.props;

    if (groupId && groupId !== 'all' && prevProps.match.params.groupId !== groupId) {
      groups.groupId.set(groupId);

      const group = groups.findGroupById(groupId);

      groups.setSelected(group);
      updateSelectedGroup({
        label: group.displayName,
        value: group.groupId,
      });
      EventsBus.get().once(APP_EVENTS.DEVICES.GET, recenterToFleet);
    }

    if (!vehicleId) {
      resetSelectedDevice();
    }
  }

  handleStatusesChange = (eventStatus: string) => {
    const {
      eventsRegistryStore: {
        devices: { toggle },
      },
    } = this.props;

    toggle(eventStatus);
  };

  handleStatusesReset = () => {
    const {
      eventsRegistryStore: { devices },
      devicesStore: { filters },
    } = this.props;

    devices.reset();
    filters.attributes.reset();
  };

  handleSelectGeozone = (id) => {
    this.props.routerStore.history.push(PATHS.MAP.GEOZONE.replace(':geozoneId', id));
  };

  setHistoryMode = (id) => {
    const {
      devicesStore: {
        history: { setMode },
      },
    } = this.props;

    setMode(id);
  };

  render() {
    const {
      devicesStore: {
        devicesList,
        filteredDevicesList,
        filters: {
          date,
          query,
          attributes,
          setQuery,
          tempDate: { to },
        },
        getDevicesRequestStatus,
        pinnedDevicesIdsList,
        selectedDeviceId,
        setPinnedDevicesIds,
        dragPinnedDevices,
        groups,
        showHistory,
      },
      mapStore: { mapLabelSize, timezone, mapHeading, showDirectionalArrows },
      subNavStore: { panelMode, setPanelMode },
      eventsRegistryStore: {
        filtersOrder,
        devices: { filterItems },
      },
      userStore: { noDriverAssignedToVehicle },
    } = this.props;

    return (
      <div className="VehiclesContainer">
        <div className="VehiclesContainer-groupsSelector">
          <GroupsSelector />
        </div>
        <div className="VehiclesContainer-filters">
          <SubNavFilters
            isDisabled={Boolean(!devicesList.length && !date.to)}
            onPanelModeChange={setPanelMode}
            onSearchQueryChange={setQuery}
            onStatusesChange={this.handleStatusesChange}
            panelMode={panelMode}
            resetStatuses={this.handleStatusesReset}
            searchPlaceholder="Filter Driver/Vehicle..."
            searchQuery={query}
            filterEvents={filterItems}
            filterOrder={filtersOrder.devices}
            filterAttributes={attributes}
            withStatusesFilter
            showHistory={showHistory}
          />
        </div>
        {noDriverAssignedToVehicle ? (
          <div className="VehiclesContainer-noDriverAssignedToVehicle">
            <p className="VehiclesContainer-noDriverAssignedToVehicleText">
              You are currently not assigned to any Vehicles
            </p>
            <p className="VehiclesContainer-noDriverAssignedToVehicleText">
              Contact your account administrator to assign you to a vehicle.
            </p>
          </div>
        ) : (
          <div
            className={classNames('VehiclesContainer-vehiclesItemsList', {
              'VehiclesContainer-vehiclesItemsList--loading': Boolean(getDevicesRequestStatus.loading),
            })}
          >
            {Boolean(getDevicesRequestStatus.success) && (
              <VehicleItemsList
                devices={filteredDevicesList}
                dragPinnedDevices={dragPinnedDevices}
                groupId={groups.groupId.value}
                itemMode={panelMode}
                itemSize={mapLabelSize}
                mapHeading={mapHeading}
                onItemPin={setPinnedDevicesIds}
                pinnedDevicesIdsList={pinnedDevicesIdsList}
                selectedDeviceId={selectedDeviceId}
                setHistoryMode={this.setHistoryMode}
                showDirectionalArrows={showDirectionalArrows.value}
                showHistory={showHistory}
                timezone={timezone}
                toDate={to}
                vehicleItemGeozoneClick={this.handleSelectGeozone}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(VehiclesContainer);
