import React, { CSSProperties, FC } from 'react';

import ConfirmationModal from 'components/Admin/ConfirmationModal';

import './styles.scss';

interface IProps {
  cancelButtonStyles?: CSSProperties;
  title: string;
  isOpen?: boolean;
  onCancel: () => void;
  onSubmit?: () => void;
  onOpen?: () => void;
  isSubmitDisabled?: boolean;
  applyButtonTitle?: string;
  cancelButtonTitle?: string;
  contentStyles?: CSSProperties;
  withoutCloseIcon?: boolean;
}

const MaintenanceModal: FC<IProps> = ({
  cancelButtonStyles,
  title,
  isOpen,
  isSubmitDisabled,
  onCancel,
  onSubmit,
  onOpen,
  children,
  applyButtonTitle,
  cancelButtonTitle,
  contentStyles,
  withoutCloseIcon,
}) => {
  return (
    <ConfirmationModal
      cancelButtonStyles={cancelButtonStyles}
      applyButtonTitle={applyButtonTitle}
      cancelButtonTitle={cancelButtonTitle}
      closeOutside={false}
      contentStyles={contentStyles}
      isOpen={isOpen}
      isSubmitDisabled={isSubmitDisabled}
      onCancel={onCancel}
      onOpen={onOpen}
      onSubmit={onSubmit}
      title={title}
      withoutCloseIcon={withoutCloseIcon}
    >
      {children}
    </ConfirmationModal>
  );
};

interface IPropsRow {
  className?: string;
}

const MaintenanceModalRow: FC<IPropsRow> = ({ children, className = '' }) => {
  return <div className={`MaintenanceModal-modalRow ${className}`}>{children}</div>;
};

const MaintenanceModalLabel: FC = ({ children }) => {
  return <div className="MaintenanceModal-modalLabel">{children}</div>;
};

const MaintenanceModalValue: FC = ({ children }) => {
  return <div className="MaintenanceModal-modalValue">{children}</div>;
};

const MaintenanceModalInput: FC = ({ children }) => {
  return <div className="MaintenanceModal-modalInput">{children}</div>;
};

const MaintenanceModalUnits: FC = ({ children }) => {
  return <div className="MaintenanceModal-modalUnits">{children}</div>;
};

export {
  MaintenanceModal as default,
  MaintenanceModalRow,
  MaintenanceModalLabel,
  MaintenanceModalValue,
  MaintenanceModalInput,
  MaintenanceModalUnits,
};
