import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';

import { MAINTENANCES_STORE } from 'config';
import type { Maintenances } from 'stores';

import ReportCompletedTaskPanel from 'components/Reports/ReportCompletedTaskPanel';

interface IProps {
  maintenancesStore?: Maintenances;
  item: {
    value: string;
    sortValue?: number;
    mapLink: { lat: number; lon: number };
    color?: string;
    type: string;
    maintenanceId?: string;
  };
  className?: string;
}

@inject(MAINTENANCES_STORE)
@observer
class ReportTableByGroupRowEventCellMaintenanceLink extends Component<IProps> {
  render() {
    const {
      maintenancesStore: { fetchCompleted },
      item,
      className,
    } = this.props;

    return (
      <div className={classnames(className, 'ReportTableByGroupRowEvent-Link')}>
        <ReportCompletedTaskPanel title={item.value} id={item.maintenanceId} onClick={fetchCompleted} />
      </div>
    );
  }
}

export default ReportTableByGroupRowEventCellMaintenanceLink;
