import React from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  className?: string;
  filter: Filters.IPredefinedTimeFilterOption;
  setTimeRange: (range: Filters.IDateFilterOption) => void;
}

const PredefinedTimeFilterLink: React.FC<IProps> = ({
  className,
  filter: { name, timeRange, value },
  setTimeRange,
}) => {
  const cn = classNames('PredefinedTimeFilterLink', className);

  return (
    <div className={cn} onClick={() => setTimeRange(value)}>
      <span className="PredefinedTimeFilterLink-name">{name}</span>
      <span className="PredefinedTimeFilterLink-timeRange">{timeRange}</span>
    </div>
  );
};

export default PredefinedTimeFilterLink;
