import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { parse } from 'query-string';

import { PATHS } from 'config';

import './styles.scss';

interface IQueryParams {
  token?: string;
  email?: string;
}

const WelcomeEmail: FC = () => {
  let { token, email } = useMemo(() => ({ token, email } = parse(window.location.search) as IQueryParams), []);

  return (
    <div className="WelcomeEmail">
      <div className="WelcomeEmail-wrapper">
        <div className="WelcomeEmail-header">
          <div className="WelcomeEmail-headerLogo">
            <img src="https://public-data.clearpathgps.com/logo-dark-bg.svg" alt="Header logo" />
          </div>
          <img
            className="WelcomeEmail-headerImage"
            src="https://public-data.clearpathgps.com/welcome.png"
            alt="Map image"
          />
        </div>
        <div className="WelcomeEmail-content">
          <p className="WelcomeEmail-text WelcomeEmail-text--dark">
            An account has been created for you on our GPS fleet management portal. Click below to get started:
          </p>
          <Link className="WelcomeEmail-button" to={`${PATHS.AUTH.CREATE_USER}?token=${token}&email=${email}`}>
            Setup My New Account
          </Link>
          <p className="WelcomeEmail-text WelcomeEmail-text--medium WelcomeEmail-text--expiry">
            This link is only valid for one use and will expire after 30 days.
          </p>
          <div className="WelcomeEmail-line" />
          <p className="WelcomeEmail-text WelcomeEmail-text--bookmark">
            Be sure to bookmark the portal’s{' '}
            <Link className="link" to={PATHS.AUTH.LOGIN}>
              Login Page
            </Link>
            .
          </p>
          <p className="WelcomeEmail-text WelcomeEmail-text--padding WelcomeEmail-text--medium">
            Additionally, you can access ClearPathGPS by going to our{' '}
            <a href="https://www.clearpathgps.com/" className="WelcomeEmail-link" target="_blank">
              Home Page
            </a>{' '}
            and clicking on the <b>Customer Log In</b> link in the top right corner.
          </p>
          <div className="WelcomeEmail-line" />
          <p className="WelcomeEmail-text"> Be sure to download our mobile app too! </p>
          <div className="WelcomeEmail-apps">
            <a
              className="WelcomeEmail-appImage"
              href="https://apps.apple.com/us/app/clearpathgps-manager/id1485414109?utm_source=portal"
            >
              <img
                className="WelcomeEmail-appImageSrc"
                src="https://public-data.clearpathgps.com/image_app_store.png"
                alt="App store"
              />
            </a>
            <a
              className="WelcomeEmail-appImage"
              href="https://play.google.com/store/apps/details?id=com.clearpathgps.android.production&hl=en_US&utm_source=portal&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            >
              <img
                className="WelcomeEmail-appImageSrc"
                src="https://public-data.clearpathgps.com/image_google_play.png"
                alt="Play market"
              />
            </a>
          </div>
          <p className="WelcomeEmail-text WelcomeEmail-text--small WelcomeEmail-text--emailInfo WelcomeEmail-text--padding WelcomeEmail-text--lightGrey">
            This email was sent to {email} from the ClearPathGPS online portal. If you received this message in error,
            please invalidate the link by clicking{' '}
            <a className="WelcomeEmail-link" href="">
              here
            </a>{' '}
            or contact <span className="WelcomeEmail-link">support@clearpathgps.com</span> |{' '}
            <span className="WelcomeEmail-link">(805) 979 3442</span>
          </p>
          <div className="WelcomeEmail-footer">
            <img src="https://public-data.clearpathgps.com/logo-white-bg.svg" alt="" className="WelcomeEmail-logo" />
            <p className="WelcomeEmail-text WelcomeEmail-text--lightGrey WelcomeEmail-text--xsmall">
              © {new Date().getFullYear()} ClearPathGPS, Inc. | All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeEmail;
