import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { PATHS } from 'config';

import type { MapStore, CustomersStore } from 'stores';

import withCollapseStorage from 'hocs/withCollapseStorage';

import CloseButton from 'components/Button/CloseButton';
import SecondaryPanelInvoiceDetails from './SecondaryPanelInvoiceDetails';
import SecondaryPanelServiceAddress from './SecondaryPanelServiceAddress';
import SecondaryPanelEstimates from './SecondaryPanelEstimates';

import CustomerDomain from '../../../CustomerDomain';

import { Collapse } from 'antd';
import { ChevronIcon } from 'assets';

import './styles.scss';

enum collapsePanels {
  InvoiceDetails = 'invoice-details',
  ServiceAddress = 'service-address',
  Estimates = 'estimates',
}

interface IMatchParams {
  invoiceId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  customersStore: CustomersStore;
  mapStore?: MapStore;
  collapseKeys: string[];

  onCollapseChange(values: string | string[]): void;
}

@inject(({ customersStore, devicesMapStore: { mapStore } }) => ({
  customersStore,
  mapStore,
}))
@observer
class SecondaryPanelInvoiceQB extends Component<IProps> {
  get selectedCustomer() {
    const {
      customersStore: { selectedCustomer },
    } = this.props;
    return selectedCustomer;
  }

  handleCloseClick = () => {
    const {
      history: { push },
      customersStore: { selectedCustomerId },
    } = this.props;

    push(PATHS.MAP.CUSTOMER.replace(':customerId', selectedCustomerId));
  };

  render() {
    const { collapseKeys, onCollapseChange } = this.props;

    const selectedCustomer = this.selectedCustomer;

    if (!selectedCustomer.selectedInvoice) {
      return null;
    }

    return (
      <div className="SecondaryPanelInvoice">
        <div className="SecondaryPanelInvoice-header">
          <div className="SecondaryPanelInvoice-name">
            <div className="SecondaryPanelInvoice-integration">QuickBooks Invoice</div>
            <div className="SecondaryPanelInvoice-basic">
              <div className="SecondaryPanelInvoice-icon">
                <CustomerDomain domain={selectedCustomer.domain} size={24} />
              </div>
              <div className="SecondaryPanelInvoice-info">
                <div className="SecondaryPanelInvoice-id">{selectedCustomer.selectedInvoice.id}</div>
              </div>
            </div>
          </div>
          <div className="SecondaryPanelInvoice-close">
            <CloseButton onClick={this.handleCloseClick} />
          </div>
        </div>

        <div className="SecondaryPanelInvoice-content">
          <Collapse
            activeKey={collapseKeys}
            expandIcon={() => (
              <span className="anticon anticon-right ant-collapse-arrow">
                <ChevronIcon />
              </span>
            )}
            expandIconPosition={'left'}
            onChange={onCollapseChange}
          >
            <Collapse.Panel
              header={'Invoice Details'}
              key={collapsePanels.InvoiceDetails}
              className="SecondaryPanelInvoice-Block"
            >
              <SecondaryPanelInvoiceDetails />
            </Collapse.Panel>
            <Collapse.Panel
              header={'Service Address'}
              key={collapsePanels.ServiceAddress}
              className="SecondaryPanelInvoice-Block"
            >
              <SecondaryPanelServiceAddress />
            </Collapse.Panel>
            <Collapse.Panel header={'Estimates'} key={collapsePanels.Estimates} className="SecondaryPanelInvoice-Block">
              <SecondaryPanelEstimates />
            </Collapse.Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}

export default withRouter(
  withCollapseStorage(SecondaryPanelInvoiceQB, 'map-secondary-panel-invoice-qb-collapse', Object.values(collapsePanels))
);
