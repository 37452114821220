import React, { Component } from 'react';
// @ts-ignore
import DayPicker, { DateUtils } from 'react-day-picker';
import type { Modifier } from 'react-day-picker/types/Modifiers';
import classNames from 'classnames';

import { getBrowserName, getBrowserVersion, subtractMonths } from 'utils';

import YearMonthForm from './YearMonthForm';

import 'react-day-picker/lib/style.css';
import './styles.scss';

interface IProps {
  withMonthYearForm?: boolean;
  withRange?: boolean;
  withoutOutside?: boolean;
  onClick?: (date: Date) => void;
  month?: Date;
  disabledDays?:
    | Modifier
    | Modifier[]
    | Array<{
        after: Date;
        before: Date;
      }>;
  numberOfMonths?: number;
  /* range props */
  from?: Date;
  to?: Date;
  fromMonth?: Date;
  toMonth?: Date;
  /* selected day props */
  selectedDate?: Date;
  ignoreNull?: boolean;
}

interface IState {
  from?: Date;
  to?: Date;
  month?: Date;
  selectedDay?: Date;
}

class Calendar extends Component<IProps, IState> {
  static defaultProps = {
    fromMonth: new Date(2015, 0),
    onClick: () => true,
    toMonth: new Date(new Date().getFullYear() + 10, 11),
    withRange: false,
  };

  constructor(props) {
    super(props);

    // const { fromMonth, toMonth } = this.props;
    const { month } = this.props;

    this.state = {
      ...this.getInitialState(),
      month,
    };
  }

  getInitialState = () => {
    const { withRange, selectedDate, from, to } = this.props;
    const selectedDateState = {
      selectedDay: selectedDate || null,
    };
    const dateRangeState = {
      from: from || undefined,
      to: to || undefined,
    };

    return withRange ? dateRangeState : selectedDateState;
  };

  handleDayClick = (day, modifiers = {}) => {
    // @ts-ignore
    if (modifiers.disabled) {
      return;
    }

    const { withRange, onClick, ignoreNull } = this.props;

    if (withRange) {
      // @ts-ignore
      const range = DateUtils.addDayToRange(day, this.state);
      if ((range.from === null || range.to === null) && ignoreNull) {
        return;
      }
      this.setState(range);
      // @ts-ignore
      onClick(range);
    } else {
      this.setState({ selectedDay: day });
      onClick(day);
    }
  };

  handleYearMonthChange = (month, index?: number) => {
    this.setState({
      month: subtractMonths(month, this.props.numberOfMonths > 1 ? index % 2 : 0),
    });
  };

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (this.props.selectedDate?.getTime?.() !== prevProps.selectedDate?.getTime?.()) {
      this.setState({
        ...this.getInitialState(),
        month: this.props.month,
      });
    }
  }

  render() {
    const {
      withMonthYearForm,
      fromMonth,
      toMonth,
      withRange,
      disabledDays,
      numberOfMonths,
      withoutOutside,
    } = this.props;
    const { from, to, month, selectedDay } = this.state;
    const modifiers = { start: from, end: to };
    const cn = classNames('Calendar', {
      'Calendar--withMonthYear': Boolean(withMonthYearForm),
      'Calendar--withRange': Boolean(withRange),
      'Calendar--withoutOutside': Boolean(withoutOutside),
      'Calendar--oldSafari': getBrowserName() === 'safari' && getBrowserVersion() < '15',
      'Calendar--double': numberOfMonths > 1,
    });
    const selectedDays = withRange ? [from, { from, to }] : selectedDay;
    let index = 0;
    const additionalOptions = withMonthYearForm
      ? {
          captionElement: (props) => {
            const { date, localeUtils } = props;
            return (
              <YearMonthForm
                key={index}
                index={index++}
                date={date}
                fromMonth={fromMonth}
                toMonth={toMonth}
                localeUtils={localeUtils}
                numberOfMonths={numberOfMonths}
                onChange={this.handleYearMonthChange}
              />
            );
          },
          fromMonth,
          month,
          toMonth,
        }
      : null;

    return (
      <div className={cn}>
        <DayPicker
          className="Selectable"
          selectedDays={selectedDays}
          fromMonth={fromMonth}
          toMonth={toMonth}
          onDayClick={this.handleDayClick}
          modifiers={modifiers}
          showOutsideDays
          disabledDays={disabledDays}
          numberOfMonths={numberOfMonths}
          onMonthChange={this.handleYearMonthChange}
          {...additionalOptions}
        />
      </div>
    );
  }
}

export default Calendar;
