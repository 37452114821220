import { computed, observable } from 'mobx';
import TableBase from 'stores/TableBase';
import type PersonAccessRoleModel from '../PersonAccess/PersonAccessRoleModel';
import type { PeopleAdmin } from 'stores/Admin/People';
import type { IEditColumns } from 'models/Tables/IEditColumns';
import type { ISortColumn } from 'models/Tables/ISortColumn';
import { PEOPLE_ADMIN_SEARCH_PARAMS, PEOPLE_ADMIN_TABLE_COLUMNS, STORAGE_ITEMS } from 'config';
import { formatPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';
import PeopleTableDownload from './PeopleTableDownload';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import { repositoryService } from 'services';
import Filters from './Filters';

const initialColumns = [
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.NAME, isSelected: true },
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.LOGIN_EMAIL, isSelected: true },
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.JOB_TITLE, isSelected: true },
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.CONTACT_NUMBER, isSelected: true },
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.USER_ACCESS, isSelected: true },
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.USER_ROLE, isSelected: true },
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.PERMISSIONS, isSelected: true },
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.ASSIGNED_VEHICLE, isSelected: true },
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.EMPLOYEE_ID, isSelected: false },
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.ADDRESS, isSelected: false },
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.TIME_ZONE, isSelected: false },
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.LICENCE_EXPIRATION, isSelected: false },
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.FOB_ID, isSelected: false },
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.LAST_WEB_LOGIN, isSelected: false },
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.LAST_MOBILE_APP_LOGIN, isSelected: false },
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.CREATED_BY, isSelected: false },
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.CREATED_ON, isSelected: false },
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.MODIFIED_BY, isSelected: false },
  { value: PEOPLE_ADMIN_TABLE_COLUMNS.MODIFIED_ON, isSelected: false },
];

const initialSortedColumn: ISortColumn = {
  field: PEOPLE_ADMIN_TABLE_COLUMNS.NAME,
  order: 'ascend',
};

const storageNames = {
  columns: STORAGE_ITEMS.admin.people.columns,
  sortedColumn: STORAGE_ITEMS.admin.people.sortedColumn,
  pagination: STORAGE_ITEMS.admin.people.pagination,
  searchInColumn: STORAGE_ITEMS.admin.people.searchInColumn,
};

const searchMap = {
  [PEOPLE_ADMIN_TABLE_COLUMNS.NAME]: PEOPLE_ADMIN_SEARCH_PARAMS.CONTACT_NAME,
  [PEOPLE_ADMIN_TABLE_COLUMNS.LOGIN_EMAIL]: PEOPLE_ADMIN_SEARCH_PARAMS.LOGIN_EMAIL,
  [PEOPLE_ADMIN_TABLE_COLUMNS.JOB_TITLE]: PEOPLE_ADMIN_SEARCH_PARAMS.JOB_TITLE,
  [PEOPLE_ADMIN_TABLE_COLUMNS.CONTACT_NUMBER]: PEOPLE_ADMIN_SEARCH_PARAMS.CONTACT_NUMBER,
  [PEOPLE_ADMIN_TABLE_COLUMNS.PERMISSIONS]: PEOPLE_ADMIN_SEARCH_PARAMS.PERMISSIONS,
  [PEOPLE_ADMIN_TABLE_COLUMNS.ASSIGNED_VEHICLE]: PEOPLE_ADMIN_SEARCH_PARAMS.ASSIGNED_VEHICLE,
  [PEOPLE_ADMIN_TABLE_COLUMNS.EMPLOYEE_ID]: PEOPLE_ADMIN_SEARCH_PARAMS.EMPLOYEE_ID,
  [PEOPLE_ADMIN_TABLE_COLUMNS.ADDRESS]: PEOPLE_ADMIN_SEARCH_PARAMS.ADDRESS,
  [PEOPLE_ADMIN_TABLE_COLUMNS.TIME_ZONE]: PEOPLE_ADMIN_SEARCH_PARAMS.TIME_ZONE,
  [PEOPLE_ADMIN_TABLE_COLUMNS.LICENCE_EXPIRATION]: PEOPLE_ADMIN_SEARCH_PARAMS.LICENCE_EXPIRATION,
  [PEOPLE_ADMIN_TABLE_COLUMNS.LAST_WEB_LOGIN]: PEOPLE_ADMIN_SEARCH_PARAMS.LAST_WEB_LOGIN,
  [PEOPLE_ADMIN_TABLE_COLUMNS.LAST_MOBILE_APP_LOGIN]: PEOPLE_ADMIN_SEARCH_PARAMS.LAST_MOBILE_APP_LOGIN,
  [PEOPLE_ADMIN_TABLE_COLUMNS.CREATED_BY]: PEOPLE_ADMIN_SEARCH_PARAMS.CREATED_BY,
  [PEOPLE_ADMIN_TABLE_COLUMNS.CREATED_ON]: PEOPLE_ADMIN_SEARCH_PARAMS.CREATION_TIME,
  [PEOPLE_ADMIN_TABLE_COLUMNS.MODIFIED_BY]: PEOPLE_ADMIN_SEARCH_PARAMS.UPDATED_BY,
  [PEOPLE_ADMIN_TABLE_COLUMNS.MODIFIED_ON]: PEOPLE_ADMIN_SEARCH_PARAMS.LAST_UPDATE_TIME,
};

const getRoleName = (roles: PersonAccessRoleModel[]) => {
  const namesList = roles.reduce((names, currentRole) => {
    names.push(currentRole.name);
    return names;
  }, []);

  return namesList.join(', ');
};

export enum CELL_ICONS {
  WEB = 'Web',
  MOBILE = 'Mobile',
  DRIVER = 'Driver',
  VEHICLE = 'Vehicle Inspector',
  GROUP = 'Vehicle Group Access Restrictions',
  DASHCAM = 'Dashcam access',
}

export default class PeopleAdminTable extends TableBase<IEditColumns<PEOPLE_ADMIN_TABLE_COLUMNS>> {
  @observable downloadTable: PeopleTableDownload;
  filters: Filters;
  context: PeopleAdmin;
  repositoryNotifications: IRepository;

  constructor(context: PeopleAdmin) {
    super({
      columns: initialColumns,
      sortedColumn: initialSortedColumn,
      storageNames,
    });

    this.context = context;
    this.repositoryNotifications = repositoryService.get('notifications');
    this.downloadTable = new PeopleTableDownload(
      this.repositoryNotifications.entity('email').entity('document')
    ).initialize(this);
    this.filters = new Filters({ permissions: this.permissionsColumnInitialValues });
  }

  @computed get source() {
    return this.context.items.map((person) => ({
      [PEOPLE_ADMIN_TABLE_COLUMNS.NAME]: person.details.name.value || '-',
      [PEOPLE_ADMIN_TABLE_COLUMNS.LOGIN_EMAIL]: person.details.contacts.email.login.value || '-',
      [PEOPLE_ADMIN_TABLE_COLUMNS.JOB_TITLE]: person.details.jobTitle.value || '-',
      [PEOPLE_ADMIN_TABLE_COLUMNS.CONTACT_NUMBER]: isPossiblePhoneNumber(person.details.contacts.officePhone.value)
        ? formatPhoneNumber(person.details.contacts.officePhone.value)
        : '-',
      [PEOPLE_ADMIN_TABLE_COLUMNS.USER_ACCESS]: {
        [CELL_ICONS.WEB]: {
          value: person.access.webPortalAccess.active.value,
          tooltip: `Last Web Login: ${person.access.webPortalAccess.lastLoginTime.date || '-'}`,
        },
        [CELL_ICONS.MOBILE]: {
          value: person.access.mobileAppAccess.active.value,
          tooltip: `Last Mobile App Login: ${person.access.mobileAppAccess.lastLoginTime.date || '-'}`,
        },
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.USER_ROLE]: getRoleName(person.access.roles.toArray()),
      [PEOPLE_ADMIN_TABLE_COLUMNS.PERMISSIONS]: {
        [CELL_ICONS.DRIVER]: { value: person.permissions.driver.active.value, tooltip: 'Driver' },
        [CELL_ICONS.VEHICLE]: {
          value: person.permissions.vehicleInspector.active.value,
          tooltip: 'Vehicle Inspector',
        },
        [CELL_ICONS.GROUP]: {
          value: person.permissions.restrictVehicleAccess.active.value,
          tooltip: 'Vehicle Group Access Restrictions',
        },
        [CELL_ICONS.DASHCAM]: {
          value: person.permissions.dashcamMediaAccess.active.value,
          tooltip: 'Dashcam access',
        },
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.ASSIGNED_VEHICLE]: person.permissions.driver.assignedVehicle.name.value || '-',
      [PEOPLE_ADMIN_TABLE_COLUMNS.EMPLOYEE_ID]: person.details.employeeId.value || '-',
      [PEOPLE_ADMIN_TABLE_COLUMNS.ADDRESS]: person.details.address.fullAddress || '-',
      [PEOPLE_ADMIN_TABLE_COLUMNS.TIME_ZONE]: person.access.timeZone.value || '-',
      [PEOPLE_ADMIN_TABLE_COLUMNS.LICENCE_EXPIRATION]: person.permissions.driver.licenseExpiration.value,
      [PEOPLE_ADMIN_TABLE_COLUMNS.FOB_ID]: person.permissions.driver.keyFob.value.keyFob || '-',
      [PEOPLE_ADMIN_TABLE_COLUMNS.LAST_WEB_LOGIN]: person.access.webPortalAccess.lastLoginTime.date || '-',
      [PEOPLE_ADMIN_TABLE_COLUMNS.LAST_MOBILE_APP_LOGIN]: person.access.mobileAppAccess.lastLoginTime.date || '-',
      [PEOPLE_ADMIN_TABLE_COLUMNS.ID]: person.id.value,
      [PEOPLE_ADMIN_TABLE_COLUMNS.CREATED_BY]: person.loggerInfo.createdBy || '-',
      [PEOPLE_ADMIN_TABLE_COLUMNS.CREATED_ON]: person.loggerInfo.creationTime.date || '-',
      [PEOPLE_ADMIN_TABLE_COLUMNS.MODIFIED_BY]: person.loggerInfo.updatedBy || '-',
      [PEOPLE_ADMIN_TABLE_COLUMNS.MODIFIED_ON]: person.loggerInfo.lastUpdateTime.date || '-',
      isEmailPresent: Boolean(person.details.contacts.email.login.value),
      key: person.id.value,
    }));
  }

  private get permissionsColumnInitialValues() {
    return this.selectedSearchColumns.find((col) => col.column === PEOPLE_ADMIN_TABLE_COLUMNS.PERMISSIONS)?.value || '';
  }

  @computed get searchData() {
    return this.selectedSearchColumns.reduce((acc, cur) => {
      return {
        ...acc,
        [searchMap[cur.column]]: cur.value,
      };
    }, {});
  }

  @computed get sortData() {
    return {
      sortBy: searchMap[this.sortedColumn.field],
      order: this.sortedColumn.order?.replace('end', '').toUpperCase(),
    };
  }
}
