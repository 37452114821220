import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';

import type { AlertsTableStore, UserStore } from 'stores';
import type { IAlert } from 'models/Alerts/IAlert';
import AfterHoursAlertModel from 'models/Alerts/AfterHoursAlert';
import CustomAlertModel from 'models/Alerts/CustomAlert';
import MaintenanceAlertModel from 'models/Alerts/MaintenanceAlertModel';

import {
  ALERTS_TYPE,
  ALERT_MODAL_TYPE,
  ALERT_STEP_INDEX,
  NEW_ALERT_ACCELERATION,
  NEW_ALERT_AFTER_HOURS,
  NEW_ALERT_BRAKING,
  NEW_ALERT_CORNERING,
  NEW_ALERT_EXCESSIVE_IDLING,
  NEW_ALERT_FAULT_CODES,
  NEW_ALERT_GEOZONE,
  NEW_ALERT_IGNITION,
  NEW_ALERT_LOW_VEHICLE_BATTERY,
  NEW_ALERT_POWER_DISCONNECT_TRACKER,
  // NEW_ALERT_POWER_DISCONNECT_DASHCAM,
  NEW_ALERT_SPEEDS_OVER,
  NEW_ALERT_CUSTOM,
  NEW_ALERT_FUELING_EXCEPTION,
  ACL,
  ALERTS_APPLIES_TO,
} from 'config';
import validateAccessLevel from 'stores/acl/validator';

import AlertModal from 'components/Alert/AlertModal';
import AlertPreferenceNotify from 'components/Alert/AlertPreferences/AlertPreferenceNotify';
import AlertPreferenceSelectAlert from 'components/Alert/AlertPreferences/AlertPreferenceSelectAlert';
import AlertPreferenceAlertDetails from 'components/Alert/AlertPreferences/AlertPreferenceAlertDetails';
import AlertPreferenceFinalize from 'components/Alert/AlertPreferences/AlertPreferenceFinalize';

interface IProps {
  alertsTableStore?: AlertsTableStore;
  modalType: ALERT_MODAL_TYPE;
  savedStep: number;
  onClose: () => void;
  onChangeStep: (index: number) => void;
  userStore?: UserStore;
}

interface IState {
  hitNextNotify: boolean;
  activeStep: number;
  isActiveNextButton: boolean;
}

const steps = [
  {
    hasPreviousStep: false,
    hasNextStep: true,
    title: 'Select Alert',
    validate: (alert: IAlert) => Boolean(alert),
  },
  {
    hasPreviousStep: true,
    hasNextStep: true,
    title: 'Alert Details',
    validate: (alert: IAlert) => {
      if (alert instanceof MaintenanceAlertModel) {
        if (
          alert.basedOn.value === 'TASK' &&
          !alert.specificTask?.maintenance?.task?.item?.id &&
          alert.metadata.appliesTo.value !== ALERTS_APPLIES_TO.GROUP
        ) {
          alert.specificTask?.maintenance?.task?.isInvalid?.toggle?.(true);
          return Boolean(false);
        }
      }

      if (alert instanceof AfterHoursAlertModel) {
        return (
          alert.displayName.isValid &&
          !alert.schedule.weekdays.isEmpty &&
          (!alert.scheduleOverrideOption.value || !alert.scheduleOverride.weekdays.isEmpty)
        );
      } else {
        return Boolean(alert.displayName.isValid);
      }
    },
  },
  {
    hasPreviousStep: true,
    hasNextStep: true,
    title: 'Notify',
    validate: (alert: IAlert) => {
      const isOneEmailValidAndNotEmpty = alert.notifyEmails.value.some((item) => item.isValid && !item.isEmpty);
      const isOneMobileValidAndNotEmpty = alert.notifyMobileNumbers.value.some((item) => item.isValid && !item.isEmpty);
      const isEmailsValidOrEmpty = alert.notifyEmails.value.every((item) => item.isValid || item.isEmpty);
      const isMobilesValidOrEmpty = alert.notifyMobileNumbers.value.every((item) => item.isValid || item.isEmpty);

      if (alert instanceof CustomAlertModel) {
        return isEmailsValidOrEmpty && isMobilesValidOrEmpty;
      } else {
        return (
          (isOneEmailValidAndNotEmpty || isOneMobileValidAndNotEmpty) && isEmailsValidOrEmpty && isMobilesValidOrEmpty
        );
      }
    },
  },
  {
    hasPreviousStep: true,
    hasNextStep: true,
    title: 'Finalize',
    nextStepTitle: 'Save',
    validate: (alert: IAlert) => Boolean(alert),
  },
];

@inject(({ alertsTableStore, userStore }) => ({ alertsTableStore, userStore }))
@observer
class AlertModalContainer extends Component<IProps, IState> {
  private saveAlert: boolean = false;

  constructor(props) {
    super(props);

    const { modalType, savedStep } = this.props;
    const activeStep = savedStep
      ? savedStep
      : modalType === ALERT_MODAL_TYPE.CREATE
      ? ALERT_STEP_INDEX.STEP_1
      : ALERT_STEP_INDEX.STEP_2;

    this.state = {
      hitNextNotify: false,
      isActiveNextButton: false,
      activeStep,
    };
  }

  componentWillUnmount() {
    const {
      alertsTableStore: { setAlert },
      onChangeStep,
    } = this.props;

    if (this.isLastStep && this.saveAlert) {
      setAlert(null);
      onChangeStep(null);
    }
  }

  get userPlan() {
    const {
      userStore: { userData },
    } = this.props;

    return get(userData, 'plan.description', '').toLowerCase();
  }

  get alertTypes() {
    return [
      { name: ALERTS_TYPE.ACCELERATION, pro: !validateAccessLevel([NEW_ALERT_ACCELERATION]) },
      { name: ALERTS_TYPE.AFTER_HOURS, pro: !validateAccessLevel([NEW_ALERT_AFTER_HOURS]) },
      { name: ALERTS_TYPE.BRAKING, pro: !validateAccessLevel([NEW_ALERT_BRAKING]) },
      { name: ALERTS_TYPE.CORNERING, pro: !validateAccessLevel([NEW_ALERT_CORNERING]) },
      { name: ALERTS_TYPE.EXCESSIVE_IDLING, pro: !validateAccessLevel([NEW_ALERT_EXCESSIVE_IDLING]) },
      { name: ALERTS_TYPE.FAULT_CODES, pro: !validateAccessLevel([NEW_ALERT_FAULT_CODES]) },
      { name: ALERTS_TYPE.FUELING_EXCEPTION, pro: !validateAccessLevel([NEW_ALERT_FUELING_EXCEPTION]) },
      { name: ALERTS_TYPE.GEOZONE, pro: !validateAccessLevel([NEW_ALERT_GEOZONE]) },
      { name: ALERTS_TYPE.IGNITION, pro: !validateAccessLevel([NEW_ALERT_IGNITION]) },
      { name: ALERTS_TYPE.LOW_VEHICLE_BATTERY, pro: !validateAccessLevel([NEW_ALERT_LOW_VEHICLE_BATTERY]) },
      { name: ALERTS_TYPE.MAINTENANCE, pro: !validateAccessLevel([ACL.MAINTENANCE.BASIC.CREATE]) },
      { name: ALERTS_TYPE.POWER_DISCONNECT_TRACKER, pro: !validateAccessLevel([NEW_ALERT_POWER_DISCONNECT_TRACKER]) },
      // { name: ALERTS_TYPE.POWER_DISCONNECT_DASHCAM, pro: !validateAccessLevel([NEW_ALERT_POWER_DISCONNECT_DASHCAM]) },
      { name: ALERTS_TYPE.SPEEDS_OVER, pro: !validateAccessLevel([NEW_ALERT_SPEEDS_OVER]) },
      { name: ALERTS_TYPE.CUSTOM, pro: !validateAccessLevel([NEW_ALERT_CUSTOM]) },
    ].filter(Boolean);
  }

  setIsActiveNextButton = (isActiveNextButton: boolean) => {
    this.setState({ isActiveNextButton });
  };

  handleNextClick = async () => {
    const {
      alertsTableStore: { alert, isSuccessSave },
      onClose,
      modalType,
      onChangeStep,
    } = this.props;
    const { isActiveNextButton, activeStep } = this.state;

    if (this.isLastStep) {
      if (modalType === ALERT_MODAL_TYPE.CREATE) {
        await alert.create();
      } else if (modalType === ALERT_MODAL_TYPE.EDIT) {
        await alert.update();
      }

      this.saveAlert = true;

      if (isSuccessSave.value) {
        onClose();
      }

      return;
    }

    this.setState({ hitNextNotify: true });

    if (isActiveNextButton && steps[activeStep].validate(alert)) {
      const currentStep = this.state.activeStep + 1;

      this.setState({
        activeStep: currentStep,
        isActiveNextButton: false,
      });
      onChangeStep(currentStep);
    }
  };

  get isLastStep() {
    const { activeStep } = this.state;
    return steps.length === activeStep + 1;
  }

  handlePreviousClick = () => {
    const {
      onChangeStep,
      alertsTableStore: { isErrorSave },
    } = this.props;
    const currentStep = this.state.activeStep - 1;

    this.setState({ activeStep: currentStep });
    isErrorSave.toggle(false);
    onChangeStep(currentStep);
  };

  cancelHitNextNotify = () => {
    this.setState({ hitNextNotify: false });
  };

  getBlockSteps = () => {
    const { modalType } = this.props;

    return modalType === ALERT_MODAL_TYPE.EDIT ? [ALERT_STEP_INDEX.STEP_1] : [];
  };

  render() {
    const {
      alertsTableStore: { alert, createAlert },
      modalType,
    } = this.props;
    const { activeStep, hitNextNotify, isActiveNextButton } = this.state;

    return (
      <AlertModal
        hasNextStep
        hasPreviousStep
        blockSteps={this.getBlockSteps()}
        activeStepIndex={activeStep}
        title={modalType}
        steps={steps}
        onNextClick={this.handleNextClick}
        onPreviousClick={this.handlePreviousClick}
        nextButtonEnabled={
          isActiveNextButton &&
          alert?.displayName.isValidated &&
          alert?.displayName.isValid &&
          ((!alert?.displayName.isEmpty && alert?.displayName.touched) || !alert?.displayName.touched)
        }
        loading={
          alert ? alert.repositoryType.createState.loading || alert.repositoryTypeAlert.patchState.loading : false
        }
      >
        {activeStep === ALERT_STEP_INDEX.STEP_1 && (
          <AlertPreferenceSelectAlert
            types={this.alertTypes}
            selected={alert ? alert.type.value : null}
            onClick={createAlert}
            setIsActiveNextButton={this.setIsActiveNextButton}
            isProAcc={this.userPlan === 'pro'}
          />
        )}
        {activeStep === ALERT_STEP_INDEX.STEP_2 && alert && (
          <AlertPreferenceAlertDetails alert={alert} setIsActiveNextButton={this.setIsActiveNextButton} />
        )}
        {activeStep === ALERT_STEP_INDEX.STEP_3 && alert && (
          <AlertPreferenceNotify
            alert={alert}
            hitNext={hitNextNotify}
            cancelHitNextNotify={this.cancelHitNextNotify}
            setIsActiveNextButton={this.setIsActiveNextButton}
          />
        )}
        {activeStep === ALERT_STEP_INDEX.STEP_4 && alert && (
          <AlertPreferenceFinalize alert={alert} setIsActiveNextButton={this.setIsActiveNextButton} />
        )}
      </AlertModal>
    );
  }
}

export default AlertModalContainer;
