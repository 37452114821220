import React from 'react';
import ConfirmationModal from 'components/Admin/ConfirmationModal';
import type { IScheduledReport } from 'models/ScheduledReport/IScheduledReport';

import './styles.scss';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  report: IScheduledReport;
}

const ScheduledReportDeleteModal: React.FC<IProps> = ({ isOpen, onClose, report }) => {
  const handleSubmit = async () => {
    if (report) {
      await report.destroy();
      onClose();
    }
  };

  return (
    <ConfirmationModal
      applyButtonTitle="Yes, Continue"
      cancelButtonTitle="No, Cancel"
      isOpen={isOpen}
      className="DeleteScheduledReportModal"
      onCancel={onClose}
      onSubmit={handleSubmit}
      title={`Delete ${report ? report.scheduledReportName : ''}?`}
      contentStyles={{ padding: '0 20px', backgroundColor: '#fff' }}
      withoutCloseIcon
    >
      <div className="DeleteScheduledReportModal-content">
        <p className="DeleteScheduledReportModal-text DeleteScheduledReportModal-text--top">
          Deleting this Scheduled Report will remove it from the list and it will no longer be sent to any recipients.
        </p>
        <p className="DeleteScheduledReportModal-text DeleteScheduledReportModal-text--bottom">
          Do you want to continue?
        </p>
      </div>
    </ConfirmationModal>
  );
};

export default ScheduledReportDeleteModal;
