import React from 'react';
import classNames from 'classnames';
import { ClockIcon, Vehicle2Icon } from 'assets';
import { DATE_TIME_FORMATS } from 'config';
import { getFormattedTime, getNowTimestampForTimezone } from 'utils';
import timeStore from 'stores/TimeStore';

import type MaintenanceConfig from 'models/Maintenances/Models/MaintenanceConfig';

import './styles.scss';

interface IProps {
  currentOdometer: number;
  currentEngineHours: number;
  odometer: MaintenanceConfig;
  engHours: MaintenanceConfig;
  serviceTime: MaintenanceConfig;
}

const TaskToApplyServiceDueIn: React.FC<IProps> = ({
  odometer,
  engHours,
  serviceTime,
  currentOdometer,
  currentEngineHours,
}) => {
  const odometerValueCn = classNames('TaskToApplyServiceDueIn-odometerValue', {
    'TaskToApplyServiceDueIn-odometerValue--negative':
      Boolean(odometer.next.value) && currentOdometer > Number(odometer.next.value),
  });
  const engHoursValueCn = classNames('TaskToApplyServiceDueIn-engHoursValue', {
    'TaskToApplyServiceDueIn-engHoursValue--negative':
      Boolean(engHours.next.value) && currentEngineHours > Number(engHours.next.value),
  });
  const serviceTimeCn = classNames('TaskToApplyServiceDueIn-serviceTimeValue', {
    'TaskToApplyServiceDueIn-serviceTimeValue--negative':
      Boolean(serviceTime.next.value) &&
      getNowTimestampForTimezone(timeStore.sessionTimezone) > Number(serviceTime.next.value),
  });

  return (
    <div className="TaskToApplyServiceDueIn">
      {Boolean(odometer.next.value) && (
        <div className="TaskToApplyServiceDueIn-odometer">
          <div className="TaskToApplyServiceDueIn-odometerIcon">
            <Vehicle2Icon />
          </div>
          <div className={odometerValueCn}>
            {(Number(odometer.next.value) - currentOdometer)?.toLocaleString('en-US', {
              maximumFractionDigits: 0,
            })}{' '}
            mi
          </div>
        </div>
      )}
      {Boolean(engHours.next.value) && (
        <div className="TaskToApplyServiceDueIn-engHours">
          <div className="TaskToApplyServiceDueIn-engHoursIcon">
            <Vehicle2Icon />
          </div>
          <div className={engHoursValueCn}>
            {(Number(engHours.next.value) - currentEngineHours)?.toLocaleString('en-US', { maximumFractionDigits: 1 })}{' '}
            hrs
          </div>
        </div>
      )}
      {Boolean(serviceTime.next.value) && (
        <div className="TaskToApplyServiceDueIn-serviceTime">
          <div className="TaskToApplyServiceDueIn-serviceTimeIcon">
            <ClockIcon />
          </div>
          <div className={serviceTimeCn}>
            {getFormattedTime(
              Number(serviceTime.next.value),
              DATE_TIME_FORMATS.monthDatYearFull,
              timeStore.sessionTimezone
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskToApplyServiceDueIn;
