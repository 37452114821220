import React from 'react';

import './styles.scss';

interface IProps {
  text?: string;
  id?: string;
  disabled?: boolean;

  onClick?(id?: string): void;
}

const GroupTableVehiclesButton: React.FC<IProps> = ({ onClick, text, disabled = false, children, id }) => {
  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <button className="GroupTableVehiclesButton-button" onClick={handleClick} disabled={disabled}>
      <span className="GroupTableVehiclesButton-icon">{children}</span>
      {text && <span className="GroupTableVehiclesButton-text">{text}</span>}
    </button>
  );
};

export default GroupTableVehiclesButton;
