import React from 'react';
import classNames from 'classnames';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';

import Input from 'components/Input';
import Button from 'components/Button';

import './styles.scss';

interface IFormValues {
  email: string;
}

interface IProps {
  className?: string;
  initialValues?: IFormValues;
  onSubmit: (values: IFormValues) => void;
  sending?: boolean;
  sent?: boolean;
}

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is invalid')
    .required('Email is required'),
});

const ForgotPasswordForm: React.FC<IProps> = ({ className, initialValues, onSubmit, sending, sent }) => {
  const cn = classNames('ForgotPasswordForm', className);
  const email = get(initialValues, 'email', '');

  return (
    <div className={cn}>
      <Formik
        initialValues={{ email }}
        onSubmit={onSubmit}
        validationSchema={ForgotPasswordSchema}
        render={({ errors, touched, isValid }: FormikProps<IFormValues>) => (
          <Form>
            <div className="ForgotPasswordForm-field">
              <Field
                component={Input}
                disabled={sent}
                error={Boolean(errors.email && touched.email) ? errors.email : ''}
                name="email"
                placeholder="Login Email Address"
              />
            </div>
            <div className="ForgotPasswordForm-submit">
              <Button
                className="Button--apply"
                disabled={!isValid || sent}
                sending={sending}
                sent={sent}
                title="Send Link to Reset Password"
              />
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default ForgotPasswordForm;
