import { SimpleField } from 'models/Fields';

class ReportPreferenceItem {
  id: SimpleField<string>;
  displayName: SimpleField<string>;

  constructor({ id = null, displayName = null }) {
    this.id = new SimpleField(id);
    this.displayName = new SimpleField(displayName);
  }

  get model() {
    return {
      id: this.id.value,
      displayName: this.displayName.value,
    };
  }

  set = (id: string, displayName: string) => {
    this.id.set(id);
    this.displayName.set(displayName);
  };

  reset = () => {
    this.id.reset();
    this.displayName.reset();
  };
}

export default ReportPreferenceItem;
