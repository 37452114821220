import React from 'react';
import { Observer } from 'mobx-react';
import { Radio } from 'antd';
import classNames from 'classnames';
import { ACL, FREQUENCY_TYPE } from 'config';
import { validateAccessLevel } from 'stores';

import type MaintenanceModalUI from 'models/Maintenances/UI/MaintenanceModalUI/MaintenanceModalUI';

import './styles.scss';

interface IProps {
  ui: MaintenanceModalUI;
}

const MaintenancePreferenceFrequency: React.FC<IProps> = ({ ui }) => {
  const edit = validateAccessLevel([ACL.MAINTENANCE.BASIC.UPDATE]);
  const switchType = (e) => {
    if (e.target.value === FREQUENCY_TYPE.ONE_TIME) {
      ui.dueAt.reset();
    } else {
      ui.dueEvery.reset();
    }

    ui.dueAt.isError.toggle(false);
    ui.dueEvery.isError.toggle(false);
    ui.frequency.set(e.target.value);
  };

  return (
    <Observer
      render={() => {
        const switchCN = classNames('MaintenancePreferenceFrequency-switch', {
          'MaintenancePreferenceFrequency-switch--disabled': ui.isFrequencyDisabled.value,
        });

        return (
          <div className="MaintenancePreference-line MaintenancePreferenceFrequency">
            <div className="MaintenancePreference-label">Frequency</div>
            <div className="MaintenancePreference-value">
              <Radio.Group
                onChange={switchType}
                value={ui.frequency.value}
                className={switchCN}
                disabled={ui.isFrequencyDisabled.value || !edit}
              >
                <Radio.Button value={FREQUENCY_TYPE.REPEAT} className="MaintenancePreferenceFrequency-switchButton">
                  Repeat
                </Radio.Button>
                <Radio.Button value={FREQUENCY_TYPE.ONE_TIME} className="MaintenancePreferenceFrequency-switchButton">
                  One Time
                </Radio.Button>
              </Radio.Group>
            </div>
          </div>
        );
      }}
    />
  );
};

export default MaintenancePreferenceFrequency;
