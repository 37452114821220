const halves = ['am', 'pm'];

const minutesInterval = (interval: number = 30): Array<[number, number, number, number, string]> => {
  return Array((24 * 60) / interval)
    .fill('')
    .map((_, index) => {
      const hour = Math.floor((index * interval) / 60);
      const minutes = (index * interval) % 60;
      const half = Math.floor(hour / 12);
      const time = timeInSeconds(hour, minutes, half);
      return [hour % 12 || 12, minutes, half, time, timeArrayToString(hour % 12 || 12, minutes, half)];
    });
};

const timeInSeconds = (hour: number, minutes: number, half: number) => {
  return half * 12 * 60 * 60 + (hour % 12) * 60 * 60 + minutes * 60;
};

const timeArrayToString = (hour: number, minutes: number, half: number) => {
  return `${('0' + hour).slice(-2)}:${('0' + minutes).slice(-2)} ${halves[half]}`;
};

const minutesIntervalString = (interval: number = 30): string[] => {
  return minutesInterval(interval).map(([, , , , str]) => {
    return str;
  });
};

export { minutesIntervalString, minutesInterval, timeArrayToString, timeInSeconds };
