import { ToggleField } from 'models/Fields';

class MaintenancePanelUI {
  isEdit: ToggleField;

  constructor() {
    this.isEdit = new ToggleField(false);
  }
}

export default MaintenancePanelUI;
