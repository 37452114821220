import { SimpleField } from 'models/Fields';
import { convert24toAMPM, convertAMPMto24 } from 'utils';

class ScheduleTime {
  time: SimpleField<string>;

  constructor(time24: string) {
    this.time = new SimpleField(convert24toAMPM(time24));
  }

  to24Format = () => {
    return convertAMPMto24(this.time.value);
  };
}

export default ScheduleTime;
