import React from 'react';
import { Observer } from 'mobx-react';
import Input from 'components/Input';
import type { SimpleField } from 'models/Fields';

import './styles.scss';

interface IProps {
  name: SimpleField<string>;
}

const MaintenanceMarkAsCompletePerformedBy: React.FC<IProps> = ({ name }) => {
  return (
    <Observer
      render={() => (
        <div className="MaintenancePreference-line MaintenanceMarkAsCompletePerformedBy">
          <div className="MaintenancePreference-label">Performed by</div>
          <div className="MaintenancePreference-value">
            <Input
              placeholder="Name"
              value={name.value}
              onChange={(e) => name.set(e.currentTarget.value)}
              className="MaintenanceMarkAsCompletePerformedBy-input"
            />
          </div>
        </div>
      )}
    />
  );
};

export default MaintenanceMarkAsCompletePerformedBy;
