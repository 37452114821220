import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { PATHS, REPORTS_STORE } from 'config';

import type { ReportsStore } from 'stores';

import ReportsPageIndex from '../ReportsPageIndex';
import ReportsPageTable from '../ReportsPageTable';
import ScheduledReports from '../ScheduledReports';

import './styles.scss';

interface IProps {
  className?: string;
  reportsStore?: ReportsStore;
}

@inject(REPORTS_STORE)
@observer
class ReportPage extends Component<IProps, null> {
  render() {
    return (
      <div className="ReportPage-container">
        <Switch>
          <Route path={PATHS.REPORTS.INDEX} exact component={ReportsPageIndex} />
          <Route path={PATHS.REPORTS.SCHEDULE} exact component={ScheduledReports} />
          <Route path={PATHS.REPORTS.REPORT} component={ReportsPageTable} />
        </Switch>
      </div>
    );
  }
}

export default ReportPage;
