import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import PersonAccessModel from './PersonAccessModel';
import PersonDetailsModel from './PersonDetailsModel';
import PersonPermissionsModel from './PersonPermissionsModel';
import { repositoryService } from 'services';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import { SimpleField } from 'models/Fields';
import type { PeopleAdmin } from 'stores/Admin/People';
import LoggerInfo from '../../General/LoggerInfo';
import { DATE_TIME_FORMATS, PEOPLE_ADMIN_ROLES } from 'config';

class PersonModel {
  id: SimpleField<number>;
  details: PersonDetailsModel;
  access: PersonAccessModel;
  permissions: PersonPermissionsModel;
  loggerInfo: LoggerInfo;
  repository: IRepository;
  repositoryPerson: IEntityRepository;
  context: PeopleAdmin;

  constructor(item, context: PeopleAdmin) {
    this.context = context;
    this.repository = repositoryService.get('users');
    this.repositoryPerson = this.repository.entity(item.id.toString());
    this.id = new SimpleField(item.id || 0);
    this.details = new PersonDetailsModel(item, this);
    this.access = new PersonAccessModel(item);
    this.permissions = new PersonPermissionsModel(item.permissions || {});
    this.loggerInfo = new LoggerInfo(
      {
        creationTime: item.creationTime,
        createdBy: item.createdBy,
        lastUpdateTime: item.lastUpdateTime,
        updatedBy: item.updatedBy,
      },
      DATE_TIME_FORMATS.adminVehicleSettings
    );
  }

  create = async () => {
    try {
      const response = await this.repository.create(this.createData);

      return response;
    } catch (e) {
      return e;
    }
  };

  update = async () => {
    const response = await this.repository.patch(this.data);

    if (this.repository.patchState.success) {
      this.context.onUpdate(response);
    }
  };

  delete = async () => {
    try {
      await this.repositoryPerson.delete();
    } catch (e) {
      return e;
    }
  };

  reset = () => {
    this.details.reset();
    this.access.reset();
    this.permissions.reset();
  };

  validateAsync = async () => {
    await Promise.all([this.permissions.validateAsync()]);
  };

  get data() {
    return {
      ...this.details.data,
      ...this.access.data,
      permissions: {
        driverDetails: this.permissions.data.driverDetails,
        dashcamDetails: this.permissions.data.dashcamDetails,
        inspectorDetails: this.permissions.data.inspectorDetails,
      },
      accessRestrictedByAssetGroupIds: this.permissions.data.groupRestrictions,
      id: this.id.value,
    };
  }

  get createData() {
    const data = {
      loginEmail: this.details.data.loginEmail,
      contactName: this.details.data.contactName,
      jobTitle: this.details.data.jobTitle,
    };

    if (this.details.role === PEOPLE_ADMIN_ROLES.DRIVER || this.details.role === PEOPLE_ADMIN_ROLES.BOTH) {
      return { ...data, ...{ permissions: { driverDetails: {} } } };
    }

    return data;
  }

  get isUpdated() {
    return this.details.isUpdated || this.access.isUpdated || this.permissions.isUpdated;
  }

  get isValid() {
    return this.details.isValid;
  }
}

export default PersonModel;
