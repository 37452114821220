import { observable } from 'mobx';
import isNumber from 'lodash/isNumber';

import { ALERTS_TYPE, WEEKDAYS } from 'config';

import AlertWithIntervalModel from './AlertWithInterval';
import ScheduleModel from './Schedule';
import { SimpleField, ToggleField } from 'models/Fields';

const initialSchedule = {
  weekdays: [WEEKDAYS.MONDAY, WEEKDAYS.TUESDAY, WEEKDAYS.WEDNESDAY, WEEKDAYS.THURSDAY, WEEKDAYS.FRIDAY],
  from: '05:00',
  to: '18:00',
};

const initialScheduleOverride = {
  weekdays: [WEEKDAYS.SATURDAY],
};

class AfterHoursAlertModel extends AlertWithIntervalModel {
  @observable subType: SimpleField<Alerts.Modal.WorkHours.Type>;
  @observable schedule: ScheduleModel;
  @observable scheduleOverride: ScheduleModel;
  @observable scheduleOverrideOption: ToggleField;

  constructor(item) {
    super({
      ...item,
      isIntervalActive: isNumber(item.interval) ? Boolean(item.interval) : true,
      interval: isNumber(item.interval) ? item.interval : 5 * 60 * 1000,
    });

    this.subType = new SimpleField(item.subType || 'IGNITION_ON');
    this.schedule = new ScheduleModel(item.schedule || initialSchedule);
    this.scheduleOverride = new ScheduleModel(item.scheduleOverride || initialScheduleOverride);
    this.scheduleOverrideOption = new ToggleField(Boolean(item.scheduleOverride));
    this.urlType = 'after-hours';
    this.description = 'Alerts anytime a vehicle or group is working outside of the "work hours" defined.';
    this.type.reInitialize(ALERTS_TYPE.AFTER_HOURS);
  }

  get model() {
    const scheduleOverride = this.scheduleOverrideOption.value
      ? {
          allDay: this.scheduleOverride.allDay.value,
          from: this.scheduleOverride.from.to24Format(),
          to: this.scheduleOverride.to.to24Format(),
          weekdays: this.scheduleOverride.weekdays.toArray(),
        }
      : null;

    return {
      ...super.model,
      subType: this.subType.value,
      schedule: {
        allDay: this.schedule.allDay.value,
        from: this.schedule.from.to24Format(),
        to: this.schedule.to.to24Format(),
        weekdays: this.schedule.weekdays.toArray(),
      },
      scheduleOverride,
    };
  }

  isUpdated(): boolean {
    return (
      super.isUpdated() ||
      this.subType.isUpdated ||
      this.schedule.allDay.isUpdated ||
      this.schedule.from.time.isUpdated ||
      this.schedule.to.time.isUpdated ||
      this.schedule.weekdays.isUpdated ||
      this.scheduleOverrideOption.isUpdated ||
      this.scheduleOverride.allDay.isUpdated ||
      this.scheduleOverride.from.time.isUpdated ||
      this.scheduleOverride.to.time.isUpdated ||
      this.scheduleOverride.weekdays.isUpdated
    );
  }
}

export default AfterHoursAlertModel;
