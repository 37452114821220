import React, { useCallback } from 'react';
import { Observer } from 'mobx-react';

import SearchableCronOptionsSelect from 'containers/Select/SearchableCronOptionsSelect';

import type AlertIDModel from 'models/Alerts/AlertID';

import './styles.scss';


interface IProps {
  cronOption: AlertIDModel<string>;
}

const AlertPreferencesCronOption: React.FC<IProps> = ({ cronOption }) => {
  const handleChangeValue = useCallback((value) => {
    cronOption.id.set(value.value);
    cronOption.name.set(value.label);
  }, []);

  return (
    <Observer
      render={() => (
        <div className="AlertPreference-line">
          <div className="AlertPreference-label">Cron Option</div>
          <div className="AlertPreference-value AlertPreferencesCronOption-value">
            <SearchableCronOptionsSelect
              withAll
              handleChange={handleChangeValue}
              value={cronOption && { value: cronOption.id.value, label: cronOption.name.value }}
            />
          </div>
        </div>
      )}
    />
  );
};

export default AlertPreferencesCronOption;
