import React, { FC, Fragment, useCallback, useState } from 'react';
import { Dropdown, Menu } from 'antd';

import DeleteModal from '../DeleteModal';
import { MoreIcon } from 'assets';

import './styles.scss';

interface IProps {
  dispatchId: number;
  onComplete: (dispatchId: number, isCompleted: boolean) => void;
  onDelete: (dispatchId: number) => void;
  onEdit: (dispatchId: number) => void;
  isCompleted: boolean;
  canEdit?: boolean;
}

const Actions: FC<IProps> = ({ canEdit = true, dispatchId, isCompleted, onComplete, onDelete, onEdit }) => {
  const [modalShown, setModalShown] = useState(false);

  const handleComplete = useCallback(() => onComplete(dispatchId, isCompleted), [dispatchId, isCompleted, onComplete]);
  const handleDelete = useCallback(() => onDelete(dispatchId), [dispatchId, onDelete]);
  const handleEdit = useCallback(() => onEdit(dispatchId), [dispatchId, onEdit]);

  const handleDeleteDispatch = () => {
    handleDelete();
    handleModalClose();
  };

  const handleModalOpen = () => setModalShown(true);

  const handleModalClose = () => setModalShown(false);

  return (
    <Fragment>
      <Dropdown
        disabled={!canEdit && isCompleted}
        trigger={['click']}
        overlay={
          <Menu>
            {canEdit && !isCompleted && <Menu.Item onClick={handleEdit}>Edit Dispatch</Menu.Item>}
            <Menu.Item disabled={!canEdit && isCompleted} onClick={handleComplete}>
              {isCompleted ? `Mark Dispatch is not Completed` : `Mark Dispatch as Completed`}
            </Menu.Item>
            <Menu.Item disabled={!canEdit && isCompleted} onClick={handleModalOpen}>
              Delete Dispatch
            </Menu.Item>
          </Menu>
        }
      >
        <MoreIcon
          className="DispatchActions-more"
          style={{
            fontSize: '20px',
            opacity: !canEdit && isCompleted ? 0.3 : 1,
            cursor: !canEdit && isCompleted ? 'no-drop' : 'pointer',
            transform: 'scale(1.5)',
          }}
        />
      </Dropdown>
      <DeleteModal
        isOpen={modalShown}
        onCancel={handleModalClose}
        onOpen={handleModalOpen}
        onSubmit={handleDeleteDispatch}
      />
    </Fragment>
  );
};

export default Actions;
