class FilterOrderSettings {
  trackPoint: string[];
  events: string[];

  private trackPointName: string;
  private eventsName: string;

  constructor() {
    this.trackPointName = 'Track point';
    this.eventsName = 'Events';
    this.trackPoint = [];
    this.events = [];
  }

  set = (settings) => {
    this.trackPoint = settings[this.trackPointName] || [];
    this.events = settings[this.eventsName] || [];
  };
}

export default FilterOrderSettings;
