import { action, observable } from 'mobx';
import { repositoryService } from 'services';
import type EntityRepository from 'services/RepositoryService/EntityRepository';
import ExceptionOption from './ExceptionOption';

class ExceptionOptions {
  @observable options: ExceptionOption[];
  repository: EntityRepository;

  constructor() {
    this.options = [];
    this.repository = repositoryService
      .get('alerts')
      .entity('fuel-exception')
      .entity('types');
  }

  @action fetch = async () => {
    const options = await this.repository.get();

    this.options = options.map((option) => new ExceptionOption(option));
  };
}

export default ExceptionOptions;
