import React, { FC } from 'react';
import classNames from 'classnames';

import GoogleMapsLocationSearch from 'components/GoogleMapsLocationSearch';

import { DirectionsIcon } from 'assets';
import './styles.scss';

interface IProps {
  color: Geozone.IGeozoneColor;
  dimmed?: boolean;
  disabled?: boolean;
  onChange?: (place: { address?: string; latitude?: number; longitude?: number }) => void;
  searchValue?: string;
  selectedAddress?: { address?: string; latitude?: number; longitude?: number };
}

const DispatchInputGeozone: FC<IProps> = ({
  color,
  dimmed,
  disabled = false,
  onChange,
  searchValue,
  selectedAddress,
}) => {
  const centerMarkerCN = classNames('DispatchInputGeozone-iconWrapper', {
    'DispatchInputGeozone-iconWrapper--invertColor':
      color.name.toLowerCase() === 'white' || color.name.toLowerCase() === 'yellow',
    'DispatchInputGeozone-iconWrapper--dimmed': dimmed,
  });

  return (
    <div className="DispatchInputGeozone">
      <div className="DispatchInputGeozone-container">
        <div className="DispatchInputGeozone-iconContainer">
          <div className={centerMarkerCN} style={{ background: color.hex }}>
            <DirectionsIcon width={12} className="DispatchInputGeozone-iconWrapperIcon" />
          </div>
        </div>
        <div className="DispatchInputGeozone-inputContainer">
          <GoogleMapsLocationSearch
            disabled={disabled}
            googleMapURL={process.env.REACT_APP_GOOGLE_MAP_URL}
            loadingElement={<div style={{ height: `100%` }} />}
            searchValue={searchValue}
            selectedAddress={selectedAddress}
            onChange={onChange}
            placeholder="Address/City/Zip/Lat, Long"
          />
        </div>
      </div>
    </div>
  );
};

export default DispatchInputGeozone;
