class ExceptionOption {
  id: string;
  name: string;

  constructor({ id, name }) {
    this.id = id || 'default';
    this.name = name;
  }
}

export default ExceptionOption;
