import Cookies from 'js-cookie';

export const getCookie = (name: string): string => Cookies.get(name);

export const setCookie = (name: string, value: string | number, options?: any) => Cookies.set(name, value, options);

export const removeCookie = (name: string, options?): void => Cookies.remove(name, { ...options });

export const getStorageItem = (name: string): string => localStorage.getItem(name);

export const setStorageItem = (name: string, value: string): void => localStorage.setItem(name, value);

export const removeStorageItem = (name: string): void => localStorage.removeItem(name);

export const getSessionStorageItem = (name: string): string => sessionStorage.getItem(name);

export const setSessionStorageItem = (name: string, value: string): void => sessionStorage.setItem(name, value);

export const removeSessionStorageItem = (name: string): void => sessionStorage.removeItem(name);

export const getParsedStorageItem = (name: string): any => JSON.parse(localStorage.getItem(name) || '{}');

export const setStringifiedStorageItem = (name: string, value: any): void =>
  localStorage.setItem(name, JSON.stringify(value));

export const updateStorageItem = (name: string, value: any): void => {
  const savedMapOptions = getParsedStorageItem(name);
  setStringifiedStorageItem(name, { ...savedMapOptions, ...value });
};

export const setJSONItemToStorage = (key, value) => setStorageItem(key, JSON.stringify(value));

export const getJSONItemFromStorage = (key, initial) => {
  const value = JSON.parse(getStorageItem(key));
  return value === undefined || value === null ? initial : value;
};
