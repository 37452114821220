import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { WEB_FINANCE_DETAILS_READ } from 'config';
import { formatDecimalNumber } from 'utils';

import type { Customer } from 'stores/Customers/Customer';
import validateAccessLevel from 'stores/acl/validator';

import CustomerInvoiceItem from './CustomerInvoiceItem';
import Button from 'components/Button';
import FinancialDataAccessError from 'components/FinancialDataAccessError';

import './styles.scss';

interface IProps {
  customer: Customer;
  step?: number
  withHeader?: boolean
}

interface IState {
  limit: number
}

const LIMIT = 5;

@observer
class CustomerInvoices extends Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      limit: 5,
    }
  }

  get maxCount() {
    const {
      customer,
    } = this.props;

    return customer.invoices?.length || 0
  }

  get hiddenElements () {
    return  this.maxCount - this.state.limit;
  }

  handleMoreClick = () => {
    this.increaseLimit();
  }

  increaseLimit = () => {
    this.setState({
      limit: this.state.limit + (this.props.step || LIMIT),
    })
  }

  get hasAccess () {
    return validateAccessLevel([WEB_FINANCE_DETAILS_READ]);
  }

  render() {
    const {
      customer,
      step = LIMIT,
      withHeader,
    } = this.props;

    const {
      limit,
    } = this.state;

    const hasAccess = this.hasAccess;
    const hasInvoices = customer.invoices?.length;

    return <>
      {withHeader && (<div className="MapCustomerPanel-InvoicesTotal">
        <div className="MapCustomerPanel-InvoicesTotal--open">
          <div className="MapCustomerPanel-InvoicesTotalHeader">Open</div>
          {hasAccess && (
            <div
              className="MapCustomerPanel-InvoicesTotalValue"
            >
              ${formatDecimalNumber(customer.balance, 2)}
            </div>
          )}
          {!hasAccess && <FinancialDataAccessError className="MapCustomerPanel-InvoicesTotalValue--limited" size={16} />}
        </div>
        <div className="MapCustomerPanel-InvoicesTotal--overdue">
          <div className="MapCustomerPanel-InvoicesTotalHeader">Overdue</div>
          {hasAccess && (<div
            className={`MapCustomerPanel-InvoicesTotalValue ${customer.overdueBalance > 0 ? 'MapCustomerPanel-InvoicesTotal--red' : ''}`}
          >
            ${formatDecimalNumber(customer.overdueBalance, 2)}
          </div>)}
          {!hasAccess && <FinancialDataAccessError className="MapCustomerPanel-InvoicesTotalValue--limited" size={16} />}
        </div>
      </div>)}

      <div className="MapCustomerPanel-InvoicesList">
        <div className="MapCustomerPanel-InvoicesListHeader">
          <div>Invoice</div>
          <div>Date</div>
          <div>Total</div>
          <div>Due</div>
        </div>
        <div className={classNames('MapCustomerPanel-InvoicesListBody', {
          'MapCustomerPanel-InvoicesListBody--limited': !hasAccess,
        })}>
          <div className="MapCustomerPanel-InvoicesListItems">
            {customer.full && !hasInvoices && (<span>-</span>)}
            {customer.invoices?.slice(0, this.state.limit)?.map?.((invoice) => <CustomerInvoiceItem
              key={invoice.id}
              invoice={invoice}
              customerId={customer.id}
              limited={!hasAccess}
            />)}
          </div>
          {!hasAccess && customer.full && hasInvoices ? <FinancialDataAccessError className="MapCustomerPanel-InvoicesListColumn--limited" /> : null}
          {!hasAccess && customer.full && hasInvoices ? <FinancialDataAccessError className="MapCustomerPanel-InvoicesListColumn--limited" /> : null}
        </div>
        <div className="MapCustomerPanel-InvoicesListFooter">
          {this.maxCount && this.maxCount > limit ? (
            <Button
              title={`Load ${this.hiddenElements > step ? step : this.hiddenElements} More`}
              className="MapCustomerPanel-InvoicesLink"
              onClick={this.handleMoreClick}
            />
          ) : null}
        </div>
      </div>
    </>;
  }
}

export default CustomerInvoices;
