import React from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  name?: string;
  title: string;
}

const MediaRequestReviewItem: React.FC<IProps> = ({ children, name, title }) => {
  const cn = classNames('MediaRequestReviewItem', {
    [`MediaRequestReviewItem--${name}`]: Boolean(name),
  });
  return (
    <div className={cn}>
      <div className="MediaRequestReviewItem-label">{title}</div>
      <div className="MediaRequestReviewItem-value">{children}</div>
    </div>
  );
};

export default MediaRequestReviewItem;
