import React from 'react';

import type { IIntegration } from 'interfaces/stores/Integrations/IIntegration';
import STIntegrationModel from 'stores/Integrations/STIntegration';
import QBIntegrationModel from 'stores/Integrations/QBIntegration';
import WEXIntegrationModel from 'stores/Integrations/WEXIntegration';

import ServiceTitanIntegration from '../STIntegration';
import QuickBooksBIntegration from '../QBIntegration';
import WEXIntegration from '../WEXIntegration';

import './style.scss';

interface IProps {
  integration: IIntegration;
}

const IntegrationFabric: React.FC<IProps> = ({ integration }) => {
  return (
    <>
      {integration instanceof STIntegrationModel && <ServiceTitanIntegration integration={integration} />}
      {integration instanceof QBIntegrationModel && <QuickBooksBIntegration integration={integration} />}
      {integration instanceof WEXIntegrationModel && <WEXIntegration integration={integration} />}
    </>
  );
};

export default IntegrationFabric;
