import React, { FC, useCallback, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import moment from 'moment';
import classnames from 'classnames';
import timeStore from 'stores/TimeStore';

import { getFormattedTime, isValidDate, isToday } from 'utils';
import { DATE_TIME_FORMATS } from 'config';

import Input from 'components/Input';
import Calendar from 'components/Calendar';

import { CalendarIcon, FloatingCLoseIcon } from 'assets';
import './styles.scss';

interface IProps {
  disabled?: boolean;
  disabledRange?: Array<{
    after: Date;
    before: Date;
  }>;
  fromToday?: boolean;
  onlyFuture?: boolean;
  onlyPast?: boolean;
  onSubmit?: (serviceTimeValue: number) => void;
  placeholder?: string;
  value?: number;
  withClear?: boolean;
  withTodayLabel?: boolean;
  isInputEditable?: boolean;
  placement?: string;
}

const getDateValues = (value: number) =>
  getFormattedTime(value, 'YYYY/M/D')
    .split('/')
    .map((val: string) => Number(val));

const getFromMonth = (serviceTimeValue?: number, previousYears: number = 0) => {
  const [year, month] = getDateValues(serviceTimeValue);
  return new Date(year - previousYears, month - 1);
};

const getDisabledDays = (serviceTimeValue?: number, direction?: 'past' | 'next') => {
  const [year, month, day] = getDateValues(serviceTimeValue);

  if (direction === 'next') {
    return [
      {
        after: new Date(year - 1, 0, 0),
        before: new Date(year, month - 1, day),
      },
    ];
  } else {
    return [
      {
        before: new Date(year, month + 1, 0),
        after: new Date(year, month - 1, day),
      },
      {
        after: new Date(year, month - 1, day),
        before: new Date(year + 11, 12, 1),
      },
    ];
  }
};

const formatValue = (value: number): string => (value ? moment.utc(value).format(DATE_TIME_FORMATS.monthDatYear) : '');

const CalendarWithInput: FC<IProps> = ({
  disabled,
  disabledRange,
  fromToday = false,
  onlyFuture = false,
  onlyPast = false,
  onSubmit,
  placeholder = 'MM/DD/YYYY',
  value,
  withClear = false,
  withTodayLabel = false,
  isInputEditable = true,
  placement,
}) => {
  const [open, setOpen] = useState(false);
  const [internalDateValue, setInternalDateValue] = useState(
    withTodayLabel && isToday(value, timeStore.sessionTimezone) ? 'Today' : formatValue(value)
  );
  const [selected] = useState(() => (value ? value : Date.now()));
  const calendarCn = classnames('CalendarWithInput-item', {
    [`CalendarWithInput-item--${placement}`]: Boolean(placement),
  });

  const fromMonth = disabledRange
    ? disabledRange[0].before
    : onlyFuture
    ? fromToday
      ? getFromMonth(Date.now())
      : getFromMonth(selected)
    : getFromMonth(selected, 10);
  const disabledDays = disabledRange
    ? disabledRange
    : onlyFuture
    ? getDisabledDays(new Date().getTime(), 'next')
    : onlyPast
    ? getDisabledDays(new Date().getTime(), 'past')
    : [];
  const toMonth = disabledRange ? disabledRange[0].after : new Date(new Date().getFullYear() + (onlyPast ? 0 : 10), 11);

  const handleDateSelect = (value?: Date, close: boolean = true) => {
    if (close) {
      setOpen(false);
    }
    onSubmit(value ? value.getTime() : 0);
  };
  const handleFocus = () => setOpen(true);
  const onOutsideClick = () => setOpen(false);
  const handleClear = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleDateSelect();
  };

  const selectedFromDate = useCallback(() => {
    return new Date(moment.utc(value || Date.now()).format(DATE_TIME_FORMATS.monthDatYearFull));
  }, [value]);

  const handleChange = (e) => {
    const { value } = e.target;
    const isValid = isValidDate(value);

    if (!isInputEditable && !isValid) {
      return;
    }

    if (!value) {
      handleDateSelect();
      return;
    }

    const internal = isValid
      ? moment.utc(value, DATE_TIME_FORMATS.monthDatYear).format(DATE_TIME_FORMATS.monthDatYear)
      : value;
    setInternalDateValue(withTodayLabel && isToday(value, timeStore.sessionTimezone) ? 'Today' : internal);
    if (isValid) {
      handleDateSelect(new Date(moment.utc(internal, DATE_TIME_FORMATS.monthDatYear).valueOf()), false);
    }
  };

  useEffect(() => {
    setInternalDateValue(withTodayLabel && isToday(value, timeStore.sessionTimezone) ? 'Today' : formatValue(value));
    selectedFromDate();
  }, [value]);

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <div className="CalendarWithInput-container">
        <div className="CalendarWithInput-modalInput">
          <Input
            disabled={disabled}
            icon={
              internalDateValue && withClear && open ? (
                <FloatingCLoseIcon onClick={handleClear} className="CalendarWithInput-iconClear" />
              ) : (
                <CalendarIcon fill="#6B7A99" className="CalendarWithInput-iconCalendar" />
              )
            }
            onChange={handleChange}
            placeholder={placeholder}
            value={internalDateValue}
            onFocus={handleFocus}
          />
        </div>
        {open ? (
          <div className={calendarCn}>
            <Calendar
              disabledDays={disabledDays}
              from={selectedFromDate()}
              fromMonth={fromMonth}
              ignoreNull
              month={selectedFromDate()}
              onClick={handleDateSelect}
              selectedDate={selectedFromDate()}
              toMonth={toMonth}
              withMonthYearForm
            />
          </div>
        ) : null}
      </div>
    </OutsideClickHandler>
  );
};

export default CalendarWithInput;
