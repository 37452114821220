import { observable, action, computed, reaction, IReactionDisposer } from 'mobx';
import { pickBy } from 'lodash';
import MediaTypeCategory from './MediaTypeCategory';
import type MediaType from './MediaType';
import { MEDIA_CLIPS_VIEW } from 'config';

export enum SERVER_MEDIA_DATA_TOTAL_CATEGORIES {
  EVENT_TYPES = 'eventTypeGroups',
  HIDE_BUMP_EVENTS = 'bumpEventsTotal',
  MEDIA_TYPES = 'mediaTypeTotals',
}

export enum SERVER_MEDIA_DATA_PARAMS {
  EXCLUDE_EVENT_TYPES = 'excludeEventTypes',
  EXPIRING_SOON = 'expiringSoon',
  INCLUDE_EVENT_TYPES = 'includeEventTypes',
  MEDIA_TYPES = 'mediaTypes',
}

export enum EVENT_TYPES {
  ALERT_BUMP = 'Alert Bump',
  GLASS_BREAK = 'Glass Break',
}

export enum PERSIST_FILTER_NAMES {
  HIDE_BUMP_EVENTS = 'dashcam-hide-bump-events',
  HIDE_GLASS_BREAK = 'dashcam-hide-glass-break',
  PANEL_HIDE_BUMP_EVENTS = 'panel-dashcam-hide-bump-events',
  PANEL_HIDE_GLASS_BREAK = 'panel-dashcam-hide-glass-break',
}

export default class MediaTypeCategories {
  @observable list: MediaTypeCategory[];
  context: MediaType;
  hideGlassBreakOptionDisposer: IReactionDisposer;
  glassBreakOptionDisposer: IReactionDisposer;

  constructor(context: MediaType) {
    this.list = [];
    this.context = context;

    this.hideGlassBreakOptionDisposer = reaction(
      () => {
        return this.hideGlassBreakOption?.isSelected.value;
      },
      (value) => {
        if (value && this.glassBreakOption?.isSelected.value) {
          this.glassBreakOption.isSelected.toggle(false);
        }
      },
      { name: 'Unselect Glass Break filter on activation Hide Glass Break' }
    );

    this.glassBreakOptionDisposer = reaction(
      () => {
        return this.glassBreakOption?.isSelected.value;
      },
      (value) => {
        if (value && this.hideGlassBreakOption?.isSelected.value) {
          this.hideGlassBreakOption.isSelected.toggle(false);
        }
      },
      { name: 'Unselect Hide Glass Break on activation Glass Break filter' }
    );
  }

  @action set = (categories) => {
    const list = [];

    Object.keys(categories).forEach((key) => {
      const categoryGroup = categories[key];
      let mediaTypeCategory;

      switch (key) {
        case SERVER_MEDIA_DATA_TOTAL_CATEGORIES.EVENT_TYPES: {
          categoryGroup.forEach((group) => {
            mediaTypeCategory = new MediaTypeCategory({
              name: group.groupName,
              filters: group.eventTypeTotals,
              type: SERVER_MEDIA_DATA_PARAMS.INCLUDE_EVENT_TYPES,
              hidden: false,
              useCategoryName: false,
              persistName: '',
            });
            list.push(mediaTypeCategory);

            /* Add Glass Break option as an exclude type */
            if (group.groupName === 'Other' && EVENT_TYPES.GLASS_BREAK in group.eventTypeTotals) {
              mediaTypeCategory = new MediaTypeCategory({
                name: 'Hide Glass Break Events',
                filters: { [EVENT_TYPES.GLASS_BREAK]: group.eventTypeTotals[EVENT_TYPES.GLASS_BREAK] },
                type: SERVER_MEDIA_DATA_PARAMS.EXCLUDE_EVENT_TYPES,
                hidden: true,
                useCategoryName: true,
                defaultSelect: true,
                persistName:
                  this.context.context.view === MEDIA_CLIPS_VIEW.VEHICLE_MEDIA_PANEL
                    ? PERSIST_FILTER_NAMES.PANEL_HIDE_GLASS_BREAK
                    : PERSIST_FILTER_NAMES.HIDE_GLASS_BREAK,
              });
              list.push(mediaTypeCategory);
            }
          });
          break;
        }
        case SERVER_MEDIA_DATA_TOTAL_CATEGORIES.HIDE_BUMP_EVENTS: {
          mediaTypeCategory = new MediaTypeCategory({
            name: 'Hide Bump Events',
            filters: { [EVENT_TYPES.ALERT_BUMP]: categoryGroup },
            type: SERVER_MEDIA_DATA_PARAMS.EXCLUDE_EVENT_TYPES,
            hidden: true,
            useCategoryName: true,
            defaultSelect: true,
            persistName:
              this.context.context.view === MEDIA_CLIPS_VIEW.VEHICLE_MEDIA_PANEL
                ? PERSIST_FILTER_NAMES.PANEL_HIDE_BUMP_EVENTS
                : PERSIST_FILTER_NAMES.HIDE_BUMP_EVENTS,
          });
          list.push(mediaTypeCategory);
          break;
        }
        case SERVER_MEDIA_DATA_TOTAL_CATEGORIES.MEDIA_TYPES: {
          mediaTypeCategory = new MediaTypeCategory({
            name: 'Media Type',
            filters: {
              All: categoryGroup.VIDEO + categoryGroup.IMAGE,
              Video: categoryGroup.VIDEO,
              Image: categoryGroup.IMAGE,
            },
            type: SERVER_MEDIA_DATA_PARAMS.MEDIA_TYPES,
            hidden: true,
            useCategoryName: false,
            persistName: '',
          });
          list.push(mediaTypeCategory);
          break;
        }
      }
    });

    this.list = list;
  };

  @action reset = () => this.list.forEach((category) => category.reset());

  destroy = () => {
    this.hideGlassBreakOptionDisposer();
    this.glassBreakOptionDisposer();
    this.list.forEach((item) => item.destroy());
  };

  @computed get selected() {
    return this.list.map((category) => category.selected).flat();
  }

  @computed get params() {
    const allParams = this.list.reduce((object, current: MediaTypeCategory) => {
      if (!object[current.type]) {
        object[current.type] = current.params;
      } else {
        object[current.type] += current.params ? `,${current.params}` : '';
      }
      return object;
    }, {});

    return pickBy(allParams, (value) => Boolean(value));
  }

  @computed get filterList() {
    const list = [];

    if (this.hideBumpEventsOption) {
      list.push(this.hideBumpEventsOption);
    }

    if (this.hideGlassBreakOption) {
      list.push(this.hideGlassBreakOption);
    }

    return list;
  }

  @computed get mediaTotals() {
    const mediaTypeCategory = this.list.find((category) => category.type === SERVER_MEDIA_DATA_PARAMS.MEDIA_TYPES);

    return {
      video: mediaTypeCategory?.list.find((filter) => filter.value.toLowerCase() === 'video')?.count,
      images: mediaTypeCategory?.list.find((filter) => filter.value.toLowerCase() === 'image')?.count,
    };
  }

  get hideBumpEventsOption() {
    let option;

    this.list.find((category) => (option = category.list.find((filter) => filter.value === EVENT_TYPES.ALERT_BUMP)));
    return option;
  }

  get glassBreakOption() {
    let option;
    this.list.find(
      (category) =>
        (option =
          category.type === SERVER_MEDIA_DATA_PARAMS.INCLUDE_EVENT_TYPES &&
          category.list.find((filter) => filter.value === EVENT_TYPES.GLASS_BREAK))
    );
    return option;
  }

  get hideGlassBreakOption() {
    let option;

    this.list.find(
      (category) =>
        (option =
          category.type === SERVER_MEDIA_DATA_PARAMS.EXCLUDE_EVENT_TYPES &&
          category.list.find((filter) => filter.value === EVENT_TYPES.GLASS_BREAK))
    );
    return option;
  }
}
