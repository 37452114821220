import React, { Component } from 'react';
import classNames from 'classnames';

import { TIME_OPTIONS_ARRAY } from 'config';
import SearchableStaticSelect from '../Select/SearchableStaticSelect';

import './styles.scss';


interface IProps {
  className?: string;
  placeholder?: string;
  options?: Array<{
    key: string;
    text: string;
    value: string;
  }>;
  fluid?: boolean;
  noResultsMessage?: string;
  onChange: (time: string) => void;
  onError?: (time) => void;
  upward?: boolean;
  value?: string;
}

interface IState {
  searchQuery: string;
  value?: string;
}

class TimePicker extends Component<IProps, IState> {
  static defaultProps = {
    fluid: false,
    noResultsMessage: `Please match this time format "12:00am"`,
    onError: () => true,
    options: TIME_OPTIONS_ARRAY,
    placeholder: 'Select time please',
  };


  handleChange = (data) => {
    const { onChange } = this.props;
    const { value } = data;

    onChange(value);
  };

  render() {
    const { className, placeholder, options, fluid, noResultsMessage, value } = this.props;
    const cn = classNames('TimePicker', className, {
      'TimePicker--block': Boolean(fluid),
    });

    const values = options.map(item => ({...item, label: item.text}))

    return (
      <div className={cn}>
        <SearchableStaticSelect
          value={values.find(item => item.value === value)}
          values={values}
          handleChange={this.handleChange}
          placeholder={placeholder}
          emptySearchResults={noResultsMessage}
        />
      </div>
    );
  }
}

export default TimePicker;
