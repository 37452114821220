import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';

import { CircleErrorIcon } from 'assets';

import { getJSONItemFromStorage, printBlob, setJSONItemToStorage } from 'utils';
import { REPORTS_STORE, STORAGE_ITEMS } from 'config';

import type ReportTable from 'models/Report/ReportTable';
import type { IEditColumns } from 'models/Tables/IEditColumns';
import type { ISortColumn } from 'models/Tables/ISortColumn';
import type { ITableSearchField } from 'models/Tables/ITableSearchField';
import type { ReportsStore } from 'stores';

import CustomTable from 'components/Table/CustomTable';
import ReportTableHeaderTitle from 'components/Reports/ReportTableHeaderTitle';
import TableDownloadModal from 'components/Table/TableDownloadModal';
import TableEmailModal from 'components/Table/TableEmailModal';

import './styles.scss';

interface IProps {
  getColumns?: (table: ReportTable) => ColumnsType;
  reportsStore?: ReportsStore;
}

interface IState {
  isFilterOpen: boolean;
  isDownloadModalOpen: boolean;
  isEmailModalOpen: boolean;
}

@inject(REPORTS_STORE)
@observer
class ReportTableByDevice extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      isFilterOpen: getJSONItemFromStorage(STORAGE_ITEMS.reports.filterOpenStatus, true),
      isDownloadModalOpen: false,
      isEmailModalOpen: false,
    };
  }

  handleClickFilter = (isFilterOpen: boolean) => {
    this.setState((prevState) => ({ ...prevState, isFilterOpen }));
    setJSONItemToStorage(STORAGE_ITEMS.reports.filterOpenStatus, isFilterOpen);
  };

  setSortedColumn = (column: ISortColumn) => {
    const {
      reportsStore: { reportTable },
    } = this.props;

    reportTable?.filter.sort.set(column);
  };

  getGroupsList = () => {
    return void 0;
  };

  handleSearchColumns = (columns: ITableSearchField[]) => {
    const {
      reportsStore: { reportTable },
    } = this.props;

    reportTable?.filter.search.replace(columns);
  };

  handleColumnsChange = (columns: Array<IEditColumns<string>>) => {
    const {
      reportsStore: { reportTable },
    } = this.props;

    reportTable?.editColumns.setVisibleEditColumns(columns);
    reportTable?.filter.selectedColumns.replace(columns);
  };

  handleRefreshDataClick = () => {
    this.props.reportsStore.refreshLatestReport();
  };

  closeDownloadModal = () => {
    this.setState({ isDownloadModalOpen: false });
  };

  openDownloadModal = () => {
    this.setState({ isDownloadModalOpen: true });
  };

  closeEmailModal = () => {
    this.setState({ isEmailModalOpen: false });
  };

  openEmailModal = () => {
    this.setState({ isEmailModalOpen: true });
  };

  onPrint = () => {
    const {
      reportsStore: { downloadReport },
    } = this.props;
    downloadReport.format.set('html');
    downloadReport.print().then(printBlob);
  };

  get summary() {
    const {
      reportsStore: { reportTable },
    } = this.props;

    if (Boolean(reportTable.dataSource.length)) {
      return [
        reportTable.totalValues,
        reportTable.hasLimitError && {
          limitMessage:
            'The report for this vehicle exceeds the limit of 2,000 lines. Reduce the time period to load a compete data set.',
        },
      ];
    } else if (reportTable.isPartial) {
      return [
        reportTable.totalValues,
        {
          limitMessage: `This report has reached it's record display limit and may only contain a portion of the possible data`,
        },
      ];
    }

    return undefined;
  }

  render() {
    const {
      getColumns,
      reportsStore: {
        getReportsListRequestStatus,
        getReportsTableRequestStatus,
        reportHeaderOptionsText,
        reportHeaderTimeRangeText,
        reportTable,
        selectedReport,
        downloadReport,
      },
    } = this.props;

    const { isDownloadModalOpen, isEmailModalOpen, isFilterOpen } = this.state;
    const tableHeight = isFilterOpen ? 'calc(100vh - 215px)' : 'calc(100vh - 185px)';

    return (
      <div
        className={classNames(['ReportTableByDevice-table'], {
          'ReportTableByDevice-tableWithError': reportTable.hasLimitError || reportTable.isPartial,
          'ReportTableByDevice-table--withOpenFilters': isFilterOpen,
        })}
      >
        <CustomTable<string, string>
          columns={reportTable ? getColumns(reportTable) : []}
          dataSource={reportTable ? reportTable.dataSource : null}
          editColumnsMap={reportTable ? reportTable.editColumns.editColumnsMap : {}}
          getData={this.getGroupsList}
          loading={getReportsListRequestStatus.loading || getReportsTableRequestStatus.loading}
          onColumnsChange={this.handleColumnsChange}
          onColumnsSearch={this.handleSearchColumns}
          onColumnSort={this.setSortedColumn}
          onFilterOpen={this.handleClickFilter}
          isFilterActive={isFilterOpen}
          selectedColumns={reportTable ? toJS(reportTable.editColumns.editColumnsList) : []}
          summary={this.summary}
          summaryRowProps={{ rowToMergeIndex: 1, columnsNumber: reportTable.columns.length }}
          tableHeight={tableHeight}
          total={reportTable.total}
          totalTitle={
            reportTable.hasLimitError || reportTable.isPartial ? (
              <div className="ReportTableByDevice-tableHeader">
                <span className="ReportTableByDevice-tableHeader-icon">
                  <Tooltip
                    placement="right"
                    title={
                      reportTable.hasLimitError
                        ? 'The report for this vehicle exceeds the limit of 2,000 lines. Reduce the time period to load a compete data set.'
                        : `This report has reached it's record display limit and may only contain a portion of the possible data`
                    }
                  >
                    <CircleErrorIcon />
                  </Tooltip>
                </span>
                <span className="ReportTableByDevice-tableHeader-title">
                  <ReportTableHeaderTitle
                    reportName={selectedReport.title}
                    reportOptions={reportHeaderOptionsText}
                    reportTimeRange={reportHeaderTimeRangeText}
                  />
                </span>
              </div>
            ) : (
              <ReportTableHeaderTitle
                reportName={selectedReport.title}
                reportOptions={reportHeaderOptionsText}
                reportTimeRange={reportHeaderTimeRangeText}
              />
            )
          }
          withActions
          withDownload
          withEmail
          openDownloadModal={this.openDownloadModal}
          openEmailModal={this.openEmailModal}
          actionsList={[{ text: 'Refresh Report Data', onClick: this.handleRefreshDataClick }]}
          withPagination={false}
          withPrint
          onPrint={this.onPrint}
        />
        <TableDownloadModal<ReportsStore>
          isOpen={isDownloadModalOpen}
          onClose={this.closeDownloadModal}
          tableDownload={downloadReport}
        />
        <TableEmailModal<ReportsStore>
          isOpen={isEmailModalOpen}
          onClose={this.closeEmailModal}
          tableDownload={downloadReport}
        />
      </div>
    );
  }
}

export default ReportTableByDevice;
