import { computed } from 'mobx';
import { INSPECTION_RESULTS, INSPECTION_STATUSES } from 'config';

class InspectionResults {
  status: INSPECTION_STATUSES;
  failedItemsCount: number;

  constructor(status, failedItemsCount) {
    this.status = status;
    this.failedItemsCount = failedItemsCount;
  }

  @computed get results() {
    switch (this.status) {
      case INSPECTION_STATUSES.PASSED:
        return {
          text: this.failedItemsCount ? `Passed with ${this.failedItemsCount} Defects` : 'Passed',
          status: this.failedItemsCount ? INSPECTION_RESULTS.PASSED_WITH_DEFECTS : INSPECTION_RESULTS.PASSED,
        };
      case INSPECTION_STATUSES.FAILED:
        return {
          text: this.failedItemsCount ? `Failed with ${this.failedItemsCount} Defects` : 'Failed',
          status: INSPECTION_RESULTS.FAILED,
        };
      case INSPECTION_STATUSES.IN_PROGRESS:
        return {
          text: 'In Progress',
          status: INSPECTION_RESULTS.IN_PROGRESS,
        };
      case INSPECTION_STATUSES.ABANDONED:
        return {
          text: `Abandoned`,
          status: INSPECTION_RESULTS.ABANDONED,
        };
    }
  }
}

export default InspectionResults;
