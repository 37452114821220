import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { MAINTENANCES_STORE } from 'config';
import type { Maintenances as MaintenancesStore } from 'stores';

import AssetsList from 'components/AssetsList';
import Button from 'components/Button';
import ConfirmationModal from 'components/Admin/ConfirmationModal';
import MaintenanceTaskModal from 'components/Maintenance/TaskModal';
import Modal from 'components/Modal';
import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';
import TaskToApplyCard from 'components/Maintenance/TaskToApply/TaskToApplyCard';

import './styles.scss';

interface IProps {
  [MAINTENANCES_STORE]?: MaintenancesStore;
}

interface IState {
  isEditModalOpen: boolean;
  isExitingConfirmationModalOpen: boolean;
}

@inject(MAINTENANCES_STORE)
@observer
class ApplyTasksSidebar extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isEditModalOpen: false,
      isExitingConfirmationModalOpen: false,
    };
  }

  componentDidMount() {
    const {
      maintenancesStore: {
        applyTasks: { groups },
      },
    } = this.props;

    groups.fetch(true, true);
  }

  componentWillUnmount() {
    const {
      maintenancesStore: {
        applyTasks: { groups },
      },
    } = this.props;

    groups.reset();
  }

  handleGroupChange = (group: Select.ISelectOption) => {
    const {
      maintenancesStore: {
        applyTasks: { groups },
      },
    } = this.props;

    const selectedGroup = groups.findGroupById(group.value);
    groups.setSelected(selectedGroup, true);
  };

  editMaintenanceTask = () => {
    const {
      maintenancesStore: { applyTasks },
    } = this.props;

    applyTasks.edit();
    this.setState({ isEditModalOpen: true });
  };

  tryCloseEditModal = () => {
    const {
      maintenancesStore: {
        applyTasks: { editTask },
      },
    } = this.props;

    if (editTask.value?.model.isUpdated) {
      this.setState({ isExitingConfirmationModalOpen: true });
    }

    this.closeEditModal();
  };

  closeEditModal = () => {
    this.setState({ isEditModalOpen: false });
  };

  onSubmitExitingConfirmation = () => {
    this.setState({ isExitingConfirmationModalOpen: false });
  };

  onCancelExitingConfirmation = () => {
    this.setState({ isEditModalOpen: true, isExitingConfirmationModalOpen: false });
  };

  updateTask = async () => {
    const {
      maintenancesStore: {
        applyTasks: { update },
      },
    } = this.props;

    update();
    this.closeEditModal();
  };

  render() {
    const {
      maintenancesStore: {
        applyTasks: {
          numberOfCreatedTask,
          task,
          editTask,
          groups: { selectValues, selectedValue, selected },
        },
      },
    } = this.props;
    const { isEditModalOpen, isExitingConfirmationModalOpen } = this.state;
    const noAssetsMessage =
      selected?.repositoryAssetsIds.getState.success && !Boolean(selected.assets.value.length)
        ? 'No Vehicles in selected group'
        : '';

    return (
      <div className="ApplyTasksSidebar">
        <div className="ApplyTasksSidebar-section ApplyTasksSidebar-section--taskToApply">
          <p className="ApplyTasksSidebar-sectionTitle">Task To Apply</p>
          <div className="ApplyTasksSidebar-sectionContent">
            {task.value && (
              <TaskToApplyCard
                task={task.value.representation.applyTaskCard}
                onEdit={this.editMaintenanceTask}
                editDisabled={Boolean(numberOfCreatedTask > 0)}
              />
            )}
          </div>
        </div>
        <div className="ApplyTasksSidebar-section ApplyTasksSidebar-section--vehiclesToApply">
          <p className="ApplyTasksSidebar-sectionTitle">Vehicles To Apply To</p>
          <div className="ApplyTasksSidebar-sectionContent">
            <div className="ApplyTasksSidebar-groups">
              <SearchableStaticSelect
                values={selectValues}
                value={selectedValue}
                handleChange={this.handleGroupChange}
              />
            </div>
            {selected && (
              <div className="ApplyTasksSidebar-assets">
                <div className="ApplyTasksSidebar-assetsList">
                  <AssetsList
                    assets={selected.filteredAssets}
                    disabled={!Boolean(selected.assets.value.length)}
                    search={selected.searchAssetQuery}
                    withSearch
                    loading={selected.repositoryAssetsIds.getState.loading}
                    noAssetsMessage={noAssetsMessage}
                    mainTaskAssetId={task.value?.model.asset.id.value}
                  />
                </div>
                {Boolean(selected.filteredAssets.length) && (
                  <div className="ApplyTasksSidebar-assetsActions">
                    <div className="ApplyTasksSidebar-assetsSelectAll">
                      <Button
                        title={selected.allAssetsSelected ? 'Deselect All' : 'Select All'}
                        className="Button--link Button--apply"
                        disabled={selected.isSelectAllProcessing.value}
                        onClick={selected.allAssetsSelected ? selected.deselectAllAssets : selected.selectAllAssets}
                      />
                    </div>
                    <p className="ApplyTasksSidebar-assetsSelectedCount">{selected.selectedAssetsCount} selected</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <Modal isModalOpen={isEditModalOpen} onClose={this.tryCloseEditModal} className="Modal--editApplyTask">
          {editTask.value && (
            <MaintenanceTaskModal
              title="Edit Maintenance Task to Apply"
              tryCloseModal={this.tryCloseEditModal}
              closeModal={this.closeEditModal}
              task={editTask.value}
              onSave={this.updateTask}
              applyTask
              showVehicle={false}
              showAttachments={false}
              showNote={false}
              showLastPerformed={false}
              showNext={false}
            />
          )}
        </Modal>
        <ConfirmationModal
          title="Cancel Applying Tasks to Vehicles?"
          applyButtonTitle="Yes, Continue"
          cancelButtonTitle="No, Go Back"
          cancelButtonStyles={{ border: 'none' }}
          contentStyles={{ backgroundColor: '#fff', padding: '0 20px' }}
          isOpen={isExitingConfirmationModalOpen}
          onCancel={this.onCancelExitingConfirmation}
          onSubmit={this.onSubmitExitingConfirmation}
          popupClassName="Modal--confirmEditApplyTask"
        >
          <>
            Tasks will not be created for the remaining Vehicles selected and all changes made on this page will not be
            saved.
            <br />
            <br />
            Do you want to continue?
          </>
        </ConfirmationModal>
      </div>
    );
  }
}

export default ApplyTasksSidebar;
