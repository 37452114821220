import React, { FC } from 'react';
import classNames from 'classnames';

import Button from 'components/Button';
import Modal from 'components/Modal';

import { ArrowIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  driverNameToAssign: string;
  driverNameToRemove: string;
  isOpen: boolean;
  onCancel?: () => void;
  onSubmit: () => void;
  onOpen: () => void;
  vehicleName: string;
}

const ReassignModal: FC<IProps> = ({
  className,
  driverNameToAssign,
  driverNameToRemove,
  isOpen,
  onCancel,
  onSubmit,
  onOpen,
  vehicleName,
}) => {
  const cn = classNames('ReassignModal', className);

  return (
    <Modal className="Modal--reassignVehicle" isModalOpen={isOpen} onClose={onCancel} onOpen={onOpen}>
      <div className={cn}>
        <p className="ReassignModal-title">Reassign Vehicle {vehicleName}</p>
        <p className="ReassignModal-text">
          This Vehicle is currently assigned to another driver. Assigning to a new driver will remove the vehicle from
          the current driver.
          <br />
          Do you want to continue?
        </p>
        <div className="ReassignModal-reassign">
          <div className="ReassignModal-driver ReassignModal-driver--toRemove">
            <p className="ReassignModal-driverLabel">Driver to remove</p>
            <p className="ReassignModal-driverName">{driverNameToRemove}</p>
          </div>
          <div className="ReassignModal-arrow">
            <ArrowIcon className="ReassignModal-arrowImg" />{' '}
          </div>
          <div className="ReassignModal-driver ReassignModal-driver--toAssign">
            <p className="ReassignModal-driverLabel">Driver to assign</p>
            <p className="ReassignModal-driverName">{driverNameToAssign}</p>
          </div>
        </div>
        <div className="ReassignModal-buttons">
          <div className="ReassignModal-cancel">
            <Button className="Button--cancel Button--cancelColorLynch" title="Cancel" onClick={onCancel} />
          </div>
          <div className="ReassignModal-submit">
            <Button className="Button--apply" title="Reassign now" onClick={onSubmit} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReassignModal;
