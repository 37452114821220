import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { CaretDownOutlined } from '@ant-design/icons';

import { PATHS } from 'config';

import type { RouterStore } from 'stores/RouterStore';

import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';

import './styles.scss';

interface IProps {
  routerStore?: RouterStore;
  options: Report.IServerOption[];
  selectedId: string;
  onChange?(id: string): void;
}

@inject(({ routerStore }) => ({
  routerStore,
}))
@observer
class ReportSubTypeOptionSelector extends Component<IProps> {
  handleChange = (option: any) => {
    if (this.props.onChange) {
      this.props.onChange(option.value);
    } else {
      this.props.routerStore.history.push(
        PATHS.REPORTS.REPORT.replace(':reportId', option.value).replace(':optionId', '')
      );
    }
  };

  get filteredList() {
    return this.props.options.map(({ displayName, report }) => ({ label: displayName, value: report.reportId }));
  }

  get selectedOptionName() {
    const { selectedId, options } = this.props;
    return options.find((item) => item.report.reportId === selectedId);
  }

  get renderTrigger() {
    const selectedOptionName = this.selectedOptionName;
    return (
      <div className="ReportSubTypeOptionSelector-trigger">
        <div className="ReportSubTypeOptionSelector-optionTrigger">
          <span className="ReportSubTypeOptionSelector-optionName">
            {selectedOptionName ? selectedOptionName.displayName : ''}
          </span>
          <div className="ReportSubTypeOptionSelector-optionArrow">
            <CaretDownOutlined className="ReportSubTypeOptionSelector-optionArrowImg" />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { selectedId } = this.props;

    return (
      <div className="ReportSubTypeOptionSelector ReportSubTypeOptionSelector--inline">
        <SearchableStaticSelect
          isSearchable={false}
          value={{ value: selectedId, label: '' }}
          values={this.filteredList}
          handleChange={this.handleChange}
        />
      </div>
    );
  }
}

export default ReportSubTypeOptionSelector;
