import React, { FC } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import type { IDashcamSettingsNumberField } from 'interfaces/models/Dashcams/IDashcamSettingsNumberField';
import type { SimpleField } from 'models/Fields';

import InfoTooltip from 'components/InfoTooltip';
import InputNumber from 'components/InputNumber';

import { OpenNewWindow } from 'assets';

import './styles.scss';

interface IProps {
  item: IDashcamSettingsNumberField<SimpleField<number>>;
  disabled?: boolean;
}

const CameraSettingsItemNumeric: FC<IProps> = ({ item, disabled }) => {
  const handleChange = (value: string) => {
    item.value.set(Number(value));
  };

  const handleBlur = (val: string) => {
    let value = Number(val);
    if (item.allowableRange?.step) {
      value = Math.floor(value - (value % item.allowableRange?.step));
    }

    item.value.set(value);
  };

  return (
    <div
      className={classnames('CameraSettingsItem CameraSettingsItemNumeric', {
        'CameraSettingsItemNumeric--disabled CameraSettingsItem--disabled': disabled,
      })}
    >
      <div
        className={classnames('CameraSettingsItem-info CameraSettingsItemNumeric-info', {
          'CameraSettingsItem-infoError': item.error,
        })}
      >
        <div className="CameraSettingsItem-name CameraSettingsItemNumeric-name">{item.name}</div>
        {item.description && (
          <InfoTooltip className="CameraSettingsItem-infoTooltip CameraSettingsItemNumeric-infoTooltip">
            {item.description}
          </InfoTooltip>
        )}
      </div>
      <div className="CameraSettingsItem-action CameraSettingsItemNumeric-action">
        <div
          className={classnames('CameraSettingsItemNumeric-value', {
            'CameraSettingsItem-valueError': item.error,
          })}
        >
          {item.error && (
            <div className="CameraSettingsItem-actionError">
              <div>
                {item.error.description && (
                  <InfoTooltip className="CameraSettingsItem-infoTooltip CameraSettingsItemNumeric-infoTooltip">
                    {item.error.description}
                  </InfoTooltip>
                )}
              </div>
              <a
                href={item.error.link}
                target="_blank"
                rel="noopener noreferrer"
                className="CameraSettingsHeader-infoActionLink"
              >
                <span>{item.error.name}</span>
              </a>
              <OpenNewWindow className="CameraSettingsHeader-infoActionIcon" />
            </div>
          )}

          {!item.error && (
            <InputNumber
              disabled={disabled}
              className="CameraSettingsItemNumeric-input"
              min={item.allowableRange?.from && String(item.allowableRange?.from)}
              max={item.allowableRange?.to && String(item.allowableRange?.to)}
              step={item.allowableRange?.step && String(item.allowableRange?.step)}
              onChange={handleChange}
              placeholder="Year"
              value={String(item.value.value || item.allowableRange?.from || 0)}
              size="small"
              onBlur={handleBlur}
            />
          )}
        </div>
        {!item.error && item.typeDescription && (
          <div className="CameraSettingsItemNumeric-measure">{item.typeDescription}</div>
        )}
      </div>
    </div>
  );
};

export default observer(CameraSettingsItemNumeric);
