import React, { FC, useCallback } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { PATHS, LINKS } from 'config';
import { ArrowIcon, MapEmptyIcon, HelpIcon, SadPathImage } from 'assets';
import './styles.scss';

interface IProps extends RouteComponentProps {
  className?: string;
}

const NotFound: FC<IProps> = ({ history: { goBack, push }, location }) => {
  const handleBack = useCallback(
    (e) => {
      const prevLink = location.state?.from;

      if (prevLink) {
        push(prevLink);
      } else {
        e.preventDefault();
        goBack();
      }
    },
    [goBack, push, location.state]
  );

  return (
    <div className="NotFound">
      <p className="NotFound-title">This page isn’t available.</p>
      <p className="NotFound-message NotFound-message--top">
        The link you followed may be broken, or the page may have been removed.
      </p>
      <div className="NotFound-image">
        <SadPathImage className="NotFound-imageSrc" />
      </div>
      <p className="NotFound-message NotFound-message--bottom">
        Try one of these options, or use the navigation on the lefthand side of the page.
      </p>
      <div className="NotFound-links">
        <Link to="#" className="NotFound-link NotFound-link--prev" onClick={handleBack}>
          <ArrowIcon className="NotFound-linkIcon" />
          <span className="NotFound-linkText">Go back to the previous page</span>
        </Link>
        <Link to={PATHS.MAP.VEHICLES} className="NotFound-link NotFound-link--map">
          <MapEmptyIcon className="NotFound-linkIcon" />
          <span className="NotFound-linkText">Go to the Map</span>
        </Link>
        <a href={LINKS.helpCenter} target="_blank" className="NotFound-link NotFound-link--help">
          <HelpIcon className="NotFound-linkIcon" />
          <span className="NotFound-linkText">Visit our Help Center</span>
        </a>
      </div>
    </div>
  );
};

export default NotFound;
