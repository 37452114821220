import React from 'react';
import classNames from 'classnames';

import type MediaDateOptionProperty from 'models/Dashcam/MediaLibrary/MediaSource/MediaDateOptionProperty';
import type MediaDateOptionRange from 'models/Dashcam/MediaLibrary/MediaSource/MediaDateOptionRange';
import type MediaDateRange from 'models/Dashcam/MediaLibrary/MediaSource/MediaDateRange';
import type { IMediaDateOption } from 'interfaces/models/Dashcams/IMediaDateOption';

import './styles.scss';

interface IProps {
  className?: string;
  closeDateRangeTooltip: () => void;
  dateRange: MediaDateRange;
}

const MediaLibrarySourceDateRangeOptions: React.FC<IProps> = ({ className, dateRange, closeDateRangeTooltip }) => {
  const cn = classNames('MediaLibrarySourceDateRangeOptions', className);
  const handleClickOnOption = (option: IMediaDateOption<MediaDateOptionRange | MediaDateOptionProperty>) => {
    if (!option.action) {
      dateRange.unselectOptions();
      closeDateRangeTooltip();
    }
    option.isSelected.toggle(true);
  };

  return (
    <div className={cn}>
      <ul className="MediaLibrarySourceDateRangeOptions-list">
        {dateRange.options.map((option) => (
          <li
            key={option.name}
            className="MediaLibrarySourceDateRangeOptions-item"
            onClick={() => handleClickOnOption(option)}
          >
            {option.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MediaLibrarySourceDateRangeOptions;
