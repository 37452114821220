import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';
import { parse } from 'query-string';
import { Layout } from 'antd';
import noop from 'lodash/noop';
import { setCookie } from 'utils';

import {
  MAINTENANCES_STORE,
  ROUTER_STORE,
  ACL,
  PATHS,
  MAINTENANCE_VIEW,
  MAINTENANCE_APPLY_TASK_CREATED_SUCCESSFULLY,
} from 'config';
import { Maintenances as MaintenancesStore, UserAccessLevelComponent, RouterStore, validateAccessLevel } from 'stores';
import Button from 'components/Button';
import TabsMenu from 'components/TabsMenu';
import Modal from 'components/Modal';
import MaintenanceTaskModal from 'components/Maintenance/TaskModal';
import UpcomingTable from './UpcomingTable';
import CompletedTable from './CompletedTable';
import MaintenanceRequests from './MaintenanceRequests';
import Notification from 'components/Notification';
import ConfirmationModal from 'components/Admin/ConfirmationModal';

import './styles.scss';

const { Content, Header } = Layout;
interface IProps {
  [MAINTENANCES_STORE]?: MaintenancesStore;
  [ROUTER_STORE]?: RouterStore;
  assetId?: number;
  assetName?: string;
}

interface IState {
  taskModalIsOpen: boolean;
  isExitingConfirmationModalOpen: boolean;
  selectedTab: number;
}

interface ISearchParams {
  tab?: string;
}

@inject(MAINTENANCES_STORE, ROUTER_STORE)
@observer
class Maintenance extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      taskModalIsOpen: false,
      isExitingConfirmationModalOpen: false,
      selectedTab: this.initialSelectedTab,
    };
    document.documentElement.style.setProperty('--maintenance-request-height', `0px`);
  }

  componentDidMount() {
    const {
      maintenancesStore: { accountConfig, requests },
    } = this.props;

    accountConfig.fetch().catch(noop);
    if (validateAccessLevel([ACL.MAINTENANCE.REQUESTS.READ])) {
      requests.fetch().catch(noop);
    }
  }

  componentWillUnmount() {
    const {
      maintenancesStore: { resetTasks },
    } = this.props;

    resetTasks();
  }

  openTaskModal = () => {
    const { maintenancesStore, assetId, assetName } = this.props;

    maintenancesStore.create();

    if (assetId) {
      maintenancesStore.task.model.asset.set(assetId.toString(), assetName);
    }

    this.setState({ taskModalIsOpen: true });
  };

  onCreateFromRequestTaskModal = async (requestId?: number) => {
    const { maintenancesStore } = this.props;
    try {
      await maintenancesStore.createFromRequest(requestId);

      this.setState({ taskModalIsOpen: true });
    } catch (_) {
      noop();
    }
  };

  taskModalClose = () => {
    this.setState({ taskModalIsOpen: false });
  };

  tryCloseModal = () => {
    const { maintenancesStore } = this.props;

    if (maintenancesStore.task && maintenancesStore.task.model.documents.isUpdated.value) {
      this.setState({ isExitingConfirmationModalOpen: true });
    }

    this.taskModalClose();
  };

  onSubmitExitingConfirmation = () => {
    this.setState({ isExitingConfirmationModalOpen: false });
    this.props.maintenancesStore.resetTask();
  };

  onCancelExitingConfirmation = () => {
    this.setState({ taskModalIsOpen: true, isExitingConfirmationModalOpen: false });
  };

  setSelectedTab = (selectedTab: number) => this.setState({ selectedTab });

  get initialSelectedTab() {
    const { tab } = parse(this.props.routerStore.history.location.search) as ISearchParams;

    return tab === 'completed' ? 1 : 0;
  }

  get isCompletedTabSelected() {
    return this.state.selectedTab === 1;
  }

  redirectToApplyTaskPage = () => {
    const {
      routerStore: {
        history: { push },
      },
      maintenancesStore: { task },
    } = this.props;

    setCookie(
      MAINTENANCE_APPLY_TASK_CREATED_SUCCESSFULLY,
      `${task.model.service.type.item.name}: ${task.model.asset.name.value}`
    );
    push(`${PATHS.MAINTENANCE.APPLY_TASKS.TASK.replace(':id', String(task.model.id.value))}`);
  };

  render() {
    const { maintenancesStore } = this.props;
    const { taskModalIsOpen, isExitingConfirmationModalOpen, selectedTab } = this.state;
    const cn = classnames('Maintenance', {
      [`Maintenance--${maintenancesStore.view.value}`]: Boolean(maintenancesStore.view.value),
      'Maintenance--basic': !validateAccessLevel([ACL.MAINTENANCE.BASIC.CREATE]),
    });
    const createTaskState = maintenancesStore.task?.model.repository.createState;
    const convertRequestState = maintenancesStore.requests?.repositoryRequests.patchState;

    return (
      <div className={cn}>
        <div className="Maintenance-header">
          <div className="Maintenance-title">
            <p className="Maintenance-mainTitle">Maintenance</p>
            <p className="Maintenance-subTitle">
              Create, edit, and manage maintenance tasks for all Assets in your fleet.
            </p>
          </div>
          <UserAccessLevelComponent requiredAccessLevel={[ACL.MAINTENANCE.BASIC.CREATE]}>
            <div className="Maintenance-create">
              <Button
                className="Button--apply"
                disabled={this.isCompletedTabSelected}
                onClick={this.openTaskModal}
                title="Create New Task"
              />
            </div>
          </UserAccessLevelComponent>
        </div>
        <Layout className="Maintenance-container">
          {maintenancesStore.requests.status === 'COMPLETED' &&
            maintenancesStore.requests.totalCount > 0 &&
            maintenancesStore.view.value === MAINTENANCE_VIEW.DEFAULT && (
              <Header className="Maintenance-containerRequests">
                <MaintenanceRequests onCreate={this.onCreateFromRequestTaskModal} onIgnore={this.taskModalClose} />
              </Header>
            )}
          <Content className="Maintenance-containerTasks">
            <div className="Maintenance-content">
              <TabsMenu
                tabs={[{ name: 'Upcoming' }, { name: 'Completed' }]}
                onSelect={this.setSelectedTab}
                rounded
                selectedTab={selectedTab}
              >
                <div className="Maintenance-table Maintenance-table--upcoming">
                  <UpcomingTable />
                </div>
                <div className="Maintenance-table Maintenance-table--completed">
                  <CompletedTable />
                </div>
              </TabsMenu>
            </div>
          </Content>
        </Layout>
        <Modal isModalOpen={taskModalIsOpen} onClose={this.tryCloseModal} className="Maintenance-taskModal">
          {maintenancesStore.task && (
            <MaintenanceTaskModal
              title={maintenancesStore.task.model.requestId ? 'Convert to Maintenance Task' : 'Create Maintenance Task'}
              closeModal={this.taskModalClose}
              tryCloseModal={this.tryCloseModal}
              task={maintenancesStore.task}
              onSave={
                maintenancesStore.task.model.requestId
                  ? maintenancesStore.task.model.update
                  : maintenancesStore.task.model.save
              }
              onApplyTo={this.redirectToApplyTaskPage}
            />
          )}
        </Modal>
        {createTaskState?.success && (
          <Notification
            onClose={createTaskState.reset}
            text={`${maintenancesStore.task.model.asset.name.value}: ${maintenancesStore.task.model.service.type.item.name}`}
            title="Maintenance Task Created"
            type="success"
          />
        )}
        {createTaskState?.error && (
          <Notification
            onClose={createTaskState.reset}
            text="Maintenance Task was unable to be created"
            title="Create Task Failed"
            type="error"
            onRetry={maintenancesStore.task.model.save}
          />
        )}
        {convertRequestState?.success && (
          <Notification
            onClose={convertRequestState.reset}
            text="Maintenance Task was successfully created."
            title="Success!"
            type="success"
          />
        )}
        {convertRequestState?.error && (
          <Notification
            onClose={convertRequestState.reset}
            text="The Task was unable to be created. Please try again."
            title="Convert to Task Failed"
            type="error"
          />
        )}
        <ConfirmationModal
          className="Maintenance-exitingConfirmation"
          title="Cancel Create Task?"
          applyButtonTitle="Yes, Continue"
          cancelButtonTitle="No, Go Back"
          cancelButtonStyles={{ border: 'none' }}
          isOpen={isExitingConfirmationModalOpen}
          onCancel={this.onCancelExitingConfirmation}
          onSubmit={this.onSubmitExitingConfirmation}
        >
          <div className="Maintenance-exitingConfirmationContent">
            <p>This task has attachments that will be removed from the system if you proceed.</p>
            <p className="Maintenance-exitingConfirmationContentQuestion">Do you want to continue?</p>
          </div>
        </ConfirmationModal>
      </div>
    );
  }
}

export default Maintenance;
