import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import DashCamsMediaSource from './MediaSource';
import DashCamsMediaFilter from 'components/DashCams/MediaLibrary/FilterMedia';
import type { MediaManager } from 'stores';
import { LINKS } from 'config';

import { GoIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  mediaManagerStore?: MediaManager;
}

@inject(({ mediaManagerStore }) => ({ mediaManagerStore }))
@observer
class DashCamsMediaLibraryPanel extends Component<IProps> {
  render() {
    const {
      className,
      mediaManagerStore: {
        library: {
          type: { categories },
        },
      },
    } = this.props;
    const cn = classNames('DashCamsMediaLibraryPanel', className);

    return (
      <div className={cn}>
        <div className="DashCamsMediaLibraryPanel-header">
          <div className="DashCamsMediaLibraryPanel-section DashCamsMediaLibraryPanel-section--header">
            <div className="DashCamsMediaLibraryPanel-sectionContent">
              <p className="DashCamsMediaLibraryPanel-title">Dashcam Media</p>
            </div>
          </div>
        </div>
        <div className="DashCamsMediaLibraryPanel-content">
          <div className="DashCamsMediaLibraryPanel-section DashCamsMediaLibraryPanel-section--source">
            <p className="DashCamsMediaLibraryPanel-sectionTitle">Media Source</p>
            <div className="DashCamsMediaLibraryPanel-sectionContent">
              <DashCamsMediaSource />
            </div>
          </div>
          <div className="DashCamsMediaLibraryPanel-section DashCamsMediaLibraryPanel-section--filter">
            <p className="DashCamsMediaLibraryPanel-sectionTitle">Filter Media</p>
            <div className="DashCamsMediaLibraryPanel-sectionContent">
              <DashCamsMediaFilter categories={categories} />
            </div>
          </div>
          <div className="DashCamsMediaLibraryPanel-section DashCamsMediaLibraryPanel-section--link">
            <div className="DashCamsMediaLibraryPanel-sectionContent">
              <a href={LINKS.learnAboutDashcamAI} target="_blank" className="DashCamsMediaLibraryPanel-featuresLink">
                <span className="DashCamsMediaLibraryPanel-featuresLinkText">Learn about Dashcam AI features</span>
                <div className="DashCamsMediaLibraryPanel-featuresLinkIcon">
                  <GoIcon />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DashCamsMediaLibraryPanel;
