import React from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  index: number;
  title: string;
  isActive?: boolean;
  isCompleted?: boolean;
  isBlocked?: boolean;
}

const AlertModalStepListItem: React.FC<IProps> = ({
  index,
  title,
  isActive = false,
  isCompleted = false,
  isBlocked = false,
}) => {
  const cn = classNames('AlertModalStepListItem', {
    'AlertModalStepListItem--active': isActive,
    'AlertModalStepListItem--completed': isCompleted,
    'AlertModalStepListItem--blocked': isCompleted && isBlocked,
  });

  const indexTitle = index + 1;

  return (
    <div className={cn}>
      <div className="AlertModalStepListItem-badge">{indexTitle}</div>
      <div className="AlertModalStepListItem-title">{title}</div>
    </div>
  );
};

export default AlertModalStepListItem;
