import React from 'react';
import { observer } from 'mobx-react';

import type { ToggleField } from 'models/Fields';

import Checkbox from 'components/Checkbox';

import './styles.scss';

interface IProps {
  requireUpdatingOdometer: ToggleField;
  requireUpdatingEngHours: ToggleField;
}

const InspectionsFormPanelRequire: React.FC<IProps> = ({ requireUpdatingOdometer, requireUpdatingEngHours }) => {
  return (
    <div className="InspectionsFormPanelRequire">
      <Checkbox
        className="InspectionsFormPanelRequire-odometer"
        label="Updating Odometer"
        checked={requireUpdatingOdometer.value}
        onChange={requireUpdatingOdometer.toggle}
      />
      <Checkbox
        className="InspectionsFormPanelRequire-engHours"
        label="Updating Engine Hours"
        checked={requireUpdatingEngHours.value}
        onChange={requireUpdatingEngHours.toggle}
      />
    </div>
  );
};

export default observer(InspectionsFormPanelRequire);
