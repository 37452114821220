import { SimpleField } from 'models/Fields';
import MaintenanceTasks from 'models/Maintenances/Models/MaintenanceTasks/MaintenanceTasks';

class MaintenanceAlertTaskModel {
  id: SimpleField<number>;
  value: SimpleField<number>;
  type: SimpleField<string>;
  task: MaintenanceTasks;

  constructor({ id, type }) {
    this.id = new SimpleField(id);
    this.value = new SimpleField(null);
    this.type = new SimpleField(type);
    this.task = new MaintenanceTasks(null, id);
  }
}

export default MaintenanceAlertTaskModel;
