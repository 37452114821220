import type { Maintenances } from 'stores';
import MaintenanceCompleteRecordModel from './Models/MaintenanceCompleteRecordModel';
import MaintenanceModel from './Models/MaintenanceModel';
import MaintenanceRepresentation from './Representation/MaintenanceRepresentation';
import MaintenanceUI from './UI/MaintenanceModalUI/MaintenanceUI';
import { observable } from 'mobx';

class MaintenanceTask {
  id: number;
  model: MaintenanceModel;
  @observable completed: MaintenanceCompleteRecordModel;
  ui: MaintenanceUI;
  representation: MaintenanceRepresentation;
  context: Maintenances;

  constructor(data, context: Maintenances) {
    this.context = context;
    this.id = Date.now();
    this.model = new MaintenanceModel(data, this);
    this.completed = null;
    this.ui = new MaintenanceUI(this.model);
    this.representation = new MaintenanceRepresentation(this.model, this.context.accountConfig);
  }

  onUpdate = () => {
    this.context.isUpdate.toggle(true);
  };

  markAsComplete = () => {
    const record = this.model.completedData;

    this.completed = new MaintenanceCompleteRecordModel(record, this.model);
    this.completed.documents.fetch();
  };

  setCompleted = (record) => {
    this.completed = new MaintenanceCompleteRecordModel(record, this.model);
  };

  selectCompleted = (id) => {
    const completed = this.findCompletedById(id);
    this.completed = new MaintenanceCompleteRecordModel(completed.serverData, this.model);
    this.completed.documents.fetch();
  };

  selectCompletedLast = () => {
    const lastCompleted = this.model.historyRecords[0];

    this.completed = new MaintenanceCompleteRecordModel(lastCompleted.serverData, this.model);
    this.completed.documents.fetch();
  };

  findCompletedById = (id: number) => {
    return this.model.historyRecords.find((record) => record.id === id);
  };
}

export default MaintenanceTask;
