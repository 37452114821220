import React, { memo, useCallback } from 'react';
import classNames from 'classnames';

import WayPointMarker from './WayPointMarker';
import type { timezoneNames } from 'stores';
import type { Event, Device } from 'models';
import type DevicesFilters from 'stores/Map/DevicesFilters';

import './styles.scss';

interface IProps {
  className?: string;
  wayPoints: Event[];
  timezone: timezoneNames | string;
  selectedEventIndex: number;
  locked?: boolean;
  showHistory?: boolean;
  onClick: (index: any) => void;
  onGeozoneClick: (geozoneId: string) => void;
  setStreetViewOpened: () => void;
  onZoomIntoClick: () => void;
  selectedDevice: Device;
  filters: DevicesFilters;
  mapHeading: number;
  isHeading: boolean;
}

const WayPoints: React.FC<IProps> = ({
  wayPoints,
  timezone,
  setStreetViewOpened,
  onZoomIntoClick,
  className,
  locked,
  showHistory,
  onClick,
  onGeozoneClick,
  selectedDevice,
  filters,
  mapHeading,
  isHeading,
}) => {
  const cn = classNames('WayPoints', className);

  const handleClick = useCallback((index: number) => {
    onClick(index);
  }, []);

  const unselectClick = useCallback(() => {
    onClick(null);
  }, []);

  return (
    <div className={cn}>
      {wayPoints.map((wayPoint, index) => (
        <WayPointMarker
          index={index}
          wayPoint={wayPoint}
          timezone={timezone}
          locked={locked}
          showHistory={showHistory}
          selectedDevice={selectedDevice}
          filters={filters}
          setStreetViewOpened={setStreetViewOpened}
          onZoomIntoClick={onZoomIntoClick}
          key={`${wayPoint.timestamp}_${wayPoint.eventStatusCode}_${index}`}
          onClick={handleClick}
          onGeozoneClick={onGeozoneClick}
          unselectClick={unselectClick}
          defaultZIndex={wayPoints.length - 1 === index ? 11 : 10}
          mapHeading={mapHeading}
          isHeading={isHeading}
        />
      ))}
    </div>
  );
};

export default memo(WayPoints);
