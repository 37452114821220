import { action, observable } from 'mobx';
import { APP_EVENTS } from 'services/EventsBus/appEvents';
import EventsBus from 'services/EventsBus/eventsBus';

interface IDate {
  from: number;
  to: number;
}

export class FiltersBase {
  constructor(date?: Filters.IDateFilterOption) {
    this.date = date || FiltersBase.initialDate;
  }

  static get initialDate() {
    return {
      from: null,
      to: null,
    };
  }

  @observable query: string = '';
  @observable date: Filters.IDateFilterOption;

  @action setQuery = (query: string) => {
    this.query = query;
    EventsBus.get().trigger(APP_EVENTS.DEVICES.SEARCH);
  };

  @action applyDate = (date: IDate) => (this.date = date);

  @action resetDate = (date?: IDate) => {
    this.date = date ? date : FiltersBase.initialDate;
  };

  @action reset = (date?: IDate) => {
    this.resetDate(date);
    this.query = '';
  };
}

export default FiltersBase;
