import SimpleField from '../Fields/SimpleField';
import AlertIDModel from './AlertID';

export default class AlertPlace extends AlertIDModel<string> {
  type: SimpleField<Alerts.Modal.GeoZone.Type>;

  constructor(place = { id: 'all', name: '', type: 'GEOZONE' }) {
    super(place.id, place.name);
    this.type = new SimpleField(place.type);
  }

  get model() {
    return {
      id: this.id.value,
      name: this.name.value,
      type: this.type.value,
    };
  }

  get isUpdated() {
    return this.id.isUpdated || this.type.isUpdated;
  }

  reset = () => {
    Object.values(this).map((value) => value.reset());
  };
}
