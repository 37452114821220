import React, { Fragment, memo } from 'react';
import { Polyline } from 'react-google-maps';
import type { PolylineProps } from 'react-google-maps/lib/components/Polyline';

import type { timezoneNames } from 'stores';
import WayPoints from '../WayPoints';
import type { Event, Device } from 'models';
import type DevicesFilters from 'stores/Map/DevicesFilters';
import WayPointMarker from '../WayPoints/WayPointMarker';

interface IProps {
  color?: string;
  weight?: number;
  selectedEventIndex?: number;
  locked?: boolean;
  showHistory?: boolean;
  wayPoints?: Event[];
  timezone?: timezoneNames | string;
  onWayPointClick?: (index: number) => void;
  onGeozoneClick?: (geozoneId: string) => void;
  setStreetViewOpened?: () => void;
  onZoomIntoClick?: () => void;
  selectedDevice?: Device;
  filters?: DevicesFilters;
  mapHeading?: number;
  isHeading?: boolean;
  routeReplayPlay?: boolean;
  selectedWayPointEvent?: Event;
}

const VehicleRoute: React.FC<PolylineProps & IProps> = ({
  color,
  onWayPointClick,
  onGeozoneClick,
  path,
  wayPoints,
  timezone,
  setStreetViewOpened,
  onZoomIntoClick,
  selectedEventIndex,
  locked,
  showHistory,
  weight,
  selectedDevice,
  filters,
  mapHeading,
  isHeading,
  routeReplayPlay,
  selectedWayPointEvent,
}) => (
  <Fragment>
    <Polyline
      path={path}
      options={{
        strokeColor: showHistory ? '#ff8801' : color,
        strokeWeight: weight,
      }}
    />
    {Boolean(wayPoints.length) && !routeReplayPlay ? (
      <WayPoints
        wayPoints={wayPoints}
        timezone={timezone}
        setStreetViewOpened={setStreetViewOpened}
        onZoomIntoClick={onZoomIntoClick}
        onClick={onWayPointClick}
        onGeozoneClick={onGeozoneClick}
        selectedEventIndex={selectedEventIndex}
        locked={locked}
        showHistory={showHistory}
        selectedDevice={selectedDevice}
        filters={filters}
        mapHeading={mapHeading}
        isHeading={isHeading}
      />
    ) : (
      Boolean(selectedWayPointEvent) && (
        <WayPointMarker
          wayPoint={selectedWayPointEvent}
          timezone={timezone}
          setStreetViewOpened={setStreetViewOpened}
          onZoomIntoClick={onZoomIntoClick}
          onClick={onWayPointClick}
          onGeozoneClick={onGeozoneClick}
          index={wayPoints.indexOf(selectedWayPointEvent)}
          locked={locked}
          showHistory={showHistory}
          selectedDevice={selectedDevice}
          filters={filters}
          mapHeading={mapHeading}
          isHeading={isHeading}
          hiddenTrackPointCard
        />
      )
    )}
  </Fragment>
);

VehicleRoute.defaultProps = {
  color: '#3c90f4',
  weight: 2,
  wayPoints: [],
};

export default memo(VehicleRoute);
