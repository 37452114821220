import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Collapse } from 'antd';

import validateAccessLevel from 'stores/acl/validator';
import type { MapStore, GoogleMapSearchStore, DevicesStore } from 'stores';
import { KeyboardShortcutsManager } from 'tools';
import { KEY_TYPE, LINKS, WEB_MAP_CLOSEST_VEHICLE_READ } from 'config';

import ClosestVehicles from 'containers/Map/ClosestVehicles';
import LocationPanelActions from 'components/Map/LocationPanel/LocationPanelActions';
import LocationPanelHeader from 'components/Map/LocationPanel/LocationPanelHeader';
import LocationPanelDetails from 'components/Map/LocationPanel/LocationPanelDetails';
import Notification from 'components/Notification';
import StreetView from 'components/Map/StreetView';
import TextLocationModal from 'components/Map/TextLocationModal';
import withCollapseStorage from 'hocs/withCollapseStorage';
import CloseButton from 'components/Button/CloseButton';

import { ChevronIcon } from 'assets';

import './styles.scss';

interface IProps {
  collapseKeys: string[];
  mapSearchStore?: GoogleMapSearchStore;
  mapStore?: MapStore;
  devicesStore?: DevicesStore;
  onClose(): void;
  onCollapseChange(values: string | string[]): void;
}

interface IState {
  textLocation: boolean;
}

enum collapsePanels {
  LocationDetails = 'map-location-details',
  Actions = 'map-location-actions',
  StreetView = 'map-location-street-view',
  ClosestVehicles = 'map-location-closest-vehicles',
}

@inject(({ devicesMapStore: { mapStore, devicesStore, mapSearchStore } }) => ({
  mapStore,
  devicesStore,
  mapSearchStore,
}))
@observer
class LocationPanel extends Component<IProps, IState> {
  private readonly onHandleKeyUp: (event) => void;

  constructor(props) {
    super(props);

    this.state = {
      textLocation: false,
    };

    this.onHandleKeyUp = this.handleKeyUp.bind(this);
  }

  private get googleDirectionToLink() {
    const {
      mapSearchStore: {
        searchResults: { latitude, longitude },
      },
    } = this.props;

    return LINKS.directionTo.replace(/{latitude}/g, String(latitude)).replace(/{longitude}/g, String(longitude));
  }

  private get googleDirectionLink() {
    const {
      mapSearchStore: {
        searchResults: { latitude, longitude },
      },
    } = this.props;

    return LINKS.showInGoogleMaps.replace(/{latitude}/g, String(latitude)).replace(/{longitude}/g, String(longitude));
  }

  private get locationText() {
    return `Location\n${this.googleDirectionLink}`;
  }

  componentDidMount = () => {
    KeyboardShortcutsManager.get().add(this.onHandleKeyUp);
  };

  componentWillUnmount = () => {
    KeyboardShortcutsManager.get().remove(this.onHandleKeyUp);
  };

  handleKeyUp = (event) => {
    const {
      mapStore: { pointGeozoneLocation, togglePointGeozoneLocation },
    } = this.props;
    const { textLocation } = this.state;

    if (event.key === KEY_TYPE.ESCAPE) {
      if (pointGeozoneLocation) {
        togglePointGeozoneLocation(false);
      } else if (!textLocation) {
        this.close();
      }
    }
  };

  openDirections = () => {
    window.open(this.googleDirectionToLink);
  };

  shareLocation = async (phone: string, message: string) => {
    const {
      mapSearchStore: { shareLocation },
    } = this.props;

    await shareLocation({ phone, message });
    this.closeTextLocationModal();
  };

  openStreetView = () => {
    const {
      mapStore: { streetViewOpened, setStreetViewPosition, setStreetViewPov, setStreetViewOpened },
      mapSearchStore: {
        searchResults: { latitude, longitude },
      },
    } = this.props;

    setStreetViewPosition({ lat: latitude, lng: longitude });
    setStreetViewPov({ heading: 0, pitch: 0 });

    if (!streetViewOpened) {
      setStreetViewOpened(true);
    }
  };

  openTextLocationModal = () => this.setState({ textLocation: true });

  closeTextLocationModal = () => this.setState({ textLocation: false });

  close = () => {
    const {
      mapSearchStore: { resetSearch },
    } = this.props;

    resetSearch();
  };

  render() {
    const {
      devicesStore: {
        filters: { date },
      },
      mapStore: { togglePointGeozoneLocation, pointGeozoneLocation },
      mapSearchStore: { searchResults, repositoryNotifications },
      collapseKeys,
      onCollapseChange,
    } = this.props;
    const { textLocation } = this.state;

    return (
      <div className="LocationPanel">
        <div className="LocationPanel-container">
          <div className="LocationPanel-header">
            <div className="LocationPanel-details">
              <LocationPanelHeader
                latitude={searchResults?.latitude}
                longitude={searchResults?.longitude}
                subTitle={searchResults?.meta.additionalAddress}
                title={searchResults?.meta.name}
                type={searchResults?.meta.type}
              />
            </div>
            <div className="LocationPanel-close">
              <CloseButton onClick={this.close} />
            </div>
          </div>
          <div className="LocationPanel-content">
            <Collapse
              activeKey={collapseKeys}
              expandIcon={() => (
                <span className="anticon anticon-right ant-collapse-arrow">
                  <ChevronIcon />
                </span>
              )}
              expandIconPosition="left"
              onChange={onCollapseChange}
            >
              {searchResults?.meta.type === 'poi' && (
                <Collapse.Panel
                  header="Location Details"
                  key={collapsePanels.LocationDetails}
                  className="LocationPanel-block"
                >
                  <LocationPanelDetails
                    category={searchResults?.meta.category}
                    hours={searchResults?.meta.hours}
                    phone={searchResults?.meta.phone}
                    website={searchResults?.meta.website}
                  />
                </Collapse.Panel>
              )}
              <Collapse.Panel header="Actions" key={collapsePanels.Actions} className="LocationPanel-block">
                <LocationPanelActions
                  createGeozone={pointGeozoneLocation}
                  onCreateGeozoneClick={togglePointGeozoneLocation}
                  onDirectionsClick={this.openDirections}
                  onTextLocationClick={this.openTextLocationModal}
                />
              </Collapse.Panel>
              <Collapse.Panel
                header="Street View"
                key={collapsePanels.StreetView}
                className="LocationPanel-block LocationPanel-blockNoMargin"
              >
                <StreetView
                  address={searchResults?.address}
                  latitude={String(searchResults?.latitude)}
                  longitude={String(searchResults?.longitude)}
                  heading={0}
                  setStreetViewOpened={this.openStreetView}
                />
              </Collapse.Panel>
              {validateAccessLevel([WEB_MAP_CLOSEST_VEHICLE_READ]) && (
                <Collapse.Panel
                  header="Closest Vehicles"
                  key={collapsePanels.ClosestVehicles}
                  className="LocationPanel-block"
                >
                  <ClosestVehicles
                    latitude={String(searchResults?.latitude)}
                    longitude={String(searchResults?.longitude)}
                    timestamp={date.to}
                  />
                </Collapse.Panel>
              )}
            </Collapse>
          </div>
        </div>

        <TextLocationModal
          isOpen={textLocation}
          onCancel={this.closeTextLocationModal}
          onSubmit={this.shareLocation}
          text={this.locationText}
          type=""
        />
        {repositoryNotifications.createState.success && (
          <Notification
            onClose={repositoryNotifications.createState.reset}
            text="The text message was successfully sent."
            title="Success!"
            type="success"
          />
        )}
        {repositoryNotifications.createState.error && (
          <Notification
            onClose={repositoryNotifications.createState.reset}
            text="The text message failed to send. Try again."
            title="Send Message Failed"
            type="error"
          />
        )}
      </div>
    );
  }
}

export default withCollapseStorage(LocationPanel, 'map-vehicle-panel-collapse', Object.values(collapsePanels));
