import type TableToggleFilter from 'models/Tables/TableToggleFilter';
import DueIn from './DueIn';
import Frequency from './Frequency';

export default class Filters {
  dueIn: DueIn;
  frequency: Frequency;

  constructor({ dueIn, frequency }: { dueIn?: string; frequency?: string }) {
    this.dueIn = new DueIn();
    this.frequency = new Frequency();
    this.init(this.dueIn.list, dueIn);
    this.init(this.frequency.list, frequency);
  }

  init = (filters: TableToggleFilter[] = [], savedValues: string = '') => {
    const arr = savedValues.split(',');

    filters.forEach((filter) => {
      if (arr.includes(filter.value)) {
        filter.isActive.toggle(true);
      }
    });
  };
}
