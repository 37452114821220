import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { repositoryService } from 'services';
import { PATHS, ERROR_MESSAGES, DEFAULT_GEOZONE_RADIUS } from 'config';
import { getPolygonFromPoint, latLngToServerCoordinates } from 'utils';
import { geozoneColors, GeozonesAdmin } from 'stores/Admin/Geozones';
import type { RouterStore } from 'stores/RouterStore';

import CreateGeozoneModal from 'components/CreateGeozoneModal';
import CreateGeozoneConfirmationModal from 'components/CreateGeozoneModal/ConfirmationModal';
import type Repository from 'services/RepositoryService/Repository';

interface IProps {
  className?: string;
  geozonesAdmin?: GeozonesAdmin;
  geozoneColor?: Geozone.IGeozoneColor;
  geozoneId?: string | number;
  geozoneLocation?: {
    address?: string;
    latitude?: number;
    longitude?: number;
  };
  geozoneName?: string;
  geozoneType?: string;
  isOpen?: boolean;
  onCancel?: () => void;
  onCreate?: () => void;
  routerStore?: RouterStore;
}

interface IState {
  isConfirmationModalOpen: boolean;
  geozoneToCreate: Geozone.IServerGeozone;
  isLoading?: boolean;
}

@inject(({ adminStore: { geozonesAdmin }, routerStore }) => ({
  geozonesAdmin,
  routerStore,
}))
@observer
class CreateGeozone extends Component<IProps, IState> {
  state = {
    isConfirmationModalOpen: false,
    geozoneToCreate: { name: '' },
    isLoading: false,
  };

  repositoryGeozone: Repository;

  constructor(props) {
    super(props);

    this.repositoryGeozone = repositoryService.get('geozones');
  }

  getServerGeozone = (geozoneForm) => {
    const radius = geozoneForm.type === 'circle' ? { radius: DEFAULT_GEOZONE_RADIUS } : {};
    const coordinates =
      geozoneForm.type === 'circle'
        ? [
            {
              index: 0,
              latitude: geozoneForm.location.latitude,
              longitude: geozoneForm.location.longitude,
            },
          ]
        : latLngToServerCoordinates(
            getPolygonFromPoint(
              { lat: geozoneForm.location.latitude, lng: geozoneForm.location.longitude },
              DEFAULT_GEOZONE_RADIUS
            )
          );
    return {
      name: geozoneForm.name,
      type: geozoneForm.type.toUpperCase(),
      colorId: geozoneForm.color.id,
      ...radius,
      dispatchMarkerAddress: geozoneForm.location.address,
      dispatchMarkerLatitude: geozoneForm.location.latitude,
      dispatchMarkerLongitude: geozoneForm.location.longitude,
      coordinates,
    };
  };

  redirectToCreatedGeozone = (geozoneId: string) => {
    const {
      geozonesAdmin: { setSelectedGeozoneTabIndex },
      routerStore: {
        history: { push },
      },
    } = this.props;

    setSelectedGeozoneTabIndex(0);
    push(PATHS.ADMIN.TABLES.GEOZONES.GEOZONE.replace(':id', geozoneId));
  };

  setGeozoneToCreate = (geozoneForm) => {
    this.setState({
      geozoneToCreate: this.getServerGeozone(geozoneForm),
    });
  };

  setLoading = (isLoading: boolean) => {
    this.setState({ isLoading });
  };

  openConfirmationModal = () => {
    this.setState({ isConfirmationModalOpen: true });
  };

  cancelConfirmationModal = () => {
    this.setState({ isConfirmationModalOpen: false });
  };

  handleFormSubmit = async (geozoneForm) => {
    const { onCancel } = this.props;
    this.setLoading(true);

    try {
      const geozone = await this.repositoryGeozone.create(
        { ...this.getServerGeozone(geozoneForm) },
        {},
        { isDuplicatedNamesAllowed: false }
      );

      this.redirectToCreatedGeozone(geozone.id);
    } catch (_) {
      const errorMessage = new Error(this.repositoryGeozone.createState.error).message.replace('Error: ', '');

      if (errorMessage === ERROR_MESSAGES.GEOZONES.ALREADY_EXIST) {
        this.setGeozoneToCreate(geozoneForm);
        this.openConfirmationModal();
      }
    } finally {
      this.setLoading(false);
      onCancel();
    }
  };

  handleConfirmationSubmit = async () => {
    const { geozoneToCreate } = this.state;
    this.setLoading(true);

    try {
      const geozone = await this.repositoryGeozone.create(
        { ...geozoneToCreate },
        {},
        { isDuplicatedNamesAllowed: true }
      );

      this.cancelConfirmationModal();
      this.redirectToCreatedGeozone(geozone.id);
    } catch (e) {
      this.setLoading(false);
      throw new Error(e);
    }
  };

  render() {
    const { isOpen, onCancel, geozoneName, geozoneColor, geozoneType, geozoneLocation, geozoneId } = this.props;
    const { isConfirmationModalOpen, geozoneToCreate, isLoading } = this.state;

    return (
      <>
        <CreateGeozoneModal
          geozoneColor={geozoneColor || geozoneColors.find(({ id }) => id === 3)}
          geozoneId={geozoneId}
          geozoneLocation={{
            address: geozoneLocation?.address,
            latitude: geozoneLocation?.latitude,
            longitude: geozoneLocation?.longitude,
          }}
          geozoneName={geozoneName}
          geozoneType={geozoneType || 'polygon'}
          isDisabled={isLoading}
          isOpen={isOpen}
          onCancel={onCancel}
          onSubmit={this.handleFormSubmit}
        />
        <CreateGeozoneConfirmationModal
          geozoneName={geozoneToCreate?.name}
          isDisabled={isLoading}
          isOpen={isConfirmationModalOpen}
          onCancel={this.cancelConfirmationModal}
          onSubmit={this.handleConfirmationSubmit}
        />
      </>
    );
  }
}

export default CreateGeozone;
