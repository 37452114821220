import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import type { CustomersStore } from 'stores';

import SecondaryPanelEstimateQB from './SecondaryPanelEstimateQB';
import SecondaryPanelEstimateST from './SecondaryPanelEstimateST';

import './styles.scss';

interface IMatchParams {
  estimateId: string;
}

interface IProps extends RouteComponentProps<IMatchParams>{
  customersStore: CustomersStore;
  collapseKeys: string[];

  onCollapseChange(values: string | string[]): void;
}


@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class SecondaryPanelEstimate extends Component<IProps> {

  get selectedCustomer () {
    const {
      customersStore: {
        selectedCustomer,
      },
    } = this.props;
    return selectedCustomer;
  }

  componentDidMount() {
    const {
      match: {
        params: { estimateId },
      },
    } = this.props;
    this.selectedCustomer?.selectedEstimateId?.set?.(estimateId);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { estimateId },
      },
    } = this.props;
    if (estimateId !== prevProps?.match?.params?.estimateId) {
      this.selectedCustomer?.selectedEstimateId?.set?.(estimateId);
    }
  }

  componentWillUnmount() {
    this.selectedCustomer?.selectedEstimateId?.reset?.();
  }

  render() {
    const selectedCustomer = this.selectedCustomer;

    if (!selectedCustomer.selectedEstimate) {
      return null;
    }

    return <>
      {selectedCustomer.domain === 'QUICK_BOOKS' && <SecondaryPanelEstimateQB />}
      {selectedCustomer.domain === 'SERVICE_TITAN' && <SecondaryPanelEstimateST />}
    </>;
  }
}

export default withRouter(SecondaryPanelEstimate);
