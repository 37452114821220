import React, { useEffect } from 'react';

import { QB_INTEGRATION_SUCCESS_CONNECT_EVENT } from 'config';

import Button from 'components/Button';

export const IntegrationsConnectingQBSuccess = () => {

  useEffect(() => {
    window.localStorage.setItem(QB_INTEGRATION_SUCCESS_CONNECT_EVENT, String(Date.now()));
  }, [])

  const handleCLick = () => {
    window.close();
  };

  return (<div className="IntegrationsConnecting-Success">
    <div className="IntegrationsConnecting-SuccessDescription">
      Quickbooks Successfully Connected
    </div>
    <div className="IntegrationsConnecting-SuccessActions">
      <Button title="Done" className="Integration-ItemActionButton" inline onClick={handleCLick} />
    </div>
  </div>);
};

export default IntegrationsConnectingQBSuccess;
