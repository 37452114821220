import React, { FC } from 'react';
import classNames from 'classnames';

import Button from 'components/Button';
import Modal from 'components/Modal';

import './styles.scss';

interface IProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const ChangesConfirmationModal: FC<IProps> = ({ className, isOpen, onClose, onSubmit }) => {
  const cn = classNames('ChangesConfirmationModal', className);

  return (
    <Modal
      className="Modal--changesConfirmationModal"
      closeOutside={false}
      isModalOpen={isOpen}
      onClose={onClose}
      withoutCloseIcon
    >
      <div className={cn}>
        <p className="ChangesConfirmationModal-title">Continue Without Saving?</p>
        <p className="ChangesConfirmationModal-message">Changes made to this ClearShare Link will not be saved.</p>
        <div className="ChangesConfirmationModal-buttons">
          <div className="ChangesConfirmationModal-button ChangesConfirmationModal-button--cancel">
            <Button className="Button--cancel" title="No, Cancel" onClick={onClose} />
          </div>
          <div className="ChangesConfirmationModal-button ChangesConfirmationModal-button--apply">
            <Button className="Button--apply" title="Yes, Continue" onClick={onSubmit} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangesConfirmationModal;
