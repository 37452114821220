import MediaDateOptionRange from './MediaDateOptionRange';
import MediaDateOptionProperty from './MediaDateOptionProperty';
import { MEDIA_LIBRARY_DATE_RANGE_OPTION_TYPE } from './MediaDateRangeOptionsConfig';

export default class MediaDateOptionFactory {
  private readonly type: MEDIA_LIBRARY_DATE_RANGE_OPTION_TYPE;

  constructor(type) {
    this.type = type;
  }

  get = (option) => {
    switch (this.type) {
      case MEDIA_LIBRARY_DATE_RANGE_OPTION_TYPE.RANGE:
        return new MediaDateOptionRange(option);
      case MEDIA_LIBRARY_DATE_RANGE_OPTION_TYPE.PROPERTY:
        return new MediaDateOptionProperty(option);
    }
  };
}
