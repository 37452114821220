import { computed } from 'mobx';
import { getTimeInTimeZone } from 'utils';
import timeStore from 'stores/TimeStore';
import { DATE_TIME_FORMATS } from 'config';

class MediaInfoDate {
  value: number;

  constructor(value = 0) {
    this.value = value;
  }

  @computed get monthDateYear() {
    return getTimeInTimeZone(this.value, timeStore.sessionTimezone).format(DATE_TIME_FORMATS.monthDatYearFull);
  }

  @computed get hourMinuteAm() {
    return getTimeInTimeZone(this.value, timeStore.sessionTimezone).format(DATE_TIME_FORMATS.hoursFullMinutesSpaceAm);
  }

  @computed get hourMinuteSecondAm() {
    return getTimeInTimeZone(this.value, timeStore.sessionTimezone).format(DATE_TIME_FORMATS.hoursMinutesSecondsAm);
  }

  @computed get mediaClip() {
    return getTimeInTimeZone(this.value, timeStore.sessionTimezone).format(DATE_TIME_FORMATS.mediaClipVideo);
  }
}

export default MediaInfoDate;
