import React, { Component } from 'react';
import classNames from 'classnames';

import { SearchIcon, CloseIcon } from 'assets';

import './styles.scss';

interface IProps {
  className?: string;
  isDisabled?: boolean;
  initialActiveStatus?: boolean;
  value?: string;
  inline?: boolean;
  onChange?: (query: string) => void;
  placeholder?: string;
}

interface IState {
  isActive: boolean;
}

class SearchFilter extends Component<IProps, IState> {
  static defaultProps = {
    initialActiveStatus: false,
    onChange: () => true,
    placeholder: 'Search by name',
  };

  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    const { initialActiveStatus } = this.props;

    this.state = {
      isActive: initialActiveStatus,
    };

    this.inputRef = React.createRef();
  }

  handleChange = (value) => {
    const { onChange } = this.props;

    onChange(value);
  };

  setActive = () => {
    this.setState({
      isActive: true,
    });
  };

  setInactive = () => {
    this.setState({
      isActive: false,
    });
  };

  handleOpen = () => {
    this.setActive();

    this.inputRef.current.focus();
  };

  handleBlur = () => {
    const { value } = this.props;

    if (!value) {
      this.setInactive();
    }
  };

  handleClose = () => {
    const { onChange } = this.props;

    onChange('');
    this.setInactive();
  };

  render() {
    const { isActive } = this.state;
    const { className, inline, placeholder, value, isDisabled } = this.props;
    const cn = classNames('SearchFilter', className, {
      'SearchFilter--inline': Boolean(inline),
      'SearchFilter--disabled': Boolean(isDisabled),
      'SearchFilter--active': Boolean(isActive || value),
    });

    return (
      <div className={cn}>
        <div className="SearchFilter-inputField">
          <div className="SearchFilter-searchIcon" onClick={this.handleOpen}>
            <SearchIcon width="14px" height="14px" fill="#6B7A99" />
          </div>
          <input
            className="SearchFilter-input"
            onBlur={this.handleBlur}
            onChange={(e) => this.handleChange(e.target.value)}
            onFocus={this.setActive}
            placeholder={placeholder}
            ref={this.inputRef}
            type="text"
            value={value}
          />
          {Boolean(value) && (
            <div className="SearchFilter-closeIcon" onMouseDown={this.handleClose}>
              <CloseIcon width="10px" height="10px" fill="#48515d" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SearchFilter;
