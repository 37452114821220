import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Collapse } from 'antd';

import type { Maintenances as MaintenancesStore, Inspections as InspectionsStore } from 'stores';
import { INSPECTIONS_STORE, MAINTENANCES_STORE } from 'config';
import withCollapseStorage from 'hocs/withCollapseStorage';
import ConfirmationModal from 'components/Admin/ConfirmationModal';
import InspectionPerformedPanelActions from 'components/Inspections/InspectionPerformedPanel/InspectionPerformedPanelActions';
import InspectionPerformedPanelItems from 'components/Inspections/InspectionPerformedPanel/InspectionPerformedPaneltems';
import InspectionPerformedPanelSummary from 'components/Inspections/InspectionPerformedPanel/InspectionPerformedPanelSummary';
import InspectionPerformedPanelTitle from 'components/Inspections/InspectionPerformedPanel/InspectionPerformedPanelTitle';
import MaintenanceTaskModal from 'components/Maintenance/TaskModal';
import Modal from 'components/Modal';
import Notification from 'components/Notification';
import SideModal from 'components/SideModal';

import { ChevronIcon } from 'assets';
import './styles.scss';

interface IProps {
  collapseKeys: string[];
  isOpen: boolean;
  onClose: () => void;
  onCollapseChange(values: string | string[]): void;
  [INSPECTIONS_STORE]?: InspectionsStore;
  [MAINTENANCES_STORE]?: MaintenancesStore;
}

interface IState {
  createTaskModalOpen: boolean;
  confirmationModalOpen: boolean;
}

enum collapsePanels {
  Summary = 'inspection-performed-summary',
  Actions = 'inspection-performed-actions',
  InspectedItems = 'inspection-performed-items',
}

@inject(INSPECTIONS_STORE, MAINTENANCES_STORE)
@observer
class InspectionPerformedPanel extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      createTaskModalOpen: false,
      confirmationModalOpen: false,
    };
  }

  openCreateTaskModal = (itemId: number) => {
    const {
      maintenancesStore: { create },
      inspectionsStore: {
        performed: { selected },
      },
    } = this.props;
    const item = selected.value.form.items.findItemById(itemId);

    create({
      asset: selected.value.asset.data,
      name: item.value.name.value,
      documents: item.value.attachments.map((attachment) => ({
        id: attachment.model.id,
        displayName: attachment.model.displayName || `Attachment_${attachment.model.id}`,
        name: attachment.model.displayName,
        url: attachment.model.url.value,
        type: attachment.model.type,
      })),
      notes: item.value.notes,
    });
    this.setState({ createTaskModalOpen: true });
  };

  closeCreateModal = () => {
    this.setState({ createTaskModalOpen: false });
  };

  tryCloseCreateModal = () => {
    const {
      maintenancesStore: { task },
    } = this.props;

    if (task && task.model.documents.list.length) {
      this.setState({ confirmationModalOpen: true });
    }

    this.closeCreateModal();
  };

  closeConfirmationModal = () => {
    this.setState({ confirmationModalOpen: false });
  };

  cancelConfirmationModal = () => {
    this.setState({ createTaskModalOpen: true });
    this.closeConfirmationModal();
  };

  render() {
    const {
      collapseKeys,
      isOpen,
      onClose,
      onCollapseChange,
      inspectionsStore: { performed },
      maintenancesStore: { task },
    } = this.props;
    const { createTaskModalOpen, confirmationModalOpen } = this.state;
    const createTaskState = task?.model.repository.createState;

    return (
      <>
        <SideModal
          visible={isOpen}
          setVisible={onClose}
          className="InspectionPerformedPanel"
          title={<InspectionPerformedPanelTitle date={performed.selected.value?.loggerInfo.creationTime.date} />}
          width={400}
          isHeaderFixed
        >
          {Boolean(performed.selected.value) && (
            <div className="InspectionPerformedPanel-content">
              <Collapse
                activeKey={collapseKeys}
                expandIcon={() => (
                  <span className="anticon anticon-left ant-collapse-arrow">
                    <ChevronIcon />
                  </span>
                )}
                expandIconPosition="left"
                onChange={onCollapseChange}
              >
                <Collapse.Panel
                  header="Summary"
                  key={collapsePanels.Summary}
                  className="InspectionPerformedPanel-panel"
                >
                  <InspectionPerformedPanelSummary inspectionPerformed={performed.selected.value} />
                </Collapse.Panel>
              </Collapse>
              <Collapse
                activeKey={collapseKeys}
                expandIcon={() => (
                  <span className="anticon anticon-left ant-collapse-arrow">
                    <ChevronIcon />
                  </span>
                )}
                expandIconPosition="left"
                onChange={onCollapseChange}
              >
                <Collapse.Panel
                  header="Actions"
                  key={collapsePanels.Actions}
                  className="InspectionPerformedPanel-panel"
                >
                  <InspectionPerformedPanelActions inspectionPerformed={performed.selected.value} />
                </Collapse.Panel>
              </Collapse>
              <Collapse
                activeKey={collapseKeys}
                expandIcon={() => (
                  <span className="anticon anticon-left ant-collapse-arrow">
                    <ChevronIcon />
                  </span>
                )}
                expandIconPosition="left"
                onChange={onCollapseChange}
              >
                <Collapse.Panel
                  header="Inspected Items"
                  key={collapsePanels.InspectedItems}
                  className="InspectionPerformedPanel-panel InspectionPerformedPanel-panel--items"
                >
                  <InspectionPerformedPanelItems
                    inspectionPerformed={performed.selected.value}
                    onCreateTask={this.openCreateTaskModal}
                  />
                </Collapse.Panel>
              </Collapse>
            </div>
          )}
        </SideModal>
        <Modal isModalOpen={createTaskModalOpen} onClose={this.tryCloseCreateModal} className="Maintenance-taskModal">
          {task && (
            <MaintenanceTaskModal
              title="Create Maintenance Task"
              closeModal={this.closeCreateModal}
              tryCloseModal={this.tryCloseCreateModal}
              task={task}
              onSave={task.model.save}
              footerRight
            />
          )}
        </Modal>
        <ConfirmationModal
          className="Maintenance-exitingConfirmation"
          title="Cancel Create Task?"
          applyButtonTitle="Yes, Continue"
          cancelButtonTitle="No, Go Back"
          cancelButtonStyles={{ border: 'none' }}
          isOpen={confirmationModalOpen}
          onCancel={this.cancelConfirmationModal}
          onSubmit={this.closeConfirmationModal}
        >
          <div className="Maintenance-exitingConfirmationContent">
            <p>This task has attachments that will be removed from the system if you proceed.</p>
            <p className="Maintenance-exitingConfirmationContentQuestion">Do you want to continue?</p>
          </div>
        </ConfirmationModal>
        {createTaskState?.success && (
          <Notification
            onClose={createTaskState.reset}
            text={`${task.model.asset.name.value}: ${task.model.service.type.item.name}`}
            title="Maintenance Task Created"
            type="success"
          />
        )}
        {createTaskState?.error && (
          <Notification
            onClose={createTaskState.reset}
            text="Maintenance Task was unable to be created"
            title="Create Task Failed"
            type="error"
            onRetry={task.model.save}
          />
        )}
      </>
    );
  }
}

export default withCollapseStorage(
  InspectionPerformedPanel,
  'inspection-performed-panel',
  Object.values(collapsePanels)
);
