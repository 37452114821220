import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import PersonPermissionsDriverModel from './PersonPermissions/PersonPermissionsDriverModel';
import PersonPermissionsRestrictVehicleAccessModel from './PersonPermissions/PersonPermissionsRestrictVehicleAccessModel';
import PersonPermissionsVehicleInspectorModel from './PersonPermissions/PersonPermissionsVehicleInspectorModel';
import { repositoryService } from 'services';
import { PersonPermissionsDriverError } from 'interfaces/models/IPeopleAdmin/IPerson';
import { SimpleField } from 'models/Fields';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import PersonPermissionsDashcamMediaAccess from './PersonPermissions/PersonPermissionsDashcamMediaAccess';

class PersonPermissionsModel {
  driver: PersonPermissionsDriverModel;
  dashcamMediaAccess: PersonPermissionsDashcamMediaAccess;
  vehicleInspector: PersonPermissionsVehicleInspectorModel;
  restrictVehicleAccess: PersonPermissionsRestrictVehicleAccessModel;
  repositoryAssets: IRepository;
  repositoryAssetId: SimpleField<IEntityRepository>;

  constructor(permissions) {
    this.driver = new PersonPermissionsDriverModel(permissions.driverDetails);
    this.dashcamMediaAccess = new PersonPermissionsDashcamMediaAccess(permissions.dashcamDetails);
    this.vehicleInspector = new PersonPermissionsVehicleInspectorModel(permissions.inspectorDetails);
    this.restrictVehicleAccess = new PersonPermissionsRestrictVehicleAccessModel(permissions.groupRestrictions);
    this.repositoryAssets = repositoryService.get('assets');
    this.repositoryAssetId = new SimpleField(null);
  }

  get data() {
    return {
      driverDetails: this.driver.data,
      dashcamDetails: this.dashcamMediaAccess.data,
      inspectorDetails: this.vehicleInspector.data,
      groupRestrictions: this.restrictVehicleAccess.data,
    };
  }

  get isUpdated() {
    return (
      this.driver.isUpdated ||
      this.dashcamMediaAccess.isUpdated ||
      this.vehicleInspector.isUpdated ||
      this.restrictVehicleAccess.isUpdated
    );
  }

  validateAsync = async () => {
    if (this.driver.assignedVehicle.id.value && !this.restrictVehicleAccess.groups.isEmpty) {
      this.repositoryAssetId.set(this.repositoryAssets.entity(this.driver.assignedVehicle.id.value));

      const response = await this.repositoryAssetId.value.get();

      if (this.isAssetExistInRestrictGroups(response.assetGroups)) {
        this.driver.errorField.set(null);
        this.driver.isShowErrorPopup.toggle(false);
      } else {
        this.driver.errorField.set(PersonPermissionsDriverError.AssignVehicle);
        this.driver.isShowErrorPopup.toggle(true);
      }
    }
  };

  reset = () => {
    this.driver.reset();
    this.dashcamMediaAccess.reset();
    this.vehicleInspector.reset();
    this.restrictVehicleAccess.reset();
  };

  private isAssetExistInRestrictGroups = (assetGroups) => {
    return this.restrictVehicleAccess.groups.toArray().some((restrictGroup) => {
      return Boolean(assetGroups.find((assetGroup) => assetGroup.id.toString() === restrictGroup.groupId.toString()));
    });
  };
}

export default PersonPermissionsModel;
