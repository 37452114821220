import React from 'react';

import Button from 'components/Button';
import { TimeEventIcon } from 'assets';
import { validateAccessLevel } from 'stores';
import { WEB_REPORTS_SCHEDULED_REPORTS_READ } from 'config';

import './styles.scss';

interface IProps {
  onClick: () => void;
  onScheduledClick: () => void;
  disabledRun: boolean;
}

const ReportRunReport: React.FC<IProps> = ({ onClick, onScheduledClick, disabledRun }) => {
  return (
    <div className="ReportRunReport">
      {validateAccessLevel([WEB_REPORTS_SCHEDULED_REPORTS_READ]) && (
        <Button
          title="Schedule Report"
          className="ReportRunReport-scheduleReport Button--link"
          IconComponent={<TimeEventIcon />}
          onClick={onScheduledClick}
        />
      )}
      <Button title={`Run Report`} className={`ReportRunReport-button`} onClick={onClick} disabled={disabledRun} />
    </div>
  );
};

export default ReportRunReport;
