import React, { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { isToday } from 'utils';
import Calendar from 'components/Calendar';
import Popup from 'components/Popup';
import type { FieldProps } from 'formik';

import './styles.scss';

interface IProps extends FieldProps {
  appliedDate: Date;
  className?: string;
  onApply: (date: Date) => void;
  onCancel?: () => void;
  icon?: React.ReactElement;
}

const DatePicker: React.FC<IProps> = ({ appliedDate, className, onApply, field, icon }) => {
  const [isOpen, setOpen] = useState(false);
  const cn = classNames('DatePicker', className);
  const iconCn = classNames('DatePicker-titleIcon', { 'DatePicker-titleIcon--open': isOpen });
  const dateText = isToday(appliedDate.getTime()) ? 'Today' : moment(appliedDate).format('MM/DD/YYYY');

  const handleApplyDate = (date) => {
    onApply(date);

    if (field) {
      field.onChange({
        target: {
          name: field.name,
          value: date,
        },
      });
    }
    setOpen(false);
  };

  const handleOpen = () => setOpen(true);

  return (
    <Popup
      isOpen={isOpen}
      onOpen={handleOpen}
      triggerElement={
        <div className={cn}>
          <p className="DatePicker-label">Date</p>
          <div className="DatePicker-title">
            <span className="DatePicker-titleText">{dateText}</span>
            <div className={iconCn}>{Boolean(icon) && icon}</div>
          </div>
        </div>
      }
    >
      <div className="DatePicker-content">
        <Calendar
          disabledDays={[{ before: new Date(), after: undefined }]}
          onClick={handleApplyDate}
          selectedDate={appliedDate}
          withMonthYearForm
        />
      </div>
    </Popup>
  );
};

DatePicker.defaultProps = {
  onApply: () => true,
  onCancel: () => true,
  // position: 'bottom left',
};

export default DatePicker;
