import React from 'react';

import AlertPreferenceFinalizeSimpleLine from '../AlertPreferenceFinalizeSimpleLine';

import './styles.scss';

interface IProps {
  text: {
    basedOn: string;
    when: string;
  };
}

const AlertPreferenceFinalizeMaintenanceLine: React.FC<IProps> = ({ text }) => {
  return (
    <>
      <AlertPreferenceFinalizeSimpleLine label="Based On" value={text.basedOn} />
      <AlertPreferenceFinalizeSimpleLine
        label="Alert When"
        value={
          <span
            className="AlertPreferenceFinalizeMaintenanceLine-when"
            dangerouslySetInnerHTML={{ __html: text.when }}
          />
        }
      />
    </>
  );
};

export default AlertPreferenceFinalizeMaintenanceLine;
