import { SimpleField } from 'models/Fields';

import { repositoryService } from 'services';
import userStore from 'stores/UserStore';

import Integration from './Integration';
import type { IWEXIntegrationModel } from 'interfaces/stores/Integrations/IIntegration';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';

class WEXIntegrationModel extends Integration implements IWEXIntegrationModel {
  repository: IRepository;
  repositoryEntityAuth: IEntityRepository;
  repositoryEntityAuthConnect: IEntityRepository;
  repositoryEntityAuthDisconnect: IEntityRepository;

  accountName: string;
  id: number;
  username: SimpleField<string>;
  password: SimpleField<string>;
  host: SimpleField<string>;

  constructor(props) {
    super(props);

    this.accountName = props.username;
    this.id = props.id || 0;
    this.username = new SimpleField('');
    this.password = new SimpleField('');
    this.host = new SimpleField('');

    this.repository = repositoryService.get('integrations');
    this.repositoryEntityAuth = this.repository.entity('fuel-cards').entity('WEX');
    this.repositoryEntityAuthConnect = this.repositoryEntityAuth.entity('connect');
    this.repositoryEntityAuthDisconnect = this.repositoryEntityAuth.entity('disconnect').entity(this.id.toString());
  }

  connect = async () => {
    await this.repositoryEntityAuthConnect.create({
      username: this.username.value,
      password: this.password.value,
      host: this.host.value,
    });
  };

  reconnect = async () => {
    await this.connect();
  };

  disconnect = async () => {
    await this.repositoryEntityAuthDisconnect.delete();
  };

  update = async () => {
    await this.context?.loadAllIntegrations();
    await userStore.setCurrentUserACLs();
  };
}

export default WEXIntegrationModel;
