import { computed } from 'mobx';

import type MaintenanceConfig from '../Models/MaintenanceConfig';
import timeStore from 'stores/TimeStore';
import { DATE_TIME_FORMATS, MS_IN_ONE_DAY } from 'config';
import { getFormattedTime, pluralize } from 'utils';
import type { SimpleField } from 'models/Fields';

export default class DueInCellRow {
  dueIn: number;
  dueInDays: number;
  dueSoon: SimpleField<number>;
  format: string;
  next: number;
  unit: { full: string; short: string; maximumFractionDigits: number };
  value: string;
  visible: boolean;

  constructor({
    config,
    dueInDays,
    dueSoon,
    format,
    value,
  }: {
    config: MaintenanceConfig;
    dueInDays: number;
    dueSoon: SimpleField<number>;
    format: string;
    value: string;
  }) {
    this.dueIn = Number(config.dueIn.value);
    this.dueInDays = dueInDays;
    this.dueSoon = dueSoon;
    this.format = format;
    this.next = Number(config.next.value);
    this.unit = config.unit;
    this.value = value;
    this.visible = Boolean(config.next.value);
  }

  @computed get textDueIn() {
    switch (this.format) {
      case 'date':
        return `${pluralize(Math.trunc(this.dueIn / MS_IN_ONE_DAY), this.unit.full)}`;
      case 'text':
        return `${this.dueIn.toLocaleString('en-US', {
          maximumFractionDigits: this.unit.maximumFractionDigits,
        })} ${this.dueIn !== 1 ? `${this.unit.full}s` : this.unit.full}`;
      default:
        return '-';
    }
  }

  @computed get textNext() {
    switch (this.format) {
      case 'date':
        return `${getFormattedTime(this.next, DATE_TIME_FORMATS.monthDatYearFull, timeStore.sessionTimezone)}`;
      case 'text':
        return `${this.next.toLocaleString('en-US', {
          maximumFractionDigits: this.unit.maximumFractionDigits,
        })} ${this.unit.short}`;
      default:
        return '-';
    }
  }

  @computed get textDueInColor() {
    if (this.dueIn < 0) {
      return 'red';
    } else if (this.dueInDays >= 0 && this.dueInDays < this.dueSoon.value) {
      return 'orange';
    } else {
      return '';
    }
  }
}
