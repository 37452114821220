export const getTextWidth = (text) => {
  const el = document.createElement('div');
  el.innerHTML = text;
  el.style.position = 'absolute';
  el.style.whiteSpace = 'nowrap';
  el.style.font = 'inherit';
  el.style.fontSize = '12px';
  el.style.padding = '10px 16px';
  document.body.appendChild(el);
  const w = el.clientWidth;
  el.remove();
  return w;
};

export const objectKeysToLowerCase = (item: {}) => {
  return Object.entries(item).reduce((acc, [key, value]) => {
    return {
      [key.toLowerCase()]: value,
      ...acc,
    };
  }, {});
};

export const hasEntries = (value: {} = {}) => {
  return value !== null && value !== undefined && Object.entries(value).length > 0;
};
