import { computed } from 'mobx';
import { SimpleField } from '../../Fields';

class DueValue {
  miles: SimpleField<number>;
  hours: SimpleField<number>;
  days: SimpleField<number>;
  avgDaysBefore: SimpleField<number>;

  constructor(props) {
    this.miles = new SimpleField<number>(props.miles || 500);
    this.hours = new SimpleField<number>(props.hours || 10);
    this.days = new SimpleField<number>(props.days || 10);
    this.avgDaysBefore = new SimpleField<number>(props.avgDaysBefore || 10);
  }

  @computed get isUpdated() {
    return this.miles.isUpdated || this.hours.isUpdated || this.days.isUpdated || this.avgDaysBefore.isUpdated;
  }

  @computed get value() {
    return {
      miles: this.miles.value,
      hours: this.hours.value,
      days: this.days.value,
      avgDaysBefore: this.avgDaysBefore.value,
    };
  }
}

export default DueValue;
