import { DASHCAMS_SETTINGS_TYPES } from 'config';
import { DashcamSettingToggle } from './DashcamSettingToggle';
import { DashcamSettingRadio } from './DashcamSettingRadio';
import { DashcamSettingNumber } from './DashcamSettingNumber';
import { DashcamSetting } from './DashcamSetting';
import type { IDashcamSettingsField } from 'interfaces/models/Dashcams/IDashcamSettingsField';
import type { DashcamSettings } from './DashcamSettings';

export class DashcamSettingFactory {
  context: DashcamSettings;
  private readonly setting;
  private level: number = 0;
  private parent: string = '';

  constructor(context, setting, level: number = 0, parent = '') {
    this.context = context;
    this.setting = setting;
    this.level = level;
    this.parent = parent;
  }

  get = (): IDashcamSettingsField<any> => {
    switch (this.setting.type) {
      case DASHCAMS_SETTINGS_TYPES.TOGGLE:
        return new DashcamSettingToggle(this.context, this.setting, this.level, this.parent);
      case DASHCAMS_SETTINGS_TYPES.ENUM:
        return new DashcamSettingRadio(this.context, this.setting, this.level);
      case DASHCAMS_SETTINGS_TYPES.NUMBER:
        return new DashcamSettingNumber(this.context, this.setting, this.level);
      default:
        return new DashcamSetting(this.context, { ...this.setting, level: this.level });
    }
  };
}
