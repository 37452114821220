import React from 'react';
import { observer } from 'mobx-react';

import type { SimpleField } from 'models/Fields';

import { InputNumber } from 'antd';

import './styles.scss';

interface IProps {
  disabled?: boolean;
  title: string;
  max?: number;
  min?: number;
  field: SimpleField<number>;
}

const AlertPreferencesOptionItem: React.FC<IProps> = ({ disabled = false, title, max, min, field }) => {
  const handleChange = (value) => {
    field.set(value);
  };

  return (
    <div className="AlertPreferencesOptionItem">
      <div className="AlertPreferencesOptionItem-input">
        <InputNumber value={field.value} max={max} min={min} disabled={disabled} onChange={handleChange} />
      </div>
      <div className="AlertPreferencesOptionItem-title">{title}</div>
    </div>
  );
};

export default observer(AlertPreferencesOptionItem);
