import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import classnames from 'classnames';
import { some } from 'lodash';

import {
  AlertsTableStore,
  Maintenances as MaintenancesStore,
  RouterStore,
  TimeStore,
  validateAccessLevel,
  UserAccessLevelComponent,
} from 'stores';
import type { VehiclesAdmin } from 'stores/Admin/Vehicles';
import {
  ALERTS_TYPE,
  DATE_TIME_FORMATS,
  MAINTENANCE_UPCOMING_TABLE_COLUMNS,
  MAINTENANCE_VIEW,
  MAINTENANCES_STORE,
  PATHS,
  ROUTER_STORE,
  STORAGE_ITEMS,
  TIME_STORE,
  ACL,
  KEY_TYPE,
  VEHICLE_DRIVER_NAME_READ,
  VEHICLE_DRIVER_NAME_UPDATE,
} from 'config';
import { getFormattedTime, getJSONItemFromStorage, setJSONItemToStorage } from 'utils';
import type MaintenanceUpcomingTableModel from 'models/Maintenances/Tables/UpcomingTable';
import type { SimpleField } from 'models/Fields';
import MaintenanceAlertModel from 'models/Alerts/MaintenanceAlertModel';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

import AverageDailyUsageTooltip from 'components/Maintenance/Table/AverageDailyUsageTooltip';
import Button from 'components/Button';
import CustomTable from 'components/Table/CustomTable';
import FrequencyCell from 'components/Maintenance/Table/FrequencyCell';
import InfoTooltip from 'components/InfoTooltip';
import MaintenanceDueInCell from 'components/Maintenance/Table/DueInCell';
import TableActions from 'components/Table/Actions';
import MaintenanceTaskModal from 'components/Maintenance/TaskModal';
import MarkAsCompleteModal from 'components/Maintenance/MarkAsCompleteModal';
import MaintenanceTaskDeleteModal from 'components/Maintenance/DeleteModal';
import TableDownloadModal from 'components/Table/TableDownloadModal';
import TableEmailModal from 'components/Table/TableEmailModal';
import Modal from 'components/Modal';
import AdjustDueSoonModal from 'components/Maintenance/AdjustDueSoonModal';
import ConfirmationModal from 'components/Admin/ConfirmationModal';
import Notification from 'components/Notification';
import SearchableGroupSelect from 'containers/Select/SearchableGroupSelect';

import { GroupsTwoVehiclesIcon } from 'assets';
import './styles.scss';

interface IProps {
  [MAINTENANCES_STORE]?: MaintenancesStore;
  [TIME_STORE]?: TimeStore;
  [ROUTER_STORE]?: RouterStore;
  vehiclesAdmin?: VehiclesAdmin;
  alertsTableStore?: AlertsTableStore;
}

interface IState {
  isFilterOpen: boolean;
  isEditModalOpen: boolean;
  isMarkAsCompletedModalOpen: boolean;
  isDeleteModalOpen: boolean;
  isExitingConfirmationModalOpen: boolean;
  isDownloadModalOpen: boolean;
  isEmailModalOpen: boolean;
  isAdjustDueSoonModalOpen: boolean;
}

@inject(({ maintenancesStore, timeStore, alertsTableStore, adminStore: { vehiclesAdmin }, routerStore }) => ({
  maintenancesStore,
  timeStore,
  vehiclesAdmin,
  routerStore,
  alertsTableStore,
}))
@observer
class MaintenanceUpcomingTable extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isFilterOpen: getJSONItemFromStorage(STORAGE_ITEMS.maintenance.upcoming.filterOpenStatus, true),
      isEditModalOpen: false,
      isMarkAsCompletedModalOpen: false,
      isDeleteModalOpen: false,
      isExitingConfirmationModalOpen: false,
      isDownloadModalOpen: false,
      isEmailModalOpen: false,
      isAdjustDueSoonModalOpen: false,
    };
  }

  async componentDidMount() {
    window.addEventListener('keyup', this.listenEscKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.listenEscKeyPress);
  }

  get isSearchApplied() {
    const {
      maintenancesStore: {
        upcomingTable: { searchData },
      },
    } = this.props;

    return some(searchData, (data) => Boolean(data));
  }

  get emptyText() {
    return this.isSearchApplied ? (
      ''
    ) : (
      <div className="MaintenanceUpcomingTable-emptyText">
        <span className="MaintenanceUpcomingTable-emptyText--bold">Maintenance Tasks</span> will appear here. Click{' '}
        <span className="MaintenanceUpcomingTable-emptyText--bold">Create New Task</span> to get started.
      </div>
    );
  }

  get isAdminView() {
    return this.props.maintenancesStore.view.value === MAINTENANCE_VIEW.ADMIN;
  }

  get tableHeight() {
    return {
      [MAINTENANCE_VIEW.DEFAULT]: {
        default: 'calc(100vh - 340px)',
        withOpenFilters: 'calc(100vh - 375px)',
      },
      [MAINTENANCE_VIEW.ADMIN]: {
        default: 'calc(100vh - 370px)',
        withOpenFilters: 'calc(100vh - 405px)',
      },
    };
  }

  get editColumnsMap() {
    return {
      [MAINTENANCE_UPCOMING_TABLE_COLUMNS.TASK_NAME]: {
        text: 'Task Name',
        isDisabled: true,
      },
      [MAINTENANCE_UPCOMING_TABLE_COLUMNS.VEHICLE_NAME]: {
        text: 'Vehicle/Asset',
        isDisabled: false,
        isVisible: () => !this.isAdminView,
      },
      [MAINTENANCE_UPCOMING_TABLE_COLUMNS.DUE_IN]: {
        text: 'Due In',
        isDisabled: false,
      },
      [MAINTENANCE_UPCOMING_TABLE_COLUMNS.FREQUENCY]: {
        text: 'Frequency',
        isDisabled: false,
      },
      [MAINTENANCE_UPCOMING_TABLE_COLUMNS.SERVICE_TYPE]: {
        text: 'Service Type',
        isDisabled: false,
      },
      [MAINTENANCE_UPCOMING_TABLE_COLUMNS.CURRENT_ODOMETER]: {
        text: 'Current Odometer',
        isDisabled: false,
        isVisible: () => !this.isAdminView,
      },
      [MAINTENANCE_UPCOMING_TABLE_COLUMNS.CURRENT_ENGINE_HOURS]: {
        text: 'Current Engine Hours',
        isDisabled: false,
        isVisible: () => !this.isAdminView,
      },
      [MAINTENANCE_UPCOMING_TABLE_COLUMNS.DRIVER_NAME]: {
        text: 'Driver Name',
        isDisabled: false,
        isVisible: () => validateAccessLevel([VEHICLE_DRIVER_NAME_READ, VEHICLE_DRIVER_NAME_UPDATE]),
      },
      [MAINTENANCE_UPCOMING_TABLE_COLUMNS.CREATED_ON]: {
        text: 'Created On',
        isDisabled: false,
      },
      [MAINTENANCE_UPCOMING_TABLE_COLUMNS.CREATED_BY]: {
        text: 'Created By',
        isDisabled: false,
      },
      [MAINTENANCE_UPCOMING_TABLE_COLUMNS.MODIFIED_ON]: {
        text: 'Modified On',
        isDisabled: false,
      },
      [MAINTENANCE_UPCOMING_TABLE_COLUMNS.MODIFIED_BY]: {
        text: 'Modified By',
        isDisabled: false,
      },
    };
  }

  fetch = (params) => {
    const {
      vehiclesAdmin: { selectedVehicle },
      maintenancesStore: {
        fetch,
        upcomingTable: { selectedGroup },
      },
    } = this.props;
    const assetGroupId = Number(selectedGroup.value.value);

    if (this.isAdminView && selectedVehicle) {
      fetch({ ...params, isCompleted: false }, selectedVehicle.assetId);
    } else {
      fetch({ ...params, isCompleted: false }, null, assetGroupId);
    }
  };

  getColumns = () => {
    const {
      maintenancesStore: {
        upcomingTable: { columns },
      },
      timeStore: { sessionTimezone },
    } = this.props;

    const allColumns = [
      {
        title: 'Task Name',
        dataIndex: MAINTENANCE_UPCOMING_TABLE_COLUMNS.TASK_NAME,
        width: 180,
        className: 'MaintenanceUpcomingTable-taskName',
        render: (value, task) => (
          <Button className="Button--link" title={value} onClick={() => this.editMaintenanceTask(task.key)} />
        ),
        fixed: 'left',
      },
      {
        title: 'Vehicle/Asset',
        dataIndex: MAINTENANCE_UPCOMING_TABLE_COLUMNS.VEHICLE_NAME,
        width: 180,
        hidden: this.isAdminView,
      },
      {
        title: (
          <>
            <span className="MaintenanceUpcomingTable-dueInTitle">Due In</span>
            <div className="MaintenanceUpcomingTable-dueInTooltip">
              <InfoTooltip>Column is ordered based on average daily vehicle usage</InfoTooltip>
            </div>
          </>
        ),
        titleText: 'Due In',
        dataIndex: MAINTENANCE_UPCOMING_TABLE_COLUMNS.DUE_IN,
        width: 180,
        hiddenSearch: true,
        className: 'MaintenanceUpcomingTable-dueIn',
        render: (cell) => {
          const avgMileagePerDay = cell?.asset.details.avgMileagePerDay.value;
          const avgEngineHoursPerDay = cell?.asset.details.avgEngineHoursPerDay.value;

          return cell ? (
            Boolean(avgMileagePerDay && avgEngineHoursPerDay) ? (
              <AverageDailyUsageTooltip
                miles={avgMileagePerDay}
                hours={avgEngineHoursPerDay}
                dueInDays={cell.averageDueInDays}
              >
                <MaintenanceDueInCell timezone={sessionTimezone} cell={cell} />
              </AverageDailyUsageTooltip>
            ) : (
              <MaintenanceDueInCell timezone={sessionTimezone} cell={cell} />
            )
          ) : (
            '-'
          );
        },
      },
      {
        title: 'Frequency',
        dataIndex: MAINTENANCE_UPCOMING_TABLE_COLUMNS.FREQUENCY,
        width: 180,
        hiddenSearch: true,
        render: (cell) => (cell ? <FrequencyCell cell={cell} /> : '-'),
      },
      {
        title: 'Service Type',
        dataIndex: MAINTENANCE_UPCOMING_TABLE_COLUMNS.SERVICE_TYPE,
        width: 180,
      },
      {
        title: 'Current Odometer',
        dataIndex: MAINTENANCE_UPCOMING_TABLE_COLUMNS.CURRENT_ODOMETER,
        width: 180,
        hiddenSearch: true,
        hidden: this.isAdminView,
      },
      {
        title: 'Current Engine Hours',
        dataIndex: MAINTENANCE_UPCOMING_TABLE_COLUMNS.CURRENT_ENGINE_HOURS,
        width: 190,
        hiddenSearch: true,
        hidden: this.isAdminView,
      },
      {
        title: 'Driver Name',
        dataIndex: MAINTENANCE_UPCOMING_TABLE_COLUMNS.DRIVER_NAME,
        width: 180,
        hiddenSearch: true,
        hidden: !validateAccessLevel([VEHICLE_DRIVER_NAME_READ, VEHICLE_DRIVER_NAME_UPDATE]),
        render: (cell: SimpleField<string>) => <>{cell.value || ''}</>,
      },
      {
        title: 'Created On',
        dataIndex: MAINTENANCE_UPCOMING_TABLE_COLUMNS.CREATED_ON,
        width: 180,
        hiddenSearch: true,
        render: (value) => (
          <>{value ? getFormattedTime(value, DATE_TIME_FORMATS.adminVehicleSettings, sessionTimezone) : '-'}</>
        ),
      },
      {
        title: 'Created By',
        dataIndex: MAINTENANCE_UPCOMING_TABLE_COLUMNS.CREATED_BY,
        width: 180,
        hiddenSearch: true,
      },
      {
        title: 'Modified On',
        dataIndex: MAINTENANCE_UPCOMING_TABLE_COLUMNS.MODIFIED_ON,
        width: 180,
        hiddenSearch: true,
        render: (value) => (
          <>{value ? getFormattedTime(value, DATE_TIME_FORMATS.adminVehicleSettings, sessionTimezone) : '-'}</>
        ),
      },
      {
        title: 'Modified By',
        dataIndex: MAINTENANCE_UPCOMING_TABLE_COLUMNS.MODIFIED_BY,
        width: 180,
        hiddenSearch: true,
      },
    ];

    const filteredColumns = [];

    columns.forEach((column) => {
      if (column.isSelected) {
        filteredColumns.push(allColumns.find((col) => col.dataIndex === column.value));
      }
    });

    return [
      ...filteredColumns.filter((col) => !col.hidden),
      {
        title: 'Actions',
        dataIndex: 'actions',
        width: 200,
        className: 'ant-table-cell--actions',
        fixed: 'right',
        render: (_, task) => {
          const items = [
            {
              text: 'Edit Task',
              onClick: () => this.editMaintenanceTask(task.key),
            },
            ...(validateAccessLevel([ACL.MAINTENANCE.BASIC.UPDATE])
              ? [
                  {
                    text: 'Apply Task to other Vehicles',
                    onClick: () => this.redirectToApplyTasks(task.key),
                  },
                ]
              : []),
            ...(validateAccessLevel([ACL.MAINTENANCE.BASIC.UPDATE])
              ? [
                  {
                    text: 'Create an Alert',
                    onClick: () => this.redirectToAlerts(task),
                  },
                ]
              : []),
            {
              text: 'View this Vehicle’s Maintenance',
              onClick: () => this.redirectToAdmin(task.cpNumber),
              hidden: this.isAdminView,
            },
            ...(validateAccessLevel([ACL.MAINTENANCE.BASIC.DELETE])
              ? [
                  {
                    text: 'Delete Task',
                    onClick: () => this.onDeleteMaintenanceTask(task.key),
                  },
                ]
              : []),
          ];

          return (
            <div className="MaintenanceUpcomingTable-Actions">
              <TableActions items={items} />
              <UserAccessLevelComponent requiredAccessLevel={[ACL.MAINTENANCE.BASIC.UPDATE]}>
                <div className="MaintenanceUpcomingTable-ActionsMarkAsComplete">
                  <Button
                    className="Button--link"
                    title="Mark as Complete"
                    onClick={() => this.onMarkAsCompleteTask(task.key)}
                  />
                </div>
              </UserAccessLevelComponent>
            </div>
          );
        },
      },
    ];
  };

  handleOpenFilter = (isFilterOpen: boolean) => {
    this.setState({ isFilterOpen });
    setJSONItemToStorage(STORAGE_ITEMS.maintenance.upcoming.filterOpenStatus, isFilterOpen);
  };

  editMaintenanceTask = (taskId: number) => {
    const { maintenancesStore } = this.props;

    maintenancesStore.edit(taskId);
    this.setState({ isEditModalOpen: true });
  };

  tryCloseEditModal = () => {
    const { maintenancesStore } = this.props;

    if (maintenancesStore.task && maintenancesStore.task.model.isUpdated) {
      this.setState({ isExitingConfirmationModalOpen: true });
    }

    this.closeEditModal();
  };

  closeEditModal = () => {
    this.setState({ isEditModalOpen: false });
  };

  onSubmitExitingConfirmation = () => {
    this.setState({ isExitingConfirmationModalOpen: false });
  };

  onCancelExitingConfirmation = () => {
    this.setState({ isEditModalOpen: true, isExitingConfirmationModalOpen: false });
  };

  onMarkAsCompleteTask = (taskId: number) => {
    const { maintenancesStore } = this.props;

    maintenancesStore.markAsCompleted(taskId);
    this.setState({ isMarkAsCompletedModalOpen: true });
  };

  closeMarkAsCompleteModal = () => this.setState({ isMarkAsCompletedModalOpen: false });

  onDeleteMaintenanceTask = (taskId: number) => {
    const { maintenancesStore } = this.props;

    maintenancesStore.delete(taskId);
    this.setState({ isDeleteModalOpen: true });
  };

  closeDeleteModal = () => this.setState({ isDeleteModalOpen: false });

  redirectToAdmin = (cpNumber: string) => {
    const {
      vehiclesAdmin: { setSelectedVehicleEditTab },
      routerStore: {
        history: { push },
      },
    } = this.props;

    setSelectedVehicleEditTab(1);
    push(`${PATHS.ADMIN.TABLES.VEHICLES.VEHICLE.replace(':id', cpNumber)}`);
  };

  redirectToAlerts = (task) => {
    const {
      routerStore: {
        history: { push },
      },
      alertsTableStore: { createAlert, isModalOpen, savedStep },
    } = this.props;

    createAlert(ALERTS_TYPE.MAINTENANCE, {
      metadata: {
        asset: {
          id: Number(task.vehicleId),
          name: task.vehicleName,
        },
      },
      specificTaskConfig: {
        taskId: task.key,
      },
    });
    if (this.props.alertsTableStore.alert instanceof MaintenanceAlertModel) {
      this.props.alertsTableStore.alert.basedOn.set('TASK');
    }
    isModalOpen.toggle(true);
    savedStep.set(1);
    push(`${PATHS.ALERTS.INDEX}/`);
  };

  redirectToApplyTasks = (id: number) => {
    const {
      routerStore: {
        history: { push },
      },
    } = this.props;

    push(`${PATHS.MAINTENANCE.APPLY_TASKS.TASK.replace(':id', String(id))}`);
  };

  updateDueSoon = async (value: number) => {
    const {
      maintenancesStore: { accountConfig },
    } = this.props;

    accountConfig.dueSoon.set(value);
    await accountConfig.update();
    this.closeAdjustDueSoonModal();
  };

  closeDownloadModal = () => {
    this.setState({ isDownloadModalOpen: false });
  };

  openDownloadModal = () => {
    this.setState({ isDownloadModalOpen: true });
  };

  closeEmailModal = () => {
    this.setState({ isEmailModalOpen: false });
  };

  openEmailModal = () => {
    this.setState({ isEmailModalOpen: true });
  };

  closeAdjustDueSoonModal = () => {
    const {
      maintenancesStore: {
        accountConfig: { dueSoon },
      },
    } = this.props;

    dueSoon.reset();
    this.setState({ isAdjustDueSoonModalOpen: false });
  };

  openAdjustDueSoonModal = () => {
    this.setState({ isAdjustDueSoonModalOpen: true });
  };

  listenEscKeyPress = (e) => {
    if (this.state.isAdjustDueSoonModalOpen && e.key === KEY_TYPE.ESCAPE) {
      this.closeAdjustDueSoonModal();
    }
  };

  renderTableTitle = () => {
    const {
      maintenancesStore: {
        upcomingTable: { selectedGroup, selectGroup },
        total,
      },
    } = this.props;

    return (
      <div className="MaintenanceUpcomingTable-title">
        {!this.isAdminView && (
          <>
            <div className="MaintenanceUpcomingTable-groupIcon">
              <GroupsTwoVehiclesIcon />
            </div>
            <div className="MaintenanceUpcomingTable-groupSelect">
              <SearchableGroupSelect
                handleChange={selectGroup}
                valueSource="assetGroupId"
                value={selectedGroup.value}
                withAll
              />
            </div>
          </>
        )}
        <div className="MaintenanceUpcomingTable-total">
          <div>{Boolean(total.value) && `${total.value} Scheduled Maintenance Tasks`}</div>
        </div>
      </div>
    );
  };

  render() {
    const {
      maintenancesStore: {
        total,
        task,
        repository: { getState },
        isUpdate,
        upcomingTable: {
          columns,
          pagination,
          searchColumns,
          searchData,
          setColumns,
          setPagination,
          setSearchColumn,
          setSortedColumn,
          sortData,
          source,
          filters,
          download,
        },
        view,
        accountConfig: { dueSoon },
      },
      vehiclesAdmin: { selectedVehicle },
    } = this.props;
    const {
      isFilterOpen,
      isEditModalOpen,
      isMarkAsCompletedModalOpen,
      isDeleteModalOpen,
      isExitingConfirmationModalOpen,
      isDownloadModalOpen,
      isEmailModalOpen,
      isAdjustDueSoonModalOpen,
    } = this.state;
    const cn = classnames('MaintenanceUpcomingTable', {
      'MaintenanceUpcomingTable--withOpenFilters': isFilterOpen,
      [`MaintenanceUpcomingTable--${view.value}`]: Boolean(view.value),
    });
    const tableHeight = isFilterOpen
      ? this.tableHeight[view.value].withOpenFilters
      : this.tableHeight[view.value].default;
    const completeTaskState = task?.completed?.repositoryHistory.createState;
    const deleteTaskState = task?.model.repositoryModel.deleteState;
    const triggerUpdate = isUpdate.value || selectedVehicle?.repositoryVehicles.patchState.success;

    return (
      <div className={cn}>
        <CustomTable
          actionsList={
            validateAccessLevel([ACL.MAINTENANCE.BASIC.DELETE]) && view.value === MAINTENANCE_VIEW.DEFAULT
              ? [
                  {
                    text: 'Adjust ‘Due Soon’ Threshold',
                    onClick: this.openAdjustDueSoonModal,
                  },
                ]
              : undefined
          }
          columns={this.getColumns()}
          dataSource={source}
          filters={{
            [MAINTENANCE_UPCOMING_TABLE_COLUMNS.DUE_IN]: filters.dueIn.list,
            [MAINTENANCE_UPCOMING_TABLE_COLUMNS.FREQUENCY]: filters.frequency.list,
          }}
          editColumnsMap={this.editColumnsMap}
          getData={this.fetch}
          isFilterActive={isFilterOpen}
          loading={getState.loading}
          triggerUpdate={triggerUpdate}
          onColumnsChange={setColumns}
          onColumnSort={setSortedColumn}
          onColumnsSearch={setSearchColumn}
          onFilterOpen={this.handleOpenFilter}
          onPaginationChange={setPagination}
          pagination={pagination}
          searchColumns={searchColumns}
          searchParams={searchData}
          selectedColumns={toJS(columns)}
          sortedColumn={sortData}
          tableHeight={tableHeight}
          total={total.value}
          totalTitle={this.renderTableTitle()}
          withActions
          withDownload
          withEmail
          openDownloadModal={this.openDownloadModal}
          openEmailModal={this.openEmailModal}
          onRowMouseEnter={(_, event) => {
            event.currentTarget.classList.add('ant-table-row-hover');
          }}
          onRowMouseLeave={(_, event) => {
            event.currentTarget.classList.remove('ant-table-row-hover');
          }}
          emptyText={this.emptyText}
          fetchEventListenerName={APP_EVENTS.MAINTENANCE.UPCOMING.GROUP.CHANGED}
        />
        <Modal isModalOpen={isEditModalOpen} onClose={this.tryCloseEditModal} className="Maintenance-taskModal">
          {task && (
            <MaintenanceTaskModal
              title="Edit Maintenance Task"
              tryCloseModal={this.tryCloseEditModal}
              closeModal={this.closeEditModal}
              task={task}
              isMarkCompleted
              onMarkAsComplete={this.onMarkAsCompleteTask}
              onSave={task.model.update}
            />
          )}
        </Modal>
        <Modal
          isModalOpen={isMarkAsCompletedModalOpen}
          onClose={this.closeMarkAsCompleteModal}
          className="MaintenanceUpcomingTable-markAsCompleted"
        >
          {task && task.completed && <MarkAsCompleteModal task={task} onClose={this.closeMarkAsCompleteModal} />}
        </Modal>
        <Modal
          isModalOpen={isDeleteModalOpen}
          onClose={this.closeDeleteModal}
          className="MaintenanceUpcomingTable-deleteModal"
        >
          {task && (
            <MaintenanceTaskDeleteModal
              taskName={task.model.name.value}
              type={task.model.isRepeat ? 'repeat' : 'one_time'}
              onClose={this.closeDeleteModal}
              deleteTask={task.model.delete}
              sending={deleteTaskState.loading}
            />
          )}
        </Modal>
        <ConfirmationModal
          className="MaintenanceUpcomingTable-exitingConfirmation"
          title="Continue Without Saving?"
          applyButtonTitle="Yes, Continue"
          cancelButtonTitle="No, Go Back"
          cancelButtonStyles={{ border: 'none' }}
          isOpen={isExitingConfirmationModalOpen}
          onCancel={this.onCancelExitingConfirmation}
          onSubmit={this.onSubmitExitingConfirmation}
        >
          <div className="MaintenanceUpcomingTable-exitingConfirmationContent">
            <p>Changes made to {task ? task.model.name.value : 'task'} will not be saved.</p>
            <p className="MaintenanceUpcomingTable-exitingConfirmationContentQuestion">Do you want to continue?</p>
          </div>
        </ConfirmationModal>
        <TableDownloadModal<MaintenanceUpcomingTableModel>
          isOpen={isDownloadModalOpen}
          onClose={this.closeDownloadModal}
          tableDownload={download}
        />
        <TableEmailModal<MaintenanceUpcomingTableModel>
          isOpen={isEmailModalOpen}
          onClose={this.closeEmailModal}
          tableDownload={download}
        />
        <AdjustDueSoonModal
          onCancel={this.closeAdjustDueSoonModal}
          onSubmit={this.updateDueSoon}
          isOpen={isAdjustDueSoonModalOpen}
          dueSoon={dueSoon}
        />
        {task?.model.repository.patchState?.error && (
          <Notification
            onClose={task?.model.repository.patchState?.reset}
            text={
              task?.model?.requestId
                ? 'The Task was unable to be created. Please try again'
                : 'Maintenance Task was unable to be updated'
            }
            title={`${task?.model?.requestId ? 'Convert to Task' : 'Update Task'} Failed`}
            type="error"
          />
        )}
        {completeTaskState?.success && (
          <Notification
            onClose={completeTaskState.reset}
            text={`${task.completed.info.vehicleName}: ${task.completed.serviceType.name}`}
            title="Maintenance Task Completed"
            type="success"
          />
        )}
        {completeTaskState?.error && (
          <Notification
            onClose={completeTaskState.reset}
            text="Maintenance Task was unable to be completed"
            title="Mark as Complete Failed"
            type="error"
            onRetry={task.completed.save}
          />
        )}
        {deleteTaskState?.success && (
          <Notification
            onClose={deleteTaskState.reset}
            text="The maintenance task was successfully deleted."
            title="Success!"
            type="success"
          />
        )}
        {deleteTaskState?.error && (
          <Notification
            onClose={deleteTaskState.reset}
            text="Something went wrong. Try again."
            title="Delete Task Failed"
            type="error"
            onRetry={task?.model.delete}
          />
        )}
      </div>
    );
  }
}

export default MaintenanceUpcomingTable;
