import React, { FC, useMemo, useState } from 'react';
import Marker from '../Marker';
import markerIcon from './DispatchMarkerIcon';

interface IProps {
  position: Locations.ILocation;
  onDrag?: (e: google.maps.MouseEvent) => void;
  color?: string;
  colorName?: string;
  draggable?: boolean;
  onHover?: (hover: boolean) => void;
  dimmed?: boolean;
}

const DispatchMarker: FC<IProps> = ({
  position,
  onDrag,
  color,
  colorName,
  draggable = false,
  onHover = () => void 0,
  dimmed,
}) => {
  const [hovered, setHovered] = useState(false);
  const handleMouseOver = () => {
    setHovered(true);
    onHover(true);
  };
  const handleMouseOut = () => {
    setHovered(false);
    onHover(false);
  };
  const icon = useMemo(() => {
    return `data:image/svg+xml;charset=utf-8,${escape(markerIcon({ color, colorName }))}`;
  }, [color, colorName]);
  const defaultState = useMemo(
    () => ({
      icon: {
        url: icon,
        anchor: new google.maps.Point(10, 10),
        size: new google.maps.Size(20, 20),
        scaledSize: new google.maps.Size(20, 20),
      },
      opacity: 1,
      zIndex: 28,
    }),
    [icon]
  );
  const hoverState = useMemo(
    () => ({
      icon: {
        url: icon,
        anchor: new google.maps.Point(13, 13),
        size: new google.maps.Size(26, 26),
        scaledSize: new google.maps.Size(26, 26),
      },
      opacity: 1,
      zIndex: 28,
    }),
    [icon]
  );
  const dimmedState = useMemo(
    () => ({
      icon: {
        url: icon,
        anchor: new google.maps.Point(9, 9),
        size: new google.maps.Size(18, 18),
        scaledSize: new google.maps.Size(18, 18),
      },
      opacity: 0.5,
      zIndex: 28,
    }),
    [icon]
  );
  const options = useMemo(() => {
    if (dimmed) {
      return dimmedState;
    } else if (hovered) {
      return hoverState;
    } else {
      return defaultState;
    }
  }, [defaultState, dimmed, dimmedState, hoverState, hovered]);

  return (
    <Marker
      draggable={draggable}
      anchor={new google.maps.Point(10, 10)}
      position={position}
      onDrag={onDrag}
      options={options}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    />
  );
};

export default DispatchMarker;
