import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';
import type { PersistenceStore } from 'stores';

import SearchableSelect from 'components/Select/SearchableSelect';

interface IProps {
  disabled?: boolean;
  emptySearchResults?: string;
  handleChange?: (option: Select.ISelectOption) => void;
  isSearchable?: boolean;
  labelFormat?: 'code' | 'state';
  noAll?: boolean;
  onBlur?: () => void;
  persistChange?: boolean;
  persistenceStore?: PersistenceStore;
  placeholder?: string;
  searchPlaceholder?: string;
  value?: Select.ISelectOption;
  withEmpty?: boolean;
}

interface IState {
  states: Select.ISelectOption[];
}

@inject(({ persistenceStore }) => ({ persistenceStore }))
@observer
class StateSelect extends Component<IProps, IState> {
  repository: IEntityRepository;

  static defaultProps = {
    emptySearchResults: 'No Found',
    handleChange: () => true,
    labelFormat: 'code',
  };

  constructor(props) {
    super(props);

    if (props.persistChange) {
      if (props.persistenceStore.selectedState && !props.value) {
        this.props.handleChange(props.persistenceStore.selectedState);
      }
    }

    this.state = {
      states: [],
    };

    this.repository = repositoryService.get('info').entity('state');
  }

  loadStates = () => {
    if (this.state.states.length !== 0) {
      return Promise.resolve([...this.state.states]);
    } else {
      return this.repository.get().then((data) => {
        const serverStates = data
          .map((item) => {
            return {
              value: String(item.state),
              label: this.props.labelFormat === 'code' ? item.code.toUpperCase() : item.state,
            };
          })
          .filter((item) => item.label);
        const states = this.props.withEmpty ? [{ value: '', label: '--' }, ...serverStates] : serverStates;

        this.setState({ states });

        return states;
      });
    }
  };

  get value() {
    const state = this.state.states.find(({ value }) => value === this.props.value.label);

    return !state
      ? undefined
      : {
          label: state?.label,
          value: state?.label,
        };
  }

  getData = () => {
    return this.loadStates().then((data) => {
      return {
        options: data
          .map((item) => {
            return {
              value: item.label,
              label: item.label,
            };
          })
          .filter((item) => item.label),
      };
    });
  };

  handleChange = (option: Select.ISelectOption) => {
    const state = this.state.states.find(({ label }) => label === option.label);

    this.props.handleChange(state);
    if (this.props.persistChange) {
      this.props.persistenceStore.updateSelectedState({ ...option });
    }
  };

  render() {
    return (
      <SearchableSelect
        {...this.props}
        getData={this.getData}
        handleChange={this.handleChange}
        isSearchable={this.props.isSearchable}
        onBlur={this.props.onBlur}
        value={this.value}
      />
    );
  }
}

export default StateSelect;
