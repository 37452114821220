import React from 'react';
import classNames from 'classnames';

import { CloseModalIcon } from 'assets';

import './styles.scss';

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  bgColor?: boolean;
}

const CloseButton: React.FC<IProps> = ({ bgColor, className = '', children, ...props }) => {
  return (
    <button className={classNames('ButtonClose', className, { 'ButtonClose--bgColor': bgColor })} {...props}>
      <CloseModalIcon />
    </button>
  );
};

export default CloseButton;
