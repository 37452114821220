import React, { FC } from 'react';
import classNames from 'classnames';

import ColorPickerIcon from './ColorPickerIcon/ColorPickerIcon';

import './styles.scss';

interface IProps {
  className?: string;
  activeColor: Geozone.IGeozoneColor;
  colors: Geozone.IGeozoneColor[];
  onChange: (color: Geozone.IGeozoneColor) => void;
}

const ColorPickerList: FC<IProps> = ({ className, activeColor, colors, onChange }) => {
  const cn = classNames('ColorPickerList', className);

  return (
    <div className={cn}>
      <ul className="ColorPickerList-list">
        {colors.map((color) => {
          return (
            <li className="ColorPickerList-item" key={color.id} onClick={() => onChange(color)}>
              <ColorPickerIcon color={color} isActiveColor={color.id === activeColor.id} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ColorPickerList;
