import React, { FC } from 'react';
import { Table } from 'antd';
import type { TableProps } from 'antd/lib/table';
import { PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons';

interface IProps extends TableProps<any> {
  columns: any;
  data: any;
  ExpandedTableComponent: any;
  childrenRowClassName?: string;
}

const ExpandableTable: FC<IProps> = ({ columns, childrenRowClassName, data, ExpandedTableComponent, ...props }) => {
  return (
    <Table
      showHeader={false}
      pagination={false}
      indentSize={0}
      columns={columns}
      dataSource={data}
      expandedRowClassName={() => childrenRowClassName}
      expandable={{
        expandedRowRender: ExpandedTableComponent,
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <MinusSquareOutlined className="TableExpand TableExpand--minus" onClick={(e) => onExpand(record, e)} />
          ) : (
            <PlusSquareOutlined className="TableExpand TableExpand--plus" onClick={(e) => onExpand(record, e)} />
          ),
      }}
      {...props}
    />
  );
};

export default ExpandableTable;
