import React, { FC, useState, useCallback } from 'react';
import { Observer } from 'mobx-react';
import { CloseIcon, AddIcon } from 'assets';
import classNames from 'classnames';

import type { Group } from 'models';
import type { SimpleField } from 'models/Fields';
import Popup from 'components/Popup';
import SearchableGroupSelect from 'containers/Select/SearchableGroupSelect';

import './styles.scss';

interface IProps {
  addDisabled?: boolean;
  className?: string;
  disabled?: boolean;
  filterOpen?: boolean;
  groups: Array<SimpleField<Group>>;
  onAdd?: (group: Group.IServerGroup) => void;
  onRemove?: (group: SimpleField<Group>) => void;
  withAll?: boolean;
}

const AddGroup = ({ disabled }: { disabled?: boolean }) => {
  const cn = classNames('GroupsAccess-groupItem GroupsAccess-add', {
    'GroupsAccess-add--disabled': disabled,
  });

  return (
    <div className={cn}>
      <AddIcon />
    </div>
  );
};

const GroupsAccess: FC<IProps> = ({
  addDisabled,
  disabled,
  filterOpen,
  groups,
  onAdd = () => true,
  onRemove = () => true,
  withAll,
}) => {
  const [isFilterOpen, setFilterOpen] = useState(filterOpen && !groups.length);
  const toggleFilter = useCallback((isFilterOpen) => setFilterOpen(isFilterOpen), []);
  const closeFilter = useCallback(() => setFilterOpen(false), []);
  const handleAddGroup = useCallback(
    (option: Select.ISelectOption) => {
      onAdd({ description: option.label, groupId: option.value });
    },
    [onAdd]
  );
  const handleRemoveGroup = useCallback(
    (group: SimpleField<Group>) => {
      onRemove(group);
    },
    [onRemove]
  );

  return (
    <Observer
      render={() => (
        <div className="GroupsAccess">
          <div className="GroupsAccess-container">
            {withAll && <div className="GroupsAccess-groupItem GroupsAccess-groupItem--all">All Vehicles</div>}

            {groups.map((group, index) => (
              <div key={`${group.value.groupId}_${index}`} className="GroupsAccess-groupItem">
                <span className="GroupsAccess-groupItemName">{group.value.description}</span>
                {!disabled && (
                  <div className="GroupsAccess-remove" onClick={() => handleRemoveGroup(group)}>
                    <CloseIcon />
                  </div>
                )}
              </div>
            ))}

            {!disabled && (
              <Popup
                className="Popup-content--groupSelector"
                disabled={addDisabled}
                isOpen={isFilterOpen}
                onVisibleChange={toggleFilter}
                placement="bottomLeft"
                triggerElement={isFilterOpen ? null : <AddGroup disabled={addDisabled} />}
              >
                {isFilterOpen ? (
                  <SearchableGroupSelect
                    handleChange={handleAddGroup}
                    handleMenuClose={closeFilter}
                    isMenuOpen
                    menuShouldBlockScroll={false}
                    valueSource="assetGroupId"
                  />
                ) : (
                  <></>
                )}
              </Popup>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default GroupsAccess;
