import React, { FC, useMemo } from 'react';

import './styles.scss';

interface IProps {
  category?: string;
  hours?: string;
  phone?: string;
  website?: string;
}

const LocationPanelDetails: FC<IProps> = ({ category, hours, phone, website }) => {
  const data = useMemo(
    () => [
      { title: 'Hours', value: hours },
      { title: 'Website', value: website },
      { title: 'Phone', value: phone },
      { title: 'Category', value: category },
    ],
    [category, hours, phone, website]
  );

  return (
    <div className="LocationPanelDetails">
      {data.map((row) => {
        const isWebsite = row.title === 'Website';
        const url = isWebsite && Boolean(row.value) && new URL(row.value).hostname;

        return (
          <div
            className={`LocationPanelDetails-row LocationPanelDetails-row--${row.title.toLowerCase()}`}
            key={row.title}
          >
            <p className="LocationPanelDetails-title">{row.title}</p>
            {isWebsite ? (
              <a href={row.value} target="_blank" rel="noopener noreferrer" className="LocationPanelDetails-value">
                {url || '-'}
              </a>
            ) : (
              <p className="LocationPanelDetails-value">{row.value || '-'}</p>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default LocationPanelDetails;
