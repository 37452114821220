import { observable } from 'mobx';

import { ALERTS_TYPE } from 'config';
import isNumber from 'lodash/isNumber';

import { SimpleField } from 'models/Fields';
import AlertWithIntervalModel from './AlertWithInterval';
import AlertPlace from './AlertPlace';

class GeozoneAlertModel extends AlertWithIntervalModel {
  @observable place: AlertPlace;
  @observable actionType: SimpleField<Alerts.Modal.GeoZone.Action>;

  constructor(item) {
    super({
      ...item,
      isIntervalActive: isNumber(item.interval) ? Boolean(item.interval) : false,
      interval: isNumber(item.interval) ? item.interval : 5 * 60 * 1000,
    });

    this.place = new AlertPlace(item.place);
    this.actionType = new SimpleField(item.actionType || 'ARRIVE_DEPART');
    this.urlType = 'geozone';
    this.description =
      'Arrive/Depart must be enabled on a Geozone in the Geozone Admin for this alert to trigger (default enabled).';
    this.type.reInitialize(ALERTS_TYPE.GEOZONE);
  }

  get model() {
    return {
      ...super.model,
      placeType: this.place.type.value,
      placeId: this.place.id.value === 'all' ? '' : this.place.id.value,
      place: this.place.model,
      actionType: this.actionType.value,
    };
  }

  isUpdated(): boolean {
    return super.isUpdated() || this.place.isUpdated || this.actionType.isUpdated;
  }
}

export default GeozoneAlertModel;
