import React from 'react';
import { inject, observer } from 'mobx-react';
import { withScriptjs } from 'react-google-maps';
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import type SearchBox from 'react-google-maps/lib/components/places/SearchBox';
import type { GoogleMapProps } from 'react-google-maps/lib/components/GoogleMap';

import type { GoogleMapSearchStore } from 'stores/Map/GoogleMapSearchStore';
import Input from 'components/Input';
import { KeyboardShortcutsManager } from 'tools';
import get from 'lodash/get';
import isValidCoords, { getValidCoords } from 'is-valid-coords';

import { CloseIcon, LocationPinIcon } from 'assets';
import './styles.scss';

interface IProps {
  mapSearchStore?: GoogleMapSearchStore;
  disabled?: boolean;
}

interface IState {
  isOnFocus: boolean;
}

@inject(({ devicesMapStore: { mapSearchStore } }) => ({
  mapSearchStore,
}))
@observer
class GoogleMapSearch extends React.Component<IProps & GoogleMapProps, IState> {
  searchBox: SearchBox;

  constructor(props) {
    super(props);

    this.state = {
      isOnFocus: false,
    };
  }

  componentDidUpdate(_, prevState) {
    if (prevState.isOnFocus !== this.state.isOnFocus) {
      this.state.isOnFocus ? KeyboardShortcutsManager.get().deactivate() : KeyboardShortcutsManager.get().activate();
    }
  }

  setFocus = () => this.setState({ isOnFocus: true });

  unsetFocus = () => this.setState({ isOnFocus: false });

  setSearchBoxRefs = (refs) => (this.searchBox = refs);

  onPlacesChanged = () => {
    const {
      mapSearchStore: { setSearchResult, searchQuery },
    } = this.props;
    const searchResults = this.searchBox.getPlaces();
    const address = get(searchResults, '[0].formatted_address', '');
    const lat = get(searchResults, '[0].geometry.location.lat', null)();
    const lng = get(searchResults, '[0].geometry.location.lng', null)();

    if (isValidCoords(searchQuery)) {
      const [latitude, longitude] = getValidCoords(searchQuery);
      setSearchResult({ latitude, longitude, address, meta: {} });
    } else {
      setSearchResult({ latitude: lat, longitude: lng, address, meta: {} });
    }
  };

  componentWillUnmount() {
    const {
      mapSearchStore: { resetSearch },
    } = this.props;

    resetSearch();
  }

  handleChange = (e) => {
    const { setSearchQuery } = this.props.mapSearchStore;
    setSearchQuery(e.target.value);
  };

  render() {
    const { isOnFocus } = this.state;
    const { searchQuery, resetSearch } = this.props.mapSearchStore;
    const { disabled } = this.props;
    return (
      <div className="GoogleMapSearch" data-standalone-searchbox="">
        <StandaloneSearchBox ref={this.setSearchBoxRefs} onPlacesChanged={this.onPlacesChanged}>
          <div className="GoogleMapSearch-content">
            <Input
              className="GoogleMapSearch-input"
              iconPosition="left"
              placeholder="Search location"
              value={searchQuery}
              onChange={this.handleChange}
              onFocus={this.setFocus}
              onBlur={this.unsetFocus}
              disabled={disabled}
            />
            {!searchQuery && !isOnFocus && <LocationPinIcon className="GoogleMapSearch-icon" />}
            {searchQuery && (
              <div className="GoogleMapSearch-closeIcon" onClick={resetSearch}>
                <CloseIcon width="12px" height="12px" />
              </div>
            )}
          </div>
        </StandaloneSearchBox>
      </div>
    );
  }
}

export default withScriptjs(GoogleMapSearch);
