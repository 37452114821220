import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { inject, observer } from 'mobx-react';

import type { VehiclesAdmin } from 'stores/Admin/Vehicles';
import InspectionFormTiles from 'components/Inspections/FormTiles';
import InspectionPerformedTable from 'containers/Inspections/PerformedTable';

import './styles.scss';

interface IProps {
  className?: string;
  vehiclesAdmin?: VehiclesAdmin;
  onChange?: (field: { name: string; value: string | number | boolean }) => void;
}

@inject(({ adminStore: { vehiclesAdmin } }) => ({
  vehiclesAdmin,
}))
@observer
class AdminVehicleInspections extends Component<IProps> {
  constructor(props) {
    super(props);
  }

  componentDidMount(): void {
    const {
      vehiclesAdmin: { fuelTypeOptions },
    } = this.props;

    fuelTypeOptions.fetch();
  }

  render() {
    const {
      vehiclesAdmin: {
        selectedVehicle: { inspectionTemplates },
      },
    } = this.props;

    return (
      <div className="AdminVehicleInspections">
        <div className="AdminVehicleInspections-container">
          <Row>
            <Col className="AdminVehicleInspections-leftCol" span={16}>
              <InspectionPerformedTable />
            </Col>
            <Col className="AdminVehicleInspections-rightCol" span={8}>
              <div className="AdminVehicleInspections-dataRow">
                <p className="AdminVehicleInspections-label">Inspection Information</p>
                <div className="AdminVehicleInspections-dataRowContent">
                  <div className="AdminVehicleInspections-dataRowContentLabel">Current Applied Forms</div>
                  <div className="AdminVehicleInspections-dataRowContentForms">
                    <InspectionFormTiles list={inspectionTemplates.toArray()} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default AdminVehicleInspections;
