import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { WEB_FINANCE_DETAILS_READ } from 'config';

import type { CustomersStore } from 'stores';
import validateAccessLevel from 'stores/acl/validator';

import SecondaryPanelJobInvoice from './SecondaryPanelJobInvoice';
import FinancialDataAccessError from 'components/FinancialDataAccessError';

import './styles.scss';

interface IProps {
  customersStore?: CustomersStore;
}

@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class SecondaryPanelJobInvoices extends Component<IProps> {
  get hasAccess () {
    return validateAccessLevel([WEB_FINANCE_DETAILS_READ]);
  }

  render() {
    const {
      customersStore: {
        selectedCustomer: {
          jobDetails,
          id: customerId,
        },
      },
    } = this.props;

    const hasInvoices = jobDetails.invoices.length;
    const hasAccess = this.hasAccess;

    return <div className="SecondaryPanelJobInvoices">
      <div className="SecondaryPanelJobInvoices-header">
        <div>Invoice</div>
        <div>Date</div>
        <div>Total</div>
      </div>
      <div
        className={classNames('SecondaryPanelJobInvoices-body', {
          'SecondaryPanelJobInvoices-body--limited': !hasAccess,
        })}
      >
        <div className="SecondaryPanelJobInvoices-list">
          {jobDetails.invoices.map(invoice => (
            <SecondaryPanelJobInvoice
              customer={customerId}
              key={invoice.id}
              id={invoice.id}
              date={invoice.transactionDate}
              total={invoice.totalAmount}
              limited={!hasAccess}
            />
          ))}
        </div>
        {!hasAccess && hasInvoices && <FinancialDataAccessError className="SecondaryPanelJobInvoices-listColumn--limited" />}
      </div>
    </div>;
  }
}

export default SecondaryPanelJobInvoices;
