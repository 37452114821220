import React, { useState, useEffect } from 'react';
import { Observer } from 'mobx-react';
import { FREQUENCY_TYPE, ACL, METRICS_SWITCH_TYPE } from 'config';
import { validateAccessLevel } from 'stores';
import classnames from 'classnames';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

import type MaintenanceTask from 'models/Maintenances/MaintenanceTask';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import MaintenancePreferenceServiceType from './MaintenancePreferenceServiceType';
import MaintenancePreferenceTaskName from './MaintenancePreferenceTaskName';
import MaintenancePreferenceVehicle from './MaintenancePreferenceVehicle';
import MaintenancePreferenceFrequency from './MaintenancePreferenceFrequency';
import MaintenancePreferenceDueEvery from './MaintenancePreferenceDueEvery';
import MaintenancePreferenceDue from './MaintenancePreferenceDue';
import MaintenancePreferenceAttachments from './MaintenancePreferenceAttachments';
import MaintenancePreferenceNote from './MaintenancePreferenceNote';
import MaintenanceCompletedTaskPanel from '../CompletedTaskPanel';

import './styles.scss';

interface IProps {
  title?: string;
  task: MaintenanceTask;
  tryCloseModal?: () => void;
  closeModal: () => void;
  isMarkCompleted?: boolean;
  onMarkAsComplete?: (id: number) => void;
  onSave: () => Promise<void>;
  onApplyTo?: () => void;
  applyTask?: boolean;
  showVehicle?: boolean;
  showAttachments?: boolean;
  showNote?: boolean;
  showLastPerformed?: boolean;
  footerRight?: boolean;
  showNext?: boolean;
}

const MaintenanceTaskModal: React.FC<IProps> = ({
  tryCloseModal = () => true,
  closeModal,
  task,
  title = 'Maintenance Task',
  isMarkCompleted = false,
  onMarkAsComplete,
  onSave,
  onApplyTo,
  applyTask = false,
  showVehicle = true,
  showAttachments = true,
  showNote = true,
  showLastPerformed,
  showNext = true,
  footerRight,
}) => {
  const [isCompletedTaskPanelOpen, setIsCompletedTaskPanelOpen] = useState(false);
  const [applyTo, setApplyTo] = useState(false);
  const [showOverlay, setShowOverlay] = useState(showVehicle);
  const edit = validateAccessLevel([ACL.MAINTENANCE.BASIC.UPDATE]);
  const footerCn = classnames('MaintenanceTaskModal-footer', {
    'MaintenanceTaskModal-footer--right': !edit || applyTask || footerRight,
  });

  useEffect(() => {
    EventsBus.get().on(APP_EVENTS.VEHICLES.LIST.GET, onShowOverlay);

    return () => {
      EventsBus.get().off(APP_EVENTS.VEHICLES.LIST.GET, onShowOverlay);
    };
  }, []);

  const onShowOverlay = () => {
    setShowOverlay(false);
  };

  const saveTask = async () => {
    await onSave();
    if (task.model.repository.createState.success || task.model.repository.patchState.success) {
      closeModal();
      if (onApplyTo && applyTo) {
        onApplyTo();
      }
    }
  };
  const convertRequest = async () => {
    await task.context.requests.convertToMaintenance(task.model);
    if (task.context.requests.repositoryRequests.patchState.success) {
      closeModal();
      if (onApplyTo && applyTo) {
        onApplyTo();
      }
    }
  };

  const submitTask = async () => {
    const isRepeat = task.ui.modal.frequency.value === FREQUENCY_TYPE.REPEAT;
    const isOneTime = task.ui.modal.frequency.value === FREQUENCY_TYPE.ONE_TIME;
    const isMetricsChecked = task.ui.modal.dueEvery.metricsCheckbox.value;
    const isLastMilesInValid =
      task.ui.modal.dueEvery.metricsSwitch.value === METRICS_SWITCH_TYPE.MILES &&
      !Boolean(Number(task.model.odometer.config.last.value));
    const isLastHoursInValid =
      task.ui.modal.dueEvery.metricsSwitch.value === METRICS_SWITCH_TYPE.HOURS &&
      !Boolean(Number(task.model.engine.config.last.value));

    task.ui.modal.isTaskNameError.toggle(!task.model.name.isValid);

    if (isOneTime && !(task.ui.modal.dueAt.metricsCheckbox.value || task.ui.modal.dueAt.dateCheckbox.value)) {
      task.ui.modal.dueAt.isError.toggle(true);
      return;
    }

    if (isRepeat && !(task.ui.modal.dueEvery.metricsCheckbox.value || task.ui.modal.dueEvery.dateCheckbox.value)) {
      task.ui.modal.dueEvery.isError.toggle(true);
      return;
    }

    if (isRepeat && isMetricsChecked && (isLastMilesInValid || isLastHoursInValid)) {
      task.ui.modal.dueEvery.isLastPerformedError.toggle(true);
      return;
    }

    if (task.model.name.isValid) {
      task.model.requestId ? await convertRequest() : await saveTask();
    }
  };

  const tryIgnoreRequest = async () => {
    task.context.requests.ignoreRequest.set(task.model.requestId);
  };

  const markAsComplete = () => {
    if (onMarkAsComplete) {
      onMarkAsComplete(task.model.id.value);
      closeModal();
    }
  };

  const showTaskPanel = () => {
    task.selectCompletedLast();
    task.ui.panel.isEdit.toggle(true);
    setIsCompletedTaskPanelOpen(true);
  };

  const hideTaskPanel = () => setIsCompletedTaskPanelOpen(false);

  return (
    <Observer
      render={() => (
        <div className="MaintenanceTaskModal">
          <div className="MaintenanceTaskModal-header">
            <h3 className="MaintenanceTaskModal-title">{title}</h3>
          </div>
          <div className="MaintenanceTaskModal-body">
            {showVehicle && <MaintenancePreferenceVehicle asset={task.model.asset} ui={task.ui.modal} />}
            <MaintenancePreferenceTaskName name={task.model.name} ui={task.ui.modal} />
            <MaintenancePreferenceServiceType serviceType={task.model.service.type} ui={task.ui.modal} />
            <MaintenancePreferenceFrequency ui={task.ui.modal} />
            {task.ui.modal.frequency.value === FREQUENCY_TYPE.ONE_TIME ? (
              <MaintenancePreferenceDue
                odometer={task.model.odometer.config}
                engHours={task.model.engine.config}
                serviceTime={task.model.service.config}
                asset={task.model.asset}
                assetId={task.model.asset.id.value}
                ui={task.ui.modal.dueAt}
              />
            ) : (
              <MaintenancePreferenceDueEvery
                odometer={task.model.odometer.config}
                engHours={task.model.engine.config}
                serviceTime={task.model.service.config}
                asset={task.model.asset}
                ui={task.ui.modal.dueEvery}
                isLastPerformed={Boolean(task.model.historyRecords.length)}
                onEditLastPerformed={showTaskPanel}
                showOverlay={showOverlay}
                showLastPerformed={showLastPerformed}
                showNext={showNext}
              />
            )}
            {showAttachments && <MaintenancePreferenceAttachments documents={task.model.documents} />}
            {showNote && <MaintenancePreferenceNote note={task.model.notes} />}
          </div>
          <div className={footerCn}>
            {edit && isMarkCompleted && (
              <div className="MaintenanceTaskModal-markComplete">
                <Button
                  className="MaintenanceTaskModal-markCompleteButton"
                  title="Mark as Complete"
                  onClick={markAsComplete}
                />
              </div>
            )}
            {task.model.requestId && (
              <Button
                className="MaintenanceTaskModal-navigationCancel Button--cancel Button--wide Button--cancelColorGrey"
                onClick={tryIgnoreRequest}
                title="Ignore Request"
                inline
              />
            )}
            {onApplyTo && !task.model.requestId && (
              <div className="MaintenanceTaskModal-onApplyTo">
                <Checkbox
                  label="Apply this task to other Vehicles after saving"
                  checked={applyTo}
                  onChange={setApplyTo}
                />
              </div>
            )}
            <div className="MaintenanceTaskModal-navigation">
              <Button
                className="MaintenanceTaskModal-navigationCancel Button--cancel"
                onClick={tryCloseModal}
                title="Cancel"
                inline
              />
              <Button
                className="MaintenanceTaskModal-navigationSave Button--apply"
                onClick={submitTask}
                sending={task.model.repository.createState.loading || task.model.repository.patchState.loading}
                title={task.model.requestId ? 'Convert to Task' : 'Save'}
                inline
              />
            </div>
          </div>
          <MaintenanceCompletedTaskPanel
            isPanelOpen={isCompletedTaskPanelOpen}
            onClosePanel={hideTaskPanel}
            task={task}
            isShowDelete={false}
            isCancelClosePanel
          />
        </div>
      )}
    />
  );
};

export default MaintenanceTaskModal;
