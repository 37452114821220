import { SimpleField } from 'models/Fields';
import StateModel from 'models/General/StateModel';

class PersonDetailsAddressModel {
  street: SimpleField<string>;
  line2: SimpleField<string>;
  city: SimpleField<string>;
  zipCode: SimpleField<string>;
  state: SimpleField<StateModel>;

  constructor(address) {
    this.street = new SimpleField(address.line1 || '');
    this.line2 = new SimpleField(address.line2 || '');
    this.city = new SimpleField(address.city || '');
    this.zipCode = new SimpleField(address.zipCode || '');
    this.state = new SimpleField(new StateModel(address.state));
  }

  get data() {
    return {
      line1: this.street.value,
      line2: this.line2.value,
      city: this.city.value,
      zipCode: this.zipCode.value,
      state: {
        state: this.state.value.state,
        code: this.state.value.code === '--' ? '' : this.state.value.code.toLowerCase(),
      },
    };
  }

  get isUpdated() {
    return (
      this.street.isUpdated ||
      this.line2.isUpdated ||
      this.city.isUpdated ||
      this.zipCode.isUpdated ||
      this.state.isUpdated
    );
  }

  get fullAddress() {
    return [this.street.value, this.line2.value, this.city.value, this.state.value.state, this.zipCode.value].reduce(
      (acc, current) => {
        if (current) {
          acc = `${acc ? `${acc}, ` : acc} ${current}`;
        }

        return acc;
      },
      ''
    );
  }

  reset = () => {
    this.street.reset();
    this.line2.reset();
    this.city.reset();
    this.zipCode.reset();
    this.state.reset();
  };
}

export default PersonDetailsAddressModel;
