import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import type { RouteComponentProps } from 'react-router-dom';
import { Collapse } from 'antd';

import validateAccessLevel from 'stores/acl/validator';
import withCollapseStorage from 'hocs/withCollapseStorage';
import type { GeozonesAdmin } from 'stores/Admin/Geozones';
import type { DevicesStore, GeozonesStore } from 'stores';
import {
  KEY_TYPE,
  LINKS,
  MANAGEMENT_GEOZONES_NOTES_DELETE,
  MANAGEMENT_GEOZONES_NOTES_READ,
  PATHS,
  WEB_MAP_CLOSEST_VEHICLE_READ,
} from 'config';
import { KeyboardShortcutsManager } from 'tools';

import GeozoneSelector from '../GeozoneSelector';
import GeozoneTextLocationModal from 'components/Map/TextLocationModal';
import MapGeozonePanelActions from 'components/Map/GeozonePanel/GeozonePanelActions';
import MapGeozonePanelDetails from 'components/Map/GeozonePanel/GeozonePanelDetails';
import PanelNote from 'components/SubNav/PanelNote';
import Notification from 'components/Notification';
import ClosestVehicles from '../../ClosestVehicles';
import CloseButton from 'components/Button/CloseButton';

import { ChevronIcon } from 'assets';
import './styles.scss';

enum collapsePanels {
  Details = 'map-geozone-details',
  Actions = 'map-geozone-actions',
  Note = 'map-geozone-note',
  Closest = 'map-geozone-closest',
}

interface IMatchParams {
  geozoneId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  collapseKeys: string[];
  geozonesAdmin: GeozonesAdmin;
  geozonesStore: GeozonesStore;
  devicesStore?: DevicesStore;
  onClose(): void;
  onCollapseChange(values: string | string[]): void;
}

interface IState {
  isTextLocationModalOpen: boolean;
  gotLocationMessageStatus: boolean;
}

@inject(({ adminStore: { geozonesAdmin }, devicesMapStore: { devicesStore }, geozonesStore }) => ({
  geozonesAdmin,
  devicesStore,
  geozonesStore,
}))
@observer
class GeozonePanel extends Component<IProps, IState> {
  private readonly onHandleKeyUp: (event: KeyboardEvent) => void;

  constructor(props) {
    super(props);

    this.state = {
      isTextLocationModalOpen: false,
      gotLocationMessageStatus: false,
    };
    this.onHandleKeyUp = this.handleKeyUp.bind(this);
    this.props.geozonesStore.repositoryGeozone.listenTo('error:do', () => {
      this.props.history.push(PATHS.MAP.GEOZONES);
    });
  }

  componentDidMount() {
    KeyboardShortcutsManager.get().add(this.onHandleKeyUp);
    this.setSelectedGeozone();
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { geozoneId },
      },
    } = this.props;

    if (prevProps.match.params.geozoneId !== geozoneId) {
      this.setSelectedGeozone();
    }
  }

  componentWillUnmount() {
    const {
      geozonesStore: { reset },
    } = this.props;
    KeyboardShortcutsManager.get().remove(this.onHandleKeyUp);
    reset(true, true);
  }

  setSelectedGeozone = (): void => {
    const {
      geozonesStore: { setSelectedGeozoneId },
      match: {
        params: { geozoneId },
      },
    } = this.props;

    if (geozoneId) {
      setSelectedGeozoneId(geozoneId);
    }
  };

  handleKeyUp = (event) => {
    const { isTextLocationModalOpen } = this.state;

    if (event.key === KEY_TYPE.ESCAPE && !isTextLocationModalOpen) {
      this.closePanel();
    }
  };

  setLocationMessageStatus = (status: boolean) => {
    this.setState({ gotLocationMessageStatus: status });
  };

  closePanel = () => {
    const {
      history: { push },
    } = this.props;

    push(PATHS.MAP.GEOZONES);
  };

  handleGeozoneChange = (geozoneId) => {
    const {
      history: { push },
    } = this.props;

    push(PATHS.MAP.GEOZONE.replace(':geozoneId', geozoneId));
  };

  handleOnDirectionsClick = () => {
    window.open(this.geozoneDirectionToLink);
  };

  handleOnEditGeozoneClick = () => {
    const {
      geozonesAdmin: { setSelectedGeozoneTabIndex },
      geozonesStore: { selectedGeozone },
      history: { push },
    } = this.props;

    setSelectedGeozoneTabIndex(0);
    push(PATHS.ADMIN.TABLES.GEOZONES.GEOZONE.replace(':id', String(selectedGeozone?.id)));
  };

  saveGeozoneNote = (note: string) => {
    const {
      geozonesStore: { selectedGeozone },
    } = this.props;

    selectedGeozone?.setGeozoneNotes(note);
    selectedGeozone?.updateGeozone(['notes']);
  };

  shareGeozoneLocation = async (phone: string, message: string) => {
    const {
      geozonesStore: { selectedGeozone },
    } = this.props;

    try {
      await selectedGeozone?.shareGeozoneLocation({ phone, message });
      this.setLocationMessageStatus(true);
    } catch (e) {
      this.setLocationMessageStatus(true);
    }

    this.closeTextLocationModal();
    setTimeout(() => this.setLocationMessageStatus(false), 5000);
  };

  openTextLocationModal = () => this.setState({ isTextLocationModalOpen: true });

  closeTextLocationModal = () => this.setState({ isTextLocationModalOpen: false });

  get geozoneDetails() {
    const {
      geozonesStore: { selectedGeozone },
    } = this.props;

    return {
      tag: selectedGeozone?.tag || '-',
      color: selectedGeozone?.color,
      dispatchMarker: selectedGeozone?.dispatchMarkerAddress
        ? selectedGeozone?.dispatchMarkerAddress
        : `${selectedGeozone?.dispatchMarkerLatitude}, ${selectedGeozone?.dispatchMarkerLongitude}`,
      group: selectedGeozone?.groupDescription ? selectedGeozone?.groupDescription : 'All Vehicles',
    };
  }

  private get geozoneDirectionToLink() {
    const {
      geozonesStore: {
        selectedGeozone: { dispatchMarkerLatitude, dispatchMarkerLongitude },
      },
    } = this.props;

    return LINKS.directionTo
      .replace(/{latitude}/g, String(dispatchMarkerLatitude))
      .replace(/{longitude}/g, String(dispatchMarkerLongitude));
  }

  get geozoneDirections() {
    const {
      geozonesStore: {
        selectedGeozone: { dispatchMarkerLatitude, dispatchMarkerLongitude },
      },
    } = this.props;

    return LINKS.showInGoogleMaps
      .replace(/{latitude}/g, String(dispatchMarkerLatitude))
      .replace(/{longitude}/g, String(dispatchMarkerLongitude));
  }

  get geozoneText() {
    const {
      geozonesStore: { selectedGeozone },
    } = this.props;

    return `${selectedGeozone?.name} Geozone\n${this.geozoneDirections}`;
  }

  get notificationData(): { title: string; text: string; type: 'success' | 'error' } {
    const {
      geozonesStore: { selectedGeozone },
    } = this.props;

    if (selectedGeozone?.repositoryNotificationSMS.createState.success) {
      return {
        title: 'Success!',
        text: 'The text message was successfully sent.',
        type: 'success',
      };
    } else if (selectedGeozone?.repositoryNotificationSMS.createState.error) {
      return {
        title: 'Send Message Failed',
        text: 'The text message failed to send. Try again.',
        type: 'error',
      };
    }
  }

  render() {
    const {
      collapseKeys,
      onCollapseChange,
      geozonesStore: { selectedGeozone },
      devicesStore: {
        filters: { date },
      },
    } = this.props;
    const { isTextLocationModalOpen, gotLocationMessageStatus } = this.state;

    return (
      selectedGeozone && (
        <>
          <div className="GeozonePanel">
            <div className="GeozonePanel-header">
              <div className="GeozonePanel-selector">
                <GeozoneSelector onSelect={this.handleGeozoneChange} />
              </div>
              <div className="GeozonePanel-close">
                <CloseButton onClick={this.closePanel} />
              </div>
            </div>
            <div className="GeozonePanel-content">
              <Collapse
                activeKey={collapseKeys}
                expandIcon={() => (
                  <span className="anticon anticon-right ant-collapse-arrow">
                    <ChevronIcon />
                  </span>
                )}
                expandIconPosition="left"
                onChange={onCollapseChange}
              >
                <Collapse.Panel
                  className="GeozonePanel-block GeozonePanel-block--details"
                  header="Geozone Details"
                  key={collapsePanels.Details}
                >
                  <MapGeozonePanelDetails {...this.geozoneDetails} />
                </Collapse.Panel>
                <Collapse.Panel
                  className="GeozonePanel-block GeozonePanel-block--actions"
                  header="Actions"
                  key={collapsePanels.Actions}
                >
                  <MapGeozonePanelActions
                    onDirectionsClick={this.handleOnDirectionsClick}
                    onEditGeozoneClick={this.handleOnEditGeozoneClick}
                    onTextLocationClick={this.openTextLocationModal}
                  />
                </Collapse.Panel>
                {validateAccessLevel([MANAGEMENT_GEOZONES_NOTES_READ]) && (
                  <Collapse.Panel
                    className="GeozonePanel-block GeozonePanel-block--note"
                    header="Geozone Note"
                    key={collapsePanels.Note}
                  >
                    <PanelNote
                      disabled={!validateAccessLevel([MANAGEMENT_GEOZONES_NOTES_DELETE])}
                      note={selectedGeozone.notes}
                      onUpdate={this.saveGeozoneNote}
                    />
                  </Collapse.Panel>
                )}
                {validateAccessLevel([WEB_MAP_CLOSEST_VEHICLE_READ]) && (
                  <Collapse.Panel
                    header={'Closest Vehicles'}
                    key={collapsePanels.Closest}
                    className="MapVehiclePanel-Block"
                  >
                    <ClosestVehicles
                      latitude={String(selectedGeozone.dispatchMarkerLatitude)}
                      longitude={String(selectedGeozone.dispatchMarkerLongitude)}
                      timestamp={date.to}
                    />
                  </Collapse.Panel>
                )}
              </Collapse>
            </div>
          </div>
          <GeozoneTextLocationModal
            isOpen={isTextLocationModalOpen}
            note={selectedGeozone?.notes}
            onCancel={this.closeTextLocationModal}
            onSubmit={this.shareGeozoneLocation}
            text={this.geozoneText}
            type="Geozone"
          />
          {gotLocationMessageStatus && (
            <Notification {...this.notificationData} onClose={() => this.setLocationMessageStatus(false)} />
          )}
        </>
      )
    );
  }
}

export default withCollapseStorage(GeozonePanel, 'map-geozone-panel-collapse', Object.values(collapsePanels));
