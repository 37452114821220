import React, { FC } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  className?: string;
  imageCount: number;
  imageTotal: number;
  videoCount: number;
  videoTotal: number;
}

const MediaLibraryFilterLabelTitle: FC<IProps> = ({ className, imageCount, imageTotal, videoTotal, videoCount }) => {
  const cn = classNames('MediaLibraryFilterLabelTitle', className);
  const videoText = `${videoCount} of ${videoTotal} Video clip${videoCount === 0 || videoCount > 1 ? 's' : ''}`;
  const imagesText = `${imageCount} of ${imageTotal} Image${imageCount === 0 || imageCount > 1 ? 's' : ''}`;

  return (
    <div className={cn}>
      {Boolean(videoTotal && imageTotal) && `Showing ${videoText}, ${imagesText}`}
      {Boolean(videoTotal && !imageTotal) && `Showing ${videoText}`}
      {Boolean(!videoTotal && imageTotal) && `Showing ${imagesText}`}
    </div>
  );
};

export default observer(MediaLibraryFilterLabelTitle);
