import React from 'react';
import { Observer } from 'mobx-react';
import { PingingIcon, PlayArrowIcon } from 'assets';

import { PLAYER_ERROR_TYPE } from 'config';
import Button from 'components/Button';

import MediaManagerPlayerHeader from './MediaManagerPlayerHeader';
import MediaManagerPlayerControls from './MediaManagerPlayerControls';
import type RequestMediaTimeSegment from 'models/Dashcam/MediaRequestCheckout/RequestMedia/RequestMediaTimeSegment';
import MediaManagerPlayerImage from './MediaManagerPlayerImage';
import MediaManagerPlayerVideo from './MediaManagerPlayerVideo';
import type RequestMediaCamerasType from 'models/Dashcam/MediaRequestCheckout/RequestMedia/RequestMediaCamerasType';
import type PlayerViewModel from 'models/Dashcam/MediaRequestCheckout/Player/Player';
import PlayingPlayerState from 'models/Dashcam/MediaRequestCheckout/Player/PlayingPlayerState';
import PauseImagePlayerState from 'models/Dashcam/MediaRequestCheckout/Player/PauseImagePlayerState';
import ErrorPlayerState from 'models/Dashcam/MediaRequestCheckout/Player/ErrorPlayerState';

import './styles.scss';

interface IProps {
  cameraType: RequestMediaCamerasType;
  timeSegment: RequestMediaTimeSegment;
  timezone: string;
  player: PlayerViewModel;
}

const MediaManagerPlayer: React.FC<IProps> = ({ timeSegment, timezone, player, cameraType }) => {
  const handleErrorClick = () => {
    if (player.state instanceof ErrorPlayerState) {
      if (player.state.type === PLAYER_ERROR_TYPE.VIDEO) {
        player.play();
      } else {
        player.pauseStatic();
      }
    }
  };

  const isPlaying = () => player.state instanceof PlayingPlayerState;
  const isError = () => player.state instanceof ErrorPlayerState;
  const isPauseStatic = () => player.state instanceof PauseImagePlayerState;

  return (
    <Observer
      render={() => (
        <div className="MediaManagerPlayer">
          <MediaManagerPlayerHeader player={player} cameraType={cameraType} timeSegment={timeSegment} />
          <div className="MediaManagerPlayer-content">
            {player.isLoading.value ? (
              <div className="MediaManagerPlayer-loading">
                <PingingIcon width={30} height={30} />
              </div>
            ) : (
              !isPlaying() &&
              !isError() && (
                <div className="MediaManagerPlayer-play" onClick={player.play}>
                  <PlayArrowIcon className="MediaManagerPlayer-play--icon" width={28} height={22} />
                  <div className="MediaManagerPlayer-play--button" />
                </div>
              )
            )}
            {isError() ? (
              <div className="MediaManagerPlayer-streamError">
                <div className="MediaManagerPlayer-streamError--container">
                  <p>Connection to Camera Stream Preview Failed</p>
                  <Button className="Button--link" title="Try Again" onClick={handleErrorClick} />
                </div>
              </div>
            ) : isPauseStatic() ? (
              <MediaManagerPlayerImage imageData={player.image.data.value} onClick={player.play} />
            ) : (
              <MediaManagerPlayerVideo
                url={player.video.url.value}
                isMuted={player.isMuted.value}
                isPlaying={player.state instanceof PlayingPlayerState}
                onClick={player.pause}
              />
            )}
          </div>
          <MediaManagerPlayerControls timezone={timezone} timeSegment={timeSegment} player={player} />
        </div>
      )}
    />
  );
};

export default MediaManagerPlayer;
