import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Observer } from 'mobx-react';
import Draggable from 'react-draggable';
import classNames from 'classnames';

import { getFormattedTime } from 'utils';
import type CameraInterval from 'models/Dashcam/MediaRequestCheckout/CamerasJournal/CameraInterval';
import type CameraCustomInterval from 'models/Dashcam/MediaRequestCheckout/CamerasJournal/CameraCustomInterval';
import JournalBarControls from './JournalBarControls';
import JournalView from 'models/Dashcam/MediaRequestCheckout/CamerasJournal/JournalView';

import { CameraWithFlashIcon } from 'assets';

import './styles.scss';

interface IProps {
  className?: string;
  date: string;
  intervals: CameraInterval[];
  customInterval: CameraCustomInterval;
  labelFormat?: string;
  tz?: string;
  onChange?: (interval: CameraInterval) => void;
  ignoreInterval?: boolean;
  isOffline: boolean;
}

const JournalBar: FC<IProps> = ({
  className,
  date,
  intervals = [],
  customInterval,
  labelFormat = 'ha',
  tz,
  ignoreInterval = false,
  onChange,
  isOffline,
}) => {
  const cn = classNames('JournalBar', className);
  const view = useMemo(() => new JournalView(), []);
  const barRef = useRef(null);
  const anyIntervalSelected = useCallback(() => intervals.some((interval) => interval.isSelected.value), [intervals]);
  const getIntervalStyle = useCallback(
    (interval: CameraInterval): { left: string; width: string } => {
      let left;
      let width;
      const intervalStartsPrevDate =
        interval.startTimestamp <= view.range.from.value && interval.endTimestamp >= view.range.from.value;
      const intervalEndsNextDate =
        interval.endTimestamp >= view.range.to.value && interval.startTimestamp <= view.range.to.value;

      left = intervalStartsPrevDate
        ? '0'
        : `${((interval.startTimestamp - view.range.from.value) / view.duration) * 100}%`;

      if (intervalEndsNextDate) {
        const from = interval.startTimestamp > view.range.from.value ? interval.startTimestamp : view.range.from.value;

        width = `${((view.range.to.value - from) / view.duration) * 100}%`;
      } else if (intervalStartsPrevDate) {
        const to = interval.endTimestamp < view.range.to.value ? interval.endTimestamp : view.range.to.value;

        width = `${((to - view.range.from.value) / view.duration) * 100}%`;
      } else {
        width = `${((interval.endTimestamp - interval.startTimestamp) / view.duration) * 100}%`;
      }

      return { left, width };
    },
    [view.duration, view.range.from.value, view.range.to.value]
  );

  useEffect(() => {
    if (date) {
      view.setRangeLimits(date);
    }
  }, [view, date]);

  const handleChangeInterval = (interval) => {
    if (ignoreInterval) {
      return;
    }

    interval.select();

    if (onChange) {
      onChange(interval);
    }
  };

  const handleClick = (event) => {
    if (!ignoreInterval && event.target.className.includes('interval')) {
      customInterval?.reset();
    } else {
      let offsetX = event.nativeEvent.layerX;

      if (event.target.className.includes('interval')) {
        offsetX += event.target.offsetLeft;
      }

      const active = Math.round(
        (offsetX / barRef.current.offsetWidth) * (view.range.to.value - view.range.from.value) + view.range.from.value
      );

      customInterval?.set(active, view.range.from.value, view.range.to.value);
    }
  };

  const positionX = () => {
    return barRef.current
      ? ((customInterval.activeTimestamp.value - view.range.from.value) /
          (view.range.to.value - view.range.from.value)) *
          barRef.current.offsetWidth
      : 0;
  };

  useEffect(() => {
    if (!isOffline) {
      customInterval?.reset();
    }
  }, [date]);

  return (
    <Observer
      render={() => {
        return (
          <div className={cn}>
            <div className="JournalBar-timeline">
              <div className="JournalBar-section JournalBar-section--left">
                <div className="JournalBar-logo">
                  <CameraWithFlashIcon className="JournalBar-logoIcon" />
                </div>
              </div>
              <div className="JournalBar-section JournalBar-section--right">
                <div className="JournalBar-bar" onClick={handleClick} ref={barRef}>
                  {customInterval?.isSelected.value && (
                    <Draggable axis="x" bounds="parent" position={{ x: positionX(), y: 0 }}>
                      <div className="JournalBar-outsideScrubber" />
                    </Draggable>
                  )}
                  {intervals.map((interval) => {
                    const intervalCn = classNames('JournalBar-interval', {
                      'JournalBar-interval--unselected': anyIntervalSelected() && !interval.isSelected.value,
                      'JournalBar-interval--selected': interval.isSelected.value,
                      'JournalBar-interval--ignore': ignoreInterval,
                    });

                    return (
                      <div
                        className={intervalCn}
                        key={interval.startTimestamp}
                        onClick={() => handleChangeInterval(interval)}
                        style={getIntervalStyle(interval)}
                      />
                    );
                  })}
                </div>
                <div className="JournalBar-labels">
                  {view.labelTimestamps.map((ts, i) => (
                    <span className="JournalBar-label" key={i}>
                      {getFormattedTime(ts, labelFormat, tz)}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="JournalBar-controls">
              <JournalBarControls
                disabledLeft={view.disabledLeft}
                disabledRight={view.disabledRight}
                disabledZoomIn={view.disabledZoomIn}
                disabledZoomOut={view.disabledZoomOut}
                onGoLeft={view.goLeft}
                onGoRight={view.goRight}
                onZoomIn={view.zoomIn}
                onZoomOut={view.zoomOut}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export default JournalBar;
