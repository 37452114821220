import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import type { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import { parse } from 'query-string';

import type { Dispatch } from 'models';
import { DISPATCH_STORE } from 'config';
import type { DispatchStore } from 'stores/Dispatch';
import type { IDispatchModalSearchParams } from '../index';
import SendDispatchForm from 'components/Dispatch/SendForm';
import Modal from 'components/Modal';

import './styles.scss';

interface IProps extends RouteComponentProps {
  className?: string;
  [DISPATCH_STORE]?: DispatchStore;
}

interface IState {
  currentDispatch: Dispatch;
}

@inject(DISPATCH_STORE)
@observer
class EditDispatch extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      currentDispatch: null,
    };
  }

  async componentDidMount() {
    const {
      dispatchStore: { getDispatch },
      location: { search },
    } = this.props;

    const { dispatchId }: IDispatchModalSearchParams = parse(search);
    const currentDispatch = await getDispatch(dispatchId);
    this.setState({ currentDispatch });
  }

  getInitialValues = (): Dispatch.ISendDispatchFormValues => {
    const {
      currentDispatch: { dispatchDate, driverId, destination, dispatchNotes, driverContactPhone },
    } = this.state;

    return {
      date: moment(dispatchDate, 'YYYY-MM-DD').toDate(),
      destination: {
        address: destination.address,
        latitude: destination.latitude,
        longitude: destination.longitude,
      },
      name: driverId,
      note: dispatchNotes,
      phone: driverContactPhone,
    };
  };

  handleBack = () => {
    const {
      history: { goBack },
    } = this.props;

    goBack();
  };

  handleSubmit = async (values: Dispatch.ISendDispatchData) => {
    const {
      currentDispatch: { dispatchId },
    } = this.state;
    const {
      dispatchStore: { sendDispatch, sendDispatchRequestStatus },
    } = this.props;

    await sendDispatch('PUT', { ...values, dispatchId: Number(dispatchId) });

    if (sendDispatchRequestStatus.success) {
      this.handleBack();
    }
  };

  render() {
    const {
      className,
      dispatchStore: { filteredDriversList },
    } = this.props;
    const { currentDispatch } = this.state;
    const cn = classNames('EditDispatch', className);

    return (
      currentDispatch && (
        <div className={cn}>
          <Modal
            className="Modal--sendDispatch Modal--withoutPadding"
            isModalOpen
            onClose={this.handleBack}
            bgColor="white"
            width={800}
          >
            <p className="EditDispatch-title">Edit Dispatch</p>
            <div className="EditDispatch-form">
              <SendDispatchForm
                driversList={filteredDriversList}
                onSubmit={this.handleSubmit}
                onCancel={this.handleBack}
                initialValues={this.getInitialValues()}
              />
            </div>
          </Modal>
        </div>
      )
    );
  }
}

export default EditDispatch;
