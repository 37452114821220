import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import type { RECIPIENT_TYPE } from 'config';
import type { ListField } from 'models/Fields';

import Button from 'components/Button';
import RecipientItem from 'components/Recipients/RecipientItem';

import './styles.scss';

interface IProps {
  items: ListField<string>;
  className?: string;
  title: string;
  linkTitle: string;
  type: RECIPIENT_TYPE;
  hitNext: boolean;
  cancelHitNextNotify: () => void;
  isEmailFocus?: boolean;
  isAllItemsEmpty?: boolean;
  isCharacterLimitReached?: boolean;
  characterLimitLeft?: number;
  characterLimitText?: string;
}

const TableRecipientGroup: React.FC<IProps> = ({
  items,
  className,
  title,
  linkTitle,
  type,
  hitNext,
  cancelHitNextNotify,
  isEmailFocus = true,
  isAllItemsEmpty = false,
  isCharacterLimitReached = false,
  characterLimitLeft = Infinity,
  characterLimitText = 'Max character limit reached.',
}) => {
  const addItem = () => {
    items.add('');
  };

  const removeItem = (item) => {
    items.remove(item);
  };

  const cn = classNames('TableRecipientGroup', className);

  return (
    <div className={cn}>
      <div className="TableRecipientGroup-title">
        <p>{title}</p>
      </div>
      <div className="TableRecipientGroup-content">
        <ul className="TableRecipientGroup-list">
          {items.value.map((item, i) => (
            <RecipientItem
              key={`${i}_${item.id}`}
              type={type}
              item={item}
              isEnabledCloseButton={Boolean(items.value.length > 1)}
              isEmailFocus={isEmailFocus || Boolean(items.value.length > 1)}
              onRemove={removeItem}
              hitNext={hitNext}
              cancelHitNextNotify={cancelHitNextNotify}
              hitNextErrorEmpty="Email address required"
              isAllItemsEmpty={isAllItemsEmpty}
              characterLimitLeft={characterLimitLeft}
            />
          ))}
        </ul>
        <div className="TableRecipientGroup-add">
          <Button
            title={linkTitle}
            className="TableRecipientGroup-add--button Button--link"
            onClick={addItem}
            disabled={!items.isItemsNotEmpty || isCharacterLimitReached}
          />
          {isCharacterLimitReached && <div className="TableRecipientGroup-add--info">{characterLimitText}</div>}
        </div>
      </div>
    </div>
  );
};

export default observer(TableRecipientGroup);
