import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { CustomersStore } from 'stores';

import SecondaryPanelAttributesRow from '../../../SecondaryPanelAttributes/SecondaryPanelAttributesRow';
import SecondaryPanelAttributesCol from '../../../SecondaryPanelAttributes/SecondaryPanelAttributesCol';

interface IProps {
  customersStore?: CustomersStore;
}


@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class SecondaryPanelServiceAddress extends Component<IProps> {

  get invoice () {
    return this.props.customersStore.selectedCustomer?.selectedInvoice;
  }

  render() {
    return <div className="SecondaryPanelServiceAddress">
      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Contact</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{this.invoice?.contact}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>
      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Address</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>
          {this.invoice?.address.map((addressLine, index) => (<div key={index}>{addressLine}</div>))}
        </SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>
      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Email</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{this.invoice?.email}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>
    </div>;
  }
}

export default SecondaryPanelServiceAddress;
