import { observable, action, computed } from 'mobx';

import FiltersBase from '../../FiltersBase';
import type MediaManagerStore from './index';

export default class MediaManagerFilters extends FiltersBase {
  mediaManager: MediaManagerStore;
  @observable selectedDeviceId: string = '';
  @observable selectedTypes: string[] = [];

  constructor({
    initialDate,
    mediaManager,
  }: {
    initialDate: Filters.IDateFilterOption;
    mediaManager: MediaManagerStore;
  }) {
    super(initialDate);

    this.mediaManager = mediaManager;
  }

  @computed get selectedCameraId() {
    const selectedDevice = this.mediaManager.devicesList.find((device) => device.deviceId === this.selectedDeviceId);

    return selectedDevice ? selectedDevice.camera?.id : null;
  }

  @action setSelectedDeviceId = (deviceId: string) => (this.selectedDeviceId = deviceId);

  @action setSelectedTypes = (selectedTypes: string[]) => (this.selectedTypes = selectedTypes);

  @action resetFilters = (date?: { from: number; to: number }) => {
    this.reset(date);
    this.selectedDeviceId = '';
    this.selectedTypes = [];
  };
}
