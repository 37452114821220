import React from 'react';

import type AlertIntervalModel from 'models/Alerts/AlertInterval';
import type { ToggleField } from 'models/Fields';

import AlertPreferenceFinalizeSimpleLine from '../AlertPreferenceFinalizeSimpleLine';

interface IProps {
  label?: string;
  interval?: AlertIntervalModel;
  option?: ToggleField;
  startText?: string;
  showAlways?: boolean;
}

const AlertPreferenceFinalizeOptionsLine: React.FC<IProps> = ({
  startText = '',
  label,
  interval,
  option = { value: 0 },
  showAlways = false,
}) => {
  const getValue = () => {
    let text = startText;

    if (interval.weeks.value) {
      text += ` ${interval.weeks.value} ${interval.weeks.value === 1 ? 'week' : 'weeks'}`;
    }

    if (interval.days.value) {
      text += ` ${interval.days.value} ${interval.days.value === 1 ? 'days' : 'days'}`;
    }

    if (interval.hours.value) {
      text += ` ${interval.hours.value} ${interval.hours.value === 1 ? 'hour' : 'hours'}`;
    }

    if (interval.minutes.value) {
      text += ` ${interval.minutes.value} ${interval.minutes.value === 1 ? 'minute' : 'minutes'}`;
    }

    if (interval.seconds.value) {
      text += ` ${interval.seconds.value} ${interval.seconds.value === 1 ? 'second' : 'seconds'}`;
    }

    return text;
  };

  return (
    <>
      {(showAlways || (option.value && Boolean(interval.value))) && (
        <AlertPreferenceFinalizeSimpleLine label={label} value={getValue()} />
      )}
    </>
  );
};

export default AlertPreferenceFinalizeOptionsLine;
