import React, { useState } from 'react';
import { Observer } from 'mobx-react';
import classnames from 'classnames';

import { PERSON_LOGIN_CREDENTIALS } from 'config';
import { CautionIcon } from 'assets';

import type PersonDetailsContactsLoginCredentials from 'models/Admin/People/PersonDetails/PersonDetailsContacts/PersonDetailsContactsLoginCredentials';
import type { SimpleField } from 'models/Fields';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Input from 'components/Input/AntInput';
import PhoneNumberInput from 'components/PhoneNumberInput';
import EmailInput from 'components/EmailInput';
import Notification from 'components/Notification';

import './styles.scss';

const CONTENT = {
  EMAIL: {
    edit: {
      title: 'Edit Email Address',
      text: 'Editing a user`s email address will change their Login credentials and the old address will no longer be valid to login. You will need to confirm the request by validating the new email address with an email that will be sent.',
      label: 'Email Address',
      placeholder: 'email@company.com',
      successTitle: 'Validation Email Sent',
      successMessage:
        'You will need to confirm the request by validating the new email address with an email that will be sent.',
      errorTitle: 'Send Failed',
      errorMessage: 'The Validation Email was unable to be sent',
    },
    validate: {
      title: 'Validate Email Address',
      text: 'Enter the Validation Code sent to the email address provided.',
      label: 'Validation Code',
      placeholder: '',
      actionEditText: 'Enter Email Address',
      successTitle: 'Email Address Successfully Updated',
      successMessage: 'The new email for [Name] may now be used to log in',
    },
  },
  PHONE: {
    edit: {
      title: 'Edit Phone Number',
      text: 'Editing a user`s phone number will change their Login credentials and the old number will no longer be valid to login. You will need to confirm the request by validating the new number with a text message (SMS) that will be sent.',
      label: 'Phone Number',
      placeholder: '',
      successTitle: 'Validation Message Sent',
      successMessage:
        'You will need to confirm the request by validating the new number with a text message (SMS) that will be sent.',
      errorTitle: 'Sent Failed',
      errorMessage: 'The Validation Message was unable to be sent',
    },
    validate: {
      title: 'Validate Phone Number',
      text: 'Enter the Validation Code sent to the phone number provided.',
      label: 'Validation Code',
      placeholder: '',
      actionEditText: 'Edit Phone Number',
      successTitle: 'Phone Number Successfully Updated',
      successMessage: 'The new phone number for [Name] may now be used to log in',
    },
  },
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  credentials: PersonDetailsContactsLoginCredentials;
  editType: 'EMAIL' | 'PHONE';
  personName: SimpleField<string>;
}

const PersonLoginCredentials: React.FC<IProps> = ({ isOpen, onClose, credentials, editType, personName }) => {
  const [code, setCode] = useState('');

  const onEditSave = async () => {
    await credentials.sendCode();
  };

  const onValidateBack = () => {
    credentials.state.set(PERSON_LOGIN_CREDENTIALS.EDIT);
    credentials.stopCountDown();
  };

  const onVerify = async () => {
    await credentials.verify(code);

    if (credentials.repositoryVerify.patchState.success) {
      onClose();
      credentials.stopCountDown();
    }
  };

  const onEditEmail = (value: string) => {
    credentials.newLogin.set(value);
    credentials.errorMessage.set(null);
  };

  const onEditPhone = (value: string) => {
    credentials.newLogin.set(value);
    credentials.errorMessage.set(null);
  };

  return (
    <Observer
      render={() => {
        const cnEditInputValue = classnames('PersonLoginCredentials-editContentValueInput', {
          'PersonLoginCredentials-editContentValueInput--error': credentials.errorMessage.value,
        });

        return (
          <div className="PersonLoginCredentials">
            <Modal isModalOpen={isOpen} onClose={onClose} className="PersonLoginCredentials-modal">
              {credentials.state.value === PERSON_LOGIN_CREDENTIALS.EDIT && (
                <div className="PersonLoginCredentials-edit">
                  <div className="PersonLoginCredentials-editHeader">
                    <div className="PersonLoginCredentials-editHeaderTitle">{CONTENT[editType].edit.title}</div>
                  </div>
                  <div className="PersonLoginCredentials-editContent">
                    <div className="PersonLoginCredentials-editContentInfo">
                      <div className="PersonLoginCredentials-editContentInfoIcon">
                        <CautionIcon width={20} height={20} />
                      </div>
                      <p className="PersonLoginCredentials-editContentInfoText">{CONTENT[editType].edit.text}</p>
                    </div>
                    <div className="PersonLoginCredentials-editContentValue">
                      <label className="PersonLoginCredentials-editContentValueLabel">
                        {CONTENT[editType].edit.label}
                      </label>
                      <div className={cnEditInputValue}>
                        {editType === 'EMAIL' && (
                          <EmailInput
                            placeholder={CONTENT[editType].edit.placeholder}
                            value={credentials.newLogin.value}
                            onChange={onEditEmail}
                            onError={(err) => credentials.newLogin.setIsValid(!err)}
                            shouldBeTouched={false}
                            isEmptyValid
                          />
                        )}
                        {editType === 'PHONE' && (
                          <PhoneNumberInput
                            onChange={onEditPhone}
                            onError={(err) => credentials.newLogin.setIsValid(!err)}
                            shouldBeTouched={false}
                            value={credentials.newLogin.value}
                            isEmptyValid
                          />
                        )}
                        {Boolean(credentials.errorMessage.value) && (
                          <p className="PersonLoginCredentials-editContentValueInputError">
                            {credentials.isShowCountDown.value
                              ? `${credentials.errorMessage.value} ${credentials.resendTimeout.value / 1000}s`
                              : credentials.errorMessage.value}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="PersonLoginCredentials-editActions">
                    <Button title="Cancel" className="Button--inline Button--cancel" onClick={onClose} />
                    <Button
                      title="Save"
                      className="Button--inline Button--apply"
                      onClick={onEditSave}
                      sending={credentials.repositoryCode.getState.loading}
                      disabled={
                        !Boolean(credentials.newLogin.value) ||
                        !credentials.newLogin.isValid ||
                        Boolean(credentials.errorMessage.value)
                      }
                    />
                  </div>
                </div>
              )}
              {credentials.state.value === PERSON_LOGIN_CREDENTIALS.VALIDATE && (
                <div className="PersonLoginCredentials-validate">
                  <div className="PersonLoginCredentials-validateHeader">
                    <div className="PersonLoginCredentials-validateHeaderTitle">{CONTENT[editType].validate.title}</div>
                  </div>
                  <div className="PersonLoginCredentials-validateContent">
                    <div className="PersonLoginCredentials-validateContentInfo">
                      <p className="PersonLoginCredentials-validateContentInfoText">
                        {CONTENT[editType].validate.text}
                      </p>
                    </div>
                    <div className="PersonLoginCredentials-validateContentValue">
                      <label className="PersonLoginCredentials-validateContentValueLabel">
                        {CONTENT[editType].validate.label}
                      </label>
                      <div className="PersonLoginCredentials-validateContentValueInput">
                        <Input
                          placeholder={CONTENT[editType].validate.placeholder}
                          value={code}
                          onFocus={credentials.repositoryVerify.patchState.reset}
                          onChange={(e) => setCode(e.target.value)}
                          error={credentials.repositoryVerify.patchState.error && 'Incorrect Validation Code'}
                        />
                        {credentials.resendTimeout.value > 0 ? (
                          <p className="PersonLoginCredentials-validateContentValueInputTimeoutResend">
                            Resend code again in {credentials.resendTimeout.value / 1000}s
                          </p>
                        ) : (
                          <Button
                            title="Resend code"
                            onClick={onEditSave}
                            className="Button--link PersonLoginCredentials-validateContentValueInputResend"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="PersonLoginCredentials-validateActions">
                    <Button
                      title={CONTENT[editType].validate.actionEditText}
                      className="Button--link"
                      onClick={onValidateBack}
                    />
                    <div className="PersonLoginCredentials-validateActions--right">
                      <Button title="Cancel" className="Button--inline Button--cancel" onClick={onClose} />
                      <Button
                        title="Verify"
                        className="Button--inline Button--apply"
                        onClick={onVerify}
                        sending={credentials.repositoryVerify.patchState.loading}
                      />
                    </div>
                  </div>
                </div>
              )}
            </Modal>
            {credentials.repositoryCode.getState.success && (
              <Notification
                title={CONTENT[editType].edit.successTitle}
                text={CONTENT[editType].edit.successMessage}
                type="success"
                onClose={credentials.repositoryCode.getState.reset}
              />
            )}
            {credentials.repositoryCode.getState.error && (
              <Notification
                title={CONTENT[editType].edit.errorTitle}
                text={CONTENT[editType].edit.errorMessage}
                type="error"
                onClose={credentials.repositoryCode.getState.reset}
                onRetry={onEditSave}
              />
            )}
            {credentials.repositoryVerify.patchState.success && (
              <Notification
                title={CONTENT[editType].validate.successTitle}
                text={CONTENT[editType].validate.successMessage.replace('[Name]', `'${personName.value}'`)}
                type="success"
                onClose={credentials.repositoryVerify.patchState.reset}
              />
            )}
          </div>
        );
      }}
    />
  );
};

export default PersonLoginCredentials;
