import React from 'react';

import type { SimpleField } from 'models/Fields';

import AlertPreferenceFinalizeSimpleLine from '../AlertPreferenceFinalizeSimpleLine';

interface IProps {
  label: string;
  idleMinutes: SimpleField<number>;
}

const AlertPreferenceFinalizeExcessiveIdlingLine: React.FC<IProps> = ({ label, idleMinutes }) => {
  const value = `Idles for ${idleMinutes.value} minutes`;

  return <AlertPreferenceFinalizeSimpleLine label={label} value={value} />;
};

export default AlertPreferenceFinalizeExcessiveIdlingLine;
