import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import {
  ADMIN_INTEGRATIONS_READ,
  INTEGRATIONS_STORE,
  PATHS,
} from 'config';

import validateAccessLevel from 'stores/acl/validator';

import type { IIntegrationsStore } from 'interfaces/stores/Integrations/IIntegrationsStore';

import './styles.scss';

interface IProps {
  integrationsStore: IIntegrationsStore;
  className?: string;
}

@inject(INTEGRATIONS_STORE)
@observer
class SubNavNavigation extends Component<IProps, null> {
  async componentDidMount() {
    if (
      validateAccessLevel([ADMIN_INTEGRATIONS_READ]) &&
      !this.props.integrationsStore.repositoryEntityIntegrationStatuses.getState.success
    ) {
      await this.props.integrationsStore?.loadAllIntegrations?.();
    }
  }

  render() {
    const {
      integrationsStore: {
        hasCustomersIntegration,
      },
      className,
    } = this.props;

    const cn = classNames('SubNavNavigation', {
      className,
    });

    return (
      <div className={cn}>
        <NavLink
          to={PATHS.MAP.VEHICLES}
          isActive={(_, location) => {
            if (location.pathname.includes('groups') || location.pathname.includes('vehicles')) {
              return true;
            }

            return false;
          }}
          className="SubNavNavigation-link SubNavNavigation-link--vehicles"
          activeClassName="SubNavNavigation-link--selected"
        >
          Vehicles
        </NavLink>
        <NavLink
          to={PATHS.MAP.GEOZONES}
          className="SubNavNavigation-link SubNavNavigation-link--geozones"
          activeClassName="SubNavNavigation-link--selected"
        >
          Geozones
        </NavLink>
        {hasCustomersIntegration && validateAccessLevel([ADMIN_INTEGRATIONS_READ]) && <NavLink
          to={PATHS.MAP.CUSTOMERS}
          className="SubNavNavigation-link SubNavNavigation-link--customers"
          activeClassName="SubNavNavigation-link--selected"
        >
          Customers
        </NavLink>}
      </div>
    );
  }
}

export default SubNavNavigation;
