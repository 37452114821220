import { SimpleField } from 'models/Fields';

class InspectionTemplate {
  id: number;
  name: SimpleField<string>;

  constructor({ id = null, name = '' }) {
    this.id = id;
    this.name = new SimpleField(name);
  }
}

export default InspectionTemplate;
