import { action, observable, computed } from 'mobx';
import isEqual from 'lodash/isEqual';

interface ISimpleField<T> {
  value: T;
  isValid: boolean;
  isTouched: boolean;
  initialValue: T;
  id: number;
  isUpdated: boolean;
  isEmpty: boolean;
  setIsValid(isValid: boolean): void;
  set(value: T): void;
  reset(): void;
  reInitialize(value: T): void;
}

class SimpleField<T> implements ISimpleField<T> {
  @observable value: T;
  @observable isValid: boolean;
  @observable isTouched: boolean = false;
  initialValue: T;
  id: number;

  constructor(value, isValid = false) {
    this.value = value;
    this.initialValue = value;
    this.isValid = isValid;
    this.id = Date.now();
  }

  @computed get isUpdated(): boolean {
    return !isEqual(this.value, this.initialValue);
  }

  @computed get isEmpty(): boolean {
    return !this.value;
  }

  @action setIsValid = (isValid: boolean) => (this.isValid = isValid);

  @action set = (value: T) => {
    if (!this.isTouched) {
      this.isTouched = true;
    }
    this.value = value;
  };

  @action reset = () => (this.value = this.initialValue);

  @action reInitialize = (value: T) => {
    this.initialValue = value;
    this.value = value;
    this.isTouched = false;
  };
}

export default SimpleField;
