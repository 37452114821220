import React, { FC, useCallback } from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import type RouteReplayState from 'models/Timeline/RouteReplayState';
import { PlayArrowIcon, PauseIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  disabled?: boolean;
  onPlay: (play: boolean) => void;
  state: RouteReplayState;
}

const PlayButton: FC<IProps> = ({ className, disabled, onPlay, state }) => {
  const togglePlaying = useCallback(() => {
    if (state.isPlaying.value) {
      state.pause(true);
    } else {
      state.play();
    }
    onPlay(state.isPlaying.value);
  }, [onPlay, state]);

  return (
    <Observer
      render={() => {
        const cn = classNames('PlayButton', className, {
          [`PlayButton--play`]: !state.isPlaying.value,
          [`PlayButton--pause`]: state.isPlaying.value,
          [`PlayButton--disabled`]: disabled,
        });

        return (
          <button className={cn} onClick={togglePlaying}>
            <div className="PlayButton-icon">{state.isPlaying.value ? <PauseIcon /> : <PlayArrowIcon />}</div>
            <div className="PlayButton-text">{state.isPlaying.value ? 'Stop Route Replay' : 'Play Route Replay'}</div>
          </button>
        );
      }}
    />
  );
};

export default PlayButton;
