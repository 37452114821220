import React from 'react';
import classnames from 'classnames';

import MapVehiclePanelEventBase from '../MapVehiclePanelEventBase';
import EventStatusText from 'components/EventStatusText';
import StatusMarker from 'components/StatusMarker';
import type { EVENT_STATUS_TYPE } from 'config';

import './styles.scss';

interface IProps {
  arrowColor?: string;
  color?: string;
  currentSpeed: number;
  eventStatusType: EVENT_STATUS_TYPE;
  eventType: string;
  heading?: number;
  label: string;
  subTitle?: string;
  timeDiff?: string;
}

const MapVehiclePanelEventStatus: React.FC<IProps> = ({
  arrowColor,
  color,
  currentSpeed,
  eventStatusType,
  eventType,
  heading,
  label,
  subTitle,
  timeDiff,
}) => {
  const cn = classnames('MapVehiclePanelEventStatus-title', {
    'MapVehiclePanelEventStatus-title--single': label && !subTitle,
  });

  return (
    <MapVehiclePanelEventBase icon={<StatusMarker arrowColor={arrowColor} color={color} heading={heading} size={18} />}>
      {label && (
        <div className={cn}>
          <EventStatusText
            status={eventType}
            statusCodeText={label}
            currentSpeed={currentSpeed}
            eventStatusType={eventStatusType}
            timeDiff={timeDiff}
          />
        </div>
      )}
      {subTitle && <div className="MapVehiclePanelEventStatus-subTitle">{subTitle}</div>}
    </MapVehiclePanelEventBase>
  );
};

export default MapVehiclePanelEventStatus;
