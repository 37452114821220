import React from 'react';
import { Observer } from 'mobx-react';

import { DASHCAM_REQUEST_MEDIA } from 'config';
import UserAccessLevelComponent from 'stores/acl/UserAccessLevelComponent';
import type MediaClips from 'models/Dashcam/MediaLibrary/MediaClips';
import type MediaTypeCategories from 'models/Dashcam/MediaLibrary/MediaType/MediaTypeCategories';
import type MediaVehicleList from 'models/Dashcam/MediaLibrary/MediaSource/MediaVehicleList';
import MediaLibraryFilterLabelList from '../FilterMediaLabelList';
import MediaLibraryRequestMedia from './MediaLibraryRequestMedia';
import MediaLibraryFilterLabelTitle from '../FIlterMediaLabelTitle';

import './styles.scss';

interface IProps {
  clips: MediaClips;
  categories: MediaTypeCategories;
  vehicles: MediaVehicleList;
}

const MediaLibraryHeader: React.FC<IProps> = ({ categories, clips, vehicles }) => {
  return (
    <Observer
      render={() => {
        return (
          <div className="MediaLibraryHeader">
            <div className="MediaLibraryHeader-section MediaLibraryHeader-section--left">
              <div className="MediaLibraryHeader-title">
                <MediaLibraryFilterLabelTitle
                  imageCount={clips.typeTotal.images}
                  imageTotal={categories.mediaTotals.images}
                  videoCount={clips.typeTotal.video}
                  videoTotal={categories.mediaTotals.video}
                />
              </div>
              {Boolean(categories.selected.length) && (
                <div className="MediaLibraryHeader-labels">
                  <MediaLibraryFilterLabelList filters={categories.selected} />
                </div>
              )}
            </div>
            <div className="MediaLibraryHeader-section MediaLibraryHeader-section--right">
              <UserAccessLevelComponent requiredAccessLevel={[DASHCAM_REQUEST_MEDIA]}>
                <div className="MediaLibraryHeader-request">
                  <MediaLibraryRequestMedia vehicles={vehicles} />
                </div>
              </UserAccessLevelComponent>
            </div>
          </div>
        );
      }}
    />
  );
};

export default MediaLibraryHeader;
