import { action, computed, observable } from 'mobx';

class ReportTableGroupVehicle {
  id: string;
  displayName: string;
  description: string;
  @observable open: boolean;
  items: Array<{ value: string; sortValue: string | number }>;
  totals: Array<{ value: string }>;
  formats: Array<{ format: string }>;

  constructor(props) {
    this.id = props.id;
    this.displayName = props.displayName;
    this.description = props.description;
    this.open = props.open;
    this.items = props.items;
    this.totals = props.totals;
    this.formats = props.formats;
  }

  @action
  toggleOpen = () => {
    this.open = !this.open;
  };

  @action
  updateOpen = (value: boolean) => {
    this.open = value;
  };

  @computed get totalsIndexes() {
    return this.totals
      ? this.totals.reduce((acc, curr, index) => {
          return [...acc, !!curr.value ? this.formats[index] : undefined];
        }, [])
      : [];
  }
}

export default ReportTableGroupVehicle;
