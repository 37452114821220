import React from 'react';
import classNames from 'classnames';

import { getTimeInTimeZone } from 'utils';

import { DATE_TIME_FORMATS } from 'config';

import './styles.scss';

interface IProps {
  className?: string;
  date: { from: number; to: number; timezone?: string };
}

const TimeTitle: React.FC<IProps> = ({ className, date: { from, to, timezone = '' } }) => {
  const isDateApplied = Boolean(to);
  const cn = classNames('TimeTitle', className, {
    'TimeTitle--active': isDateApplied,
  });

  const renderLiveTimeTitle = () => <span className="TimeTitle-time TimeTitle-time--live">LIVE</span>;

  const convertedTo = getTimeInTimeZone(to, timezone);
  const convertedFrom = getTimeInTimeZone(from, timezone);

  const renderTimeTitle = () => (
    <span className="TimeTitle-time TimeTitle-time--applied">
      {convertedTo.format(DATE_TIME_FORMATS.monthSlashDateSlashYearSpaceFullHourFullMinAm)}
    </span>
  );

  const renderTimeRangeTitle = () => (
    <div className="TimeTitle-appliedRange">
      <span className="TimeTitle-appliedRangeFrom">
        <span className="TimeTitle-time TimeTitle-time--applied">
          {convertedFrom.format(DATE_TIME_FORMATS.monthSlashDateSlashYearSpaceFullHourFullMinAm)}
        </span>
      </span>
      <span className="TimeTitle-appliedRangeDivider">-</span>
      <span className="TimeTitle-appliedRangeTo">
        {Boolean(to) ? (
          <span className="TimeTitle-time TimeTitle-time--applied">
            {convertedTo.format(DATE_TIME_FORMATS.monthSlashDateSlashYearSpaceFullHourFullMinAm)}
          </span>
        ) : (
          <span className="TimeTitle-time TimeTitle-time--now">LIVE</span>
        )}
      </span>
    </div>
  );

  return (
    <div className={cn}>
      {Boolean(!to) && renderLiveTimeTitle()}
      {Boolean(from && to) && renderTimeRangeTitle()}
      {Boolean(!from && to) && renderTimeTitle()}
    </div>
  );
};

export default TimeTitle;
