import React, { FC } from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
  message: string;
  title: string;
}

const AdminContentWrapper: FC<IProps> = ({ className, title, message, children }) => {
  const cn = classNames('AdminContentWrapper', className);

  return (
    <div className={cn}>
      <p className="AdminContentWrapper-title">{title}</p>
      <p className="AdminContentWrapper-message">{message}</p>
      <div className="AdminContentWrapper-content">{children}</div>
    </div>
  );
};

export default AdminContentWrapper;
