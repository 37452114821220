import { observable, action } from 'mobx';

import AppFile from './AppFile';
import type { IAppFile } from './IAppFile';
import type { IAppFileList } from './IAppFileList';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type { IFileServerModel } from './IFileModel';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { ToggleField } from 'models/Fields';

const DOCUMENTS_PATH = 'documents';

class AppFileList implements IAppFileList {
  @observable list: IAppFile[] = [];
  rootId: number;
  rootName: string;
  repository: IRepository;
  repositoryDocuments: IEntityRepository;
  isUpdated: ToggleField;

  constructor(repository: IRepository, fileModels: IFileServerModel[], root: { id: number; name: string }) {
    this.rootId = root.id;
    this.rootName = root.name;
    this.isUpdated = new ToggleField(false);
    this.repository = repository;
    this.repositoryDocuments = this.repository.entity(DOCUMENTS_PATH);
    this.initList(fileModels);
  }

  fetch = async () => {
    const response = await this.repositoryDocuments.get({ [this.rootName]: this.rootId });

    if (this.repositoryDocuments.getState.success && response?.items) {
      this.initList(response.items);
    }
  };

  @action initList = (fileModels: IFileServerModel[]) => {
    this.list = fileModels.map((model) => new AppFile(this.repository.entity(DOCUMENTS_PATH), model));
    this.isUpdated.toggle(false);
  };

  @action add = async (file: File, isUploadRequired = true): Promise<void> => {
    const item = await new AppFile(this.repository.entity(DOCUMENTS_PATH)).attach(file);

    this.list.push(item);
    this.isUpdated.toggle(true);

    if (isUploadRequired) {
      await item.upload();
    }
  };

  @action remove = (file: IAppFile): void => {
    const index = this.list.findIndex((listFile) => listFile === file);

    this.list.splice(index, 1);
    this.isUpdated.toggle(true);
  };

  @action reset = (): void => {
    this.list = [];
    this.isUpdated.toggle(true);
  };

  @action upload = (): void => {
    this.list.forEach((file) => file.upload());
  };

  get model() {
    return this.list.filter((item) => item.model.id).map((item) => item.data);
  }
}

export default AppFileList;
