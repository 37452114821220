import Repository from './Repository';

const defaultVersion = 'v1.0';

export class RepositoryService {
  private urlRoot: string = '';

  constructor(urlRoot: string) {
    this.urlRoot = urlRoot;
  }

  public get = (repositoryName: string, version: string = defaultVersion): Repository => {
    return new Repository([this.urlRoot, version, repositoryName].filter(Boolean).join('/'));
  };
}

export const repositoryService = new RepositoryService(`${process.env.REACT_APP_API_HOST}`);
