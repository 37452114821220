import React, { useState, useEffect } from 'react';
import { Observer } from 'mobx-react';
import { PingingIcon } from 'assets';

import type RequestMediaCamerasType from 'models/Dashcam/MediaRequestCheckout/RequestMedia/RequestMediaCamerasType';
import type PlayerImage from 'models/Dashcam/MediaRequestCheckout/Player/PlayerImage';
import MediaManagerPlayerCameraTypeSwitcher from '../MediaManagerPlayer/MediaManagerPlayerCameraTypeSwitcher';
import type PlayerState from 'models/Dashcam/MediaRequestCheckout/Player/PlayerState';
import ErrorPlayerState from 'models/Dashcam/MediaRequestCheckout/Player/ErrorPlayerState';
import BasePlayerState from 'models/Dashcam/MediaRequestCheckout/Player/BasePlayerState';
import PauseImagePlayerState from 'models/Dashcam/MediaRequestCheckout/Player/PauseImagePlayerState';

import './styles.scss';

interface IProps {
  cameraType: RequestMediaCamerasType;
  playerImage: PlayerImage;
  startTime: number;
  state: PlayerState;
  isLoading: boolean;
  dataImage: string;
}

const PREVIEW_STATE = {
  IMAGE: 'image',
  UNAVAILABLE: 'unavailable',
  CLICK_TO_PREVIEW: 'clickToPreview',
};

const PreviewSmartWitness: React.FC<IProps> = ({ cameraType, playerImage, startTime, state, isLoading, dataImage }) => {
  const [stateContainer, setStateContainer] = useState(PREVIEW_STATE.UNAVAILABLE);

  const showImage = async () => {
    await playerImage.fetch();
    setStateContainer(PREVIEW_STATE.IMAGE);
  };

  useEffect(() => {
    if (startTime) {
      setStateContainer(PREVIEW_STATE.CLICK_TO_PREVIEW);
    }
  }, [startTime]);

  useEffect(() => {
    if (state instanceof ErrorPlayerState) {
      setStateContainer(PREVIEW_STATE.UNAVAILABLE);
    } else if (state instanceof BasePlayerState || state instanceof PauseImagePlayerState) {
      setStateContainer(PREVIEW_STATE.CLICK_TO_PREVIEW);
    }
  }, [state]);

  return (
    <Observer
      render={() => (
        <div className="PreviewSmartWitness">
          <div className="PreviewSmartWitness-header">
            <div className="PreviewSmartWitness-headerTitle">
              <p>Timestamp thumbnail</p>
            </div>
            <div className="PreviewSmartWitness-headerSwitcher">
              <MediaManagerPlayerCameraTypeSwitcher cameraType={cameraType} isLoading={isLoading} />
            </div>
          </div>
          <div className="PreviewSmartWitness-content">
            {isLoading && (
              <div className="PreviewSmartWitness-loading">
                <PingingIcon width={30} height={30} />
              </div>
            )}

            {stateContainer === PREVIEW_STATE.CLICK_TO_PREVIEW && (
              <div className="PreviewSmartWitness-contentLayout">
                <p className="PreviewSmartWitness-contentLink" onClick={showImage}>
                  Click to Preview
                </p>
              </div>
            )}

            {stateContainer === PREVIEW_STATE.UNAVAILABLE && (
              <div className="PreviewSmartWitness-contentLayout">
                <p className="PreviewSmartWitness-contentUnavailable">Preview Unavailable</p>
              </div>
            )}

            {stateContainer === PREVIEW_STATE.IMAGE && (
              <div className="MediaManagerPlayerDisplay">
                {dataImage && <img src={`data:image/jpeg;base64,${dataImage}`} />}
              </div>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default PreviewSmartWitness;
