import React from 'react';

import './styles.scss';

interface IProps {
  name: string;
}

const GroupTableVehiclesEmpty: React.FC<IProps> = ({ name }) => {
  return (
    <div className="GroupTableVehiclesEmpty">
      <div className="GroupTableVehiclesEmpty-header">
        <span>{name}</span> is currently empty
      </div>
      <div className="GroupTableVehiclesEmpty-footer">
        Use the vehicle list to the right to add vehicles to this group.
      </div>
    </div>
  );
};

export default GroupTableVehiclesEmpty;
