import React, { FC } from 'react';

import ConfirmationModal from 'components/Admin/ConfirmationModal';
import CalendarWithInput from 'components/CalendarWithInput';

import './styles.scss';

interface IProps {
  title: string;
  isOpen?: boolean;
  onCancel: () => void;
  onSubmit?: () => void;
  isSubmitDisabled?: boolean;
  onChange?: (value: number) => void;
  value?: number;
}

const ResetMetricsModal: FC<IProps> = ({ title, isOpen, isSubmitDisabled, onCancel, onSubmit, onChange, value }) => {
  return (
    <ConfirmationModal
      applyButtonTitle="Reset"
      cancelButtonStyles={{ border: 'none' }}
      isOpen={isOpen}
      isSubmitDisabled={isSubmitDisabled}
      onCancel={onCancel}
      onSubmit={onSubmit}
      title={title}
    >
      <div className="ResetMetricsModal">
        <div className="ResetMetricsModal-calendar">
          <CalendarWithInput
            isInputEditable={false}
            onSubmit={onChange}
            placement="left"
            value={value || undefined}
            withTodayLabel
          />
        </div>
        <div className="ResetMetricsModal-text">
          Metrics will be reset overnight and will be recalculated from the date selected to today.
        </div>
      </div>
    </ConfirmationModal>
  );
};

export default ResetMetricsModal;
