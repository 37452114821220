import { action, observable } from 'mobx';

import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import PeopleAdminTable from 'models/Admin/People/Tables';
import PersonModel from 'models/Admin/People/PersonModel';
import { SimpleField } from 'models/Fields';
import { repositoryService } from 'services';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import PeopleUIModel from 'models/Admin/People/PeopleUIModel';
import { RequestType } from 'services/RepositoryService/RequestsType';

export class PeopleAdmin {
  @observable items: PersonModel[];
  item: SimpleField<PersonModel>;
  table: PeopleAdminTable;
  total: SimpleField<number>;
  ui: PeopleUIModel;
  repository: IRepository;
  repositorySendWelcomeEmail: IEntityRepository;
  repositoryEmailPasswordReset: IEntityRepository;
  repositoryPassword: IEntityRepository;

  constructor() {
    this.items = [];
    this.item = new SimpleField(null);
    this.table = new PeopleAdminTable(this);
    this.total = new SimpleField(0);
    this.ui = new PeopleUIModel();
    this.repository = repositoryService.get('users');
    this.repositorySendWelcomeEmail = this.repository.entity('welcome-email');
    this.repositoryEmailPasswordReset = this.repository.entity('reset-password-email');
    this.repositoryPassword = this.repository.entity('password');
  }

  @action fetch = async ({
    order,
    page,
    pageSize,
    search,
    sortBy,
  }: {
    order?: string;
    page?: number;
    pageSize?: number;
    search?: { [key: string]: string };
    sortBy?: string;
  }) => {
    const response = await this.repository.get({ order, page, pageSize, ...search, sortBy });

    this.items = response.items.map((item) => new PersonModel(item, this));
    this.total.set(response.total);
  };

  setItem = (data) => {
    this.item.set(new PersonModel(data, this));
  };

  select = async (id) => {
    if (!id) {
      return;
    }

    try {
      const response = await this.repository.entity(id).get();

      this.item.set(new PersonModel(response, this));
    } catch (e) {
      return e;
    }
  };

  find = (id: number) => this.items.find((item) => item.id.value === id);

  onUpdate = (data) => {
    this.item.set(new PersonModel(data, this));
  };

  @action sendWelcomeEmail = async (loginEmail: string) => {
    try {
      await this.repositorySendWelcomeEmail.create({}, {}, { loginEmail });
    } catch (e) {
      return e;
    }
  };

  @action sendResetPasswordEmail = async (loginEmail: string) => {
    try {
      await this.repositoryEmailPasswordReset.create({}, {}, { loginEmail });
    } catch (e) {
      return e;
    }
  };

  @action updatePassword = async (userId: string, password: string) => {
    await this.repositoryPassword.do(userId, { newPassword: password }, { requestType: RequestType.PUT });
  };
}

export default new PeopleAdmin();
