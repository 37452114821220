class MaintenanceLoggerInfo {
  creationTime: number;
  createdBy: string;
  lastUpdateTime: number;
  updatedBy: string;

  constructor({ creationTime, createdBy, lastUpdateTime, updatedBy }) {
    this.creationTime = creationTime;
    this.createdBy = createdBy;
    this.lastUpdateTime = lastUpdateTime;
    this.updatedBy = updatedBy;
  }
}

export default MaintenanceLoggerInfo;
