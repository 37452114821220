import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import { get } from 'lodash';
import noop from 'lodash/noop';

import { ADMIN_GROUPS_TABLE_COLUMNS, PATHS, STORAGE_ITEMS } from 'config';
import { setJSONItemToStorage, getJSONItemFromStorage } from 'utils';
import type { GroupsAdmin, ITableGroup } from 'stores/Admin/Groups';
import type { TimeStore, UserStore } from 'stores';

import AdminContentWrapper from 'components/Admin/ContentWrapper';
import CustomTable from 'components/Table/CustomTable';
import TableActions from 'components/Table/Actions';
import AddNewGroupModal from '../AddNewGroup';
import DeleteGroupModal from '../DeleteGroup';
import Button from 'components/Button';
import TableDownloadModal from 'components/Table/TableDownloadModal';
import TableEmailModal from 'components/Table/TableEmailModal';

import './styles.scss';

interface IProps {
  className?: string;
  groupsAdmin?: GroupsAdmin;
  userStore?: UserStore;
  timeStore?: TimeStore;
}

interface IState {
  addNewGroup: boolean;
  deleteGroup: boolean;
  groupToDelete: ITableGroup;
  isFilterOpen: boolean;
  isDownloadModalOpen: boolean;
  isEmailModalOpen: boolean;
}

const editColumnsMap = {
  [ADMIN_GROUPS_TABLE_COLUMNS.NAME]: {
    text: 'Group Name',
    isDisabled: true,
  },
  [ADMIN_GROUPS_TABLE_COLUMNS.COUNT]: {
    text: 'Vehicle Count',
    isDisabled: true,
  },
  [ADMIN_GROUPS_TABLE_COLUMNS.CREATED_BY]: {
    text: 'Created By',
    isDisabled: false,
  },
  [ADMIN_GROUPS_TABLE_COLUMNS.CREATION_TIME]: {
    text: 'Time Created',
    isDisabled: false,
  },
  [ADMIN_GROUPS_TABLE_COLUMNS.UPDATED_BY]: {
    text: 'Modified By',
    isDisabled: false,
  },
  [ADMIN_GROUPS_TABLE_COLUMNS.LAST_UPDATE_TIME]: {
    text: 'Last Modified',
    isDisabled: false,
  },
};

@inject(({ adminStore: { groupsAdmin }, userStore, timeStore }) => ({
  groupsAdmin,
  userStore,
  timeStore,
}))
@observer
class AdminGroupsTable extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      addNewGroup: false,
      deleteGroup: false,
      groupToDelete: null,
      isFilterOpen: getJSONItemFromStorage(STORAGE_ITEMS.admin.filterOpenStatus, true),
      isDownloadModalOpen: false,
      isEmailModalOpen: false,
    };
  }

  getColumns = (): ColumnsType<ITableGroup> | any => {
    const {
      groupsAdmin: {
        columns,
        sortedColumn: { field, order },
      },
      userStore: { userData },
    } = this.props;
    const nameColumnProps: ColumnType<ITableGroup> = {
      dataIndex: ADMIN_GROUPS_TABLE_COLUMNS.NAME,
      defaultSortOrder: field === ADMIN_GROUPS_TABLE_COLUMNS.NAME ? order : undefined,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: noop,
    };
    const countColumnProps: ColumnType<ITableGroup> = {
      dataIndex: ADMIN_GROUPS_TABLE_COLUMNS.COUNT,
      defaultSortOrder: field === ADMIN_GROUPS_TABLE_COLUMNS.COUNT ? order : undefined,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: noop,
    };
    const allColumns = [
      {
        title: 'Group Name',
        ...nameColumnProps,
        children: [
          {
            title: 'All Vehicles',
            ...nameColumnProps,
            render: (_, { id, name }) => <Link to={PATHS.ADMIN.TABLES.GROUPS.GROUP.replace(':id', id)}>{name}</Link>,
            width: 250,
          },
        ],
      },
      {
        title: 'Vehicle Count',
        ...countColumnProps,
        hiddenSearch: true,
        children: [
          {
            title: get(userData, 'vehiclesCount', ''),
            ...countColumnProps,
          },
        ],
      },
      {
        title: 'Created By',
        dataIndex: ADMIN_GROUPS_TABLE_COLUMNS.CREATED_BY,
        className: 'ant-table-cell--actions',
        hiddenSearch: true,
        children: [
          {
            title: '',
            dataIndex: ADMIN_GROUPS_TABLE_COLUMNS.CREATED_BY,
            width: 180,
          },
        ],
      },
      {
        title: 'Time Created',
        dataIndex: ADMIN_GROUPS_TABLE_COLUMNS.CREATION_TIME,
        className: 'ant-table-cell--actions',
        hiddenSearch: true,
        children: [
          {
            title: '',
            dataIndex: ADMIN_GROUPS_TABLE_COLUMNS.CREATION_TIME,
            width: 180,
          },
        ],
      },
      {
        title: 'Modified By',
        dataIndex: ADMIN_GROUPS_TABLE_COLUMNS.UPDATED_BY,
        className: 'ant-table-cell--actions',
        hiddenSearch: true,
        children: [
          {
            title: '',
            dataIndex: ADMIN_GROUPS_TABLE_COLUMNS.UPDATED_BY,
            width: 180,
          },
        ],
      },
      {
        title: 'Last Modified',
        dataIndex: ADMIN_GROUPS_TABLE_COLUMNS.LAST_UPDATE_TIME,
        className: 'ant-table-cell--actions',
        hiddenSearch: true,
        children: [
          {
            title: '',
            dataIndex: ADMIN_GROUPS_TABLE_COLUMNS.LAST_UPDATE_TIME,
            width: 180,
          },
        ],
      },
    ];
    const filteredColumns = [];

    columns.forEach((column) => {
      if (column.isSelected) {
        filteredColumns.push(allColumns.find((col) => col.dataIndex === column.value));
      }
    });

    return [
      ...filteredColumns,
      {
        title: 'Actions',
        dataIndex: 'actions',
        className: 'ant-table-cell--actions',
        children: [
          {
            dataIndex: 'actions',
            render: (_, group) => {
              return (
                <TableActions items={[{ text: 'Delete Group', onClick: () => this.handleDeleteGroupClick(group) }]} />
              );
            },
            width: 180,
          },
        ],
      },
    ];
  };

  get isShowHeader() {
    const {
      groupsAdmin: { hasActiveSearch, groupsListTotal, isSearchChanged },
    } = this.props;

    return isSearchChanged || hasActiveSearch || Boolean(groupsListTotal);
  }

  setDeletedGroupId = (groupToDelete: ITableGroup, callback?: () => void) => this.setState({ groupToDelete }, callback);

  handleOpenAddNewGroupModal = () => this.setState({ addNewGroup: true });

  handleCloseAddNewGroupModal = () => this.setState({ addNewGroup: false });

  handleOpenDeleteGroupModal = () => this.setState({ deleteGroup: true });

  handleCloseDeleteGroupModal = () => {
    this.setState({ deleteGroup: false });
    this.setDeletedGroupId(null);
  };

  handleDeleteGroupClick = (groupToDelete: ITableGroup) => {
    this.setDeletedGroupId(groupToDelete, this.handleOpenDeleteGroupModal);
  };

  handleOpenFilter = (isFilterOpen: boolean) => {
    this.setState({ isFilterOpen });
    setJSONItemToStorage(STORAGE_ITEMS.admin.filterOpenStatus, isFilterOpen);
  };

  closeDownloadModal = () => {
    this.setState({ isDownloadModalOpen: false });
  };

  openDownloadModal = () => {
    this.setState({ isDownloadModalOpen: true });
  };

  closeEmailModal = () => {
    this.setState({ isEmailModalOpen: false });
  };

  openEmailModal = () => {
    this.setState({ isEmailModalOpen: true });
  };

  render() {
    const {
      className,
      groupsAdmin: {
        columns,
        deleteGroupAPIRequestStatus,
        getGroupsList,
        repositoryGroups,
        groupsListTotal,
        pagination,
        searchColumns,
        searchData,
        setPagination,
        setSearchColumn,
        setSortedColumn,
        setColumns,
        sortData,
        tableSource,
        downloadGroups,
      },
    } = this.props;
    const { addNewGroup, deleteGroup, groupToDelete, isFilterOpen, isDownloadModalOpen, isEmailModalOpen } = this.state;
    const cn = classNames('AdminGroupsTable', className, {
      'AdminGroupsTable--withOpenFilters': isFilterOpen,
    });
    const tableHeight = isFilterOpen ? 'calc(100vh - 330px)' : 'calc(100vh - 301px)';

    return (
      <AdminContentWrapper
        className="AdminContentWrapper--groupsTable"
        message="Create asset groups and edit existing group information and membership."
        title="Group Admin"
      >
        {
          <div className={cn}>
            <div className="AdminGroupsTable-addGroup">
              <Button
                className="Button--apply"
                inline
                onClick={this.handleOpenAddNewGroupModal}
                title="Create Group"
                type="button"
              />
            </div>
            <div className="AdminGroupsTable-table">
              <CustomTable<ADMIN_GROUPS_TABLE_COLUMNS, ITableGroup>
                columns={this.isShowHeader ? this.getColumns() : []}
                dataSource={tableSource}
                getData={getGroupsList}
                editColumnsMap={editColumnsMap}
                isFilterActive={isFilterOpen}
                loading={repositoryGroups.getState.loading}
                onColumnsChange={setColumns}
                onColumnSort={setSortedColumn}
                onColumnsSearch={setSearchColumn}
                triggerUpdate={deleteGroupAPIRequestStatus.success}
                onFilterOpen={this.handleOpenFilter}
                onPaginationChange={setPagination}
                pagination={pagination}
                searchColumns={searchColumns}
                searchParams={searchData}
                selectedColumns={toJS(columns)}
                sortedColumn={sortData}
                tableHeight={tableHeight}
                total={groupsListTotal}
                totalTitle={groupsListTotal ? `${groupsListTotal} Groups` : ''}
                emptyText={
                  this.isShowHeader ? (
                    'No Results Found'
                  ) : (
                    <div className="AdminGroupsTableHeader-noResults">
                      Groups will appear here.
                      <span className="AdminGroupsTableHeader-noResults--create">
                        Click{' '}
                        <Button
                          title="Create Group"
                          className="Button--link"
                          onClick={this.handleOpenAddNewGroupModal}
                        />{' '}
                        to get started.
                      </span>
                    </div>
                  )
                }
                showHeader={this.isShowHeader}
                withFilter={this.isShowHeader}
                withDownload={this.isShowHeader}
                withEmail={this.isShowHeader}
                openDownloadModal={this.openDownloadModal}
                openEmailModal={this.openEmailModal}
                withActions
              />
            </div>
            <AddNewGroupModal isOpen={addNewGroup} onCancel={this.handleCloseAddNewGroupModal} />
            <DeleteGroupModal
              groupId={groupToDelete && groupToDelete.id}
              groupName={groupToDelete && groupToDelete.name}
              isOpen={deleteGroup}
              onCancel={this.handleCloseDeleteGroupModal}
              onSuccess={this.handleCloseDeleteGroupModal}
            />
            <TableDownloadModal<GroupsAdmin>
              isOpen={isDownloadModalOpen}
              onClose={this.closeDownloadModal}
              tableDownload={downloadGroups}
            />
            <TableEmailModal<GroupsAdmin>
              isOpen={isEmailModalOpen}
              onClose={this.closeEmailModal}
              tableDownload={downloadGroups}
            />
          </div>
        }
      </AdminContentWrapper>
    );
  }
}

export default AdminGroupsTable;
