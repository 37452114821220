import React, { Component } from 'react';
import { observer } from 'mobx-react';
import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';
import { clone } from 'lodash';

interface IOption {
  label: string;
  value: string;
}

interface IProps {
  options: Report.IServerOption[];
  value: string;
  onChange: (value: [string, string]) => void;
  onRedirect: (value: string) => void;
}

@observer
class SearchableReportOptionSelect extends Component<IProps> {
  private value: IOption;
  private values: IOption[];
  private internalOptions: any;

  constructor(props) {
    super(props);

    this.internalOptions = this.adaptData();
    this.value = { value: this.props.value, label: this.props.value };
    this.values = this.internalOptions.map((option) => ({ value: option.name, label: option.displayName }));
  }

  componentDidMount() {
    this.setFirstOptionAsSelected();
  }

  setFirstOptionAsSelected = () => {
    const [first] = this.values;
    const selected = this.values.find((item) => item.value && item.value === this.value.label);

    this.onSelect(selected || first);
  };

  adaptData = () => {
    const options = clone(this.props.options);

    options.forEach((option) => {
      if (!option.name) {
        option.name = option.displayName;
      }
    });

    return options;
  };

  onSelect = ({ value, label }) => {
    const option = this.internalOptions.find((option) => option.name === value);

    if (option?.report?.reportId) {
      this.props.onRedirect(option.report.reportId);
    } else {
      this.props.onChange([value, label]);
    }

    this.value = { value, label };
  };

  render() {
    return (
      <SearchableStaticSelect
        isSearchable={Boolean(false)}
        value={this.value}
        values={this.values}
        handleChange={this.onSelect}
      />
    );
  }
}

export default SearchableReportOptionSelect;
