import { action, computed, observable } from 'mobx';
import MaintenanceModel from '../MaintenanceModel';

export interface IServerMaintenanceTasksItem {
  name: string;
  status?: string;
  id: number;
}

export interface IMaintenanceTasksItem {
  name: string;
  id: string;
  maintenanceModel: MaintenanceModel;

  hasDateOnly: boolean;
  hasMilesOnly: boolean;
  hasHoursOnly: boolean;
  hasMilesAndDate: boolean;
  hasHoursAndDate: boolean;
}

class MaintenanceTasksItem implements IMaintenanceTasksItem {
  @observable name: string;
  @observable id: string;

  @observable maintenanceModel: MaintenanceModel;

  constructor(data) {
    this.name = data.name || '';
    this.id = data.id ? data.id.toString() : '';
    this.maintenanceModel = new MaintenanceModel(data, null);
  }

  @action set = ({ name, id }) => {
    this.name = name;
    this.id = id;
  };

  get model() {
    return {
      name: this.name,
      id: Number(this.id),
    };
  }

  @computed get hasHours() {
    return this.maintenanceModel?.engine?.hoursDueInDays !== 0;
  }

  @computed get hasService() {
    return this.maintenanceModel?.service?.timeDueInDays !== 0;
  }

  @computed get hasMiles() {
    return this.maintenanceModel?.odometer?.dueInDays !== 0;
  }

  @computed get hasDateOnly() {
    return !this.hasMiles && this.hasService && !this.hasHours;
  }

  @computed get hasMilesOnly() {
    return this.hasMiles && !this.hasService && !this.hasHours;
  }
  @computed get hasHoursOnly() {
    return !this.hasMiles && !this.hasService && this.hasHours;
  }

  @computed get hasMilesAndDate() {
    return this.hasMiles && this.hasService && !this.hasHours;
  }

  @computed get hasHoursAndDate() {
    return !this.hasMiles && this.hasService && this.hasHours;
  }
}

export default MaintenanceTasksItem;
