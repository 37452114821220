import React from 'react';
import AlertPreferenceFinalizeSimpleLine from '../AlertPreferenceFinalizeSimpleLine';

interface IProps {
  label?: string;
  type?: Alerts.Modal.WorkHours.Type;
}

const AlertPreferenceFinalizeAfterHoursLine: React.FC<IProps> = ({ label, type }) => {
  const value = type === 'IGNITION_ON' ? 'Ignition on' : 'Moving';
  return <AlertPreferenceFinalizeSimpleLine label={label} value={value} />;
};

export default AlertPreferenceFinalizeAfterHoursLine;
