import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';

import type { ReportsStore } from 'stores';

import { REPORTS_STORE } from 'config';

import type TableFilter from 'models/Tables/TableFilter';
import TableColumnHeader from 'components/Table/ColumnHeader';
import { Tooltip } from 'antd';
import { CircleErrorIcon } from 'assets';

import './styles.scss';

interface IProps {
  reportsStore?: ReportsStore;
  className?: string;
  withControls?: boolean;
  expanded?: boolean;
  isFilterOpen?: boolean;
  isLoading?: boolean;
  onChange?: () => void;
  columns: any[];
  filter: TableFilter;
  hasLimitError?: boolean;
}

@inject(REPORTS_STORE)
@observer
class ReportTableByGroupHeader extends Component<IProps, null> {
  handleChangeFilter = (value, key) => {
    const { filter } = this.props;
    const item = filter.search.value.find((item) => item.value.column === key);

    if (item) {
      item.value.value = value;
    } else {
      filter.search.add({ column: key, value });
    }
  };

  render() {
    const {
      className,
      withControls,
      expanded,
      onChange,
      columns,
      filter,
      isFilterOpen,
      isLoading,
      hasLimitError,
      reportsStore: { selectedReport, reportHeaderOptionsText, reportHeaderTimeRangeText },
    } = this.props;

    const cn = classNames('ReportTableHeaderTitle', className);

    const filters = filter.search.value.reduce((acc, item) => {
      return {
        ...acc,
        [item.value.column]: item.value.value,
      };
    }, {});

    return (
      <>
        <div className={cn}>
          {withControls && (
            <div
              className={classNames('ReportTableHeaderTitle-section ReportTableHeaderTitle-section--toggle', {
                'ReportTableHeaderTitle-section--disabled': isLoading,
              })}
              onClick={onChange}
            >
              {expanded ? (
                <MinusSquareOutlined className="ReportTableHeaderTitle-rowExpandIcon ReportTableHeaderTitle-rowExpandIcon--minus" />
              ) : (
                <PlusSquareOutlined className="ReportTableHeaderTitle-rowExpandIcon ReportTableHeaderTitle-rowExpandIcon--plus" />
              )}
            </div>
          )}
          <div className="ReportTableHeaderTitle-section ReportTableHeaderTitle-section--reportName">
            {hasLimitError && (
              <span className="ReportTableHeaderTitle-limit">
                <Tooltip
                  placement="right"
                  title="This report has reached it's record display limit and may only contain a portion of the possible data"
                >
                  <CircleErrorIcon />
                </Tooltip>
              </span>
            )}
            <h4 className="ReportTableHeaderTitle-reportName">{selectedReport.title} Report</h4>
          </div>
          {Boolean(reportHeaderOptionsText) && (
            <div className="ReportTableHeaderTitle-section ReportTableHeaderTitle-section--options">
              <p className="ReportTableHeaderTitle-sectionTitle">Options</p>
              <p className="ReportTableHeaderTitle-sectionText">{reportHeaderOptionsText}</p>
            </div>
          )}
          {Boolean(reportHeaderTimeRangeText) && (
            <div className="ReportTableHeaderTitle-section ReportTableHeaderTitle-section--timeRange">
              <p className="ReportTableHeaderTitle-sectionTitle">Time Range</p>
              <p className="ReportTableHeaderTitle-sectionText">{reportHeaderTimeRangeText}</p>
            </div>
          )}
        </div>
        {isFilterOpen && (
          <div className="ReportTableHeaderFilters ant-table-header">
            <table>
              <thead className="ant-table-thead">
                <tr>
                  {columns &&
                    columns.map((column) => {
                      const key = column?.dataIndex || '';
                      return (
                        <th className={`ant-table-cell ant-table-cell--${key}`} key={key}>
                          <TableColumnHeader
                            title={String(column.title || '')}
                            value={filters[key]}
                            onChange={(value) => this.handleChangeFilter(value, key)}
                            showInput={isFilterOpen}
                            open={isFilterOpen}
                          />
                        </th>
                      );
                    })}
                </tr>
              </thead>
            </table>
          </div>
        )}
      </>
    );
  }
}

export default ReportTableByGroupHeader;
