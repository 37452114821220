import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import { getValidMaintenanceName, validateName } from 'utils';
import classNames from 'classnames';
import { ACL } from 'config';
import { validateAccessLevel } from 'stores';

import type { SimpleField } from 'models/Fields';
import type MaintenanceModalUI from 'models/Maintenances/UI/MaintenanceModalUI/MaintenanceModalUI';

import Input from 'components/Input';

import './styles.scss';

interface IProps {
  name: SimpleField<string>;
  ui: MaintenanceModalUI;
}

const MaintenancePreferenceTaskName: React.FC<IProps> = ({ name, ui }) => {
  const edit = validateAccessLevel([ACL.MAINTENANCE.BASIC.UPDATE]);
  const handleChange = (e) => {
    const allowedName = getValidMaintenanceName(e.currentTarget.value);

    name.set(allowedName);
    name.setIsValid(validateName(allowedName));
  };

  const handleFocus = () => {
    ui.isTaskNameError.toggle(false);
  };

  useEffect(() => {
    name.setIsValid(validateName(name.value));
  }, []);

  return (
    <Observer
      render={() => {
        const inputCn = classNames('MaintenancePreferenceTaskName-input', {
          'MaintenancePreferenceTaskName-input--error': ui.isTaskNameError.value,
        });

        return (
          <div className="MaintenancePreference-line MaintenancePreferenceTaskName">
            <div className="MaintenancePreference-label">Task name</div>
            <div className="MaintenancePreference-value">
              <Input
                isFocus
                className={inputCn}
                placeholder="Maintenance Task Name"
                onFocus={handleFocus}
                value={name.value}
                onChange={handleChange}
                disabled={!edit}
              />
              {ui.isTaskNameError.value && (
                <p className="MaintenancePreferenceTaskName-error">Task Name is a required field</p>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export default MaintenancePreferenceTaskName;
