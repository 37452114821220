import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { CustomersStore } from 'stores';

import SecondaryPanelAttributesRow from '../../../SecondaryPanelAttributes/SecondaryPanelAttributesRow';
import SecondaryPanelAttributesCol from '../../../SecondaryPanelAttributes/SecondaryPanelAttributesCol';

interface IProps {
  customersStore?: CustomersStore;
}


@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class SecondaryPanelBillingAddress extends Component<IProps> {
  get selectedCustomer() {
    return this.props.customersStore.selectedCustomer;
  }

  get address() {
    const address = this.selectedCustomer?.selectedInvoice?.billAddress;
    if (address && (address.line1 || address.line2) && address.city) {
      return <div>
        <div>{address.line1 || ''}{address.line2 || ''}</div>
        <div>{address.city}, {address.countrySubDivisionCode} {address.postalCode} {address.country}</div>
      </div>
    }
    return null;
  }

  get invoice () {
    return this.selectedCustomer?.selectedInvoice;
  }

  get email () {
    return this.invoice.billEmail || this.invoice.billEmailCc || this.selectedCustomer?.email;
  }

  render() {
    const address = this.address;

    return <div className="SecondaryPanelServiceAddress">
      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Address</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{address ?? '-'}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>
      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Email</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>
          {
            this.email
              ? (<a href="mailto:selectedCustomer.email">{this.email}</a>)
              : '-'
          }
        </SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>
    </div>;
  }
}

export default SecondaryPanelBillingAddress;
