import type { IRole } from './User';
import { ListField, SimpleField, ToggleField } from '../Fields';
import { getValidUSPhoneNumber } from 'utils';

export interface IUserGroupItem {
  displayName?: string;
  id: string;
  description?: string;
}

export default class UserModel {
  accessRestrictedByGroups: ListField<IUserGroupItem>;
  activeStatus: ToggleField;
  cellPhone: SimpleField<string>;
  contactPhone: SimpleField<string>;
  contactPhoneExt: SimpleField<string>;
  id: SimpleField<string>;
  jobTitle: SimpleField<string>;
  lastLogin: SimpleField<number>;
  loginEmail: SimpleField<string>;
  name: SimpleField<string>;
  notes: SimpleField<string>;
  role: SimpleField<string>;
  roles: ListField<IRole>;
  timeZone: SimpleField<string>;
  userId: SimpleField<string>;
  groupAccessRestricted: ToggleField;
  createdBy: SimpleField<string>;
  updatedBy: SimpleField<string>;
  creationTime: SimpleField<number>;
  lastUpdateTime: SimpleField<number>;

  constructor({
    accessRestrictedByGroups,
    active,
    contactName,
    cellPhone,
    contactPhone,
    contactPhoneExt,
    id,
    jobTitle,
    lastLogin,
    loginEmail,
    notes,
    roleId,
    roles,
    timeZone,
    userId,
    createdBy,
    updatedBy,
    creationTime,
    lastUpdateTime,
  }: User.IAppServerUser) {
    this.accessRestrictedByGroups =
      accessRestrictedByGroups && new ListField(UserModel.setAccessRestrictedByGroups(accessRestrictedByGroups));
    this.activeStatus = new ToggleField(active);
    this.cellPhone = new SimpleField(getValidUSPhoneNumber(cellPhone));
    this.contactPhone = new SimpleField(getValidUSPhoneNumber(contactPhone));
    this.contactPhoneExt = new SimpleField(contactPhoneExt);
    this.id = new SimpleField(String(id));
    this.jobTitle = new SimpleField(jobTitle);
    this.lastLogin = new SimpleField(lastLogin);
    this.loginEmail = new SimpleField(loginEmail);
    this.name = new SimpleField(contactName);
    this.notes = new SimpleField(notes);
    this.role = new SimpleField(roleId);
    this.roles = new ListField<IRole>(roles);
    this.timeZone = new SimpleField(timeZone);
    this.userId = new SimpleField(userId);
    this.createdBy = new SimpleField(createdBy);
    this.updatedBy = new SimpleField(updatedBy);
    this.creationTime = new SimpleField(creationTime);
    this.lastUpdateTime = new SimpleField(lastUpdateTime);
    this.groupAccessRestricted = new ToggleField(Boolean(this.accessRestrictedByGroups?.value?.length));
  }

  static setAccessRestrictedByGroups = (groups: User.IAccessRestrictedGroup[]): IUserGroupItem[] => {
    return groups.map((group) => ({ displayName: group.displayName, id: group.id, description: group.description }));
  };
}
