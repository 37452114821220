import React from 'react';
import Button from 'components/Button';
import InfoTooltip from 'components/InfoTooltip';

import './styles.scss';

interface IProps {
  onClick: () => void;
}

const EditCardAssociations: React.FC<IProps> = ({ onClick }) => {
  return (
    <div className="EditCardAssociations">
      <Button title="Edit Card Associations" onClick={onClick} className="Button--link EditCardAssociations-button" />
      <InfoTooltip className="EditCardAssociations-infoTooltip">
        Associating cards to Vehicles allows fueling events to be tagged as exceptions. An exception occurs when a card
        is used and the assigned vehicle is in a different location.
      </InfoTooltip>
    </div>
  );
};

export default EditCardAssociations;
