import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import type { UserStore } from 'stores';
import Modal from 'components/Modal';
import ChangePasswordForm from 'components/Auth/ChangePassword';

import './styles.scss';

interface IProps {
  className?: string;
  onClose?: () => void;
  onSubmit?: () => void;
  isModalOpen?: boolean;
  userStore?: UserStore;
}

@inject(({ userStore }) => ({
  userStore,
}))
@observer
class ChangePassword extends Component<IProps> {
  componentWillUnmount() {
    const {
      userStore: { repositoryUsersPassword },
    } = this.props;

    repositoryUsersPassword.putState.reset();
  }

  handleSubmit = async (currentPassword: string, newPassword: string) => {
    const {
      onClose,
      userStore: {
        changePassword,
        repositoryUsersPassword: { putState },
      },
    } = this.props;

    await changePassword(currentPassword, newPassword);

    if (putState.success) {
      onClose();
    } else if (putState.error) {
      throw new Error(putState.error);
    }
  };

  render() {
    const { className, isModalOpen, onClose } = this.props;
    const cn = classNames('ChangePassword', 'Modal--changePassword', className);

    return (
      <Modal className={cn} isModalOpen={isModalOpen} onClose={onClose}>
        <p className="ChangePassword-title">Change Password</p>
        <div className="ChangePassword-form">
          <ChangePasswordForm onSubmit={this.handleSubmit} onCancel={onClose} />
        </div>
      </Modal>
    );
  }
}

export default ChangePassword;
