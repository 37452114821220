import React, { useState } from 'react';
import { Observer } from 'mobx-react';
import { validateAccessLevel } from 'stores';
import { ACL } from 'config';

import type { IServerServiceTypeItem, default as ServiceTypeItem } from 'models/Maintenances/Models/Service/ServiceTypeItem';
import type ServiceType from 'models/Maintenances/Models/Service/ServiceType';
import type MaintenanceModalUI from 'models/Maintenances/UI/MaintenanceModalUI/MaintenanceModalUI';

import SearchableSelectAsync from 'components/Select/SearchableSelectAsync';
import Button from 'components/Button';
import InfoTooltip from 'components/InfoTooltip';
import Input from 'components/Input/AntInput';

import './styles.scss';

const LEGACY_TASK = 'Legacy Task';

interface IProps {
  serviceType: ServiceType;
  ui: MaintenanceModalUI;
}

const MaintenancePreferenceServiceType: React.FC<IProps> = ({ serviceType, ui }) => {
  const edit = validateAccessLevel([ACL.MAINTENANCE.BASIC.UPDATE]);

  const [showConvertLink, setShowConvertLink] = useState(true);

  const changeServiceType = (option: Select.ISelectOption) => {
    serviceType.item.set({ id: option.value, name: option.label });
  };

  const convertToNewTask = () => {
    ui.isServiceTypeDisabled.toggle(false);
    ui.isFrequencyDisabled.toggle(false);
    setShowConvertLink(false);
  };

  return (
    <Observer
      render={() => {
        return (
          <div className="MaintenancePreference-line MaintenancePreferenceServiceType">
            <div className="MaintenancePreference-label">Service type</div>
            <div className="MaintenancePreference-value">
              <div className="MaintenancePreferenceServiceType-select">
                {ui.isServiceTypeDisabled.value || !edit ? (
                  <Input value={serviceType.item.name} disabled />
                ) : (
                  <SearchableSelectAsync<ServiceTypeItem, IServerServiceTypeItem>
                    handleChange={changeServiceType}
                    keys={{ value: 'id', label: 'name' }}
                    options={serviceType}
                    value={
                      serviceType.item.name === LEGACY_TASK
                        ? { value: '', label: '' }
                        : { value: serviceType.item.id, label: serviceType.item.name }
                    }
                    hasBadgeExtended
                    defaultMenuIsOpen={Boolean(serviceType.item.name === LEGACY_TASK)}
                  />
                )}
              </div>
              {showConvertLink && serviceType.item.name === LEGACY_TASK && (
                <div className="MaintenancePreferenceServiceType-convert">
                  <Button title="Convert to New Task" className="Button--link" onClick={convertToNewTask} />
                  <InfoTooltip>
                    This one time conversion will make the Service Type editable and will retain all information related
                    to this Task.
                  </InfoTooltip>
                </div>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export default MaintenancePreferenceServiceType;
