import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { GeozonesAdmin } from 'stores/Admin/Geozones';
import ConfirmationModal from 'components/Admin/ConfirmationModal';

import './styles.scss';

interface IProps {
  className?: string;
  geozoneId: string;
  geozoneName: string;
  geozonesAdmin?: GeozonesAdmin;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}

@inject(({ adminStore: { geozonesAdmin } }) => ({
  geozonesAdmin,
}))
@observer
class DeleteGeozoneModal extends Component<IProps> {
  handleDeleteGeozone = async () => {
    const {
      geozoneId,
      geozonesAdmin: { deleteGeozoneRequestStatus, deleteGeozone },
      onSuccess,
    } = this.props;

    await deleteGeozone(geozoneId);

    if (deleteGeozoneRequestStatus.success) {
      onSuccess();
    }
  };

  render() {
    const {
      isOpen,
      onCancel,
      geozoneName,
      geozoneId,
      geozonesAdmin: { deleteGeozoneRequestStatus },
    } = this.props;

    return (
      <ConfirmationModal
        applyButtonTitle="Yes, Delete"
        cancelButtonStyles={{ border: 'none' }}
        contentStyles={{ padding: '0 20px', backgroundColor: '#fff' }}
        isOpen={isOpen}
        isSubmitDisabled={deleteGeozoneRequestStatus.loading}
        onCancel={onCancel}
        onSubmit={this.handleDeleteGeozone}
        title={`Delete ${geozoneName || geozoneId} Geozone?`}
        withoutCloseIcon
      >
        <div className="DeleteGeozoneModal">
          <p className="DeleteGeozoneModal-text DeleteGeozoneModal-text--top">
            Deleting this Geozone will permanently remove it from the system and it will no longer be accessible.
          </p>
          <p className="DeleteGeozoneModal-text DeleteGeozoneModal-text--bottom">Do you want to continue?</p>
        </div>
      </ConfirmationModal>
    );
  }
}

export default DeleteGeozoneModal;
