import React from 'react';
import { Observer } from 'mobx-react';
import { CloseIcon, ReorderIcon } from 'assets';
import classNames from 'classnames';

import type { ToggleField } from 'models/Fields';
import type InspectionItem from 'models/Inspections/Models/InspectionItem';

import Input from 'components/Input';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Note from 'components/Note';

import './styles.scss';

interface IProps {
  item: InspectionItem;
  index: number;
  isError: ToggleField;
  disabledDelete: boolean;
  onDeleteItem: (index: number) => void;
}

const InspectionsFormPanelItem: React.FC<IProps> = ({ item, index, isError, disabledDelete, onDeleteItem }) => {
  const changeName = (e) => {
    item.name.set(e.currentTarget.value);

    if (e.currentTarget.value.trim().length) {
      item.name.setIsValid(true);
    } else {
      item.name.setIsValid(false);
    }
  };

  const deleteNote = () => {
    item.isInstructionVisible.toggle(false);
    item.instruction.set('');
  };

  const onDelete = () => {
    if (disabledDelete) {
      return;
    }

    onDeleteItem(index);
  };

  return (
    <Observer
      render={() => {
        const inputCn = classNames('InspectionsFormPanelItem-cardNameInput', {
          'InspectionsFormPanelItem-cardNameInput--error': isError.value && !item.name.isValid,
        });

        const deleteCN = classNames('InspectionsFormPanelItem-close', {
          'InspectionsFormPanelItem-close--disabled': disabledDelete,
        });

        return (
          <div className="InspectionsFormPanelItem">
            <div className="InspectionsFormPanelItem-icon">
              <ReorderIcon className="InspectionsFormPanelItem-iconImg" />
            </div>
            <div className="InspectionsFormPanelItem-card">
              <div className="InspectionsFormPanelItem-cardName">
                <Input
                  placeholder="Enter Item"
                  value={item.name.value}
                  onChange={changeName}
                  className={inputCn}
                  onFocus={() => isError.toggle(false)}
                />
                {isError.value && !item.name.isValid && (
                  <p className="InspectionsFormPanelItem-error">At least one item is required</p>
                )}
              </div>
              <div className="InspectionsFormPanelItem-cardInstruction">
                {item.isInstructionVisible.value ? (
                  <div className="InspectionsFormPanelItem-cardInstructionNote">
                    <Note
                      className="InspectionsFormPanelItem-cardInstructionNoteText"
                      note={item.instruction.value}
                      isEditing
                      onChange={item.instruction.set}
                      placeholder="Enter Instruction"
                      hideControls
                      countCharactersEntered
                    />
                    <CloseIcon
                      onClick={deleteNote}
                      className="InspectionsFormPanelItem-cardInstructionNoteClose"
                      width={16}
                      height={16}
                      fill="#6B7A99"
                    />
                  </div>
                ) : (
                  <Button
                    className="Button--link"
                    title="+ Add Instruction"
                    onClick={() => item.isInstructionVisible.toggle(true)}
                  />
                )}
              </div>
              <div className="InspectionsFormPanelItem-cardCheckboxes">
                <label className="InspectionsFormPanelItem-cardCheckboxesLabel">Require</label>
                <Checkbox
                  label="Pass/Fail"
                  checked={item.requirePassFail.value}
                  onChange={item.toggleRequirePassFail}
                />
                <Checkbox label="Note" checked={item.requireNote.value} onChange={item.toggleRequireNote} />
                <Checkbox label="Photo" checked={item.requirePhoto.value} onChange={item.toggleRequirePhoto} />
              </div>
            </div>
            <div className={deleteCN}>
              <CloseIcon className="InspectionsFormPanelItem-closeIcon" onClick={onDelete} />
            </div>
          </div>
        );
      }}
    />
  );
};

export default InspectionsFormPanelItem;
