import { DATE_TIME_FORMATS } from 'config';
import { observable } from 'mobx';
import { TimeField } from 'models/Fields';

import type { IntegrationDomain, IntegrationStatus, IIntegration } from 'interfaces/stores/Integrations/IIntegration';
import type { IIntegrationsStore } from 'interfaces/stores/Integrations/IIntegrationsStore';

abstract class Integration implements IIntegration {
  created: TimeField;
  name: string = '';
  provider: string = '';
  description: string = '';
  domain: IntegrationDomain;
  lastSyncDate: TimeField;
  context: IIntegrationsStore;
  hasCustomers: boolean = false;

  @observable status: IntegrationStatus = 'DISCONNECTED';

  constructor({ name, provider, description, domain, status, lastSyncDate, created }) {
    this.created = new TimeField(created || 0).setFormat(DATE_TIME_FORMATS.monthDatYearFull);
    this.name = name;
    this.provider = provider;
    this.description = description;
    this.domain = domain;
    this.status = status;
    this.lastSyncDate = new TimeField(lastSyncDate || 0).setFormat(DATE_TIME_FORMATS.monthDatYearFull);
  }

  initialize = (context: IIntegrationsStore) => {
    this.context = context;
    return this;
  };

  abstract connect(): void;

  abstract disconnect(): void;
}

export default Integration;
