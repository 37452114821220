import React, { FC } from 'react';
import classNames from 'classnames';
import { FEATURES } from 'config';

import ModeFilter from 'components/Filters/ModeFilter';
import SearchFilter from 'components/Filters/SearchFilter';
import TableActions from 'components/Table/Actions';
import HeaderIcon from 'components/Table/HeaderIcon';

import { DownloadIcon, EmailIcon, FiltersIcon, LoaderIcon, PrintIcon } from 'assets';
import './styles.scss';

interface IProps {
  actionsList?: Array<{ text: string; onClick?(): void; link?: string }>;
  className?: string;
  isFilterActive?: boolean;
  leftContent?: React.ReactNode;
  onEditTable?: () => void;
  onOpenDownload?: () => void;
  onPrint?: () => void;
  onOpenEmail?: () => void;
  onOpenFilter?: () => void;
  onSearchChange?: (value: string) => void;
  onTableModeChange?(mode: UI.panelMode): void;
  searchQuery?: string;
  tableMode?: UI.panelMode;
  totalTitle?: React.ReactNode | string;
  withActions?: boolean;
  withDownload?: boolean;
  withEmail?: boolean;
  withFilter?: boolean;
  withSearch?: boolean;
  withPrint?: boolean;
  isLoading?: boolean;
  progress?: string;
}

const TableHeader: FC<IProps> = ({
  actionsList = [],
  className,
  isFilterActive,
  leftContent,
  onEditTable,
  onOpenDownload,
  onOpenEmail,
  onOpenFilter,
  onSearchChange,
  onTableModeChange,
  searchQuery,
  tableMode,
  totalTitle,
  withActions,
  withDownload,
  withEmail,
  withFilter,
  withSearch,
  isLoading = true,
  progress = '',
  onPrint,
  withPrint,
}) => {
  const cn = classNames('TableHeader', className);

  return (
    <div className={cn}>
      <div className="TableHeader-title">
        <div className="TableHeader-left">
          {withSearch && (
            <div className="TableHeader-search">
              <SearchFilter
                className="SearchFilter--adminVehiclesTable"
                inline
                onChange={onSearchChange}
                placeholder="Search Table"
                value={searchQuery}
              />
            </div>
          )}
          <div className="TableHeader-leftContent">{leftContent}</div>
          <div className="TableHeader-total">{Boolean(totalTitle) && totalTitle}</div>
        </div>
        <div className="TableHeader-right">
          {isLoading && (
            <>
              <span className="TableHeader-progress">
                <LoaderIcon stroke="#4285F4" />
                <span className="TableHeader-progressText">{progress}</span>
              </span>
            </>
          )}
          {FEATURES.downloadEmailReport && withPrint && (
            <div
              className={classNames('TableHeader-action TableHeader-action--print', {
                'TableHeader-action--disabled': isLoading,
              })}
            >
              <HeaderIcon icon={<PrintIcon />} iconText="Print" onClick={onPrint} />
            </div>
          )}
          {FEATURES.downloadEmailReport && withEmail && (
            <div
              className={classNames('TableHeader-action TableHeader-action--email', {
                'TableHeader-action--disabled': isLoading,
              })}
            >
              <HeaderIcon
                icon={<EmailIcon style={{ width: '18px', height: '18px' }} />}
                iconText="Email"
                onClick={onOpenEmail}
              />
            </div>
          )}
          {FEATURES.downloadEmailReport && withDownload && (
            <div
              className={classNames('TableHeader-action TableHeader-action--download', {
                'TableHeader-action--disabled': isLoading,
              })}
            >
              <HeaderIcon icon={<DownloadIcon />} iconText="Download" onClick={onOpenDownload} />
            </div>
          )}
          {tableMode && (
            <div
              className={classNames('TableHeader-action TableHeader-action--mode', {
                'TableHeader-action--disabled': isLoading,
              })}
            >
              <ModeFilter mode={tableMode} onChange={onTableModeChange} />
            </div>
          )}
          {withFilter && (
            <div
              className={classNames('TableHeader-action TableHeader-action--filter', {
                'TableHeader-action--disabled': isLoading,
              })}
            >
              <HeaderIcon selected={isFilterActive} icon={<FiltersIcon />} iconText="Filter" onClick={onOpenFilter} />
            </div>
          )}
          {withActions && (
            <div
              className={classNames('TableHeader-action TableHeader-action--more', {
                'TableHeader-action--disabled': isLoading,
              })}
            >
              <TableActions items={[{ text: 'Edit Table Columns', onClick: onEditTable }, ...actionsList]} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
