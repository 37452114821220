import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';
import { get } from 'lodash';

import { REPORTS_STORE } from 'config';
import type { ReportsStore } from 'stores';

import MapPopover from 'components/Reports/MapPopover';

interface IProps {
  reportsStore?: ReportsStore;
  item: {
    value: string;
    sortValue?: number;
    mapLink: { lat: number; lon: number; timestamp?: number; deviceId?: string };
  };
  className: string;
}

@inject(REPORTS_STORE)
@observer
class ReportTableByGroupRowEventCellLink extends Component<IProps> {
  get deviceId() {
    return get(
      this.props.reportsStore.latestReportPreferences.find((pref) => pref[0] === 'deviceId'),
      '[1][0]',
      ''
    );
  }

  render() {
    const {
      reportsStore: {
        mapLinkMapType,
        setMapLinkMapType,
        vehiclesIdsWithCamera,
        reportTableGroup: {
          tableStructure: { requestMediaAllowed },
        },
      },
      item,
      className,
    } = this.props;

    let requestMedia = {};

    if (requestMediaAllowed) {
      const deviceId = this.deviceId || item.mapLink.deviceId;
      requestMedia = {
        deviceId,
        timestamp: item.mapLink.timestamp,
        isCameraConnected: vehiclesIdsWithCamera.toArray().includes(deviceId),
      };
    }

    return (
      <div className={classnames(className, 'ReportTableByGroupRowEvent-Link')}>
        <MapPopover
          location={{ lat: item.mapLink.lat, lng: item.mapLink.lon }}
          mapType={mapLinkMapType}
          setMapType={setMapLinkMapType}
          {...requestMedia}
        >
          {item.value}
        </MapPopover>
      </div>
    );
  }
}

export default ReportTableByGroupRowEventCellLink;
