import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Table } from 'antd';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';

import ExpandableTable from '../ExpandableTable';

import 'antd/dist/antd.css';
import './styles.scss';

const type = 'Dispatches';

const DispatchRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      // @ts-ignore
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      // @ts-ignore
      const record = item.children[0].props.record;
      // @ts-ignore
      moveRow(item.index, index, record);
    },
  });
  const [, drag] = useDrag({
    item: { type, index, ...restProps },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => {
      return restProps.children[0].props.record.status !== 'completed';
    },
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

const DateDispatchesTable = ({ dispatches, columns, date, driverId, moveRow }) => {
  const dispatchesData = useMemo(
    () =>
      dispatches.map(({ order, address, completeTime, notes, fieldNotes, status, dispatchId }, index) => {
        const isCurrentNotCompleted = status !== 'completed';
        const isFirstNotCompletedDispatch = !index && isCurrentNotCompleted;
        const isPrevDispatchCompleted = index && dispatches[index - 1].status === 'completed';
        const isNextDispatchToDo = isFirstNotCompletedDispatch || (isPrevDispatchCompleted && isCurrentNotCompleted);

        return {
          key: index,
          sort: index,
          address,
          completeTime,
          date,
          notes,
          fieldNotes,
          order,
          status,
          dispatchId,
          isNext: isNextDispatchToDo,
        };
      }),
    [dispatches, date]
  );

  const [data, setData] = useState(dispatchesData);

  const components = {
    body: {
      row: DispatchRow,
    },
  };

  useEffect(() => {
    setData(dispatchesData);
  }, [dispatches]);

  const handleMoveRow = useCallback(
    (dragIndex, hoverIndex, record) => {
      const dragRow = data[dragIndex];
      moveRow({
        id: record.dispatchId,
        toDriverId: driverId,
        toDate: date,
        withOrder: hoverIndex + 1,
      });
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [data]
  );

  const onRow = (_, index) => ({
    index,
    moveRow: handleMoveRow,
  });

  // @ts-ignore
  return <Table pagination={false} columns={columns} dataSource={data} components={components} onRow={onRow} />;
};

const DateRows = ({ dates, dateColumns, dispatchColumns, driverId, driverName, moveRow }) => {
  const data = useMemo(
    () =>
      dates.map(({ date, dispatches }, key) => ({
        key,
        dataIndex: 'date',
        date,
        dispatches,
        driverId,
        driverName,
        dispatchesLength: dispatches.length,
      })),
    [dates, driverId, driverName]
  );

  return (
    <ExpandableTable
      columns={dateColumns}
      data={data}
      rowClassName="DispatchTable-dateRow"
      childrenRowClassName="DispatchTable-dispatchesTable"
      ExpandedTableComponent={({ dispatches, date, driverId, ...props }) => (
        <DateDispatchesTable
          columns={dispatchColumns}
          moveRow={moveRow}
          date={date}
          driverId={driverId}
          dispatches={dispatches}
          {...props}
        />
      )}
    />
  );
};

const DispatchDriversTable = ({ data, dispatchColumns, dateColumns, driverColumns, moveRow, loading }) => {
  const driversData = useMemo(
    () =>
      data.map(({ driverName, driverId, dates }, key) => ({
        key,
        dataIndex: 'name',
        name: driverName,
        driverId,
        dates,
        driverName,
      })),
    [data]
  );

  return (
    <ExpandableTable
      columns={driverColumns}
      data={driversData}
      loading={loading}
      rowClassName="DispatchTable-driverRow"
      childrenRowClassName="DispatchTable-datesTable"
      ExpandedTableComponent={({ dates, driverId, driverName, ...props }) => (
        <DateRows
          dates={dates}
          dateColumns={dateColumns}
          dispatchColumns={dispatchColumns}
          driverId={driverId}
          driverName={driverName}
          moveRow={moveRow}
          {...props}
        />
      )}
    />
  );
};

export default DispatchDriversTable;
