import React, { FC } from 'react';
import classNames from 'classnames';

import HeaderIcon from 'components/Table/HeaderIcon';

import { CompactModeIcon, FullModeIcon } from 'assets';

interface IProps {
  className?: string;
  disabled?: boolean;
  mode?: UI.panelMode;
  onChange?: (value: UI.panelMode) => void;
}

const ModeFilter: FC<IProps> = ({ className, disabled, mode = 'full', onChange }) => {
  const cn = classNames('ModeFilter', className, {
    [`ModeFilter--${mode}`]: Boolean(mode),
    'ModeFilter--disabled': disabled,
  });

  const toggleMode = () => {
    onChange(mode === 'full' ? 'compact' : 'full');
  };

  return (
    <div className={cn}>
      <HeaderIcon
        icon={mode === 'full' ? <CompactModeIcon /> : <FullModeIcon />}
        iconText="Mode"
        onClick={toggleMode}
      />
    </div>
  );
};

export default ModeFilter;
