import { SimpleField } from 'models/Fields';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import PersonDetailsContactsLoginCredentials from './PersonDetailsContacts/PersonDetailsContactsLoginCredentials';

class PersonDetailsContactsModel {
  email: PersonDetailsContactsLoginCredentials;
  mobilePhone: PersonDetailsContactsLoginCredentials;
  officePhone: SimpleField<string>;
  officePhoneExt: SimpleField<string>;

  constructor(item, id) {
    this.email = new PersonDetailsContactsLoginCredentials(
      item.loginEmail || '',
      { entity: 'email', param: 'loginEmail' },
      id
    );
    this.mobilePhone = new PersonDetailsContactsLoginCredentials(
      item.cellPhone || '',
      { entity: 'cellphone', param: 'cellPhone' },
      id
    );

    this.officePhone = new SimpleField(item.contactPhone || '', isPossiblePhoneNumber(item?.contactPhone || ''));
    this.officePhoneExt = new SimpleField(item.contactPhoneExt || '');
  }

  get data() {
    return {
      loginEmail: this.email.data,
      cellPhone: this.mobilePhone.data,
      contactPhone: this.officePhone.value,
      contactPhoneExt: this.officePhoneExt.value,
    };
  }

  get isValid() {
    return this.officePhone.isValid || this.officePhone.isEmpty;
  }

  get isUpdated() {
    return (
      this.email.isUpdated || this.mobilePhone.isUpdated || this.officePhone.isUpdated || this.officePhoneExt.isUpdated
    );
  }

  reset = () => {
    this.email.reset();
    this.mobilePhone.reset();
    this.officePhone.reset();
    this.officePhoneExt.reset();
  };
}

export default PersonDetailsContactsModel;
