import { action, observable } from 'mobx';
import type IAPIStoreBase from 'interfaces/stores/IAPIStoreBase';

export class APIStoreBase implements IAPIStoreBase {
  @observable loading: boolean = false;
  @observable success: boolean = false;
  @observable error: string | null = null;

  @action setLoading = (loading: boolean) => (this.loading = loading);

  @action setSuccess = (success: boolean) => (this.success = success);

  @action setError = (error: string | null) => (this.error = error);

  @action reset = () => {
    this.loading = false;
    this.error = null;
    this.success = false;

    return this;
  };
}

export default APIStoreBase;
