import React from 'react';
import { ListItemPinHoverIconSrcIcon, ListItemPinnedIcon, ListItemPinPinnedIconSrcIcon } from 'assets';
import { observer } from 'mobx-react';

interface IReportListItemPin {
  pinned: boolean;
  virtual?: boolean;
  className: string;

  onClick?(e: React.MouseEvent<HTMLElement>): void;
}

@observer
class ReportListItemPin extends React.Component<IReportListItemPin, null> {
  render() {
    const { pinned, onClick, className, virtual } = this.props;
    return (
      <div onClick={onClick}>
        {pinned ? (
          virtual ? (
            <ListItemPinPinnedIconSrcIcon />
          ) : (
            <ListItemPinnedIcon className={className} />
          )
        ) : (
          <ListItemPinHoverIconSrcIcon />
        )}
      </div>
    );
  }
}

export default ReportListItemPin;
