import { SimpleField } from 'models/Fields';
import Peer from 'simple-peer';
import { Client, StompSubscription } from '@stomp/stompjs';
import { getCookie } from 'utils';
import { TOKEN_COOKIE_NAME } from 'config/cookie';
import type RequestMediaCamerasType from '../RequestMedia/RequestMediaCamerasType';
import type RequestMediaTimeSegment from '../RequestMedia/RequestMediaTimeSegment';
import type PlayerViewModel from './Player';
import { PLAYER_ERROR_TYPE } from 'config';
import type CameraModel from '../VehicleDetails/CameraModel';
import type { IPlayerVideo } from 'interfaces/models/Dashcams/IPlayerVideo';

const PEER_ERROR_IGNORE_CODE = 'ERR_DATA_CHANNEL';

class PlayerVideo implements IPlayerVideo {
  public url: SimpleField<string>;
  private peerClient: Peer;
  private sock: Client;
  private wss: string;
  private token: string;
  private cameraModel: CameraModel;
  private cameraType: RequestMediaCamerasType;
  private timeSegment: RequestMediaTimeSegment;
  private context: PlayerViewModel;
  private subscribeId: StompSubscription;

  constructor(context: PlayerViewModel) {
    this.url = new SimpleField(null);
    this.context = context;
    this.wss = `${process.env.REACT_APP_WS_HOST}/v2.0/media/streaming`;
    this.token = getCookie(TOKEN_COOKIE_NAME);
    this.cameraModel = this.context.cameraModel;
    this.cameraType = this.context.cameraType;
    this.timeSegment = this.context.timeSegment;
    this.subscribeId = null;
    this.sock = new Client({
      brokerURL: this.wss + '?Authorization=' + this.token,
      connectHeaders: this.headers,
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,
    });
    this.sock.onWebSocketError = () => {
      this.context.onError(PLAYER_ERROR_TYPE.VIDEO);
    };
  }

  private initializePeer = () => {
    this.peerClient = new Peer({ trickle: false });

    this.peerClient.on('signal', (data) => this.sendCommand(data));
    this.peerClient.on('data', (data) => {
      this.peerClient.signal(data);
    });
    this.peerClient.on('stream', (stream) => {
      if (stream.getAudioTracks().length) {
        this.context.isAudioEnabled.toggle(true);
      }
      this.url.set(stream);
    });
    this.peerClient.on('error', (error) => {
      if (error.code === PEER_ERROR_IGNORE_CODE) {
        return;
      }
      this.context.onError(PLAYER_ERROR_TYPE.VIDEO);
    });

    return this;
  };

  public connect = () => {
    this.initializePeer();
    this.sock.activate();

    if (this.sock.connected) {
      this.onConnect();
    } else {
      this.sock.onConnect = () => this.onConnect();
    }
  };

  public disconnect = async () => {
    if (this.sock.connected) {
      this.closeCommand();
      this.unsubscribe();

      await this.sock.deactivate();
    }
  };

  public switchCameraOnline = () => {
    this.switchCameraCommand();
  };

  private sendCommand = (data) => {
    if (this.sock) {
      this.sock.publish({
        destination: '/app/command',
        body: JSON.stringify({
          command: 'SEND',
          payload: JSON.stringify(data),
        }),
      });
    }
  };

  private switchCameraCommand = () => {
    if (this.sock.connected) {
      this.sock.publish({
        destination: '/app/command',
        body: JSON.stringify({
          command: 'SWITCH_CAMERAS',
          cameraType: this.cameraTypeParam,
        }),
      });
    }
  };

  private initializeCommand = () => {
    const body = {
      command: 'INITIALIZE',
      cameraId: this.cameraModel.id,
      cameraType: this.cameraTypeParam,
    };
    const timestampParam = 'timestamp';

    if (!this.context.liveMode) {
      body[timestampParam] = this.timeSegment.scrubber.value;
    }
    this.sock.publish({
      destination: '/app/command',
      body: JSON.stringify(body),
    });
  };

  private playCommand = () => {
    this.sock.publish({
      destination: '/app/command',
      body: `{"command": "PLAY", "timestamp": ${this.timeSegment.scrubber.value}}`,
    });
  };

  private closeCommand = () => {
    this.sock.publish({
      destination: '/app/command',
      body: '{"command": "CLOSE"}',
    });
  };

  private onConnect = () => {
    this.url.set(null);
    this.subscribe();
    this.initializeCommand();
  };

  private subscribe = () => {
    const path = '/camera/stream';

    this.subscribeId = this.sock.subscribe(
      path,
      (message) => {
        const resp = JSON.parse(message.body);

        if (resp.statusCode === 400) {
          this.context.onError(PLAYER_ERROR_TYPE.VIDEO);
        }

        if (this.peerClient && resp.body.candidates) {
          resp.body.candidates.forEach((candidate) => {
            this.peerClient.signal({ candidate });
          });
          this.peerClient.signal(resp.body.offer);

          if (!this.context.liveMode) {
            this.playCommand();
          }
        }
      },
      this.headers
    );
  };

  private unsubscribe = () => {
    if (this.subscribeId) {
      this.sock.unsubscribe(this.subscribeId.id);
    }
  };

  private getSubscriptionId = () => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  };

  private get cameraTypeParam() {
    return this.cameraType.type.value === 'OUT' ? 'outside' : 'inside';
  }

  private get headers() {
    return {
      'user-agent': window.navigator.userAgent,
      id: this.getSubscriptionId(),
    };
  }
}

export default PlayerVideo;
