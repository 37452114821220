import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  MAINTENANCES_STORE,
  MAINTENANCE_APPLY_TASKS_ROW_LOADING_ID,
  MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS,
  TIME_STORE,
} from 'config';
import { toJS } from 'mobx';
import { InfoIcon, LoaderIcon, RemoveCircleIcon, SuccessIcon } from 'assets';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

import type { Maintenances as MaintenancesStore, TimeStore } from 'stores';

import CustomTable from 'components/Table/CustomTable';
import TableUnSelected from 'components/Table/UnSelected';
import Button from 'components/Button';
import TaskToApplyDueInOnMetrics from 'components/Maintenance/TaskToApply/TaskToApplyDueInOnMetrics';
import TaskToApplyLastService from 'components/Maintenance/TaskToApply/TaskToApplyLastService';
import TaskToApplyNextService from 'components/Maintenance/TaskToApply/TaskToApplyNextService';
import TaskToApplyServiceDueIn from 'components/Maintenance/TaskToApply/TaskToApplyServiceDueIn';

import './styles.scss';

interface IProps {
  [MAINTENANCES_STORE]?: MaintenancesStore;
  [TIME_STORE]?: TimeStore;
}

@inject(({ maintenancesStore, timeStore }) => ({ maintenancesStore, timeStore }))
@observer
class ApplyTasksTable extends Component<IProps> {
  constructor(props) {
    super(props);
  }

  componentDidMount(): void {
    const {
      maintenancesStore: { applyTasks },
    } = this.props;

    applyTasks.removeAll();
    EventsBus.get().on(APP_EVENTS.MAINTENANCE.APPLY_TASKS.TASK.LOADING, this.onAddLoadingRow);
    EventsBus.get().on(APP_EVENTS.MAINTENANCE.APPLY_TASKS.ADDED, this.onAdd);
    EventsBus.get().on(APP_EVENTS.MAINTENANCE.APPLY_TASKS.ALL.ADDED, this.onAddAll);
    EventsBus.get().on(APP_EVENTS.MAINTENANCE.APPLY_TASKS.REMOVED, this.onRemove);
    EventsBus.get().on(APP_EVENTS.GROUPS.GROUP.ASSETS.CHANGED, this.preSelectAssets);
    EventsBus.get().on(APP_EVENTS.MAINTENANCE.APPLY_TASKS.SORT, this.sortApplyTasks);
  }

  componentWillUnmount(): void {
    EventsBus.get().off(APP_EVENTS.MAINTENANCE.APPLY_TASKS.TASK.LOADING, this.onAddLoadingRow);
    EventsBus.get().off(APP_EVENTS.MAINTENANCE.APPLY_TASKS.ADDED, this.onAdd);
    EventsBus.get().off(APP_EVENTS.MAINTENANCE.APPLY_TASKS.ALL.ADDED, this.onAddAll);
    EventsBus.get().off(APP_EVENTS.MAINTENANCE.APPLY_TASKS.REMOVED, this.onRemove);
    EventsBus.get().off(APP_EVENTS.GROUPS.GROUP.ASSETS.CHANGED, this.preSelectAssets);
    EventsBus.get().off(APP_EVENTS.MAINTENANCE.APPLY_TASKS.SORT, this.sortApplyTasks);
  }

  onAddLoadingRow = () => {
    const {
      maintenancesStore: { applyTasks },
    } = this.props;

    applyTasks.addTask({ name: 'loading', id: MAINTENANCE_APPLY_TASKS_ROW_LOADING_ID });
  };

  onAdd = (asset) => {
    const {
      maintenancesStore: { applyTasks },
    } = this.props;

    applyTasks.addTask(asset);
  };

  onAddAll = (assets) => {
    const {
      maintenancesStore: { applyTasks },
    } = this.props;

    applyTasks.addAllTasks(assets);
  };

  onRemove = (data) => {
    const {
      maintenancesStore: { applyTasks },
    } = this.props;

    applyTasks.removeTask(data.id.toString());
  };

  sortApplyTasks = () => {
    const {
      maintenancesStore: { applyTasks },
    } = this.props;

    applyTasks.sort();
  };

  preSelectAssets = (assets) => {
    const {
      maintenancesStore: { applyTasks },
    } = this.props;

    applyTasks.preSelectAssets(assets);
  };

  getColumns = () => {
    const allColumns = [
      {
        title: '',
        dataIndex: MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.REMOVE_TASK,
        width: 50,
        render: (_, { remove, assetId, loading, successSave }) => {
          return (
            <>
              {assetId !== MAINTENANCE_APPLY_TASKS_ROW_LOADING_ID && (
                <div className="ApplyTasksTable-TableRemove">
                  {loading || successSave ? null : (
                    <RemoveCircleIcon onClick={() => remove(assetId)} className="ApplyTasksTable-TableRemoveIcon" />
                  )}
                </div>
              )}
            </>
          );
        },
      },
      {
        title: 'Vehicle Name',
        dataIndex: MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.VEHICLE_NAME,
        width: 150,
        render: (_, { vehicleName, assetId }) => {
          return (
            <>
              {assetId !== MAINTENANCE_APPLY_TASKS_ROW_LOADING_ID && (
                <div className="ApplyTasksTable-TableVehicleName">{vehicleName}</div>
              )}
            </>
          );
        },
      },
      {
        title: 'Last Service (Odometer/Date)',
        dataIndex: MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.LAST_SERVICE,
        width: 280,
        render: (
          _,
          { currentOdometer, currentEngineHours, odometer, assetId, engHours, serviceTime, loading, successSave }
        ) => {
          return (
            <>
              {assetId !== MAINTENANCE_APPLY_TASKS_ROW_LOADING_ID && (
                <TaskToApplyLastService
                  disabled={Boolean(loading || successSave)}
                  currentOdometer={currentOdometer}
                  currentEngineHours={currentEngineHours}
                  odometer={odometer}
                  engHours={engHours}
                  serviceTime={serviceTime}
                />
              )}
            </>
          );
        },
        hidden: !this.isRepeat,
      },
      {
        title: 'Current Odometer',
        dataIndex: MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.CURRENT_ODOMETER,
        render: (_, { currentOdometer, assetId }) => {
          return (
            <>
              {assetId !== MAINTENANCE_APPLY_TASKS_ROW_LOADING_ID ? (
                <p>
                  {currentOdometer?.toLocaleString('en-US', {
                    maximumFractionDigits: 0,
                  })}
                </p>
              ) : (
                this.isRepeat && <LoaderIcon stroke="#4285F4" />
              )}
            </>
          );
        },
        width: this.isRepeat ? 120 : 180,
      },
      {
        title: 'Current Engine Hours',
        dataIndex: MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.CURRENT_ENGINE_HOURS,
        render: (_, { currentEngineHours, assetId }) => {
          return (
            <>
              {assetId !== MAINTENANCE_APPLY_TASKS_ROW_LOADING_ID ? (
                <p>{currentEngineHours?.toLocaleString('en-US', { maximumFractionDigits: 1 })}</p>
              ) : (
                !this.isRepeat && <LoaderIcon stroke="#4285F4" />
              )}
            </>
          );
        },
        width: this.isRepeat ? 120 : 180,
      },
      {
        title: 'Next Service Due In/On',
        dataIndex: MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.NEXT_SERVICE_DUE_IN_ON,
        width: 230,
        render: (_, { currentOdometer, currentEngineHours, odometer, engHours, serviceTime, assetId }) => {
          return (
            <>
              {assetId !== MAINTENANCE_APPLY_TASKS_ROW_LOADING_ID && (
                <TaskToApplyNextService
                  currentOdometer={currentOdometer}
                  currentEngineHours={currentEngineHours}
                  odometer={odometer}
                  engHours={engHours}
                  serviceTime={serviceTime}
                />
              )}
            </>
          );
        },
        hidden: !this.isRepeat,
      },
      {
        title: 'Service Due In',
        dataIndex: MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.SERVICE_DUE_IN,
        width: 200,
        render: (_, { currentOdometer, currentEngineHours, odometer, engHours, serviceTime, assetId }) => {
          return (
            <>
              {assetId !== MAINTENANCE_APPLY_TASKS_ROW_LOADING_ID && (
                <TaskToApplyServiceDueIn
                  currentOdometer={currentOdometer}
                  currentEngineHours={currentEngineHours}
                  odometer={odometer}
                  engHours={engHours}
                  serviceTime={serviceTime}
                />
              )}
            </>
          );
        },
        hidden: this.isRepeat,
      },
      {
        title: '',
        dataIndex: MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.CREATE_TASK,
        width: 100,
        render: (_, { create, assetId, loading, successSave, errorSave, isValid }) => {
          return (
            <>
              {assetId !== MAINTENANCE_APPLY_TASKS_ROW_LOADING_ID && (
                <div className={`ApplyTasksTable-Table${successSave ? 'Success' : errorSave ? 'Error' : 'Create'}`}>
                  {successSave ? (
                    <SuccessIcon />
                  ) : errorSave ? (
                    <div className="ApplyTasksTable-TableRetry">
                      <Button
                        title="Retry"
                        className="Button--inline Button--link ApplyTasksTable-TableRetryButton"
                        sending={loading}
                        onClick={create}
                        disabled={!isValid}
                      />
                      <InfoIcon className="ApplyTasksTable-TableRetryIcon" />
                    </div>
                  ) : (
                    <Button
                      title="Create"
                      className="Button--apply Button--inline ApplyTasksTable-TableCreateButton"
                      sending={loading}
                      onClick={create}
                      disabled={!isValid}
                    />
                  )}
                </div>
              )}
            </>
          );
        },
      },
    ];

    return [...allColumns.filter((col) => !col.hidden)];
  };

  getData = () => {
    return [];
  };

  get isRepeat() {
    const {
      maintenancesStore: {
        applyTasks: { task },
      },
    } = this.props;

    return Boolean(task.value?.model.isRepeat);
  }

  get editColumnsMap() {
    return {
      [MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.VEHICLE_NAME]: {
        text: 'Vehicle Name',
        isDisabled: false,
      },
      [MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.LAST_SERVICE]: {
        text: 'Last Service',
        isDisabled: false,
        isVisible: () => this.isRepeat,
      },
      [MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.CURRENT_ODOMETER]: {
        text: 'Current Odometer',
        isDisabled: false,
      },
      [MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.CURRENT_ENGINE_HOURS]: {
        text: 'Current Engine Hours',
        isDisabled: false,
      },
      [MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.NEXT_SERVICE_DUE_IN_ON]: {
        text: 'Next Service Due In/On',
        isDisabled: false,
        isVisible: () => this.isRepeat,
      },
      [MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.SERVICE_DUE_IN]: {
        text: 'Service Due In',
        isDisabled: false,
        isVisible: () => !this.isRepeat,
      },
    };
  }

  render() {
    const {
      maintenancesStore: {
        applyTasks: {
          task,
          table: {
            source,
            setColumns,
            setSortedColumn,
            setSearchColumn,
            setPagination,
            pagination,
            searchColumns,
            columns,
          },
        },
      },
    } = this.props;

    return (
      <div className="ApplyTasksTable">
        <div className="ApplyTasksTable-Header">
          <div className="ApplyTasksTable-HeaderText">
            <h4 className="ApplyTasksTable-HeaderTextTitle">
              {this.isRepeat ? 'Adjust Last Service' : 'Tasks will be Applied to'}
            </h4>
            <p className="ApplyTasksTable-HeaderTextSubTitle">
              {this.isRepeat ? 'Enter when the last service was performed for each Vehicle' : ''}
            </p>
          </div>
          <div className="ApplyTasksTable-HeaderDueInOnMetrics">
            {Boolean(task.value) && (
              <TaskToApplyDueInOnMetrics task={task.value.representation.applyTaskCard} direction="row" />
            )}
          </div>
        </div>
        <div className="ApplyTasksTable-Table">
          <CustomTable
            columns={this.getColumns()}
            dataSource={source}
            editColumnsMap={this.editColumnsMap}
            getData={this.getData}
            onColumnsChange={setColumns}
            onColumnSort={setSortedColumn}
            onColumnsSearch={setSearchColumn}
            onPaginationChange={setPagination}
            pagination={pagination}
            searchColumns={searchColumns}
            selectedColumns={toJS(columns)}
            tableHeight="calc(100vh - 320px)"
            withFilter={false}
            withPagination={false}
            rowClassName={(record) => record.className}
            emptyText={
              <TableUnSelected
                text={
                  <div className="ApplyTasksTable-TableEmptyText">
                    Select the Vehicles you want to apply {task.value ? task.value.model.service.type.item.name : ''} to
                    and they will appear in this table.
                  </div>
                }
                className="ApplyTasksTable-TableEmpty"
              />
            }
          />
        </div>
      </div>
    );
  }
}

export default ApplyTasksTable;
