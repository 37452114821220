import React from 'react';
import { Observer } from 'mobx-react';
import { MediaNavIcon, PingingIcon } from 'assets';
import classnames from 'classnames';

import DashcamStatusModel, { DASHCAM_STATUS } from 'models/Dashcam/DashcamStatus';

import './styles.scss';

interface IProps {
  dashcamPing: DashcamStatusModel;
}

const DashcamStatus: React.FC<IProps> = ({ dashcamPing }) => {
  const getColor = () => {
    if (dashcamPing.cameraDetails.isBusy.value) {
      return '#FF7F02';
    }

    switch (dashcamPing.status.value) {
      case DASHCAM_STATUS.OFFLINE:
        return '#F44336';
      case DASHCAM_STATUS.ONLINE:
        return '#4CAF50';
      case DASHCAM_STATUS.UNKNOWN:
        return '#6B7A99';
      default:
        return '#6B7A99';
    }
  };

  const getText = () => {
    if (dashcamPing.cameraDetails.isBusy.value) {
      return 'Camera Busy';
    }

    switch (dashcamPing.status.value) {
      case DASHCAM_STATUS.OFFLINE:
        return 'Camera Offline';
      case DASHCAM_STATUS.ONLINE:
        return 'Camera Online';
      case DASHCAM_STATUS.UNKNOWN:
        return 'Camera Status Unknown';
      case DASHCAM_STATUS.PINGING:
        return 'Pinging Camera';
      default:
        return 'Camera Status Unknown';
    }
  };

  return (
    <Observer
      render={() => {
        const cn = classnames(
          `AdminDashcamStatus AdminDashcamStatus--${
            dashcamPing.cameraDetails.isBusy.value ? 'busy' : dashcamPing.status.value
          }`
        );

        return (
          <div className={cn}>
            <p className="AdminDashcamStatus-title">Dashcam Status</p>
            <div className="AdminDashcamStatus-info">
              {dashcamPing.status.value === DASHCAM_STATUS.PINGING ? (
                <PingingIcon width={16} />
              ) : (
                <MediaNavIcon fill={getColor()} width={16} />
              )}
              <span className="AdminDashcamStatus-info--text">{getText()}</span>
            </div>
          </div>
        );
      }}
    />
  );
};

export default DashcamStatus;
