import React, { useCallback, useState } from 'react';
import { Observer } from 'mobx-react';
import classnames from 'classnames';
import { Dropdown, Menu } from 'antd';
import { MediaVideoIcon, MoreIcon } from 'assets';
import type { MapStore } from 'stores';
import { MEDIA_CLIP_STATUS, MEDIA_CLIPS_VIEW } from 'config';

import type MediaClipsItem from 'models/Dashcam/MediaLibrary/MediaClips/MediaClipsItem';
import type MediaTypeCategories from 'models/Dashcam/MediaLibrary/MediaType/MediaTypeCategories';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import Modal from 'components/Modal';
import MediaLibraryModal from '../MediaLibraryModal';
import MediaLibraryClipImage from '../MediaLibraryClipImage';
import MediaLibraryClipRename from '../MediaLibraryClipRename';

import 'react-circular-progressbar/dist/styles.css';
import './styles.scss';

interface IProps {
  clip: MediaClipsItem;
  clips: MediaClipsItem[];
  categories: MediaTypeCategories;
  map: MapStore;
  view?: MEDIA_CLIPS_VIEW;
}

const MediaLibraryClip: React.FC<IProps> = ({ clip, clips, map, categories, view }) => {
  const [isModalRenameOpen, setModelRenameOpen] = useState(false);

  const cnEventTypeItem = useCallback(
    (type) => {
      return classnames('MediaLibraryClip-detailsMainEventTypesListItem', {
        'MediaLibraryClip-detailsMainEventTypesListItem--active': categories.selected
          .map((category) => category.value)
          .includes(type),
        'MediaLibraryClip-detailsMainEventTypesListItem--reduced': clip.eventTypes.value.length > 1,
      });
    },
    [categories.selected]
  );

  const handleMouseEnter = () => {
    clip.interaction.set('hover');
  };
  const handleMouseLeave = () => {
    clip.interaction.set('default');
  };
  const handleClick = () => {
    if (clip.media.front.status.value === MEDIA_CLIP_STATUS.AVAILABLE) {
      clip.interaction.set('active');
    }
  };

  return (
    <Observer
      render={() => {
        const isModalPlayerOpen = clip.interaction.value === 'active';
        const cnClipName = classnames('MediaLibraryClip-detailsMainClipName', {
          'MediaLibraryClip-detailsMainClipName--untitled': !clip.media.front.title.isSet.value,
        });

        return (
          <li
            className={classnames('MediaLibraryClip', {
              [`MediaLibraryClip--${clip.media.front.status.value.toLowerCase()}`]: Boolean(
                clip.media.front.status.value
              ),
              [`MediaLibraryClip--${view}`]: Boolean(view),
            })}
          >
            {clip.media.front && (
              <div
                className={classnames('MediaLibraryClip-wrapper', {
                  'MediaLibraryClip-wrapper--hover': clip.interaction.value === 'hover',
                })}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div className="MediaLibraryClip-tile">
                  {!clip.media.front.mediaFile.downloading.value ? (
                    <div className="MediaLibraryClip-image">
                      <MediaLibraryClipImage infoItem={clip.media.front} />
                    </div>
                  ) : (
                    <div className="MediaLibraryClip-downloadProgress">
                      <div className="MediaLibraryClip-downloadProgressCircle">
                        <CircularProgressbar
                          text={`${clip.media.front.mediaFile.downloadingPercent}%`}
                          value={clip.media.front.mediaFile.downloadingPercent}
                          styles={buildStyles({
                            pathColor: '#FF8801',
                            trailColor: '#EFF1F5',
                            textColor: '#FF8801',
                          })}
                          strokeWidth={10}
                        />
                      </div>
                      <p className="MediaLibraryClip-downloadProgressText">Preparing file...</p>
                    </div>
                  )}
                </div>
                <div className="MediaLibraryClip-details">
                  <div className="MediaLibraryClip-detailsMain">
                    <div
                      className={classnames('MediaLibraryClip-detailsMainDevice', {
                        'MediaLibraryClip-detailsMainDevice--bothNames': Boolean(
                          clip.displayName && clip.driver.displayName
                        ),
                      })}
                    >
                      <div className="MediaLibraryClip-detailsMainDeviceVehicleName">{clip.displayName}</div>
                      <div className="MediaLibraryClip-detailsMainDeviceDriverName">{clip.driver.displayName}</div>
                    </div>
                    <div className="MediaLibraryClip-detailsMainAddress">{clip.address}</div>
                    <div className={cnClipName}>{clip.media.front.title.display.value}</div>
                    <div className="MediaLibraryClip-detailsMainEventTypes">
                      <ul className="MediaLibraryClip-detailsMainEventTypesList">
                        {clip.eventTypes.value.map((eventType) => (
                          <li key={eventType.value} className={cnEventTypeItem(eventType.value)}>
                            {eventType.value}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="MediaLibraryClip-detailsAdditional">
                    <div className="MediaLibraryClip-detailsAdditionalTop">
                      <div className="MediaLibraryClip-detailsAdditionalDate">
                        {clip.media.front.date.monthDateYear}
                      </div>
                      <div className="MediaLibraryClip-detailsAdditionalTime">
                        {clip.media.front.date.hourMinuteSecondAm}
                      </div>
                      {Boolean(clip.media.front.duration.active.value) && (
                        <div className="MediaLibraryClip-detailsAdditionalDuration">
                          <MediaVideoIcon fill="#7C93BF" />
                          <span className="MediaLibraryClip-detailsAdditionalDurationText">{`${clip.media.front.duration.active.value}s`}</span>
                        </div>
                      )}
                    </div>
                    <div className="MediaLibraryClip-detailsAdditionalBottom">
                      <div className="MediaLibraryClip-detailsActions" onClick={(e) => e.stopPropagation()}>
                        <Dropdown
                          trigger={['click']}
                          overlay={
                            <Menu className="MediaLibraryClip-detailsActionList">
                              <Menu.Item
                                className="MediaLibraryClip-detailsActionListItem"
                                key="renameClip"
                                onClick={() => setModelRenameOpen(true)}
                              >
                                Rename Clip
                              </Menu.Item>
                              {clip.media.front.status.value === MEDIA_CLIP_STATUS.AVAILABLE && (
                                <Menu.Item
                                  className="MediaLibraryClip-detailsActionListItem"
                                  key="downloadClip"
                                  onClick={clip.media.front.mediaFile.download}
                                >
                                  Download Clip
                                </Menu.Item>
                              )}
                            </Menu>
                          }
                        >
                          <span className="MediaLibraryClip-detailsActionTrigger">
                            <MoreIcon className="MediaLibraryClip-detailsActionTriggerIcon" />
                          </span>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Modal
              className="MediaLibraryClip-player"
              isModalOpen={isModalPlayerOpen}
              onClose={clip.interaction.reset}
              width={1200}
            >
              <MediaLibraryModal clip={clip} clips={clips} map={map} />
            </Modal>
            <Modal
              className="MediaLibraryClip-rename"
              isModalOpen={isModalRenameOpen}
              onClose={() => setModelRenameOpen(false)}
              width={392}
            >
              <MediaLibraryClipRename onClose={() => setModelRenameOpen(false)} info={clip.media.front} />
            </Modal>
          </li>
        );
      }}
    />
  );
};

export default MediaLibraryClip;
