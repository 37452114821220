import { getBrowserInfo, getUniqueId } from 'utils';
import type { IWebSocketConfigType } from './WebSocketTypes';

export const DEFAULT_VERSION = 'v1.0';

export interface IFallbackStrategyPolling {
  repeat: boolean;
  reconnect: boolean;
}
export interface IFallbackStrategy {
  callback: () => void;
  polling?: IFallbackStrategyPolling;
}

export const DEFAULT_FALLBACK_STRATEGY = {
  callback: () => true,
  polling: { repeat: true, reconnect: true },
};

export enum WS_OPTIONS {
  DELIVERY = 'delivery',
  MEDIA = 'media',
  NOTIFICATION = 'notification',
}

export const WS_CONFIG: IWebSocketConfigType = {
  [WS_OPTIONS.NOTIFICATION]: () => {
    return {
      reconnectDelay: 1000,
      headers: {
        id: getUniqueId(),
      },
    };
  },
  [WS_OPTIONS.DELIVERY]: () => {
    return {
      reconnectDelay: 1000,
      headers: { 'Client-App-Info': getBrowserInfo(), id: getUniqueId() },
    };
  },
  [WS_OPTIONS.MEDIA]: () => {
    return {
      reconnectDelay: 0,
      headers: {
        'user-agent': window.navigator.userAgent,
        id: getUniqueId(),
      },
    };
  },
};
