import { action, observable } from 'mobx';

class ReportTableGroupHeader {
  key: string;
  value: unknown;
  maxText: string;
  @observable width: number;
  @observable visible: boolean;
  searchable: boolean;
  @observable search: string;
  updated: number;
  @observable sortable: boolean;
  valueType: 'string' | 'number' | 'time' | 'datetime';
  @observable sortDirection: 'DESC' | 'ASC' | 'NONE';

  constructor(props) {
    this.key = props.key;
    this.value = props.value;
    this.maxText = props.maxText;
    this.width = props.width;
    this.visible = props.visible;
    this.searchable = props.searchable;
    this.search = props.search;
    this.sortable = props.sortable;
    this.valueType = props.valueType;
    this.sortDirection = props.sortDirection;
    this.updated = 0;
  }

  @action
  updateSearch = (value: string) => {
    this.search = value;
  };

  @action
  updateSortable = (value: boolean) => {
    this.sortable = value;
  };

  @action
  updateVisible = (value: boolean) => {
    this.visible = value;
  };

  @action
  toggleSortDirection = () => {
    if (this.sortable) {
      if (this.sortDirection === 'NONE') {
        this.sortDirection = 'DESC';
        this.updated = Date.now();
      } else if (this.sortDirection === 'DESC') {
        this.sortDirection = 'ASC';
      } else if (this.sortDirection === 'ASC') {
        this.sortDirection = 'NONE';
        this.updated = 0;
      }
    }
  };

  @action
  updateSortDirection = (value: 'DESC' | 'ASC' | 'NONE') => {
    this.sortDirection = value;
  };
}

export default ReportTableGroupHeader;
