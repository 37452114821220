import { SatelliteCount1, SatelliteCount2, SatelliteCount3, SatelliteCount4, SatelliteCount5 } from 'assets';

export const arrayToObj = (array: Array<{}>, key: string): object => {
  return array.reduce((obj, item) => {
    if (!(item[key] in obj)) {
      obj[item[key]] = [];
    }
    obj[item[key]].push(item);

    return obj;
  }, {});
};

export const pluralize = (count: number, noun: string, suffix: string = 's'): string =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const isItemVisible = (
  list: React.RefObject<HTMLElement> | Element,
  selectedItem: React.RefObject<HTMLElement> | Element
) => {
  const vehiclesListNode = (list as React.RefObject<HTMLElement>)?.current || (list as HTMLElement);
  const selectedVehicleItemNode =
    (selectedItem as React.RefObject<HTMLElement>)?.current || (selectedItem as HTMLElement);

  if (!vehiclesListNode || !selectedVehicleItemNode) {
    return;
  }

  const vehiclesListTop = vehiclesListNode.scrollTop;
  const vehiclesListHeight = vehiclesListNode.offsetHeight;
  const vehiclesListBottom = vehiclesListTop + vehiclesListHeight;
  const selectedVehicleTop = selectedVehicleItemNode.offsetTop;
  const selectedVehicleHeight = selectedVehicleItemNode.offsetHeight;
  const selectedVehicleBottom = selectedVehicleTop + selectedVehicleHeight;

  return selectedVehicleBottom <= vehiclesListBottom && selectedVehicleTop >= vehiclesListTop;
};

export const scrollToSelectedItem = (
  list: React.RefObject<HTMLElement> | Element,
  selectedItem: React.RefObject<HTMLElement> | Element,
  scrollToCenter?: boolean
): void => {
  const vehiclesListNode = (list as React.RefObject<HTMLElement>)?.current || (list as HTMLElement);
  const selectedVehicleItemNode =
    (selectedItem as React.RefObject<HTMLElement>)?.current || (selectedItem as HTMLElement);

  if (!vehiclesListNode || !selectedVehicleItemNode) {
    return;
  }

  const vehiclesListTop = vehiclesListNode.scrollTop;
  const vehiclesListHeight = vehiclesListNode.offsetHeight;
  const vehiclesListBottom = vehiclesListTop + vehiclesListHeight;
  const selectedVehicleTop = selectedVehicleItemNode.offsetTop;
  const selectedVehicleHeight = selectedVehicleItemNode.offsetHeight;
  const selectedVehicleBottom = selectedVehicleTop + selectedVehicleHeight;

  const isSelectedVehicleItemVisible =
    selectedVehicleBottom <= vehiclesListBottom && selectedVehicleTop >= vehiclesListTop;

  if (!isSelectedVehicleItemVisible) {
    vehiclesListNode?.scroll?.({
      top: selectedVehicleTop - (scrollToCenter ? vehiclesListHeight / 2 - selectedVehicleHeight / 2 : 0),
      behavior: 'smooth',
    });
  }
};

export const getCompassDirectionTextByHeading = (heading: number): string => {
  let res;

  if (heading < 22.5 || heading > 337.5) {
    res = 'North';
  } else if (heading < 67.5) {
    res = 'North-East';
  } else if (heading < 112.5) {
    res = 'East';
  } else if (heading < 157.5) {
    res = 'South-East';
  } else if (heading < 202.5) {
    res = 'South';
  } else if (heading < 247.5) {
    res = 'South-West';
  } else if (heading < 292.5) {
    res = 'West';
  } else if (heading < 337.5) {
    res = 'North-West';
  }

  return res;
};

export const getSatelliteCountsReception = (satelliteCount: number) => {
  if (satelliteCount > 20) {
    return { accuracy: '1', satIcon: SatelliteCount5 };
  } else if (satelliteCount > 15 && satelliteCount < 21) {
    return { accuracy: '3', satIcon: SatelliteCount4 };
  } else if (satelliteCount > 10 && satelliteCount < 16) {
    return { accuracy: '10', satIcon: SatelliteCount3 };
  } else if (satelliteCount > 5 && satelliteCount < 11) {
    return { accuracy: '50', satIcon: SatelliteCount2 };
  } else {
    return { accuracy: '100', satIcon: SatelliteCount1 };
  }
};

export const isEveryObjPropTrue = (obj: any) => {
  return Object.values(obj).every((value) => Boolean(value));
};

export const isSomeObjPropTrue = (obj: any) => {
  return Object.values(obj).some((value) => Boolean(value));
};

export const getUniqueId = () => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  for (let i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};
