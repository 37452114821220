import React, { Component } from 'react';
import { observer } from 'mobx-react';

import type ReportTableGroupHeader from 'models/Report/ReportTableGroup/ReportTableGroupHeader';

import Input from 'components/Input';

import './styles.scss';

interface IProps {
  header: ReportTableGroupHeader;
}

@observer
class ReportGroupedTableHeaderFilter extends Component<IProps> {
  handleChange = (e) => {
    this.props.header.updateSearch(e.target.value);
  };

  render() {
    const { header } = this.props;

    return (
      <span className="ReportGroupedTableHeaderFilter">
        <Input value={header.search} onChange={this.handleChange} className="ReportGroupedTableHeaderFilter-input" />
      </span>
    );
  }
}

export default ReportGroupedTableHeaderFilter;
