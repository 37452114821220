import { computed, observable, action } from 'mobx';
import { DATE_TIME_FORMATS } from 'config';
import { ListField, SimpleField, TimeField, UniqueField } from 'models/Fields';
import { repositoryService } from 'services';
import APIStoreBase from 'stores/APIStoreBase';
import DashcamStatusModel from './DashcamStatus';
import type Repository from 'services/RepositoryService/Repository';
import { DashcamSettings } from './DashcamSettings';

export default class Dashcam {
  @observable id: SimpleField<string>;
  @observable name: UniqueField;
  @observable uniqueId: SimpleField<string>;
  @observable serialNumber: SimpleField<string>;
  @observable dashcamId: SimpleField<string>;
  @observable vehicleId: SimpleField<string>;
  @observable vehicleName: SimpleField<string>;
  @observable lastCommunication: TimeField;
  @observable notes: SimpleField<string>;
  @observable vehicleDescription: SimpleField<string>;
  @observable createdBy: SimpleField<string>;
  @observable updatedBy: SimpleField<string>;
  @observable creationTime: SimpleField<number>;
  @observable lastUpdateTime: SimpleField<number>;
  @observable fetchDashcamRequestStatus: APIStoreBase = new APIStoreBase();
  @observable status: DashcamStatusModel;
  @observable assetId: SimpleField<number>;

  availableCameraTypes: ListField<'INSIDE' | 'OUTSIDE'>;
  settings: DashcamSettings;
  repositoryDashcams: Repository;

  constructor(item) {
    this.setModel(item);
    this.status = new DashcamStatusModel();
    this.repositoryDashcams = repositoryService.get('dashcams');
    this.settings = new DashcamSettings(this);
  }

  setModel = (item) => {
    this.id = new SimpleField(item.id || null);
    this.name = new UniqueField(item.name || '', 'dashcams');
    this.uniqueId = new SimpleField(item.uniqueId || '');
    this.serialNumber = new SimpleField(item.serialNumber || '');
    this.dashcamId = new SimpleField(item.dashcamId || '');
    this.vehicleId = new SimpleField(item.vehicleId);
    this.vehicleName = new SimpleField(item.vehicleName);
    this.lastCommunication = new TimeField(item.lastCommunication || 0).setFormat(
      DATE_TIME_FORMATS.adminVehicleSettings
    );
    this.notes = new SimpleField(item.notes);
    this.vehicleDescription = new SimpleField(item.vehicleDescription);
    this.createdBy = new SimpleField(item.createdBy || '');
    this.updatedBy = new SimpleField(item.updatedBy || '');
    this.creationTime = new SimpleField(item.creationTime || 0);
    this.lastUpdateTime = new SimpleField(item.lastUpdateTime || 0);
    this.availableCameraTypes = new ListField(item.availableCameraTypes || []);
    this.assetId = new SimpleField(item.assetId || null);
  };

  get model() {
    return {
      name: this.name.value,
      uniqueId: this.uniqueId.value,
      vehicleId: this.vehicleId.value === 'none' ? '' : this.vehicleId.value,
      notes: this.notes.value,
    };
  }

  @computed get isUpdate() {
    return this.name.isUpdated || this.vehicleId.isUpdated || this.notes.isUpdated;
  }

  @computed get isValid() {
    return !this.name.isEmpty;
  }

  fetch = async (uniqueId) => {
    this.fetchDashcamRequestStatus.reset().setLoading(true);

    try {
      const item = await this.repositoryDashcams.entity(uniqueId).get();

      this.setModel(item);
      this.fetchDashcamRequestStatus.setSuccess(true);
      this.settings.setRepositorySettings(item.id).fetch();
    } catch (error) {
      this.fetchDashcamRequestStatus.setError(error);
      throw new Error(error);
    }

    this.fetchDashcamRequestStatus.setLoading(false);
  };

  update = async () => {
    const item = await this.repositoryDashcams.patch(this.model);

    this.setModel(item);
  };

  clear = () => {
    this.setModel({});
    this.status.clear();
    this.fetchDashcamRequestStatus.reset();
  };

  @action reset = () => {
    this.name.reset();
    this.notes.reset();
    this.vehicleId.reset();
    this.vehicleName.reset();
  };
}
