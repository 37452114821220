import { action, computed, observable, reaction } from 'mobx';
import { get } from 'lodash';

import { repositoryService, RepositoryService } from 'services';
import {
  getCookie,
  getSessionStorageItem,
  getStorageItem,
  initPendo,
  initZendesk,
  parseJwt,
  removeCookie,
  removeSessionStorageItem,
  setCookie,
  setSessionStorageItem,
  setStorageItem,
} from 'utils';
import timeStore from 'stores/TimeStore';

import {
  LEGACY_TOKEN_NAME,
  REFRESH_TOKEN_COOKIE_NAME,
  TOKEN_COOKIE_NAME,
  USER_ACCOUNT_ID,
  USER_ID,
} from 'config/cookie';
import { BUILD_VERSION, DEFAULT_TIMEZONE, USER_DATA_STATUS, USER_DATA_STATUS_STORAGE } from '../config';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { RequestType } from 'services/RepositoryService/RequestsType';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';
import AppFile from '../models/Files/AppFile';
import UserDetailsModel from 'models/User/UserDetailsModel';

const MULTIPLE_ACCOUNTS_ERROR = 'ACCOUNT_ID_REQUIRED';

export class UserStore {
  details: UserDetailsModel;
  @observable userData: User.IUserData = null;
  @observable jwToken: string = '';
  @observable otpToken: string = '';
  @observable refreshToken: string = '';
  @observable timezone: string = '';
  @observable acls: any = null;
  @observable defaultSystem: number = 3;
  @observable multiAccount: boolean = false;
  @observable latestSessionOpened: number = 0;
  @observable avatar: AppFile;
  repositoryLegacyAuth: IRepository;
  repositoryLegacyAuthToken: IEntityRepository;
  repositoryConfig: IRepository;
  repositoryAuth: IRepository;
  repositorySwitchAccount: IEntityRepository;
  repositoryAuthPasswordRecovery: IEntityRepository;
  repositoryAuthToken: IEntityRepository;
  repositoryUsers: IRepository;
  repositoryUsersPassword: IEntityRepository;
  repositoryUsersCurrent: IEntityRepository;
  repositoryPermissionsUsers: IEntityRepository;

  constructor() {
    this.details = new UserDetailsModel();
    setStorageItem(USER_DATA_STATUS_STORAGE, USER_DATA_STATUS.LOADING);
    this.jwToken = getCookie(TOKEN_COOKIE_NAME) || '';
    const searchParams = new URLSearchParams(window.location.search);
    this.otpToken = searchParams.get('otp') || null;

    if (this.jwToken) {
      this.updateUserDetailsFromJWT();
      initPendo({
        visitorId: this.details.active.userId.value,
        accountId: this.details.active.accountId.value,
      });
      initZendesk();
    } else {
      initPendo({
        visitorId: {},
        accountId: {},
      });
    }

    this.repositoryLegacyAuth = new RepositoryService('').get('/track', '');
    this.repositoryLegacyAuthToken = this.repositoryLegacyAuth.entity('Track');
    this.repositoryAuth = repositoryService.get('auth');
    this.repositorySwitchAccount = this.repositoryAuth.entity('accounts').entity('switch');
    this.repositoryConfig = repositoryService.get('user-configs');
    this.repositoryAuthPasswordRecovery = this.repositoryAuth.entity('password-recovery');
    this.repositoryAuthToken = this.repositoryAuth.entity('token');
    this.repositoryUsers = repositoryService.get('users');
    this.repositoryUsersCurrent = this.repositoryUsers.entity('current');
    this.repositoryUsersPassword = this.repositoryUsers.entity('password');
    this.repositoryPermissionsUsers = repositoryService
      .get('permissions')
      .entity('user')
      .entity('gts')
      .entity('current');

    reaction(
      () => this.jwToken,
      () => {
        if (this.jwToken) {
          this.updateUserDetailsFromJWT();
          initPendo({
            visitorId: this.details.active.userId.value,
            accountId: this.details.active.accountId.value,
          });
          initZendesk();
        }
      }
    );

    EventsBus.get().on(APP_EVENTS.UPDATE_TOKEN, this.refreshJwtToken);
  }

  @action getUserData = async () => {
    this.userData = await this.repositoryUsersCurrent.get({}, { triggerError: true });

    this.multiAccount = this.userData.isMultiAccountUser;
    this.setUserAvatar(this.userData.profileImage || { url: '', type: '' });
    this.updateLatestSessionOpened();

    const buildVersion = process.env.REACT_APP_BUILD_NUMBER;
    const status = getStorageItem(BUILD_VERSION) === buildVersion ? USER_DATA_STATUS.STABLE : USER_DATA_STATUS.UPDATED;

    setStorageItem(USER_DATA_STATUS_STORAGE, status);
    setStorageItem(BUILD_VERSION, buildVersion);
    setCookie(USER_ID, this.userData.id, { expires: 30 });

    this.details.active.accountIdCP.set(this.userData.account.idGts);
    this.details.active.accountId.set(this.userData.account.id);
    this.details.active.userId.set(this.userData.id);
    timeStore.setUserTimeZone(this.userData.timeZone || DEFAULT_TIMEZONE);

    EventsBus.get().trigger(APP_EVENTS.USER.DATA.GET, { status });
  };

  @action updateLatestSessionOpened = () => {
    // this is the value of the latest session opened by the user
    const latestActiveSession = Number(getSessionStorageItem(`latest-active-session-${this.userData.id}`));
    // this is the value of the latest session started by the user
    const latestSessionStarted = Number(getStorageItem(`latest-session-started-${this.userData.id}`) || 1);

    // after user logs in, if there is no latest active session, we set the latest session started as the latest active session
    if (!latestActiveSession) {
      setSessionStorageItem(`latest-active-session-${this.userData.id}`, String(latestSessionStarted));
      // also we set the latest session started as the latest session opened
      setStorageItem(`latest-session-started-${this.userData.id}`, String(Date.now()));
      this.latestSessionOpened = latestSessionStarted;
    } else {
      this.latestSessionOpened = latestActiveSession;
    }
  };

  @action updateLatestSessionStarted = () => {
    setStorageItem(`latest-session-started-${this.userData.id}`, String(Date.now()));
    setSessionStorageItem(`latest-active-session-${this.userData.id}`, String(Date.now()));
  };

  @action removeLatestSessionOpened = () => {
    removeSessionStorageItem(`latest-active-session-${this.userData?.id}`);
  };

  @action switchAccount = async (accountId: string) => {
    const repository = this.repositorySwitchAccount.entity(
      `${accountId}?refreshToken=${getCookie(REFRESH_TOKEN_COOKIE_NAME)}`
    );
    const loginDetails: User.IUserData = await repository.create();

    if (repository.createState.success) {
      this.saveUserData(loginDetails);
    } else if (repository.createState.error) {
      throw Error(repository.createState.error);
    }
  };

  private saveUserData(loginDetails) {
    this.setJWToken(loginDetails.token);

    this.updateUserDetailsFromJWT();

    const expires = (this.details.active.exp.value - Date.now() / 1000) / (24 * 60 * 60) || 0.5;

    setCookie(TOKEN_COOKIE_NAME, loginDetails.token, { expires });
    setCookie(USER_ACCOUNT_ID, this.details.selectedAccount.accountId.value || '', { expires: 30 });

    if (loginDetails.refreshToken) {
      this.setRefreshToken(loginDetails.refreshToken);
      setCookie(REFRESH_TOKEN_COOKIE_NAME, loginDetails.refreshToken, { expires: 30 });
    }
  }

  @action setJWToken = (token: string) => (this.jwToken = token);
  @action resetOPT = () => (this.otpToken = '');

  @action setRefreshToken = (token: string) => (this.refreshToken = token);

  /** Save user details from the server */
  @action updateUserDetailsFromJWT = (): User.IUserContract => {
    const data = parseJwt(this.jwToken);
    const accountId = getCookie(USER_ACCOUNT_ID);

    this.details.active.exp.set(data.exp);
    this.details.active.accountId.set(Number(accountId));

    return data;
  };

  static getChildAclAccessLevels(aclAccessLevel) {
    const levels = ['READ', 'UPDATE', 'CREATE', 'DELETE'];
    const currentLevelIndex = levels.indexOf(aclAccessLevel);
    return levels.slice(0, currentLevelIndex + 1);
  }

  @action setCurrentUserACLs = async () => {
    const aclsResponse = await this.repositoryPermissionsUsers.get({}, { requireAuth: true });
    const acls = [];

    aclsResponse.forEach(({ aclAccessLevel, aclName }) => {
      // get child access levels
      const childLevels = UserStore.getChildAclAccessLevels(aclAccessLevel);
      // create acls for child levels
      childLevels.forEach((aclLevel) => {
        acls.push(`${aclName}::${aclLevel.toLowerCase()}`);
      });
    });

    this.acls = acls;
    EventsBus.get().trigger(APP_EVENTS.ACL_UPDATED);
  };

  @action setTimezone = (timesone) => (this.timezone = timesone);

  @action login = async (emailId: string, password: string, accountId: string): Promise<any> => {
    try {
      this.details.email.set(emailId);
      this.details.password.set(password);

      await this.JWTokenLogin(emailId, password, accountId);
      await this.legacyLogin(emailId, password);
    } catch (err) {
      if (err.message === MULTIPLE_ACCOUNTS_ERROR) {
        this.multiAccount = true;
        this.repositoryAuthToken.createState.reset();
      }
    }
  };

  @action async getUserConfig() {
    try {
      const config = await this.repositoryConfig.get({
        applicationId: 1,
      });
      this.defaultSystem = config?.web?.default_system || this.defaultSystem;
    } catch (_) {
      // we don't need to handle this error, because if it fails we will keep default value
    }
  }

  @action legacyLogin = async (user: string, password: string): Promise<any> => {
    const data = new FormData();
    data.append('user', user);
    data.append('password', password);

    try {
      removeCookie(LEGACY_TOKEN_NAME, {
        domain: '.clearpathgps.com',
        path: '/',
      });
      await this.repositoryLegacyAuthToken.create(data, {
        requireAuth: false,
        contentType: 'text/html',
        accept: 'text/html',
      });
    } catch (_) {
      throw Error('Failed to log in with Legacy API');
    }
  };

  @action JWTokenLogin = async (emailId: string, password: string, accountId: string): Promise<any> => {
    const loginDetails: User.IServerUser = await this.repositoryAuthToken.create(
      { emailId, password, accountId, appName: 'web' },
      { requireAuth: false }
    );

    if (this.repositoryAuthToken.createState.success) {
      this.saveUserData(loginDetails);
    } else if (this.repositoryAuthToken.createState.error) {
      throw Error(this.repositoryAuthToken.createState.error);
    }
  };

  @action forgotPass = async (emailId: string) => {
    await this.repositoryAuthPasswordRecovery.create({ emailId }, { requireAuth: false });
  };

  @action changePassword = async (currentPassword: string, newPassword: string) => {
    await this.repositoryUsersPassword.put({ currentPassword, newPassword });
  };

  @action setPassword = async (token: string, password: string) => {
    await this.repositoryAuthPasswordRecovery.do(
      `update?token=${token}`,
      { password },
      { requestType: RequestType.POST },
      false
    );
  };

  @action forgetUser = () => {
    this.removeLatestSessionOpened();
    this.details.reset();
    this.userData = null;
    this.multiAccount = false;
    this.jwToken = '';
    this.refreshToken = '';
  };

  legacyLogout = async () => {
    await this.repositoryLegacyAuthToken.get({
      page: 'login',
    });
  };

  JWTokenLogout = () => {
    removeCookie(TOKEN_COOKIE_NAME);
    removeCookie(REFRESH_TOKEN_COOKIE_NAME);
  };

  refreshJwtToken = async (loginDetails) => {
    this.setJWToken(loginDetails.token);

    const expires = (this.details.active.exp.value - Date.now() / 1000) / (24 * 60 * 60) || 0.5;

    setCookie(TOKEN_COOKIE_NAME, loginDetails.token, { expires });
  };

  @action logout = async () => {
    this.JWTokenLogout();
    this.forgetUser();
    EventsBus.get().trigger(APP_EVENTS.USER.LOGOUT);
    this.repositoryAuthToken.createState.reset();
    this.repositoryLegacyAuthToken.createState.reset();
    this.repositoryAuthPasswordRecovery.createState.reset();
    this.repositoryUsersCurrent.getState.reset();
    timeStore.resetTimezones();
  };

  @computed get userRoles() {
    const roles = get(this.userData, 'roles', []);
    const roleValues = {
      Manager: 'Manager',
      Admin: 'Administrator',
      Basic: 'Basic User',
    };
    return roles.map(({ name }) => get(roleValues, name, name));
  }

  @computed get userAvatarSrc() {
    return this.avatar?.model.source.value;
  }

  @computed get noDriverAssignedToVehicle() {
    const accessRestrictedToAssignedAssets = get(
      this.userData,
      'permissions.driverDetails.accessRestrictedToAssignedAssets',
      false
    );
    const asset = get(this.userData, 'permissions.driverDetails.asset.id', null);

    return accessRestrictedToAssignedAssets && !asset;
  }

  @action updateMultiAccount = (value: boolean) => {
    this.multiAccount = value;
  };

  setUserAvatar = ({ url, type }: { url: string; type: string }) => {
    this.avatar = new AppFile(null, {
      url: url || '',
      type: type || 'image',
      fileName: '',
      displayName: '',
      id: null,
    });
  };

  @computed get userPlan(): string {
    return get(this.userData, 'plan.description', '').toLowerCase();
  }
}

export default new UserStore();
