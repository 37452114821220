import isBoolean from 'lodash/isBoolean';
import { action, computed, observable } from 'mobx';

import { ListField, SimpleField, ToggleField, UniqueField } from 'models/Fields';
import { ReportType, ReportFor } from 'models/Report/Report';
import { repositoryService } from 'services';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import type { ScheduledReportsStore } from 'stores/ScheduledReports';
import type {
  IScheduledReport,
  IScheduledReportAppliesTo,
  IScheduledReportInterval,
  IScheduledReportParameters,
  IScheduledReportFormat,
  IScheduledReportServer,
} from './IScheduledReport';
import ScheduledReportAppliesTo from './ScheduledReportAppliesTo';
import ScheduledReportFormat from './ScheduledReportFormat';
import ScheduledReportInterval from './ScheduledReportInterval';
import ScheduledReportParameters from './ScheduledReportParameters';

const initialDefaultModel = {
  recipients: [],
  format: {
    displayName: '',
    id: '',
  },
  description: '',
  interval: {
    displayName: '',
    description: '',
    id: '',
  },
  reportParameters: {
    driverId: '',
    reportId: '',
    reportName: '',
    tagId: '',
    hideInactive: true,
    geozoneId: '',
    option: '',
  },
  id: 0,
  source: {
    group: {
      displayName: '',
      id: '',
    },
    device: {
      displayName: '',
      id: '',
    },
  },
  isActive: true,
  createdBy: '',
  updatedBy: '',
  creationTime: 0,
  lastUpdateTime: 0,
};

const CHARACTER_LIMIT = 512;

class ScheduledReport implements IScheduledReport {
  id: number;
  format: IScheduledReportFormat;
  scheduledReportName: UniqueField;
  reportParameters: IScheduledReportParameters;
  interval: IScheduledReportInterval;
  appliesTo: IScheduledReportAppliesTo;
  recipients: ListField<string>;
  isActive: ToggleField;
  createdBy: SimpleField<string>;
  updatedBy: SimpleField<string>;
  creationTime: SimpleField<number>;
  lastUpdateTime: SimpleField<number>;
  private context: ScheduledReportsStore;
  initialModel: IScheduledReportServer;
  @observable selectedTypeOption: ReportType;
  @observable selectedForOption: ReportFor;
  repositoryScheduledReport: IEntityRepository;

  constructor(model) {
    this.initialModel = model || initialDefaultModel;
    this.model = model || {};
    this.repositoryScheduledReport = repositoryService.get('reports').entity('scheduled-reports');
    this.repositoryScheduledReport.listenTo('error:create', () => this.context.isCreatedError.toggle(true));
  }

  initialize(context) {
    this.context = context;
    return this;
  }

  set model({
    id,
    format,
    description,
    reportParameters,
    interval,
    source,
    recipients,
    isActive,
    createdBy,
    updatedBy,
    creationTime,
    lastUpdateTime,
  }) {
    this.id = id;
    this.format = new ScheduledReportFormat(format || {});
    this.scheduledReportName = new UniqueField(description || '', 'reports/scheduled-reports');
    this.reportParameters = new ScheduledReportParameters(reportParameters || {});
    this.interval = new ScheduledReportInterval(interval || {});
    this.appliesTo = new ScheduledReportAppliesTo(source || {});
    this.recipients = new ListField(recipients);
    this.isActive = new ToggleField(isBoolean(isActive) ? isActive : true);
    this.createdBy = new SimpleField(createdBy || '');
    this.updatedBy = new SimpleField(updatedBy || '');
    this.creationTime = new SimpleField(creationTime || 0);
    this.lastUpdateTime = new SimpleField(lastUpdateTime || 0);

    const tagValue = this.reportParameters.tag.id.value;

    if (!this.appliesTo.device.id.value && this.appliesTo.group.id.value) {
      this.setSelectedTypeOption(ReportType.byGroup);
    }

    if (tagValue && tagValue !== 'all') {
      this.setSelectedForOption(ReportFor.tag);
    } else {
      this.setSelectedForOption(ReportFor.geozone);
    }
  }

  getModel = () => {
    return {
      formatId: this.format.value,
      description: this.scheduledReportName.value,
      reportParameters: this.reportParameters.getModel(),
      intervalId: this.interval.id.value,
      source: this.appliesTo.getModel(),
      recipients: this.recipients.toArray(),
      isActive: this.isActive.value,
      createdBy: this.createdBy.value,
      updatedBy: this.updatedBy.value,
      creationTime: this.creationTime.value,
      lastUpdateTime: this.lastUpdateTime.value,
    };
  };

  @computed get isCharacterLimitReached() {
    return this.getTotalCharacter() >= CHARACTER_LIMIT;
  }

  @computed get characterLimitLeft() {
    return CHARACTER_LIMIT - this.getTotalCharacter();
  }

  @action setSelectedTypeOption = (value: ReportType) => {
    this.selectedTypeOption = value;
  };

  @action setSelectedForOption = (value: ReportFor) => {
    this.selectedForOption = value;
  };

  @computed get isValid() {
    const isOneEmailValidAndNotEmpty = this.recipients.value.some((item) => item.isValid && !item.isEmpty);
    const isEmailsValidOrEmpty = this.recipients.value.every((item) => item.isValid || item.isEmpty);

    return Boolean(isOneEmailValidAndNotEmpty && isEmailsValidOrEmpty && this.scheduledReportName.value);
  }

  create = async () => {
    this.resetStates();
    await this.repositoryScheduledReport.create(this.getModel());

    if (this.repositoryScheduledReport.createState.success) {
      this.context.isCreated.toggle(true);
    }
  };

  update = async () => {
    const repositoryScheduledReportUpdate = this.repositoryScheduledReport.entity(String(this.id));

    repositoryScheduledReportUpdate.listenTo('error:patch', () => this.context.isUpdatedError.toggle(true));

    this.resetStates();

    await repositoryScheduledReportUpdate.patch(this.getModel());

    if (repositoryScheduledReportUpdate.patchState.success) {
      this.context.isUpdated.toggle(true);
    }
  };

  destroy = async () => {
    const repositoryScheduledReportDelete = this.repositoryScheduledReport.entity(String(this.id));

    repositoryScheduledReportDelete.listenTo('error:delete', () => this.context.isDeletedError.toggle(true));

    this.resetStates();

    await repositoryScheduledReportDelete.delete();

    if (repositoryScheduledReportDelete.deleteState.success) {
      this.context.isDeleted.toggle(true);
    }
  };

  resetStates = () => {
    this.context.isCreated.toggle(false);
    this.context.isUpdated.toggle(false);
    this.context.isDeleted.toggle(false);
  };

  private getTotalCharacter = () => {
    return this.recipients.value.reduce(
      (previousValue, currentItem) => previousValue + currentItem.value.length + 1,
      0
    );
  };
}

export default ScheduledReport;
