import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import type { Customer } from 'stores/Customers/Customer';
import validateAccessLevel from 'stores/acl/validator';

import { WEB_FINANCE_DETAILS_READ } from 'config';

import CustomerUpcomingJobItem from './CustomerUpcomingJobItem';
import Button from 'components/Button';
import FinancialDataAccessError from 'components/FinancialDataAccessError';

import './styles.scss';

interface IProps {
  customer: Customer;
  step?: number
}

interface IState {
  limit: number
}

const LIMIT = 5;

@observer
class CustomerUpcomingJobs extends Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      limit: 5,
    }
  }

  get maxCount() {
    const {
      customer,
    } = this.props;

    return customer.upcomingJobs?.length || 0
  }

  get hiddenElements () {
    return  this.maxCount - this.state.limit;
  }

  handleMoreClick = () => {
    this.increaseLimit();
  }

  increaseLimit = () => {
    this.setState({
      limit: this.state.limit + (this.props.step || LIMIT),
    })
  }

  get hasAccess () {
    return validateAccessLevel([WEB_FINANCE_DETAILS_READ]);
  }

  render() {
    const {
      customer,
      step = LIMIT,
    } = this.props;

    const {
      limit,
    } = this.state;

    const hasAccess = this.hasAccess;
    const hasUpcomingJobs = customer.upcomingJobs?.length;

    return <>
      <div className="CustomerUpcomingJobs-List">
        <div className="CustomerUpcomingJobs-ListHeader">
          <div>Job</div>
          <div>Service Date</div>
          <div>Status</div>
          <div>Total</div>
        </div>
        <div className={classNames('CustomerUpcomingJobs-ListBody', {
          'CustomerUpcomingJobs-ListBody--limited': !hasAccess,
        })}>
          <div className="CustomerUpcomingJobs-ListItems">
            {customer.upcomingJobs?.slice(0, this.state.limit)?.map?.((job) => (
              <CustomerUpcomingJobItem key={job.id} job={job} customerId={customer.id} limited={!hasAccess} />
            ))}
          </div>
          {!hasAccess && customer.full && hasUpcomingJobs ? <FinancialDataAccessError className="CustomerUpcomingJobs-ListColumn--limited" /> : null}
        </div>
        <div className="CustomerUpcomingJobs-ListFooter">
          {this.maxCount && this.maxCount > limit ? (
            <Button
              title={`Load ${this.hiddenElements > step ? step : this.hiddenElements} More`}
              className="CustomerUpcomingJobs-Link"
              onClick={this.handleMoreClick}
            />
          ) : null}
        </div>
      </div>
    </>;
  }
}

export default CustomerUpcomingJobs;
