import React, { Component } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import noop from 'lodash/noop';

import { getFormattedTime } from 'utils';

import { DATE_TIME_FORMATS, MAINTENANCE_REQUESTS_TABLE_COLUMNS, MAINTENANCES_STORE, TIME_STORE } from 'config';

import type { Maintenances as MaintenancesStore, TimeStore } from 'stores';
import type MaintenanceRequest from 'models/Maintenances/Models/MaintenanceRequests/MaintenanceRequest';
import CustomTable from 'components/Table/CustomTable';
import MaintenanceRequestsModal from '../../MaintenanceRequestsModal';
import TooltipParagraph from 'components/TooltipParagraph';
import Button from 'components/Button';

import './styles.scss';

interface IProps {
  [MAINTENANCES_STORE]?: MaintenancesStore;
  [TIME_STORE]?: TimeStore;
  onCreate?: (value: number) => void;
  onIgnore?: (value: number) => void;
}

@inject(({ maintenancesStore, timeStore }) => ({
  maintenancesStore,
  timeStore,
}))
@observer
class MaintenanceRequests extends Component<IProps> {
  private rootRef: any = React.createRef();
  getColumns = () => {
    const {
      timeStore: { sessionTimezone },
    } = this.props;

    return [
      {
        title: 'Request Name',
        dataIndex: MAINTENANCE_REQUESTS_TABLE_COLUMNS.REQUEST_NAME,
        width: 180,
        fixed: 'left',
        ellipsis: {
          showTitle: false,
        },
        render: (value, maintenanceRequest) => (
          <Button
            className="Button--link Button--inline MaintenanceRequests-convertLink"
            title={value}
            onClick={() => this.convertToTask(maintenanceRequest.id)}
          />
        ),
      },
      {
        title: 'Vehicle Name',
        dataIndex: MAINTENANCE_REQUESTS_TABLE_COLUMNS.VEHICLE_NAME,
        width: 180,
        ellipsis: {
          showTitle: false,
        },
        render: (value) => <TooltipParagraph placement="bottomRight">{value}</TooltipParagraph>,
      },
      {
        title: 'Request Received',
        dataIndex: MAINTENANCE_REQUESTS_TABLE_COLUMNS.REQUEST_RECEIVED,
        width: 150,
        render: (value) => <>{value ? getFormattedTime(value, DATE_TIME_FORMATS.dateAtTime, sessionTimezone) : '-'}</>,
      },
      {
        title: 'Requested By',
        dataIndex: MAINTENANCE_REQUESTS_TABLE_COLUMNS.REQUESTED_BY,
        width: 180,
        ellipsis: {
          showTitle: false,
        },
        render: (value) => <TooltipParagraph placement="bottomRight">{value}</TooltipParagraph>,
      },
      {
        title: 'Service Type',
        dataIndex: MAINTENANCE_REQUESTS_TABLE_COLUMNS.SERVICE_TYPE,
        width: 180,
      },
      {
        title: 'Notes',
        dataIndex: MAINTENANCE_REQUESTS_TABLE_COLUMNS.NOTES,
        width: 180,
        ellipsis: {
          showTitle: false,
        },
        render: (value) => <TooltipParagraph placement="bottomRight">{value}</TooltipParagraph>,
      },
      {
        title: 'Actions',
        dataIndex: MAINTENANCE_REQUESTS_TABLE_COLUMNS.ACTIONS,
        width: 220,
        fixed: 'right',
        className: 'MaintenanceRequests-tableCellActions',
        render: (_, maintenanceRequest: MaintenanceRequest) => (
          <>
            <Button
              className="Button--link Button--inline"
              title="Convert to Task"
              onClick={() => this.convertToTask(maintenanceRequest.id)}
            />
            <Button
              className="Button--link Button--inline"
              title="Ignore Request"
              onClick={() => this.ignoreRequest(maintenanceRequest.id)}
            />
          </>
        ),
      },
    ];
  };

  convertToTask = (id: number) => {
    this.props.onCreate(id);
  };
  ignoreRequest = (ignoreRequestId: number) => {
    this.props.maintenancesStore!.requests.ignoreRequest.set(ignoreRequestId);
  };

  get editColumnsMap() {
    return [];
  }

  updateCSSHeight = () => {
    document.documentElement.style.setProperty(
      '--maintenance-request-height',
      `${this.rootRef.current.clientHeight}px`
    );
  };

  componentDidUpdate() {
    this.updateCSSHeight();
  }

  componentDidMount() {
    this.updateCSSHeight();
  }

  componentWillUnmount() {
    document.documentElement.style.setProperty('--maintenance-request-height', `0px`);
  }

  handleConfirmIgnore = () => {
    this.props.onIgnore(this.props[MAINTENANCES_STORE]!.requests.ignoreRequest.value);
  };

  render() {
    const source = this.props[MAINTENANCES_STORE]!.requests.items;
    const columns = [];

    return (
      <div className="MaintenanceRequests" ref={this.rootRef}>
        <div className="MaintenanceRequests-header">Maintenance Requests</div>
        <div className="MaintenanceRequests-body">
          <CustomTable
            columns={this.getColumns()}
            dataSource={[...source]}
            editColumnsMap={this.editColumnsMap}
            getData={noop}
            selectedColumns={toJS(columns)}
            withFilter={false}
            withTitle={false}
            withPagination={false}
          />
        </div>
        <MaintenanceRequestsModal onConfirm={this.handleConfirmIgnore} />
      </div>
    );
  }
}

export default MaintenanceRequests;
