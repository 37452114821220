import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { REPORTS_STORE } from 'config';
import type { ReportsStore } from 'stores';

interface IProps {
  reportsStore?: ReportsStore;
  item: { value: string; sortValue?: number; mapLink: { lat: number; lon: number }; color?: string };
  className: string;
}

@inject(REPORTS_STORE)
@observer
class ReportTableByGroupRowEventCellLink extends Component<IProps> {
  render() {
    const { item, className } = this.props;

    return (
      <div className={className} style={{ color: item.color }}>
        {item.value}
      </div>
    );
  }
}

export default ReportTableByGroupRowEventCellLink;
