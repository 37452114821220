import { observable, action, computed } from 'mobx';
import { repositoryService } from 'services';
import { ListField } from 'models/Fields';

import UserModel from './UserDataModel';
import type Repository from 'services/RepositoryService/Repository';

export interface IRole {
  description?: string;
  id: number;
  name?: string;
}

export enum UserModelMap {
  accessRestrictedByGroups = 'accessRestrictedByGroupIds',
  activeStatus = 'active',
  cellPhone = 'cellPhone',
  contactPhone = 'contactPhone',
  contactPhoneExt = 'contactPhoneExt',
  groupAccessRestricted = 'groupAccessRestricted',
  id = 'id',
  jobTitle = 'jobTitle',
  lastLogin = 'lastLogin',
  loginEmail = 'loginEmail',
  name = 'contactName',
  notes = 'notes',
  role = 'roleId',
  roles = 'roles',
  timeZone = 'timeZone',
  userId = 'userId',
  createdBy = 'createdBy',
  updatedBy = 'updatedBy',
}

export class User {
  @observable model: UserModel;
  repositoryUsers: Repository;

  constructor(userAppModel: User.IAppServerUser) {
    this.userModel = userAppModel;
    this.repositoryUsers = repositoryService.get('users');
  }

  private set userModel(userAppModel: User.IAppServerUser) {
    this.model = new UserModel(userAppModel);
  }

  private get data() {
    return Object.keys(this.model).reduce((object, key) => {
      const field = this.model[key];

      if (field) {
        return {
          ...object,
          [UserModelMap[key]]:
            field instanceof ListField
              ? key === 'accessRestrictedByGroups'
                ? field.toArray().map((group) => group.id)
                : field.toArray()
              : field.value,
        };
      }
      return {
        ...object,
      };
    }, {});
  }

  @computed get isUpdated() {
    return Object.values(this.model).some((field) => field.isUpdated);
  }

  @action update = async () => {
    this.userModel = await this.repositoryUsers.patch(this.data);
  };

  @action reset = () => {
    Object.values(this.model).forEach((field) => field.reset());
  };
}

export default User;
