import React, { Component, useEffect } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import Highlighter from 'react-highlight-words';

import type { OptionTypeBase } from 'react-select/src/types';
import type { ISelectOptions } from 'interfaces/models/Select/ISelect';

import Select, { components, ControlProps, OptionProps, SingleValueProps, ValueContainerProps } from 'react-select';
import AddSelectOption from '../AddSelectOption';
import { STYLES } from './styles';

import { CaretDownOutlined } from '@ant-design/icons';
import { CloseIcon, Search2Icon } from 'assets';

import './styles.scss';

interface IProps<T, O> {
  options: ISelectOptions<T, O>;
  keys: Select.ISelectOption;
  value?: Select.ISelectOption;
  pageSize?: number;
  placeholder?: string;
  searchPlaceholder?: string;
  isSearchable?: boolean;
  isMenuOpen?: boolean;
  disabled?: boolean;
  hasBadge?: boolean;
  hasBadgeExtended?: boolean;
  sortOptions?: boolean;
  alignRight?: boolean;
  emptySearchResults?: string;
  handleChange?: (option: Select.ISelectOption) => void;
  onBlur?: () => void;
  onMenuClose?: () => void;
  getData?: (props: { page: number; pageSize: number; filter: string }) => Promise<any>;
  withAll?: boolean;
  addOption?: {
    text: string;
    onCreate: (value: any) => Promise<any>;
  };
  menuShouldBlockScroll?: boolean;
  autoFocus?: boolean;
  timeout?: number;
  menuPortalTarget?: HTMLElement | null | undefined;
  isGetOptionsOnMenuOpen?: boolean;
  selectFirst?: boolean;
  defaultMenuIsOpen?: boolean;
}

interface IState {
  currentPage: number;
  loadedLastPage: boolean;
  isLoading: boolean;
  filterValue: string;
  isMenuOpen: boolean;
}

class SearchableSelectAsync<T, O> extends Component<IProps<T, O>, IState> {
  static defaultProps = {
    pageSize: 50,
  };
  static allOption = { label: 'All Vehicles', value: 'all' };

  private inputRef = React.createRef();
  private selectRef: any = React.createRef();
  private documentBody = document && document.querySelector('body');
  private _isMounted: boolean = true;

  private _latestRequest: number = null;

  constructor(props) {
    super(props);

    const timeout = props.timeout || 300;

    this._isMounted = true;

    this.state = {
      currentPage: 0,
      isLoading: false,
      loadedLastPage: false,
      filterValue: '',
      isMenuOpen: false,
    };

    if (timeout) {
      this.promiseOptions = debounce(this.promiseOptions, timeout);
    }

    if (props.isGetOptionsOnMenuOpen && props.value) {
      props.options.addDefaultOption(props.value);
    }
  }

  promiseOptionsWithClear = () => {
    return this.promiseOptions(true);
  };

  promiseOptions = async (clear: boolean = false) => {
    const { pageSize, options, keys } = this.props;
    const { currentPage, filterValue } = this.state;

    if (this.state.loadedLastPage && !clear) {
      return;
    }

    if (!this._isMounted) {
      return;
    }

    const requestTime = Date.now();
    this._latestRequest = requestTime;

    this.setState({ isLoading: true });

    await options.fetch(
      { page: currentPage, pageSize, filter: filterValue, includeInactive: true, mediaEnabled: false },
      { clear, sortId: keys.value, sortLabel: keys.label }
    );

    if (options.repository.getState.success) {
      if (!this._isMounted || this._latestRequest !== requestTime) {
        return;
      }

      this.setState((prevState) => ({
        ...prevState,
        isLoading: false,
        loadedLastPage: (clear ? 0 : prevState.currentPage) >= options.totalPages - 1,
        currentPage: clear ? 0 : prevState.currentPage,
      }));
    }

    if (this.props.selectFirst && options.items.length && !this.props.value?.value) {
      this.props.handleChange({
        value: options.items[0][keys.value],
        label: options.items[0][keys.label],
        badge: options.items[0][keys.badge],
      });
    }

    if (this.isOptionNotInOptions(options, this.props.value, this.props.value?.value)) {
      this.updateOptions(options, this.props.value);
    }

    if (options.repository.getState.error) {
      if (!this._isMounted) {
        return;
      }
      this.setState({ isLoading: false });
    }
  };

  updateOptions = (options, option) => {
    const { keys } = this.props;
    options.addOption({ [keys.value]: option.value, [keys.label]: option.label, [keys.badge]: option.badge });
  };

  isOptionNotInOptions = (options, option, optionValue) => {
    const { keys } = this.props;
    const { filterValue, currentPage } = this.state;

    return (
      !filterValue &&
      currentPage === 0 &&
      option &&
      optionValue &&
      !options.items.find((item) => item[keys.value] === optionValue)
    );
  };

  componentDidMount() {
    const { isMenuOpen, isGetOptionsOnMenuOpen } = this.props;

    if (!isGetOptionsOnMenuOpen) {
      this.promiseOptions();
    }

    if (isMenuOpen) {
      this.setState({ isMenuOpen: true });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInputChange = (filterValue: string) => {
    if (filterValue !== this.state.filterValue) {
      this.setState({ filterValue, currentPage: 0 }, this.promiseOptionsWithClear);
    }
    return filterValue;
  };

  handleChangeOnPressEnter = (e) => {
    if (e.keyCode === 13 && this.showAddOption) {
      e.preventDefault();
      e.stopPropagation();
      this.handleAddOption();
    }
  };

  handleChange = (option: any) => {
    this.props.handleChange(option);
  };

  handleBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  handleMenuScrollToBottom = () => {
    this.setState(
      {
        currentPage: this.state.currentPage + 1,
      },
      this.promiseOptions
    );
  };

  handleMenuClose = () => {
    if (this.props.onMenuClose) {
      this.props.onMenuClose();
    }
  };

  handleMenuOpen = () => {
    const { isGetOptionsOnMenuOpen, options } = this.props;

    if (isGetOptionsOnMenuOpen && !(options.items.length > 1)) {
      this.promiseOptions();
    }
  };

  handleAddOption = async () => {
    const { addOption } = this.props;
    const { filterValue } = this.state;

    await addOption?.onCreate(filterValue);
    this.selectRef.current.blur();
  };

  get options() {
    const { options, keys } = this.props;

    return options.items.map((item) => ({ value: item[keys.value], label: item[keys.label], badge: item[keys.badge] }));
  }

  get value() {
    const { value } = this.props;

    if (!value) {
      return null;
    }

    return this.options.find((item) => item.value === value.value);
  }

  get styles() {
    return {
      ...STYLES,
      menu: (base) => {
        return {
          ...base,
          marginTop: 0,
          borderRadius: '0 0 4px 4px',
          zIndex: 2,
          width: 'max-content',
          minWidth: '100%',
          right: this.props.alignRight ? 0 : undefined,
        };
      },
    };
  }

  get menuIsOpen() {
    const { isMenuOpen, isLoading } = this.state;
    const { options } = this.props;

    return isMenuOpen && (!isLoading || options.items.length) ? isMenuOpen : undefined;
  }

  get showAddOption() {
    const { filterValue } = this.state;
    const { options, keys } = this.props;

    return (
      filterValue && !options.items.find((option) => option[keys.label].toLowerCase() === filterValue.toLowerCase())
    );
  }

  get addOptionIsValid() {
    const { addOption } = this.props;

    return Boolean(addOption) && this.showAddOption;
  }

  get menuShouldBlockScroll() {
    return typeof this.props.menuShouldBlockScroll === 'boolean' ? this.props.menuShouldBlockScroll : true;
  }

  Input = (props: any) => {
    return (
      <components.Input
        innerRef={this.inputRef}
        {...props}
        placeholder={this.props.searchPlaceholder}
        className="SearchableSelect-input"
      />
    );
  };

  ControlComponent: React.FC<ControlProps<OptionTypeBase, boolean>> = ({ children, ...props }) => {
    return (
      <div className={`${props.menuIsOpen ? 'SearchableSelect-controlOpen' : ''}`}>
        <components.Control {...props}>
          {props.isFocused && this.props.isSearchable !== false ? (
            <Search2Icon className="SearchableSelect-search" />
          ) : null}
          {children}
          {props.isFocused && this.state.filterValue ? (
            <CloseIcon className="SearchableSelect-close" width="10px" height="10px" />
          ) : null}
        </components.Control>
      </div>
    );
  };

  NoOptionsMessage = () => {
    return <span className="SearchableSelect-noResults">{this.props.emptySearchResults || 'No Results.'}</span>;
  };

  ValueContainer: React.FC<ValueContainerProps<OptionTypeBase, boolean>> = ({ children, ...props }): any => {
    return components.ValueContainer && <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  };

  IndicatorSeparator: React.FC = () => null;

  DropdownIndicator: React.FC<any> = (props) => {
    return !props.isFocused && !this.props.disabled ? <CaretDownOutlined className="SearchableSelect-caret" /> : null;
  };

  SingleValue: React.FC<SingleValueProps<OptionTypeBase>> = ({ children, ...props }) => (
    <components.SingleValue {...props}>{children}</components.SingleValue>
  );

  Option: React.FC<OptionProps<OptionTypeBase, boolean>> = ({ children, ...props }) => {
    const { data, isFocused } = props;
    let option = null;

    const setOptionRef = (element) => {
      option = element;
    };

    useEffect(() => {
      if (props.isSelected) {
        option.scrollIntoView(false);
      }
    }, [props.isSelected]);

    return (
      <components.Option
        {...props}
        innerRef={setOptionRef}
        isFocused={isFocused || data.value === this.value?.value}
        className={classNames({ 'SearchableSelect-optionWithBadge': !!data.badge })}
      >
        <span>
          {this.state.filterValue && data.label ? (
            <Highlighter
              highlightClassName="SearchableSelect-highlight"
              className="SearchableSelect-highlightContainer"
              searchWords={[this.state.filterValue]}
              textToHighlight={data.label}
              autoEscape
            />
          ) : (
            data.label
          )}
        </span>
        {data.badge ? (
          <span className={'SearchableSelect-badge'}>
            {this.state.filterValue && data.badge ? (
              <Highlighter
                highlightClassName="SearchableSelect-highlight"
                className="SearchableSelect-highlightContainer"
                searchWords={[this.state.filterValue]}
                textToHighlight={data.badge}
                autoEscape
              />
            ) : (
              data.badge
            )}
          </span>
        ) : null}
      </components.Option>
    );
  };

  AddOption = (props) => {
    const { addOption } = this.props;

    return (
      <components.MenuList {...props}>
        {this.addOptionIsValid && <AddSelectOption text={addOption?.text} onClick={this.handleAddOption} />}
        {props.children}
      </components.MenuList>
    );
  };

  render() {
    return (
      <div>
        <Select
          autoFocus={this.props.autoFocus}
          className={'ReactSelect'}
          components={{
            DropdownIndicator: this.DropdownIndicator,
            IndicatorSeparator: this.IndicatorSeparator,
            SingleValue: this.SingleValue,
            Control: this.ControlComponent,
            ValueContainer: this.ValueContainer,
            NoOptionsMessage: this.NoOptionsMessage,
            Input: this.Input,
            Option: this.Option,
            MenuList: this.AddOption,
          }}
          inputValue={this.state.filterValue}
          isDisabled={this.props.disabled}
          isLoading={this.state.isLoading}
          isSearchable={this.props.isSearchable}
          menuIsOpen={this.menuIsOpen}
          menuPlacement="auto"
          menuPortalTarget={this.props.menuPortalTarget === undefined ? this.documentBody : this.props.menuPortalTarget}
          menuShouldBlockScroll={this.menuShouldBlockScroll}
          menuShouldScrollIntoView
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onMenuClose={this.handleMenuClose}
          onMenuOpen={this.handleMenuOpen}
          onKeyDown={this.props.addOption ? this.handleChangeOnPressEnter : undefined}
          onMenuScrollToBottom={this.handleMenuScrollToBottom}
          options={this.options}
          placeholder={this.props.placeholder}
          styles={this.styles}
          ref={this.selectRef}
          value={this.value}
          defaultMenuIsOpen={this.props.defaultMenuIsOpen}
        />
      </div>
    );
  }
}

export default SearchableSelectAsync;
