import React, { FC } from 'react';
import InfoTooltip from 'components/InfoTooltip';

import './styles.scss';

interface IProps {
  className?: string;
  mpg: number;
  tankCapacity: number;
}

const MPG: FC<IProps> = ({ mpg, tankCapacity }) => {
  return (
    <div className="MPG">
      <span className="MPG-text">{tankCapacity && mpg ? mpg : 'Tank Capacity needed to calculate'}</span>
      <span className="MPG-tooltip">
        <InfoTooltip>MPG calculated using tank percentage and capacity and may not be precise.</InfoTooltip>
      </span>
    </div>
  );
};

export default MPG;
