import React, { FC } from 'react';

import { LINKS } from 'config';
import { RefreshIconTransparent, HelpIcon, SadPathImage } from 'assets';

import './styles.scss';

const ErrorPage: FC = () => {
  return (
    <div className="ErrorPage">
      <p className="ErrorPage-title">Something went wrong...</p>
      <p className="ErrorPage-message ErrorPage-message--top">We’re not sure why but this page failed to load.</p>
      <div className="ErrorPage-image">
        <SadPathImage className="ErrorPage-imageSrc" />
      </div>
      <p className="ErrorPage-message ErrorPage-message--bottom">
        You can refresh your browser or try one of these options
      </p>
      <div className="ErrorPage-links">
        <a onClick={() => window.location.reload()} className="ErrorPage-link ErrorPage-link--reload">
          <RefreshIconTransparent className="ErrorPage-linkIcon" />
          <span className="ErrorPage-linkText">Reload this page</span>
        </a>
        <a href={LINKS.helpCenter} target="_blank" className="ErrorPage-link ErrorPage-link--help">
          <HelpIcon className="ErrorPage-linkIcon" />
          <span className="ErrorPage-linkText">Visit our Help Center</span>
        </a>
      </div>
    </div>
  );
};

export default ErrorPage;
