import React, { FC } from 'react';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import classNames from 'classnames';
import moment from 'moment';

import type Driver from 'models/Driver';
import Button from 'components/Button';
import DriversList from './DriversList';
import GoogleMapsSearch from 'components/GoogleMapsSearch';
import Input from 'components/Input';
import TextArea from 'components/Textarea';
import DatePicker from './DatePicker';

import { CalendarIcon } from 'assets';
import './styles.scss';

/*interface ISendDispatchValues {
  name: string;
  phone: string;
  destination: {
    address: string;
    latitude: number;
    longitude: number;
  };
  date: string;
  note: string;
}*/

interface IProps {
  className?: string;
  driversList?: Driver[];
  initialValues?: Dispatch.ISendDispatchFormValues;
  isEdit?: boolean;
  onCancel?: () => void;
  onSubmit: (values: Dispatch.ISendDispatchData) => void;
  cancelPath?: string;
}

const SendDispatchFormSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  phone: Yup.string()
    .matches(/^\d{10}$|^\d{3}-\d{3}-\d{4}$/, 'This driver doesn`t have a valid phone number')
    .required('This driver doesn`t have a valid phone number'),
  destination: Yup.object().shape({
    address: Yup.string('Invalid address').required('Invalid address'),
    latitude: Yup.number('Invalid address').required('Invalid address'),
    longitude: Yup.number('Invalid address').required('Invalid address'),
  }),
  date: Yup.date('Invalid date').required('Date is required'),
  note: Yup.string().max(200, 'Note must be not more than 200 characters'),
});

const SendDispatchForm: FC<IProps> = ({ className, driversList, initialValues, isEdit, onCancel, onSubmit }) => {
  const cn = classNames('SendDispatchForm', className);
  const name = get(initialValues, 'name', '');
  const phone = get(initialValues, 'phone', '');
  const destination = get(initialValues, 'destination', {});
  const date = get(initialValues, 'date', new Date());
  const note = get(initialValues, 'note', '');

  const handleSubmit = ({ name, destination, date, note }: Dispatch.ISendDispatchFormValues) => {
    onSubmit({ driverId: name, destination, dispatchDate: moment(date).format('YYYY-MM-DD'), dispatchNotes: note });
  };

  return (
    <div className={cn}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ name, phone, destination, date, note }}
        validationSchema={SendDispatchFormSchema}
        render={({ errors, touched, isValid, values, isSubmitting }: FormikProps<Dispatch.ISendDispatchFormValues>) => {
          return (
            <Form className="SendDispatchForm-form">
              <div className="SendDispatchForm-formContent">
                <div className="SendDispatchForm-labels">
                  <p className="SendDispatchForm-label SendDispatchForm-label--name">Who needs to be dispatched?</p>
                  <p className="SendDispatchForm-label SendDispatchForm-label--address">Where do they need to go?</p>
                  <p className="SendDispatchForm-label SendDispatchForm-label--date">
                    When should the driver go to this location?
                  </p>
                  <p className="SendDispatchForm-label SendDispatchForm-label--note">Notes</p>
                </div>
                <div className="SendDispatchForm-fields">
                  <div className="SendDispatchForm-field SendDispatchForm-field--name">
                    <Field
                      component={DriversList}
                      list={driversList}
                      error={Boolean((errors.name || errors.phone) && touched.name) ? errors.name || errors.phone : ''}
                      name="name"
                      placeholder="Driver Name"
                    />
                  </div>
                  <div className="SendDispatchForm-field SendDispatchForm-field--phone" style={{ display: 'none' }}>
                    <Field component={Input} name="phone" type="hidden" />
                  </div>
                  <div className="SendDispatchForm-field SendDispatchForm-field--address">
                    <Field
                      component={GoogleMapsSearch}
                      error={
                        Boolean(errors.destination && touched.destination)
                          ? errors.destination.latitude || errors.destination.address
                          : ''
                      }
                      googleMapURL={process.env.REACT_APP_GOOGLE_MAP_URL}
                      loadingElement={<div style={{ height: `100%` }} />}
                      name="destination.address"
                      placeholder="Address"
                    />
                  </div>
                  <div className="SendDispatchForm-field SendDispatchForm-field--date">
                    <Field
                      appliedDate={values.date}
                      component={DatePicker}
                      error={Boolean(errors.date && touched.date) ? errors.date : ''}
                      name="date"
                      placeholder="Today"
                      icon={<CalendarIcon fill="#929EB8" />}
                    />
                  </div>
                  <div className="SendDispatchForm-field SendDispatchForm-field--note">
                    <Field
                      component={TextArea}
                      counter
                      error={Boolean(errors.note && touched.note) ? errors.note : ''}
                      maxLength={200}
                      name="note"
                    />
                  </div>
                </div>
              </div>

              <div className="SendDispatchForm-buttons">
                <div className="SendDispatchForm-cancel">
                  <Button
                    className="Button--cancel Button--cancelColorLynch"
                    onClick={onCancel}
                    title="Cancel"
                    type="button"
                  />
                </div>
                <div className="SendDispatchForm-submit">
                  <Button
                    className="Button--apply"
                    disabled={!isValid || isSubmitting}
                    title={isEdit ? 'Update' : 'Send'}
                    type="submit"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
};

export default SendDispatchForm;
