import { action, computed, observable } from 'mobx';
import MediaTypeToggleFilter from './MediaTypeToggleFilter';
import type { PERSIST_FILTER_NAMES, SERVER_MEDIA_DATA_PARAMS } from './MediaTypeCategories';

export default class MediaTypeCategory {
  name: string;
  @observable list: MediaTypeToggleFilter[];
  type: SERVER_MEDIA_DATA_PARAMS;
  hidden: boolean;
  useCategoryName: boolean;
  persistName: PERSIST_FILTER_NAMES;
  private defaultSelect: boolean;

  constructor({ name, filters, type, hidden, useCategoryName, defaultSelect = false, persistName }) {
    this.name = name;
    this.list = [];
    this.type = type;
    this.hidden = hidden;
    this.useCategoryName = useCategoryName;
    this.persistName = persistName;
    this.defaultSelect = defaultSelect;
    this.setList(filters);
  }

  @action setList = (filters: { [key: string]: number }) => {
    this.list = Object.keys(filters).map((filter) => {
      return new MediaTypeToggleFilter(
        {
          count: filters[filter],
          icon: filter.toLowerCase() === 'video' || filter.toLowerCase() === 'image',
          isSelected: this.defaultSelect,
          name: this.useCategoryName ? this.name : filter,
          value: filter,
        },
        this.persistName
      );
    });
  };

  @action reset = () => {
    this.list.forEach((filter) => filter.isSelected.toggle(false));
  };

  destroy = () => {
    this.list.forEach((filter) => filter.destroy());
  };

  @computed get selected(): MediaTypeToggleFilter[] {
    return this.list.filter((filter) => filter.isSelected.value);
  }

  @computed get selectedFilter(): MediaTypeToggleFilter {
    return this.list.find((filter) => filter.isSelected.value);
  }

  get params() {
    return this.selected.map((filter) => filter.value).join();
  }
}
