import { ToggleField } from 'models/Fields';

class InspectionFormView {
  isError: ToggleField;

  constructor() {
    this.isError = new ToggleField(false);
  }
}

export default InspectionFormView;
