import React, { FC, Fragment } from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  className?: string;
  items: string[];
}

const TableRecipients: FC<IProps> = ({ items, className }) => {
  const isMoreThanOneGroup = items.length > 1;
  const cn = classNames('TableRecipients', className, {
    'TableRecipients--withRecipientsList': isMoreThanOneGroup,
  });

  return (
    <div className={cn}>
      {Boolean(items.length) ? (
        <div className="TableRecipients-wrapper">
          <p className="TableRecipients-firstRecipient">{items[0]}</p>
          {Boolean(isMoreThanOneGroup) && (
            <Fragment>
              <p className="TableRecipients-moreRecipients">+ {items.length - 1} more...</p>
              <ul className="TableRecipients-list">
                {items.map((item, index) => {
                  return (
                    <li className="TableRecipients-item" key={index}>
                      {item}
                    </li>
                  );
                })}
              </ul>
            </Fragment>
          )}
        </div>
      ) : (
        <div className="TableRecipient-noRecipients">-</div>
      )}
    </div>
  );
};

export default TableRecipients;
