import React, { Fragment } from 'react';
import validateAccessLevel from './validator';

interface IProps {
  requiredAccessLevel?: string[];
  strict?: boolean;
  reject?: any;
}

const UserAccessLevel: React.FC<IProps> = ({ requiredAccessLevel = [], children, reject = null, strict = false }) => {
  const hasAccess = validateAccessLevel(requiredAccessLevel, strict);
  if (hasAccess) {
    return <Fragment>{children}</Fragment>;
  }
  return reject;
};

export default UserAccessLevel;
