import React, { useEffect, useState, useRef } from 'react';
import numeral from 'numeral';
import type { InputProps } from 'antd/lib/input';
import Input from '../AntInput';

interface IFormats {
  endsWithDot: string;
  includesDot: string;
  base: string;
  full: string;
}

const createFormat = (decimals: number): IFormats => {
  const v = new Array(decimals).fill(0).join('');
  return {
    endsWithDot: '0,0.',
    includesDot: '0,0.0[' + v.replace(/0$/g, '') + ']',
    base: '0,0.[' + v + ']',
    full: '00.[' + v + ']',
  };
};

const calculateValue = (value: number | string, formats: IFormats): [string, string] => {
  let nextValue = String(value) || '';
  let val;

  nextValue = nextValue.replace(
    /\./g,
    (
      (occurrence) => (match) =>
        !occurrence++ ? match : ''
    )(0)
  );

  if (nextValue.endsWith('.')) {
    val = numeral(nextValue).format(formats?.endsWithDot) + '.';
  } else if (nextValue.includes('.')) {
    val = numeral(nextValue).format(formats?.includesDot);
  } else {
    val = numeral(nextValue).format(formats?.base);
  }
  return [val, numeral(val).format(formats?.full)];
};

interface IProps extends InputProps {
  value: number | string;
  decimals?: number;
  disabled?: boolean;
  onValueChange?(value: string, visualValue: string): void;
}

export default function NumberInputWithDecimalAndThousands({
  value,
  onValueChange,
  decimals = 2,
  disabled = false,
}: IProps) {
  const decimalsRef = useRef<IFormats | null>(null);

  if (decimalsRef.current === null) {
    decimalsRef.current = createFormat(decimals);
  }

  const [visualValue, setVisualValue] = useState(() => {
    const [visualValue] = calculateValue(value, decimalsRef.current);
    return visualValue;
  });

  useEffect(() => {
    const [visual] = calculateValue(value, decimalsRef.current);
    setVisualValue(visual);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [visual, internal] = calculateValue(e.target.value, decimalsRef.current);
    onValueChange?.(internal, visual);
  };

  return <Input maxLength={7} value={visualValue} onChange={handleChange} disabled={disabled} />;
}
