import { action, computed, observable } from 'mobx';
import type { Maintenances } from 'stores';
import MaintenanceTask from '../MaintenanceTask';
import Groups from 'models/Devices/Groups/Groups';
import { ListField, SimpleField, ToggleField } from 'models/Fields';
import ApplyTasksTable from './ApplyTasksTable';
import type Asset from 'models/Assets/Asset';
import { MAINTENANCE_APPLY_TASKS_ROW_LOADING_ID } from 'config';
import sortBy from 'lodash/sortBy';

class ApplyTasks {
  @observable tasks: MaintenanceTask[];
  task: SimpleField<MaintenanceTask>;
  editTask: SimpleField<MaintenanceTask>;
  isLoadingAllTasks: ToggleField;
  table: ApplyTasksTable;
  groups: Groups;
  context: Maintenances;

  constructor(context: Maintenances) {
    this.context = context;
    this.task = new SimpleField(null);
    this.editTask = new SimpleField(null);
    this.isLoadingAllTasks = new ToggleField(false);
    this.tasks = [];
    this.groups = Groups.get();
    this.table = new ApplyTasksTable(this);
  }

  @computed get numberOfCreatedTask() {
    return this.tasks.filter((task) => Boolean(task.model.id.value)).length;
  }

  @computed get isAllTasksCreated() {
    return Boolean(this.tasks.length) && this.tasks.every((task) => Boolean(task.model.id.value));
  }

  @computed get isValid() {
    return this.tasks.every((task) => task.model.isValid);
  }

  fetchTask = (id: string) => {
    return this.context.repository.entity(id).get();
  };

  setTask = async ({ data, id }: { data?: object; id?: string }) => {
    if (data) {
      this.task.set(new MaintenanceTask(data, this.context));
    } else if (id) {
      const data = await this.fetchTask(id);
      this.task.set(new MaintenanceTask(data, this.context));
    }
  };

  edit = () => {
    this.editTask.set(new MaintenanceTask(this.task.value.model.serverData, this.context));

    this.editTask.value.ui.modal.reset();
  };

  update = async () => {
    this.task.set(new MaintenanceTask(this.editTask.value.model.serverData, this.context));
    this.reCreateTasks();
  };

  @action sort = () => {
    this.tasks = sortBy(this.tasks, ['priority', (task) => task.model.asset.name.value.toLowerCase()]);
  };

  @action addTask = (asset) => {
    if (this.task.value) {
      this.removeTask(MAINTENANCE_APPLY_TASKS_ROW_LOADING_ID);

      this.tasks.push(this.createNewTask(asset));
    }
  };

  @action addAllTasks = (assets) => {
    if (this.task.value) {
      this.removeTask(MAINTENANCE_APPLY_TASKS_ROW_LOADING_ID);

      const tasks = assets.map((asset) => this.createNewTask(asset));

      this.tasks = tasks;
    }
  };

  createNewTask = (asset) => {
    const isOdometerPresent =
      (this.task.value.model.isRepeat && this.task.value.model.odometer.config.interval.value) ||
      this.task.value.model.odometer.config.next.value;

    const isEngHoursPresent =
      (this.task.value.model.isRepeat && this.task.value.model.engine.config.interval.value) ||
      this.task.value.model.engine.config.next.value;

    const isServiceTimePresent =
      (this.task.value.model.isRepeat && this.task.value.model.service.config.interval.value) ||
      this.task.value.model.service.config.next.value;

    return new MaintenanceTask(
      {
        ...this.task.value.model.serverData,
        asset,
        id: null,
        documents: [],
        odometerConfig: isOdometerPresent ? { ...this.task.value.model.serverData.odometerConfig, last: null } : null,
        engHoursConfig: isEngHoursPresent ? { ...this.task.value.model.serverData.engHoursConfig, last: null } : null,
        serviceTimeConfig: isServiceTimePresent
          ? { ...this.task.value.model.serverData.serviceTimeConfig, last: Date.now() }
          : null,
      },
      this.context
    );
  };

  preSelectAssets = (assets: ListField<Asset>) => {
    this.tasks.forEach((task) => {
      const asset = assets.value.find((asset) => asset.value.id.value === task.model.asset.id.value);

      if (asset) {
        asset.value.selected.toggle(true);
      }
    });
  };

  @action removeTask = (assetId: string) => {
    const index = this.tasks.findIndex((task) => task.model.asset.id.value === assetId);

    if (index >= 0) {
      this.tasks.splice(index, 1);
    }

    if (this.groups.selected) {
      const asset = this.groups.selected.assets.value.find((asset) => asset.value.id.value === assetId);

      if (asset) {
        asset.value.selected.toggle(false);
      }
    }
  };

  reCreateTasks = () => {
    const assets = this.tasks.map((task) => task.model.asset.serverModel);

    this.removeAll();
    assets.forEach((asset) => this.addTask(asset));
  };

  createAllTasks = async () => {
    this.isLoadingAllTasks.toggle(true);

    for (const task of this.tasks) {
      if (!Boolean(task.model.id.value)) {
        await task.model.save();
      }
    }

    this.isLoadingAllTasks.toggle(false);
  };

  @action removeAll = () => {
    this.tasks = [];
  };
}

export default ApplyTasks;
