/* Roles
 * Basic User "name": "Basic User", "id": 2, "description": "Basic User Role"
 * Manager Role "name": "Manager", "id": 3, "description": "Manager Role",
 * Admin Role "name": "Admin", "id": 1, "description": "Admin Role",
 * Map Only User Role "name": "Map Only User", "id": 2589, "description": "Map Only User Role",
 * Group Map Only User Role "name": "Group Map Only User", "id": 2590, "description": "Group Map Only User Role",
 * */

/* Features
 * Admin "name": "admin", "id": 2
 * Map "name": "map", "id": 5,
 * DashCams: "name": "dashcam", "id": 3
 * */

/* Applications
 * Web 3.0 "name": "web" "id": 1,
 * */

/* Subscription Plans
 * Starter "name": "str","id": 1
 * Standard "name": "std", "id": 2,
 * Pro "name": "pro", "id": 3,
 * */

// 2686 Map Only User Role
// 2687 Group Map Only User Role

export const ADMIN_GROUPS_UPDATE = 'web::management::groups::update';
export const ADMIN_USERS_UPDATE = 'web::management::users::update';
export const ADMIN_GEOZONES_READ = 'web::management::geozones::read';
export const ADMIN_GEOZONES_UPDATE = 'web::management::geozones::update';
export const ADMIN_GEOZONES_DELETE = 'web::management::geozones::delete';
export const ADMIN_VEHICLES_UPDATE = 'web::management::vehicles::update';
export const ADMIN_DRIVERS_DELETE = 'web::management::driver-basic::delete';
export const ADMIN_DRIVERS_READ = 'web::management::driver-basic::read';
export const ADMIN_DRIVERS_NOTES_UPDATE = 'web::management::driver-notes::update';
export const ADMIN_VEHICLE_FAULT_CODE_UPDATE = 'web::management::vehicle::fault-code::update';
export const ADMIN_VEHICLE_FAULT_CODE_READ = 'web::management::vehicle::fault-code::read';
export const VEHICLE_DRIVER_NAME_READ = 'web::management::vehicle-driver-name::read';
export const VEHICLE_DRIVER_NAME_UPDATE = 'web::management::vehicle-driver-name::update';
export const DASHCAM_VIEW_MEDIA = 'web::dashcam::view-media::read';
export const DASHCAM_REQUEST_MEDIA = 'web::dashcam::request-media::update';
export const MAP_VEHICLE_LABEL_READ = 'web::map::vehicle-label-toggle::read';
export const MAP_CREATE_GEOZONE_CREATE = 'web::management::geozones::create';
export const ADMIN_VEHICLE_NOTES_UPDATE = 'web::management::vehicle-notes::update';
export const ADMIN_VEHICLE_NOTES_READ = 'web::management::vehicle-notes::read';

export const ADMIN_INTEGRATIONS_READ = 'web::management::integrations::read';
export const ADMIN_INTEGRATIONS_DELETE = 'web::management::integrations::delete';

export const MANAGEMENT_GEOZONES_NOTES_DELETE = 'web::management::geozones-notes::delete';
export const MANAGEMENT_GEOZONES_NOTES_READ = 'web::management::geozones-notes::read';

export const REPORTS_PRIMARY_NAV = 'web::reports::primary-nav-icon::read';
export const MANAGEMENT_PRIMARY_NAV = 'web::management::primary-nav-icon::read';
export const ALERTS_PRIMARY_NAV = 'web::alerts::primary-nav-icon::read';
export const ALERTS_CONVERT_TO_CUSTOM = 'web::alerts::convert-to-custom::read';
export const DASHCAM_PRIMARY_NAV = 'web::dashcam::primary-nav-icon::read';
export const GENERAL_GLOBAL_SEARCH_READ = 'web::general::global-search::read';
export const DASHCAM_VEHICLE_LIST_TIME_SELECTOR = 'web::dashcam::vehicle-list-time-selector::read';
export const MAP_VEHICLE_PANEL_NOTE = 'web::map::vehicle-panel-note::read';
export const MAP_VEHICLE_PANEL_SHOW_TODAYS_TRAIL = 'web::map::vehicle-panel-show-todays-trail::read';
export const MAP_VEHICLE_PANEL_HISTORY_BUTTON = 'web::map::vehicle-panel-history-button::read';
export const MAP_VEHICLE_PANEL_FUEL_LEVEL = 'web::map::event-fuel-level::read';
export const MAP_TRACKPOINTS_AND_EVENTS_CHECKBOXES = 'web::map::trackpoint-event-checkbox::read';

export const ALERTS_PRIMARY_NAV_ICON = 'web::alerts::basic::read';
export const ALERTS_TABLE = 'web::alerts::basic::read';
export const CREATE_ALERT_BUTTON = 'web::alerts::basic::create';
export const NEW_ALERT_ACCELERATION = 'web::alerts::acceleration-alert::create';
export const NEW_ALERT_AFTER_HOURS = 'web::alerts::after-hours-alert::create';
export const NEW_ALERT_BRAKING = 'web::alerts::braking-alert::create';
export const NEW_ALERT_CORNERING = 'web::alerts::cornering-alert::create';
export const NEW_ALERT_EXCESSIVE_IDLING = 'web::alerts::excessive-idling-alert::create';
export const NEW_ALERT_FAULT_CODES = 'web::alerts::fault-codes-alert::create';
export const NEW_ALERT_FUELING_EXCEPTION = 'web::fuel-cards::fueling-exception-alert::create';
export const NEW_ALERT_GEOZONE = 'web::alerts::geozone-alert::create';
export const NEW_ALERT_IGNITION = 'web::alerts::ignition-alert::create';
export const NEW_ALERT_LOW_VEHICLE_BATTERY = 'web::alerts::low-vehicle-battery-alert::create';
export const NEW_ALERT_MAINTENANCE = 'web::alerts::maintenance-due-alert::create';
export const NEW_ALERT_POWER_DISCONNECT_TRACKER = 'web::alerts::power-disconnect-alert::create';
export const NEW_ALERT_POWER_DISCONNECT_DASHCAM = 'web::alerts::power-disconnect-alert::create';
export const NEW_ALERT_SPEEDS_OVER = 'web::alerts::speeding-over-alert::create';
export const NEW_ALERT_CUSTOM = 'web::alerts::custom-alert::create';
export const ROW_ACTIONS_EDIT_ALERT = 'web::alerts::basic::update';
export const ROW_ACTIONS_DELETE_ALERT = 'web::alerts::basic::delete';
export const ROW_ACTIONS_CONVERT_TO_CUSTOM = 'web::alerts::custom-alert::update';

export const SCHEDULED_REPORTS_NAV = 'web::reports::primary-nav-icon::read';
export const SCHEDULED_REPORTS_CREATE = 'web::reports::primary-nav-icon::read';
export const SCHEDULED_REPORTS_EDIT = 'web::reports::primary-nav-icon::read';
export const SCHEDULED_REPORTS_CLONE = 'web::reports::primary-nav-icon::read';
export const SCHEDULED_REPORTS_DELETE = 'web::reports::primary-nav-icon::read';
export const SCHEDULED_REPORTS_TABLE = 'web::reports::primary-nav-icon::read';
export const WEB_DASHCAM_BASIC_READ = 'web::dashcam::basic::read';
export const WEB_DASHCAM_BASIC_CREATE = 'web::dashcam::basic::create';
export const WEB_DASHCAM_BASIC_UPDATE = 'web::dashcam::basic::update';
export const WEB_DASHCAM_NOTES_UPDATE = 'web::dashcam::notes::update';
export const WEB_DASHCAM_REPORTS_UPDATE = 'web::reports::time-since-last-media-event::read';

export const WEB_TIMELINE_DETAILS_READ = 'web::map::time-line-details::read';
export const WEB_TIMELINE_READ = 'web::map::time-line-full::read';

export const WEB_MAP_TEXT_LOCATION_READ = 'web::map::text-location::read';
export const WEB_MAP_TEXT_LOCATION_CREATE = 'web::map::text-location::create';
export const WEB_MAP_CLOSEST_VEHICLE_READ = 'web::map::closest-vehicle::read';
export const WEB_MAP_CLEARSHARE_BASIC_READ = 'web::map::clearshare-basic::read';
export const WEB_MAP_CLEARSHARE_BASIC_CREATE = 'web::map::clearshare-basic::create';
export const WEB_MAP_CLEARSHARE_BASIC_DELETE = 'web::map::clearshare-basic::delete';

export const WEB_REPORTS_SCHEDULED_REPORTS_READ = 'web::reports::scheduled-reports::read';

export const WEB_FUEL_CARDS_BASIC_UPDATE = 'web::fuel-cards::basic::update';
export const WEB_FUEL_CARDS_BASIC_DELETE = 'web::fuel-cards::basic::delete';

export const WEB_MAP_FINANCE_BASIC_DELETE = 'web::finance::basic::delete';

export const WEB_MANAGEMENT_VEHICLE_FUEL_DETAILS_READ = 'web::management::vehicle-fuel-details::read';
export const WEB_MANAGEMENT_VEHICLE_FUEL_DETAILS_UPDATE = 'web::management::vehicle-fuel-details::update';

export const WEB_FINANCE_DETAILS_READ = 'web::finance::details::read';

export const ACL = {
  MAP: {
    SETTINGS: {
      DIRECTIONAL_ARROWS: { READ: 'web::map::directional-arrows::read' },
    },
  },
  MAINTENANCE: {
    BASIC: {
      READ: 'web::maintenance::basic::read',
      CREATE: 'web::maintenance::basic::create',
      UPDATE: 'web::maintenance::basic::update',
      DELETE: 'web::maintenance::basic::delete',
    },
    REQUESTS: {
      READ: 'web::maintenance::requests::read',
    },
    NOTES: {
      UPDATE: 'web::maintenance::notes::update',
    },
  },
  MANAGEMENT: {
    RESET_MPG: {
      DELETE: 'web::management::reset-mpg::delete',
    },
    RESET_ADU: {
      DELETE: 'web::management::reset-adu::delete',
    },
    DRIVERS: {
      READ: 'web::management::driver-basic::read',
      CREATE: 'web::management::driver-basic::create',
      UPDATE: 'web::management::driver-basic::update',
      DELETE: 'web::management::driver-basic::delete',
    },
  },
  INSPECTIONS: {
    BASIC: {
      READ: 'web::inspections::basic::read',
      CREATE: 'web::inspections::basic::create',
      UPDATE: 'web::inspections::basic::update',
      DELETE: 'web::inspections::basic::delete',
    },
    TEMPLATES: {
      READ: 'web::inspections::templates::read',
      CREATE: 'web::inspections::templates::create',
      UPDATE: 'web::inspections::templates::update',
      DELETE: 'web::inspections::templates::delete',
    },
    NOTES: {
      READ: 'web::inspections::notes::read',
      CREATE: 'web::inspections::notes::create',
      UPDATE: 'web::inspections::notes::update',
      DELETE: 'web::inspections::notes::delete',
    },
  },
};
