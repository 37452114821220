import type ICustomerEstimate from 'interfaces/stores/Customers/ICustomerEstimate';
import type ICustomerAddress from 'interfaces/stores/Customers/ICustomerAddress';
import type ICustomerEstimateItem from 'interfaces/stores/Customers/ICustomerEstimateItem';
import { CustomerEstimateItem } from './CustomerEstimateItem';

export class CustomerEstimate implements ICustomerEstimate {
  jobId: number;
  description: string;
  domain: string;
  id: number;
  integrationId: string;
  name: string;
  status: string;
  modifiedOn: number;
  soldBy: string;
  totalAmount: number;
  transactionDate: number;
  items: ICustomerEstimateItem[] = [];
  shipAddress?: ICustomerAddress;
  billAddress?: ICustomerAddress;

  constructor(props) {
    this.jobId = props.jobId;
    this.description = props.description;
    this.domain = props.domain;
    this.id = props.id;
    this.integrationId = props.integrationId;
    this.name = props.name;
    this.status = props.status;
    this.soldBy = props.soldBy;
    this.totalAmount = props.totalAmount;
    this.transactionDate = props.transactionDate;
    this.items = (props.items || []).map(item => new CustomerEstimateItem(item));
    this.shipAddress = props.shipAddress;
    this.billAddress = props.billAddress;
  }
}
