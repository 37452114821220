import { observable, action, computed } from 'mobx';
import { uniqBy, sortBy } from 'lodash';
import type { ISelectOptions } from 'interfaces/models/Select/ISelect';
import ServiceTypeItem, { IServerServiceTypeItem } from './ServiceTypeItem';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';
import { SimpleField } from 'models/Fields';

class ServiceType implements ISelectOptions<ServiceTypeItem, IServerServiceTypeItem> {
  _items: ServiceTypeItem[];
  @observable item: ServiceTypeItem;
  @observable totalPages: number;

  repository: IEntityRepository;

  preselectedId: string;
  preselectedFetched: boolean;
  includeAll: boolean;

  @observable filter: string;
  @observable assetGroupId: SimpleField<number>;
  @observable assetId: SimpleField<number>;

  constructor(id: string = null, name: string = '', includeAll: boolean = false) {
    this._items = [];
    this.item = new ServiceTypeItem({ id, name, isDefault: false, status: 'ENABLED', count: 0 });
    this.repository = repositoryService.get('maintenances').entity('counts');
    this.preselectedId = id;
    this.assetGroupId = new SimpleField<number>(null);
    this.assetId = new SimpleField<number>(null);
    this.includeAll = includeAll;
  }

  @computed get items() {
    return this._items.filter((item) => {
      return (
        ((item.id && !this.includeAll) || this.includeAll) &&
        (item?.name.toLowerCase?.().includes?.(this.filter?.toLowerCase?.()) || !this.filter)
      );
    });
  }

  @action fetch = async (data, { clear, sortId, sortLabel }) => {
    const { filter } = data;

    if (!this._items.length) {
      const response = await this.repository.get({
        assetGroupId: this.assetGroupId.value,
        assetId: this.assetId.value,
      });

      const options = response?.map?.(
        (item) =>
          new ServiceTypeItem({
            status: 'ENABLED',
            ...item,
            isDefault: !this.includeAll ? item.isDefault : false,
          })
      );

      const nextOptions = clear ? [...options] : [...this._items, ...options];
      const uniqueOptions = uniqBy(nextOptions, sortId);
      const sortOptions = sortBy(uniqueOptions, [(option) => option[sortLabel].toLowerCase()]);

      this._items = sortOptions.filter(
        (option) => option.status === 'ENABLED' || this.item.id === option.id.toString()
      );
      this.totalPages = response?.totalPages || 1;
      this.addDefaultOption();
    } else {
      this.filter = filter;
    }
  };

  @action addDefaultOption = () => {
    if (this.preselectedId && !this.preselectedFetched) {
      const option = this.getOptionById(String(this.preselectedId));

      if (option) {
        this.preselectedFetched = true;
        this.item.set({ ...option.model, id: option.model.id.toString() });
      }
    } else {
      const option = this.items.find((item) => item.isDefault);

      if (option) {
        this.item.set({ ...option.model, id: option.model.id.toString() });
      }
    }
  };

  @action addOption = async (option: IServerServiceTypeItem) => {
    option = option || { id: 0, name: 'None', isDefault: false, status: 'DISABLED', count: 0 };

    const currentOption = this.getOptionById(option.id.toString());

    if (!currentOption) {
      this._items.push(new ServiceTypeItem(option));
    }
  };

  getOptionById = (id: string): ServiceTypeItem => {
    return this.items.find((item) => item.id === id);
  };

  @action clear = () => {
    this._items = [];
  };
}

export default ServiceType;
