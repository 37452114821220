import { ListField, SimpleField } from 'models/Fields';

class MediaDuration {
  active: SimpleField<number>;
  options: ListField<number>;

  constructor(active: number = 20000, options: number[] = []) {
    this.active = new SimpleField(active);
    this.options = new ListField(options);
  }
}

export default MediaDuration;
