import React, { FC } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import type MediaTypeToggleFilter from 'models/Dashcam/MediaLibrary/MediaType/MediaTypeToggleFilter';
import MediaLibraryFilterLabel from '../FIlterMediaLabel';

import './styles.scss';

interface IProps {
  className?: string;
  filters: MediaTypeToggleFilter[];
}

const MediaLibraryFilterLabelList: FC<IProps> = ({ className, filters }) => {
  const cn = classNames('MediaLibraryFilterLabelList', className);
  const showClearAll = filters.some((filter) => filter.count > 0);
  const clearAll = () => filters.forEach((filter) => filter.isSelected.toggle(false));

  return (
    filters.length && (
      <div className={cn}>
        <div className="MediaLibraryFilterLabelList-labels">
          {filters.map((filter, index) => {
            return (
              filter.count > 0 && (
                <div className="MediaLibraryFilterLabelList-label" key={index}>
                  <MediaLibraryFilterLabel filter={filter} />
                </div>
              )
            );
          })}
          {showClearAll && (
            <div className="MediaLibraryFilterLabelList-clear" onClick={clearAll}>
              Clear All Filters
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default observer(MediaLibraryFilterLabelList);
