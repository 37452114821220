import React from 'react';
import classNames from 'classnames';

import { CheckIcon } from 'assets';

import './styles.scss';

const DispatchOrder = ({ order, isCompleted, isFirst }) => {
  const containerCN = classNames('DispatchOrder-container', {
    'DispatchOrder-container--completed': isCompleted,
    'DispatchOrder-container--first': isFirst,
  });

  return (
    <div className="DispatchOrder">
      <div className={containerCN}>
        {isCompleted ? (
          <CheckIcon fill="#fff" width="14px" height="11px" />
        ) : (
          <span className="DispatchOrder-order">{order}</span>
        )}
      </div>
    </div>
  );
};

export default DispatchOrder;
