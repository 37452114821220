import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { SearchStore } from 'stores';
import Search from 'components/Search';

interface ISearchContainerProps {
  searchStore?: SearchStore;
}

@inject(({ searchStore }) => ({
  searchStore,
}))
@observer
class SearchContainer extends Component<ISearchContainerProps> {
  render() {
    const {
      searchStore: {
        isSearchActive,
        toggleSearchState,
        searchQuery,
        handleChange,
        getSearchResults,
        resetSearch,
        results,
        noResults,
        devicesWithDrivers,
        existingDevicesIds,
        repositorySearch: { getState },
      },
    } = this.props;

    return (
      <Search
        isSearchActive={isSearchActive}
        results={results}
        onChange={handleChange}
        onChangeSearchState={toggleSearchState}
        searchQuery={searchQuery}
        getSearchResults={getSearchResults}
        resetSearch={resetSearch}
        noResults={noResults}
        devicesWithDriversList={devicesWithDrivers}
        existingDevicesIds={existingDevicesIds}
        error={getState.error}
      />
    );
  }
}

export default SearchContainer;
