import React, { useEffect } from 'react';

import SearchableSelectAsync from 'components/Select/SearchableSelectAsync';
import type { IServerServiceTypeItem, IServiceTypeItem } from 'models/Maintenances/Models/Service/ServiceTypeItem';
import type ServiceType from 'models/Maintenances/Models/Service/ServiceType';

interface IProps {
  serviceType: ServiceType;
  onChange?: (option: Select.ISelectOption) => void;
  assetId?: string;
  assetGroupId?: string;
}

const AlertPreferencesMaintenanceTypeSelect: React.FC<IProps> = ({ serviceType, onChange, assetId, assetGroupId }) => {
  const handleChangeServiceType = (option: Select.ISelectOption) => {
    onChange(option);
  };

  useEffect(() => {
    serviceType.clear();
    serviceType.assetId.set(!isNaN(Number(assetId)) ? Number(assetId) : null);
    serviceType.assetGroupId.set(!isNaN(Number(assetGroupId)) ? Number(assetGroupId) : null);
  }, [assetId, assetGroupId]);

  return (
    <SearchableSelectAsync<IServiceTypeItem, IServerServiceTypeItem>
      handleChange={handleChangeServiceType}
      keys={{ value: 'id', label: 'name', badge: 'count' }}
      options={serviceType}
      value={serviceType.item ? { value: serviceType.item.id, label: serviceType.item.name } : null}
      key={assetId || assetGroupId || 'AlertPreferencesMaintenanceTypeSelect'}
    />
  );
};

export default AlertPreferencesMaintenanceTypeSelect;
