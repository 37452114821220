import { observable, action, computed } from 'mobx';
import { orderBy, unionBy } from 'lodash';
import { ADMIN_GROUPS_TABLE_COLUMNS, STORAGE_ITEMS } from 'config';
import { Group } from 'models';
import { repositoryService } from 'services';
import TableBase from '../TableBase';
import type { IEditColumns } from 'models/Tables/IEditColumns';
import type { ISortColumn } from 'models/Tables/ISortColumn';
import APIStoreBase from '../APIStoreBase';
import GroupVehicle from 'models/GroupVehicle';
import GroupsDownload from 'models/Admin/Groups/GroupsDownload';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

const initialColumns: Array<IEditColumns<ADMIN_GROUPS_TABLE_COLUMNS>> = [
  { value: ADMIN_GROUPS_TABLE_COLUMNS.NAME, isSelected: true },
  { value: ADMIN_GROUPS_TABLE_COLUMNS.COUNT, isSelected: true },
  { value: ADMIN_GROUPS_TABLE_COLUMNS.CREATED_BY, isSelected: false },
  { value: ADMIN_GROUPS_TABLE_COLUMNS.CREATION_TIME, isSelected: false },
  { value: ADMIN_GROUPS_TABLE_COLUMNS.UPDATED_BY, isSelected: false },
  { value: ADMIN_GROUPS_TABLE_COLUMNS.LAST_UPDATE_TIME, isSelected: false },
];

const initialSortedColumn: ISortColumn = {
  field: 'name',
  order: 'ascend',
};

const storageNames = {
  columns: STORAGE_ITEMS.admin.groups.columns,
  sortedColumn: STORAGE_ITEMS.admin.groups.sortedColumn,
  pagination: STORAGE_ITEMS.admin.groups.pagination,
  searchInColumn: STORAGE_ITEMS.admin.groups.searchInColumn,
};

export interface ITableGroup {
  name: string;
  count: number;
  id: string;
  key: string;
  createdBy: string;
  updatedBy: string;
  creationTime: string;
  lastUpdateTime: string;
}

export interface IGroupSearchParams {
  description?: string;
  displayName?: string;
  groupId?: string;
  createdBy?: string;
  updatedBy?: string;
  creationTime?: string;
  lastUpdateTime?: string;
}

export enum GroupSearchMap {
  description = 'description',
  id = 'groupId',
  name = 'description',
  count = 'assetCount',
  createdBy = 'createdBy',
  updatedBy = 'updatedBy',
  creationTime = 'creationTime',
  lastUpdateTime = 'lastUpdateTime',
}

export class GroupsAdmin extends TableBase<IEditColumns<ADMIN_GROUPS_TABLE_COLUMNS>> {
  constructor() {
    super({ columns: initialColumns, sortedColumn: initialSortedColumn, storageNames });

    this.repositoryVehicles = repositoryService.get('vehicles/ids');
    this.repositoryGroups = repositoryService.get('groups');
    this.repositoryAssetGroups = repositoryService.get('asset-groups');
    this.repositoryNotifications = repositoryService.get('notifications');
    this.repositoryGroupsAssignDevice = this.repositoryGroups.entity('assign').entity('device');
    this.repositoryGroupsUnAssignDevice = this.repositoryGroups.entity('unassign').entity('device');
    this.repositoryGroups.listenTo('error:get', () => this.resetGroupsList());
    this.downloadGroups = new GroupsDownload(
      this.repositoryNotifications.entity('email').entity('document')
    ).initialize(this);
  }

  @observable selectedGroupId: string = '';
  @observable selectedGroup: Group = null;
  @observable groupsList: Group[] = [];
  @observable groupsListTotal: number = null;
  @observable vehiclesList: GroupVehicle[] = [];

  @observable getSelectedGroupRequestStatus: APIStoreBase = new APIStoreBase();
  @observable deleteGroupAPIRequestStatus: APIStoreBase = new APIStoreBase();
  @observable updateSelectedGroupVehiclesListRequestStatus: APIStoreBase = new APIStoreBase();

  @observable searchQueryGroupVehicles = '';
  @observable searchQueryVehicles = '';
  @observable downloadGroups: GroupsDownload;

  repositoryVehicles: IRepository;
  repositoryGroups: IRepository;
  repositoryAssetGroups: IRepository;
  repositoryNotifications: IRepository;
  repositoryGroupsAssignDevice: IEntityRepository;
  repositoryGroupsUnAssignDevice: IEntityRepository;

  @computed get tableSource(): ITableGroup[] {
    return this.groupsList.map(
      ({
        displayName,
        description,
        deviceCount,
        groupId,
        loggerInfo: { createdBy, updatedBy, creationTime, lastUpdateTime },
      }) => ({
        name: displayName || description || '-',
        count: deviceCount,
        id: groupId,
        key: groupId,
        createdBy: createdBy || '-',
        updatedBy: updatedBy || '-',
        creationTime: creationTime.date,
        lastUpdateTime: lastUpdateTime.date,
      })
    );
  }

  @computed get selectedGroupVehiclesList() {
    return this.vehiclesList.filter((item: GroupVehicle) => {
      return item.selected === true;
    });
  }

  get notSelectedGroupVehiclesList() {
    return this.vehiclesList.filter((item: GroupVehicle) => {
      return item.selected === false;
    });
  }

  @computed get filteredSelectedGroupVehiclesList() {
    return this.selectedGroupVehiclesList.filter(({ name, id }: GroupVehicle) => {
      return (
        name.toLowerCase().includes(this.searchQueryGroupVehicles.toLowerCase()) ||
        id.toLowerCase().includes(this.searchQueryGroupVehicles.toLowerCase())
      );
    });
  }

  @computed get filteredVehiclesList() {
    return this.vehiclesList.filter(({ name, id, selected }: GroupVehicle) => {
      return (
        (selected !== undefined && name.toLowerCase().includes(this.searchQueryVehicles.toLowerCase())) ||
        id.toLowerCase().includes(this.searchQueryVehicles.toLowerCase())
      );
    });
  }

  @computed get searchData() {
    return this.selectedSearchColumns.reduce(
      (acc, cur) => ({
        ...acc,
        [GroupSearchMap[cur.column]]: cur.value,
      }),
      {}
    );
  }

  @computed get sortData() {
    return {
      sortBy: GroupSearchMap[this.sortedColumn.field],
      order: this.sortedColumn.order?.replace('end', '').toUpperCase(),
    };
  }

  @action setSelectedGroupVehiclesList(devices: []) {
    this.vehiclesList = orderBy(
      unionBy(
        devices.map((serverVehicle) => {
          const { deviceId, displayName, cp } = serverVehicle;
          return new GroupVehicle(deviceId, displayName, cp, true);
        }),
        this.vehiclesList,
        'id'
      ),
      ({ originalName }: GroupVehicle) => (originalName ? originalName : undefined),
      ['asc']
    );
  }

  @action setSelectedGroup = async (groupId) => {
    this.getSelectedGroupRequestStatus.reset().setLoading(true);

    try {
      this.resetVehiclesList();

      const serverGroup = await this.repositoryGroups
        .entity(groupId)
        .get({ withDevices: true, withInactiveDevices: true });

      this.selectedGroup = new Group(serverGroup);
      this.selectedGroupId = groupId;
      this.setSelectedGroupVehiclesList(serverGroup.devices);
      this.getSelectedGroupRequestStatus.setSuccess(true);
      this.getAllVehicles();
    } catch (error) {
      this.getSelectedGroupRequestStatus.setError(error);
      this.resetSelectedGroup();
      throw new Error(error);
    }

    this.getSelectedGroupRequestStatus.setLoading(false);
  };

  @action resetSelectedGroup = () => {
    this.selectedGroupId = '';
    this.selectedGroup = null;
  };

  @computed get filteredGroupsList() {
    return orderBy(this.groupsList, ({ displayName }: Group) => displayName.toLowerCase(), ['asc']);
  }

  @action setSelectedGroupId = (groupId: string) => (this.selectedGroupId = groupId);

  @action getGroupsList = async ({
    order,
    page,
    pageSize,
    search,
    sortBy,
  }: {
    order?: string;
    page?: number;
    pageSize?: number;
    search?: IGroupSearchParams;
    sortBy?: string;
  }) => {
    const response = await this.repositoryAssetGroups.get({ order, page, pageSize, ...search, sortBy });

    this.groupsList = response.items.map((serverGroup) => new Group(serverGroup));
    this.groupsListTotal = response.total;
    this.isSearchChanged = false;
  };

  @action getAllVehicles = async () => {
    const response = await this.repositoryVehicles.get({ includeInactive: true });
    const serverVehiclesList = response.items;

    this.vehiclesList = orderBy(
      unionBy(
        this.vehiclesList,
        serverVehiclesList.map((serverVehicle) => {
          const { id, displayName, cpNumber } = serverVehicle;
          return new GroupVehicle(id, displayName, cpNumber);
        }),
        'id'
      ),
      ({ originalName }: GroupVehicle) => (originalName ? originalName : undefined),
      ['asc']
    );
  };

  @action resetGroupsList = () => (this.groupsList = []);

  @action updateSearchQueryGroupVehicles = (value: string) => {
    this.searchQueryGroupVehicles = value;
  };

  @action updateSearchQueryVehicles = (value: string) => {
    this.searchQueryVehicles = value;
  };

  @action addNewGroup = async (groupName: string) => {
    const data = await this.repositoryGroups.create({ description: groupName });
    await this.setSelectedGroup(data.groupId);
    EventsBus.get().trigger(APP_EVENTS.GROUPS.UPDATED);

    return data.groupId;
  };

  @action deleteGroup = async (groupId: string) => {
    this.deleteGroupAPIRequestStatus.reset().setLoading(true);

    try {
      await this.repositoryGroups.entity(groupId).delete();

      this.deleteGroupAPIRequestStatus.setSuccess(true);
      EventsBus.get().trigger(APP_EVENTS.GROUPS.UPDATED);
    } catch (error) {
      this.deleteGroupAPIRequestStatus.setError(error.message || 'Something went wrong');
    }

    this.deleteGroupAPIRequestStatus.setLoading(false);
  };

  @action resetVehiclesList = () => {
    this.vehiclesList = [];
  };

  getVehicleById(id: string): GroupVehicle {
    return this.vehiclesList.find((vehicle: GroupVehicle) => vehicle.id === id);
  }

  @action assignVehicleToSelectedGroup = async (id: string, makeCall?: boolean) => {
    const vehicle = this.getVehicleById(id);
    if (vehicle) {
      vehicle.setSelected();
      if (makeCall) {
        await this.repositoryGroupsAssignDevice.put({ deviceID: id, groupID: this.selectedGroupId });
      }
    }
  };

  @action assignVehiclesToSelectedGroup = async (ids: string[]) => {
    await this.repositoryGroupsAssignDevice.put({ deviceIds: ids, groupID: this.selectedGroupId });
  };

  @action assignAllVehiclesToSelectedGroup = async (makeCall?: boolean) => {
    const vehicles: string[] = this.notSelectedGroupVehiclesList.map((vehicle: GroupVehicle) => {
      vehicle.setSelected();
      return vehicle.id;
    });

    if (vehicles && makeCall) {
      await this.repositoryGroupsAssignDevice.put({ deviceIds: vehicles, groupID: this.selectedGroupId });
    }
  };

  @action unAssignVehicleFromSelectedGroup = async (id: string, makeCall?: boolean) => {
    const vehicle = this.getVehicleById(id);

    if (vehicle) {
      vehicle.setNotSelected();
      if (makeCall) {
        await this.repositoryGroupsUnAssignDevice.put({ deviceID: id, groupID: this.selectedGroupId });
      }
    }
  };

  @action unAssignAllVehiclesFromSelectedGroup = async (makeCall?: boolean) => {
    const vehicles: string[] = this.selectedGroupVehiclesList.map((vehicle: GroupVehicle) => {
      vehicle.setNotSelected();
      return vehicle.id;
    });

    if (vehicles.length && makeCall) {
      await this.repositoryGroupsUnAssignDevice.put({ deviceIds: vehicles, groupID: this.selectedGroupId });
    }
  };

  @action unAssignVehiclesFromSelectedGroup = async (ids: string[]) => {
    await this.repositoryGroupsUnAssignDevice.put({ deviceIds: ids, groupID: this.selectedGroupId });
  };

  @action updateGroupVehiclesList = async (removedVehiclesIds: string[], addedVehiclesIds: string[]) => {
    const requests = [
      ...(removedVehiclesIds.length ? [this.unAssignVehiclesFromSelectedGroup(removedVehiclesIds)] : []),
      ...(addedVehiclesIds.length ? [this.assignVehiclesToSelectedGroup(addedVehiclesIds)] : []),
    ];

    this.updateSelectedGroupVehiclesListRequestStatus.reset().setLoading(true);

    try {
      await Promise.all(requests);
      this.updateSelectedGroupVehiclesListRequestStatus.setSuccess(true);
    } catch (error) {
      this.updateSelectedGroupVehiclesListRequestStatus.setError(error.message);
    }
    this.updateSelectedGroupVehiclesListRequestStatus.setLoading(false);
  };
}

export default new GroupsAdmin();
