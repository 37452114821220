import React from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  value: string;
  selected: boolean;
  onSelect?: (time: string) => void;
}

const TimePickerListItem: React.FC<IProps> & { activeClassName: string } = ({ value, onSelect, selected }) => {
  const handleClick = () => {
    onSelect(value);
  };

  const cn = classNames('TimePickerListItem', {
    'TimePickerListItem--active': Boolean(selected),
  });

  return (
    <li onClick={handleClick} className={cn} data-value={value}>
      {value}
    </li>
  );
};

TimePickerListItem.activeClassName = 'TimePickerListItem--active';

export default TimePickerListItem;
