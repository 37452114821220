import React, { useCallback } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import classnames from 'classnames';

import { LogoClearpathIcon, LogoFieldServiceIcon, OpenNewWindow, RefreshIcon } from 'assets';

import { AppSwitcherItem } from '../AppSwitcherItem';

import { tokenManager } from 'utils';

import './styles.scss';

export interface IAppSwitcherProps {
  className?: string;
  onClick: (id?: string) => void;
}

export const AppSwitcher = ({ className, onClick }: IAppSwitcherProps) => {
  const handleClick = useCallback((id: string) => {
    if (id === 'clearpath') {
      window.location.reload();
    } else if (id === 'field-service') {
      tokenManager.getSBToken().then((url) => {
        window.open(url, '_blank');
      });
    }
  }, []);

  const onClickOutside = useCallback(() => {
    onClick();
  }, []);

  return (
    <OutsideClickHandler onOutsideClick={onClickOutside}>
      <div className={classnames('AppSwitcher-root', className)}>
        <AppSwitcherItem onClick={handleClick} id="clearpath">
          <LogoClearpathIcon />
          <i>
            <RefreshIcon />
          </i>
        </AppSwitcherItem>
        <AppSwitcherItem onClick={handleClick} id="field-service">
          <LogoFieldServiceIcon />
          <i>
            <OpenNewWindow />
          </i>
        </AppSwitcherItem>
      </div>
    </OutsideClickHandler>
  );
};
