import { ListField } from 'models/Fields';
import type { ITableSearchField } from './ITableSearchField';
import type { IEditColumns } from './IEditColumns';
import TableSort from './TableSort';

class TableFilter {
  search: ListField<ITableSearchField>;
  selectedColumns: ListField<IEditColumns<string>>;
  sort: TableSort;

  constructor(columns: Array<IEditColumns<string>> | null) {
    this.search = new ListField();
    this.selectedColumns = new ListField(columns);
    this.sort = new TableSort();
  }
}

export default TableFilter;
