import { DATE_TIME_FORMATS, VEHICLE_DRIVER_NAME_READ, VEHICLE_DRIVER_NAME_UPDATE } from 'config';
import TableDownload from 'models/Tables/TableDownload';
import timeStore from 'stores/TimeStore';
import { getFormattedTime } from 'utils';
import type { SimpleField } from 'models/Fields';
import type DueInCell from '../Representation/DueInCell';
import type FrequencyCell from '../Representation/FrequencyCell';
import type MaintenanceUpcomingTable from '../Tables/UpcomingTable';
import { validateAccessLevel } from 'stores';

class MaintenanceUpcomingDownload extends TableDownload<MaintenanceUpcomingTable> {
  private mapNameHeader = {
    taskName: {
      title: 'Task Name',
    },
    vehicleName: {
      title: 'Vehicle Name',
    },
    dueIn: {
      title: 'Due In',
    },
    frequency: {
      title: 'Frequency',
    },
    serviceType: {
      title: 'Service Type',
    },
    currentOdometer: {
      title: 'Current Odometer',
    },
    currentEngineHours: {
      title: 'Current Engine Hours',
    },
    driverName: {
      title: 'Driver Name',
      acls: [VEHICLE_DRIVER_NAME_READ, VEHICLE_DRIVER_NAME_UPDATE],
    },
    createdOn: {
      title: 'Created On',
    },
    createdBy: {
      title: 'Created By',
    },
    modifiedOn: {
      title: 'Modified On',
    },
    modifiedBy: {
      title: 'Modified By',
    },
  };

  private extendedFields = {
    dueIn: {
      get(value: DueInCell) {
        return value
          ? value.rows
              .map((config) => {
                if (config.visible) {
                  return `${config.textDueIn} ${config.textNext}`;
                }
              })
              .join(' ')
          : '-';
      },
    },
    frequency: {
      get(value: FrequencyCell) {
        return `${value.isRepeat ? 'Repeat' : 'One time'} ${value.isRepeat && value.text ? value.text : ''}`;
      },
    },
    driverName: {
      get(value: SimpleField<string>) {
        return value.value || '';
      },
    },
    createdOn: {
      get(value: number) {
        return value ? getFormattedTime(value, DATE_TIME_FORMATS.adminVehicleSettings, timeStore.sessionTimezone) : '-';
      },
    },
    modifiedOn: {
      get(value: number) {
        return value ? getFormattedTime(value, DATE_TIME_FORMATS.adminVehicleSettings, timeStore.sessionTimezone) : '-';
      },
    },
  };

  protected getFile = async (): Promise<Blob> => {
    const ReportFile = await import('../../Report/ReportDownload/ReportFile');
    const reportFile = new ReportFile.default(this.format.value, {
      title: 'Upcoming Maintenance',
      description: '',
      tables: [{ ...this.getData(), title: null }],
    });

    const file = await reportFile.create();

    return file;
  };

  protected get fileName() {
    return 'Upcoming Maintenance';
  }
  private getData = () => {
    const columnsIds = this.context.columns
      .filter((column) => {
        if (this.mapNameHeader[column.value]?.acls) {
          return column.isSelected && validateAccessLevel(this.mapNameHeader[column.value]?.acls);
        } else {
          return column.isSelected;
        }
      })
      .map((column) => column.value);
    const rows = this.context.source.map((item) => {
      return columnsIds.map((column) => {
        if (this.extendedFields.hasOwnProperty(column)) {
          return this.extendedFields[column].get(item[column]);
        }
        return item[column];
      });
    });

    return {
      columns: columnsIds.map((columnId) => this.mapNameHeader[columnId].title),
      rows,
      total: [],
    };
  };
}

export default MaintenanceUpcomingDownload;
