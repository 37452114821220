import type { IFileServerModel } from '../IFileModel';
import AppFileImageModel from './AppFileImageModel';
import AppFileVideoModel from './AppFileVideoModel';
import AppFileOthersModel from './AppFileOthersModel';
import AppFilePDFModel from './AppFilePDFModel';

class AppFileModelFactory {
  model: IFileServerModel;

  constructor(model: IFileServerModel) {
    this.model = model;
  }

  get = () => {
    const { type } = this.model;

    switch (true) {
      case type?.includes('video'):
        return new AppFileVideoModel(this.model);
      case type?.includes('image'):
        return new AppFileImageModel(this.model);
      case type?.includes('pdf'):
        return new AppFilePDFModel(this.model);
      default:
        return new AppFileOthersModel(this.model);
    }
  };
}

export default AppFileModelFactory;
