import React, { Component } from 'react';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { some, noop } from 'lodash';
import { toJS } from 'mobx';
import { Link } from 'react-router-dom';

import type { PeopleAdmin as PeopleAdminStore } from 'stores/Admin/People';
import {
  ROUTER_STORE,
  TIME_STORE,
  STORAGE_ITEMS,
  PEOPLE_ADMIN_STORE,
  PEOPLE_ADMIN_TABLE_COLUMNS,
  PATHS,
  DATE_TIME_FORMATS,
} from 'config';
import type { RouterStore, TimeStore } from 'stores';
import { getFormattedTime, getJSONItemFromStorage, setJSONItemToStorage } from 'utils';

import CustomTable from 'components/Table/CustomTable';
import DeletePersonModal from 'components/Admin/DeletePersonModal';
import IconsCell from 'components/Admin/IconsCell';
import Notification from 'components/Notification';
import type PeopleAdmin from 'models/Admin/People/Tables';
import TableActions from 'components/Table/Actions';
import TableDownloadModal from 'components/Table/TableDownloadModal';
import TableEmailModal from 'components/Table/TableEmailModal';

import './styles.scss';

interface IProps {
  [PEOPLE_ADMIN_STORE]?: PeopleAdminStore;
  [ROUTER_STORE]?: RouterStore;
  [TIME_STORE]?: TimeStore;
}

interface IState {
  isDownloadModalOpen: boolean;
  isEmailModalOpen: boolean;
  isFilterOpen: boolean;
  personToDelete: { id: number; name: string };
}

@inject(({ adminStore: { peopleAdmin }, routerStore, timeStore }) => ({
  peopleAdmin,
  routerStore,
  timeStore,
}))
@observer
class PeopleAdminTable extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isDownloadModalOpen: false,
      isEmailModalOpen: false,
      isFilterOpen: getJSONItemFromStorage(STORAGE_ITEMS.inspections.performed.filterOpenStatus, true),
      personToDelete: null,
    };
  }

  get personModelToDelete() {
    const {
      peopleAdmin: { find },
    } = this.props;
    const { personToDelete } = this.state;

    return find(personToDelete?.id);
  }

  get isSearchApplied() {
    const {
      peopleAdmin: {
        table: { searchData },
      },
    } = this.props;

    return some(searchData, (data) => Boolean(data));
  }

  get emptyText() {
    return this.isSearchApplied ? (
      ''
    ) : (
      <p className="PeopleAdminTable-emptyText">
        People will appear here. Click <span className="PeopleAdminTable-emptyText--bold">Add Person </span> to get
        started.
      </p>
    );
  }

  get tableHeight() {
    return {
      default: 'calc(100vh - 260px)',
      withOpenFilters: 'calc(100vh - 290px)',
    };
  }

  get editColumnsMap() {
    return {
      [PEOPLE_ADMIN_TABLE_COLUMNS.NAME]: {
        text: 'Name',
        isDisabled: true,
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.LOGIN_EMAIL]: {
        text: 'Login Email',
        isDisabled: false,
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.JOB_TITLE]: {
        text: 'Job Title',
        isDisabled: false,
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.CONTACT_NUMBER]: {
        text: 'Contact Number',
        isDisabled: false,
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.USER_ACCESS]: {
        text: 'User Access',
        isDisabled: false,
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.USER_ROLE]: {
        text: 'User Role',
        isDisabled: false,
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.PERMISSIONS]: {
        text: 'Permissions',
        isDisabled: false,
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.ASSIGNED_VEHICLE]: {
        text: 'Assigned Vehicle',
        isDisabled: false,
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.EMPLOYEE_ID]: {
        text: 'Employee ID',
        isDisabled: false,
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.ADDRESS]: {
        text: 'Address',
        isDisabled: false,
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.TIME_ZONE]: {
        text: 'Time Zone',
        isDisabled: false,
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.LICENCE_EXPIRATION]: {
        text: 'Licence Expiration',
        isDisabled: false,
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.FOB_ID]: {
        text: 'Fob #',
        isDisabled: false,
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.LAST_WEB_LOGIN]: {
        text: 'Last Web Login',
        isDisabled: false,
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.LAST_MOBILE_APP_LOGIN]: {
        text: 'Last Mobile App Login',
        isDisabled: false,
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.CREATED_BY]: {
        text: 'Created By',
        isDisabled: false,
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.CREATED_ON]: {
        text: 'Created On',
        isDisabled: false,
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.MODIFIED_BY]: {
        text: 'Modified By',
        isDisabled: false,
      },
      [PEOPLE_ADMIN_TABLE_COLUMNS.MODIFIED_ON]: {
        text: 'Modified On',
        isDisabled: false,
      },
    };
  }

  getColumns = () => {
    const {
      peopleAdmin: {
        table: {
          columns,
          sortedColumn: { field, order },
        },
      },
      timeStore: { sessionTimezone },
    } = this.props;

    const allColumns = [
      {
        title: 'Name',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.NAME,
        width: 180,
        defaultSortOrder: field === PEOPLE_ADMIN_TABLE_COLUMNS.NAME ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        render: (value, { id }) => <Link to={PATHS.ADMIN.TABLES.PEOPLE.PERSON.replace(':id', id)}>{value}</Link>,
        fixed: 'left',
      },
      {
        title: 'Login Email',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.LOGIN_EMAIL,
        width: 180,
        defaultSortOrder: field === PEOPLE_ADMIN_TABLE_COLUMNS.LOGIN_EMAIL ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
      },
      {
        title: 'Job Title',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.JOB_TITLE,
        defaultSortOrder: field === PEOPLE_ADMIN_TABLE_COLUMNS.JOB_TITLE ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Contact Number',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.CONTACT_NUMBER,
        defaultSortOrder: field === PEOPLE_ADMIN_TABLE_COLUMNS.CONTACT_NUMBER ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'User Access',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.USER_ACCESS,
        width: 180,
        hiddenSearch: true,
        render: (access: { [key: string]: { value: boolean; tooltip: string } }) =>
          Object.values(access).some((data) => data.value) ? <IconsCell icons={access} /> : '-',
      },
      {
        title: 'User Role',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.USER_ROLE,
        width: 180,
        hiddenSearch: true,
      },
      {
        title: 'Permissions',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.PERMISSIONS,
        width: 180,
        hiddenSearch: true,
        render: (permissions: { [key: string]: { value: boolean; tooltip: string } }) =>
          Object.values(permissions).some((data) => data.value) ? <IconsCell icons={permissions} /> : '-',
      },
      {
        title: 'Assigned Vehicle',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.ASSIGNED_VEHICLE,
        defaultSortOrder: field === PEOPLE_ADMIN_TABLE_COLUMNS.ASSIGNED_VEHICLE ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
        hiddenSearch: true,
      },
      {
        title: 'Employee ID',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.EMPLOYEE_ID,
        defaultSortOrder: field === PEOPLE_ADMIN_TABLE_COLUMNS.EMPLOYEE_ID ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Address',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.ADDRESS,
        defaultSortOrder: field === PEOPLE_ADMIN_TABLE_COLUMNS.ADDRESS ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Time Zone',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.TIME_ZONE,
        defaultSortOrder: field === PEOPLE_ADMIN_TABLE_COLUMNS.TIME_ZONE ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Licence Expiration',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.LICENCE_EXPIRATION,
        defaultSortOrder: field === PEOPLE_ADMIN_TABLE_COLUMNS.LICENCE_EXPIRATION ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
        hiddenSearch: true,
        render: (value) => (
          <>{value ? getFormattedTime(value, DATE_TIME_FORMATS.adminVehicleSettings, sessionTimezone) : '-'}</>
        ),
      },
      {
        title: 'Fob #',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.FOB_ID,
        width: 180,
        hiddenSearch: true,
      },
      {
        title: 'Last Web Login',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.LAST_WEB_LOGIN,
        defaultSortOrder: field === PEOPLE_ADMIN_TABLE_COLUMNS.LAST_WEB_LOGIN ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
        hiddenSearch: true,
      },
      {
        title: 'Last Mobile App Login',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.LAST_MOBILE_APP_LOGIN,
        defaultSortOrder: field === PEOPLE_ADMIN_TABLE_COLUMNS.LAST_MOBILE_APP_LOGIN ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
        hiddenSearch: true,
      },
      {
        title: 'Created By',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.CREATED_BY,
        width: 180,
        hiddenSearch: true,
      },
      {
        title: 'Created On',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.CREATED_ON,
        width: 180,
        hiddenSearch: true,
      },
      {
        title: 'Modified By',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.MODIFIED_BY,
        width: 180,
        hiddenSearch: true,
      },
      {
        title: 'Modified On',
        dataIndex: PEOPLE_ADMIN_TABLE_COLUMNS.MODIFIED_ON,
        width: 180,
        hiddenSearch: true,
      },
    ];

    const filteredColumns = [];

    columns.forEach((column) => {
      if (column.isSelected) {
        filteredColumns.push(allColumns.find((col) => col.dataIndex === column.value));
      }
    });

    return [
      ...filteredColumns.filter((col) => !col.hidden),
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: (_, person) => {
          const personEverLogedIn = Object.values(person.userAccess).some((value) => value);
          const authAction = personEverLogedIn
            ? {
                text: 'Email Password Reset',
                onClick: () => this.sendResetPasswordEmail(person.loginEmail),
              }
            : {
                text: 'Resend Welcome Email',
                onClick: () => this.sendWelcomeEmail(person.loginEmail),
              };
          const items = [
            {
              text: 'Edit Person',
              link: PATHS.ADMIN.TABLES.PEOPLE.PERSON.replace(':id', person.id),
            },
            authAction,
            { text: 'Delete Person', onClick: () => this.setPersonToDelete({ id: person.id, name: person.name }) },
          ];

          if (!person.isEmailPresent) {
            items.splice(1, 1);
          }

          return <TableActions items={items} />;
        },
        className: 'ant-table-cell--actions',
        width: 120,
      },
    ];
  };

  handleOpenFilter = (isFilterOpen: boolean) => {
    this.setState({ isFilterOpen });
    setJSONItemToStorage(STORAGE_ITEMS.admin.filterOpenStatus, isFilterOpen);
  };

  closeDownloadModal = () => {
    this.setState({ isDownloadModalOpen: false });
  };

  openDownloadModal = () => {
    this.setState({ isDownloadModalOpen: true });
  };

  closeEmailModal = () => {
    this.setState({ isEmailModalOpen: false });
  };

  openEmailModal = () => {
    this.setState({ isEmailModalOpen: true });
  };

  sendWelcomeEmail = async (email: string) => {
    const {
      peopleAdmin: { sendWelcomeEmail },
    } = this.props;

    await sendWelcomeEmail(email);
  };

  sendResetPasswordEmail = async (email: string) => {
    const {
      peopleAdmin: { sendResetPasswordEmail },
    } = this.props;

    await sendResetPasswordEmail(email);
  };

  setPersonToDelete = (personToDelete: { id: number; name: string }) => this.setState({ personToDelete });

  deletePerson = async () => {
    const personToDelete = this.personModelToDelete;

    if (personToDelete) {
      await personToDelete.delete();
      this.setPersonToDelete(null);
    }
  };

  render() {
    const {
      peopleAdmin: {
        table: {
          columns,
          filters,
          pagination,
          searchColumns,
          searchData,
          setColumns,
          setPagination,
          setSearchColumn,
          setSortedColumn,
          sortData,
          source,
          downloadTable,
        },
        repository: { getState },
        total,
        fetch,
        repositoryEmailPasswordReset,
        repositorySendWelcomeEmail,
      },
    } = this.props;
    const { isEmailModalOpen, isDownloadModalOpen, isFilterOpen, personToDelete } = this.state;
    const cn = classnames('PeopleAdminTable', {
      'PeopleAdminTable--withOpenFilters': isFilterOpen,
    });
    const tableHeight = isFilterOpen ? this.tableHeight.withOpenFilters : this.tableHeight.default;
    const triggerUpdate = this.personModelToDelete?.repositoryPerson.deleteState.success;

    return (
      <>
        <div className={cn}>
          <CustomTable
            actionsList={[{ text: 'User Login Report', link: `${PATHS.REPORTS.INDEX}/UserLogin` }]}
            columns={this.getColumns()}
            dataSource={source}
            filters={{ [PEOPLE_ADMIN_TABLE_COLUMNS.PERMISSIONS]: filters.permissions.list }}
            editColumnsMap={this.editColumnsMap}
            emptyText={this.emptyText}
            getData={fetch}
            isFilterActive={isFilterOpen}
            loading={getState.loading}
            onColumnSort={setSortedColumn}
            onColumnsChange={setColumns}
            onColumnsSearch={setSearchColumn}
            onFilterOpen={this.handleOpenFilter}
            onPaginationChange={setPagination}
            openDownloadModal={this.openDownloadModal}
            openEmailModal={this.openEmailModal}
            pagination={pagination}
            searchColumns={searchColumns}
            searchParams={searchData}
            selectedColumns={toJS(columns)}
            sortedColumn={sortData}
            tableHeight={tableHeight}
            totalTitle={Boolean(total.value) && `${total.value} People`}
            total={total.value}
            triggerUpdate={triggerUpdate}
            withActions
            withDownload
            withEmail
          />
          <DeletePersonModal
            id={personToDelete && personToDelete.id}
            name={personToDelete && personToDelete.name}
            isOpen={Boolean(personToDelete)}
            onCancel={() => this.setPersonToDelete(null)}
            onSubmit={this.deletePerson}
          />
          {repositorySendWelcomeEmail.createState.success && (
            <Notification
              text="Welcome Email was successfully sent."
              title="Success!"
              type="success"
              onClose={repositorySendWelcomeEmail.createState.reset}
            />
          )}
          {repositorySendWelcomeEmail.createState.error && (
            <Notification
              text="Welcome Email failed to send. Please try again."
              title="Send Email Failed"
              type="error"
              onClose={repositorySendWelcomeEmail.createState.reset}
            />
          )}
          {repositoryEmailPasswordReset.createState.success && (
            <Notification
              text="Password Reset Email was successfully sent."
              title="Success!"
              type="success"
              onClose={repositoryEmailPasswordReset.createState.reset}
            />
          )}
          {repositoryEmailPasswordReset.createState.error && (
            <Notification
              text="Password Reset failed to send. Please try again."
              title="Send Email Failed"
              type="error"
              onClose={repositoryEmailPasswordReset.createState.reset}
            />
          )}
        </div>
        <TableDownloadModal<PeopleAdmin>
          isOpen={isDownloadModalOpen}
          onClose={this.closeDownloadModal}
          tableDownload={downloadTable}
        />
        <TableEmailModal<PeopleAdmin>
          isOpen={isEmailModalOpen}
          onClose={this.closeEmailModal}
          tableDownload={downloadTable}
        />
      </>
    );
  }
}

export default PeopleAdminTable;
