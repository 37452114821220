import { action, computed, observable } from 'mobx';
import isNull from 'lodash/isNull';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import debounce from 'lodash/debounce';

import Event from 'models/Event';
import DevicesMapStore from 'stores/Map';
import { repositoryService } from 'services';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import eventsRegistryStore from 'stores/EventsRegistryStore';
import TableBase from 'stores/TableBase';
import timeStore from 'stores/TimeStore';
import validateAccessLevel from 'stores/acl/validator';
import { APIStoreBase, DevicesStore } from 'stores';
import { getNowTimestampForTimezone, getStartOfDayByDate, getFormattedTime } from 'utils';
import type { IEditColumns } from 'models/Tables/IEditColumns';
import type { ISortColumn } from 'models/Tables/ISortColumn';
import {
  VEHICLE_DRIVER_NAME_READ,
  MI_TO_KM,
  EVENT_STATUS_TYPE,
  DATE_TIME_FORMATS,
  TIMELINE_TABLE_COLUMNS,
  STORAGE_ITEMS,
} from 'config';
import DeviceMedia from './DeviceMedia';
import { ToggleField } from 'models/Fields';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

const initialColumns: Array<IEditColumns<TIMELINE_TABLE_COLUMNS>> = [
  { value: TIMELINE_TABLE_COLUMNS.INDEX, isSelected: true },
  { value: TIMELINE_TABLE_COLUMNS.DATE, isSelected: true },
  { value: TIMELINE_TABLE_COLUMNS.TIME, isSelected: true },
  { value: TIMELINE_TABLE_COLUMNS.STATUS, isSelected: true },
  { value: TIMELINE_TABLE_COLUMNS.COORDINATES, isSelected: false },
  { value: TIMELINE_TABLE_COLUMNS.VEHICLE_SPEED, isSelected: true },
  { value: TIMELINE_TABLE_COLUMNS.ENGINE_HOURS, isSelected: false },
  { value: TIMELINE_TABLE_COLUMNS.ODOMETER_MILES, isSelected: false },
  { value: TIMELINE_TABLE_COLUMNS.ADDRESS, isSelected: true },
  { value: TIMELINE_TABLE_COLUMNS.DRIVER, isSelected: false },
];

export interface ITableEvent {
  address: string;
  coordinates: string;
  date: string;
  driver: string;
  engineHours: number;
  index: number;
  isSelected: boolean;
  key: string;
  odometerMiles: number;
  status: string;
  time: string;
  timestamp: number;
  vehicleSpeed: number;
}

const initialSortedColumn: ISortColumn = {
  field: TIMELINE_TABLE_COLUMNS.INDEX,
  order: 'ascend',
};

const storageNames = {
  columns: STORAGE_ITEMS.map.timeline.columns,
  sortedColumn: STORAGE_ITEMS.map.timeline.sortedColumn,
  pagination: STORAGE_ITEMS.map.timeline.pagination,
  searchInColumn: STORAGE_ITEMS.map.timeline.searchInColumn,
};

export class Device extends TableBase<IEditColumns<TIMELINE_TABLE_COLUMNS>> {
  @observable latestPossibleEvent?: Event;
  @observable latestEvent?: Event;
  @observable data: Device.IDevice;
  @observable.shallow details: any = {};
  @observable events: Event[] = [];
  @observable stopTime: string = '';
  @observable firstEventTimestamp: number = null;
  @observable latestRegisteredEventTimestamp: number = null;
  @observable deviceEventsRequestStatus: APIStoreBase = new APIStoreBase();
  @observable isShowEventLimitModal: boolean = false;
  isDetailsFetched: ToggleField;
  media: DeviceMedia;
  private context: DevicesStore;
  private deviceEventsLatestRequestTime = 0;
  public eventsLimit: number = 6000;
  public repositoryNotifications: IEntityRepository;
  public repositoryVehicles: IRepository;
  public repositoryEventsDevice: IEntityRepository;

  constructor() {
    super({ columns: initialColumns, sortedColumn: initialSortedColumn, storageNames });

    this.media = null;

    this.isDetailsFetched = new ToggleField(false);
    this.repositoryNotifications = repositoryService.get('notifications').entity('sms');
    this.repositoryVehicles = repositoryService.get('vehicles');
    this.repositoryEventsDevice = repositoryService.get('events').entity('device');
  }

  initialize(context: DevicesStore): Device {
    this.context = context;
    return this;
  }

  setMedia = () => {
    this.context.devicesList.forEach((device) => device.destroy());
    this.media = new DeviceMedia(this);
  };

  setData = (device: Event.IServerEvent) => {
    this.data = {
      assetId: device.assetId,
      deviceId: device.id || '',
      driverId: (validateAccessLevel([VEHICLE_DRIVER_NAME_READ]) && device.driverId) || '',
      driverName: (validateAccessLevel([VEHICLE_DRIVER_NAME_READ]) && device.driverName) || '',
      driverDescription: (validateAccessLevel([VEHICLE_DRIVER_NAME_READ]) && device.driverDescription) || '',
      hasActiveClearShareLink: device.hasActiveClearShareLink,
      isPowerDisconnected: device.isPowerDisconnected || false,
      isCameraPowerDisconnected: device.isCameraPowerDisconnected || false,
      mediaEnabled: device.mediaEnabled,
      note: device.notes || '',
      shortName: device.displayName || '',
      description: device.description || '',
    };

    return this;
  };

  triggerGetDetails = (details) => EventsBus.get().trigger(APP_EVENTS.DEVICES.DEVICE.DETAILS.GET, details);

  @computed get routePoints() {
    return this.events.map((event) => new google.maps.LatLng(Number(event.latitude), Number(event.longitude)));
  }

  @computed get tableSource(): ITableEvent[] {
    return this.events.map(
      (
        {
          address,
          driverName,
          isSelected,
          latitude,
          longitude,
          metadata,
          reportedEngineHours,
          reportedOdometer,
          speedMph,
          timestamp,
        },
        index
      ) => ({
        address,
        coordinates: `${latitude}/${longitude}`,
        date: getFormattedTime(timestamp, DATE_TIME_FORMATS.monthDatFullYear, timeStore.sessionTimezone),
        driver: driverName,
        engineHours: Math.round(reportedEngineHours),
        index: index + 1,
        isSelected,
        key: `${timestamp}_${index}`,
        odometerMiles: Math.round(reportedOdometer),
        status: metadata.attributes.label,
        time: getFormattedTime(timestamp, DATE_TIME_FORMATS.hoursMinutesAm, timeStore.sessionTimezone),
        timestamp,
        vehicleSpeed: Math.round(speedMph),
      })
    );
  }

  @action updateEvent = (event: Event.IServerEvent, clearEvents: boolean = false) => {
    const newEvent = this.registerEvent(event);

    if (this.isShowEvent(newEvent)) {
      this.events = sortBy([...(clearEvents ? [] : this.events), newEvent], 'timestamp');
    }

    this.latestPossibleEvent = this.events[this.events.length - 1];
    this.latestEvent = this.events[this.events.length - 1];
    if (this.latestEvent) {
      this.markLastEvent();
    }

    for (let i = this.events.length - 2; i > 0; i--) {
      const event = this.events[i];

      if (event.metadata.attributes.eventStatusType === EVENT_STATUS_TYPE.TIME_DIFF) {
        if (event.metadata.attributes.calculateTotalIdle && !event.motionDetails.idleTime) {
          event.motionDetails.idleTime =
            getNowTimestampForTimezone(timeStore.sessionTimezone) - event.motionDetails.lastNonIdleTimestamp;
        }

        if (event.metadata.attributes.calculateTotalStop && !event.motionDetails.stopTime) {
          event.motionDetails.stopTime =
            getNowTimestampForTimezone(timeStore.sessionTimezone) - event.motionDetails.lastNonStopTimestamp;
        }

        if (event.metadata.attributes.calculateTotalIgnitionOff && !event.motionDetails.ignitionOffTime) {
          event.motionDetails.ignitionOffTime =
            getNowTimestampForTimezone(timeStore.sessionTimezone) - event.motionDetails.lastIgnitionOnTimestamp;
        }
      } else {
        break;
      }
    }
  };

  fetchEventsData = async (from?: number, to?: number) => {
    return this.repositoryEventsDevice.entity(this.data.deviceId).get({
      from: from || new Date().getTime() - 1000 * 60 * 60 * 24,
      to: to || new Date().getTime(),
    });
  };

  @action getEvents = debounce(async (quietly = false) => {
    let { from, to } = DevicesMapStore.devicesStore.filters.date;
    const requestTime = Date.now();
    to = !isNull(to) && !isNaN(to) ? to : getNowTimestampForTimezone(timeStore.sessionTimezone) + 59 * 1000;
    from = !isNull(from) && !isNaN(from) ? from : getStartOfDayByDate(to, timeStore.sessionTimezone);

    if (!quietly) {
      this.deviceEventsRequestStatus.reset().setLoading(true);
    }

    this.deviceEventsLatestRequestTime = requestTime;

    try {
      const eventsResponse = await this.fetchEventsData(from, to);
      const eventData = eventsResponse.eventData.items;

      if (this.deviceEventsLatestRequestTime <= requestTime) {
        if (eventData.length) {
          const events = [];

          if (eventData.length >= 6000) {
            this.showEventsLimitModal(true);
          }

          orderBy(eventData, ['timestamp', 'creationTime'], ['asc']).forEach((event) => {
            const eventWithMetadata = this.registerEvent(event);

            if (this.isShowEvent(eventWithMetadata)) {
              events.push(eventWithMetadata);
            }
          });

          this.preSelectEvent(events);
          this.events = events;
          this.latestEvent = this.events[this.events.length - 1];
          this.markLastEvent();
        } else {
          const latestEvent = await this.repositoryEventsDevice
            .entity(this.data.deviceId)
            .entity('latest')
            .get({ to: to || new Date().getTime() });
          const eventWithMetadata = this.registerEvent(latestEvent.eventData[0]);

          this.events = [];
          this.latestEvent = this.isShowEvent(eventWithMetadata) ? eventWithMetadata : null;

          if (this.latestEvent) {
            this.latestEvent.setIsLastEvent(true);
          }
        }
      }

      if (!quietly) {
        this.deviceEventsRequestStatus.reset().setSuccess(true);
      }
    } catch (error) {
      if (!quietly) {
        this.deviceEventsRequestStatus.reset().setError(error);
      }
    }

    if (!quietly) {
      this.deviceEventsRequestStatus.setLoading(false);
    }
  }, 60);

  @action getDetails = async () => {
    if (!this.isDetailsFetched.value) {
      const eventsResponse = await this.repositoryVehicles.entity(this.data.deviceId).get();
      eventsResponse.odometer = eventsResponse.reportedOdometer * MI_TO_KM;
      this.details = eventsResponse;
      this.media.initialize();
      this.isDetailsFetched.toggle(true);
      this.triggerGetDetails(eventsResponse);
    }
  };

  @action getFirstEvent = async () => {
    if (!this.firstEventTimestamp) {
      const firstEvent = await this.repositoryEventsDevice
        .entity(this.data.deviceId)
        .entity('first')
        .get();

      this.firstEventTimestamp = firstEvent.timestamp;
    }
  };

  @action getLatestRegisteredEvent = async () => {
    if (!this.latestRegisteredEventTimestamp) {
      const latestRegisteredEvent = await this.repositoryEventsDevice
        .entity(this.data.deviceId)
        .entity('latest')
        .get({ limit: 1 });

      this.latestRegisteredEventTimestamp = latestRegisteredEvent.eventData[0]?.timestamp;
    }
  };

  @action updateNote = async (note: string) => {
    this.data.note = note;
    await this.repositoryVehicles.patch({ deviceId: this.data.deviceId, notes: this.data.note });
  };

  @action showEventsLimitModal = (value: boolean) => {
    this.isShowEventLimitModal = value;
  };

  @action textLocation = async ({ phone, message }: { async?: boolean; phone: string; message: string }) => {
    await this.repositoryNotifications.create({ async: false, phone, message });
  };

  get selectedEvents() {
    const selectedEvents = this.context.showTimelineDetails.value ? this.events : this.context.selectedEvents();
    return selectedEvents ? selectedEvents : [];
  }

  get isOneOfEventsSelected() {
    return this.events.some((event) => event.isSelected);
  }

  private registerEvent = (event): Event => {
    return eventsRegistryStore.register(new Event(event).initialize(this), this.data.assetId, this.context.accountIdCP);
  };

  private isShowEvent = (event: Event): boolean => {
    return event.isRegistered && event.metadata.attributes && event.metadata.attributes.showOnMap;
  };

  updateDriverName = (event) => {
    this.data.driverId = event.driverId || '';
    this.data.driverName = event.driverName || '';
    this.data.driverDescription = event.driverDescription || '';
    this.details = this.details || {};
    this.details.driverDescription = event.driverDescription;
  };

  onShowTrackPointCard(event: Event) {
    const selectedEvents = this.events.filter((event) => event.isSelected);

    selectedEvents.forEach((selectedEvent) => {
      if (selectedEvent !== event) {
        selectedEvent.hideTrackPointCard();
      }
    });
  }

  getIndex(child: Event) {
    return this.selectedEvents.findIndex((event) => event === child);
  }

  markLastEvent = () => {
    const prevLastEvent = this.events.find((event) => event.isLastEvent);

    if (prevLastEvent) {
      prevLastEvent.setIsLastEvent(false);
    }

    this.latestEvent.setIsLastEvent(true);
  };

  onMoveToNextEvent(activeEvent) {
    const eventIndex = this.getIndex(activeEvent);

    if (this.selectedEvents.length === eventIndex + 1) {
      this.moveToEvent(0);
    } else {
      this.moveToEvent(eventIndex + 1);
    }
  }

  onMoveToPreviousEvent(activeEvent) {
    const eventIndex = this.getIndex(activeEvent);

    if (eventIndex === 0) {
      this.moveToEvent(this.selectedEvents.length - 1);
    } else {
      this.moveToEvent(eventIndex - 1);
    }
  }

  moveToEvent(index) {
    const selectedEvent = this.selectedEvents[index];

    this.context.setSelectedEventIndex(index);

    if (selectedEvent) {
      selectedEvent.showTrackPointCard();
      this.context.onMoveEvent();
    }
  }

  get eventsLimitExceed() {
    return this.events.length >= this.eventsLimit;
  }

  preSelectEvent(events) {
    const selectedEventWas = this.events.find((event) => event?.isSelected);
    const selectedEvent = events.find((event) => event?.timestamp === selectedEventWas?.timestamp);

    if (selectedEvent) {
      selectedEvent.isSelected = true;
    }
  }

  destroy = () => {
    if (this.media) {
      this.media.destroy();
    }
  };

  clearEvents = () => {
    this.events = [];
    this.latestEvent = null;
  };
}

export default Device;
