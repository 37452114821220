import React from 'react';

import './styles.scss';

interface IProps {
  title: string;
  InfoBlock?: React.ReactNode;
}

const LayerTitle: React.FC<IProps> = ({ title, InfoBlock }) => (
  <div className="LayerTitle">
    <div className="LayerTitle-container">
      <p className="LayerTitle-text">{title}</p>
      {Boolean(InfoBlock) && <div className="LayerTitle-infoBlock">{InfoBlock}</div>}
    </div>
  </div>
);

export default LayerTitle;
