import React from 'react';
import classNames from 'classnames';
import './styles.scss';

interface IProps {
  isSubNavExpanded: boolean;
}

const MapTopControls: React.FC<IProps> = ({ isSubNavExpanded, children }) => {
  const cn = classNames('MapTopControls-container', {
    'MapTopControls-container--left': !isSubNavExpanded,
  });

  return (
    <div className="MapTopControls">
      <div className={cn}>{children}</div>
    </div>
  );
};

export default MapTopControls;
