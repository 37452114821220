import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { formatDecimalNumber, getFormattedTime } from 'utils';

import { PATHS, WEB_FINANCE_DETAILS_READ } from 'config';

import type { CustomersStore } from 'stores';
import validateAccessLevel from 'stores/acl/validator';

import SecondaryPanelAttributesRow from '../../../SecondaryPanelAttributes/SecondaryPanelAttributesRow';
import SecondaryPanelAttributesCol from '../../../SecondaryPanelAttributes/SecondaryPanelAttributesCol';
import FinancialDataAccessError from 'components/FinancialDataAccessError';

interface IProps {
  customersStore?: CustomersStore;
}


@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class SecondaryPanelInvoiceDetails extends Component<IProps> {
  get hasAccess () {
    return validateAccessLevel([WEB_FINANCE_DETAILS_READ]);
  }

  render() {
    const customer = this.props.customersStore.selectedCustomer;
    const invoice = customer.selectedInvoice;
    const link = `${PATHS.MAP.CUSTOMER_JOB.replace(':customerId', customer.id).replace(':jobId', String(invoice.jobId))}`
    const hasAccess = this.hasAccess;

    return <div className="SecondaryPanelInvoiceDetails">

      <div className="SecondaryPanelInvoiceDetailsPrice">
        <div className="SecondaryPanelInvoiceDetailsPrice-item">
          <div className="SecondaryPanelInvoiceDetailsPrice-header">Total</div>
          {hasAccess && (<div
            className="SecondaryPanelInvoiceDetailsPrice-value"
          >${formatDecimalNumber(invoice.totalAmount, 2)}</div>)}
          {!hasAccess && <FinancialDataAccessError className="SecondaryPanelInvoiceDetailsPrice-value--limited" />}
        </div>
        <div className="SecondaryPanelInvoiceDetailsPrice-item">
          <div className="SecondaryPanelInvoiceDetailsPrice-header">Due</div>
          {hasAccess && (<div
            className="SecondaryPanelInvoiceDetailsPrice-value"
          >${formatDecimalNumber(invoice.balance, 2)}</div>)}
          {!hasAccess && <FinancialDataAccessError className="SecondaryPanelInvoiceDetailsPrice-value--limited" />}
        </div>

      </div>
      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Status</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{invoice.status || ''}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>
      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Due Date</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{invoice.dueDate ? getFormattedTime(invoice.dueDate, 'MM/DD/YYYY') : '-'}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>
      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Service Date</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{invoice.transactionDate ? getFormattedTime(invoice.transactionDate, 'MM/DD/YYYY') : '-'}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>
      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Job</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}><Link to={link}>{invoice.jobId}</Link></SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>
    </div>;
  }
}

export default SecondaryPanelInvoiceDetails;
