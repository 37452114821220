import React, { Component } from 'react';

import AdminContentWrapper from 'components/Admin/ContentWrapper';
import AdminDashcamsTable from './DashcamsTable';

import './styles.scss';

class AdminDashcam extends Component {
  render() {
    return (
      <AdminContentWrapper
        className="AdminDashcamWrapper"
        title="Dashcam Admin"
        message="Assign dashcams to vehicles and adjust Dashcams settings."
      >
        <AdminDashcamsTable />
      </AdminContentWrapper>
    );
  }
}

export default AdminDashcam;
