import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import withSizes from 'react-sizes';
import classNames from 'classnames';
import { get } from 'lodash';
import { Spin } from 'antd';

import type ReportTable from 'models/Report/ReportTable';
import { REPORTS_STORE, MAINTENANCES_STORE } from 'config';
import type { ReportsStore, Maintenances } from 'stores';

import ReportTableByDevice from './ReportTableByDevice';
import ReportCompletedTaskPanelContainer from './ReportCompletedTaskPanelContainer';
import ReportTableByGroupVirtualized from './ReportTableByGroupVirtualized';
import MapPopover from 'components/Reports/MapPopover';
import InfoTooltip from 'components/InfoTooltip';
import ReportCompletedTaskPanel from 'components/Reports/ReportCompletedTaskPanel';
import TableUnSelected from 'components/Table/UnSelected';

import './styles.scss';

interface IProps {
  className?: string;
  reportsStore?: ReportsStore;
  maintenancesStore?: Maintenances;
  empty?: boolean;
  isMinHeight?: boolean;
}

@withSizes(({ height }) => ({
  isMinHeight: height < 600,
}))
@inject(REPORTS_STORE, MAINTENANCES_STORE)
@observer
class ReportTableContainer extends Component<IProps, null> {
  componentDidMount() {
    const {
      reportsStore: { getVehiclesIdsWithCameras },
    } = this.props;

    getVehiclesIdsWithCameras();
  }

  get deviceId() {
    return get(
      this.props.reportsStore.latestReportPreferences.find((pref) => pref[0] === 'deviceId'),
      '[1][0]',
      ''
    );
  }

  getColumns = (table: ReportTable) => {
    const {
      reportsStore: { mapLinkMapType, setMapLinkMapType, vehiclesIdsWithCamera, selectedReportId },
      maintenancesStore: { fetchCompleted },
    } = this.props;
    const requestMedia = table.requestMediaAllowed;
    const filters = table?.filter.search.value.reduce((acc, item) => {
      return {
        ...acc,
        [item.value.column]: item.value.value,
      };
    }, {});

    return table?.columns.map((column) => {
      const linkedColumn = table.mapLinks?.find((mapLink) => mapLink.link === column.dataIndex);
      const coloredColumn = table.coloredColumns.find((item) => item === column.dataIndex);

      if (filters[column.dataIndex] !== undefined) {
        column.filteredValue = [filters[column.dataIndex]];
        column.onFilter = (cell, record) => {
          return record[column.dataIndex]?.value?.toLowerCase?.().includes?.(cell?.toLowerCase?.());
        };
      } else {
        column.filteredValue = undefined;
        column.onFilter = undefined;
      }

      return {
        ...column,
        ...{
          render: (cell) => {
            return {
              children: cell.type === 'HTML' ? <div dangerouslySetInnerHTML={{ __html: cell.value }} /> : cell.value,
            };
          },
        },
        ...(coloredColumn && {
          onCell: (cell) => {
            return {
              style: {
                color: cell[column.dataIndex].color || cell[column.dataIndex].foreground,
              },
            };
          },
        }),
        ...(linkedColumn && {
          render: (cell, row) => {
            const latitude = Number(row[linkedColumn.lat]?.value ?? 0);
            const longitude = Number(row[linkedColumn.lon]?.value ?? 0);
            const deviceId = requestMedia ? this.deviceId || row.deviceid?.value : '';
            const requestMediaProps = requestMedia
              ? {
                  deviceId,
                  timestamp: Number(row.timestamp.value) * 1000,
                  isCameraConnected: vehiclesIdsWithCamera.toArray().includes(deviceId),
                }
              : {};
            const cellCn = classNames(`ant-table-cell--${column.dataIndex}`, {
              'ant-table-cell--summary': !Boolean(row.index.value),
              'ant-table-cell--link': latitude && longitude,
            });

            return {
              children:
                latitude && longitude ? (
                  <MapPopover
                    location={{ lat: latitude, lng: longitude }}
                    mapType={mapLinkMapType}
                    setMapType={setMapLinkMapType}
                    {...requestMediaProps}
                  >
                    {<span className="ant-table-cell-value">{cell.value}</span>}
                  </MapPopover>
                ) : (
                  cell.value
                ),
              props: {
                className: cellCn,
              },
            };
          },
        }),
        ...(column.dataIndex === 'maintenancename' &&
          ['CompletedMaintenance', 'CompletedMaintenanceOrderByDate_Group'].includes(selectedReportId) && {
            render: (cell, row) => {
              const cellCn = classNames(`ant-table-cell--${column.dataIndex}`, 'ant-table-cell--link');

              return {
                children: (
                  <ReportCompletedTaskPanel
                    title={cell.value}
                    id={row.maintenancehistoryid?.value}
                    onClick={fetchCompleted}
                  />
                ),
                props: {
                  className: cellCn,
                },
              };
            },
          }),
        ...(column.dataIndex === 'datetime' && {
          render: (cell) => {
            const cellCn = `ant-table-cell--${column.dataIndex}`;
            return {
              children: cell.tooltip ? (
                <>
                  <span className="ReportTableContainer-tableRow-exception">
                    <span>{cell.value}</span>
                    <span>
                      <InfoTooltip placement="bottom">{cell.tooltip}</InfoTooltip>
                    </span>
                  </span>
                </>
              ) : (
                cell.value
              ),
              props: {
                className: cellCn,
              },
            };
          },
        }),
      };
    });
  };

  render() {
    const {
      reportsStore: {
        getReportsListRequestStatus,
        selectedReport,
        reportTable,
        reportTableGroup,
        getReportsTableRequestStatus,
      },
    } = this.props;

    const hasMaintenancePanel = [
      'CompletedMaintenance',
      'CompletedMaintenanceOrderByDate_Group',
      'CompletedMaintenanceGroupByVehicle_Group',
    ].includes(reportTable?.reportId || reportTableGroup?.reportId);

    const showSpinner = getReportsTableRequestStatus.loading;

    const cn = classNames('ReportTableContainer-table', {
      'ReportTableContainer-table--empty': true,
      'ReportTableContainer-table--main': !selectedReport,
    });

    if (reportTable) {
      return (
        <>
          <ReportTableByDevice getColumns={this.getColumns} />
          {hasMaintenancePanel && <ReportCompletedTaskPanelContainer />}
        </>
      );
    } else if (reportTableGroup) {
      return (
        <>
          <ReportTableByGroupVirtualized />
          {hasMaintenancePanel && <ReportCompletedTaskPanelContainer />}
        </>
      );
    } else {
      return (
        <div className={cn}>
          <div>
            <div className="ReportTableContainer-header--empty ReportGroupedTable-header ">
              <div className="ant-table-title" />
            </div>
            <div className="ReportGroupedTable-content">
              <div className="ReportTableContainer-table-filters--empty" />
              {getReportsListRequestStatus.loading ||
                (showSpinner && <Spin size="large" className="ReportTableContainer-table-spinner" />)}
              {!showSpinner && !selectedReport && (
                <TableUnSelected
                  text={
                    <div>
                      <strong>Choose a report</strong>, select your desired options, and then click ‘Run Report’ and the
                      report results will be shown here.
                    </div>
                  }
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ReportTableContainer;
