const vehicleLabelOptions = [
  {
    id: 'vehicle',
    label: 'Vehicle Name',
    name: 'vehicleLabel',
    value: 'vehicle',
  },
  {
    id: 'driver',
    label: 'Driver Name',
    name: 'vehicleLabel',
    value: 'driver',
  },
];

const mapLabelsOptions = [
  {
    id: 'small',
    label: 'Small',
    name: 'small',
    value: 'small',
  },
  {
    id: 'medium',
    label: 'Medium',
    name: 'medium',
    value: 'medium',
  },
  {
    id: 'large',
    label: 'Large',
    name: 'large',
    value: 'large',
  },
];

const markerSizeOptions = [
  {
    value: 'small',
    label: 'Small',
    labelClassName: 'MarkerRangeSizeSmall',
  },
  {
    value: 'medium',
    label: 'Medium',
    labelClassName: 'MarkerRangeSizeMedium',
  },
  {
    value: 'large',
    label: 'Large',
    labelClassName: 'MarkerRangeSizeLarge',
  },
];

const clusteringSensitivityToggleOptions = [
  {
    id: 'clusteringOn',
    label: 'On',
    name: 'clusteringSens',
    value: 'on',
  },
  {
    id: 'clusteringOff',
    label: 'Off',
    name: 'clusteringSens',
    value: 'off',
  },
];

const clusteringSensitivityOptions = [
  {
    value: 20,
  },
  {
    value: 40,
  },
  {
    value: 50,
  },
  {
    value: 80,
  },
  {
    value: 100,
  },
];

export {
  clusteringSensitivityOptions,
  clusteringSensitivityToggleOptions,
  mapLabelsOptions,
  markerSizeOptions,
  vehicleLabelOptions,
};
