class GPSItem {
  altitude: number;
  bearing: number;
  latitude: number;
  accuracy: number;
  speed: number;
  longitude: number;
  timestamp: number;

  constructor({ altitude, bearing, latitude, accuracy, speed, longitude, timestamp }) {
    this.altitude = altitude;
    this.bearing = bearing;
    this.latitude = latitude;
    this.accuracy = accuracy;
    this.speed = speed;
    this.longitude = longitude;
    this.timestamp = timestamp;
  }
}

export default GPSItem;
