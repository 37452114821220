import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { WEB_FINANCE_DETAILS_READ } from 'config';

import type { CustomersStore } from 'stores';
import validateAccessLevel from 'stores/acl/validator';

import SecondaryPanelJobEstimate from './SecondaryPanelJobEstimate';
import FinancialDataAccessError from 'components/FinancialDataAccessError';

import './styles.scss';

interface IProps {
  customersStore?: CustomersStore;
}

@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class SecondaryPanelJobEstimates extends Component<IProps> {
  get hasAccess () {
    return validateAccessLevel([WEB_FINANCE_DETAILS_READ]);
  }

  render() {
    const {
      customersStore: {
        selectedCustomer: {
          jobDetails,
          id: customerId,
        },
      },
    } = this.props;

    const hasAccess = this.hasAccess;
    const hasEstimates = !!jobDetails?.estimates?.length;

    return <div className="SecondaryPanelJobEstimates">
      <div className="SecondaryPanelJobEstimates-header">
        <div>Estimate</div>
        <div>Date</div>
        <div>Total</div>
      </div>
      <div
        className={classNames('SecondaryPanelJobEstimates-body', {
          'SecondaryPanelJobEstimates-body--limited': !hasAccess,
        })}
      >
        <div className="SecondaryPanelJobEstimates-list">
          {jobDetails?.estimates?.map(estimate => (
            <SecondaryPanelJobEstimate
              customer={customerId}
              key={estimate.id}
              id={estimate.id}
              date={estimate.modifiedOn}
              total={estimate.totalAmount}
              limited={!hasAccess}
            />
          ))}
        </div>
        {!hasAccess && hasEstimates && <FinancialDataAccessError className="SecondaryPanelJobEstimates-listColumn--limited" />}
      </div>
    </div>;
  }
}

export default SecondaryPanelJobEstimates;
