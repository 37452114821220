import React, { FC } from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  selected?: boolean;
  className?: string;
  disabled?: boolean;
  icon: React.ReactElement;
  onClick?: () => void;
  open?: boolean;
}

const MediaPanelFilterButton: FC<IProps> = ({ selected, className, disabled, icon, onClick, open }) => {
  const cn = classNames('MediaPanelFilterButton', className, {
    'MediaPanelFilterButton--selected': selected,
    'MediaPanelFilterButton--open': open,
    'MediaPanelFilterButton--disabled': disabled,
  });

  return (
    <button className={cn} onClick={onClick}>
      <div className="MediaPanelFilterButton-icon">{icon}</div>
    </button>
  );
};

export default MediaPanelFilterButton;
