import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

import { INTEGRATIONS_STORE } from 'config';

import type { IIntegrationsStore } from 'interfaces/stores/Integrations/IIntegrationsStore';

import InfoTooltip from 'components/InfoTooltip';
import Button from 'components/Button';
import FinanceRequestModalTimer from '../FinanceRequestModalTimer';

import './styles.scss';

interface IProps {
  integrationsStore?: IIntegrationsStore;
  onClose: () => void;
}

@inject(INTEGRATIONS_STORE)
@observer
class FinanceRequestModal extends Component<IProps> {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    }
  }

  async componentDidMount() {
    return this.props.integrationsStore.financeAccess.validateStatus()
  }

  handleActionClick = async () => {
    const {
      integrationsStore: {
        financeAccess,
        financeAccess: {
          time,
        },
      },
    } = this.props;

    if (time.value) {
      return financeAccess.revokeAccess()
    } else {
      return financeAccess.grandAccess()
    }
  }

  render() {
    const {
      integrationsStore: {
        financeAccess: {
          isRequested,
          isFailed,
          isLoading,
          time,
        },
      },
    } = this.props;

    return (
      <OutsideClickHandler onOutsideClick={this.props.onClose}>
        <div className="FinanceRequestModal">
          <div className="FinanceRequestModal-header">
            <div className="FinanceRequestModal-title">Allow Support to view financial Information</div>
            <div className="FinanceRequestModal-info">
              <InfoTooltip>
                By Default, ClearPathGPS Support staff have no visibility into financial data
                via integrations. If you would like our Support team to be able to see the
                Invoices, Estimates, etc. in the Customer Tab on the map for troubleshooting
                purposes, click Grant Access. This will allow designated Support staff to see
                this information for the next 24 hours. You may revoke these privileges sooner.
              </InfoTooltip>
            </div>
          </div>
          {isLoading && (<div>Loading...</div>)}
          <div className="FinanceRequestModal-body">
            <div className="FinanceRequestModal-action">
              {isRequested && <Button
                title={time.value ? 'Revoke Access' : 'Grant Access'}
                className={classNames({
                  'FinanceRequestModal-actionButton--revoke': time.value,
                  'FinanceRequestModal-actionButton--grand': !time.value,
                })}
                onClick={this.handleActionClick}
              />}
            </div>
            <div className="FinanceRequestModal-status">
              {isRequested && time.value && <FinanceRequestModalTimer time={time.value}/>}
              {isFailed && (<span className="FinanceRequestModal-error">Something went wrong. Please try again</span>)}
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}

export default FinanceRequestModal;
