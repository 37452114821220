import React from 'react';
import { Link } from 'react-router-dom';

import { PATHS } from 'config';

import { LogoReducedIcon } from 'assets';
import './styles.scss';

interface IProps {
  onClick?: () => void;
}

const SideBarLogo: React.FC<IProps> = ({ onClick }) => {
  return (
    <div className="SideBarLogo SideBarLogo--withLogoOnly">
      <Link className="SideBarLogo-logoLink" to={PATHS.MAP.VEHICLES} onClick={onClick}>
        <LogoReducedIcon />
      </Link>
    </div>
  );
};

export default SideBarLogo;
