import { action, observable } from 'mobx';

export class PersistenceStore {
  @observable selectedDriver: Select.ISelectOption;
  @observable selectedDashCam: Select.ISelectOption;
  @observable selectedGroup: Select.ISelectOption;
  @observable selectedGeoZone: Select.ISelectOption;
  @observable selectedGeoZoneTag: Select.ISelectOption;
  @observable selectedVehicle: Select.ISelectOption;
  @observable selectedTimeRange: {
    from: number;
    to: number;
  };
  @observable selectedUserRole: Select.ISelectOption;
  @observable selectedUser: Select.ISelectOption;
  @observable selectedState: Select.ISelectOption;
  @observable selectedKeyFob: Select.ISelectOption;

  @action updateSelectedDriver = (value: Select.ISelectOption) => {
    this.selectedDriver = value;
  };

  @action updateSelectedDashcam = (value: Select.ISelectOption) => {
    this.selectedDashCam = value;
  };

  @action updateSelectedGroup = (value: Select.ISelectOption) => {
    this.selectedGroup = value;
  };

  @action updateSelectedGeoZone = (value: Select.ISelectOption) => {
    this.selectedGeoZone = value;
  };

  @action updateSelectedGeoZoneTag = (value: Select.ISelectOption) => {
    this.selectedGeoZoneTag = value;
  };

  @action updateSelectedVehicle = (value: Select.ISelectOption) => {
    if (!this.selectedVehicle || this.selectedVehicle.value !== value.value) {
      this.selectedVehicle = value;
    }
  };

  @action updateSelectedTimeRange = (from: number, to: number) => {
    this.selectedTimeRange = {
      from,
      to,
    };
  };

  @action updateSelectedUserRole = (value: Select.ISelectOption) => {
    this.selectedUserRole = value;
  };

  @action updateSelectedUser = (value: Select.ISelectOption) => {
    this.selectedUser = value;
  };

  @action updateSelectedState = (value: Select.ISelectOption) => {
    this.selectedState = value;
  };

  @action updateSelectedKeyFob = (value: Select.ISelectOption) => {
    this.selectedKeyFob = value;
  };
}

export default new PersistenceStore();
