import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import classNames from 'classnames';

import type { DevicesStore, ClearshareStore } from 'stores';

import LinksTable from 'components/ClearshareLink/LinksTable';

import './styles.scss';

interface IProps {
  className?: string;
  devicesStore?: DevicesStore;
  clearshareStore?: ClearshareStore;
}

@inject(({ devicesMapStore: { devicesStore }, clearshareStore }) => ({
  devicesStore,
  clearshareStore,
}))
@observer
class ClearshareLinksList extends Component<IProps> {
  private readonly createLinkWatcher: () => void;
  private readonly vehicleChangeWatcher: () => void;

  constructor(props) {
    super(props);

    this.createLinkWatcher = reaction(
      () => {
        return (
          this.repositories.repositoryClearshare.createState.success ||
          this.repositories.repositoryClearshareExpire.doState.success
        );
      },
      async (status) => {
        if (status) {
          await this.fetchActiveLinks();
          this.repositories.repositoryClearshare.createState.reset();
          this.repositories.repositoryClearshareExpire.doState.reset();
        }
      },
      { name: 'Fetch clearshare links on create or expire link' }
    );

    this.vehicleChangeWatcher = reaction(
      () => {
        const {
          devicesStore: {
            selectedDevice: {
              data: { deviceId },
            },
          },
        } = this.props;

        return deviceId;
      },
      (id) => {
        if (id) {
          this.fetchActiveLinks();
        }
      },
      { name: 'Fetch clearshare links on vehicle change' }
    );
  }

  get repositories() {
    const {
      clearshareStore: { repositoryClearshare, repositoryClearshareExpire },
    } = this.props;

    return { repositoryClearshare, repositoryClearshareExpire };
  }

  componentDidMount() {
    this.fetchActiveLinks();
  }

  componentWillUnmount() {
    this.createLinkWatcher();
    this.vehicleChangeWatcher();
  }

  fetchActiveLinks = async () => {
    const {
      devicesStore: {
        selectedDevice: {
          data: { deviceId },
        },
      },
      clearshareStore: { getLinks },
    } = this.props;

    await getLinks({ search: { deviceId, active: true } });
  };

  expireLink = async (linkId: string) => {
    const {
      clearshareStore: { expireLink },
    } = this.props;

    await expireLink(linkId);
  };

  render() {
    const {
      className,
      clearshareStore: { sortedLinks, repositoryClearshare },
    } = this.props;
    const cn = classNames('ClearshareLinkMain', className, {
      'ClearshareLinkMain--loading': Boolean(repositoryClearshare.getState.loading),
    });

    return (
      <div className={cn}>
        {sortedLinks.length ? (
          <div className="ClearshareLinkMain-links">
            <LinksTable links={sortedLinks} onExpireLink={this.expireLink} />
          </div>
        ) : (
          <p className="ClearshareLinkMain-noLinksMessage">No Active ClearShare Links</p>
        )}
      </div>
    );
  }
}

export default ClearshareLinksList;
