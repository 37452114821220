import { action, computed, observable } from 'mobx';
import isBoolean from 'lodash/isBoolean';

class ToggleField {
  @observable value: boolean;
  initialValue: boolean;
  id: number;

  constructor(value: boolean = false) {
    this.value = value;
    this.initialValue = value;
    this.id = Date.now();
  }

  @computed get isUpdated() {
    return this.value !== this.initialValue;
  }

  @action toggle = (value?: boolean) => (this.value = isBoolean(value) ? value : !this.value);

  @action reset = () => (this.value = this.initialValue);
}

export default ToggleField;
