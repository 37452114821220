import React, { useEffect } from 'react';

import type MaintenanceTasks from 'models/Maintenances/Models/MaintenanceTasks/MaintenanceTasks';

import type {
  IMaintenanceTasksItem,
  IServerMaintenanceTasksItem,
} from 'models/Maintenances/Models/MaintenanceTasks/MaintenanceTasksItem';
import SearchableSelectAsync from 'components/Select/SearchableSelectAsync';

interface IProps {
  maintenanceTask: MaintenanceTasks;
  onChange?: (option: Select.ISelectOption) => void;
  deviceId?: string;
}

const AlertPreferencesMaintenanceTaskSelect: React.FC<IProps> = ({ maintenanceTask, onChange, deviceId }) => {
  const handleChangeMaintenanceTask = (option: Select.ISelectOption) => {
    onChange(option);
  };

  useEffect(() => {
    maintenanceTask.updateAssetId(deviceId);
  }, [deviceId]);

  return (
    deviceId && (
      <SearchableSelectAsync<IMaintenanceTasksItem, IServerMaintenanceTasksItem>
        key={deviceId}
        handleChange={handleChangeMaintenanceTask}
        keys={{ value: 'id', label: 'name' }}
        options={maintenanceTask}
        selectFirst
        value={maintenanceTask.item?.id ? { value: maintenanceTask.item.id, label: maintenanceTask.item.name } : null}
      />
    )
  );
};

export default AlertPreferencesMaintenanceTaskSelect;
