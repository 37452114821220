import { observable, action, computed } from 'mobx';
import axios from 'axios';
import downloadjs from 'downloadjs';
import AppFileModelFactory from './Factory/AppFileModelFactory';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import type { IAppFile } from './IAppFile';
import type { IAppFileModel } from './Factory/IAppFileModel';
import type { IFileServerModel } from './IFileModel';

export const EXTENDED_SIZE = 52428800;

class AppFile implements IAppFile {
  @observable file: File = null;
  model: IAppFileModel;
  repository: IEntityRepository;

  constructor(repository: IEntityRepository, model: IFileServerModel = null) {
    this.repository = repository;
    this.model = model ? new AppFileModelFactory(model).get() : null;
  }

  @action attach = async (file): Promise<IAppFile> => {
    this.file = file;
    this.model = new AppFileModelFactory({
      displayName: file.name,
      type: this.isHeicImage ? 'image/heic' : file.type,
      url: '',
      fileName: '',
      id: null,
    }).get();

    return this;
  };

  @action download = async (): Promise<void> => {
    const response = await axios.get(this.model.url.value, { responseType: 'blob' });
    downloadjs(response.data, this.model.displayName, this.model.type);
  };

  @action upload = async (): Promise<void> => {
    if (this.sizeExtended) {
      return;
    }
    const formData = new FormData();

    formData.append('document', this.file);
    formData.append('type', this.model.type);

    const model = await this.repository.create(formData);

    if (this.repository.createState.success) {
      this.model.set(model);
    }
  };

  @computed get sizeExtended() {
    return this.file?.size >= EXTENDED_SIZE;
  }

  get data() {
    return this.model.data;
  }

  get isHeicImage() {
    return !this.file.type && this.file.name.includes('.heic');
  }
}

export default AppFile;
