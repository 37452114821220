import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type { MediaAdapter } from './MediaAdapter';
import { repositoryService } from 'services';

export class MediaItemsAdapter {
  repositoryMedia: IRepository;
  repositoryMediaCameras: IEntityRepository;
  context: MediaAdapter;

  constructor(context) {
    this.repositoryMedia = repositoryService.get('media', 'v2.0');
    this.repositoryMediaCameras = this.repositoryMedia.entity('legacy').entity('cameras');
    this.context = context;
  }

  getAll = async (params) => {
    const response = await this.repositoryMediaCameras
      .entity(params.selectedCameraId)
      .entity('data')
      .get(params);

    return this.convert(response);
  };

  private convert = (response) => {
    return {
      currentPage: response.currentPage,
      pageData: response.items,
      totalPages: response.totalPages,
    };
  };
}
