import React, { Component } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import type { PeopleAdmin } from 'stores/Admin/People';
import { PATHS, PEOPLE_ADMIN_ROLES } from 'config';

import AdminContentWrapper from 'components/Admin/ContentWrapper';
import Button from 'components/Button';
import ConfirmationModal from 'components/Admin/ConfirmationModal';
import CreatePersonModal from 'components/Admin/CreatePersonModal';
import PeopleAdminTable from './PeopleTable';

import './styles.scss';

interface IProps extends RouteComponentProps {
  peopleAdmin?: PeopleAdmin;
}

interface IState {
  createPersonModalOpen: boolean;
  confirmationModalOpen: boolean;
  isSendWelcomeEmail: boolean;
}

@inject(({ adminStore: { peopleAdmin } }) => ({
  peopleAdmin,
}))
@observer
class AdminPeople extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      createPersonModalOpen: false,
      confirmationModalOpen: false,
      isSendWelcomeEmail: false,
    };
  }

  openCreatePersonModal = () => this.setState({ createPersonModalOpen: true });

  closeCreatePersonModal = () => this.setState({ createPersonModalOpen: false });

  openConfirmationModal = () => this.setState({ confirmationModalOpen: true });

  closeConfirmationModal = () => {
    const {
      peopleAdmin: { item },
    } = this.props;

    this.setState({ confirmationModalOpen: false });
    item.value.repository.createState.reset();
  };

  goToPersonPage = () => {
    const {
      peopleAdmin: { item },
      history: { push },
    } = this.props;

    this.setState({ createPersonModalOpen: true });
    push(PATHS.ADMIN.TABLES.PEOPLE.PERSON.replace(':id', item.value.id.value.toString()));
  };

  createPerson = async ({ name, jobTitle, loginEmail, role, welcomeEmail }) => {
    const {
      peopleAdmin: { setItem, item, sendWelcomeEmail, repositorySendWelcomeEmail },
    } = this.props;

    setItem({
      contactName: name,
      jobTitle,
      loginEmail,
      role,
      id: 0,
    });

    try {
      const person = await item.value.create();
      const isSendWelcomeEmail = welcomeEmail && role !== PEOPLE_ADMIN_ROLES.DRIVER;

      this.setState({ isSendWelcomeEmail });

      if (person) {
        item.value.id.set(person.id);
      }

      if (item.value.repository.createState.success && isSendWelcomeEmail) {
        await sendWelcomeEmail(loginEmail);
        setTimeout(() => {
          repositorySendWelcomeEmail.createState.reset();
        }, 5000);
      }
    } finally {
      this.closeCreatePersonModal();
      this.openConfirmationModal();
    }
  };

  render() {
    const {
      peopleAdmin: { item },
    } = this.props;
    const { confirmationModalOpen, createPersonModalOpen, isSendWelcomeEmail } = this.state;

    return (
      <AdminContentWrapper
        className="AdminContentWrapper--people"
        title="People Admin"
        message="Manage the people in your organization and edit their user access."
      >
        <div className="AdminPeople-addPerson">
          <Button
            className="Button--apply"
            inline
            onClick={this.openCreatePersonModal}
            title="Add Person"
            type="button"
          />
        </div>
        <div className="AdminPeople-table">
          <PeopleAdminTable />
        </div>
        <CreatePersonModal
          isOpen={createPersonModalOpen}
          onCancel={this.closeCreatePersonModal}
          onSubmit={this.createPerson}
        />
        {item.value?.repository.createState.success && (
          <ConfirmationModal
            applyButtonTitle="Done"
            cancelButtonStyles={{ display: 'none' }}
            isOpen={confirmationModalOpen}
            onCancel={this.closeConfirmationModal}
            onSubmit={this.goToPersonPage}
            title="Person Successfully Added"
          >
            An account for {item.value.details.name.value} has been created
            {isSendWelcomeEmail
              ? ' and a welcome email with instructions to login have been sent to the Login Email provided'
              : ''}
            .
          </ConfirmationModal>
        )}
        {item.value?.repository.createState.error && (
          <ConfirmationModal
            applyButtonTitle="Done"
            cancelButtonStyles={{ display: 'none' }}
            isOpen={confirmationModalOpen}
            onCancel={this.closeConfirmationModal}
            onSubmit={this.closeConfirmationModal}
            title="Something went wrong"
          >
            {item.value.repository.createState.error?.replace('loginEmail', 'login email')}
          </ConfirmationModal>
        )}
      </AdminContentWrapper>
    );
  }
}

export default AdminPeople;
