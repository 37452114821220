import React, { FC } from 'react';
import type { FieldProps } from 'formik';

import PhoneNumberInput from 'components/PhoneNumberInput';

interface IProps extends FieldProps {
  value: string;
}

const PhoneNumberInputField: FC<IProps> = ({ field, value }) => {
  const handleChange = (value: string) => {
    field.onChange({
      target: {
        name: field.name,
        value,
      },
    });
  };

  return <PhoneNumberInput onChange={handleChange} value={value} />;
};

export default PhoneNumberInputField;
