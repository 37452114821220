import { SimpleField } from 'models/Fields';

class MaintenanceAlertSpecificTypeConfigModel {
  kmBefore: SimpleField<number>;
  engHoursBefore: SimpleField<number>;
  id: number;
  type: SimpleField<string>;
  avgDaysBefore: SimpleField<number>;
  daysBefore: SimpleField<number>;

  constructor({ kmBefore, engHoursBefore, serviceTypeId, type, avgDaysBefore, daysBefore }) {
    this.kmBefore = new SimpleField(kmBefore);
    this.engHoursBefore = new SimpleField(engHoursBefore);
    this.id = serviceTypeId;
    this.type = new SimpleField(type);
    this.avgDaysBefore = new SimpleField(avgDaysBefore);
    this.daysBefore = new SimpleField(daysBefore);
  }
}

export default MaintenanceAlertSpecificTypeConfigModel;
