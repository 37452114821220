import { CAMERA_JOURNAL_STATE, MEDIA_CLIP_TYPE } from 'config';
import union from 'lodash/union';
import type MediaClipsItem from '../MediaClipsItem';
import MediaInfoItem from './MediaInfo/MediaInfoItem';

class MediaInfo {
  items: MediaInfoItem[] = [];
  context: MediaClipsItem;

  constructor(items, context) {
    this.context = context;
    this.items = items.map((item) => new MediaInfoItem(item, this));
  }

  update = (items) => {
    this.items.forEach((item, i) => item.update(items[i]));
  };

  get front() {
    return this.items ? this.items[0] : null;
  }

  get faceVideo() {
    return this.items.find(
      (item) => item.cameraType.value === CAMERA_JOURNAL_STATE.IN && item.type === MEDIA_CLIP_TYPE.VIDEO
    );
  }

  get faceImage() {
    return this.items.find(
      (item) => item.cameraType.value === CAMERA_JOURNAL_STATE.IN && item.type === MEDIA_CLIP_TYPE.IMAGE
    );
  }

  get cameraTypes() {
    return union(this.items.map((item) => item.cameraType.value)).map((item) =>
      item === CAMERA_JOURNAL_STATE.IN ? 'INSIDE' : 'OUTSIDE'
    );
  }
}

export default MediaInfo;
