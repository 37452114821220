import { STORAGE_ITEMS } from 'config';
import { SimpleField } from 'models/Fields';
import { getJSONItemFromStorage, setJSONItemToStorage } from 'utils';

class PeopleUIModel {
  selectedTab: SimpleField<number>;

  constructor() {
    this.selectedTab = new SimpleField(PeopleUIModel.initialSelectedUserTab);
  }

  static get initialSelectedUserTab(): number {
    return getJSONItemFromStorage(STORAGE_ITEMS.admin.people.selectedPerson.tabIndex, 0);
  }

  setSelectedTab = (index: number) => {
    this.selectedTab.set(index);
    setJSONItemToStorage(STORAGE_ITEMS.admin.people.selectedPerson.tabIndex, index);
  };
}

export default PeopleUIModel;
