import React, { FC } from 'react';
import classnames from 'classnames';
import { Tooltip, TooltipProps } from 'antd';

import { CELL_ICONS } from 'models/Admin/People/Tables';

import { PhoneIcon, LaptopIcon, CalendarCar, DriverIcon, VehicleGroupIcon, MediaNavIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  icons: { [key: string]: { value: boolean; tooltip: string } };
}

const IconsCell: FC<IProps> = ({ icons, className }) => {
  const cn = classnames('IconsCell', className);
  const renderIcon = (name: string, tooltip: string) => {
    const tooltipProps: TooltipProps = {
      title: tooltip,
      color: '#FFF',
      placement: 'bottomLeft',
      overlayInnerStyle: { color: '#2A3344', fontSize: '12px', fontWeight: 500 },
    };

    switch (name) {
      case CELL_ICONS.WEB:
        return (
          <Tooltip {...tooltipProps}>
            <LaptopIcon />
          </Tooltip>
        );
      case CELL_ICONS.MOBILE:
        return (
          <Tooltip {...tooltipProps}>
            <PhoneIcon />
          </Tooltip>
        );
      case CELL_ICONS.DRIVER:
        return (
          <Tooltip {...tooltipProps}>
            <DriverIcon />
          </Tooltip>
        );
      case CELL_ICONS.VEHICLE:
        return (
          <Tooltip {...tooltipProps}>
            <CalendarCar />
          </Tooltip>
        );
      case CELL_ICONS.GROUP:
        return (
          <Tooltip {...tooltipProps}>
            <VehicleGroupIcon />
          </Tooltip>
        );
      case CELL_ICONS.DASHCAM:
        return (
          <Tooltip {...tooltipProps}>
            <MediaNavIcon />
          </Tooltip>
        );
      default:
        return null;
    }
  };

  return (
    <div className={cn}>
      <div className="IconsCell-list">
        {Object.keys(icons).map((key) => {
          const item = icons[key];
          const isActive = item.value;

          if (!isActive) {
            return null;
          }

          return (
            <div className="IconsCell-item" key={key}>
              {renderIcon(key, item.tooltip)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default IconsCell;
