import { ListField, SimpleField, TimeField, ToggleField } from 'models/Fields';
import MediaCameraType from 'models/Dashcam/MediaCommon/MediaCameraType';
import MediaDuration from 'models/Dashcam/MediaCommon/MediaDuration';
import GPS from './GPS';
import Location from './Location';
import { CAMERA_JOURNAL_STATE, MEDIA_CLIP_STATUS, MEDIA_CLIP_TYPE } from 'config';
import MediaVideo from './MediaVideo';
import type MediaInfo from '../MediaInfo';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';
import MediaInfoDate from './MediaInfoDate';
import MediaFile from './MediaFile';
import MediaInfoItemTitle from '../MediaInfoItemTitle';

class MediaInfoItem {
  video: MediaVideo;
  gps: GPS;
  eventTypes: ListField<string>;
  title: MediaInfoItemTitle;
  type: MEDIA_CLIP_TYPE;
  cameraType: MediaCameraType;
  expiringSoon: ToggleField;
  duration: MediaDuration;
  location: Location;
  updated: TimeField;
  status: SimpleField<MEDIA_CLIP_STATUS>;
  thumbnailUrl: string;
  cameraId: string;
  date: MediaInfoDate;
  mediaFile: MediaFile;
  context: MediaInfo;
  repository: IEntityRepository;
  repositoryRename: IEntityRepository;
  repositoryRequestMedia: IEntityRepository;

  constructor(
    {
      accessUrl,
      cameraId,
      gps,
      eventTypes,
      title,
      type,
      cameraType,
      expiringSoon,
      duration,
      location,
      updated,
      status,
      thumbnailUrl,
    },
    context
  ) {
    this.context = context;
    this.cameraId = cameraId?.toString();
    this.video = new MediaVideo(accessUrl);
    this.gps = new GPS(gps);
    this.eventTypes = new ListField(eventTypes);
    this.title = new MediaInfoItemTitle(title);
    this.type = type;
    this.cameraType = new MediaCameraType(cameraType === 'inside' ? CAMERA_JOURNAL_STATE.IN : CAMERA_JOURNAL_STATE.OUT);
    this.expiringSoon = new ToggleField(expiringSoon);
    this.duration = new MediaDuration(duration || 0);
    this.location = new Location(location);
    this.updated = new TimeField(updated);
    this.status = new SimpleField(status);
    this.thumbnailUrl = thumbnailUrl;
    this.date = new MediaInfoDate(this.context.context.date.value);
    this.repository = repositoryService
      .get('media', 'v2.0')
      .entity('cameras')
      .entity(this.cameraId);
    this.repositoryRename = this.repository.entity('clip');
    this.repositoryRequestMedia = this.repository.entity('request-media');
    this.mediaFile = new MediaFile({ repository: this.repository, id: this.cameraId, timestamp: this.date.value });
  }

  update = (item) => {
    this.video.set(item.accessUrl);
    this.gps.set(item.gps);
    this.eventTypes.replace(item.eventTypes);
    this.title.set(item.title);
    this.expiringSoon.toggle(item.expiringSoon);
    this.duration.active.set(item.duration || 0);
    this.location.set(item.location);
    this.updated.set(item.updated);
    this.status.set(item.status);
    this.thumbnailUrl = item.thumbnailUrl;
  };

  rename = async (clipTitle: string) => {
    await this.repositoryRename.patch({ clipTitle, timestamp: this.date.value });

    if (this.repositoryRename.patchState.success) {
      this.title.set(clipTitle);
    }
  };

  replay = async () => {
    const response = await this.repositoryRequestMedia.create({
      timestamp: this.date.value,
      cameraTypes: this.context.cameraTypes,
    });

    if (response?.status) {
      this.status.set(response.status);
    }
  };
}

export default MediaInfoItem;
