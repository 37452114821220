class TimePickerItem {
  order: number;
  value: string;
  active: boolean;

  constructor(value: string, order: number = 0, active: boolean = false) {
    this.value = value;
    this.active = active;
    this.order = order;
  }
}

export default TimePickerItem;
