import React, { useState, useEffect } from 'react';
import { Observer } from 'mobx-react';

import { RECIPIENT_TYPE } from 'config';

import type ITableDownload from 'interfaces/models/TableDownload/ITableDownload';

import Modal from 'components/Modal';
import Button from 'components/Button';
import TableDropDownDownloadFormat from '../TableDropDownDownloadFormat';
import TableRecipientGroup from '../TableRecipientGroup';

interface IProps<T> {
  isOpen: boolean;
  onClose: () => void;
  tableDownload: ITableDownload<T>;
}

function TableEmailModal<T>({ isOpen, onClose, tableDownload }: IProps<T>): React.ReactElement {
  const [hitNext, setHitNext] = useState(false);

  useEffect(() => {
    if (!tableDownload.emails.value.length) {
      tableDownload.emails.add('');
    }
  }, []);

  const cancelHitNext = () => setHitNext(false);

  const handleSubmit = async () => {
    if (tableDownload.emails.isItemsNotEmptyAndValid) {
      await tableDownload.send();
      handleClose();
    }

    setHitNext(true);
  };

  const handleClose = () => {
    tableDownload.emails.replace(['']);
    onClose();
  };

  return (
    <Observer
      render={() => (
        <Modal isModalOpen={isOpen} onClose={handleClose} className="TableEmailModal">
          <p className="TableEmailModal-title">Email Report</p>
          <div className="TableEmailModal-content">
            <TableDropDownDownloadFormat format={tableDownload.format} />
            <TableRecipientGroup
              items={tableDownload.emails}
              className="TableEmailList"
              title="Send report to"
              linkTitle="+ Add Another Email Address"
              type={RECIPIENT_TYPE.EMAIL}
              hitNext={hitNext}
              cancelHitNextNotify={cancelHitNext}
              isAllItemsEmpty={!tableDownload.emails.isItemsSomeNotEmpty}
              isCharacterLimitReached={tableDownload.isCharacterLimitReached}
              characterLimitLeft={tableDownload.characterLimitLeft}
            />
          </div>
          <div className="TableEmailModal-buttons">
            <div className="TableEmailModal-cancel">
              <Button className="Button--cancel Button--cancelColorLynch" title="Cancel" onClick={handleClose} />
            </div>
            <div className="TableEmailModal-submit">
              <Button
                className="Button--apply"
                title="Send"
                onClick={handleSubmit}
                sending={tableDownload.repositoryEmail.createState.loading}
              />
            </div>
          </div>
        </Modal>
      )}
    />
  );
}

export default TableEmailModal;
