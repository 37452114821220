import React, { useRef, useEffect } from 'react';
import { Observer } from 'mobx-react';

import classNames from 'classnames';
import { defer } from 'lodash';
import { Radio } from 'antd';
import { ACL, METRICS_SWITCH_TYPE } from 'config';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';
import { validateAccessLevel } from 'stores';

import type MaintenanceConfig from 'models/Maintenances/Models/MaintenanceConfig';
import type MaintenanceAsset from 'models/Maintenances/Models/MaintenanceAsset';
import type MaintenanceModalDueEveryUI from 'models/Maintenances/UI/MaintenanceModalUI/MaintenanceModalDueEveryUI';

import Checkbox from 'components/Checkbox';
import InputNumber from 'components/InputNumber';

import './styles.scss';

interface IProps {
  ui: MaintenanceModalDueEveryUI;
  odometer: MaintenanceConfig;
  engHours: MaintenanceConfig;
  asset: MaintenanceAsset;
  showNext?: boolean;
}

const DEFAULT_VALUES = {
  ODOMETER: '3000',
  ENG_HOURS: '100',
};

const MaintenancePreferenceDueEveryMetrics: React.FC<IProps> = ({ ui, odometer, engHours, asset, showNext = true }) => {
  let odometerValue = DEFAULT_VALUES.ODOMETER;
  let engHoursValue = DEFAULT_VALUES.ENG_HOURS;
  const inputRef = useRef(null);
  const edit = validateAccessLevel([ACL.MAINTENANCE.BASIC.UPDATE]);

  useEffect(() => {
    odometerValue = odometer.interval.value ? odometer.interval.value : DEFAULT_VALUES.ODOMETER;
    engHoursValue = engHours.interval.value ? engHours.interval.value : DEFAULT_VALUES.ENG_HOURS;

    onChangeAsset();
    EventsBus.get().on(APP_EVENTS.MAINTENANCE.TASK.ASSET.CHANGED, onChangeAsset);
    engHours.reset();

    return () => {
      odometer.interval.reInitialize('');
      odometer.next.reInitialize('');
      engHours.interval.reInitialize('');
      engHours.next.reInitialize('');
      EventsBus.get().off(APP_EVENTS.MAINTENANCE.TASK.ASSET.CHANGED, onChangeAsset);
    };
  }, []);

  const onChangeAsset = () => {
    if (ui.metricsCheckbox.value && ui.metricsSwitch.value === METRICS_SWITCH_TYPE.MILES) {
      odometer.interval.reInitialize(odometerValue);
      odometer.next.reInitialize((getLastOdometer() + Number(odometerValue)).toString());
    }
  };

  const toggleMetrics = () => {
    if (ui.metricsCheckbox.value) {
      odometer.remove();
      engHours.remove();
    } else {
      if (ui.metricsSwitch.value === METRICS_SWITCH_TYPE.MILES) {
        setOdometerDefaultValues();
      } else {
        setEngHoursDefaultValues();
      }

      defer(() => inputRef.current.focus());
    }

    ui.isError.toggle(false);
    ui.isLastPerformedError.toggle(false);
    ui.metricsCheckbox.toggle();
  };

  const switchType = (e) => {
    if (e.target.value === METRICS_SWITCH_TYPE.MILES) {
      setOdometerDefaultValues();
      engHours.remove();
    } else {
      setEngHoursDefaultValues();
      odometer.remove();
    }

    ui.metricsSwitch.set(e.target.value);
    ui.isLastPerformedError.toggle(false);
    inputRef.current.focus();
  };

  const onMetricsChange = (value) => {
    const interval = value ? value.toString().replace(/\,|\./gi, '') : value;

    if (ui.metricsSwitch.value === METRICS_SWITCH_TYPE.MILES) {
      const next = Number(interval) + Number(odometer.last.value);

      odometer.interval.set(interval);
      odometer.next.set(next.toString());
    } else {
      const next = Number(interval) + Number(engHours.last.value);

      engHours.interval.set(interval);
      engHours.next.set(next.toString());
    }
  };

  const setOdometerDefaultValues = () => {
    odometer.last.set(getLastOdometer().toString());
    odometer.interval.set(odometerValue);
    odometer.next.set((Number(odometer.last.value) + Number(odometer.interval.value)).toString());
  };

  const setEngHoursDefaultValues = () => {
    engHours.last.set(getLastEngineHours().toString());
    engHours.interval.set(engHoursValue);
    engHours.next.set((Number(engHours.last.value) + Number(engHours.interval.value)).toString());
  };

  const getLastOdometer = () => {
    return Number(odometer.last.value) || asset.details.odometer.value;
  };

  const getLastEngineHours = () => {
    return Number(engHours.last.value) || asset.details.engHours.value;
  };

  return (
    <Observer
      render={() => {
        const switchCN = classNames('MaintenancePreferenceDueEveryMetrics-editMetricsSwitch', {
          'MaintenancePreferenceDueEveryMetrics-editMetricsSwitch--disabled': !ui.metricsCheckbox.value || !edit,
        });

        return (
          <div className="MaintenancePreferenceDueEveryMetrics">
            <div className="MaintenancePreferenceDueEveryMetrics-edit">
              <Checkbox
                checked={ui.metricsCheckbox.value}
                onChange={toggleMetrics}
                className={`${ui.isError.value ? 'MaintenancePreferenceDueEveryMetrics-checkbox--error' : ''}`}
                disabled={!edit}
              />
              <InputNumber
                inputRef={inputRef}
                className="MaintenancePreferenceDueEveryMetrics-editMetricsInput"
                disabled={!ui.metricsCheckbox.value || !edit}
                value={
                  ui.metricsSwitch.value === METRICS_SWITCH_TYPE.MILES
                    ? Number(odometer.interval.value)?.toLocaleString('en-US', { maximumFractionDigits: 0 })
                    : Number(engHours.interval.value)?.toLocaleString('en-US', { maximumFractionDigits: 1 })
                }
                onChange={onMetricsChange}
                max="100000"
                min="0"
                controls={false}
              />

              <Radio.Group
                className={switchCN}
                value={ui.metricsSwitch.value}
                onChange={switchType}
                disabled={!ui.metricsCheckbox.value || !edit}
              >
                <Radio.Button
                  className="MaintenancePreferenceDueEveryMetrics-editMetricsSwitchButton"
                  value={METRICS_SWITCH_TYPE.MILES}
                >
                  Miles
                </Radio.Button>
                <Radio.Button
                  className="MaintenancePreferenceDueEveryMetrics-editMetricsSwitchButton"
                  value={METRICS_SWITCH_TYPE.HOURS}
                >
                  Hours
                </Radio.Button>
              </Radio.Group>
            </div>
            {showNext && (
              <div className="MaintenancePreferenceDueEveryMetrics-label">
                <span className="MaintenancePreferenceDueEveryMetrics-labelTitle">Next</span>
                <span className="MaintenancePreferenceDueEveryMetrics-labelValue">
                  {ui.metricsCheckbox.value &&
                    (ui.metricsSwitch.value === METRICS_SWITCH_TYPE.MILES
                      ? `${Number(odometer.next.value)?.toLocaleString('en-US', { maximumFractionDigits: 0 })} mi`
                      : `${Number(engHours.next.value)?.toLocaleString('en-US', { maximumFractionDigits: 1 })} hrs`)}
                </span>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default MaintenancePreferenceDueEveryMetrics;
