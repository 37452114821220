import React, { FC, useState } from 'react';
import classnames from 'classnames';

import type { IAppFile } from 'models/Files/IAppFile';
import FilePreview from '../FilePreview';

import './styles.scss';

interface IProps {
  className?: string;
  file: IAppFile;
  size?: string;
  withPreview?: boolean;
}

const PhotoTile: FC<IProps> = ({ className, file, size, withPreview = true }) => {
  const cn = classnames('PhotoTile', className, { [`PhotoTile--${size}`]: Boolean(size) });
  const [previewOpen, setPreview] = useState(false);

  return (
    <div className={cn}>
      <img className="PhotoTile-image" src={file.model.url.value} onClick={() => setPreview(true)} />
      {withPreview && previewOpen && (
        <div className="PhotoTile-preview">
          <FilePreview file={file} onDownload={file.download} onClose={() => setPreview(false)} />
        </div>
      )}
    </div>
  );
};

export default PhotoTile;
