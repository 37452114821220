import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import { getSatelliteCountsReception } from 'utils';
import InfoTooltip from '../../InfoTooltip';

import { SatelliteIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  satelliteCount: number;
}

const SatelliteCounts: FC<IProps> = ({ className, satelliteCount }) => {
  const cn = classNames('SatelliteCounts', className);
  const satelliteCounts = useMemo(() => {
    return getSatelliteCountsReception(satelliteCount);
  }, [satelliteCount]);

  return (
    <div className={cn}>
      <InfoTooltip placement="top" icon={<satelliteCounts.satIcon />}>
        <div className="SatelliteCounts-info">
          <div className="SatelliteCounts-icon">
            <SatelliteIcon className="SatelliteCounts-iconImg" height="10px" />
          </div>
          <div className="SatelliteCounts-text">
            <p className="SatelliteCounts-satellitesCount">{satelliteCount} Satellites</p>
            <p className="SatelliteCounts-accuracy">Accuracy: ~{satelliteCounts.accuracy}m</p>
          </div>
        </div>
      </InfoTooltip>
    </div>
  );
};

export default SatelliteCounts;
