import { observable } from 'mobx';

import { EVENT_STATUS_TYPE, SETTINGS_CATEGORY } from 'config';

class AttributesRegistry {
  @observable category: string = null;
  @observable defaultOn: boolean = false;
  @observable description: string = null;
  @observable eventStatusType: EVENT_STATUS_TYPE;
  @observable heading: boolean = false;
  @observable iconColor: string = 'FFFFFF';
  @observable iconLink: string = null;
  @observable label: string = null;
  @observable settingsCategory: SETTINGS_CATEGORY;
  @observable settingsLabel: string;
  @observable filterLabel: string;
  @observable showOnMap: boolean = false;
  @observable type: string = null;
  @observable calculateTotalStop: boolean = false;
  @observable calculateTotalIdle: boolean = false;
  @observable calculateTotalIgnitionOff: boolean = false;
  @observable showDriver: boolean = true;
  @observable arrowColor: string = 'FFFFFF';

  constructor(attributes) {
    this.category = attributes.category;
    this.defaultOn = attributes.defaultOn;
    this.description = attributes.description;
    this.eventStatusType = attributes.eventStatusType;
    this.heading = attributes.heading;
    this.iconColor = attributes.iconColor;
    this.iconLink = attributes.iconLink;
    this.label = attributes.label;
    this.settingsCategory = attributes.settingsCategory;
    this.settingsLabel = attributes.settingsLabel;
    this.filterLabel = attributes.filterLabel;
    this.showOnMap = attributes.showOnMap;
    this.type = attributes.type;
    this.calculateTotalIdle = attributes.calculateTotalIdle;
    this.calculateTotalStop = attributes.calculateTotalStop;
    this.calculateTotalIgnitionOff = attributes.calculateTotalIgnitionOff;
    this.showDriver = attributes.showDriver;
    this.arrowColor = this.type === 'Speeding' ? '2B2E41' : 'FFFFFF';
  }
}

export default AttributesRegistry;
