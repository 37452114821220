import React from 'react';
import classNames from 'classnames';

import PredefinedTimeFilterLink from 'components/Filters/PredefinedTimeFilterLink';

import './styles.scss';

interface IProps {
  className?: string;
  filters: Filters.IPredefinedTimeFilterOption[];
  setTimeRange: (range: Filters.IDateFilterOption) => void;
}

const PredefinedTimeFilter: React.FC<IProps> = ({ className, filters = [], setTimeRange }) => {
  const cn = classNames('PredefinedTimeFilter', className);

  return (
    <div className={cn}>
      <ul className="PredefinedTimeFilter-list">
        {filters.map((filter, index) => (
          <li key={index} className="PredefinedTimeFilter-item">
            <PredefinedTimeFilterLink filter={filter} setTimeRange={setTimeRange} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PredefinedTimeFilter;
