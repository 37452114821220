import { computed } from 'mobx';
import { getTimeInTimeZone, isToday } from 'utils';
import timeStore from 'stores/TimeStore';
import { DATE_TIME_FORMATS, MEDIA_CLIPS_VIEW } from 'config';

class MediaClipDate {
  value: number;

  constructor(value = 0) {
    this.set(value);
  }

  set = (value) => {
    this.value = value;
  };

  @computed get main() {
    return getTimeInTimeZone(this.value, timeStore.sessionTimezone).format(DATE_TIME_FORMATS.maintSchedule);
  }

  getLabel = (view: MEDIA_CLIPS_VIEW) => {
    switch (view) {
      case MEDIA_CLIPS_VIEW.MEDIA_LIBRARY:
        return getTimeInTimeZone(this.value, timeStore.sessionTimezone).format(DATE_TIME_FORMATS.mediaClipDate);
      case MEDIA_CLIPS_VIEW.VEHICLE_MEDIA_PANEL:
      default:
        return isToday(this.value, timeStore.sessionTimezone)
          ? `Today's Media`
          : getTimeInTimeZone(this.value, timeStore.sessionTimezone).format(DATE_TIME_FORMATS.monthDatYearFull);
    }
  };
}

export default MediaClipDate;
