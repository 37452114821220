import React, { useState, useEffect } from 'react';
import { Observer } from 'mobx-react';

import { RECIPIENT_TYPE } from 'config';

import type InspectionPerformedPDF from 'models/Inspections/Models/InspectionPerformedPDF';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Input from 'components/Input/AntInput';
import Loader from 'components/Loader';
import TableRecipientGroup from 'components/Table/TableRecipientGroup';

import './styles.scss';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  pdf: InspectionPerformedPDF;
}

const InspectionEmailModal: React.FC<IProps> = ({ isOpen, onClose, pdf }): React.ReactElement => {
  const [hitNext, setHitNext] = useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);

  useEffect(() => {
    if (!pdf.emails.value.length) {
      pdf.emails.add('');
    }
  }, []);

  const cancelHitNext = () => setHitNext(false);

  const handleSubmit = async () => {
    if (pdf.emails.isItemsNotEmptyAndValid) {
      setIsSubmitted(true);
      await pdf.emailFile();
      handleClose();
    } else {
      setHitNext(true);
    }
  };

  const handleClose = () => {
    pdf.emails.replace(['']);
    onClose();
    setIsSubmitted(false);
  };

  return (
    <Observer
      render={() => {
        return !isSubmitted ? (
          <Modal isModalOpen={isOpen} onClose={handleClose} className="InspectionEmailModal">
            <p className="InspectionEmailModal-title">Email Performed Inspection</p>
            <div className="InspectionEmailModal-content">
              <div className="InspectionEmailModal-contentFormat">
                <span className="InspectionEmailModal-contentFormatTitle">Format</span>
                <div className="InspectionEmailModal-contentFormatSelect">
                  <Input disabled value="PDF (Adobe)" />
                </div>
              </div>

              <TableRecipientGroup
                items={pdf.emails}
                className="InspectionEmailModalList"
                title="Send report to"
                linkTitle="+ Add Another Email Address"
                type={RECIPIENT_TYPE.EMAIL}
                hitNext={hitNext}
                cancelHitNextNotify={cancelHitNext}
                isAllItemsEmpty={!pdf.emails.isItemsSomeNotEmpty}
                isCharacterLimitReached={pdf.isCharacterLimitReached}
                characterLimitLeft={pdf.characterLimitLeft}
              />
            </div>
            <div className="InspectionEmailModal-buttons">
              <div className="InspectionEmailModal-cancel">
                <Button className="Button--cancel Button--cancelColorLynch" title="Cancel" onClick={handleClose} />
              </div>
              <div className="InspectionEmailModal-submit">
                <Button
                  className="Button--apply"
                  title="Send"
                  onClick={handleSubmit}
                  sending={pdf.repository.createState.loading}
                />
              </div>
            </div>
          </Modal>
        ) : isOpen ? (
          <Loader color="blue" />
        ) : null;
      }}
    />
  );
};

export default InspectionEmailModal;
