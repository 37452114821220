import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { RECIPIENT_TYPE } from 'config';

import Button from 'components/Button';
import InfoTooltip from 'components/InfoTooltip';
import RecipientItem from 'components/Recipients/RecipientItem';
import AlertNotifyEmail from '../AlertNotifyEmail';
import AlertNotifySMS from '../AlertNotifySMS';

import type { ListField, SimpleField } from 'models/Fields';

import './styles.scss';

interface IProps {
  title: string;
  linkTitle: string;
  info?: string;
  type: RECIPIENT_TYPE;
  items: ListField<string>;
  className?: string;
  isCharacterLimitReached?: boolean;
  isCustom?: boolean;
  isAllItemsEmpty?: boolean;
  hitNext: boolean;
  cancelHitNextNotify: () => void;
  emailSubject?: SimpleField<string>;
  emailText?: SimpleField<string>;
  smsText?: SimpleField<string>;
}

const AlertNotifyGroup: React.FC<IProps> = ({
  title,
  linkTitle,
  info,
  type,
  items,
  className,
  isCharacterLimitReached = false,
  isAllItemsEmpty = false,
  isCustom = false,
  hitNext,
  cancelHitNextNotify,
  emailSubject,
  emailText,
  smsText,
}) => {
  const addItem = () => {
    items.add('');
  };

  const removeItem = (item) => {
    items.remove(item);
  };

  const cn = classNames('AlertNotifyGroup', className);

  return (
    <div className={cn}>
      <div className="AlertNotifyGroup-title">
        <p>{title}</p>
        {info && (
          <div className="AlertNotifyGroup-title--info">
            <InfoTooltip>{info}</InfoTooltip>
          </div>
        )}
      </div>
      <ul className="AlertNotifyGroup-list">
        {items.value.map((item) => (
          <RecipientItem
            key={item.id}
            type={type}
            item={item}
            isEnabledCloseButton={Boolean(items.value.length > 1)}
            onRemove={removeItem}
            hitNext={hitNext}
            cancelHitNextNotify={cancelHitNextNotify}
            hitNextErrorEmpty="At least one email address or phone number is required."
            isAllItemsEmpty={isAllItemsEmpty}
          />
        ))}
      </ul>
      <div className="AlertNotifyGroup-add">
        <Button
          title={linkTitle}
          className="AlertNotifyGroup-add--button Button--link"
          onClick={addItem}
          disabled={!items.isItemsNotEmpty || isCharacterLimitReached}
        />
        {isCharacterLimitReached && (
          <div className="AlertNotifyGroup-add--info">
            Max character limit reached. Create another alert for additional contacts.
          </div>
        )}
      </div>
      {isCustom && (
        <div className="AlertNotifyGroup-title AlertNotifyGroup-title--custom">
          {type === RECIPIENT_TYPE.EMAIL && <AlertNotifyEmail emailSubject={emailSubject} emailText={emailText} />}
          {type === RECIPIENT_TYPE.TEXT && <AlertNotifySMS smsText={smsText} />}
        </div>
      )}
    </div>
  );
};

export default observer(AlertNotifyGroup);
