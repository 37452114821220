import React, { FC } from 'react';
import classnames from 'classnames';

import { PATHS } from 'config';
import NavLink from 'components/NavLink';
import Button from 'components/Button';
import CloseButton from 'components/Button/CloseButton';

import './styles.scss';

interface IProps {
  assetId: string;
  historyMode?: boolean;
  liveSteamAllowed?: boolean;
  onClose: () => void;
  onViewLiveStream: () => void;
}

const SelectedVehicleMediaPanelHeader: FC<IProps> = ({
  assetId,
  historyMode,
  liveSteamAllowed,
  onClose,
  onViewLiveStream,
}) => {
  const cn = classnames('SelectedVehicleMediaPanelHeader', {
    'SelectedVehicleMediaPanelHeader--historyMode': historyMode,
  });

  return (
    <div className={cn}>
      <div className="SelectedVehicleMediaPanelHeader-section SelectedVehicleMediaPanelHeader-section--left">
        <p className="SelectedVehicleMediaPanelHeader-title">Dashcam Media</p>
        <div className="SelectedVehicleMediaPanelHeader-actions">
          <div className="SelectedVehicleMediaPanelHeader-viewLivestream">
            <Button
              className="Button--apply Button--small"
              title="VIEW LIVE STREAM"
              onClick={onViewLiveStream}
              disabled={!liveSteamAllowed}
            />
          </div>
          <div className="SelectedVehicleMediaPanelHeader-requestMedia">
            <NavLink title="Request Media" to={`${PATHS.DASHCAMS.MEDIA_REQUEST.STEP_1}?assetId=${assetId}`} />
          </div>
        </div>
      </div>
      <div className="SelectedVehicleMediaPanelHeader-section SelectedVehicleMediaPanelHeader-section--right">
        <CloseButton onClick={onClose} />
      </div>
    </div>
  );
};

export default SelectedVehicleMediaPanelHeader;
