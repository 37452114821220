import React, { FC } from 'react';
import classnames from 'classnames';
import { Tooltip } from 'antd';

import './styles.scss';

interface IProps {
  children: React.ReactNode;
  miles: number;
  hours: number;
  dueInDays: { value: number; color: string };
}

const AverageDailyUsageTooltip: FC<IProps> = ({ children, miles, hours, dueInDays }) => {
  const dueInDaysCn = classnames('AverageDailyUsageTooltip-dueInDays', {
    [`AverageDailyUsageTooltip-dueInDays--${dueInDays.color}`]: Boolean(dueInDays.color),
  });
  return (
    <Tooltip
      color="#fff"
      placement="bottomLeft"
      title={
        <div className="AverageDailyUsageTooltip">
          {Boolean(dueInDays.value) && (
            <p className={dueInDaysCn}>{`About ${Math.abs(dueInDays.value)} days ${
              dueInDays.value < 0 ? 'overdue' : ''
            }`}</p>
          )}
          <p className="AverageDailyUsageTooltip-title">Avg. Daily Usage</p>
          <div className="AverageDailyUsageTooltip-values">
            {miles && (
              <span className="AverageDailyUsageTooltip-value AverageDailyUsageTooltip-value--miles">{miles} mi</span>
            )}
            {hours && (
              <>
                <span className="AverageDailyUsageTooltip-divider"> / </span>
                <span className="AverageDailyUsageTooltip-value AverageDailyUsageTooltip-value--miles">
                  {hours} hrs
                </span>
              </>
            )}
          </div>
        </div>
      }
    >
      <div className="AverageDailyUsageTooltip-children">{children}</div>
    </Tooltip>
  );
};

export default AverageDailyUsageTooltip;
