import { reaction } from 'mobx';
import { CAMERA_PLAYER_EVENTS, CAMERA_TYPE } from 'config';
import { SimpleField } from 'models/Fields';
import MediaDuration from 'models/Dashcam/MediaCommon/MediaDuration';
import EventsBus from 'services/EventsBus/eventsBus';

class RequestMediaTimeSegment {
  timeline: {
    start: SimpleField<number>;
    end: SimpleField<number>;
  };
  startTime: SimpleField<number>;
  scrubber: SimpleField<number>;
  duration: MediaDuration;
  private provider: SimpleField<CAMERA_TYPE>;

  constructor(provider: SimpleField<CAMERA_TYPE>) {
    this.provider = provider;
    this.timeline = { start: new SimpleField(null), end: new SimpleField(null) };
    this.startTime = new SimpleField(null);
    this.scrubber = new SimpleField(null);

    this.duration = new MediaDuration(20000, [20000, 40000, 60000]);

    reaction(
      () => this.isSmartWitness,
      (isSmartWitness) => {
        if (isSmartWitness) {
          this.duration.active.set(20000);
          this.duration.options.replace([20000, 40000]);
        }
      }
    );

    reaction(
      () => this.startTime.value,
      () => {
        EventsBus.get().trigger(CAMERA_PLAYER_EVENTS.PLAY_ONSTART);
      }
    );
  }

  get isSmartWitness() {
    return this.provider.value === CAMERA_TYPE.SMARTWITNESS;
  }

  initialize(startTimestamp: number, endTimestamp: number) {
    this.timeline.start.set(startTimestamp);
    this.timeline.end.set(endTimestamp);
    this.startTime.set(startTimestamp);
    this.scrubber.set(startTimestamp);
  }
}

export default RequestMediaTimeSegment;
