import React from 'react';
import classNames from 'classnames';
import { capitalize } from 'lodash';

import { APP_VERSION, FEATURES } from 'config';

import Popup from '../../Popup';
import UserRoleTag from '../UserRoleTag';
import ProfileAccountType from './ProfileAccountType';

import { ProfileIcon } from 'assets';

import './styles.scss';

interface IUserProps {
  accountName: string;
  accountPlan: string;
  userName: string;
  userEmail: string;
  profileImage?: string;
}

interface IProps extends IUserProps {
  userRoles: string[];
  onOpenChangePasswordModal?: () => void;
  onLogout?: (event: React.MouseEvent<HTMLElement>) => void;
  multiAccount?: boolean;
  onChangeAccount?: () => void;
}

const Profile = ({
  accountName,
  accountPlan,
  userName,
  userEmail,
  onOpenChangePasswordModal,
  onLogout,
  userRoles,
  multiAccount = false,
  onChangeAccount = () => void 0,
  profileImage,
}: IProps) => {
  const name = userName ? userName : userEmail;
  const userNameCN = classNames('Profile-detailsUserName', {
    'Profile-detailsUserName--email': !userName,
  });
  const isProAccount = accountPlan.toLowerCase() === 'pro';
  const accountPlanTitle = capitalize(accountPlan);

  return (
    <Popup
      hideOnEscape
      triggerElement={
        <div className="Profile">
          <div className="Profile-avatar">
            {profileImage && !profileImage.includes('.heic') ? (
              <img alt="Profile" className="Profile-avatarImage" src={profileImage} />
            ) : (
              <ProfileIcon />
            )}
          </div>
        </div>
      }
    >
      <div className="Profile-popup">
        <div className="Profile-detailsAccount">
          <div className="Profile-detailsSectionHeader">Account</div>
          <div className="Profile-detailsSectionBody">
            <div className="Profile-detailsAccountName">{accountName}</div>
            <ProfileAccountType isPro={isProAccount}>{accountPlanTitle}</ProfileAccountType>
            {multiAccount && (
              <div className="Profile-detailsAccountSwitcher">
                <button className="Profile-action Profile-action--changeAccount" onClick={onChangeAccount}>
                  Switch to another account
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="Profile-detailsUser">
          <div className="Profile-detailsSectionHeader">User</div>
          <div className="Profile-detailsSectionBody">
            <div className={userNameCN}>{name}</div>
            <div className="Profile-detailsUserEmail">{userEmail}</div>
            <div className="Profile-detailsUserRoles">
              {userRoles.map((role, index) => (
                <UserRoleTag key={index}>{role}</UserRoleTag>
              ))}
            </div>
            <div className="Profile-detailsUserActions">
              {FEATURES.changePassword && (
                <div className="Profile-detailsUserAction">
                  <button className="Profile-action Profile-action--changePassword" onClick={onOpenChangePasswordModal}>
                    Change my Password
                  </button>
                </div>
              )}
              <div className="Profile-detailsUserAction">
                <button className="Profile-action Profile-action--logout" onClick={onLogout}>
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="Profile-version">
          <p className="Profile-versionText">
            Version {APP_VERSION} ({process.env.REACT_APP_BUILD_NUMBER})
          </p>
          <p>© {new Date().getFullYear()} ClearPathGPS, Inc.</p>
        </div>
      </div>
    </Popup>
  );
};

Profile.defaultProps = {
  userName: 'User',
  userEmail: 'user@cpgps.com',
};

export default Profile;
