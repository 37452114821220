import React from 'react';
import classNames from 'classnames';

import LoginSlide from '../SlideWrapper';

import './styles.scss';

interface IProps {
  className?: string;
}

const LoginSlideOne: React.FC<IProps> = ({ className }) => {
  const cn = classNames('LoginSlideOne', className);

  return (
    <div className={cn}>
      <LoginSlide className="LoginSlide--slideOne">
        <p className="LoginSlide-title">Are You Getting the Most Out of Your Assets?</p>
        <p className="LoginSlide-message">
          Learn how our asset tracking can help you maximize profits and protect your gear.
        </p>
        <a
          className="LoginSlide-link"
          href="https://www.clearpathgps.com/solutions/gps-asset-tracking"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </a>
      </LoginSlide>
    </div>
  );
};

export default LoginSlideOne;
