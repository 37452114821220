import React, { FC } from 'react';
import classNames from 'classnames';

import type { IDashcamSettingsField } from 'interfaces/models/Dashcams/IDashcamSettingsField';
import { DashcamSettingRadio } from 'models/Dashcam/DashcamSettingRadio';
import { DASHCAMS_SETTINGS_TYPES } from 'config';

import './styles.scss';

interface IProps {
  className?: string;
  settings: Array<IDashcamSettingsField<any>>;
  top?: boolean;
  availableCameraTypes: string[];
}
const topList = [
  'xirgo.aggressiveDriving',
  'xirgo.roadFacingAI',
  'xirgo.driverFacingAI',
  'smartwitness.roadFacingAI',
  'smartwitness.driverFacingAI',
];

const DashcamSettingsList: FC<IProps> = ({ className, settings, top = false, availableCameraTypes }) => {
  const cn = classNames('DashcamSettingsList', className);

  const list = settings.filter(item => topList.includes(item.id) === top);

  return (
    <div className={cn}>
      <div className="DashcamSettingsList-list">
        {list.map((setting, index) => {
          const isDisabled =  setting.cameraType && !availableCameraTypes.includes(setting.cameraType);

          const itemValueCn = classNames('DashcamSettingsList-itemValue', {
            'DashcamSettingsList-itemValue--uppercase': setting.type === DASHCAMS_SETTINGS_TYPES.TOGGLE && !top,
            'DashcamSettingsList-itemValue--disabled': isDisabled,
          });

          const itemNameCn = classNames('DashcamSettingsList-itemName', {
            'DashcamSettingsList-itemName--disabled': isDisabled,
          });

          let currentValue =
            typeof setting.value?.value === 'boolean'
              ? setting.value.value
                ? top ? 'Active' : 'Yes'
                : top ? 'Inactive' : 'No'
              : setting instanceof DashcamSettingRadio
              ? setting.allowableValues?.toArray().find((allowableValue) => allowableValue.id === setting.value.value)
                  ?.name
              : setting.value?.value?.toLowerCase?.();

          if (setting.error && setting.error.status) {
            currentValue = setting.error.status;
          }

          return (!top || (top && currentValue)) && (
            <div className="DashcamSettingsList-item" key={setting.id || String(index)}>
              <span className={itemNameCn}>{setting.name}</span>
              <span className={itemValueCn}>{currentValue}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DashcamSettingsList;
