import { SimpleField, ToggleField } from 'models/Fields';

class PlayerVideoRestAPISource {
  duration: SimpleField<number>;
  url: SimpleField<string>;
  isActive: ToggleField;

  constructor(isActive) {
    this.duration = new SimpleField(0);
    this.url = new SimpleField(null);
    this.isActive = new ToggleField(isActive);
  }

  set = (url, duration) => {
    this.url.set(url);
    this.duration.set(duration);
  };
}

export default PlayerVideoRestAPISource;
