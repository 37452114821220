import React, { Fragment, useCallback } from 'react';
import classNames from 'classnames';
import find from 'lodash/find';

import SearchResultsItem from '../SearchResultsItem';
import { GeozoneIcon, PersonIcon, VehicleIcon, GroupIcon } from 'assets';

import './styles.scss';

const groupsIcons = {
  device: VehicleIcon,
  geozone: GeozoneIcon,
  group: GroupIcon,
  driver: PersonIcon,
};

interface IProps {
  results: Search.ISearchResults;
  searchQuery: string;
  devicesWithDriversList?: Array<{ driverId: string; deviceId: string }>;
  existingDevicesIds?: string[];
  onClick?: () => void;
}

const SearchResultsList: React.FC<IProps> = ({
  results,
  searchQuery,
  devicesWithDriversList,
  existingDevicesIds,
  onClick,
}) => {
  const cn = classNames('SearchResultsList');
  const getLink = useCallback(
    (item: Search.ISearchResultsItem): string => {
      let link = '';

      if (item.type === 'driver') {
        const deviceWithDriverObj = find(devicesWithDriversList, (obj) => obj.driverId === item.id, null);
        link = deviceWithDriverObj ? `/map/vehicles/${deviceWithDriverObj.deviceId}` : '';
      } else if (item.type === 'device') {
        link = existingDevicesIds.includes(item.id) || !existingDevicesIds.length ? `/map/vehicles/${item.id}` : '';
      } else {
        link = `/map/${item.type}s/${item.id}`;
      }

      return link;
    },
    [devicesWithDriversList, existingDevicesIds]
  );

  return (
    <div className={cn}>
      {Object.keys(results).map((type) => (
        <Fragment key={type}>
          {results[type].length ? (
            <div className={`SearchResultsList-group SearchResultsList-group--${type}`}>
              <p className="SearchResultsList-title">{type === 'devices' ? 'vehicles' : `${type}`}</p>
              <ul className="SearchResultsList-list">
                {results[type].map((item) => (
                  <li className="SearchResultsList-item" key={item.id}>
                    <SearchResultsItem
                      {...item}
                      link={getLink(item)}
                      Icon={groupsIcons[item.type]}
                      searchQuery={searchQuery}
                      onClick={onClick}
                    />
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </Fragment>
      ))}
    </div>
  );
};

export default SearchResultsList;
