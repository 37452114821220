import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';

import { RECIPIENT_TYPE } from 'config';

import type { IAlert } from 'models/Alerts/IAlert';
import CustomAlertModel from 'models/Alerts/CustomAlert';
import MaintenanceAlertModel from 'models/Alerts/MaintenanceAlertModel';

import AlertNotifyGroup from 'components/Alert/AlertNotifyGroup';
import InfoTooltip from 'components/InfoTooltip';
import Checkbox from 'components/Checkbox';

import './styles.scss';

interface IProps {
  alert: IAlert;
  hitNext: boolean;
  cancelHitNextNotify: () => void;
  setIsActiveNextButton: (value: boolean) => void;
}

const AlertPreferenceNotify: React.FC<IProps> = ({ alert, hitNext, cancelHitNextNotify, setIsActiveNextButton }) => {
  useEffect(() => {
    setIsActiveNextButton(true);
    cancelHitNextNotify();

    return () => cancelHitNextNotify();
  }, []);

  const isAllEmailsAndMobilesEmpty = () => {
    return !alert.notifyEmails.isItemsSomeNotEmpty && !alert.notifyMobileNumbers.isItemsSomeNotEmpty;
  };

  const handleIncludeMetricsCheckboxChange = () => {
    if (alert instanceof MaintenanceAlertModel) {
      alert.includeCurrentVehicleMetrics.toggle();
    }
  };
  const handleIncludeTaskNotesCheckboxChange = () => {
    if (alert instanceof MaintenanceAlertModel) {
      alert.includeTaskNotes.toggle();
    }
  };

  return (
    <Observer
      render={() => (
        <div className="AlertPreferenceNotify">
          <div className="AlertPreferenceNotify-line">
            <h3 className="AlertPreferenceNotify-type">{alert.title ? alert.title : alert.type.value} Alert</h3>
            {alert.description && <InfoTooltip>{alert.description}</InfoTooltip>}
          </div>
          <h4 className="AlertPreferenceNotify-title">NOTIFY</h4>
          <div className="AlertPreferenceNotify-content">
            <AlertNotifyGroup
              title="via Email"
              linkTitle="+ Add Another Email Address"
              type={RECIPIENT_TYPE.EMAIL}
              items={alert.notifyEmails}
              className="AlertPreferenceNotify-email"
              isCharacterLimitReached={alert.isCharacterLimitReached}
              isAllItemsEmpty={isAllEmailsAndMobilesEmpty()}
              hitNext={hitNext}
              cancelHitNextNotify={cancelHitNextNotify}
              isCustom={alert instanceof CustomAlertModel}
              emailSubject={alert instanceof CustomAlertModel && alert.emailSubject}
              emailText={alert instanceof CustomAlertModel && alert.emailText}
              smsText={alert instanceof CustomAlertModel && alert.smsText}
            />
            <AlertNotifyGroup
              title="via Text"
              info="Standard messaging rates apply"
              linkTitle="+ Add Another Phone Number"
              type={RECIPIENT_TYPE.TEXT}
              items={alert.notifyMobileNumbers}
              className="AlertPreferenceNotify-text"
              isCharacterLimitReached={alert.isCharacterLimitReached}
              isAllItemsEmpty={isAllEmailsAndMobilesEmpty()}
              hitNext={hitNext}
              cancelHitNextNotify={cancelHitNextNotify}
              isCustom={alert instanceof CustomAlertModel}
              emailSubject={alert instanceof CustomAlertModel && alert.emailSubject}
              emailText={alert instanceof CustomAlertModel && alert.emailText}
              smsText={alert instanceof CustomAlertModel && alert.smsText}
            />
          </div>

          {alert instanceof MaintenanceAlertModel && (
            <>
              <h4 className="AlertPreferenceNotify-title">Customize alert message</h4>
              <div className="AlertPreferenceNotify-content--transparent AlertPreferenceNotify-alertMessage">
                <Checkbox
                  label="Include Current Vehicle Metrics"
                  checked={alert.includeCurrentVehicleMetrics.value}
                  onChange={handleIncludeMetricsCheckboxChange}
                />
                <Checkbox
                  label="Include Task Note"
                  checked={alert.includeTaskNotes.value}
                  onChange={handleIncludeTaskNotesCheckboxChange}
                />
              </div>
            </>
          )}
        </div>
      )}
    />
  );
};

export default AlertPreferenceNotify;
