import React from 'react';
import type { SimpleField } from 'models/Fields';
import Chart from 'components/DashCams/Chart';
import { observer } from 'mobx-react';

interface IProps {
  chartData: number[];
  currentSecond: SimpleField<number>;
  scaleLimit?: number;
}

const ChartSpeed: React.FC<IProps> = ({ chartData, currentSecond, scaleLimit }) => {
  return (
    <div className="MediaLibrarySpeedChart">
      <Chart chartData={chartData} currentSecond={currentSecond.value} scaleLimit={scaleLimit} />
    </div>
  );
};

export default observer(ChartSpeed);
