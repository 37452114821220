import devicesStore, { DevicesStore as DevicesStoreClass } from './Devices';
import mapStore, { MapStore as MapStoreClass } from './MapStore';
import googleMapSearchStore, { GoogleMapSearchStore as GoogleMapSearchStoreClass } from './GoogleMapSearchStore';

export class DevicesMapStore {
  devicesStore: DevicesStoreClass;
  mapStore: MapStoreClass;
  mapSearchStore: GoogleMapSearchStoreClass;

  constructor() {
    this.devicesStore = devicesStore;
    this.mapStore = mapStore;
    this.mapSearchStore = googleMapSearchStore;
  }
}

export default new DevicesMapStore();
