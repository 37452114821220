import React from 'react';
import { observer } from 'mobx-react';

import VideoPlayer from 'components/DashCams/VideoPlayer';
import type MediaVideo from 'models/Dashcam/MediaLibrary/MediaClips/MediaClipsItem/MediaInfo/MediaVideo';

interface IProps {
  video: MediaVideo;
  play?: () => void;
  pause?: () => void;
  seek?: (value: number) => void;
  mainVideo?: boolean;
}

const MediaLibraryPlayer: React.FC<IProps> = ({
  video,
  play = () => true,
  pause = () => true,
  seek = () => true,
  mainVideo = false,
}) => {
  return (
    <div className="MediaLibraryPlayer">
      <VideoPlayer
        playing={video?.isPlaying.value}
        url={video?.url}
        mainVideo={mainVideo}
        muted={!mainVideo}
        onPlay={play}
        onPause={pause}
        onSeek={seek}
        seekTime={video?.seekTime.value}
        setProgress={video?.progressSecond.set}
      />
    </div>
  );
};

export default observer(MediaLibraryPlayer);
