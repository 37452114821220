import EventsBus from 'services/EventsBus/eventsBus';
import type WS from 'services/Websocket/WebSocket';
import { IFallbackStrategy, WS_OPTIONS } from 'services/Websocket/WebSocketConfig';
import { webSocketService } from 'services/Websocket/WebSocketService';
import { NOTIFICATION_EVENT_TYPES } from './NotificationEventTypes';
import { removeCookie, tokenManager } from 'utils';
import { TOKEN_COOKIE_NAME } from 'config/cookie';

export default class NotificationService {
  websocket: WS;
  rootName: string;

  constructor() {
    this.rootName = 'notification';
    this.websocket = webSocketService.get(this.rootName, WS_OPTIONS.NOTIFICATION, this.fallbackStrategy);
    EventsBus.get().on(`ws:connect:${this.rootName}`, this.onConnect);
    EventsBus.get().on(`ws:error:${this.rootName}`, this.onErrorWS);
  }

  public subscribe = () => {
    this.websocket?.connect();
  };

  public unsubscribe = () => {
    this.websocket?.disconnect();
  };

  private onConnect = () => {
    this.websocket.on('/notifications/all', this.handleEvents);
  };

  onErrorWS = async (error) => {
    if (error?.body.includes('401')) {
      removeCookie(TOKEN_COOKIE_NAME);
      await tokenManager.refresh((data) => {
        this.websocket.updateUrl(data.token).connect();
      });
    }
  };

  private handleEvents = (response) => {
    const data = JSON.parse(response.body);

    if (!data.entity) {
      return;
    }

    switch (data.entity) {
      case 'Media':
        EventsBus.get().trigger(NOTIFICATION_EVENT_TYPES.MEDIA, data.payload);
        break;
      case 'Report':
        EventsBus.get().trigger(NOTIFICATION_EVENT_TYPES.REPORT, data.payload);
        break;
      case 'MaintenanceRequest':
        EventsBus.get().trigger(NOTIFICATION_EVENT_TYPES.MAINTENANCE_REQUEST, data.payload);
        break;
    }
  };

  get fallbackStrategy(): IFallbackStrategy {
    return {
      callback: () => {
        EventsBus.get().trigger(NOTIFICATION_EVENT_TYPES.MEDIA_FALLBACK_STRATEGY);
        EventsBus.get().trigger(NOTIFICATION_EVENT_TYPES.REPORT_FALLBACK_STRATEGY);
      },
      polling: {
        repeat: false,
        reconnect: true,
      },
    };
  }
}

export const notificationService = new NotificationService();
