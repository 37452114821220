import React from 'react';

import { ADMIN_GEOZONES_UPDATE, WEB_MAP_TEXT_LOCATION_CREATE } from 'config';
import { UserAccessLevelComponent } from 'stores';
import PanelAction from 'components/SubNav/PanelAction';

import { DirectionEventIcon, PenIcon, PhoneIcon } from 'assets';
import './styles.scss';

interface IProps {
  onDirectionsClick?(): void;
  onTextLocationClick?(): void;
  onEditGeozoneClick?(): void;
}

const MapGeozonePanelActions: React.FC<IProps> = ({
  onDirectionsClick = () => void 0,
  onTextLocationClick = () => void 0,
  onEditGeozoneClick = () => void 0,
}) => (
  <div className="MapGeozonePanelActions">
    <div className="MapGeozonePanelActions-action">
      <PanelAction icon={<DirectionEventIcon />} onClick={onDirectionsClick}>
        Directions
      </PanelAction>
    </div>
    <UserAccessLevelComponent requiredAccessLevel={[WEB_MAP_TEXT_LOCATION_CREATE]}>
      <div className="MapGeozonePanelActions-action">
        <PanelAction icon={<PhoneIcon />} onClick={onTextLocationClick}>
          <span>
            Text
            <br />
            Location
          </span>
        </PanelAction>
      </div>
    </UserAccessLevelComponent>
    <UserAccessLevelComponent requiredAccessLevel={[ADMIN_GEOZONES_UPDATE]}>
      <div className="MapGeozonePanelActions-action">
        <PanelAction icon={<PenIcon />} onClick={onEditGeozoneClick}>
          <span>
            Edit in
            <br />
            Admin
          </span>
        </PanelAction>
      </div>
    </UserAccessLevelComponent>
  </div>
);

export default MapGeozonePanelActions;
