import { KM_TO_MI } from 'config';
import { ToggleField } from 'models/Fields';
import MaintenanceConfig from '../MaintenanceConfig';

class MaintenanceCompleteConfigsModel {
  odometer: MaintenanceConfig;
  engHours: MaintenanceConfig;
  serviceTime: MaintenanceConfig;
  isCompleted: ToggleField;

  constructor(odometerConfig = {}, engHoursConfig = {}, serviceTimeConfig = {}) {
    this.odometer = new MaintenanceConfig(odometerConfig, KM_TO_MI);
    this.engHours = new MaintenanceConfig(engHoursConfig, 1);
    this.serviceTime = new MaintenanceConfig(serviceTimeConfig, 1);
    this.isCompleted = new ToggleField(!this.isRepeat);
  }

  get isRepeat() {
    return Boolean(this.odometer.interval.value || this.engHours.interval.value || this.serviceTime.interval.value);
  }

  get model() {
    let odometerConfig = null;
    let engHoursConfig = null;
    let serviceTimeConfig = null;

    if (this.isRepeat) {
      odometerConfig = {
        last: this.odometer.model.last,
        interval: this.odometer.model.interval,
      };
      engHoursConfig = {
        last: this.engHours.model.last,
        interval: this.engHours.model.interval,
      };

      serviceTimeConfig = {
        last: this.serviceTime.model.last,
        interval: this.serviceTime.model.interval,
        intervalUnit: this.serviceTime.intervalUnit.value,
      };
    } else {
      odometerConfig = { next: this.odometer.model.next };
      engHoursConfig = { next: this.engHours.model.next };
      serviceTimeConfig = { next: this.serviceTime.model.next };
    }

    return {
      isCompleted: this.isCompleted.value,
      odometerConfig,
      engHoursConfig,
      serviceTimeConfig,
    };
  }
}

export default MaintenanceCompleteConfigsModel;
