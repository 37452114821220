import React, { FC } from 'react';
import classnames from 'classnames';

import { INSPECTION_RESULTS } from 'config';

import { CheckInCircleIconSrc, InfoIcon, InProgress, CrossInCircleIconSrc } from 'assets';
import './styles.scss';

interface IProps {
  text: string;
  status: string;
}

const InspectionResultsCell: FC<IProps> = ({ text, status }) => {
  const cn = classnames('InspectionResultsCell');
  const renderIcon = () => {
    switch (status) {
      case INSPECTION_RESULTS.PASSED:
        return <CheckInCircleIconSrc fill="#4CAF50" />;
      case INSPECTION_RESULTS.PASSED_WITH_DEFECTS:
        return <CheckInCircleIconSrc fill="#FFC107" />;
      case INSPECTION_RESULTS.IN_PROGRESS:
        return <InProgress fill="#6B7A99" />;
      case INSPECTION_RESULTS.ABANDONED:
        return <CrossInCircleIconSrc fill="#7C93BF" />;
      case INSPECTION_RESULTS.FAILED:
        return <InfoIcon fill="#F44336" />;
    }
  };

  return (
    <div className={cn}>
      <div className="InspectionResultsCell-icon">{renderIcon()}</div>
      <div className="InspectionResultsCell-text">{text}</div>
    </div>
  );
};

export default InspectionResultsCell;
