import React, { FC, useEffect } from 'react';
import classNames from 'classnames';

import Button from '../Button';
import { InfoIcon, SuccessIcon, CloseIcon } from 'assets';
import './styles.scss';

interface IProps {
  autoClose?: boolean;
  className?: string;
  onClose: () => void;
  position?: 'topRight';
  text: string | React.ReactNode;
  title: string;
  type: 'success' | 'error' | 'warning' | 'custom';
  icon?: React.ReactNode;
  onRetry?: () => void;
}

const Notification: FC<IProps> = ({
  autoClose = true,
  className,
  onClose,
  position = 'topRight',
  text,
  title,
  type,
  icon,
  onRetry,
}) => {
  const cn = classNames('Notification', className, {
    [`Notification--${position}`]: Boolean(position),
    [`Notification--${type}`]: Boolean(type),
  });

  useEffect(() => {
    if (autoClose) {
      setTimeout(onClose, 5000);
    }
  }, [autoClose, onClose]);

  return (
    <div className={cn}>
      <div className="Notification-icon">
        {type === 'success' && <SuccessIcon className="Notification-iconImage Notification-iconImage--success" />}
        {type === 'error' && <InfoIcon className="Notification-iconImage Notification-iconImage--error" />}
        {type === 'warning' && <InfoIcon className="Notification-iconImage Notification-iconImage--warning" />}
        {type === 'custom' && icon && <>{icon}</>}
      </div>
      <div className="Notification-message">
        <p className="Notification-messageTitle">{title}</p>
        <p className="Notification-messageText">{text}</p>
        {type === 'error' && onRetry && (
          <div className="Notification-messageRetry">
            <Button className="Button--link" title="Retry" onClick={onRetry} />
          </div>
        )}
      </div>
      <div className="Notification-icon">
        <CloseIcon onClick={onClose} className="Notification-iconImage Notification-iconImage--close" />
      </div>
    </div>
  );
};

export default Notification;
