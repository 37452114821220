import { observable, action } from 'mobx';
import { uniqBy, sortBy } from 'lodash';
import type { ISelectOptions } from 'interfaces/models/Select/ISelect';
import MaintenanceTasksItem, { IServerMaintenanceTasksItem } from './MaintenanceTasksItem';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';
import { ToggleField } from 'models/Fields';

class MaintenanceTasks implements ISelectOptions<MaintenanceTasksItem, IServerMaintenanceTasksItem> {
  items: MaintenanceTasksItem[];
  @observable item: MaintenanceTasksItem;
  @observable totalPages: number;
  repository: IEntityRepository;
  @observable assetId: string;

  preselectedId: string;
  preselectedFetched: boolean;
  isInvalid: ToggleField;

  constructor(item = null, id = null) {
    this.items = [];
    this.assetId = null;
    this.item = item ? new MaintenanceTasksItem(item) : null;
    this.preselectedId = id;
    this.repository = repositoryService.get('maintenances').entity('');
    this.isInvalid = new ToggleField(false);
  }

  @action fetch = async (data, { clear, sortId, sortLabel }) => {
    const { filter, order, page, pageSize } = data;
    const response = await this.repository.get({
      sortBy: 'dueInDays',
      isCompleted: false,
      order,
      page,
      pageSize,
      searchValue: filter,
      assetId: this.assetId,
    });

    const options = response?.items.map((item) => new MaintenanceTasksItem(item));

    const nextOptions = clear ? [...options] : [...this.items, ...options];
    const uniqueOptions = uniqBy(nextOptions, sortId);
    const sortOptions = sortBy(uniqueOptions, [(option) => option[sortLabel].toLowerCase()]);

    this.items = sortOptions;
    this.totalPages = response?.totalPages;
    if (this.isInvalid.value && this.items.length) {
      this.isInvalid.toggle(false);
    }
    this.setFirstOption();
  };

  @action setFirstOption = () => {
    if (this.preselectedId && !this.preselectedFetched) {
      const option = this.getOptionById(String(this.preselectedId));
      if (option) {
        this.preselectedFetched = true;
        this.item = option;
      }
    }

    if (!this.item && this.items.length) {
      this.item = this.items[0];
    }
  };

  @action addDefaultOption = () => void 0;

  @action addOption = async (option: IServerMaintenanceTasksItem) => {
    option = option || { id: 0, name: 'None' };

    const currentOption = this.getOptionById(option.id.toString());

    if (!currentOption) {
      this.items.push(new MaintenanceTasksItem(option));
    }
  };

  getOptionById = (id: string): MaintenanceTasksItem => {
    return this.items.find((item) => item.id === id);
  };

  @action setItemById = (value: string) => {
    const item = this.items.find(({ id }) => id === value);
    if (item) {
      this.item = item;
    }
  };

  @action updateAssetId = (val: string) => {
    this.items = [];
    this.assetId = val;
    this.item = null;
  };
}

export default MaintenanceTasks;
