import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { formatDecimalNumber, getFormattedTime } from 'utils';

import { WEB_FINANCE_DETAILS_READ } from 'config';

import type { CustomersStore } from 'stores';
import validateAccessLevel from 'stores/acl/validator';

import SecondaryPanelAttributesRow from '../../SecondaryPanelAttributes/SecondaryPanelAttributesRow';
import SecondaryPanelAttributesCol from '../../SecondaryPanelAttributes/SecondaryPanelAttributesCol';
import FinancialDataAccessError from 'components/FinancialDataAccessError';

interface IProps {
  customersStore?: CustomersStore;
}


@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class SecondaryPanelJobDetails extends Component<IProps> {
  get hasAccess () {
    return validateAccessLevel([WEB_FINANCE_DETAILS_READ]);
  }

  render() {
    const {
      customersStore: {
        selectedCustomer: {
          jobDetails,
        },
      },
    } = this.props;
    const hasAccess = this.hasAccess;

    return <div className="SecondaryPanelJobDetails">

      <div className="SecondaryPanelInvoiceDetailsPrice">
        <div className="SecondaryPanelInvoiceDetailsPrice-item">
          <div className="SecondaryPanelInvoiceDetailsPrice-header">Total</div>
          {hasAccess && (<div
            className="SecondaryPanelInvoiceDetailsPrice-value"
          >${formatDecimalNumber(jobDetails.totalAmount, 2)}</div>)}
          {!hasAccess && <FinancialDataAccessError className="SecondaryPanelInvoiceDetailsPrice-value--limited" />}
        </div>
        <div className="SecondaryPanelInvoiceDetailsPrice-item">
          <div className="SecondaryPanelInvoiceDetailsPrice-header">Balance</div>
          {hasAccess && (<div
            className="SecondaryPanelInvoiceDetailsPrice-value"
          >${formatDecimalNumber(jobDetails.overdueBalance, 2)}</div>)}
          {!hasAccess && <FinancialDataAccessError className="SecondaryPanelInvoiceDetailsPrice-value--limited" />}
        </div>
      </div>

      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Date</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{jobDetails.serviceDate ? getFormattedTime(jobDetails.serviceDate, 'MM/DD/YYYY') : '-'}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>

      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Status</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{jobDetails.status}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>

      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Technician</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{jobDetails.technicianNames.join('\n')}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>
    </div>;
  }
}

export default SecondaryPanelJobDetails;
