import { MAP_FILTER_ATTRIBUTES, STORAGE_ITEMS, WEB_DASHCAM_BASIC_READ } from 'config';
import validateAccessLevel from 'stores/acl/validator';
import { getParsedStorageItem, removeStorageItem, setStringifiedStorageItem } from 'utils';
import AttributeFilter from './AttributeFilter';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

class DevicesAttributesFilter {
  items: AttributeFilter[] = [];

  constructor() {
    this.add({
      label: 'Device Lost Power',
      id: MAP_FILTER_ATTRIBUTES.POWER_DISCONNECT,
      fieldKey: 'isPowerDisconnected',
    });

    EventsBus.get().on(APP_EVENTS.ACL_UPDATED, this.addAttributesWithACLs);
    this.preSelect();
  }

  add = (item) => {
    this.items.push(new AttributeFilter(item, this));

    return this;
  };

  onChecked = () => {
    const storageValue = {};
    const storage = getParsedStorageItem(STORAGE_ITEMS.devicesAttributes);

    this.items.forEach((item) => (storageValue[item.id] = item.isChecked.value));

    setStringifiedStorageItem(
      STORAGE_ITEMS.devicesAttributes,
      storage ? { ...storage, ...storageValue } : storageValue
    );

    EventsBus.get().trigger(APP_EVENTS.DEVICES.FILTER);
  };

  isShow = (fields) => {
    return this.isSomeChecked() ? this.items.some((item) => fields[item.fieldKey] && item.isChecked.value) : true;
  };

  isSomeChecked = () => {
    return this.items.some((item) => item.isChecked.value);
  };

  reset = () => {
    this.items.forEach((item) => item.isChecked.toggle(false));
    removeStorageItem(STORAGE_ITEMS.devicesAttributes);
    EventsBus.get().trigger(APP_EVENTS.DEVICES.FILTER);
  };

  private addAttributesWithACLs = () => {
    if (validateAccessLevel([WEB_DASHCAM_BASIC_READ])) {
      this.add({
        label: 'Has Dashcam',
        id: MAP_FILTER_ATTRIBUTES.HAS_DASHCAM,
        fieldKey: 'mediaEnabled',
      });
    }

    this.preSelect();
  };

  private preSelect = () => {
    const storage = getParsedStorageItem(STORAGE_ITEMS.devicesAttributes);

    if (storage) {
      Object.keys(storage).forEach((id) => {
        const item = this.items.find((item) => item.id === id);

        if (item) {
          item.toggle(storage[id]);
        }
      });
    }
  };
}

export default DevicesAttributesFilter;
