enum EVENT_REGISTRY_TYPE {
  BRAKING = 'Braking',
  CORNERING = 'Cornering',
  ACCELERATION = 'Excess_Accel',
}

export default ({ statusColor, heading, isHeading, type, mapHeading, arrowColor }) => {
  const StatusHarshBrakingIcon = `
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#FF5722" stroke="#CC461B" d="M10,0.5c-5.2,0-9.5,4.3-9.5,9.5c0,5.2,4.3,9.5,9.5,9.5c5.2,0,9.5-4.3,9.5-9.5
         \tC19.5,4.8,15.2,0.5,10,0.5z"/>
      <path fill="#FFFFFF" d="M13.1,4l-2.2,3.8L11.2,8l-0.2,0.4c-0.6,0.1-1.8,0.7-2.6,0.5c-1-0.3-1.8-0.8-2.2-0.7
        \t\tC6,8.2,5.6,8.7,5.4,9c0,0,0,0-0.1,0c0,0,0,0-0.1,0.1L5.1,9.3c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0l6.6,3.8
        \t\tc0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0l0.2-0.3c0,0,0,0,0-0.1s0,0,0-0.1c0.4-0.8,0.8-1.6,1.1-2.4l0.6-1l0.3,0.2
        \t\tl2.2-3.8L13.1,4z"/>
      <path fill="#FFFFFF" d="M9.5,13L3.8,9.7c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1L3,10.6c0,0,0,0,0,0.1c0,0,0,0,0,0.1
        \t\tc0,0,0,0,0,0.1c0,0,0,0,0,0l2.8,1.6l-1.4,2.4l0.8,0.5l1.4-2.4l2.2,1.3c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0
        \t\tl0.5-0.9c0,0,0,0,0-0.1c0,0,0,0,0-0.1C9.6,13.1,9.6,13.1,9.5,13C9.6,13,9.5,13,9.5,13z"/>
    </svg>
  `;

  const StatusHarshAccelerationIcon = `
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 10C0.5 4.75114 4.75114 0.5 10 0.5C15.2489 0.5 19.5 4.75114 19.5 10C19.5 15.2489 15.2489 19.5 10 19.5C4.75114 19.5 0.5 15.2489 0.5 10Z"
        fill="#FF5722" stroke="#B33128"/>
      <path
        d="M15.0858 7.15939C14.844 6.85719 14.6022 6.55498 14.3605 6.25278C13.2725 5.16485 11.822 4.56044 10.3714 4.5H9.76699C8.31641 4.56044 6.86584 5.16485 5.77791 6.25278C4.20645 7.82424 3.60204 10.2419 4.26689 12.3573C4.38777 12.7804 4.56909 13.2035 4.81086 13.5661C4.93174 13.7474 5.05262 13.9287 5.23394 13.9892C5.41526 14.1101 5.59658 14.1705 5.83835 14.1705H14.1792C14.4209 14.1705 14.6022 14.1101 14.7836 13.9892C14.9649 13.8683 15.1462 13.7474 15.2066 13.5661C15.7506 12.599 16.0528 11.5111 15.9924 10.4232C16.0528 9.27481 15.6902 8.12644 15.0858 7.15939ZM12.1242 8.42864L11.0362 11.1485C10.9154 11.3902 10.734 11.5716 10.4923 11.6924C9.94831 11.9342 9.28346 11.6924 9.0417 11.088C8.86038 10.6649 8.98126 10.121 9.40434 9.81878C9.40434 9.81878 10.734 8.79129 11.6406 8.066C11.9429 7.88468 12.2451 8.12644 12.1242 8.42864ZM12.5473 7.21983C12.3659 7.46159 12.1242 7.58247 11.822 7.58247C11.7011 7.58247 11.5198 7.52203 11.3989 7.46159C10.9758 7.21983 10.5527 7.09895 10.1296 7.03851C9.40434 6.91763 8.7395 6.97807 8.07465 7.28027C7.34936 7.58247 6.8054 8.066 6.38231 8.6704C5.95923 9.27481 5.65702 10.0605 5.59658 10.8463H5.23394C5.1735 9.93966 5.35482 9.09349 5.77791 8.30776C6.14055 7.52203 6.8054 6.79674 7.59113 6.31322C8.37685 5.82969 9.28346 5.58793 10.2505 5.52749C10.9154 5.52749 11.5802 5.58793 12.1846 5.82969C12.4264 5.95058 12.6681 6.1319 12.7286 6.37366C12.7286 6.7363 12.7286 6.97807 12.5473 7.21983Z"
        fill="white"/>
    </svg>
  `;

  const StatusCorneringIcon = `
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 10C0.5 4.75114 4.75114 0.5 10 0.5C15.2489 0.5 19.5 4.75114 19.5 10C19.5 15.2489 15.2489 19.5 10 19.5C4.75114 19.5 0.5 15.2489 0.5 10Z"
        fill="#FF5722" stroke="#B33128"/>
      <path
        d="M6.89303 12.0547V11.4194V9.80958H10.4853V14.7489H11.3833V8.91153H6.89303V7.30176V6.66638L4.19885 9.36055L6.89303 12.0547Z"
        fill="white"/>
      <path
        d="M9.50926 4.46344L10.4826 4.46344L10.4826 6.91227L9.50926 6.91227C9.50926 6.91227 9.53334 4.51503 9.50926 4.46344ZM13.7792 5.80651L14.4671 6.49438L12.7474 8.21407L12.0595 7.52619L13.7792 5.80651ZM13.3613 10.7644L13.3613 9.79101L15.8102 9.79101L15.8102 10.7644C15.7586 10.7403 13.3613 10.7644 13.3613 10.7644Z"
        fill="white"/>
    </svg>
  `;

  const IconWithArrow = `
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" >
      <circle cx="10" cy="10" r="9" fill="#${statusColor}" stroke="#48515d" stroke-width="1"/>
      <path d="M11.1969 15L11.1969 8.93128L15 8.93128L10.1103 4.04154L5.22051 8.93128L9.02364 8.93128L9.02364 15L11.1969 15Z"
          fill="#${arrowColor}" transform="rotate(${heading - mapHeading}, 10, 10)"/>
    </svg>
  `;

  const IconWithoutArrow = `
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="9" fill="#${statusColor}" stroke="#48515d" stroke-width="1"/>
    </svg>
  `;

  switch (type) {
    case EVENT_REGISTRY_TYPE.BRAKING:
      return StatusHarshBrakingIcon;
    case EVENT_REGISTRY_TYPE.CORNERING:
      return StatusCorneringIcon;
    case EVENT_REGISTRY_TYPE.ACCELERATION:
      return StatusHarshAccelerationIcon;
    default:
      return isHeading ? IconWithArrow : IconWithoutArrow;
  }
};
