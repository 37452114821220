import React, { Component } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

import { REPORTS_STORE } from 'config';
import type { ReportsStore } from 'stores';

import type ReportTable from 'models/Report/ReportTable';

import TableHeader from 'components/Table/Header';
import EditColumnsModal from 'components/Admin/EditColumnsModal';

interface IProps {
  getColumns?: (table: ReportTable) => any;
  reportsStore?: ReportsStore;
  isFilterOpen: boolean;
  handleFilterToggle: () => void;
  onDownloadModalOpen: () => void;
  onEmailModalOpen: () => void;
  onPrint: () => void;
}

interface IState {
  isEditColumns: boolean;
}

@inject(REPORTS_STORE)
@observer
class ReportGroupedTableActions extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      isEditColumns: false,
    };
  }

  closeTableEditModal = () => {
    this.setState({
      isEditColumns: false,
    });
  };

  handleColumnsChange = (columns) => {
    const {
      reportsStore: { reportTableGroup },
    } = this.props;

    reportTableGroup.updateHeadersVisibility?.(columns);
    reportTableGroup.editColumns?.setVisibleEditColumns?.(columns);
    reportTableGroup.updateHeaders?.();
  };

  openTableEditModal = () => {
    this.setState({
      isEditColumns: true,
    });
  };

  openDownloadModal = () => {
    this.props.onDownloadModalOpen();
  };

  openEmailModal = () => {
    this.props.onEmailModalOpen();
  };
  handleOpenFilter = () => {
    this.props.handleFilterToggle();
  };

  render() {
    const {
      reportsStore: { reportTableGroup, asyncReportLoading, asyncReportCompleted },
      isFilterOpen,
      onPrint,
    } = this.props;

    const actionsList = [];

    return (
      <>
        <TableHeader
          actionsList={actionsList}
          isFilterActive={isFilterOpen}
          onEditTable={this.openTableEditModal}
          onOpenDownload={this.openDownloadModal}
          onOpenEmail={this.openEmailModal}
          onOpenFilter={this.handleOpenFilter}
          withActions
          withDownload
          withEmail
          withFilter
          isLoading={asyncReportLoading}
          progress={asyncReportCompleted}
          withPrint
          onPrint={onPrint}
        />
        <EditColumnsModal
          isOpen={this.state.isEditColumns}
          onCancel={this.closeTableEditModal}
          onSubmit={this.handleColumnsChange}
          columnsMap={reportTableGroup.editColumns.editColumnsMap}
          columns={toJS(reportTableGroup.editColumns.editColumnsList)}
        />
      </>
    );
  }
}

export default ReportGroupedTableActions;
