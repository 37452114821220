import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import FilterOrderDevices from './FilterOrderDevices';
import FilterOrderSettings from './FilterOrderSettings';

class FiltersOrder {
  repositoryFilters: IEntityRepository;
  devices: FilterOrderDevices;
  settings: FilterOrderSettings;

  constructor(repositoryMetadata: IEntityRepository) {
    this.devices = new FilterOrderDevices();
    this.settings = new FilterOrderSettings();
    this.repositoryFilters = repositoryMetadata.entity('filters');
  }

  fetch = async () => {
    const response = await this.repositoryFilters.get();

    this.devices.set(response.filters);
    this.settings.set(response.settings);
  };
}

export default FiltersOrder;
