import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { SimpleField } from 'models/Fields';
import { repositoryService } from 'services';
import { APP_EVENTS } from 'services/EventsBus/appEvents';
import EventsBus from 'services/EventsBus/eventsBus';
import MaintenanceCompleteConfigsModel from './CompleteTask/MaintenanceCompleteConfigsModel';
import MaintenanceCompleteInfoModel from './CompleteTask/MaintenanceCompleteInfoModel';
import MaintenanceCompleteValuesModel from './CompleteTask/MaintenanceCompleteValuesModel';
import type MaintenanceModel from './MaintenanceModel';
import ServiceTypeItem from './Service/ServiceTypeItem';
import MaintenanceLoggerInfo from './MaintenanceLoggerInfo';
import AppFileList from 'models/Files/AppFileList';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import MaintenanceCompleteCostsModel from './CompleteTask/MaintenanceCompleteCostsModel';

class MaintenanceCompleteRecordModel {
  info: MaintenanceCompleteInfoModel;
  costs: MaintenanceCompleteCostsModel;
  notes: SimpleField<string>;
  values: MaintenanceCompleteValuesModel;
  maintenanceId: number;
  maintenanceDate: SimpleField<number>;
  maintenance: MaintenanceCompleteConfigsModel;
  performedByPosition: SimpleField<string>;
  documents: AppFileList;
  performedByFullName: SimpleField<string>;
  serviceType: ServiceTypeItem;
  id: number;
  loggerInfo: MaintenanceLoggerInfo;
  context: MaintenanceModel;
  repository: IRepository;
  repositoryHistory: IEntityRepository;
  repositoryModel: IEntityRepository;

  constructor(
    {
      id,
      documents,
      partsCost = 0,
      laborCost = 0,
      otherCost = 0,
      totalCost = 0,
      notes = '',
      name,
      values,
      maintenanceId = null,
      maintenanceDate,
      performedByPosition = '',
      serviceType,
      creationTime = 0,
      performedByFullName = '',
      lastUpdateTime = 0,
      updatedBy = '',
      createdBy = '',
    },
    context: MaintenanceModel
  ) {
    this.context = context;
    this.repository = repositoryService.get('maintenances');
    this.repositoryHistory = this.repository.entity('history');
    this.repositoryModel = this.repositoryHistory.entity(id ? id.toString() : null);

    this.info = new MaintenanceCompleteInfoModel({
      taskName: name || context.name.value,
      vehicleName: context.asset.serverModel.name,
      currentOdometer: context.asset.serverModel.odometer,
      currentEngineHours: context.asset.serverModel.engHours,
    });
    this.id = id;
    this.costs = new MaintenanceCompleteCostsModel({ partsCost, laborCost, otherCost, totalCost });
    this.notes = new SimpleField(notes);
    this.values = new MaintenanceCompleteValuesModel(values);
    this.maintenanceId = maintenanceId;
    this.maintenanceDate = new SimpleField(maintenanceDate || Date.now());
    this.maintenance = new MaintenanceCompleteConfigsModel(
      context.odometer.config.model,
      context.engine.config.model,
      context.service.config.model
    );
    this.performedByPosition = new SimpleField(performedByPosition);
    this.documents = new AppFileList(
      this.repository,
      documents,
      id ? { id, name: 'maintenanceHistoryId' } : { id: maintenanceId, name: 'maintenanceId' }
    );
    this.performedByFullName = new SimpleField(performedByFullName);
    this.serviceType = new ServiceTypeItem(serviceType || {});
    this.loggerInfo = new MaintenanceLoggerInfo({
      creationTime,
      createdBy,
      lastUpdateTime,
      updatedBy,
    });
  }

  save = async () => {
    await this.repositoryHistory.create(this.data);

    if (this.repositoryHistory.createState.success) {
      this.context.onUpdate();
    }
  };

  delete = async () => {
    await this.repositoryModel.delete();

    if (this.repositoryModel.deleteState.success) {
      EventsBus.get().trigger(APP_EVENTS.MAINTENANCE.COMPLETED.DELETED);
      this.context.onUpdate();
    }
  };

  update = async () => {
    const record = await this.repositoryModel.patch({ ...this.data, name: this.info.taskName.value });

    if (this.repositoryModel.patchState.success) {
      this.context.resetHistoryRecord(this.id, record);
      EventsBus.get().trigger(APP_EVENTS.MAINTENANCE.COMPLETED.UPDATED);
      this.context.onUpdate();
    }
  };

  reset = () => {
    this.info.taskName.reset();
    this.values.reset();
    this.performedByFullName.reset();
    this.documents.reset();
    this.costs.reset();
    this.notes.reset();
  };

  get isUpdated() {
    return (
      this.info.taskName.isUpdated ||
      this.values.isUpdated ||
      this.performedByFullName.isUpdated ||
      this.costs.isUpdated ||
      this.notes.isUpdated ||
      this.documents.isUpdated.value
    );
  }

  get isLatestRecord() {
    return this.id === this.context.historyRecords[0]?.id;
  }

  get data() {
    return {
      ...this.costs.data,
      maintenanceId: this.context.id.value,
      notes: this.notes.value,
      values: this.values.model,
      maintenanceDate: this.maintenanceDate.value,
      maintenance: this.maintenance.model,
      performedByPosition: this.performedByPosition.value,
      documentIds: this.documents.model.map((document) => document.id),
      performedByFullName: this.performedByFullName.value,
    };
  }

  get serverData() {
    return {
      ...this.costs.data,
      id: this.id,
      name: this.info.taskName.value,
      documents: this.documents.model,
      notes: this.notes.value,
      values: this.values.model,
      maintenanceId: this.maintenanceId,
      maintenanceDate: this.maintenanceDate.value,
      performedByPosition: this.performedByPosition.value,
      performedByFullName: this.performedByFullName.value,
      serviceType: this.serviceType.model,
      ...this.loggerInfo,
    };
  }
}

export default MaintenanceCompleteRecordModel;
