import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import noop from 'lodash/noop';
import type { ColumnsType } from 'antd/es/table';

import {
  ADMIN_VEHICLES_TABLE_COLUMNS,
  PATHS,
  VEHICLE_DRIVER_NAME_READ,
  VEHICLE_DRIVER_NAME_UPDATE,
  STORAGE_ITEMS,
  WEB_MANAGEMENT_VEHICLE_FUEL_DETAILS_READ,
  LINKS,
} from 'config';
import { setJSONItemToStorage, getJSONItemFromStorage } from 'utils';
import type { VehiclesAdmin, ITableVehicle } from 'stores/Admin/Vehicles';
import validateAccessLevel from 'stores/acl/validator';
import type { TimeStore } from 'stores';

import AdminContentWrapper from 'components/Admin/ContentWrapper';
import Button from 'components/Button';
import CustomTable from 'components/Table/CustomTable';
import TableDownloadModal from 'components/Table/TableDownloadModal';
import TableEmailModal from 'components/Table/TableEmailModal';
import VehiclesColumnActions from 'components/Admin/VehiclesActions';
import VehiclesGroups from 'components/Admin/VehiclesGroups';

import './styles.scss';

interface IProps {
  className?: string;
  vehiclesAdmin?: VehiclesAdmin;
  timeStore?: TimeStore;
}

interface IState {
  isFilterOpen: boolean;
  isDownloadModalOpen: boolean;
  isEmailModalOpen: boolean;
}

const editColumnsMap = {
  [ADMIN_VEHICLES_TABLE_COLUMNS.NAME]: {
    text: 'Vehicle Name',
    isDisabled: true,
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.VIN]: {
    text: 'VIN',
    isDisabled: false,
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.YEAR]: {
    text: 'Year',
    isDisabled: false,
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.MAKE]: {
    text: 'Make',
    isDisabled: false,
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.MODEL]: {
    text: 'Model',
    isDisabled: false,
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.PLATE]: {
    text: 'Vehicle Plate Number',
    isDisabled: false,
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.DRIVER_DESCRIPTION]: {
    text: 'Driver Name',
    isDisabled: false,
    isVisible: () => validateAccessLevel([VEHICLE_DRIVER_NAME_READ, VEHICLE_DRIVER_NAME_UPDATE]),
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.ODOMETER]: {
    text: 'Current Odometer',
    isDisabled: false,
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.GROUP]: {
    text: 'Group Membership',
    isDisabled: false,
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.VEHICLE_ID]: {
    text: 'CP# (Vehicle ID)',
    isDisabled: false,
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.SUSPENDED]: {
    text: 'Active/Suspended',
    isDisabled: false,
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.LAST_COMMUNICATION]: {
    text: 'Last Communication',
    isDisabled: false,
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.UNIQUE_ID]: {
    text: 'IMEI/ESN',
    isDisabled: false,
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.DESCRIPTION]: {
    text: 'Description',
    isDisabled: false,
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.EQUIPMENT_TYPE]: {
    text: 'Equipment Type',
    isDisabled: false,
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.IGNITION_STATE]: {
    text: 'Ignition State',
    isDisabled: false,
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.DEVICE_TYPE]: {
    text: 'Device Type',
    isDisabled: false,
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.COORDINATES]: {
    text: 'Latitude/Longitude',
    isDisabled: false,
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.MPG]: {
    text: 'MPG',
    isDisabled: false,
    isVisible: () => validateAccessLevel([WEB_MANAGEMENT_VEHICLE_FUEL_DETAILS_READ]),
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.UPDATED_BY]: {
    text: 'Modified By',
    isDisabled: false,
  },
  [ADMIN_VEHICLES_TABLE_COLUMNS.LAST_UPDATE_TIME]: {
    text: 'Last Modified',
    isDisabled: false,
  },
};

@inject(({ adminStore: { vehiclesAdmin }, timeStore }) => ({
  vehiclesAdmin,
  timeStore,
}))
@observer
class AdminVehiclesTable extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isFilterOpen: getJSONItemFromStorage(STORAGE_ITEMS.admin.filterOpenStatus, true),
      isDownloadModalOpen: false,
      isEmailModalOpen: false,
    };
  }

  getColumns = (): ColumnsType<ITableVehicle> => {
    const {
      vehiclesAdmin: {
        columns,
        sortedColumn: { field, order },
      },
    } = this.props;

    const allColumns = [
      {
        title: 'Vehicle Name',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.NAME,
        defaultSortOrder: field === 'name' ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        render: (_, { id, name }) => <Link to={PATHS.ADMIN.TABLES.VEHICLES.VEHICLE.replace(':id', id)}>{name}</Link>,
        fixed: true,
        width: 180,
      },
      {
        title: 'VIN',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.VIN,
        defaultSortOrder: field === 'vin' ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Year',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.YEAR,
        hiddenSearch: true,
        width: 120,
      },
      {
        title: 'Make',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.MAKE,
        defaultSortOrder: field === 'make' ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Model',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.MODEL,
        defaultSortOrder: field === 'model' ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Plate',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.PLATE,
        defaultSortOrder: field === 'plate' ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Driver Name',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.DRIVER_DESCRIPTION,
        defaultSortOrder: field === 'driverName' ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
        isVisible: () => validateAccessLevel([VEHICLE_DRIVER_NAME_READ, VEHICLE_DRIVER_NAME_UPDATE]),
      },
      {
        title: 'Current Odometer',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.ODOMETER,
        defaultSortOrder: field === 'odometer' ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        hiddenSearch: true,
        width: 180,
      },
      {
        title: 'Group',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.GROUP,
        render: (_, { groups }) => <VehiclesGroups groups={groups} />,
        width: 180,
        className: 'ant-table-cell--no-sorter',
      },
      {
        title: 'CP #',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.VEHICLE_ID,
        defaultSortOrder: field === 'id' ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Active/Sus',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.SUSPENDED,
        defaultSortOrder: field === ADMIN_VEHICLES_TABLE_COLUMNS.SUSPENDED ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        render: (value) => {
          const cn = classNames('ant-table-cell-suspended-value', {
            'ant-table-cell-ignition-value--suspended': value,
            'ant-table-cell-ignition-value--active': !value,
          });

          return <span className={cn}>{value ? 'Suspended' : 'Active'}</span>;
        },
        width: 180,
        hiddenSearch: true,
      },
      {
        title: `Last comm (${this.props.timeStore.userTimezone})`,
        sorter: noop,
        defaultSortOrder: field === ADMIN_VEHICLES_TABLE_COLUMNS.LAST_COMMUNICATION ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.LAST_COMMUNICATION,
        width: 220,
        hiddenSearch: true,
      },
      {
        title: 'IMEI/ESN',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.UNIQUE_ID,
        defaultSortOrder: field === ADMIN_VEHICLES_TABLE_COLUMNS.UNIQUE_ID ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Description',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.DESCRIPTION,
        defaultSortOrder: field === ADMIN_VEHICLES_TABLE_COLUMNS.DESCRIPTION ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Equipment Type',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.EQUIPMENT_TYPE,
        defaultSortOrder: field === ADMIN_VEHICLES_TABLE_COLUMNS.EQUIPMENT_TYPE ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Ignition',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.IGNITION_STATE,
        defaultSortOrder: field === ADMIN_VEHICLES_TABLE_COLUMNS.IGNITION_STATE ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
        hiddenSearch: true,
        render: (value) => {
          const lowerCaseValue = value.toLowerCase();
          const isOn = lowerCaseValue === 'on';
          const isOff = lowerCaseValue === 'off';
          const cn = classNames('ant-table-cell-ignition-value', {
            'ant-table-cell-ignition-value--on': isOn,
            'ant-table-cell-ignition-value--off': isOff,
            'ant-table-cell-ignition-value--unknown': !isOn && !isOff,
          });

          return <span className={cn}>{lowerCaseValue}</span>;
        },
      },
      {
        title: 'Device Type',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.DEVICE_TYPE,
        defaultSortOrder: field === ADMIN_VEHICLES_TABLE_COLUMNS.DEVICE_TYPE ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Lat/Long',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.COORDINATES,
        render: (_, { coordinates: { latitude, longitude } }) => (
          <Fragment>
            {latitude} / {longitude}
          </Fragment>
        ),
        width: 180,
        hiddenSearch: true,
        className: 'ant-table-cell--no-filter',
      },
      {
        title: 'MPG',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.MPG,
        width: 180,
        hiddenSearch: true,
        className: 'ant-table-cell--no-filter',
        isVisible: () => validateAccessLevel([WEB_MANAGEMENT_VEHICLE_FUEL_DETAILS_READ]),
      },
      {
        title: 'Modified By',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.UPDATED_BY,
        width: 180,
        hiddenSearch: true,
        className: 'ant-table-cell--no-filter',
      },
      {
        title: 'Last Modified',
        dataIndex: ADMIN_VEHICLES_TABLE_COLUMNS.LAST_UPDATE_TIME,
        width: 180,
        hiddenSearch: true,
        className: 'ant-table-cell--no-filter',
      },
    ];

    const filteredColumns = [];

    columns.forEach((column) => {
      if (column.isSelected) {
        filteredColumns.push(allColumns.find((col) => col.dataIndex === column.value));
      }
    });

    return [
      ...filteredColumns.filter((col) => !col?.isVisible || col?.isVisible()),
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: (_, { id, lastCommunication }) => (
          <VehiclesColumnActions vehicleId={id} hasEvents={Boolean(lastCommunication) && lastCommunication !== '-'} />
        ),
        width: 180,
        className: 'ant-table-cell--actions',
        hiddenSearch: true,
      },
    ];
  };

  handleOpenFilter = (isFilterOpen: boolean) => {
    this.setState({ isFilterOpen });
    setJSONItemToStorage(STORAGE_ITEMS.admin.filterOpenStatus, isFilterOpen);
  };

  closeDownloadModal = () => {
    this.setState({ isDownloadModalOpen: false });
  };

  openDownloadModal = () => {
    this.setState({ isDownloadModalOpen: true });
  };

  closeEmailModal = () => {
    this.setState({ isEmailModalOpen: false });
  };

  openEmailModal = () => {
    this.setState({ isEmailModalOpen: true });
  };

  openMoreTrackersTab = () => {
    window.open(LINKS.buyMoreTrackers, '_blank');
  };

  render() {
    const {
      className,
      vehiclesAdmin: {
        columns,
        getAllVehicles,
        repositoryVehicles: { getState },
        pagination,
        searchColumns,
        searchData,
        setColumns,
        setPagination,
        setSearchColumn,
        setSortedColumn,
        sortData,
        vehiclesListTotal,
        vehiclesTableSource,
        downloadVehicles,
      },
    } = this.props;
    const { isFilterOpen, isEmailModalOpen, isDownloadModalOpen } = this.state;
    const cn = classNames('AdminVehiclesTable', className, { 'AdminVehiclesTable--withOpenFilters': isFilterOpen });
    const tableHeight = isFilterOpen ? 'calc(100vh - 280px)' : 'calc(100vh - 251px)';

    return (
      <AdminContentWrapper
        className="AdminContentWrapper--vehiclesTable"
        message="See all vehicles in your fleet. Edit vehicle details like name, make, model, as well as managing the vehicle’s maintenance."
        title="Vehicle Admin"
      >
        {
          <div className={cn}>
            <div className="AdminVehiclesTable-orderTrackers">
              <Button title="Order More Trackers" className="Button--apply" inline onClick={this.openMoreTrackersTab} />
            </div>
            <div className="AdminVehiclesTable-table">
              <CustomTable<ADMIN_VEHICLES_TABLE_COLUMNS, ITableVehicle>
                columns={this.getColumns()}
                dataSource={vehiclesTableSource}
                editColumnsMap={editColumnsMap}
                getData={getAllVehicles}
                isFilterActive={isFilterOpen}
                loading={getState.loading}
                onColumnsChange={setColumns}
                onColumnSort={setSortedColumn}
                onColumnsSearch={setSearchColumn}
                onFilterOpen={this.handleOpenFilter}
                onPaginationChange={setPagination}
                pagination={pagination}
                searchColumns={searchColumns}
                searchParams={searchData}
                selectedColumns={toJS(columns)}
                sortedColumn={sortData}
                tableHeight={tableHeight}
                total={vehiclesListTotal}
                totalTitle={`${vehiclesListTotal ? vehiclesListTotal : ''} Vehicles`}
                withActions
                withDownload
                withEmail
                openDownloadModal={this.openDownloadModal}
                openEmailModal={this.openEmailModal}
                columnGroup={ADMIN_VEHICLES_TABLE_COLUMNS.GROUP}
                columnCoordinates={ADMIN_VEHICLES_TABLE_COLUMNS.COORDINATES}
              />
            </div>
            <TableDownloadModal<VehiclesAdmin>
              isOpen={isDownloadModalOpen}
              onClose={this.closeDownloadModal}
              tableDownload={downloadVehicles}
            />
            <TableEmailModal<VehiclesAdmin>
              isOpen={isEmailModalOpen}
              onClose={this.closeEmailModal}
              tableDownload={downloadVehicles}
            />
          </div>
        }
      </AdminContentWrapper>
    );
  }
}

export default AdminVehiclesTable;
