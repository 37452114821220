import { observable, action } from 'mobx';
import DevicesAttributesFilter from 'models/Devices/Filters/DevicesAttributesFilter';

import FiltersBase from '../FiltersBase';

export interface IEventStatuses {
  [key: string]: boolean;
}

interface IDate {
  from: number;
  to: number;
}

export default class DevicesFilters extends FiltersBase {
  static get initialDate(): IDate {
    return {
      from: null,
      to: null,
    };
  }

  @observable groupsQuery: string = '';
  @observable tempDate: IDate = { from: null, to: null };
  @observable latestTempDate: IDate = { from: null, to: null };
  attributes: DevicesAttributesFilter;

  constructor(date?: Filters.IDateFilterOption) {
    super(date);

    this.attributes = new DevicesAttributesFilter();
  }

  @action setGroupsQuery = (query: string) => (this.groupsQuery = query);

  @action applyTempDate = (date: IDate) => {
    this.latestTempDate = date;
    this.tempDate = date;
  };

  @action resetTempDate = (date?: IDate) => {
    this.tempDate = date ? date : DevicesFilters.initialDate;
  };

  @action resetDevicesFilters = (date?: IDate) => {
    this.reset();
    this.resetTempDate(date);
    this.groupsQuery = '';
  };
}
