export interface IAlertsSearchParams {
  type?: string;
  displayName?: string;
  recipients?: string;
  cronOption?: string;
}

export enum AlertsSearchMap {
  displayName = 'displayName',
  type = 'type',
  recipients = 'recipients',
  cronOption = 'cronOption',
}
