import React, { FC, Fragment } from 'react';
import classNames from 'classnames';

import type { Vehicle } from 'models';

interface IProps {
  list: Vehicle[];
  onChange: (value: Vehicle) => void;
  selectedItemId: string;
}

const SearchVehiclesList: FC<IProps> = ({ list, onChange, selectedItemId }) => {
  return (
    <Fragment>
      {list.map((item) => {
        const { id, name } = item;
        const itemCn = classNames('SearchList-item', {
          'SearchList-item--active': id === selectedItemId,
        });

        return (
          <li key={id} className={itemCn} onClick={() => onChange(item)}>
            <span className="SearchList-itemText">{name || id}</span>
          </li>
        );
      })}
    </Fragment>
  );
};

export default SearchVehiclesList;
