import { computed, observable } from 'mobx';
import type { ALERTS_TYPE } from 'config';
import { formatPhoneNumber } from 'utils';
import isBoolean from 'lodash/isBoolean';
import type { IAlert, IServerAlert } from './IAlert';
import AlertIDModel from './AlertID';
import AlertMetadata from './AlertMetadata';

import type { AlertsTableStore } from 'stores';
import { ListField, SimpleField, ToggleField, UniqueField } from 'models/Fields';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

class AlertModel implements IAlert {
  @observable displayName: UniqueField;
  @observable id: string;
  @observable type: SimpleField<ALERTS_TYPE>;
  @observable isActive: ToggleField;
  @observable isLegacy: ToggleField;
  @observable metadata: AlertMetadata;
  @observable notifyEmails: ListField<string>;
  @observable notifyMobileNumbers: ListField<string>;
  @observable createdBy: SimpleField<string>;
  @observable updatedBy: SimpleField<string>;
  @observable creationTime: SimpleField<number>;
  @observable lastUpdateTime: SimpleField<number>;
  @observable cronOption: AlertIDModel<string>;
  private context: AlertsTableStore;
  urlType: string;
  description: string = null;
  title: string = null;
  repositoryType: IEntityRepository;
  repositoryTypeAlert: IEntityRepository;
  repositoryAlert: IEntityRepository;
  repositoryCustom: IEntityRepository;

  constructor(item) {
    this.setModel(item);
  }

  initialize(context: AlertsTableStore) {
    this.context = context;
    this.repositoryType = this.context?.repository.entity(this.urlType);
    this.repositoryTypeAlert = this.repositoryType?.entity(this.id);
    this.repositoryAlert = this.context?.repository.entity(this.id);
    this.repositoryCustom = this.context?.repository.entity(this.id).entity('convert-to-custom');

    this.repositoryType?.listenTo('error:create', () => this.context?.onError());
    this.repositoryTypeAlert?.listenTo('error:patch', () => this.context?.onError());
    this.repositoryAlert?.listenTo('error:delete', () => this.context?.isErrorDelete.toggle(true));
    this.repositoryCustom?.listenTo('error:patch', () => this.context?.onError());

    return this;
  }

  setModel = (item) => {
    this.displayName = new UniqueField(item.displayName || '', 'alerts', true, true);
    this.metadata = new AlertMetadata(
      item.metadata || { asset: null, assetGroup: null, device: null, appliesTo: item.appliesTo || null }
    );
    this.id = item.id;
    this.type = new SimpleField(item.type);
    this.isActive = new ToggleField(isBoolean(item.isActive) ? item.isActive : true);
    this.isLegacy = new ToggleField(item.isLegacy);

    this.notifyEmails = new ListField<string>(item.notifyEmails && item.notifyEmails.length ? item.notifyEmails : ['']);
    this.notifyMobileNumbers = new ListField<string>(this.getMobileNumbersList(item.notifyMobileNumbers));
    this.createdBy = new SimpleField(item.createdBy || '');
    this.updatedBy = new SimpleField(item.updatedBy || '');
    this.creationTime = new SimpleField(item.creationTime || 0);
    this.lastUpdateTime = new SimpleField(item.lastUpdateTime || 0);

    this.cronOption = new AlertIDModel(null);
  };

  @computed get isCharacterLimitReached() {
    let totalCharacter = this.notifyEmails.value.reduce(
      (previousValue, currentItem) => previousValue + currentItem.value.length + 1,
      0
    );

    totalCharacter += this.notifyMobileNumbers.value.length * 16;

    return totalCharacter >= 496;
  }

  get model(): IServerAlert {
    return {
      displayName: this.displayName.value,
      metadata: this.metadata.model,
      id: this.id,
      type: this.type.value,
      isActive: this.isActive.value,
      isLegacy: this.isLegacy.value,
      notifyEmails: this.notifyEmails.toArray(),
      notifyMobileNumbers: this.notifyMobileNumbers.toArray().map((phone) => {
        return '1' + phone.replace(/[^0-9]/gi, '');
      }),
      createdBy: this.createdBy.value,
      updatedBy: this.updatedBy.value,
      creationTime: this.creationTime.value,
      lastUpdateTime: this.lastUpdateTime.value,
    };
  }

  isUpdated(): boolean {
    return (
      this.displayName.isUpdated ||
      this.metadata.asset.id.isUpdated ||
      this.metadata.assetGroup.id.isUpdated ||
      this.type.isUpdated ||
      this.isActive.isUpdated ||
      this.notifyEmails.isUpdated ||
      this.notifyMobileNumbers.isUpdated
    );
  }

  create = async () => {
    await this.repositoryType.create(this.model);

    if (this.repositoryType.createState.success) {
      this.context.onCreateAlert();
    }
  };

  update = async () => {
    await this.repositoryTypeAlert.patch(this.model);

    if (this.repositoryTypeAlert.patchState.success) {
      this.context.onUpdateAlert();
    }
  };

  destroy = async () => {
    await this.repositoryAlert.delete();

    if (this.repositoryAlert.deleteState.success) {
      this.context.onDestroyAlert();
    }
  };

  convertToCustom = async () => {
    await this.repositoryCustom.patch();
    this.context.onUpdateAlert();
  };

  private getMobileNumbersList = (list) => {
    return list && list.length
      ? list.map((phone) => formatPhoneNumber(phone?.length > 10 ? phone.replace(/^1/, '') : phone))
      : [''];
  };
}

export default AlertModel;
