import EventsBus from 'services/EventsBus/eventsBus';
import isBoolean from 'lodash/isBoolean';
import { SimpleField, ToggleField } from '../Fields';
import { APP_EVENTS } from 'services/EventsBus/appEvents';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';
import AssetTrackerDetails from './AssetTrackerDetails';

class Asset {
  cpNumber: SimpleField<string>;
  id: SimpleField<string>;
  name: SimpleField<string>;
  selected: ToggleField;
  repository: IEntityRepository;
  trackerDetails: AssetTrackerDetails;

  constructor({
    cpNumber = '',
    id = null,
    name = '',
    selected = false,
    odometer = 0,
    engHours = 0,
    avgEngineHoursPerDay = 0,
    avgKmPerDay = 0,
  }) {
    this.cpNumber = new SimpleField(cpNumber);
    this.id = new SimpleField(id);
    this.name = new SimpleField<string>(name);
    this.selected = new ToggleField(selected);
    this.repository = repositoryService.get('assets').entity(id);
    this.trackerDetails = new AssetTrackerDetails(
      this.repository,
      {
        odometer,
        engHours,
        avgEngineHoursPerDay,
        avgKmPerDay,
      },
      selected
    );
  }

  toggle = async (value?: boolean, silent: boolean = true) => {
    this.selected.toggle(isBoolean(value) ? value : !this.selected.value);

    if (this.selected.value) {
      EventsBus.get().trigger(APP_EVENTS.MAINTENANCE.APPLY_TASKS.TASK.LOADING);

      await this.trackerDetails.fetch();

      EventsBus.get().trigger(APP_EVENTS.MAINTENANCE.APPLY_TASKS.ADDED, {
        ...this.data,
        ...this.trackerDetails.data,
      });

      if (!silent) {
        EventsBus.get().trigger(APP_EVENTS.MAINTENANCE.APPLY_TASKS.SORT);
      }
    } else {
      EventsBus.get().trigger(APP_EVENTS.MAINTENANCE.APPLY_TASKS.REMOVED, this.data);
    }
  };

  get data() {
    return {
      name: this.name.value,
      id: this.id.value,
      cpNumber: this.cpNumber.value,
    };
  }
}

export default Asset;
