import React from 'react';
import { Observer } from 'mobx-react';

import type { ToggleField } from 'models/Fields';

import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';

import './styles.scss';

interface IProps {
  title: string;
  value: string;
  isEdit: ToggleField;
  onChange?: () => void;
  disabled?: boolean;
}

const MaintenanceCompletedTaskPanelSummarySelect: React.FC<IProps> = ({ title, value, isEdit, disabled = false }) => {
  return (
    <Observer
      render={() => (
        <Observer
          render={() => (
            <div className="MaintenanceCompletedTaskPanelContent-SummaryLine">
              <div className="MaintenanceCompletedTaskPanelContent-SummaryLineLabel">{title}</div>
              <div className="MaintenanceCompletedTaskPanelContent-SummaryLineValue">
                {isEdit.value ? (
                  <div className="MaintenanceCompletedTaskPanelContent-SummaryLineValueSelect">
                    <SearchableStaticSelect
                      value={{ value, label: value }}
                      disabled={disabled}
                      values={[{ value, label: value }]}
                    />
                  </div>
                ) : (
                  <div>{value ? value : '-'}</div>
                )}
              </div>
            </div>
          )}
        />
      )}
    />
  );
};

export default MaintenanceCompletedTaskPanelSummarySelect;
