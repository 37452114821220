import React from 'react';
import classNames from 'classnames';

import Carousel from 'components/Carousel';
import Arrow from './Arrow';
import Dot from './Dot';
import SlideOne from './SlideOne';
import SlideTwo from './SlideTwo';
import SlideThree from './SlideThree';

import './styles.scss';

interface IProps {
  className?: string;
}

const LoginCarousel: React.FC<IProps> = ({ className }) => {
  const cn = classNames('LoginCarousel', className);
  const carouselSettings = {
    appendDots: (dots) => (
      <div style={{ marginBottom: '50px' }}>
        <ul className="CarouselDots"> {dots} </ul>
      </div>
    ),
    autoplay: true,
    autoplaySpeed: 5000,
    customPaging: () => {
      return <Dot />;
    },
    dots: true,
    fade: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 1000,
    nextArrow: <Arrow direction="right" />,
    prevArrow: <Arrow direction="left" />,
  };
  return (
    <div className={cn}>
      <Carousel settings={carouselSettings} className="Carousel--login">
        <div className="Carousel-slide">
          <SlideOne />
        </div>
        <div className="Carousel-slide">
          <SlideTwo />
        </div>
        <div className="Carousel-slide">
          <SlideThree />
        </div>
      </Carousel>
    </div>
  );
};

export default LoginCarousel;
