import React from 'react';
import classNames from 'classnames';

import LoginSlide from '../SlideWrapper';

import './styles.scss';

interface IProps {
  className?: string;
}

const LoginSlideThree: React.FC<IProps> = ({ className }) => {
  const cn = classNames('LoginSlideThree', className);

  return (
    <div className={cn}>
      <LoginSlide className="LoginSlide--slideThree">
        <p className="LoginSlide-title">Are You Using Our Latest Mobile App?</p>
        <p className="LoginSlide-message">Our updated app provides access to more features, plus is easier to use!</p>
        <a
          className="LoginSlide-link"
          href="https://support.clearpathgps.com/hc/en-us/articles/360035913691-Mobile-Manager-App-Quick-Guide"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
      </LoginSlide>
    </div>
  );
};

export default LoginSlideThree;
