import React, { Component } from 'react';
import classNames from 'classnames';
import { Col, Row } from 'antd';
import { inject, observer } from 'mobx-react';

import * as notification from 'utils/notification';
import validateAccessLevel from 'stores/acl/validator';
import { ADMIN_VEHICLE_FAULT_CODE_READ, ADMIN_VEHICLE_FAULT_CODE_UPDATE, ADMIN_VEHICLES_UPDATE, ACL } from 'config';
import { TimeStore, UserAccessLevelComponent } from 'stores';
import type { VehiclesAdmin } from 'stores/Admin/Vehicles';
import { formatDecimalNumber } from 'utils';

import Button from 'components/Button'; /**/
import Maintenance from 'containers/Maintenance/Tables';
import Note from 'components/Note';
import ResetMetricsModal from 'components/Admin/ResetMetricsModal';
import UpdateLastModal from 'components/Admin/UpdateLastModal';

import { CautionIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  vehiclesAdmin?: VehiclesAdmin;
  timeStore?: TimeStore;
  onChange?: (field: { name: string; value: string | number }) => void;
}

interface IState {
  isOdometerModalOpen: boolean;
  isEngHoursModalOpen: boolean;
  notificationShown: boolean;
  resetAduModalOpen: boolean;
}

@inject(({ timeStore, adminStore: { vehiclesAdmin } }) => ({
  vehiclesAdmin,
  timeStore,
}))
@observer
class VehicleMaintenance extends Component<IProps, IState> {
  state = {
    isOdometerModalOpen: false,
    isEngHoursModalOpen: false,
    notificationShown: false,
    resetAduModalOpen: false,
  };

  handleOdometerModalOpen = () => this.setState({ isOdometerModalOpen: true });
  handleOdometerModalClose = () => this.setState({ isOdometerModalOpen: false });
  handleEngHoursModalOpen = () => this.setState({ isEngHoursModalOpen: true });
  handleEngHoursModalClose = () => this.setState({ isEngHoursModalOpen: false });

  handleModifyChangeOdometerSubmit = async (value) => {
    await this.props.vehiclesAdmin.selectedVehicle.updateOdometer(value);
    this.handleOdometerModalClose();
  };
  handleModifyChangeEngHoursSubmit = async (value) => {
    await this.props.vehiclesAdmin.selectedVehicle.updateEngineHours(value);
    this.handleEngHoursModalClose();
  };

  renderFaultCode = (code, key) => {
    return (
      <div key={key} className="VehicleMaintenance-codeContainer">
        <div className="VehicleMaintenance-codeIcon">
          <CautionIcon fill="#FFC107" />
        </div>
        <div className="VehicleMaintenance-code">
          <span className="VehicleMaintenance-codeLabel">{code}</span>
        </div>
      </div>
    );
  };

  componentWillUnmount(): void {
    notification.close('VehicleMaintenance');
  }

  handleNoteChange = (note) => {
    const { onChange } = this.props;
    const {
      vehiclesAdmin: {
        selectedVehicle: { updateMaintenanceNotes },
      },
    } = this.props;

    updateMaintenanceNotes(note);
    onChange({ name: 'maintenanceNotes', value: note });
  };

  openResetAduModal = () => this.setState({ resetAduModalOpen: true });

  closeResetAduModal = () => this.setState({ resetAduModalOpen: false });

  cancelResetAdu = () => {
    const {
      vehiclesAdmin: {
        selectedVehicle: { resetAduTime },
      },
    } = this.props;

    resetAduTime.reset();
    this.closeResetAduModal();
  };

  resetAdu = async () => {
    const {
      vehiclesAdmin: {
        selectedVehicle: { resetAdu },
      },
    } = this.props;

    await resetAdu();
    this.closeResetAduModal();
  };

  render() {
    const {
      vehiclesAdmin: {
        selectedVehicle: {
          assetId,
          name,
          diagnosticFaultCodesList,
          maintenanceNotes,
          odometer,
          engineHours,
          clearDiagnosticFaultCodes,
          avgEngineHoursPerDay,
          avgMileagePerDay,
          resetAduTime,
        },
      },
    } = this.props;

    const { isOdometerModalOpen, isEngHoursModalOpen, resetAduModalOpen } = this.state;
    const isEditable = validateAccessLevel([ADMIN_VEHICLES_UPDATE]);
    const faultCodes = validateAccessLevel([ADMIN_VEHICLE_FAULT_CODE_UPDATE, ADMIN_VEHICLE_FAULT_CODE_READ]);

    const faultCodesEdit = validateAccessLevel([ADMIN_VEHICLE_FAULT_CODE_UPDATE]);
    const faultCodesContainerCN = classNames('VehicleMaintenance-faultCodesContainer', {
      'VehicleMaintenance-faultCodesContainer--empty': !diagnosticFaultCodesList.length,
    });

    return (
      <div className="VehicleMaintenance">
        <div className="VehicleMaintenance-container">
          <Row>
            <Col className="VehicleMaintenance-leftCol" span={16}>
              <div className="VehicleMaintenance-tables" key={assetId}>
                <Maintenance assetId={assetId} assetName={name} />
              </div>
            </Col>
            <Col className="VehicleMaintenance-rightCol" span={8}>
              <div className="VehicleMaintenance-dataRow">
                <p className="VehicleMaintenance-label">Vehicle Metrics</p>
                <div className="VehicleMaintenance-dataRowContent VehicleMaintenance-dataRowContent--metrics">
                  <div className="VehicleMaintenance-usage">
                    <div className="VehicleMaintenance-usageLabel">Reported Odometer</div>
                    <div className="VehicleMaintenance-usageRow">
                      <div className="VehicleMaintenance-usageValue">{formatDecimalNumber(odometer)} mi</div>
                      {isEditable && (
                        <div
                          className="VehicleMaintenance-link VehicleMaintenance-updateOdometed"
                          onClick={this.handleOdometerModalOpen}
                        >
                          Update
                        </div>
                      )}
                    </div>
                    <UpdateLastModal
                      isOpen={isOdometerModalOpen}
                      actualLabel={'actual total odometer'}
                      lastPerformed={formatDecimalNumber(odometer)}
                      lastPerformedLabel={'reported Odometer'}
                      onCancel={this.handleOdometerModalClose}
                      onSubmit={this.handleModifyChangeOdometerSubmit}
                      title={`Update Odometer`}
                      units={'mi'}
                    />
                  </div>
                  <div className="VehicleMaintenance-usage">
                    <div className="VehicleMaintenance-usageLabel">Reported Engine Hours</div>
                    <div className="VehicleMaintenance-usageRow">
                      <div className="VehicleMaintenance-usageValue">{formatDecimalNumber(engineHours, 2)} hrs</div>
                      {isEditable && (
                        <div
                          className="VehicleMaintenance-link VehicleMaintenance-updateEngHours"
                          onClick={this.handleEngHoursModalOpen}
                        >
                          Update
                        </div>
                      )}
                    </div>
                    <UpdateLastModal
                      isOpen={isEngHoursModalOpen}
                      actualLabel={'actual Total Engine Hours'}
                      lastPerformed={formatDecimalNumber(engineHours, 2)}
                      lastPerformedLabel={'reported Engine Hours'}
                      onCancel={this.handleEngHoursModalClose}
                      onSubmit={this.handleModifyChangeEngHoursSubmit}
                      title={`Update Engine Hours`}
                      units={'hrs'}
                    />
                  </div>
                </div>
                <div className="VehicleMaintenance-dataRowContent VehicleMaintenance-dataRowContent--average">
                  <div className="VehicleMaintenance-usage">
                    <div className="VehicleMaintenance-usageLabel">Average Daily Usage</div>
                    <div className="VehicleMaintenance-usageRow">
                      <div className="VehicleMaintenance-usageValue">{avgMileagePerDay.value} mi</div>
                      <UserAccessLevelComponent requiredAccessLevel={[ACL.MANAGEMENT.RESET_ADU.DELETE]}>
                        <div className="VehicleMaintenance-aduReset">
                          <Button className="Button--link" title="Reset" onClick={this.openResetAduModal} />
                        </div>
                      </UserAccessLevelComponent>
                    </div>
                  </div>
                  <div className="VehicleMaintenance-usage VehicleMaintenance-usage--hours">
                    <div className="VehicleMaintenance-usageRow">
                      <div className="VehicleMaintenance-usageValue">{avgEngineHoursPerDay.value} hrs</div>
                    </div>
                  </div>
                </div>
              </div>
              {faultCodes && (
                <div className="VehicleMaintenance-dataRow">
                  <p className="VehicleMaintenance-label">Last Active Trouble Codes</p>
                  <div className="VehicleMaintenance-dataRowContent VehicleMaintenance-faultCodes">
                    {faultCodesEdit && Boolean(diagnosticFaultCodesList.length) && (
                      <div
                        className={'VehicleMaintenance-link VehicleMaintenance-clearFaultCodes'}
                        onClick={clearDiagnosticFaultCodes}
                      >
                        Clear All
                      </div>
                    )}
                    <div className={faultCodesContainerCN}>
                      {diagnosticFaultCodesList.length
                        ? diagnosticFaultCodesList.map((code, index) => this.renderFaultCode(code, index))
                        : 'No Active Codes'}
                    </div>
                  </div>
                </div>
              )}
              <div className="VehicleMaintenance-dataRow">
                <div className="VehicleMaintenance-noteTitle">
                  <p className="VehicleMaintenance-label">General Maintenance note</p>
                </div>
                <div className="VehicleMaintenance-note">
                  <Note
                    countCharactersEntered
                    hideControls
                    isEditing
                    note={maintenanceNotes}
                    onChange={this.handleNoteChange}
                    placeholder="Note"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <ResetMetricsModal
          title="Select ADU calculation start date"
          onCancel={this.cancelResetAdu}
          onSubmit={this.resetAdu}
          isOpen={resetAduModalOpen}
          onChange={resetAduTime.set}
          value={resetAduTime.value}
        />
      </div>
    );
  }
}

export default VehicleMaintenance;
