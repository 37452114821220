import { observable, action } from 'mobx';

export default class MediaGroup {
  @observable assetGroupId: string;
  @observable description: string;
  @observable displayName: string;
  @observable id: string;

  constructor(
    { assetGroupId, description, displayName, id } = { assetGroupId: '', description: '', displayName: '', id: '' }
  ) {
    this.assetGroupId = assetGroupId?.toString();
    this.description = description;
    this.displayName = displayName;
    this.id = id;
  }

  @action set = (group: MediaGroup) => {
    this.assetGroupId = group.assetGroupId;
    this.description = group.description;
    this.displayName = group.displayName;
    this.id = group.id;
  };

  @action reset = () => {
    this.assetGroupId = null;
    this.description = '';
    this.displayName = '';
    this.id = '';
  };
}
