interface IWrapper {
  eventsBus: EventsBus;
  handler: (data: any) => void;
  callback?: (data: any) => void;
}

class EventsBus {
  private originalKey: string = 'original';
  private eventHandlers: any = {};
  private static instance: EventsBus;

  private constructor() {}

  public static get(): EventsBus {
    if (!EventsBus.instance) {
      EventsBus.instance = new EventsBus();
    }

    return EventsBus.instance;
  }

  public on(eventName: string, handler: any): void {
    let handlers = this.eventHandlers[eventName];

    if (!handlers) {
      handlers = [];
      this.eventHandlers[eventName] = handlers;
    }

    handlers.push(handler);
  }

  public once(eventName: string, handler: any): void {
    const wrapper: IWrapper = {
      eventsBus: this,
      handler,
    };

    wrapper.callback = (data) => {
      wrapper.handler(data);
      wrapper.eventsBus.off(eventName, wrapper.callback);
    };

    this.on(eventName, wrapper.callback);
  }

  public off(eventName: string, handler: any): void {
    const handlers = this.eventHandlers[eventName];

    if (!handlers) {
      return;
    }

    for (let i = 0; i < handlers.length; i++) {
      if (handlers[i] === handler) {
        handlers.splice(i--, 1);
      }
    }
  }

  public trigger(eventName: string, data?: any, context?: any): void {
    const handlers = this.eventHandlers[eventName];

    if (!handlers) {
      if (context && context.callback) {
        context.callback();
      }

      return;
    }

    data = data || {};
    context = context || { sender: this.originalKey };

    let root = null;
    let last = null;

    for (const handler of handlers) {
      const chain = {
        sender: context.sender,
        data,
        handler,

        callbackTimeout: null,
        suppressedNext: false,

        process() {
          this.handler.call(this, this.data);

          if (!this.suppressedNext) {
            this.next();
          }
        },

        next() {
          if (this.callbackTimeout) {
            clearTimeout(this.callbackTimeout);
          }

          if (this.nextChain) {
            this.nextChain.process();
          }
        },

        getCallback() {
          this.callbackTimeout = setTimeout(() => {
            this.next();
          }, 1000);

          this.suppressedNext = true;
          return this.next.bind(this);
        },

        nextChain: null,
      };

      if (!root) {
        root = chain;
      }

      if (last) {
        last.nextChain = chain;
      }

      last = chain;
    }

    if (root) {
      if (context.callback) {
        last.nextChain = {
          process: context.callback,
        };
      }

      root.process();
    } else {
      if (context.callback) {
        context.callback();
      }
    }
  }
}

export default EventsBus;
