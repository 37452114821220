import React from 'react';
import classNames from 'classnames';

import NavigationItem from './NavigationItem';
import NavigationImposterItem from './NavigationImposterItem';
import MaintenanceRequestsNotificationIcon from 'containers/Maintenance/Tables/MaintenanceRequests/MaintenanceRequestsNotification';
import {
  PATHS,
  FEATURES,
  REPORTS_PRIMARY_NAV,
  MANAGEMENT_PRIMARY_NAV,
  ALERTS_PRIMARY_NAV_ICON,
  WEB_DASHCAM_BASIC_READ,
  ACL,
} from 'config';
import validateAccessLevel from 'stores/acl/validator';

import {
  MediaNavIcon,
  DispatchIcon,
  AdminNavIcon,
  ReportsNavIcon,
  MapNavIcon,
  AlertNavIcon,
  WrenchIcon,
  InspectionIcon,
} from 'assets';

import './styles.scss';

interface IProps {
  className?: string;
  onClick?: () => void;
  pathname?: string;
  customerRole?: string;
}

interface ISideBarRouteItem {
  renderIcon: (className?: string) => JSX.Element;
  notificationIcon?: (pathname: string) => JSX.Element;
  title: string;
  to: string;
  imposterLink?: string;
  rule?: () => boolean;
}

const navigationItems: ISideBarRouteItem[] = [
  {
    renderIcon: (className?: string) => <MapNavIcon className={className} />,
    title: 'Map',
    to: PATHS.MAP.INDEX,
  },
  {
    renderIcon: (className?: string) => <ReportsNavIcon className={className} />,
    title: 'Reports',
    to: PATHS.REPORTS.INDEX,
    rule: () => FEATURES.reports && validateAccessLevel([REPORTS_PRIMARY_NAV]),
  },
  {
    renderIcon: (className?: string) => <MediaNavIcon className={className} />,
    title: 'Dashcam Media',
    to: PATHS.DASHCAMS.INDEX,
    rule: () => validateAccessLevel([WEB_DASHCAM_BASIC_READ]),
  },
  {
    renderIcon: (className?: string) => <WrenchIcon className={className} />,
    notificationIcon: (pathname: string) => <MaintenanceRequestsNotificationIcon pathname={pathname} />,
    title: 'Maintenance',
    to: PATHS.MAINTENANCE.INDEX,
    rule: () => validateAccessLevel([ACL.MAINTENANCE.BASIC.READ]),
  },
  {
    renderIcon: (className?: string) => <InspectionIcon className={className} />,
    title: 'Inspections',
    to: PATHS.INSPECTIONS.INDEX,
    rule: () => validateAccessLevel([ACL.INSPECTIONS.BASIC.READ]),
  },
  {
    renderIcon: (className?: string) => <AlertNavIcon className={className} />,
    title: 'Alerts',
    to: PATHS.ALERTS.INDEX,
    rule: () => validateAccessLevel([ALERTS_PRIMARY_NAV_ICON]) && FEATURES.alerts,
  },
  {
    renderIcon: (className?: string) => <DispatchIcon className={className} />,
    title: 'Dispatch',
    to: PATHS.DISPATCH.INDEX,
    rule: () => FEATURES.dispatch,
  },
  {
    renderIcon: (className?: string) => <AdminNavIcon className={className} />,
    title: 'Admin',
    to: PATHS.ADMIN.INDEX,
    rule: () => FEATURES.admin && validateAccessLevel([MANAGEMENT_PRIMARY_NAV]),
  },
];

const navigationImposterItems = [
  {
    renderIcon: (className?: string) => <MediaNavIcon className={className} />,
    title: 'Dashcam Media',
    imposterLink: 'https://hubs.la/Q01s6S_N0',
    rule: () => validateAccessLevel([WEB_DASHCAM_BASIC_READ]),
  },
];

const Navigation: React.FC<IProps> = ({ className, onClick, pathname, customerRole = '' }) => {
  const cn = classNames('Navigation', className);

  return (
    <div className={cn}>
      <ul className="Navigation-list">
        {navigationItems.map((item) => {
          const modifier = `${item.title.charAt(0).toLowerCase()}${item.title.slice(1).replace(/ /g, '')}`;
          const itemCn = classNames('Navigation-item', `Navigation-item--${modifier}`);
          const shouldBeRendered = !item.hasOwnProperty('rule') || item.rule();

          return shouldBeRendered ? (
            <div key={item.title} className={itemCn}>
              <NavigationItem item={item} onClick={onClick} pathname={pathname} />
            </div>
          ) : null;
        })}
        {navigationImposterItems.map((item) => {
          const shouldBeRendered = !item.hasOwnProperty('rule') || item.rule();
          const shouldBeRenderedImposter =
            item.imposterLink && customerRole && !customerRole.toLowerCase().includes('user');

          return (
            !shouldBeRendered &&
            shouldBeRenderedImposter && (
              <div key={item.title} className="Navigation-item">
                <NavigationImposterItem item={item} />
              </div>
            )
          );
        })}
      </ul>
    </div>
  );
};

export default Navigation;
