import React from 'react';
import classNames from 'classnames';

import AlertTile from '../../AlertTile';

import { FaultCodesIcon } from 'assets';

import './styles.scss';

interface IProps {
  isActive?: boolean;
  requiredPro?: boolean;
  onClick?: () => void;
  isProAcc?: boolean;
}

const AlertTileFaultCodes: React.FC<IProps> = ({ isActive, onClick, requiredPro, isProAcc }) => {
  const cn = classNames({ 'AlertTileFaultCodes-active': isActive && !requiredPro });

  const title = 'Fault Codes';
  const description = 'Alerts when any vehicle has a fault code';

  return (
    <AlertTile
      icon={<FaultCodesIcon className={cn} />}
      title={title}
      description={description}
      isActive={isActive}
      onClick={onClick}
      requiredPro={requiredPro}
      isProAcc={isProAcc}
    />
  );
};

export default AlertTileFaultCodes;
