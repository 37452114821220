import React, { useCallback } from 'react';
import { Radio } from 'antd';
import { Observer } from 'mobx-react';

import { ALERTS_APPLIES_TO } from 'config';
import type { SimpleField } from 'models/Fields';

import type AlertIDModel from 'models/Alerts/AlertID';

import SearchableVehicleSelect from 'containers/Select/SearchableVehicleSelect';
import SearchableGroupSelect from 'containers/Select/SearchableGroupSelect';
import SearchableDashcamSelect from 'containers/Select/SearchableDashcamSelect';

import './styles.scss';

interface IProps {
  deviceId: AlertIDModel<string>;
  groupId: AlertIDModel<string>;
  dashCamId?: AlertIDModel<string>;
  appliesTo: SimpleField<ALERTS_APPLIES_TO>;
}

const AlertPreferencesAppliesTo: React.FC<IProps> = ({ groupId, deviceId, appliesTo, dashCamId }) => {
  const handleChangeType = useCallback((e) => {
    const type = e.target.value;

    if (type === ALERTS_APPLIES_TO.VEHICLE) {
      groupId.id.set(null);
      groupId.name.set('');
    }

    if (type === ALERTS_APPLIES_TO.GROUP) {
      deviceId.id.set(null);
      deviceId.name.set('');
    }

    appliesTo.set(type);
  }, []);

  const handleChangeDeviceValue = useCallback((value, system: boolean = false) => {
    if (system && deviceId.id.value === null) {
      deviceId.id.reInitialize(value.value);
      deviceId.name.reInitialize(value.label);
    } else {
      deviceId.id.set(value.value);
      deviceId.name.set(value.label);
    }
  }, []);

  const handleChangeGroupValue = useCallback((value) => {
    groupId.id.set(value.value);
    groupId.name.set(value.label);
  }, []);

  const handleChangeDashCamValue = useCallback((value) => {
    dashCamId.id.set(value.value);
    dashCamId.name.set(value.label);
  }, []);

  return (
    <Observer
      render={() => (
        <div className="AlertPreference-line">
          <div className="AlertPreference-label">Applies to</div>
          <div className="AlertPreference-value AlertPreferencesAppliesTo-value">
            {(appliesTo.value === ALERTS_APPLIES_TO.VEHICLE || appliesTo.value === ALERTS_APPLIES_TO.GROUP) && (
              <Radio.Group
                onChange={handleChangeType}
                value={appliesTo.value}
                className="AlertPreferencesAppliesTo-switch"
              >
                <Radio.Button value={ALERTS_APPLIES_TO.VEHICLE} className="AlertPreferencesAppliesTo-switchButton">
                  Vehicle
                </Radio.Button>
                <Radio.Button value={ALERTS_APPLIES_TO.GROUP} className="AlertPreferencesAppliesTo-switchButton">
                  Group
                </Radio.Button>
              </Radio.Group>
            )}
            {appliesTo.value === ALERTS_APPLIES_TO.DASHCAM && dashCamId ? (
              <SearchableDashcamSelect
                withAll
                handleChange={handleChangeDashCamValue}
                value={{ value: dashCamId.id.value, label: dashCamId.name.value }}
              />
            ) : appliesTo.value === ALERTS_APPLIES_TO.VEHICLE ? (
              <SearchableVehicleSelect
                selectFirst
                handleChange={handleChangeDeviceValue}
                valueSource="assetId"
                value={{ value: deviceId.id.value, label: deviceId.name.value }}
              />
            ) : (
              <SearchableGroupSelect
                withAll
                valueSource="assetGroupId"
                handleChange={handleChangeGroupValue}
                value={{ value: groupId.id.value, label: groupId.name.value }}
              />
            )}
          </div>
        </div>
      )}
    />
  );
};

export default AlertPreferencesAppliesTo;
