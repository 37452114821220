import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import classNames from 'classnames';
import isNull from 'lodash/isNull';

import type { GeozonesAdmin } from 'stores/Admin/Geozones';
import type { MapStore } from 'stores';
import validateAccessLevel from 'stores/acl/validator';
import { ADMIN_GEOZONES_UPDATE } from 'config';

import GoogleMapSearch from 'containers/Map/GoogleMapSearch';
import DispatchMarker from 'components/Map/DispatchMarker';
import EditGeozonePath from '../EditGeozonePath';
import type { IChangeParams } from '../SelectedGeozone';
import CoordinatesInfoBox from 'components/Map/CoordinatesInfoBox';
import Geozone from 'components/Map/Geozone';
import Icon from 'components/Icon';
import InfoTooltip from 'components/InfoTooltip';
import CustomMap from 'components/Map';
import MapButton from 'components/Map/MapButton';
import RadioGroup from 'components/RadioGroup';
import Toggle from 'components/Toggle';

import { FocusAllGeozonesIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  onChange: <T extends IChangeParams>(field: T | T[]) => void;
  geozonesAdmin?: GeozonesAdmin;
  mapStore?: MapStore;
}

interface IState {
  cursorPosition: {
    lat: number;
    lng: number;
  };
  moveOverMap: boolean;
}

const mapTypes = [
  {
    id: 'roadmap',
    label: 'Map',
    name: 'admin-geozone-map-type',
    value: 'roadmap',
  },
  {
    id: 'hybrid',
    label: 'Hybrid',
    name: 'admin-geozone-map-type',
    value: 'hybrid',
  },
];

@inject(({ adminStore: { geozonesAdmin }, devicesMapStore: { mapStore } }) => ({
  geozonesAdmin,
  mapStore,
}))
@observer
class AdminGeozoneMap extends Component<IProps, IState> {
  private readonly map: React.RefObject<HTMLDivElement>;
  private catchIsMapAvailableDisposer: () => void;

  constructor(props: IProps) {
    super(props);

    this.map = React.createRef();
    this.state = {
      cursorPosition: {
        lat: null,
        lng: null,
      },
      moveOverMap: false,
    };
  }

  handleDrag = ({ latLng: { lat, lng } }) => {
    const {
      onChange,
      geozonesAdmin: {
        selectedGeozone: { setDispatchMarkerLatLng },
      },
    } = this.props;
    const latitude = lat();
    const longitude = lng();
    setDispatchMarkerLatLng({ lat: latitude, lng: longitude });
    onChange([
      { name: 'dispatchMarkerLatitude', value: latitude },
      { name: 'dispatchMarkerLongitude', value: longitude },
    ]);
  };

  renderDispatchMarker = () => {
    const {
      geozonesAdmin: { selectedGeozone },
    } = this.props;
    const isEditable = validateAccessLevel([ADMIN_GEOZONES_UPDATE]);

    if (selectedGeozone) {
      const { dispatchMarkerPosition, color, setHoveredDispatchMarker, hoveredMarkerIndex } = selectedGeozone;

      return (
        <DispatchMarker
          draggable={isEditable}
          color={color.hex}
          colorName={color.name}
          position={dispatchMarkerPosition}
          onDrag={this.handleDrag}
          onHover={setHoveredDispatchMarker}
          dimmed={!isNull(hoveredMarkerIndex)}
        />
      );
    } else {
      return null;
    }
  };

  handleZoomToGeozone = () => {
    const {
      mapStore: { zoomToGeozoneBounds },
      geozonesAdmin: {
        selectedGeozone: { type, radius, formatterCoordinates },
      },
    } = this.props;
    if (formatterCoordinates.length) {
      zoomToGeozoneBounds(formatterCoordinates, type, radius);
    }
  };

  handleShowAllGeozones = () => {
    const {
      geozonesAdmin: { setShowAllGeozones },
    } = this.props;
    setShowAllGeozones();
  };

  setCursorPosition = (e: google.maps.MouseEvent) => {
    this.setState({ cursorPosition: { lat: e.latLng.lat(), lng: e.latLng.lng() } });
  };

  setMoveOver = () => {
    this.setState({ moveOverMap: true });
  };

  setMoveOut = () => {
    this.setState({ moveOverMap: false });
  };

  handleMouseMove = (e) => {
    const {
      mapStore: { pointGeozoneLocation },
    } = this.props;

    if (pointGeozoneLocation) {
      this.setCursorPosition(e);
      this.setMoveOver();
    }
  };

  componentDidMount() {
    const {
      mapStore: { togglePointGeozoneLocation },
      geozonesAdmin: { getGeozonesList, showAllGeozones },
    } = this.props;
    this.props.mapStore.validateMapAvailable();
    this.handleZoomToGeozone();
    this.catchIsMapAvailable();
    togglePointGeozoneLocation(true);
    if (showAllGeozones) {
      getGeozonesList({ mappable: true, isActive: true });
    }
  }

  catchIsMapAvailable = () => {
    this.catchIsMapAvailableDisposer = reaction(
      () => this.props.mapStore.isMapAvailable,
      (isMapAvailable) => {
        if (isMapAvailable) {
          this.handleZoomToGeozone();
        }
      }
    );
  };

  componentWillUnmount() {
    const {
      mapStore: { togglePointGeozoneLocation },
    } = this.props;
    this.catchIsMapAvailableDisposer();
    togglePointGeozoneLocation(false);
  }

  render() {
    const {
      className,
      mapStore: {
        bounds,
        mapCenter,
        mapZoom,
        mapType,
        setMapType,
        isMapAvailable,
        pointGeozoneLocation,
        setStreetViewOpened,
      },
      geozonesAdmin: { selectedGeozone, geozonesListWithoutSelected, showAllGeozones },
    } = this.props;

    const {
      moveOverMap,
      cursorPosition: { lat, lng },
    } = this.state;

    const cn = classNames('AdminGeozoneMap', className);

    return (
      <div className={cn}>
        <div className="AdminGeozoneMap-controls">
          <div className="AdminGeozoneMap-mapMode">
            <RadioGroup
              className="RadioGroup--adminGeozones"
              checkedValue={mapType}
              onChange={setMapType}
              radios={mapTypes}
              tabs
            />
          </div>
          <div className="AdminGeozoneMap-mapSearch">
            <GoogleMapSearch
              googleMapURL={process.env.REACT_APP_GOOGLE_MAP_URL}
              loadingElement={<div style={{ height: `100%` }} />}
            />
          </div>
          <div className="AdminGeozoneMap-button AdminGeozoneMap-button--fitGeozone">
            <MapButton
              className="MapButton--fitGeozone"
              hoverText="Zoom to Geozone"
              iconComponent={<Icon active={false} Icon={FocusAllGeozonesIcon} size={15} />}
              onClick={this.handleZoomToGeozone}
            />
          </div>
          <div className="AdminGeozoneMap-button AdminGeozoneMap-button--showAllGeozones">
            <Toggle checked={showAllGeozones} onChange={this.handleShowAllGeozones} />
            <span className="AdminGeozoneMap-button--showAllGeozonesTitle">Show All Geozones</span>
            <InfoTooltip>
              Zones that are Inactive or have the 'Hide on Maps' option selected will not be shown.
            </InfoTooltip>
          </div>
        </div>
        <div className="AdminGeozoneMap-map">
          <CustomMap
            bounds={bounds}
            center={mapCenter}
            containerElement={<div style={{ height: `calc(100vh - 154px)`, width: '100%' }} />}
            googleMapURL={process.env.REACT_APP_GOOGLE_MAP_URL}
            loadingElement={<div style={{ height: `100%` }} />}
            map={this.map}
            mapElement={<div style={{ height: `100%` }} />}
            setStreetViewOpened={setStreetViewOpened}
            zoom={mapZoom}
            mapTypeId={mapType}
            onMouseMove={this.handleMouseMove}
            onMouseOut={this.setMoveOut}
          >
            {showAllGeozones && geozonesListWithoutSelected.length
              ? geozonesListWithoutSelected.map(({ name, formatterCoordinates, type, radius, color, id }, key) => {
                  return (
                    <Geozone
                      dimmed
                      color={color.hex}
                      coordinates={formatterCoordinates}
                      id={String(id)}
                      key={key}
                      showLabels
                      label={name}
                      radius={radius}
                      type={type}
                      clickable={!pointGeozoneLocation}
                    />
                  );
                })
              : null}
            {isMapAvailable && selectedGeozone && (
              <EditGeozonePath onChange={this.props.onChange} onMouseMove={this.handleMouseMove} />
            )}
            {this.renderDispatchMarker()}
            {pointGeozoneLocation && moveOverMap && <CoordinatesInfoBox position={{ lat, lng }} />}
          </CustomMap>
        </div>
      </div>
    );
  }
}

export default AdminGeozoneMap;
