import { DATE_TIME_FORMATS } from 'config';

export const COLUMN_WIDTH_BY_KEY = {};
export const DATE_TIME_KEYS = [
  ['startdatetime', DATE_TIME_FORMATS.adminVehicleSettings],
  ['enddatetime', DATE_TIME_FORMATS.adminVehicleSettings],
  ['stopdatetime', DATE_TIME_FORMATS.adminVehicleSettings],
  ['datetime', DATE_TIME_FORMATS.adminVehicleSettings],
  ['servicelasttime', DATE_TIME_FORMATS.monthDatFullYear],
  ['servicenexttime', DATE_TIME_FORMATS.monthDatFullYear],
];

export const DATE_TIME_KEYS_VALUES: string[] = DATE_TIME_KEYS.reduce((acc, [key]) => {
  return [...acc, key];
});

export const NUMBER_KEYS = [
  'enginehours',
  'odometer',
  'distancetovehicle',
  'gallonspurchased',
  'odometerwex',
  'totalcost',
  'pricepergallon',
  'index',
  'odomdelta',
  'statuscount0xf11a',
  'statuscount0xf116',
  'statuscount0xf930',
  'statuscount0xf937',
  'statuscount0xf960',
  'statuscountper1000xf11a',
  'statuscountper1000xf930',
  'statuscountper1000xf937',
  'statuscountper1000xf960',
  'enginerpm',
  'speed',
  'count',
  'servicenexttimedays',
  'elapsesechobbs',
  'vbatteryvolts',
  'serviceinterval',
  'serviceinterval2',
  'servicelast',
  'servicelast2',
  'servicenext',
  'servicenext2',
  'serviceremaining',
  'serviceremaining2',
  'startodometer',
  'stopodometer',
  'enginehoursvalue',
  'odometervalue',
];
export const TIME_KEYS = ['drivingelapse', 'stopelapse', 'usageelapse', 'elapsesec', 'idleelapse', 'enginehoursdelta'];

export const REPORT_SUMMARY_ORIGINAL_FORMATTING_KEYS = {
  IdleDetail_Group: ['idlestopdatetime'],
};
