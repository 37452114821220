import React, { FC } from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import type MediaTypeCategories from 'models/Dashcam/MediaLibrary/MediaType/MediaTypeCategories';
import MediaLibraryPanelSectionTitle from '../PanelSectionTitle';
import MediaLibraryFilterMediaToggle from '../FilterMediaToggle';

import './styles.scss';

interface IProps {
  className?: string;
  categories: MediaTypeCategories;
}

const MediaLibraryFilterMedia: FC<IProps> = ({ className, categories }) => {
  const cn = classNames('MediaLibraryFilterMedia', className);

  return (
    <Observer
      render={() => (
        <div className={cn}>
          {Boolean(categories.list.length) &&
            categories.list.map((category) => {
              return (
                !category.hidden && (
                  <div className="MediaLibraryFilterMedia-section" key={category.name}>
                    <div className="MediaLibraryFilterMedia-sectionTitle">
                      <MediaLibraryPanelSectionTitle>{category.name}</MediaLibraryPanelSectionTitle>
                    </div>
                    <div className="MediaLibraryFilterMedia-sectionContent">
                      {category.list.map((filter) => (
                        <div className="MediaLibraryFilterMedia-filter" key={filter.value}>
                          <MediaLibraryFilterMediaToggle filter={filter} />
                        </div>
                      ))}
                    </div>
                  </div>
                )
              );
            })}
        </div>
      )}
    />
  );
};

export default MediaLibraryFilterMedia;
