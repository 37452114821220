import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import {
  DATE_TIME_FORMATS,
  EVENT_STATUS_TYPE,
  HISTORY_MODE,
  OLD_EVENTS_SHOW_ERROR,
  PATHS,
  VEHICLE_DRIVER_NAME_READ,
  WEB_DASHCAM_BASIC_READ,
} from 'config';
import { IntervalEvents } from 'services';
import { getDateOrName, getDiffInDays, getDiffMin, getFormattedTime } from 'utils';
import { timezoneNames, mapLabelSizeType, UserAccessLevelComponent } from 'stores';
import validateAccessLevel from 'stores/acl/validator';

import EventStatusText from 'components/EventStatusText';
import StatusMarker from 'components/StatusMarker';
import VehicleItemIcons from '../VehicleItemIcons';

import { ListItemPinHoverIconSrcIcon, ListItemPinPinnedIconSrcIcon, NoMediaIcon, PowerOffIcon } from 'assets';
import InfoTooltip from 'components/InfoTooltip';

import './styles.scss';

interface IProps {
  address: string;
  calculateTotalIdle: boolean;
  calculateTotalIgnitionOff: boolean;
  currentSpeed: number;
  deviceId: string;
  driverName: string;
  eventStatusType: EVENT_STATUS_TYPE;
  eventType: string;
  geozone: Event.IEventGeozone;
  getTimeDiff?: (isLastEvent: boolean, isIdle?: boolean, isIgnitionOff?: boolean, toDate?: number) => string;
  groupId?: string;
  hasActiveClearShareLink: boolean;
  heading?: number;
  headingColor?: string;
  isUpdateTime: boolean;
  label: string;
  mediaEnabled: boolean;
  mode?: UI.panelMode;
  name: string;
  onGeozoneClick?: (geozoneId: string) => void;
  onItemPin?: (arg: string) => void;
  onSelectedClick: (select: boolean) => void;
  pinnedDevicesIdsList?: string[];
  setHistoryMode: (id: string) => void;
  showDriver: boolean;
  showHistory: boolean;
  size?: mapLabelSizeType;
  statusColor: string;
  timestamp: number;
  timezone: timezoneNames | string;
  toDate?: number;
  isPowerDisconnected: boolean;
  isCameraPowerDisconnected: boolean;
}

const liveTime = 120000; // live events that comes less than 2 minutes ago

const VehicleItem: React.FC<IProps> = ({
  address,
  calculateTotalIdle = false,
  calculateTotalIgnitionOff = false,
  currentSpeed,
  deviceId,
  driverName,
  eventStatusType,
  eventType,
  geozone,
  getTimeDiff,
  groupId,
  hasActiveClearShareLink,
  heading,
  headingColor,
  isUpdateTime,
  label,
  mediaEnabled,
  mode,
  name,
  onGeozoneClick,
  onItemPin,
  onSelectedClick,
  pinnedDevicesIdsList,
  setHistoryMode,
  showDriver,
  showHistory,
  size,
  statusColor,
  timestamp,
  timezone,
  toDate = null,
  isPowerDisconnected,
  isCameraPowerDisconnected,
}) => {
  const vehiclePath =
    groupId && groupId !== 'all'
      ? `${PATHS.MAP.GROUP_VEHICLE.replace(':groupId', groupId).replace(':vehicleId', deviceId)}`
      : `${PATHS.MAP.VEHICLES}/${deviceId}`;

  const [to, setLinkTo] = useState(vehiclePath);
  const [timeDifference, setTimeDifference] = useState(
    getTimeDiff(true, calculateTotalIdle, calculateTotalIgnitionOff, toDate)
  );

  useEffect(() => {
    setTimeDifference(getTimeDiff(true, calculateTotalIdle, calculateTotalIgnitionOff, toDate));
  });

  const isLive = useMemo(() => getDiffMin(timestamp) <= liveTime, [timestamp]);

  const isOldEvent = useMemo(() => {
    return getDiffInDays(Date.now(), timestamp, timezone) > 1 && OLD_EVENTS_SHOW_ERROR.includes(eventType);
  }, [timestamp, timezone]);

  const isActive = (match, location) => {
    if (!match) {
      return false;
    }
    if (location.pathname.indexOf(deviceId) === -1) {
      setLinkTo(vehiclePath);
    }
    return match.pathname === location.url;
  };
  const isPinned = pinnedDevicesIdsList && pinnedDevicesIdsList.includes(deviceId);
  const cn = classNames('VehicleItem', {
    'VehicleItem--pinned': isPinned,
    [`VehicleItem--${mode}`]: Boolean(mode),
    [`VehicleItem--${size}`]: Boolean(size),
  });
  const pinIconCn = classNames('VehicleItem-pinIcon', {
    'VehicleItem-pinIcon--pinned': isPinned,
  });
  const nameAndDriverClassName = classNames('VehicleItem-nameAndDriver', {
    'VehicleItem-nameAndDriver--withDriverName': Boolean(driverName),
  });
  const addressGeozoneClassName = classNames('VehicleItem-address VehicleItem-address--geozone', {
    'VehicleItem-address--historyEnabled': showHistory,
  });
  const vehicleDateClassName = classNames('VehicleItem-date', {
    'VehicleItem-date--oldEvent': isOldEvent && !showHistory,
  });

  const street = useMemo(() => address.split(', ')[0], [address]);
  const state = useMemo(() => address.split(', ').splice(1, 2).join(', '), [address]);

  const handleGeozoneClick = (e) => {
    if (geozone) {
      e.preventDefault();
      e.stopPropagation();
      onGeozoneClick(String(geozone?.geozoneIdCP));
    }
  };

  const handleVehicleItemClick = () => {
    if (showHistory) {
      setHistoryMode(HISTORY_MODE.POINT_IN_TIME);
    }

    onSelectedClick(true);
  };

  const handleVehicleItemPin = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onItemPin(deviceId);
    },
    [onItemPin, deviceId]
  );

  const time = useMemo(() => {
    const day = getDateOrName(timestamp, timezone);
    const time = getFormattedTime(timestamp, DATE_TIME_FORMATS.timeCapitalAM, timezone);
    return day === 'Today' || day === 'Yesterday' ? time : null;
  }, [timestamp, timezone]);

  const date = useMemo(() => {
    const day = getDateOrName(timestamp, timezone);
    return day !== 'Today' ? day : null;
  }, [timestamp, timezone]);

  const updateTimeDiff = () => {
    setTimeDifference(getTimeDiff(true, calculateTotalIdle, calculateTotalIgnitionOff, toDate));
  };

  useEffect(() => {
    if (isUpdateTime) {
      IntervalEvents.get().on(updateTimeDiff);
    }

    return () => {
      IntervalEvents.get().off(updateTimeDiff);
    };
  }, []);

  useEffect(() => {
    if (!isUpdateTime) {
      IntervalEvents.get().off(updateTimeDiff);
    } else {
      IntervalEvents.get().on(updateTimeDiff);
    }
  }, [isUpdateTime]);

  return (
    <NavLink
      to={to}
      isActive={isActive}
      className={cn}
      activeClassName="VehicleItem--active"
      onClick={handleVehicleItemClick}
      draggable="false"
    >
      <div className="VehicleItem-container">
        <div className="VehicleItem-column VehicleItem-column--grow">
          <div className={nameAndDriverClassName}>
            <div className="VehicleItem-name">
              <div className="VehicleItem-vehicleName">
                <div className="VehicleItem-status">
                  <StatusMarker color={statusColor} heading={heading} arrowColor={headingColor} size={size} />
                </div>
                {name}
              </div>
              <div className="VehicleItem-icons">
                <VehicleItemIcons
                  hasActiveClearShareLink={hasActiveClearShareLink}
                  mediaEnabled={mediaEnabled}
                  isShowCamera={!isCameraPowerDisconnected || showHistory}
                />
              </div>
              {validateAccessLevel([WEB_DASHCAM_BASIC_READ]) && isCameraPowerDisconnected && !showHistory && (
                <div className="VehicleItem-cameraPowerDisconnected">
                  <InfoTooltip
                    className="VehicleItem-cameraPowerDisconnected--info"
                    placement="top"
                    icon={<NoMediaIcon />}
                    width={20}
                    height={20}
                  >
                    Dashcam is offline
                  </InfoTooltip>
                </div>
              )}

              {isPowerDisconnected && !showHistory && (
                <div className="VehicleItem-powerDisconnected">
                  <InfoTooltip
                    className="VehicleItem-powerDisconnected--info"
                    placement="top"
                    icon={<PowerOffIcon />}
                    width={20}
                    height={20}
                  >
                    Tracker has lost power
                  </InfoTooltip>
                </div>
              )}
            </div>
            {showDriver && (
              <UserAccessLevelComponent requiredAccessLevel={[VEHICLE_DRIVER_NAME_READ]}>
                <div className="VehicleItem-driverContainer">
                  {Boolean(driverName) && <p className="VehicleItem-driverName">{driverName}</p>}
                </div>
              </UserAccessLevelComponent>
            )}
          </div>
          <div className="VehicleItem-statusContainer">
            <EventStatusText
              currentSpeed={currentSpeed}
              size={size}
              status={eventType}
              eventStatusType={eventStatusType}
              statusCodeText={label}
              timeDiff={timeDifference}
            />
          </div>
          {/* <div className="VehicleItem-lastLocation">Last known location:</div> */}
          {geozone?.reverseGeocode && geozone?.isActive ? (
            <p className={addressGeozoneClassName} onClick={handleGeozoneClick}>
              {address}
            </p>
          ) : (
            <div className="VehicleItem-address">
              <p className="VehicleItem-addressStreet">{street}</p>
              <p className="VehicleItem-addressState">{state}</p>
            </div>
          )}
        </div>
        <div className="VehicleItem-column VehicleItem-column--center">
          {isLive && eventStatusType === EVENT_STATUS_TYPE.SPEED ? (
            <p className="VehicleItem-liveText">{time ? time : 'live'}</p>
          ) : (
            <Fragment>
              {time && <p className="VehicleItem-time">{time}</p>}
              {date && (
                <div className={vehicleDateClassName}>
                  <p className="VehicleItem-date--text">{date}</p>
                  {isOldEvent && !showHistory && (
                    <InfoTooltip className="VehicleItem-date--info" width={12} height={12}>
                      The timestamp for this event is greater than 48 hours
                    </InfoTooltip>
                  )}
                </div>
              )}
            </Fragment>
          )}
        </div>
        <div className={pinIconCn} onClick={handleVehicleItemPin}>
          {isPinned ? (
            <ListItemPinPinnedIconSrcIcon className="VehicleItem-pinIconImg" />
          ) : (
            <ListItemPinHoverIconSrcIcon className="VehicleItem-pinIconImg" />
          )}
        </div>
      </div>
    </NavLink>
  );
};

VehicleItem.defaultProps = {
  onItemPin: () => true,
  onSelectedClick: () => true,
};

export default React.memo(VehicleItem);
