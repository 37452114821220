import React, { FC, Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Circle, Polygon } from 'react-google-maps';

import DispatchMarker from '../DispatchMarker';
import GeozoneLabel from '../GeozoneLabel';
import GeozoneMarkerWithLabel from '../GeozoneMarkerWithLabel';
import { geozoneOptions, getCenterOfGeozone, lineToCorridor } from 'utils';
import type { mapLabelSizeType } from 'stores';

import './styles.scss';

interface IProps {
  color: string;
  colorName?: string;
  coordinates: Locations.ILocation[];
  dispatchMarkerPosition?: Locations.ILocation;
  id: string;
  dimmed?: boolean;
  isSelected?: boolean;
  label?: string;
  labelSize?: mapLabelSizeType;
  onClick?: (geozoneId: string) => void;
  radius?: number;
  showLabels?: boolean;
  type: Geozone.geozoneType;
  zoomToGeozoneBounds?: (position: Locations.ILocation[], type: Geozone.geozoneType, radius: number) => void;
  clickable?: boolean;
}

const Geozone: FC<IProps> = ({
  color,
  colorName,
  coordinates,
  dispatchMarkerPosition = null,
  id,
  isSelected,
  label,
  labelSize,
  onClick = () => null,

  radius,
  showLabels,
  dimmed = false,
  type,
  zoomToGeozoneBounds,
  clickable = true,
}) => {
  const [hovered, setHovered] = useState(false);
  const defaultOptions = useMemo(() => geozoneOptions(color, hovered || isSelected), [color, hovered, isSelected]);
  const options = dimmed
    ? { ...defaultOptions, fillOpacity: 0.2, strokeWeight: 2, clickable }
    : {
        ...defaultOptions,
        clickable,
      };

  const formattedCoordinates = useMemo(
    () => (type === 'corridor' ? lineToCorridor(coordinates, radius) : coordinates),
    [type, coordinates, radius]
  );

  const centerOfMass = useMemo(() => {
    if (coordinates.length) {
      return type === 'circle' ? coordinates[0] : getCenterOfGeozone(coordinates);
    } else {
      return { lat: 0, lng: 0 };
    }
  }, [coordinates]);

  const onMarkerClick = useCallback(() => {
    onClick(id);
  }, [onClick, id]);
  const onMouseOut = useCallback(() => {
    setHovered(false);
  }, []);
  const onMouseOver = useCallback(() => {
    setHovered(true);
  }, []);

  useEffect(() => {
    if (isSelected) {
      zoomToGeozoneBounds(coordinates, type, radius);
    }
  }, [isSelected, coordinates, dispatchMarkerPosition, zoomToGeozoneBounds, type, radius]);

  return (
    <Fragment>
      {showLabels || isSelected ? (
        <Fragment>
          {hovered && !isSelected && <GeozoneLabel label={label} position={centerOfMass} />}
          {isSelected && dispatchMarkerPosition && (
            <DispatchMarker position={dispatchMarkerPosition} color={color} colorName={colorName} />
          )}
          {type.toLowerCase() === 'circle' ? (
            <Circle
              center={formattedCoordinates[0]}
              radius={radius}
              options={options}
              onClick={onMarkerClick}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
            />
          ) : (
            <Polygon
              path={formattedCoordinates}
              options={options}
              onClick={onMarkerClick}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
            />
          )}
        </Fragment>
      ) : (
        <GeozoneMarkerWithLabel
          color={color}
          label={label}
          labelSize={labelSize}
          onClick={onMarkerClick}
          position={dispatchMarkerPosition}
        />
      )}
    </Fragment>
  );
};

export default memo(Geozone);
