import { detect } from 'detect-browser';
import appPackage from '../../package.json';

export const getBrowserInfo = () => {
  let browserInfo = 'unknown browser';
  const browser = detect();

  // handle the case where we don't detect the browser
  if (browser) {
    browserInfo = `ClearPath Web::${appPackage.version}(${process.env.REACT_APP_BUILD_NUMBER})::${browser.name}::${browser.version}`;
  }
  return browserInfo;
};

export const getBrowserName = () => {
  const browser = detect();
  return browser?.name;
};

export const getBrowserVersion = () => {
  const browser = detect();
  return browser?.version;
};

export const printBlob = (blob) => {
  const iframe = document.createElement('iframe');
  document.body.appendChild(iframe);

  iframe.style.display = 'none';
  iframe.src = URL.createObjectURL(blob);
  iframe.onload = () => {
    setTimeout(() => {
      iframe.focus();
      iframe.contentWindow.print();
    }, 1);
  };
};
