import React from 'react';

import EventStatusText from 'components/EventStatusText';
import type { EVENT_STATUS_TYPE } from 'config';

import './styles.scss';

interface IProps {
  eventType: string;
  label: string;
  timeDiff: string;
  eventStatusType: EVENT_STATUS_TYPE;
}

const MapVehiclePanelEventStatusTotalStop: React.FC<IProps> = ({ eventType, label, timeDiff, eventStatusType }) => {
  return (
    <div className="MapVehiclePanelEventStatusTotalStop">
      <div className="MapVehiclePanelEventStatusTotalStop-text">
        <EventStatusText
          status={eventType}
          statusCodeText={label}
          currentSpeed={null}
          eventStatusType={eventStatusType}
          timeDiff={timeDiff}
        />
      </div>
    </div>
  );
};

export default MapVehiclePanelEventStatusTotalStop;
