import { SimpleField, ToggleField } from 'models/Fields';

class MediaVideo {
  url: string;
  seekTime: SimpleField<number>;
  progressSecond: SimpleField<number>;
  isPlaying: ToggleField;

  constructor(url) {
    this.set(url);
    this.seekTime = new SimpleField(0);
    this.progressSecond = new SimpleField(0);
    this.isPlaying = new ToggleField(false);
  }

  set = (url) => {
    this.url = url;
  };
}

export default MediaVideo;
