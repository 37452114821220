class KeyboardShortcutsManager {
  private static instance: KeyboardShortcutsManager;
  private callbacks = [];

  private constructor() {}

  public static get(): KeyboardShortcutsManager {
    if (!KeyboardShortcutsManager.instance) {
      KeyboardShortcutsManager.instance = new KeyboardShortcutsManager();
    }

    return KeyboardShortcutsManager.instance;
  }

  private activateLastCallback(keydown?: boolean): void {
    if (this.callbacks.length) {
      window.addEventListener(keydown ? 'keydown' : 'keyup', this.callbacks[this.callbacks.length - 1]);
    }
  }

  public add(callback, keydown?: boolean): void {
    this.callbacks.push(callback);
    this.activate(keydown);
  }

  public remove(callback, keydown?: boolean): void {
    this.deactivate(keydown);

    for (let i = 0; i < this.callbacks.length; i++) {
      if (this.callbacks[i] === callback) {
        this.callbacks.splice(i--, 1);
      }
    }

    this.activateLastCallback(keydown);
  }

  public activate(keydown?: boolean): void {
    this.deactivate(keydown);
    this.activateLastCallback(keydown);
  }

  public deactivate(keydown?: boolean): void {
    for (const callback of this.callbacks) {
      window.removeEventListener(keydown ? 'keydown' : 'keyup', callback);
    }
  }
}

export { KeyboardShortcutsManager };
