import { reaction } from 'mobx';
import type RouteReplaySpeed from './RouteReplaySpeed';
import { ToggleField } from 'models/Fields';
import type { MapStore } from 'stores/Map/MapStore';
import type DevicesFilters from 'stores/Map/DevicesFilters';

class RouteReplayState {
  isPlaying: ToggleField;
  speed: RouteReplaySpeed;
  context: MapStore;
  mapFilters: DevicesFilters;
  index: number;
  private intervalId: number;

  constructor(isPlaying: boolean, speed: RouteReplaySpeed, context: MapStore, mapFilters: DevicesFilters) {
    this.isPlaying = new ToggleField(isPlaying);
    this.speed = speed;
    this.context = context;
    this.mapFilters = mapFilters;
    this.index = 0;

    reaction(
      () => this.speed.value.value,
      () => {
        if (this.isPlaying.value) {
          this.pause(true);
          this.play();
        }
      },
      { name: 'Update route replay on speed change' }
    );

    reaction(
      () => [this.mapFilters.date.from, this.mapFilters.date.to],
      () => {
        this.pause();
      },
      { name: 'Update route replay on update of waypoints' }
    );
  }

  private run = () => {
    const wayPoints = this.context.wayPoints;
    const isLastWayPoint = wayPoints.indexOf(this.context.selectedWayPointEvent) + 1 === wayPoints.length;
    let selectedWayPointIndex =
      this.context.selectedWayPointEvent && !isLastWayPoint
        ? wayPoints.indexOf(this.context.selectedWayPointEvent)
        : this.index || 0;

    this.intervalId = setInterval(() => {
      if (selectedWayPointIndex + 1 > wayPoints.length) {
        this.pause();
        wayPoints[selectedWayPointIndex - 1].isSelected = false;
      } else {
        if (selectedWayPointIndex !== 0) {
          wayPoints[selectedWayPointIndex - 1].isSelected = false;
        }
        wayPoints[selectedWayPointIndex].isSelected = true;
        selectedWayPointIndex += 1;
        this.index += 1;
      }
    }, this.speed.value.value);
  };

  play = () => {
    this.isPlaying.toggle(true);
    this.run();
  };

  pause = (saveIndex?: boolean) => {
    const selectedWayPointEvent = this.context.selectedWayPointEvent;
    if (!saveIndex) {
      this.index = 0;
    }
    if (selectedWayPointEvent) {
      selectedWayPointEvent.isSelected = false;
    }
    this.isPlaying.toggle(false);
    clearInterval(this.intervalId);
  };

  reset = () => {
    this.pause();
  };
}

export default RouteReplayState;
