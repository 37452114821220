import React, { FC } from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import type MediaType from 'models/Dashcam/MediaLibrary/MediaSource/MediaType';
import RadioGroup from 'components/RadioGroup';

import './styles.scss';

interface IProps {
  className?: string;
  mediaType: MediaType;
}

const MediaLibraryMediaTypeSwitcher: FC<IProps> = ({ className, mediaType }) => {
  const cn = classNames('MediaLibraryMediaTypeSwitcher', className);
  const changeMediaSource = (value) => {
    mediaType.unselectAll();
    mediaType.findOption(value)?.isSelected.toggle(true);
  };

  return (
    <Observer
      render={() => (
        <div className={cn}>
          <div className="MediaLibraryMediaTypeSwitcher-section MediaLibraryMediaTypeSwitcher-section--mediaType">
            <div className="MediaLibraryMediaTypeSwitcher-sectionContent">
              <RadioGroup
                checkedValue={mediaType.selected?.value || ''}
                onChange={changeMediaSource}
                radios={mediaType.options.toArray().map((option) => ({
                  id: option.value,
                  label: option.name,
                  value: option.value,
                  name: 'dashCamMediaType',
                }))}
                size="medium"
                tabs
              />
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default MediaLibraryMediaTypeSwitcher;
