import React, { FC } from 'react';
import { observer } from 'mobx-react';

import CloseButton from 'components/Button/CloseButton';

import { OpenNewWindow } from 'assets';

import './styles.scss';


interface IProps {
  onClose: () => void;
}

const CameraSettingsHeader: FC<IProps> = ({onClose}) => {
  return (<div className="CameraSettingsHeader">
    <div className="CameraSettingsHeader-info">
      <div className="CameraSettingsHeader-title">
        Edit Camera Settings
      </div>
      <div className="CameraSettingsHeader-infoAction">
        <a href="https://support.clearpathgps.com/hc/en-us/articles/9676607389588-Camera-Settings" target="_blank" rel="noreferrer" className="CameraSettingsHeader-infoActionLink">
          <span>Help Center</span>
        </a>
        <OpenNewWindow className="CameraSettingsHeader-infoActionIcon"/>
      </div>
    </div>
    <div className="CameraSettingsHeader-action">
      <CloseButton onClick={onClose} />
    </div>
  </div>);
};

export default observer(CameraSettingsHeader);
