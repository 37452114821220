import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { reaction, IReactionDisposer } from 'mobx';
import { stringify, parse } from 'query-string';
import { Redirect } from 'react-router-dom';
import { isNull } from 'lodash';

import type { MediaManager, RouterStore } from 'stores';
import { PATHS } from 'config';

interface IProps {
  mediaManagerStore?: MediaManager;
  routerStore?: RouterStore;
}

interface ISearchParams {
  assetGroupId?: string;
  assetId?: string;
  from?: string;
  timestamp?: string;
  to?: string;
}

@inject(({ mediaManagerStore, routerStore }) => ({ mediaManagerStore, routerStore }))
@observer
class MediaLibraryRouter extends Component<IProps> {
  disposer: IReactionDisposer;

  constructor(props) {
    super(props);
    const {
      mediaManagerStore: { library },
    } = this.props;
    const params = parse(props.routerStore.history.location.search, { arrayFormat: 'comma' }) as ISearchParams;

    library.init(params);
    this.disposer = reaction(() => library.params, this.updateRoute);
  }

  componentWillUnmount() {
    const {
      mediaManagerStore: { library },
    } = this.props;

    this.disposer();
    library.redirect.reset();
    library.unsubscribeChanges();
    library.clips.delivery.deactivate();
  }

  updateRoute = () => {
    const {
      mediaManagerStore: {
        library: { params },
      },
      routerStore: {
        history: { push },
      },
    } = this.props;

    if (params) {
      push({ search: stringify(params, { skipNull: true, skipEmptyString: true }) });
    }
  };

  render() {
    const {
      mediaManagerStore: {
        library: {
          redirect: { assetId, timestamp },
          clips: { isClipExist, totalPages },
        },
      },
      routerStore: {
        location: { pathname },
      },
    } = this.props;

    return (
      assetId.value &&
      timestamp.value &&
      !isNull(totalPages.value) && (
        <>
          {!isClipExist && (
            <Redirect
              from={pathname}
              to={`${PATHS.DASHCAMS.MEDIA_REQUEST.STEP_1}?assetId=${assetId.value}&timestamp=${timestamp.value}`}
            />
          )}
        </>
      )
    );
  }
}

export default MediaLibraryRouter;
