import React, { FC } from 'react';
import classnames from 'classnames';

import './styles.scss';

interface IProps {
  className?: string;
  children: React.ReactNode;
}

const InspectionFormTile: FC<IProps> = ({ className, children }) => {
  const cn = classnames('InspectionFormTile', className);

  return (
    <div className={cn}>
      <p className="InspectionFormTile-content">{children}</p>
    </div>
  );
};

export default InspectionFormTile;
