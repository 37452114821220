import React, { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Popover } from 'antd';
import type { PopoverProps } from 'antd/lib/popover';

import { KeyboardShortcutsManager } from 'tools';

import { KEY_TYPE } from 'config';

import './styles.scss';

interface IProps extends PopoverProps {
  className?: string;
  disabled?: boolean;
  isOpen?: boolean;
  onOpen?: () => void;
  onVisibleChange?: (value?: boolean) => void;
  triggerElement?: React.ReactNode;
  hideOnEscape?: boolean;
}

const Popup: FC<IProps> = ({
  disabled = false,
  className,
  children,
  triggerElement,
  onOpen = () => true,
  onVisibleChange = () => true,
  isOpen = false,
  placement = 'bottomLeft',
  hideOnEscape = false,
}) => {
  const [visible, setVisible] = useState(isOpen);
  const handleVisibleChange = (isVisible) => {
    setVisible(isVisible);
    onVisibleChange(isVisible);
  };
  const contentCn = classNames('Popup-content', className);
  const triggerCn = classNames('Popup-trigger', {
    'Popup-trigger--disabled': disabled,
  });

  useEffect(() => setVisible(isOpen), [isOpen]);

  const listenEscKeyPress = useCallback((e) => {
    if (e.key === KEY_TYPE.ESCAPE) {
      setVisible(false);
    }
  }, [])

  useEffect(() => {
    if (hideOnEscape) {
      KeyboardShortcutsManager.get().add(listenEscKeyPress);
    }
    return () => {
      if (hideOnEscape) {
        KeyboardShortcutsManager.get().remove(listenEscKeyPress);
      }
    }
  }, [hideOnEscape, listenEscKeyPress])

  return (
    <Popover
      className="Popup"
      content={children}
      onVisibleChange={handleVisibleChange}
      overlayClassName={contentCn}
      placement={placement}
      trigger={disabled ? 'none' : 'click'}
      visible={visible}
    >
      <div className={triggerCn} onClick={onOpen}>
        {triggerElement}
      </div>
    </Popover>
  );
};

export default Popup;
