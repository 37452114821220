import React, { memo, useMemo, useCallback, useState } from 'react';
import svgToMiniDataURI from 'mini-svg-data-uri';
import { isEqual } from 'lodash';

import Marker from 'components/Map/Marker';
import MarkerIconLarge from 'components/Map/DeviceMarker/DeviceMarkerIconLarge';
import MarkerIconMedium from 'components/Map/DeviceMarker/DeviceMarkerIconMedium';
import MarkerIconSmall from 'components/Map/DeviceMarker/DeviceMarkerIconSmall';
import type { mapLabelSizeType } from 'stores';
import { MAP_MARKERS } from 'config';

interface IProps {
  arrowColor?: string;
  outline?: string;
  statusColor: string;
  size?: mapLabelSizeType;
  name: string;
  active?: boolean;
  position: {
    lat: number;
    lng: number;
  };
  onClick?: (deviceId: string) => void;
  deviceId?: string;
  heading?: number;
}

const markerGenerator = (size: mapLabelSizeType) => {
  switch (size) {
    case 'small':
      return MarkerIconSmall;
    case 'medium':
      return MarkerIconMedium;
    case 'large':
      return MarkerIconLarge;
  }
};

const DeviceMarker: React.FC<IProps> = ({
  active = false,
  arrowColor,
  heading,
  deviceId,
  name,
  onClick,
  outline,
  position,
  size = 'large',
  statusColor,
}) => {
  const icon = useMemo(() => {
    const getMarkerIcon = markerGenerator(size);
    return svgToMiniDataURI(getMarkerIcon({ name, statusColor, active, outline, arrowColor, heading }));
  }, [arrowColor, name, size, statusColor, outline, heading, active]);

  const [isHovered, setIsHovered] = useState(false);
  const [hideTitle, setHideTitle] = useState(false);

  const anchor = useMemo(() => {
    switch (size) {
      case 'small':
        return new google.maps.Point(22, 35);
      case 'medium':
        return new google.maps.Point(24, 41);
      case 'large':
        return new google.maps.Point(26, 47);
    }
  }, [size]);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(deviceId);
    }
  }, [deviceId]);

  const handleMouseOver = useCallback(() => {
    if (deviceId) {
      setIsHovered(true);
    }
    setHideTitle(true);
  }, [deviceId]);

  const handleMouseOut = useCallback(() => {
    if (deviceId) {
      setIsHovered(false);
    }
    setHideTitle(false);
  }, [deviceId]);

  return (
    <Marker
      title={hideTitle ? '' : 'Device Marker'}
      icon={icon}
      anchor={anchor}
      position={position}
      zIndex={active ? MAP_MARKERS.ACTIVE : isHovered ? MAP_MARKERS.HOVER : MAP_MARKERS.BASE}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    />
  );
};

export default memo(DeviceMarker, isEqual);
