import get from 'lodash/get';
import moment from 'moment';
import { formatPhoneNumber } from 'react-phone-number-input';
import type { ISearchResults } from '../stores';

export const offsetCenter = (
  latlng: google.maps.LatLng | Locations.ILocation,
  offsetx: number,
  offsety: number,
  zoom: number,
  map
) => {
  // latlng is the apparent centre-point
  // offsetx is the distance you want that point to move to the right, in pixels
  // offsety is the distance you want that point to move upwards, in pixels
  // offset can be negative
  // offsetx and offsety are both optional
  try {
    const scale = Math.pow(2, zoom);
    const worldCoordinateCenter = map.getProjection().fromLatLngToPoint(latlng);
    const pixelOffset = new google.maps.Point(offsetx / scale || 0, offsety / scale || 0);

    const worldCoordinateNewCenter = new google.maps.Point(
      worldCoordinateCenter.x - pixelOffset.x,
      worldCoordinateCenter.y + pixelOffset.y
    );

    return map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);
  } catch (e) {
    return latlng;
  }
};

export const getFormattedSelectedAddress = (searchResults): ISearchResults => {
  const address = get(searchResults, `formatted_address`, '');
  const latitude = get(searchResults, `geometry.location.lat`, null)();
  const longitude = get(searchResults, `geometry.location.lng`, null)();
  const name = get(searchResults, `name`, '');
  const additionalAddress = get(searchResults, `vicinity`, '');
  const website = get(searchResults, `website`, '');
  const firstCategory = get(searchResults, `types[0]`, '');
  const category = (firstCategory === 'point_of_interest' ? get(searchResults, `types[1]`, '') : firstCategory)
    .split('_')
    .join(' ');
  const isPoi = get(searchResults, `types`, []).includes('point_of_interest');
  const openingHours = get(searchResults, `opening_hours`, '');
  const hours = openingHours
    ? `${openingHours.isOpen() ? 'Open' : 'Closed'} now. ${
        openingHours.weekday_text
          ? openingHours.weekday_text
              ?.find((weekday) =>
                weekday.toLowerCase().includes(
                  moment()
                    .format('dddd')
                    .toLocaleLowerCase()
                )
              )
              ?.split(':')
              .splice(1)
              .join(':')
          : ''
      }`
    : '';
  const intPhone = get(searchResults, `international_phone_number`, '');
  const phone = intPhone ? formatPhoneNumber(intPhone) : '';
  const type = isPoi ? 'poi' : address ? 'address' : 'coordinates';

  return {
    address,
    latitude,
    longitude,
    meta: { name, additionalAddress, website, category, phone, hours, type },
  };
};
