import { SimpleField } from 'models/Fields';
import type { IScheduledReportInterval } from './IScheduledReport';

class ScheduledReportInterval implements IScheduledReportInterval {
  displayName: SimpleField<string>;
  description: SimpleField<string>;
  id: SimpleField<string>;

  constructor({ displayName = 'Daily', description = 'Yesterday', id = 'daily' }) {
    this.displayName = new SimpleField(displayName);
    this.description = new SimpleField(description);
    this.id = new SimpleField(id);
  }
}

export default ScheduledReportInterval;
