import React, { FC } from 'react';
import { OverlayView, OverlayViewProps } from 'react-google-maps';
import classNames from 'classnames';

import type { mapLabelSizeType } from 'stores';

import './styles.scss';

interface IProps {
  label: string;
  position: Locations.ILocation | google.maps.LatLng;
  size?: mapLabelSizeType;
}

const MapLabel: FC<IProps & OverlayViewProps> = ({ label, position, size, ...props }) => {
  const cn = classNames('MapLabel-label', {
    [`MapLabel-label--${size}`]: Boolean(size),
  });

  return (
    <div className="MapLabel">
      <OverlayView position={position} mapPaneName={OverlayView.MARKER_LAYER} {...props}>
        <div className={cn}>{label}</div>
      </OverlayView>
    </div>
  );
};

export default MapLabel;
