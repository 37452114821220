import React, { FC } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import { ToggleField } from 'models/Fields';
import type { IDashcamSettingsField } from 'interfaces/models/Dashcams/IDashcamSettingsField';

import Toggle from 'components/Toggle';
import InfoTooltip from 'components/InfoTooltip';
import CameraSettingsItem from '../index';

import { EmptySvgIcon, OpenNewWindow } from 'assets';

import './styles.scss';

interface IProps {
  item: IDashcamSettingsField<ToggleField>;
  toggle?: boolean;
  disabled?: boolean;
  availableCameraTypes?: string[];
}

const updateChildrenToggle = (settings, value: boolean) => {
  settings.forEach((setting) => {
    if (setting.value instanceof ToggleField) {
      setting.value.toggle(value);
    }
    if (setting.settings) {
      updateChildrenToggle(setting.settings, value);
    }
  });
};

const CameraSettingsItemTop: FC<IProps> = ({ item, toggle = false, availableCameraTypes = [], disabled }) => {
  const isCameraTypesMissed =
    item.cameraType && availableCameraTypes.length && !availableCameraTypes.includes(item.cameraType);
  const isBlockedByLinkedSetting = !!item.blocked?.value;

  const handleChange = () => {
    item.value.toggle();
    updateChildrenToggle(item.settings, item.value.value);
  };

  const handleChildrenChange = () => {
    if (
      item.settings &&
      item.settings.length === item.settings.filter((setting) => setting.value.value === false).length
    ) {
      item.value.value = false;
      updateChildrenToggle(item.settings, item.value.value);
    }
  };

  return (
    <div className="CameraSettingsItemTop">
      <div className="CameraSettingsItemTop-header">
        <div
          className={classnames('CameraSettingsItemTop-name', {
            'CameraSettingsItemTop-nameError': !!item.error,
          })}
        >
          {item.name}
        </div>
        {toggle && (
          <div
            className={classnames('CameraSettingsItemTop-action', {
              'CameraSettingsItemTop-actionError': !!item.error,
            })}
          >
            {item.error && (
              <div className="CameraSettingsItem-actionErrorItem CameraSettingsItemTop-actionErrorItem">
                <div>
                  {item.error.description && (
                    <InfoTooltip className="CameraSettingsItem-infoTooltip CameraSettingsItemNumeric-infoTooltip">
                      {item.error.description}
                    </InfoTooltip>
                  )}
                </div>
                <a
                  href={item.error.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="CameraSettingsHeader-infoActionLink"
                >
                  <span>{item.error.name}</span>
                </a>
                <OpenNewWindow className="CameraSettingsHeader-infoActionIcon" />
              </div>
            )}
            <div className="CameraSettingsItem-action CameraSettingsItemBoolean-action">
              <Toggle
                disabled={!!item.error || isCameraTypesMissed || isBlockedByLinkedSetting || disabled}
                className="CameraSettingsItemTop-toggle"
                checked={isBlockedByLinkedSetting ? false : item.value.value}
                onChange={handleChange}
              />
              {isBlockedByLinkedSetting && (
                <InfoTooltip
                  icon={<EmptySvgIcon />}
                  className="CameraSettingsItem-actionTooltip CameraSettingsItemBoolean-actionTooltip"
                >
                  Enable {item.blocked?.value} to access this control
                </InfoTooltip>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="CameraSettingsItemTop-body">
        {item.settings?.map((setting) => (
          <CameraSettingsItem
            key={setting.id}
            item={setting}
            disabled={item.value?.value === false || !!item.error || isBlockedByLinkedSetting || disabled}
            availableCameraTypes={availableCameraTypes}
            onChange={handleChildrenChange}
          />
        ))}
      </div>
    </div>
  );
};

export default observer(CameraSettingsItemTop);
