import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { NavLink, RouteComponentProps } from 'react-router-dom';

import type { TimeStore } from 'stores';
import { PATHS, WEB_DASHCAM_BASIC_READ, WEB_FUEL_CARDS_BASIC_UPDATE } from 'config';
import type { VehiclesAdmin } from 'stores/Admin/Vehicles';
import validateAccessLevel from 'stores/acl/validator';

import InfoTooltip from 'components/InfoTooltip';
import Input from 'components/Input';
import WEXFuelCardsVehicleAdmin from 'components/Admin/Integrations/WEXIntegration/WEXFuelCardsVehicleAdmin';
import Button from 'components/Button';
import Notification from 'components/Notification';

import './styles.scss';

interface IProps extends RouteComponentProps {
  className?: string;
  vehiclesAdmin?: VehiclesAdmin;
  timeStore?: TimeStore;
}

interface IState {
  notificationShown: boolean;
}

@inject(({ adminStore: { vehiclesAdmin }, timeStore }) => ({
  vehiclesAdmin,
  timeStore,
}))
@observer
class VehicleSettings extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    const {
      vehiclesAdmin: {
        selectedVehicle: { fuelCards, assetId },
      },
    } = this.props;

    fuelCards.fetch({ assetId });
  }

  redirectToEditIntegrations = () => {
    const {
      history: { push },
    } = this.props;

    push(PATHS.ADMIN.INTEGRATIONS.INDEX);
  };

  closeNotification = () => {
    const {
      vehiclesAdmin: {
        selectedVehicle: {
          fuelCards: { repositoryFuelCards },
        },
      },
    } = this.props;

    repositoryFuelCards.patchState.reset();
  };

  render() {
    const {
      timeStore: { userTimezone },
      vehiclesAdmin: {
        selectedVehicle: {
          activationDate,
          camera,
          deviceType,
          id,
          lastCommunication,
          status,
          uniqueId,
          fuelCards: { items, repositoryFuelCards },
        },
      },
    } = this.props;
    const lastCommunicationText = 'Last Communication' + (userTimezone ? ` (${userTimezone})` : '');

    return (
      <div className="VehicleSettings">
        <div className="VehicleSettings-container">
          <div className="VehicleSettings-mainColumn">
            <div className="VehicleSettings-dataRow">
              <p className="VehicleSettings-label">Vehicle Settings</p>
              <div className="VehicleSettings-dataRowContent">
                <div className="VehicleSettings-deviceStatus">
                  <div className="VehicleSettings-deviceStatusInput">
                    <Input
                      disabled
                      className="VehicleSettings-input VehicleSettings-inputStatus"
                      label="Device Status"
                      placeholder="Device Status"
                      value={status}
                    />
                  </div>
                  <div className="VehicleSettings-deviceStatusInfo">
                    <InfoTooltip>
                      <Fragment>To Suspend or Unsuspend a device, please contact our Support department</Fragment>
                    </InfoTooltip>
                  </div>
                </div>
                <Input
                  disabled
                  className="VehicleSettings-input VehicleSettings-inputDeviceId"
                  label="ClearPath ID Number"
                  value={id}
                />
                <Input
                  disabled
                  className="VehicleSettings-input VehicleSettings-inputActivationDate"
                  label={`Activation Date (${userTimezone})`}
                  value={activationDate}
                />
              </div>
            </div>
            <div className="VehicleSettings-dataRow">
              <p className="VehicleSettings-label">gps tracking device</p>
              <div className="VehicleSettings-dataRowContent">
                <Input
                  disabled
                  className="VehicleSettings-input VehicleSettings-inputIMEI"
                  label="IMEI/ESN"
                  value={uniqueId}
                />
                <Input
                  disabled
                  className="VehicleSettings-input VehicleSettings-inputModel"
                  label="Model"
                  value={deviceType}
                />
                <Input
                  disabled
                  className="VehicleSettings-input VehicleSettings-inputLastEvent"
                  label={lastCommunicationText}
                  value={lastCommunication}
                />
              </div>
            </div>
            {Boolean(camera?.uniqueId) && validateAccessLevel([WEB_DASHCAM_BASIC_READ]) && (
              <div className="VehicleSettings-dataRow">
                <div className="VehicleSettings-header">
                  <p className="VehicleSettings-label">Dashcam</p>
                  {validateAccessLevel([WEB_DASHCAM_BASIC_READ]) && (
                    <NavLink
                      className="VehicleSettings-header--dashcamLink"
                      to={PATHS.ADMIN.TABLES.DASHCAMS.DASHCAM.replace(':id', String(camera.id))}
                    >
                      View/Edit in Dashcam Admin
                    </NavLink>
                  )}
                </div>
                <div className="VehicleSettings-dataRowContent VehicleSettings-dataRowContent--dashcam">
                  <div className="VehicleSettings-input VehicleSettings-input--cameraName">
                    <Input label="Camera Name" value={camera.name} disabled />
                  </div>
                  <div className="VehicleSettings-input VehicleSettings-input--dashcamId">
                    <Input label="ClearPath Transport ID" value={camera.dashcamId} disabled />
                  </div>
                  <div className="VehicleSettings-input VehicleSettings-input--serialNum">
                    <Input label="Serial #" value={camera.serialNumber} disabled />
                  </div>
                  <div className="VehicleSettings-input VehicleSettings-input--activationNumber">
                    <Input label="Activation #" value={camera.uniqueId} disabled />
                  </div>
                </div>
              </div>
            )}
            {Boolean(items.length) && (
              <div className="VehicleSettings-dataRow">
                <div className="VehicleSettings-header">
                  <p className="VehicleSettings-label">Wex Fuel card Associations</p>
                  {validateAccessLevel([WEB_FUEL_CARDS_BASIC_UPDATE]) && (
                    <Button
                      title="Edit in Integrations"
                      className="Button--link VehicleSettings-link"
                      onClick={this.redirectToEditIntegrations}
                    />
                  )}
                </div>
                <div className="VehicleSettings-content">
                  <WEXFuelCardsVehicleAdmin items={items} />
                </div>
              </div>
            )}
            {repositoryFuelCards.patchState.error && (
              <Notification
                type="error"
                title="Failure"
                text="Something went wrong. Please try again."
                onClose={this.closeNotification}
              />
            )}
            {repositoryFuelCards.patchState.success && (
              <Notification
                type="success"
                title="Success"
                text="Success, card is no longer associated with that vehicle."
                onClose={this.closeNotification}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default VehicleSettings;
