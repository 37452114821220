import React, { FC, useState, useCallback, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import AntInput from 'antd/lib/input';
import { validateEmail } from 'utils';

import './styles.scss';

interface IProps {
  className?: string;
  disabled?: boolean;
  error?: string;
  isEmptyValid?: boolean;
  onChange?: (value: string) => void;
  onError?: (value: boolean) => void;
  placeholder?: string;
  shouldBeTouched?: boolean;
  value?: string;
}

const EmailInput: FC<IProps> = ({
  className,
  disabled,
  error = 'Invalid email address',
  isEmptyValid = false,
  onChange = () => true,
  onError = () => true,
  placeholder = 'email@company.com',
  shouldBeTouched = true,
  value = '',
}) => {
  const [touched, setTouched] = useState(false);
  const [email, setEmail] = useState(value);
  const handleChange = useCallback(
    (e) => {
      let email = e.target.value;

      if (!email) {
        email = '';
      }
      setEmail(email);
      onChange(email);
      setTouched(true);
    },
    [onChange]
  );
  const handleBlur = useCallback(() => setTouched(true), []);
  const isError = useMemo(() => {
    if (isEmptyValid && !email) {
      return false;
    }

    if (shouldBeTouched) {
      return touched && !validateEmail(email);
    } else {
      return !validateEmail(email);
    }
  }, [email, shouldBeTouched, touched, isEmptyValid]);
  const cn = classNames('EmailInput', className, {
    'EmailInput--error': isError,
    'EmailInput--disabled': disabled,
  });
  const inputCn = classNames('EmailInput-input', {
    'EmailInput-input--error': isError,
    'EmailInput-input--disabled': disabled,
  });

  useEffect(() => {
    setEmail(value);
  }, [value]);

  useEffect(() => {
    if (isError) {
      onError(true);
    } else {
      onError(false);
    }
  }, [onError, isError]);

  return (
    <div className={cn}>
      <AntInput
        className={inputCn}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={placeholder}
        value={email}
      />
      {isError && <p className="EmailInput-error">{error}</p>}
    </div>
  );
};

export default EmailInput;
