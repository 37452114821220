import React, { useState } from 'react';
import Button from 'components/Button';
import { NavLink } from 'react-router-dom';
import { PATHS } from 'config';

import SearchableSelectAsync from 'components/Select/SearchableSelectAsync';
import type MediaVehicle from 'models/Dashcam/MediaLibrary/MediaSource/MediaVehicle';
import type { IServerAsset, default as MediaVehicleList } from 'models/Dashcam/MediaLibrary/MediaSource/MediaVehicleList';

import './styles.scss';

interface IProps {
  onClose: () => void;
  vehicles: MediaVehicleList;
}

const MediaLibraryRequestModal: React.FC<IProps> = ({ onClose, vehicles }) => {
  const [selectedVehicle, setSelectedVehicle] = useState({
    value: vehicles.selected?.assetId,
    label: vehicles.selected?.displayName,
  });

  const changeVehicle = (option: Select.ISelectOption) => {
    setSelectedVehicle(option);
  };

  const preventRedirectToMediaRequest = (e) => {
    if (!selectedVehicle.value) {
      e.preventDefault();
    }
  };

  return (
    <div className="MediaLibraryRequestModal">
      <div className="MediaLibraryRequestModal-header">
        <p className="MediaLibraryRequestModal-headerTitle">Choose Media Source</p>
      </div>
      <div className="MediaLibraryRequestModal-content">
        <label className="MediaLibraryRequestModal-contentLabel">Vehicle</label>
        <div className="MediaLibraryRequestModal-contentSelect">
          <SearchableSelectAsync<MediaVehicle, IServerAsset>
            handleChange={changeVehicle}
            keys={{ value: 'assetId', label: 'displayName' }}
            options={vehicles}
            value={selectedVehicle}
            selectFirst
          />
        </div>
      </div>
      <div className="MediaLibraryRequestModal-controls">
        <Button
          title="Cancel"
          className="Button--cancel Button--inline MediaLibraryRequestModal-controlsCancel"
          onClick={onClose}
        />
        <NavLink
          to={`${PATHS.DASHCAMS.MEDIA_REQUEST.STEP_1}?assetId=${selectedVehicle.value}`}
          onClick={preventRedirectToMediaRequest}
        >
          <Button
            title="Select"
            className="Button--apply Button--inline MediaLibraryRequestModal-controlsSubmit"
            disabled={!selectedVehicle.value}
          />
        </NavLink>
      </div>
    </div>
  );
};

export default MediaLibraryRequestModal;
