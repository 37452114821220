import React from 'react';
import { Observer } from 'mobx-react';
import InputNumber from 'components/InputNumber';
import type { SimpleField } from 'models/Fields';

import './styles.scss';

interface IProps {
  engHours: SimpleField<number>;
}

const MaintenanceMarkAsCompleteEngineHours: React.FC<IProps> = ({ engHours }) => {
  const onChange = (value: string) => {
    engHours.set(Math.round(Number(value) * 10) / 10);
  };

  return (
    <Observer
      render={() => (
        <div className="MaintenancePreference-line MaintenanceMarkAsCompleteEngineHours">
          <div className="MaintenancePreference-label">Engine Hours at service</div>
          <div className="MaintenanceMarkAsCompleteEngineHours-valueWrapper">
            <div className="MaintenancePreference-value MaintenanceMarkAsCompleteEngineHours-value">
              <InputNumber
                min="0"
                value={engHours.value?.toLocaleString('en-US', { maximumFractionDigits: 1 })}
                onChange={onChange}
                className="MaintenanceMarkAsCompleteEngineHours-valueInput"
                controls={false}
                error={engHours.isEmpty}
              />
              <span className="MaintenanceMarkAsCompleteEngineHours-valueLabel">hours</span>
            </div>
            {engHours.isEmpty && (
              <p className="MaintenanceMarkAsCompleteEngineHours-error">Value must be bigger than 0</p>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default MaintenanceMarkAsCompleteEngineHours;
