import { ALERTS_TYPE } from 'config';
import AlertWithIntervalModel from './AlertWithInterval';
import isNumber from 'lodash/isNumber';

class BrakingAlertModel extends AlertWithIntervalModel {
  constructor(item) {
    super({
      ...item,
      isIntervalActive: isNumber(item.interval) ? Boolean(item.interval) : true,
      interval: isNumber(item.interval) ? item.interval : 5 * 60 * 1000,
    });

    this.urlType = 'braking';
    this.description =
      'Only OBD Plug-In devices are capable of detecting Harsh Braking. Visit our Help Center for more information on Harsh Driving Events.';
    this.type.reInitialize(ALERTS_TYPE.BRAKING);
  }
}

export default BrakingAlertModel;
