import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import type { MapStore, MediaManager } from 'stores';

import MediaMapItem from './MediaMapItem';
import SimpleMap from 'components/Map/SimpleMap';

import './styles.scss';

interface IProps {
  mediaManagerStore?: MediaManager;
  mapStore?: MapStore;
}
@inject(({ devicesMapStore: { mapStore }, mediaManagerStore }) => ({
  mapStore,
  mediaManagerStore,
}))
@observer
class DashCamsMediaLibraryMap extends Component<IProps> {
  render() {
    const {
      mapStore: { setStreetViewOpened },
      mediaManagerStore: {
        library: { clips },
      },
    } = this.props;

    const itemsCoordinates = clips.itemsCoordinates;

    return (
      <div className="DashCamsMediaLibraryMap">
        <SimpleMap bounds={itemsCoordinates} setStreetViewOpened={setStreetViewOpened}>
          {itemsCoordinates.map(({ lat, lng, interaction }, key) => (
            <MediaMapItem key={key} lat={lat} lng={lng} update={interaction.set} interaction={interaction.value} />
          ))}
        </SimpleMap>
      </div>
    );
  }
}

export default DashCamsMediaLibraryMap;
