import { getSVGTextWidth } from 'utils';
import { escape } from 'lodash';
import renderHeadingArrow from '../renderHeadingArrow';

const activeBorder = (textWidth, outline) => {
  const centerWidth = textWidth + 1;
  const fillColor = outline ? outline : '#4285f4';

  return `<g clip-path="url(#_clipPath_u4419JUIyXxLn5Ow290yJ7lHABNcUMDR)">
    <path d=" M 3 0 C 1.343 0 0 1.343 0 3 L 0 27 C 0 28.657 1.343 30 3 30 L 11.727 30 L 17.501 40 L 23.273 30 L 32 30 L 32 28 L 22.119 28 L 17.501 36 L 12.881 28 L 3 28 C 2.448 28 2 27.552 2 27 L 2 3 C 2 2.448 2.448 2 3 2 L 32 2 L 32 0 L 3 0 Z " fill-rule="evenodd" fill="${fillColor}" transform="translate(8 7)"/>
    <path d=" M 0 28 L 0 30 L ${centerWidth} 30 L ${centerWidth} 28 L 0 28 Z  M ${centerWidth} 2 L 0 2 L 0 0 L ${centerWidth} 0 L ${centerWidth} 2 Z " fill-rule="evenodd" fill="${fillColor}" transform="translate(40 7)"/>
    <path d=" M 0 28 L 7.275 28 C 7.849 28 8.315 27.553 8.315 27 L 8.315 3 C 8.315 2.447 7.849 2 7.275 2 L 0 2 L 0 0 L 7.275 0 C 8.998 0 10.394 1.343 10.394 3 L 10.394 27 C 10.394 28.657 8.998 30 7.275 30 L 0 30 L 0 28 Z " fill-rule="evenodd" fill="${fillColor}" transform="translate(${textWidth +
    40} 7)"/>
  </g>`;
};

export default ({ arrowColor, name, statusColor, active = false, outline = '', heading }) => {
  const nameToShow = name.length > 36 ? `${name.substring(0, 35)}...` : name;
  const escapedNameToShow = escape(nameToShow);

  const textWidth = getSVGTextWidth(nameToShow, '700 15px "Avenir Next"');

  return `<svg fill="none" xmlns="http://www.w3.org/2000/svg" width="${60 +
    textWidth}" height="51.123" viewBox="0 0 ${60 + textWidth} 51.123">
          <g filter="url(#filter0_ddd)">
            <path d="M8,34V10c0-1.7,1.3-3,3-3h29v30h-8.7l-5.8,10l-5.8-10H11C9.3,37,8,35.7,8,34z"
                  fill="white"/>
            <rect xmlns="http://www.w3.org/2000/svg" x="39" y="7" width="${textWidth + 1}" height="30" fill="white"/>
            <path d="M49,34V10c0-1.7-1.3-3-3-3h-7v30h7C47.7,37,49,35.7,49,34z" fill="white" transform="translate(${textWidth} 0)"/>
          </g>
          <circle cx="24" cy="22" r="9" fill="#${statusColor}"/>
          ${heading ? renderHeadingArrow(arrowColor, heading, '1', '14 12') : null}
          <text id="Stevens_car" data-name="Stevens car" fill="#2B2E41" transform="translate(38 27)" font-size="15"
                font-family="Avenir Next, Helvetica, Arial, sans-serif" font-weight="700">
            ${escapedNameToShow}
          </text>
          ${active === true ? activeBorder(textWidth, outline) : null}
          <defs>
            <filter id="filter0_ddd" x="0" y="0" width="${60 + textWidth}" height="51.123" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
              <feOffset dy="1"/>
              <feGaussianBlur stdDeviation="4"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
              <feOffset dy="3"/>
              <feGaussianBlur stdDeviation="2"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"/>
              <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
              <feOffset dy="3"/>
              <feGaussianBlur stdDeviation="1.5"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
              <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
            </filter>
            <clipPath id="_clipPath_u4419JUIyXxLn5Ow290yJ7lHABNcUMDR">
              <rect width="${60 + textWidth}" height="51.123"/>
            </clipPath>
          </defs>
        </svg>
`;
};
