import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import type { SimpleField } from 'models/Fields';
import type ExceptionOptions from 'models/Alerts/ExceptionOptions';
import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';

import './styles.scss';

interface IProps {
  exception: SimpleField<string>;
  exceptionType: ExceptionOptions;
}

const AlertPreferenceFuelException: React.FC<IProps> = ({ exception, exceptionType }) => {
  useEffect(() => {
    exceptionType.fetch();
  }, []);

  const handleChange = ({ value }) => {
    exception.set(value);
  };

  return (
    <Observer
      render={() => (
        <div className="AlertPreference-line">
          <div className="AlertPreference-label">Exception</div>
          <div className="AlertPreference-value">
            <div className="AlertPreference-value--select">
              <SearchableStaticSelect
                value={{ value: exception.value, label: exception.value }}
                values={exceptionType.options.map((option) => ({ value: option.id, label: option.name }))}
                handleChange={handleChange}
                isSearchable={false}
              />
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default AlertPreferenceFuelException;
