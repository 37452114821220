import React from 'react';

import { splitAddressStringIntoTwoLines } from 'utils';

import SatelliteCounts from 'components/Map/SatelliteCounts';
import MapVehiclePanelEventBase from '../MapVehiclePanelEventBase';

import { LocationEventIcon } from 'assets';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  address: string;
  coordinates?: [string, string];
  isGeozone?: boolean;
  satelliteCount?: number;
  showHistory: boolean;
  onClick: () => void;
}

const MapVehiclePanelEventLocation: React.FC<IProps> = ({
  address,
  coordinates,
  isGeozone,
  satelliteCount,
  onClick,
  showHistory,
}) => {
  const [line1, line2] = splitAddressStringIntoTwoLines(address);
  const vehicleGeozoneClassName = classNames('MapVehiclePanelEventGeoZone-name', {
    'MapVehiclePanelEventGeoZone-historyEnabled': showHistory,
  });

  return (
    <MapVehiclePanelEventBase
      icon={<LocationEventIcon className="MapVehiclePanelEventLocation-icon" />}
      className="MapVehiclePanelEventLocation-content"
    >
      {!isGeozone && line1 && <div className="MapVehiclePanelEventLocation-address-line-1">{line1}</div>}
      {!isGeozone && line2 && <div className="MapVehiclePanelEventLocation-address-line-2">{line2}</div>}
      {isGeozone && (
        <span className={vehicleGeozoneClassName} onClick={onClick}>
          {address}
        </span>
      )}
      {coordinates && coordinates.length === 2 && (
        <div className="MapVehiclePanelEventLocation-satellite">
          <div className="MapVehiclePanelEventLocation-coordinates">
            {coordinates[0]}, {coordinates[1]}
          </div>
          <div className="MapVehiclePanelEventLocation-satelliteCounts">
            <SatelliteCounts satelliteCount={satelliteCount} />
          </div>
        </div>
      )}
    </MapVehiclePanelEventBase>
  );
};

export default MapVehiclePanelEventLocation;
