import React, { useState } from 'react';
import { QBIcon } from 'assets';

import { WEB_MAP_FINANCE_BASIC_DELETE } from 'config';

import type { IQBIntegrationModel } from 'interfaces/stores/Integrations/IIntegration';

import IntegrationItem from '../IntegrationItem';
import ConfirmationModal from '../../ConfirmationModal';
import Notification from '../../../Notification';

import './styles.scss';

interface IProps {
  integration: IQBIntegrationModel;
}

const QuickBooksBIntegration: React.FC<IProps> = ({ integration }) => {
  const [isOpenDisconnectModal, setIsOpenDisconnectModal] = useState(false);

  const closeDisconnectModal = () => {
    setIsOpenDisconnectModal(false);
  };

  const submitDisconnect = async () => {
    await integration.disconnect();
    closeDisconnectModal();

    if (integration.repositoryEntityAuthDisconnect.deleteState.success) {
      await integration.update();
    }
  };

  const handleDisconnect = () => {
    setIsOpenDisconnectModal(true);
  };

  const onCloseConnectNotification = () => {
    integration.repositoryEntityAuthConnect.getState.reset();
  };

  const onCloseDisconnectNotification = () => {
    integration.repositoryEntityAuthDisconnect.deleteState.reset();
  };

  return (
    <>
      <IntegrationItem
        className="QBIntegration"
        integration={integration}
        onConnect={integration.connect}
        onReconnect={integration.reconnect}
        onDisconnect={handleDisconnect}
        icon={<QBIcon width={40} height={40} />}
        accessLevelToDisconnect={[WEB_MAP_FINANCE_BASIC_DELETE]}
        isBeta
      />
      <ConfirmationModal
        className="QBIntegrationDisconnectModal IntegrationDisconnectModal"
        title="Disconnect Quickbooks Online Integration?"
        applyButtonTitle="Yes, Disconnect"
        cancelButtonTitle="No, Cancel"
        isOpen={isOpenDisconnectModal}
        onSubmit={submitDisconnect}
        onCancel={closeDisconnectModal}
        withoutCloseIcon
      >
        <p className="QBIntegrationDisconnectModal-top">
          Disconnecting the Quickbooks Online Integration will remove all your Quickbooks data from ClearPathGPS and
          your company’s financial data will no longer be displayed.
        </p>
        <p className="QBIntegrationDisconnectModal-bottom">Do you want to continue?</p>
      </ConfirmationModal>
      {integration.repositoryEntityAuthConnect.getState.success && (
        <Notification
          type="success"
          text="Quickbooks Online data successfully connected."
          title="Success!"
          onClose={onCloseConnectNotification}
        />
      )}
      {integration.repositoryEntityAuthConnect.getState.error && (
        <Notification
          type="error"
          text="Something went wrong. Please try again."
          title="Connection Failed"
          onClose={onCloseConnectNotification}
        />
      )}
      {integration.repositoryEntityAuthDisconnect.deleteState.success && (
        <Notification
          type="success"
          text="Quickbooks Online data successfully disconnected."
          title="Success!"
          onClose={onCloseDisconnectNotification}
        />
      )}
      {integration.repositoryEntityAuthDisconnect.deleteState.error && (
        <Notification
          type="error"
          text="Something went wrong. Please try again."
          title="Disconnection Failed"
          onClose={onCloseDisconnectNotification}
        />
      )}
    </>
  );
};

export default QuickBooksBIntegration;
