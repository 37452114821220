import type { IDashcamSettingsField } from 'interfaces/models/Dashcams/IDashcamSettingsField';
import type { DASHCAMS_SETTINGS_TYPES } from 'config';
import { DashcamSettingFactory } from './DashcamSettingFactory';
import { SimpleField, ToggleField } from '../Fields';
import type { DashcamSettings } from './DashcamSettings';

export class DashcamSetting<V> implements IDashcamSettingsField<V> {
  context: DashcamSettings;
  level: number;
  description: string;
  id: string;
  name: string;
  parent: string;
  type: DASHCAMS_SETTINGS_TYPES;
  typeDescription: string;
  value: V;
  settings?: any;
  showMode?: 'select' | 'radio';
  expanded?: ToggleField;
  blocked?: SimpleField<string>;
  visible?: ToggleField;
  error?: {
    description: string;
    link: string;
    name: string;
    status: string;
  };
  showIf?: {
    id: string;
    value: string;
  };

  cameraType?: string;
  linkedSettings?: string[];

  constructor(
    context,
    {
      description,
      id,
      name,
      type,
      typeDescription,
      level,
      settings,
      error,
      cameraType,
      linkedSettings,
      showMode,
      showIf,
      visible = true,
    }
  ) {
    this.context = context;
    this.description = description;
    this.id = id;
    this.name = name;
    this.type = type;
    this.typeDescription = typeDescription;
    this.level = level;
    this.error = error;
    this.cameraType = cameraType;
    this.linkedSettings = linkedSettings;
    this.blocked = new SimpleField<string>(undefined);
    if (showMode) {
      if (showMode === 'DROPDOWN') {
        this.showMode = 'select';
      }
    }
    this.visible = new ToggleField(visible);
    this.showIf = showIf;
    this.settings =
      settings && settings.map((setting) => new DashcamSettingFactory(context, setting, level + 1, name).get());
  }

  get model() {
    return {
      id: this.id,
      description: this.description,
      name: this.name,
      type: this.type,
      level: this.level,
      settings: this.settings,
      typeDescription: this.typeDescription,
      error: this.error,
    };
  }

  reset: () => void;
}
