import React, { useCallback } from 'react';
import { Observer } from 'mobx-react';

import SearchableEventMetaDataSelect from 'containers/Select/SearchableEventMetaDataSelect';

import type AlertIDModel from 'models/Alerts/AlertID';

import './styles.scss';

interface IProps {
  statusCode: AlertIDModel<string>;
}

const AlertPreferencesStatusCode: React.FC<IProps> = ({ statusCode }) => {
  const handleChangeValue = useCallback((value) => {
    statusCode.id.set(value.value);
    statusCode.name.set(value.label);
  }, []);

  return (
    <Observer
      render={() => (
        <div className="AlertPreference-line">
          <div className="AlertPreference-label">Status code</div>
          <div className="AlertPreference-value AlertPreferencesStatusCode-value">
            <SearchableEventMetaDataSelect
              withAll
              handleChange={handleChangeValue}
              value={{ value: statusCode.id.value, label: statusCode.name.value }}
            />
          </div>
        </div>
      )}
    />
  );
};

export default AlertPreferencesStatusCode;
