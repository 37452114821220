import React, { useCallback } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import type { mapLabelSizeType } from 'stores';
import { PATHS } from 'config';
import { Geozone2Icon, ListItemPinHoverIconSrcIcon, ListItemPinPinnedIconSrcIcon } from 'assets';

import './styles.scss';

interface IProps {
  className?: string;
  color?: Geozone.IGeozoneColor;
  disabled?: boolean;
  id: number;
  name: string;
  onClick?: (arg: number) => void;
  onItemPin?: (arg: string) => void;
  pinnedGeozonesIdsList?: string[];
  size?: mapLabelSizeType;
  tag?: string;
}

const GeozonesItem: React.FC<IProps> = ({
  className,
  color,
  disabled,
  id,
  name,
  onClick,
  onItemPin,
  pinnedGeozonesIdsList,
  size,
  tag,
}) => {
  const isPinned = pinnedGeozonesIdsList && pinnedGeozonesIdsList.includes(String(id));
  const cn = classNames('GeozonesItem', className, {
    'GeozonesItem--disabled': disabled,
    'GeozonesItem--pinned': isPinned,
    [`GeozonesItem--${size}`]: Boolean(size),
  });
  const pinIconCn = classNames('GeozonesItem-pinIcon', {
    'GeozonesItem-pinIcon--pinned': isPinned,
  });
  const iconCn = classNames('GeozonesItem-icon', {
    [`GeozonesItem-icon--${color.name?.toLowerCase()}`]: Boolean(color.name),
  });
  const handleClick = useCallback(() => {
    onClick(id);
  }, [onClick, id]);
  const handleGeozonesItemPin = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      onItemPin(id.toString());
    },
    [id, onItemPin]
  );

  return (
    <NavLink
      activeClassName="GeozonesItem--active"
      className={cn}
      draggable={false}
      onClick={handleClick}
      to={PATHS.MAP.GEOZONE.replace(':geozoneId', String(id))}
    >
      <div className="GeozonesItem-row GeozonesItem-row--top">
        <Geozone2Icon className={iconCn} fill={color.hex} width={15} height={19} />
        <p className="GeozonesItem-name">{name}</p>
      </div>
      <div className="GeozonesItem-row GeozonesItem-row--bottom">
        <p className="GeozonesItem-category">{tag}</p>
      </div>
      <div className={pinIconCn} onClick={handleGeozonesItemPin}>
        {isPinned ? (
          <ListItemPinPinnedIconSrcIcon className="GeozonesItem-pinIconImg" />
        ) : (
          <ListItemPinHoverIconSrcIcon className="GeozonesItem-pinIconImg" />
        )}
      </div>
    </NavLink>
  );
};

GeozonesItem.defaultProps = {
  onClick() {
    return true;
  },
};

export default GeozonesItem;
