import React from 'react';
import { Observer } from 'mobx-react';

import type PersonPermissionsDashcamMediaAccess from 'models/Admin/People/PersonPermissions/PersonPermissionsDashcamMediaAccess';

import Toggle from 'components/Toggle';
import InfoTooltip from 'components/InfoTooltip';

import './styles.scss';

interface IProps {
  mediaAccess: PersonPermissionsDashcamMediaAccess;
}

const PersonPermissionsMediaAccess: React.FC<IProps> = ({ mediaAccess }) => {
  return (
    <Observer
      render={() => (
        <div className="PersonPermissionsMediaAccess">
          <Toggle checked={mediaAccess.active.value} onChange={mediaAccess.active.toggle} />
          <p className="PersonPermissionsMediaAccess-label">Dashcam Media Access</p>
          <InfoTooltip>
            When enabled, this user will have access to requesting, viewing, and downloading dashcam media files. When
            disabled, all Dashcam information will be hidden.
          </InfoTooltip>
        </div>
      )}
    />
  );
};

export default PersonPermissionsMediaAccess;
