import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Switch, Route, Redirect } from 'react-router';

import { PATHS } from 'config';
import type { MediaManager } from 'stores';
import RequestMediaStep1 from './Step1';
import RequestMediaStep2 from './Step2';
import RequestMediaStep3 from './Step3';

interface IProps extends RouteComponentProps {
  mediaManagerStore?: MediaManager;
}

@inject(({ mediaManagerStore }) => ({ mediaManagerStore }))
@observer
class MediaRequest extends Component<IProps> {
  constructor(props) {
    super(props);
    const {
      mediaManagerStore: { checkout },
    } = this.props;

    checkout.initialize();
  }

  render() {
    const {
      mediaManagerStore: { checkout },
    } = this.props;

    return (
      <Switch>
        {!checkout.vehicle.isExist.value && <Redirect to={PATHS.DASHCAMS.MEDIA_MANAGER} />}
        <Route exact path={PATHS.DASHCAMS.MEDIA_REQUEST.STEP_1} component={RequestMediaStep1} />
        <Route exact path={PATHS.DASHCAMS.MEDIA_REQUEST.STEP_2} component={RequestMediaStep2} />
        <Route exact path={PATHS.DASHCAMS.MEDIA_REQUEST.STEP_3} component={RequestMediaStep3} />
        <Redirect from="*" to={PATHS.NOT_FOUND} />
      </Switch>
    );
  }
}

export default withRouter(MediaRequest);
