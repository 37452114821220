import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Prompt, RouteComponentProps } from 'react-router-dom';

import type { PeopleAdmin } from 'stores/Admin/People';
import type { UserStore } from 'stores';
import BackButton from 'components/BackButton';
import { PATHS } from 'config';
import SearchableUserSelect from 'containers/Select/SearchableUserSelect';
import Button from 'components/Button';
import TabsMenu from 'components/TabsMenu';
import AdminPersonalDetails from './PersonalDetails';
import AdminAccessPermissions from './AccessPermissions';

import './styles.scss';

interface IMatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  peopleAdmin?: PeopleAdmin;
  userStore?: UserStore;
}

const tabs = [{ name: 'Personal Details' }, { name: 'Access/Permissions' }];

@inject(({ adminStore: { peopleAdmin }, userStore }) => ({
  peopleAdmin,
  userStore,
}))
@observer
class AdminPeopleDetails extends Component<IProps> {
  async componentDidMount() {
    const { peopleAdmin } = this.props;

    await peopleAdmin.select(this.personId);
  }

  async componentDidUpdate(prevProps) {
    const { peopleAdmin } = this.props;

    if (prevProps.match.params.id !== this.personId) {
      await peopleAdmin.select(this.personId);
    }
  }

  handlePersonChange = ({ value }) => {
    const {
      history: { push },
    } = this.props;

    push(PATHS.ADMIN.TABLES.PEOPLE.PERSON.replace(':id', value));
  };

  onSave = async () => {
    const {
      peopleAdmin: { item },
      userStore: { getUserData },
    } = this.props;
    const isAssignedVehicleUpdated = item.value.permissions.driver.isAssignedVehicleUpdated;

    await item.value.validateAsync();

    if (!item.value.permissions.driver.errorField.value) {
      await item.value.update();
    }

    if (isAssignedVehicleUpdated) {
      getUserData();
    }
  };

  onCancel = () => {
    const {
      peopleAdmin: { item },
    } = this.props;

    item.value.reset();
  };

  get personId() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    return id;
  }

  get cancelDisabled() {
    const {
      peopleAdmin: { item },
    } = this.props;

    return !item.value.isUpdated || item.value.repository.patchState.loading;
  }

  get saveDisabled() {
    const {
      peopleAdmin: { item },
    } = this.props;

    return !item.value.isUpdated || !item.value.isValid || item.value.repository.patchState.loading;
  }

  render(): React.ReactNode {
    const {
      peopleAdmin: { ui, item },
    } = this.props;

    return (
      <>
        {item.value && (
          <div className="AdminPeopleDetails">
            <div className="AdminPeopleDetails-header">
              <div className="AdminPeopleDetails-headerBackButton">
                <BackButton link={PATHS.ADMIN.TABLES.PEOPLE.INDEX}>Back to People Admin</BackButton>
              </div>
              <div className="AdminPeopleDetails-headerSelectPerson">
                <span className="AdminPeopleDetails-headerSelectPersonLabel">View/Edit Person</span>
                <div className="AdminPeopleDetails-headerSelectPersonDropDown">
                  <SearchableUserSelect
                    handleChange={this.handlePersonChange}
                    value={{ label: item.value.details.name.value, value: item.value.id.value.toString() }}
                    key={item.value.details.name.initialValue}
                  />
                </div>
              </div>
            </div>
            <div className="AdminPeopleDetails-content">
              <div className="AdminPeopleDetails-contentChangesButtons">
                <div className="AdminPeopleDetails-contentChangeButton AdminPeopleDetails-contentChangeButton--cancel">
                  <Button
                    className="Button--cancel Button--cancelColorLynch"
                    disabled={this.cancelDisabled}
                    inline
                    onClick={this.onCancel}
                    title="Cancel"
                  />
                </div>
                <div className="AdminPeopleDetails-contentChangeButton AdminPeopleDetails-contentChangeButton--save">
                  <Button
                    className="Button--apply"
                    disabled={this.saveDisabled}
                    inline
                    onClick={this.onSave}
                    sending={
                      item.value.repository.patchState.loading ||
                      item.value.permissions.repositoryAssetId?.value?.getState.loading
                    }
                    title="Save"
                  />
                </div>
              </div>
              <TabsMenu
                className="AdminPeopleDetails-contentTabsMenu"
                selectedTab={ui.selectedTab.value}
                onSelect={ui.setSelectedTab}
                tabs={tabs}
              >
                <AdminPersonalDetails />
                <AdminAccessPermissions />
              </TabsMenu>
            </div>
            <Prompt when={item.value.isUpdated} message="Changes that you made may not be saved." />
          </div>
        )}
      </>
    );
  }
}

export default AdminPeopleDetails;
