class MaintenanceMixedTracker {
  lastPerformedByFullName: string;
  lastPerformedByPosition: string;
  engineHoursOrServiceTimeDueInDays: number;
  odometerOrEngineHoursDueInDays: number;
  odometerOrServiceTimeDueInDays: number;
  odometerOrEngineHoursOrServiceTimeDueInDays: number;

  constructor({
    engineHoursOrServiceTimeDueInDays = 0,
    odometerOrEngineHoursDueInDays = 0,
    odometerOrServiceTimeDueInDays = 0,
    odometerOrEngineHoursOrServiceTimeDueInDays = 0,
    lastPerformedByPosition = '',
    lastPerformedByFullName = '',
  }) {
    this.lastPerformedByFullName = lastPerformedByFullName;
    this.lastPerformedByPosition = lastPerformedByPosition;
    this.engineHoursOrServiceTimeDueInDays = engineHoursOrServiceTimeDueInDays;
    this.odometerOrEngineHoursDueInDays = odometerOrEngineHoursDueInDays;
    this.odometerOrServiceTimeDueInDays = odometerOrServiceTimeDueInDays;
    this.odometerOrEngineHoursOrServiceTimeDueInDays = odometerOrEngineHoursOrServiceTimeDueInDays;
  }
}

export default MaintenanceMixedTracker;
