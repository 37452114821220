import React from 'react';
import { observer } from 'mobx-react';

import type { ToggleField } from 'models/Fields';

import Checkbox from 'components/Checkbox';

import './styles.scss';

interface IProps {
  email: ToggleField;
  queue: ToggleField;
}

const AlertPreferencesActionTrigger: React.FC<IProps> = ({ email, queue }) => {
  const handleEmailCheckboxChange = () => {
    email.toggle();
  };

  const handleQueueCheckboxChange = () => {
    queue.toggle();
  };

  return (
    <div className="AlertPreference-line">
      <div className="AlertPreference-label AlertPreferencesActionTrigger-label">Trigger Action</div>
      <div className="AlertPreference-value">
        <div className="AlertPreferencesActionTrigger-title">
          <div className="AlertPreferencesActionTrigger-info">
            <Checkbox label="Email" checked={email.value} onChange={handleEmailCheckboxChange} />
          </div>
        </div>
        <div className="AlertPreferencesActionTrigger-title">
          <div className="AlertPreferencesActionTrigger-info">
            <Checkbox label="Queue" checked={queue.value} onChange={handleQueueCheckboxChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AlertPreferencesActionTrigger);
