import React, { Fragment } from 'react';
import type { LocaleUtils } from 'react-day-picker';
import SearchableStaticSelect from '../Select/SearchableStaticSelect';

interface IProps {
  index?: number;
  date?: Date;
  localeUtils?: LocaleUtils;
  onChange?: (day: Date, index?: number) => void;
  fromMonth?: Date;
  toMonth?: Date;
  numberOfMonths?: number;
}

const YearMonthForm: React.FC<IProps> = ({
  date,
  localeUtils,
  onChange,
  fromMonth,
  toMonth,
  index,
  numberOfMonths,
}) => {
  const months = localeUtils.getMonths();
  const years = [];

  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleMonthChange = (option) => {
    let nextValue = new Date(date.getFullYear(), option.value);
    const nextValuePreviousDate = new Date(nextValue);
    nextValuePreviousDate.setMonth(nextValue.getMonth() - (index % 2));
    const startOfFromMonth = new Date(fromMonth.getFullYear(), fromMonth.getMonth(), 1)

    if (nextValuePreviousDate < startOfFromMonth) {
      nextValue = new Date(fromMonth);
      nextValue.setMonth(nextValue.getMonth() + (numberOfMonths > 1 ? 1 : 0));
    }

    onChange(nextValue, index);
  };

  const handleYearChange = (option) => {
    let nextValue = new Date(new Date(option.value, date.getMonth() + 1, 1).getTime() - 1)
    const endOfToMonth = new Date(new Date(toMonth.getFullYear(), toMonth.getMonth() + 1, 1).getTime() - 1);
    if (nextValue < fromMonth) {
      nextValue = new Date(fromMonth);
      nextValue.setMonth(nextValue.getMonth() + (numberOfMonths > 1 ? (index % 2) : 0));
    } else if (nextValue > endOfToMonth) {
      nextValue = new Date(toMonth);
      nextValue.setMonth(nextValue.getMonth() + (numberOfMonths > 1 ? 1 : 0));
    }
    onChange(nextValue, index);
  };

  const monthValues = months.map((month, i) => {
    const isBeforeFromMonth = fromMonth > new Date(date.getFullYear(), (i + 1) - (numberOfMonths > 1 ? index % 2 : 0));
    const isAfterToMonth = toMonth < new Date(date.getFullYear(), i);

    return {
      value: String(i),
      label: month,
      isDisabled: isBeforeFromMonth || isAfterToMonth,
    };
  });

  const valueYear = {
    value: years.includes(date.getFullYear()) ? String(date.getFullYear()) : null,
    label: years.includes(date.getFullYear()) ? String(date.getFullYear()) : null,
  };

  return (
    <Fragment>
      <form className="DayPicker-Caption">
        <div className="DayPicker-Caption-container">
          <div className="DayPicker-Caption-month">
            <SearchableStaticSelect
              value={{
                value: String(date.getMonth()),
                label: String(date.getMonth()),
              }}
              values={monthValues}
              isSearchable={Boolean(false)}
              handleChange={handleMonthChange}
              menuPortalTarget={null}
            />
          </div>

          <div className="DayPicker-Caption-year">
            <SearchableStaticSelect
              value={valueYear}
              values={years.map((year) => {
                return {
                  value: String(year),
                  label: year,
                };
              })}
              isSearchable={Boolean(false)}
              handleChange={handleYearChange}
              menuPortalTarget={null}
            />
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default YearMonthForm;
