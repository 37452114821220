import React from 'react';
import { Observer } from 'mobx-react';
import classnames from 'classnames';
import { CAMERA_TYPE, MEDIA_CLIPS_VIEW, TIME_ZONE_INFO_TEXT } from 'config';

import type { MapStore } from 'stores';
import type MediaClips from 'models/Dashcam/MediaLibrary/MediaClips';
import type MediaTypeCategories from 'models/Dashcam/MediaLibrary/MediaType/MediaTypeCategories';
import MediaLibraryClip from '../MediaLibraryClip';
import Tooltip from 'components/Tooltip';
import InfoTooltip from 'components/InfoTooltip';
import TimezoneSelect from 'containers/Select/TimezoneSelect';
import MediaPanelFilter from 'components/DashCams/MediaPanelFilter';

import { CogIcon } from 'assets';
import './styles.scss';

interface IProps {
  clips: MediaClips;
  categories: MediaTypeCategories;
  map: MapStore;
  cameraProvider?: CAMERA_TYPE;
}

const MediaLibraryListItems: React.FC<IProps> = ({ clips, categories, map, cameraProvider }) => {
  return (
    <Observer
      render={() => {
        const cn = classnames('MediaLibraryListItems', {
          [`MediaLibraryListItems--${clips.view}`]: Boolean(clips.view),
        });
        const settingsFilterCn = classnames('MediaLibraryListItems-settings MediaLibraryListItems-settings--filter', {
          'MediaLibraryListItems-settings--filterHidden': Boolean(clips.items.value.length),
        });

        const filterCount = categories.filterList.reduce((acc, filter) => acc + filter?.count, 0);

        return (
          <div className={cn}>
            {clips.view === MEDIA_CLIPS_VIEW.MEDIA_LIBRARY && (
              <div className="MediaLibraryListItems-settings">
                <Tooltip className="MediaLibraryListItems-tooltip" name="" Icon={CogIcon} forceClose>
                  <div className="MediaLibraryListItems-tooltipContainer">
                    <div className="MediaLibraryListItems-tooltipTimezone">
                      <div className="MediaLibraryListItems-tooltipName">Time zone</div>
                      <InfoTooltip>{TIME_ZONE_INFO_TEXT}</InfoTooltip>
                    </div>
                    <TimezoneSelect />
                  </div>
                </Tooltip>
              </div>
            )}
            {clips.view === MEDIA_CLIPS_VIEW.VEHICLE_MEDIA_PANEL && cameraProvider === CAMERA_TYPE.XIRGO && (
              <div className={settingsFilterCn}>
                <div className="MediaLibraryListItems-filter">
                  <MediaPanelFilter filters={categories.filterList} />
                </div>
              </div>
            )}
            {!clips.items.value.length && !clips.repository.getState.loading ? (
              <div className="MediaLibraryListItems-noResult">
                <h4 className="MediaLibraryListItems-noResultTitle">No Results Found</h4>
                {clips.view === MEDIA_CLIPS_VIEW.VEHICLE_MEDIA_PANEL && Boolean(filterCount) && (
                  <p className="MediaLibraryListItems-filterMessage">{filterCount} Events Filtered</p>
                )}
                {clips.view === MEDIA_CLIPS_VIEW.MEDIA_LIBRARY && (
                  <p className="MediaLibraryListItems-noResultDescription">
                    Adjust your filters or choose another source to view more clips
                  </p>
                )}
              </div>
            ) : (
              <ul className="MediaLibraryListItems-wrapper">
                {Object.keys(clips.itemsByDate).map((date) => (
                  <li className="MediaLibraryListItems-dateItem" key={date}>
                    <div className="MediaLibraryListItems-dateItemHeader">
                      <p className="MediaLibraryListItems-dateItemHeaderTitle">{date}</p>
                    </div>
                    <ul className="MediaLibraryListItems-dateItemClips">
                      {clips.itemsByDate[date].map((item, i) => (
                        <MediaLibraryClip
                          key={`${item.date.value}_${i}`}
                          clip={item}
                          clips={
                            clips.view === MEDIA_CLIPS_VIEW.MEDIA_LIBRARY
                              ? clips.itemsByDate[date]
                              : clips.items.toArray()
                          }
                          categories={categories}
                          map={map}
                          view={clips.view}
                        />
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            )}
          </div>
        );
      }}
    />
  );
};

export default MediaLibraryListItems;
