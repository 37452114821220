import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { PATHS } from 'config';

import type { GroupsAdmin } from 'stores/Admin/Groups';
import type { RouterStore } from 'stores/RouterStore';
import { validateGroupName, getValidGroupName } from 'utils';

import ConfirmationModal from 'components/Admin/ConfirmationModal';
import Input from 'components/Input';

import './styles.scss';

interface IProps {
  className?: string;
  groupsAdmin?: GroupsAdmin;
  routerStore?: RouterStore;
  isOpen: boolean;
  onCancel: () => void;
}

interface IState {
  newGroupName: string;
  touched: boolean;
}

@inject(({ adminStore: { groupsAdmin }, routerStore }) => ({
  groupsAdmin,
  routerStore,
}))
@observer
class AddNewGroupModal extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      newGroupName: '',
      touched: false,
    };
  }

  handleGroupNameChange = (event) => {
    const allowedGroupName = getValidGroupName(event.target.value);

    if (allowedGroupName.length <= 32) {
      this.setState({ newGroupName: allowedGroupName, touched: true });
    }
  };

  handleClickOnCancel = () => {
    const {
      onCancel,
      groupsAdmin: { repositoryGroups },
    } = this.props;

    this.setState({ newGroupName: '', touched: false });
    repositoryGroups.createState.reset();
    onCancel();
  };

  onAddNewGroup = async () => {
    const { newGroupName } = this.state;
    const {
      groupsAdmin: { addNewGroup, repositoryGroups },
      routerStore: {
        history: { push },
      },
    } = this.props;
    const groupId = await addNewGroup(newGroupName);

    if (repositoryGroups.createState.success) {
      this.handleClickOnCancel();
      if (groupId) {
        push(PATHS.ADMIN.TABLES.GROUPS.GROUP.replace(':id', groupId));
      }
    }
  };

  render() {
    const {
      isOpen,
      groupsAdmin: { repositoryGroups },
    } = this.props;
    const { newGroupName, touched } = this.state;
    const isGroupNameValid = validateGroupName(newGroupName);

    return (
      <ConfirmationModal
        closeOutside={false}
        isOpen={isOpen}
        isSubmitDisabled={!newGroupName || repositoryGroups.createState.loading || !isGroupNameValid}
        onCancel={this.handleClickOnCancel}
        onSubmit={this.onAddNewGroup}
        title="Add New Group"
      >
        <div className="AddNewGroupModal">
          <p className="AddNewGroupModal-title">Group Name</p>
          <div className="AddNewGroupModal-input">
            <Input
              onChange={this.handleGroupNameChange}
              value={newGroupName}
              error={repositoryGroups.createState.error || (touched && !isGroupNameValid ? 'Not valid name' : '')}
            />
          </div>
        </div>
      </ConfirmationModal>
    );
  }
}

export default AddNewGroupModal;
