import React, { useCallback } from 'react';
import { Polyline } from 'react-google-maps';

const options = {
  strokeColor: '#ff8801',
  strokeWeight: 20,
  strokeOpacity: 0,
  zIndex: 26,
  geodesic: true,
};

const NewMarkerHoverArea = ({ path, orderNumber, onMouseOver, onMouseOut }) => {
  const handleMouseOver = useCallback(() => {
    onMouseOver(orderNumber, path);
  }, [orderNumber, path, onMouseOver]);
  return <Polyline path={path} options={options} onMouseOver={handleMouseOver} onMouseOut={onMouseOut} />;
};

export default NewMarkerHoverArea;
