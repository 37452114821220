import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { parse } from 'query-string';

import { PATHS } from 'config';

import './styles.scss';

interface IQueryParams {
  token?: string;
  email?: string;
}

const UpdatePasswordEmail: FC = () => {
  let { token, email } = useMemo(() => ({ token, email } = parse(window.location.search) as IQueryParams), []);

  return (
    <div className="UpdatePasswordEmail">
      <div className="UpdatePasswordEmail-wrapper">
        <div className="UpdatePasswordEmail-header">
          <div className="UpdatePasswordEmail-headerLogo">
            <img src="https://public-data.clearpathgps.com/logo-dark-bg.svg" alt="Header logo" />
          </div>
          <img
            className="UpdatePasswordEmail-headerImage"
            src="https://public-data.clearpathgps.com/update-password.png"
            alt="Map image"
          />
        </div>
        <div className="UpdatePasswordEmail-content">
          <div className="UpdatePasswordEmail-title">Update your ClearPathGPS password.</div>
          <p className="UpdatePasswordEmail-text UpdatePasswordEmail-text--clickBellow">
            Click below and follow the instructions to update your password.
          </p>
          <Link
            className="UpdatePasswordEmail-button"
            to={`${PATHS.AUTH.UPDATE_PASSWORD}?token=${token}&email=${email}`}
          >
            Update My Password
          </Link>
          <p className="UpdatePasswordEmail-text UpdatePasswordEmail-text--small UpdatePasswordEmail-text--expiry">
            This link is only valid for one use and will expire after 24 hours.
          </p>
          <p className="UpdatePasswordEmail-text UpdatePasswordEmail-text--small UpdatePasswordEmail-text--emailInfo UpdatePasswordEmail-text--padding UpdatePasswordEmail-text--lightGrey">
            This email was sent to {email} from the ClearPathGPS online portal at your or your admins request. If you
            didn’t request this password reset or you received this message in error, please invalidate the link by
            clicking{' '}
            <a className="UpdatePasswordEmail-link" href="">
              here
            </a>{' '}
            or contact <span className="UpdatePasswordEmail-link">support@clearpathgps.com</span> |{' '}
            <span className="UpdatePasswordEmail-link">(805) 979 3442</span>
          </p>
          <div className="UpdatePasswordEmail-footer">
            <img
              src="https://public-data.clearpathgps.com/logo-white-bg.svg"
              alt=""
              className="UpdatePasswordEmail-logo"
            />
            <p className="UpdatePasswordEmail-text UpdatePasswordEmail-text--lightGrey UpdatePasswordEmail-text--xsmall">
              © {new Date().getFullYear()} ClearPathGPS, Inc. | All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePasswordEmail;
