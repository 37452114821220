import { observable, action, computed, reaction } from 'mobx';
import { Vehicle, Group, Driver } from 'models';
import { orderBy } from 'lodash';

import APIStoreBase from '../APIStoreBase';
import TableBase from '../TableBase';
import type { IEditColumns } from 'models/Tables/IEditColumns';
import type { ISortColumn } from 'models/Tables/ISortColumn';
import { repositoryService } from 'services';
import { getParsedStorageItem, updateStorageItem } from 'utils';
import { ADMIN_VEHICLES_TABLE_COLUMNS, STORAGE_ITEMS } from 'config';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import VehiclesDownload from 'models/Admin/Vehicles/VehiclesDownload';
import type { IFuelTypes } from 'interfaces/stores/Assets/IFuelTypes';
import FuelTypes from 'models/Assets/FuelTypes/FuelTypes';

const initialColumns: Array<IEditColumns<ADMIN_VEHICLES_TABLE_COLUMNS>> = [
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.NAME, isSelected: true },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.VIN, isSelected: true },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.YEAR, isSelected: true },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.MAKE, isSelected: true },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.MODEL, isSelected: true },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.PLATE, isSelected: true },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.DRIVER_DESCRIPTION, isSelected: true },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.ODOMETER, isSelected: true },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.GROUP, isSelected: true },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.VEHICLE_ID, isSelected: false },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.SUSPENDED, isSelected: false },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.UNIQUE_ID, isSelected: false },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.DESCRIPTION, isSelected: false },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.EQUIPMENT_TYPE, isSelected: false },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.IGNITION_STATE, isSelected: false },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.DEVICE_TYPE, isSelected: false },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.COORDINATES, isSelected: false },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.LAST_COMMUNICATION, isSelected: true },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.MPG, isSelected: false },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.UPDATED_BY, isSelected: false },
  { value: ADMIN_VEHICLES_TABLE_COLUMNS.LAST_UPDATE_TIME, isSelected: false },
];

const initialSortedColumn: ISortColumn = {
  field: 'name',
  order: 'ascend',
};

const storageNames = {
  columns: STORAGE_ITEMS.admin.vehicles.columns,
  sortedColumn: STORAGE_ITEMS.admin.vehicles.sortedColumn,
  pagination: STORAGE_ITEMS.admin.vehicles.pagination,
  searchInColumn: STORAGE_ITEMS.admin.vehicles.searchInColumn,
};

export interface ITableVehicle {
  coordinates: { latitude: string; longitude: string };
  description: string;
  deviceType: string;
  driverName: string;
  driverDescription: string;
  equipmentType: string;
  odometer: string;
  groups: Vehicle.IVehicleMembershipGroup[];
  id: string;
  ignitionState: string;
  imei?: string;
  uniqueId: string;
  key: string;
  lastEventTimestamp: number;
  lastCommunication: string;
  vehicleYear: string;
  make: string;
  model: string;
  name: string;
  plate: string;
  suspended: boolean | string;
  vin: string;
  milesPerGallon: string;
  updatedBy: string;
  lastUpdateTime: string;
}

export interface IVehicleSearchParams {
  description?: string;
  deviceId?: string;
  deviceType?: string;
  displayName?: string;
  driverName?: string;
  driverDescription?: string;
  equipmentType?: string;
  groupName?: string;
  ignitionState?: string;
  imeiNumber?: string;
  licensePlate?: string;
  vehicleMake?: string;
  vehicleModel?: string;
  milesPerGallon?: string;
  updatedBy?: string;
  lastUpdateTime?: string;
}

export enum VehicleSearchMap {
  description = 'description',
  id = 'deviceId',
  vin = 'vehicleID',
  deviceType = 'deviceType',
  name = 'displayName',
  odometer = 'reportedOdometer',
  driverName = 'driverName',
  driverDescription = 'driverDescription',
  equipmentType = 'equipmentType',
  groups = 'groupName',
  ignitionState = 'ignitionState',
  imei = 'imeiNumber',
  uniqueId = 'uniqueId',
  plate = 'licensePlate',
  make = 'vehicleMake',
  model = 'vehicleModel',
  lastCommunication = 'lastEventTimestamp',
  suspended = 'suspended',
  milesPerGallon = 'milesPerGallon',
  updatedBy = 'updatedBy',
  lastUpdateTime = 'lastUpdateTime',
  vehicleYear = 'vehicleYear',
}

export class VehiclesAdmin extends TableBase<IEditColumns<ADMIN_VEHICLES_TABLE_COLUMNS>> {
  constructor() {
    super({ columns: initialColumns, sortedColumn: initialSortedColumn, storageNames });

    this.repositoryVehicles = repositoryService.get('vehicles');
    this.repositoryDrivers = repositoryService.get('drivers');
    this.repositoryGroups = repositoryService.get('groups');
    this.repositoryNotifications = repositoryService.get('notifications');
    this.repositoryGroupsSearch = this.repositoryGroups.entity('ids');
    this.downloadVehicles = new VehiclesDownload(
      this.repositoryNotifications.entity('email').entity('document')
    ).initialize(this);
    this.fuelTypeOptions = new FuelTypes();

    this.repositoryVehicles.listenTo('error:get', () => this.resetVehiclesList());
    this.repositoryDrivers.listenTo('error:get', () => this.resetDriversList());
    this.repositoryGroupsSearch.listenTo('error:get', () => this.resetGroupsList());

    reaction(
      () => {
        return {
          editTab: this.selectedVehicleEditTab,
        };
      },
      (options) => updateStorageItem(STORAGE_ITEMS.admin.selectedVehicle, { ...options }),
      { name: 'Save selected vehicle settings' }
    );

    reaction(
      () => this.selectedGroup,
      (selectedGroup) => {
        if (selectedGroup) {
          this.getGroupVehicles(selectedGroup.groupId);
        } else {
          this.getAllVehicles({ pageSize: 50 });
        }
      },
      { name: 'Get vehicles list on groups filter change' }
    );
  }
  @observable selectedVehicleEditTab: number = VehiclesAdmin.initialSelectedVehicleEditTab;
  @observable selectedVehicleId: string = '';
  @observable selectedVehicle: Vehicle = null;
  @observable vehiclesList: Vehicle[] = [];
  @observable vehiclesListTotal: number = null;
  @observable searchQuery: string = '';
  @observable groupsList: Group[] = [];
  @observable selectedGroup: Group = null;
  @observable driversList: Driver[] = [];
  @observable getSelectedVehicleRequestStatus: APIStoreBase = new APIStoreBase();
  @observable getGroupVehiclesRequestStatus: APIStoreBase = new APIStoreBase();
  @observable downloadVehicles: VehiclesDownload;
  fuelTypeOptions: IFuelTypes;

  repositoryNotifications: IRepository;
  repositoryVehicles: IRepository;
  repositoryDrivers: IRepository;
  repositoryGroups: IRepository;
  repositoryGroupsSearch: IEntityRepository;
  // setSelectedVehicleEditTab
  static get initialSelectedVehicleEditTab(): number {
    return getParsedStorageItem(STORAGE_ITEMS.admin.selectedVehicle).editTab || 0;
  }

  @computed get vehiclesTableSource(): ITableVehicle[] {
    return this.vehiclesList.map(
      ({
        coordinates,
        description,
        deviceType,
        driverName,
        driverDescription,
        equipmentType,
        groups,
        id,
        ignitionState,
        uniqueId,
        odometer,
        vehicleYear,
        lastEventTimestamp,
        lastCommunication,
        make,
        model,
        name,
        plate,
        suspended,
        vin,
        milesPerGallon,
        loggerInfo: { updatedBy, lastUpdateTime },
      }) => ({
        coordinates,
        description: description || '-',
        deviceType: deviceType || '-',
        driverName: driverName || '-',
        driverDescription: driverDescription || '-',
        equipmentType: equipmentType || '-',
        groups,
        odometer: odometer ? odometer.toLocaleString('en-US', { maximumFractionDigits: 1 }) : '0',
        vehicleYear: vehicleYear || '-',
        id,
        ignitionState: ignitionState || '-',
        uniqueId: uniqueId || '-',
        key: id,
        lastEventTimestamp,
        lastCommunication: lastCommunication || '-',
        make: make || '-',
        model: model || '-',
        name: name || '-',
        plate: plate || '-',
        suspended,
        vin: vin || '-',
        milesPerGallon: milesPerGallon ? milesPerGallon.toString() : '-',
        updatedBy: updatedBy || '-',
        lastUpdateTime: lastUpdateTime.date,
      })
    );
  }

  @computed get filteredVehiclesList() {
    return orderBy(this.vehiclesList, ({ name }: Vehicle) => name.toLowerCase(), ['asc']);
  }

  @computed get filteredVehiclesListWithNonEmptyNames() {
    return orderBy(this.vehiclesList, ({ name }: Vehicle) => (!!name ? name.toLowerCase() : undefined), ['asc']);
  }

  @computed get searchData() {
    return this.selectedSearchColumns.reduce(
      (acc, cur) => ({
        ...acc,
        [VehicleSearchMap[cur.column]]: cur.value,
      }),
      {}
    );
  }

  @computed get sortData() {
    return {
      sortBy: VehicleSearchMap[this.sortedColumn.field],
      order: this.sortedColumn.order?.replace('end', '').toUpperCase(),
    };
  }

  @action setSelectedVehicleEditTab = (tab: number) => {
    this.selectedVehicleEditTab = tab;
  };

  @action setSelectedVehicleId = (deviceId) => (this.selectedVehicleId = deviceId);

  fetchVehicleData = (deviceId: string) => {
    return this.repositoryVehicles.entity(deviceId).get();
  };

  @action setSelectedVehicle = async (deviceId) => {
    this.getSelectedVehicleRequestStatus.reset().setLoading(true);

    try {
      const serverVehicle = await this.fetchVehicleData(deviceId);
      this.selectedVehicle = new Vehicle(serverVehicle);
      this.selectedVehicleId = deviceId;
      this.getSelectedVehicleRequestStatus.setSuccess(true);
    } catch (error) {
      this.getSelectedVehicleRequestStatus.setError(error);
      this.resetSelectedVehicle();
      throw new Error(error);
    }

    this.getSelectedVehicleRequestStatus.setLoading(false);
  };

  @action getAllVehicles = async ({
    order,
    page,
    pageSize,
    search,
    sortBy,
  }: {
    order?: string;
    page?: number;
    pageSize?: number;
    search?: IVehicleSearchParams;
    sortBy?: string;
  }) => {
    const response = await this.repositoryVehicles.get({
      includeInactive: true,
      order,
      page,
      pageSize,
      ...search,
      sortBy,
    });
    const serverVehiclesList = response.items;

    this.vehiclesList = serverVehiclesList.map((serverVehicle) => new Vehicle(serverVehicle));
    this.vehiclesListTotal = response.total;
  };

  @action setSearchQuery = (query: string) => (this.searchQuery = query);

  @action getAllGroups = async () => {
    const serverGroupsList = await this.repositoryGroupsSearch.get({ page: 0, pageSize: 50, filter: '' });

    this.groupsList = serverGroupsList.items.map((serverGroup) => new Group(serverGroup));
  };

  @action getAllDrivers = async () => {
    const response = await this.repositoryDrivers.get();

    this.driversList = response.items.map((serverDriver) => new Driver(serverDriver));
  };

  @computed get filteredDriversList() {
    return orderBy(
      this.driversList,
      ({ driverName, driverId }: Driver) =>
        driverName ? driverName.toLowerCase() : driverId ? driverId.toLowerCase() : undefined,
      ['asc']
    );
  }

  @action setSelectedGroup = (group: Group) => (this.selectedGroup = group);

  @action getGroupVehicles = async (groupId: string) => {
    this.getGroupVehiclesRequestStatus.reset().setLoading(true);

    try {
      const groupData = await this.repositoryGroups
        .entity(groupId)
        .get({ withDevices: true, withInactiveDevices: false });

      this.vehiclesList = groupData.devices.map((serverVehicle) => new Vehicle(serverVehicle));
      this.getGroupVehiclesRequestStatus.setSuccess(true);
    } catch (error) {
      this.getGroupVehiclesRequestStatus.setError(error);
    }

    this.getGroupVehiclesRequestStatus.setLoading(false);
  };

  @action resetVehiclesList = () => (this.vehiclesList = []);

  @action resetSelectedVehicle = () => {
    this.selectedVehicle = null;
    this.selectedVehicleId = '';
  };

  @action resetGroupsList = () => (this.groupsList = []);

  @action resetSelectedGroup = () => (this.selectedGroup = null);

  @action resetDriversList = () => (this.driversList = []);
}

export default new VehiclesAdmin();
