import { observable, action, reaction } from 'mobx';
import devicesStore from 'stores/Map/Devices';
import geozonesStore from 'stores/Geozones';
import googleMapSearchStore from 'stores/Map/GoogleMapSearchStore';

import subNavUIStateStore, {
  SubNavUIStateStore as SubNavUIStateStoreClass,
} from 'components/SubNav/SubNavUIState.store';

export class UIStore {
  constructor() {
    reaction(
      () => {
        return (
          devicesStore.selectedDeviceId ||
          geozonesStore.selectedGeozoneId ||
          (googleMapSearchStore.searchResults?.latitude && googleMapSearchStore.searchResults?.longitude)
        );
      },
      (value) => {
        if (Boolean(value) && !this.subNav.isExpanded) {
          this.subNav.setSubNavMenuState();
        }
      },
      { name: 'Expand sub nav on device, geozone, location select' }
    );
  }

  @observable isModalOpen: boolean = false;

  subNav: SubNavUIStateStoreClass = subNavUIStateStore;

  @action openModal = (): void => {
    this.isModalOpen = true;
  };

  @action closeModal = (): void => {
    this.isModalOpen = false;
  };
}

export default new UIStore();
