import React, { FC } from 'react';
import { Observer } from 'mobx-react';
import classnames from 'classnames';

import type InspectionPerformed from 'models/Inspections/Models/InspectionPerformed';
import InspectionPerformedPanelItem from './InspectionPerformedPanelItem';

import './styles.scss';

interface IProps {
  className?: string;
  inspectionPerformed: InspectionPerformed;
  onCreateTask: (itemId: number) => void;
}

const InspectionPerformedPanelItems: FC<IProps> = ({ className, inspectionPerformed, onCreateTask }) => {
  const cn = classnames('InspectionPerformedPanelItems', className);

  return (
    <Observer
      render={() => (
        <div className={cn}>
          <div className="InspectionPerformedPanelItems-list">
            {inspectionPerformed.form.items.list.value.map((item) => (
              <div className="InspectionPerformedPanelItems-item" key={item.value.id}>
                <InspectionPerformedPanelItem item={item.value} onCreateTask={onCreateTask} />
              </div>
            ))}
          </div>
        </div>
      )}
    />
  );
};

export default InspectionPerformedPanelItems;
