import { SimpleField } from 'models/Fields';

export enum MEDIA_LIBRARY_LOADING_STATE {
  NONE = 'none',
  CLIPS = 'clips',
  FULL = 'full',
  ERROR = 'error',
}

class MediaLibraryLoading {
  state: SimpleField<MEDIA_LIBRARY_LOADING_STATE>;
  timeout: number;

  constructor() {
    this.timeout = null;
    this.state = new SimpleField(MEDIA_LIBRARY_LOADING_STATE.NONE);
  }
}

export default MediaLibraryLoading;
