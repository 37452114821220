import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import SearchableSelect from 'components/Select/SearchableSelect';

import type { PersistenceStore } from 'stores';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';

interface IProps {
  disabled?: boolean;
  emptySearchResults?: string;
  handleChange?: (option: Select.ISelectOption) => void;
  handleMenuClose?: () => void;
  isMenuOpen?: boolean;
  isSearchable?: boolean;
  persistChange?: boolean;
  persistenceStore?: PersistenceStore;
  placeholder?: string;
  searchPlaceholder?: string;
  value?: Select.ISelectOption;
  withAll?: boolean;
  menuShouldBlockScroll?: boolean;
  timeout?: number;
  valueSource?: 'id' | 'assetGroupId';
}

@inject(({ persistenceStore }) => ({ persistenceStore }))
@observer
class SearchableGroupSelect extends Component<IProps> {
  static defaultProps = {
    placeholder: 'Select Group',
    searchPlaceholder: 'Search Groups',
    emptySearchResults: 'No Groups Found',
    valueSource: 'id',
  };

  static allOption = { label: 'All Vehicles', value: 'all' };

  repositoryGroupsSearch: IEntityRepository;

  constructor(props) {
    super(props);
    if (props.persistChange && props.persistenceStore.selectedGroup && !props.value) {
      this.props.handleChange(props.persistenceStore.selectedGroup);
    } else if (props.withAll && !props.value?.value) {
      this.props.handleChange(SearchableGroupSelect.allOption);
    }

    this.repositoryGroupsSearch = repositoryService.get('groups').entity('ids');
  }

  getData = (props: { page: number; pageSize: number; filter: string }) => {
    const data = { page: props.page || 0, pageSize: props.pageSize || 50, description: props.filter || '' };
    const { valueSource } = this.props;

    return this.repositoryGroupsSearch.get(data).then((data) => {
      const totalPages = data.totalPages;
      const options = data.items.map((item) => {
        return {
          value: item[valueSource].toString(),
          label: item.description,
        };
      });

      if (!props.filter && this.props.withAll && props.page === 0) {
        options.unshift(SearchableGroupSelect.allOption);
      }

      return {
        totalPages,
        options,
      };
    });
  };

  handleChange = (option: Select.ISelectOption) => {
    this.props.handleChange(option);
    if (this.props.persistChange) {
      this.props.persistenceStore.updateSelectedGroup({ ...option });
    }
  };

  render() {
    return (
      <SearchableSelect
        {...this.props}
        getData={this.getData}
        handleChange={this.handleChange}
        isMenuOpen={this.props.isMenuOpen}
        onMenuClose={this.props.handleMenuClose}
        menuShouldBlockScroll={this.props.menuShouldBlockScroll}
      />
    );
  }
}

export default SearchableGroupSelect;
