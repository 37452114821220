import React, { FC } from 'react';
import classNames from 'classnames';

import { CircleIcon, TriangleIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  color: Geozone.IGeozoneColor;
}

const ColorPickerTrigger: FC<IProps> = ({ className, color }) => {
  const cn = classNames('ColorPickerTrigger', className, {
    [`ColorPickerTrigger--${color.name.replaceAll(' ', '').toLowerCase()}`]: Boolean(color),
  });

  return (
    <div className={cn}>
      <CircleIcon className="ColorPickerTrigger-activeColor" fill={color.hex} width={20} height={20} />
      <TriangleIcon className="ColorPickerTrigger-arrow" fill="#6B7A99" />
    </div>
  );
};

export default ColorPickerTrigger;
