import TableDownload from 'models/Tables/TableDownload';
import type { GroupsAdmin } from 'stores/Admin/Groups';
import { ADMIN_GROUPS_TABLE_COLUMNS } from 'config';

class GroupsDownload extends TableDownload<GroupsAdmin> {
  private mapNameHeader = {
    [ADMIN_GROUPS_TABLE_COLUMNS.NAME]: 'Group Name',
    [ADMIN_GROUPS_TABLE_COLUMNS.COUNT]: 'Vehicle Count',
    [ADMIN_GROUPS_TABLE_COLUMNS.CREATED_BY]: 'Created By',
    [ADMIN_GROUPS_TABLE_COLUMNS.CREATION_TIME]: 'Time Created',
    [ADMIN_GROUPS_TABLE_COLUMNS.UPDATED_BY]: 'Modified By',
    [ADMIN_GROUPS_TABLE_COLUMNS.LAST_UPDATE_TIME]: 'Last Modified',
  };

  protected getFile = async (): Promise<Blob> => {
    const ReportFile = await import('../../Report/ReportDownload/ReportFile');
    const reportFile = new ReportFile.default(this.format.value, {
      title: 'Groups Admin',
      description: '',
      tables: [{ ...this.getData(), title: null }],
    });
    const file = await reportFile.create();
    return file;
  };

  protected get fileName() {
    return 'Groups Admin';
  }

  private getData = () => {
    const columnsIds = this.context.columns.filter((column) => column.isSelected).map((column) => column.value);
    const rows = this.context.tableSource.map((vehicle) => {
      return columnsIds.map((column) => vehicle[column]);
    });

    return {
      columns: columnsIds.map((columnId) => this.mapNameHeader[columnId]),
      rows,
      total: [],
    };
  };
}

export default GroupsDownload;
