import TableDownload from 'models/Tables/TableDownload';
import type { VehiclesAdmin } from 'stores/Admin/Vehicles';
import {
  ADMIN_VEHICLES_TABLE_COLUMNS,
  VEHICLE_DRIVER_NAME_READ,
  VEHICLE_DRIVER_NAME_UPDATE,
  WEB_MANAGEMENT_VEHICLE_FUEL_DETAILS_READ,
} from 'config';
import { validateAccessLevel } from 'stores';

class VehiclesDownload extends TableDownload<VehiclesAdmin> {
  private mapNameHeader = {
    [ADMIN_VEHICLES_TABLE_COLUMNS.NAME]: {
      title: 'Vehicle Name',
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.VIN]: {
      title: 'VIN',
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.YEAR]: {
      title: 'Year',
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.MAKE]: {
      title: 'Make',
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.MODEL]: {
      title: 'Model',
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.PLATE]: {
      title: 'Vehicle Plate Number',
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.DRIVER_DESCRIPTION]: {
      title: 'Driver Name',
      acls: [VEHICLE_DRIVER_NAME_READ, VEHICLE_DRIVER_NAME_UPDATE],
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.ODOMETER]: {
      title: 'Current Odometer',
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.GROUP]: {
      title: 'Group Membership',
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.VEHICLE_ID]: {
      title: 'CP# (Vehicle ID)',
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.SUSPENDED]: {
      title: 'Active/Suspended',
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.LAST_COMMUNICATION]: {
      title: 'Last Communication',
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.UNIQUE_ID]: {
      title: 'IMEI/ESN',
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.DESCRIPTION]: {
      title: 'Description',
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.EQUIPMENT_TYPE]: {
      title: 'Equipment Type',
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.IGNITION_STATE]: {
      title: 'Ignition State',
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.DEVICE_TYPE]: {
      title: 'Device Type',
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.COORDINATES]: {
      title: 'Latitude/Longitude',
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.MPG]: {
      title: 'MPG',
      acls: [WEB_MANAGEMENT_VEHICLE_FUEL_DETAILS_READ],
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.UPDATED_BY]: {
      title: 'Modified By',
    },
    [ADMIN_VEHICLES_TABLE_COLUMNS.LAST_UPDATE_TIME]: {
      title: 'Last Modified',
    },
  };

  protected getFile = async (): Promise<Blob> => {
    const ReportFile = await import('../../Report/ReportDownload/ReportFile');
    const reportFile = new ReportFile.default(this.format.value, {
      title: 'Vehicle Admin',
      description: '',
      tables: [{ ...this.getData(), title: null }],
    });

    const file = await reportFile.create();

    return file;
  };

  protected get fileName() {
    return 'Vehicle Admin';
  }

  private getData = () => {
    const columnsIds = this.context.columns
      .filter((column) => {
        if (this.mapNameHeader[column.value]?.acls) {
          return column.isSelected && validateAccessLevel(this.mapNameHeader[column.value]?.acls);
        } else {
          return column.isSelected;
        }
      })
      .map((column) => column.value);

    const rows = this.context.vehiclesTableSource.map((vehicle) => {
      return columnsIds.map((column) => {
        if (column === 'coordinates') {
          return `${vehicle[column].latitude} / ${vehicle[column].longitude}`;
        } else if (column === 'groups') {
          return vehicle[column].map((item) => item.description).join(', ');
        } else if (column === 'suspended') {
          return vehicle[column] ? 'Suspended' : 'Active';
        } else {
          return vehicle[column];
        }
      });
    });

    return {
      columns: columnsIds.map((columnId) => this.mapNameHeader[columnId].title),
      rows,
      total: [],
    };
  };
}

export default VehiclesDownload;
