import React from 'react';
import ReactDOMserver from 'react-dom/server';
import {
  abandonedGraySrc,
  clearPathGPSLogo,
  failedRedSrc,
  inProgressGraySrc,
  naGraySrc,
  passedGreenSrc,
  passedYellowSrc,
} from 'assets';
import { INSPECTION_RESULTS } from 'config';
import { getMinutesSecondsText } from 'utils';

import type InspectionPerformed from 'models/Inspections/Models/InspectionPerformed';

import './styles.scss';

const getBase64ImageFromUrl = async (imageUrl): Promise<string> => {
  const res = await fetch(imageUrl, { cache: 'no-cache' });
  const blob = await res.blob();

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result as string);
      },
      false
    );

    reader.readAsDataURL(blob);
  });
};

const InspectionHtmlTemplate = async (inspection: InspectionPerformed) => {
  const renderIcon = (status) => {
    switch (status) {
      case INSPECTION_RESULTS.PASSED:
        return <img src={passedGreenSrc} />;
      case INSPECTION_RESULTS.PASSED_WITH_DEFECTS:
        return <img src={passedYellowSrc} />;
      case INSPECTION_RESULTS.IN_PROGRESS:
        return <img src={inProgressGraySrc} />;

      case INSPECTION_RESULTS.ABANDONED:
        return <img src={abandonedGraySrc} />;
      case INSPECTION_RESULTS.FAILED:
        return <img src={failedRedSrc} />;
      default:
        return <img src={naGraySrc} />;
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case INSPECTION_RESULTS.PASSED:
        return 'Passed';
      case INSPECTION_RESULTS.IN_PROGRESS:
        return 'In Progress';
      case INSPECTION_RESULTS.ABANDONED:
        return 'Abandoned';
      case INSPECTION_RESULTS.FAILED:
        return 'Failed';
    }
  };

  const mainImages = await Promise.all(
    inspection.attachments.value.map(async (attachment): Promise<string> => {
      const url = await getBase64ImageFromUrl(attachment.value.model.url.value);
      return url;
    })
  );

  const items = await Promise.all(
    inspection.form.items.list.value.map(async (item): Promise<string[]> => {
      const images = await Promise.all(
        item.value.attachments.map(async (attachment): Promise<string> => {
          const url = await getBase64ImageFromUrl(attachment.model.url.value);

          return url;
        })
      );

      return images;
    })
  );

  return ReactDOMserver.renderToString(
    <div className="InspectionHtmlTemplate">
      <img className="InspectionHtmlTemplate-image" src={clearPathGPSLogo} />
      <div className="InspectionHtmlTemplate-content">
        <div className="InspectionHtmlTemplate-contentTop">
          <div className="InspectionHtmlTemplate-contentTopBlock">
            <h4 className="InspectionHtmlTemplate-contentTopBlockTitle">INSPECTION DETAILS</h4>
            <div className="InspectionHtmlTemplate-contentTopBlockInfo">
              <div className="InspectionHtmlTemplate-contentTopBlockLine">
                <div className="InspectionHtmlTemplate-contentTopBlockLineLabel">Results</div>
                <div className="InspectionHtmlTemplate-contentTopBlockLineValue">
                  {renderIcon(inspection.results.results.status)}
                  <span className="InspectionHtmlTemplate-contentTopBlockLineValueText">
                    {inspection.results.results.text}
                  </span>
                </div>
              </div>
              <div className="InspectionHtmlTemplate-contentTopBlockLine InspectionHtmlTemplate-contentTopBlockLine--gray">
                <div className="InspectionHtmlTemplate-contentTopBlockLineLabel">Vehicle</div>
                <div className="InspectionHtmlTemplate-contentTopBlockLineValue">{inspection.asset.name.value}</div>
              </div>
              <div className="InspectionHtmlTemplate-contentTopBlockLine">
                <div className="InspectionHtmlTemplate-contentTopBlockLineLabel">Inspection Form</div>
                <div className="InspectionHtmlTemplate-contentTopBlockLineValue">{inspection.form.name.value}</div>
              </div>
            </div>
          </div>
          <div className="InspectionHtmlTemplate-contentTopBlock">
            <h4 className="InspectionHtmlTemplate-contentTopBlockTitle">INSPECTION SUMMARY</h4>
            <div className="InspectionHtmlTemplate-contentTopBlockInfo">
              <div className="InspectionHtmlTemplate-contentTopBlockLine">
                <div className="InspectionHtmlTemplate-contentTopBlockLineLabel">Start Date/Time</div>
                <div className="InspectionHtmlTemplate-contentTopBlockLineValue">
                  {inspection.loggerInfo.creationTime.date}
                </div>
              </div>
              <div className="InspectionHtmlTemplate-contentTopBlockLine InspectionHtmlTemplate-contentTopBlockLine--gray">
                <div className="InspectionHtmlTemplate-contentTopBlockLineLabel">Elapsed Time</div>
                <div className="InspectionHtmlTemplate-contentTopBlockLineValue">
                  {inspection.elapsedTime ? getMinutesSecondsText(inspection.elapsedTime) : '-'}
                </div>
              </div>
              <div className="InspectionHtmlTemplate-contentTopBlockLine">
                <div className="InspectionHtmlTemplate-contentTopBlockLineLabel">Inspection By</div>
                <div className="InspectionHtmlTemplate-contentTopBlockLineValue">{inspection.inspectionBy}</div>
              </div>
              <div className="InspectionHtmlTemplate-contentTopBlockLine InspectionHtmlTemplate-contentTopBlockLine--gray">
                <div className="InspectionHtmlTemplate-contentTopBlockLineLabel">Location</div>
                <div className="InspectionHtmlTemplate-contentTopBlockLineValue">
                  {inspection.location.address.value || '-'}
                </div>
              </div>
              <div className="InspectionHtmlTemplate-contentTopBlockLine">
                <div className="InspectionHtmlTemplate-contentTopBlockLineLabel">Odometer At Inspection</div>
                <div className="InspectionHtmlTemplate-contentTopBlockLineValue">
                  {inspection.service.odometer
                    ? `${inspection.service.odometer?.toLocaleString('en-US', { maximumFractionDigits: 3 })} mi`
                    : '-'}
                </div>
              </div>
              <div className="InspectionHtmlTemplate-contentTopBlockLine InspectionHtmlTemplate-contentTopBlockLine--gray">
                <div className="InspectionHtmlTemplate-contentTopBlockLineLabel">Engine Hours At Inspection</div>
                <div className="InspectionHtmlTemplate-contentTopBlockLineValue">
                  {inspection.service.engineHours
                    ? `${inspection.service.engineHours?.toLocaleString('en-US', { maximumFractionDigits: 1 })} hrs`
                    : '-'}
                </div>
              </div>
              <div className="InspectionHtmlTemplate-contentTopBlockLine">
                <div className="InspectionHtmlTemplate-contentTopBlockLineLabel">Photo</div>
                <div className="InspectionHtmlTemplate-contentTopBlockLineValue">
                  <div className="InspectionHtmlTemplate-contentTopBlockPhotos">
                    {mainImages.map((url, i) => (
                      <div key={i} className="InspectionHtmlTemplate-contentTopBlockPhotosItem">
                        <img src={url} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="InspectionHtmlTemplate-contentTopBlockLine InspectionHtmlTemplate-contentTopBlockLine--gray">
                <div className="InspectionHtmlTemplate-contentTopBlockLineLabel">Notes</div>
                <div className="InspectionHtmlTemplate-contentTopBlockLineValue">{inspection.notes}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="InspectionHtmlTemplate-contentItems">
          <h4 className="InspectionHtmlTemplate-contentItemsTitle">INSPECTED ITEMS</h4>
          <div className="InspectionHtmlTemplate-contentItemsInfo">
            {inspection.form.items.list.value.map((item, i) => (
              <div
                className={`InspectionHtmlTemplate-contentItemsInfoLine ${
                  Boolean(i % 2) ? 'InspectionHtmlTemplate-contentItemsInfoLine--gray' : ''
                }`}
                key={i}
              >
                <div className="InspectionHtmlTemplate-contentItemsInfoLineName">{item.value.name.value}</div>
                <div className="InspectionHtmlTemplate-contentItemsInfoLineNote">{item.value.notes}</div>
                <div className="InspectionHtmlTemplate-contentItemsInfoLineStatus">
                  {renderIcon(item.value.status)}
                  <span className="InspectionHtmlTemplate-contentItemsInfoLineStatusText">
                    {item.value.status ? getStatusText(item.value.status) : 'N/A'}
                  </span>
                </div>
                <div className="InspectionHtmlTemplate-contentItemsInfoLineResult">
                  {items[i].map((url, j) => (
                    <div key={j} className="InspectionHtmlTemplate-contentItemsInfoLineResultItem">
                      <div className="InspectionHtmlTemplate-contentItemsInfoLineResultItemImage">
                        <img src={url} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InspectionHtmlTemplate;
