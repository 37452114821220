import React from 'react';
import { Observer } from 'mobx-react';
import { WrenchIcon } from 'assets';

import { validateAccessLevel } from 'stores';
import { ACL } from 'config';
import type { SimpleField } from 'models/Fields';
import type MaintenancePanelUI from 'models/Maintenances/UI/MaintenanceModalUI/MaintenancePanelUI';
import Input from 'components/Input';

import './styles.scss';

interface IProps {
  taskName: SimpleField<string>;
  ui: MaintenancePanelUI;
}

const MaintenanceCompletedTaskPanelHeader: React.FC<IProps> = ({ taskName, ui }) => {
  const edit = validateAccessLevel([ACL.MAINTENANCE.BASIC.UPDATE]);

  return (
    <Observer
      render={() => (
        <div className="MaintenanceCompletedTaskPanelHeader">
          <div className="MaintenanceCompletedTaskPanelHeader-title">Completed Maintenance Task</div>
          <div className="MaintenanceCompletedTaskPanelHeader-task">
            <div className="MaintenanceCompletedTaskPanelHeader-taskIcon">
              <WrenchIcon width={16.67} height={16.67} />
            </div>
            <div className="MaintenanceCompletedTaskPanelHeader-taskName">
              {ui.isEdit.value ? (
                <Input
                  className="MaintenanceCompletedTaskPanelHeader-taskNameInput"
                  value={taskName.value}
                  onChange={(e) => taskName.set(e.currentTarget.value)}
                  disabled={!edit}
                />
              ) : (
                <span className="MaintenanceCompletedTaskPanelHeader-taskNameLabel">{taskName.value}</span>
              )}
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default MaintenanceCompletedTaskPanelHeader;
