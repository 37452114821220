import React from 'react';
import { Drawer } from 'antd';
import type { DrawerProps } from 'antd/lib/drawer';
import classnames from 'classnames';

import CloseButton from 'components/Button/CloseButton';

import './styles.scss';

interface IProps extends DrawerProps {
  title: string | React.ReactNode;
  visible: boolean;
  setVisible: () => void;
  width?: number;
  mask?: boolean;
  className?: string;
  isHeaderFixed?: boolean;
}

const SideModal: React.FC<IProps> = ({
  closable = false,
  placement = 'right',
  title,
  visible,
  setVisible,
  width = 300,
  mask = true,
  className,
  isHeaderFixed = false,
  ...props
}) => {
  const cn = classnames('SideModal', className);
  const cnHeader = classnames('SideModal-header', {
    'SideModal-header--fixed': isHeaderFixed,
  });

  return (
    <Drawer
      className={cn}
      closable={closable}
      destroyOnClose
      onClose={setVisible}
      placement={placement}
      visible={visible}
      width={width}
      mask={mask}
    >
      <div className={cnHeader}>
        <div className="SideModal-title">{title}</div>
        <div className="SideModal-close">
          <CloseButton onClick={setVisible} />
        </div>
      </div>
      <div className="SideModal-content">{props.children}</div>
    </Drawer>
  );
};

export default SideModal;
