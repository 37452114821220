import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { SuccessIcon } from 'assets';

import { MAINTENANCES_STORE, MAINTENANCE_APPLY_TASK_CREATED_SUCCESSFULLY, PATHS } from 'config';
import type { Maintenances as MaintenancesStore } from 'stores';
import { getCookie, removeCookie } from 'utils';

import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';
import Button from 'components/Button';
import BackButton from 'components/BackButton';
import ApplyTasksTable from './ApplyTasksTable';
import ApplyTasksSidebar from './ApplyTasksSidebar';
import Notification from 'components/Notification';

import './styles.scss';

interface IState {
  isAllTasksSaved: boolean;
  successMessage: string;
  errorCreateMessage: string;
  errorConvertMessage: string;
}

interface IProps extends RouteComponentProps {
  [MAINTENANCES_STORE]?: MaintenancesStore;
}

interface IMatchParams {
  id: string;
}

@inject(MAINTENANCES_STORE)
@observer
class MaintenanceApplyTasks extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isAllTasksSaved: false,
      successMessage: null,
      errorCreateMessage: null,
      errorConvertMessage: null,
    };
  }

  componentDidMount(): void {
    const {
      maintenancesStore: { applyTasks, findById },
      match,
    } = this.props;
    const { id } = match.params as IMatchParams;
    const task = findById(Number(id));

    applyTasks.setTask({ data: task?.model.serverData, id });

    const notifyMessage = getCookie(MAINTENANCE_APPLY_TASK_CREATED_SUCCESSFULLY);

    if (notifyMessage) {
      this.setState({ successMessage: notifyMessage });
      removeCookie(MAINTENANCE_APPLY_TASK_CREATED_SUCCESSFULLY);
    }

    EventsBus.get().on(APP_EVENTS.MAINTENANCE.APPLY_TASKS.TASK.CREATED, this.onCreated);
    EventsBus.get().on(APP_EVENTS.MAINTENANCE.APPLY_TASKS.TASK.ERROR.CREATED, this.onErrorCreate);
  }

  componentWillUnmount(): void {
    EventsBus.get().off(APP_EVENTS.MAINTENANCE.APPLY_TASKS.TASK.CREATED, this.onCreated);
    EventsBus.get().off(APP_EVENTS.MAINTENANCE.APPLY_TASKS.TASK.ERROR.CREATED, this.onErrorCreate);
  }

  onCreated = (message) => {
    this.setState({ successMessage: message });
  };

  onErrorConverted = (message) => {
    this.setState({ errorConvertMessage: message });
  };

  onErrorCreate = (message) => {
    this.setState({ errorCreateMessage: message });
  };

  resetState = () => {
    this.setState({
      successMessage: null,
      errorCreateMessage: null,
      errorConvertMessage: null,
      isAllTasksSaved: false,
    });
  };

  createAllTasks = async () => {
    const {
      maintenancesStore: { applyTasks },
    } = this.props;

    await applyTasks.createAllTasks();

    if (applyTasks.isAllTasksCreated) {
      this.setState({ isAllTasksSaved: true });
    }
  };

  render() {
    const {
      maintenancesStore: { applyTasks },
    } = this.props;

    const { successMessage, errorCreateMessage, errorConvertMessage, isAllTasksSaved } = this.state;

    return (
      <div className="MaintenanceApplyTasks">
        <div className="MaintenanceApplyTasks-back">
          <BackButton link={PATHS.MAINTENANCE.INDEX}>Back to Maintenance</BackButton>
        </div>
        <div className="MaintenanceApplyTasks-header">
          <div className="MaintenanceApplyTasks-title">Apply Maintenance Task to Vehicles</div>
          <div className="MaintenanceApplyTasks-buttons">
            <div className="MaintenanceApplyTasks-buttonsCreatedInfo">
              {applyTasks.isAllTasksCreated ? (
                <div className="MaintenanceApplyTasks-buttonsCreatedInfoMessage">
                  <SuccessIcon className="MaintenanceApplyTasks-buttonsCreatedInfoMessageIcon" />
                  <span className="MaintenanceApplyTasks-buttonsCreatedInfoMessageText">All Tasks Created</span>
                </div>
              ) : Boolean(applyTasks.numberOfCreatedTask) ? (
                <div className="MaintenanceApplyTasks-buttonsCreatedInfoMessage">
                  <SuccessIcon className="MaintenanceApplyTasks-buttonsCreatedInfoMessageIcon" />
                  <span className="MaintenanceApplyTasks-buttonsCreatedInfoMessageText">
                    {applyTasks.numberOfCreatedTask} of {applyTasks.tasks.length} Tasks Created
                  </span>
                </div>
              ) : null}
            </div>
            <div className="MaintenanceApplyTasks-button MaintenanceApplyTasks-button--cancel">
              <Link to={PATHS.MAINTENANCE.INDEX}>
                <Button className="Button--cancel Button--cancelColorLynch" title="Cancel" />
              </Link>
            </div>
            <div className="MaintenanceApplyTasks-button MaintenanceApplyTasks-button--save">
              {applyTasks.isAllTasksCreated ? (
                <Link to={PATHS.MAINTENANCE.INDEX}>
                  <Button title="Return to Maintenance" className="Button--apply" />
                </Link>
              ) : (
                <Button
                  className="Button--apply"
                  onClick={this.createAllTasks}
                  sending={applyTasks.isLoadingAllTasks.value}
                  disabled={!Boolean(applyTasks.tasks.length) || !Boolean(applyTasks.isValid)}
                  title="Create All Tasks"
                />
              )}
            </div>
          </div>
        </div>
        <div className="MaintenanceApplyTasks-content">
          <div className="MaintenanceApplyTasks-sidebar">
            <ApplyTasksSidebar />
          </div>
          <div className="MaintenanceApplyTasks-table">
            <ApplyTasksTable />
          </div>
          {(applyTasks.isLoadingAllTasks.value || applyTasks.isAllTasksCreated) && (
            <div className="MaintenanceApplyTasks-content--disable" />
          )}
        </div>
        {successMessage && !isAllTasksSaved && (
          <Notification
            type="success"
            text={`Maintenance Task was successfully created. ${successMessage}`}
            title="Success!"
            onClose={this.resetState}
          />
        )}
        {errorCreateMessage && (
          <Notification
            type="error"
            text={`${errorCreateMessage}`}
            title="Task Failed to Create"
            onClose={this.resetState}
          />
        )}
        {errorConvertMessage && (
          <Notification
            type="error"
            text={`${errorConvertMessage}`}
            title="Convert to Task Failed"
            onClose={this.resetState}
          />
        )}
        {isAllTasksSaved && (
          <Notification
            type="success"
            text="All Maintenance Tasks successfully created"
            title="Success!"
            onClose={this.resetState}
          />
        )}
      </div>
    );
  }
}

export default MaintenanceApplyTasks;
