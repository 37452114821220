import React, { FC } from 'react';

import { DASHCAMS_SETTINGS_TYPES } from 'config';

import type { IDashcamSettingsField } from 'interfaces/models/Dashcams/IDashcamSettingsField';

import CameraSettingsItemTop from './CameraSettingsItemTop';
import CameraSettingsItemBoolean from './CameraSettingsItemBoolean';
import CameraSettingsItemEnum from './CameraSettingsItemEnum';
import CameraSettingsItemNumeric from './CameraSettingsItemNumeric';
import CameraSettingsItemExpandedBoolean from './CameraSettingsItemExpandedBoolean';

import CameraSettingsItemSubBoolean from './CameraSettingsItemSubBoolean';
import './styles.scss';

interface IProps {
  item: IDashcamSettingsField<any>;
  disabled?: boolean;
  onChange?: () => void;
  availableCameraTypes?: string[];
}

const CameraSettingsItem: FC<IProps> = ({ item, disabled, onChange = () => void 0, availableCameraTypes = [] }) => {
  switch (item.level) {
    case 0:
      switch (item.type) {
        case DASHCAMS_SETTINGS_TYPES.TOGGLE:
          return (
            <CameraSettingsItemTop item={item} disabled={disabled} availableCameraTypes={availableCameraTypes} toggle />
          );
        default:
          return <CameraSettingsItemTop item={item} disabled={disabled} availableCameraTypes={availableCameraTypes} />;
      }
    case 1:
      switch (item.type) {
        case DASHCAMS_SETTINGS_TYPES.TOGGLE:
          if (item.settings) {
            return <CameraSettingsItemExpandedBoolean item={item} disabled={disabled} onChange={onChange} />;
          } else {
            return (
              <CameraSettingsItemBoolean item={item} availableCameraTypes={availableCameraTypes} disabled={disabled} />
            );
          }
        case DASHCAMS_SETTINGS_TYPES.ENUM:
          return <CameraSettingsItemEnum item={item} disabled={disabled} />;
        case DASHCAMS_SETTINGS_TYPES.NUMBER:
          return <CameraSettingsItemNumeric item={item} disabled={disabled} />;
        default:
          return null;
      }
    case 2:
      switch (item.type) {
        case DASHCAMS_SETTINGS_TYPES.TOGGLE:
          if (item.settings) {
            return <CameraSettingsItemSubBoolean item={item} disabled={disabled} onChange={onChange} />;
          } else {
            return <CameraSettingsItemBoolean item={item} disabled={disabled} />;
          }
        case DASHCAMS_SETTINGS_TYPES.ENUM:
          return <CameraSettingsItemEnum item={item} disabled={disabled} />;
        case DASHCAMS_SETTINGS_TYPES.NUMBER:
          return <CameraSettingsItemNumeric item={item} disabled={disabled} />;
        default:
          return null;
      }
    case 3:
      switch (item.type) {
        case DASHCAMS_SETTINGS_TYPES.TOGGLE:
          return <CameraSettingsItemBoolean item={item} disabled={disabled} />;
        case DASHCAMS_SETTINGS_TYPES.ENUM:
          return <CameraSettingsItemEnum item={item} disabled={disabled} />;
        case DASHCAMS_SETTINGS_TYPES.NUMBER:
          return <CameraSettingsItemNumeric item={item} disabled={disabled} />;
        default:
          return null;
      }
    default:
      return null;
  }
};

export default CameraSettingsItem;
