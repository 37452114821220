import React, { useState } from 'react';
import { Observer } from 'mobx-react';
import { Radio, Space } from 'antd';
import ConfirmationModal from 'components/Admin/ConfirmationModal';
import CalendarWithInput from 'components/CalendarWithInput';
import type { SimpleField } from 'models/Fields';

import './styles.scss';

interface IProps {
  isApplyCardData: boolean;
  closeApplyCardData: () => void;
  saveFuelCards: () => void;
  applyData: SimpleField<number>;
}

const WEXFuelCardApplyCardData: React.FC<IProps> = ({
  isApplyCardData,
  closeApplyCardData,
  saveFuelCards,
  applyData,
}) => {
  const [radio, setRadio] = useState(0);

  const handleChangeRadio = (e) => {
    const radioType = e.target.value;

    setRadio(radioType);

    if (!radioType) {
      applyData.set(null);
    } else {
      applyData.set(Date.now());
    }
  };

  const changeApplyDate = (timestamp) => {
    applyData.set(timestamp);
  };

  return (
    <Observer
      render={() => (
        <ConfirmationModal
          className="WEXFuelCardApplyCardData"
          title="Apply Card Data"
          applyButtonTitle="Apply"
          isOpen={isApplyCardData}
          onCancel={closeApplyCardData}
          onSubmit={saveFuelCards}
        >
          <p className="WEXFuelCardApplyCardData-description">
            Choose the historical date range that card was associated with vehicle.
          </p>
          <div className="WEXFuelCardApplyCardData-options">
            <p className="WEXFuelCardApplyCardData-optionsText">Apply Card-Vehicle assignment(s) for:</p>
            <Radio.Group onChange={handleChangeRadio} value={radio}>
              <Space direction="vertical">
                <div className="WEXFuelCardApplyCardData-option">
                  <Radio value={0} className="WEXFuelCardApplyCardData-optionRadio">
                    <span className="WEXFuelCardApplyCardData-optionRadio--text">All historical data</span>
                  </Radio>
                </div>
                <div className="WEXFuelCardApplyCardData-option">
                  <Radio value={1} className="WEXFuelCardApplyCardData-optionRadio">
                    <span className="WEXFuelCardApplyCardData-optionRadio--text">All data starting:</span>
                  </Radio>
                  <div className="WEXFuelCardApplyCardData-option--calendar">
                    <CalendarWithInput
                      onSubmit={changeApplyDate}
                      value={applyData.value || Date.now()}
                      onlyPast
                      disabled={!Boolean(radio)}
                    />
                  </div>
                </div>
              </Space>
            </Radio.Group>
          </div>
        </ConfirmationModal>
      )}
    />
  );
};

export default WEXFuelCardApplyCardData;
