import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import type { CustomersStore } from 'stores';

import './styles.scss'

interface IProps {
  customersStore?: CustomersStore;
}

const CUSTOMER_NOTE_EMPTY = '-';

@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class CustomerPanelNote extends Component<IProps> {

  private get text() {
    const {
      customersStore: {
        selectedCustomer,
      },
    } = this.props;

    return selectedCustomer.notes || CUSTOMER_NOTE_EMPTY
  }

  render() {

    return (
      <div className="MapCustomerPanelNote-text">
        {this.text}
      </div>
    );
  }
}


export default CustomerPanelNote;
