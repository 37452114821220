import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import { SimpleField } from 'models/Fields';
import { repositoryService } from 'services';
import { KM_TO_MI } from 'config';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { action, observable } from 'mobx';

class MaintenanceAssetDetails {
  odometer: SimpleField<number>;
  engHours: SimpleField<number>;
  avgEngineHoursPerDay: SimpleField<number>;
  avgMileagePerDay: SimpleField<number>;
  repository: IRepository;
  @observable repositoryId: IEntityRepository;

  constructor({
    odometer,
    engHours,
    avgEngineHoursPerDay,
    avgKmPerDay,
  }: {
    odometer: number;
    engHours: number;
    avgEngineHoursPerDay: number;
    avgKmPerDay: number;
  }) {
    this.repository = repositoryService.get('assets');
    this.repositoryId = null;
    this.odometer = new SimpleField(Number((odometer * KM_TO_MI).toFixed(1)));
    this.engHours = new SimpleField(engHours);
    this.avgEngineHoursPerDay = new SimpleField(avgEngineHoursPerDay);
    this.avgMileagePerDay = new SimpleField(Number((avgKmPerDay * KM_TO_MI).toFixed(1)));
  }

  @action fetch = async (id) => {
    this.repositoryId = this.repository.entity(id);

    const response = await this.repositoryId.get();

    if (response.trackerDetails) {
      this.odometer.set(Number((response.reportedOdometer * KM_TO_MI).toFixed(1)));
      this.engHours.set(response.reportedEngineHours.toFixed(1));
      EventsBus.get().trigger(APP_EVENTS.MAINTENANCE.TASK.ASSET.CHANGED);
    }
  };

  get serverModel() {
    return {
      odometer: Number((this.odometer.value / KM_TO_MI).toFixed(1)),
      engHours: this.engHours.value,
    };
  }
}

export default MaintenanceAssetDetails;
