import React, { Fragment, Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import { PATHS } from 'config';
import Layout from 'components/Layout';
import OfflineContainer from './OfflineContainer';

interface IProps extends RouteComponentProps {
  renderSideBar: () => React.ReactNode;
  children: React.ReactNode;
}

class LayoutContainer extends Component<IProps> {
  render() {
    const {
      renderSideBar,
      children,
      location: { pathname },
    } = this.props;

    const isLayoutContentScrollable = PATHS.DASHCAMS.AD.includes(pathname);

    return (
      <Fragment>
        <OfflineContainer />
        <Layout renderSideBar={renderSideBar} isLayoutContentScrollable={isLayoutContentScrollable}>
          {children}
        </Layout>
      </Fragment>
    );
  }
}

export default withRouter(LayoutContainer);
