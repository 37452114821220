import React, { FC, useRef, useEffect } from 'react';
import { parse } from 'query-string';

import Map from 'components/Map';
import Marker from 'components/Map/Marker';
import { StreetViewPanorama } from 'react-google-maps';

import './styles.scss';

interface IQuerySearchParams {
  lat?: string;
  lng?: string;
  mapType?: string;
  liveView?: string;
}

const MapInPopup: FC = () => {
  const mapRef = useRef(null);
  const { lat, lng, mapType, liveView } = parse(window.location.search) as IQuerySearchParams;
  const location = { lat: Number(lat), lng: Number(lng) };

  useEffect(() => {
    document.querySelector('html').classList.add('popup');

    return () => {
      document.querySelector('html').classList.remove('popup');
    };
  }, []);

  return (
    <Map
      center={location}
      containerElement={<div style={{ height: '100vh', width: '100%' }} />}
      googleMapURL={process.env.REACT_APP_GOOGLE_MAP_URL}
      loadingElement={<div style={{ height: `100%` }} />}
      map={mapRef}
      mapElement={<div style={{ height: `100%` }} />}
      mapTypeId={mapType}
      options={{
        fullscreenControl: false,
        rotateControl: false,
        tilt: 0,
      }}
      zoom={16}
    >
      <Marker position={location} icon={null} />
      <StreetViewPanorama
        options={{ enableCloseButton: false, disableDefaultUI: true }}
        position={location}
        visible={liveView === 'true'}
      />
    </Map>
  );
};

export default MapInPopup;
