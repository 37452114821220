import { observable, action, computed } from 'mobx';
import type Dashcam from './index';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { DashcamSettingFactory } from './DashcamSettingFactory';
import type { IDashcamSettingsField } from 'interfaces/models/Dashcams/IDashcamSettingsField';
import { repositoryService } from 'services';

const extractValues = (obj) => {
  return (
    obj &&
    obj.reduce((acc, curr) => {
      let items = [];
      if (curr.id && curr.visible?.value !== false) {
        const item = {
          id: curr.id,
          value:
            curr.value?.value !== undefined
              ? curr.value.value
              : curr.value?.isValid !== undefined
              ? undefined
              : curr.value,
        };
        if (item.value !== undefined) {
          items = [item];
        }
      }
      if (curr.settings) {
        items = [...items, ...extractValues(curr.settings)];
      }
      return [...acc, ...items];
    }, [])
  );
};

const isSettingsChanged = (settings) => {
  return (
    settings?.find((item) => item.value?.isUpdated || (item?.settings && isSettingsChanged(item?.settings))) || false
  );
};

const resetSettings = (settings) => {
  return settings?.forEach((item) => {
    item.value?.reset?.();
    if (item?.settings) {
      resetSettings(item?.settings);
    }
  });
};

export class DashcamSettings {
  @observable list: Array<IDashcamSettingsField<any>> = [];
  @observable extended: Array<IDashcamSettingsField<any>> = [];
  @observable linked: Array<IDashcamSettingsField<any>> = [];
  context: Dashcam;
  repository: IEntityRepository;
  repositorySettings: IEntityRepository;
  repositorySettingsExtended: IEntityRepository;

  constructor(context: Dashcam) {
    this.context = context;
    this.repository = repositoryService.get('media', 'v2.0').entity('cameras');
    this.repositorySettings = this.repository.entity('').entity('settings');
    this.repositorySettingsExtended = this.repository.entity('').entity('settings');
  }

  @action fetch = async () => {
    const response = await this.repositorySettings.get();

    this.list = this.repositorySettings.getState.success
      ? response.settings.map((setting) => new DashcamSettingFactory(this, setting, 0).get())
      : [];

    const extended = await this.repositorySettingsExtended.get();

    this.extended = this.repositorySettingsExtended.getState.success
      ? extended.settings.map((setting) => new DashcamSettingFactory(this, setting, 0).get())
      : [];
    this.linkSettings(this.extended);
  };

  @action linkSettings(settings: Array<IDashcamSettingsField<any>>) {
    settings.forEach((setting) => {
      if (setting.settings?.length) {
        this.linkSettings(setting.settings);
      }

      if (setting.showIf?.id) {
        const showIfLink = this.getSettingById(setting.showIf.id);
        if (showIfLink && showIfLink.value.value !== setting.showIf.value) {
          setting.blocked.value = setting.showIf.id;
          setting.visible.toggle(false);
        }
      }

      if (setting.linkedSettings?.length) {
        this.updateLinkSettings(setting.linkedSettings, setting.value.value, setting.name);
      }
    });
  }

  @action updateShowIfSettings = (settings: Array<IDashcamSettingsField<any>> = this.extended) => {
    settings.forEach((setting) => {
      if (setting.settings?.length) {
        this.updateShowIfSettings(setting.settings);
      }

      if (setting.showIf?.id) {
        const showIfLink = this.getSettingById(setting.showIf.id);
        setting.visible.toggle(showIfLink.value.value === setting.showIf.value);
      }
    });
  };

  @action updateLinkSettings = (settings: string[], value: boolean, name: string) => {
    settings?.forEach((id) => {
      const child = this.getSettingById(id);
      if (child) {
        child.blocked.value = !value ? name : undefined;
      }
    });
  };

  getSettingById = (value: string, parent?: Array<IDashcamSettingsField<any>>) => {
    const settings = parent || this.extended;
    let found;
    settings.forEach((setting) => {
      if (!found && setting.id === value) {
        found = setting;
      }
      if (!found && setting.settings?.length) {
        found = this.getSettingById(value, setting.settings);
      }
    });
    return found;
  };

  @action reset = () => {
    this.list.forEach((item) => item.reset?.());
    resetSettings(this.extended);
  };

  @action update = async () => {
    const data = this.extended.map((item) => item.model);
    await this.repositorySettingsExtended.patch({ settings: extractValues(data) });

    if (!this.repositorySettingsExtended.patchState.success) {
      this.reset();
    } else {
      await this.fetch();
    }
  };

  @computed get changed() {
    return !!isSettingsChanged(this.extended);
  }

  setRepositorySettings = (id: string) => {
    this.repositorySettings = this.repository.entity(id).entity('settings');
    this.repositorySettingsExtended = this.repository.entity(id).entity('settings').entity('extended');
    return this;
  };

  @computed get hasSettingsError() {
    return this.extended.some((item) => item.error);
  }
}
