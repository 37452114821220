import { computed, observable } from 'mobx';
import find from 'lodash/find';

import timeStore from 'stores/TimeStore';
import { DATE_TIME_FORMATS, MI_TO_KM } from 'config';
import { getFormattedTime } from 'utils';

class MediaEventModel {
  @observable address: string;
  batchId: string;
  @observable deviceId: string;
  @observable displayName: string;
  @observable eventTimestamp: number;
  @observable expiringSoon: boolean;
  @observable statusCodeText: string;
  @observable status: string;
  @observable outsideCameraVideo: string;
  @observable insideCameraVideo: string;
  @observable outsideCameraImage: string;
  @observable insideCameraImage: string;
  @observable eventName: string;
  @observable lastUpdate: number;
  @observable location: Locations.ILocation;
  @observable listOfGPSEvents: Media.IMediaServerGPS[];
  @observable route: Locations.ILocation[];
  @observable chartData: number[];
  @observable thumbnailProps: Media.IThumbnailProps;
  cameraTypes: string[];

  constructor(event?: Media.IServerMediaEvent) {
    const mediaObject = event ? event.mediaObject : [];
    const videoMedia = event ? mediaObject.filter(({ type }) => type?.toLowerCase() === 'video') : [];
    const imageMedia = event ? mediaObject.filter(({ type }) => type?.toLowerCase() === 'image') : [];
    const mediaVideoOutside = find(videoMedia, { channel: 'outside' });
    const mediaVideoInside = find(videoMedia, { channel: 'inside' });
    const mediaImageOutside = find(imageMedia, { channel: 'outside' });
    const mediaImageInside = find(imageMedia, { channel: 'inside' });
    const mediaEvent = mediaVideoOutside || mediaVideoInside || mediaImageOutside || mediaImageInside || {};

    const gps = mediaEvent.gps ? mediaEvent.gps : [];

    const validObject = find(mediaObject, ({ status }) => status === event?.status);
    const { thumbnailUrl, type } = validObject || this.initialMediaObject;
    const status: Media.mediaStatus = event?.status?.toLowerCase() as Media.mediaStatus;

    this.address = event ? event.address : '';
    this.batchId = event ? event.batchId : null;
    this.chartData = gps.map(({ speed }) => Math.trunc(speed * MI_TO_KM));
    this.deviceId = event ? event.deviceId : '';
    this.displayName = event ? event.displayName : '';
    this.eventName = mediaEvent.title;
    this.eventTimestamp = event ? event.eventTimestamp : 0;
    this.expiringSoon = event ? event.expiringSoon : false;
    this.insideCameraImage = mediaImageInside ? mediaImageInside.accessUrl : null;
    this.insideCameraVideo = mediaVideoInside ? mediaVideoInside.accessUrl : null;
    this.lastUpdate = event ? event.lastUpdate : null;
    this.listOfGPSEvents = gps;
    this.location = mediaEvent.location;
    this.outsideCameraImage = mediaImageOutside ? mediaImageOutside.accessUrl : null;
    this.outsideCameraVideo = mediaVideoOutside ? mediaVideoOutside.accessUrl : null;
    this.statusCodeText = event ? event.statusCodeText : '';
    this.status = status;
    this.thumbnailProps = { thumbnailUrl, type, status };
    this.route = gps.map(({ latitude, longitude }) => ({
      lat: latitude,
      lng: longitude,
    }));
    this.cameraTypes = [
      ...(mediaVideoOutside || mediaImageOutside ? ['OUTSIDE'] : []),
      ...(mediaVideoInside || mediaImageInside ? ['INSIDE'] : []),
    ];
  }

  get initialMediaObject() {
    return {
      thumbnailUrl: '',
      type: '',
    };
  }

  @computed get mediaTime() {
    const timezone = timeStore.sessionTimezone;
    return getFormattedTime(this.eventTimestamp, DATE_TIME_FORMATS.hoursMinutesSecondsAm, timezone);
  }
}

export default MediaEventModel;
