import React from 'react';
import { MEDIA_CLIP_STATUS, MEDIA_CLIP_TYPE } from 'config';
import { BlockIcon, CameraIcon, PlayButtonIcon } from 'assets';
import type MediaInfoItem from 'models/Dashcam/MediaLibrary/MediaClips/MediaClipsItem/MediaInfo/MediaInfoItem';

import './styles.scss';

interface IProps {
  infoItem: MediaInfoItem;
  onClick?: () => void;
}

const MediaLibraryClipImage: React.FC<IProps> = ({ infoItem, onClick }) => {
  const availableImage = () => (
    <div className="MediaLibraryClipImage-available" onClick={onClick}>
      <img src={infoItem.thumbnailUrl} />
      {infoItem.type === MEDIA_CLIP_TYPE.IMAGE && (
        <i>
          <CameraIcon />
        </i>
      )}
    </div>
  );

  const unavailableImage = () => (
    <div className="MediaLibraryClipImage-unavailable">
      <BlockIcon width={32} height={32} className="MediaLibraryClipImage-unavailableIcon" />
      <p className="MediaLibraryClipImage-unavailableText">Media unavailable</p>
    </div>
  );

  const failedImage = () => (
    <div className="MediaLibraryClipImage-failed">
      <p className="MediaLibraryClipImage-failedText">Request failed</p>
      <p className="MediaLibraryClipImage-failedAction" onClick={infoItem.replay}>
        Retry
      </p>
    </div>
  );

  const queuedImage = () => (
    <div className="MediaLibraryClipImage-queued">
      <PlayButtonIcon width={32} height={32} className="MediaLibraryClipImage-queuedIcon" />
      <p className="MediaLibraryClipImage-queuedText">Media queued</p>
    </div>
  );

  const requestedImage = () => (
    <div className="MediaLibraryClipImage-requested">
      <p className="MediaLibraryClipImage-requestedText">Waiting for Camera...</p>
    </div>
  );

  return (
    <div className="MediaLibraryClipImage">
      {infoItem.status.value === MEDIA_CLIP_STATUS.AVAILABLE && availableImage()}
      {infoItem.status.value === MEDIA_CLIP_STATUS.UNAVAILABLE && unavailableImage()}
      {infoItem.status.value === MEDIA_CLIP_STATUS.FAILED && failedImage()}
      {infoItem.status.value === MEDIA_CLIP_STATUS.QUEUED && queuedImage()}
      {infoItem.status.value === MEDIA_CLIP_STATUS.REQUESTED && requestedImage()}
    </div>
  );
};

export default MediaLibraryClipImage;
