import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import type { MediaManager, TimeStore } from 'stores';
import { getFormattedTime } from 'utils';
import { DATE_TIME_FORMATS } from 'config';

import { DASHCAM_STATUS } from 'models/Dashcam/DashcamStatus';

import MediaManagerDetails from 'components/DashCams/MediaManagerDetails';
import JournalBar from 'components/DashCams/JournalBar';
import RequestMediaCalendar from 'components/DashCams/RequestMediaCalendar';
import InfoTooltip from 'components/InfoTooltip';

import './styles.scss';

interface IProps {
  mediaManagerStore?: MediaManager;
  timeStore?: TimeStore;
  timestamp?: number;
}

@inject(({ mediaManagerStore, timeStore }) => ({ mediaManagerStore, timeStore }))
@observer
class MediaManagerXirgo extends Component<IProps> {
  handleChangeDate = (date: string) => {
    const {
      mediaManagerStore: {
        checkout: {
          camerasJournal,
          requestMedia: { timeSegment },
        },
      },
      timeStore: { sessionTimezone },
    } = this.props;

    if (this.isOffline) {
      const timeString = getFormattedTime(
        timeSegment.startTime.value,
        DATE_TIME_FORMATS.hoursMinutesSeconds24,
        sessionTimezone
      );
      const dateString = `${date} ${timeString}`;
      const timestamp = moment
        .tz(
          dateString,
          `${DATE_TIME_FORMATS.monthDatYearFull} ${DATE_TIME_FORMATS.hoursMinutesSeconds24}`,
          sessionTimezone
        )
        .valueOf();

      timeSegment.startTime.set(timestamp);
    } else {
      camerasJournal.dates.select(date);
    }
  };

  get isOnline() {
    const {
      mediaManagerStore: {
        checkout: { dashcamStatus },
      },
    } = this.props;

    return dashcamStatus.status.value === DASHCAM_STATUS.ONLINE;
  }

  get isOffline() {
    const {
      mediaManagerStore: {
        checkout: { dashcamStatus },
      },
    } = this.props;

    return dashcamStatus.status.value === DASHCAM_STATUS.OFFLINE || dashcamStatus.cameraDetails.isBusy.value;
  }

  get selectedDate() {
    const {
      mediaManagerStore: {
        checkout: { camerasJournal },
      },
    } = this.props;

    return camerasJournal.dates.active;
  }

  get formattedStartDate() {
    const {
      mediaManagerStore: {
        checkout: {
          requestMedia: { timeSegment },
        },
      },
      timeStore: { sessionTimezone },
    } = this.props;

    return timeSegment.startTime.value
      ? getFormattedTime(timeSegment.startTime.value, DATE_TIME_FORMATS.monthDatYearFull, sessionTimezone)
      : null;
  }

  get selectedCameraDate() {
    const {
      mediaManagerStore: {
        checkout: { camerasJournal },
      },
    } = this.props;

    return camerasJournal.dates.model[this.selectedDate];
  }

  get isIntervalSelected() {
    const {
      mediaManagerStore: {
        checkout: { camerasJournal },
      },
    } = this.props;

    return camerasJournal.selectByTimestamp(this.props.timestamp);
  }

  get selectedCameraInterval() {
    return this.selectedCameraDate && this.selectedCameraDate.findSelectedInterval();
  }

  render() {
    const {
      mediaManagerStore: {
        checkout: {
          vehicle,
          dashcamStatus,
          camerasJournal,
          requestMedia: { timeSegment },
        },
      },
      timeStore: { sessionTimezone },
    } = this.props;

    return (
      <div className="RequestMediaXirgo">
        <p className="RequestMediaXirgo-header dimmed-grey">Chose the date, time, and clip duration to request</p>
        <p className="RequestMediaXirgo-subHeader dimmed-grey">
          {dashcamStatus.status.value === DASHCAM_STATUS.ONLINE
            ? 'Blue segments represent the times the camera was recording'
            : 'Media request will be queued and requested from the camera when camera is online'}
        </p>
        <div className="RequestMediaXirgo-details dimmed-grey">
          <MediaManagerDetails name={vehicle.details.displayName} dashcamPing={dashcamStatus} />
        </div>
        <div className="RequestMediaXirgo-calendar dimmed-grey">
          <RequestMediaCalendar
            firstMediaDate={!this.isOffline && camerasJournal.dates.oldest}
            daysWithMediaItems={!this.isOffline && camerasJournal.dates.list}
            onSelectDay={this.handleChangeDate}
            selectedDay={!this.isOffline ? this.selectedDate : this.formattedStartDate}
            offline={this.isOffline}
            segment={timeSegment}
            timezone={sessionTimezone}
            duration={timeSegment.duration}
          />
        </div>
        <div className="RequestMediaXirgo-journalBar dimmed-grey">
          <div className="RequestMediaXirgo-journalBarHeader">
            <p className="RequestMediaXirgo-journalBarDate">
              {moment(!this.isOffline ? this.selectedDate : this.formattedStartDate, 'MM/DD/YYYY').format(
                DATE_TIME_FORMATS.fullMonthComaDateYear
              )}
            </p>
            <InfoTooltip>The blue segments indicate the camera has media for this time period.</InfoTooltip>
          </div>
          <div className="RequestMediaXirgo-journalBarComponent">
            <JournalBar
              date={this.isOffline ? this.formattedStartDate : camerasJournal.dates.active}
              intervals={!this.isOffline ? this.selectedCameraDate?.intervals : []}
              customInterval={camerasJournal.customInterval}
              tz={sessionTimezone}
              isOffline={this.isOffline}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MediaManagerXirgo;
