import { observable, action } from 'mobx';

import FiltersBase from '../FiltersBase';
import { getJSONItemFromStorage, setJSONItemToStorage } from '../../utils';
import { STORAGE_ITEMS } from '../../config';

export default class GeozonesFilters extends FiltersBase {
  @observable selectedColorIds: number[] = getJSONItemFromStorage(STORAGE_ITEMS.map.geozones.selectedColors, []);

  @action setSelectedColors = (id: number) => {
    const index = this.selectedColorIds.indexOf(id);

    if (index === -1) {
      this.selectedColorIds.push(id);
    } else {
      this.selectedColorIds.splice(index, 1);
    }

    setJSONItemToStorage(STORAGE_ITEMS.map.geozones.selectedColors, this.selectedColorIds);
  };

  @action resetSelectedColors = () => {
    this.selectedColorIds = [];
    setJSONItemToStorage(STORAGE_ITEMS.map.geozones.selectedColors, this.selectedColorIds);
  };
}
