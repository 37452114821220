import React from 'react';
import { observer } from 'mobx-react';
import ConfirmationModal from 'components/Admin/ConfirmationModal';

import './styles.scss';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const AlertCancelModal: React.FC<IProps> = ({ isOpen, onClose, onSubmit }) => {
  return (
    <ConfirmationModal
      applyButtonTitle="Yes, Continue"
      cancelButtonTitle="No, Cancel"
      isOpen={isOpen}
      className="AlertCancelModal"
      onCancel={onClose}
      onSubmit={onSubmit}
      title="Continue Without Saving?"
      contentStyles={{ padding: '0 20px', backgroundColor: '#fff' }}
      withoutCloseIcon
    >
      <div className="AlertCancelModal-content">
        <p className="AlertCancelModal-text AlertCancelModal-text--top">
          Changes made to this alert will not be saved.
        </p>
      </div>
    </ConfirmationModal>
  );
};

export default observer(AlertCancelModal);
