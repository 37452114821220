import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { PersistenceStore } from 'stores';

import SearchableSelect from 'components/Select/SearchableSelect';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';

interface IProps {
  disabled?: boolean;
  emptySearchResults?: string;
  handleChange?: (option: Select.ISelectOption) => void;
  isSearchable?: boolean;
  noAll?: boolean;
  persistChange?: boolean;
  persistenceStore?: PersistenceStore;
  placeholder?: string;
  searchPlaceholder?: string;
  value?: Select.ISelectOption;
  valueSource?: 'id' | 'driverFobId';
  selectFirst?: boolean;
}
@inject(({ persistenceStore }) => ({ persistenceStore }))
@observer
class SearchableDriverSelect extends Component<IProps> {
  static defaultProps = {
    placeholder: 'Select Driver',
    searchPlaceholder: 'Search Drivers',
    emptySearchResults: 'No Drivers Found',
    valueSource: 'id',
  };

  repositoryDriversSearch: IEntityRepository;

  constructor(props) {
    super(props);
    if (props.persistChange) {
      if (props.persistenceStore.selectedDriver && !props.value) {
        this.props.handleChange(props.persistenceStore.selectedDriver);
      }
    }

    this.repositoryDriversSearch = repositoryService.get('drivers').entity('ids');
  }

  static allOption = { value: 'all', label: 'All Drivers' };

  getData = (props: { page: number; pageSize: number; filter: string }) => {
    const data = { page: props.page || 0, pageSize: props.pageSize || 50, searchValue: props.filter || '' };

    return this.repositoryDriversSearch.get(data).then((data) => {
      const totalPages = data.totalPages;

      const options = data.items
        .map((item) => {
          return {
            value: (this.props.valueSource === 'driverFobId' ? `${item.driverFobId}|${item.id}` : item.id).toString(),
            label: item.name,
          };
        })
        .filter((item) => item.label);

      if (!props.filter && !this.props.noAll && props.page === 0) {
        options.unshift(SearchableDriverSelect.allOption);
      }

      if (this.props.selectFirst && !this.props.value?.value && options.length) {
        this.props.handleChange(options[0]);
      }

      return {
        totalPages,
        options,
      };
    });
  };

  handleChange = (option: Select.ISelectOption) => {
    this.props.handleChange(option);
    if (this.props.persistChange) {
      this.props.persistenceStore.updateSelectedDriver({ ...option });
    }
  };

  render() {
    return <SearchableSelect {...this.props} getData={this.getData} handleChange={this.handleChange} sortOptions />;
  }
}

export default SearchableDriverSelect;
