import React from 'react';
import classnames from 'classnames';

import CollapsibleButton from 'components/CollapsibleButton';

import './styles.scss';

interface IProps {
  isExpanded: boolean;
  onClick: () => void;
}

const ReportsNavigationCollapsibleButton: React.FC<IProps> = ({ isExpanded, onClick }) => {
  const cn = classnames('ReportsNavigationCollapsibleButton', {
    'ReportsNavigationCollapsibleButton--collapsed': !isExpanded,
  });

  return (
    <div className={cn}>
      <CollapsibleButton
        isExpanded={isExpanded}
        onClick={onClick}
        className={`ReportsNavigationCollapsibleButton-button`}
      />
    </div>
  );
};

export default ReportsNavigationCollapsibleButton;
