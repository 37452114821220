import { computed, IReactionDisposer, observable, reaction } from 'mobx';
import debounce from 'lodash/debounce';
import MediaSource from './MediaSource/MediaSource';
import MediaType from './MediaType/MediaType';
import MediaClips from './MediaClips';
import Redirect from './Redirect';
import MediaLibraryLoading, { MEDIA_LIBRARY_LOADING_STATE } from './MediaLibraryLoading';
import { MEDIA_CLIPS_VIEW } from 'config';

export default class MediaLibrary {
  @observable source: MediaSource;
  clips: MediaClips;
  type: MediaType;
  redirect: Redirect;
  loading: MediaLibraryLoading;
  initialized: boolean;
  view: MEDIA_CLIPS_VIEW;
  disposerSource: IReactionDisposer;
  disposerType: IReactionDisposer;
  disposerError: IReactionDisposer;
  debouncedFetchType: () => void;
  debouncedFetchMedia: () => void;

  constructor(view = MEDIA_CLIPS_VIEW.MEDIA_LIBRARY) {
    this.source = new MediaSource();
    this.type = new MediaType(this);
    this.clips = new MediaClips(this, view);
    this.redirect = new Redirect(this);
    this.loading = new MediaLibraryLoading();
    this.initialized = false;
    this.view = view;

    this.debouncedFetchType = debounce(() => {
      this.loading.state.set(MEDIA_LIBRARY_LOADING_STATE.FULL);
      this.postponedLoading(() => this.type.fetch());
    }, 300);

    this.debouncedFetchMedia = debounce(() => {
      if (this.loading.state.value === MEDIA_LIBRARY_LOADING_STATE.NONE) {
        this.loading.state.set(MEDIA_LIBRARY_LOADING_STATE.CLIPS);
      }

      this.postponedLoading(() => this.clips.fetch());
    }, 777);

    this.disposerError = reaction(
      () => [this.clips.repository.getState.error, this.type.repository.getState.error],
      (e) => e && this.loading.state.set(MEDIA_LIBRARY_LOADING_STATE.ERROR),
      { name: 'Set loading error on media failed request' }
    );
  }

  @computed get params() {
    return { ...this.source.params, ...this.type.params };
  }

  init = async ({
    assetId,
    assetGroupId,
    from,
    to,
    expiringSoon,
    includeEventTypes,
    excludeEventTypes,
    mediaTypes,
    timestamp,
  }: {
    assetId?: string;
    assetGroupId?: string;
    from?: string;
    to?: string;
    expiringSoon?: boolean;
    includeEventTypes?: string | string[];
    excludeEventTypes?: string | string[];
    mediaTypes?: string | string[];
    timestamp?: string;
  }) => {
    this.source.init({ assetId, assetGroupId, from, to, expiringSoon, mediaType: mediaTypes });
    this.redirect.init({ assetId, timestamp });
    this.type.initFilters({ includeEventTypes, excludeEventTypes });
    this.loading.state.set(MEDIA_LIBRARY_LOADING_STATE.FULL);

    await this.type.fetch();
    await this.clips.fetch();

    if (this.type.repository.getState.success && this.clips.repository.getState.success) {
      this.loading.state.set(MEDIA_LIBRARY_LOADING_STATE.NONE);
    }

    this.subscribeChanges();
    this.initialized = true;
  };

  subscribeChanges = () => {
    this.disposerSource = reaction(() => this.source.params, this.debouncedFetchType, {
      name: 'Get total on source change',
    });
    this.disposerType = reaction(() => this.type.params, this.debouncedFetchMedia, {
      name: 'Get media on type filters change',
    });
  };

  unsubscribeChanges = () => {
    if (this.initialized) {
      this.disposerSource();
      this.disposerType();
    }
  };

  destroy = () => {
    this.disposerError();
    this.type.destroy();
    this.unsubscribeChanges();
  };

  get isMediaLibraryView() {
    return this.view === MEDIA_CLIPS_VIEW.MEDIA_LIBRARY;
  }

  private postponedLoading = async (fetch: () => void) => {
    if (this.loading.timeout) {
      clearTimeout(this.loading.timeout);
    }

    await fetch();

    this.loading.timeout = setInterval(() => {
      if (this.type.repository.getState.success && this.clips.repository.getState.success) {
        this.loading.state.set(MEDIA_LIBRARY_LOADING_STATE.NONE);
      }
    }, 1000);
  };
}
