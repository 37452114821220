import React, { FC } from 'react';
import classNames from 'classnames';
import { Link, RouteComponentProps } from 'react-router-dom';

import { LINKS } from 'config';
import type { Driver } from 'models';
import Button from 'components/Button';

import './styles.scss';

interface IProps extends RouteComponentProps {
  className?: string;
  driversList: Driver[];
}

const WelcomeScreen: FC<IProps> = ({ className, driversList, history: { push } }) => {
  const cn = classNames('WelcomeScreen', className);
  const onSendDispatch = () => {
    push('?dispatchModal=send');
  };

  return (
    <div className={cn}>
      <div className="WelcomeScreen-sendButton">
        <Button
          className="Button--apply"
          disabled={!driversList.length}
          inline
          onClick={onSendDispatch}
          title="New Dispatch"
        />
      </div>
      <div className="WelcomeScreen-content">
        {Boolean(driversList.length) ? (
          <p className="WelcomeScreen-message">
            Active Dispatches will appear here. Click <Link to="?dispatchModal=send">New Dispatch</Link> to get started.
          </p>
        ) : (
          <p className="WelcomeScreen-message">
            In order to use this feature, drivers will need to be created. <br />
            Please visit the{' '}
            <a className="WelcomeScreen-messageLink" target="_blank" href={LINKS.portalDriverAdmin}>
              Driver Management
            </a>{' '}
            page to get started.
          </p>
        )}
      </div>
    </div>
  );
};

export default WelcomeScreen;
