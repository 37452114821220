import React, { FC } from 'react';
import Modal from 'components/Modal';
import Button from 'components/Button';

import './styles.scss';

interface IProps {
  name: string;
  onCancel: () => void;
  onSubmit: () => void;
  open: boolean;
  loading: boolean;
  allAssets: boolean;
  assets: string[];
}

const ConfirmDeactivateFormModal: FC<IProps> = ({ name, open, onCancel, onSubmit, loading, allAssets, assets }) => {
  return (
    <Modal className="Modal--confirmDeactivate" isModalOpen={open} onClose={onCancel} withoutCloseIcon>
      <div className="ConfirmDeactivateFormModal">
        <p className="ConfirmDeactivateFormModal-title">Deactivate {name}?</p>
        <p className="ConfirmDeactivateFormModal-text">
          Deactivating this form will remove access for {allAssets ? 'All Vehicles' : `${assets.join(',')} of Vehicles`}
          .
        </p>
        <p className="ConfirmDeactivateFormModal-question">Do you want to continue?</p>

        <div className="ConfirmDeactivateFormModal-buttons">
          <div className="ConfirmDeactivateFormModal-button ConfirmDeactivateFormModal-button--cancel">
            <Button className="Button Button--cancel" title="No, Cancel" onClick={onCancel} />
          </div>
          <div className="ConfirmDeactivateFormModal-button ConfirmDeactivateFormModal-button--submit">
            <Button
              disabled={loading}
              className="Button Button--apply"
              title="Yes, Continue"
              onClick={onSubmit}
              sending={loading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeactivateFormModal;
