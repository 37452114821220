import React  from 'react';

interface IProps {
  name: string;
  quantity: number;
  totalPrice: number;
  unitPrice: number;
  limited: boolean;
}

const SecondaryPanelEstimateItem: React.FC<IProps> = ({ name, quantity, totalPrice, unitPrice, limited}) => {

  return <div className="SecondaryPanelEstimateItem">
    <div>{name}</div>
    <div>{quantity}</div>
    {!limited && <div>${totalPrice}</div>}
    {!limited && <div>${unitPrice}</div>}
  </div>;
};

export default SecondaryPanelEstimateItem;
