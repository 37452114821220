import React from 'react';
import classnames from 'classnames';

import './styles.scss';

interface IProps {
  circle?: boolean;
  isExpanded: boolean;
  onClick: () => void;
}

const ChevronButton: React.FC<IProps> = ({ isExpanded, circle, onClick }) => {
  const cn = classnames('ChevronButton', {
    'ChevronButton--circle': circle,
    'ChevronButton--expanded': isExpanded,
  });

  return <button className={cn} onClick={onClick} />;
};

export default ChevronButton;
