import React from 'react';

import { NoResultsSmileIcon } from 'assets';

interface IProps {
  searchQuery: string;
}

const NoSearchResults: React.FC<IProps> = ({ searchQuery }) => (
  <div className="Search-results">
    <div className="Search-resultsContainer Search-resultsContainer--noResults">
      <div className="Search-noResultsLogo">
        <NoResultsSmileIcon className="Search-noResultsImg" />
      </div>
      <div className="Search-noResultsMessage">
        <p className="Search-noResultsMessageQuery">"{searchQuery}"</p>
        <p className="Search-noResultsMessageText">Sorry... no results found</p>
      </div>
    </div>
  </div>
);

export default NoSearchResults;
