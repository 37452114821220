import React, { Component } from 'react';

import moment from 'moment';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import type { MediaManager, TimeStore } from 'stores';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

import { getEndOfDayByDate, getFormattedTime, getStartOfDayByDate, getTimestampForTimezone } from 'utils';
import { CAMERA_JOURNAL_STATE, DATE_TIME_FORMATS } from 'config';
import { DASHCAM_STATUS } from 'models/Dashcam/DashcamStatus';
import type CameraInterval from 'models/Dashcam/MediaRequestCheckout/CamerasJournal/CameraInterval';

import Input from 'components/Input';
import MediaManagerDetails from 'components/DashCams/MediaManagerDetails';
import JournalBar from 'components/DashCams/JournalBar';
import RequestMediaCalendar from 'components/DashCams/RequestMediaCalendar';
import MediaRequestReviewCameraItem from 'components/DashCams/MediaRequestReview/MediaRequestReviewCameraItem';
import MediaManagerRequestMediaSettings from 'components/DashCams/MediaManagerRequestMediaSettings';
import MediaRequestReviewItem from 'components/DashCams/MediaRequestReview/MediaRequestReviewItem';
import PreviewSmartWitness from 'components/DashCams/PreviewSmartWitness';
import InfoTooltip from 'components/InfoTooltip';

import './styles.scss';

interface IProps {
  mediaManagerStore?: MediaManager;
  timeStore?: TimeStore;
  timestamp?: number;
}

@inject(({ mediaManagerStore, timeStore }) => ({ mediaManagerStore, timeStore }))
@observer
class MediaManagerSmartWitness extends Component<IProps> {
  componentDidMount(): void {
    const {
      mediaManagerStore: { checkout },
    } = this.props;

    EventsBus.get().once(APP_EVENTS.DASHCAM_STATUS, () => {
      if (checkout.dashcamStatus.status.value === DASHCAM_STATUS.ONLINE) {
        const availableCameraTypes = checkout.dashcamStatus.cameraDetails.availableCameraTypes.toArray();

        const cameraTypes =
          availableCameraTypes.includes('outside') && availableCameraTypes.includes('inside')
            ? CAMERA_JOURNAL_STATE.IN
            : availableCameraTypes.includes('outside')
            ? CAMERA_JOURNAL_STATE.OUT
            : CAMERA_JOURNAL_STATE.IN;
        checkout.requestMedia.cameraType.setOptions(cameraTypes);
        checkout.requestMedia.cameraType.setInitialType();
      }
    });
  }

  handleChangeDate = (date: string) => {
    const {
      mediaManagerStore: {
        checkout: {
          camerasJournal,
          requestMedia: { timeSegment },
        },
      },
      timeStore: { sessionTimezone },
    } = this.props;

    if (!this.isIntervals) {
      const timeString = getFormattedTime(
        timeSegment.startTime.value,
        DATE_TIME_FORMATS.hoursMinutesSeconds24,
        sessionTimezone
      );
      const dateString = `${date} ${timeString}`;
      const timestamp = moment
        .tz(
          dateString,
          `${DATE_TIME_FORMATS.monthDatYearFull} ${DATE_TIME_FORMATS.hoursMinutesSeconds24}`,
          sessionTimezone
        )
        .valueOf();

      timeSegment.startTime.set(timestamp);
    } else {
      camerasJournal.dates.model[this.selectedDate]?.reset();
      camerasJournal.dates.select(date);
      timeSegment.startTime.set(null);
      timeSegment.timeline.start.set(null);
      timeSegment.timeline.end.set(null);
    }
  };

  changeClipName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      mediaManagerStore: {
        checkout: {
          requestMedia: { clipName },
        },
      },
    } = this.props;

    clipName.set(e.target.value);
  };

  handleChangeInterval = (interval: CameraInterval) => {
    const {
      mediaManagerStore: {
        checkout: {
          requestMedia: {
            timeSegment: { timeline, startTime },
          },
        },
      },
      timeStore: { sessionTimezone },
    } = this.props;

    const activeDateTimestamp = getTimestampForTimezone(this.selectedDate, 'MM/DD/YYYY', sessionTimezone);
    let startTimestamp = interval.startTimestamp;
    let endTimestamp = interval.endTimestamp;

    if (getStartOfDayByDate(activeDateTimestamp, sessionTimezone) > interval.startTimestamp) {
      startTimestamp = getStartOfDayByDate(activeDateTimestamp, sessionTimezone);
    }

    if (getEndOfDayByDate(activeDateTimestamp, sessionTimezone) < interval.endTimestamp) {
      endTimestamp = getEndOfDayByDate(activeDateTimestamp, sessionTimezone);
    }

    startTime.set(startTimestamp);
    timeline.start.set(startTimestamp);
    timeline.end.set(endTimestamp);
  };

  get isOnline() {
    const {
      mediaManagerStore: {
        checkout: { dashcamStatus },
      },
    } = this.props;

    return dashcamStatus.status.value === DASHCAM_STATUS.ONLINE;
  }

  get isOffline() {
    const {
      mediaManagerStore: {
        checkout: { dashcamStatus },
      },
    } = this.props;

    return dashcamStatus.status.value === DASHCAM_STATUS.OFFLINE;
  }

  get selectedDate() {
    const {
      mediaManagerStore: {
        checkout: { camerasJournal },
      },
    } = this.props;

    return camerasJournal.dates.active;
  }

  get isIntervals() {
    const {
      mediaManagerStore: {
        checkout: { camerasJournal },
      },
    } = this.props;

    return Boolean(camerasJournal.initialList.length);
  }

  get selectedCameraDate() {
    const {
      mediaManagerStore: {
        checkout: { camerasJournal },
      },
    } = this.props;

    return camerasJournal.dates.model[this.selectedDate];
  }

  get isIntervalSelected() {
    const {
      mediaManagerStore: {
        checkout: { camerasJournal },
      },
    } = this.props;

    return camerasJournal.isIntervalSelected();
  }

  get formattedStartDate() {
    const {
      mediaManagerStore: {
        checkout: {
          requestMedia: { timeSegment },
        },
      },
      timeStore: { sessionTimezone },
    } = this.props;

    return timeSegment.startTime.value
      ? getFormattedTime(timeSegment.startTime.value, DATE_TIME_FORMATS.monthDatYearFull, sessionTimezone)
      : null;
  }

  get selectedCameraInterval() {
    return this.selectedCameraDate && this.selectedCameraDate.findSelectedInterval();
  }

  render() {
    const {
      mediaManagerStore: {
        checkout: {
          vehicle,
          dashcamStatus,
          camerasJournal,
          requestMedia: { timeSegment, cameraType, clipName, player },
        },
      },
      timeStore: { sessionTimezone },
    } = this.props;

    return (
      <div className="RequestMediaSmartWitness">
        <p className="RequestMediaSmartWitness-header dimmed-grey">Choose a date, time, and clip duration to request</p>
        <p className="RequestMediaSmartWitness-subHeader dimmed-grey">
          Media will be pulled from the camera when the camera is online, and stored on the Dashcam Media page for 30
          days.
        </p>
        <div className="RequestMediaSmartWitness-details dimmed-grey">
          <MediaManagerDetails name={vehicle.details.displayName} dashcamPing={dashcamStatus} />
        </div>
        <div
          className={classNames('RequestMediaSmartWitness-calendar dimmed-grey', {
            'RequestMediaSmartWitness-calendar--row': this.isOnline,
          })}
        >
          <RequestMediaCalendar
            className={this.isOnline ? 'RequestMediaSmartWitness-calendarRow' : ''}
            daysWithMediaItems={this.isIntervals && camerasJournal.dates.list}
            onSelectDay={this.handleChangeDate}
            selectedDay={this.isIntervals ? this.selectedDate : this.formattedStartDate}
            offline={!this.isIntervals}
            segment={timeSegment}
            timezone={sessionTimezone}
            duration={timeSegment.duration}
            firstMediaDate={this.isIntervals && camerasJournal.dates.oldest}
            isShowMediaSettings={false}
          />
          {this.isOnline && (
            <div className="RequestMediaSmartWitness-preview">
              <PreviewSmartWitness
                cameraType={cameraType}
                playerImage={player.image}
                startTime={timeSegment.startTime.value}
                dataImage={player.image.data.value}
                state={player.state}
                isLoading={player.image.isLoading.value}
              />
            </div>
          )}
          <div className="RequestMediaSmartWitness-requestInformation">
            <p className="RequestMediaSmartWitness-requestInformationTitle">Request Information</p>
            <div className="RequestMediaSmartWitness-requestInformationBox">
              <MediaRequestReviewItem title="Clip Name" name="clipName">
                <Input placeholder="Optional" value={clipName.value} onChange={this.changeClipName} maxLength={32} />
              </MediaRequestReviewItem>
              <MediaManagerRequestMediaSettings
                duration={timeSegment.duration}
                segment={timeSegment}
                timezone={sessionTimezone}
                withoutAdditionalLength
                withRestrictions={false}
              />
              {cameraType && (
                <div className="MediaRequestReview-cameraType">
                  <p className="MediaRequestReview-cameraType--title">Camera Selection</p>
                  {cameraType.cameras.map((camera) => (
                    <div
                      className={`MediaRequestReview-camera MediaRequestReview-camera--${camera.value.toLowerCase()}`}
                      key={camera.value}
                    >
                      <MediaRequestReviewCameraItem
                        checked={camera.media.isActive.value}
                        disabled={!camera.isAvailable.value}
                        onCheck={camera.media.isActive.toggle}
                        type={camera.value === 'OUT' ? 'Road-Facing Camera' : 'Driver-Facing Camera'}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="RequestMediaSmartWitness-journalBar dimmed-grey">
          <div className="RequestMediaSmartWitness-journalBarHeader">
            <p className="RequestMediaSmartWitness-journalBarDate">
              {moment(this.isIntervals ? this.selectedDate : this.formattedStartDate, 'MM/DD/YYYY').format(
                DATE_TIME_FORMATS.fullMonthComaDateYear
              )}
            </p>
            <InfoTooltip>The blue segments indicate the camera has media for this time period.</InfoTooltip>
          </div>
          <div className="RequestMediaSmartWitness-journalBarComponent">
            <JournalBar
              date={this.isIntervals ? camerasJournal.dates.active : this.formattedStartDate}
              intervals={this.selectedCameraDate?.intervals || []}
              customInterval={camerasJournal.customInterval}
              tz={sessionTimezone}
              ignoreInterval
              isOffline={this.isOffline}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MediaManagerSmartWitness;
