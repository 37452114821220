import { observable, action, computed } from 'mobx';
import { CAMERA_JOURNAL_STATE } from 'config';
import { SimpleField } from 'models/Fields';
import MediaCameraType from '../../MediaCommon/MediaCameraType';

class RequestMediaCamerasType {
  @observable type: SimpleField<CAMERA_JOURNAL_STATE>;
  cameras: MediaCameraType[];

  constructor() {
    this.type = new SimpleField(null);
    this.cameras = [];
    this.cameras.push(new MediaCameraType(CAMERA_JOURNAL_STATE.OUT));
    this.cameras.push(new MediaCameraType(CAMERA_JOURNAL_STATE.IN));
  }

  @action setInitialType = () => {
    const optionValues = this.cameras.map((option) => option.value);

    switch (true) {
      case optionValues.includes(CAMERA_JOURNAL_STATE.OUT):
        return (this.type.value = CAMERA_JOURNAL_STATE.OUT);
      case optionValues.includes(CAMERA_JOURNAL_STATE.IN):
        return (this.type.value = CAMERA_JOURNAL_STATE.IN);
    }
  };

  setOptions = (state: CAMERA_JOURNAL_STATE = CAMERA_JOURNAL_STATE.IN_OUT) => {
    this.cameras.forEach((option) => {
      if (state?.includes(option.value)) {
        option.isAvailable.toggle(true);
      }
    });
  };

  getCamera = (value: CAMERA_JOURNAL_STATE) => this.cameras.find((camera) => camera.value === value);

  @computed get anySelected() {
    return this.cameras.some((camera) => camera.isAvailable.value && camera.media.isActive.value);
  }

  @computed get anySelectedVideoType() {
    return this.cameras.some(
      (camera) => camera.isAvailable.value && camera.media.isActive.value && camera.media.selectedType === 'video'
    );
  }
}

export default RequestMediaCamerasType;
