import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { parse } from 'query-string';

import type { UserStore } from 'stores';
import { PATHS } from 'config';

import AuthPage from 'components/Auth/AuthPage';
import ConfirmPassword from 'components/Auth/ConfirmPassword';

import Logo from 'components/Logo';
import './styles.scss';

interface IProps extends RouteComponentProps {
  className?: string;
  page?: 'set' | 'update';
  userStore?: UserStore;
}

interface IQueryParams {
  token?: string;
  email?: string;
}

@inject(({ userStore }) => ({
  userStore,
}))
@observer
class SetUpdatePassword extends Component<IProps> {
  get queryParams() {
    const {
      location: { search },
    } = this.props;
    const { token, email } = parse(search) as IQueryParams;

    return { token, email };
  }

  get sending() {
    const {
      userStore: { repositoryAuthPasswordRecovery },
    } = this.props;

    return repositoryAuthPasswordRecovery.doState.loading;
  }

  handleSubmit = async (password: string) => {
    const {
      userStore: { setPassword, repositoryAuthPasswordRecovery },
      history: { push },
      page,
    } = this.props;
    const loginRoute = page === 'set' ? PATHS.AUTH.LOGIN : `${PATHS.AUTH.LOGIN}?reset=true`;

    await setPassword(decodeURIComponent(this.queryParams.token), password);
    if (repositoryAuthPasswordRecovery.doState.success) {
      push(loginRoute);
    }
  };

  render() {
    const {
      userStore: { repositoryAuthPasswordRecovery },
    } = this.props;
    const errorMessage = new Error(repositoryAuthPasswordRecovery.doState.error).message.replace('Error: ', '');

    return (
      <AuthPage className="AuthPage--setUpdatePassword">
        <div className="SetUpdatePassword">
          <div className="SetUpdatePassword-header">
            {this.props.page === 'set' && <p className="SetUpdatePassword-title">Welcome to your new account!</p>}
            <div className="SetUpdatePassword-logo">
              <Logo />
            </div>
            <p className="SetUpdatePassword-choosePasswordText">
              {this.props.page === 'set' ? 'Choose a password for' : 'Choose a new password for'}
            </p>
            <p className="SetUpdatePassword-email">{this.queryParams.email}</p>
            {this.props.page === 'update' && (
              <Link className="SetUpdatePassword-link" to={PATHS.AUTH.LOGIN}>
                Not You?
              </Link>
            )}
          </div>
          <div className="SetUpdatePassword-form">
            <ConfirmPassword
              onSubmit={this.handleSubmit}
              sending={this.sending}
              submitText={this.props.page === 'set' ? 'Create Password' : 'Update Password'}
            />
          </div>
          <p className="SetUpdatePassword-login">
            <Link className="SetUpdatePassword-link" to={PATHS.AUTH.LOGIN}>
              Return to Log In
            </Link>
          </p>
          {Boolean(repositoryAuthPasswordRecovery.doState.error) && (
            <p className="SetUpdatePassword-errorMessage">{errorMessage}</p>
          )}
        </div>
      </AuthPage>
    );
  }
}

export default SetUpdatePassword;
