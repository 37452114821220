import React from 'react';

import { CautionIcon } from 'assets';

import ConfirmationModal from 'components/Admin/ConfirmationModal';
import type { IAlert } from 'models/Alerts/IAlert';

import './styles.scss';

interface IProps {
  isOpen: boolean;
  alert: IAlert;
  onClose: () => void;
}

const AlertConvertToCustomModal: React.FC<IProps> = ({ isOpen, onClose, alert }) => {
  const handleSubmit = async () => {
    if (alert) {
      await alert.convertToCustom();

      if (alert.repositoryCustom.patchState.success) {
        onClose();
      }
    }
  };

  return (
    <ConfirmationModal
      applyButtonTitle="Yes, Continue"
      cancelButtonTitle="No, Cancel"
      iconTitle={<CautionIcon className="AlertConvertToCustomModal-icon" />}
      title="Convert to Custom Alert?"
      isOpen={isOpen}
      onCancel={onClose}
      onSubmit={handleSubmit}
      className="AlertConvertToCustomModal-modal"
      popupClassName="AlertConvertToCustomModal"
      contentStyles={{ padding: '0 20px', backgroundColor: '#fff' }}
      withoutCloseIcon
    >
      <div className="AlertConvertToCustomModal-content">
        <p className="AlertConvertToCustomModal-text AlertConvertToCustomModal-text--top">
          Converting to a Custom Alert will provide more advanced options but the templated options will no longer be
          accessible. This action is irreversible and requires knowledge of code to adjust the alert. It is recommended
          to contact Support before completing this action.
        </p>
        <p className="AlertConvertToCustomModal-text AlertConvertToCustomModal-text--bottom">
          Do you want to continue?
        </p>
      </div>
    </ConfirmationModal>
  );
};

export default AlertConvertToCustomModal;
