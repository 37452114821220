import { SimpleField } from 'models/Fields';
import type { timezoneNames } from 'stores';
import ReportPreferenceItem from './ReportPreferenceItem';

class ReportPreferences {
  device: ReportPreferenceItem;
  group: ReportPreferenceItem;
  from: SimpleField<number>;
  to: SimpleField<number>;
  option: ReportPreferenceItem;
  geozone: ReportPreferenceItem;
  driver: ReportPreferenceItem;
  tag: ReportPreferenceItem;
  timeZone: SimpleField<timezoneNames>;
  optionId: string;
  maintenanceServiceType: ReportPreferenceItem;

  constructor({
    device = {},
    group = {},
    from = null,
    to = null,
    option = {},
    geozone = {},
    driver = {},
    tag = {},
    timeZone,
    maintenanceServiceType = {},
  }) {
    this.device = new ReportPreferenceItem(device);
    this.group = new ReportPreferenceItem(group);
    this.from = new SimpleField(from);
    this.to = new SimpleField(to);
    this.option = new ReportPreferenceItem(option);
    this.geozone = new ReportPreferenceItem(geozone);
    this.driver = new ReportPreferenceItem(driver);
    this.tag = new ReportPreferenceItem(tag);
    this.timeZone = new SimpleField(timeZone);
    this.maintenanceServiceType = new ReportPreferenceItem(maintenanceServiceType);
  }
}

export default ReportPreferences;
