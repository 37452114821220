import React from 'react';
import { Observer } from 'mobx-react';
import moment from 'moment';
import isNumber from 'lodash/isNumber';

import type MaintenanceConfig from 'models/Maintenances/Models/MaintenanceConfig';

import InputNumber from 'components/InputNumber';
import CalendarWithInput from 'components/CalendarWithInput';

import './styles.scss';

type MomentDuration = 'month' | 'week' | 'day' | 'year';

interface IProps {
  disabled: boolean;
  currentOdometer: number;
  currentEngineHours: number;
  odometer: MaintenanceConfig;
  engHours: MaintenanceConfig;
  serviceTime: MaintenanceConfig;
}

const TaskToApplyLastService: React.FC<IProps> = ({
  disabled,
  odometer,
  engHours,
  serviceTime,
  currentOdometer,
  currentEngineHours,
}) => {
  const changeOdometer = (value) => {
    const next = Number(value) + Number(odometer.interval.value) - currentOdometer;

    odometer.last.set(value);
    odometer.next.set(next.toString());
  };

  const changeEngHours = (value) => {
    const next = Number(value) + Number(engHours.interval.value) - currentEngineHours;

    engHours.last.set(value);
    engHours.next.set(next.toString());
  };

  const changeServiceTime = (value) => {
    serviceTime.last.set(value.toString());

    const time = moment(value)
      .add(Number(serviceTime.interval.value), serviceTime.intervalUnit.value.toLowerCase() as MomentDuration)
      .valueOf();
    serviceTime.next.set(time.toString());
  };

  return (
    <Observer
      render={() => (
        <div className="TaskToApplyLastService">
          {Boolean(odometer.interval.value) && (
            <div className="TaskToApplyLastServiceOdometer">
              <InputNumber
                disabled={disabled}
                className="TaskToApplyLastServiceOdometerInput"
                value={
                  isNumber(odometer.last.value)
                    ? Number(odometer.last.value)?.toLocaleString('en-US', { maximumFractionDigits: 0 })
                    : null
                }
                onChange={changeOdometer}
                min="0"
                controls={false}
              />
              <span className="TaskToApplyLastServiceOdometerLabel">mi</span>
            </div>
          )}
          {Boolean(engHours.interval.value) && (
            <div className="TaskToApplyLastServiceEngHours">
              <InputNumber
                disabled={disabled}
                className="TaskToApplyLastServiceEngHoursInput"
                value={
                  isNumber(engHours.last.value)
                    ? Number(engHours.last.value)?.toLocaleString('en-US', { maximumFractionDigits: 0 })
                    : null
                }
                onChange={changeEngHours}
                min="0"
                controls={false}
              />
              <span className="TaskToApplyLastServiceEngHoursLabel">hrs</span>
            </div>
          )}
          {Boolean(serviceTime.interval.value) && (
            <div className="TaskToApplyLastServiceServiceTime">
              <CalendarWithInput
                disabled={disabled}
                withTodayLabel
                isInputEditable={false}
                value={Number(serviceTime.last.value)}
                onSubmit={changeServiceTime}
              />
            </div>
          )}
        </div>
      )}
    />
  );
};

export default TaskToApplyLastService;
