import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import validateAccessLevel from 'stores/acl/validator';
import {
  WEB_DASHCAM_BASIC_READ,
  ADMIN_GEOZONES_READ,
  ADMIN_GROUPS_UPDATE,
  ADMIN_INTEGRATIONS_DELETE,
  ADMIN_INTEGRATIONS_READ,
  ADMIN_NAV_SELECTION,
  ADMIN_USERS_UPDATE,
  FEATURES,
  PATHS,
} from 'config';

import AdminDashcam from './Dashcams';
import AdminNavigation from './AdminNavigation';
import AdminPeople from './People';
import ControlledRoute from 'components/ControlledRoute';
import GroupsTable from './Groups/GroupsTable';
import SelectedGroup from './Groups/GroupDetails';
import SelectedVehicle from './Vehicles/SelectedVehicle';
import VehiclesTable from './Vehicles/VehiclesTable';
import GeozonesTable from './Geozones/GeozonesTable';
import SelectedGeozone from './Geozones/SelectedGeozone';
import SelectedDashcam from './Dashcams/SelectedDashcam';
import Integrations from './Integrations';
import AdminPeopleDetails from './People/PeopleDetails';

import { getCookie } from 'utils';

import './styles.scss';

class Admin extends Component {
  private availablePaths: Array<[string, () => boolean]> = [
    [PATHS.ADMIN.TABLES.DASHCAMS.INDEX, () => FEATURES.dashcams && validateAccessLevel([WEB_DASHCAM_BASIC_READ])],
    [PATHS.ADMIN.TABLES.GEOZONES.INDEX, () => FEATURES.geozonesAdmin && validateAccessLevel([ADMIN_GEOZONES_READ])],
    [PATHS.ADMIN.TABLES.GROUPS.INDEX, () => FEATURES.groupsAdmin && validateAccessLevel([ADMIN_GROUPS_UPDATE])],
    [PATHS.ADMIN.TABLES.PEOPLE.INDEX, () => validateAccessLevel([ADMIN_USERS_UPDATE])],
    [PATHS.ADMIN.INTEGRATIONS.INDEX, () => FEATURES.vehiclesAdmin && validateAccessLevel([ADMIN_INTEGRATIONS_DELETE])],
  ];

  private validatePath(value: string) {
    return this.availablePaths.find(([path, validator]) => path === value && validator());
  }

  render() {
    const savedPath = getCookie(ADMIN_NAV_SELECTION);
    const path = savedPath && this.validatePath(savedPath) ? savedPath : PATHS.ADMIN.TABLES.VEHICLES.INDEX;

    return (
      <div className="AdminPage">
        <div className="AdminPage-navigation">
          <Switch>
            <Route path={PATHS.ADMIN.TABLES.INDEX} component={AdminNavigation} />
            <Route path={PATHS.ADMIN.INTEGRATIONS.INDEX} component={AdminNavigation} />
          </Switch>
        </div>
        <div className="AdminPage-content">
          <Switch>
            <Route path={PATHS.ADMIN.TABLES.INDEX} exact>
              <Redirect from={PATHS.ADMIN.TABLES.INDEX} to={path} />
            </Route>
            <ControlledRoute
              rule={FEATURES.dashcams}
              exact
              path={PATHS.ADMIN.TABLES.DASHCAMS.INDEX}
              render={(props) =>
                validateAccessLevel([WEB_DASHCAM_BASIC_READ]) ? (
                  <AdminDashcam {...props} />
                ) : (
                  <Redirect from="*" to={PATHS.NOT_FOUND} />
                )
              }
            />
            <ControlledRoute
              rule={FEATURES.dashcamsAdmin}
              exact
              path={PATHS.ADMIN.TABLES.DASHCAMS.DASHCAM}
              render={(props) =>
                validateAccessLevel([WEB_DASHCAM_BASIC_READ]) ? (
                  <SelectedDashcam {...props} />
                ) : (
                  <Redirect from="*" to={PATHS.NOT_FOUND} />
                )
              }
            />
            <ControlledRoute
              rule={FEATURES.devicesAdmin}
              path={PATHS.ADMIN.TABLES.DEVICES}
              render={() => <div>Devices</div>}
            />
            <ControlledRoute
              rule={FEATURES.geozonesAdmin}
              exact
              path={PATHS.ADMIN.TABLES.GEOZONES.INDEX}
              render={() =>
                this.validatePath(PATHS.ADMIN.TABLES.GEOZONES.INDEX) ? (
                  <GeozonesTable />
                ) : (
                  <Redirect from="*" to={PATHS.NOT_FOUND} />
                )
              }
            />
            <ControlledRoute
              rule={FEATURES.geozonesAdmin}
              exact
              path={PATHS.ADMIN.TABLES.GEOZONES.GEOZONE}
              render={(props) =>
                validateAccessLevel([ADMIN_GEOZONES_READ]) ? (
                  <SelectedGeozone {...props} />
                ) : (
                  <Redirect from="*" to={PATHS.NOT_FOUND} />
                )
              }
            />
            <ControlledRoute
              rule={FEATURES.groupsAdmin}
              exact
              path={PATHS.ADMIN.TABLES.GROUPS.INDEX}
              render={() =>
                this.validatePath(PATHS.ADMIN.TABLES.GROUPS.INDEX) ? (
                  <GroupsTable />
                ) : (
                  <Redirect from="*" to={PATHS.NOT_FOUND} />
                )
              }
            />
            <ControlledRoute
              rule={FEATURES.groupsAdmin}
              exact
              path={PATHS.ADMIN.TABLES.GROUPS.GROUP}
              render={(props) =>
                validateAccessLevel([ADMIN_GROUPS_UPDATE]) ? (
                  <SelectedGroup {...props} />
                ) : (
                  <Redirect from="*" to={PATHS.NOT_FOUND} />
                )
              }
            />
            <ControlledRoute
              exact
              path={PATHS.ADMIN.TABLES.PEOPLE.INDEX}
              render={(props) =>
                validateAccessLevel([ADMIN_USERS_UPDATE]) ? (
                  <AdminPeople {...props} />
                ) : (
                  <Redirect from="*" to={PATHS.NOT_FOUND} />
                )
              }
            />
            <ControlledRoute
              exact
              path={PATHS.ADMIN.TABLES.PEOPLE.PERSON}
              render={(props) =>
                validateAccessLevel([ADMIN_USERS_UPDATE]) ? (
                  <AdminPeopleDetails {...props} />
                ) : (
                  <Redirect from="*" to={PATHS.NOT_FOUND} />
                )
              }
            />
            <ControlledRoute
              rule={FEATURES.vehiclesAdmin}
              exact
              path={PATHS.ADMIN.TABLES.VEHICLES.INDEX}
              component={VehiclesTable}
            />
            <ControlledRoute
              rule={FEATURES.vehiclesAdmin}
              exact
              path={PATHS.ADMIN.INTEGRATIONS.INDEX}
              render={(props) =>
                validateAccessLevel([ADMIN_INTEGRATIONS_READ]) ? (
                  <Integrations {...props} />
                ) : (
                  <Redirect from="*" to={PATHS.NOT_FOUND} />
                )
              }
            />
            <ControlledRoute
              rule={FEATURES.vehiclesAdmin}
              path={PATHS.ADMIN.TABLES.VEHICLES.VEHICLE}
              component={SelectedVehicle}
            />
            <ControlledRoute
              rule={FEATURES.accountAdmin}
              path={PATHS.ADMIN.TABLES.ACCOUNT}
              render={() => <div>Account</div>}
            />
            <Redirect from="*" to={PATHS.NOT_FOUND} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Admin;
