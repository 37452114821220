import { computed } from 'mobx';

import { getStartOfDayByDate, getEndOfDayByDate } from 'utils';
import timeStore from 'stores/TimeStore';
import MediaDateOptionFactory from './MediaDateOptionFactory';
import type { IMediaDateOption } from 'interfaces/models/Dashcams/IMediaDateOption';
import MediaDateOptionRange from './MediaDateOptionRange';
import { optionsList } from './MediaDateRangeOptionsConfig';

export default class MediaDateRange {
  options: Array<IMediaDateOption<any>> = [];

  constructor() {
    optionsList.forEach((item) => {
      this.options.push(new MediaDateOptionFactory(item.type).get(item.option));
    });
  }

  @computed get params() {
    return this.selected?.params;
  }

  init = ({ from, to, expiringSoon }) => {
    if (from && to) {
      if (this.customDateRangeOption) {
        this.unselectOptions();
        this.customDateRangeOption.isSelected.toggle(true);
        if (this.customDateRangeOption instanceof MediaDateOptionRange) {
          this.customDateRangeOption.range.set({ from: Number(from), to: Number(to) });
        }
      }
    }
    if (expiringSoon) {
      if (this.expiringSoonOption) {
        this.unselectOptions();
        this.expiringSoonOption.isSelected.toggle(true);
      }
    }
  };

  selectCustomDateOption = (timestamp) => {
    const option = this.options.find((option) => option.name.toLowerCase().includes('custom'));

    if (option && option instanceof MediaDateOptionRange) {
      this.unselectOptions();
      option.isSelected.toggle(true);
      option.range.set({
        from: getStartOfDayByDate(Number(timestamp), timeStore.sessionTimezone),
        to: getEndOfDayByDate(Number(timestamp), timeStore.sessionTimezone),
      });
    }
  };

  unselectOptions = () => this.options.forEach((option) => option.isSelected.toggle(false));

  get customDateRangeOption() {
    return this.options.find((option) => option.name.toLowerCase().includes('custom'));
  }

  get expiringSoonOption() {
    return this.options.find((option) => option.name.toLowerCase().includes('expiring'));
  }

  get selected() {
    return this.options.find((option) => option.isSelected.value);
  }
}
