import React, { FC, Fragment } from 'react';
import classNames from 'classnames';

import { CheckIcon, CircleIcon } from 'assets';

import './styles.scss';

interface IProps {
  color: Geozone.IGeozoneColor;
  isActiveColor?: boolean;
  size?: number;
}

const ColorPickerIcon: FC<IProps> = ({ color, isActiveColor = false, size = 24 }) => {
  const adjustedColorName = color.name.replaceAll(' ', '').toLowerCase();
  const colorCn = classNames('ColorPickerIcon', {
    'ColorPickerIcon--active': isActiveColor,
    [`ColorPickerIcon--${adjustedColorName}`]: Boolean(color.name),
  });
  const activeIconCn = classNames('ColorPickerIcon-activeIcon', {
    [`ColorPickerIcon-activeIcon--${adjustedColorName}`]: Boolean(color.name),
  });
  return (
    <Fragment>
      <CircleIcon className={colorCn} fill={color.hex} style={{ height: `${size}`, width: `${size}` }} />
      {isActiveColor && <CheckIcon className={activeIconCn} />}
    </Fragment>
  );
};

export default ColorPickerIcon;
