import { DASHCAMS_SETTINGS_TYPES } from 'config';
import { DashcamSetting } from './DashcamSetting';
import { ToggleField } from '../Fields';

export class DashcamSettingToggle extends DashcamSetting<ToggleField> {
  constructor(context, setting, level: number = 0, parent: string = '') {
    super(context, { ...setting, level, type: DASHCAMS_SETTINGS_TYPES.TOGGLE });
    this.value = new ToggleField(setting.value === 'true');
    this.expanded = new ToggleField(false);
    this.parent = parent;
  }

  get model() {
    return { ...super.model, value: this.value.value ? 'true' : 'false' };
  }

  reset = () => {
    this.settings?.forEach((setting) => setting.reset?.());
    this.value.reset();
  };
}
