import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { CameraWithFlashIcon, FilterIcon, PowerOffIcon } from 'assets';

import { validateAccessLevel } from 'stores';
import { MAP_FILTER_ATTRIBUTES, WEB_DASHCAM_BASIC_READ } from 'config';
import { pluralize } from 'utils';
import Tooltip from 'components/Tooltip';
import Checkbox from 'components/Checkbox';
import StatusMarker from 'components/StatusMarker';
import type DevicesAttributesFilter from 'models/Devices/Filters/DevicesAttributesFilter';
import type FilterOrderDevices from 'models/EventRegistry/FilterOrderDevices';
import type { IFilterItems } from 'models/EventRegistry/IFilterItems';

import './styles.scss';

interface IProps {
  className?: string;
  isDisabled?: boolean;
  onStatusesChange: (status: string) => void;
  resetStatuses?: () => void;
  filterEvents?: IFilterItems;
  filterOrder?: FilterOrderDevices;
  filterAttributes: DevicesAttributesFilter;
  showHistory: boolean;
}

const getFilterData = (
  filterEvents: IFilterItems,
  filterAttributes: DevicesAttributesFilter,
  showHistory: boolean
): { title: string; isActive: boolean } => {
  const statusesValues = Object.values(filterEvents);
  const appliedFiltersNumber = statusesValues.filter((value) => value.isChecked).length;
  const appliedAttributesNumber = filterAttributes.items.filter((item) => {
    if (item.id === MAP_FILTER_ATTRIBUTES.POWER_DISCONNECT && showHistory) {
      return false;
    }
    return item.isChecked.value;
  }).length;
  const isActive = Boolean(appliedFiltersNumber || appliedAttributesNumber);

  return {
    title: isActive ? pluralize(appliedFiltersNumber + appliedAttributesNumber, 'Filter') : '',
    isActive,
  };
};

const StatusFilter: React.FC<IProps> = ({
  className,
  isDisabled,
  onStatusesChange,
  resetStatuses,
  filterEvents,
  filterOrder,
  filterAttributes,
  showHistory,
}) => {
  const { title, isActive } = getFilterData(filterEvents, filterAttributes, showHistory);
  const tooltipCn = classNames(['Tooltip--statusFilter', 'StatusFilter-ToolTip'], {
    'Tooltip--disabled': Boolean(isDisabled),
    'Tooltip--active': Boolean(isActive),
    'Tooltip--empty': Boolean(!isActive),
  });
  const cn = classNames('StatusFilter', className);

  const getCheckBoxFilterLabel = (label, iconColor) => {
    return (
      <>
        <div className="StatusFilter-dot">
          <StatusMarker color={iconColor} size="medium" />
        </div>
        <span className="StatusFilter-label">{label}</span>
      </>
    );
  };

  const getCheckBoxAttributeLabel = (label, id) => {
    return (
      <>
        <div className="AttributeFilter-icon">
          {id === MAP_FILTER_ATTRIBUTES.POWER_DISCONNECT && <PowerOffIcon width={16} height={16} />}
          {id === MAP_FILTER_ATTRIBUTES.HAS_DASHCAM && <CameraWithFlashIcon width={16} height={16} fill="#6B7A99" />}
        </div>
        <span className="AttributeFilter-label">{label}</span>
      </>
    );
  };

  const getCheckbox = (item) => {
    if (
      (item.id === MAP_FILTER_ATTRIBUTES.HAS_DASHCAM && validateAccessLevel([WEB_DASHCAM_BASIC_READ])) ||
      item.id !== MAP_FILTER_ATTRIBUTES.HAS_DASHCAM
    ) {
      return (
        <div className="AttributeFilter-checkbox">
          <Checkbox
            label={getCheckBoxAttributeLabel(item.label, item.id)}
            value={item.id}
            checked={item.isChecked.value}
            onChange={item.toggle}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <Tooltip
      className={tooltipCn}
      Icon={FilterIcon}
      name={title}
      withReset
      position="bottomRight"
      onReset={resetStatuses}
      forceClose
    >
      <div className={cn}>
        <div className="StatusFilter-container">
          <p className="StatusFilter-title">By Status</p>
          <ul className="StatusFilter-list">
            {filterOrder.byStatus.map((status) => {
              if (filterEvents[status]) {
                const isChecked = filterEvents[status].isChecked;
                const iconColor = filterEvents[status].items[0].attributes.iconColor;

                return (
                  <li className="StatusFilter-item" key={status}>
                    <div className="StatusFilter-checkbox">
                      <Checkbox
                        label={getCheckBoxFilterLabel(status, iconColor)}
                        value={status}
                        checked={isChecked}
                        onChange={onStatusesChange}
                      />
                    </div>
                  </li>
                );
              }

              return null;
            })}
          </ul>
        </div>
        {filterAttributes.items.length && (
          <div className="AttributeFilter-container">
            <p className="AttributeFilter-title">By Attribute</p>
            <ul className="AttributeFilter-list">
              {filterAttributes.items.map((item) => {
                if (showHistory && item.id === MAP_FILTER_ATTRIBUTES.POWER_DISCONNECT) {
                  return true;
                }
                return (
                  <li className="AttributeFilter-item" key={item.id}>
                    {getCheckbox(item)}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default observer(StatusFilter);
