import { action, computed, observable } from 'mobx';

export interface IGroupVehicle {
  id: string;
  name: string;
  cp: string;
  selected: boolean;
}

const MISSED_NAME = '-';

class GroupVehicle implements IGroupVehicle {
  id: string = '';
  name: string = '';
  cp: string = '';
  key: string = '';

  @observable
  selected: boolean = false;

  constructor(id: string, name: string = MISSED_NAME, cp?: string, selected: boolean = false) {
    this.id = id;
    this.name = !name ? MISSED_NAME : name;
    this.cp = cp || id;
    this.selected = selected;
    this.key = id;
  }

  @computed get originalName() {
    return this.name !== MISSED_NAME ? this.name.toLowerCase() : undefined;
  }

  @action setSelected() {
    this.selected = true;
  }

  @action setNotSelected() {
    this.selected = false;
  }
}

export default GroupVehicle;
