import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { LINKS, MAP_CREATE_GEOZONE_CREATE, WEB_MAP_TEXT_LOCATION_CREATE } from 'config';

import { MapStore, CustomersStore, UserAccessLevelComponent } from 'stores';

import TextLocationModal from 'components/Map/TextLocationModal';
import Notification from 'components/Notification';
import PanelAction from 'components/SubNav/PanelAction';

import { AddGeozoneIcon, DirectionEventIcon, GeozoneCancelIcon, PhoneIcon } from 'assets';

import './styles.scss';

interface IProps {
  customersStore?: CustomersStore;
  mapStore?: MapStore;
}

interface IState {
  textLocation: boolean;
}

@inject(({ customersStore, devicesMapStore: { mapStore } }) => ({
  customersStore,
  mapStore,
}))
@observer
class CustomerPanelActions extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      textLocation: false,
    };
  }

  get hasCoordinates() {
    const { customersStore } = this.props;

    return !!customersStore.selectedCustomer?.address?.latitude;
  }

  onDirectionsClick = () => {
    const { customersStore } = this.props;

    const latitude = customersStore.selectedCustomer?.address?.latitude;
    const longitude = customersStore.selectedCustomer?.address?.longitude;

    if (latitude && longitude) {
      const directionURL = LINKS.directionTo
        .replace(/{latitude}/g, String(latitude))
        .replace(/{longitude}/g, String(longitude));
      window.open(directionURL);
    }
  };

  openTextLocationModal = () => this.setState({ textLocation: true });

  closeTextLocationModal = () => this.setState({ textLocation: false });

  sendVehicleLocation = async (phone: string, message: string) => {
    const { customersStore } = this.props;

    await customersStore.textLocation({ phone, message });
    this.closeTextLocationModal();
  };

  handleCreateGeoZone = () => {
    const {
      mapStore: { togglePointGeozoneLocation },
    } = this.props;
    togglePointGeozoneLocation();
  };

  private get vehicleCoordinates() {
    const { customersStore } = this.props;

    const latitude = customersStore.selectedCustomer?.address?.latitude;
    const longitude = customersStore.selectedCustomer?.address?.longitude;

    return LINKS.showInGoogleMaps.replace(/{latitude}/g, String(latitude)).replace(/{longitude}/g, String(longitude));
  }

  private get locationText() {
    const { customersStore } = this.props;

    return `${customersStore.selectedCustomer.companyName} Vehicle\n${this.vehicleCoordinates}`;
  }

  render() {
    const {
      mapStore: { pointGeozoneLocation },
      customersStore: {
        repositoryNotifications: { createState },
      },
    } = this.props;

    const { textLocation } = this.state;

    return (
      <div className="CustomerPanelActions">
        <div className="CustomerPanelActions-action">
          <PanelAction disabled={!this.hasCoordinates} icon={<DirectionEventIcon />} onClick={this.onDirectionsClick}>
            Directions
          </PanelAction>
        </div>

        <UserAccessLevelComponent requiredAccessLevel={[MAP_CREATE_GEOZONE_CREATE]}>
          <div className="CustomerPanelActions-action">
            <PanelAction
              icon={pointGeozoneLocation ? <GeozoneCancelIcon /> : <AddGeozoneIcon />}
              onClick={this.handleCreateGeoZone}
              selected={pointGeozoneLocation}
            >
              {pointGeozoneLocation ? 'Cancel' : 'Create Geozone'}
            </PanelAction>
          </div>
        </UserAccessLevelComponent>
        <UserAccessLevelComponent requiredAccessLevel={[WEB_MAP_TEXT_LOCATION_CREATE]}>
          <div className="CustomerPanelActions-action">
            <PanelAction disabled={!this.hasCoordinates} icon={<PhoneIcon />} onClick={this.openTextLocationModal}>
              <span>
                Text
                <br />
                Location
              </span>
            </PanelAction>
          </div>
        </UserAccessLevelComponent>
        <TextLocationModal
          isOpen={textLocation}
          note={''}
          onCancel={this.closeTextLocationModal}
          onSubmit={this.sendVehicleLocation}
          text={this.locationText}
          type="Vehicle"
        />
        {createState.success && (
          <Notification
            onClose={createState.reset}
            text="The text message was successfully sent."
            title="Success!"
            type="success"
          />
        )}
        {createState.error && (
          <Notification
            onClose={createState.reset}
            text="The text message failed to send. Try again."
            title="Send Message Failed"
            type="error"
          />
        )}
      </div>
    );
  }
}

export default CustomerPanelActions;
