import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { PATHS } from 'config';
import { getFormattedTime } from 'utils';

import type { CustomersStore } from 'stores';

import SecondaryPanelAttributesRow from '../../../SecondaryPanelAttributes/SecondaryPanelAttributesRow';
import SecondaryPanelAttributesCol from '../../../SecondaryPanelAttributes/SecondaryPanelAttributesCol';

interface IProps {
  customersStore?: CustomersStore;
}


@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class SecondaryPanelEstimateSTDetails extends Component<IProps> {

  render() {
    const customer = this.props.customersStore.selectedCustomer;
    const estimate = customer.selectedEstimate;
    const link = `${PATHS.MAP.CUSTOMER_JOB.replace(':customerId', customer.id).replace(':jobId', String(estimate.jobId))}`

    return <div className="SecondaryPanelEstimateDetails">
      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Date</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{estimate.transactionDate ? getFormattedTime(estimate.transactionDate, 'MM/DD/YYYY') : '-'}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>

      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Job</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}><Link to={link}>{estimate.jobId}</Link></SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>

      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Status</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{estimate.status || '-'}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>

      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Sold By</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{estimate.soldBy || '-'}  </SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>
    </div>;
  }
}

export default SecondaryPanelEstimateSTDetails;
