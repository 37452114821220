import { repositoryService } from 'services';

import { SimpleField } from 'models/Fields';

import Integration from './Integration';

import type { ISTIntegrationModel } from 'interfaces/stores/Integrations/IIntegration';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

class STIntegrationModel extends Integration implements ISTIntegrationModel {
  repository: IRepository;
  repositoryEntityAuth: IEntityRepository;
  repositoryEntityAuthConnect: IEntityRepository;
  repositoryEntityAuthDisconnect: IEntityRepository;

  applicationId: SimpleField<string>;
  clientId: SimpleField<string>;
  secret: SimpleField<string>;

  hasCustomers: boolean = true;

  constructor(props) {
    super(props);

    this.applicationId = new SimpleField('');
    this.clientId = new SimpleField('');
    this.secret = new SimpleField('');

    this.repository = repositoryService.get('integrations');
    this.repositoryEntityAuth = this.repository.entity('finance').entity('service-titan');
    this.repositoryEntityAuthConnect = this.repositoryEntityAuth.entity('connect');
    this.repositoryEntityAuthDisconnect = this.repositoryEntityAuth.entity('disconnect');
  }

  connect = async () => {
    await this.repositoryEntityAuthConnect.create({
      applicationId: this.applicationId.value,
      clientId: this.clientId.value,
      clientSecret: this.secret.value,
    });
  };

  reconnect = async () => {
    await this.repositoryEntityAuth.entity('reconnect').create();
  };

  disconnect = async () => {
    await this.repositoryEntityAuthDisconnect.delete({
      cleanData: true,
    });
  };

  update = async () => {
    await this.context?.loadAllIntegrations();
  };
}

export default STIntegrationModel;
