import { observable, action, computed } from 'mobx';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import MediaTypeCategories from './MediaTypeCategories';
import { repositoryService } from 'services';
import EventsBus from 'services/EventsBus/eventsBus';
import type MediaLibrary from '..';

export const GET_MEDIA_TOTALS = 'GET_MEDIA_TOTALS';

export default class MediaType {
  @observable categories: MediaTypeCategories;
  initialFilters: string[];
  repository: IEntityRepository;
  context: MediaLibrary;

  constructor(context) {
    this.repository = repositoryService
      .get('media', 'v2.0')
      .entity('data')
      .entity('totals');
    this.context = context;
    this.categories = new MediaTypeCategories(this);
    EventsBus.get().once(GET_MEDIA_TOTALS, this.init);
  }

  initFilters = ({ includeEventTypes, excludeEventTypes }) => {
    const initialFilters = [];

    this.setInitialFilter(initialFilters, includeEventTypes);
    this.setInitialFilter(initialFilters, excludeEventTypes);

    this.initialFilters = [...initialFilters.filter((value) => Boolean(value))];
  };

  init = () => {
    this.initialFilters.forEach((value) =>
      this.categories.list.forEach((category) => {
        const filter = category.list.find((filter) => filter.value === value);
        if (filter) {
          filter.isSelected.toggle(true);
        }
      })
    );
  };

  @computed get params() {
    return this.categories.params;
  }

  @action fetch = async () => {
    try {
      const response = await this.repository.get(this.context.params);
      this.categories.set(response);
      EventsBus.get().trigger(GET_MEDIA_TOTALS);
    } catch (e) {
      return e;
    }
  };

  destroy = () => {
    this.categories.destroy();
  };

  private setInitialFilter = (filter, value) => {
    if (value instanceof Array) {
      filter.push(...value);
    } else {
      filter.push(value);
    }
  };
}
