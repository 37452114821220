class FilterOrderDevices {
  byStatus: string[];

  constructor() {
    this.byStatus = [];
  }

  set = (filters) => {
    this.byStatus = filters.byStatus || [];
  };
}

export default FilterOrderDevices;
