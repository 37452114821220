import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { DISPATCH_STORE, PATHS } from 'config';
import type { DispatchStore } from 'stores/Dispatch';
import type { Vehicle } from 'models';
import Popup from 'components/Popup';
import ReassignModal from 'components/Dispatch/ReassignModal';
import SearchList from 'components/Filters/SearchList';
import SearchVehiclesList from 'components/Filters/SearchList/VehiclesList';

import { ChevronIcon } from 'assets';
import './styles.scss';

interface IProps {
  [DISPATCH_STORE]?: DispatchStore;
  assignedVehicle: Vehicle;
  className?: string;
  driver: { id: string; name: string };
  inline?: boolean;
  rightAligned?: boolean;
}

interface IState {
  assignedVehicle: Vehicle;
  isChangeAssignmentOpen: boolean;
  isModalOpen: boolean;
  isOpen: boolean;
  searchValue: string;
  vehicleToReassign: Vehicle;
}

@inject(({ dispatchStore }) => ({ dispatchStore }))
@observer
class AssignedVehicle extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      assignedVehicle: this.props.assignedVehicle,
      isChangeAssignmentOpen: false,
      isModalOpen: false,
      isOpen: false,
      searchValue: '',
      vehicleToReassign: null,
    };
  }

  handleOpen = () => this.setState({ isOpen: true });

  handleClose = () => this.setState({ isOpen: false });

  handleModalOpen = () => this.setState({ isModalOpen: true });

  handleModalClose = () => this.setState({ isModalOpen: false });

  handleChangeAssignmentOpen = () => this.setState({ isChangeAssignmentOpen: true });

  handleChangeAssignmentClose = () => this.setState({ isChangeAssignmentOpen: false });

  setVehicleToReassign = (vehicleToReassign: Vehicle) => this.setState({ vehicleToReassign });

  setSelectedVehicle = (assignedVehicle: Vehicle) => this.setState({ assignedVehicle });

  handleSearchChange = (searchValue: string) => this.setState({ searchValue });

  handleVehicleChange = (assignedVehicle: Vehicle) => {
    /* TODO: update driver correctly */
    return assignedVehicle;

    /*const {
      dispatchStore: { assignDriverToVehicle },
      driver,
    } = this.props;

    if (assignedVehicle.driverId) {
      this.setVehicleToReassign(assignedVehicle);
      this.handleModalOpen();
    } else {
      assignDriverToVehicle(driver.id, assignedVehicle.id);
      this.setSelectedVehicle(assignedVehicle);
      this.handleClose();
    }*/
  };

  handleCancelReassign = () => {
    this.setVehicleToReassign(null);
    this.handleClose();
    this.handleModalClose();
  };

  handleReassign = async () => {
    /* TODO: update driver correctly */
    return true;

    /*const { vehicleToReassign } = this.state;
    const {
      dispatchStore: { assignDriverToVehicle, updateDriverRequestStatus },
      driver,
    } = this.props;

    await assignDriverToVehicle(driver.id, vehicleToReassign.id);
    this.setSelectedVehicle(vehicleToReassign);
    this.setVehicleToReassign(null);

    if (updateDriverRequestStatus.success) {
      this.handleClose();
      this.handleModalClose();
    }*/
  };

  handlePopupClose = () => {
    this.handleClose();
    this.handleChangeAssignmentClose();
  };

  getFilteredList = (list: Vehicle[]) => {
    const { searchValue } = this.state;

    return list.filter(({ name }) => name.toLowerCase().includes(searchValue.toLowerCase()));
  };

  renderTrigger = (assignedVehicleName: string) => {
    return (
      <div className="AssignedVehicle-trigger">
        {assignedVehicleName ? (
          <div className="AssignedVehicle-vehicleTrigger">
            <span className="AssignedVehicle-vehicleName">{assignedVehicleName}</span>
            <div className="AssignedVehicle-vehicleArrow">
              <ChevronIcon className="AssignedVehicle-vehicleArrowImg" />
            </div>
          </div>
        ) : (
          <div className="AssignedVehicle-noVehicleTrigger">Assign Vehicle</div>
        )}
      </div>
    );
  };

  render() {
    const { vehicleToReassign, isChangeAssignmentOpen, isOpen, isModalOpen, searchValue, assignedVehicle } = this.state;
    const {
      className,
      driver,
      inline,
      rightAligned,
      dispatchStore: { filteredVehiclesList },
    } = this.props;
    const cn = classNames('AssignedVehicle', className, {
      'AssignedVehicle--inline': Boolean(inline),
      'AssignedVehicle--rightAligned': Boolean(rightAligned),
    });

    return (
      <div className={cn}>
        <Popup
          className="AssignedVehicle-popup"
          onOpen={this.handleOpen}
          isOpen={isOpen}
          placement="bottomRight"
          triggerElement={this.renderTrigger(assignedVehicle && assignedVehicle.name)}
        >
          <div className="AssignedVehicle-popupContent">
            {!assignedVehicle || isChangeAssignmentOpen ? (
              <div className="AssignedVehicle-list">
                <SearchList
                  list={this.getFilteredList(filteredVehiclesList)}
                  noResultsMessage="No Vehicles Found"
                  onSearchChange={this.handleSearchChange}
                  placeholder="Search Vehicles"
                  searchValue={searchValue}
                >
                  <SearchVehiclesList
                    list={this.getFilteredList(filteredVehiclesList)}
                    onChange={this.handleVehicleChange}
                    selectedItemId={assignedVehicle && assignedVehicle.id}
                  />
                </SearchList>
              </div>
            ) : (
              <div className="AssignedVehicle-menu">
                <ul className="AssignedVehicle-menuList">
                  <li className="AssignedVehicle-menuItem">
                    <span className="AssignedVehicle-menuItemLink" onClick={this.handleChangeAssignmentOpen}>
                      Change Vehicle Assignment
                    </span>
                  </li>
                  <li className="AssignedVehicle-menuItem">
                    <Link
                      className="AssignedVehicle-menuItemLink"
                      to={`${PATHS.MAP.VEHICLE.replace(':vehicleId', assignedVehicle.id)}`}
                    >
                      View vehicle on Map
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </Popup>
        {Boolean(vehicleToReassign) && (
          <div className="AssignedVehicle-modal">
            <ReassignModal
              driverNameToAssign={driver.name || driver.id}
              driverNameToRemove={vehicleToReassign.driverName || vehicleToReassign.driverId}
              isOpen={isModalOpen}
              onCancel={this.handleCancelReassign}
              onOpen={this.handleModalOpen}
              onSubmit={this.handleReassign}
              vehicleName={vehicleToReassign.name || vehicleToReassign.id}
            />
          </div>
        )}
      </div>
    );
  }
}

export default AssignedVehicle;
