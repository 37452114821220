import type { IFuelType, IFuelTypeServer } from 'interfaces/stores/Assets/IFuelTypes';

class FuelType implements IFuelType {
  fuelType: string;
  name: string;

  constructor(item: IFuelTypeServer) {
    this.fuelType = item.fuelType;
    this.name = item.name;
  }
}

export default FuelType;
