import { TimeField, ToggleField } from 'models/Fields';
import { DATE_TIME_FORMATS } from 'config';

class PersonAccessAppModel {
  active: ToggleField;
  lastLoginTime: TimeField;
  name: string;
  id: number;

  constructor(item = { name: '', lastLoginTime: 0, id: null }) {
    this.active = new ToggleField(Boolean(item.id));
    this.lastLoginTime = new TimeField(item.lastLoginTime).setFormat(DATE_TIME_FORMATS.adminVehicleSettings);
    this.name = item.name || '';
    this.id = item.id;
  }

  get data() {
    return {
      lastLoginTime: this.lastLoginTime.value,
      name: this.name,
      id: this.id,
    };
  }

  get isUpdated() {
    return this.active.isUpdated || this.lastLoginTime.isUpdated;
  }

  reset = () => {
    this.active.reset();
    this.lastLoginTime.reset();
  };
}

export default PersonAccessAppModel;
