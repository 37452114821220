import React, { useEffect, useRef } from 'react';
import moment from 'moment';

import './styles.scss';

interface IProps {
  value: string;

  onChange(value: Date): void;
}

const getDateFromValue = (value) => {
  return value.split('/');
};

const getMonthFromValue = (value) => {
  return value.split('/')[0];
};

const getDayFromValue = (value) => {
  return value.split('/')[1];
};

const getYearFromValue = (value) => {
  return value.split('/')[2];
};

const getValueWithLeadingZero = (value: number) => {
  return `${value < 10 ? '0' : ''}${value}`;
};

const getMaxDay = (month, year) => [0, 31, year % 4 ? 28 : 29, 31, 30, 31, 0, 31, 31, 30, 31, 30, 31][Number(month)];

// @ts-ignore
const DatePicker: React.FC<IProps> = ({ value, onChange }) => {
  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);
  const [focused, setFocused] = React.useState(null);
  const [month, setMonth] = React.useState(() => getMonthFromValue(value));
  const [day, setDay] = React.useState(() => getDayFromValue(value));
  const [year, setYear] = React.useState(() => getYearFromValue(value));

  useEffect(() => {
    const [propsMonth, propsDay, propsYear] = getDateFromValue(value);

    if (propsMonth !== month) {
      setMonth(propsMonth);
    }
    if (propsDay !== day) {
      setDay(propsDay);
    }
    if (propsYear !== year) {
      setYear(propsYear);
    }
  }, [value]);

  useEffect(() => {
    const [propsMonth, propsDay, propsYear] = getDateFromValue(value);
    if (propsMonth !== month || propsDay !== day || propsYear !== year) {
      if (String(year).length === 4 && year >= 2011 && month > 0 && day > 0) {
        onChange(moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').toDate());
      }
    }
  }, [year, month, day]);

  useEffect(() => {
    const maxDay = getMaxDay(month, year);
    if (day > maxDay && maxDay) {
      setDay(maxDay);
    }
  }, [month]);

  const handleItemChange = (currentValue, value, stateSet, focusKey, maxValue) => {
    if (/^\d$/.test(value.key)) {
      const currentMonth = Number(currentValue);
      const num = Number(value.key);
      const nextJoinedValue = Number(`${currentMonth}${num}`);
      if (nextJoinedValue <= maxValue) {
        stateSet(getValueWithLeadingZero(nextJoinedValue));
        setFocused(focusKey);
      } else {
        const nextStepValue = Number(`${num}0`);
        if (nextStepValue > maxValue) {
          stateSet(getValueWithLeadingZero(num));
          setFocused(focusKey);
        } else {
          stateSet(`${num ? num : maxValue}`);
        }
      }
    }
  };

  const handleMonthChange = (value) => {
    handleItemChange(month, value, setMonth, 'day', 12);
  };

  const handleMonthBlur = () => {
    setMonth(getValueWithLeadingZero(Number(month)));
  };

  const handleDayChange = (value) => {
    handleItemChange(day, value, setDay, 'year', getMaxDay(month, year));
  };

  const handleDayBlur = () => {
    setDay(getValueWithLeadingZero(Number(day)));
  };

  const handleYearChange = (value) => {
    handleItemChange(year, value, setYear, null, 2021);
  };

  const handleYearBlur = () => {
    setYear(getValueWithLeadingZero(Number(year)));
  };

  const empty = () => null;

  const resetFocus = () => {
    setFocused(null);
  };

  useEffect(() => {
    if (focused) {
      if (focused === 'day') {
        dayRef.current.focus();
      } else if (focused === 'month') {
        monthRef.current.focus();
      } else if (focused === 'year') {
        yearRef.current.focus();
      }
    }
  }, [focused]);

  return (
    <div className="DatePicker">
      <input
        ref={monthRef}
        className="DatePicker-Input DatePicker-Input-month"
        value={month}
        onKeyPress={handleMonthChange}
        onFocus={resetFocus}
        onBlur={handleMonthBlur}
        onChange={empty}
      />
      <input
        ref={dayRef}
        className="DatePicker-Input DatePicker-Input-day"
        value={day}
        onKeyPress={handleDayChange}
        onFocus={resetFocus}
        onBlur={handleDayBlur}
        onChange={empty}
      />
      <input
        ref={yearRef}
        className="DatePicker-Input DatePicker-Input-year"
        value={year}
        onKeyPress={handleYearChange}
        onFocus={resetFocus}
        onBlur={handleYearBlur}
        onChange={empty}
      />
    </div>
  );
};

export default DatePicker;
