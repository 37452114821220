import { ListField, SimpleField } from 'models/Fields';
import UserAccountExtendedModel from './UserAccountExtendedModel';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';
import UserAccountModel from './UserAccountModel';

class UserDetailsModel {
  active: UserAccountExtendedModel;
  selectedAccount: UserAccountModel;
  accounts: ListField<UserAccountModel>;
  email: SimpleField<string>;
  password: SimpleField<string>;
  filter: SimpleField<string>;

  constructor() {
    this.active = new UserAccountExtendedModel();
    this.selectedAccount = new UserAccountModel();
    this.accounts = new ListField<UserAccountModel>();
    this.email = new SimpleField<string>('');
    this.password = new SimpleField<string>('');
    this.filter = new SimpleField<string>('');
  }

  getAccounts = async () => {
    let accounts = [];
    const hasCredentials = this.hasPasswordAndEmail;

    try {
      const response = await this.repositoryAccountsSearch[hasCredentials ? 'create' : 'get'](this.options, {
        requireAuth: !hasCredentials,
      });

      accounts = response.items.map(({ accountId, description, accountIdGts, isLocked, planDescription }) => {
        return new UserAccountModel(
          {
            accountId,
            description,
            accountIdGts,
            isLocked,
            planDescription,
          },
          this.active.accountId.value === accountId
        );
      });
    } catch (err) {
      let message = 'Unknown error';

      if (err instanceof Error) {
        message = err.message;
      }

      Error(message);
    }

    this.accounts.replace(accounts);
  };

  reset = () => {
    this.active.set();
    this.accounts.reset();
  };

  get repositoryAccountsSearch(): IEntityRepository {
    return this.hasPasswordAndEmail
      ? repositoryService.get('auth').entity('accounts')
      : repositoryService.get('users').entity('current').entity('accounts');
  }

  private get hasPasswordAndEmail() {
    return this.email.value && this.password.value;
  }

  private get options() {
    return {
      searchValue: this.filter.value,
      emailId: this.email.value,
      password: this.password.value,
      order: 'ASC',
      sortBy: 'description',
      appName: 'web',
    };
  }
}

export default UserDetailsModel;
