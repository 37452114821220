import { computed } from 'mobx';
import type { MEDIA_CLIP_STATUS } from 'config';
import { ListField, ToggleField } from 'models/Fields';
import Driver from './MediaClipsItem/Driver';
import MediaInfo from './MediaClipsItem/MediaInfo';
import MediaClipsInteraction from './MediaClipsItem/MediaClipsInteraction';
import MediaClipDate from './MediaClipsItem/MediaClipDate';
import type MediaLibrary from '../index';

class MediaClipsItem {
  address: string;
  driver: Driver;
  assetId: number;
  displayName: string;
  eventTypes: ListField<string>;
  mediaStatus: MEDIA_CLIP_STATUS;
  batchId: string;
  updated: number;
  expiringSoon: ToggleField;
  media: MediaInfo;
  date: MediaClipDate;
  interaction: MediaClipsInteraction;
  context: MediaLibrary;

  constructor(item, context) {
    this.setSimpleFields(item);
    this.driver = new Driver(item.driver);
    this.eventTypes = new ListField(item.eventTypes);
    this.date = new MediaClipDate(item.timestamp);
    this.media = new MediaInfo(item.mediaObjects || [], this);
    this.context = context;
    this.setInteraction();
  }

  setSimpleFields = ({ address, assetId, displayName, mediaStatus, batchId, updated, expiringSoon }) => {
    this.address = address || '';
    this.assetId = assetId;
    this.displayName = displayName;
    this.mediaStatus = mediaStatus;
    this.batchId = batchId;
    this.updated = updated;
    this.expiringSoon = expiringSoon;
  };

  @computed get coordinates() {
    const location = this.media.items[0]?.location;
    if (location) {
      const { lng, lat } = location;
      if (!lng && !lat) {
        return null;
      }
      return {
        lng,
        lat,
      };
    }
    return null;
  }

  update = (item) => {
    this.setSimpleFields(item);
    this.driver.set(item.driver);
    this.eventTypes.replace(item.eventTypes);
    this.date.set(item.timestamp);
    this.media.update(item.mediaObjects || []);
  };

  setInteraction = () => {
    const status = this.date.value.toString() === this.context.redirect.timestamp.value ? 'active' : 'default';

    this.interaction = new MediaClipsInteraction(status);
  };

  get isActive() {
    return this.interaction.status.value === 'active';
  }
}

export default MediaClipsItem;
