import React, { FC } from 'react';

import RadioGroup from 'components/RadioGroup';

import './styles.scss';

const options = [
  {
    id: 'geozone',
    label: 'Geozone',
    name: 'geozone',
    value: 'geozone',
  },
  {
    id: 'tag',
    label: 'Category',
    name: 'tag',
    value: 'tag',
  },
];

interface IProps {
  checkedValue: string;
  onChange: (val: string) => void;
  GeozoneSelect: React.ReactNode;
  TagSelect: React.ReactNode;
  radioName?: string;
}

const GeozoneTagRadioSwitcher: FC<IProps> = ({ checkedValue, onChange, GeozoneSelect, TagSelect, radioName = '' }) => {
  return (
    <div className="GeozoneTagRadioSwitcher">
      <RadioGroup
        checkedValue={checkedValue}
        onChange={onChange}
        radios={options.map((option) => ({
          ...option,
          name: `${option.name}_${radioName}`,
          id: `${option.id}_${radioName}`,
        }))}
        tabs
      />
      <div className="GeozoneTagRadioSwitcher-content">
        {checkedValue === options[0].value ? GeozoneSelect : TagSelect}
      </div>
    </div>
  );
};

export default GeozoneTagRadioSwitcher;
