import validateAccessLevel from './validator';

function userAccessLevel(requiredAccessLevel: string[], reject: React.ReactNode = null, strict: boolean = false) {
  return (constructor: any) => {
    constructor.prototype.render = new Proxy(constructor.prototype.render, {
      apply(target, thisArg, args) {
        const hasAccess = validateAccessLevel(requiredAccessLevel, strict);
        if (hasAccess) {
          return target.apply(thisArg, args);
        }
        return reject;
      },
    });
  };
}

export default userAccessLevel;
