import React, { Component } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import moment from 'moment';

import { timeZoneOffset, isToday as isTodayChecker } from 'utils';

import { TIME_OPTIONS_ARRAY, DATE_TIME_FORMATS, TIME } from 'config';

import Calendar from 'components/Calendar';
import DateField from 'components/DateField';
import TimePicker from 'components/TimePicker';
import Button from 'components/Button';
import TimeHintTooltip from '../TimeHintTooltip';

import './styles.scss';

interface IProps {
  className?: string;
  date?: Filters.IDateFilterOption;
  timezone: string;
  isToday?: boolean;
  onApplyDate: (date: Filters.IDateFilterOption) => void;
  pathToBack: string;
}

interface IState {
  date: {
    from: Date;
    to: Date;
  };
  time: {
    from: string;
    to: string;
  };
}

class CustomTimeFilterWithRange extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    const { date, isToday } = this.props;
    const defaultToTime = isToday ? moment().format(DATE_TIME_FORMATS.hoursMinutesSpaceAm) : TIME.endOfTheDay;

    this.state = {
      date: {
        from: (date.from && new Date(date.from)) || new Date(),
        to: (date.to && new Date(date.to)) || new Date(),
      },
      time: {
        from: (date.from && moment(date.from).format(DATE_TIME_FORMATS.hoursMinutesSpaceAm)) || TIME.beggingOfTheDay,
        to: (date.to && moment(date.to).format(DATE_TIME_FORMATS.hoursMinutesSpaceAm)) || defaultToTime,
      },
    };

    this.handleApplyDate = this.handleApplyDate.bind(this);
  }

  checkToDate = (toDate: Date) => {
    if (!toDate || !isTodayChecker(toDate.getTime())) {
      this.setState({
        time: {
          ...this.state.time,
          to: TIME.endOfTheDay,
        },
      });
    }
  };

  setDate = (range) => {
    this.setState({ date: range }, () => this.checkToDate(range.to));
  };

  setTime = (time, period) => {
    this.setState({
      time: {
        ...this.state.time,
        [period]: time,
      },
    });
  };

  handleApplyDate() {
    const { date, time } = this.state;
    const { onApplyDate, timezone } = this.props;
    const from =
      moment(`${moment(date.from).format(DATE_TIME_FORMATS.fullMonthComaDateYear)} ${time.from} +00:00`).valueOf() +
      timeZoneOffset(timezone);
    const to =
      moment(`${moment(date.to).format(DATE_TIME_FORMATS.fullMonthComaDateYear)} ${time.to} +00:00`).valueOf() +
      timeZoneOffset(timezone) +
      59 * 1000;
    const timeRange = to >= from ? { from, to } : { from: to, to: from };

    onApplyDate(timeRange);
  }

  render() {
    const { date, time } = this.state;
    const { pathToBack, className } = this.props;
    const cn = classNames('CustomTimeFilterWithRange', className);
    const todayDate = new Date();
    const fromMonth = new Date(new Date().getFullYear() - 4, 0);
    const timeOptions = TIME_OPTIONS_ARRAY.slice();

    forEach(time, (selectedTime, index) => {
      const isSelectedTimeInTimeOptions = find(TIME_OPTIONS_ARRAY, { value: selectedTime });

      if (!isSelectedTimeInTimeOptions) {
        timeOptions.push({
          key: index,
          value: selectedTime,
          text: selectedTime.replace(' ', ''),
        });
      }
    });

    return (
      <div className={cn}>
        <div className="CustomTimeFilterWithRange-content">
          <div className="CustomTimeFilterWithRange-header">
            <NavLink className="CustomTimeFilterWithRange-backButton" to={pathToBack} />
            <div className="CustomTimeFilterWithRange-title">Select a date range</div>
            <div className="CustomTimeFilterWithRange-tooltip">
              <TimeHintTooltip />
            </div>
          </div>
          <div className="CustomTimeFilterWithRange-calendar">
            <Calendar
              withMonthYearForm
              withRange
              from={date.from}
              to={date.to}
              fromMonth={fromMonth}
              toMonth={todayDate}
              onClick={this.setDate}
              month={date.to}
              disabledDays={[{ before: undefined, after: todayDate }]}
            />
          </div>
          <div className="CustomTimeFilterWithRange-time CustomTimeFilterWithRange-time--from">
            <p className="CustomTimeFilterWithRange-timeTitle">From</p>
            <div className="CustomTimeFilterWithRange-dateField">
              <DateField date={date.from} />
            </div>
            <div className="CustomTimeFilterWithRange-timeDropdown">
              <TimePicker
                placeholder="Select time"
                options={timeOptions}
                onChange={(val) => this.setTime(val, 'from')}
                value={time.from}
                fluid
              />
            </div>
          </div>
          <div className="CustomTimeFilterWithRange-time CustomTimeFilterWithRange-time--to">
            <p className="CustomTimeFilterWithRange-timeTitle">To</p>
            <div className="CustomTimeFilterWithRange-dateField">
              <DateField date={date.to} />
            </div>
            <div className="CustomTimeFilterWithRange-timeDropdown">
              <TimePicker
                placeholder="Select time"
                options={timeOptions}
                onChange={(val) => this.setTime(val, 'to')}
                value={time.to}
                fluid
              />
            </div>
          </div>
        </div>

        <div className="CustomTimeFilterWithRange-buttons">
          <div className="CustomTimeFilterWithRange-button CustomTimeFilterWithRange-button--cancel">
            <NavLink className="Button Button--cancel" to={pathToBack}>
              Cancel
            </NavLink>
          </div>
          <div className="CustomTimeFilterWithRange-button CustomTimeFilterWithRange-button--apply">
            <Button
              title="Apply"
              className="Button--apply"
              onClick={this.handleApplyDate}
              disabled={Boolean(!date.from || !date.to)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CustomTimeFilterWithRange;
