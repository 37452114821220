import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { CustomersStore } from 'stores';

import SecondaryPanelAttributesRow from '../../SecondaryPanelAttributes/SecondaryPanelAttributesRow';
import SecondaryPanelAttributesCol from '../../SecondaryPanelAttributes/SecondaryPanelAttributesCol';

interface IProps {
  customersStore?: CustomersStore;
}


@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class SecondaryPanelJobServiceAddress extends Component<IProps> {
  get address () {
    const address = this.props.customersStore.selectedCustomer?.jobDetails?.address;
    if (address && (address.line1 || address.line2) && address.city) {
      return <div>
        <div>{address.line1 || ''}{address.line2 || ''}</div>
        <div>{address.city}, {address.countrySubDivisionCode} {address.postalCode} {address.country}</div>
      </div>
    }
    return null;
  }

  get customerContacts() {
    return this.props.customersStore.selectedCustomer?.jobDetails?.customerContacts;
  }

  render() {
    const address = this.address;
    const customerContacts = this.customerContacts;

    return <div className="SecondaryPanelJobDetails">
      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Contact</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{customerContacts.name || '-'}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>

      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Address</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{address ?? '-'}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>

      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Phone</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{customerContacts.phoneNumber || '-'}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>

      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Email</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{customerContacts.email || '-'}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>
    </div>;
  }
}

export default SecondaryPanelJobServiceAddress;
