import React, { useEffect } from 'react';
import { ALERTS_APPLIES_TO } from 'config';

import InfoTooltip from 'components/InfoTooltip';

import type { IAlert } from 'models/Alerts/IAlert';
import AfterHoursAlertModel from 'models/Alerts/AfterHoursAlert';
import AlertWithIntervalModel from 'models/Alerts/AlertWithInterval';
import SpeedOverAlertModel from 'models/Alerts/SpeedsOverAlert';
import MaintenanceAlertModel from 'models/Alerts/MaintenanceAlertModel';
import ExcessiveIdlingAlertModel from 'models/Alerts/ExcessiveIdlingAlert';
import IgnitionAlertModel from 'models/Alerts/IgnitionAlert';
import GeozoneAlertModel from 'models/Alerts/GeozoneAlert';
import CustomAlertModel from 'models/Alerts/CustomAlert';

import ActiveToggle from 'components/ActiveToggle';
import AlertPreferenceFinalizeSimpleLine from './AlertPreferenceFinalizeSimpleLine';
import AlertPreferenceFinalizeAfterHoursLine from './AlertPreferenceFinalizeAfterHoursLine';
import AlertPreferenceFinalizeOptionsLine from './AlertPreferenceFinalizeOptionsLine';
import AlertPreferenceFinalizeWorkHoursLine from './AlertPreferenceFinalizeWorkHoursLine';
import AlertPreferenceFinalizeSpeedsOverLine from './AlertPreferenceFinalizeSpeedsOverLine';
import AlertPreferenceFinalizeMaintenanceLine from './AlertPreferenceFinalizeMaintenanceLine';
import AlertPreferenceFinalizeMaintenanceDueLine from './AlertPreferenceFinalizeMaintenanceDueLine';
import AlertPreferenceFinalizeExcessiveIdlingLine from './AlertPreferenceFinalizeExcessiveIdlingLine';
import AlertPreferenceFinalizeIgnitionLine from './AlertPreferenceFinalizeIgnitionLine';
import AlertPreferenceFinalizeGeozoneLine from './AlertPreferenceFinalizeGeozoneLine';
import FuelingExceptionAlertModel from 'models/Alerts/FuelingExceptionAlert';
import MaintenanceDueAlertModel from 'models/Alerts/MaintenanceDueAlertModel';

import './styles.scss';

interface IProps {
  alert: IAlert;
  setIsActiveNextButton: (value: boolean) => void;
}

const AlertPreferenceFinalize: React.FC<IProps> = ({ alert, setIsActiveNextButton }) => {
  useEffect(() => {
    setIsActiveNextButton(true);
  }, []);

  const notifyValue = [...alert.notifyEmails.toArray(), ...alert.notifyMobileNumbers.toArray()].join(', ');

  return (
    <div className="AlertPreferenceFinalize">
      <div className="AlertPreferenceFinalize-header">
        <div className="AlertPreferenceFinalize-title">
          <p>{alert.title ? alert.title : alert.type.value} Alert</p>
          {alert.description && <InfoTooltip>{alert.description}</InfoTooltip>}
        </div>
        <ActiveToggle isActive={alert.isActive} />
      </div>
      <div className="AlertPreferenceFinalize-content">
        <AlertPreferenceFinalizeSimpleLine label="Alert Name" value={alert.displayName.value} />
        {alert instanceof CustomAlertModel && <AlertPreferenceFinalizeSimpleLine label="Alert ID" value={alert.id} />}
        <AlertPreferenceFinalizeSimpleLine
          label="Applies to"
          value={
            alert.metadata.appliesTo.value === ALERTS_APPLIES_TO.DASHCAM
              ? alert.metadata.dashcamId.name.value
              : alert.metadata.asset.name.value || alert.metadata.assetGroup.name.value
          }
        />
        {alert instanceof CustomAlertModel && (
          <AlertPreferenceFinalizeSimpleLine label="Status Code" value={alert.statusCode.name.value} />
        )}
        {alert instanceof CustomAlertModel && (
          <AlertPreferenceFinalizeSimpleLine label="Cron Option" value={alert.cronOption.name.value} />
        )}

        {alert instanceof CustomAlertModel && (
          <AlertPreferenceFinalizeSimpleLine label="Rule Selector" value={alert.selector.value} />
        )}

        {alert instanceof CustomAlertModel && (
          <AlertPreferenceFinalizeOptionsLine label="Minimum interval" showAlways interval={alert.interval} />
        )}

        {alert instanceof CustomAlertModel && (
          <AlertPreferenceFinalizeSimpleLine
            label="Trigger action"
            value={
              [alert.emailNotificationEnabled.value && 'Email', alert.queueNotificationEnabled.value && 'Queue']
                .filter((item) => item)
                .join(', ') || 'None'
            }
          />
        )}

        {alert instanceof AfterHoursAlertModel && (
          <>
            <AlertPreferenceFinalizeAfterHoursLine label="Alert When" type={alert.subType.value} />
            <AlertPreferenceFinalizeWorkHoursLine
              label="Work Hours"
              schedule={alert.schedule}
              scheduleOverride={alert.scheduleOverride}
              option={alert.scheduleOverrideOption.value}
            />
          </>
        )}
        {alert instanceof GeozoneAlertModel && (
          <AlertPreferenceFinalizeGeozoneLine label="Alert When" actionType={alert.actionType} id={alert.place} />
        )}
        {alert instanceof SpeedOverAlertModel && (
          <AlertPreferenceFinalizeSpeedsOverLine label="Alert When" speed={alert.speed.value} />
        )}
        {alert instanceof MaintenanceDueAlertModel && (
          <AlertPreferenceFinalizeMaintenanceDueLine
            label="Alert When"
            type={alert.subType}
            dueValue={alert.dueValue}
          />
        )}
        {alert instanceof MaintenanceAlertModel && <AlertPreferenceFinalizeMaintenanceLine text={alert.text} />}
        {alert instanceof ExcessiveIdlingAlertModel && (
          <AlertPreferenceFinalizeExcessiveIdlingLine label="Alert When" idleMinutes={alert.idleMinutes} />
        )}
        {alert instanceof IgnitionAlertModel && (
          <AlertPreferenceFinalizeIgnitionLine label="Alert When" ignitionOn={alert.ignitionOn} />
        )}
        {alert instanceof FuelingExceptionAlertModel && (
          <AlertPreferenceFinalizeSimpleLine
            label="Exception"
            value={alert.exceptionType.options.find(({ id }) => id === alert.exception.value).name}
          />
        )}
        {alert instanceof AlertWithIntervalModel && !(alert instanceof CustomAlertModel) && (
          <AlertPreferenceFinalizeOptionsLine
            label="Options"
            startText="After alerting, disable this alert for"
            interval={alert.interval}
            option={alert.intervalOption}
          />
        )}

        <AlertPreferenceFinalizeSimpleLine label="Notify" value={notifyValue} />

        {alert instanceof CustomAlertModel && (
          <AlertPreferenceFinalizeSimpleLine label="Email subject" value={alert.emailSubject.value} />
        )}

        {alert instanceof CustomAlertModel && (
          <AlertPreferenceFinalizeSimpleLine label="Email message" value={alert.emailText.value} />
        )}

        {alert instanceof CustomAlertModel && (
          <AlertPreferenceFinalizeSimpleLine label="Text message" value={alert.smsText.value} />
        )}

        {alert instanceof MaintenanceAlertModel && (
          <AlertPreferenceFinalizeSimpleLine label="Custom Alert" value={alert.text.message} />
        )}
      </div>
    </div>
  );
};

export default AlertPreferenceFinalize;
