import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { ClearshareStore, DevicesStore, MapStore, TimeStore } from 'stores';

import CreateLinkModal from 'components/ClearshareLink/CreateLinkModal';
import ChangesConfirmationModal from 'components/ClearshareLink/ChangesConfirmationModal';
import Notification from 'components/Notification';

interface IProps {
  className?: string;
  clearshareStore?: ClearshareStore;
  devicesStore?: DevicesStore;
  isCreateClearshareLinkOpen?: boolean;
  mapStore?: MapStore;
  onCloseClearshareLink?: () => void;
  timeStore?: TimeStore;
}

interface IState {
  withoutSavingModal: boolean;
}

@inject(({ devicesMapStore: { devicesStore, mapStore }, clearshareStore, timeStore }) => ({
  devicesStore,
  mapStore,
  clearshareStore,
  timeStore,
}))
@observer
class ClearShareCreate extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      withoutSavingModal: false,
    };
  }

  openWithoutSavingModal = () => this.setState({ withoutSavingModal: true });

  closeWithoutSavingModal = () => this.setState({ withoutSavingModal: false });

  submitWithoutSavingModal = () => {
    const { onCloseClearshareLink } = this.props;

    onCloseClearshareLink();
    this.closeWithoutSavingModal();
  };

  closeLinkModal = (dirty?: boolean) => {
    const { onCloseClearshareLink } = this.props;

    if (dirty) {
      this.openWithoutSavingModal();
    } else {
      onCloseClearshareLink();
    }
  };

  saveLink = async ({ name, destination, emailPhone, expiration, email, phoneNumber, notes }) => {
    const {
      devicesStore: {
        selectedDevice: {
          data: { deviceId },
        },
      },
      clearshareStore: { createLink },
      onCloseClearshareLink,
      timeStore: { sessionTimezone },
    } = this.props;

    await createLink({
      deviceId,
      name,
      destination: destination.latitude && destination.longitude ? destination : null,
      expiration,
      recipient: {
        email: emailPhone === 'email' ? email : null,
        phoneNumber: emailPhone === 'phoneNumber' ? phoneNumber : null,
      },
      notes,
      userTimeZone: sessionTimezone,
    });

    onCloseClearshareLink();
  };

  render() {
    const {
      className,
      clearshareStore: { repositoryClearshare },
      isCreateClearshareLinkOpen,
      mapStore: { currentMapBounds },
      timeStore: { sessionTimezone },
    } = this.props;
    const { withoutSavingModal } = this.state;

    return (
      <div className={className}>
        <CreateLinkModal
          currentMapBounds={currentMapBounds}
          isOpen={isCreateClearshareLinkOpen}
          onClose={this.closeLinkModal}
          onSubmit={this.saveLink}
          timeZone={sessionTimezone}
        />
        <ChangesConfirmationModal
          isOpen={withoutSavingModal}
          onClose={this.closeWithoutSavingModal}
          onSubmit={this.submitWithoutSavingModal}
        />
        {repositoryClearshare.createState.success && (
          <Notification
            text="The ClearShare link was successfully sent."
            title="Success!"
            type="success"
            onClose={repositoryClearshare.createState.reset}
          />
        )}
        {repositoryClearshare.createState.error && (
          <Notification
            text="Something went wrong. Please try again. "
            title="ClearShare Link Failed to Send"
            type="error"
            onClose={repositoryClearshare.createState.reset}
          />
        )}
      </div>
    );
  }
}

export default ClearShareCreate;
