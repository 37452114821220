import React, { FC } from 'react';

import { CalendarCar } from 'assets';
import './styles.scss';

interface IProps {
  date: string;
}

const InspectionPerformedPanelTitle: FC<IProps> = ({ date }) => {
  return (
    <div className="InspectionPerformedPanelTitle">
      <div className="InspectionPerformedPanelTitle-header">Inspection</div>
      <div className="InspectionPerformedPanelTitle-content">
        <div className="InspectionPerformedPanelTitle-icon">
          <CalendarCar />
        </div>
        <div className="InspectionPerformedPanelTitle-date">{date}</div>
      </div>
    </div>
  );
};

export default InspectionPerformedPanelTitle;
