import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import type { RouteComponentProps } from 'react-router-dom';

import type { DevicesStore, MapStore } from 'stores';
import { PATHS } from 'config';

import CustomTimeFilter from 'components/SubNav/CustomTimeFilter';

interface IProps extends RouteComponentProps {
  devicesStore: DevicesStore;
  mapStore: MapStore;
}

@inject(({ devicesMapStore: { devicesStore, mapStore } }) => ({
  devicesStore,
  mapStore,
}))
@observer
class VehiclesCustomTimeFilter extends Component<IProps> {
  private get pathToRedirect() {
    const {
      devicesStore: { groups },
      history: {
        location: { pathname },
      },
    } = this.props;

    switch (true) {
      case pathname.includes('/vehicles/'):
        return PATHS.MAP.VEHICLES;

      case pathname.includes('/groups'):
        return `${PATHS.MAP.GROUPS}/${groups.groupId.value}`;

      case pathname.includes('/geozones/'):
        return PATHS.MAP.GEOZONES;

      default:
        return PATHS.MAP.VEHICLES;
    }
  }

  handleApplyDate = async ({ from, to }) => {
    const {
      devicesStore: {
        filters: { applyDate, applyTempDate },
      },
      history: { push },
    } = this.props;

    await applyDate({ from, to });
    await applyTempDate({ from, to });
    push(this.pathToRedirect);
  };

  render() {
    const {
      devicesStore,
      mapStore: { timezone },
    } = this.props;
    const {
      filters: { date },
      groups,
    } = devicesStore;
    const cn = classNames('CustomTimeFilter--withExtraHeight', {
      'CustomTimeFilter--vehicles': !Boolean(groups.groupId.value),
      'CustomTimeFilter--groups': Boolean(groups.groupId.value),
    });

    return (
      <Fragment>
        <CustomTimeFilter
          className={cn}
          date={date}
          timezone={timezone}
          onApplyDate={this.handleApplyDate}
          pathToBack={this.pathToRedirect}
        />
      </Fragment>
    );
  }
}

export default VehiclesCustomTimeFilter;
