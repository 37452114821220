import React, { FC } from 'react';

import classnames from 'classnames';

import type InspectionItem from 'models/Inspections/Models/InspectionItem';
import PhotoTile from 'components/FileManager/PhotoTile';
import InfoTooltip from 'components/InfoTooltip';
import InspectionItemStatusIcon from 'components/Inspections/InspectionItemStatusIcon';

import { WrenchPlus } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  item: InspectionItem;
  onCreateTask: (itemId: number) => void;
}

const InspectionPerformedPanelItem: FC<IProps> = ({ className, item, onCreateTask }) => {
  const cn = classnames('InspectionPerformedPanelItem', className);

  return (
    <div className={cn}>
      <div className="InspectionPerformedPanelItem-header">
        <div className="InspectionPerformedPanelItem-headerSection">
          <div className="InspectionPerformedPanelItem-status">
            <InspectionItemStatusIcon status={item.status} />
          </div>
          <p className="InspectionPerformedPanelItem-name">{item.name.value}</p>
          {Boolean(item.instruction.value) && (
            <div className="InspectionPerformedPanelItem-note">
              <InfoTooltip>{item.instruction.value}</InfoTooltip>
            </div>
          )}
        </div>
        <div className="InspectionPerformedPanelItem-headerSection">
          <p className="InspectionPerformedPanelItem-createTask" onClick={() => onCreateTask(item.id)}>
            <WrenchPlus />
          </p>
        </div>
      </div>
      <div className="InspectionPerformedPanelItem-content">
        <div className="InspectionPerformedPanelItem-description">{item.notes}</div>
        <div className="InspectionPerformedPanelItem-attachments">
          {item.attachments.map((attachment) => (
            <div className="InspectionPerformedPanelItem-photo" key={attachment.model.id}>
              <PhotoTile file={attachment} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InspectionPerformedPanelItem;
