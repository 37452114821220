import { ALERTS_TYPE } from 'config';
import type { AlertsTableStore } from 'stores';
import type { IAlert, IServerAlert } from './IAlert';
import AfterHoursAlertModel from './AfterHoursAlert';
import AlertModel from './Alert';
import BrakingAlertModel from './BrakingAlert';
import CorneringAlertModel from './CorneringAlert';
import CustomAlertModel from './CustomAlert';
import ExcessiveIdlingAlertModel from './ExcessiveIdlingAlert';
import FaultCodesAlertModel from './FaultCodesAlert';
import GeozoneAlertModel from './GeozoneAlert';
import IgnitionAlertModel from './IgnitionAlert';
import LowVehicleBatteryAlertModel from './LowVehicleBatteryAlert';
import MaintenanceAlertModel from './MaintenanceAlertModel';
import MaintenanceDueAlertModel from './MaintenanceDueAlertModel';
import PowerDisconnectTrackerAlert from './PowerDisconnectTrackerAlert';
import PowerDisconnectDashCamAlert from './PowerDisconnectDashCamAlert';
import SpeedOverAlertModel from './SpeedsOverAlert';
import AccelerationAlertModel from './AccelerationAlert';
import FuelingExceptionAlertModel from './FuelingExceptionAlert';

class AlertFactory {
  private item: IServerAlert;

  constructor(item) {
    this.item = item;
  }

  get = (context: AlertsTableStore): IAlert => {
    switch (this.item.type) {
      case ALERTS_TYPE.ACCELERATION:
        return new AccelerationAlertModel(this.item).initialize(context);
      case ALERTS_TYPE.CUSTOM:
        return new CustomAlertModel(this.item).initialize(context);
      case ALERTS_TYPE.AFTER_HOURS:
        return new AfterHoursAlertModel(this.item).initialize(context);
      case ALERTS_TYPE.BRAKING:
        return new BrakingAlertModel(this.item).initialize(context);
      case ALERTS_TYPE.CORNERING:
        return new CorneringAlertModel(this.item).initialize(context);
      case ALERTS_TYPE.EXCESSIVE_IDLING:
        return new ExcessiveIdlingAlertModel(this.item).initialize(context);
      case ALERTS_TYPE.FAULT_CODES:
        return new FaultCodesAlertModel(this.item).initialize(context);
      case ALERTS_TYPE.GEOZONE:
        return new GeozoneAlertModel(this.item).initialize(context);
      case ALERTS_TYPE.IGNITION:
        return new IgnitionAlertModel(this.item).initialize(context);
      case ALERTS_TYPE.LOW_VEHICLE_BATTERY:
        return new LowVehicleBatteryAlertModel(this.item).initialize(context);
      case ALERTS_TYPE.MAINTENANCE:
        return new MaintenanceAlertModel(this.item).initialize(context);
      case ALERTS_TYPE.MAINTENANCE_DUE:
        return new MaintenanceDueAlertModel(this.item).initialize(context);
      case ALERTS_TYPE.POWER_DISCONNECT_TRACKER:
        return new PowerDisconnectTrackerAlert(this.item).initialize(context);
      case ALERTS_TYPE.POWER_DISCONNECT_DASHCAM:
        return new PowerDisconnectDashCamAlert(this.item).initialize(context);
      case ALERTS_TYPE.SPEEDS_OVER:
        return new SpeedOverAlertModel(this.item).initialize(context);
      case ALERTS_TYPE.FUELING_EXCEPTION:
        return new FuelingExceptionAlertModel(this.item).initialize(context);
      default:
        return new AlertModel(this.item).initialize(context);
    }
  };
}

export default AlertFactory;
