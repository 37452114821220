export const mediaChartOptions = {
  title: {
    display: false,
  },
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        display: false,
      },
    ],
    yAxes: [
      {
        ticks: {
          fontColor: '#6B7A99',
          fontSize: 12,
          fontWeight: 500,
        },
      },
    ],
  },
  legend: {
    display: false,
  },
  tooltips: {
    displayColors: false,
    callbacks: {
      title: () => {
        return '';
      },
      label: (tooltipItem, data) => {
        return data.datasets[0].data[tooltipItem.index] + ' mph';
      },
    },
    intersect: false,
    responsive: true,
    position: 'nearest',
  },
  width: 630,
};

export const datasetOptions = {
  borderColor: '#C9C9C9',
  fill: false,
  pointRadius: 1,
};
