import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import find from 'lodash/find';

import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';
import { TIME_STORE, TIMEZONE_OPTIONS } from 'config';
import type { TimeStore } from 'stores';

interface IProps {
  timeStore?: TimeStore;
  onChange?: (value: string) => void;
}

@inject(TIME_STORE)
@observer
class TimezoneSelect extends Component<IProps> {
  get currentTimezone() {
    const { sessionTimezone } = this.props.timeStore;
    const currentTZData = find(TIMEZONE_OPTIONS, { value: sessionTimezone });

    return { label: currentTZData?.value, value: currentTZData?.key };
  }

  get values() {
    return [
      ...TIMEZONE_OPTIONS.map(({ key, value }) => {
        return { label: value, value: key };
      }),
    ];
  }

  handleModeChange = ({ label }) => {
    const {
      timeStore: { setSessionTimeZone },
      onChange,
    } = this.props;

    setSessionTimeZone(label);

    if (onChange) {
      onChange(label);
    }
  };

  render() {
    return (
      <SearchableStaticSelect
        isSearchable={false}
        value={this.currentTimezone}
        values={this.values}
        handleChange={this.handleModeChange}
      />
    );
  }
}

export default TimezoneSelect;
