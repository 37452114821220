import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import './styles.scss';

interface IProps {
  className?: string;
  title: string;
  to: string;
}

const NavLink: FC<IProps> = ({ className, title, to }) => {
  const cn = classNames('NavLink', className);

  return (
    <Link className={cn} to={to}>
      {title}
    </Link>
  );
};

export default NavLink;
