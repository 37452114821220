import React from 'react';
import { Observer } from 'mobx-react';
import classnames from 'classnames';

import type MaintenanceCompleteCostsModel from 'models/Maintenances/Models/CompleteTask/MaintenanceCompleteCostsModel';

import InputNumber from 'components/InputNumber';

import './styles.scss';

interface IProps {
  costs: MaintenanceCompleteCostsModel;
  edit?: boolean;
  className?: string;
}

const MaintenanceCosts: React.FC<IProps> = ({ costs, edit = true, className = '' }) => {
  const changeParts = (value) => {
    const newValue = Number(Number(value).toFixed(2));

    costs.parts.set(newValue);
    costs.total.set(costs.labor.value + costs.other.value + newValue);
  };

  const changeLabor = (value) => {
    const newValue = Number(Number(value).toFixed(2));

    costs.labor.set(newValue);
    costs.total.set(costs.parts.value + costs.other.value + newValue);
  };

  const changeOther = (value) => {
    const newValue = Number(Number(value).toFixed(2));

    costs.other.set(newValue);
    costs.total.set(costs.parts.value + costs.labor.value + newValue);
  };

  const changeTotal = (value) => {
    const newValue = Number(Number(value).toFixed(2));

    costs.total.set(newValue);
  };

  const cn = classnames('MaintenanceCosts', className);

  return (
    <Observer
      render={() => {
        const cnParts = classnames('MaintenanceCosts-content', {
          'MaintenanceCosts-content--inactive': !Boolean(costs.parts.value),
        });

        const cnLabor = classnames('MaintenanceCosts-content', {
          'MaintenanceCosts-content--inactive': !Boolean(costs.labor.value),
        });

        const cnOther = classnames('MaintenanceCosts-content', {
          'MaintenanceCosts-content--inactive': !Boolean(costs.other.value),
        });

        const cnTotal = classnames('MaintenanceCosts-content', {
          'MaintenanceCosts-content--inactive': !Boolean(costs.total.value),
        });

        return (
          <div className={cn}>
            <div className="MaintenanceCosts-details">
              <div className={cnParts}>
                <p className="MaintenanceCosts-contentTitle">Parts</p>
                <InputNumber
                  prefix="$"
                  placeholder="0.00"
                  disabled={!edit}
                  value={
                    costs.parts.value ? costs.parts.value.toLocaleString('en-US', { maximumFractionDigits: 2 }) : ''
                  }
                  onChange={changeParts}
                  step="0.01"
                  min="0"
                  max="9999999"
                  controls={false}
                />
              </div>
              <div className={cnLabor}>
                <p className="MaintenanceCosts-contentTitle">Labor</p>
                <InputNumber
                  prefix="$"
                  placeholder="0.00"
                  disabled={!edit}
                  value={
                    costs.labor.value ? costs.labor.value.toLocaleString('en-US', { maximumFractionDigits: 2 }) : ''
                  }
                  onChange={changeLabor}
                  step="0.01"
                  min="0"
                  max="9999999"
                  controls={false}
                />
              </div>
              <div className={cnOther}>
                <p className="MaintenanceCosts-contentTitle">Other</p>
                <InputNumber
                  prefix="$"
                  placeholder="0.00"
                  disabled={!edit}
                  value={
                    costs.other.value ? costs.other.value.toLocaleString('en-US', { maximumFractionDigits: 2 }) : ''
                  }
                  onChange={changeOther}
                  step="0.01"
                  min="0"
                  max="9999999"
                  controls={false}
                />
              </div>
            </div>
            <div className="MaintenanceCosts-total">
              <div className={cnTotal}>
                <p className="MaintenanceCosts-contentTitle">Total</p>
                <InputNumber
                  prefix="$"
                  placeholder="0.00"
                  disabled={
                    !edit || Boolean(costs.parts.value) || Boolean(costs.labor.value) || Boolean(costs.other.value)
                  }
                  value={
                    costs.total.value ? costs.total.value.toLocaleString('en-US', { maximumFractionDigits: 2 }) : ''
                  }
                  onChange={changeTotal}
                  step="0.01"
                  min="0"
                  max="9999999"
                  controls={false}
                />
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default MaintenanceCosts;
