import React, { FC } from 'react';

import type ApplyTaskCard from 'models/Maintenances/Representation/ApplyTaskCard';
import TaskToApplyDueInOnMetrics from 'components/Maintenance/TaskToApply/TaskToApplyDueInOnMetrics';
import Button from 'components/Button';

import './styles.scss';

interface IProps {
  task: ApplyTaskCard;
  editDisabled?: boolean;
  onEdit: () => void;
}

const TaskToApplyCard: FC<IProps> = ({ task, editDisabled = false, onEdit }) => {
  return (
    <div className="TaskToApplyCard">
      <div className="TaskToApplyCard-title">
        <span className="TaskToApplyCard-name">{task.nameText}</span>
        <div className="TaskToApplyCard-edit">
          <Button className="Button--apply Button--link" title="Edit" onClick={onEdit} disabled={editDisabled} />
        </div>
      </div>
      <div className="TaskToApplyCard-service">
        {task.serviceTypeText} | {task.frequencyText}
      </div>
      <div className="TaskToApplyCard-rows">
        <TaskToApplyDueInOnMetrics task={task} />
      </div>
    </div>
  );
};

export default TaskToApplyCard;
