import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import { ACL, TIME_ZONE_INFO_TEXT, VEHICLE_DRIVER_NAME_READ } from 'config';
import { DevicesStore, MapStore, TimeStore, UserAccessLevelComponent } from 'stores';

import InfoTooltip from 'components/InfoTooltip';
import LayerTitle from 'components/Layers/LayerTitle';
import MapLayerButton from 'components/Layers/MapLayerButton';
import MapTypeButton from 'components/Layers/MapTypeButton';
import RadioGroup from 'components/RadioGroup';
import RangeSelector from 'components/RangeSelector';
import TimezoneSelect from 'containers/Select/TimezoneSelect';
import Checkbox from 'components/Checkbox';

import { defaultMapIconSrc, satelliteMapIconSrc, terrainMapIconSrc, TrafficIcon } from 'assets';

import './styles.scss';
import {
  clusteringSensitivityOptions,
  clusteringSensitivityToggleOptions,
  mapLabelsOptions,
  vehicleLabelOptions,
} from './layersConfig';

interface IProps {
  mapStore?: MapStore;
  devicesStore?: DevicesStore;
  timeStore?: TimeStore;
}

@inject(({ devicesMapStore: { mapStore, devicesStore }, timeStore }) => ({
  mapStore,
  devicesStore,
  timeStore,
}))
@observer
class LayerMap extends Component<IProps> {
  toggleDirectionalArrows = () => this.props.mapStore.showDirectionalArrows.toggle();

  render() {
    const {
      mapStore: {
        clusteringSensitivity,
        iconClustering,
        mapLabelSize,
        mapTrafficLayer,
        mapType,
        setClusteringSensitivity,
        setMapLabelSize,
        setMapType,
        setVehicleLabel,
        showDirectionalArrows,
        toggleIconClustering,
        toggleMapTrafficLayer,
        vehicleLabel,
      },
      devicesStore: { showHistory },
    } = this.props;

    return (
      <div className="LayerMap">
        <div className="LayerMap-container">
          <LayerTitle title="time zone display" InfoBlock={<InfoTooltip>{TIME_ZONE_INFO_TEXT}</InfoTooltip>} />
          <div className="LayerMap-buttonsContainer LayerMap-buttonsContainer--fullWidth">
            <TimezoneSelect />
          </div>
          <LayerTitle title="Map Type" />
          <div className="LayerMap-buttonsContainer">
            <MapTypeButton
              icon={defaultMapIconSrc}
              title="Default"
              isSelected={mapType === 'roadmap'}
              onClick={() => setMapType('roadmap')}
              className="MapTypeButton--default"
            />
            <MapTypeButton
              icon={satelliteMapIconSrc}
              title="Satellite Map"
              isSelected={mapType === 'hybrid'}
              onClick={() => setMapType('hybrid')}
              className="MapTypeButton--satellite"
            />
            <MapTypeButton
              icon={terrainMapIconSrc}
              title="Terrain"
              isSelected={mapType === 'terrain'}
              onClick={() => setMapType('terrain')}
              className="MapTypeButton--terrain"
            />
          </div>
          {!showHistory && (
            <Fragment>
              <LayerTitle title="Show on map" />
              <div className="LayerMap-buttonsContainer LayerMap-buttonsContainer--left">
                <MapLayerButton
                  Icon={TrafficIcon}
                  title="Traffic"
                  isSelected={mapTrafficLayer}
                  onClick={toggleMapTrafficLayer}
                  className="MapLayerButton--traffic"
                />
              </div>
            </Fragment>
          )}
          <div className="LayerMap-checkboxContainer">
            <div className="LayerMap-checkbox LayerMap-checkbox--clustering">
              <LayerTitle title={`Vehicle Clustering`} />
              <div className="LayerMap-clusteringContainer">
                <div className="LayerMap-clusteringControlContainer">
                  <RadioGroup
                    checkedValue={iconClustering}
                    onChange={toggleIconClustering}
                    radios={clusteringSensitivityToggleOptions}
                    tabs
                  />
                </div>
                <div className="LayerMap-clusteringSensContainer">
                  <RangeSelector
                    controls
                    disabled={iconClustering === 'off'}
                    rangeDataList={clusteringSensitivityOptions}
                    setValue={setClusteringSensitivity}
                    selectedValue={clusteringSensitivity}
                  />
                </div>
              </div>
            </div>
          </div>
          <Fragment>
            <LayerTitle title="Map and List Labels" />
            <div className="LayerMap-labelContainer">
              <UserAccessLevelComponent requiredAccessLevel={[VEHICLE_DRIVER_NAME_READ]}>
                <div className="LayerMap-checkboxContainer LayerMap-checkboxContainer--names">
                  <RadioGroup
                    checkedValue={vehicleLabel}
                    onChange={setVehicleLabel}
                    radios={vehicleLabelOptions}
                    tabs
                  />
                </div>
              </UserAccessLevelComponent>
              <div className="LayerMap-checkboxContainer LayerMap-checkboxContainer--size">
                <RadioGroup
                  checkedValue={mapLabelSize}
                  onChange={setMapLabelSize}
                  radios={mapLabelsOptions}
                  size="small"
                  tabs
                />
              </div>
              <UserAccessLevelComponent requiredAccessLevel={[ACL.MAP.SETTINGS.DIRECTIONAL_ARROWS.READ]}>
                <div className="LayerMap-checkboxContainer LayerMap-checkboxContainer--directionalArrows">
                  <Checkbox
                    checked={showDirectionalArrows.value}
                    label="Show Directional Arrows"
                    onChange={this.toggleDirectionalArrows}
                    value={showDirectionalArrows.value}
                  />
                </div>
              </UserAccessLevelComponent>
            </div>
          </Fragment>
        </div>
      </div>
    );
  }
}

export default LayerMap;
