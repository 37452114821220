import React, { FC } from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
  color?: 'orange';
  disabled?: boolean;
  icon?: React.ReactNode;
  modificator?: string;
  onClick?: () => void;
  selected?: boolean;
}

const PanelAction: FC<IProps> = ({
  children,
  className,
  color,
  disabled,
  icon,
  modificator,
  onClick = () => true,
  selected,
}) => {
  const cn = classNames('PanelAction', className, {
    'PanelAction--selected': selected,
    [`PanelAction--${color}`]: Boolean(color),
    [`PanelAction--${modificator}`]: Boolean(modificator),
  });

  return (
    <button className={cn} disabled={disabled} onClick={onClick}>
      <div className="PanelAction-icon">{icon}</div>
      <div className="PanelAction-name">{children}</div>
    </button>
  );
};

export default PanelAction;
