import React, { FC, useCallback } from 'react';
import classnames from 'classnames';
import Input from 'components/Input';
import type TableToggleFilter from 'models/Tables/TableToggleFilter';
import ColumnToggleFilter from '../ColumnToggleFilter';

import './styles.scss';

interface IProps {
  className?: string;
  onChange?: (value: string, filter?: TableToggleFilter) => void;
  title: string;
  value?: string;
  filters?: TableToggleFilter[];
  showInput?: boolean;
  open?: boolean;
}

const TableColumnHeader: FC<IProps> = ({ title, onChange, value, filters = [], showInput, open }) => {
  const cn = classnames('TableColumnHeader', { 'TableColumnHeader--open': open });
  const preventClickOnSearch = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <div className={cn}>
      <span className="TableColumnHeader-title">{title}</span>
      {showInput && (
        <div className="TableColumnHeader-input" onClick={preventClickOnSearch}>
          <Input size="small" onChange={(e) => onChange(e.target.value)} value={value} />
        </div>
      )}
      {Boolean(filters.length) && (
        <div className="TableColumnHeader-toggleFilters" onClick={preventClickOnSearch}>
          <ColumnToggleFilter filters={filters} onChange={onChange} />
        </div>
      )}
    </div>
  );
};

export default TableColumnHeader;
