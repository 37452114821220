import React, { FC, useState, useCallback, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';

import './styles.scss';

interface IProps {
  className?: string;
  country?: string;
  disabled?: boolean;
  error?: string;
  isEmptyValid?: boolean;
  onChange?: (value: string) => void;
  onError?: (value: boolean) => void;
  placeholder?: string;
  shouldBeTouched?: boolean;
  value?: string;
}

const PhoneNumberInput: FC<IProps> = ({
  className,
  country = 'US',
  disabled,
  error = 'Invalid phone number',
  isEmptyValid = false,
  onChange = () => true,
  onError = () => true,
  placeholder = '(555) 123-4567',
  shouldBeTouched = true,
  value = '',
}) => {
  const [touched, setTouched] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(value);
  const handleChange = useCallback(
    (phoneNumber) => {
      if (!phoneNumber) {
        phoneNumber = '';
      }
      setPhoneNumber(phoneNumber);
      onChange(phoneNumber);
      setTouched(true);
    },
    [onChange]
  );
  const handleBlur = useCallback(() => setTouched(true), []);
  const isError = useMemo(() => {
    if (isEmptyValid && !phoneNumber) {
      return false;
    }

    if (shouldBeTouched) {
      return touched && !isPossiblePhoneNumber(phoneNumber);
    } else {
      return !isPossiblePhoneNumber(phoneNumber);
    }
  }, [phoneNumber, shouldBeTouched, touched, isEmptyValid]);
  const cn = classNames('PhoneNumberInput', className, {
    'PhoneNumberInput--error': isError,
    'PhoneNumberInput--disabled': disabled,
  });
  const inputCn = classNames('PhoneNumberInput-input', {
    'PhoneNumberInput-input--error': isError,
    'PhoneNumberInput-input--disabled': disabled,
  });

  useEffect(() => {
    setPhoneNumber(value);
  }, [value]);

  useEffect(() => {
    if (isError) {
      onError(true);
    } else {
      onError(false);
    }
  }, [onError, isError]);

  return (
    <div className={cn}>
      <PhoneInput
        className={inputCn}
        country={country}
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={placeholder}
        value={phoneNumber}
        data-lpignore
      />
      {isError && <p className="PhoneNumberInput-error">{error}</p>}
    </div>
  );
};

export default PhoneNumberInput;
