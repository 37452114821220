import React from 'react';

import CustomerDomain from '../CustomerDomain';
import type { Customer } from 'stores/Customers/Customer';


interface IProps {
  customer: Customer;
  onClick: (id: string) => void;
  style: React.CSSProperties;
}

const CustomersListItem: React.FC<IProps> = ({ customer, onClick, style }) => {
  const handleClick = () => {
    onClick(customer.id);
  };

  return (
    <div key={customer.id} className="CustomersList-ListItem" onClick={handleClick} style={style}>
      <div className="CustomersList-ListItemIcon">
        <CustomerDomain
          size={20}
          domain={customer.domain}
        />
      </div>
      <div className="CustomersList-ListItemName">
        <span className="CustomersList-ListItemName--company">{customer.companyName ? customer.companyName : '-'}</span>
        <span className="CustomersList-ListItemName--person">{customer.name}</span>
      </div>
    </div>
  );
};

export default CustomersListItem;
