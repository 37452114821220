import React from 'react';
import classNames from 'classnames';

import ModeFilter from 'components/Filters/ModeFilter';
import SearchFilter from 'components/Filters/SearchFilter';
import StatusFilter from 'components/Filters/StatusFilter';
import type DevicesAttributesFilter from 'models/Devices/Filters/DevicesAttributesFilter';
import type FilterOrderDevices from 'models/EventRegistry/FilterOrderDevices';
import type { IFilterItems } from 'models/EventRegistry/IFilterItems';

import './styles.scss';

interface IProps {
  isDisabled?: boolean;
  onPanelModeChange?: (mode: UI.panelMode) => void;
  onSearchQueryChange?: (query: string) => void;
  onStatusesChange?: (status: string) => void;
  panelMode?: UI.panelMode;
  resetStatuses?: () => void;
  searchPlaceholder?: string;
  searchQuery?: string;
  withStatusesFilter?: boolean;
  filterEvents?: IFilterItems;
  filterOrder?: FilterOrderDevices;
  filterAttributes?: DevicesAttributesFilter;
  showHistory?: boolean;
}

const SubNavFilters: React.FC<IProps> = ({
  isDisabled,
  onPanelModeChange = () => true,
  onSearchQueryChange,
  onStatusesChange,
  panelMode,
  resetStatuses,
  searchPlaceholder,
  searchQuery,
  withStatusesFilter,
  filterEvents,
  filterOrder,
  filterAttributes,
  showHistory = false,
}) => {
  const cn = classNames('SubNavFilters', {
    'SubNavFilters--disabled': Boolean(isDisabled),
  });

  return (
    <div className={cn}>
      <div className="SubNavFilters-filters SubNavFilters-filters--bottom">
        <div className="SubNavFilters-filter SubNavFilters-filter--search">
          <SearchFilter
            placeholder={searchPlaceholder}
            value={searchQuery}
            onChange={onSearchQueryChange}
            isDisabled={isDisabled}
          />
        </div>
        {Boolean(withStatusesFilter) && (
          <div className="SubNavFilters-filter SubNavFilters-filter--status">
            <StatusFilter
              isDisabled={isDisabled}
              onStatusesChange={onStatusesChange}
              resetStatuses={resetStatuses}
              filterEvents={filterEvents}
              filterOrder={filterOrder}
              filterAttributes={filterAttributes}
              showHistory={showHistory}
            />
          </div>
        )}
        {Boolean(panelMode) && (
          <div className="SubNavFilters-filter SubNavFilters-filter--mode">
            <ModeFilter disabled={isDisabled} mode={panelMode} onChange={onPanelModeChange} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SubNavFilters;
