import React, { useState } from 'react';
import { Observer } from 'mobx-react';

import type { ToggleField } from 'models/Fields';

import Toggle from 'components/Toggle';
import ConfirmDeactivateFormModal from '../DeactivateFormModal';

interface IProps {
  isActive: ToggleField;
  inspectionName: string;
  loading: boolean;
  allAssets: boolean;
  assets: string[];
  save: () => void;
}

const InspectionToggleTable: React.FC<IProps> = ({ isActive, inspectionName, loading, allAssets, assets, save }) => {
  const [isConfirmDeactivateModalOpen, setIsConfirmDeactivateModalOpen] = useState(false);

  const onChange = () => {
    if (isActive.value) {
      setIsConfirmDeactivateModalOpen(true);
    } else {
      isActive.toggle();
      save();
    }
  };

  const closeConfirmDeactivateModal = () => {
    setIsConfirmDeactivateModalOpen(false);
  };

  const submitDeactivateSave = async () => {
    isActive.toggle(false);
    await save();
    closeConfirmDeactivateModal();
  };

  return (
    <Observer
      render={() => (
        <div>
          <Toggle checked={isActive.value} onChange={onChange} />
          <ConfirmDeactivateFormModal
            open={isConfirmDeactivateModalOpen}
            name={inspectionName}
            onCancel={closeConfirmDeactivateModal}
            onSubmit={submitDeactivateSave}
            loading={loading}
            allAssets={allAssets}
            assets={assets}
          />
        </div>
      )}
    />
  );
};

export default InspectionToggleTable;
