import { isEmpty, get } from 'lodash';
import UserStore from '../../stores/UserStore';

/**
 * Function checks if user has required roles
 *
 * @param {String[]} requiredLevel
 * @param {boolean} strict - deny mode. return true only when user has rights
 *
 * @return {boolean} - if user has required roles.
 */
function validateAccessLevel(requiredLevel: string[], strict: boolean = false): boolean {
  const userRoles = get(UserStore, ['acls'], []);
  return (!strict && isEmpty(requiredLevel)) || userRoles?.some((item) => requiredLevel.includes(item));
}

export default validateAccessLevel;
