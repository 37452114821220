import React, { FC } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import type { IDashcamSettingsField } from 'interfaces/models/Dashcams/IDashcamSettingsField';
import type { ToggleField } from 'models/Fields';

import InfoTooltip from 'components/InfoTooltip';
import Toggle from 'components/Toggle';
import CollapsibleButton from 'components/CollapsibleButton';
import CameraSettingsItem from '../index';

import { EmptySvgIcon } from 'assets';

import './styles.scss';

interface IProps {
  item: IDashcamSettingsField<ToggleField>;
  disabled?: boolean;
  onChange?: () => void;
}

const CameraSettingsItemExpandedBoolean: FC<IProps> = ({ item, disabled = false, onChange = () => void 0 }) => {
  const isExpanded = item.expanded.value && !disabled;

  const handleChange = () => {
    item.value.toggle();
    onChange();
  };

  const handleChildrenChange = () => {
    if (
      item.settings &&
      item.settings.length === item.settings.filter((setting) => setting.value.value === false).length
    ) {
      item.value.value = false;
    }
  };

  return (
    <div
      className={classnames('CameraSettingsItem CameraSettingsItemExpandedBoolean', {
        'CameraSettingsItem--disabled CameraSettingsItemExpandedBoolean--disabled': disabled,
      })}
    >
      <div className="CameraSettingsItemExpandedBoolean-header">
        <div className="CameraSettingsItem-info CameraSettingsItemExpandedBoolean-info">
          <div className="CameraSettingsItem-name CameraSettingsItemExpandedBoolean-collapse">
            <CollapsibleButton
              direction="right"
              size="small"
              isExpanded={isExpanded}
              onClick={item.expanded.toggle}
              className={`AdminNavigationCollapsibleButton-button`}
            />
          </div>
          <div className="CameraSettingsItem-name CameraSettingsItemExpandedBoolean-name">{item.name}</div>
          {item.description && (
            <InfoTooltip className="CameraSettingsItem-infoTooltip CameraSettingsItemExpandedBoolean-infoTooltip">
              {item.description}
            </InfoTooltip>
          )}
        </div>
        <div className="CameraSettingsItem-action CameraSettingsItemExpandedBoolean-action">
          <Toggle
            disabled={disabled}
            className="CameraSettingsItem-toggle CameraSettingsItemExpandedBoolean-toggle"
            checked={disabled ? false : item.value.value}
            onChange={handleChange}
          />
          {disabled && (
            <InfoTooltip
              icon={<EmptySvgIcon />}
              className="CameraSettingsItem-actionTooltip CameraSettingsItemExpandedBoolean-actionTooltip"
            >{`Enable ${item.parent} to access this control`}</InfoTooltip>
          )}
        </div>
      </div>
      <div className="CameraSettingsItemExpandedBoolean-body">
        {isExpanded && (
          <div className="CameraSettingsItem-settings CameraSettingsItemExpandedBoolean-settings">
            {item.settings.map((setting, index) => {
              return (
                <CameraSettingsItem
                  key={setting.id || String(index)}
                  item={setting}
                  disabled={disabled || item.value?.value === false}
                  onChange={handleChildrenChange}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(CameraSettingsItemExpandedBoolean);
