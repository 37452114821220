import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';
import type { PersistenceStore } from 'stores';

import SearchableSelect from 'components/Select/SearchableSelect';

interface IProps {
  disabled?: boolean;
  emptySearchResults?: string;
  handleChange?: (option: Select.ISelectOption) => void;
  isSearchable?: boolean;
  persistChange?: boolean;
  persistenceStore?: PersistenceStore;
  placeholder?: string;
  searchPlaceholder?: string;
  value?: Select.ISelectOption;
  withAll?: boolean;
  valueSource?: 'id';
}
@inject(({ persistenceStore }) => ({ persistenceStore }))
@observer
class SearchableDashcamSelect extends Component<IProps> {
  static defaultProps = {
    placeholder: 'Select Dashcam',
    searchPlaceholder: 'Search Dashcams',
    emptySearchResults: 'No Dashcams Found',
    valueSource: 'id',
  };

  static allOption = { label: 'All Dashcams', value: 'all' };

  repositoryDashcamSearch: IEntityRepository;

  constructor(props) {
    super(props);
    if (props.persistChange) {
      if (props.persistenceStore.selectedDashcam && !props.value) {
        this.props.handleChange(props.persistenceStore.selectedDashcam);
      } else if (props.withAll && !props.value?.value) {
        this.props.handleChange(SearchableDashcamSelect.allOption);
      }
    }

    this.repositoryDashcamSearch = repositoryService.get('dashcams').entity('ids');
  }

  getData = (props: { page: number; pageSize: number; filter: string }) => {
    const data = { page: props.page || 0, pageSize: props.pageSize || 50, name: props.filter || '' };

    return this.repositoryDashcamSearch.get(data).then((data) => {
      const totalPages = data.totalPages;
      const options = data.items
        .map((item) => {
          return {
            value: item.id,
            label: item.name ? item.name : '-',
          };
        })
        .filter((item) => item.label);

      if (!props.filter && this.props.withAll && props.page === 0) {
        options.unshift(SearchableDashcamSelect.allOption);
      }

      return {
        totalPages,
        options,
      };
    });
  };

  handleChange = (option: Select.ISelectOption) => {
    this.props.handleChange(option);
    if (this.props.persistChange) {
      this.props.persistenceStore.updateSelectedDashcam({ ...option });
    }
  };

  render() {
    return <SearchableSelect {...this.props} getData={this.getData} handleChange={this.handleChange} />;
  }
}

export default SearchableDashcamSelect;
