import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';
import classNames from 'classnames';

import { HISTORY_MODE, MAP_VEHICLE_PANEL_HISTORY_BUTTON, PATHS, WEB_TIMELINE_DETAILS_READ } from 'config';
import { DevicesStore, GeozonesStore, MapStore, RouterStore, UserAccessLevelComponent } from 'stores';
import type { GoogleMapSearchStore } from 'stores/Map/GoogleMapSearchStore';

import EventsLimitModal from 'components/EventsLimitModal';
import GoogleMapSearch from 'components/GoogleMapsLocationSearch';
import HistoryToolBar from './HistoryToolBar';
import Icon from 'components/Icon';
import LayerMap from './LayersMap';
import Loader from 'components/Loader';
import LockMapControl from './LockMap/LockMapControl';
import MapButton from 'components/Map/MapButton';
import MapTopControls from 'components/Map/MapTopControls';
import MapWrapper from 'components/Map/MapWrapper';
import Path from './PathMap';
import SideModal from 'components/SideModal';
import SubNavContainer from './SubNav';
import TabsMenu from 'components/TabsMenu';
import TimelineDetails from 'containers/Map/Timeline';
import SecondaryPanel from './SubNav/Customers/SecondaryPanel';
import RouteReplay from 'components/Map/TimelineDetails/RouteReplay';
import type { SubNavUIStateStore as SubNavUIStateStoreClass } from 'components/SubNav/SubNavUIState.store';

import {
  CogIcon,
  FocusAllGeozonesIcon,
  HistoryEventIcon,
  RecenterToFleetIcon,
  RecenterToPathIcon,
  VehicleGroupIcon,
  DetailsIcon,
} from 'assets';
import './styles.scss';

const tabs = [
  {
    name: 'Map',
  },
  {
    name: 'Trail',
  },
];

interface IProps {
  devicesStore?: DevicesStore;
  geozonesStore: GeozonesStore;
  mapSearchStore?: GoogleMapSearchStore;
  mapStore: MapStore;
  routerStore: RouterStore;
  subNavStore?: SubNavUIStateStoreClass;
}

interface IState {
  sideModalVisible: boolean;
  showTrail: boolean;
}

@inject(
  ({
    devicesMapStore: { devicesStore, mapStore, mapSearchStore },
    geozonesStore,
    routerStore,
    uiStateStore: { subNav },
  }) => ({
    devicesStore,
    geozonesStore,
    mapSearchStore,
    mapStore,
    routerStore,
    subNavStore: subNav,
  })
)
@observer
class MapTab extends Component<IProps, IState> {
  _isMounted: boolean = false; // tslint:disable-line

  constructor(props) {
    super(props);

    this.state = {
      sideModalVisible: false,
      showTrail: props.mapStore.showTrail,
    };
  }

  toggleSideModal = () =>
    this.setState((prevState) => ({
      sideModalVisible: !prevState.sideModalVisible,
    }));

  recenterToFleet = () => {
    const {
      mapStore: { recenterToFleet },
    } = this.props;

    recenterToFleet();
  };

  recenterToPath = () => {
    const {
      devicesStore: {
        showHistory,
        history: { activeMode },
      },
      mapStore: { zoomVehiclePath, zoomIntoVehicle },
    } = this.props;

    if (showHistory && activeMode.id === HISTORY_MODE.POINT_IN_TIME) {
      zoomIntoVehicle();
    } else {
      zoomVehiclePath();
    }
  };

  recenterToGeozone = () => {
    const {
      mapStore: { zoomToGeozoneBounds },
      geozonesStore: { selectedGeozone },
    } = this.props;

    if (selectedGeozone) {
      const { type, radius, formatterCoordinates } = selectedGeozone;
      if (formatterCoordinates.length) {
        zoomToGeozoneBounds(formatterCoordinates, type, radius);
      }
    }
  };

  componentDidMount() {
    this._isMounted = true;
    const {
      devicesStore: {
        deviceDelivery: { websocket },
      },
      geozonesStore: { getGeozonesList },
      mapStore: { mapGeozonesLayer },
      routerStore: {
        location: { pathname },
      },
    } = this.props;

    if (mapGeozonesLayer && !pathname.includes('/geozones')) {
      getGeozonesList();
    }

    if (this._isMounted) {
      if (websocket && !websocket.isConnected) {
        websocket.connect();
      }
    }
  }

  handleHistoryClick = () => {
    const {
      devicesStore: { toggleHistory },
    } = this.props;

    toggleHistory();
  };

  handleClickOnShowDetails = () => {
    const {
      devicesStore: { toggleTrail, showTimelineDetails },
    } = this.props;

    showTimelineDetails.toggle(true);
    toggleTrail(true);
  };

  toggleRouteReplay = (play: boolean) => {
    const {
      devicesStore: { showTrail, toggleTrail },
    } = this.props;

    if (play) {
      this.setState({ showTrail });
      toggleTrail(true);
    } else {
      toggleTrail(this.state.showTrail);
    }
  };

  componentWillUnmount() {
    const {
      devicesStore: {
        setHistory,
        deviceDelivery: { websocket },
      },
      mapStore: { routeReplay },
    } = this.props;
    setHistory(false);
    routeReplay.reset();
    websocket?.disconnect();
    this._isMounted = false;
  }

  render() {
    const {
      subNavStore: { isExpanded },
      mapStore: { mapZoom, locked, mapLabelSize, currentMapBounds, routeReplay },
      mapSearchStore: { setSearchQuery, setSearchResult, searchQuery, searchResults },
      devicesStore: {
        enableTrail,
        getDevicesRequestStatus,
        history: { activeMode },
        selectedDevice,
        groups,
        showHistory,
        showTrail,
        showTimelineDetails,
      },
      geozonesStore: { selectedGeozone },
    } = this.props;
    const { sideModalVisible } = this.state;
    const isShowButtonGroup = Boolean(selectedDevice && groups.selected && groups.selected.groupId !== 'all');
    const isShowButtonRecenterToPath = showHistory
      ? Boolean(selectedDevice && activeMode.id !== HISTORY_MODE.POINT_IN_TIME)
      : Boolean(selectedDevice && enableTrail && showTrail);

    const isHistoryActive = Boolean(showHistory);
    const isShowLoading =
      isHistoryActive &&
      (getDevicesRequestStatus.loading || (selectedDevice && selectedDevice.deviceEventsRequestStatus.loading));
    const showDetails = !isHistoryActive || (isHistoryActive && activeMode.id !== 'pointInTime');

    const mainMapCN = classNames(['MapContainer-mainMap', `MapContainer-mainMap-markerSize--${mapLabelSize}`], {
      'MapContainer-mainMap--locked': locked,
      'MapContainer-mainMap-markerSize': !!mapLabelSize,
    });

    const historyCN = classNames('MapButton--history', {
      'MapButton--active': isHistoryActive,
    });

    const mapContainerCN = classNames('MapContainer', {
      'MapContainer--history': isHistoryActive,
    });

    return (
      <div className="MapTab">
        {isHistoryActive ? <HistoryToolBar /> : null}
        <div className={mapContainerCN}>
          <Switch>
            <Route path="/map/layers" component={null} />
            <Route component={SubNavContainer} />
          </Switch>
          <Switch>
            <Route path={PATHS.MAP.CUSTOMER_ITEM} component={SecondaryPanel} />
          </Switch>
          <div className={mainMapCN}>
            <MapWrapper locked={locked} />
          </div>
          <div className="MapContainer-rightSideControls">
            <div className="MapRightSideControls">
              <div className="MapRightSideControls-container">
                <LockMapControl />
              </div>
            </div>
          </div>
          <div className="MapContainer-topControls">
            <MapTopControls isSubNavExpanded={isExpanded}>
              <div className="MapContainer-topControlsItem">
                <GoogleMapSearch
                  className="GoogleMapsLocationSearch--map"
                  disabled={locked}
                  googleMapURL={process.env.REACT_APP_GOOGLE_MAP_URL}
                  mapBounds={currentMapBounds}
                  loadingElement={<div style={{ height: `100%` }} />}
                  onChange={setSearchResult}
                  onSearchChange={setSearchQuery}
                  placeholder="Search location"
                  searchValue={searchQuery}
                  selectedAddress={searchResults}
                  validate={false}
                  withIcon
                />
              </div>
              <div className="MapContainer-topControlsItem">
                <MapButton
                  className="MapButton--settings"
                  hoverText="Map Settings"
                  iconComponent={<Icon active={sideModalVisible} Icon={CogIcon} size={15} />}
                  onClick={this.toggleSideModal}
                />
              </div>
              {isShowButtonRecenterToPath ? (
                <div className="MapContainer-topControlsItem">
                  <MapButton
                    className="MapButton--recenterToPath"
                    hoverText="Recenter to Trail"
                    iconComponent={<Icon Icon={RecenterToPathIcon} size={20} />}
                    onClick={this.recenterToPath}
                    disabled={locked}
                  />
                </div>
              ) : selectedGeozone ? (
                <div className="MapContainer-topControlsItem">
                  <MapButton
                    className="MapButton--recenterToGeozone"
                    disabled={locked}
                    hoverText="Recenter to Geozone"
                    iconComponent={<Icon Icon={FocusAllGeozonesIcon} size={20} />}
                    onClick={this.recenterToGeozone}
                  />
                </div>
              ) : (
                <div className="MapContainer-topControlsItem">
                  <MapButton
                    className="MapButton--recenterToFleet"
                    hoverText="Recenter to Group"
                    iconComponent={<Icon Icon={RecenterToFleetIcon} size={20} />}
                    onClick={this.recenterToFleet}
                    disabled={locked}
                  />
                </div>
              )}
              {process.env.REACT_APP_ENV === 'develop' || process.env.NODE_ENV === 'development' ? (
                <div className="MapContainer-topControlsItem">
                  <MapButton
                    title={`Zoom: ${mapZoom}`}
                    onClick={() => true}
                    className="MapButton--zoom"
                    disabled={locked}
                  />
                </div>
              ) : null}
              <UserAccessLevelComponent requiredAccessLevel={[MAP_VEHICLE_PANEL_HISTORY_BUTTON]}>
                <div className="MapContainer-topControlsItem">
                  <MapButton
                    title="History"
                    onClick={this.handleHistoryClick}
                    className={historyCN}
                    iconComponent={<Icon Icon={HistoryEventIcon} size={20} />}
                  />
                </div>
              </UserAccessLevelComponent>
              {isShowButtonGroup && (
                <div className="MapContainer-topControlsItem">
                  <MapButton
                    className="MapButton--group"
                    hoverText="Group Name"
                    iconComponent={<Icon Icon={VehicleGroupIcon} size={18} />}
                    title={groups.selected.displayName}
                    onClick={() => true}
                  />
                </div>
              )}
            </MapTopControls>
          </div>
          <UserAccessLevelComponent requiredAccessLevel={[WEB_TIMELINE_DETAILS_READ]}>
            <div className="MapContainer-bottomControls">
              {selectedDevice && showDetails && (
                <>
                  {!showTimelineDetails.value && (
                    <>
                      <div className="MapContainer-bottomControlsItem MapContainer-bottomControlsItem--routeReplay">
                        <RouteReplay
                          disabled={!enableTrail || locked || selectedDevice?.events.length < 2}
                          state={routeReplay.state}
                          speed={routeReplay.speed}
                          onPlay={this.toggleRouteReplay}
                        />
                      </div>
                      <div className="MapContainer-bottomControlsItem MapContainer-bottomControlsItem--showTimelineDetails">
                        <MapButton
                          className="MapButton--timelineDetails"
                          disabled={!enableTrail || locked || routeReplay.state.isPlaying.value}
                          iconComponent={<Icon Icon={DetailsIcon} size={16} />}
                          onClick={this.handleClickOnShowDetails}
                          title="Show Details"
                        />
                      </div>
                    </>
                  )}
                  <div className="MapContainer-timelineDetails">
                    <TimelineDetails />
                  </div>
                </>
              )}
            </div>
          </UserAccessLevelComponent>
          <SideModal visible={sideModalVisible} title="Settings" setVisible={this.toggleSideModal}>
            <TabsMenu
              tabs={tabs}
              disabledTab={{
                disabled: selectedDevice && (showTimelineDetails.value || routeReplay.state.isPlaying.value),
                index: 1,
                tooltipText: showTimelineDetails.value
                  ? 'In order to access the trail settings, close the\n' + '“Show Details” view.'
                  : 'In order to access the trail settings, press “Stop Route Replay“',
              }}
            >
              <LayerMap />
              <Path />
            </TabsMenu>
          </SideModal>
        </div>
        {selectedDevice && (
          <EventsLimitModal
            isOpen={selectedDevice.isShowEventLimitModal}
            showEventsLimitModal={selectedDevice.showEventsLimitModal}
          />
        )}
        {isShowLoading && <Loader className="MapContainer-loader" />}
      </div>
    );
  }
}

export default MapTab;
