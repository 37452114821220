import React, { Fragment } from 'react';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';

import { clusterBlueIconSrc, clusterOrangeIconSrc, clusterRedIconSrc } from 'assets';

import './styles.scss';

interface IMarkersListProps {
  isClustered: boolean;
  isVisible: boolean;
  clusteringSize: number;
}

const clustererStyles = [
  {
    url: clusterBlueIconSrc,
    height: 48,
    lineHeight: 48,
    width: 48,
  },
  {
    url: clusterOrangeIconSrc,
    height: 48,
    lineHeight: 48,
    width: 48,
  },
  {
    url: clusterRedIconSrc,
    height: 48,
    lineHeight: 48,
    width: 48,
  },
];

const MarkersList: React.FC<IMarkersListProps> = ({ clusteringSize, isClustered, isVisible, children }) => {
  if (!isVisible) {
    return null;
  }

  // @ts-ignore
  const calculateIcon: Calculator = (markers) => {
    let index = 1;
    const clusterSize = markers.length;
    if (clusterSize > 4 && clusterSize < 100) {
      index = 2;
    } else if (clusterSize >= 100) {
      index = 3;
    }
    return {
      text: clusterSize,
      index,
    };
  };

  return isClustered ? (
    <MarkerClusterer
      averageCenter
      enableRetinaIcons
      clusterClass={'MarkersList-cluster'}
      gridSize={clusteringSize}
      calculator={calculateIcon}
      styles={clustererStyles}
    >
      {children}
    </MarkerClusterer>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

export default MarkersList;
