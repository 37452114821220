import { ALERTS_TYPE } from 'config';
import AlertWithIntervalModel from './AlertWithInterval';
import isNumber from 'lodash/isNumber';

class AccelerationAlertModel extends AlertWithIntervalModel {
  constructor(item) {
    super({
      ...item,
      isIntervalActive: isNumber(item.interval) ? Boolean(item.interval) : true,
      interval: isNumber(item.interval) ? item.interval : 5 * 60 * 1000,
    });

    this.urlType = 'acceleration';
    this.description =
      'Solar Tracker cannot detect Harsh Acceleration. Visit our Help Center for more information on Harsh Driving Events.';
    this.type.reInitialize(ALERTS_TYPE.ACCELERATION);
  }
}

export default AccelerationAlertModel;
