import { action, computed, observable } from 'mobx';

import { SCHEDULED_REPORTS_TABLE_COLUMNS, STORAGE_ITEMS } from 'config';

import TableBase from '../TableBase';
import type { IEditColumns } from 'models/Tables/IEditColumns';
import {
  IScheduledReport,
  IScheduledReportSearchParams,
  ScheduledReportSearchMap,
} from 'models/ScheduledReport/IScheduledReport';
import type { ISortColumn } from 'models/Tables/ISortColumn';
import ScheduledReport from 'models/ScheduledReport/ScheduledReport';
import { ToggleField } from 'models/Fields';
import { repositoryService } from 'services';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

export interface ITableScheduledReport {
  key: number;
  scheduledReportName: string;
  reportName: string;
  interval: {
    name: string;
    description: string;
  };
  appliesTo: string;
  recipients: string[];
  isActive: boolean;
  createdBy: string;
  updatedBy: string;
  creationTime: number;
  lastUpdateTime: number;
  toggleIsActive: () => void;
  update: () => void;
  destroy: () => void;
}

const initialSortedColumn: ISortColumn = {
  field: 'description',
  order: 'ascend',
};

const initialColumns: Array<IEditColumns<SCHEDULED_REPORTS_TABLE_COLUMNS>> = [
  { value: SCHEDULED_REPORTS_TABLE_COLUMNS.NAME, isSelected: true },
  { value: SCHEDULED_REPORTS_TABLE_COLUMNS.TYPE, isSelected: true },
  { value: SCHEDULED_REPORTS_TABLE_COLUMNS.INTERVAL, isSelected: true },
  { value: SCHEDULED_REPORTS_TABLE_COLUMNS.APPLIES, isSelected: true },
  { value: SCHEDULED_REPORTS_TABLE_COLUMNS.RECIPIENTS, isSelected: true },
  { value: SCHEDULED_REPORTS_TABLE_COLUMNS.ACTIVE, isSelected: true },
  { value: SCHEDULED_REPORTS_TABLE_COLUMNS.CREATED_BY, isSelected: false },
  { value: SCHEDULED_REPORTS_TABLE_COLUMNS.UPDATED_BY, isSelected: false },
  { value: SCHEDULED_REPORTS_TABLE_COLUMNS.CREATION_TIME, isSelected: false },
  { value: SCHEDULED_REPORTS_TABLE_COLUMNS.LAST_UPDATE_TIME, isSelected: false },
];

const storageNames = {
  columns: STORAGE_ITEMS.reports.scheduled.columns,
  sortedColumn: STORAGE_ITEMS.reports.scheduled.sortedColumn,
  pagination: STORAGE_ITEMS.reports.scheduled.pagination,
  searchInColumn: STORAGE_ITEMS.reports.scheduled.searchInColumn,
};

export class ScheduledReportsStore extends TableBase<IEditColumns<SCHEDULED_REPORTS_TABLE_COLUMNS>> {
  @observable items: IScheduledReport[] = [];
  @observable item: IScheduledReport = null;
  @observable total: number = 0;
  isUpdated: ToggleField = new ToggleField(false);
  isCreated: ToggleField = new ToggleField(false);
  isDeleted: ToggleField = new ToggleField(false);
  isCreatedError: ToggleField = new ToggleField(false);
  isUpdatedError: ToggleField = new ToggleField(false);
  isDeletedError: ToggleField = new ToggleField(false);
  reportScheduledReports: IEntityRepository;

  constructor() {
    super({ columns: initialColumns, sortedColumn: initialSortedColumn, storageNames });

    this.reportScheduledReports = repositoryService.get('reports').entity('scheduled-reports');
  }

  @action getData = async ({
    order,
    page,
    search,
    pageSize,
    sortBy,
  }: {
    order?: string;
    page?: number;
    pageSize?: number;
    search?: IScheduledReportSearchParams;
    sortBy?: string;
  }) => {
    const response = await this.reportScheduledReports.get({ order, page, pageSize, sortBy, ...search });

    this.items = response.items.map((item) => new ScheduledReport(item).initialize(this));
    this.total = response.total;
    this.isSearchChanged = false;
  };

  @action create = () => {
    this.item = new ScheduledReport(null).initialize(this);
  };

  @action setItem = (scheduledReport) => {
    this.item = scheduledReport;
  };

  findById = (id: number) => {
    return this.items.find((item) => item.id === id);
  };

  @computed get dataSource(): ITableScheduledReport[] {
    return this.items.map(
      ({
        scheduledReportName,
        reportParameters: { report },
        interval: { displayName, description },
        appliesTo,
        recipients,
        isActive,
        id,
        update,
        destroy,
        createdBy,
        updatedBy,
        creationTime,
        lastUpdateTime,
      }) => {
        let appliesToText = '-';

        if (appliesTo.device.id.value) {
          appliesToText = appliesTo.device.displayName.value;
        } else if (appliesTo.group.id.value) {
          appliesToText = appliesTo.group.displayName.value;
        }

        return {
          key: id,
          scheduledReportName: scheduledReportName.value,
          reportName: report.displayName.value,
          interval: {
            name: displayName.value,
            description: description.value,
          },
          appliesTo: appliesToText,
          recipients: recipients.toArray(),
          isActive: isActive.value,
          toggleIsActive: isActive.toggle,
          update,
          destroy,
          createdBy: createdBy.value || '-',
          updatedBy: updatedBy.value || '-',
          creationTime: creationTime.value || 0,
          lastUpdateTime: lastUpdateTime.value || 0,
        };
      }
    );
  }

  @computed get sortData() {
    return {
      sortBy: ScheduledReportSearchMap[this.sortedColumn.field],
      order: this.sortedColumn.order?.replace('end', '').toUpperCase(),
    };
  }

  @computed get searchData() {
    return this.selectedSearchColumns.reduce(
      (acc, cur) => ({
        ...acc,
        [ScheduledReportSearchMap[cur.column]]: cur.value,
      }),
      {}
    );
  }
}

export default new ScheduledReportsStore();
