import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { EventsRegistryStore } from 'stores';

import LayerTitle from 'components/Layers/LayerTitle';
import PathMapOption from 'components/Map/PathMapOption';

import './styles.scss';

interface IProps {
  eventsRegistryStore?: EventsRegistryStore;
}

@inject(({ eventsRegistryStore }) => ({
  eventsRegistryStore,
}))
@observer
class Path extends Component<IProps> {
  render() {
    const {
      eventsRegistryStore: {
        filtersOrder,
        settings: { filterItems, toggle },
      },
    } = this.props;

    return (
      <div className="PathMap">
        <div className="PathMap-container">
          <LayerTitle title="Track points" />
          <div className="PathMap-checkboxContainer">
            {filtersOrder.settings.trackPoint.map((status) => {
              if (filterItems[status]) {
                const details = filterItems[status].items[0].attributes;

                return (
                  <PathMapOption
                    key={status}
                    label={status}
                    isChecked={filterItems[status].isChecked}
                    toggle={toggle}
                    iconLink={details.iconLink}
                    heading={details.heading}
                    iconColor={details.iconColor}
                    arrowColor={details.arrowColor}
                  />
                );
              }

              return null;
            })}
          </div>
          <LayerTitle title="Events" />
          <div className="PathMap-checkboxContainer">
            {filtersOrder.settings.events.map((status) => {
              if (filterItems[status]) {
                const details = filterItems[status].items[0].attributes;

                return (
                  <PathMapOption
                    key={status}
                    label={status}
                    isChecked={filterItems[status].isChecked}
                    toggle={toggle}
                    iconLink={details.iconLink}
                    heading={details.heading}
                    iconColor={details.iconColor}
                    arrowColor={details.arrowColor}
                  />
                );
              }

              return null;
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Path;
