import type IMaintenanceRequestServerDto from './IMaintenanceRequestServerDto';
import type IMaintenanceRequestClientDto from './IMaintenanceRequestClientDto';
import type { IFileServerModel } from 'models/Files/IFileModel';

class MaintenanceRequest {
  key: number;
  id: number;
  requestName: string;
  vehicleName: string;
  vehicleId: number;
  requestReceived: number;
  requestedBy: string;
  serviceType: string;
  serviceTypeId: number;
  notes: string;
  documents: IFileServerModel[];

  constructor(props) {
    this.key = props.id;
    this.id = props.id || 0;
    this.requestName = props.requestName || '';
    this.vehicleName = props.vehicleName || '';
    this.vehicleId = props.vehicleId || 0;
    this.requestReceived = props.requestReceived || 0;
    this.requestedBy = props.requestedBy || '';
    this.serviceType = props.serviceType || '';
    this.serviceTypeId = props.serviceTypeId || 0;
    this.notes = props.notes || '';
    this.documents = props.documents || [];
  }

  static convertServerToClientDto = (data: IMaintenanceRequestServerDto): IMaintenanceRequestClientDto => {
    return {
      id: data.id,
      requestedBy: data.requestedBy,
      requestName: data.name,
      notes: data.notes,
      requestReceived: data.requestedTime,
      serviceType: data.serviceType.name,
      serviceTypeId: data.serviceType.id,
      vehicleName: data.asset.name,
      vehicleId: data.asset.id,
      documents: data.documents,
    };
  };
}

export default MaintenanceRequest;
