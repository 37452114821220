import React, { FC, useState } from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import type { APIStoreBase } from 'stores';
import type { IDashcamSettingsField } from 'interfaces/models/Dashcams/IDashcamSettingsField';
import UserAccessLevel from 'stores/acl/UserAccessLevelComponent';
import { WEB_DASHCAM_BASIC_UPDATE } from 'config';
import Button from 'components/Button';
import DashcamSettingsList from './DashcamSettingsList';
import Notification from 'components/Notification';
import CameraSettings from '../CameraSettings';

import { LoaderIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  getState: APIStoreBase;
  updateState: APIStoreBase;
  onFetch: () => void;
  onUpdate: () => void;
  onReset: () => void;
  settings: Array<IDashcamSettingsField<any>>;
  basic: Array<IDashcamSettingsField<any>>;
  changed?: boolean;
  hasSettingsError?: boolean;

  availableCameraTypes: string[];
}

const DashcamSettings: FC<IProps> = ({
  className,
  onFetch,
  onUpdate,
  onReset,
  settings,
  hasSettingsError,
  basic,
  getState,
  updateState,
  changed = true,
  availableCameraTypes,
}) => {
  const [editSettingsModal, setEditSettingsModal] = useState(false);
  const cn = classNames('DashcamSettings', className);

  const handleCloseModal = () => {
    onReset();
    setEditSettingsModal(false);
  };

  const handleUpdate = async () => {
    await onUpdate();
    setEditSettingsModal(false);
  };

  return (
    <Observer
      render={() => (
        <div className={cn}>
          {getState.loading ? (
            <LoaderIcon fill="#4285F4" />
          ) : getState.success && Boolean(basic.length) ? (
            <>
              <div className="AdminSelectedDashcam-settingsList">
                <DashcamSettingsList settings={basic} availableCameraTypes={availableCameraTypes} />
                <DashcamSettingsList settings={basic} availableCameraTypes={availableCameraTypes} top />
              </div>
              <UserAccessLevel requiredAccessLevel={[WEB_DASHCAM_BASIC_UPDATE]}>
                <div className="AdminSelectedDashcam-settingsEdit">
                  <Button
                    className="Button--link"
                    onClick={() => setEditSettingsModal(true)}
                    title="Edit Camera Settings"
                  />
                </div>
              </UserAccessLevel>
            </>
          ) : (
            !getState.error && <p className="AdminSelectedDashcam-settingsListEmpty">No settings</p>
          )}

          {getState.error && (
            <div className="AdminSelectedDashcam-settingsError">
              <p className="AdminSelectedDashcam-settingsErrorText">Something went wrong.</p>
              <div className="AdminSelectedDashcam-settingsErrorButton">
                <Button className="Button--link" title="Try again" onClick={onFetch} />
              </div>
            </div>
          )}

          {editSettingsModal && (
            <CameraSettings
              settings={settings}
              hasSettingsError={hasSettingsError}
              show={editSettingsModal}
              onClose={handleCloseModal}
              onSubmit={handleUpdate}
              sending={updateState.loading}
              changed={changed}
              availableCameraTypes={availableCameraTypes}
            />
          )}

          {updateState.success && (
            <Notification
              onClose={updateState.reset}
              text="Settings will be applied if camera connects"
              title="Settings successfully queued"
              type="success"
            />
          )}

          {updateState.error && (
            <Notification
              onClose={updateState.reset}
              text="Something went wrong. Please try again."
              title="Settings failed to queue"
              type="error"
            />
          )}
        </div>
      )}
    />
  );
};

export default DashcamSettings;
