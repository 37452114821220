import React, { useState } from 'react';
import classNames from 'classnames';

import MediaDateOptionRange from 'models/Dashcam/MediaLibrary/MediaSource/MediaDateOptionRange';
import type MediaDateRange from 'models/Dashcam/MediaLibrary/MediaSource/MediaDateRange';
import MediaLibrarySourceCustomRange from './CustomRange';
import MediaLibrarySourceDateRangeOptions from './DateRangeOptions';
import Tooltip from 'components/Tooltip';

import './styles.scss';

interface IProps {
  className?: string;
  dateRange: MediaDateRange;
  timezone: string;
}

const MediaLibrarySourceDateRange: React.FC<IProps> = ({ className, dateRange, timezone }) => {
  const [isTooltipOpen, setTooltipView] = useState(false);
  const cn = classNames('MediaLibrarySourceDateRange', className);
  const tooltipTitle =
    dateRange.selected.name.toLowerCase().includes('custom') && dateRange.selected instanceof MediaDateOptionRange
      ? `
    ${dateRange.selected.range.from.date} -
    ${dateRange.selected.range.to.date}`
      : dateRange.selected.name;
  const handleDateRangeView = (isTooltipOpen) => setTooltipView(isTooltipOpen);
  const closeDateRangeTooltip = () => setTooltipView(false);
  const applyCustomRange = () => {
    dateRange.unselectOptions();
    closeDateRangeTooltip();
  };

  return (
    <Tooltip
      className="Tooltip--mediaLibrarySourceDateRange"
      forceClose
      initialOpen={isTooltipOpen}
      name={tooltipTitle}
      onChange={handleDateRangeView}
      position="bottomLeft"
    >
      <div className={cn}>
        <div className="MediaLibrarySourceDateRange-options">
          <MediaLibrarySourceDateRangeOptions closeDateRangeTooltip={closeDateRangeTooltip} dateRange={dateRange} />
        </div>
        <div className="MediaLibrarySourceDateRange-customRange">
          {dateRange.options.map(
            (option) =>
              option instanceof MediaDateOptionRange &&
              option.action && (
                <MediaLibrarySourceCustomRange
                  initialDateRange={{
                    from:
                      dateRange.selected instanceof MediaDateOptionRange ? dateRange.selected.range.from.value : null,
                    to: dateRange.selected instanceof MediaDateOptionRange ? dateRange.selected.range.to.value : null,
                  }}
                  key={String(isTooltipOpen) || option.name}
                  onApply={applyCustomRange}
                  onCancel={closeDateRangeTooltip}
                  option={option}
                  timezone={timezone}
                />
              )
          )}
        </div>
      </div>
    </Tooltip>
  );
};

export default MediaLibrarySourceDateRange;
