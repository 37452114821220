import React, { useCallback, useEffect, useState } from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import type { Event, Device } from 'models';
import {
  LocationIcon,
  DriverIcon,
  ZoomInEventIcon,
  CameraWithFlashIcon,
  ChevronIcon,
  DirectionEventIcon,
} from 'assets';
import { getFormattedTime } from 'utils';
import { DATE_TIME_FORMATS, PATHS, KEY_TYPE, LINKS, WEB_DASHCAM_BASIC_READ } from 'config';
import type { timezoneNames } from 'stores';
import Button from 'components/Button';
import validateAccessLevel from 'stores/acl/validator';
import { IntervalEvents } from 'services';
import { KeyboardShortcutsManager } from 'tools';
import type DevicesFilters from 'stores/Map/DevicesFilters';

import CompassDirectionLabel from 'components/Map/DirectionTextLabel';
import EventStatusText from 'components/EventStatusText';
import SatelliteCounts from 'components/Map/SatelliteCounts';
import StatusMarker from 'components/StatusMarker';
import StreetView from 'components/Map/StreetView';

import './styles.scss';

interface IProps {
  wayPoint: Event;
  timezone: timezoneNames | string;
  locked: boolean;
  selectedDevice: Device;
  filters: DevicesFilters;
  showHistory: boolean;
  setStreetViewOpened: () => void;
  onZoomIntoClick: () => void;
  onGeozoneClick: (geozoneId: string) => void;
  unselectClick: () => void;
}

const TrackPointCard: React.FC<IProps> = ({
  wayPoint,
  timezone,
  locked = false,
  selectedDevice = false,
  showHistory = false,
  onZoomIntoClick,
  setStreetViewOpened,
  onGeozoneClick,
  unselectClick,
  filters,
}) => {
  const {
    speedMph,
    heading,
    address,
    timestamp,
    driverName,
    latitude,
    longitude,
    satelliteCount,
    currentIndex,
    totalQuantityEvents,
    moveToPreviousEvent,
    moveToNextEvent,
    hideTrackPointCard,
    getTimeDiff,
    isLastEvent,
    isUpdateTime,
    geozone,
    metadata: {
      attributes: { iconColor, label, eventStatusType, calculateTotalIdle, calculateTotalIgnitionOff },
    },
  } = wayPoint;

  const { mediaEnabled, assetId } = get(selectedDevice, 'data', {});
  const { to } = get(filters, 'tempDate', {});
  const toDate = showHistory ? to : null;

  const [timeDifference, setTimeDifference] = useState(
    getTimeDiff(isLastEvent, calculateTotalIdle, calculateTotalIgnitionOff, toDate)
  );
  const [timeDifferenceStop, setTimeDifferenceStop] = useState(getTimeDiff(isLastEvent, false, false, toDate));
  const formattedEventTime = getFormattedTime(timestamp, DATE_TIME_FORMATS.dateAtTime, timezone);
  const isFirstWayPoint = currentIndex === 0;
  const isLastWayPoint = currentIndex + 1 === totalQuantityEvents;
  const isGeozone = geozone?.geozoneId && geozone?.reverseGeocode && geozone?.isActive;
  const addressClassName = classNames('TrackPointDetails-position--adress', {
    'TrackPointDetails-position--geozoneLink': isGeozone,
    'TrackPointDetails-position--historyEnabled': isGeozone && showHistory,
  });

  useEffect(() => {
    if (toDate) {
      IntervalEvents.get().off(updateTimeDiff);
    } else {
      IntervalEvents.get().on(updateTimeDiff);
    }

    setTimeDifference(getTimeDiff(isLastWayPoint && !showHistory, calculateTotalIdle, calculateTotalIgnitionOff, toDate));
    setTimeDifferenceStop(getTimeDiff(isLastWayPoint && !showHistory, false, false, toDate));
  }, [toDate, isLastWayPoint, showHistory]);

  const handleKeyUp = (event) => {
    if (event.key === KEY_TYPE.LEFT_ARROW && !isFirstWayPoint) {
      moveToPreviousEvent.call(wayPoint);
    }

    if (event.key === KEY_TYPE.RIGHT_ARROW && !isLastWayPoint) {
      moveToNextEvent.call(wayPoint);
    }

    if (event.key === KEY_TYPE.ESCAPE) {
      unselectClick();
      hideTrackPointCard();
    }
  };

  const handleGeozoneClick = () => {
    if (isGeozone) {
      onGeozoneClick(String(geozone.geozoneIdCP));
    }
  };

  const updateTimeDiff = useCallback(() => {
    setTimeDifference(getTimeDiff(isLastEvent, calculateTotalIdle, calculateTotalIgnitionOff, toDate));

    if (calculateTotalIdle || calculateTotalIgnitionOff) {
      setTimeDifferenceStop(getTimeDiff(isLastEvent, false, false, toDate));
    }
  }, []);

  const onDirectionClick = () => {
    const directionURL = LINKS.directionFromHere.replace(/{latitude}/g, latitude).replace(/{longitude}/g, longitude);
    window.open(directionURL);
  };

  useEffect(() => {
    KeyboardShortcutsManager.get().add(handleKeyUp);

    if (isUpdateTime) {
      IntervalEvents.get().on(updateTimeDiff);
    }

    return () => {
      KeyboardShortcutsManager.get().remove(handleKeyUp);

      if (isUpdateTime) {
        IntervalEvents.get().off(updateTimeDiff);
      }
    };
  }, []);

  useEffect(() => {
    if (!isUpdateTime) {
      IntervalEvents.get().off(updateTimeDiff);
    }
  }, [isUpdateTime]);

  return (
    <Observer
      render={() => (
        <div className="TrackPointDetails">
          <div className="TrackPointDetails-top">
            <div className="TrackPointDetails-left">
              <StreetView
                latitude={latitude}
                longitude={longitude}
                heading={heading}
                address={address}
                setStreetViewOpened={setStreetViewOpened}
                showLayer
              />
            </div>
            <div className="TrackPointDetails-right">
              <div className="TrackPointDetails-row TrackPointDetails-row--noMargin">
                <div className="TrackPointDetails-row--icon TrackPointDetails-statusMarker">
                  <StatusMarker color={iconColor} size={18} />
                </div>
                <EventStatusText
                  statusCodeText={calculateTotalIdle ? 'Total Idle' : label}
                  className="TrackPointDetails-row--info TrackPointDetails-eventStatus"
                  status={label}
                  currentSpeed={speedMph}
                  timeDiff={timeDifference}
                  eventStatusType={eventStatusType}
                />
              </div>
              <div className="TrackPointDetails-row">
                <div className="TrackPointDetails-row--info">
                  <span className="TrackPointDetails-time">{formattedEventTime}</span>
                  {(calculateTotalIdle || calculateTotalIgnitionOff) && (
                    <EventStatusText
                      className="TrackPointDetails-totalStop"
                      statusCodeText="Total Stop"
                      status={label}
                      currentSpeed={speedMph}
                      timeDiff={timeDifferenceStop}
                      eventStatusType={eventStatusType}
                    />
                  )}
                </div>
              </div>
              <div className="TrackPointDetails-row">
                {wayPoint.metadata.attributes.heading && (
                  <CompassDirectionLabel className="TrackPointDetails-compass" heading={heading} />
                )}
              </div>
              <div className="TrackPointDetails-row">
                <div className="TrackPointDetails-row--icon">
                  <LocationIcon className="TrackPointDetails-location" height="11px" width="11px" />
                </div>
                <div className="TrackPointDetails-row--info TrackPointDetails-position">
                  <span className={addressClassName} onClick={handleGeozoneClick}>
                    {address}
                  </span>
                  <div className="TrackPointDetails-position--satellites">
                    <span className="coordinates">
                      {latitude}, {longitude}
                    </span>
                    <div className="TrackPointDetails-satelliteCounts">
                      <SatelliteCounts satelliteCount={satelliteCount} />
                    </div>
                  </div>
                </div>
              </div>
              {driverName && (
                <div className="TrackPointDetails-row">
                  <div className="TrackPointDetails-row--icon">
                    <DriverIcon className="TrackPointDetails-driver" height="12px" />
                  </div>
                  <span className="TrackPointDetails-row--info TrackPointDetails-name">{driverName}</span>
                </div>
              )}
              <div className="TrackPointDetails-row">
                <div className="TrackPointDetails-row--info TrackPointDetails-row--withoutIcon">
                  <Button
                    IconComponent={<ZoomInEventIcon height="19px" width="19px" />}
                    title=""
                    onClick={() => onZoomIntoClick()}
                    disabled={locked}
                    className="TrackPointDetails-button"
                  />
                  <Button
                    IconComponent={<DirectionEventIcon height="19px" width="19px" />}
                    title=""
                    onClick={onDirectionClick}
                    disabled={locked}
                    className="TrackPointDetails-button"
                  />
                  {validateAccessLevel([WEB_DASHCAM_BASIC_READ]) && mediaEnabled && (
                    <Link
                      to={`${PATHS.DASHCAMS.MEDIA_MANAGER}?assetId=${assetId}&timestamp=${timestamp}`}
                      className="TrackPointDetails-button"
                    >
                      <CameraWithFlashIcon width="16px" height="14.5px" />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="TrackPointDetails-bottom">
            <button
              className="TrackPointDetails-LeftArrow"
              onClick={moveToPreviousEvent.bind(wayPoint)}
              disabled={isFirstWayPoint}
            >
              <span className="TrackPointDetails-IconLeft">
                <ChevronIcon />
              </span>
              <span className="TrackPointDetails-ArrowText">Previous</span>
            </button>
            <span className="TrackPointDetails-Counter">
              {currentIndex + 1} of {totalQuantityEvents}
            </span>
            <button
              className="TrackPointDetails-RightArrow"
              onClick={moveToNextEvent.bind(wayPoint)}
              disabled={isLastWayPoint}
            >
              <span className="TrackPointDetails-ArrowText">Next</span>
              <span className="TrackPointDetails-IconRight">
                <ChevronIcon />
              </span>
            </button>
          </div>
        </div>
      )}
    />
  );
};

export default TrackPointCard;
