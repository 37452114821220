import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';

import { PATHS } from 'config';

import CustomersList from './Customers/CustomersList';


@observer
class CustomersRoutes extends Component<null, null> {
  render() {
    return (
      <Switch>
        <Route path={PATHS.MAP.CUSTOMERS} component={CustomersList} exact />
      </Switch>
    );
  }
}

export default CustomersRoutes;
