import { action, computed, observable } from 'mobx';
import debounce from 'lodash/debounce';

import { repositoryService } from 'services';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

import SimpleField from './SimpleField';

class UniqueField {
  protected field: SimpleField<string>;
  protected repository: IEntityRepository;
  protected allowEmpty: boolean = true;
  @observable private valid: boolean = true;
  @observable private validated: boolean = true;
  updatedOn: number = 0;
  @observable touched: boolean = false;
  trim: boolean = false;

  constructor(value: string, repository: string, allowEmpty: boolean = true, trim: boolean = false) {
    this.field = new SimpleField(value);
    this.repository = repositoryService.get(repository).entity('exists');
    this.updatedOn = Date.now();
    this.validated = true;
    this.allowEmpty = allowEmpty;
    this.trim = trim;
  }

  @computed get value(): string {
    return this.field.value;
  }

  private validate = debounce(async (): Promise<void> => {
    if (!this.value) {
      this.valid = true;
      this.validated = true;
      return;
    } else if (!this.value.trim() && !this.allowEmpty) {
      this.valid = false;
      this.validated = true;
      return;
    }
    try {
      const updatedOn = this.updatedOn;
      const name = this.trim ? this.value.trim() : this.value;
      const response = await this.repository.get({ name }, { ignoreError: true });
      if (updatedOn !== this.updatedOn) {
        return;
      }
      this.valid = !(response === true || response === 'true');
    } catch (error) {
      this.valid = false;
    } finally {
      this.validated = true;
    }
  }, 200);

  @action set(value: string) {
    if (this.field.value !== value) {
      this.validated = false;
      this.field.set(value);
      this.updatedOn = Date.now();
      this.validate();
      this.touched = true;
    }
  }

  @computed get isValid(): boolean {
    return this.valid;
  }

  @computed get isValidated(): boolean {
    return this.validated;
  }

  @action reset() {
    this.field.reset();
    this.valid = true;
    this.validated = true;
    this.updatedOn = Date.now();
    this.touched = false;
  }

  @computed get isUpdated(): boolean {
    return this.field.isUpdated;
  }

  @computed get initialValue(): string {
    return this.field.initialValue;
  }

  @computed get isEmpty(): boolean {
    return this.field.isEmpty;
  }

  @action setTouched(value: boolean) {
    this.touched = value;
  }
}

export default UniqueField;
