import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import { Col, Row } from 'antd';

import { PATHS, WEB_DASHCAM_BASIC_UPDATE, WEB_DASHCAM_NOTES_UPDATE } from 'config';
import type { DashCamsAdmin } from 'stores/Admin/Dashcams';
import type { TimeStore } from 'stores';
import validateAccessLevel from 'stores/acl/validator';

import Button from 'components/Button';
import BackButton from 'components/BackButton';
import AdminField from 'components/Admin/AdminField';
import AdminUniqueField from 'components/Admin/AdminUniqueField';
import AdminTimeField from 'components/Admin/AdminTimeField';
import SearchableDashcamSelect from 'containers/Select/SearchableDashcamSelect';
import SearchableVehicleSelect from 'containers/Select/SearchableVehicleSelect';
import Note from 'components/Note';
import DashcamStatus from 'components/Admin/Dashcams/DashcamStatus';
import InfoTooltip from 'components/InfoTooltip';
import DashcamSettings from 'components/Admin/Dashcams/DashcamSettings';

import './styles.scss';

interface IMatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  dashcamsAdmin?: DashCamsAdmin;
  timeStore?: TimeStore;
}

@inject(({ adminStore: { dashcamsAdmin }, timeStore }) => ({ dashcamsAdmin, timeStore }))
@observer
class SelectedDriver extends Component<IProps> {
  dashcamSelectKey: number;

  constructor(props) {
    super(props);

    this.dashcamSelectKey = Date.now();
    this.state = {
      editCameraSettings: false,
    };
  }

  async componentDidMount() {
    await this.getData();
  }

  componentWillUnmount() {
    const {
      dashcamsAdmin: { selectedDashcam },
    } = this.props;

    selectedDashcam.clear();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.ID) {
      await this.getData();
    }
  }

  getData = async () => {
    const {
      dashcamsAdmin: {
        selectedDashcam: { fetch, status },
      },
      history: { push },
    } = this.props;

    try {
      await fetch(this.ID);
      await status.fetch(this.ID);
    } catch (e) {
      push({ pathname: PATHS.NOT_FOUND, state: { from: PATHS.ADMIN.TABLES.DASHCAMS.INDEX } });
    }
  };

  get ID() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    return id;
  }

  handleDashcamChange = ({ value }) => {
    const {
      history: { push },
    } = this.props;

    push(PATHS.ADMIN.TABLES.DASHCAMS.DASHCAM.replace(':id', value));
  };

  handleDeviceChange = ({ value, label }) => {
    const {
      dashcamsAdmin: {
        selectedDashcam: { vehicleId, vehicleName },
      },
    } = this.props;

    vehicleId.set(value === 'none' ? '' : value);
    vehicleName.set(label === 'None' ? '' : label);
  };

  handleNoteChange = (note) => {
    const {
      dashcamsAdmin: {
        selectedDashcam: { notes },
      },
    } = this.props;

    notes.set(note);
  };

  handleCancel = () => {
    const {
      dashcamsAdmin: { selectedDashcam },
    } = this.props;

    selectedDashcam.reset();
  };

  handleSave = () => {
    const {
      dashcamsAdmin: { selectedDashcam },
    } = this.props;

    selectedDashcam.update();
    this.dashcamSelectKey = Date.now();
  };

  removeVehicle = () => {
    const {
      dashcamsAdmin: { selectedDashcam },
    } = this.props;

    selectedDashcam.vehicleName.set('');
    selectedDashcam.vehicleId.set('');
  };

  get availableCameraTypes() {
    return (
      this.props.dashcamsAdmin.selectedDashcam?.availableCameraTypes?.value?.map?.((item) =>
        item?.value?.toLowerCase?.()
      ) || []
    );
  }

  get nameFieldError() {
    const {
      dashcamsAdmin: { selectedDashcam },
    } = this.props;
    return selectedDashcam.name.touched
      ? selectedDashcam.name.isEmpty
        ? 'Dashcam Name is a required field'
        : !selectedDashcam.name.isValid && selectedDashcam.name.isValidated
        ? 'Dashcam already exists'
        : ''
      : '';
  }

  render() {
    const {
      dashcamsAdmin: { selectedDashcam },
      timeStore: { userTimezone },
    } = this.props;
    const {
      settings: {
        fetch,
        update,
        reset,
        list,
        extended,
        changed,
        hasSettingsError,
        repositorySettingsExtended: { getState, patchState },
      },
    } = selectedDashcam;

    const nameFieldError = this.nameFieldError;

    return (
      <div className="AdminSelectedDashcam">
        <div className="AdminSelectedDashcam-header">
          <div className="AdminSelectedDashcam-header--top">
            <div className="AdminSelectedDashcam-backButton">
              <BackButton link={PATHS.ADMIN.TABLES.DASHCAMS.INDEX}>Back to Dashcam List</BackButton>
            </div>
            <div className="AdminSelectedDashcam-selectDashcam">
              <span className="AdminSelectedDashcam-selectDashcam--title">View/Edit Dashcam</span>
              <div className="AdminSelectedDashcam-selectDashcamDropdown">
                <SearchableDashcamSelect
                  handleChange={this.handleDashcamChange}
                  value={{ label: selectedDashcam.name.value, value: selectedDashcam.id.value }}
                  key={this.dashcamSelectKey}
                />
              </div>
            </div>
          </div>
          <div className="AdminSelectedDashcam-header--bottom">
            <div className="AdminSelectedDashcam-header-buttons">
              <Button
                className="Button--cancel Button--cancelColorLynch"
                title="Cancel"
                disabled={!selectedDashcam.isUpdate || selectedDashcam.repositoryDashcams.patchState.loading}
                onClick={this.handleCancel}
                inline
              />
              <Button
                className="Button--apply"
                title="Save"
                disabled={
                  !(selectedDashcam.isUpdate && selectedDashcam.isValid) ||
                  selectedDashcam.repositoryDashcams.patchState.loading ||
                  !selectedDashcam.name.isValidated ||
                  !selectedDashcam.name.isValid
                }
                onClick={this.handleSave}
                sending={selectedDashcam.repositoryDashcams.patchState.loading}
                inline
              />
            </div>
          </div>
        </div>
        <div className="AdminSelectedDashcam-content">
          <Row>
            <Col className="AdminSelectedDashcam-details" span={16}>
              <div className="AdminSelectedDashcam-details--main">
                <AdminUniqueField
                  title="Dashcam name"
                  className="AdminSelectedDashcam-name"
                  field={selectedDashcam.name}
                  showError={!selectedDashcam.fetchDashcamRequestStatus.loading && Boolean(nameFieldError)}
                  errorMessage={nameFieldError}
                  isEditable={validateAccessLevel([WEB_DASHCAM_BASIC_UPDATE])}
                />
                <DashcamStatus dashcamPing={selectedDashcam.status} />
              </div>
              <div className="AdminSelectedDashcam-details--info">
                <p className="AdminSelectedDashcam-details--title">Device information</p>
                <div className="AdminSelectedDashcam-details--content">
                  <AdminField<string>
                    title="ClearPath Transport ID"
                    className="AdminSelectedDashcam-dashcamId"
                    field={selectedDashcam.dashcamId}
                    isEditable={false}
                  />
                  <AdminField<string>
                    title="Serial #"
                    className="AdminSelectedDashcam-serial"
                    field={selectedDashcam.serialNumber}
                    isEditable={false}
                  />
                  <AdminTimeField
                    title={`Last Communication ${userTimezone ? userTimezone : ''}`}
                    className="AdminSelectedDashcam-lastCommunication"
                    field={selectedDashcam.lastCommunication}
                    userDate
                  />
                  <AdminField<string>
                    title="Activation #"
                    className="AdminSelectedDashcam-activation"
                    field={selectedDashcam.uniqueId}
                    isEditable={false}
                  />
                </div>
              </div>
            </Col>
            <Col className="AdminSelectedDashcam-vehicle" span={8}>
              <p className="AdminSelectedDashcam-vehicle--title">Assigned Vehicle Information</p>
              <div className="AdminSelectedDashcam-vehicle--assigned">
                <p>Current Assigned Vehicle</p>
                <div className="AdminSelectedDashcam-selectVehicle">
                  <div className="AdminSelectedDashcam-selectVehicle--dropdown">
                    {selectedDashcam.fetchDashcamRequestStatus.success && (
                      <SearchableVehicleSelect
                        handleChange={this.handleDeviceChange}
                        withNone
                        disabled={!validateAccessLevel([WEB_DASHCAM_BASIC_UPDATE])}
                        placeholder="No Vehicle Assigned"
                        value={{
                          value: selectedDashcam.vehicleId.value,
                          label: selectedDashcam.vehicleName.value
                            ? selectedDashcam.vehicleName.value
                            : selectedDashcam.vehicleId.value,
                        }}
                      />
                    )}
                  </div>
                  <Button
                    title="Remove Vehicle "
                    className="Button--link AdminSelectedDashcam-selectVehicle--remove"
                    disabled={
                      selectedDashcam.vehicleId.value === 'none' ||
                      selectedDashcam.vehicleId.value === '' ||
                      !validateAccessLevel([WEB_DASHCAM_BASIC_UPDATE])
                    }
                    onClick={this.removeVehicle}
                  />
                </div>
              </div>
              <div className="AdminSelectedDashcam-settings">
                <div className="AdminSelectedDashcam-settingsTitle">
                  <p className="AdminSelectedDashcam-settingsTitleText">Camera Settings</p>
                  <div className="AdminSelectedDashcam-settingsTitleTooltip">
                    <InfoTooltip>Camera settings will reflect what is shown when camera is online</InfoTooltip>
                  </div>
                </div>
                <div className="AdminSelectedDashcam-settingsContent">
                  <DashcamSettings
                    getState={getState}
                    onFetch={fetch}
                    onUpdate={update}
                    onReset={reset}
                    basic={list}
                    settings={extended}
                    changed={changed}
                    updateState={patchState}
                    availableCameraTypes={this.availableCameraTypes}
                    hasSettingsError={hasSettingsError}
                  />
                </div>
              </div>
              <div className="AdminSelectedDashcam-note">
                <p className="AdminSelectedDashcam-note--title">Dashcam Note</p>
                <div className="AdminVehicleDetails-note">
                  <Note
                    countCharactersEntered
                    hideControls
                    isEditing={validateAccessLevel([WEB_DASHCAM_NOTES_UPDATE])}
                    note={selectedDashcam.notes.value}
                    onChange={this.handleNoteChange}
                    placeholder="Note"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Prompt when={selectedDashcam.isUpdate} message="Changes that you made may not be saved." />
      </div>
    );
  }
}

export default SelectedDriver;
