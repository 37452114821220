import MediaDate from './MediaDate';
import type { IMediaDateOption } from 'interfaces/models/Dashcams/IMediaDateOption';
import { ToggleField } from 'models/Fields';

export default class MediaDateOptionRange implements IMediaDateOption<{ from: number; to: number }> {
  action: boolean;
  isSelected: ToggleField;
  name: string;
  range: MediaDate;

  constructor({ action, name, isSelected, range }) {
    this.action = action;
    this.isSelected = new ToggleField(isSelected);
    this.name = name;
    this.range = new MediaDate(range);
  }

  get params() {
    return { from: this.range.from.value, to: this.range.to.value };
  }
}
