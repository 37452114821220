import type { SimpleField } from 'models/Fields';
import type DueValue from './DueValue';
import MaintenanceAlertServiceModel from './MaintenanceAlertServiceModel';
import MaintenanceAlertSpecificTypeConfigModel from './MaintenanceAlertSpecificTypeConfigModel';

class MaintenanceAlertSpecificTypeModel {
  when: SimpleField<Alerts.Modal.Maintenance.When>;
  dueValue: DueValue;
  config: MaintenanceAlertSpecificTypeConfigModel;
  service: MaintenanceAlertServiceModel;

  constructor({ when, dueValue, config }) {
    this.when = when;
    this.dueValue = dueValue;
    this.config = config ? new MaintenanceAlertSpecificTypeConfigModel(config) : null;
    this.service = new MaintenanceAlertServiceModel(this.config ? this.config.id : null);

    this.setup();
  }

  get model() {
    const config = {
      type: '',
      serviceTypeId: this.service.type.item?.id || this.service.id.value,
      avgDaysBefore: null,
      kmBefore: null,
      engHoursBefore: null,
      daysBefore: null,
    };

    if (this.when.value === 0) {
      config.type = 'IS_DUE';
    } else if (this.when.value === 1) {
      config.type = 'DAYS_BEFORE_IS_DUE_AVERAGE';
      config.avgDaysBefore = this.dueValue.avgDaysBefore.value;
    } else if (this.when.value === 2) {
      config.type = 'BEFORE_IS_DUE';
      config.kmBefore = this.dueValue.miles.value;
      config.engHoursBefore = this.dueValue.hours.value;
      config.daysBefore = this.dueValue.days.value;
    }

    return config;
  }

  get text() {
    const basedOn = 'Service Type: ' + (this.service.type.item.name || 'All service type');
    let when = '';

    if (this.when.value === 1) {
      when = `${this.dueValue.avgDaysBefore.value} days before Task is due <span>(based on Average Daily Usage)</span>`;
    } else if (this.when.value === 2) {
      when = `${this.dueValue.miles.value} miles, ${this.dueValue.hours.value} engine hours, or ${this.dueValue.days.value} days before Task is due`;
    }

    return { basedOn, when };
  }

  private setup = () => {
    if (!this.config) {
      return;
    }

    switch (this.config.type.value) {
      case 'DAYS_BEFORE_IS_DUE_AVERAGE':
        this.when.set(1);
        this.dueValue.avgDaysBefore.reInitialize(this.config.avgDaysBefore.value ?? 10);
        break;
      case 'BEFORE_IS_DUE':
        this.when.set(2);
        this.dueValue.days.reInitialize(this.config.daysBefore.value ?? 10);
        this.dueValue.hours.reInitialize(this.config.engHoursBefore.value ?? 10);
        this.dueValue.miles.reInitialize(this.config.kmBefore.value ?? 10);
        break;
    }
  };
}

export default MaintenanceAlertSpecificTypeModel;
