import React from 'react';

import Checkbox from 'components/Checkbox';
import SearchableGroupSelect from 'containers/Select/SearchableGroupSelect';

interface IProps {
  excludeInactive?: boolean;
  hasExclude?: boolean;
  onExcludeChange?: (status: boolean) => void;
  onGroupChange?: (option: Select.ISelectOption) => void;
  value?: Select.ISelectOption;
}

const GroupIdSelector: React.FC<IProps> = ({
  excludeInactive = true,
  onExcludeChange = () => true,
  onGroupChange,
  hasExclude = true,
  value,
}) => {
  const [excludeValue, setExcludeValue] = React.useState(excludeInactive);

  const updateExcludeValue = () => {
    setExcludeValue(!excludeValue);
    onExcludeChange(!excludeValue);
  };

  const handleChange = (option: Select.ISelectOption) => {
    onGroupChange(option);
  };

  return (
    <>
      <SearchableGroupSelect handleChange={handleChange} value={value} withAll persistChange />

      {hasExclude ? (
        <Checkbox
          label={`Exclude inactive vehicles`}
          checked={excludeValue}
          onChange={updateExcludeValue}
          className="VehicleAndGroupSwitcher-checkbox"
        />
      ) : null}
    </>
  );
};

export default GroupIdSelector;
