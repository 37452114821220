import React, { useState } from 'react';
import { Observer } from 'mobx-react';

import type InspectionForm from 'models/Inspections/Models/InspectionForm';

import SideModal from 'components/SideModal';
import Button from 'components/Button';
import InspectionsFormPanelLine from './InspectionsFormPanelLine';
import InspectionsFormPanelNameActive from './InspectionsFormPanelNameActive';
import InspectionsFormPanelRequire from './InspectionsFormPanelRequire';
import InspectionsFormPanelItems from './InspectionsFormPanelItems';
import Notification from 'components/Notification';
import ConfirmationModal from 'components/Admin/ConfirmationModal';
import ConfirmDeleteFormModal from '../DeleteFormModal';
import ConfirmDeactivateFormModal from '../DeactivateFormModal';
import InspectionFormPanelVehiclesSelect from './InspectionFormPanelVehiclesSelect';

import './styles.scss';

interface IProps {
  isPanelOpen: boolean;
  title: string;
  inspectionForm: InspectionForm;
  loading: boolean;
  canDelete?: boolean;
  onSave: () => void;
  closePanel: () => void;
}

const InspectionsFormPanel: React.FC<IProps> = ({
  isPanelOpen,
  title,
  inspectionForm,
  loading,
  canDelete = false,
  onSave,
  closePanel,
}) => {
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const [isConfirmDeactivateModalOpen, setIsConfirmDeactivateModalOpen] = useState(false);

  const saveForm = async () => {
    if (inspectionForm.isValid) {
      if (inspectionForm.isActive.initialValue && !inspectionForm.isActive.value) {
        setIsConfirmDeactivateModalOpen(true);
      } else {
        await onSave();
        closePanel();
      }
    } else {
      inspectionForm.view.isError.toggle(true);
    }
  };

  const closeNotification = () => {
    inspectionForm.repository.createState.reset();
  };

  const openConfirmation = () => {
    if (inspectionForm.isUpdated) {
      setIsShowConfirmModal(true);
    } else {
      closePanel();
    }
  };

  const closeConfirmation = () => {
    setIsShowConfirmModal(false);
  };

  const submitConfirmation = () => {
    setIsShowConfirmModal(false);
    closePanel();
  };

  const openConfirmDeleteFormModal = () => {
    setIsConfirmDeleteModalOpen(true);
  };

  const closeConfirmDeleteFormModal = () => {
    setIsConfirmDeleteModalOpen(false);
  };

  const deleteForm = async () => {
    await inspectionForm.delete();
    closeConfirmDeleteFormModal();
    closePanel();
  };

  const closeConfirmDeactivateModal = () => {
    setIsConfirmDeactivateModalOpen(false);
  };

  const submitDeactivateSave = async () => {
    await onSave();
    closeConfirmDeactivateModal();
    closePanel();
  };

  return (
    <Observer
      render={() => (
        <>
          <SideModal
            visible={isPanelOpen}
            setVisible={openConfirmation}
            title={title}
            width={628}
            className="InspectionsFormPanelSideBar"
          >
            {inspectionForm && (
              <div className="InspectionsFormPanel">
                <div className="InspectionsFormPanel-content">
                  <InspectionsFormPanelLine title="Name">
                    <InspectionsFormPanelNameActive
                      name={inspectionForm.name}
                      isActive={inspectionForm.isActive}
                      isError={inspectionForm.view.isError}
                    />
                  </InspectionsFormPanelLine>
                  <InspectionsFormPanelLine title="Applies To">
                    <InspectionFormPanelVehiclesSelect
                      appliesTo={inspectionForm.appliesTo}
                      isError={inspectionForm.view.isError}
                    />
                  </InspectionsFormPanelLine>
                  <InspectionsFormPanelLine title="Require" alignTitle="top">
                    <InspectionsFormPanelRequire
                      requireUpdatingOdometer={inspectionForm.requireUpdatingOdometer}
                      requireUpdatingEngHours={inspectionForm.requireUpdatingEngHours}
                    />
                  </InspectionsFormPanelLine>
                  <InspectionsFormPanelLine title="Items" alignTitle="top">
                    <InspectionsFormPanelItems items={inspectionForm.items} isError={inspectionForm.view.isError} />
                  </InspectionsFormPanelLine>
                </div>
                <div className="InspectionsFormPanel-footer">
                  <div className="InspectionsFormPanel-footerLeft">
                    {canDelete && (
                      <Button
                        title="Delete Inspection Form"
                        className="Button--inline InspectionsFormPanel-footerDelete"
                        onClick={openConfirmDeleteFormModal}
                      />
                    )}
                  </div>
                  <div className="InspectionsFormPanel-footerRight">
                    <Button
                      title="Cancel"
                      className="Button--cancel Button--inline InspectionsFormPanel-footerCancel"
                      onClick={openConfirmation}
                    />
                    <Button
                      title="Save"
                      className="Button--apply Button--inline"
                      onClick={saveForm}
                      sending={loading}
                      disabled={!inspectionForm.isValid}
                    />
                  </div>
                </div>
                <ConfirmDeleteFormModal
                  open={isConfirmDeleteModalOpen}
                  name={inspectionForm.name.value}
                  onCancel={closeConfirmDeleteFormModal}
                  onSubmit={deleteForm}
                  loading={inspectionForm.repositoryForm.deleteState.loading}
                />
                <ConfirmDeactivateFormModal
                  open={isConfirmDeactivateModalOpen}
                  name={inspectionForm.name.value}
                  onCancel={closeConfirmDeactivateModal}
                  onSubmit={submitDeactivateSave}
                  loading={inspectionForm.repositoryForm.patchState.loading}
                  allAssets={inspectionForm.appliesTo.allAssets.value}
                  assets={inspectionForm.appliesTo.items.value.map((item) => item.value.name.value)}
                />
              </div>
            )}
          </SideModal>
          {inspectionForm?.repository.createState.success && (
            <Notification
              type="success"
              text="The Inspection Form was successfully created."
              title="Success!"
              onClose={closeNotification}
            />
          )}
          {inspectionForm?.repository.createState.error && (
            <Notification
              type="error"
              text="The Inspection Form failed to save."
              title="Save Failed"
              onClose={closeNotification}
            />
          )}

          <ConfirmationModal
            className="InspectionsFormPanel-exitingConfirmation"
            title="Continue Without Saving?"
            applyButtonTitle="Yes, Continue"
            cancelButtonTitle="No, Cancel"
            cancelButtonStyles={{ border: 'none' }}
            isOpen={isShowConfirmModal}
            onCancel={closeConfirmation}
            onSubmit={submitConfirmation}
          >
            <div className="InspectionsFormPanel-exitingConfirmationContent">
              <p>Changes made to {inspectionForm ? inspectionForm.name.value : 'form'} will not be saved.</p>
              <p className="InspectionsFormPanel-exitingConfirmationContentQuestion">Do you want to continue?</p>
            </div>
          </ConfirmationModal>
        </>
      )}
    />
  );
};

export default InspectionsFormPanel;
