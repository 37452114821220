import React from 'react';
import { Observer } from 'mobx-react';
import { getFormattedTime } from 'utils';
import { DATE_TIME_FORMATS } from 'config';
import timeStore from 'stores/TimeStore';

import type { ToggleField } from 'models/Fields';

import CalendarWithInput from 'components/CalendarWithInput';
import InfoTooltip from 'components/InfoTooltip';

import './styles.scss';

interface IProps {
  title: string;
  value: number;
  isEdit: ToggleField;
  isShowTooltip?: boolean;
  onChange?: (value: number) => void;
  disabled?: boolean;
}

const MaintenanceCompletedTaskPanelSummaryDate: React.FC<IProps> = ({
  title,
  value,
  isEdit,
  isShowTooltip = false,
  onChange = () => true,
  disabled,
}) => {
  return (
    <Observer
      render={() => (
        <div className="MaintenanceCompletedTaskPanelContent-SummaryLine">
          <div className="MaintenanceCompletedTaskPanelContent-SummaryLineLabel">{title}</div>
          <div className="MaintenanceCompletedTaskPanelContent-SummaryLineValue">
            {isEdit.value ? (
              <>
                <div className="MaintenanceCompletedTaskPanelContent-Calendar">
                  <CalendarWithInput
                    withTodayLabel
                    value={value}
                    onSubmit={(value) => onChange(value)}
                    isInputEditable={false}
                    disabled={disabled}
                  />
                </div>
                {isShowTooltip && (
                  <div className="MaintenanceCompletedTaskPanelContent-InfoTooltip">
                    <InfoTooltip>
                      This is a repeating Task so updating this field will change when the next Task is due.
                    </InfoTooltip>
                  </div>
                )}
              </>
            ) : (
              <div>
                {value ? getFormattedTime(value, DATE_TIME_FORMATS.monthDatYearFull, timeStore.sessionTimezone) : '-'}
              </div>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default MaintenanceCompletedTaskPanelSummaryDate;
