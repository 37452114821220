import React, { FC } from 'react';
import classNames from 'classnames';
import RadioGroup from 'components/RadioGroup';

interface IProps {
  className?: string;
  onChange: (value: string) => void;
  value: string;
}

const MapTypeSwitcher: FC<IProps> = ({ className, onChange, value }) => {
  const cn = classNames('MapTypeSwitcher', className);
  const uniqueId = Math.random().toString();

  return (
    <div className={cn}>
      <RadioGroup
        className="RadioGroup--mapButton"
        radios={[
          {
            id: `roadmap-${uniqueId}`,
            label: 'Default',
            name: `report-popover-map-type-${uniqueId}`,
            value: google.maps.MapTypeId.ROADMAP,
          },
          {
            id: `hybrid-${uniqueId}`,
            label: 'Satellite',
            name: `report-popover-map-type-${uniqueId}`,
            value: google.maps.MapTypeId.HYBRID,
          },
        ]}
        size="xsmall"
        tabs
        onChange={onChange}
        checkedValue={value}
      />
    </div>
  );
};

export default MapTypeSwitcher;
