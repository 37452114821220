import React from 'react';
import { observer } from 'mobx-react';

import Toggle from 'components/Toggle';
import type { ToggleField } from 'models/Fields';

import './styles.scss';

interface IProps {
  isActive: ToggleField;
}

const ActiveToggle: React.FC<IProps> = ({ isActive }) => {
  return (
    <div className="ActiveToggle">
      <p className="ActiveToggle-title">Active</p>
      <Toggle checked={isActive.value} onChange={isActive.toggle} />
    </div>
  );
};

export default observer(ActiveToggle);
