import React, { useState } from 'react';

import ConfirmationModal from 'components/Admin/ConfirmationModal';
import Toggle from 'components/Toggle';

import './styles.scss';

interface IProps {
  reportName: string;
  isActive: boolean;
  toggle: () => void;
  update: () => void;
}

const ReportTableScheduledToggle: React.FC<IProps> = ({ reportName, isActive, toggle, update }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsActive = () => {
    if (isActive) {
      setIsOpen(true);
    } else {
      toggle();
      update();
    }
  };

  const handleSubmit = () => {
    toggle();
    update();
    setIsOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Toggle checked={isActive} onChange={toggleIsActive} />
      <ConfirmationModal
        applyButtonTitle="Yes, Continue"
        cancelButtonTitle="No, Cancel"
        isOpen={isOpen}
        className="ReportInactiveModal"
        onCancel={handleClose}
        onSubmit={handleSubmit}
        title={`Make ${reportName} Inactive?`}
        contentStyles={{ padding: '0 20px', backgroundColor: '#fff' }}
        withoutCloseIcon
      >
        <div className="ReportInactiveModal-content">
          <p className="ReportInactiveModal-text ReportInactiveModal-text--top">
            This action will disable this Scheduled Report for all recipients.
          </p>
          <p className="ReportInactiveModal-text ReportInactiveModal-text--bottom">Do you want to continue?</p>
        </div>
      </ConfirmationModal>
    </div>
  );
};

export default ReportTableScheduledToggle;
