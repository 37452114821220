import React from 'react';
import classNames from 'classnames';

import { LockIcon } from 'assets';

import InfoTooltip from '../InfoTooltip';

import './styles.scss'

interface IProps {
  className?: string;
  size?: number;
}

const FinancialDataAccessError: React.FC<IProps> = ({ className, size = 13 }) => {
  return <div className={classNames('FinancialDataAccessError', className)}>
    <InfoTooltip
      className="FinancialDataAccessError-tooltip"
      placement="bottom"
      icon={<LockIcon className="FinancialDataAccessError-icon" width={size} height={size}/>}
    >
      Financial data only visible to Finance Admin users.
    </InfoTooltip>
  </div>
}

export default FinancialDataAccessError;
