class IntervalEvents {
  private static instance: IntervalEvents;
  private callbacks = [];
  private interval: number;

  private constructor() {}

  public static get(): IntervalEvents {
    if (!IntervalEvents.instance) {
      IntervalEvents.instance = new IntervalEvents();
    }

    return IntervalEvents.instance;
  }

  public on = (callback): void => {
    if (!this.callbacks.length) {
      this.interval = setInterval(() => {
        this.trigger();
      }, 1000);
    }

    this.callbacks.push(callback);
  };

  public off = (callback): void => {
    for (let i = 0; i < this.callbacks.length; i++) {
      if (this.callbacks[i] === callback) {
        this.callbacks.splice(i--, 1);
      }
    }

    if (!this.callbacks.length) {
      clearInterval(this.interval);
    }
  };

  private trigger = (): void => {
    for (const callback of this.callbacks) {
      callback();
    }
  };
}

export { IntervalEvents };
