import React from 'react';
import classNames from 'classnames';

import { clearPathGPSLogo } from 'assets';

import './styles.scss';

interface IProps {
  className?: string;
}

const Logo: React.FC<IProps> = ({ className }) => {
  const cn = classNames('Logo', className);

  return (
    <div className={cn}>
      <img className="Logo-img" src={clearPathGPSLogo} alt="ClearPathGPS" />
    </div>
  );
};

export default Logo;
