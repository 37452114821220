import React, { FC, useState, useCallback } from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import type { IAppFile } from 'models/Files/IAppFile';
import FileItem from './FileItem';
import FilePreview from './FilePreview';

import './styles.scss';

interface IProps {
  accept?: string;
  className?: string;
  edit?: boolean;
  files: IAppFile[];
  multiple?: boolean;
  id: string;
  onAdd: (file: File) => void;
  onDownload: (file: IAppFile) => void;
  onPreview: (file: IAppFile) => void;
  onRemove: (file: IAppFile) => void;
  onUpload: (file: IAppFile) => void;
}

const FileManager: FC<IProps> = ({
  accept = '.pdf, image/*, .heic, video/mp4, video/x-m4v, video/*, text/plain, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .zip',
  className,
  edit = true,
  files,
  multiple,
  id,
  onAdd,
  onDownload,
  onRemove,
  onUpload,
}) => {
  const [fileToPreview, setPreviewFile] = useState(null);
  const cn = classNames('FileManager', className);
  const add = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      Array.prototype.forEach.call(event.target.files, (file) => onAdd(file));
    },
    [onAdd]
  );

  return (
    <Observer
      render={() => (
        <div className={cn}>
          {Boolean(files.length) && (
            <div className="FileManager-list">
              {files.map((item, i) => {
                return (
                  <div className="FileManager-item" key={i}>
                    <FileItem
                      edit={edit}
                      file={item}
                      onDownload={onDownload}
                      onPreview={item.repository.createState.success || item.model.id ? setPreviewFile : undefined}
                      onRemove={onRemove}
                      onUpload={onUpload}
                    />
                  </div>
                );
              })}
            </div>
          )}

          {edit && (
            <div className="FileManager-add">
              <label htmlFor={`FileManager-addInput-${id}`} className="FileManager-addLabel">
                Browse
              </label>
              <input
                accept={accept}
                className="FileManager-addInput"
                id={`FileManager-addInput-${id}`}
                multiple={multiple}
                onChange={add}
                type="file"
              />
            </div>
          )}
          {fileToPreview && (
            <div className="FileManager-preview">
              <FilePreview
                file={fileToPreview}
                onDownload={fileToPreview.download}
                onClose={() => setPreviewFile(null)}
              />
            </div>
          )}
        </div>
      )}
    />
  );
};

export default FileManager;
