import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { GroupsAdmin } from 'stores/Admin/Groups';

import SearchFilter from 'components/Filters/SearchFilter';
import GroupTableVehiclesAdd from 'components/Admin/GroupTableVehicles/GroupTableVehiclesButton/GroupTableVehiclesAdd';

import './styles.scss';

interface IProps {
  groupsAdmin?: GroupsAdmin;
  onAdd?: () => void;
}

interface IState {
  isFiltersOpen: boolean;
}

@inject(({ adminStore: { groupsAdmin } }) => ({
  groupsAdmin,
}))
@observer
class GroupTableVehiclesAllHeader extends Component<IProps, IState> {
  handleAdd = () => {
    const {
      groupsAdmin: { assignAllVehiclesToSelectedGroup },
      onAdd,
    } = this.props;

    assignAllVehiclesToSelectedGroup();
    onAdd();
  };

  render() {
    const {
      groupsAdmin: { searchQueryVehicles, updateSearchQueryVehicles },
    } = this.props;

    return (
      <div className="GroupTableVehiclesAllHeader">
        <div className="GroupTableVehiclesAllHeader-title">
          <div className="GroupTableVehiclesAllHeader-left">
            <div className="GroupTableVehiclesAllHeader-search">
              <SearchFilter
                className="SearchFilter--groupTableVehicles"
                inline
                onChange={updateSearchQueryVehicles}
                placeholder="Search All Vehicles"
                value={searchQueryVehicles}
              />
            </div>
          </div>
          <div className="GroupTableVehiclesAllHeader-right GroupTableVehiclesAllHeader-action">
            <GroupTableVehiclesAdd
              text={`Add all`}
              disabled={Boolean(searchQueryVehicles.length)}
              onClick={this.handleAdd}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default GroupTableVehiclesAllHeader;
