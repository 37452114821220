import React, { FC } from 'react';
import { Observer } from 'mobx-react';
import classnames from 'classnames';

import AppFileImageModel from 'models/Files/Factory/AppFileImageModel';
import AppFileOthersModel from 'models/Files/Factory/AppFileOthersModel';
import AppFilePDFModel from 'models/Files/Factory/AppFilePDFModel';
import AppFileVideoModel from 'models/Files/Factory/AppFileVideoModel';
import type { IAppFile } from 'models/Files/IAppFile';

import { CloseIcon, DownloadIcon } from 'assets';
import './styles.scss';

interface IProps {
  file: IAppFile;
  onDownload: () => void;
  onClose: () => void;
}

const FilePreview: FC<IProps> = ({
  file: {
    model: { type, source },
    model,
  },
  onDownload,
  onClose,
}) => {
  const cn = classnames('FilePreview', { [`FilePreview--${type}`]: Boolean(type) });

  return (
    <Observer
      render={() => (
        <div className={cn}>
          <div className="FilePreview-overlay" />
          <div className="FilePreview-content">
            <div className="FilePreview-buttons">
              <div className="FilePreview-button FilePreview-button--download" onClick={onDownload}>
                <DownloadIcon />
              </div>
              <div className="FilePreview-button FilePreview-button--close" onClick={onClose}>
                <CloseIcon />
              </div>
            </div>
            <div className="FilePreview-file">
              {model instanceof AppFileVideoModel && (
                <video className="FilePreview-video" controls>
                  <source src={source.value} />
                  <p className="FilePreview-unavailable">Your browser does not support the video tag.</p>
                </video>
              )}
              {model instanceof AppFileImageModel && model.initialized.value && (
                <img className="FilePreview-image" src={source.value} alt="Preview" />
              )}
              {model instanceof AppFilePDFModel && <embed className="FilePreview-pdf" type={type} src={source.value} />}
              {model instanceof AppFileOthersModel && (
                <p className="FilePreview-unavailable">Preview is unavailable. You can download the file.</p>
              )}
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default FilePreview;
