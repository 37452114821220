import { action, computed, observable, reaction } from 'mobx';
import { get, isBoolean } from 'lodash';

import { repositoryService } from 'services';
import type Repository from 'services/RepositoryService/Repository';
import type { Device } from 'models';
import devicesStore from 'stores/Map/Devices';
import uiStore from 'stores/UIStore';

const initialResutls = {
  devices: [],
  drivers: [],
  geozones: [],
  groups: [],
};

export class SearchStore {
  @observable isSearchActive: boolean = false;
  @observable searchQuery: string = '';
  @observable results: Search.ISearchResults = { ...initialResutls };

  repositorySearch: Repository;

  constructor() {
    reaction(
      () => get(uiStore, 'sideBar.isOpen'),
      () => this.resetSearch(),
      { name: 'Reset search on toggling a sidebar' }
    );

    this.repositorySearch = repositoryService.get('search');
  }

  @computed get noResults() {
    const isEmpty = Object.values(this.results).every((result) => !result.length);
    return this.repositorySearch.getState.success && isEmpty;
  }

  @computed get devicesWithDrivers(): Array<{ driverId: string; deviceId: string }> {
    const devicesWithDriversList = [];

    this.results.drivers.forEach(({ id }: Search.ISearchResultsItem) => {
      devicesStore.devicesList.forEach(({ data }: Device) => {
        const { driverId, deviceId } = data;

        if (driverId === id) {
          devicesWithDriversList.push({ driverId, deviceId });
        }
      });
    });

    return devicesWithDriversList;
  }

  @computed get existingDevicesIds(): string[] {
    const list = [];

    this.results.devices.forEach(({ id }: Search.ISearchResultsItem) => {
      devicesStore.devicesWithEventsList.forEach(({ data }: Device) => {
        const { deviceId } = data;

        if (deviceId === id) {
          list.push(deviceId);
        }
      });
    });

    return list;
  }

  @action toggleSearchState = (searchState?: boolean) => {
    this.isSearchActive = isBoolean(searchState) ? searchState : !this.isSearchActive;
  };

  @action getSearchResults = async (search: string) => {
    try {
      const results = { ...initialResutls };
      const response = await this.repositorySearch.get({ search });

      Object.keys(response).forEach((key) => {
        results[key] =
          key === 'geozones' ? response[key].filter((geozoneItem) => !geozoneItem.hideOnMaps) : response[key];
      });

      this.results = results;
    } catch (err) {
      this.results = { ...initialResutls };
    }
  };

  @action handleChange = (searchQuery: string) => {
    this.searchQuery = searchQuery;
  };

  @action resetSearch = (toSaveQuery?: boolean) => {
    this.repositorySearch.getState.reset();
    this.isSearchActive = false;
    this.results = { ...initialResutls };
    if (!toSaveQuery) {
      this.searchQuery = '';
    }
    return this;
  };
}

export default new SearchStore();
