import React, { useMemo, useState } from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import { CautionIcon, LaptopIcon, PhoneIcon } from 'assets';
import { PERSON_LOGIN_CREDENTIALS, TIMEZONE_OPTIONS } from 'config';
import find from 'lodash/find';
import get from 'lodash/get';

import type PersonAccessModel from 'models/Admin/People/PersonAccessModel';
import type PersonDetailsModel from 'models/Admin/People/PersonDetailsModel';
import PersonAccessRoleModel from 'models/Admin/People/PersonAccess/PersonAccessRoleModel';

import RolesSelect from 'containers/Select/RolesSelect';
import PeopleChangePasswordModal from 'containers/Admin/People/PeopleChangePasswordModal';
import Toggle from 'components/Toggle';
import Input from 'components/Input/AntInput';
import TableActions from 'components/Table/Actions';
import Button from 'components/Button';
import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';
import PersonLoginCredentials from '../PersonLoginCredentials';
import Tooltip from 'antd/lib/tooltip';
import InfoTooltip from 'components/InfoTooltip';

import './styles.scss';

interface IProps {
  access: PersonAccessModel;
  details: PersonDetailsModel;
  resetPasswordEmail: (value: string) => void;
}

const PersonAccess: React.FC<IProps> = ({ access, details, resetPasswordEmail }) => {
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] = useState(false);

  const timeZoneSelectOptionList = useMemo(() => {
    return [
      ...TIMEZONE_OPTIONS.map(({ key, value }) => {
        return { label: value, value: key };
      }),
    ];
  }, [TIMEZONE_OPTIONS]);

  const handleResetPasswordClick = async () => {
    await resetPasswordEmail(details.contacts.email.login.value);
  };

  const openChangeEmailEditModal = () => {
    setIsChangeEmailModalOpen(true);
  };

  const closeChangeEmailEditModal = () => {
    setIsChangeEmailModalOpen(false);
  };

  return (
    <Observer
      render={() => {
        const selectedTimeZoneOption = () => {
          const timeZoneData = find(TIMEZONE_OPTIONS, { value: access.timeZone.value });

          return { label: timeZoneData?.value, value: timeZoneData?.key };
        };

        return (
          <div className="PersonAccess">
            <h4 className="PersonAccess-title">User Access</h4>
            <div className="PersonAccess-content">
              <div className="PersonAccess-contentActivations">
                <Tooltip
                  title={
                    Boolean(details.contacts.email.login.value) ? undefined : 'Add Login Email to enable User access'
                  }
                  color="#fff"
                  overlayInnerStyle={{ color: '#2A3344', fontSize: '12px' }}
                >
                  <div className="PersonAccess-contentActivationsWeb">
                    <div className="PersonAccess-contentActivationsWebOption">
                      <Toggle
                        disabled={!Boolean(details.contacts.email.login.value)}
                        checked={
                          Boolean(details.contacts.email.login.value) ? access.webPortalAccess.active.value : false
                        }
                        onChange={() => access.webPortalAccess.active.toggle()}
                      />
                      <LaptopIcon
                        className={classNames('PersonAccess-contentActivationsWebOptionIcon', {
                          'PersonAccess-contentActivationsWebOptionIcon--disabled': !Boolean(
                            details.contacts.email.login.value
                          ),
                        })}
                      />
                      <div
                        className={classNames('PersonAccess-contentActivationsWebOptionInfo', {
                          'PersonAccess-contentActivationsWebOptionInfo--disabled': !Boolean(
                            details.contacts.email.login.value
                          ),
                        })}
                      >
                        <p className="PersonAccess-contentActivationsWebOptionInfoTitle">Web Portal Access</p>
                      </div>
                    </div>
                    <div
                      className={classNames('PersonAccess-contentActivationsWebLastLogin', {
                        'PersonAccess-contentActivationsWebLastLogin--disabled': !Boolean(
                          details.contacts.email.login.value
                        ),
                      })}
                    >
                      <label className="PersonAccess-contentActivationsWebLastLoginLabel">Last Login</label>
                      <span className="PersonAccess-contentActivationsWebLastLoginValue">
                        {access.webPortalAccess.lastLoginTime.date ? access.webPortalAccess.lastLoginTime.date : '-'}
                      </span>
                    </div>
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    Boolean(details.contacts.email.login.value) ? undefined : 'Add Login Email to enable User access'
                  }
                  color="#fff"
                  overlayInnerStyle={{ color: '#2A3344', fontSize: '12px' }}
                >
                  <div className="PersonAccess-contentActivationsMobile">
                    <div className="PersonAccess-contentActivationsMobileOption">
                      <Toggle
                        disabled={!Boolean(details.contacts.email.login.value)}
                        checked={
                          Boolean(details.contacts.email.login.value) ? access.mobileAppAccess.active.value : false
                        }
                        onChange={() => access.mobileAppAccess.active.toggle()}
                      />
                      <PhoneIcon
                        className={classNames('PersonAccess-contentActivationsMobileOptionIcon', {
                          'PersonAccess-contentActivationsMobileOptionIcon--disabled': !Boolean(
                            details.contacts.email.login.value
                          ),
                        })}
                      />
                      <div
                        className={classNames('PersonAccess-contentActivationsMobileOptionInfo', {
                          'PersonAccess-contentActivationsMobileOptionInfo--disabled': !Boolean(
                            details.contacts.email.login.value
                          ),
                        })}
                      >
                        <p className="PersonAccess-contentActivationsMobileOptionInfoTitle">Mobile App Access</p>
                      </div>
                    </div>
                    <div
                      className={classNames('PersonAccess-contentActivationsMobileLastLogin', {
                        'PersonAccess-contentActivationsMobileLastLogin--disabled': !Boolean(
                          details.contacts.email.login.value
                        ),
                      })}
                    >
                      <label className="PersonAccess-contentActivationsMobileLastLoginLabel">Last Login</label>
                      <span className="PersonAccess-contentActivationsMobileLastLoginValue">
                        {access.mobileAppAccess.lastLoginTime.date ? access.mobileAppAccess.lastLoginTime.date : '-'}
                      </span>
                    </div>
                  </div>
                </Tooltip>
              </div>
              {(access.webPortalAccess.active.value || access.mobileAppAccess.active.value) && (
                <>
                  <div className="PersonAccess-contentActions">
                    <Tooltip
                      title={
                        Boolean(details.contacts.email.login.value)
                          ? undefined
                          : 'Add Login Email to enable User access'
                      }
                      color="#fff"
                      overlayInnerStyle={{ color: '#2A3344', fontSize: '12px' }}
                    >
                      <div>
                        <TableActions
                          disabled={!Boolean(details.contacts.email.login.value)}
                          items={[
                            {
                              text: 'Change User’s Password',
                              onClick: () => setIsPasswordModalOpen(true),
                            },
                            {
                              text: 'Email Password Reset',
                              onClick: () => handleResetPasswordClick(),
                            },
                          ]}
                          type="page"
                        />
                      </div>
                    </Tooltip>
                  </div>
                  {Boolean(details.contacts.email.login.value) && (
                    <div className="PersonAccess-contentDetails">
                      <div className="PersonAccess-contentDetailsEmail">
                        <div className="PersonAccess-contentDetailsEmailHeader">
                          <p className="PersonAccess-contentDetailsEmailHeaderTitle">Login Email</p>
                          {details.contacts.email.state.value === PERSON_LOGIN_CREDENTIALS.EDIT && (
                            <Button className="Button--link" title="Edit" onClick={openChangeEmailEditModal} />
                          )}
                          {details.contacts.email.state.value === PERSON_LOGIN_CREDENTIALS.VALIDATE && (
                            <Button className="Button--link" title="Validate" onClick={openChangeEmailEditModal} />
                          )}
                        </div>
                        <Input
                          disabled
                          value={
                            details.contacts.email.state.value === PERSON_LOGIN_CREDENTIALS.VALIDATE
                              ? details.contacts.email.newLogin.value
                              : details.contacts.email.login.value
                          }
                        />
                        {details.contacts.email.state.value === PERSON_LOGIN_CREDENTIALS.VALIDATE && (
                          <InfoTooltip icon={<CautionIcon />} className="PersonAccess-contentDetailsEmailCaution">
                            Email Address has not been validated and cannot be used to log in.
                          </InfoTooltip>
                        )}
                        <PersonLoginCredentials
                          isOpen={isChangeEmailModalOpen}
                          onClose={closeChangeEmailEditModal}
                          credentials={details.contacts.email}
                          editType="EMAIL"
                          personName={details.name}
                        />
                      </div>
                      <div className="PersonAccess-contentDetailsRole">
                        <div className="PersonAccess-contentDetailsRoleHeader">
                          <p className="PersonAccess-contentDetailsRoleHeaderTitle">User Role</p>
                        </div>
                        <RolesSelect
                          handleChange={(role: Select.ISelectOption) =>
                            access.roles.replace([
                              new PersonAccessRoleModel({ id: Number(role.value), name: role.label }),
                            ])
                          }
                          value={{
                            value: String(get(access.roles.value[0], 'value.id', '')),
                            label: get(access.roles.value[0], 'value.name', ''),
                          }}
                        />
                      </div>
                      <div className="PersonAccess-contentDetailsTimeZone">
                        <div className="PersonAccess-contentDetailsTimeZoneHeader">
                          <p className="PersonAccess-contentDetailsTimeZoneHeaderTitle">Time Zone</p>
                        </div>
                        <SearchableStaticSelect
                          handleChange={(timeZoneOption: Select.ISelectOption) =>
                            access.timeZone.set(timeZoneOption.label)
                          }
                          value={selectedTimeZoneOption()}
                          values={timeZoneSelectOptionList}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <PeopleChangePasswordModal isOpen={isPasswordModalOpen} onCancel={() => setIsPasswordModalOpen(false)} />
          </div>
        );
      }}
    />
  );
};

export default PersonAccess;
