import React, { useState } from 'react';
import { Observer } from 'mobx-react';

import { EyeCrossedIcon, EyeIcon, STIcon } from 'assets';

import { WEB_MAP_FINANCE_BASIC_DELETE } from 'config';

import { Input as InputAntd } from 'antd';
import type STIntegrationModel from 'stores/Integrations/STIntegration';

import IntegrationItem from '../IntegrationItem';
import ConfirmationModal from 'components/Admin/ConfirmationModal';
import Input from 'components/Input';
import Notification from 'components/Notification';

import './styles.scss';

interface IProps {
  integration: STIntegrationModel;
}

const ServiceTitanIntegration: React.FC<IProps> = ({ integration }) => {
  const [isOpenConnectModal, setIsOpenConnectModal] = useState(false);
  const [isOpenDisconnectModal, setIsOpenDisconnectModal] = useState(false);

  const closeConnectModal = () => {
    setIsOpenConnectModal(false);
  };

  const closeDisconnectModal = () => {
    setIsOpenDisconnectModal(false);
  };

  const handleConnect = () => {
    setIsOpenConnectModal(true);
  };

  const handleReconnect = async () => {
    await integration.reconnect();
    await integration.update();
  };

  const handleDisconnect = () => {
    setIsOpenDisconnectModal(true);
  };

  const submitConnect = async () => {
    await integration.connect();
    closeConnectModal();

    if (integration.repositoryEntityAuthConnect.createState.success) {
      await integration.update();
    }
  };

  const submitDisconnect = async () => {
    await integration.disconnect();
    closeDisconnectModal();

    if (integration.repositoryEntityAuthDisconnect.deleteState.success) {
      await integration.update();
    }
  };

  const handleChangeApplicationId = (e) => {
    integration.applicationId.set(e.target.value);
  };

  const handleChangeClientId = (e) => {
    integration.clientId.set(e.target.value);
  };

  const handleChangeSecret = (e) => {
    integration.secret.set(e.target.value);
  };

  const onConnectNotificationClose = () => {
    integration.repositoryEntityAuthConnect.createState.reset();
  };

  const onDisconnectNotificationClose = () => {
    integration.repositoryEntityAuthDisconnect.deleteState.reset();
  };

  return (
    <Observer
      render={() => {
        return (
          <>
            <IntegrationItem
              className="STIntegration"
              integration={integration}
              onConnect={handleConnect}
              onReconnect={handleReconnect}
              onDisconnect={handleDisconnect}
              icon={<STIcon width={40} height={40} />}
              accessLevelToDisconnect={[WEB_MAP_FINANCE_BASIC_DELETE]}
              isBeta
            />
            <ConfirmationModal
              className="STIntegrationConnectModal"
              title="Connect Service Titan"
              applyButtonTitle="Connect"
              isOpen={isOpenConnectModal}
              onSubmit={submitConnect}
              onCancel={closeConnectModal}
            >
              <p className="STIntegrationConnectModal-text">Enter your Service Titan credentials:</p>
              <div className="STIntegrationConnectModal-ApplicationID">
                <span className="STIntegrationConnectModal-ApplicationID--text">Application ID</span>
                <Input
                  className="STIntegrationConnectModal-ApplicationID--input"
                  placeholder="Application ID"
                  value={integration.applicationId.value}
                  onChange={handleChangeApplicationId}
                  name="applicationId"
                />
              </div>
              <div className="STIntegrationConnectModal-ClientID">
                <span className="STIntegrationConnectModal-ClientID--text">Client ID</span>
                <Input
                  className="STIntegrationConnectModal-ClientID--input"
                  placeholder="Client ID"
                  value={integration.clientId.value}
                  onChange={handleChangeClientId}
                  name="clientId"
                />
              </div>
              <div className="STIntegrationConnectModal-Secret">
                <span className="STIntegrationConnectModal-Secret--text">Secret</span>
                <InputAntd.Password
                  className="STIntegrationConnectModal-Secret--input"
                  placeholder="Secret"
                  value={integration.secret.value}
                  onChange={handleChangeSecret}
                  name="secret"
                  iconRender={(visible) => (visible ? <EyeIcon fill="#6B7A99" /> : <EyeCrossedIcon fill="#6B7A99" />)}
                />
              </div>
            </ConfirmationModal>
            <ConfirmationModal
              className="STIntegrationDisconnectModal IntegrationDisconnectModal"
              title="Disconnect Service Titan Integration?"
              applyButtonTitle="Yes, Disconnect"
              cancelButtonTitle="No, Cancel"
              isOpen={isOpenDisconnectModal}
              onSubmit={submitDisconnect}
              onCancel={closeDisconnectModal}
              withoutCloseIcon
            >
              <p className="STIntegrationDisconnectModal-top">
                Disconnecting the Service Titan Integration will remove all your Service Titan data from ClearpathGPS
                and your company’s customer data will no longer be accessible.
              </p>
              <p className="STIntegrationDisconnectModal-bottom">Do you want to continue?</p>
            </ConfirmationModal>
            {integration.repositoryEntityAuthConnect.createState.success && (
              <Notification
                type="success"
                text="Service Titan data successfully connected."
                title="Success!"
                onClose={onConnectNotificationClose}
              />
            )}
            {integration.repositoryEntityAuthConnect.createState.error && (
              <Notification
                type="error"
                text="Something went wrong. Please try again."
                title="Connection Failed"
                onClose={onConnectNotificationClose}
              />
            )}

            {integration.repositoryEntityAuthDisconnect.deleteState.success && (
              <Notification
                type="success"
                text="Service Titan data successfully disconnected."
                title="Success!"
                onClose={onDisconnectNotificationClose}
              />
            )}
            {integration.repositoryEntityAuthDisconnect.deleteState.error && (
              <Notification
                type="error"
                text="Something went wrong. Please try again."
                title="Disconnection Failed"
                onClose={onDisconnectNotificationClose}
              />
            )}
          </>
        );
      }}
    />
  );
};

export default ServiceTitanIntegration;
