import React from 'react';
import classNames from 'classnames';

import { Button } from 'antd';
import { ChevronIcon } from 'assets';

import './styles.scss';

interface IProps {
  direction?: 'horizontal' | 'vertical' | 'right';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  isExpanded: boolean;
  onClick: () => void;
}

const CollapsibleButton: React.FC<IProps> = ({ isExpanded, onClick, size = 'medium', direction = 'horizontal', className = '' }) => {
  const cn = classNames(
    `CollapsibleButton`,
    {
      [`CollapsibleButton--expanded`]: isExpanded,
      [`CollapsibleButton--collapsed`]: !isExpanded,
      [`CollapsibleButton-horizontal`]: direction === 'horizontal',
      [`CollapsibleButton-vertical`]: direction === 'vertical',
      [`CollapsibleButton-right`]: direction === 'right',
      [`CollapsibleButton-size--small`]: size === 'small',
    },
    className
  );

  return <Button className={cn} onClick={onClick} icon={<ChevronIcon />} />;
};

export default CollapsibleButton;
