import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Modal as AntModal } from 'antd';

import { CloseModalIcon } from 'assets';

import './styles.scss';

interface IProps {
  bgColor?: string;
  children: React.ReactNode;
  className?: string;
  isModalOpen?: boolean;
  onClose?: (modalState: boolean) => void;
  onOpen?: (modalState: boolean) => void;
  trigger?: React.ReactNode;
  style?: React.CSSProperties;
  closable?: boolean;
  closeOutside?: boolean;
  width?: number;
  centered?: boolean;
  withoutCloseIcon?: boolean;
}

const Modal: FC<IProps> = ({
  isModalOpen,
  centered,
  onClose,
  className,
  closable = false,
  closeOutside,
  children,
  bgColor,
  style,
  width,
  withoutCloseIcon,
}) => {
  const cn = classNames('Modal', className, {
    [`Modal--${bgColor}`]: Boolean(bgColor),
  });
  const [modalOpen, toggleModal] = useState(isModalOpen);

  const handleCloseModal = () => {
    toggleModal(false);
    onClose(false);
  };

  useEffect(() => {
    toggleModal(isModalOpen);
  }, [isModalOpen]);

  return (
    <AntModal
      closable={closable}
      centered={centered}
      className={cn}
      destroyOnClose
      footer={null}
      visible={modalOpen}
      onCancel={handleCloseModal}
      bodyStyle={{ ...style }}
      width={width}
      maskClosable={closeOutside}
      keyboard={false}
    >
      {!withoutCloseIcon && (
        <span onClick={handleCloseModal} className="Modal-closeIcon">
          <CloseModalIcon />
        </span>
      )}
      {children}
    </AntModal>
  );
};

Modal.defaultProps = {
  centered: true,
  closable: false,
  isModalOpen: false,
  onClose: () => true,
  width: 800,
};

export default Modal;
