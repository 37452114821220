import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import type { RouteComponentProps } from 'react-router-dom';
import { DATE_TIME_FORMATS, KEY_TYPE, PATHS } from 'config';
import queryString from 'query-string';
import moment from 'moment';

import { KeyboardShortcutsManager } from 'tools';
import type { MediaManager, TimeStore } from 'stores';
import MediaManagerDetails from 'components/DashCams/MediaManagerDetails';
import MediaManagerWrapper from 'components/DashCams/MediaManagerWrapper';
import MediaManagerPlayer from 'components/DashCams/MediaManagerPlayer';
import MediaManagerRequestMediaSettings from 'components/DashCams/MediaManagerRequestMediaSettings';

import './styles.scss';

interface ISearchParams {
  assetId?: string;
  startTimestamp?: string;
  endTimestamp?: string;
  timestamp?: string;
}
interface IProps extends RouteComponentProps {
  mediaManagerStore?: MediaManager;
  timeStore?: TimeStore;
}

@inject(({ mediaManagerStore, timeStore }) => ({ mediaManagerStore, timeStore }))
@observer
class RequestMediaStep2 extends Component<IProps> {
  async componentDidMount() {
    const {
      mediaManagerStore: {
        checkout: {
          requestMedia: { timeSegment },
        },
        checkout,
      },
    } = this.props;
    const startNumber = parseInt(this.startTimestamp, 10);
    const endNumber = parseInt(this.endTimestamp, 10);
    const timestampNumber = parseInt(this.timestamp, 10);
    const isTimestampNumberValid = timestampNumber && timestampNumber >= startNumber && timestampNumber <= endNumber;

    await checkout.fetch(this.assetId);

    checkout
      .preSelectCameraJournalByInterval(startNumber, endNumber)
      .preSelectRequestMediaTimeSegment(startNumber, endNumber)
      .preSelectRequestMediaCameraType();
    checkout.requestMedia.player.isAudioEnabled.toggle(false);

    if (isTimestampNumberValid) {
      timeSegment.startTime.set(timestampNumber);
    }

    this.addListenerKeys();
  }

  componentWillUnmount(): void {
    const {
      mediaManagerStore: {
        checkout: {
          requestMedia: { player, cameraType },
        },
      },
    } = this.props;

    player.stop();
    cameraType.type.set(null);
    this.removeListenerKeys();
  }

  handleArrowKeys = (e) => {
    const {
      mediaManagerStore: {
        checkout: {
          requestMedia: { timeSegment },
        },
      },
    } = this.props;

    if (e.key === KEY_TYPE.LEFT_ARROW) {
      const decreasedValue = timeSegment.startTime.value - 1000;
      const start = timeSegment.timeline.start.value;

      timeSegment.startTime.set(start < decreasedValue ? decreasedValue : start);
    }

    if (e.key === KEY_TYPE.RIGHT_ARROW) {
      const increasedValue = timeSegment.startTime.value + 1000;
      const end = timeSegment.timeline.end.value;

      timeSegment.startTime.set(increasedValue < end ? increasedValue : end);
    }
  };

  addListenerKeys = () => KeyboardShortcutsManager.get().add(this.handleArrowKeys);

  removeListenerKeys = () => KeyboardShortcutsManager.get().remove(this.handleArrowKeys);

  get startTimestamp() {
    const {
      history: {
        location: { search },
      },
    } = this.props;

    const { startTimestamp } = queryString.parse(search) as ISearchParams;

    return startTimestamp;
  }

  get endTimestamp() {
    const {
      history: {
        location: { search },
      },
    } = this.props;

    const { endTimestamp } = queryString.parse(search) as ISearchParams;

    return endTimestamp;
  }

  get timestamp() {
    const {
      history: {
        location: { search },
      },
    } = this.props;

    const { timestamp } = queryString.parse(search) as ISearchParams;

    return timestamp;
  }

  get assetId() {
    const {
      history: {
        location: { search },
      },
    } = this.props;
    const { assetId } = queryString.parse(search) as ISearchParams;

    return assetId;
  }

  get selectedDate() {
    const {
      mediaManagerStore: {
        checkout: { camerasJournal },
      },
    } = this.props;

    return camerasJournal.dates.active;
  }

  render(): React.ReactNode {
    const {
      timeStore: { sessionTimezone },
      mediaManagerStore: {
        checkout: {
          isLoading,
          dashcamStatus,
          requestMedia: { timeSegment, player, cameraType },
          vehicle,
        },
      },
    } = this.props;

    return (
      <MediaManagerWrapper
        className="RequestMediaStep2Wrapper"
        isLoading={isLoading.value}
        currentStep={2}
        prevPath={`${PATHS.DASHCAMS.MEDIA_REQUEST.INDEX}/step-1?assetId=${this.assetId}`}
        nextPath={`${PATHS.DASHCAMS.MEDIA_REQUEST.INDEX}/step-3?assetId=${this.assetId}&startTimestamp=${timeSegment.startTime.value}&duration=${timeSegment.duration.active.value}`}
      >
        <div className="RequestMediaStep2">
          <p className="RequestMediaStep2-header dimmed-grey">Preview Video Clip and Set Download Request</p>
          <p className="RequestMediaStep2-subHeader dimmed-grey">
            Specify the section of video you want to download and the duration of the request.
          </p>
          <div className="RequestMediaStep2-details dimmed-grey">
            <MediaManagerDetails name={vehicle.details.displayName} dashcamPing={dashcamStatus} />
          </div>
          <p className="RequestMediaStep2-date dimmed-grey">
            {moment(this.selectedDate, 'MM/DD/YYYY').format(DATE_TIME_FORMATS.fullMonthComaDateYear)}
          </p>
          <div className="RequestMediaStep2-player dimmed-grey">
            <MediaManagerPlayer
              timezone={sessionTimezone}
              timeSegment={timeSegment}
              player={player}
              cameraType={cameraType}
            />
          </div>
          <div className="RequestMediaStep2-settings dimmed-grey">
            <MediaManagerRequestMediaSettings
              duration={timeSegment.duration}
              segment={timeSegment}
              timezone={sessionTimezone}
              onClockOpen={this.removeListenerKeys}
              onClockClose={this.addListenerKeys}
            />
          </div>
        </div>
      </MediaManagerWrapper>
    );
  }
}

export default RequestMediaStep2;
