import MaintenanceModalUI from './MaintenanceModalUI';
import MaintenancePanelUI from './MaintenancePanelUI';

class MaintenanceUI {
  modal: MaintenanceModalUI;
  panel: MaintenancePanelUI;

  constructor(model) {
    this.modal = new MaintenanceModalUI(model);
    this.panel = new MaintenancePanelUI();
  }
}

export default MaintenanceUI;
