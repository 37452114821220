import React, { FC } from 'react';

import Button from 'components/Button';

import './styles.scss';

interface IProps {
  onClose: () => void;
  onSubmit: () => void;
  sending: boolean;
  changed: boolean;
}

const CameraSettingsFooter: FC<IProps> = ({onClose, onSubmit, sending, changed}) => {
  return (<div className="CameraSettingsFooter">
    <Button
      className="Button--cancel Button--inline CameraSettingsFooter-buttonCancel"
      title="Cancel"
      onClick={onClose}
    />
    <Button
      disabled={!changed}
      className="Button--apply Button--inline CameraSettingsFooter-buttonSubmit"
      title="Update Settings"
      onClick={onSubmit}
      sending={sending}
    />
  </div>);
};

export default CameraSettingsFooter;
