import React, { FC } from 'react';
import Button from 'components/Button';

import { RefreshIconTransparent } from 'assets';
import './styles.scss';

interface IProps {
  disabled?: boolean;
  onRetry: () => void;
}

const MediaLibraryFailedMessage: FC<IProps> = ({ disabled, onRetry }) => {
  return (
    <div className="MediaLibraryFailedMessage">
      <p className="MediaLibraryFailedMessage-text">Something Went Wrong...</p>
      <div className="MediaLibraryFailedMessage-button">
        <Button
          className="Button--apply"
          IconComponent={<RefreshIconTransparent fill="#FFF" />}
          inline
          onClick={onRetry}
          disabled={disabled}
          title="Retry"
        />
      </div>
    </div>
  );
};

export default MediaLibraryFailedMessage;
