import { action, observable } from 'mobx';

import { STORAGE_ITEMS } from 'config';
import { getStorageItem, setStorageItem } from '../utils';

class SubNavigation {
  private readonly key: string = '';

  @observable expanded: boolean = true;

  @action expand = () => {
    this.expanded = true;
    this.save();
  };

  @action collapse = () => {
    this.expanded = false;
    this.save();
  };

  @action toggle = () => {
    if (this.expanded) {
      this.collapse();
    } else {
      this.expand();
    }
  };

  read(local: boolean = false) {
    const data = JSON.parse(getStorageItem(STORAGE_ITEMS.subNavigation)) || {};
    if (local) {
      return data[this.key];
    }
    return data;
  }

  save() {
    const data = this.read();
    data[this.key] = this.expanded;
    setStorageItem(STORAGE_ITEMS.subNavigation, JSON.stringify(data));
  }

  constructor(key: string) {
    this.key = key;
    this.expanded = this.read(true) || true;
  }
}

export default SubNavigation;
