import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import type { RouteComponentProps } from 'react-router-dom';

import type { RouterStore, DevicesStore, PersistenceStore, MapStore } from 'stores';
import type { SubNavUIStateStore } from 'components/SubNav/SubNavUIState.store';

import { HISTORY_MODE, PATHS } from 'config';

import MapVehiclePanel from '../MapVehiclePanel';
import SelectedVehicleMediaPanel from '../MediaPanel';

interface IProps {
  devicesStore: DevicesStore;
  mapStore: MapStore;
  routerStore: RouterStore;
  subNavStore: SubNavUIStateStore;
  persistenceStore?: PersistenceStore;
  match: {
    params: {
      vehicleId: string;
    };
  };
}

@inject(({ devicesMapStore: { devicesStore, mapStore }, routerStore, uiStateStore: { subNav }, persistenceStore }) => ({
  devicesStore,
  mapStore,
  routerStore,
  subNavStore: subNav,
  persistenceStore,
}))
@observer
class SelectedVehicleWithPanel extends Component<RouteComponentProps & IProps> {
  componentDidMount() {
    this.setSelectedDevice();
  }

  componentDidUpdate(prevProps) {
    const {
      devicesStore: { selectedDevice },
      match: {
        params: { vehicleId },
      },
    } = this.props;

    if (prevProps.match.params.vehicleId !== vehicleId) {
      this.setSelectedDevice();
    }
    this.persistSelectedVehicle();

    if (selectedDevice && !selectedDevice.latestEvent) {
      this.props.routerStore.history.push(PATHS.MAP.VEHICLES);
    }
  }

  setSelectedDevice = () => {
    const {
      devicesStore: { setSelectedDeviceId },
      match: {
        params: { vehicleId },
      },
    } = this.props;

    setSelectedDeviceId(vehicleId);
  };

  persistSelectedVehicle = () => {
    const {
      devicesStore: { selectedDevice },
    } = this.props;

    if (selectedDevice?.data) {
      this.props.persistenceStore.updateSelectedVehicle({
        value: selectedDevice.data.deviceId,
        label: selectedDevice.data.shortName,
      });
    }
  };

  handleBack = () => {
    const {
      routerStore: { push },
      devicesStore: {
        groups,
        resetSelectedEvent,
        setHistory,
        history: { modeId },
      },
      mapStore: { setStreetViewOpened },
      subNavStore: { isExpanded, setSubNavMenuState },
    } = this.props;

    const groupId = groups.groupId.value;
    const backPath = groupId && groupId !== 'all' ? PATHS.MAP.GROUP.replace(':groupId', groupId) : PATHS.MAP.VEHICLES;

    push(backPath);
    resetSelectedEvent();
    setStreetViewOpened(false);

    if (!isExpanded) {
      setSubNavMenuState();
    }

    if (modeId !== HISTORY_MODE.POINT_IN_TIME) {
      setHistory(false);
    }
  };

  render() {
    const {
      devicesStore: { selectedDevice, mediaPanel },
    } = this.props;

    return selectedDevice && selectedDevice.latestEvent ? (
      <div>
        <MapVehiclePanel onClose={this.handleBack} />
        {selectedDevice.data.mediaEnabled && mediaPanel.value && <SelectedVehicleMediaPanel />}
      </div>
    ) : null;
  }
}

export default SelectedVehicleWithPanel;
