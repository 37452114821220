import { computed } from 'mobx';
import TableBase from 'stores/TableBase';
import type { Inspections } from 'stores';
import type { IEditColumns } from 'models/Tables/IEditColumns';
import type { ISortColumn } from 'models/Tables/ISortColumn';
import { INSPECTIONS_FORMS_TABLE_COLUMNS, INSPECTIONS_SEARCH_PARAMS, INSPECTIONS_VIEW, STORAGE_ITEMS } from 'config';

const getInitialColumns = (view: INSPECTIONS_VIEW): Array<IEditColumns<INSPECTIONS_FORMS_TABLE_COLUMNS>> => {
  switch (view) {
    case INSPECTIONS_VIEW.ADMIN:
      return [{ value: INSPECTIONS_FORMS_TABLE_COLUMNS.INSPECTION_FORM, isSelected: true }];
    case INSPECTIONS_VIEW.DEFAULT:
    default:
      return [
        { value: INSPECTIONS_FORMS_TABLE_COLUMNS.INSPECTION_FORM, isSelected: true },
        { value: INSPECTIONS_FORMS_TABLE_COLUMNS.APPLIES_TO, isSelected: true },
        { value: INSPECTIONS_FORMS_TABLE_COLUMNS.TOTAL_ITEMS, isSelected: true },
        { value: INSPECTIONS_FORMS_TABLE_COLUMNS.TOTAL_REQUIRED_ITEMS, isSelected: true },
        { value: INSPECTIONS_FORMS_TABLE_COLUMNS.ACTIVE, isSelected: true },
      ];
  }
};

const initialSortedColumn: ISortColumn = {
  field: INSPECTIONS_FORMS_TABLE_COLUMNS.INSPECTION_FORM,
  order: 'ascend',
};

const storageNames = {
  [INSPECTIONS_VIEW.DEFAULT]: {
    columns: STORAGE_ITEMS.inspections.forms.columns,
    sortedColumn: STORAGE_ITEMS.inspections.forms.sortedColumn,
    pagination: STORAGE_ITEMS.inspections.forms.pagination,
    searchInColumn: STORAGE_ITEMS.inspections.forms.searchInColumn,
  },
  [INSPECTIONS_VIEW.ADMIN]: {
    columns: STORAGE_ITEMS.admin.inspections.forms.columns,
    sortedColumn: STORAGE_ITEMS.admin.inspections.forms.sortedColumn,
    pagination: STORAGE_ITEMS.admin.inspections.forms.pagination,
    searchInColumn: STORAGE_ITEMS.admin.inspections.forms.searchInColumn,
  },
};

const inspectionsSearchMap = {
  [INSPECTIONS_FORMS_TABLE_COLUMNS.INSPECTION_FORM]: INSPECTIONS_SEARCH_PARAMS.NAME,
};

export default class InspectionsFormsTable extends TableBase<IEditColumns<INSPECTIONS_FORMS_TABLE_COLUMNS>> {
  context: Inspections;

  constructor(context: Inspections) {
    super({
      columns: getInitialColumns(context.view.value),
      sortedColumn: initialSortedColumn,
      storageNames: storageNames[context.view.value],
    });

    this.context = context;
  }

  @computed get source() {
    return this.context.forms.items.toArray().map(({ name, id, appliesTo, items, isActive, repositoryForm, edit }) => {
      return {
        [INSPECTIONS_FORMS_TABLE_COLUMNS.INSPECTION_FORM]: name.value || '-',
        [INSPECTIONS_FORMS_TABLE_COLUMNS.APPLIES_TO]: appliesTo,
        [INSPECTIONS_FORMS_TABLE_COLUMNS.TOTAL_ITEMS]: items.list.value.length,
        [INSPECTIONS_FORMS_TABLE_COLUMNS.TOTAL_REQUIRED_ITEMS]: items.list.value.filter(
          (item) => item.value.requirePassFail
        ).length,
        [INSPECTIONS_FORMS_TABLE_COLUMNS.ACTIVE]: isActive,
        isUpdateLoading: repositoryForm.patchState.loading,
        update: edit,
        key: id,
      };
    });
  }

  @computed get searchData() {
    return this.selectedSearchColumns.reduce((acc, cur) => {
      return {
        ...acc,
        [inspectionsSearchMap[cur.column]]: cur.value,
      };
    }, {});
  }

  @computed get sortData() {
    return {
      sortBy: inspectionsSearchMap[this.sortedColumn.field],
      order: this.sortedColumn.order?.replace('end', '').toUpperCase(),
    };
  }
}
