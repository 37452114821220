import { computed } from 'mobx';
import moment from 'moment/moment';
import SimpleField from 'models/Fields/SimpleField';
import timeStore from 'stores/TimeStore';
import { DATE_TIME_FORMATS, JOURNAL_ZOOM_LEVELS } from 'config';
import { getStartOfDayByDate } from 'utils';

const GO_STEP = 7200000;
const ZOOM_STEP = 14400000;

class JournalView {
  zoomLevel: SimpleField<JOURNAL_ZOOM_LEVELS>;
  range: { from: SimpleField<number>; to: SimpleField<number> };
  limits: { from: SimpleField<number>; to: SimpleField<number> };
  goStep: SimpleField<number>;
  zoomStep: SimpleField<number>;

  constructor(zoomLevel = JOURNAL_ZOOM_LEVELS.LEVEL_1, goStep = GO_STEP, zoomStep = ZOOM_STEP) {
    this.zoomLevel = new SimpleField(zoomLevel);
    this.goStep = new SimpleField(goStep);
    this.zoomStep = new SimpleField(zoomStep);
    this.range = { from: new SimpleField(null), to: new SimpleField(null) };
    this.limits = { from: new SimpleField(null), to: new SimpleField(null) };
    this.setRangeLimits();
  }

  setRangeLimits = (date = '01/01/2022') => {
    const momentSelectedDate = moment.tz(date, DATE_TIME_FORMATS.monthDatYearFull, timeStore.sessionTimezone);
    const selectedDateTimestamp = momentSelectedDate.valueOf();
    const nextDateTimestamp = momentSelectedDate.add(1, 'days').valueOf();
    const from = getStartOfDayByDate(selectedDateTimestamp, timeStore.sessionTimezone);
    const to = getStartOfDayByDate(nextDateTimestamp, timeStore.sessionTimezone);

    this.range.from.set(from + this.rangeToLimitsDiff.from);
    this.range.to.set(to - this.rangeToLimitsDiff.to);
    this.limits.from.set(from);
    this.limits.to.set(to);
  };

  zoomRange = (direction?: 'in' | 'out') => {
    const from =
      direction === 'in' ? this.range.from.value + this.zoomStep.value : this.range.from.value - this.zoomStep.value;
    const to =
      direction === 'in' ? this.range.to.value - this.zoomStep.value : this.range.to.value + this.zoomStep.value;

    this.range.from.set(this.zoomLevel.value === JOURNAL_ZOOM_LEVELS.LEVEL_1 ? this.limits.from.value : from);
    this.range.to.set(this.zoomLevel.value === JOURNAL_ZOOM_LEVELS.LEVEL_1 ? this.limits.to.value : to);
  };

  zoomIn = () => {
    if (this.zoomLevel.value < JOURNAL_ZOOM_LEVELS.LEVEL_3) {
      this.zoomLevel.set(this.zoomLevel.value + 1);
      this.zoomRange('in');
    }
  };

  zoomOut = () => {
    if (this.zoomLevel.value > JOURNAL_ZOOM_LEVELS.LEVEL_1) {
      this.zoomLevel.set(this.zoomLevel.value - 1);
      this.zoomRange('out');
    }
  };

  goLeft = () => {
    this.range.from.set(this.range.from.value - this.goStep.value);
    this.range.to.set(this.range.to.value - this.goStep.value);
  };

  goRight = () => {
    this.range.from.set(this.range.from.value + this.goStep.value);
    this.range.to.set(this.range.to.value + this.goStep.value);
  };

  @computed get duration() {
    return this.range.to.value - this.range.from.value;
  }

  @computed get labelTimestamps() {
    const timeStep = this.duration / this.labelCount;
    const labelList = [];
    let labelTime = this.range.from.value;

    for (let i = 1; i <= this.labelCount; i++) {
      labelList.push(labelTime);
      labelTime += timeStep;
    }

    labelList.push(this.range.to.value);

    return labelList;
  }

  @computed get labelCount() {
    switch (this.zoomLevel.value) {
      case JOURNAL_ZOOM_LEVELS.LEVEL_1:
        return 12;
      case JOURNAL_ZOOM_LEVELS.LEVEL_2:
      case JOURNAL_ZOOM_LEVELS.LEVEL_3:
        return 8;
      default:
        return 12;
    }
  }

  @computed get disabledLeft() {
    return this.range.from.value === this.limits.from.value;
  }

  @computed get disabledRight() {
    return this.range.to.value === this.limits.to.value;
  }

  @computed get disabledZoomOut() {
    return this.zoomLevel.value === JOURNAL_ZOOM_LEVELS.LEVEL_1;
  }

  @computed get disabledZoomIn() {
    return this.zoomLevel.value === JOURNAL_ZOOM_LEVELS.LEVEL_3;
  }

  @computed get rangeToLimitsDiff() {
    return {
      from: Math.abs(this.limits.from.value - this.range.from.value),
      to: Math.abs(this.limits.to.value - this.range.to.value),
    };
  }
}

export default JournalView;
