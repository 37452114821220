export const ROUTER_STORE = 'routerStore';
export const USER_STORE = 'userStore';
export const DEVICES_MAP_STORE = 'devicesMapStore';
export const DEVICES_STORE = 'devicesStore';
export const UI_STATE_STORE = 'uiStateStore';
export const SEARCH_STORE = 'searchStore';
export const DASH_CAMS_STORE = 'dashCamsStore';
export const GEOZONES_STORE = 'geozonesStore';
export const CLEARSHARE_STORE = 'clearshareStore';
export const DISPATCH_STORE = 'dispatchStore';
export const ADMIN_STORE = 'adminStore';
export const REPORTS_STORE = 'reportsStore';
export const PERSISTENCE_STORE = 'persistenceStore';
export const TIME_STORE = 'timeStore';
export const EVENTS_REGISTRY_STORE = 'eventsRegistryStore';
export const ALERTS_TABLE_STORE = 'alertsTableStore';
export const SCHEDULED_REPORTS_STORE = 'scheduledReportsStore';

export const INTEGRATIONS_STORE = 'integrationsStore';
export const CUSTOMERS_STORE = 'customersStore';
export const WEX_CARDS_STORE = 'wexCardAssociationsTableStore';

export const MEDIA_MANAGER_STORE = 'mediaManagerStore';
export const MAINTENANCES_STORE = 'maintenancesStore';
export const INSPECTIONS_STORE = 'inspectionsStore';
export const PEOPLE_ADMIN_STORE = 'peopleAdmin';
