import React from 'react';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

interface IProps {
  children: React.ReactNode;
  fallback: React.ReactNode;
}

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  setError = () => this.setState({ hasError: true });

  componentDidMount() {
    EventsBus.get().on(APP_EVENTS.ERROR.UNEXPECTED, this.setError);
  }

  componentWillUnmount() {
    EventsBus.get().off(APP_EVENTS.ERROR.UNEXPECTED, this.setError);
  }

  componentDidCatch() {
    this.setError();
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { fallback, children } = this.props;

    if (hasError) {
      return fallback;
    }

    return children;
  }
}

export default ErrorBoundary;
