import React from 'react';

import type { SimpleField } from 'models/Fields';
import { validateAccessLevel } from 'stores';
import { ACL } from 'config';

import Note from 'components/Note';

import './styles.scss';

interface IProps {
  note: SimpleField<string>;
  isShowTitle?: boolean;
}

const MaintenancePreferenceNote: React.FC<IProps> = ({ note, isShowTitle = true }) => {
  const edit = validateAccessLevel([ACL.MAINTENANCE.NOTES.UPDATE]);

  return (
    <div className="MaintenancePreference-line MaintenancePreferenceNote">
      <div className="MaintenancePreference-label MaintenancePreference-label--top">{isShowTitle && 'Note'}</div>
      <div className="MaintenancePreference-value">
        <div className="MaintenancePreferenceNote-note">
          <Note
            isEditing
            note={note.value}
            onChange={note.set}
            hideControls
            countCharactersEntered
            placeholder="Note"
            disabled={!edit}
          />
        </div>
      </div>
    </div>
  );
};

export default MaintenancePreferenceNote;
