import { ToggleField } from 'models/Fields';

class PersonPermissionsVehicleInspectorModel {
  active: ToggleField;

  constructor(inspector) {
    this.active = new ToggleField(Boolean(inspector));
  }

  get data() {
    return this.active.value ? {} : null;
  }

  get isUpdated() {
    return this.active.isUpdated;
  }

  reset = () => {
    this.active.reset();
  };
}

export default PersonPermissionsVehicleInspectorModel;
