import { computed } from 'mobx';
import { ListField, ToggleField } from 'models/Fields';
import type { IMediaTypeOption } from 'interfaces/models/Dashcams/IMediaTypeOption';

const initialMediaTypes = [
  { name: 'All', value: '', isSelected: new ToggleField(true) },
  { name: 'Video', value: 'Video', isSelected: new ToggleField(false) },
  { name: 'Image', value: 'Image', isSelected: new ToggleField(false) },
];

export default class MediaType {
  options: ListField<IMediaTypeOption>;

  constructor(options = initialMediaTypes) {
    this.options = new ListField<IMediaTypeOption>(options);
  }

  @computed get selected() {
    return this.options.toArray().find((option) => option.isSelected.value);
  }

  @computed get params() {
    return this.selected?.value && { mediaTypes: this.selected?.value };
  }

  init = (value: string) => {
    this.unselectAll();
    this.findOption(value)?.isSelected.toggle(true);
  };

  findOption = (value: string = '') => {
    return this.options.toArray().find((option) => option.value.toLowerCase() === value.toLowerCase());
  };

  unselectAll = () => this.options.toArray().forEach((option) => option.isSelected.toggle(false));
}
