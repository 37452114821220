import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import validateAccessLevel from 'stores/acl/validator';
import { PATHS } from 'config';

interface IProps extends RouteProps {
  rule?: boolean;
  acls?: string[];
}

const ControlledRoute: FC<IProps> = ({ rule = true, acls = null, ...props }) => {
  if (rule && (!acls || validateAccessLevel(acls))) {
    return <Route {...props} />;
  }
  return <Redirect from="*" to={PATHS.NOT_FOUND} />;
};

export default observer(ControlledRoute);
