import React from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  list: Report.IServerOption[];
  onChange: (value: Report.IServerOption) => void;
  selectedItemName?: string;
}

const ReportOptionSearchList: React.FC<IProps> = ({ list, onChange, selectedItemName = '' }) => {
  return (
    <>
      {list.map((item, index) => {
        const { displayName, name } = item;
        const itemCn = classNames('ReportOptionSearchList-item', {
          'ReportOptionSearchList-item--active': name === selectedItemName,
        });

        return (
          <li key={`${name}/${index}`} className={itemCn} onClick={() => onChange(item)}>
            <span className="ReportOptionSearchList-itemText">{displayName || name}</span>
          </li>
        );
      })}
    </>
  );
};

export default ReportOptionSearchList;
