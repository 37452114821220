import React, { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

import './styles.scss';
import { isDevelop } from '../../config';

const TIMEOUT_DELAY = 30000;

const logger = (msg) => {
  if (isDevelop) {
    // tslint:disable-next-line:no-console
    console.log(msg);
  }
};

const OfflineContainer = () => {
  const [isOnline, setOnline] = useState(true);
  const timeout = useRef<number>(0);

  const handleOnline = () => {
    logger('handleOnline');
    setOnline(true);
    window.removeEventListener('mousemove', handleMouseMove);
    if (timeout.current) {
      logger('clearTimeout');
      clearTimeout(timeout.current);
    }
  };
  const handleOffline = () => {
    logger('handleOffline');
    timeout.current = setTimeout(() => {
      logger('setTimeout setOnline(false);');
      setOnline(false);
    }, TIMEOUT_DELAY);
    window.addEventListener('mousemove', handleMouseMove);
  };

  const handleMouseMove = debounce(() => {
    logger('handleMouseMove');
    if (window.navigator.onLine) {
      handleOnline();
    }
  }, 1000);

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return !isOnline ? (
    <div className="OfflineContainer">
      <div className="OfflineContainer-alert">Network access unavailable. Please check your network settings.</div>
    </div>
  ) : null;
};

export default OfflineContainer;
