import React, { useCallback } from 'react';

import { mediaManagerHoveredMarker, mediaManagerMarker } from 'assets';
import { MAP_MARKERS } from 'config';

import Marker from 'components/Map/Marker';

interface IProps {
  lat: number;
  lng: number;
  update: (value: string) => void;
  interaction: string;
}

const MediaMapItem: React.FC<IProps> = ({ lat, lng, interaction, update }) => {
  const handleMouseEnter = useCallback(() => {
    update('hover');
  }, [update]);

  const handleMouseLeave = useCallback(() => {
    update('default');
  }, [update]);

  const handleClick = useCallback(() => {
    update('active');
  }, [update]);

  return (
    <Marker
      icon={interaction === 'hover' ? mediaManagerHoveredMarker : mediaManagerMarker}
      zIndex={
        interaction === 'active' ? MAP_MARKERS.ACTIVE : interaction === 'hover' ? MAP_MARKERS.HOVER : MAP_MARKERS.BASE
      }
      position={{ lat, lng }}
      onClick={handleClick}
      onMouseOver={handleMouseEnter}
      onMouseOut={handleMouseLeave}
    />
  );
};

export default MediaMapItem;
