import React, { useMemo } from 'react';
import { Observer } from 'mobx-react';
import moment from 'moment';
import classNames from 'classnames';

import { getFormattedTime } from 'utils';
import { DATE_TIME_FORMATS } from 'config';

import TimePickerInput from 'components/TimePickerInput';
import type MediaDuration from 'models/Dashcam/MediaCommon/MediaDuration';
import type RequestMediaTimeSegment from 'models/Dashcam/MediaRequestCheckout/RequestMedia/RequestMediaTimeSegment';
import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';

import './styles.scss';

interface IProps {
  duration: MediaDuration;
  segment: RequestMediaTimeSegment;
  timezone: string;
  horizontal?: boolean;
  withoutAdditionalLength?: boolean;
  withRestrictions?: boolean;
  onClockOpen?: () => void;
  onClockClose?: () => void;
}

const MediaManagerRequestMediaSettings: React.FC<IProps> = ({
  duration,
  segment,
  timezone,
  horizontal = false,
  withoutAdditionalLength = false,
  withRestrictions = true,
  onClockOpen = () => true,
  onClockClose = () => true,
}) => {
  const handleChangeDuration = ({ value }) => {
    duration.active.set(parseInt(value, 10));
  };

  const handleStartTimeChange = (timeString) => {
    const date = getFormattedTime(segment.startTime.value, DATE_TIME_FORMATS.monthDatYearFull, timezone);
    const dateString = `${date} ${timeString}`;
    const timestamp = moment
      .tz(dateString, `${DATE_TIME_FORMATS.monthDatYearFull} ${DATE_TIME_FORMATS.hoursMinutesSeconds24}`, timezone)
      .valueOf();

    segment.startTime.set(timestamp);
  };

  const time = () => {
    return segment.startTime.value
      ? getFormattedTime(segment.startTime.value, DATE_TIME_FORMATS.hoursMinutesSeconds24, timezone)
      : null;
  };

  const getSelectedMediaLimits = () => {
    return {
      start: segment.timeline.start.value
        ? getFormattedTime(segment.timeline.start.value, DATE_TIME_FORMATS.hoursMinutesSeconds24, timezone)
        : undefined,
      end: segment.timeline.end.value
        ? getFormattedTime(
            segment.timeline.end.value - duration.active.value,
            DATE_TIME_FORMATS.hoursMinutesSeconds24,
            timezone
          )
        : undefined,
    };
  };

  const additionalLengthValue = useMemo(() => {
    switch (duration.active.value) {
      case 40000:
        return '2 clips (20s ea)';
      case 60000:
        return '3 clips (20s ea)';
      case 20000:
      default:
        return '';
    }
  }, [duration.active.value]);

  return (
    <Observer
      render={() => (
        <div
          className={classNames('MediaManagerRequestMediaSettings', {
            'MediaManagerRequestMediaSettings--horizontal': horizontal,
          })}
        >
          <div className="MediaManagerRequestMediaSettings-section MediaManagerRequestMediaSettings-section--startTime">
            <p className="MediaManagerRequestMediaSettings-sectionLabel MediaManagerRequestMediaSettings-sectionLabel--startTime">
              Request start time
            </p>
            <div className="MediaManagerRequestMediaSettings-sectionContent">
              <TimePickerInput
                disabled={!time()}
                amPmStatic={Boolean(false)}
                value={time()}
                onChange={handleStartTimeChange}
                onClockOpen={onClockOpen}
                onClockClose={onClockClose}
                minTime={withRestrictions && getSelectedMediaLimits().start}
                maxTime={withRestrictions && getSelectedMediaLimits().end}
              />
            </div>
          </div>
          <div className="MediaManagerRequestMediaSettings-section MediaManagerRequestMediaSettings-section--duration">
            <p className="MediaManagerRequestMediaSettings-sectionLabel MediaManagerRequestMediaSettings-sectionLabel--duration">
              Request duration
            </p>
            <div className="MediaManagerRequestMediaSettings-sectionContent">
              <div className="MediaManagerRequestMediaSettings-sectionContent--select">
                <SearchableStaticSelect
                  value={{ value: duration.active.value.toString(), label: `${duration.active.value / 1000} Seconds` }}
                  values={duration.options.value.map((option) => ({
                    value: option.value.toString(),
                    label: `${option.value / 1000} Seconds`,
                  }))}
                  handleChange={handleChangeDuration}
                  isSearchable={false}
                />
              </div>
              {!withoutAdditionalLength && (
                <div className="MediaManagerRequestMediaSettings-sectionContent--text">{additionalLengthValue}</div>
              )}
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default MediaManagerRequestMediaSettings;
