import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { DevicesStore } from 'stores';
import { KeyboardShortcutsManager } from 'tools';
import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';

import { ChevronIcon } from 'assets';
import './styles.scss';

interface IProps {
  devicesStore?: DevicesStore;

  onSelect: (option: string) => void;
}

interface IState {
  isOpen: boolean;
}

@inject(({ devicesMapStore: { devicesStore } }) => ({
  devicesStore,
}))
@observer
class MapVehicleSelector extends Component<IProps, IState> {
  containerRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      isOpen: false,
    };
  }

  componentDidUpdate(_, prevState) {
    if (prevState.isOpen !== this.state.isOpen) {
      this.state.isOpen ? KeyboardShortcutsManager.get().deactivate() : KeyboardShortcutsManager.get().activate();
    }
  }

  get options() {
    const {
      devicesStore: { sortedDevicesList },
    } = this.props;

    return [
      ...sortedDevicesList.map((devices) => {
        return {
          value: devices.data.deviceId,
          label: devices.data.shortName,
        };
      }),
    ];
  }

  handleClick = () => {
    this.setState({
      isOpen: true,
    });
  };

  handleChange = (option: Select.ISelectOption) => {
    this.props.onSelect(option.value);

    this.setState({
      isOpen: false,
    });
  };

  onMenuClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  render() {
    const {
      devicesStore: {
        selectedDevice: {
          data: { shortName, deviceId },
        },
      },
    } = this.props;

    const { isOpen } = this.state;

    return (
      <div ref={this.containerRef}>
        {isOpen ? (
          <div className="MapVehicleSelector-select">
            <SearchableStaticSelect
              handleChange={this.handleChange}
              values={this.options}
              value={{ value: deviceId, label: shortName }}
              onMenuClose={this.onMenuClose}
              autoFocus
              isMenuOpen
              emptySearchResults={'No Vehicles Found'}
            />
          </div>
        ) : (
          <div className="MapVehicleSelector-container" onClick={this.handleClick}>
            <div className="MapVehicleSelector-title">{shortName}</div>
            <span className="MapVehicleSelector-caret">
              <ChevronIcon className="MapVehicleSelector-caretIcon" />
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default MapVehicleSelector;
