import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { REPORTS_STORE } from 'config';
import type { ReportsStore } from 'stores';

interface IProps {
  reportsStore?: ReportsStore;
  item: { value: string; sortValue?: number; mapLink: { lat: number; lon: number }; color?: string; type?: string };
  className: string;
}

@inject(REPORTS_STORE)
@observer
class ReportTableByGroupRowEventCellHTML extends Component<IProps> {
  render() {
    const { item, className } = this.props;

    return <div className={className} style={{ color: item.color }} dangerouslySetInnerHTML={{ __html: item.value }} />;
  }
}

export default ReportTableByGroupRowEventCellHTML;
