import WSState from './WebSocketState';

class WSDisconnectState extends WSState {
  activate(): void {
    if (this.context.client) {
      this.context.client.reconnectDelay = 0;
      this.context.client.deactivate();
      this.context.clearPolling();
    }
  }
}

export default WSDisconnectState;
