import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { CustomersStore } from 'stores';

import Marker from 'components/Map/Marker';
import CustomerMapMarkerIcon from 'components/Map/Customers/CustomerMapMarkerIcon';

import svgToMiniDataURI from 'mini-svg-data-uri';

interface IProps {
  customersStore?: CustomersStore;
}

@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class CustomerMapMarker extends Component<IProps> {
  render() {
    const address = this.props.customersStore.selectedCustomer?.address;
    const domain = this.props.customersStore.selectedCustomer?.domain;
    const name = this.props.customersStore.selectedCustomer?.name;

    return address && address.latitude ? (
      <Marker
        position={{
          lat: address.latitude,
          lng: address.longitude,
        }}
        icon={svgToMiniDataURI(CustomerMapMarkerIcon({ name, domain }))}
      />
    ) : null;
  }
}

export default CustomerMapMarker;
