import React, { FC } from 'react';
import classnames from 'classnames';

import type DueInCell from 'models/Maintenances/Representation/DueInCell';
import type { timezoneNames } from 'stores';

import './styles.scss';

interface IProps {
  cell: DueInCell;
  timezone: timezoneNames;
}

const MaintenanceDueInCell: FC<IProps> = ({ cell }) => {
  return (
    <div className="MaintenanceDueInCell">
      {cell.rows.map((row, i) => {
        if (row.visible) {
          const dueInCn = classnames('MaintenanceDueInCell-dueIn', {
            [`MaintenanceDueInCell-dueIn--${row.textDueInColor}`]: Boolean(row.textDueInColor),
          });

          return (
            <div className="MaintenanceDueInCell-row" key={i}>
              <span className={dueInCn}>{row.textDueIn}</span>
              <span className="MaintenanceDueInCell-next">{row.textNext}</span>
            </div>
          );
        }
      })}
    </div>
  );
};

export default MaintenanceDueInCell;
