import MediaRequestCheckout from 'models/Dashcam/MediaRequestCheckout';
import MediaLibrary from 'models/Dashcam/MediaLibrary';

export class MediaManager {
  library: MediaLibrary;
  checkout: MediaRequestCheckout;

  constructor() {
    this.library = new MediaLibrary();
    this.checkout = new MediaRequestCheckout();
  }
}

export default new MediaManager();
