import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { DevicesStore, GeozonesStore, MapStore, UserAccessLevelComponent } from 'stores';
import { MAP_CREATE_GEOZONE_CREATE } from 'config';

import Button from 'components/Button';
import GeozoneColorsFilter from 'components/Filters/GeozoneColors';
import GeozonesList from 'components/SubNav/GeozonesList';
import SubNavFilters from 'components/SubNav/Filters';
import Toggle from 'components/Toggle';

import { AddGeozoneIcon, GeozoneCancelIcon } from 'assets';
import './styles.scss';

interface IProps {
  devicesStore: DevicesStore;
  geozonesStore: GeozonesStore;
  mapStore: MapStore;
}

@inject(({ devicesMapStore: { devicesStore, mapStore }, geozonesStore }) => ({
  devicesStore,
  geozonesStore,
  mapStore,
}))
@observer
class GeozonesContainer extends Component<IProps> {
  componentDidMount(): void {
    const {
      devicesStore: { getDevices, devicesListCount },
      geozonesStore: { getGeozonesList },
    } = this.props;

    if (!devicesListCount) {
      getDevices();
    }
    getGeozonesList();
    window.addEventListener('keyup', this.listenEscKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.listenEscKeyPress);
    this.disablePointGeozoneLocation();
  }

  disablePointGeozoneLocation = () => {
    const {
      mapStore: { togglePointGeozoneLocation },
    } = this.props;

    togglePointGeozoneLocation(false);
  };

  listenEscKeyPress = (e) => {
    if (e.key === 'Escape') {
      this.disablePointGeozoneLocation();
    }
  };

  renderCreateGeozoneIcon = () => {
    const {
      mapStore: { pointGeozoneLocation },
    } = this.props;

    return pointGeozoneLocation ? (
      <GeozoneCancelIcon className="GeozonesContainer-createGeozoneIcon GeozonesContainer-createGeozoneIcon--cancel" />
    ) : (
      <AddGeozoneIcon className="GeozonesContainer-createGeozoneIcon" />
    );
  };

  render() {
    const {
      geozonesStore: {
        dragPinnedGeozones,
        filteredGeozonesList,
        filters: { query, setQuery, selectedColorIds, setSelectedColors, resetSelectedColors },
        geozonesList,
        repositoryGeozone,
        pinnedGeozonesIdsList,
        selectedGeozoneId,
        setPinnedGeozonesIds,
        uniqGeozoneColors,
      },
      mapStore: {
        mapGeozonesLayer,
        toggleMapGeozonesLayer,
        mapLabelSize,
        togglePointGeozoneLocation,
        pointGeozoneLocation,
      },
    } = this.props;

    return (
      <div className="GeozonesContainer">
        <div className="GeozonesContainer-section GeozonesContainer-section--top">
          <div className="GeozonesContainer-filters">
            <div className="GeozonesContainer-searchByName">
              <SubNavFilters
                isDisabled={Boolean(!geozonesList.length)}
                onSearchQueryChange={setQuery}
                searchPlaceholder="Filter Geozones"
                searchQuery={query}
              />
            </div>
            <div className="GeozonesContainer-searchByColor">
              <GeozoneColorsFilter
                colors={uniqGeozoneColors}
                onChange={setSelectedColors}
                onReset={resetSelectedColors}
                selectedColorIds={selectedColorIds}
              />
            </div>
          </div>
        </div>
        <div className="GeozonesContainer-section GeozonesContainer-section--bottom">
          <UserAccessLevelComponent requiredAccessLevel={[MAP_CREATE_GEOZONE_CREATE]}>
            <Button
              className="GeozonesContainer-createGeozone Button--link"
              title={pointGeozoneLocation ? 'Cancel' : 'Create Geozone'}
              IconComponent={this.renderCreateGeozoneIcon()}
              onClick={togglePointGeozoneLocation}
            />
          </UserAccessLevelComponent>
          <div className="GeozonesContainer-switcher">
            <div className="GeozonesContainer-switcherText">Show geozones</div>
            <div className="GeozonesContainer-toggle">
              <Toggle checked={mapGeozonesLayer} onChange={toggleMapGeozonesLayer} />
            </div>
          </div>
        </div>
        {Boolean(repositoryGeozone.getState.success) && (
          <div className="GeozonesContainer-list">
            <GeozonesList
              dragPinnedGeozones={dragPinnedGeozones}
              itemSize={mapLabelSize}
              list={filteredGeozonesList}
              onItemPin={setPinnedGeozonesIds}
              pinnedGeozonesIdsList={pinnedGeozonesIdsList}
              selectedGeozoneId={selectedGeozoneId}
            />
          </div>
        )}
      </div>
    );
  }
}

export default GeozonesContainer;
