import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  className?: string;
  value: number;
}

const LevelBar: FC<IProps> = ({ className, value }) => {
  const level: string = useMemo(() => {
    if (value < 20) {
      return 'low';
    } else if (20 <= value && value <= 50) {
      return 'medium';
    } else {
      return 'high';
    }
  }, [value]);
  const cn = classNames('LevelBar', className, {
    [`LevelBar--${level}`]: level,
  });

  return (
    <div className={cn}>
      <div className="LevelBar-bar">
        <div className="LevelBar-level" style={{ width: `${value}%` }} />
      </div>
      <div className="LevelBar-text">{value}%</div>
    </div>
  );
};

export default LevelBar;
