import React from 'react';
import classNames from 'classnames';

import { LoaderIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  position?: string;
  color?: string;
}

const Loader: React.FC<IProps> = ({ className, position, color }) => {
  const cn = classNames('Loader', className, {
    [`Loader--${position}`]: Boolean(position),
    [`Loader--${color}`]: true,
  });

  return (
    <div className={cn}>
      <LoaderIcon className="Loader-icon" />
    </div>
  );
};

Loader.defaultProps = {
  position: 'center',
};

export default Loader;
