import { METRICS_SWITCH_TYPE } from 'config';
import { SimpleField, ToggleField } from 'models/Fields';

class MaintenanceModalDueEveryUI {
  metricsCheckbox: ToggleField;
  dateCheckbox: ToggleField;
  metricsSwitch: SimpleField<METRICS_SWITCH_TYPE>;
  lastPerformedEdit: ToggleField;
  isLastPerformedError: ToggleField;
  isError: ToggleField;

  constructor(due = { metricsCheckbox: true, dateCheckbox: false, metricsSwitch: METRICS_SWITCH_TYPE.MILES }) {
    this.metricsCheckbox = new ToggleField(due.metricsCheckbox);
    this.dateCheckbox = new ToggleField(due.dateCheckbox);
    this.metricsSwitch = new SimpleField(due.metricsSwitch);
    this.lastPerformedEdit = new ToggleField(false);
    this.isLastPerformedError = new ToggleField(false);
    this.isError = new ToggleField(false);
  }

  reset = () => {
    this.metricsCheckbox.reset();
    this.dateCheckbox.reset();
    this.metricsSwitch.reset();
    this.lastPerformedEdit.reset();
    this.isLastPerformedError.reset();
  };
}

export default MaintenanceModalDueEveryUI;
