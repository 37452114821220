import { action } from 'mobx';

import Asset from '../../Assets/Asset';
import GroupAsset from '../../Groups/GroupAsset';
import InspectionPerformedPDF from './InspectionPerformedPDF';
import InspectionForm from './InspectionForm';
import InspectionResults from './InspectionResults';
import InspectionService from './InspectionService';
import { DATE_TIME_FORMATS, MI_TO_KM } from 'config';
import { FormattedDate, Location, LoggerInfo } from 'models/General';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';
import { ToggleField, ListField } from 'models/Fields';
import type { IAppFile } from 'models/Files/IAppFile';
import AppFile from 'models/Files/AppFile';

class InspectionPerformed {
  form: InspectionForm;
  loggerInfo: LoggerInfo;
  results: InspectionResults;
  location: Location;
  service: InspectionService;
  asset: Asset;
  completedTime: FormattedDate;
  elapsedTime: number;
  inspectionBy: string;
  attachments: ListField<IAppFile>;
  id: number;
  groups: GroupAsset[];
  notes: string;
  pdf: InspectionPerformedPDF;

  repository: IEntityRepository;
  isFetched: ToggleField;

  constructor({
    asset,
    assetGroups,
    creationTime,
    createdBy,
    lastUpdateTime,
    updatedBy,
    status,
    failedItemsCount,
    address,
    latitude,
    longitude,
    odometerKm,
    requireUpdatingEngHours,
    requireUpdatingOdometer,
    engineHours,
    performedOn,
    elapsedTime,
    attachments,
    template,
    notes,
    id,
    performedBy,
  }) {
    this.form = new InspectionForm(template);
    this.loggerInfo = new LoggerInfo(
      { creationTime, createdBy, lastUpdateTime, updatedBy },
      DATE_TIME_FORMATS.adminVehicleSettings
    );
    this.results = new InspectionResults(status, failedItemsCount);
    this.location = new Location({ address, lat: latitude, lng: longitude });
    this.service = new InspectionService(
      {
        odometer: odometerKm,
        requireUpdatingEngHours,
        requireUpdatingOdometer,
        engineHours,
      },
      MI_TO_KM
    );
    this.asset = new Asset(asset);
    this.completedTime = new FormattedDate(performedOn, DATE_TIME_FORMATS.adminVehicleSettings);
    this.elapsedTime = elapsedTime;
    this.inspectionBy = performedBy;
    this.attachments = new ListField(
      attachments.map(
        (attachment) =>
          new AppFile(repositoryService.get('dvir').entity('attachment').entity(id), {
            ...attachment,
            type: attachment.url.includes('.heic') ? 'image/heic' : 'image/*',
          })
      )
    );
    this.notes = notes;
    this.id = id;
    this.groups = assetGroups?.map(
      (serverGroup) => new GroupAsset({ id: serverGroup.id, name: serverGroup.description }) || []
    );

    this.pdf = new InspectionPerformedPDF(this);
    this.repository = repositoryService.get('dvir').entity(id);
    this.isFetched = new ToggleField(false);
  }

  fetch = async () => {
    if (!this.isFetched.value) {
      const response = await this.repository.get();

      this.updateDetails(response);
      this.isFetched.toggle(true);
    }
  };

  @action updateDetails = (response) => {
    this.attachments.replace(
      response.attachments.map(
        (attachment) =>
          new AppFile(repositoryService.get('dvir').entity('attachment').entity(this.id.toString()), {
            ...attachment,
            type: attachment.url.includes('.heic') ? 'image/heic' : 'image/*',
          })
      )
    );

    this.form.items.replaceItems(response.items);
  };
}

export default InspectionPerformed;
