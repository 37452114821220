import React from 'react';
import { Marker, MarkerProps, InfoWindow } from 'react-google-maps';
import type { Event, Device } from 'models';
import { observer } from 'mobx-react';
import TrackPointCard from '../TrackPointDetails';
import type { timezoneNames } from 'stores';
import type DevicesFilters from 'stores/Map/DevicesFilters';

interface IProps {
  icon?: string;
  anchor?: google.maps.Point;
  locked?: boolean;
  wayPoint: Event;
  timezone: timezoneNames | string;
  showHistory: boolean;
  selectedDevice: Device;
  filters: DevicesFilters;
  setStreetViewOpened: () => void;
  onZoomIntoClick: () => void;
  unselectClick: () => void;
  onGeozoneClick: (geozoneId: string) => void;
  hiddenTrackPointCard?: boolean;
}

const MarkerWithInfoWindow: React.FC<IProps & MarkerProps> = observer(
  ({
    icon = null,
    anchor,
    locked,
    wayPoint,
    timezone,
    showHistory,
    setStreetViewOpened,
    onZoomIntoClick,
    selectedDevice,
    filters,
    onClick,
    unselectClick,
    onGeozoneClick,
    hiddenTrackPointCard = false,
    ...props
  }) => {
    const { isSelected } = wayPoint;

    const handleClick = (e) => {
      onClick(e);
      wayPoint.showTrackPointCard();
    };

    const handleCloseClick = () => {
      unselectClick();
      wayPoint.hideTrackPointCard();
    };

    return (
      <Marker
        position={{ lat: Number(wayPoint.latitude), lng: Number(wayPoint.longitude) }}
        onClick={handleClick}
        options={{
          icon: {
            url: icon,
            anchor,
          },
          optimized: false,
        }}
        {...props}
      >
        {isSelected && !hiddenTrackPointCard && (
          <InfoWindow onCloseClick={handleCloseClick} options={{ disableAutoPan: locked || wayPoint.isDisablePan }}>
            <TrackPointCard
              wayPoint={wayPoint}
              timezone={timezone}
              locked={locked}
              showHistory={showHistory}
              onZoomIntoClick={onZoomIntoClick}
              setStreetViewOpened={setStreetViewOpened}
              onGeozoneClick={onGeozoneClick}
              unselectClick={unselectClick}
              selectedDevice={selectedDevice}
              filters={filters}
            />
          </InfoWindow>
        )}
      </Marker>
    );
  }
);

export default MarkerWithInfoWindow;
