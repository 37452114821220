import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import noop from 'lodash/noop';
import type { ColumnsType } from 'antd/es/table';

import { UserAccessLevelComponent, TimeStore } from 'stores';
import {
  ADMIN_GEOZONES_TABLE_COLUMNS,
  PATHS,
  STORAGE_ITEMS,
  MAP_CREATE_GEOZONE_CREATE,
  ADMIN_GEOZONES_READ,
  ADMIN_GEOZONES_UPDATE,
  ADMIN_GEOZONES_DELETE,
} from 'config';
import { getJSONItemFromStorage, setJSONItemToStorage } from 'utils';
import type { GeozonesAdmin, ITableGeozone } from 'stores/Admin/Geozones';
import validateAccessLevel from 'stores/acl/validator';

import AdminContentWrapper from 'components/Admin/ContentWrapper';
import type Geozone from 'models/Geozone';
import CustomTable from 'components/Table/CustomTable';
import TableActions from 'components/Table/Actions';
import Button from 'components/Button';
import DeleteGeozoneModal from '../DeleteGeozone';
import CreateGeozone from '../CreateGeozone';
import TableDownloadModal from 'components/Table/TableDownloadModal';
import TableEmailModal from 'components/Table/TableEmailModal';

import './styles.scss';

interface IProps {
  className?: string;
  geozonesAdmin?: GeozonesAdmin;
  timeStore?: TimeStore;
}

interface IState {
  createGeozone: boolean;
  deleteGeozone: boolean;
  geozoneToDelete: ITableGeozone;
  isFilterOpen: boolean;
  isDownloadModalOpen: boolean;
  isEmailModalOpen: boolean;
}

const editColumnsMap = {
  [ADMIN_GEOZONES_TABLE_COLUMNS.NAME]: {
    text: 'Name',
    isDisabled: true,
  },
  [ADMIN_GEOZONES_TABLE_COLUMNS.CATEGORY]: {
    text: 'Category',
    isDisabled: false,
  },
  [ADMIN_GEOZONES_TABLE_COLUMNS.COLOR]: {
    text: 'Color',
    isDisabled: false,
  },
  [ADMIN_GEOZONES_TABLE_COLUMNS.SHAPE]: {
    text: 'Shape',
    isDisabled: false,
  },
  [ADMIN_GEOZONES_TABLE_COLUMNS.GROUP]: {
    text: 'Group',
    isDisabled: false,
  },
  [ADMIN_GEOZONES_TABLE_COLUMNS.DISPATCH_MARKER]: {
    text: 'Dispatch Marker',
    isDisabled: false,
  },
  [ADMIN_GEOZONES_TABLE_COLUMNS.ADDRESS_OVERRIDE]: {
    text: 'Address Override',
    isDisabled: false,
  },
  [ADMIN_GEOZONES_TABLE_COLUMNS.TRACK_ARRIVALS]: {
    text: 'Track Arrivals',
    isDisabled: false,
  },
  [ADMIN_GEOZONES_TABLE_COLUMNS.TRACK_DEPARTURES]: {
    text: 'Track Departures',
    isDisabled: false,
  },
  [ADMIN_GEOZONES_TABLE_COLUMNS.RADIUS]: {
    text: 'Radius (Circle Zones)',
    isDisabled: false,
  },
  [ADMIN_GEOZONES_TABLE_COLUMNS.GEOZONE_ID]: {
    text: 'Geozone ID',
    isDisabled: false,
  },
  [ADMIN_GEOZONES_TABLE_COLUMNS.ACTIVE]: {
    text: 'Active',
    isDisabled: false,
  },
  [ADMIN_GEOZONES_TABLE_COLUMNS.CREATED_BY]: {
    text: 'Created By',
    isDisabled: false,
  },
  [ADMIN_GEOZONES_TABLE_COLUMNS.CREATION_TIME]: {
    text: 'Time Created',
    isDisabled: false,
  },
  [ADMIN_GEOZONES_TABLE_COLUMNS.UPDATED_BY]: {
    text: 'Modified By',
    isDisabled: false,
  },
  [ADMIN_GEOZONES_TABLE_COLUMNS.LAST_UPDATE_TIME]: {
    text: 'Last Modified',
    isDisabled: false,
  },
};

@inject(({ adminStore: { geozonesAdmin }, timeStore }) => ({
  geozonesAdmin,
  timeStore,
}))
@observer
class AdminGeozonesTable extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      createGeozone: false,
      deleteGeozone: false,
      geozoneToDelete: null,
      isFilterOpen: getJSONItemFromStorage(STORAGE_ITEMS.admin.filterOpenStatus, true),
      isDownloadModalOpen: false,
      isEmailModalOpen: false,
    };
  }

  componentDidMount() {
    const {
      geozonesAdmin: { getGroupsList },
    } = this.props;

    getGroupsList();
  }

  getColumns = (): ColumnsType<ITableGeozone> => {
    const {
      geozonesAdmin: {
        columns,
        sortedColumn: { field, order },
      },
    } = this.props;

    const allColumns = [
      {
        title: 'Name',
        dataIndex: ADMIN_GEOZONES_TABLE_COLUMNS.NAME,
        defaultSortOrder: field === ADMIN_GEOZONES_TABLE_COLUMNS.NAME ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        render: (_, { id, name }) =>
          validateAccessLevel([ADMIN_GEOZONES_READ]) ? (
            <Link to={PATHS.ADMIN.TABLES.GEOZONES.GEOZONE.replace(':id', id)}>{name}</Link>
          ) : (
            name
          ),
        fixed: true,
        width: 220,
      },
      {
        title: 'Category',
        dataIndex: ADMIN_GEOZONES_TABLE_COLUMNS.CATEGORY,
        defaultSortOrder: field === ADMIN_GEOZONES_TABLE_COLUMNS.CATEGORY ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Color',
        dataIndex: ADMIN_GEOZONES_TABLE_COLUMNS.COLOR,
        defaultSortOrder: field === ADMIN_GEOZONES_TABLE_COLUMNS.COLOR ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Shape',
        dataIndex: ADMIN_GEOZONES_TABLE_COLUMNS.SHAPE,
        defaultSortOrder: field === ADMIN_GEOZONES_TABLE_COLUMNS.SHAPE ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
        render: (shape) => <span className="ant-table-cellValue ant-table-cellValue--shape">{shape}</span>,
        hiddenSearch: true,
      },
      {
        title: 'Dispatch Marker',
        dataIndex: ADMIN_GEOZONES_TABLE_COLUMNS.DISPATCH_MARKER,
        width: 180,
        className: 'ant-table-cell--no-sorter',
      },
      {
        title: 'Group',
        dataIndex: ADMIN_GEOZONES_TABLE_COLUMNS.GROUP,
        defaultSortOrder: field === ADMIN_GEOZONES_TABLE_COLUMNS.GROUP ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Address Override',
        dataIndex: ADMIN_GEOZONES_TABLE_COLUMNS.ADDRESS_OVERRIDE,
        defaultSortOrder: field === ADMIN_GEOZONES_TABLE_COLUMNS.ADDRESS_OVERRIDE ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
        hiddenSearch: true,
      },
      {
        title: 'Track Arrivals',
        dataIndex: ADMIN_GEOZONES_TABLE_COLUMNS.TRACK_ARRIVALS,
        defaultSortOrder: field === ADMIN_GEOZONES_TABLE_COLUMNS.ADDRESS_OVERRIDE ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
        hiddenSearch: true,
      },
      {
        title: 'Track Departures',
        dataIndex: ADMIN_GEOZONES_TABLE_COLUMNS.TRACK_DEPARTURES,
        defaultSortOrder: field === ADMIN_GEOZONES_TABLE_COLUMNS.TRACK_DEPARTURES ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
        hiddenSearch: true,
      },
      {
        title: 'Radius Meters',
        dataIndex: ADMIN_GEOZONES_TABLE_COLUMNS.RADIUS,
        defaultSortOrder: field === ADMIN_GEOZONES_TABLE_COLUMNS.RADIUS ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        render: (_, { radius }) => <>{Boolean(radius) ? radius : '-'}</>,
        width: 180,
        hiddenSearch: true,
      },
      {
        title: 'Geozone ID',
        dataIndex: ADMIN_GEOZONES_TABLE_COLUMNS.GEOZONE_ID,
        defaultSortOrder: field === ADMIN_GEOZONES_TABLE_COLUMNS.GEOZONE_ID ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
      },
      {
        title: 'Active',
        dataIndex: ADMIN_GEOZONES_TABLE_COLUMNS.ACTIVE,
        defaultSortOrder: field === ADMIN_GEOZONES_TABLE_COLUMNS.ACTIVE ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
        hiddenSearch: true,
      },
      {
        title: 'Created By',
        dataIndex: ADMIN_GEOZONES_TABLE_COLUMNS.CREATED_BY,
        width: 180,
        hiddenSearch: true,
        className: 'ant-table-cell--no-filter',
      },
      {
        title: 'Time Created',
        dataIndex: ADMIN_GEOZONES_TABLE_COLUMNS.CREATION_TIME,
        width: 180,
        hiddenSearch: true,
        className: 'ant-table-cell--no-filter',
      },
      {
        title: 'Modified By',
        dataIndex: ADMIN_GEOZONES_TABLE_COLUMNS.UPDATED_BY,
        width: 180,
        hiddenSearch: true,
        className: 'ant-table-cell--no-filter',
      },
      {
        title: 'Last Modified',
        dataIndex: ADMIN_GEOZONES_TABLE_COLUMNS.LAST_UPDATE_TIME,
        width: 180,
        hiddenSearch: true,
        className: 'ant-table-cell--no-filter',
      },
    ];

    const filteredColumns = [];

    columns.forEach((column) => {
      if (column.isSelected) {
        filteredColumns.push(allColumns.find((col) => col.dataIndex === column.value));
      }
    });

    return [
      ...filteredColumns,
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: (_, geozone) => {
          const { id } = geozone;
          const actions: Array<{ text: string; onClick?: () => void; link?: string; hidden?: boolean }> = [];
          const canRead = validateAccessLevel([ADMIN_GEOZONES_READ]);
          const canEdit = validateAccessLevel([ADMIN_GEOZONES_UPDATE]);
          const canDelete = validateAccessLevel([ADMIN_GEOZONES_DELETE]);

          if (canRead) {
            actions.push({
              text: canEdit ? 'Edit Geozone' : 'View Geozone',
              link: PATHS.ADMIN.TABLES.GEOZONES.GEOZONE.replace(':id', String(id)),
            });
          }

          if (canDelete) {
            actions.push({ text: 'Delete Geozone', onClick: () => this.handleDeleteGeozoneClick(geozone) });
          }

          actions.splice(1, 0, {
            text: 'View on Map',
            link: PATHS.MAP.GEOZONE.replace(':geozoneId', String(id)),
            hidden: geozone.hideOnMaps || !geozone.isActive,
          });

          return <TableActions items={actions} />;
        },
        className: 'ant-table-cell--actions',
        width: 180,
      },
    ];
  };

  get isShowHeader() {
    const {
      geozonesAdmin: { hasActiveSearch, geozonesListTotal, isSearchChanged },
    } = this.props;

    return isSearchChanged || hasActiveSearch || Boolean(geozonesListTotal);
  }

  handleOpenFilter = (isFilterOpen: boolean) => {
    this.setState({ isFilterOpen });
    setJSONItemToStorage(STORAGE_ITEMS.admin.filterOpenStatus, isFilterOpen);
  };

  setDeletedGeozoneId = (geozoneToDelete: ITableGeozone, callback?: () => void) =>
    this.setState({ geozoneToDelete }, callback);

  handleOpenCreateGeozoneModal = () => this.setState({ createGeozone: true });

  handleCloseCreateGeozoneModal = () => this.setState({ createGeozone: false });

  handleOpenDeleteGeozoneModal = () => this.setState({ deleteGeozone: true });

  handleCloseDeleteGeozoneModal = () => {
    this.setState({ deleteGeozone: false });
    this.setDeletedGeozoneId(null);
  };

  handleDeleteGeozoneClick = (geozoneToDelete: ITableGeozone) => {
    this.setDeletedGeozoneId(geozoneToDelete, this.handleOpenDeleteGeozoneModal);
  };

  closeDownloadModal = () => {
    this.setState({ isDownloadModalOpen: false });
  };

  openDownloadModal = () => {
    this.setState({ isDownloadModalOpen: true });
  };

  closeEmailModal = () => {
    this.setState({ isEmailModalOpen: false });
  };

  openEmailModal = () => {
    this.setState({ isEmailModalOpen: true });
  };

  render() {
    const {
      className,
      geozonesAdmin: {
        columns,
        deleteGeozoneRequestStatus,
        geozonesListTotal,
        getGeozonesList,
        repositoryGeozone,
        pagination,
        searchColumns,
        searchData,
        setColumns,
        setPagination,
        setSearchColumn,
        setSortedColumn,
        sortData,
        tableSource,
        downloadGeozones,
      },
    } = this.props;
    const { isFilterOpen, deleteGeozone, geozoneToDelete, createGeozone, isDownloadModalOpen, isEmailModalOpen } =
      this.state;
    const cn = classNames('AdminGeozonesTable', className, {
      'AdminGeozonesTable--withOpenFilters': isFilterOpen,
      'AdminGeozonesTable--withAddGeozone': validateAccessLevel([MAP_CREATE_GEOZONE_CREATE]),
    });
    const tableHeight = isFilterOpen ? 'calc(100vh - 280px)' : 'calc(100vh - 251px)';

    return (
      <AdminContentWrapper
        className="AdminContentWrapper--geozonesTable"
        message="Add, edit, and delete Geofences as well as define the visibility settings and other details. "
        title="Geozone Admin"
      >
        {
          <div className={cn}>
            <UserAccessLevelComponent requiredAccessLevel={[MAP_CREATE_GEOZONE_CREATE]}>
              <div className="AdminGeozonesTable-addGeozone">
                <Button
                  className="Button--apply"
                  inline
                  onClick={this.handleOpenCreateGeozoneModal}
                  title="Create Geozone"
                  type="button"
                />
              </div>
            </UserAccessLevelComponent>
            <div className="AdminGeozonesTable-table">
              <CustomTable<ADMIN_GEOZONES_TABLE_COLUMNS, Geozone>
                columns={this.isShowHeader ? this.getColumns() : []}
                dataSource={tableSource}
                editColumnsMap={editColumnsMap}
                getData={getGeozonesList}
                isFilterActive={isFilterOpen}
                loading={repositoryGeozone.getState.loading}
                onColumnsChange={setColumns}
                onColumnSort={setSortedColumn}
                onColumnsSearch={setSearchColumn}
                triggerUpdate={deleteGeozoneRequestStatus.success}
                onFilterOpen={this.handleOpenFilter}
                onPaginationChange={setPagination}
                pagination={pagination}
                searchColumns={searchColumns}
                searchParams={searchData}
                selectedColumns={toJS(columns)}
                sortedColumn={sortData}
                tableHeight={tableHeight}
                total={geozonesListTotal}
                totalTitle={geozonesListTotal ? `${geozonesListTotal} Geozones` : ''}
                emptyText={
                  this.isShowHeader ? (
                    'No Results Found'
                  ) : (
                    <div className="AdminGeozonesTableHeader-noResults">
                      Geozones will appear here.
                      <UserAccessLevelComponent requiredAccessLevel={[MAP_CREATE_GEOZONE_CREATE]}>
                        <span className="AdminGeozonesTableHeader-noResults--create">
                          Click{' '}
                          <Button
                            title="Create Geozone"
                            className="Button--link"
                            onClick={this.handleOpenCreateGeozoneModal}
                          />{' '}
                          to get started.
                        </span>
                      </UserAccessLevelComponent>
                    </div>
                  )
                }
                showHeader={this.isShowHeader}
                withActions={this.isShowHeader}
                withFilter={this.isShowHeader}
                withDownload={this.isShowHeader}
                withEmail={this.isShowHeader}
                openDownloadModal={this.openDownloadModal}
                openEmailModal={this.openEmailModal}
              />
            </div>
            <DeleteGeozoneModal
              geozoneId={geozoneToDelete && String(geozoneToDelete.id)}
              geozoneName={geozoneToDelete && geozoneToDelete[ADMIN_GEOZONES_TABLE_COLUMNS.NAME]}
              isOpen={deleteGeozone}
              onCancel={this.handleCloseDeleteGeozoneModal}
              onSuccess={this.handleCloseDeleteGeozoneModal}
            />
            <CreateGeozone onCreate={() => true} onCancel={this.handleCloseCreateGeozoneModal} isOpen={createGeozone} />
            <TableDownloadModal<GeozonesAdmin>
              isOpen={isDownloadModalOpen}
              onClose={this.closeDownloadModal}
              tableDownload={downloadGeozones}
            />
            <TableEmailModal<GeozonesAdmin>
              isOpen={isEmailModalOpen}
              onClose={this.closeEmailModal}
              tableDownload={downloadGeozones}
            />
          </div>
        }
      </AdminContentWrapper>
    );
  }
}

export default AdminGeozonesTable;
