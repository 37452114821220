import React, { Component, createRef } from 'react';
import classNames from 'classnames';
import { withScriptjs } from 'react-google-maps';
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import type SearchBox from 'react-google-maps/lib/components/places/SearchBox';
import type { GoogleMapProps } from 'react-google-maps/lib/components/GoogleMap';
import isValidCoords, { getValidCoords } from 'is-valid-coords';

import { stripHtml, getFormattedSelectedAddress } from 'utils';
import type { ISearchResults } from 'stores/Map/GoogleMapSearchStore';

import { CloseIcon, LocationPinIcon } from 'assets';
import './styles.scss';

interface IProps extends GoogleMapProps {
  className?: string;
  disabled?: boolean;
  error?: string;
  icon?: React.ReactNode;
  label?: string;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (place: ISearchResults) => void;
  onError?: (error: string) => void;
  onSearchChange?: (value: string) => void;
  placeholder?: string;
  searchValue?: string;
  selectedAddress?: ISearchResults;
  touched?: boolean;
  validate?: boolean;
  withIcon?: boolean;
  mapBounds?: google.maps.LatLngBounds;
  required?: boolean;
}

interface IState {
  searchValue: string;
  selectedAddress: ISearchResults;
  touched: boolean;
}

export const emptyLocation = {
  address: undefined,
  latitude: undefined,
  longitude: undefined,
  meta: {
    additionalAddress: undefined,
    hours: undefined,
    website: undefined,
    phone: undefined,
    category: undefined,
    type: undefined,
  },
};

class GoogleMapsLocationSearch extends Component<IProps, IState> {
  searchBox: SearchBox;
  searchInput: React.RefObject<HTMLInputElement>;

  static defaultProps = {
    onBlur: () => true,
    onChange: () => true,
    onSearchChange: () => true,
    placeholder: 'Search Address',
    searchValue: '',
    touched: false,
    validate: true,
    withIcon: false,
    required: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchValue: props.searchValue || '',
      selectedAddress: props.selectedAddress || emptyLocation,
      touched: props.touched,
    };

    this.searchInput = createRef();
  }

  setTouched = () => this.setState({ touched: true });

  setSearchBoxRefs = (refs) => (this.searchBox = refs);

  setCoordinates = (searchValue: string) => {
    const { onChange } = this.props;
    const [latitude, longitude] = getValidCoords(searchValue);
    const selectedAddress: ISearchResults = {
      address: undefined,
      latitude,
      longitude,
      meta: {
        additionalAddress: undefined,
        hours: undefined,
        website: undefined,
        phone: undefined,
        category: undefined,
        type: 'coordinates',
      },
    };

    this.setState({ selectedAddress });
    onChange(selectedAddress);
  };

  handlePlaceChange = () => {
    const { searchValue } = this.state;
    const { onChange } = this.props;
    const searchResults = this.searchBox.getPlaces();

    if (!searchResults.length) {
      this.resetSearch(true);
      return;
    }

    const selectedAddress: ISearchResults = getFormattedSelectedAddress(searchResults[0]);
    const { address, latitude, longitude } = selectedAddress;

    this.setState({
      searchValue: isValidCoords(searchValue) ? searchValue : address ? address : `${latitude}, ${longitude}`,
      selectedAddress,
    });
    onChange(selectedAddress);
  };

  handleChange = (e) => {
    const { onSearchChange } = this.props;
    const searchValue = e.target.value;

    this.setState({ searchValue }, () => {
      onSearchChange(searchValue);
      if (!isValidCoords(searchValue)) {
        this.resetSearch(true);
      } else {
        this.setCoordinates(searchValue);
      }
    });
  };

  handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onBlur } = this.props;

    this.setTouched();
    onBlur(e);
  };

  resetSearch = (saveQuery?: boolean) => {
    const { onChange } = this.props;

    this.setState({ searchValue: saveQuery ? this.state.searchValue : '', selectedAddress: emptyLocation });
    onChange(emptyLocation);
  };

  disableAutoComplete() {
    if (this.searchInput.current) {
      // this is needed because StandaloneSearchBox replaces autocomplete to off, and it is not valid value for input
      // it should be hidden
      if (this.searchInput.current.getAttribute('autocomplete') === 'off') {
        this.searchInput.current.setAttribute('autocomplete', 'hidden');
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const searchValue = this.props.searchValue;
    this.disableAutoComplete();

    if (prevProps.searchValue !== searchValue) {
      this.setState({
        searchValue,
      });

      if (isValidCoords(searchValue)) {
        this.setCoordinates(searchValue);
      }
    }
  }

  render() {
    const { className, placeholder, disabled, icon, validate, withIcon, mapBounds, required } = this.props;
    const { searchValue, selectedAddress, touched } = this.state;
    const error = Boolean(
      required
        ? validate && touched && !selectedAddress.latitude
        : validate && touched && !selectedAddress.latitude && searchValue
    );
    const cn = classNames('GoogleMapsLocationSearch', className, {
      'GoogleMapsLocationSearch--error': error,
    });
    const inputCn = classNames('GoogleMapsLocationSearch-input', {
      'GoogleMapsLocationSearch-input--error': error,
      'GoogleMapsLocationSearch-input--disabled': disabled,
      'GoogleMapsLocationSearch-input--withIcon': withIcon,
    });

    return (
      <div className={cn} data-standalone-searchbox="">
        <StandaloneSearchBox ref={this.setSearchBoxRefs} onPlacesChanged={this.handlePlaceChange} bounds={mapBounds}>
          <>
            <input
              disabled={disabled}
              className={inputCn}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              placeholder={placeholder}
              ref={this.searchInput}
              value={stripHtml(searchValue)}
            />
            {withIcon && (
              <div className="GoogleMapsLocationSearch-locationIcon">
                {icon ? icon : <LocationPinIcon className="GoogleMapsLocationSearch-locationIconImg" />}
              </div>
            )}
            {error && <p className="GoogleMapsLocationSearch-error">Invalid location</p>}
            {searchValue && !disabled && (
              <div className="GoogleMapsLocationSearch-closeIcon" onClick={() => this.resetSearch()}>
                <CloseIcon className="GoogleMapsLocationSearch-closeIconImg" />
              </div>
            )}
          </>
        </StandaloneSearchBox>
      </div>
    );
  }
}

export default withScriptjs(GoogleMapsLocationSearch);
