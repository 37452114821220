import React, { useEffect, useState } from 'react';
import { ClockIcon } from 'assets';
import { getTimeFromTimestampDiff } from 'utils';

import './styles.scss';

interface IProps {
  time: number
}

const FinanceRequestModalTimer: React.FC<IProps> = ({ time }) => {
  const [timeLeft, setTimeLeft] = useState<number>(() => (time - Date.now()) / 1000 - 1);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((time - Date.now()) / 1000 - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [time]);


  return <div className="FinanceRequestModalTimer">
    <ClockIcon width={20} fill="#6B7A99"/>
    <span className="FinanceRequestModalTimer-time">{getTimeFromTimestampDiff(timeLeft)}</span>
  </div>;
};

export default FinanceRequestModalTimer;
