import React from 'react';
import { inject, observer } from 'mobx-react';

import { REPORTS_STORE } from 'config';

import type { ReportsStore } from 'stores';

import ReportsNavigation from './ReportsNavigation';
import ReportPage from './ReportPage';

import Notification from 'components/Notification';

import './styles.scss';

interface IProps {
  reportsStore?: ReportsStore;
}

@inject(REPORTS_STORE)
@observer
class Reports extends React.Component<IProps> {
  closeNotification = () => {
    const {
      reportsStore: {
        downloadReport: { repositoryEmail },
      },
    } = this.props;

    repositoryEmail.createState.reset();
  };

  render() {
    const {
      reportsStore: {
        downloadReport: { repositoryEmail },
      },
    } = this.props;

    return (
      <div className="ReportsPage">
        <div className="ReportsPage-navigation">
          <ReportsNavigation />
        </div>
        <div className="ReportsPage-content">
          <ReportPage />
        </div>
        {repositoryEmail.createState.success && (
          <Notification
            type="success"
            title="Success"
            text="Report successfully emailed"
            onClose={this.closeNotification}
          />
        )}
        {repositoryEmail.createState.error && (
          <Notification
            type="error"
            title="Failed"
            text="Report Failed to Send. Please try again"
            onClose={this.closeNotification}
          />
        )}
      </div>
    );
  }
}

export default Reports;
