import React from 'react';
import classnames from 'classnames';

import validateAccessLevel from 'stores/acl/validator';

import { WEB_MAP_FINANCE_BASIC_DELETE } from 'config';

import type { IIntegration } from 'interfaces/stores/Integrations/IIntegration';
import WEXIntegrationModel from 'stores/Integrations/WEXIntegration';

import Button from 'components/Button';
import InfoTooltip from 'components/InfoTooltip';

import './styles.scss';

interface IProps {
  className?: string;
  editElem?: React.ReactNode;
  icon: JSX.Element;
  integration: IIntegration;
  isBeta?: boolean;
  onConnect: () => void;
  onReconnect: () => void;
  onDisconnect: () => void;
  accessLevelToDisconnect?: string[];
}

const IntegrationItem: React.FC<IProps> = ({
  icon,
  integration,
  onConnect,
  onReconnect,
  onDisconnect,
  className = '',
  editElem,
  isBeta = false,
  accessLevelToDisconnect = [],
}) => {
  const isConnected = integration.status === 'CONNECTED';
  const isDisconnected = integration.status === 'DISCONNECTED';
  const isExpired = integration.status === 'EXPIRED';
  const cn = classnames('Integration-ItemList', className, {
    'Integration-ItemList--connected': isConnected || isExpired,
    'Integration-ItemList--disconnected': isDisconnected,
  });

  return (
    <div key={integration.name} className={cn}>
      <div className="Integration-Item">
        <div className="Integration-ItemHeader">
          <div className="Integration-ItemHeaderIcon">{icon}</div>
          <div className="Integration-ItemHeaderTitle">
            <div className="Integration-ItemHeaderName">{integration.name}</div>
            <div className="Integration-ItemHeaderProvider">{integration.provider}</div>
          </div>
        </div>
        {(isConnected || isExpired) && integration.lastSyncDate.value && (
          <div className="Integration-CreatedDate">
            <span className="Integration-CreatedDate--title">connected</span>
            <span className="Integration-CreatedDate--date">{integration.created.date}</span>
          </div>
        )}
        {integration.status === 'EXPIRED' ? (
          <div className="Integration-CreatedDate Integration-LastSyncDateExpired">
            <span className="Integration-CreatedDate--title Integration-CreatedDate--title">expired</span>
            <span className="Integration-CreatedDate--date">
              {integration.created.value ? integration.created.date : '-'}
            </span>
            <InfoTooltip className="Integration-LastSyncDateExpiredTooltip">
              This integration has expired. The data from this integration will remain in the system, but will not be
              updated until reconnected. Reconnect to continue updating data, or Remove to remove integration data.
            </InfoTooltip>
          </div>
        ) : null}
        {isConnected && integration instanceof WEXIntegrationModel && integration.accountName && (
          <div className="Integration-AccountName">
            <span className="Integration-AccountNameTitle">Account</span>
            <span className="Integration-AccountNameValue">{integration.accountName}</span>
          </div>
        )}
        {isConnected && editElem ? <div className="Integration-Edit">{editElem}</div> : null}
        <div className="Integration-ItemDescription">{integration.description}</div>
        <div className="Integration-ItemAction">
          {isDisconnected && (
            <Button title="Connect" className="Integration-ItemActionButton" inline onClick={onConnect} />
          )}
          {isExpired && validateAccessLevel([WEB_MAP_FINANCE_BASIC_DELETE]) && (
            <Button title="Remove" className="Integration-ItemActionButton--link" inline onClick={onDisconnect} />
          )}
          {isExpired && (
            <Button title="Reconnect" className="Integration-ItemActionButton" inline onClick={onReconnect} />
          )}
          {isConnected && validateAccessLevel(accessLevelToDisconnect) && (
            <Button
              title="Disconnect"
              className="Integration-ItemActionButton Integration-ItemActionButton--grey"
              inline
              onClick={onDisconnect}
            />
          )}
        </div>
      </div>
      {isBeta && <span className="Integration-BetaLabel">Beta</span>}
    </div>
  );
};

export default IntegrationItem;
