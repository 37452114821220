import React from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  text: React.ReactNode | string;
  className?: string;
}

const TableUnSelected: React.FC<IProps> = ({ text, className }) => {
  const cn = classNames('TableUnSelected-container', className);

  return (
    <div className={cn}>
      <div className="TableUnSelected-info">
        <div className="TableUnSelected-avatar" />
        {text}
      </div>
    </div>
  );
};

export default TableUnSelected;
