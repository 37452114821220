import React, { FC, useState, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';

import { geozoneColors } from 'stores/Admin/Geozones';
import { validateGeozoneName } from 'utils';

import ConfirmationModal from 'components/Admin/ConfirmationModal';
import Input from 'components/Input';
import ColorPicker from '../ColorPicker';
import GeozoneTypeRadio from '../GeozoneTypeRadio';
import GoogleMapsLocationSearch from 'components/GoogleMapsLocationSearch';

import { CircleIcon, ShapeIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  geozoneColor: Geozone.IGeozoneColor;
  geozoneId: string | number;
  geozoneLocation: { address?: string; latitude?: number; longitude?: number };
  geozoneName: string;
  geozoneType: string;
  isDisabled?: boolean;
  isOpen: boolean;
  locationTouched?: boolean;
  modal?: 'create' | 'edit';
  onCancel: () => void;
  onSubmit: (geozone: any) => void;
}

const CreateGeozoneModal: FC<IProps> = ({
  className,
  geozoneColor,
  geozoneLocation,
  geozoneName = '',
  geozoneType,
  isDisabled = true,
  isOpen,
  locationTouched = false,
  modal = 'create',
  onCancel = () => true,
  onSubmit = () => true,
}) => {
  const cn = classNames('CreateGeozoneModal', className);
  const [nameTouched, setNameTouched] = useState(false);
  const [name, setName] = useState(geozoneName);
  const [location, setLocation] = useState(geozoneLocation);
  const [type, setType] = useState(geozoneType);
  const [color, setColor] = useState(geozoneColor);
  const initialLocationSearchQuery = useMemo(() => {
    let locationSearchQuery = '';

    if (geozoneLocation.address) {
      locationSearchQuery = geozoneLocation.address;
    } else if (geozoneLocation.latitude && geozoneLocation.longitude) {
      locationSearchQuery = `${geozoneLocation.latitude}, ${geozoneLocation.longitude}`;
    }

    return locationSearchQuery;
  }, [geozoneLocation]);
  const setGeozoneState = useCallback(() => {
    setName(geozoneName);
    setLocation(geozoneLocation);
    setType(geozoneType);
    setColor(geozoneColor);
  }, [geozoneColor, geozoneLocation, geozoneName, geozoneType]);
  const handleNameChange = useCallback((e) => setName(e.target.value), []);
  const handleLocationChange = useCallback((location) => setLocation(location), []);
  const handleTagChange = useCallback((type) => setType(type), []);
  const handleColorChange = useCallback((color) => setColor(color), []);
  const handleCreateGeozone = () => {
    onSubmit({ name, location, type, color });
  };
  const handleCancelCreateGeozone = () => {
    onCancel();
    setGeozoneState();
  };
  const handleNameBlur = useCallback(() => {
    setNameTouched(true);
  }, []);

  useEffect(() => {
    /* reset form state to initial on modal closing */
    if (!isOpen) {
      setGeozoneState();
    }
  }, [isOpen, setGeozoneState]);

  return (
    <ConfirmationModal
      closeOutside={false}
      applyButtonTitle={modal === 'create' ? 'Create' : 'Save'}
      cancelButtonStyles={{ border: 'none' }}
      isOpen={isOpen}
      isSubmitDisabled={!validateGeozoneName(name) || !location.latitude || isDisabled}
      onCancel={handleCancelCreateGeozone}
      onSubmit={handleCreateGeozone}
      title={`${modal === 'create' ? 'Create New' : 'Edit'} Geozone`}
    >
      <div className={cn}>
        <div className="CreateGeozoneModal-section CreateGeozoneModal-section--name">
          <p className="CreateGeozoneModal-sectionLabel">Name</p>
          <div className="CreateGeozoneModal-sectionContent">
            <Input
              value={name}
              placeholder="Geozone Name"
              onChange={handleNameChange}
              onBlur={handleNameBlur}
              size="middle"
              maxLength={40}
              error={nameTouched && !validateGeozoneName(name) ? 'Invalid name' : ''}
            />
          </div>
        </div>
        <div className="CreateGeozoneModal-section CreateGeozoneModal-section--location">
          <p className="CreateGeozoneModal-sectionLabel">
            Starting
            <br />
            Location
          </p>
          <div className="CreateGeozoneModal-sectionContent">
            <GoogleMapsLocationSearch
              googleMapURL={process.env.REACT_APP_GOOGLE_MAP_URL}
              loadingElement={<div style={{ height: `100%` }} />}
              onChange={handleLocationChange}
              placeholder="Address/City/Zip/Lat, Long"
              searchValue={initialLocationSearchQuery}
              selectedAddress={location}
              touched={locationTouched}
            />
          </div>
        </div>
        <div className="CreateGeozoneModal-section CreateGeozoneModal-section--shapeColor">
          <p className="CreateGeozoneModal-sectionLabel">Shape/Color</p>
          <div className="CreateGeozoneModal-sectionContent CreateGeozoneModal-sectionContent--shapeColor">
            <div className="CreateGeozoneModal-shape">
              <GeozoneTypeRadio
                activeType={type}
                onChange={handleTagChange}
                types={[
                  {
                    id: modal + 'polygon',
                    label: 'Polygon',
                    name: modal + 'geozone-shape',
                    value: 'polygon',
                    Icon: ShapeIcon,
                  },
                  {
                    id: modal + 'circle',
                    label: 'Circle',
                    name: modal + 'geozone-shape',
                    value: 'circle',
                    Icon: CircleIcon,
                  },
                ]}
              />
            </div>
            <div className="CreateGeozoneModal-color">
              <ColorPicker
                activeColor={color || geozoneColors.find(({ id }) => id === 3)}
                colors={geozoneColors}
                onChange={handleColorChange}
                size="small"
              />
            </div>
          </div>
        </div>
      </div>
    </ConfirmationModal>
  );
};

export default CreateGeozoneModal;
