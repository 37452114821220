import { REFRESH_TOKEN_COOKIE_NAME, TOKEN_COOKIE_NAME, USER_ACCOUNT_ID, USER_ID } from 'config/cookie';
import { getCookie, setCookie } from './storage';
import axios, { AxiosResponse } from 'axios';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';
import { getBrowserInfo } from './browser';

class TokenManager {
  public refresh = async (onSuccess?) => {
    try {
      const refreshToken = getCookie(REFRESH_TOKEN_COOKIE_NAME);

      if (!refreshToken) {
        EventsBus.get().trigger(APP_EVENTS.SESSION_EXPIRED);
        return;
      }

      const res = await axios(this.requestBody(refreshToken));

      if (res.data && res.data.token) {
        EventsBus.get().trigger(APP_EVENTS.UPDATE_TOKEN, res.data);

        if (onSuccess) {
          onSuccess(res.data);
        }

        return res.data;
      } else {
        EventsBus.get().trigger(APP_EVENTS.SESSION_EXPIRED);
      }
    } catch (e) {
      EventsBus.get().trigger(APP_EVENTS.SESSION_EXPIRED);
    }
  };

  public getSBToken = async () => {
    const res: AxiosResponse<{ Data: { TargetUrl: string } }> = await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_SB_API_HOST}/appswitcher/GetOneTimePassword`,
      data: {
        token: getCookie(TOKEN_COOKIE_NAME),
        refreshToken: getCookie(REFRESH_TOKEN_COOKIE_NAME),
      },
    });

    return res.data.Data.TargetUrl;
  };

  public opt = async (otp: string): Promise<string> => {
    const res = await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_HOST}/v1.0/auth/token/otp/exchange`,
      data: {
        otp,
      },
    });

    if (res.data && res.data.token && res.data.refreshToken) {
      setCookie(TOKEN_COOKIE_NAME, res.data.token, { expires: 30 });
      setCookie(REFRESH_TOKEN_COOKIE_NAME, res.data.refreshToken, { expires: 30 });
      setCookie(USER_ACCOUNT_ID, res.data.accountIdCP || '', { expires: 30 });
      setCookie(USER_ID, res.data.userIdCP || '', { expires: 30 });
      return res.data.token;
    }
  };

  private requestBody = (refreshToken) => {
    const accountId = getCookie(USER_ACCOUNT_ID);
    const userId = getCookie(USER_ID);

    const security = accountId
      ? { 'X-Security-Account-ID': accountId, 'X-Security-App-Name': 'web' }
      : { 'X-Security-App-Name': 'web' };
    const clientAppInfo = { 'Client-App-Info': getBrowserInfo() };

    return {
      headers: {
        ...security,
        ...clientAppInfo,
      },
      method: 'POST',
      url: `${process.env.REACT_APP_API_HOST}/v1.0/auth/refresh`,
      data: {
        refreshTokenString: refreshToken,
        accountId,
        userIdCp: userId,
      },
    };
  };
}

export const tokenManager = new TokenManager();
