import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';
import type { Maintenances } from 'stores';
import { SimpleField } from '../Fields';

export default class MaintenanceAccountConfig {
  dueSoon: SimpleField<number>;
  repository: IEntityRepository;
  context: Maintenances;

  constructor(context: Maintenances) {
    this.dueSoon = new SimpleField(14);
    this.repository = repositoryService.get('maintenances').entity('config');
    this.context = context;
  }

  fetch = async () => {
    const response = await this.repository.get();
    const value = response?.dueSoon;

    if (value) {
      this.dueSoon.set(value);
      this.dueSoon.reInitialize(value);
    }
  };

  update = async () => {
    const response = await this.repository.patch(this.data);
    const value = response?.dueSoon;

    if (this.repository.patchState.success) {
      this.context.isUpdate.toggle(true);
      this.dueSoon.reInitialize(value);
    }

    if (this.repository.patchState.error) {
      this.dueSoon.reset();
    }
  };

  get data() {
    return {
      dueSoon: this.dueSoon.value,
    };
  }
}
