import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { ChevronIcon, DownloadIcon, MoreIcon } from 'assets';
import { MEDIA_CLIP_TYPE, PATHS, DASHCAM_REQUEST_MEDIA, KEY_TYPE, MEDIA_CLIP_STATUS } from 'config';
import type { MapStore } from 'stores';

import UserAccessLevelComponent from 'stores/acl/UserAccessLevelComponent';
import type MediaClipsItem from 'models/Dashcam/MediaLibrary/MediaClips/MediaClipsItem';

import Button from 'components/Button';
import Modal from 'components/Modal';
import MediaLibrarySpeedChart from './MediaLibrarySpeedChart';
import MediaLibraryMap from './MediaLibraryMap';
import MediaLibraryPlayer from './MediaLibraryPlayer';
import MediaLibraryVideoInfo from './MediaLibraryVideoInfo';
import MediaLibrarySlider from './MediaLibrarySlider';
import MediaLibraryClipRename from '../MediaLibraryClipRename';

import './styles.scss';

interface IProps {
  clip: MediaClipsItem;
  clips: MediaClipsItem[];
  map: MapStore;
}

const MediaLibraryModal: React.FC<IProps> = ({ clip, clips, map }) => {
  const [activeClip, setActiveClip] = useState(clip);
  const [isModalRenameOpen, setModelRenameOpen] = useState(false);

  useEffect(() => {
    return () => {
      pause();
    };
  }, []);

  const changeClip = (clip) => {
    pause();

    setTimeout(() => setActiveClip(clip), 111);
  };

  const play = () => {
    activeClip.media.front?.video.isPlaying.toggle(true);
    activeClip.media.faceVideo?.video.isPlaying.toggle(true);
  };

  const pause = () => {
    activeClip.media.front?.video.isPlaying.toggle(false);
    activeClip.media.faceVideo?.video.isPlaying.toggle(false);
  };

  const seekFaceVideo = (value) => {
    activeClip.media.faceVideo?.video.seekTime.set(value.toFixed(1));
  };

  const seekFrontVideo = (value) => {
    activeClip.media.front?.video.seekTime.set(value.toFixed(1));
  };

  const closePlayerOnEsc = (event) => {
    if (event.key === KEY_TYPE.ESCAPE) {
      clip.interaction.reset();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', closePlayerOnEsc);

    return () => {
      window.removeEventListener('keyup', closePlayerOnEsc);
    };
  }, []);

  return (
    <Observer
      render={() => {
        return (
          <div className="MediaLibraryModal">
            <div className="MediaLibraryModal-header">
              <div className="MediaLibraryModal-headerDate">{activeClip.date.main}</div>
              <div className="MediaLibraryModal-headerSlider">
                <MediaLibrarySlider
                  onChange={changeClip}
                  clips={clips.filter((clip) => clip.media.front.status.value === MEDIA_CLIP_STATUS.AVAILABLE)}
                  active={activeClip}
                />
              </div>
            </div>
            <div className="MediaLibraryModal-content">
              <div className="MediaLibraryModal-contentLeft">
                {activeClip.media.front.type === MEDIA_CLIP_TYPE.VIDEO ? (
                  <div className="MediaLibraryModal-mainVideo">
                    <MediaLibraryPlayer
                      video={activeClip.media.front.video}
                      play={play}
                      pause={pause}
                      seek={seekFaceVideo}
                      mainVideo
                    />
                  </div>
                ) : (
                  <div className="MediaLibraryModal-mainImage">
                    <img src={activeClip.media.front?.video.url} />
                  </div>
                )}

                <div className="MediaLibraryModal-mainInfo">
                  <div className="MediaLibraryModal-mainInfoDescription">
                    <MediaLibraryVideoInfo clip={activeClip} />
                  </div>
                  <div className="MediaLibraryModal-mainInfoAction">
                    {!activeClip.media.front.mediaFile.downloading.value ? (
                      <div className="MediaLibraryModal-mainInfoActionDownload">
                        <DownloadIcon onClick={activeClip.media.front.mediaFile.download} />
                      </div>
                    ) : (
                      <div className="MediaLibraryModal-mainInfoActionDownloadProgress">
                        <div className="MediaLibraryModal-mainInfoActionDownloadProgressCircle">
                          <CircularProgressbar
                            value={activeClip.media.front.mediaFile.downloadingPercent}
                            styles={buildStyles({
                              pathColor: '#ff8801',
                              trailColor: '#2A3344',
                            })}
                          />
                        </div>
                        <p className="MediaLibraryModal-mainInfoActionDownloadProgressText">
                          {activeClip.media.front.mediaFile.downloadingPercent}%
                        </p>
                      </div>
                    )}
                    <div className="MediaLibraryModal-mainInfoActionMenu">
                      <Dropdown
                        trigger={['click']}
                        overlay={
                          <Menu className="MediaLibraryModal-detailsActionList">
                            <Menu.Item
                              className="MediaLibraryModal-detailsActionListItem"
                              key="renameClip"
                              onClick={() => setModelRenameOpen(true)}
                            >
                              Rename Clip
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <MoreIcon className="MediaLibraryModal-detailsActionTrigger" />
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="MediaLibraryModal-chart">
                  {activeClip.media.front.gps.route.length > 1 ? (
                    <div className="MediaLibraryModal-chartWrapper">
                      <div className="MediaLibraryModal-chartTitle">SPEED</div>
                      <div className="MediaLibraryModal-chartContainer">
                        <MediaLibrarySpeedChart
                          key={activeClip.date.value}
                          chartData={activeClip.media.front.gps.chart}
                          currentSecond={activeClip.media.front.video.progressSecond}
                          scaleLimit={16}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="MediaLibraryModal-contentRight">
                {activeClip.media.front.type === MEDIA_CLIP_TYPE.VIDEO && activeClip.media.faceVideo?.video.url ? (
                  <div className="MediaLibraryModal-smallVideo">
                    <MediaLibraryPlayer
                      video={activeClip.media.faceVideo?.video}
                      play={play}
                      pause={pause}
                      seek={seekFrontVideo}
                    />
                  </div>
                ) : (
                  activeClip.media?.faceImage &&
                  activeClip.media?.faceImage?.video.url && (
                    <div className="MediaLibraryModal-smallImage">
                      <img src={activeClip.media.faceImage.video.url} />
                    </div>
                  )
                )}
                <div className="MediaLibraryModal-map">
                  <MediaLibraryMap
                    map={map}
                    gps={activeClip.media.front.gps}
                    location={activeClip.media.front.location}
                    progressSecond={activeClip.media.front.video.progressSecond}
                  />
                </div>
                <UserAccessLevelComponent requiredAccessLevel={[DASHCAM_REQUEST_MEDIA]}>
                  <div className="MediaLibraryModal-requestButton">
                    {activeClip.media.front.type === MEDIA_CLIP_TYPE.IMAGE ? (
                      <NavLink
                        to={`${PATHS.DASHCAMS.MEDIA_REQUEST.STEP_1}?assetId=${activeClip.assetId}&timestamp=${activeClip.date.value}`}
                      >
                        <Button title="Request more media" className="MediaLibraryModal-requestButtonTitle" />
                      </NavLink>
                    ) : (
                      <Dropdown
                        trigger={['click']}
                        overlay={
                          <Menu className="MediaLibraryModal-requestButtonActionList">
                            <Menu.Item className="MediaLibraryModal-requestButtonActionListItem" key="endOfClip">
                              <NavLink
                                to={`${PATHS.DASHCAMS.MEDIA_REQUEST.STEP_1}?assetId=${activeClip.assetId}&timestamp=${
                                  activeClip.date.value + activeClip.media.front?.duration.active.value * 1000
                                }`}
                              >
                                From End of Clip
                              </NavLink>
                            </Menu.Item>
                            <Menu.Item
                              className="MediaLibraryModal-requestButtonActionListItem"
                              key="playheadTimestamp"
                            >
                              <NavLink
                                to={`${PATHS.DASHCAMS.MEDIA_REQUEST.STEP_1}?assetId=${activeClip.assetId}&timestamp=${
                                  activeClip.date.value + activeClip.media.front?.video.progressSecond.value * 1000
                                }`}
                              >
                                From Playhead Timestamp
                              </NavLink>
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <Button
                          title="Request more media"
                          className="MediaLibraryModal-requestButtonTitle"
                          IconComponent={<ChevronIcon height={12} width={7} />}
                        />
                      </Dropdown>
                    )}
                  </div>
                </UserAccessLevelComponent>
              </div>
            </div>
            <Modal
              className="MediaLibraryClip-rename"
              isModalOpen={isModalRenameOpen}
              onClose={() => setModelRenameOpen(false)}
              width={392}
            >
              <MediaLibraryClipRename onClose={() => setModelRenameOpen(false)} info={activeClip.media.front} />
            </Modal>
          </div>
        );
      }}
    />
  );
};

export default MediaLibraryModal;
