import React, { useCallback } from 'react';

import type ScheduleModel from 'models/Alerts/Schedule';

import AlertPreferenceFinalizeSimpleLine from '../AlertPreferenceFinalizeSimpleLine';

interface IProps {
  label?: string;
  schedule: ScheduleModel;
  scheduleOverride: ScheduleModel;
  option: boolean;
}

const AlertPreferenceFinalizeWorkHoursLine: React.FC<IProps> = ({ label, schedule, scheduleOverride, option }) => {
  const capitalizeFirstLetter = useCallback((str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }, []);

  const getValue = useCallback((customSchedule) => {
    return (
      customSchedule.weekdays
        .toArray()
        .map((str) => capitalizeFirstLetter(str.toLowerCase()))
        .join(', ') + ` ${customSchedule.from.time.value.toUpperCase()} - ${customSchedule.to.time.value.toUpperCase()}`
    );
  }, []);

  const value = getValue(schedule);
  const valueOverride = getValue(scheduleOverride);

  return (
    <>
      <AlertPreferenceFinalizeSimpleLine label={label} value={value} />
      {option && <AlertPreferenceFinalizeSimpleLine value={valueOverride} />}
    </>
  );
};

export default AlertPreferenceFinalizeWorkHoursLine;
