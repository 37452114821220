import { observable } from 'mobx';
import { ALERTS_TYPE } from 'config';
import AlertWithIntervalModel from './AlertWithInterval';
import { ToggleField } from 'models/Fields';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';

class IgnitionAlertModel extends AlertWithIntervalModel {
  @observable ignitionOn: ToggleField;

  constructor(item) {
    super({
      ...item,
      isIntervalActive: isNumber(item.interval) ? Boolean(item.interval) : false,
      interval: isNumber(item.interval) ? item.interval : 5 * 60 * 1000,
    });

    this.ignitionOn = new ToggleField(isBoolean(item.ignitionOn) ? item.ignitionOn : true);
    this.urlType = 'ignition';
    this.description =
      'Ignition can be detected by the following devices: OBD Plug-Ins, Hardwired or Asset Trackers that are wired to ignition.';
    this.type.reInitialize(ALERTS_TYPE.IGNITION);
  }

  get model() {
    return { ...super.model, ignitionOn: this.ignitionOn.value };
  }

  isUpdated(): boolean {
    return super.isUpdated() || this.ignitionOn.isUpdated;
  }
}

export default IgnitionAlertModel;
