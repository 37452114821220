import React from 'react';

import { DATE_TIME_FORMATS } from 'config';
import timeStore from 'stores/TimeStore';
import { getFormattedTime } from 'utils';

import './styles.scss';

interface IProps {
  completedOn: number;
  completedBy: string;
  modifiedOn: number;
  modifiedBy: string;
}

const MaintenanceCompletedTaskPanelLoggerInfo: React.FC<IProps> = ({
  completedOn,
  completedBy,
  modifiedOn,
  modifiedBy,
}) => {
  const completedOnDisplay = getFormattedTime(
    completedOn,
    DATE_TIME_FORMATS.monthDatYearFull,
    timeStore.sessionTimezone
  );
  const modifiedOnDisplay = getFormattedTime(modifiedOn, DATE_TIME_FORMATS.monthDatYearFull, timeStore.sessionTimezone);

  return (
    <div className="MaintenanceCompletedTaskPanelLoggerInfo">
      {Boolean(completedBy) && (
        <div className="MaintenanceCompletedTaskPanelLoggerInfo-Completed">
          <span className="MaintenanceCompletedTaskPanelLoggerInfo-CompletedBy">
            Marked as Completed by: {completedBy}
          </span>
          {Boolean(completedOn) && (
            <span className="MaintenanceCompletedTaskPanelLoggerInfo-CompletedOn">on {completedOnDisplay}</span>
          )}
        </div>
      )}
      {Boolean(modifiedBy) && (
        <div className="MaintenanceCompletedTaskPanelLoggerInfo-Modified">
          <span className="MaintenanceCompletedTaskPanelLoggerInfo-ModifiedBy">Modified by: {modifiedBy}</span>
          {Boolean(modifiedOn) && (
            <span className="MaintenanceCompletedTaskPanelLoggerInfo-ModifiedOn">on {modifiedOnDisplay}</span>
          )}
        </div>
      )}
    </div>
  );
};

export default MaintenanceCompletedTaskPanelLoggerInfo;
