import { notification } from 'antd';

export const open = (key: string, text: string) => {
  notification.open({
    message: text,
    className: 'AdminVehicleDetails-message',
    duration: 0,
    placement: 'bottomRight',
    key,
  });
};

export const close = (key: string) => {
  notification.close(key);
};
