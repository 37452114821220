import React from 'react';
import { observer } from 'mobx-react';
import InfoTooltip from 'components/InfoTooltip';
import SearchableSelect from 'components/Select/SearchableSelect';
import { repositoryService } from 'services';
import type { IScheduledReportInterval } from 'models/ScheduledReport/IScheduledReport';

import './styles.scss';

interface IProps {
  interval: IScheduledReportInterval;
}

const repositoryScheduledReportInterval = repositoryService
  .get('reports')
  .entity('scheduled-reports')
  .entity('intervals');

const ScheduledReportModalInterval: React.FC<IProps> = ({ interval }) => {
  const getData = () => {
    return repositoryScheduledReportInterval.get().then((data) => {
      const options = data.map((item) => {
        return {
          value: item.id,
          label: getLabel(item.displayName, item.description),
        };
      });

      return {
        totalPages: data.length,
        options,
      };
    });
  };

  const getLabel = (name, description) => {
    return `${name} ${description ? '(' + description + ')' : ''}`;
  };

  const handleChange = (option) => {
    const [displayName, description] = option.label.split(' ');

    interval.id.set(option.value);
    interval.displayName.set(displayName);
    interval.description.set(description.replace(/\(|\)/g, ''));
  };

  return (
    <div className="ScheduledReportModalInterval">
      <p className="ScheduledReportModalInterval-title">Schedule/Time period</p>
      <div className="ScheduledReportModalInterval-dropdown">
        <SearchableSelect
          getData={getData}
          handleChange={handleChange}
          value={{ value: interval.id.value, label: getLabel(interval.displayName.value, interval.description.value) }}
          isSearchable={false}
        />
      </div>
      <InfoTooltip className="ScheduledReportModalInterval-infoTooltip">
        Report will be sent the morning after your selected time period at 3am PST (6am EST). However, the Noon-noon
        report is sent at 3pm PST (6pm EST).
      </InfoTooltip>
    </div>
  );
};

export default observer(ScheduledReportModalInterval);
