import debounce from 'lodash/debounce';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';
import { SimpleField, ToggleField } from 'models/Fields';
import type RequestMediaCamerasType from '../RequestMedia/RequestMediaCamerasType';
import type RequestMediaTimeSegment from '../RequestMedia/RequestMediaTimeSegment';
import type PlayerViewModel from './Player';
import { PLAYER_ERROR_TYPE } from 'config';
import type CameraModel from '../VehicleDetails/CameraModel';

class PlayerImage {
  data: SimpleField<string>;
  cameraType: RequestMediaCamerasType;
  timeSegment: RequestMediaTimeSegment;
  cameraModel: CameraModel;
  repository: IEntityRepository;
  isLoading: ToggleField;
  context: PlayerViewModel;
  fetch: () => Promise<void>;

  constructor(context: PlayerViewModel) {
    this.context = context;
    this.cameraModel = this.context.cameraModel;
    this.cameraType = this.context.cameraType;
    this.timeSegment = this.context.timeSegment;
    this.data = new SimpleField(null);
    this.isLoading = this.context.isLoading;
    this.repository = repositoryService.get('media', 'v2.0').entity('cameras');
    this.fetch = debounce(this._fetch, 500);
  }

  _fetch = async () => {
    const id = this.cameraModel.id;

    if (!id) {
      return;
    }

    const repositoryThumbnail = this.repository.entity(id).entity('thumbnail');

    this.isLoading.toggle(true);

    const response = await repositoryThumbnail.get({
      cameraType: this.cameraType.type.value === 'OUT' ? 'outside' : 'inside',
      id,
      timestamp: this.timeSegment.scrubber.value,
    });

    if (repositoryThumbnail.getState.success) {
      this.data.set(response?.data);
    } else {
      this.context.onError(PLAYER_ERROR_TYPE.IMAGE);
    }

    this.isLoading.toggle(false);
  };
}

export default PlayerImage;
