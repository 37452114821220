import { observable, action, computed, reaction } from 'mobx';

import APIStoreBase from '../APIStoreBase';
import TableBase from 'stores/TableBase';
import type { IEditColumns } from 'models/Tables/IEditColumns';
import type { ISortColumn } from 'models/Tables/ISortColumn';
import { ADMIN_GEOZONES_TABLE_COLUMNS, STORAGE_ITEMS } from 'config';
import { Geozone, Group } from 'models';
import { repositoryService } from 'services';
import { getJSONItemFromStorage, setJSONItemToStorage } from 'utils';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import GeozonesDownload from 'models/Admin/Geozones/GeozonesDownload';

const initialColumns: Array<IEditColumns<ADMIN_GEOZONES_TABLE_COLUMNS>> = [
  { value: ADMIN_GEOZONES_TABLE_COLUMNS.NAME, isSelected: true },
  { value: ADMIN_GEOZONES_TABLE_COLUMNS.CATEGORY, isSelected: true },
  { value: ADMIN_GEOZONES_TABLE_COLUMNS.COLOR, isSelected: true },
  { value: ADMIN_GEOZONES_TABLE_COLUMNS.SHAPE, isSelected: true },
  { value: ADMIN_GEOZONES_TABLE_COLUMNS.DISPATCH_MARKER, isSelected: true },
  { value: ADMIN_GEOZONES_TABLE_COLUMNS.GROUP, isSelected: true },
  { value: ADMIN_GEOZONES_TABLE_COLUMNS.ADDRESS_OVERRIDE, isSelected: false },
  { value: ADMIN_GEOZONES_TABLE_COLUMNS.TRACK_ARRIVALS, isSelected: false },
  { value: ADMIN_GEOZONES_TABLE_COLUMNS.TRACK_DEPARTURES, isSelected: false },
  { value: ADMIN_GEOZONES_TABLE_COLUMNS.RADIUS, isSelected: false },
  { value: ADMIN_GEOZONES_TABLE_COLUMNS.GEOZONE_ID, isSelected: false },
  { value: ADMIN_GEOZONES_TABLE_COLUMNS.ACTIVE, isSelected: false },
  { value: ADMIN_GEOZONES_TABLE_COLUMNS.CREATED_BY, isSelected: false },
  { value: ADMIN_GEOZONES_TABLE_COLUMNS.CREATION_TIME, isSelected: false },
  { value: ADMIN_GEOZONES_TABLE_COLUMNS.UPDATED_BY, isSelected: false },
  { value: ADMIN_GEOZONES_TABLE_COLUMNS.LAST_UPDATE_TIME, isSelected: false },
];

const initialSortedColumn: ISortColumn = {
  field: ADMIN_GEOZONES_TABLE_COLUMNS.NAME,
  order: 'ascend',
};

const storageNames = {
  columns: STORAGE_ITEMS.admin.geozones.columns,
  sortedColumn: STORAGE_ITEMS.admin.geozones.sortedColumn,
  pagination: STORAGE_ITEMS.admin.geozones.pagination,
  searchInColumn: STORAGE_ITEMS.admin.geozones.searchInColumn,
};

export interface ITableGeozone {
  [ADMIN_GEOZONES_TABLE_COLUMNS.NAME]: string;
  [ADMIN_GEOZONES_TABLE_COLUMNS.CATEGORY]: string;
  [ADMIN_GEOZONES_TABLE_COLUMNS.COLOR]: string;
  [ADMIN_GEOZONES_TABLE_COLUMNS.SHAPE]: string;
  [ADMIN_GEOZONES_TABLE_COLUMNS.DISPATCH_MARKER]: string;
  [ADMIN_GEOZONES_TABLE_COLUMNS.GROUP]: string;
  [ADMIN_GEOZONES_TABLE_COLUMNS.ADDRESS_OVERRIDE]: string;
  [ADMIN_GEOZONES_TABLE_COLUMNS.TRACK_ARRIVALS]: string;
  [ADMIN_GEOZONES_TABLE_COLUMNS.TRACK_DEPARTURES]: string;
  [ADMIN_GEOZONES_TABLE_COLUMNS.RADIUS]: number;
  [ADMIN_GEOZONES_TABLE_COLUMNS.GEOZONE_ID]: string;
  [ADMIN_GEOZONES_TABLE_COLUMNS.ACTIVE]: string;
  [ADMIN_GEOZONES_TABLE_COLUMNS.CREATED_BY]: string;
  [ADMIN_GEOZONES_TABLE_COLUMNS.UPDATED_BY]: string;
  [ADMIN_GEOZONES_TABLE_COLUMNS.CREATION_TIME]: string;
  [ADMIN_GEOZONES_TABLE_COLUMNS.LAST_UPDATE_TIME]: string;
  key: string;
  hideOnMaps: boolean;
  isActive: boolean;
  id: number;
}

export interface IGeozoneSearchParams {
  description?: number;
  tag?: string;
  isActive?: string;
  createdBy?: string;
  updatedBy?: string;
  creationTime?: string;
  lastUpdateTime?: string;
}

export enum GeozonesSearchMap {
  name = 'name',
  category = 'tag',
  color = 'colorName',
  shape = 'type',
  dispatchMarker = 'dispatchMarker',
  group = 'groupDescription',
  addressOverride = 'reverseGeocode',
  trackArrivals = 'arrivalZone',
  trackDepartures = 'departureZone',
  radius = 'radius',
  geozoneId = 'geozoneIdGts',
  active = 'isActive',
  createdBy = 'createdBy',
  updatedBy = 'updatedBy',
  creationTime = 'creationTime',
  lastUpdateTime = 'lastUpdateTime',
}

export const geozoneColors: Geozone.IGeozoneColor[] = [
  {
    id: 2,
    name: 'Red',
    hex: '#F44336',
  },
  {
    id: 12,
    name: 'Orange',
    hex: '#FF7F02',
  },
  {
    id: 5,
    name: 'Yellow',
    hex: '#FFEB3B',
  },
  {
    id: 1,
    name: 'Green',
    hex: '#4CAF50',
  },
  {
    id: 4,
    name: 'Light Blue',
    hex: '#03A9F4',
  },
  {
    id: 3,
    name: 'Dark Blue',
    hex: '#0154A0',
  },
  {
    id: 6,
    name: 'Purple',
    hex: '#9C27B0',
  },
  {
    id: 8,
    name: 'Pink',
    hex: '#E91E63',
  },
  {
    id: 11,
    name: 'Brown',
    hex: '#903D00',
  },
  {
    id: 7,
    name: 'Black',
    hex: '#000000',
  },
  {
    id: 10,
    name: 'Gray',
    hex: '#607D8B',
  },
  {
    id: 9,
    name: 'White',
    hex: '#FFFFFF',
  },
];

export class GeozonesAdmin extends TableBase<IEditColumns<ADMIN_GEOZONES_TABLE_COLUMNS>> {
  constructor() {
    super({ columns: initialColumns, sortedColumn: initialSortedColumn, storageNames });
    reaction(
      () => this.showAllGeozones,
      (showAllGeozones) => {
        if (showAllGeozones) {
          this.getGeozonesList({ mappable: true, isActive: true });
        } else {
          this.resetGeozonesList();
        }
      }
    );

    this.repositoryGeozone = repositoryService.get('geozones');
    this.repositoryNotifications = repositoryService.get('notifications');
    this.repositoryGroupsSearch = repositoryService.get('groups').entity('ids');
    this.repositoryGeozone.listenTo('error:get', () => this.resetGeozonesList());
    this.downloadGeozones = new GeozonesDownload(
      this.repositoryNotifications.entity('email').entity('document')
    ).initialize(this);
  }

  @observable geozonesList: Geozone[] = [];
  @observable geozonesListTotal: number = null;
  @observable selectedGeozone: Geozone = null;
  @observable selectedGeozoneTabIndex: number = GeozonesAdmin.initialSelectedGeozoneTabIndex;
  @observable getGeozoneRequestStatus: APIStoreBase = new APIStoreBase();
  @observable deleteGeozoneRequestStatus: APIStoreBase = new APIStoreBase();
  @observable createGeozoneRequestStatus: APIStoreBase = new APIStoreBase();
  @observable showAllGeozones: boolean = false;
  @observable downloadGeozones: GeozonesDownload;
  repositoryNotifications: IRepository;
  repositoryGeozone: IRepository;
  repositoryGroupsSearch: IEntityRepository;

  groupsList: Group[] = [];

  private getGroupName = (groupId: string): string =>
    this.groupsList.find((group) => groupId === group.groupId)?.displayName;

  static get initialSelectedGeozoneTabIndex(): number {
    return getJSONItemFromStorage(STORAGE_ITEMS.admin.geozones.selectedGeozone.tabIndex, 0);
  }

  @computed get tableSource(): ITableGeozone[] {
    return this.geozonesList.map(
      ({
        arrivalZone,
        color,
        departureZone,
        dispatchMarkerAddress,
        dispatchMarkerLatitude,
        dispatchMarkerLongitude,
        geozoneId,
        groupIdGts,
        hideOnMaps,
        id,
        isActive,
        name,
        radius,
        reverseGeocode,
        tag,
        type,
        loggerInfo: { createdBy, creationTime, updatedBy, lastUpdateTime },
      }) => ({
        [ADMIN_GEOZONES_TABLE_COLUMNS.NAME]: name || '-',
        [ADMIN_GEOZONES_TABLE_COLUMNS.CATEGORY]: tag || '-',
        [ADMIN_GEOZONES_TABLE_COLUMNS.COLOR]: color?.name || '-',
        [ADMIN_GEOZONES_TABLE_COLUMNS.SHAPE]: type || '-',
        [ADMIN_GEOZONES_TABLE_COLUMNS.DISPATCH_MARKER]:
          dispatchMarkerAddress || `${dispatchMarkerLatitude}, ${dispatchMarkerLongitude}`,
        [ADMIN_GEOZONES_TABLE_COLUMNS.GROUP]: this.getGroupName(groupIdGts) || groupIdGts || '-',
        [ADMIN_GEOZONES_TABLE_COLUMNS.ADDRESS_OVERRIDE]: reverseGeocode ? 'On' : 'Off',
        [ADMIN_GEOZONES_TABLE_COLUMNS.TRACK_ARRIVALS]: arrivalZone ? 'On' : 'Off',
        [ADMIN_GEOZONES_TABLE_COLUMNS.TRACK_DEPARTURES]: departureZone ? 'On' : 'Off',
        [ADMIN_GEOZONES_TABLE_COLUMNS.RADIUS]: type === 'circle' ? radius : 0,
        [ADMIN_GEOZONES_TABLE_COLUMNS.GEOZONE_ID]: geozoneId,
        [ADMIN_GEOZONES_TABLE_COLUMNS.ACTIVE]: isActive ? 'Yes' : 'No',
        [ADMIN_GEOZONES_TABLE_COLUMNS.CREATED_BY]: createdBy || '-',
        [ADMIN_GEOZONES_TABLE_COLUMNS.UPDATED_BY]: updatedBy || '-',
        [ADMIN_GEOZONES_TABLE_COLUMNS.CREATION_TIME]: creationTime.date || '-',
        [ADMIN_GEOZONES_TABLE_COLUMNS.LAST_UPDATE_TIME]: lastUpdateTime.date || '-',
        hideOnMaps,
        isActive,
        id,
        key: id.toString(),
      })
    );
  }

  @computed get geozonesListWithoutSelected() {
    return this.geozonesList.filter(({ id }) => this.selectedGeozone.id !== id);
  }

  @computed get searchData() {
    return this.selectedSearchColumns.reduce(
      (acc, cur) => ({
        ...acc,
        [GeozonesSearchMap[cur.column]]: cur.value,
      }),
      {}
    );
  }

  @computed get sortData() {
    return {
      sortBy: GeozonesSearchMap[this.sortedColumn.field],
      order: this.sortedColumn.order?.replace('end', '').toUpperCase(),
    };
  }

  @action getGeozonesList = async ({
    page,
    mappable,
    isActive,
    pageSize,
    search,
    order,
    sortBy,
  }: {
    page?: number;
    pageSize?: number;
    mappable?: boolean;
    isActive?: boolean;
    search?: IGeozoneSearchParams;
    sortBy?: string;
    order?: string;
  }) => {
    const response = await this.repositoryGeozone.get({ page, pageSize, mappable, isActive, order, sortBy, ...search });

    this.geozonesList = response.items
      .map(
        (serverGeozone) =>
          ['polygon', 'circle'].includes(serverGeozone.type.toLowerCase()) && new Geozone(serverGeozone)
      )
      .filter(Boolean);
    this.geozonesListTotal = response.total;
    this.isSearchChanged = false;
  };

  @action setShowAllGeozones = (status?: boolean) => (this.showAllGeozones = status ?? !this.showAllGeozones);

  @action getGeozone = async (geozoneId: string) => {
    this.getGeozoneRequestStatus.reset().setLoading(true);

    try {
      const serverGeozone = await this.repositoryGeozone.entity(geozoneId).get();

      this.selectedGeozone = new Geozone(serverGeozone);
      this.getGeozoneRequestStatus.setSuccess(true);
    } catch (error) {
      this.getGeozoneRequestStatus.setError(error.message || 'Something went wrong');
      throw new Error(error);
    }

    this.getGeozoneRequestStatus.setLoading(false);
  };

  @action deleteGeozone = async (geozoneId: string) => {
    this.deleteGeozoneRequestStatus.reset().setLoading(true);

    try {
      await this.repositoryGeozone.entity(geozoneId).delete();
      this.deleteGeozoneRequestStatus.setSuccess(true);
    } catch (error) {
      this.deleteGeozoneRequestStatus.setError(error.message || 'Something went wrong');
    }

    this.deleteGeozoneRequestStatus.setLoading(false);
  };

  @action setSelectedGeozoneTabIndex = (index: number) => {
    this.selectedGeozoneTabIndex = index;
    setJSONItemToStorage(STORAGE_ITEMS.admin.geozones.selectedGeozone.tabIndex, index);
  };

  @action getGroupsList = async () => {
    const serverGroupsList = await this.repositoryGroupsSearch.get({ page: null, pageSize: null, filter: '' });

    this.groupsList = serverGroupsList.items.map((serverGroup) => new Group(serverGroup));
  };

  @action resetGeozonesList = () => (this.geozonesList = []);

  @action resetSelectedGeozone = () => (this.selectedGeozone = null);
}

export default new GeozonesAdmin();
