import { observable, action, computed } from 'mobx';
import isBoolean from 'lodash/isBoolean';

import { DATE_TIME_FORMATS, EVENT_STATUS_TYPE, MI_TO_KM } from 'config';
import { convertMillisecondsToFullDate, getDiffFromNow, getFormattedTime } from 'utils';
import type { Device } from 'models';
import timeStore from 'stores/TimeStore';
import ItemRegistry from 'models/EventRegistry/ItemRegistry';

export class Event {
  @observable address: string;
  @observable deviceId: string;
  @observable driverName: string;
  @observable geozone: Event.IEventGeozone;
  @observable heading: number;
  @observable ignition: string;
  @observable isGeozoneActive: boolean = true;
  @observable isSelected: boolean = false;
  @observable isHovered: boolean = false;
  @observable lastNonIdleTimestamp: number;
  @observable latitude: string;
  @observable longitude: string;
  @observable reportedOdometer: number;
  @observable reportedEngineHours: number;
  @observable satelliteCount: number;
  @observable speedKmh: number;
  @observable speedMph: number;
  @observable statusCode: number;
  @observable statusCodeText: string;
  @observable statusColor: string;
  @observable stopTime: number;
  @observable timestamp: number;
  @observable metadata: ItemRegistry;
  @observable motionDetails: Event.IMotionDeatils;
  @observable fuelLevel: number;
  @observable isLastEvent: boolean = false;
  @observable isDisablePan: boolean = false;
  isRegistered: boolean = false;
  eventStatusCode: number;
  private context: Device;

  constructor(event: Event.IServerEvent) {
    this.address = event.address;
    this.deviceId = event.deviceId;
    this.eventStatusCode = event.statusCode;
    this.geozone = event.geozone;
    this.heading = event.heading;
    this.ignition = event.ignition;
    this.isGeozoneActive = event.isGeozoneActive;
    this.lastNonIdleTimestamp = event.lastNonIdleTimestamp;
    this.latitude = event.latitude;
    this.longitude = event.longitude;
    this.reportedOdometer = event.reportedOdometer * MI_TO_KM;
    this.reportedEngineHours = event.reportedEngineHours;
    this.satelliteCount = event.satelliteCount;
    this.speedKmh = Number(event.speedKmh.toFixed(0));
    this.speedMph = Number(event.speedMph.toFixed(0));
    this.statusCode = event.statusCode;
    this.statusCodeText = event.statusCodeText;
    this.statusColor = event.statusColor;
    this.stopTime = event.stopTime;
    this.timestamp = event.timestamp;
    this.lastNonIdleTimestamp = event.lastNonIdleTimestamp;
    this.driverName = event.driverName;
    this.motionDetails = event.motionDetails;
    this.fuelLevel = event.fuelLevel;
  }

  initialize(context) {
    this.context = context;
    return this;
  }

  @computed get isUpdateTime() {
    return Boolean(
      this.metadata.attributes.eventStatusType === EVENT_STATUS_TYPE.TIME_DIFF &&
        this.motionDetails &&
        (this.isLastEvent ||
          !(this.motionDetails.stopTime || this.motionDetails.idleTime || this.motionDetails.ignitionOffTime))
    );
  }

  @computed get totalQuantityEvents() {
    return this.context.selectedEvents.length;
  }

  @computed get currentIndex() {
    return this.context.getIndex(this);
  }

  @computed get formattedEventTime() {
    return getFormattedTime(this.timestamp, DATE_TIME_FORMATS.timeCapitalAM, timeStore.userTimezone);
  }

  getTimeDiff = (isLastEvent = false, isIdle = false, isIgnitionOff = false, toDate = null) => {
    if (this.metadata.attributes.eventStatusType === EVENT_STATUS_TYPE.TIME_DIFF && this.motionDetails) {
      let timeStamp = this.motionDetails.lastNonStopTimestamp;
      let time = this.motionDetails.stopTime;

      if (isIgnitionOff) {
        timeStamp = this.motionDetails.lastIgnitionOnTimestamp;
        time = this.motionDetails.ignitionOffTime;
      }

      if (isIdle) {
        timeStamp = this.motionDetails.lastNonIdleTimestamp;
        time = this.motionDetails.idleTime;
      }

      return isLastEvent || !time ? getDiffFromNow(timeStamp, toDate) : convertMillisecondsToFullDate(time);
    } else {
      return '';
    }
  };

  @action setMetadata = (metadata: ItemRegistry): void => {
    this.metadata = metadata;
    this.isRegistered = true;
  };

  @action showTrackPointCard = (isDisablePan: boolean = false) => {
    this.isDisablePan = isDisablePan;
    this.isSelected = true;
    this.context.onShowTrackPointCard(this);
  };

  @action hideTrackPointCard = () => {
    this.isSelected = false;
  };

  @action toggleHovered = (isHovered?: boolean) =>
    (this.isHovered = isBoolean(isHovered) ? isHovered : !this.isHovered);

  @action setIsLastEvent = (value: boolean) => {
    this.isLastEvent = value;
  };

  moveToPreviousEvent = () => {
    this.context.onMoveToPreviousEvent(this);
  };

  moveToNextEvent = () => {
    this.context.onMoveToNextEvent(this);
  };
}

export default Event;
