import React, { FC, Fragment } from 'react';
import classNames from 'classnames';

import type Asset from 'models/Assets/Asset';

import './styles.scss';

interface IProps {
  className?: string;
  assets: Asset[];
}

const AssetCellWithPopup: FC<IProps> = ({ className, assets }) => {
  const isMoreThanOne = assets.length > 1;
  const cn = classNames('AssetCellWithPopup', className, {
    'AssetCellWithPopup--withList': isMoreThanOne,
  });

  return (
    <div className={cn}>
      {Boolean(assets.length) ? (
        <div className="AssetCellWithPopup-wrapper">
          <p className="AssetCellWithPopup-first">{assets[0].name.value}</p>
          {Boolean(isMoreThanOne) && (
            <Fragment>
              <p className="AssetCellWithPopup-more">+ {assets.length - 1} more...</p>
              <ul className="AssetCellWithPopup-list">
                {assets.map(({ name }, index) => {
                  return (
                    <li className="AssetCellWithPopup-item" key={index}>
                      {name.value}
                    </li>
                  );
                })}
              </ul>
            </Fragment>
          )}
        </div>
      ) : (
        <div className="AssetCellWithPopup-empty">-</div>
      )}
    </div>
  );
};

export default AssetCellWithPopup;
