import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';

import { PATHS } from 'config';
import { MoreIcon } from 'assets';
import HeaderIcon from 'components/Table/HeaderIcon';

import './styles.scss';

interface IProps {
  vehicleId: string;
  hasEvents: boolean;
}

const Actions: FC<IProps> = ({ vehicleId, hasEvents }) => {
  return (
    <div className="VehiclesTableColumnActions">
      <Dropdown
        trigger={['click']}
        getPopupContainer={(trigger) => trigger.parentElement}
        overlay={
          <Menu className="VehiclesTableColumnActions-list">
            <Menu.Item className="VehiclesTableColumnActions-item">
              <Link
                className="VehiclesTableColumnActions-itemLink VehiclesTableColumnActions-itemLink--details"
                to={PATHS.ADMIN.TABLES.VEHICLES.VEHICLE.replace(':id', vehicleId)}
              >
                Edit Vehicle
              </Link>
            </Menu.Item>
            {hasEvents && (
              <Menu.Item className="VehiclesTableColumnActions-item">
                <Link
                  className="VehiclesTableColumnActions-itemLink VehiclesTableColumnActions-itemLink--map"
                  to={PATHS.MAP.VEHICLE.replace(':vehicleId', vehicleId)}
                >
                  View on Map
                </Link>
              </Menu.Item>
            )}
          </Menu>
        }
      >
        <HeaderIcon icon={<MoreIcon style={{ width: '16px', height: '16px' }} />} iconText="More" />
      </Dropdown>
    </div>
  );
};

export default Actions;
