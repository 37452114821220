import React, { FC } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import type { SimpleField } from 'models/Fields';
import type { IDashcamSettingsEnumField } from 'interfaces/models/Dashcams/IDashcamSettingsEnumField';

import InfoTooltip from 'components/InfoTooltip';
import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';

import './styles.scss';
import CameraSettingsItem from '../index';

interface IProps {
  item: IDashcamSettingsEnumField<SimpleField<string>>;
  disabled?: boolean;
}

const CameraSettingsItemSelect: FC<IProps> = ({ item, disabled }) => {
  const handleSelectChange = ({ value }: { value: string; label: string }) => {
    item.value.set(value);
    item.context.updateShowIfSettings();
  };

  const subSettings = item.settings?.filter((item) => item.visible.value);

  return (
    <div
      className={classnames('CameraSettingsItem CameraSettingsItemSelect', {
        'CameraSettingsItemSelect--disabled CameraSettingsItem--disabled': disabled,
      })}
    >
      <div className="CameraSettingsItemSelect-header">
        <div className="CameraSettingsItem-info CameraSettingsItemSelect-info">
          <div className="CameraSettingsItem-name CameraSettingsItemSelect-name">{item.name}</div>
          {item.description && (
            <InfoTooltip className="CameraSettingsItem-infoTooltip CameraSettingsItemSelect-infoTooltip">
              {item.description}
            </InfoTooltip>
          )}
        </div>
        <div className="CameraSettingsItem-action CameraSettingsItemSelect-action">
          <SearchableStaticSelect
            value={{
              value: item.value.value,
              label: item.value.value,
            }}
            disabled={disabled}
            handleChange={handleSelectChange}
            values={item.allowableValues.toArray().map((v) => ({
              value: v.id,
              label: v.name,
            }))}
          />
        </div>
      </div>
      {subSettings.length > 0 && (
        <div className="CameraSettingsItemSelect-sub-items">
          {subSettings.map((setting, index) => {
            return <CameraSettingsItem key={setting.id || String(index)} item={setting} disabled={disabled} />;
          })}
        </div>
      )}
    </div>
  );
};

export default observer(CameraSettingsItemSelect);
