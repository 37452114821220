import React, { useCallback, useState } from 'react';

interface IProps {
  title: string;
  id: string;
  onClick: (value: string) => Promise<void>;
}

const ReportCompletedTaskPanel: React.FC<IProps> = ({ id, title, onClick }) => {
  const [loading, setLoading] = useState(false);
  const openTaskPanel = useCallback(() => {
    setLoading(true);
    onClick(id).finally(() => setLoading(false));
  }, [id, onClick, setLoading]);

  return (
    <div>
      <p onClick={openTaskPanel}>{loading ? 'Loading...' : title}</p>
    </div>
  );
};

export default ReportCompletedTaskPanel;
