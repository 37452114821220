import { DASHCAMS_SETTINGS_TYPES } from 'config';
import { DashcamSetting } from './DashcamSetting';
import { SimpleField } from '../Fields';
import type { IDashcamSettingsNumberField } from 'interfaces/models/Dashcams/IDashcamSettingsNumberField';

export class DashcamSettingNumber
  extends DashcamSetting<SimpleField<number>>
  implements IDashcamSettingsNumberField<SimpleField<number>>
{
  allowableRange: { from: number; to: number; step: number };
  possibleValues: { from: number; to: number };
  error: {
    description: string;
    link: string;
    name: string;
    status: string;
  };
  measure?: string;

  constructor(context, setting, level: number = 0) {
    super(context, { ...setting, level, type: DASHCAMS_SETTINGS_TYPES.NUMBER });
    this.value = new SimpleField(setting.value || setting?.possibleValues?.from || setting?.allowableRange?.from);
    this.allowableRange = setting.allowableRange;
    this.possibleValues = setting.possibleValues;
    this.error = setting.error;
    this.measure = setting.measure;
  }

  get model() {
    return { ...super.model, value: this.value.value };
  }

  reset = () => {
    this.settings?.forEach((setting) => setting.reset?.());
    this.value.reset();
  };
}
