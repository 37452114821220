import { observable } from 'mobx';

import vehiclesAdmin, { VehiclesAdmin } from './Vehicles';
import groupsAdmin, { GroupsAdmin } from './Groups';
import peopleAdmin, { PeopleAdmin } from './People';
import geozonesAdmin, { GeozonesAdmin } from './Geozones';
import dashcamsAdmin, { DashCamsAdmin } from './Dashcams';

import SubNavigation from 'models/SubNavigation';

export class AdminStore {
  @observable subNavigation: SubNavigation = new SubNavigation('admin');

  vehiclesAdmin: VehiclesAdmin;
  groupsAdmin: GroupsAdmin;
  peopleAdmin: PeopleAdmin;
  geozonesAdmin: GeozonesAdmin;
  dashcamsAdmin: DashCamsAdmin;

  constructor() {
    this.vehiclesAdmin = vehiclesAdmin;
    this.groupsAdmin = groupsAdmin;
    this.peopleAdmin = peopleAdmin;
    this.geozonesAdmin = geozonesAdmin;
    this.dashcamsAdmin = dashcamsAdmin;
  }
}

export default new AdminStore();
