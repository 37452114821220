import React, { Component } from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Link } from 'react-router-dom';
import noop from 'lodash/noop';
import type { ColumnsType } from 'antd/es/table';

import { ADMIN_DASHCAMS_TABLE_COLUMNS, DATE_TIME_FORMATS, PATHS, STORAGE_ITEMS } from 'config';
import { setJSONItemToStorage, getJSONItemFromStorage, getFormattedTime } from 'utils';

import type { TimeStore } from 'stores';
import type { ITableDashcam, DashCamsAdmin } from 'stores/Admin/Dashcams';

import CustomTable from 'components/Table/CustomTable';
import TableActions from 'components/Table/Actions';

import TableDownloadModal from 'components/Table/TableDownloadModal';
import TableEmailModal from 'components/Table/TableEmailModal';

import './styles.scss';

interface IProps {
  className?: string;
  dashcamsAdmin?: DashCamsAdmin;
  timeStore?: TimeStore;
}

interface IState {
  isFilterOpen: boolean;
  isDownloadModalOpen: boolean;
  isEmailModalOpen: boolean;
}

const editColumnsMap = {
  [ADMIN_DASHCAMS_TABLE_COLUMNS.NAME]: {
    text: 'Name',
    isDisabled: true,
  },
  [ADMIN_DASHCAMS_TABLE_COLUMNS.DASHCAM_ID]: {
    text: 'Dashcam ID',
    isDisabled: false,
  },
  [ADMIN_DASHCAMS_TABLE_COLUMNS.SERIAL_NUMBER]: {
    text: 'Serial #',
    isDisabled: false,
  },
  [ADMIN_DASHCAMS_TABLE_COLUMNS.UNIQUE_ID]: {
    text: 'Activation #',
    isDisabled: false,
  },
  [ADMIN_DASHCAMS_TABLE_COLUMNS.VEHICLE_NAME]: {
    text: 'Assigned Vehicle',
    isDisabled: false,
  },
  [ADMIN_DASHCAMS_TABLE_COLUMNS.VEHICLE_ID]: {
    text: 'Assigned CP #',
    isDisabled: false,
  },
  [ADMIN_DASHCAMS_TABLE_COLUMNS.VEHICLE_DESCRIPTION]: {
    text: 'Assigned Vehicle Description',
    isDisabled: false,
  },
  [ADMIN_DASHCAMS_TABLE_COLUMNS.LAST_COMMUNICATION]: {
    text: 'Last Communication',
    isDisabled: false,
  },
  [ADMIN_DASHCAMS_TABLE_COLUMNS.UPDATED_BY]: {
    text: 'Modified By',
    isDisabled: false,
  },
  [ADMIN_DASHCAMS_TABLE_COLUMNS.LAST_UPDATE_TIME]: {
    text: 'Last Modified',
    isDisabled: false,
  },
};

@inject(({ adminStore: { dashcamsAdmin }, timeStore }) => ({
  dashcamsAdmin,
  timeStore,
}))
@observer
class AdminDashcamsTable extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isFilterOpen: getJSONItemFromStorage(STORAGE_ITEMS.admin.filterOpenStatus, true),
      isDownloadModalOpen: false,
      isEmailModalOpen: false,
    };
  }

  getColumns = (): ColumnsType<ITableDashcam> => {
    const {
      dashcamsAdmin: {
        columns,
        sortedColumn: { field, order },
      },
      timeStore: { userTimezone, sessionTimezone },
    } = this.props;

    const lastCommunicationText = 'Last Comm' + (userTimezone ? ` (${userTimezone})` : '');
    const allColumns = [
      {
        title: 'Name',
        dataIndex: ADMIN_DASHCAMS_TABLE_COLUMNS.NAME,
        defaultSortOrder: field === 'name' ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        render: (_, { name, id }) => <Link to={PATHS.ADMIN.TABLES.DASHCAMS.DASHCAM.replace(':id', id)}>{name}</Link>,
        width: 180,
      },
      {
        title: 'Dashcam ID',
        dataIndex: ADMIN_DASHCAMS_TABLE_COLUMNS.DASHCAM_ID,
        width: 180,
        className: 'ant-table-cell--no-sorter',
      },
      {
        title: 'Serial #',
        dataIndex: ADMIN_DASHCAMS_TABLE_COLUMNS.SERIAL_NUMBER,
        width: 180,
        className: 'ant-table-cell--no-sorter',
      },
      {
        title: 'Activation #',
        dataIndex: ADMIN_DASHCAMS_TABLE_COLUMNS.UNIQUE_ID,
        width: 380,
        className: 'ant-table-cell--no-sorter',
      },
      {
        title: 'Assigned Vehicle',
        dataIndex: ADMIN_DASHCAMS_TABLE_COLUMNS.VEHICLE_NAME,
        className: 'ant-table-cell--no-sorter',
        width: 180,
      },
      {
        title: 'Assigned CP #',
        dataIndex: ADMIN_DASHCAMS_TABLE_COLUMNS.VEHICLE_ID,
        className: 'ant-table-cell--no-sorter',
        width: 170,
      },
      {
        title: 'Assigned Vehicle Description',
        dataIndex: ADMIN_DASHCAMS_TABLE_COLUMNS.VEHICLE_DESCRIPTION,
        className: 'ant-table-cell--no-sorter',
        width: 220,
      },
      {
        title: lastCommunicationText,
        sorter: noop,
        defaultSortOrder: field === ADMIN_DASHCAMS_TABLE_COLUMNS.LAST_COMMUNICATION ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        dataIndex: ADMIN_DASHCAMS_TABLE_COLUMNS.LAST_COMMUNICATION,
        width: 220,
        hiddenSearch: true,
      },
      {
        title: 'Modified By',
        dataIndex: ADMIN_DASHCAMS_TABLE_COLUMNS.UPDATED_BY,
        width: 180,
        hiddenSearch: true,
        className: 'ant-table-cell--no-filter',
      },
      {
        title: 'Last Modified',
        dataIndex: ADMIN_DASHCAMS_TABLE_COLUMNS.LAST_UPDATE_TIME,
        width: 180,
        hiddenSearch: true,
        className: 'ant-table-cell--no-filter',
        render: (value) => (
          <>{value ? getFormattedTime(value, DATE_TIME_FORMATS.adminVehicleSettings, sessionTimezone) : '-'}</>
        ),
      },
    ];

    const filteredColumns = [];

    columns.forEach((column) => {
      if (column.isSelected) {
        filteredColumns.push(allColumns.find((col) => col.dataIndex === column.value));
      }
    });

    return [
      ...filteredColumns,
      {
        title: 'Actions',
        dataIndex: 'actions',
        width: 120,
        className: 'ant-table-cell--actions',
        render: (_, { id, assetId }) => {
          const items = [
            {
              text: 'Edit Dashcam',
              link: PATHS.ADMIN.TABLES.DASHCAMS.DASHCAM.replace(':id', id),
            },
            {
              text: 'Go to Available Media',
              link: `${PATHS.DASHCAMS.MEDIA_MANAGER}?assetId=${assetId}`,
              disabled: Boolean(!assetId),
            },
          ].filter((item) => item);

          return <TableActions items={items} />;
        },
      },
    ];
  };

  handleOpenFilter = (isFilterOpen: boolean) => {
    this.setState({ isFilterOpen });
    setJSONItemToStorage(STORAGE_ITEMS.admin.filterOpenStatus, isFilterOpen);
  };

  closeDownloadModal = () => {
    this.setState({ isDownloadModalOpen: false });
  };

  openDownloadModal = () => {
    this.setState({ isDownloadModalOpen: true });
  };

  closeEmailModal = () => {
    this.setState({ isEmailModalOpen: false });
  };

  openEmailModal = () => {
    this.setState({ isEmailModalOpen: true });
  };

  render() {
    const {
      className,
      dashcamsAdmin: {
        columns,
        dashcamsListTotal,
        downloadDashcams,
        getAllDashcams,
        repositoryDashcams: { getState },
        pagination,
        searchColumns,
        searchData,
        setColumns,
        setPagination,
        setSearchColumn,
        setSortedColumn,
        sortData,
        tableSource,
      },
    } = this.props;
    const { isFilterOpen, isEmailModalOpen, isDownloadModalOpen } = this.state;
    const cn = classNames('AdminDashcamsTable', className, { 'AdminDashcamsTable--withOpenFilters': isFilterOpen });
    const tableHeight = isFilterOpen ? 'calc(100vh - 280px)' : 'calc(100vh - 251px)';

    return (
      <div className={cn}>
        <CustomTable<ADMIN_DASHCAMS_TABLE_COLUMNS, ITableDashcam>
          columns={this.getColumns()}
          dataSource={tableSource}
          editColumnsMap={editColumnsMap}
          getData={getAllDashcams}
          isFilterActive={isFilterOpen}
          loading={getState.loading}
          onColumnsChange={setColumns}
          onColumnSort={setSortedColumn}
          onColumnsSearch={setSearchColumn}
          onFilterOpen={this.handleOpenFilter}
          onPaginationChange={setPagination}
          pagination={pagination}
          searchColumns={searchColumns}
          searchParams={searchData}
          selectedColumns={toJS(columns)}
          sortedColumn={sortData}
          total={dashcamsListTotal}
          totalTitle={`${dashcamsListTotal} Dashcams`}
          withActions
          withDownload
          withEmail
          openDownloadModal={this.openDownloadModal}
          openEmailModal={this.openEmailModal}
          tableHeight={tableHeight}
        />
        <TableDownloadModal<DashCamsAdmin>
          isOpen={isDownloadModalOpen}
          onClose={this.closeDownloadModal}
          tableDownload={downloadDashcams}
        />
        <TableEmailModal<DashCamsAdmin>
          isOpen={isEmailModalOpen}
          onClose={this.closeEmailModal}
          tableDownload={downloadDashcams}
        />
      </div>
    );
  }
}

export default AdminDashcamsTable;
