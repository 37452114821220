import React, { FC } from 'react';
import classnames from 'classnames';
import { CELL_ICONS } from 'models/Admin/People/Tables';
import type TableToggleFilter from 'models/Tables/TableToggleFilter';
import Checkbox from 'components/Checkbox';
import Popup from 'components/Popup';
import HeaderIcon from 'components/Table/HeaderIcon';

import { CalendarCar, DriverIcon, FilterIcon, LaptopIcon, MediaNavIcon, PhoneIcon, VehicleGroupIcon } from 'assets';
import './styles.scss';

interface IProps {
  filters: TableToggleFilter[];
  onChange: (value: string, filter: TableToggleFilter) => void;
}

const ColumnToggleFilter: FC<IProps> = ({ filters, onChange }) => {
  const selected = filters.some((filter) => filter.isActive.value);
  const handleChange = (value: string, filter: TableToggleFilter) => {
    filter.isActive.toggle();
    onChange(value, filter);
  };
  const renderIcon = (name: CELL_ICONS) => {
    switch (name) {
      case CELL_ICONS.WEB:
        return <LaptopIcon />;
      case CELL_ICONS.MOBILE:
        return <PhoneIcon />;
      case CELL_ICONS.DRIVER:
        return <DriverIcon />;
      case CELL_ICONS.VEHICLE:
        return <CalendarCar />;
      case CELL_ICONS.GROUP:
        return <VehicleGroupIcon />;
      case CELL_ICONS.DASHCAM:
        return <MediaNavIcon />;
      default:
        return null;
    }
  };
  const renderLabel = (filter: TableToggleFilter) => {
    return (
      <div className="ColumnToggleFilter-itemLabel">
        {Boolean(filter.icon) && <div className="ColumnToggleFilter-itemLabelIcon">{renderIcon(filter.icon)}</div>}
        <div className="ColumnToggleFilter-itemLabelText">{filter.text}</div>
      </div>
    );
  };

  return (
    <Popup triggerElement={<HeaderIcon selected={selected} icon={<FilterIcon />} />}>
      <div className="ColumnToggleFilter">
        {filters.map((filter) => {
          const cn = classnames('ColumnToggleFilter-item', {
            [`ColumnToggleFilter-item--${filter.value?.toLowerCase()}`]: Boolean(filter.value),
          });
          return (
            <div className={cn} key={filter.value}>
              <div className="ColumnToggleFilter-itemCheckbox">
                <Checkbox
                  checked={filter.isActive.value}
                  onChange={(value) => handleChange(value, filter)}
                  value={filter.value}
                  label={renderLabel(filter)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </Popup>
  );
};

export default ColumnToggleFilter;
