import { SimpleField, ToggleField } from 'models/Fields';
import PersonDetailsAddressModel from './PersonDetails/PersonDetailsAddressModel';
import PersonDetailsContactsModel from './PersonDetails/PersonDetailsContactsModel';
import AppFile from 'models/Files/AppFile';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import type PersonModel from './PersonModel';
import type { PEOPLE_ADMIN_ROLES } from 'config';

class PersonDetailsModel {
  name: SimpleField<string>;
  jobTitle: SimpleField<string>;
  employeeId: SimpleField<number>;
  active: ToggleField;
  address: PersonDetailsAddressModel;
  contacts: PersonDetailsContactsModel;
  notes: SimpleField<string>;
  role: PEOPLE_ADMIN_ROLES;
  avatar: AppFile;
  context: PersonModel;
  repository: IEntityRepository;

  constructor(item, context) {
    this.context = context;
    this.repository = this.context.repositoryPerson;
    this.name = new SimpleField(item.contactName || '');
    this.jobTitle = new SimpleField(item.jobTitle || '');
    this.employeeId = new SimpleField(item.employeeId);
    this.active = new ToggleField(item.active);
    this.address = new PersonDetailsAddressModel(item.address || {});
    this.contacts = new PersonDetailsContactsModel(item, this.context.id.value);
    this.notes = new SimpleField(item.notes);
    this.role = item.role;
    this.avatar = new AppFile(this.repository.entity('image'), {
      url: item.profileImage?.url || '',
      displayName: '',
      fileName: '',
      id: null,
      type: item.profileImage?.type || 'image',
    });
  }

  get data() {
    return {
      ...this.contacts.data,
      contactName: this.name.value,
      jobTitle: this.jobTitle.value,
      employeeId: this.employeeId.value,
      active: this.active.value,
      address: this.address.data,
      notes: this.notes.value,
      profileImage: { url: this.avatar.model.url.value, type: this.avatar.model?.type },
    };
  }

  get isValid() {
    return !this.name.isEmpty && this.contacts.isValid;
  }

  get isUpdated() {
    return (
      this.name.isUpdated ||
      this.jobTitle.isUpdated ||
      this.employeeId.isUpdated ||
      this.active.isUpdated ||
      this.address.isUpdated ||
      this.contacts.isUpdated ||
      this.notes.isUpdated
    );
  }

  reset = () => {
    this.name.reset();
    this.jobTitle.reset();
    this.employeeId.reset();
    this.active.reset();
    this.address.reset();
    this.contacts.reset();
    this.notes.reset();
  };
}

export default PersonDetailsModel;
