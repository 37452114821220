import { observable, action, reaction } from 'mobx';

import { MAINTENANCE_SEARCH_PARAMS, MAINTENANCE_VIEW } from 'config';
import { repositoryService } from 'services';

import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import MaintenanceAccountConfig from 'models/Maintenances/MaintenanceAccountConfig';
import UpcomingTable from 'models/Maintenances/Tables/UpcomingTable';
import CompletedTable from 'models/Maintenances/Tables/CompletedTable';
import { ListField, SimpleField, ToggleField } from 'models/Fields';
import MaintenanceTask from 'models/Maintenances/MaintenanceTask';
import ApplyTasks from 'models/Maintenances/ApplyTasks/ApplyTasks';
import MaintenanceRequests from 'models/Maintenances/Models/MaintenanceRequests/MaintenanceRequests';

interface ISearchParams {
  [MAINTENANCE_SEARCH_PARAMS.NAME]?: string;
  [MAINTENANCE_SEARCH_PARAMS.HISTORY_NAME]?: string;
  [MAINTENANCE_SEARCH_PARAMS.ASSET_NAME]?: string;
}

export class Maintenances {
  tasks: ListField<MaintenanceTask>;
  @observable task: MaintenanceTask;
  total: SimpleField<number>;
  applyTasks: ApplyTasks;
  repository: IRepository;
  repositoryHistory: IEntityRepository;
  repositoryAsset: IEntityRepository;
  upcomingTable: UpcomingTable;
  completedTable: CompletedTable;
  accountConfig: MaintenanceAccountConfig;
  requests: MaintenanceRequests;
  isUpdate: ToggleField;
  view: SimpleField<MAINTENANCE_VIEW>;
  creatingTask: ToggleField;

  constructor() {
    this.task = null;
    this.tasks = new ListField([]);
    this.total = new SimpleField<number>(0);
    this.applyTasks = new ApplyTasks(this);
    this.repository = repositoryService.get('maintenances');
    this.repositoryHistory = this.repository.entity('history');
    this.repositoryAsset = null;
    this.accountConfig = new MaintenanceAccountConfig(this);
    this.view = new SimpleField(MAINTENANCE_VIEW.DEFAULT);
    this.upcomingTable = new UpcomingTable(this);
    this.completedTable = new CompletedTable(this);
    this.isUpdate = new ToggleField(false);
    this.requests = new MaintenanceRequests(this);
    this.creatingTask = new ToggleField(false);

    reaction(
      () => this.view.value,
      () => {
        this.upcomingTable = new UpcomingTable(this);
        this.completedTable = new CompletedTable(this);
      },
      { name: 'Rebuild tables on maintenance view change' }
    );
  }

  fetch = async (
    {
      order,
      page,
      pageSize,
      search,
      sortBy,
      hasHistoryRecords,
      withHistoryRecords,
      isCompleted,
    }: {
      order?: string;
      page?: number;
      pageSize?: number;
      search?: ISearchParams;
      sortBy?: string;
      hasHistoryRecords?: boolean;
      withHistoryRecords?: boolean;
      isCompleted?: boolean;
    },
    assetId?: number,
    assetGroupId?: number
  ) => {
    this.resetTasks();
    const params = {
      ...(assetId && { assetId }),
      ...(assetGroupId && { assetGroupId }),
      order,
      page,
      pageSize,
      ...search,
      sortBy,
      hasHistoryRecords,
      withHistoryRecords,
      isCompleted,
    };

    const response = await this.repository.get(params);
    response?.items.map((item) => this.tasks.add(new MaintenanceTask(item, this)));
    this.total.set(response?.total);

    this.isUpdate.toggle(false);
  };

  create = (data?: object) => {
    this.task = new MaintenanceTask({ ...data }, this);
    this.task.ui.modal.reset();
    this.task.ui.modal.setCreateMode();
  };

  createFromRequest = async (id: number) => {
    const maintenanceRequest = this.requests.findById(id);
    if (!this.creatingTask.value) {
      this.creatingTask.toggle(true);
      try {
        const record = await this.repository.entity(String(id)).get({ withDocuments: false });
        this.task = new MaintenanceTask({ ...record, requestId: maintenanceRequest.id }, this);
        this.task.ui.modal.isVehicleDisabled.toggle(true);
        await this.task.model.documents.fetch();
        this.task.ui.modal.reset();
        this.task.ui.modal.setEditMode();
        this.task.ui.modal.isServiceTypeDisabled.toggle(false);
        this.task.ui.modal.isFrequencyDisabled.toggle(false);
      } finally {
        this.creatingTask.toggle(false);
      }
    } else {
      throw new Error('Task is already creating');
    }
  };

  edit = (id: number) => {
    const task = this.findById(id);

    this.task = task ? new MaintenanceTask(task.model.serverData, this) : null;

    if (!this.task) {
      return;
    }

    if (this.task.model.isRepeat) {
      this.task.model.fetchHistoryRecord();
    }

    this.task.model.documents.fetch();
    this.task.ui.modal.reset();
    this.task.ui.modal.setEditMode();
  };

  markAsCompleted = (id: number) => {
    this.task = this.findById(id);
    this.task.markAsComplete();
  };

  delete = (id: number) => {
    this.task = this.findById(id);
  };

  @action fetchCompleted = async (id: string) => {
    const record = await this.repositoryHistory.entity(id).get();

    this.task = new MaintenanceTask({ name: record.name, asset: record.asset }, this);
    this.task.setCompleted(record);
  };

  selectCompleted = (id: number) => {
    this.task = this.findByRecordId(id);
    this.task.selectCompleted(id);
  };

  findById = (id: number) => {
    const item = this.tasks.value.find((item) => item.value.model.id.value === id);
    return item ? item.value : null;
  };

  findByRecordId = (id: number) => {
    const item = this.tasks.value.find((item) => item.value.model.historyRecords.find((record) => record.id === id));
    return item ? item.value : null;
  };

  resetTask = () => (this.task = null);

  resetTasks = () => {
    this.tasks.reset();
    this.total.reset();
  };
}

export default new Maintenances();
