import { ALERTS_TYPE } from 'config';
import { observable } from 'mobx';
import { SimpleField } from 'models/Fields';
import AlertModel from './Alert';
import ExceptionOptions from './ExceptionOptions';

class FuelingExceptionAlertModel extends AlertModel {
  @observable exception: SimpleField<string>;
  exceptionType: ExceptionOptions;

  constructor(item) {
    super(item);

    this.exceptionType = new ExceptionOptions();
    this.exception = new SimpleField(item.exception || 'default');
    this.urlType = 'fuel-exception';
    this.description = `This alert will fire when the Fuel Card Provider processes a batch of fueling events and pushes the information to ClearPathGPS. As such, it will not be a 'live' alert.`;
    this.type.reInitialize(ALERTS_TYPE.FUELING_EXCEPTION);
  }

  get model() {
    return { ...super.model, exception: this.exception.value === 'default' ? null : this.exception.value };
  }

  isUpdated(): boolean {
    return super.isUpdated() || this.exception.isUpdated;
  }
}

export default FuelingExceptionAlertModel;
