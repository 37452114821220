import { ALERTS_TYPE } from 'config';
import AlertWithIntervalModel from './AlertWithInterval';
import isNumber from 'lodash/isNumber';

class PowerDisconnectTrackerAlertModel extends AlertWithIntervalModel {
  constructor(item) {
    super({
      ...item,
      isIntervalActive: isNumber(item.interval) ? Boolean(item.interval) : true,
      interval: isNumber(item.interval) ? item.interval : 10 * 60 * 1000,
    });

    this.urlType = 'power-disconnect';
    this.title = 'Power Disconnect (Tracker)';
    this.description = 'Alert is triggered when the device loses its connection to power.';
    this.type.reInitialize(ALERTS_TYPE.POWER_DISCONNECT_TRACKER);
  }
}

export default PowerDisconnectTrackerAlertModel;
