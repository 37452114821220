import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { PATHS } from 'config';

import type { MapStore, CustomersStore } from 'stores';

import withCollapseStorage from 'hocs/withCollapseStorage';

import CloseButton from 'components/Button/CloseButton';
import SecondaryPanelEstimateSTDetails from './SecondaryPanelEstimateSTDetails';
import SecondaryPanelEstimateSTSummary from './SecondaryPanelEstimateSTSummary';

import CustomerDomain from '../../../CustomerDomain';

import { Collapse } from 'antd';
import { ChevronIcon } from 'assets';
import SecondaryPanelEstimateSTCosts from './SecondaryPanelEstimateSTCosts';


enum collapsePanels {
  Summary = 'summary',
  EstimateDetails = 'estimate-details',
  EstimateCosts = 'estimate-costs',
}

interface IProps extends RouteComponentProps<null>{
  customersStore: CustomersStore;
  mapStore?: MapStore;
  collapseKeys: string[];

  onCollapseChange(values: string | string[]): void;

}

@inject(({ customersStore, devicesMapStore: { mapStore } }) => ({
  customersStore,
  mapStore,
}))
@observer
class SecondaryPanelEstimateST extends Component<IProps> {

  get selectedCustomer () {
    const {
      customersStore: {
        selectedCustomer,
      },
    } = this.props;
    return selectedCustomer;
  }
  handleCloseClick = () => {
    const {
      history: { push },
      customersStore: {
        selectedCustomerId,
      },
    } = this.props;

    push(PATHS.MAP.CUSTOMER.replace(':customerId', selectedCustomerId));
  };

  render() {
    const {
      collapseKeys,
      onCollapseChange,
    } = this.props;

    const selectedCustomer = this.selectedCustomer;

    if (!selectedCustomer.selectedEstimate) {
      return null;
    }

    return <div className="SecondaryPanelEstimate">
      <div className="SecondaryPanelEstimate-header">
        <div className="SecondaryPanelEstimate-name">
          <div className="SecondaryPanelEstimate-integration">Service Titan estimate</div>
          <div className="SecondaryPanelEstimate-basic">
            <div className="SecondaryPanelEstimate-icon">
              <CustomerDomain
                domain={selectedCustomer.domain}
                size={24}
              />
            </div>
            <div
              className="SecondaryPanelEstimate-info"
            >
              <div className="SecondaryPanelEstimate-id">
                {selectedCustomer.selectedEstimate.id}
              </div>
              <div className="SecondaryPanelEstimate-title">
                {selectedCustomer.selectedEstimate.name}
              </div>
            </div>
          </div>
        </div>
        <div className="SecondaryPanelEstimate-close">
          <CloseButton onClick={this.handleCloseClick} />
        </div>
      </div>

      <div className="SecondaryPanelEstimate-content">
        <Collapse
          activeKey={collapseKeys}
          expandIcon={() => (
            <span className="anticon anticon-right ant-collapse-arrow">
              <ChevronIcon />
            </span>
          )}
          expandIconPosition={'left'}
          onChange={onCollapseChange}
        >
          <Collapse.Panel header={'Summary'} key={collapsePanels.Summary} className="SecondaryPanelEstimate-Block">
            <SecondaryPanelEstimateSTSummary />
          </Collapse.Panel>
          <Collapse.Panel header={'Estimate Details'} key={collapsePanels.EstimateDetails} className="SecondaryPanelEstimate-Block">
            <SecondaryPanelEstimateSTDetails />
          </Collapse.Panel>
          <Collapse.Panel header={'Estimate Costs'} key={collapsePanels.EstimateCosts} className="SecondaryPanelEstimate-Block">
            <SecondaryPanelEstimateSTCosts />
          </Collapse.Panel>
        </Collapse>
      </div>
    </div>;
  }
}

export default withRouter(withCollapseStorage(SecondaryPanelEstimateST, 'map-secondary-panel-estimate-qb-collapse', Object.values(collapsePanels)));
