import React, { FC, Fragment } from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  className?: string;
  groups: Vehicle.IVehicleMembershipGroup[];
}

const VehiclesTableGroups: FC<IProps> = ({ className, groups }) => {
  const isMoreThanOneGroup = groups.length > 1;
  const cn = classNames('VehiclesTableGroups', className, {
    'VehiclesTableGroups--withGroupsList': isMoreThanOneGroup,
  });

  return (
    <div className={cn}>
      {Boolean(groups.length) ? (
        <div className="VehiclesTableGroups-wrapper">
          <p className="VehiclesTableGroups-firstGroup">{groups[0].description}</p>
          {Boolean(isMoreThanOneGroup) && (
            <Fragment>
              <p className="VehiclesTableGroups-moreGroups">+ {groups.length - 1} more...</p>
              <ul className="VehiclesTableGroups-list">
                {groups.map(({ description }, index) => {
                  return (
                    <li className="VehiclesTableGroups-item" key={index}>
                      {description}
                    </li>
                  );
                })}
              </ul>
            </Fragment>
          )}
        </div>
      ) : (
        <div className="VehiclesTableGroups-noGroups">-</div>
      )}
    </div>
  );
};

export default VehiclesTableGroups;
