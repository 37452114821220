import PlayerViewModel from './Player/Player';
import RequestMediaCamerasType from './RequestMedia/RequestMediaCamerasType';
import RequestMediaTimeSegment from './RequestMedia/RequestMediaTimeSegment';
import { SimpleField } from 'models/Fields';
import type MediaRequestCheckout from './index';

class RequestMedia {
  timeSegment: RequestMediaTimeSegment;
  cameraType: RequestMediaCamerasType;
  player: PlayerViewModel;
  context: MediaRequestCheckout;
  clipName: SimpleField<string>;

  constructor(context) {
    this.context = context;
    this.timeSegment = new RequestMediaTimeSegment(this.context.vehicle.details.camera.provider);
    this.cameraType = new RequestMediaCamerasType();
    this.player = new PlayerViewModel(this.cameraType, this.timeSegment, this.context.vehicle.details.camera);
    this.clipName = new SimpleField<string>('');
  }
}

export default RequestMedia;
