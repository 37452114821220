import React, { FC, Fragment, memo, useCallback, useMemo, useRef } from 'react';
import { Circle } from 'react-google-maps';
import isEqual from 'lodash/isEqual';
import { geozoneOptions } from 'utils';

interface IProps {
  color: string;
  draggable?: boolean;
  position: Locations.ILocation;
  radius: number;
  setPosition: (coord: { index: number; lat: number; lng: number }) => void;
  onMouseMove: (e: google.maps.MouseEvent) => void;
}

const EditableCircleGeozone: FC<IProps> = ({ color, draggable = true, position, radius, setPosition, onMouseMove }) => {
  const circle = useRef(null);

  const options = useMemo(() => geozoneOptions(color), [color]);

  const handleDrag = useCallback(() => {
    const currentCenter = circle?.current?.getCenter?.();
    if (currentCenter) {
      const lat = currentCenter.lat();
      const lng = currentCenter.lng();
      setPosition({ index: 0, lat, lng });
    }
  }, [setPosition]);

  return (
    <Fragment>
      <Circle
        ref={circle}
        draggable={draggable}
        center={position}
        radius={radius}
        options={{ ...options, strokeWeight: 5, fillOpacity: 0.3, strokeOpacity: 1, zIndex: 25 }}
        onDrag={handleDrag}
        onMouseMove={onMouseMove}
      />
    </Fragment>
  );
};

export default memo(EditableCircleGeozone, isEqual);
