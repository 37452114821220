import React from 'react';
import Modal from 'components/Modal';

import { CloseIcon } from 'assets';
import './styles.scss';

interface IProps {
  link: string;
  onClose: () => void;
  isOpen: boolean;
}

const InspectionPrintModal: React.FC<IProps> = ({ link, onClose, isOpen }) => {
  return (
    <Modal isModalOpen={isOpen} onClose={onClose} withoutCloseIcon>
      <div className="InspectionPrintModal">
        <div className="InspectionPrintModal-header">
          <div onClick={onClose} className="InspectionPrintModal-headerClose">
            <CloseIcon width={16} height={16} />
          </div>
        </div>
        {link && <embed type="application/pdf" src={link} className="InspectionPrintModal-pdf" />}
      </div>
    </Modal>
  );
};

export default InspectionPrintModal;
