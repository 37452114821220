import React from 'react';

const ErrorMessage: React.FC = () => (
  <div className="Search-results">
    <div className="Search-resultsContainer Search-resultsContainer--noResults">
      <p className="Search-noResultsMessage">Oops, something went wrong... Please try again.</p>
    </div>
  </div>
);

export default ErrorMessage;