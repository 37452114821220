import React, { FC } from 'react';
import './styles.scss';

interface IProps {
  date: string;
}

const MapVehiclePanelEventOutOfRange: FC<IProps> = ({ date }) => {
  return (
    <div className="MapVehiclePanelEventOutOfRange">
      <p className="MapVehiclePanelEventOutOfRange-text">No Data</p>
      <p className="MapVehiclePanelEventOutOfRange-text">Vehicle Did Not Exist for Time Range</p>
      <p className="MapVehiclePanelEventOutOfRange-date">Original Device Communication: {date}</p>
    </div>
  );
};

export default MapVehiclePanelEventOutOfRange;
