import axios, { AxiosResponse } from 'axios';
import { RequestType } from './RequestsType';
import { TOKEN_COOKIE_NAME, USER_ACCOUNT_ID } from 'config/cookie';
import isBoolean from 'lodash/isBoolean';
import { getBrowserInfo, getCookie, handleErrors, tokenManager } from 'utils';
import queryString from 'query-string';

import type { IRequestOptions, default as IHttpRequest } from 'interfaces/services/RepositoryService/IHttpRequest';
import EventsBus from '../EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

const ExcludeUrlsFromRefresh = ['auth/token'];

class HttpRequest implements IHttpRequest {
  private requestType: RequestType = RequestType.GET;
  private url: string = '';
  private clientAppInfo: string = '';
  private accountId: string = '';

  constructor() {
    this.clientAppInfo = getBrowserInfo();
    this.accountId = getCookie(USER_ACCOUNT_ID) || '';
  }

  public get = (url: string) => {
    this.url = url;
    this.requestType = RequestType.GET;

    return this;
  };

  public post = (url: string) => {
    this.url = url;
    this.requestType = RequestType.POST;

    return this;
  };

  public put = (url: string) => {
    this.url = url;
    this.requestType = RequestType.PUT;

    return this;
  };

  public patch = (url: string) => {
    this.url = url;
    this.requestType = RequestType.PATCH;

    return this;
  };

  public delete = (url: string) => {
    this.url = url;
    this.requestType = RequestType.DELETE;

    return this;
  };

  public send = async (data = {}, options: IRequestOptions = {}) => {
    options.contentType = options.contentType || 'application/json';
    options.requireAuth = isBoolean(options.requireAuth) ? options.requireAuth : true;
    options.responseType = options.responseType || null;
    options.accept = options.accept || null;
    options.onProgress = options.onProgress || null;
    let token = getCookie(TOKEN_COOKIE_NAME);

    if (options.requireAuth && !token) {
      await tokenManager.refresh((data) => (token = data.token));
    }

    const Authorization = options.requireAuth
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {};

    const response = options.responseType ? { responseType: options.responseType } : {};
    const acceptOption = options.accept ? { accept: options.accept } : {};
    const onDownloadProgress = options.onProgress ? { onDownloadProgress: options.onProgress } : {};
    const security = this.accountId
      ? { 'X-Security-Account-ID': this.accountId, 'X-Security-App-Name': 'web' }
      : { 'X-Security-App-Name': 'web' };

    const req = {
      headers: {
        ...Authorization,
        ...acceptOption,
        ...security,
        'Content-Type': options.contentType,
        'Client-App-Info': this.clientAppInfo,
      },
      method: this.requestType,
      url: this.url,
      ...response,
      ...onDownloadProgress,
    };

    const params = HttpRequest.getParams(data, this.requestType);

    Object.assign(
      req,
      [RequestType.GET, RequestType.DELETE].includes(this.requestType) ? { params } : { data: params }
    );

    try {
      const res: AxiosResponse = await axios(req);

      return res.data;
    } catch (error) {
      if (error?.message?.includes('401') && !ExcludeUrlsFromRefresh.some((url) => this.url.includes(url))) {
        try {
          await tokenManager.refresh((data) => (token = data.token));

          if (!token) {
            return;
          }

          const Authorization = {
            Authorization: `Bearer ${token}`,
          };

          const res: AxiosResponse = await axios({
            ...req,
            headers: {
              ...req.headers,
              ...Authorization,
            },
          });

          return res.data;
        } catch (error) {
          EventsBus.get().trigger(APP_EVENTS.ERROR.UNEXPECTED, error);
        }
      }

      if (this.requestType === RequestType.GET && options.triggerError) {
        EventsBus.get().trigger(APP_EVENTS.ERROR.UNEXPECTED, error);
      }
      return handleErrors(error);
    }
  };

  private static getParams(data = {}, requestType) {
    return requestType === RequestType.GET ? new URLSearchParams(queryString.stringify(data)) : data;
  }
}

export default HttpRequest;
