import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import { get } from 'lodash';

import { PATHS } from 'config';
import { validateGroupName, getValidGroupName } from 'utils';
import type { RouterStore } from 'stores/RouterStore';
import type { GroupsAdmin } from 'stores/Admin/Groups';
import type { PersistenceStore } from 'stores';

import Button from 'components/Button';
import BackButton from 'components/BackButton';
import DeleteGroupModal from '../DeleteGroup';
import ErrorNotification from 'components/ErrorNotification';
import GroupTableVehicles from './GroupTableVehicles';
import GroupTableVehiclesAll from './GroupTableVehiclesAll';
import Input from 'components/Input';
import SearchableGroupSelect from 'containers/Select/SearchableGroupSelect';
import TableActions from 'components/Table/Actions';

import './styles.scss';

interface IMatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  className?: string;
  groupsAdmin?: GroupsAdmin;
  routerStore?: RouterStore;
  persistenceStore?: PersistenceStore;
}

interface IState {
  addedVehiclesIds: string[];
  deleteGroup: boolean;
  initialGroupName: string;
  initialGroupVehiclesIds: string[];
  isGroupDetailsUpdated: boolean;
  isGroupDetailsTouched: boolean;
  isGroupNameValidAndUpdated: boolean;
  removedVehiclesIds: string[];
  isSent: boolean;
}

@inject(({ adminStore: { groupsAdmin }, routerStore, persistenceStore }) => ({
  groupsAdmin,
  routerStore,
  persistenceStore,
}))
@observer
class GroupDetails extends Component<IProps, IState> {
  state = {
    addedVehiclesIds: [],
    deleteGroup: false,
    initialGroupVehiclesIds: [],
    initialGroupName: '',
    isGroupDetailsUpdated: false,
    isGroupNameValidAndUpdated: false,
    removedVehiclesIds: [],
    isSent: false,
    isGroupDetailsTouched: false,
  };

  onUnload = (e) => {
    const { isGroupDetailsUpdated } = this.state;

    if (isGroupDetailsUpdated) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  componentDidMount() {
    this.setGroup();
    window.addEventListener('beforeunload', this.onUnload);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.groupId) {
      this.setGroup();
    }
    this.persistSelectedGroup();
  }

  persistSelectedGroup = () => {
    const {
      groupsAdmin: { selectedGroup },
    } = this.props;
    if (selectedGroup) {
      this.props.persistenceStore.updateSelectedGroup({
        label: selectedGroup.description,
        value: selectedGroup.groupId,
      });
    }
  };

  componentWillUnmount() {
    const {
      groupsAdmin: { resetSelectedGroup },
    } = this.props;

    resetSelectedGroup();
    window.removeEventListener('beforeunload', this.onUnload);
  }

  get groupId() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    return id;
  }

  get errorMessage(): string {
    const {
      groupsAdmin: { selectedGroup },
    } = this.props;
    let message = '';

    if (selectedGroup.repositoryGroups.putState.error) {
      message = selectedGroup.repositoryGroups.putState.error;
    } else if (!validateGroupName(selectedGroup.description)) {
      message = 'Not valid name';
    }

    return message;
  }

  setGroup = async () => {
    const {
      groupsAdmin: { setSelectedGroup },
      history: { push },
    } = this.props;

    try {
      await setSelectedGroup(this.groupId);

      this.setInitialGroupName();
      this.setInitialGroupVehicles();
      this.setInitialGroupDetails();
    } catch (e) {
      push({ pathname: PATHS.NOT_FOUND, state: { from: PATHS.ADMIN.TABLES.GROUPS.INDEX } });
    }
  };

  setInitialGroupName = () => {
    const {
      groupsAdmin: { selectedGroup },
    } = this.props;
    const initialGroupName = get(selectedGroup, 'description', '');

    this.setState({ initialGroupName, isGroupNameValidAndUpdated: false });
  };

  setInitialGroupVehicles = () => {
    const {
      groupsAdmin: { selectedGroupVehiclesList },
    } = this.props;

    this.setState({
      initialGroupVehiclesIds: selectedGroupVehiclesList.map((groupVehicle) => groupVehicle.id),
    });
  };

  setInitialGroupDetails = () => {
    this.setState({
      addedVehiclesIds: [],
      removedVehiclesIds: [],
      isGroupDetailsUpdated: false,
      isGroupDetailsTouched: false,
    });
  };

  setSuccessSend = () => {
    this.setState({ isSent: true });
    setTimeout(() => {
      this.setState({ isSent: false });
    }, 500);
  };

  resetErrorMessage = () => {
    const {
      groupsAdmin: { selectedGroup },
    } = this.props;

    selectedGroup.repositoryGroups.putState.reset();
  };

  handleNameChange = (e) => {
    const name = e.target.value;
    const {
      groupsAdmin: {
        selectedGroup: { updateDescription },
      },
    } = this.props;
    const allowedGroupName = getValidGroupName(name);

    if (allowedGroupName.length <= 32) {
      updateDescription(allowedGroupName);
      this.checkGroupNameUpdates(allowedGroupName);
    }
  };

  handleGroupVehiclesListChange = () => {
    const {
      groupsAdmin: { selectedGroupVehiclesList },
    } = this.props;
    const { initialGroupVehiclesIds } = this.state;
    const selectedGroupVehiclesIds = selectedGroupVehiclesList.map((groupVehicle) => groupVehicle.id);
    const addedVehiclesIds = selectedGroupVehiclesIds.filter(
      (selectedVehicleId) => !initialGroupVehiclesIds.includes(selectedVehicleId)
    );
    const removedVehiclesIds = initialGroupVehiclesIds.filter(
      (initialVehicleId) => !selectedGroupVehiclesIds.includes(initialVehicleId)
    );

    this.setState(
      {
        addedVehiclesIds,
        removedVehiclesIds,
      },
      () => {
        this.checkGroupDetailsUpdates();
      }
    );
  };

  checkGroupNameUpdates = (name: string) => {
    const isGroupNameValid = validateGroupName(name);
    const { initialGroupName } = this.state;
    const {
      groupsAdmin: {
        selectedGroup: { description },
      },
    } = this.props;
    const isGroupNameValidAndUpdated = isGroupNameValid && initialGroupName !== description;

    this.setState({ isGroupNameValidAndUpdated }, this.checkGroupDetailsUpdates);
  };

  checkGroupDetailsUpdates = () => {
    const { addedVehiclesIds, removedVehiclesIds, isGroupNameValidAndUpdated } = this.state;
    const {
      groupsAdmin: { selectedGroup },
    } = this.props;

    if (
      validateGroupName(selectedGroup.description) &&
      (addedVehiclesIds.length || removedVehiclesIds.length || isGroupNameValidAndUpdated)
    ) {
      this.setState({ isGroupDetailsUpdated: true, isGroupDetailsTouched: true });
    } else {
      this.setState({ isGroupDetailsUpdated: false, isGroupDetailsTouched: true });
    }
  };

  handleClickOnSave = async () => {
    const {
      groupsAdmin: {
        updateGroupVehiclesList,
        selectedGroup: { save },
        repositoryGroupsAssignDevice,
        repositoryGroupsUnAssignDevice,
      },
    } = this.props;
    const { removedVehiclesIds, addedVehiclesIds, isGroupNameValidAndUpdated } = this.state;

    if (isGroupNameValidAndUpdated) {
      await save();
      this.setState({ isGroupNameValidAndUpdated: false });
      this.setInitialGroupName();
    }
    await updateGroupVehiclesList(removedVehiclesIds, addedVehiclesIds);
    if (repositoryGroupsAssignDevice.putState.error || repositoryGroupsUnAssignDevice.putState.error) {
      this.handleClickOnCancel();
    } else {
      this.setSuccessSend();
      this.setInitialGroupDetails();
      this.setInitialGroupVehicles();
    }
  };

  handleClickOnCancel = async () => {
    const {
      groupsAdmin: {
        assignVehicleToSelectedGroup,
        unAssignVehicleFromSelectedGroup,
        selectedGroup: { updateDescription },
      },
    } = this.props;
    const { addedVehiclesIds, initialGroupVehiclesIds, initialGroupName } = this.state;

    /* assign back removed vehicles */
    initialGroupVehiclesIds.forEach((id) => assignVehicleToSelectedGroup(id));
    /* unassign back added vehicles */
    addedVehiclesIds.forEach((id) => {
      if (!initialGroupVehiclesIds.includes(id)) {
        unAssignVehicleFromSelectedGroup(id);
      }
    });
    updateDescription(initialGroupName);
    this.setInitialGroupDetails();
    this.resetErrorMessage();
  };

  handleClickOnCloseError = () => {
    const {
      groupsAdmin: {
        selectedGroup: { repositoryGroups },
        updateSelectedGroupVehiclesListRequestStatus,
      },
    } = this.props;

    repositoryGroups.putState.setError(null);
    updateSelectedGroupVehiclesListRequestStatus.setError(null);
  };

  closeDeleteModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      deleteGroup: false,
    }));
  };

  openDeleteModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      deleteGroup: true,
    }));
  };

  redirectToGroupsPage = () => {
    this.props.routerStore.history.push(PATHS.ADMIN.TABLES.GROUPS.INDEX);
  };

  handleDeleteGroupClick = () => {
    this.openDeleteModal();
  };

  handleCancelDeleteGroupModal = () => {
    this.closeDeleteModal();
  };

  handleSuccessDeleteGroupModal = () => {
    this.closeDeleteModal();
    this.redirectToGroupsPage();
  };

  handleGroupChange = ({ value }) => {
    const { push } = this.props.routerStore.history;
    push(PATHS.ADMIN.TABLES.GROUPS.GROUP.replace(':id', value));
  };

  render() {
    const {
      groupsAdmin: { selectedGroup, updateSelectedGroupVehiclesListRequestStatus },
    } = this.props;
    const { isGroupDetailsUpdated, initialGroupName, isSent, isGroupDetailsTouched } = this.state;
    const isError =
      (selectedGroup && selectedGroup.repositoryGroups.putState.error) ||
      updateSelectedGroupVehiclesListRequestStatus.error;
    const isSending =
      (selectedGroup && selectedGroup.repositoryGroups.putState.loading) ||
      updateSelectedGroupVehiclesListRequestStatus.loading;
    const disabled = !isGroupDetailsUpdated || isSending;

    return (
      Boolean(selectedGroup) && (
        <>
          <div className="GroupDetails">
            <div className="GroupDetails-container">
              <div className="GroupDetails-header">
                <div className="GroupDetails-backButton">
                  <BackButton link={PATHS.ADMIN.TABLES.GROUPS.INDEX}>Back to Group List</BackButton>
                </div>
                <div className="GroupDetails-selectGroup">
                  <span className="GroupDetails-selectGroupCopy">View/Edit Group</span>
                  <div className="GroupDetails-selectGroupDropdown">
                    <SearchableGroupSelect
                      handleChange={this.handleGroupChange}
                      value={{ label: initialGroupName, value: selectedGroup.groupId }}
                      persistChange
                    />
                  </div>
                </div>
              </div>
              <div className={'GroupDetails-content'}>
                {isError && (
                  <div className="GroupDetails-errorNotification">
                    <ErrorNotification name={initialGroupName} onClose={this.handleClickOnCloseError} />
                  </div>
                )}
                <div className="GroupDetails-preferences">
                  <div className="GroupDetails-preferencesRow">
                    <p className="GroupDetails-preferences-label">Group Name</p>
                    <div className="GroupDetails-inputName GroupDetails-inputName">
                      <Input
                        placeholder="Group Name"
                        onChange={this.handleNameChange}
                        key="group_name"
                        value={selectedGroup.description}
                        name={'group_name'}
                        maxLength={128}
                        error={this.errorMessage}
                      />
                    </div>
                  </div>
                  <div className="GroupDetails-preferencesRow">
                    <p className="GroupDetails-preferences-label">group ID</p>
                    <Input
                      className="GroupDetails-preferences-input GroupDetails-inputName"
                      placeholder="Group ID"
                      onChange={() => {
                        return;
                      }}
                      value={selectedGroup.groupId}
                      key="group_id"
                      disabled
                    />
                  </div>
                  <div className="GroupDetails-preferencesRow GroupDetails-preferencesRow--actions">
                    <TableActions
                      items={[{ text: 'Delete Group', onClick: this.handleDeleteGroupClick }]}
                      type="page"
                    />
                  </div>
                  <div className="GroupDetails-preferencesRow GroupDetails-preferencesRow--buttons">
                    <div className="GroupDetails-preferencesButton GroupDetails-preferencesButton--cancel">
                      <Button
                        className="Button--cancel Button--cancelColorLynch"
                        disabled={disabled && !isGroupDetailsTouched}
                        inline
                        onClick={this.handleClickOnCancel}
                        title="Cancel"
                      />
                    </div>
                    <div className="GroupDetails-preferencesButton GroupDetails-preferencesButton--save">
                      <Button
                        className="Button--apply"
                        disabled={disabled}
                        inline
                        onClick={this.handleClickOnSave}
                        sending={isSending}
                        sent={isSent}
                        title="Save"
                      />
                    </div>
                  </div>
                </div>
                <div className="GroupDetails-lists">
                  <div className="GroupDetails-list GroupDetails-vehicles">
                    <GroupTableVehicles
                      className="GroupDetails-list-table GroupDetails-list-table--noBorder"
                      name={initialGroupName}
                      onChange={this.handleGroupVehiclesListChange}
                    />
                  </div>
                  <div className="GroupDetails-list GroupDetails-members">
                    <div className="GroupDetails-list-label">Add Group Members</div>
                    <div className="GroupDetails-list-table">
                      <GroupTableVehiclesAll
                        className="GroupDetails-list-table GroupDetails-list-table--noBorder GroupDetails-list-table--noMargin"
                        name={selectedGroup.description}
                        onChange={this.handleGroupVehiclesListChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DeleteGroupModal
            groupId={selectedGroup && selectedGroup.groupId}
            groupName={selectedGroup && selectedGroup.description}
            isOpen={this.state.deleteGroup}
            onCancel={this.handleCancelDeleteGroupModal}
            onSuccess={this.handleSuccessDeleteGroupModal}
          />
          <Prompt when={isGroupDetailsUpdated} message="Changes that you made may not be saved." />
        </>
      )
    );
  }
}

export default GroupDetails;
