import { action, computed, observable } from 'mobx';
import { CronOption } from 'models/Alerts/CronOption';
import { repositoryService } from 'services';
import type EntityRepository from 'services/RepositoryService/EntityRepository';

class CronOptionsModel {
  @observable items: CronOption[] = [];
  repository: EntityRepository;

  constructor() {
    this.repository = repositoryService
      .get('alerts')
      .entity('custom')
      .entity('cron-options');
  }

  getInitialCronOptions = async () => {
    if (!this.repository.getState.success) {
      await this.getCronOptions();
    }
  };

  @action getCronOptions = async () => {
    const items = await this.repository.get();

    if (this.repository.getState.success) {
      this.items = items.map((item) => new CronOption(item));
    }
  };

  @computed get values() {
    return this.items;
  }

  @computed get names() {
    return this.items.reduce((acc, current) => {
      return {
        ...acc,
        [current.name]: current.description,
      };
    }, {});
  }
}

export default CronOptionsModel;
