import React, { Fragment } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import type { History } from 'history';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import {
  ALERTS_PRIMARY_NAV_ICON,
  DASHCAM_PRIMARY_NAV,
  FEATURES,
  MANAGEMENT_PRIMARY_NAV,
  PATHS,
  REPORTS_PRIMARY_NAV,
  ACL,
} from 'config';

import SideBarContainer from 'containers/SideBar';
import Map from 'containers/Map';
import DashCams from 'containers/DashCams/ProtectedRoute';
import ProtectedRoute from 'containers/ProtectedRoute';
import Layout from './containers/Layout';
import Dispatch from './containers/Dispatch';
import Admin from './containers/Admin';
import NotFound from './containers/NotFound';
import SessionExpired from './containers/Auth/SessionExpired';
import Reports from './containers/Reports';
import Alerts from './containers/Alerts';
import ControlledRoute from './components/ControlledRoute';
import AuthRoutes from './containers/Auth';
import MapInPopup from 'components/Map/MapInPopup';
import IntegrationsConnecting from 'containers/Admin/Integrations/Connecting';
import Maintenance from './containers/Maintenance';
import Inspections from './containers/Inspections';

interface IProps {
  history?: History;
}

const renderDevTool = () => {
  if (process.env.NODE_ENV !== 'production') {
    const DevTools = require('mobx-react-devtools').default;
    return <DevTools />;
  }
};

export const App = ({ history }: IProps) => {
  return (
    <Router history={history}>
      <DndProvider backend={Backend}>
        <Switch>
          <Route path={PATHS.AUTH.INDEX} component={AuthRoutes} />
          {/*<ProtectedRoute renderUnauthorized={() => <Redirect to={PATHS.AUTH.LOGIN} />}>*/}
          <ProtectedRoute renderUnauthorized={() => <SessionExpired />}>
            <Fragment>
              <Switch>
                <Layout
                  renderSideBar={() => (
                    <Switch>
                      <Route path={PATHS.AUTH.LOGIN} component={null} />
                      <Route path={PATHS.MAP.BASIC} component={null} />
                      <Route path={PATHS.ADMIN.INTEGRATIONS.CONNECTING.INDEX} component={null} />
                      <Route path="/" component={SideBarContainer} />
                    </Switch>
                  )}
                >
                  <Switch>
                    <Redirect exact from="/" to={PATHS.MAP.INDEX} />
                    <Route path={PATHS.MAP.BASIC} component={MapInPopup} />
                    <Route path={PATHS.MAP.INDEX} component={Map} />
                    <ControlledRoute acls={[ALERTS_PRIMARY_NAV_ICON]} path={PATHS.ALERTS.INDEX} component={Alerts} />
                    <ControlledRoute acls={[DASHCAM_PRIMARY_NAV]} path={PATHS.DASHCAMS.INDEX} component={DashCams} />
                    <ControlledRoute rule={FEATURES.dispatch} path={PATHS.DISPATCH.INDEX} component={Dispatch} />
                    <Route path={PATHS.ADMIN.INTEGRATIONS.CONNECTING.INDEX} component={IntegrationsConnecting} />
                    <Redirect exact from={PATHS.ADMIN.INDEX} to={PATHS.ADMIN.TABLES.INDEX} />
                    <ControlledRoute
                      acls={[MANAGEMENT_PRIMARY_NAV]}
                      rule={FEATURES.admin}
                      path={PATHS.ADMIN.INDEX}
                      component={Admin}
                    />
                    <ControlledRoute
                      acls={[REPORTS_PRIMARY_NAV]}
                      rule={FEATURES.reports}
                      path={PATHS.REPORTS.INDEX}
                      component={Reports}
                    />
                    <ControlledRoute
                      acls={[ACL.MAINTENANCE.BASIC.READ]}
                      path={PATHS.MAINTENANCE.INDEX}
                      component={Maintenance}
                    />
                    <ControlledRoute
                      acls={[ACL.INSPECTIONS.BASIC.READ]}
                      path={PATHS.INSPECTIONS.INDEX}
                      component={Inspections}
                    />
                    <Route path={PATHS.NOT_FOUND} component={NotFound} />
                    <Redirect from="*" to={PATHS.NOT_FOUND} />
                  </Switch>
                </Layout>
              </Switch>
            </Fragment>
            {renderDevTool()}
          </ProtectedRoute>
        </Switch>
      </DndProvider>
    </Router>
  );
};

export default App;
