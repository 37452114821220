import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import noop from 'lodash/noop';

import type { IWEXCard, IWEXCardTable } from 'interfaces/stores/Integrations/IWEXCardAssociations';

import type { WEXCardAssociationsTableStore } from 'stores';
import { STORAGE_ITEMS, WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS } from 'config';
import { setJSONItemToStorage, getJSONItemFromStorage } from 'utils';

import type { ColumnsType } from 'antd/es/table';
import CustomTable from 'components/Table/CustomTable';
import Notification from 'components/Notification';
import Button from 'components/Button';
import SideModal from 'components/SideModal';
import ConfirmationModal from 'components/Admin/ConfirmationModal';
import WEXFuelCardApplyCardData from 'components/Admin/Integrations/WEXIntegration/WEXFuelCardApplyCardData';
import WEXCardAssociationsVehicle from './WEXCardAssociationsVehicle';
import WEXCardAssociationsFuelType from './WEXCardAssociationsFuelType';
import WEXCardAssociationsTankCapacity from './WEXCardAssociationsTankCapacity';
import type { IWEXIntegrationModel } from 'interfaces/stores/Integrations/IIntegration';
import WEXIntegrationModel from 'stores/Integrations/WEXIntegration';

import './styles.scss';

interface IProps {
  isEditCardOpen: boolean;
  closeEditCardAssociations: () => void;
  wexCardAssociationsTableStore?: WEXCardAssociationsTableStore;
  integration: IWEXIntegrationModel;
}

interface IState {
  isFilterOpen: boolean;
  isApplyCardData: boolean;
  isNavigateAway: boolean;
}

const editColumnsMap = {
  [WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS.CARD_ID]: {
    text: 'Card Id',
    isDisabled: false,
  },
  [WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS.NAME]: {
    text: 'Name',
    isDisabled: false,
  },
  [WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS.DRIVER_NAME]: {
    text: 'Driver Name',
    isDisabled: false,
  },
  [WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS.VEHICLE_ASSOCIATION]: {
    text: 'Vehicle Association',
    isDisabled: false,
  },
  [WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS.FUEL_TYPE]: {
    text: 'Fuel Type',
    isDisabled: false,
  },
  [WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS.TANK_CAPACITY]: {
    text: 'Tank Capacity',
    isDisabled: false,
  },
};

@inject(({ wexCardAssociationsTableStore }) => ({ wexCardAssociationsTableStore }))
@observer
class WEXCardAssociationsTable extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isFilterOpen: getJSONItemFromStorage(STORAGE_ITEMS.admin.integrations.filterOpenStatus, true),
      isApplyCardData: false,
      isNavigateAway: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (this.props.isEditCardOpen !== prevProps.isEditCardOpen && this.props.isEditCardOpen) {
      const {
        wexCardAssociationsTableStore: { fuelTypeOptions },
      } = this.props;

      fuelTypeOptions.fetch();
    }

    if (this.props.isEditCardOpen !== prevProps.isEditCardOpen && !this.props.isEditCardOpen) {
      const {
        wexCardAssociationsTableStore: { fuelCards },
      } = this.props;

      fuelCards.cancel();
    }
  }

  getColumns = (): ColumnsType<IWEXCardTable> => {
    const {
      wexCardAssociationsTableStore: {
        columns,
        sortedColumn: { field, order },
        fuelTypeOptions: { items },
      },
    } = this.props;

    const allColumns = [
      {
        title: 'Card Id',
        dataIndex: WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS.CARD_ID,
        defaultSortOrder: field === 'cardUniqueCode' ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 155,
      },
      {
        title: 'Vehicle/Asset ID',
        dataIndex: WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS.NAME,
        defaultSortOrder: field === 'name' ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 150,
      },
      {
        title: 'Driver Name (WEX)',
        dataIndex: WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS.DRIVER_NAME,
        defaultSortOrder: field === 'driverName' ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 150,
      },
      {
        title: 'Vehicle Association',
        dataIndex: WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS.VEHICLE_ASSOCIATION,
        width: 160,
        render: (_, { assetId, asset, fuelDetails }) => (
          <WEXCardAssociationsVehicle assetId={assetId} asset={asset} fuelDetails={fuelDetails} />
        ),
        className: 'ant-table-cell--no-filter',
        hiddenSearch: true,
      },
      {
        title: 'Fuel Type',
        dataIndex: WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS.FUEL_TYPE,
        render: (_, { asset: { fuelType }, assetId }) => (
          <WEXCardAssociationsFuelType fuelType={fuelType} options={items} disabled={assetId.value === '0'} />
        ),
        className: 'ant-table-cell--no-filter',
        hiddenSearch: true,
      },
      {
        title: 'Tank Capacity',
        dataIndex: WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS.TANK_CAPACITY,
        render: (_, { asset: { tankCapacity }, assetId }) => (
          <WEXCardAssociationsTankCapacity tankCapacity={tankCapacity} disabled={assetId.value === '0'} />
        ),
        width: 120,
        className: 'ant-table-cell--no-filter',
        hiddenSearch: true,
      },
    ];

    const filteredColumns = [];

    columns.forEach((column) => {
      if (column.isSelected) {
        filteredColumns.push(allColumns.find((col) => col.dataIndex === column.value));
      }
    });

    return [...filteredColumns];
  };

  handleOpenFilter = (isFilterOpen: boolean) => {
    this.setState({ isFilterOpen });
    setJSONItemToStorage(STORAGE_ITEMS.admin.integrations.filterOpenStatus, isFilterOpen);
  };

  onCloseNotification = () => {
    const {
      wexCardAssociationsTableStore: {
        fuelCards: { repositoryFuelCardsBatch },
      },
    } = this.props;

    repositoryFuelCardsBatch.patchState.reset();
  };

  cancelEdit = () => {
    const {
      closeEditCardAssociations,
      wexCardAssociationsTableStore: { fuelCards },
    } = this.props;

    fuelCards.cancel();
    closeEditCardAssociations();
  };

  saveChanges = () => {
    const {
      wexCardAssociationsTableStore: { fuelCards },
    } = this.props;

    if (fuelCards.isVehicleAssignChanged) {
      this.setState({ isApplyCardData: true });
    } else {
      fuelCards.save();
    }
  };

  saveFuelCardsChanges = async () => {
    const {
      wexCardAssociationsTableStore: {
        fuelCards: { save },
      },
    } = this.props;

    await save();
    this.closeApplyCardData();
  };

  closeCardEdit = () => {
    const {
      closeEditCardAssociations,
      wexCardAssociationsTableStore: {
        fuelCards: { isVehicleAssignChanged, isAssetsChanged },
      },
    } = this.props;

    if (isVehicleAssignChanged || isAssetsChanged) {
      this.setState({ isNavigateAway: true });
    } else {
      closeEditCardAssociations();
    }
  };

  closeNavigateAway = () => {
    this.setState({ isNavigateAway: false });
  };

  submitNavigationAway = () => {
    const { closeEditCardAssociations } = this.props;

    this.setState({ isNavigateAway: false }, closeEditCardAssociations);
  };

  closeApplyCardData = () => {
    this.setState({ isApplyCardData: false });
  };

  getAllWEXs = (data) => {
    const {
      wexCardAssociationsTableStore: { getAllWEXCards },
      integration,
    } = this.props;

    getAllWEXCards({ ...data, integrationId: integration.id });
  };

  render() {
    const {
      isEditCardOpen,
      integration,
      wexCardAssociationsTableStore: {
        wexCardsTableSource,
        sortData,
        pagination,
        fuelCards: {
          total,
          repositoryFuelCardsBatch,
          repositoryFuelCards,
          applyData,
          isVehicleAssignChanged,
          isAssetsChanged,
        },
        setSearchColumn,
        setPagination,
        setSortedColumn,
        setColumns,
        searchColumns,
        searchData,
        columns,
      },
    } = this.props;
    const { isFilterOpen, isApplyCardData, isNavigateAway } = this.state;

    const tableHeight = isFilterOpen ? 'calc(100vh - 300px)' : 'calc(100vh - 270px)';

    return (
      <SideModal
        className="WEXIntegrationSideBar"
        visible={isEditCardOpen}
        title={
          <div className="WEXIntegrationSideBar-Header">
            <span className="WEXIntegrationSideBar-HeaderTitle">Fuel Card Vehicle Associations</span>
            {integration instanceof WEXIntegrationModel && (
              <span className="WEXIntegrationSideBar-HeaderAccount">{integration.accountName}</span>
            )}
          </div>
        }
        setVisible={this.closeCardEdit}
        mask
        width={950}
      >
        <div className="WEXCardsAssociations">
          <CustomTable<WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS, IWEXCard>
            editColumnsMap={editColumnsMap}
            columns={this.getColumns()}
            dataSource={wexCardsTableSource}
            getData={this.getAllWEXs}
            loading={repositoryFuelCards.getState.loading}
            onColumnsChange={setColumns}
            onColumnsSearch={setSearchColumn}
            onColumnSort={setSortedColumn}
            isFilterActive={isFilterOpen}
            onFilterOpen={this.handleOpenFilter}
            sortedColumn={sortData}
            searchColumns={searchColumns}
            searchParams={searchData}
            selectedColumns={toJS(columns)}
            onPaginationChange={setPagination}
            pagination={pagination}
            triggerUpdate={repositoryFuelCardsBatch.patchState.success}
            total={total}
            totalTitle={total ? `${total} Fuel Cards` : ''}
            tableHeight={tableHeight}
            emptyText="No Fuel Card Data Found"
          />
          <div className="WEXCardsAssociations-controls">
            <div className="WEXCardsAssociations-controls--cancel">
              <Button title="Cancel" className="Button Button--cancel Button-inline" onClick={this.cancelEdit} />
            </div>
            <div className="WEXCardsAssociations-controls--save">
              <Button
                title="Save"
                className="Button Button--apply Button-inline"
                onClick={this.saveChanges}
                disabled={!isVehicleAssignChanged && !isAssetsChanged}
                sending={repositoryFuelCardsBatch.patchState.loading}
              />
            </div>
          </div>
          <WEXFuelCardApplyCardData
            isApplyCardData={isApplyCardData}
            closeApplyCardData={this.closeApplyCardData}
            saveFuelCards={this.saveFuelCardsChanges}
            applyData={applyData}
          />
          <ConfirmationModal
            className="WEXCardsAssociations-navigationAway"
            title="Continue without Saving? "
            isOpen={isNavigateAway}
            onSubmit={this.submitNavigationAway}
            onCancel={this.closeNavigateAway}
            applyButtonTitle="Yes, Continue"
            cancelButtonTitle="No, Cancel"
          >
            Changes made to the Fuel Card Vehicle Associations will not be saved.
          </ConfirmationModal>
          {repositoryFuelCardsBatch.patchState.success && (
            <Notification
              type="success"
              text="Data Successfully Assigned."
              title="Success!"
              onClose={this.onCloseNotification}
              autoClose
            />
          )}
          {repositoryFuelCardsBatch.patchState.error && (
            <Notification
              type="error"
              text="Something went wrong, please try again."
              title="Failure!"
              onClose={this.onCloseNotification}
              autoClose
            />
          )}
        </div>
      </SideModal>
    );
  }
}

export default WEXCardAssociationsTable;
