import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import type { FieldProps } from 'formik';
import find from 'lodash/find';

import type Driver from 'models/Driver';
import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';

import './styles.scss';

interface IProps extends FieldProps {
  error?: string;
  className?: string;
  list: Driver[];
  onChange?: (value: string) => void;
  value?: string;
}

const DriversList: FC<IProps> = ({ className, list, error, onChange, form, field, value }) => {
  const formattedDriversList = useMemo(
    () =>
      list.map(({ driverId, driverName }) => ({
        key: driverId,
        value: driverId,
        text: driverName,
        label: driverName,
      })),
    [list]
  );

  const cn = classNames('DriversList', className, {
    'DriversList--error': Boolean(error),
  });

  const handlePhoneChange = (driverId) => {
    const { setFieldValue } = form;
    const selectedDriver: Driver = find(list, { driverId });

    setFieldValue('phone', selectedDriver.driverPhone);
  };

  const handleChange = (value) => {
    onChange(value);

    if (field) {
      const { setFieldTouched } = form;

      field.onChange({
        target: {
          name: field.name,
          value,
        },
      });

      setFieldTouched(field.name);
      handlePhoneChange(value);
    }
  };

  return (
    <div className={cn}>
      <div className="DriversList-dropdown">
        <SearchableStaticSelect
          value={formattedDriversList.find(item => item.value === value)}
          values={formattedDriversList}
          handleChange={handleChange}
          placeholder="Driver Name"
          emptySearchResults="Driver Name Not Found"
        />
      </div>
      {Boolean(error) && <p className="DriversList-error">{error}</p>}
    </div>
  );
};

DriversList.defaultProps = {
  onChange: () => true,
};

export default DriversList;
