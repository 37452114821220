export const getIcon = (icon?: string | JSX.Element, iconPosition?: string) => {
  const iconProps = {
    prefix: undefined,
    suffix: undefined,
  };

  if (iconPosition === 'left') {
    iconProps.prefix = icon;
  } else {
    iconProps.suffix = icon;
  }

  return iconProps;
};
