import { computed } from 'mobx';
import TableBase from 'stores/TableBase';
import type ApplyTasks from './ApplyTasks';
import type { IEditColumns } from 'models/Tables/IEditColumns';
import { MAINTENANCE_APPLY_TASKS_ROW_LOADING_ID, MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS, STORAGE_ITEMS } from 'config';
import type { ISortColumn } from 'models/Tables/ISortColumn';

const initialColumns = [
  { value: MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.VEHICLE_NAME, isSelected: true },
  { value: MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.LAST_SERVICE, isSelected: true },
  { value: MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.CURRENT_ODOMETER, isSelected: true },
  { value: MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.CURRENT_ENGINE_HOURS, isSelected: true },
  { value: MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.NEXT_SERVICE_DUE_IN_ON, isSelected: true },
  { value: MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.SERVICE_DUE_IN, isSelected: true },
];

const initialSortedColumn: ISortColumn = {
  field: 'name',
  order: 'ascend',
};

const storageNames = {
  columns: STORAGE_ITEMS.maintenance.applyTasks.columns,
  sortedColumn: STORAGE_ITEMS.maintenance.applyTasks.sortedColumn,
  pagination: STORAGE_ITEMS.maintenance.applyTasks.pagination,
  searchInColumn: STORAGE_ITEMS.maintenance.applyTasks.searchInColumn,
};

class ApplyTasksTable extends TableBase<IEditColumns<MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS>> {
  context: ApplyTasks;

  constructor(context: ApplyTasks) {
    super({
      columns: initialColumns,
      sortedColumn: initialSortedColumn,
      storageNames,
    });

    this.context = context;
  }

  @computed get source() {
    return this.context.tasks.map(
      ({
        model: {
          asset,
          save,
          odometer,
          engine,
          service,
          repository: { createState },
          isValid,
        },
        id,
      }) => {
        if (asset.id.value === MAINTENANCE_APPLY_TASKS_ROW_LOADING_ID) {
          return {
            className: 'ant-table-row--loading',
            id: `${id}_${asset.id.value}`,
            assetId: asset.id.value,
            key: `${id}_${asset.id.value}`,
          };
        }

        return {
          [MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.VEHICLE_NAME]: asset.name.value,
          odometer: odometer.config,
          engHours: engine.config,
          serviceTime: service.config,
          [MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.CURRENT_ODOMETER]: asset.details.odometer.value,
          [MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.CURRENT_ENGINE_HOURS]: asset.details.engHours.value,
          [MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.NEXT_SERVICE_DUE_IN_ON]: '-',
          [MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS.SERVICE_DUE_IN]: '-',
          remove: this.context.removeTask,
          create: save,
          loading: createState.loading,
          successSave: createState.success,
          errorSave: createState.error,
          className: createState.loading || createState.success ? 'ant-table-row--disable' : '',
          id: `${id}_${asset.id.value}`,
          assetId: asset.id.value,
          key: `${id}_${asset.id.value}`,
          isValid,
        };
      }
    );
  }
}

export default ApplyTasksTable;
