import React, { useMemo } from 'react';

import Marker from 'components/Map/Marker';
import markerIcon from './GeozoneCirclePathMarkerIcon';

const GeozoneCirclePathMarker = ({ position, draggable = true, onDrag, color, colorName }) => {
  const icon = useMemo(() => {
    return `data:image/svg+xml;charset=utf-8,${escape(markerIcon({ color, colorName }))}`;
  }, [color, colorName]);

  return (
    <Marker
      icon={icon}
      draggable={draggable}
      anchor={new google.maps.Point(10, 10)}
      position={position}
      onDrag={onDrag}
      onDragEnd={onDrag}
    />
  );
};

export default GeozoneCirclePathMarker;
