import { CAMERA_JOURNAL_STATE } from 'config';
import { ListField, ToggleField } from 'models/Fields';

class DashcamStatusCameraDetails {
  availableCameraTypes: ListField<string>;
  isBusy: ToggleField;
  audioEnabled: ToggleField;

  constructor() {
    this.availableCameraTypes = new ListField();
    this.isBusy = new ToggleField(false);
    this.audioEnabled = new ToggleField(false);
  }

  get states() {
    let result = CAMERA_JOURNAL_STATE.IN_OUT;

    if (this.availableCameraTypes.value.length === 1) {
      result =
        this.availableCameraTypes.value[0].value === 'outside' ? CAMERA_JOURNAL_STATE.OUT : CAMERA_JOURNAL_STATE.IN;
    }

    return result;
  }
}

export default DashcamStatusCameraDetails;
