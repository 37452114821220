import { SimpleField } from 'models/Fields';
import type { IScheduledReportAppliesToItem } from './IScheduledReport';

class ScheduledReportAppliesToItem implements IScheduledReportAppliesToItem {
  displayName: SimpleField<string>;
  id: SimpleField<string>;

  constructor({ displayName = null, id = null }) {
    this.displayName = new SimpleField(displayName);
    this.id = new SimpleField(id);
  }
}

export default ScheduledReportAppliesToItem;
