import React from 'react';
import classNames from 'classnames';

import Button from 'components/Button';

import { CheckIcon } from 'assets';
import dashcamsAd from 'assets/temp-images/dashcams-ad.png';
import './styles.scss';

interface IProps {
  className?: string;
}

const DashCamsAdPage: React.FC<IProps> = ({ className }) => {
  const cn = classNames('DashCamsAdPage', className);
  const handleClick = () => {
    window.open('');
  };

  return (
    <div className={cn}>
      <h1 className="DashCamsAdPage-title">Seeing is Believing.</h1>
      <h2 className="DashCamsAdPage-subTitle">Add Dashcams to your account and watch videos here.</h2>
      <ul className="DashCamsAdPage-options">
        <li className="DashCamsAdPage-option">
          <span className="DashCamsAdPage-optionCheckbox">
            <CheckIcon fill="#fff" width="26px" height="26px" />
          </span>
          <span className="DashCamsAdPage-optionText">Fleet Protection</span>
        </li>
        <li className="DashCamsAdPage-option">
          <span className="DashCamsAdPage-optionCheckbox">
            <CheckIcon fill="#fff" width="26px" height="26px" />
          </span>
          <span className="DashCamsAdPage-optionText">Incident Verification</span>
        </li>
        <li className="DashCamsAdPage-option">
          <span className="DashCamsAdPage-optionCheckbox">
            <CheckIcon fill="#fff" width="26px" height="26px" />
          </span>
          <span className="DashCamsAdPage-optionText">Fleet Protection</span>
        </li>
      </ul>
      <div className="DashCamsAdPage-media">
        <img className="DashCamsAdPage-image" src={dashcamsAd} alt="Ad media" />
      </div>
      <div className="DashCamsAdPage-button">
        <Button className="Button--large" title="See More..." inline onClick={handleClick} />
      </div>
    </div>
  );
};

export default DashCamsAdPage;
