import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { ADMIN_GROUPS_TABLE_COLUMNS, PATHS } from 'config';
import type { GroupsAdmin } from 'stores/Admin/Groups';
import { sortByAlphabet } from 'utils';

import GroupTableVehiclesEmpty from 'components/Admin/GroupTableVehicles/GroupTableVehiclesEmpty';
import GroupTableVehiclesHeader from './GroupTableVehiclesHeader';
import GroupTableVehiclesRemove from 'components/Admin/GroupTableVehicles/GroupTableVehiclesButton/GroupTableVehiclesRemove';
import Table from 'components/Table';

import './styles.scss';

interface IProps {
  className?: string;
  groupsAdmin?: GroupsAdmin;
  name: string;
  onChange: (id?: string | string[]) => void;
}

@inject(({ adminStore: { groupsAdmin } }) => ({
  groupsAdmin,
}))
@observer
class GroupTableVehicles extends Component<IProps> {
  get columns() {
    return [
      {
        title: 'Vehicle Name',
        dataIndex: ADMIN_GROUPS_TABLE_COLUMNS.NAME,
        defaultSortOrder: 'ascend',
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => sortByAlphabet(a.name, b.name),
        render: (_, { id, name }) => <Link to={PATHS.ADMIN.TABLES.VEHICLES.VEHICLE.replace(':id', id)}>{name}</Link>,
        fixed: true,
        width: 200,
      },
      {
        title: 'CP #',
        dataIndex: ADMIN_GROUPS_TABLE_COLUMNS.CP,
      },
      {
        title: 'Remove',
        width: 100,
        render: (_, { id }) => {
          return <GroupTableVehiclesRemove onClick={this.handleRemove} id={id} />;
        },
      },
    ];
  }

  handleTableChange() {
    return void 0;
  }

  handleRemove = (id: string) => {
    const {
      groupsAdmin: { unAssignVehicleFromSelectedGroup },
      onChange,
    } = this.props;

    unAssignVehicleFromSelectedGroup(id);
    onChange(id);
  };

  render() {
    const {
      groupsAdmin: { getSelectedGroupRequestStatus, filteredSelectedGroupVehiclesList, selectedGroupVehiclesList },
      name,
      onChange,
    } = this.props;
    const hasDevices = Boolean(filteredSelectedGroupVehiclesList.length);
    const tableMargin = hasDevices ? `370px` : `390px`;
    const cn = classNames('GroupTableVehicles', {
      'GroupTableVehicles--empty': !hasDevices,
    });

    return (
      <div className={cn}>
        <Table
          className={this.props.className}
          // @ts-ignore
          columns={this.columns}
          dataSource={filteredSelectedGroupVehiclesList}
          showHeader={hasDevices}
          loading={getSelectedGroupRequestStatus.loading}
          onChange={this.handleTableChange}
          scroll={{
            y: `calc(100vh - ${tableMargin})`,
          }}
          showSorterTooltip={false}
          pagination={false}
          title={() => <GroupTableVehiclesHeader onRemove={onChange} />}
          locale={{
            emptyText: !selectedGroupVehiclesList.length ? <GroupTableVehiclesEmpty name={name} /> : undefined,
          }}
        />
      </div>
    );
  }
}

export default GroupTableVehicles;
