import React, { useMemo } from 'react';
import classNames from 'classnames';

interface IProps {
  arrowColor?: string;
  className?: string;
  color?: string;
  heading?: number;
  size?: 'small' | 'medium' | 'large' | number;
}

const StatusMarker: React.FC<IProps> = ({ arrowColor, className, size = 10, color, heading }) => {
  const cn = classNames('StatusMarker', className, {
    [`StatusMarker--${size}`]: Boolean(size),
  });
  const sizeNumber = useMemo(() => {
    switch (size) {
      case 'small':
        return 14;
      case 'medium':
        return 16;
      case 'large':
        return 18;
      default:
        return size;
    }
  }, [size]);

  return (
    <div className={cn}>
      <svg
        display="block"
        width={sizeNumber}
        height={sizeNumber}
        viewBox={`0 0 20 20`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="10" fill={`#${color}`} />
        {heading && (
          <path
            d="M11.1969 15L11.1969 8.93128L15 8.93128L10.1103 4.04154L5.22051 8.93128L9.02364 8.93128L9.02364 15L11.1969 15Z"
            fill={`#${arrowColor}`}
            transform={`rotate(${heading}, 10, 10)`}
          />
        )}
      </svg>
    </div>
  );
};

export default StatusMarker;
