import { PERSON_LOGIN_CREDENTIALS } from 'config';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { SimpleField, ToggleField } from 'models/Fields';
import { repositoryService } from 'services';

const CUSTOM_ERROR_MESSAGE = 'Allowed to resend in';

class PersonDetailsContactsLoginCredentials {
  login: SimpleField<string>;
  newLogin: SimpleField<string>;
  state: SimpleField<PERSON_LOGIN_CREDENTIALS>;
  resendTimeout: SimpleField<number>;
  repositoryCode: IEntityRepository;
  repositoryVerify: IEntityRepository;
  errorMessage: SimpleField<string>;
  isShowCountDown: ToggleField;

  private paramKey: string;
  private interval: number;

  constructor(login, option, id) {
    this.repositoryCode = repositoryService.get('users').entity(option.entity).entity('code').entity(id.toString());
    this.repositoryVerify = repositoryService.get('users').entity(option.entity).entity(id.toString());

    this.paramKey = option.param;
    this.login = new SimpleField(login);
    this.newLogin = new SimpleField('');
    this.state = new SimpleField(PERSON_LOGIN_CREDENTIALS.EDIT);
    this.resendTimeout = new SimpleField(0);
    this.errorMessage = new SimpleField(null);
    this.isShowCountDown = new ToggleField(false);
  }

  get data() {
    return this.login.value;
  }

  get isUpdated() {
    return this.login.isUpdated;
  }

  sendCode = async () => {
    const response = await this.repositoryCode.get({ [this.paramKey]: this.newLogin.value });

    if (this.repositoryCode.getState.success) {
      const timeout = this.getTimeout(response.allowToResendAt);

      this.resendTimeout.set(timeout);
      this.state.set(PERSON_LOGIN_CREDENTIALS.VALIDATE);
      this.countDown();
    } else {
      const errorMessage = new Error(this.repositoryCode.getState.error).message.replace('Error: ', '');
      const timestampResend = errorMessage.match(/\d{13}/g);

      if (timestampResend) {
        this.resendTimeout.set(this.getTimeout(Number(timestampResend[0])));
        this.countDown();
        this.errorMessage.set(CUSTOM_ERROR_MESSAGE);
      } else {
        this.errorMessage.set(errorMessage);
      }

      this.isShowCountDown.toggle(!!timestampResend);
    }
  };

  verify = async (code: string) => {
    await this.repositoryVerify.patch({ code, [this.paramKey]: this.newLogin.value });

    if (this.repositoryVerify.patchState.success) {
      this.login.set(this.newLogin.value);
      this.newLogin.set('');
      this.state.set(PERSON_LOGIN_CREDENTIALS.EDIT);
    }
  };

  reset = () => {
    this.login.reset();
    this.state.reset();
  };

  stopCountDown = () => {
    clearInterval(this.interval);
    if (this.isShowCountDown.value) {
      this.errorMessage.set(null);
    }
  };

  private countDown = () => {
    this.interval = setInterval(() => {
      this.resendTimeout.set(this.resendTimeout.value - 1000);

      if (this.resendTimeout.value <= 0) {
        this.stopCountDown();
      }
    }, 1000);
  };

  private getTimeout = (resendAt: number) => {
    const timeout = resendAt - Date.now();
    return Math.round(timeout / 1000) * 1000;
  };
}

export default PersonDetailsContactsLoginCredentials;
