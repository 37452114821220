import MaintenanceBaseTracker from './Base';

class MaintenanceEngineTracker extends MaintenanceBaseTracker {
  hoursDueInDays: number;

  constructor({ engineHoursDueInDays = 0, engHoursConfig = {} }) {
    super({ config: engHoursConfig });

    this.hoursDueInDays = engineHoursDueInDays;
  }
}

export default MaintenanceEngineTracker;
