import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { DevicesStore, MapStore } from 'stores';

import Icon from 'components/Icon';
import { LockedIcon, UnlockedIcon } from 'assets';

import MapButton from 'components/Map/MapButton';

interface IProps {
  mapStore?: MapStore;
  devicesStore?: DevicesStore;
}

@inject(({ devicesMapStore: { devicesStore, mapStore } }) => ({
  devicesStore,
  mapStore,
}))
@observer
class LockMapControl extends Component<IProps> {
  handleClick = () => {
    this.props.mapStore.toggleLocked();
  };

  render() {
    const {
      mapStore: { locked, streetViewOpened },
      devicesStore: { showTimelineDetails, selectedDevice },
    } = this.props;

    return (
      !streetViewOpened && (
        <div>
          <MapButton
            className="MapButton--lockControl"
            hoverText="Lock Map View"
            iconComponent={<Icon Icon={locked ? LockedIcon : UnlockedIcon} size={20} />}
            onClick={this.handleClick}
            disabled={selectedDevice && showTimelineDetails.value}
          />
        </div>
      )
    );
  }
}

export default LockMapControl;
