import React, { useRef, useCallback, useEffect } from 'react';
import classNames from 'classnames';

import type { mapLabelSizeType } from 'stores';
import type { Geozone } from 'models';
import { scrollToSelectedItem } from 'utils';
import GeozonesItem from './GeozonesItem';
import DraggableCard from 'components/DragableCard';

import './styles.scss';

interface IProps {
  className?: string;
  disabled?: boolean;
  dragPinnedGeozones?: (dragIndex: number, hoverIndex: number) => void;
  itemSize?: mapLabelSizeType;
  list: Geozone[];
  onClick?: (arg: number) => void;
  onItemPin?: (arg: string) => void;
  pinnedGeozonesIdsList: string[];
  selectedGeozoneId?: string;
}

const GeozonesList: React.FC<IProps> = ({
  className,
  disabled,
  dragPinnedGeozones,
  itemSize,
  list,
  onClick,
  onItemPin,
  pinnedGeozonesIdsList,
  selectedGeozoneId,
}) => {
  const geozonesListRef: React.RefObject<HTMLDivElement> = useRef(null);
  const selectedGeozoneItemRef: React.RefObject<HTMLLIElement> = useRef(null);
  const handleScrollToSelectedVehicle = useCallback(() => {
    scrollToSelectedItem(geozonesListRef, selectedGeozoneItemRef);
  }, [geozonesListRef, selectedGeozoneItemRef]);

  useEffect(handleScrollToSelectedVehicle, [geozonesListRef, selectedGeozoneItemRef, selectedGeozoneId]);
  const cn = classNames('GeozonesList', className, {
    'GeozonesList--disabled': disabled,
  });

  return (
    <div className={cn} ref={geozonesListRef}>
      {list.length ? (
        <ul className="GeozonesList-list">
          {list.map(({ name, id, color, tag }: Geozone) => {
            const Geozone = (
              <GeozonesItem
                color={color}
                disabled={disabled}
                id={id}
                name={name}
                onClick={onClick}
                onItemPin={onItemPin}
                pinnedGeozonesIdsList={pinnedGeozonesIdsList}
                size={itemSize}
                tag={tag}
              />
            );
            const pinnedGeozoneIndex = pinnedGeozonesIdsList.indexOf(id.toString());

            return (
              <li
                className="GeozonesList-item"
                key={id}
                ref={selectedGeozoneId === id.toString() ? selectedGeozoneItemRef : null}
              >
                {pinnedGeozoneIndex !== -1 ? (
                  <DraggableCard id={id} index={pinnedGeozoneIndex} key={id} moveCard={dragPinnedGeozones}>
                    {Geozone}
                  </DraggableCard>
                ) : (
                  Geozone
                )}
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="GeozonesList-noResults">No results found</div>
      )}
    </div>
  );
};

export default GeozonesList;
