import React from 'react';

import { PlusSquareFilled } from '@ant-design/icons';

import GroupTableVehiclesButton from '../index';

interface IProps {
  id?: string;
  text?: string;
  disabled?: boolean;

  onClick?(id?: string): void;
}

const GroupTableVehiclesAdd: React.FC<IProps> = (props) => {
  return (
    <GroupTableVehiclesButton {...props}>
      <PlusSquareFilled />
    </GroupTableVehiclesButton>
  );
};

export default GroupTableVehiclesAdd;
