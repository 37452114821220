import React from 'react';
import { QBIcon, STIcon, WEXIcon } from 'assets';
import type { IntegrationDomain } from 'interfaces/stores/Integrations/IIntegration';


interface IProps {
  domain: IntegrationDomain;
  size: number;
}

const CustomerDomain: React.FC<IProps> = ({ domain, size }) => {
  switch (domain) {
    case 'QUICK_BOOKS_DESKTOP':
    case 'QUICK_BOOKS':
      return <QBIcon width={size} height={size} />;
    case 'SERVICE_TITAN':
      return <STIcon width={size} height={size} />;
    case 'WEX':
      return <WEXIcon width={size} height={size} />;
    default:
      return null;
  }
};

export default CustomerDomain;
