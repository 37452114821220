import React from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';

import SearchResultsList from './SearchResultsList';
import NoSearchResults from './NoSearchResults';
import ErrorMessage from './ErrorMessage';

import { CloseIcon } from 'assets';
import './styles.scss';

interface ISearchProps {
  devicesWithDriversList?: Array<{ driverId: string; deviceId: string }>;
  error?: null | string;
  existingDevicesIds?: string[];
  getSearchResults: (searchQuery: string) => void;
  isSearchActive?: boolean;
  noResults?: boolean;
  onChange: (searchQuery: string) => void;
  onChangeSearchState?: (isSearchActive: boolean) => void;
  onClick?: () => void;
  resetSearch: (toSaveQuery?: boolean) => void;
  results?: Search.ISearchResults;
  searchQuery: string;
}

class Search extends React.Component<ISearchProps> {
  searchRef: React.RefObject<HTMLFormElement>;
  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);

    this.fireSearch = debounce(this.fireSearch, 500);
    this.searchRef = React.createRef();
    this.inputRef = React.createRef();
  }

  componentDidMount(): void {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount(): void {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = ({ target }): void => {
    const { current: searchNode } = this.searchRef;
    const { isSearchActive } = this.props;

    if (isSearchActive && searchNode && !searchNode.contains(target)) {
      this.resetSearch(true);
    }
  };

  setActive = (): void => {
    const { onChangeSearchState } = this.props;

    onChangeSearchState(true);
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  unsetActive = () => {
    const { onChangeSearchState } = this.props;

    onChangeSearchState(false);
  };

  fireSearch = (searchQuery: string): void => {
    const { getSearchResults } = this.props;

    getSearchResults(searchQuery);
  };

  resetSearch = (toSaveQuery?: boolean) => {
    const { resetSearch } = this.props;

    if (toSaveQuery) {
      resetSearch(true);
    } else {
      resetSearch();
    }
    this.unsetActive();
  };

  handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = target;
    const { onChange, onChangeSearchState } = this.props;

    onChange(value);

    if (value.length >= 3) {
      this.fireSearch(value);
    } else {
      // @ts-ignore
      this.fireSearch.cancel();
    }

    if (!value) {
      this.resetSearch();
      onChangeSearchState(true);
    }
  };

  render() {
    const {
      isSearchActive,
      searchQuery,
      results,
      noResults,
      devicesWithDriversList = [],
      existingDevicesIds = [],
      error,
    } = this.props;
    const cn = classNames('Search Search--inCollapsedSideBar', {
      'Search--isActive': isSearchActive,
    });
    const closeIconCn = classNames('Search-closeIcon', {
      'Search-closeIcon--hidden': !searchQuery.length,
    });
    const placeholderText = isSearchActive ? 'Search Vehicles and more' : 'Search';
    const toShowSearchResults = Boolean(isSearchActive && !Object.values(results).every((result) => !result.length));
    const toShowNoSearchResults = Boolean(isSearchActive && noResults);

    return (
      <form className={cn} ref={this.searchRef}>
        <div className="Search-inputField" onClick={this.setActive}>
          <input
            ref={this.inputRef}
            type="text"
            className="Search-input"
            placeholder={placeholderText}
            onFocus={this.setActive}
            onChange={this.handleChange}
            value={searchQuery}
          />
          <div
            className={closeIconCn}
            onClick={(e) => {
              e.stopPropagation();
              this.resetSearch();
            }}
          >
            <CloseIcon width="12px" height="12px" />
          </div>
        </div>
        {toShowSearchResults && (
          <div className="Search-results">
            <div className="Search-resultsContainer Search-resultsContainer--resultsList">
              <SearchResultsList
                results={results}
                searchQuery={searchQuery}
                devicesWithDriversList={devicesWithDriversList}
                existingDevicesIds={existingDevicesIds}
                onClick={this.resetSearch}
              />
            </div>
          </div>
        )}
        {toShowNoSearchResults && <NoSearchResults searchQuery={searchQuery} />}
        {error && <ErrorMessage />}
      </form>
    );
  }
}

export default Search;
