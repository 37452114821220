import type { IUserAccountContract } from 'interfaces/models/User/IUserAccounts';
import { SimpleField, ToggleField } from 'models/Fields';
import { capitalize } from 'utils';

class UserAccountModel {
  accountId: SimpleField<number>;
  description: SimpleField<string>;
  accountIdGts: SimpleField<string>;
  type: SimpleField<string>;
  disabled?: ToggleField;
  current?: ToggleField;
  locked?: ToggleField;

  constructor(data?: IUserAccountContract, isCurrent = false) {
    this.accountId = new SimpleField<number>(data?.accountId || null);
    this.description = new SimpleField<string>(data?.description || data?.accountIdGts || '');
    this.accountIdGts = new SimpleField<string>(data?.accountIdGts || '');
    this.type = new SimpleField<string>(capitalize(data?.planDescription || ''));
    this.disabled = new ToggleField(data?.isLocked || false);
    this.current = new ToggleField(isCurrent);
    this.locked = new ToggleField(data?.isLocked || false);
  }

  set = (data?: IUserAccountContract, isCurrent = false) => {
    this.accountId.set(data?.accountId || null);
    this.description.set(data?.description || data?.accountIdGts || '');
    this.accountIdGts.set(data?.accountIdGts || '');
    this.type.set(capitalize(data?.planDescription || ''));
    this.disabled.toggle(data?.isLocked || false);
    this.current.toggle(isCurrent);
    this.locked.toggle(data?.isLocked || false);
  };

  get contract(): IUserAccountContract {
    return {
      accountId: this.accountId.value,
      description: this.description.value,
      accountIdGts: this.accountIdGts.value,
      planDescription: this.type.value,
      isLocked: this.locked.value,
    };
  }
}

export default UserAccountModel;
