import React, { FC } from 'react';

import { ReportType } from 'models/Report/Report';

import RadioGroup from 'components/RadioGroup';

import './styles.scss';

interface IProps {
  title?: string;
  checkedValue: ReportType;
  onChange: (val: string) => void;
  byGroup: React.ReactNode;
  byVehicle: React.ReactNode;
  radioGroupName?: string;
}

const RadioSwitcher: FC<IProps> = ({
  checkedValue,
  onChange,
  byVehicle,
  byGroup,
  title,
  radioGroupName = 'reportMode',
}) => {
  const reportModeOptions = [
    {
      id: `${ReportType.byVehicle}${radioGroupName}`,
      label: radioGroupName === 'scheduledMode' ? 'Vehicle' : 'By Vehicle',
      name: radioGroupName,
      value: ReportType.byVehicle,
    },
    {
      id: `${ReportType.byGroup}${radioGroupName}`,
      label: radioGroupName === 'scheduledMode' ? 'Group' : 'By Group',
      name: radioGroupName,
      value: ReportType.byGroup,
    },
  ];
  return (
    <div className="RadioSwitcher">
      <div className="RadioSwitcher-tabs">
        {title && <p className="RadioSwitcher-tabs--title">{title}</p>}
        <RadioGroup checkedValue={checkedValue} onChange={onChange} radios={reportModeOptions} tabs />
      </div>
      <div className="RadioSwitcher-content">{checkedValue === ReportType.byVehicle ? byVehicle : byGroup}</div>
    </div>
  );
};

export default RadioSwitcher;
