import { getPastDay, getTodayEndDate, getTodayStartDate } from 'utils';
import timeStore from 'stores/TimeStore';

export enum MEDIA_LIBRARY_DATE_RANGE_OPTION {
  EXPIRING_SOON = 'expiringSoon',
}

export enum MEDIA_LIBRARY_DATE_RANGE_OPTION_TYPE {
  RANGE = 'range',
  PROPERTY = 'property',
}

export const optionsList = [
  {
    option: {
      action: false,
      isSelected: true,
      name: 'Today',
      range: {
        from: getTodayStartDate(timeStore.sessionTimezone).valueOf(),
        to: getTodayEndDate(timeStore.sessionTimezone).valueOf(),
      },
    },
    type: MEDIA_LIBRARY_DATE_RANGE_OPTION_TYPE.RANGE,
  },
  {
    option: {
      action: false,
      isSelected: false,
      name: 'Yesterday',
      range: {
        from: getPastDay(1, getTodayStartDate(timeStore.sessionTimezone)),
        to: getPastDay(1, getTodayEndDate(timeStore.sessionTimezone)),
      },
    },
    type: MEDIA_LIBRARY_DATE_RANGE_OPTION_TYPE.RANGE,
  },
  {
    option: {
      action: false,
      isSelected: false,
      name: 'Last 7 Days',
      range: {
        from: getPastDay(6, getTodayStartDate(timeStore.sessionTimezone)),
        to: getTodayEndDate(timeStore.sessionTimezone).valueOf(),
      },
    },
    type: MEDIA_LIBRARY_DATE_RANGE_OPTION_TYPE.RANGE,
  },
  {
    option: {
      action: false,
      isSelected: false,
      name: 'Last 30 Days',
      range: {
        from: getPastDay(29, getTodayStartDate(timeStore.sessionTimezone)),
        to: getTodayEndDate(timeStore.sessionTimezone).valueOf(),
      },
    },
    type: MEDIA_LIBRARY_DATE_RANGE_OPTION_TYPE.RANGE,
  },
  {
    option: {
      action: false,
      isSelected: false,
      name: 'This Month',
      range: {
        from: getTodayStartDate(timeStore.sessionTimezone)
          .startOf('month')
          .valueOf(),
        to: getTodayEndDate(timeStore.sessionTimezone).valueOf(),
      },
    },
    type: MEDIA_LIBRARY_DATE_RANGE_OPTION_TYPE.RANGE,
  },
  {
    option: {
      action: false,
      isSelected: false,
      name: 'Expiring Soon',
      range: { from: null, to: null },
      value: MEDIA_LIBRARY_DATE_RANGE_OPTION.EXPIRING_SOON,
    },
    type: MEDIA_LIBRARY_DATE_RANGE_OPTION_TYPE.PROPERTY,
  },
  {
    option: {
      action: true,
      isSelected: false,
      name: 'Custom range',
      range: { from: null, to: null },
    },
    type: MEDIA_LIBRARY_DATE_RANGE_OPTION_TYPE.RANGE,
  },
];
