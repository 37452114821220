import React, { CSSProperties } from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  active?: boolean;
  error?: boolean;
  Icon?: any;
  iconClassName?: string;
  disabled?: boolean;
  size?: number;
  src?: string;
  srcStyles?: CSSProperties;
}

const Icon: React.FC<IProps> = ({
  active = false,
  error = false,
  Icon = null,
  iconClassName = '',
  disabled = false,
  size = 15,
  src = '',
  srcStyles = null,
}) => {
  const IconCN = classNames('Icon-componentImage', iconClassName, {
    'Icon-componentImage--active': active,
    'Icon-componentImage--error': error,
    'Icon-componentImage--disabled': disabled,
    [`Icon-componentImage--${iconClassName}`]: iconClassName,
  });

  return (
    <div className="Icon">
      <div className="Icon-container">
        {Boolean(Icon) && <Icon className={IconCN} height={size} width={size} />}
        {Boolean(src) && <img className="Icon-image" src={src} style={srcStyles} alt="" />}
      </div>
    </div>
  );
};

export default Icon;
