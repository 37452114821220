import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { SCHEDULED_REPORTS_CREATE, SCHEDULED_REPORTS_TYPE_MODAL } from 'config';

import UserAccessLevelComponent from 'stores/acl/UserAccessLevelComponent';
import type { ReportsStore, ScheduledReportsStore } from 'stores';
import ScheduledReport from 'models/ScheduledReport/ScheduledReport';

import ScheduledReportModal from './ScheduledReportModal';
import Button from 'components/Button';
import ScheduledReportsTable from '../ScheduledReportsTable';

import './styles.scss';

interface IProps {
  scheduledReportsStore?: ScheduledReportsStore;
  reportsStore?: ReportsStore;
}

interface IState {
  titleModal: string;
  typeModal: SCHEDULED_REPORTS_TYPE_MODAL;
}

@inject(({ scheduledReportsStore, reportsStore }) => ({ scheduledReportsStore, reportsStore }))
@observer
class ScheduledReports extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      titleModal: null,
      typeModal: null,
    };
  }

  createScheduledReport = () => {
    const {
      scheduledReportsStore,
      reportsStore: { setSelectedReportId, listAllReportsSorted, getReportSubGroupDefaultReport },
    } = this.props;
    const reportId = getReportSubGroupDefaultReport(listAllReportsSorted[0].id);

    setSelectedReportId(reportId(''));
    this.setState({ titleModal: 'Create Scheduled Report', typeModal: SCHEDULED_REPORTS_TYPE_MODAL.CREATE });
    scheduledReportsStore.create();
  };

  editScheduledReport = async (report) => {
    const { scheduledReportsStore } = this.props;
    const scheduledReport = scheduledReportsStore.findById(report.key);

    if (scheduledReport?.reportParameters.report.id.value) {
      await this.preSelectDate(scheduledReport);
    }

    this.setState({ titleModal: 'Edit Scheduled Report', typeModal: SCHEDULED_REPORTS_TYPE_MODAL.EDIT });
    scheduledReportsStore.setItem(new ScheduledReport(scheduledReport.initialModel).initialize(scheduledReportsStore));
  };

  preSelectDate = async (scheduledReport) => {
    const {
      reportsStore: { setSelectedReportId, setExcludeInactive },
    } = this.props;

    await setSelectedReportId(scheduledReport.reportParameters.report.id.value);

    this.props.reportsStore.selectedReport.reportPreferences.device.set(
      scheduledReport.appliesTo.device.id.value,
      scheduledReport.appliesTo.device.displayName.value
    );
    this.props.reportsStore.selectedReport.reportPreferences.group.set(
      scheduledReport.appliesTo.group.id.value,
      scheduledReport.appliesTo.group.displayName.value
    );
    this.props.reportsStore.selectedReport.reportPreferences.driver.set(
      scheduledReport.reportParameters.driver.id.value,
      scheduledReport.reportParameters.driver.displayName.value
    );

    this.props.reportsStore.selectedReport.reportPreferences.geozone.set(
      scheduledReport.reportParameters.geozone.id.value,
      scheduledReport.reportParameters.geozone.displayName.value
    );
    this.props.reportsStore.selectedReport.reportPreferences.option.set(
      scheduledReport.reportParameters.option.id.value,
      scheduledReport.reportParameters.option.displayName.value
    );
    this.props.reportsStore.selectedReport.reportPreferences.tag.set(
      scheduledReport.reportParameters.tag.id.value,
      scheduledReport.reportParameters.tag.displayName.value
    );
    setExcludeInactive(scheduledReport.reportParameters.hideInactive.value);
  };

  duplicateScheduledReport = async (report) => {
    const { scheduledReportsStore } = this.props;
    const scheduledReport = scheduledReportsStore.findById(report.key);

    if (scheduledReport?.reportParameters.report.id.value) {
      await this.preSelectDate(scheduledReport);
    }

    this.setState({ titleModal: 'Duplicate Scheduled Report', typeModal: SCHEDULED_REPORTS_TYPE_MODAL.DUPLICATE });
    scheduledReportsStore.setItem(
      new ScheduledReport({
        ...scheduledReport.initialModel,
        description: `Copy of ${scheduledReport.initialModel.description}`,
        id: null,
      }).initialize(scheduledReportsStore)
    );
  };

  handleCloseModal = () => {
    const { scheduledReportsStore } = this.props;

    scheduledReportsStore.setItem(null);
    this.setState({ titleModal: null, typeModal: null });
  };

  render() {
    const {
      scheduledReportsStore: { item },
    } = this.props;

    const { titleModal, typeModal } = this.state;

    return (
      <div>
        <div className="ScheduledReports-header">
          <div>
            <h4 className="ScheduledReports-title">Scheduled Reports</h4>
            <p className="ScheduledReports-description">
              Schedule reports to be delivered to an email address on a recurring schedule. View, create, edit, and
              delete scheduled reports.
            </p>
          </div>
          <div>
            <UserAccessLevelComponent requiredAccessLevel={[SCHEDULED_REPORTS_CREATE]}>
              <Button title="Schedule a Report" className="Button--apply" onClick={this.createScheduledReport} />
            </UserAccessLevelComponent>
          </div>
        </div>
        <div className="ScheduledReports-table">
          <ScheduledReportsTable
            handleCreate={this.createScheduledReport}
            handleEdit={this.editScheduledReport}
            handleDuplicate={this.duplicateScheduledReport}
          />
        </div>
        <div className="ScheduledReports-modal">
          <ScheduledReportModal
            isOpen={Boolean(item)}
            onClose={this.handleCloseModal}
            title={titleModal}
            type={typeModal}
            scheduledReport={item}
          />
        </div>
      </div>
    );
  }
}

export default ScheduledReports;
