import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { getTimeInTimeZone } from 'utils';
import { INSPECTION_RESULTS, DATE_TIME_FORMATS, PATHS, ACL } from 'config';

import timeStore from 'stores/TimeStore';
import validateAccessLevel from 'stores/acl/validator';
import InspectionResults from 'models/Inspections/Models/InspectionResults';

import InspectionPerformedPanel from 'containers/Inspections/InspectionPerformedPanel';
import InspectionResultsCell from 'components/Inspections/ResultsCell';
import Button from 'components/Button';

import './styles.scss';

interface IProps {
  createdBy: string;
  creationTime: number;
  name: string;
  failedItemsCount: number;
  status: INSPECTION_RESULTS;
  deviceId: string;
  selectInspection: () => void;
}

const MapVehiclePanelLastInspection: React.FC<IProps> = ({
  createdBy,
  creationTime,
  name,
  failedItemsCount,
  status,
  deviceId,
  selectInspection,
}) => {
  const [results, setResults] = useState({ text: '', status: null });
  const [isInspectionPanelOpen, setIsInspectionPanelOpen] = useState(false);
  const inspectionTime = getTimeInTimeZone(creationTime, timeStore.sessionTimezone).format(
    DATE_TIME_FORMATS.FULL_DATE_AT_TIME
  );

  useEffect(() => {
    const results = new InspectionResults(status, failedItemsCount);
    setResults(results.results);
  }, [status, failedItemsCount]);

  const openInspectionPanel = async () => {
    await selectInspection();
    setIsInspectionPanelOpen(true);
  };

  const closePanel = () => {
    setIsInspectionPanelOpen(false);
  };

  return (
    <div className="MapVehiclePanelLastInspection">
      <div className="MapVehiclePanelLastInspection-values">
        <div className="MapVehiclePanelLastInspection-row">
          <div className="MapVehiclePanelLastInspection-label">Start Date/Time</div>
          <div className="MapVehiclePanelLastInspection-value">
            {validateAccessLevel([ACL.INSPECTIONS.BASIC.UPDATE]) ? (
              <Button className="Button--link" title={inspectionTime} onClick={openInspectionPanel} />
            ) : (
              inspectionTime
            )}
          </div>
        </div>
        <div className="MapVehiclePanelLastInspection-row">
          <div className="MapVehiclePanelLastInspection-label">Form</div>
          <div className="MapVehiclePanelLastInspection-value">{name}</div>
        </div>
        <div className="MapVehiclePanelLastInspection-row">
          <div className="MapVehiclePanelLastInspection-label">Results</div>
          <div className="MapVehiclePanelLastInspection-value">
            <InspectionResultsCell text={results.text} status={results.status} />
          </div>
        </div>
        <div className="MapVehiclePanelLastInspection-row">
          <div className="MapVehiclePanelLastInspection-label">Inspection By</div>
          <div className="MapVehiclePanelLastInspection-value">{createdBy}</div>
        </div>
      </div>
      <div className="MapVehiclePanelLastInspection-navigation">
        <Link to={`${PATHS.ADMIN.TABLES.VEHICLES.VEHICLE.replace(':id', deviceId)}?tab=inspections`}>
          View Performed Inspections in Admin Page
        </Link>
      </div>
      <InspectionPerformedPanel isOpen={isInspectionPanelOpen} onClose={closePanel} />
    </div>
  );
};

export default MapVehiclePanelLastInspection;
