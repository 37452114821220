import { computed } from 'mobx';

import timeStore from 'stores/TimeStore';
import { DATE_TIME_FORMATS } from 'config';
import { getTimeInTimeZone } from 'utils';

class FormattedDate {
  value: number;
  format: DATE_TIME_FORMATS;

  constructor(value = 0, format: DATE_TIME_FORMATS = DATE_TIME_FORMATS.fullFormatDate) {
    this.value = value;
    this.format = format;
  }

  @computed get date() {
    return this.value ? getTimeInTimeZone(this.value, timeStore.sessionTimezone).format(this.format) : '-';
  }
}

export default FormattedDate;
