import type MaintenanceModel from '../Models/MaintenanceModel';
import timeStore from 'stores/TimeStore';
import { DATE_TIME_FORMATS } from 'config';
import { getFormattedTime } from 'utils';

export default class ApplyTaskCard {
  context: MaintenanceModel;

  constructor(context: MaintenanceModel) {
    this.context = context;
  }

  get nameText() {
    return this.context.name.value;
  }

  get serviceTypeText() {
    return this.context.service.type.item.name;
  }

  get frequencyText() {
    return this.context.isRepeat ? 'Repeat' : 'One Time';
  }

  get oneTimeOdometerText() {
    const value = this.context.odometer.config.next.value;

    return (
      Boolean(value) && {
        text: `at ${Number(value).toLocaleString('en-US', {
          maximumFractionDigits: 3,
        })} miles`,
        icon: 'car',
      }
    );
  }

  get oneTimeEngineText() {
    const value = this.context.engine.config.next.value;

    return (
      Boolean(value) && {
        text: `at ${Number(value).toLocaleString('en-US', {
          maximumFractionDigits: 1,
        })} hours`,
        icon: 'car',
      }
    );
  }

  get oneTimeDateText() {
    const value = this.context.service.config.next.value;

    return (
      Boolean(value) && {
        text: `on ${getFormattedTime(Number(value), DATE_TIME_FORMATS.monthDatYearFull, timeStore.sessionTimezone)}`,
        icon: 'clock',
      }
    );
  }

  get repeatOdometerText() {
    const value = this.context.odometer.config.interval.value;

    return (
      Boolean(value) && {
        text: `every ${Number(value).toLocaleString('en-US', {
          maximumFractionDigits: 0,
        })} miles`,
        icon: 'car',
      }
    );
  }

  get repeatEngineText() {
    const value = this.context.engine.config.interval.value;

    return (
      Boolean(value) && {
        text: `every ${Number(value).toLocaleString('en-US', {
          maximumFractionDigits: 0,
        })} hours`,
        icon: 'car',
      }
    );
  }

  get repeatDateText() {
    const config = this.context.service.config;
    const value = config.interval.value;

    return (
      Boolean(value) && {
        text: `every ${Number(value).toLocaleString('en-US', {
          maximumFractionDigits: 0,
        })} ${config.intervalUnit.value?.toLowerCase()}`,
        icon: 'clock',
      }
    );
  }

  get rows() {
    return this.context.isRepeat
      ? [this.repeatOdometerText, this.repeatEngineText, this.repeatDateText].filter((text) => Boolean(text))
      : [this.oneTimeOdometerText, this.oneTimeEngineText, this.oneTimeDateText].filter((text) => Boolean(text));
  }
}
