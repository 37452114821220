import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';

import Marker from '../Marker';
import geozonePathMarkerIcon from './GeozonePathMarkerIcon';

interface IProps {
  color: string;
  colorName?: string;
  dimmed?: boolean;
  draggable?: boolean;
  hoveredIndex?: number;
  onClick?: () => void;
  onDrag?: (coordinate: { index: number; lat: number; lng: number }) => void;
  onHover?: (index: number) => void;
  orderNumber: number;
  position: Locations.ILocation;
}

const GeozonePathMarker: FC<IProps> = ({
  color,
  colorName,
  dimmed,
  draggable = true,
  hoveredIndex,
  onClick = () => void 0,
  onDrag = () => void 0,
  onHover = () => void 0,
  orderNumber,
  position,
}) => {
  const defaultState = useMemo(
    () => ({
      icon: {
        anchor: new google.maps.Point(10, 10),
        size: new google.maps.Size(20, 20),
        scaledSize: new google.maps.Size(20, 20),
      },
      opacity: 1,
      zIndex: 28,
    }),
    []
  );

  const hoverState = useMemo(
    () => ({
      icon: {
        anchor: new google.maps.Point(13, 13),
        size: new google.maps.Size(26, 26),
        scaledSize: new google.maps.Size(26, 26),
      },
      opacity: 1,
      zIndex: 28,
    }),
    []
  );

  const dimmedState = useMemo(
    () => ({
      icon: {
        anchor: new google.maps.Point(9, 9),
        size: new google.maps.Size(18, 18),
        scaledSize: new google.maps.Size(18, 18),
      },
      opacity: 0.5,
      zIndex: 28,
    }),
    []
  );

  const [options, setOptions] = useState(defaultState);

  const icon = useMemo(() => {
    return `data:image/svg+xml;charset=utf-8,${escape(
      geozonePathMarkerIcon({ color, colorName, orderNumber: orderNumber + 1 })
    )}`;
  }, [color, colorName, orderNumber]);

  const handleDrag = useCallback(
    (args) => {
      const { lat, lng } = args.latLng;
      onDrag({ index: orderNumber, lat: lat(), lng: lng() });
    },
    [onDrag, orderNumber]
  );

  const handleMouseOver = () => {
    onHover(orderNumber);
  };

  const handleMouseOut = () => {
    onHover(null);
  };

  useEffect(() => {
    if (isNull(hoveredIndex) && !dimmed) {
      setOptions(defaultState);
    } else if (hoveredIndex === orderNumber) {
      setOptions(hoverState);
    } else {
      setOptions(dimmedState);
    }
  }, [hoveredIndex, orderNumber, setOptions, defaultState, hoverState, dimmedState, dimmed]);

  return (
    <Marker
      options={{
        ...options,
        icon: {
          url: icon,
          ...options.icon,
        },
      }}
      draggable={draggable}
      position={position}
      onDrag={handleDrag}
      onClick={onClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    />
  );
};

export default memo(GeozonePathMarker, isEqual);
