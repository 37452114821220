import { DOWNLOAD_FORMAT } from 'config';
import { SimpleField } from 'models/Fields';
import type { IScheduledReportFormat } from './IScheduledReport';

class ScheduledReportFormat implements IScheduledReportFormat {
  id: SimpleField<string>;

  constructor({ id = 'email_xlsx' }) {
    this.id = new SimpleField(this.adapt(id));
  }

  get value() {
    switch (this.id.value) {
      case DOWNLOAD_FORMAT.XLSX:
        return 'email_xlsx';
      case DOWNLOAD_FORMAT.CSV:
        return 'email_csv';
      case DOWNLOAD_FORMAT.HTML:
        return 'email';
      default:
        return 'email_xlsx';
    }
  }

  adapt = (id: string) => {
    switch (id) {
      case 'email_xlsx':
        return DOWNLOAD_FORMAT.XLSX;
      case 'email_csv':
        return DOWNLOAD_FORMAT.CSV;
      case 'email':
        return DOWNLOAD_FORMAT.HTML;
      default:
        return DOWNLOAD_FORMAT.XLSX;
    }
  };
}

export default ScheduledReportFormat;
