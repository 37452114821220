import { action, observable } from 'mobx';

import { setJSONItemToStorage, getJSONItemFromStorage } from 'utils';
import { STORAGE_ITEMS } from 'config';

export class SubNavUIStateStore {
  @observable isExpanded: boolean = true;
  @observable panelMode: UI.panelMode = getJSONItemFromStorage(STORAGE_ITEMS.panelMode, 'full');

  @action setSubNavMenuState = () => (this.isExpanded = !this.isExpanded);

  @action setPanelMode = (mode) => {
    this.panelMode = mode;
    setJSONItemToStorage(STORAGE_ITEMS.panelMode, mode);
  };
}

export default new SubNavUIStateStore();
