import React, { useEffect } from 'react';

import type { IAlert } from 'models/Alerts/IAlert';
import AlertWithIntervalModel from 'models/Alerts/AlertWithInterval';
import ExcessiveIdlingAlertModel from 'models/Alerts/ExcessiveIdlingAlert';
import IgnitionAlertModel from 'models/Alerts/IgnitionAlert';
import MaintenanceDueAlertModel from 'models/Alerts/MaintenanceDueAlertModel';
import MaintenanceAlertModel from 'models/Alerts/MaintenanceAlertModel';
import SpeedOverAlertModel from 'models/Alerts/SpeedsOverAlert';
import GeozoneAlertModel from 'models/Alerts/GeozoneAlert';
import AfterHoursAlertModel from 'models/Alerts/AfterHoursAlert';
import FuelingExceptionAlertModel from 'models/Alerts/FuelingExceptionAlert';
import CustomAlertModel from 'models/Alerts/CustomAlert';

import InfoTooltip from 'components/InfoTooltip';
import AlertPreferencesAppliesTo from '../AlertPreferencesAppliesTo';
import AlertPreferencesOptions from '../AlertPreferencesOptions';
import AlertPreferenceName from '../AlertPreferenceName';
import AlertPreferencesGeoZone from '../AlertPreferencesGeoZone';
import AlertPreferencesIdle from '../AlertPreferencesIdle';
import AlertPreferencesIgnition from '../AlertPreferencesIgnition';
import AlertPreferencesMaintenance from '../AlertPreferencesMaintenance';
import AlertPreferencesMaintenanceDue from '../AlertPreferencesMaintenanceDue';
import AlertPreferencesSpeeding from '../AlertPreferencesSpeeding';
import AlertPreferencesWorkHours from '../AlertPreferencesWorkHours';
import AlertPreferencesActionTrigger from '../AlertPreferencesActionTrigger';
import AlertPreferencesMinimumInterval from '../AlertPreferencesMinimumInterval';
import AlertPreferencesStatusCode from '../AlertPreferencesStatusCode';
import AlertPreferenceRuleSelector from '../AlertPreferenceRuleSelector';
import AlertPreferencesCronOption from '../AlertPreferencesCronOption';
import AlertPreferenceFuelException from '../AlertPreferenceFuelException';

import './styles.scss';

interface IProps {
  alert: IAlert;
  setIsActiveNextButton: (value: boolean) => void;
}

const AlertPreferenceAlertDetails: React.FC<IProps> = ({ alert, setIsActiveNextButton }) => {
  useEffect(() => {
    setIsActiveNextButton(true);
  }, []);

  return (
    <div>
      <div className="AlertPreference-line AlertPreference-line--full">
        <div className="AlertPreference-title">{alert.title ? alert.title : alert.type.value} Alert</div>
        <div className="AlertPreference-description">
          {alert.description && <InfoTooltip>{alert.description}</InfoTooltip>}
        </div>
      </div>
      <AlertPreferenceName name={alert.displayName} />
      <AlertPreferencesAppliesTo
        deviceId={alert.metadata.asset}
        groupId={alert.metadata.assetGroup}
        dashCamId={alert.metadata.dashcamId}
        appliesTo={alert.metadata.appliesTo}
      />
      {alert instanceof GeozoneAlertModel && <AlertPreferencesGeoZone action={alert.actionType} place={alert.place} />}
      {alert instanceof FuelingExceptionAlertModel && (
        <AlertPreferenceFuelException exception={alert.exception} exceptionType={alert.exceptionType} />
      )}
      {alert instanceof ExcessiveIdlingAlertModel && <AlertPreferencesIdle idleMinutes={alert.idleMinutes} />}
      {alert instanceof IgnitionAlertModel && <AlertPreferencesIgnition ignitionOn={alert.ignitionOn} />}
      {alert instanceof MaintenanceDueAlertModel && (
        <AlertPreferencesMaintenanceDue subType={alert.subType} dueValue={alert.dueValue} />
      )}
      {alert instanceof MaintenanceAlertModel && (
        <AlertPreferencesMaintenance
          basedOn={alert.basedOn}
          basedTask={alert.basedTask}
          dueValue={alert.dueValue}
          when={alert.when}
          serviceType={alert.specificType.service.type}
          deviceId={alert.metadata.asset}
          groupId={alert.metadata.assetGroup}
          maintenanceTask={alert.specificTask.maintenance.task}
          appliesTo={alert.metadata.appliesTo}
        />
      )}
      {alert instanceof SpeedOverAlertModel && <AlertPreferencesSpeeding speed={alert.speed} />}
      {alert instanceof AfterHoursAlertModel && (
        <AlertPreferencesWorkHours
          subType={alert.subType}
          schedule={alert.schedule}
          scheduleOverride={alert.scheduleOverride}
          scheduleOverrideOption={alert.scheduleOverrideOption}
        />
      )}
      {alert instanceof AlertWithIntervalModel && !(alert instanceof CustomAlertModel) && (
        <AlertPreferencesOptions
          interval={alert.interval}
          option={alert.intervalOption}
          fields={alert.intervalFields}
          description={alert.intervalDescription}
        />
      )}

      {alert instanceof CustomAlertModel && (
        <>
          <AlertPreferencesStatusCode statusCode={alert.statusCode} />
          <AlertPreferencesCronOption cronOption={alert.cronOption} />
          <AlertPreferenceRuleSelector selector={alert.selector} />
          <AlertPreferencesMinimumInterval fields={alert.intervalFields} interval={alert.interval} />
          <AlertPreferencesActionTrigger
            queue={alert.queueNotificationEnabled}
            email={alert.emailNotificationEnabled}
          />
        </>
      )}
    </div>
  );
};

export default AlertPreferenceAlertDetails;
