import EventsBus from 'services/EventsBus/eventsBus';
import PlayerState from './PlayerState';
import { CAMERA_PLAYER_EVENTS } from 'config';

class PlayingPlayerState extends PlayerState {
  deactivate = async () => {
    EventsBus.get().off(CAMERA_PLAYER_EVENTS.PLAY_START, this.context.startTimer);
    clearInterval(this.context.timer);
    await this.context.video.disconnect();
  };

  activate = (): void => {
    EventsBus.get().on(CAMERA_PLAYER_EVENTS.PLAY_START, this.context.startTimer);
    this.context.isLoading.toggle(true);
    this.context.video.connect();
  };
}

export default PlayingPlayerState;
