import { action, observable } from 'mobx';

type IServerServiceTypeItemStatus = 'ENABLED' | 'DISABLED';

export interface IServerServiceTypeItem {
  name: string;
  isDefault: boolean;
  status: IServerServiceTypeItemStatus;
  id: number;
  count: number;
}

export interface IServiceTypeItem {
  name: string;
  id: string;
}

class ServiceTypeItem implements IServiceTypeItem {
  @observable name: string;
  @observable id: string;
  @observable isDefault: boolean;
  @observable status: string;
  @observable count: string;

  constructor(
    { name, id, isDefault, status, count } = {
      name: '',
      id: null,
      isDefault: false,
      status: 'ENABLED',
      count: 0,
    }
  ) {
    this.name = name;
    this.id = id ? id.toString() : '';
    this.isDefault = isDefault;
    this.status = status;
    this.count = String(count);
  }

  @action set = ({ name, id }) => {
    this.name = name;
    this.id = id;
  };

  get model() {
    return {
      name: this.name,
      id: Number(this.id),
    };
  }
}

export default ServiceTypeItem;
