import React, { Component, useEffect } from 'react';
import classNames from 'classnames';
import { uniqBy, debounce, sortBy } from 'lodash';
import Highlighter from 'react-highlight-words';
import Select, { components, ControlProps, OptionProps, SingleValueProps, ValueContainerProps } from 'react-select';

import type { OptionTypeBase } from 'react-select/src/types';

import AddSelectOption from '../AddSelectOption';
import Checkbox from 'components/Checkbox';

import { CaretDownOutlined } from '@ant-design/icons';
import { checkboxModifiedIconSrc, CloseIcon, Search2Icon } from 'assets';

import './styles.scss';

interface IProps {
  value?: Select.ISelectOption | Select.ISelectOption[];
  pageSize?: number;
  placeholder?: string;
  searchPlaceholder?: string;
  isSearchable?: boolean;
  isMenuOpen?: boolean;
  disabled?: boolean;
  hasBadge?: boolean;
  sortOptions?: boolean;
  alignRight?: boolean;
  emptySearchResults?: string;
  handleChange?: (option: Select.ISelectOption, isAllSelected?: boolean) => void;
  handleMultipleChange?: (option: Select.ISelectOption[], isAllSelected?: boolean) => void;
  onBlur?: () => void;
  onMenuClose?: () => void;
  getData?: (props: { page: number; pageSize: number; filter: string }) => Promise<any>;
  withAll?: boolean;
  addOption?: {
    text: string;
    onCreate: (value: any) => Promise<any>;
  };
  menuShouldBlockScroll?: boolean;
  autoFocus?: boolean;
  timeout?: number;
  menuPortalTarget?: HTMLElement | null;
  isStatic?: boolean;
  values?: Select.ISelectOption[];
  multiple?: boolean;
  multipleText?: {
    singular: string;
    plural: string;
    allSelect: string;
  };
  selectedAll?: boolean;
}

interface IState {
  currentPage: number;
  loadedLastPage: boolean;
  isLoading: boolean;
  filterValue: string;
  options: Select.ISelectOption[];
  value: Select.ISelectOption;
  isMenuOpen: boolean;
}

const STYLES = {
  menuPortal: (base) => ({ ...base, zIndex: 9999, left: base.left + window.scrollX }),
  valueContainer: (base) => {
    return {
      ...base,
      padding: '0 8px',
    };
  },
  control: (base, state) => {
    const searchablePadding = state.selectProps.isSearchable ? 20 : 0;
    const paddingLeft = state.isFocused ? searchablePadding : base.marginLeft;
    return {
      ...base,
      borderColor: state.isDisabled ? '#B6C6E6' : '#b6c6e5',
      padding: '2px 0 2px',
      height: '36px',
      minHeight: '36px',
      borderRadius: '4px',
      cursor: 'pointer',
      paddingLeft,
      '&:hover': {
        borderColor: '#b6c6e5',
      },
      boxShadow: 'none',
      backgroundColor: state.isDisabled ? '#eff1f5' : '#fff',
    };
  },
  singleValue: (base, state) => {
    return {
      ...base,
      borderColor: '#b6c6e5',
      color: state.isDisabled ? '#6B7A99' : '#2b2e41',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
      textOverflow: 'ellipsis',
      textTransform: 'none',
      overflow: 'hidden',
    };
  },
  option: (provided, state) => {
    let backgroundColor = provided.backgroundColor;
    if (state.isFocused) {
      backgroundColor = '#EFF1F5';
    } else if (state.isSelected) {
      backgroundColor = '#FFF';
    }
    return {
      ...provided,
      backgroundColor,
      color: state.isSelected ? 'inherit' : provided.color,
      cursor: 'pointer',
      fontWeight: 600,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: 12,
    };
  },
  placeholder: (base) => {
    return {
      ...base,
      top: 'calc(50%)',
      fontSize: 12,
      fontWeight: 500,
      color: '#929eb8',
    };
  },
};

class SearchableSelect extends Component<IProps, IState> {
  static defaultProps = {
    pageSize: 50,
  };

  private inputRef = React.createRef();
  private selectRef: any = React.createRef();
  private documentBody = document && document.querySelector('body');
  private _isMounted: boolean = true;

  private _latestRequest: number = null;

  constructor(props) {
    super(props);

    const timeout = props.timeout || 300;

    this._isMounted = true;

    this.state = {
      currentPage: 0,
      isLoading: false,
      loadedLastPage: false,
      filterValue: '',
      value: null,
      options: [],
      isMenuOpen: false,
    };

    if (timeout && !props.isStatic) {
      this.promiseOptions = debounce(this.promiseOptions, timeout);
    }
  }

  promiseOptionsWithClear = () => {
    return this.promiseOptions(true);
  };

  promiseOptions = (clear: boolean = false) => {
    const { pageSize, getData, value } = this.props;

    if ((this.state.loadedLastPage && !clear) || this.props.isStatic) {
      return;
    }

    if (!this._isMounted) {
      return;
    }

    const requestTime = Date.now();
    this._latestRequest = requestTime;

    this.setState({ isLoading: true });

    getData({
      page: this.state.currentPage,
      pageSize,
      filter: this.state.filterValue,
    })
      .then(({ totalPages, options }) => {
        if (!this._isMounted || this._latestRequest !== requestTime) {
          return;
        }
        this.setState((prevState) => {
          const nextOptions = clear ? [...options] : [...prevState.options, ...options];
          if (!Array.isArray(value) && value?.value) {
            nextOptions.push({ ...value });
          }
          if (this.state.value) {
            nextOptions.push({ ...this.state.value });
          }
          let uniqueOptions = uniqBy(nextOptions, 'value');
          if (this.props.sortOptions) {
            uniqueOptions = sortBy(uniqueOptions, ['priority', (option) => option?.label?.toLowerCase?.()]);
          }
          return {
            ...prevState,
            options: uniqueOptions,
            isLoading: false,
            loadedLastPage: (clear ? 0 : prevState.currentPage) >= totalPages - 1,
            currentPage: clear ? 0 : prevState.currentPage,
          };
        });

        if (this.props.multiple && this.props.selectedAll) {
          this.props.handleChange(options, true);
        }
      })
      .catch(() => {
        if (!this._isMounted) {
          return;
        }
        this.setState({
          isLoading: false,
          options: !Array.isArray(value) && value ? [{ ...value }] : [],
        });
      });
  };

  componentDidMount() {
    this.promiseOptions();

    if (this.props.isMenuOpen) {
      this.setState({ isMenuOpen: true });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleInputChange = (filterValue: string) => {
    if (filterValue !== this.state.filterValue) {
      this.setState({ filterValue, currentPage: 0 }, this.promiseOptionsWithClear);
    }
    return filterValue;
  };

  handleChangeOnPressEnter = (e) => {
    if (e.keyCode === 13 && this.showAddOption) {
      e.preventDefault();
      e.stopPropagation();
      this.handleAddOption();
    }
  };

  handleChange = (option: any) => {
    if (this.props.multiple && option.value === 'all') {
      const selectedOption = this.selectedOptionIds[option.value];

      this.props.handleMultipleChange(selectedOption ? [] : [...this.state.options], !selectedOption);
    } else {
      this.props.handleChange(option, this.isAllOptionsWillSelect(option));
    }
  };

  handleBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  handleMenuScrollToBottom = () => {
    this.setState(
      {
        currentPage: this.state.currentPage + 1,
      },
      this.promiseOptions
    );
  };

  handleMenuClose = () => {
    if (this.props.onMenuClose) {
      this.props.onMenuClose();
    }
  };

  handleAddOption = async () => {
    const { addOption } = this.props;
    const { filterValue } = this.state;

    await addOption?.onCreate(filterValue);
    this.selectRef.current.blur();
  };

  isAllOptionsWillSelect(option: Select.ISelectOption) {
    let isAll = false;

    if (this.props.multiple && Array.isArray(this.props.value)) {
      if (!Boolean(this.props.value.find((item) => item.value === option.value))) {
        isAll = this.state.options.length === this.props.value.length + 1;
      }
    }

    return isAll;
  }

  get options() {
    return this.state.options;
  }

  get value() {
    const { value, isStatic } = this.props;
    if (!value) {
      return null;
    }

    if (isStatic) {
      const stateValue = !Array.isArray(value) ? this.props.values.find((item) => item.value === value.value) : null;

      if (!stateValue || !stateValue.value) {
        return null;
      }

      return stateValue;
    } else {
      const stateValue =
        this.state.value ||
        (!Array.isArray(value) ? this.state.options.find((item) => item.value === value.value) : null);

      if (stateValue === undefined || stateValue?.value === undefined) {
        return null;
      }

      return stateValue;
    }
  }

  get styles() {
    return {
      ...STYLES,
      menu: (base) => {
        return {
          ...base,
          marginTop: 0,
          borderRadius: '0 0 4px 4px',
          zIndex: 2,
          minWidth: '100%',
          width: this.props.hasBadge ? 'max-content' : '100%',
          right: this.props.alignRight ? 0 : undefined,
        };
      },
    };
  }

  get menuIsOpen() {
    return this.state.isMenuOpen && (!this.state.isLoading || this.state.options.length)
      ? this.state.isMenuOpen
      : undefined;
  }

  get showAddOption() {
    const { options, filterValue } = this.state;

    return filterValue && !options.find((option) => option.label.toLowerCase() === filterValue.toLowerCase());
  }

  get addOptionIsValid() {
    const { addOption } = this.props;

    return Boolean(addOption) && this.showAddOption;
  }

  get menuShouldBlockScroll() {
    return typeof this.props.menuShouldBlockScroll === 'boolean' ? this.props.menuShouldBlockScroll : true;
  }

  Input = (props: any) => {
    return (
      <components.Input
        innerRef={this.inputRef}
        {...props}
        placeholder={this.multipleValuesCount ? this.multipleValuesCountTitle : this.props.searchPlaceholder}
        className="SearchableSelect-input"
      />
    );
  };

  get multipleValuesCount() {
    return this.props.multiple && Array.isArray(this.props.value) ? this.props.value.length : 0;
  }

  get multipleValuesCountTitle() {
    const multipleValuesCount = this.multipleValuesCount;

    if (this.isAllOptionsSelected) {
      return this.props.multipleText?.allSelect;
    } else {
      return `${multipleValuesCount} ${
        multipleValuesCount === 1 ? this.props.multipleText?.singular : this.props.multipleText?.plural
      }`;
    }
  }

  ControlComponent: React.FC<ControlProps<OptionTypeBase, boolean>> = ({ children, ...props }) => {
    return (
      <div className={`${props.menuIsOpen ? 'SearchableSelect-controlOpen' : 'SearchableSelect-controlClose'}`}>
        <components.Control {...props}>
          {props.isFocused && this.props.isSearchable !== false ? (
            <Search2Icon className="SearchableSelect-search" width="18px" height="18px" />
          ) : null}
          {children}
          {props.isFocused && this.state.filterValue ? (
            <CloseIcon className="SearchableSelect-close" width="13px" height="13px" />
          ) : null}
        </components.Control>
      </div>
    );
  };

  NoOptionsMessage = () => {
    return <span className="SearchableSelect-noResults">{this.props.emptySearchResults || 'No Results.'}</span>;
  };

  ValueContainer: React.FC<ValueContainerProps<OptionTypeBase, boolean>> = ({ children, ...props }): any => {
    return components.ValueContainer && <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  };

  IndicatorSeparator: React.FC = () => null;

  DropdownIndicator: React.FC<any> = (props) => {
    return !props.isFocused && !this.props.disabled ? <CaretDownOutlined className="SearchableSelect-caret" /> : null;
  };

  SingleValue: React.FC<SingleValueProps<OptionTypeBase>> = ({ children, ...props }) => {
    return <components.SingleValue {...props}>{children}</components.SingleValue>;
  };
  Placeholder: React.FC<any> = ({ children, ...props }) => {
    return (
      <components.Placeholder {...props}>
        {this.multipleValuesCount ? (
          <span className="SearchableSelect-placeHolderMultiple">{this.multipleValuesCountTitle}</span>
        ) : (
          children
        )}
      </components.Placeholder>
    );
  };

  get selectedOptionIds() {
    return (
      ((this.props.value as Select.ISelectOption[]) || []).reduce((acc, option) => {
        return { ...acc, [option.value]: true };
      }, {}) || {}
    );
  }

  get isAllOptionsSelected() {
    return (
      this.props.multiple && Array.isArray(this.props.value) && this.state.options.length === this.props.value.length
    );
  }

  CheckboxLabel = (label) => {
    return (
      <>
        {this.state.filterValue && label ? (
          <Highlighter
            highlightClassName="SearchableSelect-highlight"
            className="SearchableSelect-highlightContainer"
            searchWords={[this.state.filterValue]}
            textToHighlight={label}
            autoEscape
          />
        ) : (
          label
        )}
      </>
    );
  };

  Option: React.FC<OptionProps<OptionTypeBase, boolean>> = ({ children, ...props }) => {
    const { data } = props;
    const allKey = 'all';
    const handleChange = () => {
      this.handleChange({ value: data.value, label: data.label });
    };
    let option = null;

    const setOptionRef = (element) => {
      option = element;
    };

    useEffect(() => {
      if (props.isSelected) {
        option.scrollIntoView(false);
      }
    }, [props.isSelected]);

    return (
      <components.Option
        {...props}
        innerRef={setOptionRef}
        className={classNames('SearchableSelect-option', {
          'SearchableSelect-optionWithBadge': !!data.badge,
          'SearchableSelect-optionWithCheckbox': this.props.multiple,
        })}
      >
        {this.props.multiple ? (
          <span
            className="SearchableSelect-optionWithCheckboxWrapper"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {data.value === allKey ? (
              this.selectedOptionIds[allKey] && !this.isAllOptionsSelected ? (
                <Checkbox
                  className="SearchableSelect-optionWithCheckboxWrapperLabel"
                  checked={this.selectedOptionIds[data.value]}
                  onChange={handleChange}
                  icon={checkboxModifiedIconSrc}
                  label={this.CheckboxLabel(data.label)}
                />
              ) : (
                <Checkbox
                  className="SearchableSelect-optionWithCheckboxWrapperLabel"
                  checked={this.selectedOptionIds[data.value]}
                  onChange={handleChange}
                  label={this.CheckboxLabel(data.label)}
                />
              )
            ) : (
              <Checkbox
                className="SearchableSelect-optionWithCheckboxWrapperLabel"
                checked={this.selectedOptionIds[data.value]}
                onChange={handleChange}
                label={this.CheckboxLabel(data.label)}
              />
            )}
          </span>
        ) : (
          this.CheckboxLabel(data.label)
        )}
        {data.badge ? (
          <span className={'SearchableSelect-badge'}>
            {this.state.filterValue && data.badge ? (
              <Highlighter
                highlightClassName="SearchableSelect-highlight"
                className="SearchableSelect-highlightContainer"
                searchWords={[this.state.filterValue]}
                textToHighlight={data.badge}
                autoEscape
              />
            ) : (
              data.badge
            )}
          </span>
        ) : null}
      </components.Option>
    );
  };

  AddOption = (props) => {
    const { addOption } = this.props;

    return (
      <components.MenuList {...props}>
        {this.addOptionIsValid && <AddSelectOption text={addOption?.text} onClick={this.handleAddOption} />}
        {props.children}
      </components.MenuList>
    );
  };

  render() {
    return (
      <div>
        <Select
          autoFocus={this.props.autoFocus}
          className={'ReactSelect'}
          components={{
            DropdownIndicator: this.DropdownIndicator,
            IndicatorSeparator: this.IndicatorSeparator,
            SingleValue: this.SingleValue,
            Control: this.ControlComponent,
            ValueContainer: this.ValueContainer,
            NoOptionsMessage: this.NoOptionsMessage,
            Input: this.Input,
            Option: this.Option,
            MenuList: this.AddOption,
            Placeholder: this.Placeholder,
          }}
          inputValue={this.state.filterValue}
          isDisabled={this.props.disabled}
          isLoading={this.state.isLoading}
          isSearchable={this.props.isSearchable}
          menuIsOpen={this.menuIsOpen}
          menuPlacement="auto"
          menuPortalTarget={this.props.menuPortalTarget === undefined ? this.documentBody : this.props.menuPortalTarget}
          menuShouldBlockScroll={this.menuShouldBlockScroll}
          menuShouldScrollIntoView
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onMenuClose={this.handleMenuClose}
          onKeyDown={this.props.addOption ? this.handleChangeOnPressEnter : undefined}
          onMenuScrollToBottom={this.handleMenuScrollToBottom}
          options={this.props.isStatic ? this.props.values : this.options}
          placeholder={this.props.placeholder}
          styles={this.styles}
          ref={this.selectRef}
          value={this.value}
        />
      </div>
    );
  }
}

export default SearchableSelect;
