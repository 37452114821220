import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { formatDecimalNumber, getFormattedTime } from 'utils';

import { PATHS } from 'config';

import type { CustomerInvoice } from 'stores/Customers/CustomerInvoice';

interface IProps {
  invoice: CustomerInvoice;
  customerId: string;
  limited?: boolean
}


const CustomerInvoiceItem: React.FC<IProps> = ({ invoice, customerId, limited = false }) => {
  const link = React.useMemo(() => {
    return `${PATHS.MAP.CUSTOMER_INVOICE.replace(':customerId', customerId).replace(':invoiceId', String(invoice.id))}`;
  }, [customerId, invoice.id]);

  return (
    <div className={classNames('MapCustomerPanel-InvoicesListItem', {
      'MapCustomerPanel-InvoicesListItem--limited': limited,
    })}>
      <div><Link to={link}>{invoice.id}</Link></div>
      <div>{getFormattedTime(invoice.dueDate, 'MM/DD/YYYY')}</div>
      {!limited && (<>
        <div>${formatDecimalNumber(invoice.totalAmount, 2)}</div>
        <div
          className={`${invoice.balance ? 'MapCustomerPanel-Invoices--red' : ''}`}
        >
          ${formatDecimalNumber(invoice.balance, 2)}
        </div>
      </>)}
    </div>
  );
};

export default CustomerInvoiceItem;
