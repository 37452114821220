import React from 'react';
import type InspectionPerformedPDF from 'models/Inspections/Models/InspectionPerformedPDF';

import ConfirmationModal from 'components/Admin/ConfirmationModal';
import Input from 'components/Input/AntInput';
import Loader from 'components/Loader';

import './styles.scss';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  pdf: InspectionPerformedPDF;
}

const InspectionDownloadModal: React.FC<IProps> = ({ isOpen, onClose, pdf }): React.ReactElement => {
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);

  const handleSubmit = async () => {
    setIsSubmitted(true);
    await pdf.downloadFile();
    onClose();
    setIsSubmitted(false);
  };

  return !isSubmitted ? (
    <ConfirmationModal
      isOpen={isOpen}
      onCancel={onClose}
      onSubmit={handleSubmit}
      applyButtonTitle="Download"
      cancelButtonStyles={{ border: 'none' }}
      applyButtonStyles={{ fontWeight: 600 }}
      title="Download Performed Inspection"
      className="InspectionDownloadModal"
    >
      <div className="InspectionDownloadModal-content">
        <span className="InspectionDownloadModal-contentTitle">Format</span>
        <div className="InspectionDownloadModal-contentSelect">
          <Input disabled value="PDF (Adobe)" />
        </div>
      </div>
    </ConfirmationModal>
  ) : isOpen ? (
    <Loader color="blue" />
  ) : null;
};

export default InspectionDownloadModal;
