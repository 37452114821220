import WSState from './WebSocketState';

class WSConnectState extends WSState {
  constructor() {
    super();
  }

  activate(): void {
    this.context.clearPolling();
    this.context.client.activate();
  }
}

export default WSConnectState;
