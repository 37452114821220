import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import type { MapStore, MediaManager } from 'stores';
import { MEDIA_LIBRARY_LOADING_STATE } from 'models/Dashcam/MediaLibrary/MediaLibraryLoading';
import DashCamsMediaLibraryPanel from './MediaPanel';
import DashCamsMediaLibraryMap from './MediaMap';
import MediaLibraryList from 'components/DashCams/MediaLibrary/MediaLibraryList';
import MediaLibraryHeader from 'components/DashCams/MediaLibrary/MediaLibraryHeader';
import MediaLibraryRouter from './MediaLibraryRouter';
import Loader from 'components/Loader';
import MediaLibraryFailedMessage from 'components/DashCams/MediaLibrary/FailedMessage';

import './styles.scss';

interface IProps {
  className?: string;
  mediaManagerStore?: MediaManager;
  mapStore?: MapStore;
}

@inject(({ mediaManagerStore, devicesMapStore: { mapStore } }) => ({ mediaManagerStore, mapStore }))
@observer
class DashCamsMediaLibrary extends Component<IProps> {
  componentDidMount(): void {
    const {
      mediaManagerStore: {
        library: { clips },
      },
    } = this.props;

    clips.delivery.activate();
  }

  fetch = async () => {
    const {
      mediaManagerStore: {
        library: { clips, type },
      },
    } = this.props;

    await Promise.all([type.fetch(), clips.fetch()]);
  };

  render() {
    const {
      className,
      mediaManagerStore: {
        library: {
          clips,
          source: { vehicles },
          type,
          loading,
        },
      },
      mapStore,
    } = this.props;
    const cn = classNames('DashCamsMediaLibrary', className);

    return (
      <div className={cn}>
        <MediaLibraryRouter />
        <div className="DashCamsMediaLibrary-panel">
          <DashCamsMediaLibraryPanel />
        </div>
        <div className="DashCamsMediaLibrary-page">
          <div className="DashCamsMediaLibrary-header">
            <MediaLibraryHeader vehicles={vehicles} categories={type.categories} clips={clips} />
          </div>
          <div className="DashCamsMediaLibrary-content">
            <div className="DashCamsMediaLibrary-media">
              {loading.state.value === MEDIA_LIBRARY_LOADING_STATE.ERROR ? (
                <div className="DashCamsMediaLibrary-mediaFailedMessage">
                  <MediaLibraryFailedMessage
                    disabled={clips.repository.getState.loading && type.repository.getState.loading}
                    onRetry={this.fetch}
                  />
                </div>
              ) : (
                <div className="DashCamsMediaLibrary-mediaList">
                  <MediaLibraryList clips={clips} map={mapStore} categories={type.categories} />
                </div>
              )}
            </div>
            <div className="DashCamsMediaLibrary-map">
              <DashCamsMediaLibraryMap />
            </div>
            {loading.state.value === MEDIA_LIBRARY_LOADING_STATE.CLIPS && (
              <Loader className="DashCamsMediaLibrary-loader" />
            )}
          </div>
        </div>
        {loading.state.value === MEDIA_LIBRARY_LOADING_STATE.FULL && <Loader className="DashCamsMediaLibrary-loader" />}
      </div>
    );
  }
}

export default DashCamsMediaLibrary;
