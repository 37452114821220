/*global google*/
import { action, computed, observable, reaction } from 'mobx';
import { defer, isBoolean } from 'lodash';

import devicesStore from 'stores/Map/Devices';
import googleMapSearchStore from 'stores/Map/GoogleMapSearchStore';
import dashCamsStore from 'stores/DashCams';
import geozonesStore from 'stores/Geozones';
import geozonesAdmin from 'stores/Admin/Geozones';
import timeStore from 'stores/TimeStore';
import eventsRegistryStore from 'stores/EventsRegistryStore';
import { Event, Geozone } from 'models';
import { ToggleField } from 'models/Fields';
import { RouteReplay } from 'models/Timeline/RouteReplay';

import { ACL, HISTORY_MODE, MAP_CENTER, MAP_PARAMS, STORAGE_ITEMS } from 'config';
import { circleToPolygon, getParsedStorageItem, getStorageItem, setStorageItem, updateStorageItem } from 'utils';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';
import { validateAccessLevel } from 'stores/acl';

export type MapType = 'hybrid' | 'terrain' | 'roadmap';

export interface IMarker {
  lat: number;
  lng: number;
}

export interface IWayPointMarker {
  lat: number;
  lng: number;
  eventStatus: string;
  statusColor: string;
  heading: number;
  timestamp: number;
}

export interface IDeviceMarker extends IMarker {
  deviceId: string;
  shortName: string;
  event?: Event;
}

export interface IBoundsOffset {
  bottom?: number;
  left?: number;
  right?: number;
  top?: number;
}

type BoundsOffsetKeys = 'bottom' | 'left' | 'right' | 'top';

export type mapLabelSizeType = 'small' | 'medium' | 'large';

export class MapStore {
  constructor() {
    this.listenAppEvents();

    reaction(
      () => {
        return {
          iconClustering: this.iconClustering,
          clusteringSensitivity: this.clusteringSensitivity,
          mapType: this.mapType,
          mapTrafficLayer: this.mapTrafficLayer,
          vehicleLabel: this.vehicleLabel,
          mapLabelSize: this.mapLabelSize,
          showDirectionalArrows: this.showDirectionalArrows.value,
        };
      },
      (options) => updateStorageItem(STORAGE_ITEMS.mapSettings, { ...options }),
      { name: 'Save map settings' }
    );

    reaction(
      () => this.searchResultMarkers,
      (marker) => {
        if (marker && marker.triggerEvent !== 'click') {
          this.setMapCenter({ lat: marker.lat, lng: marker.lng });
          this.setZoom(MAP_PARAMS.vehicleDefaultZoom);
        }
      },
      { name: 'update map position and zoom for search result marker on map page' }
    );

    reaction(
      () => devicesStore.filteredDevicesList,
      (list) => {
        /* prevent updating a map if there is a selected vehicle */
        if (this.selectedDeviceMarker) {
          return;
        }

        /* show US if a vehicles list is empty */
        if (!list.length) {
          this.setMapToUSBounds();
          return;
        }

        if (!this.dragged) {
          this.recenterToFleet();
        }
      },
      { name: 'update map position and zoom on vehicles list change' }
    );

    reaction(
      () => devicesStore.selectedDeviceFollowing,
      () => {
        if (this.selectedDeviceMarker && devicesStore.selectedDeviceFollowing) {
          const lat = Number(this.selectedDeviceMarker.event.latitude);
          const lng = Number(this.selectedDeviceMarker.event.longitude);

          this.setMapCenter({ lat, lng });
        }
      },
      { name: 'update map position and zoom for selected device position on map page when user enables "Following"' }
    );

    reaction(
      () => this.selectedDeviceMarker,
      () => {
        if (this.selectedDeviceMarker && devicesStore.selectedDeviceFollowing) {
          const lat = Number(this.selectedDeviceMarker.event.latitude);
          const lng = Number(this.selectedDeviceMarker.event.longitude);

          if (devicesStore.selectedDeviceFollowing) {
            this.setMapCenter({ lat, lng });
          }

          if (!devicesStore.manuallySelected) {
            this.setMapCenter({ lat, lng });
          }
        }
      },
      { name: 'update map position for selected device position on map page' }
    );

    reaction(
      () => {
        return devicesStore.selectedDevice?.deviceEventsRequestStatus?.success;
      },
      (res) => {
        if (res) {
          if (devicesStore.showTrail && devicesStore.selectedDevice.events.length) {
            this.zoomVehiclePath(true);
          } else if (!devicesStore.selectedDeviceFollowing) {
            if (this.selectedDeviceMarker && !devicesStore.manuallySelected) {
              const { latitude, longitude } = this.selectedDeviceMarker.event;
              this.setMapCenter({ lat: Number(latitude), lng: Number(longitude) });
              this.setZoom(MAP_PARAMS.vehicleDefaultZoom);
            }
          }
          devicesStore.updateManuallySelected(false);
        }
      },
      { name: 'update map position and zoom for selected device route or for device has no events' }
    );

    reaction(
      () => {
        return devicesStore.selectedDeviceLatestEvent;
      },
      () => {
        if (devicesStore.selectedDeviceLatestEvent) {
          if (!devicesStore.showTrail && !devicesStore.selectedDeviceFollowing && !devicesStore.manuallySelected) {
            const { latitude, longitude } = devicesStore.selectedDeviceLatestEvent;
            if (latitude && longitude) {
              this.setMapCenter({ lat: Number(latitude), lng: Number(longitude) });
              this.setZoom(MAP_PARAMS.vehicleDefaultZoom);
            }
          }
        }
      },
      { name: 'update map position and zoom for selected device with single event' }
    );

    reaction(
      () => this.markers.length,
      (markersLength, reaction) => {
        if (markersLength && this.hasMap) {
          const bounds = new google.maps.LatLngBounds();
          this.markers.map(({ event }) => bounds.extend({ lat: Number(event.latitude), lng: Number(event.longitude) }));
          this.setBounds(bounds);
          reaction.dispose();
        }
      },
      { name: 'update map position and zoom for devices on map page' }
    );

    reaction(
      () => this.mediaItemsMarkers || dashCamsStore.mediaManager.filters.selectedDeviceId,
      () => {
        if (this.mediaItemsMarkers?.length) {
          const bounds = new google.maps.LatLngBounds();
          this.mediaItemsMarkers.map(({ lat, lng }) => bounds.extend({ lat: Number(lat), lng: Number(lng) }));
          this.setBounds(bounds);
        } else {
          this.setMapToUSBounds();
        }
      },
      { name: 'update map position and zoom for events on Media Manager page' }
    );

    reaction(
      () => this.selectedMediaEvent,
      () => {
        if (!this.mediaPlayerEventMarkerPosition) {
          this.setMapToUSBounds();
        }
      }
    );

    reaction(
      () => this.mediaPlayerEventMarkerPosition,
      () => {
        if (this.mediaPlayerEventMarkerPosition && !this.mediaPlayerEventRoute.length) {
          const bounds = new google.maps.LatLngBounds();
          const { lat, lng } = this.mediaPlayerEventMarkerPosition;
          bounds.extend({ lat, lng });
          this.setBounds(bounds);
        }
      },
      { name: 'update map position and zoom for selected event marker on Media Player page' }
    );

    reaction(
      () => this.mediaPlayerEventRoute,
      () => {
        if (this.mediaPlayerEventRoute.length) {
          const bounds = new google.maps.LatLngBounds();
          this.mediaPlayerEventRoute.map(({ lat, lng }) => bounds.extend({ lat: Number(lat), lng: Number(lng) }));
          this.setBounds(bounds);
        }
      },
      { name: 'update map position and zoom for selected event route on Media Player page' }
    );

    reaction(
      () => this.selectedMediaEventRoute,
      () => {
        if (this.selectedMediaEventRoute) {
          const bounds = new google.maps.LatLngBounds();
          this.selectedMediaEventRoute.map(({ lat, lng }) => bounds.extend({ lat: Number(lat), lng: Number(lng) }));
          this.setBounds(bounds);
        }
      },
      { name: 'update map position and zoom for selected event route on Request Media page Step 2' }
    );

    reaction(
      () => this.selectedMediaEventMarker,
      () => {
        if (this.selectedMediaEventMarker && !this.selectedMediaEventRoute.length) {
          const bounds = new google.maps.LatLngBounds();
          const { lat, lng } = this.selectedMediaEventMarker;
          bounds.extend({ lat, lng });
          this.setBounds(bounds);
        }
      },
      { name: 'update map position and zoom for selected event marker on Request Media page Step 2' }
    );

    reaction(
      () => this.streetViewOpened,
      (isOpened) => {
        if (isOpened) {
          const selectedWaypoint = this.selectedWaypoint;
          if (selectedWaypoint) {
            const { lat, lng } = selectedWaypoint;
            this.setStreetViewPosition({ lat, lng });
            this.setStreetViewPov({ heading: selectedWaypoint.heading, pitch: 0 });
          }
        }
      },
      { name: 'update streetView position and heading only when streetView opened' }
    );

    reaction(
      () => geozonesStore.selectedGeozone,
      (selectedGeozone) => {
        if (selectedGeozone) {
          const { formatterCoordinates, radius, type } = selectedGeozone;
          this.zoomToGeozoneBounds(formatterCoordinates, type, radius);
        }
      },
      { name: 'Center geozone on select a zone' }
    );

    reaction(
      () => devicesStore.showTrail,
      (showTrail) => {
        if (showTrail) {
          this.zoomVehiclePath();
        }
      },
      { name: 'Center vihecle path on show trail active' }
    );

    reaction(
      () => devicesStore.showHistory && devicesStore.selectedDevice?.events,
      (showHistory) => {
        if (showHistory && devicesStore.history.activeMode.id !== HISTORY_MODE.POINT_IN_TIME) {
          defer(this.zoomVehiclePath);
        }
      },
      { name: 'Center vihecle path on show history active' }
    );

    reaction(
      () => geozonesAdmin.selectedGeozone,
      (selectedGeozone) => {
        if (selectedGeozone) {
          const { type, radius, formatterCoordinates } = selectedGeozone;
          this.zoomToGeozoneBounds(formatterCoordinates, type, radius);
        }
      }
    );
  }

  @observable bounds: google.maps.LatLngBounds = null;
  @observable currentMapBounds: google.maps.LatLngBounds = null;
  @observable mapCenter: google.maps.LatLng | Locations.ILocation = MAP_CENTER;
  @observable markers: IDeviceMarker[] = [];

  @observable locked: boolean = false;
  @observable mapContainerName: string = null;
  @observable mapZoom: number = 5;
  @observable streetViewOpened: boolean = false;
  @observable streetViewPosition: Locations.ILocation | google.maps.LatLng = null;
  @observable streetViewPov: google.maps.StreetViewPov = null;
  @observable mapLabelSize: mapLabelSizeType =
    getParsedStorageItem(STORAGE_ITEMS.mapSettings).mapLabelSize || this.initialMapSettings.mapLabelSize;

  @observable mapType: MapType =
    getParsedStorageItem(STORAGE_ITEMS.mapSettings).mapType || this.initialMapSettings.mapType;
  @observable mapTrafficLayer: boolean =
    getParsedStorageItem(STORAGE_ITEMS.mapSettings).mapTrafficLayer || this.initialMapSettings.mapTrafficLayer;
  @observable mapGeozonesLayer: boolean = JSON.parse(getStorageItem(STORAGE_ITEMS.geozones));
  @observable iconClustering: string =
    getParsedStorageItem(STORAGE_ITEMS.mapSettings).iconClustering || this.initialMapSettings.iconClustering;
  @observable clusteringSensitivity: number =
    getParsedStorageItem(STORAGE_ITEMS.mapSettings).clusteringSensitivity ||
    this.initialMapSettings.clusteringSensitivity;
  @observable vehicleLabel: string =
    getParsedStorageItem(STORAGE_ITEMS.mapSettings).vehicleLabel || this.initialMapSettings.vehicleLabel;
  @observable boundsOffset: IBoundsOffset = {
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
  };
  @observable dragged: boolean = false;
  @observable pointGeozoneLocation: boolean = false;
  @observable mapHeading: number = 0;
  @observable isMapAvailable: boolean = false;
  @observable showDirectionalArrows = new ToggleField(
    isBoolean(getParsedStorageItem(STORAGE_ITEMS.mapSettings).showDirectionalArrows)
      ? getParsedStorageItem(STORAGE_ITEMS.mapSettings).showDirectionalArrows
      : this.initialMapSettings.showDirectionalArrows
  );
  timeout: number = null;
  routeReplay: RouteReplay = new RouteReplay({ context: this, mapFilters: devicesStore.filters });

  validateMapAvailable = (attempt = 0) => {
    if (typeof google !== 'undefined') {
      this.setIsMapAvailable(true);
    } else {
      if (this.isMapAvailable) {
        this.setIsMapAvailable(false);
      }
      if (attempt < 10) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.validateMapAvailable(attempt + 1), 300);
      }
    }
  };

  @action setIsMapAvailable = (value: boolean) => (this.isMapAvailable = value);

  get hasMap() {
    return typeof google !== 'undefined';
  }

  get initialMapSettings() {
    return {
      iconClustering: 'off',
      clusteringSensitivity: 50,
      mapType: 'hybrid',
      mapTrafficLayer: false,
      vehicleLabel: 'vehicle',
      mapLabelSize: 'medium',
      showDirectionalArrows: true,
    };
  }

  @computed get currentClusteringState() {
    return this.mapZoom < 20 ? this.iconClustering : 'off';
  }

  @computed get currentClusteringSensitivity() {
    return this.mapZoom < 20 ? this.clusteringSensitivity : 0;
  }

  @computed get timezone() {
    return timeStore.sessionTimezone;
  }

  @computed get selectedMediaEvent() {
    return dashCamsStore.mediaPlayer.mediaEvent;
  }

  @computed get geozonesList() {
    return geozonesStore.filteredGeozonesList.length
      ? geozonesStore.filteredGeozonesList.map(
          ({
            name,
            dispatchMarkerPosition,
            formatterCoordinates,
            geozoneId,
            type,
            radius,
            color,
            isActive,
            id,
          }: Geozone) => ({
            name,
            coordinates: formatterCoordinates,
            color: color.hex,
            colorName: color.name,
            geozoneId,
            isActive,
            radius,
            dispatchMarkerPosition,
            type,
            id,
          })
        )
      : [];
  }

  @computed get selectedGeozone() {
    if (geozonesStore.selectedGeozone) {
      const {
        name,
        formatterCoordinates,
        dispatchMarkerPosition,
        geozoneId,
        type,
        radius,
        color,
        isActive,
        id,
      }: Geozone = geozonesStore.selectedGeozone;

      return {
        name,
        coordinates: formatterCoordinates,
        color: color.hex,
        colorName: color.name,
        dispatchMarkerPosition,
        geozoneId,
        isActive,
        radius,
        type,
        id,
      };
    }

    return null;
  }

  @action setMarkers = () => {
    this.markers = devicesStore.filteredDevicesList.map((device) => {
      const {
        data: { deviceId, shortName, driverDescription },
        latestEvent,
      } = device;
      const label = this.vehicleLabel === 'driver' && Boolean(driverDescription) ? driverDescription : shortName;

      return {
        shortName: label,
        deviceId,
        event: latestEvent,
        lat: null,
        lng: null,
      };
    });
  };

  @computed get markersByLatestEvent(): IDeviceMarker[] {
    return [...this.markers]
      .filter((marker) => marker.deviceId !== this.selectedDeviceMarker?.deviceId)
      .sort((a, b) => (a?.event?.timestamp > b?.event?.timestamp ? 1 : -1));
  }

  @computed get wayPoints(): Event[] {
    return devicesStore.selectedDevice?.events
      .filter(({ metadata }) =>
        this.routeReplay.state.isPlaying.value || devicesStore.showTimelineDetails.value
          ? true
          : eventsRegistryStore.settings.isChecked(metadata)
      )
      .map((event: Event) => event);
  }

  @computed get selectedWayPointEvent(): Event {
    return this.wayPoints?.find((wayPoint) => wayPoint.isSelected);
  }

  @computed get selectedWaypoint(): IWayPointMarker {
    const source = devicesStore?.selectedEvent;
    if (!source) {
      return null;
    }
    const {
      metadata: {
        attributes: { label },
      },
      statusColor,
      latitude,
      longitude,
      heading,
      timestamp,
    } = source;
    return {
      lat: Number(latitude),
      lng: Number(longitude),
      eventStatus: label,
      statusColor,
      heading,
      timestamp,
    };
  }

  @computed get mediaPlayerEventMarkerPosition() {
    const { currentMarkerPosition: location } = dashCamsStore.mediaPlayer;

    return Boolean(location?.lat && location?.lng) ? dashCamsStore.mediaPlayer.currentMarkerPosition : null;
  }

  @computed get mediaPlayerEventRoute() {
    return dashCamsStore.mediaPlayer.mediaEvent.route;
  }

  @computed get mediaItemsMarkers() {
    const mediaItems = dashCamsStore.mediaManager.mediaItemsList;
    if (!mediaItems.length) {
      return null;
    }

    return mediaItems.reduce((list, item) => {
      const { eventTimestamp, location } = item;

      /*do not show markers for Requested media where is no location and lat/lng equals 0*/
      if (location && location.lat !== 0 && location.lng !== 0) {
        list.push({
          ...location,
          eventTimestamp,
        });
      }
      return list;
    }, []);
  }

  @computed get selectedDeviceMarker() {
    if (!devicesStore.selectedDevice) {
      return null;
    }
    const {
      data: { shortName, deviceId, driverDescription },
      latestEvent,
    } = devicesStore.selectedDevice;
    const label = this.vehicleLabel === 'driver' && Boolean(driverDescription) ? driverDescription : shortName;

    return {
      shortName: label,
      deviceId,
      event: latestEvent,
    };
  }

  @computed get searchResultMarkers() {
    const searchResults = googleMapSearchStore.searchResults;

    if (searchResults && searchResults.latitude && searchResults.longitude) {
      return { lat: searchResults.latitude, lng: searchResults.longitude, triggerEvent: searchResults.triggerEvent };
    }
    return null;
  }

  @computed get selectedDeviceRoute() {
    const selectedDeviceEvents = devicesStore.selectedDevice?.events;
    const selectedDeviceLatestEvent = devicesStore.selectedDevice?.latestEvent;

    if (!selectedDeviceLatestEvent || !selectedDeviceEvents || !this.hasMap) {
      return null;
    }

    return selectedDeviceEvents.length
      ? selectedDeviceEvents.map(
          ({ latitude, longitude }) => new google.maps.LatLng(Number(latitude), Number(longitude))
        )
      : [
          new google.maps.LatLng(
            Number(selectedDeviceLatestEvent.latitude),
            Number(selectedDeviceLatestEvent.longitude)
          ),
        ];
  }

  // Request Media Flow Step 2 media event preview
  @computed get selectedMediaEventRoute() {
    const selectedMediaEvent = dashCamsStore.mediaRequest.selectedMediaData;
    return selectedMediaEvent ? selectedMediaEvent.route : null;
  }

  // Request Media Flow Step 2 media event preview
  @computed get selectedMediaEventMarker() {
    const selectedMediaEvent = dashCamsStore.mediaRequest.selectedMediaData;
    return selectedMediaEvent ? selectedMediaEvent.markerPosition : null;
  }

  @computed get isMainMap() {
    return this.mapContainerName === 'main-map';
  }

  @action setCurrentMapBounds = (bounds: google.maps.LatLngBounds) => {
    this.currentMapBounds = bounds;
  };

  @action setMapContainer = (containerName: string) => (this.mapContainerName = containerName);

  @action setMapLabelSize = (size) => (this.mapLabelSize = size);

  @action setStreetViewPosition = (streetViewPosition) => (this.streetViewPosition = streetViewPosition);

  @action setStreetViewPov = (streetViewPov: google.maps.StreetViewPov) => (this.streetViewPov = streetViewPov);

  @action setBounds = (bounds: google.maps.LatLngBounds) => {
    this.bounds = bounds;
  };

  @action setZoom = (zoom: number) => {
    if (!this.locked) {
      this.mapZoom = zoom;
    }
  };

  @action setMapCenter = (position: Locations.ILocation) => {
    if (this.hasMap && !this.locked) {
      this.mapCenter = new google.maps.LatLng(position);
    }
  };

  @action setMapToUSBounds = () => {
    if (this.hasMap && !this.locked) {
      const bounds = new google.maps.LatLngBounds();
      MAP_PARAMS.US_BOUNDS.forEach(({ lat, lng }) => {
        bounds.extend({ lat, lng });
      });
      this.setBounds(bounds);
    }
  };

  @action setStreetViewOpened = (status?: boolean) =>
    (this.streetViewOpened = isBoolean(status) ? status : !this.streetViewOpened);

  @action setMapType = (type: MapType | undefined) => {
    this.mapType = type ? type : 'roadmap';
  };

  @action toggleMapTrafficLayer = () => {
    this.mapTrafficLayer = !this.mapTrafficLayer;
  };

  @action turnOffMapTrafficLayer = () => {
    this.mapTrafficLayer = false;
  };

  @action turnOnMapTrafficLayer = () => {
    this.mapTrafficLayer = true;
  };

  @action toggleMapGeozonesLayer = () => {
    this.mapGeozonesLayer = !this.mapGeozonesLayer;
    setStorageItem(STORAGE_ITEMS.geozones, JSON.stringify(this.mapGeozonesLayer));
  };

  @action toggleIconClustering = (value) => (this.iconClustering = value);

  @action setClusteringSensitivity = (sensitivity) => (this.clusteringSensitivity = sensitivity);

  @action setVehicleLabel = (label: string) => (this.vehicleLabel = label);

  @action setBoundsOffset = (offset: { [key in BoundsOffsetKeys]?: number }) => {
    if (this.locked) {
      return;
    }
    this.boundsOffset = {
      ...this.boundsOffset,
      ...offset,
    };
  };

  @action zoomIntoVehicle = () => {
    if (this.locked) {
      return;
    }
    const lat = Number(this.selectedDeviceMarker.event.latitude);
    const lng = Number(this.selectedDeviceMarker.event.longitude);
    const bounds = new google.maps.LatLngBounds();

    bounds.extend({ lat, lng });
    this.updateDragged(false);
    this.setBounds(bounds);
    this.setMapCenter({ lat, lng });
    this.setZoom(MAP_PARAMS.zoomInto);
  };

  @action zoomIntoTrackPoint = (zoom?: number) => {
    if (this.locked) {
      return;
    }

    const { latitude, longitude } = devicesStore.selectedEvent;

    this.setZoom(zoom ? zoom : MAP_PARAMS.zoomInto);
    this.mapCenter = { lat: Number(latitude), lng: Number(longitude) };
  };

  @action zoomVehiclePath = (isDragged = false) => {
    if (this.locked) {
      return;
    }

    if (this.selectedDeviceRoute?.length > 1) {
      const bounds = new google.maps.LatLngBounds();
      this.selectedDeviceRoute.map((position) => bounds.extend(position));
      this.setBoundsOffset({
        top: 70,
        right: devicesStore.selectedDevice && devicesStore.mediaPanel.value ? 470 : 70,
        bottom: devicesStore.showTimelineDetails.value ? devicesStore.timelineDetailsHeight + 70 : 70,
        left: 70,
      });
      this.setBounds(bounds);
      this.updateDragged(isDragged);
    } else {
      const lat = Number(this.selectedDeviceMarker.event.latitude);
      const lng = Number(this.selectedDeviceMarker.event.longitude);

      this.setMapCenter({ lat, lng });
    }
  };

  @action zoomToGeozone = (position: Locations.ILocation) => {
    if (!this.hasMap || this.locked) {
      return;
    }
    this.setZoom(MAP_PARAMS.defaultZoom);
    this.mapCenter = position;
  };

  // Zooms to fill screen with Geozone
  @action zoomToGeozoneBounds = (coordinates: Locations.ILocation[], type: Geozone.geozoneType, radius: number) => {
    if (!this.hasMap || this.locked || !coordinates?.length) {
      return;
    }

    const geozonePoints = type.toLowerCase() === 'circle' ? circleToPolygon(coordinates[0], radius) : coordinates;

    const bounds = new google.maps.LatLngBounds();
    geozonePoints.forEach((position) => bounds.extend(position));
    this.setBounds(bounds);
  };

  @action recenterToFleet = () => {
    if (!this.hasMap || this.locked || !this.isMainMap) {
      return;
    }
    const fleet = devicesStore.filteredDevicesList;
    if (fleet.length) {
      const bounds = new google.maps.LatLngBounds();
      devicesStore.filteredDevicesList.map(({ latestEvent: { latitude, longitude } }) =>
        bounds.extend({ lat: Number(latitude), lng: Number(longitude) })
      );
      this.updateDragged(false);
      this.setBounds(bounds);
      this.setBoundsOffset({ top: 50, right: 50, bottom: 50, left: 50 });
    } else {
      this.setMapToUSBounds();
    }
  };

  @action updateDragged = (value: boolean) => {
    this.dragged = value;
  };

  @action setLocked() {
    this.locked = true;
  }

  @action setUnlocked() {
    this.locked = false;
  }

  @action toggleLocked() {
    this.locked ? this.setUnlocked() : this.setLocked();
  }

  listenAppEvents = () => {
    EventsBus.get().on(APP_EVENTS.DEVICES.GET, this.setMarkers);
    EventsBus.get().on(APP_EVENTS.DEVICES.SEARCH, this.setMarkers);
    EventsBus.get().on(APP_EVENTS.DEVICES.FILTER, this.setMarkers);
    EventsBus.get().on(APP_EVENTS.DEVICES.DEVICE.EVENT.UPDATE, this.setMarkers);
    EventsBus.get().once(APP_EVENTS.ACL_UPDATED, this.onACLUpdated);
  };

  onACLUpdated = () => {
    if (!validateAccessLevel([ACL.MAP.SETTINGS.DIRECTIONAL_ARROWS.READ])) {
      this.showDirectionalArrows.toggle(false);
    }
  };

  onSelectDevice = () => {
    if (devicesStore.showTrail) {
      this.zoomVehiclePath();
    } else if (this.selectedDeviceMarker) {
      const { latitude, longitude } = this.selectedDeviceMarker.event;
      if (!devicesStore.manuallySelected) {
        this.setMapCenter({ lat: Number(latitude), lng: Number(longitude) });
        this.setZoom(MAP_PARAMS.vehicleDefaultZoom);
      }
    }
  };

  @action togglePointGeozoneLocation = (point?: boolean) => {
    this.pointGeozoneLocation = isBoolean(point) ? point : !this.pointGeozoneLocation;
  };

  @action setMapHeading = (value: number) => (this.mapHeading = value);
}

export default new MapStore();
