import { getSVGTextWidth } from 'utils';
import { escape } from 'lodash';
import renderHeadingArrow from '../renderHeadingArrow';

const activeBorder = (textWidth, outline) => {
  const centerWidth = textWidth - 7;
  const fillColor = outline ? outline : '#4285f4';

  return `<g clip-path="url(#_clipPath_C66k1CiDrX6Dkb0nOODNzORM0lBPqbqD)">
    <path d=" M 2.25 0 C 1.007 0 0 1.007 0 2.25 L 0 20.25 C 0 21.493 1.007 22.5 2.25 22.5 L 8.795 22.5 L 13.126 30 L 17.455 22.5 L 24 22.5 L 24 21 L 16.589 21 L 13.126 27 L 9.661 21 L 2.25 21 C 1.836 21 1.5 20.664 1.5 20.25 L 1.5 2.25 C 1.5 1.836 1.836 1.5 2.25 1.5 L 24 1.5 L 24 0 L 2.25 0 Z " fill-rule="evenodd" fill="${fillColor}" transform="translate(8 7)"/>
    <path d=" M 0 21 L 0 22.5 L ${centerWidth} 22.5 L ${centerWidth} 21 L 0 21 Z  M ${centerWidth} 1.5 L 0 1.5 L 0 0 L ${centerWidth} 0 L ${centerWidth} 1.5 Z " fill-rule="evenodd" fill="${fillColor}"  transform="translate(32 7)"/>
    <path d=" M 0 21 L 5.456 21 C 5.887 21 6.236 20.665 6.236 20.25 L 6.236 2.25 C 6.236 1.835 5.887 1.5 5.456 1.5 L 0 1.5 L 0 0 L 5.456 0 C 6.749 0 7.796 1.007 7.796 2.25 L 7.796 20.25 C 7.796 21.493 6.749 22.5 5.456 22.5 L 0 22.5 L 0 21 Z " fill-rule="evenodd" fill="${fillColor}"  transform="translate(${textWidth +
    25} 7)"/>
  </g>`;
};

export default ({ arrowColor, name, statusColor, active = false, outline = '', heading }) => {
  const nameToShow = name.length > 36 ? `${name.substring(0, 35)}...` : name;
  const escapedNameToShow = escape(nameToShow);

  const textWidth = getSVGTextWidth(nameToShow, '600 10px "Avenir Next"') + 6;

  return `<svg width="${41 + textWidth}" height="44" viewBox="0 0 ${41 +
    textWidth} 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_ddd)">
        <path fill="white" d="M8,26V10c0-1.7,1.3-3,3-3h17v22h-3l-3.5,6L18,29h-7C9.3,29,8,27.7,8,26z"/>
        <rect xmlns="http://www.w3.org/2000/svg" x="27" y="7" width="${textWidth + 1}" height="22" fill="white"/>
        <path fill="white" d="M33,26V10c0-1.7-1.3-3-3-3h-2v22h2C31.7,29,33,27.7,33,26z" transform="translate(${textWidth -
          1} 0)"/>
      </g>
      <circle cx="18" cy="18" r="7" fill="#${statusColor}"/>
      ${heading ? renderHeadingArrow(arrowColor, heading, '0.7', '11 11') : null}
      <text id="Stevens_car" data-name="Stevens car" fill="#2B2E41" transform="translate(28 21)" font-size="10"
            font-family="Avenir Next, Helvetica, Arial, sans-serif" font-weight="600">
        ${escapedNameToShow}
      </text>
      ${active === true ? activeBorder(textWidth, outline) : null}
      <defs>
        <filter id="filter0_ddd" x="0" y="0" width="${41 +
          textWidth}" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="4"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="3"/>
          <feGaussianBlur stdDeviation="2"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"/>
          <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="3"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
          <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>
        </filter>
        <clipPath id="_clipPath_C66k1CiDrX6Dkb0nOODNzORM0lBPqbqD">
            <rect width="${41 + textWidth}" height="44"/>
        </clipPath>
      </defs>
    </svg>
`;
};
