import TableDownload from 'models/Tables/TableDownload';
import type InspectionsPerformedTable from './PerformedTable';
import { INSPECTIONS_PERFORMED_TABLE_COLUMNS } from 'config';

class PerformedTableDownload extends TableDownload<InspectionsPerformedTable> {
  private mapNameHeader = {
    [INSPECTIONS_PERFORMED_TABLE_COLUMNS.START_DATE_TIME]: 'Start Date/Time',
    [INSPECTIONS_PERFORMED_TABLE_COLUMNS.VEHICLE]: 'Vehicle',
    [INSPECTIONS_PERFORMED_TABLE_COLUMNS.RESULTS]: 'Results',
    [INSPECTIONS_PERFORMED_TABLE_COLUMNS.GROUPS]: 'Groups',
    [INSPECTIONS_PERFORMED_TABLE_COLUMNS.INSPECTION_FORM]: 'Inspection Form',
    [INSPECTIONS_PERFORMED_TABLE_COLUMNS.COMPLETION_DATE_TIME]: 'Completion Date/Time',
    [INSPECTIONS_PERFORMED_TABLE_COLUMNS.ELAPSED_TIME]: 'Elapsed Time',
    [INSPECTIONS_PERFORMED_TABLE_COLUMNS.INSPECTION_BY]: 'Inspection By',
    [INSPECTIONS_PERFORMED_TABLE_COLUMNS.LOCATION]: 'Location',
    [INSPECTIONS_PERFORMED_TABLE_COLUMNS.ODOMETER_AT_INSPECTION]: 'Odometer At Inspection',
    [INSPECTIONS_PERFORMED_TABLE_COLUMNS.ENGINE_HOURS_AT_INSPECTION]: 'Engine Hours At Inspection',
  };

  protected getFile = async (): Promise<Blob> => {
    const ReportFile = await import('../../Report/ReportDownload/ReportFile');
    const reportFile = new ReportFile.default(this.format.value, {
      title: 'Inspections Performed',
      description: '',
      tables: [{ ...this.getData(), title: null }],
    });

    const file = await reportFile.create();

    return file;
  };

  protected get fileName() {
    return 'Inspections Performed';
  }

  private getData = () => {
    const columnsIds = this.context.columns.filter((column) => column.isSelected).map((column) => column.value);

    const rows = this.context.source.map((inspectionPerformed) => {
      return columnsIds.map((column) => {
        if (column === INSPECTIONS_PERFORMED_TABLE_COLUMNS.RESULTS) {
          return inspectionPerformed[column].text;
        } else if (column === INSPECTIONS_PERFORMED_TABLE_COLUMNS.GROUPS) {
          return inspectionPerformed[column].map((item) => item.name.value).join(', ');
        } else {
          return inspectionPerformed[column];
        }
      });
    });

    return {
      columns: columnsIds.map((columnId) => this.mapNameHeader[columnId]),
      rows,
      total: [],
    };
  };
}

export default PerformedTableDownload;
