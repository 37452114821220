class StateModel {
  code: string;
  state: string;

  constructor(item) {
    item = item || { code: '', state: '' };
    this.code = item.code;
    this.state = item.state;
  }
}

export default StateModel;
