import isNumber from 'lodash/isNumber';
import { ALERTS_TYPE } from 'config';
import AlertWithIntervalModel from './AlertWithInterval';

class FaultCodesAlertModel extends AlertWithIntervalModel {
  constructor(item) {
    super({
      ...item,
      isIntervalActive: isNumber(item.interval) ? Boolean(item.interval) : false,
      interval: isNumber(item.interval) ? item.interval : 5 * 60 * 1000,
    });

    this.urlType = 'fault-codes';
    this.description = 'OBD Plug-In devices only.';
    this.type.reInitialize(ALERTS_TYPE.FAULT_CODES);
  }
}

export default FaultCodesAlertModel;
