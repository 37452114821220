import React, { useEffect, useState } from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import { Collapse } from 'antd';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';
import { validateAccessLevel, UserAccessLevelComponent } from 'stores';
import { ACL } from 'config';
import { ChevronIcon } from 'assets';

import withCollapseStorage from 'hocs/withCollapseStorage';

import type MaintenanceCompleteRecordModel from 'models/Maintenances/Models/MaintenanceCompleteRecordModel';
import type MaintenancePanelUI from 'models/Maintenances/UI/MaintenanceModalUI/MaintenancePanelUI';

import TableActions from 'components/Table/Actions';

import Button from 'components/Button';
import MaintenanceCompletedTaskPanelSummaryInputNumber from '../CompletedTaskPanelSummaryInputNumber';
import MaintenanceCompletedTaskPanelSummaryDate from '../CompletedTaskPanelSummaryDate';
import MaintenanceCompletedTaskPanelSummaryInput from '../CompletedTaskPanelSummaryInput';
import MaintenanceCompletedTaskPanelSummarySelect from '../CompletedTaskPanelSummarySelect';
import MaintenanceCompletedTaskPanelNote from '../CompletedTaskPanelNote';
import Modal from 'components/Modal';
import FileManager from 'components/FileManager';
import MaintenanceTaskDeleteModal from '../../DeleteModal';
import MaintenanceCompletedTaskPanelLoggerInfo from '../CompletedTaskPanelLoggerInfo';
import MaintenanceCosts from 'components/Maintenance/MaintenanceCosts';

import './styles.scss';

enum collapsePanels {
  Summary = 'maintenance-task-summary',
  CostOfService = 'maintenance-task-cost-of-service',
  Attachments = 'maintenance-task-attachments',
  Note = 'maintenance-task-note',
}

interface IProps {
  collapseKeys: string[];
  onCollapseChange(values: string | string[]): void;
  completed: MaintenanceCompleteRecordModel;
  ui: MaintenancePanelUI;
  isEdit: boolean;
  isShowDelete: boolean;
  isCancelClosePanel: boolean;
  redirectToVehicleAdmin: (value: string) => void;
  onClose: () => void;
  openExitingConfirmation: () => void;
}

const MaintenanceCompletedTaskPanelContent: React.FC<IProps> = ({
  collapseKeys,
  onCollapseChange,
  completed,
  ui,
  isEdit,
  isShowDelete,
  isCancelClosePanel,
  redirectToVehicleAdmin,
  onClose,
  openExitingConfirmation,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const edit = validateAccessLevel([ACL.MAINTENANCE.BASIC.UPDATE]);
  const navigationCn = classNames('MaintenanceCompletedTaskPanelContent-Navigation', {
    'MaintenanceCompletedTaskPanelContent-Navigation--right': !edit,
  });

  useEffect(() => {
    EventsBus.get().on(APP_EVENTS.MAINTENANCE.COMPLETED.DELETED, onClose);

    return () => {
      EventsBus.get().off(APP_EVENTS.MAINTENANCE.COMPLETED.DELETED, onClose);
      ui.isEdit.toggle(false);
    };
  }, []);

  const handleCancel = () => {
    if (completed.isUpdated) {
      openExitingConfirmation();
    } else {
      if (isCancelClosePanel) {
        onClose();
      } else {
        ui.isEdit.toggle(false);
      }
    }
  };

  const handleSave = async () => {
    await completed.update();

    if (completed.repositoryModel.patchState.success) {
      onClose();
    }
  };

  const openDeleteModal = () => setIsDeleteModalOpen(true);

  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  return (
    <Observer
      render={() => (
        <div
          className={classNames('MaintenanceCompletedTaskPanelContent', {
            'MaintenanceCompletedTaskPanelContent--editing': ui.isEdit.value,
          })}
        >
          <Collapse
            activeKey={collapseKeys}
            expandIcon={() => (
              <span className="anticon anticon-left ant-collapse-arrow">
                <ChevronIcon />
              </span>
            )}
            expandIconPosition={'left'}
            onChange={onCollapseChange}
          >
            <Collapse.Panel
              header={'Summary'}
              key={collapsePanels.Summary}
              className="MaintenanceCompletedTaskPanelContent-block"
            >
              <div className="MaintenanceCompletedTaskPanelContent-Summary">
                <div className="MaintenanceCompletedTaskPanelContent-SummaryDetails">
                  <MaintenanceCompletedTaskPanelSummaryInput
                    title="Vehicle/Asset"
                    value={completed.info.vehicleName}
                    isEdit={ui.isEdit}
                    disabled
                  />
                  <MaintenanceCompletedTaskPanelSummarySelect
                    title="Frequency"
                    value={completed.maintenance.isRepeat ? 'Repeat' : 'One time'}
                    isEdit={ui.isEdit}
                    disabled
                  />
                  <MaintenanceCompletedTaskPanelSummaryDate
                    title="Date of service"
                    value={completed.values.serviceTime.value}
                    onChange={completed.values.serviceTime.set}
                    isEdit={ui.isEdit}
                    isShowTooltip={
                      completed.isLatestRecord &&
                      completed.maintenance.isRepeat &&
                      Boolean(completed.context.service.config.interval.value)
                    }
                    disabled={!edit}
                  />
                  <MaintenanceCompletedTaskPanelSummarySelect
                    title="Service type"
                    value={completed.serviceType.name}
                    isEdit={ui.isEdit}
                    disabled
                  />
                  <MaintenanceCompletedTaskPanelSummaryInputNumber
                    title="Odometer at service"
                    value={completed.values.odometer.value}
                    isEdit={ui.isEdit}
                    onChange={completed.values.odometer.set}
                    isShowTooltip={
                      completed.isLatestRecord &&
                      completed.maintenance.isRepeat &&
                      Boolean(completed.context.odometer.config.interval.value)
                    }
                    disabled={!edit}
                    unit="mi"
                    isError={completed.values.odometer.isEmpty}
                  />
                  <MaintenanceCompletedTaskPanelSummaryInputNumber
                    title="Engine hours at service"
                    value={completed.values.engHours.value}
                    isEdit={ui.isEdit}
                    onChange={completed.values.engHours.set}
                    isShowTooltip={
                      completed.isLatestRecord &&
                      completed.maintenance.isRepeat &&
                      Boolean(completed.context.engine.config.interval.value)
                    }
                    disabled={!edit}
                    unit="hrs"
                    isError={completed.values.engHours.isEmpty}
                  />
                  <MaintenanceCompletedTaskPanelSummaryInput
                    title="Performed by"
                    value={completed.performedByFullName.value}
                    isEdit={ui.isEdit}
                    onChange={completed.performedByFullName.set}
                    disabled={!edit}
                  />
                </div>
                <div className="MaintenanceCompletedTaskPanelContent-SummaryActions">
                  {isEdit && !ui.isEdit.value && (
                    <TableActions
                      items={[
                        { text: 'Edit Task Record', onClick: () => ui.isEdit.toggle(true) },
                        {
                          text: 'View this Vehicle’s Maintenance',
                          onClick: () => redirectToVehicleAdmin(completed.context.asset.cpNumber.value),
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
            </Collapse.Panel>
            <Collapse.Panel
              header={'Cost of Service'}
              key={collapsePanels.CostOfService}
              className="MaintenanceCompletedTaskPanelContent-block"
            >
              <div className="MaintenanceCompletedTaskPanelContent-Costs">
                <MaintenanceCosts
                  costs={completed.costs}
                  edit={ui.isEdit.value}
                  className="MaintenanceCompletedTaskPanelContent-CostsPanel"
                />
              </div>
            </Collapse.Panel>
            <Collapse.Panel
              header={'Attachments'}
              key={collapsePanels.Attachments}
              className="MaintenanceCompletedTaskPanelContent-block"
            >
              <div className="MaintenanceCompletedTaskPanelContent-Attachments">
                <div className="MaintenanceCompletedTaskPanelContent-AttachmentsManager">
                  {Boolean(completed.documents.list.length) || ui.isEdit.value ? (
                    <FileManager
                      id="maintenance-task-completed"
                      files={completed.documents.list}
                      multiple
                      edit={ui.isEdit.value}
                      onAdd={(file) => {
                        completed.documents.add(file);
                      }}
                      onDownload={() => true}
                      onPreview={() => true}
                      onRemove={(file) => {
                        completed.documents.remove(file);
                      }}
                      onUpload={() => true}
                    />
                  ) : (
                    <div>-</div>
                  )}
                </div>
              </div>
            </Collapse.Panel>
            <Collapse.Panel
              header={'Note'}
              key={collapsePanels.Note}
              className="MaintenanceCompletedTaskPanelContent-block"
            >
              {Boolean(completed.notes.value.length) || ui.isEdit.value ? (
                <MaintenanceCompletedTaskPanelNote
                  notes={completed.notes}
                  isEdit={ui.isEdit}
                  disabled={!validateAccessLevel([ACL.MAINTENANCE.NOTES.UPDATE])}
                />
              ) : (
                <div>-</div>
              )}
            </Collapse.Panel>
          </Collapse>
          <div
            className={classNames('MaintenanceCompletedTaskPanelContent-LoggerInfo', {
              'MaintenanceCompletedTaskPanelContent-LoggerInfo--editing': ui.isEdit.value,
            })}
          >
            <MaintenanceCompletedTaskPanelLoggerInfo
              completedOn={completed.loggerInfo.creationTime}
              completedBy={completed.loggerInfo.createdBy}
              modifiedOn={completed.loggerInfo.lastUpdateTime}
              modifiedBy={completed.loggerInfo.updatedBy}
            />
          </div>
          {ui.isEdit.value ? (
            <div className={navigationCn}>
              <UserAccessLevelComponent requiredAccessLevel={[ACL.MAINTENANCE.BASIC.DELETE]}>
                <div className="MaintenanceCompletedTaskPanelContent-NavigationLeft">
                  {isShowDelete && (
                    <Button
                      title="Delete Task Record"
                      className="MaintenanceCompletedTaskPanelContent-NavigationDelete"
                      onClick={openDeleteModal}
                    />
                  )}
                </div>
              </UserAccessLevelComponent>
              <div className="MaintenanceCompletedTaskPanelContent-NavigationRight">
                <Button title="Cancel" className="Button--cancel" onClick={handleCancel} />
                <Button
                  title="Save"
                  className="Button--apply"
                  disabled={
                    !completed.isUpdated || completed.values.odometer.isEmpty || completed.values.engHours.isEmpty
                  }
                  onClick={handleSave}
                  sending={completed.repositoryModel.patchState.loading}
                />
              </div>
            </div>
          ) : null}
          <Modal
            isModalOpen={isDeleteModalOpen}
            onClose={closeDeleteModal}
            className="MaintenanceCompletedTaskPanelContent-deleteModal"
          >
            <MaintenanceTaskDeleteModal
              taskName={completed.info.taskName.value}
              type="complete"
              onClose={closeDeleteModal}
              deleteTask={completed.delete}
              sending={completed.repositoryModel.deleteState.loading}
            />
          </Modal>
        </div>
      )}
    />
  );
};

export default withCollapseStorage(
  MaintenanceCompletedTaskPanelContent,
  'maintenance-task-collapse-panel',
  Object.values(collapsePanels)
);
