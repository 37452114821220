import React from 'react';
import classNames from 'classnames';

import { LINKS } from 'config';

import './styles.scss';

interface IProps {
  isPro: boolean;
}

const ProfileAccountType: React.FC<IProps> = ({ children, isPro = false }) => {
  const cn = classNames('ProfileAccountType', {
    'ProfileAccountType--pro': isPro,
  });

  return (
    <span className={cn}>
      <span className="ProfileAccountType-title">{children}</span>
      <span className="ProfileAccountType-upgrade">
        <a className="ProfileAccountType-action" href={LINKS.upgradeToPro} rel="noopener noreferrer" target="_blank">
          Upgrade
        </a>
      </span>
    </span>
  );
};

export default ProfileAccountType;
