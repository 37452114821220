import React, { Component, CSSProperties } from 'react';
import { inject, observer } from 'mobx-react';
import { Tooltip } from 'antd';

import { REPORTS_STORE } from 'config';
import type { ReportsStore } from 'stores';

import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';

import { CircleErrorIcon } from 'assets';

import './styles.scss';

interface IProps {
  index: number;
  reportsStore?: ReportsStore;
  style: CSSProperties;
}

@inject(REPORTS_STORE)
@observer
class ReportTableByGroupRowVehicle extends Component<IProps> {
  toggleOpen = () => {
    const {
      reportsStore: {
        reportTableGroup: { rows, toggleVehicleOpen },
      },
      index,
    } = this.props;

    const items = rows[index][1];

    const [id] = items;
    toggleVehicleOpen(id);
  };

  render() {
    const {
      reportsStore: {
        reportTableGroup: { rows, filteredHeadersWidth },
      },
      index,
      style,
    } = this.props;

    const items = rows[index]?.[1];

    if (!items) {
      return null;
    }

    const [, open, name, description,,limitExceeded] = items;
    return (
      <div
        className="ReportTableByGroupRow ReportTableByGroupRowVehicle"
        style={{ ...style, width: filteredHeadersWidth }}
      >
        <span onClick={this.toggleOpen} className="ReportTableByGroupRowVehicle-action">
          {open ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
        </span>
        {limitExceeded && (<span className="ReportTableByGroupRowVehicle-limit">
          <Tooltip
            placement="right"
            title={'This report has reached it\'s record display limit and may only contain a portion of the possible data'}
          >
            <CircleErrorIcon />
          </Tooltip>
        </span>)}
        <span className="ReportTableByGroupRowVehicle-name">{name}</span>
        <span className="ReportTableByGroupRowVehicle-description">{description}</span>
      </div>
    );
  }
}

export default ReportTableByGroupRowVehicle;
