import { computed } from 'mobx';
import MediaVehicleList from './MediaVehicleList';
import MediaGroupList from './MediaGroupList';
import MediaDateRange from './MediaDateRange';
import MediaType from './MediaType';

export default class MediaSource {
  vehicles: MediaVehicleList;
  groups: MediaGroupList;
  dateRange: MediaDateRange;
  mediaType: MediaType;

  constructor() {
    this.vehicles = new MediaVehicleList();
    this.groups = new MediaGroupList();
    this.dateRange = new MediaDateRange();
    this.mediaType = new MediaType();
  }

  @computed get params() {
    return { ...this.vehicles.params, ...this.groups.params, ...this.dateRange.params, ...this.mediaType.params };
  }

  init = ({ assetId, assetGroupId, from, to, expiringSoon, mediaType }) => {
    this.vehicles.init(assetId);
    this.groups.init(assetGroupId);
    this.dateRange.init({ from, to, expiringSoon });
    this.mediaType.init(mediaType);
  };
}
