import type ICustomerInvoice from 'interfaces/stores/Customers/ICustomerInvoice';
import type ICustomerAddress from 'interfaces/stores/Customers/ICustomerAddress';

export class CustomerInvoice implements ICustomerInvoice {
  dueDate: number;
  integrationId: string;
  tax: number;
  billEmail: string;
  shipDate: number;
  transactionDate: number;
  shipAddress: ICustomerAddress;
  totalAmount: number;
  balance: number;
  billAddress: ICustomerAddress;
  shipFromAddress: ICustomerAddress;
  billEmailCc: string;
  exchangeRate: number;
  domain: string;
  customerId: number;
  deposit: number;
  currency: string;
  id: number;
  status: string;
  jobId: number;

  constructor(props) {
    this.dueDate = props.dueDate
    this.integrationId = props.integrationId
    this.tax = props.tax
    this.billEmail = props.billEmail
    this.shipDate = props.shipDate
    this.transactionDate = props.transactionDate
    this.shipAddress = props.shipAddress
    this.totalAmount = props.totalAmount
    this.balance = props.balance
    this.billAddress = props.billAddress
    this.shipFromAddress = props.shipFromAddress
    this.billEmailCc = props.billEmailCc
    this.exchangeRate = props.exchangeRate
    this.domain = props.domain
    this.customerId = props.customerId
    this.deposit = props.deposit
    this.currency = props.currency
    this.id = props.id
    this.status = props.status
    this.jobId = props.jobId
  }

  get contact(): string {
    if (!this.billAddress.city && this.billAddress.line1) {
      return this.billAddress.line1;
    }
    if (!this.shipAddress.city && this.shipAddress.line1) {
      return this.shipAddress.line1;
    }
    return '-';
  }

  get address(): string[] {
    if (this.shipAddress) {
      if ((this.shipAddress.line1 || this.shipAddress.line2) && this.shipAddress.city && !this.shipAddress.line4) {
        return [
          [this.shipAddress.line1, this.shipAddress.line2].filter(Boolean).join(' '),
          `${this.shipAddress.city}, ${this.shipAddress.countrySubDivisionCode} ${this.shipAddress.postalCode} ${this.shipAddress.country}`,
        ];
      } else if ((this.shipAddress.line1 || this.shipAddress.line2 || this.shipAddress.line3 || this.shipAddress.line4)) {
        return [
          [this.shipAddress.line1, this.shipAddress.line2].filter(Boolean).join(' '),
          [this.shipAddress.line3, this.shipAddress.line4].filter(Boolean).join(' '),
        ]
      }
    }
    return ['-'];
  }

  get email(): string {
    return this.billEmail || this.billEmailCc || ''
  }
}
