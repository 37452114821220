import React, { FC } from 'react';
import classnames from 'classnames';

import { INSPECTION_ITEM_STATUSES } from 'config';

import { CheckInCircleIconSrc, InfoIcon, CircleIconNa } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  status: INSPECTION_ITEM_STATUSES;
}

const InspectionItemStatusIcon: FC<IProps> = ({ className, status }) => {
  const cn = classnames('InspectionItemStatusIcon', className);

  const renderIcon = () => {
    switch (status) {
      case INSPECTION_ITEM_STATUSES.PASSED:
        return <CheckInCircleIconSrc fill="#4CAF50" />;
      case INSPECTION_ITEM_STATUSES.FAILED:
        return <InfoIcon fill="#F44336" />;
      case INSPECTION_ITEM_STATUSES.N_A:
        return <CircleIconNa fill="#6B7A99" />;
    }
  };

  return (
    <div className={cn}>
      <div className="InspectionItemStatusIcon-icon">{renderIcon()}</div>
    </div>
  );
};

export default InspectionItemStatusIcon;
