import type TableToggleFilter from 'models/Tables/TableToggleFilter';
import PermissionsFilter from './Permissions';

export default class Filters {
  permissions: PermissionsFilter;

  constructor({ permissions }: { permissions?: string }) {
    this.permissions = new PermissionsFilter();
    this.init(this.permissions.list, permissions);
  }

  init = (filters: TableToggleFilter[] = [], savedValues: string = '') => {
    const arr = savedValues.split(',');

    filters.forEach((filter) => {
      if (arr.includes(filter.value)) {
        filter.isActive.toggle(true);
      }
    });
  };
}
