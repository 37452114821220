import { observable, action } from 'mobx';
import { sortByAlphabet } from 'utils';
import type { IFuelType, IFuelTypes } from 'interfaces/stores/Assets/IFuelTypes';
import { repositoryService } from 'services';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import FuelType from './FuelType';

class FuelTypes implements IFuelTypes {
  @observable items: IFuelType[] = [];
  repository: IRepository;
  repositoryFuelType: IEntityRepository;

  constructor() {
    this.repository = repositoryService.get('assets');
    this.repositoryFuelType = this.repository.entity('fuel-types');
  }

  @action fetch = async () => {
    const response = await this.repositoryFuelType.get();

    this.items = response.sort((a, b) => sortByAlphabet(a.name, b.name)).map((item) => new FuelType(item));
  };
}

export default FuelTypes;
