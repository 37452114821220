import React from 'react';

import { MinusSquareFilled } from '@ant-design/icons';

import GroupTableVehiclesButton from '../index';

interface IProps {
  id?: string;
  text?: string;
  disabled?: boolean;

  onClick?(id?: string): void;
}

const GroupTableVehiclesRemove: React.FC<IProps> = (props) => {
  return (
    <GroupTableVehiclesButton {...props}>
      <MinusSquareFilled />
    </GroupTableVehiclesButton>
  );
};

export default GroupTableVehiclesRemove;
