import React, { useState } from 'react';
import { UserAccessLevelComponent } from 'stores';
import { ACL } from 'config';
import Checkbox from 'components/Checkbox';

import './styles.scss';

interface IProps {
  iconLink: string;
  iconColor: string;
  heading: boolean;
  label: string;
  isChecked: boolean;
  toggle: (eventName: string) => void;
  arrowColor: string;
}

const PathMapOption: React.FC<IProps> = ({ iconColor, iconLink, heading, label, isChecked, toggle, arrowColor }) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => setImageError(true);
  const handleImageSuccess = () => setImageError(false);

  return (
    <div className="PathMap-checkbox">
      <div className="PathMap-checkboxText">
        <Checkbox label={label} value={label} checked={isChecked} onChange={toggle} />
      </div>
      {iconLink && !imageError ? (
        <img
          className="PathMap-checkboxIcon"
          src={iconLink}
          onError={handleImageError}
          onAbort={handleImageError}
          onLoad={handleImageSuccess}
        />
      ) : (
        <svg width="24" height="22" viewBox="-1 -1 22 21" xmlns="http://www.w3.org/2000/svg">
          <circle cx="10" cy="10" r="10" fill={`#${iconColor}`} stroke="#35454D" strokeWidth="1px" />
          <UserAccessLevelComponent requiredAccessLevel={[ACL.MAP.SETTINGS.DIRECTIONAL_ARROWS.READ]}>
            {heading && (
              <path
                d="M11.1969 15L11.1969 8.93128L15 8.93128L10.1103 4.04154L5.22051 8.93128L9.02364 8.93128L9.02364 15L11.1969 15Z"
                fill={`#${arrowColor}`}
                transform="rotate(0, 10, 10)"
              />
            )}
          </UserAccessLevelComponent>
        </svg>
      )}
    </div>
  );
};

export default PathMapOption;
