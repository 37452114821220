import { action, observable } from 'mobx';
import update from 'immutability-helper';

import { getStorageItem, setStorageItem } from 'utils';
import { STORAGE_ITEMS } from 'config';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

export default class PinnedListBase {
  @observable pinnedDevicesIdsList: string[] = JSON.parse(getStorageItem(STORAGE_ITEMS.pinnedDevicesIds)) || [];
  @observable pinnedGroupsIdsList: string[] = JSON.parse(getStorageItem(STORAGE_ITEMS.pinnedGroupsIds)) || [];
  @observable pinnedGeozonesIdsList: string[] = JSON.parse(getStorageItem(STORAGE_ITEMS.pinnedGeozonesIds)) || [];

  static setPinnedList = (list: string[], id: string): string[] => {
    const pinnedList = list.slice();
    const pinnedIndex = pinnedList.indexOf(id);

    if (pinnedIndex === -1) {
      pinnedList.push(id);
    } else {
      pinnedList.splice(pinnedIndex, 1);
    }

    return pinnedList;
  };

  static updatePinnedList = (list: string[], dragIndex: number, hoverIndex: number): string[] => {
    const dragDeviceId = list[dragIndex];
    const pinnedList = update(list, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragDeviceId],
      ],
    });

    return pinnedList;
  };

  @action setPinnedDevicesIds = (deviceId) => {
    const pinnedList = PinnedListBase.setPinnedList(this.pinnedDevicesIdsList, deviceId);

    this.pinnedDevicesIdsList = pinnedList;
    EventsBus.get().trigger(APP_EVENTS.DEVICES.PINNED);
    setStorageItem(STORAGE_ITEMS.pinnedDevicesIds, JSON.stringify(pinnedList));
  };

  @action dragPinnedDevices = (dragIndex: number, hoverIndex: number) => {
    const pinnedList = PinnedListBase.updatePinnedList(this.pinnedDevicesIdsList, dragIndex, hoverIndex);

    this.pinnedDevicesIdsList = pinnedList;
    EventsBus.get().trigger(APP_EVENTS.DEVICES.PINNED);
    setStorageItem(STORAGE_ITEMS.pinnedDevicesIds, JSON.stringify(pinnedList));
  };

  @action setPinnedGroupsIds = (groupId) => {
    const pinnedList = PinnedListBase.setPinnedList(this.pinnedGroupsIdsList, groupId);

    this.pinnedGroupsIdsList = pinnedList;
    setStorageItem(STORAGE_ITEMS.pinnedGroupsIds, JSON.stringify(pinnedList));
  };

  @action dragPinnedGroups = (dragIndex: number, hoverIndex: number) => {
    const pinnedList = PinnedListBase.updatePinnedList(this.pinnedGroupsIdsList, dragIndex, hoverIndex);

    this.pinnedGroupsIdsList = pinnedList;
    setStorageItem(STORAGE_ITEMS.pinnedGroupsIds, JSON.stringify(pinnedList));
  };

  @action setPinnedGeozonesIds = (geozoneId) => {
    const pinnedList = PinnedListBase.setPinnedList(this.pinnedGeozonesIdsList, geozoneId);

    this.pinnedGeozonesIdsList = pinnedList;
    setStorageItem(STORAGE_ITEMS.pinnedGeozonesIds, JSON.stringify(pinnedList));
  };

  @action dragPinnedGeozones = (dragIndex: number, hoverIndex: number) => {
    const pinnedList = PinnedListBase.updatePinnedList(this.pinnedGeozonesIdsList, dragIndex, hoverIndex);

    this.pinnedGeozonesIdsList = pinnedList;
    setStorageItem(STORAGE_ITEMS.pinnedGeozonesIds, JSON.stringify(pinnedList));
  };

  @action resetPinnedDevicesIds = () => {
    EventsBus.get().trigger(APP_EVENTS.DEVICES.PINNED);
    this.pinnedDevicesIdsList = [];
  };

  @action resetPinnedGroupsIds = () => (this.pinnedGroupsIdsList = []);

  @action resetPinnedGeozonesIds = () => (this.pinnedGeozonesIdsList = []);

  @action reset = () => {
    this.resetPinnedDevicesIds();
    this.resetPinnedGroupsIds();
    this.resetPinnedGeozonesIds();
  };
}
