import React, { FC } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import type { IDashcamSettingsField } from 'interfaces/models/Dashcams/IDashcamSettingsField';
import type { ToggleField } from 'models/Fields';

import InfoTooltip from 'components/InfoTooltip';
import Toggle from 'components/Toggle';
import CameraSettingsItem from '../index';

import { EmptySvgIcon } from 'assets';

import './styles.scss';

interface IProps {
  item: IDashcamSettingsField<ToggleField>;
  disabled?: boolean;
  onChange?: () => void;
}

const CameraSettingsItemSubBoolean: FC<IProps> = ({ item, disabled = false, onChange = () => void 0 }) => {
  const isExpanded = item.expanded.value || item.value.value;

  const handleChange = () => {
    item.value.toggle();
    onChange();
  };

  return (
    <div
      className={classnames('CameraSettingsItem CameraSettingsItemSubBoolean', {
        'CameraSettingsItem--disabled CameraSettingsItemSubBoolean--disabled': disabled,
      })}
    >
      <div className="CameraSettingsItemSubBoolean-header">
        <div className="CameraSettingsItem-info CameraSettingsItemSubBoolean-info">
          <div className="CameraSettingsItem-name CameraSettingsItemSubBoolean-name">{item.name}</div>
          {item.description && (
            <InfoTooltip className="CameraSettingsItem-infoTooltip CameraSettingsItemSubBoolean-infoTooltip">
              {item.description}
            </InfoTooltip>
          )}
        </div>
        <div className="CameraSettingsItem-action CameraSettingsItemSubBoolean-action">
          <Toggle
            disabled={disabled}
            className="CameraSettingsItem-toggle CameraSettingsItemSubBoolean-toggle"
            checked={disabled ? false : item.value.value}
            onChange={handleChange}
          />
          {disabled && (
            <InfoTooltip
              icon={<EmptySvgIcon />}
              className="CameraSettingsItem-actionTooltip CameraSettingsItemSubBoolean-actionTooltip"
            >{`Enable ${item.parent} to access this control`}</InfoTooltip>
          )}
        </div>
      </div>
      <div className="CameraSettingsItemSubBoolean-body">
        {isExpanded && (
          <div className="CameraSettingsItem-settings CameraSettingsItemSubBoolean-settings">
            {item.settings.map((setting, index) => {
              return (
                <CameraSettingsItem
                  key={setting.id || String(index)}
                  item={setting}
                  disabled={item.value?.value === false}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(CameraSettingsItemSubBoolean);
