import React from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  className?: string;
  onClick?: () => void;
}

const Dot = ({ className, onClick }: IProps) => {
  const cn = classNames('CarouselDot', className);

  return <span className={cn} onClick={onClick} />;
};

export default Dot;
