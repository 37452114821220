import { cloneDeep, orderBy } from 'lodash';

import type { ReportsStore } from 'stores';
import TableDownload from 'models/Tables/TableDownload';
import { extractTextFromHtml } from 'utils';

class ReportDownload extends TableDownload<ReportsStore> {
  protected addFormData = (data: FormData) => {
    data.append('reportName', this.context.selectedReport.title);
    data.append('from', this.context.timeFrom);
    data.append('to', this.context.timeTo);
    data.append('options', this.context.reportHeaderOptionsText);
  };

  protected getFile = async () => {
    let tables = [];

    if (this.context.reportTable) {
      const table = this.getData({
        header: this.context.reportTable.header,
        body: this.context.reportTable.body,
        total: this.context.reportTable.totalValues,
        filter: this.context.reportTable.editColumns.editColumnsList,
        search: this.context.reportTable.filter.search,
        sort: [
          {
            field: this.context.reportTable.filter.sort.field.value,
            order: this.context.reportTable.filter.sort.order.value,
          },
        ],
      });

      tables = [{ ...table, title: null }];
    } else if (this.context.reportTableGroup.vehicles.length) {
      const tablesData = this.fallBackTableGroup({
        headers: this.context.reportTableGroup.headers,
        vehicles: this.context.reportTableGroup.vehicles,
      });

      const disableSorting = this.context.reportTableGroup.headers.some((header) => header.sortable === false);

      tables = tablesData.map((table) => {
        const data = this.getData({
          header: table.header,
          body: table.body,
          total: table.totalValues,
          filter: this.context.reportTableGroup.editColumns.editColumnsList,
          search: {
            value: this.context.reportTableGroup.headers
              .filter((item) => item.search !== '')
              .reduce((acc, curr) => [...acc, { value: { column: curr.key, isActive: true, value: curr.search } }], []),
          },
          sort: disableSorting
            ? []
            : this.context.reportTableGroup.sortableHeaders.map(({ key, direction }) => ({
                field: key,
                order: direction === 'ASC' ? 'ascend' : 'descend',
              })),
        });

        return { ...data, title: table.title };
      });
    } else if (this.context.reportTableGroup && this.context.reportTableGroup.tables.length) {
      const disableSorting = this.context.reportTableGroup.headers.some((header) => header.sortable === false);
      tables = this.context.reportTableGroup.tables.map((table) => {
        const data = this.getData({
          header: table.header,
          body: table.dataSource,
          total: table.totalValues,
          filter: this.context.reportTableGroup.editColumns.editColumnsList,
          search: {
            value: this.context.reportTableGroup.headers
              .filter((item) => item.search !== '')
              .reduce((acc, curr) => [...acc, { value: { column: curr.key, isActive: true, value: curr.search } }], []),
          },
          sort: disableSorting
            ? []
            : this.context.reportTableGroup.sortableHeaders.map(({ key, direction }) => ({
                field: key,
                order: direction === 'ASC' ? 'ascend' : 'descend',
              })),
        });

        return { ...data, title: table.title };
      });
    }

    tables = tables
      .map((table) => {
        let rows = table.rows;
        const columnsWithHTML = table.columns.reduce((acc, name, index) => {
          return {
            ...acc,
            [index]: ['Due In', 'Frequency'].includes(name) ? 1 : 0,
          };
        }, {});
        const hasColumnsWithHTML = Object.values(columnsWithHTML).some(Boolean);
        if (hasColumnsWithHTML) {
          rows = rows.map((row) =>
            row.map((cell, index) => {
              return columnsWithHTML[index] ? extractTextFromHtml(cell) : cell;
            })
          );
        }

        return {
          ...table,
          rows,
        };
      })
      .filter((table) => table.rows.length);

    const ReportFile = await import('./ReportFile');
    const reportFile = new ReportFile.default(this.format.value, {
      title: this.context.selectedReport.title,
      description: `${this.context.reportHeaderOptionsText} | ${this.context.reportHeaderTimeRangeText}`,
      tables,
    });

    const file = await reportFile.create();

    return file;
  };

  protected get fileName() {
    return `Report ${this.context.selectedReport.title}.${this.format.value}`;
  }

  private getData = ({ header, body, total, filter, search, sort }) => {
    let columns = cloneDeep(header);
    let rows = cloneDeep(body);

    if (filter.length) {
      columns = this.getSelectedColumns(header, filter);
    }

    rows = this.filterBySearch(rows, search.value);

    if (sort.length) {
      rows = this.sortColumns(rows, sort);
    }

    return {
      columns: columns.map((item) => item[1]),
      rows: rows.map((rowItem) => columns.map((columnItem) => rowItem[columnItem[0]].value)),
      total: columns.map((column) => (column[0] === 'index' ? '' : total[column[0]])),
    };
  };

  private filterBySearch = (rows, search) => {
    return rows.filter((item) => {
      if (search.length) {
        return search.every((searchItem) => {
          if (item.hasOwnProperty(searchItem.value.column)) {
            return (
              !searchItem.value.value ||
              item[searchItem.value.column].value.toLowerCase().includes(searchItem.value.value.toLowerCase())
            );
          }
        });
      }

      return item;
    });
  };

  private getSelectedColumns = (header, selectedColumns) => {
    const filteredColumns = selectedColumns.filter((column) => column.isSelected);

    return filteredColumns.map((column) => header.find((item) => item[0].toLowerCase() === column.value.toLowerCase()));
  };

  private sortColumns = (rows, sort) => {
    return orderBy(
      rows,
      sort.map((value) => (item) => {
        return item[value.field]?.sortValue || item[value.field]?.value;
      }),
      sort.map((value) => (value.order === 'ascend' ? 'asc' : 'desc'))
    );
  };

  private fallBackTableGroup = ({ headers, vehicles }) => {
    const header = headers.map((item) => [item.key, item.value]);

    return vehicles.map((vehicle) => {
      const totals = {};

      vehicle.totals?.forEach((total, i) => {
        totals[headers[i].key] = total.value;
      });

      return {
        header,
        title: vehicle.description ? `${vehicle.displayName} | ${vehicle.description}` : vehicle.displayName,
        body: vehicle.items.map((line) => {
          const result = {};

          line.forEach((cell, i) => {
            result[headers[i].key] = {
              value: cell.value,
              sortValue: cell.sortValue,
            };
          });

          return result;
        }),
        totalValues: totals,
      };
    });
  };
}

export default ReportDownload;
