import React, { useRef } from 'react';
import { Observer } from 'mobx-react';
import { mediaPlayerMarker } from 'assets';

import type { MapStore } from 'stores';
import type { SimpleField } from 'models/Fields';
import type GPS from 'models/Dashcam/MediaLibrary/MediaClips/MediaClipsItem/MediaInfo/GPS';
import type Location from 'models/Dashcam/MediaLibrary/MediaClips/MediaClipsItem/MediaInfo/Location';

import CustomMap from 'components/Map';
import StreetViewLayer from 'components/Map/StreetViewLayer';
import Marker from 'components/Map/Marker';
import VehicleRoute from 'components/Map/VehicleRoute';

interface IProps {
  map: MapStore;
  gps: GPS;
  location: Location;
  progressSecond: SimpleField<number>;
}

const MediaLibraryMap: React.FC<IProps> = ({ map, gps, progressSecond, location }) => {
  const mapRef = useRef(null);
  const bounds = new google.maps.LatLngBounds();

  if (gps.items.length) {
    gps.items.forEach(({ latitude, longitude }) => {
      bounds.extend({ lat: latitude, lng: longitude });
    });
  } else {
    bounds.extend({ lat: location.lat, lng: location.lng });
  }

  return (
    <div className="MediaLibraryMap">
      <Observer
        render={() => (
          <CustomMap
            map={mapRef}
            bounds={bounds}
            zoom={map.mapZoom}
            center={map.mapCenter}
            googleMapURL={process.env.REACT_APP_GOOGLE_MAP_URL}
            loadingElement={<div style={{ height: `200px` }} />}
            containerElement={<div style={{ height: `200px`, width: '100%', maxWidth: '340px' }} />}
            mapElement={<div style={{ height: `200px` }} />}
            setStreetViewOpened={map.setStreetViewOpened}
            clickableIcons={false}
          >
            <StreetViewLayer
              visible={map.streetViewOpened}
              position={map.streetViewPosition}
              pov={map.streetViewPov}
              showZoomControl={false}
              showPanControl={false}
              onCloseClick={map.setStreetViewOpened}
            />
            {gps.isAvailable ? (
              <Marker position={gps.getCoordinate(progressSecond.value)} icon={mediaPlayerMarker} />
            ) : location.isAvailable ? (
              <Marker position={{ lat: location.lat, lng: location.lng }} icon={mediaPlayerMarker} />
            ) : null}
            <VehicleRoute path={gps.route} color="#FF8801" weight={3} />
          </CustomMap>
        )}
      />
    </div>
  );
};

export default MediaLibraryMap;
