import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { inject, observer } from 'mobx-react';

import { PATHS, REPORTS_STORE } from 'config';

import type { ReportsStore } from 'stores';

import ReportTableContainer from '../ReportTableContainer';

interface IProps {
  className?: string;
  reportsStore?: ReportsStore;
}

@inject(REPORTS_STORE)
@observer
class ReportsPageTable extends Component<IProps, null> {
  render() {
    const {
      reportsStore: { selectedReport, isReportExist },
    } = this.props;

    if (!selectedReport && isReportExist.value) {
      return null;
    }

    return (
      <>
        {isReportExist.value ? (
          <>
            <div className="ReportPage-table">
              <ReportTableContainer />
            </div>
          </>
        ) : (
          <Redirect to={PATHS.NOT_FOUND} />
        )}
      </>
    );
  }
}

export default ReportsPageTable;
