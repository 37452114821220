import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Input from 'components/Input';
import type { SimpleField } from 'models/Fields';

import './styles.scss';

interface IProps<T> {
  title?: string;
  className?: string;
  isEditable?: boolean;
  showError?: boolean;
  errorMessage?: string;
  field: SimpleField<T>;
}

function AdminField<T>({
  isEditable = true,
  title = '',
  showError = false,
  errorMessage = '',
  className,
  field,
}: IProps<T>) {
  const handleChange = (e) => {
    field.set(e.currentTarget.value);
  };

  const handleBlur = (e) => {
    field.set(e.currentTarget.value.trim());
  };

  const cn = classnames('AdminField', className);

  return (
    <div className={cn}>
      <p>{title}</p>
      <Input
        disabled={!isEditable}
        value={String(field.value)}
        error={showError && field.isEmpty ? errorMessage : ''}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
}

export default observer(AdminField);
