import React, { FC, Fragment, useMemo } from 'react';
import CoordinatesInput from '../CoordinatesInput';

import './styles.scss';
import Button from 'components/Button';
import { RangePlusIcon } from 'assets';

interface IProps {
  addMarker: () => void;
  center: Locations.ILocation;
  circlePathMarkerPosition: Locations.ILocation;
  color: Geozone.IGeozoneColor;
  coordinates: Locations.ILocation[];
  dimmed?: boolean;
  disabled?: boolean;
  hoverIndex: number;
  onHover?: (index: number) => void;
  removeMarker: (index) => void;
  type?: Geozone.geozoneType;
  updateMarker: ({ index, lat, lng }) => void;
}

const ZoneMarkersList: FC<IProps> = ({
  addMarker,
  center,
  circlePathMarkerPosition,
  color,
  coordinates,
  dimmed,
  disabled = false,
  hoverIndex,
  onHover,
  removeMarker,
  type,
  updateMarker,
}) => {
  const canDelete = useMemo(() => coordinates.length > 3, [coordinates]);
  const canAdd = useMemo(() => coordinates.length < 10, [coordinates]);

  return (
    <div className="ZoneMarkersList">
      <div className="ZoneMarkersList-container">
        {type.toLowerCase() === 'polygon' && (
          <Fragment>
            {coordinates.map(({ lat, lng }, index) => (
              <CoordinatesInput
                canDelete={canDelete}
                color={color.hex}
                colorName={color.name}
                dimmed={dimmed}
                disabled={disabled}
                hoverIndex={hoverIndex}
                key={index}
                lat={lat.toString()}
                lng={lng.toString()}
                onHover={onHover}
                orderNumber={index}
                removeMarker={removeMarker}
                updateMarker={updateMarker}
              />
            ))}
            <Button
              disabled={!canAdd || disabled}
              className="ZoneMarkersList-addButton Button--link"
              title={'Add Zone Marker'}
              IconComponent={<RangePlusIcon className="ZoneMarkersList-addButtonIcon" />}
              onClick={addMarker}
            />
          </Fragment>
        )}
        {type.toLowerCase() === 'circle' && center && (
          <Fragment>
            <CoordinatesInput
              disabled={disabled}
              isCenter
              canDelete={false}
              key={0}
              orderNumber={0}
              lat={center.lat.toString()}
              lng={center.lng.toString()}
              color={color.hex}
              colorName={color.name}
              updateMarker={updateMarker}
              removeMarker={removeMarker}
            />
            {circlePathMarkerPosition && (
              <CoordinatesInput
                isCirclePath
                disabled={disabled}
                canDelete={false}
                key={0}
                orderNumber={0}
                lat={circlePathMarkerPosition.lat.toString()}
                lng={circlePathMarkerPosition.lng.toString()}
                color={color.hex}
                colorName={color.name}
                updateMarker={updateMarker}
                removeMarker={removeMarker}
              />
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ZoneMarkersList;
