import React from 'react';
import { Observer } from 'mobx-react';
import classnames from 'classnames';

import Button from 'components/Button';
import MaintenanceMarkAsCompleteInfo from './MaintenanceMarkAsCompleteInfo';
import type MaintenanceTask from 'models/Maintenances/MaintenanceTask';
import MaintenanceMarkAsCompleteDate from './MaintenanceMarkAsCompleteDate';
import MaintenancePreferenceAttachments from '../TaskModal/MaintenancePreferenceAttachments';
import MaintenancePreferenceNote from '../TaskModal/MaintenancePreferenceNote';
import MaintenanceMarkAsCompleteOdometer from './MaintenanceMarkAsCompleteOdometer';
import MaintenanceMarkAsCompleteEngineHours from './MaintenanceMarkAsCompleteEngineHours';
import MaintenanceMarkAsCompletePerformedBy from './MaintenanceMarkAsCompletePerformedBy';
import MaintenanceMarkAsCompleteCostOfService from './MaintenanceMarkAsCompleteCostOfService';
import MaintenanceMarkAsCompleteRepeatService from './MaintenanceMarkAsCompleteRepeatService';

import './styles.scss';

interface IProps {
  task: MaintenanceTask;
  onClose: () => void;
}

const MarkAsCompleteModal: React.FC<IProps> = ({ task, onClose }) => {
  const onSave = async () => {
    await task.completed.save();
    if (task.completed.repositoryHistory.createState.success) {
      onClose();
    }
  };

  const cnBodyForm = classnames('MaintenanceMarkAsCompleteModal-bodyForm', {
    'MaintenanceMarkAsCompleteModal-bodyForm--repeat': task.model.isRepeat,
  });

  return (
    <Observer
      render={() => (
        <div className="MaintenanceMarkAsCompleteModal">
          <div className="MaintenanceMarkAsCompleteModal-header">
            <h3 className="MaintenanceMarkAsCompleteModal-title">Mark As Complete</h3>
          </div>
          <div className="MaintenanceMarkAsCompleteModal-body">
            <MaintenanceMarkAsCompleteInfo info={task.completed.info} representation={task.representation} />
            <div className={cnBodyForm}>
              <MaintenanceMarkAsCompleteDate date={task.completed.values.serviceTime} />
              <MaintenanceMarkAsCompleteOdometer odometer={task.completed.values.odometer} />
              <MaintenanceMarkAsCompleteEngineHours engHours={task.completed.values.engHours} />
              <MaintenanceMarkAsCompletePerformedBy name={task.completed.performedByFullName} />
              <MaintenanceMarkAsCompleteCostOfService costs={task.completed.costs} />
              <MaintenancePreferenceAttachments documents={task.completed.documents} />
              <MaintenancePreferenceNote note={task.completed.notes} isShowTitle={false} />
              {task.model.isRepeat ? (
                <MaintenanceMarkAsCompleteRepeatService
                  odometer={task.completed.maintenance.odometer}
                  engHours={task.completed.maintenance.engHours}
                  serviceTime={task.completed.maintenance.serviceTime}
                  isCompleted={task.completed.maintenance.isCompleted}
                  values={task.completed.values}
                />
              ) : null}
            </div>
          </div>
          <div className="MaintenanceMarkAsCompleteModal-footer">
            <div className="MaintenanceMarkAsCompleteModal-navigation">
              <Button
                className="MaintenanceMarkAsCompleteModal-navigationCancel Button--cancel"
                onClick={onClose}
                title="Cancel"
                inline
              />
              <Button
                className="MaintenanceMarkAsCompleteModal-navigationSave Button--apply"
                onClick={onSave}
                disabled={task.completed.values.odometer.isEmpty || task.completed.values.engHours.isEmpty}
                sending={task.completed.repositoryHistory.createState.loading}
                title="Save"
                inline
              />
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default MarkAsCompleteModal;
