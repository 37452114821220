import React, { FC } from 'react';
import Modal from 'components/Modal';
import Button from 'components/Button';

import './styles.scss';

interface IProps {
  fileName: string;
  onCancel: () => void;
  onSubmit: () => void;
  open: boolean;
}

const ConfirmDeleteModal: FC<IProps> = ({ fileName, open, onCancel, onSubmit }) => {
  return (
    <Modal className="Modal--confirmDelete" isModalOpen={open} onClose={onCancel} withoutCloseIcon>
      <div className="ConfirmDeleteModal">
        <p className="ConfirmDeleteModal-title">Delete {fileName}</p>
        <p className="ConfirmDeleteModal-text">
          Deleting this attachment will permanently remove it from the system and it will no longer be accessible.
          <br />
          <br />
          Do you want to continue?
        </p>
        <div className="ConfirmDeleteModal-buttons">
          <div className="ConfirmDeleteModal-button ConfirmDeleteModal-button--cancel">
            <Button className="Button Button--cancel" title="No, Cancel" onClick={onCancel} />
          </div>
          <div className="ConfirmDeleteModal-button ConfirmDeleteModal-button--submit">
            <Button className="Button Button--apply" title="Yes, Delete" onClick={onSubmit} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
