import React, { ReactElement } from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  icon?: ReactElement;
  className?: string;
}

const MapVehiclePanelEventBase: React.FC<IProps> = ({ icon, children, className }) => {
  const cn = classNames('MapVehiclePanelEventBase-content', className);

  return (
    <div className="MapVehiclePanelEventBase-container">
      <div className="MapVehiclePanelEventBase-icon">{icon}</div>
      <div className={cn}>{children}</div>
    </div>
  );
};

export default MapVehiclePanelEventBase;
