import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';

import { PATHS } from 'config';
import type { DevicesStore, MapStore } from 'stores';

import SelectedVehiclePredefinedTimeFilter from './SelectedVehiclePredefinedTimeFilter';
import SelectedVehicleCustomTimeFilter from './SelectedVehicleCustomTimeFilter';
import VehiclesCustomTimeFilter from './VehiclesCustomTimeFilter';
import Vehicles from './Vehicles';

interface IProps {
  devicesStore?: DevicesStore;
  mapStore?: MapStore;
}

@inject(({ devicesMapStore: { devicesStore } }) => ({
  devicesStore,
}))
@observer
class VehiclesRoutes extends Component<IProps> {
  componentWillUnmount(): void {
    const {
      devicesStore: { resetSelectedDevice },
    } = this.props;

    resetSelectedDevice();
  }

  render() {
    return (
      <Switch>
        <Route path={PATHS.MAP.VEHICLE_PREDEFINED_TIME_FILTER} component={SelectedVehiclePredefinedTimeFilter} />
        <Route path={PATHS.MAP.VEHICLE_TIME_FILTER} component={SelectedVehicleCustomTimeFilter} />
        <Route path={PATHS.MAP.VEHICLES_TIME_FILTER} component={VehiclesCustomTimeFilter} />
        <Route path={PATHS.MAP.VEHICLE} component={Vehicles} />
        <Route path={PATHS.MAP.VEHICLES} component={Vehicles} />
        <Route exact path={PATHS.MAP.GROUP} component={Vehicles} />
        <Route exact path={PATHS.MAP.GROUP_VEHICLE} component={Vehicles} />
      </Switch>
    );
  }
}

export default VehiclesRoutes;
