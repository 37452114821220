import React, { memo } from 'react';
import { Marker as RGMMarker, MarkerProps } from 'react-google-maps';

interface IProps {
  icon?: string;
  anchor?: google.maps.Point;
  options?: google.maps.MarkerOptions;
  position:
    | {
        lat: number;
        lng: number;
      }
    | google.maps.LatLng;
}

const Marker: React.FC<IProps & MarkerProps> = ({ icon = '', position, anchor, onClick, options = {}, ...props }) => {
  return (
    <RGMMarker
      position={position}
      onClick={onClick}
      options={{
        icon: icon
          ? {
              url: icon,
              anchor,
            }
          : undefined,
        optimized: false,
        ...options,
      }}
      {...props}
    />
  );
};

export default memo(Marker);
