import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { ChevronIcon } from 'assets';
import './styles.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
  showDefaultIcon?: boolean;
  link?: string;
  onClick?: () => void;
}

const BackButton: FC<IProps> = ({ children, className, showDefaultIcon = true, link = '', onClick = () => true }) => {
  const cn = classnames('BackButton', className);

  return (
    <div className={cn}>
      {showDefaultIcon && (
        <div className="BackButton-icon">
          <ChevronIcon />
        </div>
      )}
      {link ? (
        <Link className="BackButton-trigger BackButton-trigger--link" to={link}>
          {children}
        </Link>
      ) : (
        <button className="BackButton-trigger BackButton-trigger--button" onClick={onClick}>
          {children}
        </button>
      )}
    </div>
  );
};

export default BackButton;
