import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';

import { PATHS } from 'config';
import type { GeozonesStore } from 'stores';

import Geozones from './Geozones';
import GeozonePanel from './GeozonePanel';

interface IProps {
  geozonesStore: GeozonesStore;
}

@inject(({ geozonesStore }) => ({
  geozonesStore,
}))
@observer
class GeozonesRoutes extends Component<IProps> {
  componentWillUnmount(): void {
    const {
      geozonesStore: { reset },
    } = this.props;

    reset(true, true);
  }

  render() {
    return (
      <Switch>
        <Route path={PATHS.MAP.GEOZONE} component={GeozonePanel} />
        <Route path={PATHS.MAP.GEOZONES} component={Geozones} />
      </Switch>
    );
  }
}

export default GeozonesRoutes;
