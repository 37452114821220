import React from 'react';
import type MaintenanceCompleteInfoModel from 'models/Maintenances/Models/CompleteTask/MaintenanceCompleteInfoModel';
import type MaintenanceRepresentation from 'models/Maintenances/Representation/MaintenanceRepresentation';

import './styles.scss';

interface IProps {
  info: MaintenanceCompleteInfoModel;
  representation: MaintenanceRepresentation;
}

const MaintenanceMarkAsCompleteInfo: React.FC<IProps> = ({ info, representation }) => {
  return (
    <div className="MaintenanceMarkAsCompleteInfo">
      <ul className="MaintenanceMarkAsCompleteInfo-content">
        <li className="MaintenanceMarkAsCompleteInfo-column MaintenanceMarkAsCompleteInfo-contentTaskName">
          <p className="MaintenanceMarkAsCompleteInfo-title">Task Name</p>
          <p className="MaintenanceMarkAsCompleteInfo-value">{info.taskName.value}</p>
        </li>
        <li className="MaintenanceMarkAsCompleteInfo-column MaintenanceMarkAsCompleteInfo-contentVehicle">
          <p className="MaintenanceMarkAsCompleteInfo-title">Vehicle/Asset</p>
          <p className="MaintenanceMarkAsCompleteInfo-value">{info.vehicleName}</p>
        </li>
        <li className="MaintenanceMarkAsCompleteInfo-column MaintenanceMarkAsCompleteInfo-contentDueIn">
          <p className="MaintenanceMarkAsCompleteInfo-title">Due In</p>
          <div className="MaintenanceMarkAsCompleteInfo-value">
            {representation.dueInCell.rows.map((config, i) => {
              if (config.visible) {
                return (
                  <div className="MaintenanceMarkAsCompleteInfo-dueIn" key={i}>
                    <span className="MaintenanceMarkAsCompleteInfo-dueInLabel">{config.textDueIn}</span>
                  </div>
                );
              }
            })}
          </div>
        </li>
        <li className="MaintenanceMarkAsCompleteInfo-column MaintenanceMarkAsCompleteInfo-contentCurrentOdometer">
          <p className="MaintenanceMarkAsCompleteInfo-title">Current Odometer</p>
          <p className="MaintenanceMarkAsCompleteInfo-value">
            {Number(info.currentOdometer)?.toLocaleString('en-US', { maximumFractionDigits: 0 })} mi
          </p>
        </li>
        <li className="MaintenanceMarkAsCompleteInfo-column MaintenanceMarkAsCompleteInfo-contentCurrentEngineHours">
          <p className="MaintenanceMarkAsCompleteInfo-title">Current Engine Hours</p>
          <p className="MaintenanceMarkAsCompleteInfo-value">
            {Number(info.currentEngineHours)?.toLocaleString('en-US', { maximumFractionDigits: 1 })} hrs
          </p>
        </li>
      </ul>
    </div>
  );
};

export default MaintenanceMarkAsCompleteInfo;
