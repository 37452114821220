import React, { memo, useCallback, useMemo } from 'react';
import { Observer } from 'mobx-react';

import WayPointIcon from './WayPointIcon';
import MarkerWithInfoWindow from '../../MarkerWithInfoWindow';
import type { Event, Device } from 'models';
import type { timezoneNames } from 'stores';
import type DevicesFilters from 'stores/Map/DevicesFilters';

interface IProps {
  index: number;
  wayPoint: Event;
  timezone: timezoneNames | string;
  locked?: boolean;
  showHistory?: boolean;
  onClick: (index: number) => void;
  onGeozoneClick?: (geozoneId: string) => void;
  unselectClick?: () => void;
  defaultZIndex?: number;
  setStreetViewOpened: () => void;
  onZoomIntoClick: () => void;
  selectedDevice: Device;
  filters: DevicesFilters;
  mapHeading: number;
  isHeading: boolean;
  hiddenTrackPointCard?: boolean;
}

const WayPointMarker: React.FC<IProps> = ({
  onClick,
  onGeozoneClick,
  unselectClick,
  defaultZIndex = 10,
  wayPoint,
  timezone,
  locked,
  showHistory,
  setStreetViewOpened,
  onZoomIntoClick,
  selectedDevice,
  filters,
  index,
  mapHeading,
  isHeading,
  hiddenTrackPointCard,
}) => {
  const defaultState = useMemo(
    () => ({
      iconOptions: {
        anchor: new google.maps.Point(10, 10),
      },
      zIndex: defaultZIndex,
    }),
    [defaultZIndex]
  );

  const hoverState = useMemo(
    () => ({
      iconOptions: {
        anchor: new google.maps.Point(14, 14),
        size: new google.maps.Size(28, 28),
        scaledSize: new google.maps.Size(28, 28),
      },
      zIndex: 12,
    }),
    []
  );

  const selectedState = useMemo(
    () => ({
      iconOptions: {
        anchor: new google.maps.Point(18, 18),
        size: new google.maps.Size(36, 36),
        scaledSize: new google.maps.Size(36, 36),
      },
      zIndex: 11,
    }),
    []
  );

  const icon = useMemo(() => {
    const {
      metadata: {
        attributes: { iconColor, heading, type, arrowColor },
      },
    } = wayPoint;
    return `data:image/svg+xml;charset=utf-8,${escape(
      WayPointIcon({
        statusColor: iconColor,
        heading: wayPoint.heading,
        isHeading: isHeading && heading,
        type,
        mapHeading,
        arrowColor,
      })
    )}`;
  }, [
    wayPoint.metadata.attributes.iconColor,
    wayPoint.metadata.attributes.label,
    wayPoint.heading,
    wayPoint.metadata.attributes.heading,
    wayPoint.metadata.attributes.type,
    wayPoint.metadata.attributes.arrowColor,
    mapHeading,
    isHeading,
  ]);

  const onMouseOver = useCallback(() => {
    wayPoint.toggleHovered(true);
  }, [wayPoint]);

  const onMouseOut = useCallback(() => {
    wayPoint.toggleHovered(false);
  }, [wayPoint]);

  const handleClick = useCallback(() => {
    onClick(index);
  }, [onClick, index]);

  return (
    <Observer
      render={() => {
        const { isSelected, isHovered } = wayPoint;
        const iconOptions = isSelected
          ? selectedState.iconOptions
          : isHovered
          ? hoverState.iconOptions
          : defaultState.iconOptions;
        const zIndex = isSelected ? selectedState.zIndex : isHovered ? hoverState.zIndex : defaultState.zIndex;

        return (
          <MarkerWithInfoWindow
            options={{
              icon: {
                url: icon,
                ...iconOptions,
              },
            }}
            onClick={handleClick}
            onGeozoneClick={onGeozoneClick}
            unselectClick={unselectClick}
            timezone={timezone}
            wayPoint={wayPoint}
            locked={locked}
            showHistory={showHistory}
            setStreetViewOpened={setStreetViewOpened}
            onZoomIntoClick={onZoomIntoClick}
            selectedDevice={selectedDevice}
            filters={filters}
            cursor="pointer"
            zIndex={zIndex}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            hiddenTrackPointCard={hiddenTrackPointCard}
          />
        );
      }}
    />
  );
};

export default memo(WayPointMarker);
