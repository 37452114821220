import React, { FC } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import type MediaTypeToggleFilter from 'models/Dashcam/MediaLibrary/MediaType/MediaTypeToggleFilter';
import Checkbox from 'components/Checkbox';

import './styles.scss';

interface IProps {
  className?: string;
  option: MediaTypeToggleFilter;
}

const MediaLibraryCheckbox: FC<IProps> = ({ className, option }) => {
  const cn = classNames('MediaLibraryCheckbox', className);

  return (
    <div className={cn}>
      <div className="MediaLibraryCheckbox-checkbox">
        <Checkbox
          disabled={!option.count}
          checked={option.isSelected.value}
          label={option.name}
          onChange={option.isSelected.toggle}
          value={option.value}
        />
      </div>
      <div className="MediaLibraryCheckbox-count">{option.count}</div>
    </div>
  );
};

export default observer(MediaLibraryCheckbox);
