import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import SearchableSelect from 'components/Select/SearchableSelect';

import type { PersistenceStore } from 'stores';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';

interface IProps {
  persistenceStore?: PersistenceStore;
  value?: Select.ISelectOption;
  handleChange?: (option: Select.ISelectOption) => void;
  placeholder?: string;
  searchPlaceholder?: string;
  emptySearchResults?: string;
  isSearchable?: boolean;
  withAll?: boolean;
  optionValueKey?: string;
  withOnlyActive?: boolean;
}

@inject(({ persistenceStore }) => ({ persistenceStore }))
@observer
class SearchableServiceTypeSelect extends Component<IProps> {
  static defaultProps = {
    placeholder: 'Select Service Type',
    searchPlaceholder: 'Search Service Type',
    emptySearchResults: 'No Service Type Found',
    optionValueKey: 'id',
  };

  static allOption = { label: 'All Service Types', value: '', priority: -1 };

  repositorySearch: IEntityRepository;

  constructor(props) {
    super(props);
    if (props.withAll && !props.value?.value) {
      this.props.handleChange(SearchableServiceTypeSelect.allOption);
    }

    this.repositorySearch = repositoryService.get('maintenances').entity('service-type');
  }

  getData = (props: { page: number; pageSize: number; filter: string }) => {
    const { withOnlyActive } = this.props;
    const data = {
      page: props.page || 0,
      pageSize: props.pageSize || 50,
      searchValue: props.filter || '',
      order: 'ASC',
      sortBy: 'name',
    };

    return this.repositorySearch.get(data).then((data) => {
      const totalPages = data.totalPages;
      const options = data.items
        .filter((option) => option.status === 'ENABLED' || !withOnlyActive)
        .map((item) => {
          const value = String(item[this.props.optionValueKey]);
          return {
            value,
            label: item.name || value,
          };
        });

      if (!props.filter && this.props.withAll && props.page === 0) {
        options.unshift(SearchableServiceTypeSelect.allOption);
      }

      return {
        totalPages,
        options,
      };
    });
  };

  handleChange = (option: Select.ISelectOption) => {
    this.props.handleChange(option);
  };

  render() {
    return (
      <SearchableSelect
        {...this.props}
        getData={this.getData}
        handleChange={this.handleChange}
        sortOptions
        timeout={300}
      />
    );
  }
}

export default SearchableServiceTypeSelect;
