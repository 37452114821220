import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { toJS } from 'mobx';
import classNames from 'classnames';
import { debounce, isEmpty, isNull } from 'lodash';
import withSizes from 'react-sizes';
import type { ColumnsType, ColumnType } from 'antd/es/table';

import EventsBus from 'services/EventsBus/eventsBus';
import { scrollToSelectedItem } from 'utils';
import type { IEditColumns } from 'models/Tables/IEditColumns';
import type { ISortColumn } from 'models/Tables/ISortColumn';
import type { ITablePagination } from 'models/Tables/ITablePagination';
import type { ITableSearchField } from 'models/Tables/ITableSearchField';
import TableColumnHeader from '../ColumnHeader';
import Checkbox from 'components/Checkbox';
import Table from 'components/Table';
import TableHeader from '../Header';
import TableSummary, { TS } from 'components/Table/Summary';
import EditColumnsModal from 'components/Admin/EditColumnsModal';
import { InputNumber as AntInputNumber, Select } from 'antd';
import type TableToggleFilter from 'models/Tables/TableToggleFilter';
import { LeftArrowIcon, RightArrowIcon } from 'assets';

import { PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons';
import './styles.scss';

interface IProps<T, U> {
  actionsList?: Array<{ text: string; onClick?(): void; link?: string }>;
  className?: string;
  columns: ColumnsType<any>;
  dataSource: Array<U | any>;
  editColumns?: boolean;
  editColumnsMap?: { [key: string]: { text: string; isDisabled: boolean } };
  emptyText?: React.ReactNode;
  expanded?: boolean;
  expandedRowClassName?: string;
  expandedHeaderClassName?: string;
  expandedRowRender?: (record: any, index: any, indent: any, expanded: any) => JSX.Element;
  expandIcon?: React.ReactNode;
  getData: (options: { pageSize?: number; page?: number; search?: { [key: string]: string } }) => void;
  hasRowExpand?: boolean;
  isFilterActive?: boolean;
  loading?: boolean;
  onColumnsChange?: (columns: Array<IEditColumns<T>>) => void;
  onColumnSort: (column: ISortColumn) => void;
  onColumnsSearch?: (column: ITableSearchField[]) => void;
  triggerUpdate?: boolean;
  onFilterOpen?: (state: boolean) => void;
  onPaginationChange?: (options: ITablePagination) => void;
  onRowClick?: (record: U, index?: number) => void;
  onRowMouseEnter?: (record: U, event: React.MouseEvent<any, MouseEvent>) => void;
  onRowMouseLeave?: (record: U, event: React.MouseEvent<any, MouseEvent>) => void;
  onRowExpand?(isExpanded: boolean, key: any): void;
  onTableModeChange?(mode: UI.panelMode): void;
  openDownloadModal?: () => void;
  openEmailModal?: () => void;
  pagination?: ITablePagination;
  rowClassName?: (record: U, index: number) => string;
  searchColumns?: ITableSearchField[];
  searchParams?: { [key: string]: string };
  selectedColumns?: Array<IEditColumns<T>>;
  showHeader?: boolean;
  sortedColumn?: { sortBy: string; order: string };
  summary?: Array<{ [key: string]: string }>;
  summaryRowProps?: { rowToMergeIndex: number; columnsNumber: number };
  summaryFixed?: boolean | 'bottom' | 'top';
  tableMode?: UI.panelMode;
  tableHeight?: string;
  total?: number;
  totalTitle?: React.ReactNode | string;
  withActions?: boolean;
  withFilter?: boolean;
  withDownload?: boolean;
  withEmail?: boolean;
  withPagination?: boolean;
  withSearch?: boolean;
  withTitle?: boolean;
  sticky?: boolean;
  columnActiveStatus?: string;
  columnGroup?: string;
  columnCoordinates?: string;
  selectedId?: string;
  isMinHeight?: boolean;
  isLoading?: boolean;
  headerLeftContent?: React.ReactNode;
  filters: { [key: string]: TableToggleFilter[] };
  onPrint?: () => void;
  withPrint?: boolean;
  fetchEventListenerName?: string;
}

function CustomTable<T, U>({
  actionsList = [],
  isMinHeight,
  className,
  columns,
  dataSource,
  editColumns = false,
  editColumnsMap,
  emptyText,
  expanded = true,
  expandedRowClassName,
  expandedHeaderClassName,
  expandedRowRender,
  getData,
  hasRowExpand = false,
  isFilterActive,
  loading,
  onColumnsChange,
  onColumnSort,
  onColumnsSearch = () => void 0,
  triggerUpdate,
  onFilterOpen = () => void 0,
  onPaginationChange = () => void 0,
  onRowClick = () => void 0,
  onRowMouseEnter = () => void 0,
  onRowMouseLeave = () => void 0,
  onRowExpand = () => void 0,
  onTableModeChange = () => void 0,
  openDownloadModal = () => void 0,
  openEmailModal = () => void 0,
  pagination = { pageSize: 10, page: 1, showAll: false },
  rowClassName = () => '',
  searchColumns = [],
  searchParams,
  selectedColumns = [],
  showHeader = true,
  sortedColumn = { sortBy: 'name', order: 'ASC' },
  summary,
  summaryRowProps,
  summaryFixed,
  tableMode,
  tableHeight = 'calc(100vh - 350px)',
  total,
  totalTitle,
  withActions = false,
  withFilter = true,
  withDownload = false,
  withEmail = false,
  withPagination = true,
  withSearch = true,
  withTitle = true,
  sticky = false,
  columnActiveStatus = null,
  columnGroup = null,
  columnCoordinates = null,
  selectedId,
  headerLeftContent,
  isLoading = false,
  filters = {},
  onPrint,
  withPrint,
  fetchEventListenerName,
}: IProps<T, U>): React.ReactElement {
  const [isEditColumns, setEditColumns] = useState(editColumns);
  const [isFilterOpen, setFilterOpen] = useState(
    Boolean(isFilterActive || (searchColumns && searchColumns.some(({ value }) => value)))
  );
  const [searchData, setSearchData] = useState<ITableSearchField[]>(searchColumns);
  const [expandedRows, setExpandedRows] = useState<number[]>(null);

  const cn = classNames('CustomTable', className, {
    'CustomTable--withOpenFilter': isFilterOpen,
    'CustomTable--compact': tableMode === 'compact',
  });
  const openTableEditModal = () => setEditColumns(true);
  const closeTableEditModal = () => setEditColumns(false);
  const handleShowAllChange = useCallback(
    () => onPaginationChange({ ...pagination, showAll: !pagination.showAll }),
    [pagination, onPaginationChange]
  );
  const handlePaginationChange = useCallback(
    (page, pageSize) => onPaginationChange && onPaginationChange({ ...pagination, page, pageSize }),
    [pagination, onPaginationChange]
  );
  const handleTableChange = (_pagination, _filters, sorter) => {
    if (isEmpty(sorter)) {
      return;
    }
    const { field, order } = sorter;
    onColumnSort({ field, order });
  };
  const resetSearchOnClose = useCallback(() => {
    setSearchData([]);
    onColumnsSearch([]);
    const toggleFilters = Object.keys(filters);
    if (toggleFilters.length) {
      toggleFilters.forEach((key) => filters[key].forEach((filter) => filter.isActive.toggle(false)));
    }
  }, [onColumnsSearch]);
  const handleOpenFilter = useCallback(() => {
    const filterState = !isFilterOpen;

    onFilterOpen(filterState);
    setFilterOpen(filterState);
    if (!filterState) {
      resetSearchOnClose();
    }
  }, [isFilterOpen, onFilterOpen, resetSearchOnClose]);
  const handleColumnSearch = useCallback(
    (searchValues, filter) => {
      const searchedColumn = searchData.find((data) => data.column === searchValues.column);
      const searchedColumnIndex = searchData.indexOf(searchedColumn);
      const arr = searchData.slice();

      if (searchedColumnIndex !== -1) {
        if (filter) {
          const value = !filter.isActive.value
            ? searchedColumn.value
                .split(',')
                .filter((value) => value !== searchValues.value)
                .join(',')
            : `${searchedColumn.value ? `${searchedColumn.value},` : ''}${searchValues.value}`;
          searchValues = { ...searchValues, value };
        }

        arr.splice(searchedColumnIndex, 1);
      }

      arr.push({ ...searchValues, isActive: true });

      onColumnsSearch(arr);
      setSearchData(arr);
      handlePaginationChange(1, pagination.pageSize);
    },
    [onColumnsSearch, searchData, handlePaginationChange, pagination, filters]
  );
  const dataOptions = useMemo(() => {
    const { showAll, pageSize, page } = pagination;

    return showAll
      ? { search: searchParams, ...sortedColumn }
      : { pageSize, page: page - 1, search: searchParams, ...sortedColumn };
  }, [pagination, searchParams, sortedColumn]);

  const fireGetData = useCallback(debounce(getData, 500), []);
  const callGetDataWithOptions = () => fireGetData(dataOptions);

  useCallback(() => debounce(handlePaginationChange, 0), []);

  useEffect(() => {
    if (fetchEventListenerName) {
      EventsBus.get().on(fetchEventListenerName, callGetDataWithOptions);

      return () => {
        EventsBus.get().off(fetchEventListenerName, callGetDataWithOptions);
      };
    }
  });

  useEffect(() => {
    fireGetData(dataOptions);
  }, [fireGetData, dataOptions]);

  useEffect(() => {
    if (triggerUpdate) {
      fireGetData(dataOptions);
    }
  }, [fireGetData, dataOptions, triggerUpdate]);

  useEffect(() => {
    const tbody = document.querySelector('.ant-table-body');
    const selectedRow = tbody?.querySelector('.ant-table-row--selected');

    scrollToSelectedItem(tbody, selectedRow, true);
  }, [selectedId]);

  const columnsWithSearch = useMemo(() => {
    return columns.map((column: ColumnType<U> | any) => {
      const columnName = column.dataIndex;
      const searchedColumn = searchData.find((data) => data.column === columnName);
      const showInput =
        isFilterOpen && columnName !== 'actions' && columnName !== columnActiveStatus && !column.hiddenSearch;

      return {
        ...column,
        title: (
          <TableColumnHeader
            showInput={showInput}
            filters={filters[column.dataIndex] || []}
            title={column.title}
            onChange={(value, filter) => handleColumnSearch({ value, column: columnName }, filter)}
            value={(searchedColumn && searchedColumn.value) || ''}
            open={isFilterOpen}
          />
        ),
      };
    });
  }, [columns, isFilterOpen, searchData, handleColumnSearch]);

  const filteredDataSource = useMemo(() => {
    if (withPagination) {
      return dataSource;
    }

    return dataSource.filter((dataRow) => {
      return searchData.every(({ column, value, isActive }) => {
        if (!isActive) {
          return true;
        }

        const columnData = dataRow[column]?.value || dataRow[column];

        if (column === columnGroup) {
          return columnData.some((group) =>
            String(group.displayName || group.groupId)
              .toLowerCase()
              .includes(value.toLowerCase())
          );
        }

        if (column === columnCoordinates) {
          return Object.keys(columnData).some((coordinate) => {
            return columnData[coordinate] && String(columnData[coordinate]).toLowerCase().includes(value.toLowerCase());
          });
        }

        return String(columnData).toLowerCase().includes(value.toLowerCase());
      });
    });
  }, [dataSource, searchData]);

  const handleColumnsChange = useCallback(
    (columns) => {
      onColumnsChange(columns);

      if (!withPagination) {
        const searchArr = [...searchData];

        searchArr.forEach((search) => {
          columns.forEach((column) => {
            if (column.value.toLowerCase() === search.column.toLocaleLowerCase()) {
              search.isActive = column.isSelected;
            }
          });
        });

        setSearchData(searchArr);
      }
    },
    [onColumnsChange, searchData]
  );

  const PaginationComponent = useMemo(() => {
    const { showAll, page, pageSize } = pagination;
    const totalNum = isNull(total) ? 0 : total;
    const numberOfLastPage = Math.ceil(totalNum / pageSize);
    const pageSizes = [
      { label: '25 / page', value: '25' },
      { label: '50 / page', value: '50' },
      { label: '100 / page', value: '100' },
    ];

    if (showAll) {
      pageSizes.push({ label: `${totalNum} / page`, value: totalNum.toString() });
    }

    return withPagination && total
      ? () => (
          <div className="CustomTable-pagination">
            <div className="CustomTable-paginationContent">
              <div className="CustomTable-paginationContentShowAll">
                <Checkbox label="Show all" onChange={handleShowAllChange} checked={showAll} />
              </div>
              <div className="CustomTable-paginationContentJumper">
                <button
                  disabled={page === 1 || showAll}
                  onClick={() => handlePaginationChange(page - 1, pageSize)}
                  className={classNames('CustomTable-paginationContentJumperPrev', {
                    'CustomTable-paginationContentJumperPrev--disabled': page === 1 || showAll,
                  })}
                >
                  <LeftArrowIcon height={10} width={10} fill="#6B7A99" />
                </button>
                <div className="CustomTable-paginationContentJumperInput">
                  <AntInputNumber<number>
                    value={showAll ? 1 : page}
                    onBlur={(e) => {
                      let page = Number(e.currentTarget.value);
                      if (!page) {
                        return;
                      }

                      if (page < 1) {
                        page = 1;
                      }

                      if (page > numberOfLastPage) {
                        page = numberOfLastPage;
                      }

                      handlePaginationChange(page, pageSize);
                    }}
                    step={1}
                    min={1}
                    max={numberOfLastPage}
                    controls={false}
                    disabled={showAll}
                  />
                </div>
                <div className="CustomTable-paginationContentJumperSeparator">/</div>
                <div className="CustomTable-paginationContentJumperTotal">{showAll ? 1 : numberOfLastPage}</div>
                <button
                  disabled={page === numberOfLastPage || showAll}
                  onClick={() => handlePaginationChange(page + 1, pageSize)}
                  className={classNames('CustomTable-paginationContentJumperNext', {
                    'CustomTable-paginationContentJumperNext--disabled': page === numberOfLastPage || showAll,
                  })}
                >
                  <RightArrowIcon height={10} width={10} fill="#6B7A99" />
                </button>
              </div>
              <div className="CustomTable-paginationContentPageSizes">
                <Select
                  value={showAll ? totalNum.toString() : pageSize.toString()}
                  options={pageSizes}
                  onChange={(value) => {
                    let activePage = page;
                    const activePageSize = Number(value);
                    const activeNumberOfLastPage = Math.ceil(totalNum / activePageSize);

                    if (activePage > activeNumberOfLastPage) {
                      activePage = activeNumberOfLastPage;
                    }

                    handlePaginationChange(activePage, activePageSize);
                  }}
                  disabled={showAll}
                />
              </div>
            </div>
            {loading && <div className="CustomTable-paginationLoading" />}
          </div>
        )
      : null;
  }, [pagination, withPagination, total, loading, handleShowAllChange, handlePaginationChange]);

  const rowKeys = useMemo(() => dataSource.map(({ key }) => key), [dataSource]);

  const handleCollapseRow = (key) => {
    const newArr = [...expandedRows];
    const index = newArr.indexOf(key);
    newArr.splice(index, 1);
    setExpandedRows(newArr);
  };

  const handleExpandRow = (key) => {
    setExpandedRows([...expandedRows, key]);
  };

  const toggleRow = (key) => {
    const isExpanded = expandedRows.includes(key);
    isExpanded ? handleCollapseRow(key) : handleExpandRow(key);
    onRowExpand(Boolean(!isExpanded), key);
  };

  useEffect(() => {
    const expandedRowKeys = expanded ? rowKeys : [];
    setExpandedRows(expandedRowKeys);
  }, [expanded, rowKeys]);

  const expandableConfig = useMemo(() => {
    return expandedRowRender
      ? {
          expandedRowClassName: () => expandedRowClassName,
          expandedRowKeys: expandedRows,
          expandedRowRender,
          expandIcon: ({ expanded, record }) =>
            expanded ? (
              <MinusSquareOutlined
                className="CustomTable-rowExpandIcon CustomTable-rowExpandIcon--minus"
                onClick={() => {
                  handleCollapseRow(record.key);
                }}
              />
            ) : (
              <PlusSquareOutlined
                className="CustomTable-rowExpandIcon CustomTable-rowExpandIcon--plus"
                onClick={() => {
                  handleExpandRow(record.key);
                }}
              />
            ),
        }
      : undefined;
  }, [expandedRowRender, expandedRows]);

  const hasSearch = useMemo(() => searchData.some((searchColumn) => searchColumn.value), [searchData]);

  const title = useMemo(() => {
    return withTitle
      ? () => (
          <TableHeader
            actionsList={actionsList}
            isFilterActive={isFilterOpen || hasSearch}
            leftContent={headerLeftContent}
            onEditTable={openTableEditModal}
            onOpenDownload={openDownloadModal}
            onOpenEmail={openEmailModal}
            onOpenFilter={handleOpenFilter}
            onTableModeChange={onTableModeChange}
            tableMode={tableMode}
            totalTitle={loading ? '' : totalTitle}
            withActions={withActions}
            withDownload={withDownload}
            withEmail={withEmail}
            withFilter={withFilter}
            isLoading={isLoading}
            withPrint={withPrint}
            onPrint={onPrint}
          />
        )
      : undefined;
  }, [withTitle, isFilterOpen, loading, totalTitle, withActions, hasSearch, tableMode, onTableModeChange, isLoading]);

  const rowClassNameValue = useMemo(() => {
    return (record, key) =>
      expandedHeaderClassName
        ? `${rowClassName(record, key)} ${expandedHeaderClassName}--${
            (expandedRows || []).includes(key) ? 'expanded' : 'collapsed'
          }`
        : rowClassName(record, key);
  }, [rowClassName, expandedRows, expandedHeaderClassName]);

  return (
    <div className={cn}>
      <div className="CustomTable-table">
        <Table
          columns={withSearch ? columnsWithSearch : columns}
          dataSource={withPagination ? dataSource : filteredDataSource}
          expandable={expandableConfig}
          loading={loading}
          onChange={handleTableChange}
          pagination={false}
          scroll={{
            y: isMinHeight ? 600 - 310 : tableHeight,
          }}
          showHeader={showHeader}
          showSorterTooltip={false}
          title={title}
          rowClassName={rowClassNameValue}
          onRow={
            hasRowExpand
              ? (record) => ({
                  onClick: (_) => toggleRow(record.key),
                })
              : (record) => ({
                  onClick: () => onRowClick(record),
                  onMouseEnter: (event) => onRowMouseEnter(record, event),
                  onMouseLeave: (event) => onRowMouseLeave(record, event),
                })
          }
          locale={{
            emptyText: loading ? (
              <></>
            ) : emptyText ? (
              emptyText
            ) : (
              (withPagination ? dataSource : filteredDataSource).length === 0 && 'No Results Found'
            ),
          }}
          summary={() => (
            <TS fixed={summaryFixed}>
              <TableSummary rows={summary} rowProps={summaryRowProps} />
            </TS>
          )}
          sticky={sticky}
        />
        {PaginationComponent && <PaginationComponent />}
      </div>
      {withActions && (
        <div className="CustomTable-editColumns">
          <EditColumnsModal
            isOpen={isEditColumns}
            onCancel={closeTableEditModal}
            onSubmit={handleColumnsChange}
            columns={toJS(selectedColumns)}
            columnsMap={editColumnsMap}
          />
        </div>
      )}
    </div>
  );
}

const mapSizesToProps = ({ height }) => ({
  isMinHeight: height < 600,
});

export default withSizes(mapSizesToProps)(CustomTable);
