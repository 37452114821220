import { SimpleField } from 'models/Fields';

class MediaClipsInteraction {
  status: SimpleField<string>;

  constructor(value: string) {
    this.status = new SimpleField<string>(value);
  }

  get value() {
    return this.status.value;
  }

  set = (value: string) => {
    if (value === 'hover' && this.status.value === 'default') {
      this.status.set('hover');
    } else if (value === 'default' && this.status.value === 'hover') {
      this.reset();
    } else if (value === 'active' && this.status.value !== 'active') {
      this.status.set('active');
    }
  };

  reset = () => {
    this.status.set('default');
  };
}

export default MediaClipsInteraction;
