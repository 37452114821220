import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { List } from 'antd';

import { REPORTS_BETA_ITEMS } from 'config';

import ReportListItemPin from '../ReportListItemPin';
import InfoTooltip from 'components/InfoTooltip';

import { CautionIcon } from 'assets';

import './styles.scss';

interface IReportListItem {
  id: string;
  title: string;
  description: string;
  pinned: boolean;
  virtual?: boolean;
  deprecated?: string;

  onClick(id: string): void;
  onPinClick(id: string): void;
}

@observer
class ReportListItem extends React.Component<IReportListItem, null> {
  handlePinClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onPinClick(this.props.id);
  };

  handleClick = () => {
    this.props.onClick(this.props.id);
  };

  render() {
    const { pinned, title, description, virtual, id, deprecated } = this.props;

    const cn = classNames('ReportsListItem-meta', {
      'ReportsListItem-meta-pinned': Boolean(pinned),
    });

    return (
      <List.Item className="ReportsListItem" onClick={this.handleClick}>
        <List.Item.Meta
          title={title}
          description={description}
          className={cn}
          avatar={
            <ReportListItemPin
              pinned={pinned}
              className="ReportsListItem-meta-pinned-icon"
              virtual={virtual}
              onClick={this.handlePinClick}
            />
          }
        />
        {deprecated && (
          <InfoTooltip
            className="ReportsListItem-meta-deprecated"
            icon={<CautionIcon className="ReportsListItem-meta-deprecated-icon" />}
          >
            {deprecated}
          </InfoTooltip>
        )}
        {REPORTS_BETA_ITEMS.includes(id) && <span className="ReportsListItem-beta">Beta</span>}
      </List.Item>
    );
  }
}

export default ReportListItem;
