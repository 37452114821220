import React, { FC, Fragment } from 'react';
import classNames from 'classnames';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';

import { Group } from 'models';
import { PATHS } from 'config';

interface IMatchParams {
  groupId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  list: Group[];
  onChange: (value: Group) => void;
  selectedItemId: string;
  withAll?: boolean;
}

const SearchGroupsList: FC<IProps> = ({ list, onChange, selectedItemId, withAll }) => {
  const allVehiclesCn = classNames('SearchList-item SearchList-item--all', {
    'SearchList-item--active': Boolean(selectedItemId === 'all'),
  });

  return (
    <Fragment>
      {withAll && (
        <li className={allVehiclesCn}>
          <Link
            to={PATHS.MAP.VEHICLES}
            onClick={() =>
              onChange(
                new Group({
                  assetCount: null,
                  description: 'All Vehicles',
                  displayName: 'All Vehicles',
                  groupId: 'all',
                })
              )
            }
          >
            <span className="SearchList-itemText">All Vehicles</span>
          </Link>
        </li>
      )}
      {list.map((item) => {
        const { groupId, displayName } = item;
        const itemCn = classNames('SearchList-item', {
          'SearchList-item--active': groupId === selectedItemId,
        });

        return (
          <li key={groupId} className={itemCn}>
            <Link to={PATHS.MAP.GROUP.replace(':groupId', groupId)} onClick={() => onChange(item)}>
              <span className="SearchList-itemText">{displayName}</span>
            </Link>
          </li>
        );
      })}
    </Fragment>
  );
};

export default withRouter(SearchGroupsList);
