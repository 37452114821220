import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import type { RouteComponentProps } from 'react-router-dom';

import { INTEGRATIONS_STORE, QB_INTEGRATION_SUCCESS_CONNECT_EVENT, WEB_MAP_FINANCE_BASIC_DELETE } from 'config';

import { UserAccessLevelComponent } from 'stores';

import type { IIntegrationsStore } from 'interfaces/stores/Integrations/IIntegrationsStore';

import IntegrationFabric from 'components/Admin/Integrations/IntegrationFabric';
import FinanceRequest from './FinanceRequest';
import Notification from 'components/Notification';

import './styles.scss';

interface IProps extends RouteComponentProps {
  integrationsStore?: IIntegrationsStore;
}

@inject(INTEGRATIONS_STORE)
@observer
class Integrations extends Component<IProps, null> {
  async componentDidMount() {
    window.addEventListener('storage', this.listenToIntegrations, false);
    await this.props.integrationsStore?.loadAllIntegrations?.();
  }

  listenToIntegrations = async (event) => {
    if (event.key === QB_INTEGRATION_SUCCESS_CONNECT_EVENT) {
      await this.props.integrationsStore?.loadAllIntegrations?.();
    }
  };

  handleCloseNotification = () => {
    this.props.integrationsStore.setHideExpiredNotification(true);
  };

  componentWillUnmount() {
    window.removeEventListener('storage', this.listenToIntegrations, false);
  }

  render() {
    const {
      integrationsStore: {
        activeIntegrations,
        availableIntegrations,
        hasExpiredIntegrations,
        hideExpiredNotification,
        expiredIntegrationsTitle,
      },
    } = this.props;

    return (
      <div>
        <div className="Integration-Header">
          <div className="Integration-Title">
            <h3>Integrations</h3>
            <div className="Integration-Description">Manage your 3rd party integrations and their data.</div>
          </div>
          <div className="Integration-HeaderAction">
            <UserAccessLevelComponent requiredAccessLevel={[WEB_MAP_FINANCE_BASIC_DELETE]}>
              <FinanceRequest />
            </UserAccessLevelComponent>
          </div>
        </div>

        <div className="Integration-Items">
          <h4>Active Integrations</h4>
          <div className="Integration-List">
            {!activeIntegrations.length && (
              <div className="Integration-ItemList">
                <div className="Integration-Item Integration-ItemEmpty">No Active Integrations</div>
              </div>
            )}

            {activeIntegrations.map((integration, i) => {
              return <IntegrationFabric key={`${integration.name}_${i}`} integration={integration} />;
            })}
          </div>
        </div>

        <div className="Integration-Items">
          <div className="Integration-ItemsHeader Integration-ItemsHeader--sideMenu">
            <div className="Integration-ItemsTitle">
              <h4>Available Integrations</h4>
            </div>
            <div className="Integration-ItemsSideMenu">
              <div className="Integration-MissedIntegration">
                <span>Don't see the integration you're looking for?</span>
                <a
                  href="https://support.clearpathgps.com/hc/en-us/requests/new?ticket_form_id=360000572811"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Let us know
                </a>
              </div>
            </div>
          </div>
          <div className="Integration-List">
            {!availableIntegrations.length && (
              <div className="Integration-ItemList">
                <div className="Integration-Item Integration-ItemEmpty">More Integrations Coming Soon</div>
              </div>
            )}

            {availableIntegrations.map((integration) => {
              return <IntegrationFabric key={integration.name} integration={integration} />;
            })}

            {hasExpiredIntegrations && !hideExpiredNotification && (
              <Notification
                autoClose={Boolean(false)}
                type="warning"
                title={`${expiredIntegrationsTitle} Integration Expired`}
                text="Reconnect Integration to get updated data."
                onClose={this.handleCloseNotification}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Integrations;
