import React, { FC } from 'react';
import classNames from 'classnames';

import { InfoTransparentIcon, CloseIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  name: string;
  onClose?: () => void;
}

const ErrorNotification: FC<IProps> = ({ className, name, onClose }) => {
  const cn = classNames('ErrorNotification', className);

  return (
    <div className={cn}>
      <div className="ErrorNotification-icon">
        <InfoTransparentIcon className="ErrorNotification-iconImage ErrorNotification-iconImage--info" />
      </div>
      <div className="ErrorNotification-message">
        <p className="ErrorNotification-messageTitle">Save Failed</p>
        <p className="ErrorNotification-messageText">Changes made to {name} did not save.</p>
      </div>
      <div className="ErrorNotification-icon">
        <CloseIcon onClick={onClose} className="ErrorNotification-iconImage ErrorNotification-iconImage--close" />
      </div>
    </div>
  );
};

export default ErrorNotification;
