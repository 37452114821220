import { observable } from 'mobx';
import CameraModel from './CameraModel';

class VehicleModel {
  @observable displayName: string;
  deviceId: string;
  assetId: string;
  camera: CameraModel;

  constructor() {
    this.camera = new CameraModel();
  }

  set model(vehicle) {
    this.displayName = vehicle.displayName;
    this.deviceId = vehicle.deviceId;
    this.assetId = vehicle.assetId.toString();
    this.camera.model = vehicle.camera || {};
  }
}

export default VehicleModel;
