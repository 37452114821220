import React from 'react';
import classNames from 'classnames';

import AlertTile from '../../AlertTile';

import { PowerDisconnectDashCamIcon } from 'assets';

import './styles.scss';

interface IProps {
  isActive?: boolean;
  requiredPro?: boolean;
  onClick?: () => void;
  isProAcc?: boolean;
}

const AlertTilePowerDisconnectDashCam: React.FC<IProps> = ({ isActive, onClick, requiredPro, isProAcc }) => {
  const cn = classNames({ 'AlertTilePowerDisconnectDashCam--active': isActive && !requiredPro });

  const title = 'Power Disconnect (Dashcam)';
  const description = 'Alerts when any vehicle’s dashcam has lost power';

  return (
    <AlertTile
      icon={<PowerDisconnectDashCamIcon className={cn} />}
      title={title}
      description={description}
      isActive={isActive}
      onClick={onClick}
      requiredPro={requiredPro}
      className={{
        title: 'AlertTilePowerDisconnectDashCam-title',
      }}
      isProAcc={isProAcc}
    />
  );
};

export default AlertTilePowerDisconnectDashCam;
