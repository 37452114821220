import React from 'react';

import './styles.scss';

interface IProps {
  name: string;
  description: string;
}

const ReportTableScheduledInterval: React.FC<IProps> = ({ name, description }) => {
  return (
    <div className="ReportTableScheduledInterval">
      <p>{name}</p>
      <span>({description})</span>
    </div>
  );
};

export default ReportTableScheduledInterval;
