import React, { FC, useMemo } from 'react';
import type { locationType } from 'stores';

import './styles.scss';

interface IProps {
  latitude: number;
  longitude: number;
  subTitle?: string;
  title?: string;
  type: locationType;
}

const LocationPanelHeader: FC<IProps> = ({ latitude, longitude, subTitle, title, type }) => {
  const isAddressOrPoi = useMemo(() => type === 'address' || type === 'poi', [type]);
  const coordinates = useMemo(() => `${latitude?.toFixed(5)}, ${longitude?.toFixed(5)}`, [latitude, longitude]);
  const panelTitle = useMemo(() => (isAddressOrPoi ? title : coordinates), [isAddressOrPoi, title, coordinates]);

  return (
    <div className="LocationPanelHeader">
      <p className="LocationPanelHeader-title">{panelTitle}</p>
      {isAddressOrPoi && (
        <>
          <p className="LocationPanelHeader-subTitle">{subTitle}</p>
          <p className="LocationPanelHeader-coordinates">{coordinates}</p>
        </>
      )}
    </div>
  );
};

export default LocationPanelHeader;
