import AttributesRegistry from './AttributesRegistry';

class ItemRegistry {
  accountId: number;
  assetId: number;
  code: number;
  id: number;
  text: string;
  attributes: AttributesRegistry;

  constructor(item) {
    this.accountId = item.accountId;
    this.assetId = item.assetId;
    this.code = item.code;
    this.id = item.id;
    this.text = item.text;
    this.attributes = new AttributesRegistry(item.attributes);
  }
}

export default ItemRegistry;
