import React from 'react';
import { Observer } from 'mobx-react';

import type { SimpleField, ToggleField } from 'models/Fields';
import Note from 'components/Note';

import './styles.scss';

interface IProps {
  notes: SimpleField<string>;
  isEdit: ToggleField;
  disabled?: boolean;
}

const MaintenanceCompletedTaskPanelNote: React.FC<IProps> = ({ notes, isEdit, disabled }) => {
  return (
    <Observer
      render={() => (
        <div className="MaintenanceCompletedTaskPanelNote">
          {isEdit.value ? (
            <Note
              className="MaintenanceCompletedTaskPanelNote-Edit"
              isEditing
              note={notes.value}
              onChange={notes.set}
              hideControls
              countCharactersEntered
              placeholder="Note"
              disabled={disabled}
            />
          ) : (
            <div className="MaintenanceCompletedTaskPanelNote-Value">{notes.value}</div>
          )}
        </div>
      )}
    />
  );
};

export default MaintenanceCompletedTaskPanelNote;
