import { SimpleField } from 'models/Fields';
import MaintenanceAssetDetails from './Assets/MaintenanceAssetDetails';

class MaintenanceAsset {
  name: SimpleField<string>;
  id: SimpleField<string>;
  cpNumber: SimpleField<string>;
  details: MaintenanceAssetDetails;

  constructor({
    name = '',
    id = null,
    odometer = 0,
    engHours = 0,
    avgEngineHoursPerDay = 0,
    avgKmPerDay = 0,
    gtsId = '',
  }) {
    this.name = new SimpleField(name);
    this.id = new SimpleField(id ? id.toString() : null);
    this.cpNumber = new SimpleField(gtsId);
    this.details = new MaintenanceAssetDetails({ odometer, engHours, avgEngineHoursPerDay, avgKmPerDay });
  }

  set = (id, name) => {
    this.id.set(id);
    this.name.set(name);
    this.details.fetch(id);
  };

  get serverModel() {
    return {
      ...this.details.serverModel,
      name: this.name.value,
      id: Number(this.id.value),
    };
  }
}

export default MaintenanceAsset;
