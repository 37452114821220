import React from 'react';

import './styles.scss';

interface IProps {
  label?: string;
  value?: string | React.ReactNode;
}

const AlertPreferenceFinalizeSimpleLine: React.FC<IProps> = ({ label, value }) => {
  return (
    <div className="AlertPreferenceFinalizeLine">
      <p className="AlertPreferenceFinalizeLine-label">{label}</p>
      <p className="AlertPreferenceFinalizeLine-value">{value}</p>
    </div>
  );
};

export default AlertPreferenceFinalizeSimpleLine;
