import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import type { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import { DISPATCH_STORE } from 'config';
import type { DispatchStore } from 'stores/Dispatch';
import Modal from 'components/Modal';
import SendDispatchForm from 'components/Dispatch/SendForm';

import './styles.scss';

interface IProps extends RouteComponentProps {
  className?: string;
  isOpen: boolean;
  [DISPATCH_STORE]?: DispatchStore;
}

@inject(DISPATCH_STORE)
@observer
class SendDispatch extends Component<IProps> {
  handleBack = () => {
    const {
      history: { goBack },
    } = this.props;

    goBack();
  };

  handleSubmit = async (values: Dispatch.ISendDispatchData) => {
    const {
      dispatchStore: { sendDispatch, sendDispatchRequestStatus },
    } = this.props;

    await sendDispatch('POST', values);

    if (sendDispatchRequestStatus.success) {
      this.handleBack();
    }
  };

  render() {
    const {
      className,
      isOpen,
      dispatchStore: { filteredDriversList },
    } = this.props;
    const cn = classNames('SendDispatch', className);

    return (
      <div className={cn}>
        <Modal
          className="Modal--sendDispatch Modal--withoutPadding"
          isModalOpen={isOpen}
          onClose={this.handleBack}
          bgColor="white"
        >
          <p className="SendDispatch-title">New Dispatch</p>
          <div className="SendDispatch-form">
            <SendDispatchForm
              driversList={filteredDriversList}
              onSubmit={this.handleSubmit}
              onCancel={this.handleBack}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default SendDispatch;
