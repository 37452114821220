import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Collapse } from 'antd';

import type { DevicesStore, MapStore, RouterStore, Inspections as InspectionsStore } from 'stores';
import { IntervalEvents } from 'services';
import { KeyboardShortcutsManager } from 'tools';
import { getDiffInDays, getFormattedTime, getStorageItem, setStringifiedStorageItem } from 'utils';
import {
  ACL,
  ADMIN_VEHICLE_NOTES_READ,
  ADMIN_VEHICLE_NOTES_UPDATE,
  DATE_TIME_FORMATS,
  EVENT_STATUS_TYPE,
  HISTORY_MODE,
  INSPECTIONS_STORE,
  // INSPECTION_RESULTS,
  KEY_TYPE,
  LINKS,
  OLD_EVENTS_SHOW_ERROR,
  PATHS,
  WEB_DASHCAM_BASIC_READ,
  WEB_MAP_CLEARSHARE_BASIC_READ,
  WEB_MAP_CLOSEST_VEHICLE_READ,
} from 'config';
import validateAccessLevel from 'stores/acl/validator';

import {
  MapVehiclePanelEventDirection,
  MapVehiclePanelEventLocation,
  MapVehiclePanelEventOutOfRange,
  MapVehiclePanelEventStatus,
  MapVehiclePanelEventTime,
} from 'components/MapVehiclePanel/MapVehiclePanelEvent';
import ClearshareLinksList from '../SelectedVehicle/ClearshareLinksList';
import ClearShareCreate from '../SelectedVehicle/ClearShareCreate';
import MapVehiclePanelActions from 'components/MapVehiclePanel/MapVehiclePanelActions';
import MapVehiclePanelEventStatusTotalStop from 'components/MapVehiclePanel/MapVehiclePanelEvent/MapVehiclePanelEventStatusTotalStop';
import MapVehiclePanelVehicleAttributes from 'components/MapVehiclePanel/MapVehiclePanelVehicleAttributes';
import MapVehicleSelector from '../MapVehicleSelector';
import Notification from 'components/Notification';
import PanelNote from 'components/SubNav/PanelNote';
import StreetView from 'components/Map/StreetView';
import TextLocationModal from 'components/Map/TextLocationModal';
import VehicleItemIcons from 'components/SubNav/VehicleItemIcons';
import withCollapseStorage from 'hocs/withCollapseStorage';
import ClosestVehicles from '../ClosestVehicles';
import CloseButton from 'components/Button/CloseButton';
import MapVehiclePanelLastInspection from 'components/MapVehiclePanel/MapVehiclePanelLastInspection';
import DraggableCard from 'components/DragableCard';

import { ChevronIcon, NoMediaIcon, PowerOffIcon } from 'assets';

import './styles.scss';

enum MapVehiclePanelType {
  Events = 'map-vehicle-events',
  Actions = 'map-vehicle-actions',
  Note = 'map-vehicle-note',
  StreetView = 'map-vehicle-street-view',
  Clearshare = 'map-vehicle-clearshare',
  Attributes = 'map-vehicle-attributes',
  Closest = 'map-vehicle-closest',
  LastInspection = 'map-vehicle-last-inspection',
}

const MapVehiclePanels = {
  [MapVehiclePanelType.Events]: {
    id: MapVehiclePanelType.Events,
    name: 'MOST RECENT EVENT',
  },
  [MapVehiclePanelType.Actions]: {
    id: MapVehiclePanelType.Actions,
    name: 'ACTIONS',
  },
  [MapVehiclePanelType.Note]: {
    id: MapVehiclePanelType.Note,
    name: 'VEHICLE NOTE',
  },
  [MapVehiclePanelType.StreetView]: {
    id: MapVehiclePanelType.StreetView,
    name: 'STREET VIEW',
  },
  [MapVehiclePanelType.LastInspection]: {
    id: MapVehiclePanelType.LastInspection,
    name: 'LAST INSPECTION',
  },
  [MapVehiclePanelType.Clearshare]: {
    id: MapVehiclePanelType.Clearshare,
    name: 'CLEARSHARE',
  },
  [MapVehiclePanelType.Attributes]: {
    id: MapVehiclePanelType.Attributes,
    name: 'VEHICLE ATTRIBUTES',
  },
  [MapVehiclePanelType.Closest]: {
    id: MapVehiclePanelType.Closest,
    name: 'CLOSEST VEHICLES',
  },
};

interface IProps {
  devicesStore?: DevicesStore;
  mapStore?: MapStore;
  routerStore?: RouterStore;
  [INSPECTIONS_STORE]?: InspectionsStore;
  match: {
    params: {
      vehicleId: string;
    };
  };

  collapseKeys: string[];

  onCollapseChange(values: string | string[]): void;

  onClose(): void;
}

interface IState {
  timeDiff?: string;
  timeDiffStop?: string;
  panels: MapVehiclePanelType[];
  createClearshareLink?: boolean;
  textLocation: boolean;
}

enum collapsePanels {
  Events = 'map-vehicle-events',
  Actions = 'map-vehicle-actions',
  Note = 'map-vehicle-note',
  StreetView = 'map-vehicle-street-view',
  LastInspection = 'map-vehicle-last-inspection',
  Closest = 'map-vehicle-closest',
  Attributes = 'map-vehicle-attributes',
  Clearshare = 'map-vehicle-clearshare',
}

@inject(({ devicesMapStore: { devicesStore, mapStore }, routerStore, inspectionsStore }) => ({
  devicesStore,
  mapStore,
  routerStore,
  inspectionsStore,
}))
@observer
class MapVehiclePanel extends Component<IProps & RouteComponentProps, IState> {
  private onHandleKeyUp: any;
  private timeDiff: string;
  private timeDiffStop: string;

  constructor(props) {
    super(props);

    const {
      devicesStore: {
        selectedDevice: {
          latestEvent: {
            getTimeDiff,
            metadata: {
              attributes: { calculateTotalIdle, calculateTotalIgnitionOff },
            },
          },
        },
        showHistory,
        filters: {
          tempDate: { to },
        },
      },
    } = this.props;

    const toDate = showHistory ? to : null;
    const timeState =
      calculateTotalIdle || calculateTotalIgnitionOff
        ? {
            timeDiff: getTimeDiff(true, calculateTotalIdle, calculateTotalIgnitionOff, toDate),
            timeDiffStop: getTimeDiff(true, false, false, toDate),
          }
        : {
            timeDiff: getTimeDiff(true, false, false, toDate),
          };

    this.onHandleKeyUp = this.handleKeyUp.bind(this);

    const vehicleNoteReadPermissions = validateAccessLevel([ADMIN_VEHICLE_NOTES_READ]);

    this.state = {
      ...timeState,
      createClearshareLink: false,
      textLocation: false,
      panels: (
        JSON.parse(getStorageItem('vehicle-panels-order')) || [
          MapVehiclePanelType.Events,
          MapVehiclePanelType.Actions,
          vehicleNoteReadPermissions ? MapVehiclePanelType.Note : undefined,
          MapVehiclePanelType.StreetView,
          this.lastInspectionSectionPermissions ? MapVehiclePanelType.LastInspection : undefined,
          this.clearshareSectionPermissions ? MapVehiclePanelType.Clearshare : undefined,
          MapVehiclePanelType.Attributes,
          this.closestVehiclesSectionPermissions ? MapVehiclePanelType.Closest : undefined,
        ]
      ).filter((panel) => {
        if (
          (panel === MapVehiclePanelType.Clearshare && !this.clearshareSectionPermissions) ||
          (panel === MapVehiclePanelType.Closest && !this.closestVehiclesSectionPermissions)
        ) {
          return false;
        }
        return Boolean(panel);
      }),
    };
  }

  componentDidMount = () => {
    KeyboardShortcutsManager.get().add(this.onHandleKeyUp);
    IntervalEvents.get().on(this.updateTimeDiff);
  };

  componentWillUnmount = () => {
    KeyboardShortcutsManager.get().remove(this.onHandleKeyUp);
    IntervalEvents.get().off(this.updateTimeDiff);
  };

  componentDidUpdate() {
    const {
      devicesStore: {
        selectedDevice: {
          latestEvent: {
            metadata: {
              attributes: { eventStatusType },
            },
          },
        },
      },
    } = this.props;

    IntervalEvents.get().off(this.updateTimeDiff);
    if (eventStatusType === EVENT_STATUS_TYPE.TIME_DIFF) {
      IntervalEvents.get().on(this.updateTimeDiff);
    }
  }

  updateTimeDiff = () => {
    const {
      devicesStore: {
        selectedDevice: {
          latestEvent: {
            getTimeDiff,
            metadata: {
              attributes: { calculateTotalIdle, calculateTotalIgnitionOff },
            },
          },
        },
        showHistory,
        filters: {
          tempDate: { to },
        },
      },
    } = this.props;

    const toDate = showHistory ? to : null;
    const timeDiffCurrent = getTimeDiff(true, calculateTotalIdle, calculateTotalIgnitionOff, toDate);

    if (this.timeDiff !== timeDiffCurrent) {
      this.setState({ timeDiff: timeDiffCurrent });
    }

    if (calculateTotalIdle || calculateTotalIgnitionOff) {
      const timeDiffStopCurrent = getTimeDiff(true, false, false, toDate);

      if (this.timeDiffStop !== timeDiffStopCurrent) {
        this.setState({ timeDiffStop: timeDiffStopCurrent });
      }

      this.timeDiffStop = timeDiffStopCurrent;
    }

    this.timeDiff = timeDiffCurrent;
  };

  handleKeyUp = (event) => {
    const {
      routerStore,
      onClose,
      mapStore: { pointGeozoneLocation, togglePointGeozoneLocation },
      devicesStore: { mediaPanel },
    } = this.props;
    const { createClearshareLink, textLocation } = this.state;
    const modalsOpen = createClearshareLink || textLocation;

    if (modalsOpen) {
      return;
    }

    if (event.key === KEY_TYPE.LEFT_ARROW) {
      routerStore.history.push(this.getPreviuosDeviceLink());
    }

    if (event.key === KEY_TYPE.RIGHT_ARROW) {
      routerStore.history.push(this.getNextDeviceLink());
    }

    if (event.key === KEY_TYPE.ESCAPE) {
      if (pointGeozoneLocation) {
        togglePointGeozoneLocation(false);
      } else if (!modalsOpen && !mediaPanel.value) {
        onClose();
      }
    }
  };

  handleOnFollowClick = () => {
    this.props.devicesStore.toggleSelectedDeviceFollowing();
  };
  handleOnDirectionsClick = () => {
    const {
      devicesStore: {
        selectedDevice: {
          latestEvent: { latitude, longitude },
        },
      },
    } = this.props;

    const directionURL = LINKS.directionTo.replace(/{latitude}/g, latitude).replace(/{longitude}/g, longitude);
    window.open(directionURL);
  };
  handleSelectDeviceById = (vehicleId: string) => {
    const {
      history: { push },
      devicesStore: { groups },
    } = this.props;

    const groupId = groups.groupId.value;
    const path =
      groupId && groupId !== 'all'
        ? PATHS.MAP.GROUP_VEHICLE.replace(':groupId', groupId).replace(':vehicleId', vehicleId)
        : PATHS.MAP.VEHICLE.replace(':vehicleId', vehicleId);

    push(path);
  };

  handleOnHistoryClick = () => {
    const {
      devicesStore: { toggleHistory, updateManuallySelected, showHistory },
    } = this.props;
    if (showHistory) {
      updateManuallySelected(true);
    }

    toggleHistory();
  };

  handleOnZoomInClick = () => {
    this.props.mapStore.zoomIntoVehicle();
  };

  handleOnTrailClick = () => {
    const {
      devicesStore: { toggleTrail },
      mapStore: { routeReplay },
    } = this.props;

    toggleTrail();
    routeReplay.state.isPlaying.toggle(false);
    this.closeTimelineDetails();
  };

  handleStreetViewClick = () => {
    const {
      mapStore: { streetViewOpened, setStreetViewPosition, setStreetViewPov, setStreetViewOpened },
      devicesStore: {
        selectedDevice: {
          latestEvent: { heading, latitude, longitude },
        },
      },
    } = this.props;
    setStreetViewPosition({ lat: Number(latitude), lng: Number(longitude) });
    setStreetViewPov({ heading, pitch: 0 });

    if (!streetViewOpened) {
      setStreetViewOpened(true);
    }
  };

  handleGeozoneClick = () => {
    const {
      devicesStore: {
        selectedDevice: {
          latestEvent: { geozone },
        },
      },
    } = this.props;

    this.props.history.push(PATHS.MAP.GEOZONE.replace(':geozoneId', geozone?.geozoneIdCP.toString()));
  };

  handleCloseClick = () => {
    this.props.onClose();
  };

  selectInspection = async () => {
    const {
      devicesStore: {
        selectedDevice: {
          details: { lastInspection },
        },
      },
      inspectionsStore,
    } = this.props;

    await inspectionsStore.performed.setSelectedByIdAsync(lastInspection.id);
  };

  openCreateClearshareLink = () => this.setState({ createClearshareLink: true });

  closeCreateClearshareLink = () => this.setState({ createClearshareLink: false });

  openTextLocationModal = () => this.setState({ textLocation: true });

  closeTextLocationModal = () => this.setState({ textLocation: false });

  sendVehicleLocation = async (phone: string, message: string) => {
    const {
      devicesStore: {
        selectedDevice: { textLocation },
      },
    } = this.props;

    await textLocation({ phone, message });
    this.closeTextLocationModal();
  };

  closeTimelineDetails = (): void => {
    const {
      devicesStore: { showTimelineDetails, showTrail },
    } = this.props;

    if (!showTrail) {
      showTimelineDetails.toggle(false);
    }
  };

  toggleDashcamMediaPanel = (state: boolean) => {
    const {
      devicesStore: { toggleMediaPanel, selectedDevice },
    } = this.props;

    toggleMediaPanel(state);
    if (!state) {
      selectedDevice?.media.destroy();
    }
  };

  private getPreviuosDeviceLink = (): string => {
    const {
      devicesStore: { previousFromSelectedDevice },
    } = this.props;

    return this.getDeviceLink(previousFromSelectedDevice);
  };

  private getNextDeviceLink = (): string => {
    const {
      devicesStore: { nextFromSelectedDevice },
    } = this.props;

    return this.getDeviceLink(nextFromSelectedDevice);
  };

  private getDeviceLink(selectedDevice): string {
    const {
      devicesStore: { groups },
    } = this.props;

    const groupId = groups.groupId.value;
    const deviceLink =
      groupId && groupId !== 'all' ? PATHS.MAP.GROUP_VEHICLE.replace(':groupId', groupId) : PATHS.MAP.VEHICLE;

    return deviceLink.replace(':vehicleId', selectedDevice.data.deviceId);
  }

  get eventsPanelHeader() {
    const {
      devicesStore: {
        showHistory,
        history: { activeMode },
        filters: { tempDate },
      },
      mapStore: { timezone },
    } = this.props;

    if (showHistory) {
      if (activeMode.id === HISTORY_MODE.TIME_RANGE) {
        return 'Last event for Time Range';
      } else if (activeMode.id === HISTORY_MODE.POINT_IN_TIME) {
        return 'Last event before ' + getFormattedTime(tempDate.to, DATE_TIME_FORMATS.eventDateAtTime, timezone);
      }
    }

    return 'Most Recent Event';
  }

  get clearshareSectionPermissions() {
    return validateAccessLevel([WEB_MAP_CLEARSHARE_BASIC_READ]);
  }

  get closestVehiclesSectionPermissions() {
    return validateAccessLevel([WEB_MAP_CLOSEST_VEHICLE_READ]);
  }

  get lastInspectionSectionPermissions() {
    return validateAccessLevel([ACL.INSPECTIONS.BASIC.READ]);
  }

  private get vehicleCoordinates() {
    const {
      devicesStore: {
        selectedDevice: { latestEvent },
      },
    } = this.props;

    return LINKS.showInGoogleMaps
      .replace(/{latitude}/g, latestEvent?.latitude)
      .replace(/{longitude}/g, latestEvent.longitude);
  }

  private get locationText() {
    const {
      devicesStore: {
        selectedDevice: {
          data: { shortName },
        },
      },
    } = this.props;

    return `${shortName} Vehicle\n${this.vehicleCoordinates}`;
  }

  handleMoveCard = (dragIndex: number, hoverIndex: number) => {
    const newPanels = [...this.state.panels];
    [newPanels[dragIndex], newPanels[hoverIndex]] = [newPanels[hoverIndex], newPanels[dragIndex]];
    this.setState({ panels: newPanels });
    setStringifiedStorageItem('vehicle-panels-order', newPanels);
  };

  renderActions = () => {
    const {
      devicesStore: {
        selectedDeviceFollowing,
        showTrail,
        showHistory,
        mediaPanel,
        selectedDevice: {
          data: { mediaEnabled },
        },
        isSelectedDeviceLatestEventToday,
      },
      mapStore: { locked, pointGeozoneLocation, togglePointGeozoneLocation },
    } = this.props;

    return (
      <MapVehiclePanelActions
        createGeozone={pointGeozoneLocation}
        locked={locked}
        mediaEnabled={mediaEnabled}
        onCreateGeozone={togglePointGeozoneLocation}
        onDirectionsClick={this.handleOnDirectionsClick}
        onFollowClick={this.handleOnFollowClick}
        onHistoryClick={this.handleOnHistoryClick}
        onTrailClick={this.handleOnTrailClick}
        onZoomInClick={this.handleOnZoomInClick}
        onDaschcamClick={this.toggleDashcamMediaPanel}
        selectedDeviceFollowing={selectedDeviceFollowing}
        showHistory={showHistory}
        showTrail={showTrail}
        onOpenClearshareLink={this.openCreateClearshareLink}
        onOpenTextLocation={this.openTextLocationModal}
        mediaPanelOpen={mediaPanel.value}
        showTodayTrial={isSelectedDeviceLatestEventToday}
      />
    );
  };
  renderAttributes = () => {
    const {
      devicesStore: {
        selectedDevice,
        showHistory,
        selectedDevice: {
          latestEvent: { fuelLevel },
        },
      },
    } = this.props;
    return (
      <MapVehiclePanelVehicleAttributes
        details={{ fuelLevel: fuelLevel * 100, ...selectedDevice.details }}
        showHistory={showHistory}
      />
    );
  };
  renderClearShare = () => {
    return <ClearshareLinksList />;
  };
  renderClosest = () => {
    const {
      devicesStore: {
        selectedDevice,
        filters: {
          date: { to: filterDateTo },
        },
        selectedDevice: {
          latestEvent: { latitude, longitude },
        },
      },
    } = this.props;

    return (
      <ClosestVehicles
        latitude={latitude}
        longitude={longitude}
        timestamp={filterDateTo}
        excludedDevices={[selectedDevice?.data?.deviceId].filter(Boolean)}
      />
    );
  };
  renderEvents = () => {
    const {
      devicesStore: {
        selectedEventHasDataForTimeRange,
        showHistory,
        selectedDevice: {
          firstEventTimestamp,
          latestEvent: {
            address,
            heading,
            timestamp,
            latitude,
            longitude,
            speedMph,
            geozone,
            satelliteCount,
            metadata: {
              attributes: {
                iconColor,
                label,
                eventStatusType,
                calculateTotalIdle,
                calculateTotalIgnitionOff,
                arrowColor,
              },
            },
          },
        },
      },
      mapStore: { timezone, mapHeading, showDirectionalArrows },
    } = this.props;

    const time = getFormattedTime(timestamp, DATE_TIME_FORMATS.dateAtTime, timezone);
    const isOldEvent = getDiffInDays(Date.now(), timestamp, timezone) > 1 && OLD_EVENTS_SHOW_ERROR.includes(label);
    const firstEventDate = getFormattedTime(firstEventTimestamp, DATE_TIME_FORMATS.monthDatYearFull, timezone);
    const isEventWithDirection = this.props.devicesStore.selectedDevice.latestEvent.metadata.attributes.heading;
    const eventLabel = calculateTotalIdle ? 'Total Idle' : label;

    return selectedEventHasDataForTimeRange ? (
      <Fragment>
        <MapVehiclePanelEventStatus
          arrowColor={arrowColor}
          color={iconColor}
          currentSpeed={speedMph}
          eventStatusType={eventStatusType}
          eventType={label}
          heading={showDirectionalArrows.value && isEventWithDirection && heading - mapHeading}
          label={eventLabel}
          timeDiff={this.state.timeDiff}
        />
        <MapVehiclePanelEventTime time={time} isOldEvent={isOldEvent} showHistory={showHistory} />
        {(calculateTotalIdle || calculateTotalIgnitionOff) && (
          <MapVehiclePanelEventStatusTotalStop
            label="Total Stop"
            eventStatusType={eventStatusType}
            eventType={label}
            timeDiff={this.state.timeDiffStop}
          />
        )}
        {isEventWithDirection && <MapVehiclePanelEventDirection heading={heading} />}
        <MapVehiclePanelEventLocation
          address={address}
          coordinates={[latitude, longitude]}
          isGeozone={Boolean(geozone?.geozoneIdCP && geozone?.reverseGeocode && geozone?.isActive)}
          onClick={this.handleGeozoneClick}
          showHistory={showHistory}
          satelliteCount={satelliteCount}
        />
      </Fragment>
    ) : (
      <MapVehiclePanelEventOutOfRange date={firstEventDate} />
    );
  };
  renderStreetView = () => {
    const {
      devicesStore: {
        selectedDevice: {
          latestEvent: { address, heading, latitude, longitude },
        },
      },
    } = this.props;

    return (
      <StreetView
        address={address}
        latitude={latitude}
        longitude={longitude}
        heading={heading}
        setStreetViewOpened={this.handleStreetViewClick}
      />
    );
  };
  renderNote = () => {
    const {
      devicesStore: {
        selectedDevice: {
          updateNote,
          data: { note },
        },
      },
    } = this.props;
    const vehicleNoteUpdatePermissions = validateAccessLevel([ADMIN_VEHICLE_NOTES_UPDATE]);

    return <PanelNote disabled={!vehicleNoteUpdatePermissions} note={note} onUpdate={updateNote} />;
  };

  renderLastInspection = () => {
    const {
      devicesStore: {
        selectedDevice: { details },
      },
    } = this.props;

    return (
      details.lastInspection &&
      this.lastInspectionSectionPermissions && (
        <MapVehiclePanelLastInspection
          createdBy={details.lastInspection.createdBy}
          creationTime={details.lastInspection.creationTime}
          name={details.lastInspection.name}
          failedItemsCount={details.lastInspection.failedItemsCount}
          status={details.lastInspection.status}
          deviceId={details.deviceId}
          selectInspection={this.selectInspection}
        />
      )
    );

    // return details.lastInspection.status === INSPECTION_RESULTS.IN_PROGRESS ? 'Current Inspection' : 'Last Inspection';
  };

  renderElement = (key: MapVehiclePanelType) => {
    switch (key) {
      case MapVehiclePanelType.Actions:
        return this.renderActions();
      case MapVehiclePanelType.Attributes:
        return this.renderAttributes();
      case MapVehiclePanelType.Clearshare:
        return this.renderClearShare();
      case MapVehiclePanelType.Closest:
        return this.renderClosest();
      case MapVehiclePanelType.Events:
        return this.renderEvents();
      case MapVehiclePanelType.StreetView:
        return this.renderStreetView();
      case MapVehiclePanelType.LastInspection:
        return this.renderLastInspection();
      case MapVehiclePanelType.Note:
        return this.renderNote();

      default:
        return null;
    }
  };

  handleCollapseChange = (key: string) => {
    const { collapseKeys } = this.props;
    this.props.onCollapseChange(
      collapseKeys.includes(key) ? collapseKeys.filter((item) => item !== key) : [...collapseKeys, key]
    );
  };

  render() {
    const {
      devicesStore: {
        devicesListCount,
        showHistory,
        previousFromSelectedDevice,
        nextFromSelectedDevice,
        filters: { date },
        selectedDevice: {
          repositoryNotifications: { createState },
          data: {
            assetId,
            driverName,
            note,
            description,
            hasActiveClearShareLink,
            mediaEnabled,
            isPowerDisconnected,
            isCameraPowerDisconnected,
          },
          details: { driverDescription },
          deviceEventsRequestStatus,
        },
      },
      collapseKeys,
    } = this.props;
    const { createClearshareLink, textLocation } = this.state;

    const disabledNavigation = devicesListCount <= 1;
    const mapVehiclePanelContainerCN = classNames('MapVehiclePanel-container', {
      'MapVehiclePanel-container--loading': deviceEventsRequestStatus.loading && showHistory,
    });

    return (
      <div className="MapVehiclePanel-wrap">
        <div className={mapVehiclePanelContainerCN}>
          <div className="MapVehiclePanel-header">
            <MapVehicleSelector onSelect={this.handleSelectDeviceById} />
            {driverName && <div className="MapVehiclePanel-name">{driverDescription}</div>}
            <div className="MapVehiclePanel-descriptionAndIcons">
              <div className="MapVehiclePanel-description">{description}</div>
              <div className="MapVehiclePanel-icons">
                <VehicleItemIcons
                  hasActiveClearShareLink={hasActiveClearShareLink}
                  mediaEnabled={mediaEnabled}
                  mediaLink={`${PATHS.DASHCAMS.MEDIA_MANAGER}?assetId=${assetId}&from=${date.from}&to=${
                    date.to || Date.now()
                  }`}
                  isShowCamera={!isCameraPowerDisconnected || showHistory}
                />
              </div>
            </div>
            {validateAccessLevel([WEB_DASHCAM_BASIC_READ]) && isCameraPowerDisconnected && !showHistory && (
              <div className="MapVehiclePanel-cameraPowerDisconnected">
                <NoMediaIcon />
                <span className="MapVehiclePanel-cameraPowerDisconnectedText">Dashcam is offline</span>
              </div>
            )}
            {isPowerDisconnected && !showHistory && (
              <div className="MapVehiclePanel-powerDisconnected">
                <PowerOffIcon />
                <span className="MapVehiclePanel-powerDisconnectedText">Tracker has lost power</span>
              </div>
            )}
            <div className="MapVehiclePanel-close">
              <CloseButton onClick={this.handleCloseClick} />
            </div>
          </div>
          <div className="MapVehiclePanel-content">
            {[...this.state.panels].map((item, index) => {
              const id = String(item);
              return (
                <DraggableCard id={id} key={id} index={Number(index)} moveCard={this.handleMoveCard}>
                  <div className="MapVehiclePanel-PanelWrapper">
                    <div className="MapVehiclePanel-PanelContent">
                      <Collapse
                        activeKey={collapseKeys.includes(id) ? [id] : []}
                        expandIcon={() => (
                          <span className="anticon anticon-right ant-collapse-arrow">
                            <ChevronIcon />
                          </span>
                        )}
                        expandIconPosition={'left'}
                        onChange={() => this.handleCollapseChange(id)}
                      >
                        <Collapse.Panel header={MapVehiclePanels[id].name} key={id} className="MapVehiclePanel-Block">
                          {this.renderElement(item)}
                        </Collapse.Panel>
                      </Collapse>
                    </div>
                  </div>
                </DraggableCard>
              );
            })}
            {this.clearshareSectionPermissions && (
              <>
                <ClearShareCreate
                  isCreateClearshareLinkOpen={createClearshareLink}
                  onCloseClearshareLink={this.closeCreateClearshareLink}
                />
              </>
            )}
          </div>
        </div>
        <div className="MapVehiclePanel-footer">
          <Link
            to={this.getPreviuosDeviceLink()}
            className={classNames('MapVehiclePanel-footer-LinkLeft', {
              'MapVehiclePanel-footer-Link--disabled': disabledNavigation,
            })}
          >
            <span className="MapVehiclePanel-footer-IconLeft">
              <ChevronIcon />
            </span>
            <span className="MapVehiclePanel-footer-LinkText">{previousFromSelectedDevice.data.shortName}</span>
          </Link>

          <Link
            to={this.getNextDeviceLink()}
            className={classNames('MapVehiclePanel-footer-LinkRight', {
              'MapVehiclePanel-footer-Link--disabled': disabledNavigation,
            })}
          >
            <span className="MapVehiclePanel-footer-LinkText">{nextFromSelectedDevice.data.shortName}</span>
            <span className="MapVehiclePanel-footer-IconRight">
              <ChevronIcon />
            </span>
          </Link>
        </div>
        <TextLocationModal
          isOpen={textLocation}
          note={note}
          onCancel={this.closeTextLocationModal}
          onSubmit={this.sendVehicleLocation}
          text={this.locationText}
          type="Vehicle"
        />
        {createState.success && (
          <Notification
            onClose={createState.reset}
            text="The text message was successfully sent."
            title="Success!"
            type="success"
          />
        )}
        {createState.error && (
          <Notification
            onClose={createState.reset}
            text="The text message failed to send. Try again."
            title="Send Message Failed"
            type="error"
          />
        )}
      </div>
    );
  }
}

export default withRouter(
  withCollapseStorage(MapVehiclePanel, 'map-vehicle-panel-collapse', Object.values(collapsePanels))
);
