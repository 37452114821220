import React, { FC } from 'react';

import ConfirmationModal from 'components/Admin/ConfirmationModal';

import './styles.scss';

interface IProps {
  className?: string;
  id: number;
  isOpen: boolean;
  name: string;
  onCancel: () => void;
  onSubmit: () => void;
  submitDisabled?: boolean;
}

const DeletePersonModal: FC<IProps> = ({ id, isOpen, name, onCancel, onSubmit, submitDisabled }) => {
  return (
    <ConfirmationModal
      applyButtonTitle="Yes, Delete"
      cancelButtonStyles={{ border: 'none' }}
      contentStyles={{ padding: '0 30px', backgroundColor: '#FFF' }}
      isOpen={isOpen}
      isSubmitDisabled={submitDisabled}
      onCancel={onCancel}
      onSubmit={onSubmit}
      title={`Delete Person ${name || id}?`}
      withoutCloseIcon
    >
      <div className="DeletePersonModal">
        <p className="DeletePersonModal-text DeletePersonModal-text--top">
          Deleting this person will permanently remove them from the system and they will no longer have access to the
          portal.
        </p>
        <p className="DeletePersonModal-text DeletePersonModal-text--bottom">Do you want to continue?</p>
      </div>
    </ConfirmationModal>
  );
};

export default DeletePersonModal;
