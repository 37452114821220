import downloadjs from 'downloadjs';
import { computed } from 'mobx';
import { SimpleField, ToggleField } from 'models/Fields';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

export default class MediaFile {
  downloading: ToggleField;
  loaded: SimpleField<number>;
  total: SimpleField<number>;
  id: string;
  timestamp: number;
  repository: IEntityRepository;

  constructor({ repository, id, timestamp }) {
    this.downloading = new ToggleField(false);
    this.loaded = new SimpleField(null);
    this.total = new SimpleField(null);
    this.id = id;
    this.timestamp = timestamp;
    this.repository = repository.entity('data').entity('download');
  }

  @computed get downloadingPercent() {
    return Math.trunc((this.loaded.value / this.total.value) * 100) || 0;
  }

  download = async () => {
    this.downloading.toggle(true);
    const response = await this.repository.get(
      { id: this.id, timestamp: this.timestamp },
      { responseType: 'blob', onProgress: this.update }
    );
    downloadjs(response, `${this.timestamp}.zip`);
    setTimeout(this.reset, 2000);
  };

  private update = ({ loaded, total }) => {
    this.loaded.set(loaded);
    this.total.set(total);
  };

  private reset = () => {
    this.downloading.toggle(false);
    this.loaded.set(null);
    this.total.set(null);
  };
}
