import { PLAYER_ERROR_TYPE } from 'config';
import type { IPlayerVideo } from 'interfaces/models/Dashcams/IPlayerVideo';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { SimpleField } from 'models/Fields';
import { repositoryService } from 'services';
import type PlayerViewModel from './Player';
import PlayerVideoRestAPISource from './PlayerVideoRestAPI/PlayerVideoRestApiSource';

class PlayerVideoRestAPI implements IPlayerVideo {
  url: SimpleField<string>;
  private context: PlayerViewModel;
  private repository: IEntityRepository;
  private outside: PlayerVideoRestAPISource;
  private inside: PlayerVideoRestAPISource;

  constructor(context: PlayerViewModel) {
    this.context = context;
    this.url = new SimpleField(null);
    this.outside = new PlayerVideoRestAPISource(true);
    this.inside = new PlayerVideoRestAPISource(false);
    this.repository = repositoryService
      .get('media', 'v2.0')
      .entity('cameras')
      .entity(this.context.cameraModel.id)
      .entity('data')
      .entity('latest-video');
  }

  connect = async () => {
    const response = await this.repository.get();

    if (this.repository.getState.success) {
      this.outside.set(response.outsideUrl, response.outsideDuration * 1000);
      this.inside.set(response.insideUrl, response.insideDuration * 1000);

      this.url.set(this.outside.isActive.value ? this.outside.url.value : this.inside.url.value);
      this.context.timeSegment.duration.active.set(
        this.outside.isActive.value ? this.outside.duration.value : this.inside.duration.value
      );
    } else {
      this.context.onError(PLAYER_ERROR_TYPE.VIDEO);
    }
  };

  disconnect = () => {
    //
  };

  switchCameraOnline = () => {
    if (this.outside.isActive.value) {
      this.url.set(this.inside.url.value);
    } else {
      this.url.set(this.outside.url.value);
    }

    this.inside.isActive.toggle();
    this.outside.isActive.toggle();
    clearInterval(this.context.timer);
  };
}

export default PlayerVideoRestAPI;
