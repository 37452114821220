import { ToggleField } from 'models/Fields';
import type DevicesAttributesFilter from './DevicesAttributesFilter';

class AttributeFilter {
  label: string;
  id: string;
  fieldKey: string;
  isChecked: ToggleField;
  context: DevicesAttributesFilter;

  constructor({ label, id, fieldKey }, context: DevicesAttributesFilter) {
    this.isChecked = new ToggleField(false);
    this.label = label;
    this.id = id;
    this.fieldKey = fieldKey;
    this.context = context;
  }

  toggle = (value?: boolean) => {
    this.isChecked.toggle(value);
    this.context.onChecked();
  };
}

export default AttributeFilter;
