/*global Chart*/
import React, { useEffect, useMemo, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { cloneDeep, merge } from 'lodash';
import { datasetOptions, mediaChartOptions } from './constants';

interface IProps {
  chartData: number[];
  currentSecond: number;
  scaleLimit?: number;
}

const ChartCPG: React.FC<IProps> = ({ chartData, currentSecond = 0, scaleLimit = 0 }) => {
  const chartRef = useRef(null);

  const labels = chartData.map((_, i) => i + 1);

  useEffect(() => {
    const thisChart = chartRef.current.chartInstance;
    openToolTip(thisChart, currentSecond);
  }, [currentSecond]);

  const openToolTip = (myChart, index) => {
    const meta = myChart.getDatasetMeta(0);
    const rectangle = myChart.canvas.getBoundingClientRect();
    if (!meta.data[index]) {
      return;
    }
    const point = meta.data[index].getCenterPoint();
    const mouseMoveEvent = new MouseEvent('mousemove', {
      clientX: rectangle.left + point.x,
      clientY: rectangle.top + point.y,
    });

    myChart.canvas.dispatchEvent(mouseMoveEvent);
  };

  useEffect(() => {
    // @ts-ignore
    Chart.pluginService.register({
      beforeDraw: (chart) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const activePoint = chart.controller.tooltip._active[0];
          const ctx = chart.ctx;
          const x = activePoint.tooltipPosition().x;
          const topY = chart.scales['y-axis-0'].top;
          const bottomY = chart.scales['y-axis-0'].bottom;

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 2;
          ctx.strokeStyle = '#FF8801';
          ctx.stroke();
          ctx.restore();
        }
      },
    });
  }, []);

  const options = useMemo(() => {
    if (scaleLimit) {
      const xMin = Math.min(...(chartData || []));
      const xMax = Math.max(...(chartData || []));
      const xSpace = xMax - xMin;

      if (xSpace >= 0 && xSpace < scaleLimit) {
        const middle = xMax - (xMax - xMin) / 2;
        const bottomCompensation = middle <= scaleLimit ? Math.abs(middle - scaleLimit / 2) : 0;
        const min = Math.floor(middle - scaleLimit / 2 + bottomCompensation);
        const max = Math.ceil(middle + scaleLimit / 2 + bottomCompensation);

        return merge(cloneDeep(mediaChartOptions), {
          scales: { yAxes: [{ ticks: { min, max, stepSize: Math.ceil(scaleLimit / 4) } }] },
        });
      }
    }
    return { ...mediaChartOptions };
  }, [chartData]);

  return (
    <div>
      <Line
        ref={chartRef}
        data={{
          labels,
          datasets: [
            {
              data: [...chartData],
              ...datasetOptions,
            },
          ],
        }}
        options={options}
        width={630}
        height={60}
      />
    </div>
  );
};

export default ChartCPG;
