import * as React from 'react';

import { appStoreBadge, playMarketBadge } from 'assets';

import './styles.scss';

const MobileApps = () => (
  <div className="MobileApps">
    <div className="MobileApps-container">
      <div className="MobileApps-header">Access ClearPathGPS anywhere, anytime</div>
      <div className="MobileApps-badges">
        <a href="https://www.clearpathgps.com/ios-app-store">
          <img src={appStoreBadge} alt="" />
        </a>
        <a href="https://www.clearpathgps.com/mobile-play-store">
          <img src={playMarketBadge} alt="" />
        </a>
      </div>
    </div>
  </div>
);

export default MobileApps;
