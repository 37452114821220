import React, { FC, useCallback, useState } from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import type { IAppFile } from 'models/Files/IAppFile';
import InfoTooltip from 'components/InfoTooltip';
import FileSizeModal from '../FileSizeModal';
import ConfirmDeleteModal from '../ConfirmDeleteModal';

import { CloseIcon, LoaderIcon, InfoIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  edit?: boolean;
  file: IAppFile;
  onDownload: (file: IAppFile) => void;
  onPreview?: (file: IAppFile) => void;
  onRemove: (file: IAppFile) => void;
  onUpload?: (file: IAppFile) => void;
}

const FileItem: FC<IProps> = ({ className, edit = true, file, onRemove, onPreview = () => true }) => {
  const [fileSizeOpen, setFileSizeOpen] = useState(file.sizeExtended || false);
  const [deleteFileOpen, setDeleteFileOpen] = useState(false);
  const remove = useCallback(() => {
    onRemove(file);
  }, [file, onRemove]);
  const confirmRemove = () => {
    setDeleteFileOpen(false);
    remove();
  };

  return (
    <Observer
      render={() => {
        const isError = file.repository.createState.error || file.sizeExtended;
        const isUploading = file.repository.createState.loading;
        const cn = classNames('FileItem', className, {
          'FileItem--uploading': isUploading,
          'FileItem--error': isError,
        });

        return (
          <div className={cn}>
            {isError && (
              <div className="FileItem-errorTooltip">
                <InfoTooltip
                  icon={<InfoIcon style={{ display: 'block', width: '12px', height: '12px', fill: '#F44336' }} />}
                >
                  Upload failed
                </InfoTooltip>
              </div>
            )}
            <p className="FileItem-name" onClick={() => onPreview && onPreview(file)}>
              {file.model.displayName}
            </p>
            {isUploading ? (
              <div className="FileItem-uploading">
                <LoaderIcon className="FileItem-uploadingIcon" />
              </div>
            ) : (
              edit && (
                <div className="FileItem-close">
                  <CloseIcon
                    className="FileItem-closeIcon"
                    onClick={isError ? remove : () => setDeleteFileOpen(true)}
                  />
                </div>
              )
            )}
            <FileSizeModal open={fileSizeOpen} onClose={() => setFileSizeOpen(false)} />
            <ConfirmDeleteModal
              fileName={file.model.displayName}
              onCancel={() => setDeleteFileOpen(false)}
              onSubmit={confirmRemove}
              open={deleteFileOpen}
            />
          </div>
        );
      }}
    />
  );
};

export default FileItem;
