import React, { FC } from 'react';
import MapInfoBox from '../InfoBox';
import { reduceDecimals } from 'utils/numbers';

import './styles.scss';

interface IProps {
  position: { lat: number; lng: number };
}

const CoordinatesInfoBox: FC<IProps> = ({ position }) => {
  return (
    <MapInfoBox position={position}>
      <div className="CoordinatesInfoBox CoordinatesInfoBox--single">
        <div className="CoordinatesInfoBox-content">
          {reduceDecimals(position?.lat)}, {reduceDecimals(position?.lng)}
        </div>
      </div>
    </MapInfoBox>
  );
};

export default CoordinatesInfoBox;
