import MaintenanceConfig from '../MaintenanceConfig';

class MaintenanceBaseTracker {
  config: MaintenanceConfig;

  constructor({ config }, coefficient = 1) {
    this.config = new MaintenanceConfig(config || {}, coefficient);
  }
}

export default MaintenanceBaseTracker;
