import { FREQUENCY_TYPE, METRICS_SWITCH_TYPE } from 'config';
import { SimpleField, ToggleField } from 'models/Fields';
import type MaintenanceModel from 'models/Maintenances/Models/MaintenanceModel';
import MaintenanceModalDueAtUI from './MaintenanceModalDueAtUI';
import MaintenanceModalDueEveryUI from './MaintenanceModalDueEveryUI';

const DEFAULT_VALUES = {
  FREQUENCY: FREQUENCY_TYPE.ONE_TIME,
  DUE: {
    metricsCheckbox: false,
    dateCheckbox: true,
    metricsSwitch: METRICS_SWITCH_TYPE.MILES,
  },
  DUE_EVERY: {
    metricsCheckbox: true,
    dateCheckbox: false,
    metricsSwitch: METRICS_SWITCH_TYPE.MILES,
  },
};

class MaintenanceModalUI {
  isTaskNameError: ToggleField;
  isVehicleDisabled: ToggleField;
  isServiceTypeDisabled: ToggleField;
  isFrequencyDisabled: ToggleField;
  frequency: SimpleField<FREQUENCY_TYPE>;
  dueAt: MaintenanceModalDueAtUI;
  dueEvery: MaintenanceModalDueEveryUI;
  context: MaintenanceModel;

  constructor(context: MaintenanceModel) {
    this.context = context;

    const frequency = Boolean(context.id.value) ? this.options.frequency : DEFAULT_VALUES.FREQUENCY;
    const due = Boolean(context.id.value) && !this.context.isRepeat ? this.options.due : DEFAULT_VALUES.DUE;
    const dueEvery = Boolean(context.id.value) && this.context.isRepeat ? this.options.due : DEFAULT_VALUES.DUE_EVERY;

    this.isTaskNameError = new ToggleField(false);
    this.isVehicleDisabled = new ToggleField(false);
    this.isServiceTypeDisabled = new ToggleField(false);
    this.isFrequencyDisabled = new ToggleField(false);
    this.frequency = new SimpleField(frequency);
    this.dueAt = new MaintenanceModalDueAtUI(due);
    this.dueEvery = new MaintenanceModalDueEveryUI(dueEvery);
  }

  reset = () => {
    this.frequency.reset();
    this.dueAt.reset();
    this.dueEvery.reset();
  };

  setEditMode = () => {
    this.isVehicleDisabled.toggle(true);
    this.isServiceTypeDisabled.toggle(true);
    this.isFrequencyDisabled.toggle(true);
  };

  setCreateMode = () => {
    this.isVehicleDisabled.toggle(false);
    this.isServiceTypeDisabled.toggle(false);
    this.isFrequencyDisabled.toggle(false);
  };

  private get options() {
    const options = {
      frequency: null,
      due: { metricsCheckbox: true, dateCheckbox: true, metricsSwitch: METRICS_SWITCH_TYPE.MILES },
    };

    if (this.context.isRepeat) {
      options.frequency = FREQUENCY_TYPE.REPEAT;
      options.due.metricsCheckbox = Boolean(
        this.context.odometer.config.interval.value || this.context.engine.config.interval.value
      );
      options.due.dateCheckbox = Boolean(this.context.service.config.interval.value);
      options.due.metricsSwitch = Boolean(this.context.odometer.config.interval.value)
        ? METRICS_SWITCH_TYPE.MILES
        : METRICS_SWITCH_TYPE.HOURS;
    } else {
      options.frequency = FREQUENCY_TYPE.ONE_TIME;
      options.due.metricsCheckbox = Boolean(
        this.context.odometer.config.next.value || this.context.engine.config.next.value
      );
      options.due.dateCheckbox = Boolean(this.context.service.config.next.value);
      options.due.metricsSwitch = Boolean(this.context.odometer.config.next.value)
        ? METRICS_SWITCH_TYPE.MILES
        : METRICS_SWITCH_TYPE.HOURS;
    }

    return options;
  }
}

export default MaintenanceModalUI;
