import React, { useState } from 'react';
import { Typography, Tooltip } from 'antd';
import type { TooltipPlacement } from 'antd/lib/tooltip';
import type { ParagraphProps } from 'antd/lib/typography/Paragraph';

import './styles.scss';

const { Paragraph } = Typography;


interface IProps extends ParagraphProps {
  placement?: TooltipPlacement;
}

const TooltipParagraph: React.FC<IProps> = ({ children, placement, ...props }) => {
  const [truncated, setTruncated] = useState(false);

  return (
    <Tooltip title={truncated ? children : undefined} placement={placement} overlayClassName="TooltipParagraph-overlay">
      <Paragraph {...props} ellipsis={{ onEllipsis: setTruncated }} className="TooltipParagraph">
        {/* NOTE: Fragment is necessary */}
        <>{children}</>
      </Paragraph>
    </Tooltip>
  );
};

export default TooltipParagraph;
