import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import type { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { parse } from 'query-string';

import { PATHS } from 'config';
import type { UserStore, RouterStore } from 'stores';

import AuthPage from 'components/Auth/AuthPage';
import LoginForm from 'components/Auth/Login/Form';
import Logo from 'components/Logo';
import MobileApps from 'components/Auth/Login/MobileApps';
import NavLink from 'components/NavLink';

import './styles.scss';

interface IProps extends RouteComponentProps {
  className?: string;
  userStore: UserStore;
  routerStore: RouterStore;
}

interface IQueryParams {
  reset?: string;
}

@inject(({ userStore, routerStore }) => ({
  userStore,
  routerStore,
}))
@observer
class LoginPage extends Component<IProps> {
  componentDidMount() {
    const {
      userStore: { jwToken },
      history: { push },
    } = this.props;

    if (jwToken) {
      push(PATHS.MAP.VEHICLES);
    }
  }

  componentWillUnmount() {
    const {
      userStore: { repositoryAuthToken },
    } = this.props;

    repositoryAuthToken.createState.reset();
  }

  get queryParams() {
    const {
      location: { search },
    } = this.props;
    const { reset } = parse(search) as IQueryParams;

    return { reset };
  }

  handleLogin = async ({ email, password, accountId }: { email: string; password: string; accountId: string }) => {
    const { userStore } = this.props;

    await userStore.login(email, password, accountId);

    if (
      (userStore.repositoryLegacyAuthToken.createState.success && userStore.details !== null) ||
      userStore.repositoryAuthToken.createState.success
    ) {
      if (userStore.defaultSystem === 2) {
        window.location.replace(`/track/Track`);
      } else {
        const redirectUrl = process.env.REACT_APP_CONTENT_MODE === 'dashcams' ? PATHS.DASHCAMS.INDEX : PATHS.MAP.INDEX;
        window.location.replace(`/web${redirectUrl}?time=${Date.now()}`);
      }
    } else if (
      userStore.repositoryAuthToken.createState.error ||
      userStore.repositoryLegacyAuthToken.createState.error
    ) {
      throw new Error(userStore.repositoryAuthToken.createState.error);
    }
  };

  handleResetForm = () => {
    const {
      userStore: { repositoryAuthToken, repositoryLegacyAuthToken, updateMultiAccount },
    } = this.props;

    repositoryAuthToken.createState.reset();
    repositoryLegacyAuthToken.createState.reset();
    updateMultiAccount(false)
  }

  get errorMessage() {
    const {
      userStore: { repositoryAuthToken },
    } = this.props;

    const error = repositoryAuthToken.createState.error;

    switch (error) {
      case 'Locked':
        return 'Too many invalid login attempts. Your account has been locked for 10 minutes.';
      case 'Invalid credentials':
        return 'Invalid Email or Password';
      default:
        return error;
    }
  }

  render() {
    const {
      className,
      userStore: { repositoryAuthToken, repositoryConfig, repositoryLegacyAuthToken, multiAccount },
    } = this.props;
    const cn = classNames('LoginPage', className);

    const isSending =
      repositoryAuthToken.createState.loading ||
      repositoryLegacyAuthToken.createState.loading ||
      repositoryConfig.getState.loading;
    const hasError =
      !isSending &&
      (repositoryAuthToken.createState.error ||
        (repositoryLegacyAuthToken.createState.error && !repositoryAuthToken.createState.success));
    const hasSent =
      !isSending &&
      (repositoryAuthToken.createState.success ||
        (repositoryLegacyAuthToken.createState.success && !repositoryAuthToken.createState.error));

    return (
      <AuthPage className="AuthPage--login">
        <div className={cn}>
          <div className="LoginPage-logo">
            <Logo />
          </div>
          {this.queryParams.reset && (
            <div className="LoginPage-text LoginPage-text--reset">
              Your password has been successfully reset. Please use it to log in below.
            </div>
          )}
          <div className="LoginPage-form">
            <div className="LoginPage-login">
              <div className="LoginPage-loginForm">
                <LoginForm onSubmit={this.handleLogin} sending={isSending} sent={hasSent} isMultiLogin={multiAccount} onReset={this.handleResetForm} />
              </div>
              <div className="LoginPage-formToggle">
                <NavLink
                  to={PATHS.AUTH.FORGOT_PASSWORD}
                  title="Forgot your password?"
                  className="NavLink--medium NavLink--primary"
                />
              </div>
              {Boolean(hasError) && <p className="LoginPage-errorMessage">{this.errorMessage}</p>}
            </div>
          </div>
          <div className="LoginPage-applications">
            <MobileApps />
          </div>
        </div>
      </AuthPage>
    );
  }
}

export default LoginPage;
