import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { formatDecimalNumber } from 'utils';

import { WEB_FINANCE_DETAILS_READ } from 'config';

import type { CustomersStore } from 'stores';
import validateAccessLevel from 'stores/acl/validator';

import FinancialDataAccessError from 'components/FinancialDataAccessError';
import SecondaryPanelEstimateCostsItems from '../../SecondaryPanelEstimateCostsItems';

import './styles.scss'

interface IProps {
  customersStore?: CustomersStore;
}


@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class SecondaryPanelEstimateCosts extends Component<IProps> {

  get hasAccess () {
    return validateAccessLevel([WEB_FINANCE_DETAILS_READ]);
  }

  render() {
    const customer = this.props.customersStore.selectedCustomer;
    const estimate = customer.selectedEstimate;
    const hasAccess = this.hasAccess;

    return <div className="SecondaryPanelEstimateCosts">
      <div className="SecondaryPanelEstimateCostsPrice">
        <div className="SecondaryPanelEstimateCostsPrice-item">
          <div className="SecondaryPanelEstimateCostsPrice-header">Total</div>
          {hasAccess && (
            <div
              className="SecondaryPanelEstimateCostsPrice-value"
            >${formatDecimalNumber(estimate.totalAmount, 2)}</div>
          )}
          {!hasAccess && <FinancialDataAccessError className="SecondaryPanelEstimateCostsPrice-value--limited" />}
        </div>
      </div>

      <SecondaryPanelEstimateCostsItems
        items={estimate.items}
        limited={!hasAccess}
      />
    </div>;
  }
}

export default SecondaryPanelEstimateCosts;
