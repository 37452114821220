import { computed } from 'mobx';
import type MaintenanceConfig from 'models/Maintenances/Models/MaintenanceConfig';

export default class FrequencyCell {
  configs: MaintenanceConfig[];

  constructor({
    odometerConfig,
    serviceConfig,
    engineConfig,
  }: {
    odometerConfig: MaintenanceConfig;
    serviceConfig: MaintenanceConfig;
    engineConfig: MaintenanceConfig;
  }) {
    this.configs = [odometerConfig, engineConfig, serviceConfig];
  }

  @computed get isRepeat() {
    return this.configs.some((config) => Boolean(config.interval.value));
  }

  @computed get text() {
    return this.configs.reduce((text, current) => {
      if (Boolean(current.interval.value)) {
        text += `${text ? ' / ' : ''} ${Number(current.interval.value).toLocaleString('en-US', {
          maximumFractionDigits: 0,
        })} ${current.intervalUnit.value ? current.intervalUnit.value.toLowerCase() : current.unit.short}`;
      }

      return text;
    }, '');
  }
}
