import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import type { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import queryString from 'query-string';
import { getStartOfDayByDate, getEndOfDayByDate, getTimestampForTimezone } from 'utils';

import { PATHS, CAMERA_TYPE } from 'config';
import type { MediaManager, RouterStore, TimeStore } from 'stores';

import { DASHCAM_STATUS } from 'models/Dashcam/DashcamStatus';

import MediaManagerWrapper from 'components/DashCams/MediaManagerWrapper';
import MediaManagerSmartWitness from './MediaManagerSmartWitness';
import MediaManagerXirgo from './MediaManagerXirgo';

import './styles.scss';

interface ISearchParams {
  assetId?: string;
  timestamp?: string;
}
interface IProps extends RouteComponentProps {
  mediaManagerStore?: MediaManager;
  timeStore?: TimeStore;
  routerStore?: RouterStore;
}

@inject(({ mediaManagerStore, timeStore, routerStore }) => ({ mediaManagerStore, timeStore, routerStore }))
@observer
class RequestMediaStep1 extends Component<IProps> {
  async componentDidMount() {
    const {
      mediaManagerStore: { checkout },
      routerStore: {
        history: { push },
      },
      timeStore: { sessionTimezone },
    } = this.props;

    await checkout.fetch(this.params.assetId);

    if (this.isIntervalSelected && checkout.vehicle.details.camera.provider.value === CAMERA_TYPE.XIRGO) {
      push(`${this.secondStepPath}&timestamp=${this.params.timestamp}`);
    }

    if (this.params.timestamp) {
      const interval = checkout.camerasJournal.getSelectedInterval();

      if (checkout.vehicle.details.camera.provider.value === CAMERA_TYPE.SMARTWITNESS && !this.isOffline) {
        checkout.camerasJournal.customInterval.isSelected.toggle(true);
      }

      if (interval) {
        let startTimestamp = interval.startTimestamp;
        let endTimestamp = interval.endTimestamp;
        const activeDateTimestamp = getTimestampForTimezone(this.selectedDate, 'MM/DD/YYYY', sessionTimezone);

        checkout.requestMedia.timeSegment.startTime.set(this.params.timestamp);

        if (getStartOfDayByDate(activeDateTimestamp, sessionTimezone) > interval.startTimestamp) {
          startTimestamp = getStartOfDayByDate(activeDateTimestamp, sessionTimezone);
        }

        if (getEndOfDayByDate(activeDateTimestamp, sessionTimezone) < interval.endTimestamp) {
          endTimestamp = getEndOfDayByDate(activeDateTimestamp, sessionTimezone);
        }
        checkout.requestMedia.timeSegment.timeline.start.set(startTimestamp);
        checkout.requestMedia.timeSegment.timeline.end.set(endTimestamp);
      } else if (this.isOffline) {
        checkout.requestMedia.timeSegment.startTime.set(this.params.timestamp);
      }
    }
  }

  redirectToMediaManager = () => {
    const {
      mediaManagerStore: {
        checkout: {
          repositoryMediaRequest,
          requestMedia: { timeSegment },
        },
      },
      history: { push },
      timeStore: { sessionTimezone },
    } = this.props;
    const from = getStartOfDayByDate(timeSegment.startTime.value, sessionTimezone);
    const to = getEndOfDayByDate(timeSegment.startTime.value, sessionTimezone);

    if (repositoryMediaRequest?.createState.success) {
      push(`${PATHS.DASHCAMS.MEDIA_MANAGER}?assetId=${this.params.assetId}&from=${from}&to=${to}`);
    }
  };

  requestMedia = async () => {
    const {
      mediaManagerStore: {
        checkout: { request },
      },
    } = this.props;

    await request();
    this.redirectToMediaManager();
  };

  get selectedDate() {
    const {
      mediaManagerStore: {
        checkout: { camerasJournal },
      },
    } = this.props;

    return camerasJournal.dates.active;
  }

  get selectedCameraDate() {
    const {
      mediaManagerStore: {
        checkout: { camerasJournal },
      },
    } = this.props;

    return camerasJournal.dates.model[this.selectedDate];
  }

  get selectedCameraInterval() {
    return this.selectedCameraDate && this.selectedCameraDate.findSelectedInterval();
  }

  get params() {
    const {
      history: {
        location: { search },
      },
    } = this.props;
    const { assetId, timestamp } = queryString.parse(search) as ISearchParams;

    return { assetId, timestamp: Number(timestamp) };
  }

  get isOffline() {
    const {
      mediaManagerStore: {
        checkout: { dashcamStatus },
      },
    } = this.props;

    return dashcamStatus.status.value === DASHCAM_STATUS.OFFLINE || dashcamStatus.cameraDetails.isBusy.value;
  }

  get secondStepPath() {
    const {
      mediaManagerStore: {
        checkout: { camerasJournal },
      },
    } = this.props;

    const startTimestamp = this.params.timestamp
      ? this.params.timestamp
      : this.selectedCameraInterval?.startTimestamp || camerasJournal.customInterval.startTimestamp.value;

    return `${PATHS.DASHCAMS.MEDIA_REQUEST.INDEX}/step-2?assetId=${
      this.params.assetId
    }&startTimestamp=${startTimestamp}&endTimestamp=${this.selectedCameraInterval?.endTimestamp ||
      camerasJournal.customInterval.endTimestamp.value}&timestamp=${
      camerasJournal.customInterval.isSelected.value ? camerasJournal.customInterval.activeTimestamp.value : ''
    }`;
  }

  get thirdStepPath() {
    const {
      mediaManagerStore: {
        checkout: {
          requestMedia: { timeSegment },
        },
      },
    } = this.props;

    return `${PATHS.DASHCAMS.MEDIA_REQUEST.INDEX}/step-3?assetId=${this.params.assetId}&startTimestamp=${timeSegment.startTime.value}&duration=${timeSegment.duration.active.value}`;
  }

  get isIntervalSelected() {
    const {
      mediaManagerStore: {
        checkout: { camerasJournal },
      },
    } = this.props;

    return camerasJournal.selectByTimestamp(this.params.timestamp);
  }

  get isStartTimeOutside() {
    const {
      mediaManagerStore: {
        checkout: {
          camerasJournal,
          requestMedia: { timeSegment },
        },
      },
    } = this.props;

    const startTimeInside = camerasJournal.initialList.some(
      (item) => item.startTimestamp < timeSegment.startTime.value && item.endTimestamp > timeSegment.startTime.value
    );

    return !startTimeInside;
  }

  render(): React.ReactNode {
    const {
      mediaManagerStore: {
        checkout: {
          isLoading,
          vehicle,
          dashcamStatus,
          requestMedia: { cameraType, timeSegment },
          repositoryMediaRequest,
          camerasJournal,
        },
      },
    } = this.props;

    return (
      <>
        {vehicle.details.camera.provider.value === CAMERA_TYPE.SMARTWITNESS && (
          <MediaManagerWrapper
            isLoading={isLoading.value}
            isRequestLoading={repositoryMediaRequest?.createState.loading}
            currentStep={1}
            nextDisabled={
              repositoryMediaRequest?.createState.loading || !cameraType.anySelected || !timeSegment.startTime.value
            }
            isShowSidebar={false}
            onSubmit={this.requestMedia}
            className={classNames('MediaManagerSmartWitnessWrapper', {
              'MediaManagerSmartWitnessWrapper--online': dashcamStatus.status.value === DASHCAM_STATUS.ONLINE,
            })}
          >
            <MediaManagerSmartWitness timestamp={this.params.timestamp} />
          </MediaManagerWrapper>
        )}
        {vehicle.details.camera.provider.value === CAMERA_TYPE.XIRGO && (
          <MediaManagerWrapper
            isLoading={isLoading.value}
            currentStep={1}
            disabledSteps={this.isOffline ? [2] : []}
            nextDisabled={
              !this.isOffline &&
              !(
                Boolean(this.selectedCameraInterval?.isSelected.value) ||
                Boolean(camerasJournal.customInterval.isSelected.value)
              )
            }
            nextPath={this.isOffline ? this.thirdStepPath : this.secondStepPath}
          >
            <MediaManagerXirgo timestamp={this.params.timestamp} />
          </MediaManagerWrapper>
        )}
      </>
    );
  }
}

export default RequestMediaStep1;
