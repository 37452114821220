import React from 'react';
import { observer } from 'mobx-react';
import ConfirmationModal from 'components/Admin/ConfirmationModal';
import type { IAlert } from 'models/Alerts/IAlert';

import './styles.scss';

interface IProps {
  isOpen: boolean;
  alert: IAlert;
  onClose: () => void;
}

const DeleteAlertModal: React.FC<IProps> = ({ isOpen, onClose, alert }) => {
  const handleSubmit = async () => {
    if (alert) {
      await alert.destroy();
      onClose();
    }
  };

  return (
    <ConfirmationModal
      applyButtonTitle="Yes, Delete"
      cancelButtonTitle="No, Cancel"
      isOpen={isOpen}
      className="DeleteAlertModal"
      onCancel={onClose}
      onSubmit={handleSubmit}
      title={`Delete ${alert ? alert.type : ''} Alert?`}
      contentStyles={{ padding: '0 20px', backgroundColor: '#fff' }}
      withoutCloseIcon
    >
      <div className="DeleteAlertModal-content">
        <p className="DeleteAlertModal-text DeleteAlertModal-text--top">
          Deleting this Alert will permanently remove it from the system and it will no longer be accessible.
        </p>
        <p className="DeleteAlertModal-text DeleteAlertModal-text--bottom">Do you want to continue?</p>
      </div>
    </ConfirmationModal>
  );
};

export default observer(DeleteAlertModal);
