import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';

import type { DevicesStore, MapStore, PersistenceStore } from 'stores';

import GroupsSelector from 'components/SubNav/GroupsSelector';
import type { Group } from 'models';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

import './styles.scss';

interface IProps {
  short?: boolean;
  devicesStore?: DevicesStore;
  onChange?: (group: Group) => void;
  persistenceStore?: PersistenceStore;
  mapStore?: MapStore;
}

@inject(({ devicesMapStore: { devicesStore, mapStore }, persistenceStore }) => ({
  devicesStore,
  mapStore,
  persistenceStore,
}))
@observer
class GroupsSelectorContainer extends Component<IProps> {
  handleChange = (group: Group) => {
    const {
      devicesStore: { groups },
      mapStore: { recenterToFleet },
      persistenceStore: { updateSelectedGroup },
    } = this.props;

    EventsBus.get().once(APP_EVENTS.DEVICES.GET, recenterToFleet);

    groups.setSelected(group);
    updateSelectedGroup({
      label: group.displayName,
      value: group.groupId,
    });
    EventsBus.get().trigger(APP_EVENTS.GROUPS.GROUP.CHANGED);
  };

  get persistedDevice() {
    const {
      persistenceStore: { selectedGroup },
      devicesStore: { groups },
    } = this.props;

    if (selectedGroup) {
      const group = groups.findGroupById(selectedGroup.value);

      if (group && groups.selected && group.groupId !== groups.selected.groupId) {
        groups.setSelected(group);
      } else if (group && !groups.selected) {
        groups.setSelected(group);
      }

      return group;
    }
    return undefined;
  }

  render() {
    const {
      short,
      devicesStore: { groups },
    } = this.props;

    const groupCN = classNames({
      'GroupsSelector--short': short,
    });

    return (
      <Fragment>
        <GroupsSelector
          className={groupCN}
          cnModificator="subNav"
          list={groups.items}
          setSelectedGroup={this.handleChange}
          selectedGroup={this.persistedDevice || groups.selected}
          withAll
        />
      </Fragment>
    );
  }
}

export default GroupsSelectorContainer;
