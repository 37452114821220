import { observable, action, computed, reaction } from 'mobx';
import { repositoryService } from 'services';

import isBoolean from 'lodash/isBoolean';
import {
  formatDecimalNumber,
  formatNumber,
  getBearing,
  getCenterOfGeozone,
  getDestination,
  getDistance,
  getLatLng,
  getMidPoint,
  getPoint,
  getPolygonFromPoint,
} from '../utils';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type { Units } from '@turf/helpers';
import { DATE_TIME_FORMATS, DEFAULT_GEOZONE_RADIUS, MI_TO_KM } from 'config';
import { LoggerInfo } from './General';
import { reduceDecimals } from '../utils/numbers';

export class Geozone {
  initialCoordinates: Geozone.IGeozoneCoordinates;
  initialType: Geozone.geozoneType;
  // @observable autoNotify: boolean;
  @observable arrivalZone: boolean;
  @observable color?: Geozone.IGeozoneColor;
  @observable coordinates: Geozone.IGeozoneCoordinates;
  @observable departureZone: boolean;
  @observable description: string;
  @observable dispatchMarkerAddress?: string;
  @observable dispatchMarkerLatitude?: number;
  @observable dispatchMarkerLongitude?: number;
  @observable geozoneId: string;
  @observable groupDescription: string;
  @observable groupIdGts: string;
  @observable hideOnMaps: boolean;
  @observable id: number;
  @observable isActive: boolean;
  @observable name: string;
  @observable notes: string;
  @observable pushPin: string;
  @observable radius: number;
  @observable units: Units = 'meters';
  @observable reverseGeocode: boolean;
  @observable speedLimit: number;
  @observable tag: string;
  @observable type: Geozone.geozoneType;
  loggerInfo: LoggerInfo;

  @observable bearing: number = 90;
  @observable hoveredMarkerIndex: number = null;
  @observable hoveredDispatchMarker: boolean = false;

  @observable currentCircleMarkerPosition: Locations.ILocation = { lat: 0, lng: 0 };

  repositoryNotificationSMS: IEntityRepository;
  repositoryGeozone: IRepository;

  constructor(geozone: Geozone.IServerGeozone) {
    // this.autoNotify = geozone.autoNotify;
    this.arrivalZone = geozone.arrivalZone;
    this.color = geozone.color
      ? geozone.color
      : {
          id: 3,
          name: 'Dark Blue',
          hex: '#0154A0',
        };
    this.coordinates = geozone.coordinates.reduce((acc, { index, latitude: lat, longitude: lng }) => {
      return { ...acc, [index]: { lat, lng } };
    }, {});
    this.initialCoordinates = { ...this.coordinates };
    this.departureZone = geozone.departureZone;
    this.description = geozone.description;
    this.dispatchMarkerAddress = geozone.dispatchMarkerAddress;
    this.dispatchMarkerLatitude = reduceDecimals(geozone.dispatchMarkerLatitude);
    this.dispatchMarkerLongitude = reduceDecimals(geozone.dispatchMarkerLongitude);
    this.geozoneId = geozone.geozoneId;
    this.groupDescription = geozone.groupDescription;
    this.groupIdGts = geozone.groupIdGts;
    this.hideOnMaps = geozone.hideOnMaps;
    this.id = geozone.id;
    this.isActive = geozone.isActive;
    this.name = geozone.name;
    this.notes = geozone.notes;
    this.pushPin = geozone.pushPin;
    this.speedLimit = geozone.speedLimit;
    this.radius = geozone.radius;
    this.reverseGeocode = geozone.reverseGeocode;
    this.tag = geozone.tag;
    this.type = geozone.type.toLowerCase() as Geozone.geozoneType;
    this.loggerInfo = new LoggerInfo(
      {
        creationTime: geozone.creationTime,
        createdBy: geozone.createdBy,
        lastUpdateTime: geozone.lastUpdateTime,
        updatedBy: geozone.updatedBy,
      },
      DATE_TIME_FORMATS.adminVehicleSettings
    );
    this.initialType = this.type;
    this.repositoryNotificationSMS = repositoryService.get('notifications').entity('sms');
    this.repositoryGeozone = repositoryService.get('geozones');

    reaction(
      () => this.type,
      () => {
        if (this.type === 'circle') {
          this.setDefaultCircle();
        } else {
          this.setDefaultPolygon();
        }
      },
      { name: 'Set new coordinates when type is changed' }
    );

    reaction(
      () => this.coordinates,
      () => {
        if (this.type === this.initialType) {
          this.setInitialCoordinates(this.coordinates);
        }
      },
      { name: 'Cache coordinates when user change it' }
    );
  }

  @action setHoveredMarkerIndex = (index: number = null) => (this.hoveredMarkerIndex = index);

  @action setHoveredDispatchMarker = (hovered: boolean) => (this.hoveredDispatchMarker = hovered);

  @action setInitialCoordinates = (coordinates) => (this.initialCoordinates = coordinates);

  @action setDefaultCircle = () => {
    if (this.type !== this.initialType) {
      const center = getCenterOfGeozone(this.formatterCoordinates);
      this.setCoordinates({ 0: { ...center } });
      this.setRadius(DEFAULT_GEOZONE_RADIUS);
    } else {
      this.setCoordinates(this.initialCoordinates);
    }
  };

  @action setDefaultPolygon = () => {
    if (this.type !== this.initialType) {
      const center = this.formatterCoordinates[0];
      const coords = getPolygonFromPoint(center, this.radius);
      this.setCoordinates(coords.reduce((acc, value, index) => ({ ...acc, [index]: value }), {}));
    } else {
      this.setCoordinates(this.initialCoordinates);
    }
  };

  @computed get formattedRadius() {
    const radius = this.units === 'miles' ? (this.radius * MI_TO_KM) / 1000 : this.radius;

    return this.units === 'miles' ? formatDecimalNumber(radius, 2) : formatNumber(radius);
  }

  @computed get formatterCoordinates(): Locations.ILocation[] {
    return Object.entries(this.coordinates).map(([_, value]) => value);
  }

  @computed get centerOfMass(): Locations.ILocation {
    if (this.formatterCoordinates.length) {
      return this.type === 'circle' ? this.formatterCoordinates[0] : getCenterOfGeozone(this.formatterCoordinates);
    } else {
      return { lat: 0, lng: 0 };
    }
  }

  @computed get dispatchMarkerPosition() {
    return {
      lat: this.dispatchMarkerLatitude,
      lng: this.dispatchMarkerLongitude,
    };
  }

  @computed get dispatchMarkerPositionModel() {
    return {
      address: this.dispatchMarkerAddress,
      latitude: this.dispatchMarkerLatitude,
      longitude: this.dispatchMarkerLongitude,
    };
  }

  @computed get dispatchMarkerPositionString() {
    if (this.dispatchMarkerAddress) {
      return this.dispatchMarkerAddress;
    } else {
      if (this.dispatchMarkerLatitude && this.dispatchMarkerLongitude) {
        return `${reduceDecimals(this.dispatchMarkerLatitude)},${reduceDecimals(this.dispatchMarkerLongitude)}`;
      } else {
        return '';
      }
    }
  }

  @action handleRadiusChange = (args) => {
    const newPosition = getLatLng(args.latLng);
    const from = getPoint(this.coordinates[0]);
    const to = getPoint(newPosition);
    const newBearing = getBearing(from, to);
    const dist = getDistance(from, to, this.units);
    this.currentCircleMarkerPosition = newPosition;
    this.setBearing(newBearing);
    this.setRadius(dist);
  };

  @action handleCenterMarkerDrag = (args) => {
    const newPosition = getLatLng(args.latLng);
    this.setCoordinate({ index: 0, ...newPosition });
  };

  @computed get isMiles() {
    return this.units === 'miles';
  }

  @computed get radiusLimit() {
    return this.isMiles ? 250 : 400000;
  }

  @computed get circleMarker() {
    if (this.currentCircleMarkerPosition) {
      const point = getPoint(this.coordinates[0] || { lat: 0, lng: 0 });
      const dest = getDestination(point, this.radius, this.bearing /*, this.units*/);
      const lat = reduceDecimals(dest.geometry.coordinates[1]);
      const lng = reduceDecimals(dest.geometry.coordinates[0]);
      return { lat, lng };
    }
  }

  @action setRadius = (value) => {
    const radius = value <= this.radiusLimit ? value : this.radiusLimit;
    this.radius = this.isMiles ? (radius / MI_TO_KM) * 1000 : radius;
  };

  @action setBearing = (bearing: number) => (this.bearing = bearing);

  @action setCoordinate = ({ index, lat, lng }) => {
    const { [index]: value, ...rest } = this.coordinates;
    this.coordinates = { ...rest, [index]: { lat: reduceDecimals(lat), lng: reduceDecimals(lng) } };
  };

  @action setCoordinates = (coordinates) => (this.coordinates = coordinates);

  @action removeMarker = (orderNumber) => {
    const { [orderNumber]: value, ...rest } = this.coordinates;
    const reducedCoordinates = Object.entries(rest).reduce((acc, [, value], index) => ({ [index]: value, ...acc }), {});
    this.setCoordinates(reducedCoordinates);
  };

  @action addPointByNumber = (numberCoord, point) => {
    const arr = Object.entries(this.coordinates).map(([_, value]) => value);
    const firstStack = arr.slice(0, numberCoord - 1);
    const lastStack = arr.slice(numberCoord - 1);
    const newArr = [...firstStack, point, ...lastStack];
    this.coordinates = newArr.reduce((acc, value, index) => ({ ...acc, [index]: value }), {});
  };

  @action addPoint = () => {
    const length = this.formatterCoordinates.length;
    const start = this.formatterCoordinates[0];
    const end = this.formatterCoordinates[length - 1];
    const { lat, lng } = getMidPoint(start, end);
    this.setCoordinate({ index: length, lat, lng });
  };

  @action setUnits = (units) => (this.units = units);

  @action setDispatchMarkerAddress = (dispatchMarkerAddress) => (this.dispatchMarkerAddress = dispatchMarkerAddress);

  @action setDispatchMarkerLatitude = (dispatchMarkerLatitude) =>
    (this.dispatchMarkerLatitude = dispatchMarkerLatitude);

  @action setDispatchMarkerLongitude = (dispatchMarkerLongitude) =>
    (this.dispatchMarkerLongitude = dispatchMarkerLongitude);

  @action setDispatchMarkerLatLng = ({ lat, lng }) => {
    this.setDispatchMarkerAddress('');
    this.setDispatchMarkerLatitude(lat);
    this.setDispatchMarkerLongitude(lng);
  };

  @action setGeozoneName = (name: string) => (this.name = name);

  @action setGeozoneShape = (shape) => (this.type = shape);

  @action setGeozoneColor = (color: Geozone.IGeozoneColor) => (this.color = color);

  @action setGeozoneTag = (tag: string) => (this.tag = tag);

  @action setGeozoneGroupId = (groupId: string) => (this.groupIdGts = groupId);

  @action setGeozoneActive = (isActive?: boolean) => (this.isActive = isBoolean(isActive) ? isActive : !this.isActive);

  @action setGeozoneSpeedLimit = (speedLimit: number) => (this.speedLimit = speedLimit);

  @action setGeozoneOverride = (isOverride?: boolean) =>
    (this.reverseGeocode = isBoolean(isOverride) ? isOverride : !this.reverseGeocode);

  @action setGeozoneHideOnMaps = (isHide?: boolean) =>
    (this.hideOnMaps = isBoolean(isHide) ? isHide : !this.hideOnMaps);

  @action setGeozoneArrival = (isArrive?: boolean) =>
    (this.arrivalZone = isBoolean(isArrive) ? isArrive : !this.arrivalZone);

  @action setGeozoneDeparture = (isDepart?: boolean) =>
    (this.departureZone = isBoolean(isDepart) ? isDepart : !this.departureZone);

  @action setGeozoneNotes = (note: string) => (this.notes = note);

  @action shareGeozoneLocation = async ({ phone, message }: { async?: boolean; phone: string; message: string }) => {
    await this.repositoryNotificationSMS.create({ async: false, phone, message });
  };

  @computed get colorId() {
    return this.color.id;
  }

  getServerCoordinates(coordinates: Geozone.IGeozoneCoordinates) {
    return Object.entries(coordinates).map(([key, { lat, lng }]) => ({
      index: key,
      latitude: lat,
      longitude: lng,
    }));
  }

  getFieldValue(field: string) {
    let value;

    switch (field) {
      case 'name':
        value = this.name;
        break;
      case 'type':
        value = this.type.toUpperCase();
        break;
      case 'color':
        value = this.color;
        break;
      case 'colorId':
        value = this.colorId;
        break;
      case 'tag':
        value = this.tag;
        break;
      case 'groupIdGts':
        value = this.groupIdGts === 'all' ? '' : this.groupIdGts;
        break;
      case 'isActive':
        value = this.isActive;
        break;
      case 'speedLimit':
        value = this.speedLimit;
        break;
      case 'reverseGeocode':
        value = this.reverseGeocode;
        break;
      case 'hideOnMaps':
        value = this.hideOnMaps;
        break;
      case 'arrivalZone':
        value = this.arrivalZone;
        break;
      case 'dispatchMarkerAddress':
        value = this.dispatchMarkerAddress;
        break;
      case 'dispatchMarkerLatitude':
        value = this.dispatchMarkerLatitude;
        break;
      case 'dispatchMarkerLongitude':
        value = this.dispatchMarkerLongitude;
        break;
      case 'departureZone':
        value = this.departureZone;
        break;
      case 'notes':
        value = this.notes;
        break;
      case 'radius':
        value = this.radius;
        break;
      case 'coordinates':
        value = this.getServerCoordinates(this.coordinates);
        break;
    }

    return value;
  }

  @action updateGeozone = async (keys: string[]) => {
    const items = keys
      .map((key) => {
        const keyToUse = key === 'color' ? 'colorId' : key;

        return {
          key: keyToUse,
          value: this.getFieldValue(keyToUse),
        };
      })
      .filter((item) => item.hasOwnProperty('value'));

    if (items.length) {
      const data = {
        id: this.id,
        ...{
          ...items.reduce((prev, next) => {
            return {
              ...prev,
              [next.key]: next.value,
            };
          }, {}),
        },
      };

      await this.repositoryGeozone.patch(data);
    } else {
      return Promise.reject(`Unknown field`);
    }
  };
}

export default Geozone;
