import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import noop from 'lodash/noop';
import { setJSONItemToStorage, getJSONItemFromStorage, getFormattedTime } from 'utils';
import validateAccessLevel from 'stores/acl/validator';

import {
  STORAGE_ITEMS,
  SCHEDULED_REPORTS_EDIT,
  SCHEDULED_REPORTS_DELETE,
  SCHEDULED_REPORTS_TABLE_COLUMNS,
  SCHEDULED_REPORTS_TABLE,
  SCHEDULED_REPORTS_CREATE,
  DATE_TIME_FORMATS,
} from 'config';

import UserAccessLevelComponent from 'stores/acl/UserAccessLevelComponent';
import type { IScheduledReport } from 'models/ScheduledReport/IScheduledReport';
import type { ScheduledReportsStore } from 'stores/ScheduledReports';
import type { ITableScheduledReport, TimeStore } from 'stores';

import Button from 'components/Button';
import CustomTable from 'components/Table/CustomTable';
import TableActions from 'components/Table/Actions';
import TableRecipients from 'components/Table/TableRecipients';
import ReportTableScheduledReportName from 'components/Reports/ReportTable/ReportTableScheduledReportName';
import ReportTableScheduledInterval from 'components/Reports/ReportTable/ReportTableScheduledInterval';
import ReportTableScheduledAppliesTo from 'components/Reports/ReportTable/ReportTableScheduledAppliesTo';
import ReportTableScheduledToggle from 'components/Reports/ReportTable/ReportTableScheduledToggle';
import ScheduledReportDeleteModal from 'components/Reports/ScheduledReportDeleteModal';
import InfoTooltip from 'components/InfoTooltip';

import type { ColumnsType } from 'antd/es/table';

import './styles.scss';

interface IProps {
  scheduledReportsStore?: ScheduledReportsStore;
  handleCreate: () => void;
  handleEdit: (report: ITableScheduledReport) => void;
  handleDuplicate: (report: ITableScheduledReport) => void;
  timeStore?: TimeStore;
}

interface IState {
  isFilterOpen: boolean;
  isDeleteModalOpen: boolean;
  deleteReport: IScheduledReport;
}

const editColumnsMap = {
  [SCHEDULED_REPORTS_TABLE_COLUMNS.NAME]: {
    text: 'Scheduled Report Name',
    isDisabled: true,
  },
  [SCHEDULED_REPORTS_TABLE_COLUMNS.TYPE]: {
    text: 'Report',
    isDisabled: false,
  },
  [SCHEDULED_REPORTS_TABLE_COLUMNS.INTERVAL]: {
    text: 'Schedule',
    isDisabled: false,
  },
  [SCHEDULED_REPORTS_TABLE_COLUMNS.APPLIES]: {
    text: 'Applies to',
    isDisabled: false,
  },
  [SCHEDULED_REPORTS_TABLE_COLUMNS.RECIPIENTS]: {
    text: 'Recipients',
    isDisabled: false,
  },
  [SCHEDULED_REPORTS_TABLE_COLUMNS.ACTIVE]: {
    text: 'Active',
    isDisabled: false,
  },
  [SCHEDULED_REPORTS_TABLE_COLUMNS.CREATED_BY]: {
    text: 'Created By',
    isDisabled: false,
  },
  [SCHEDULED_REPORTS_TABLE_COLUMNS.CREATION_TIME]: {
    text: 'Time Created',
    isDisabled: false,
  },
  [SCHEDULED_REPORTS_TABLE_COLUMNS.UPDATED_BY]: {
    text: 'Modified By',
    isDisabled: false,
  },
  [SCHEDULED_REPORTS_TABLE_COLUMNS.LAST_UPDATE_TIME]: {
    text: 'Last Modified',
    isDisabled: false,
  },
};

@inject(({ scheduledReportsStore, timeStore }) => ({ scheduledReportsStore, timeStore }))
@observer
class ScheduledReportsTable extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isFilterOpen: getJSONItemFromStorage(STORAGE_ITEMS.reports.scheduled.filter, true),
      isDeleteModalOpen: false,
      deleteReport: null,
    };
  }

  getColumns = (): ColumnsType<IScheduledReport> => {
    const {
      scheduledReportsStore: {
        columns,
        sortedColumn: { field, order },
      },
      timeStore: { sessionTimezone },
    } = this.props;

    const allColumns = [
      {
        title: 'Scheduled Report Name',
        dataIndex: SCHEDULED_REPORTS_TABLE_COLUMNS.NAME,
        defaultSortOrder: field === SCHEDULED_REPORTS_TABLE_COLUMNS.NAME ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        render: (_, report) => (
          <Button
            title={report.scheduledReportName}
            className="Button--fixedWidth Button--link"
            onClick={() => this.editScheduledReport(report)}
          />
        ),
        width: 230,
        fixed: true,
      },
      {
        title: 'Report',
        dataIndex: SCHEDULED_REPORTS_TABLE_COLUMNS.TYPE,
        defaultSortOrder: field === SCHEDULED_REPORTS_TABLE_COLUMNS.TYPE ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
        render: (_, { reportName }) => <ReportTableScheduledReportName name={reportName} />,
      },
      {
        title: 'Schedule',
        dataIndex: SCHEDULED_REPORTS_TABLE_COLUMNS.INTERVAL,
        defaultSortOrder: field === SCHEDULED_REPORTS_TABLE_COLUMNS.INTERVAL ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
        render: (_, { interval }) => (
          <ReportTableScheduledInterval name={interval.name} description={interval.description} />
        ),
      },
      {
        title: 'Applies to',
        dataIndex: SCHEDULED_REPORTS_TABLE_COLUMNS.APPLIES,
        defaultSortOrder: field === SCHEDULED_REPORTS_TABLE_COLUMNS.APPLIES ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
        render: (_, { appliesTo }) => <ReportTableScheduledAppliesTo appliesTo={appliesTo} />,
        hiddenSearch: true,
      },
      {
        title: 'Recipients',
        dataIndex: SCHEDULED_REPORTS_TABLE_COLUMNS.RECIPIENTS,
        defaultSortOrder: field === SCHEDULED_REPORTS_TABLE_COLUMNS.RECIPIENTS ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: noop,
        width: 180,
        render: (_, { recipients }) => (
          <TableRecipients items={recipients} className="TableScheduledReportRecipients" />
        ),
      },
      {
        title: 'Active',
        dataIndex: SCHEDULED_REPORTS_TABLE_COLUMNS.ACTIVE,
        render: (_, { scheduledReportName, isActive, toggleIsActive, update }) => (
          <ReportTableScheduledToggle
            reportName={scheduledReportName}
            isActive={isActive}
            toggle={toggleIsActive}
            update={update}
          />
        ),
        className: 'ant-table-cell--active',
        width: 120,
        hiddenSearch: true,
      },
      {
        title: 'Created By',
        dataIndex: SCHEDULED_REPORTS_TABLE_COLUMNS.CREATED_BY,
        width: 180,
        hiddenSearch: true,
        className: 'ant-table-cell--no-filter',
      },
      {
        title: 'Time Created',
        dataIndex: SCHEDULED_REPORTS_TABLE_COLUMNS.CREATION_TIME,
        width: 180,
        hiddenSearch: true,
        className: 'ant-table-cell--no-filter',
        render: (value) => (
          <>{value ? getFormattedTime(value, DATE_TIME_FORMATS.adminVehicleSettings, sessionTimezone) : '-'}</>
        ),
      },
      {
        title: 'Modified By',
        dataIndex: SCHEDULED_REPORTS_TABLE_COLUMNS.UPDATED_BY,
        width: 180,
        hiddenSearch: true,
        className: 'ant-table-cell--no-filter',
      },
      {
        title: 'Last Modified',
        dataIndex: SCHEDULED_REPORTS_TABLE_COLUMNS.LAST_UPDATE_TIME,
        width: 180,
        hiddenSearch: true,
        className: 'ant-table-cell--no-filter',
        render: (value) => (
          <>{value ? getFormattedTime(value, DATE_TIME_FORMATS.adminVehicleSettings, sessionTimezone) : '-'}</>
        ),
      },
    ];

    const filteredColumns = [];

    columns.forEach((column) => {
      if (column.isSelected) {
        filteredColumns.push(allColumns.find((col) => col.dataIndex === column.value));
      }
    });

    return [
      ...filteredColumns,
      {
        title: 'Actions',
        dataIndex: 'actions',
        width: 120,
        className: 'ant-table-cell--actions',
        render: (_, report) => {
          const items = [
            validateAccessLevel([SCHEDULED_REPORTS_EDIT]) && {
              text: 'Edit',
              onClick: () => this.editScheduledReport(report),
            },
            validateAccessLevel([SCHEDULED_REPORTS_EDIT]) && {
              text: 'Duplicate',
              onClick: () => this.duplicateScheduledReport(report),
            },
            validateAccessLevel([SCHEDULED_REPORTS_DELETE]) && {
              text: 'Delete',
              onClick: () => this.deleteScheduledReport(report),
            },
          ].filter((item) => item);

          return <TableActions items={items} />;
        },
      },
    ];
  };

  get isShowHeader() {
    const {
      scheduledReportsStore: { hasActiveSearch, total, isSearchChanged },
    } = this.props;

    return isSearchChanged || hasActiveSearch || Boolean(total);
  }

  editScheduledReport = (report) => {
    const { handleEdit } = this.props;
    handleEdit(report);
  };

  duplicateScheduledReport = (report) => {
    const { handleDuplicate } = this.props;
    handleDuplicate(report);
  };

  deleteScheduledReport = (report) => {
    this.setState({ isDeleteModalOpen: true, deleteReport: report });
  };

  handleOpenFilter = (isFilterOpen: boolean) => {
    this.setState({ isFilterOpen });
    setJSONItemToStorage(STORAGE_ITEMS.reports.scheduled.filter, isFilterOpen);
  };

  handleCloseDeleteModal = () => {
    this.setState({ isDeleteModalOpen: false, deleteReport: null });
  };

  render() {
    if (!this.props.scheduledReportsStore) {
      return null;
    }

    const {
      scheduledReportsStore: {
        dataSource,
        getData,
        sortData,
        pagination,
        total,
        reportScheduledReports,
        setSearchColumn,
        setPagination,
        setSortedColumn,
        setColumns,
        searchColumns,
        searchData,
        columns,
        isUpdated,
        isCreated,
        isDeleted,
        // hasActiveSearch,
      },
      handleCreate,
    } = this.props;
    const { isFilterOpen, isDeleteModalOpen, deleteReport } = this.state;
    // const isShowHeader = hasActiveSearch || Boolean(total);

    return (
      <UserAccessLevelComponent requiredAccessLevel={[SCHEDULED_REPORTS_TABLE]}>
        <CustomTable<SCHEDULED_REPORTS_TABLE_COLUMNS, IScheduledReport>
          className="TableScheduledReportContent"
          editColumnsMap={editColumnsMap}
          columns={this.isShowHeader ? this.getColumns() : []}
          dataSource={dataSource}
          getData={getData}
          loading={reportScheduledReports.getState.loading}
          onColumnsChange={setColumns}
          onColumnsSearch={setSearchColumn}
          onColumnSort={setSortedColumn}
          isFilterActive={isFilterOpen}
          onFilterOpen={this.handleOpenFilter}
          sortedColumn={sortData}
          searchColumns={searchColumns}
          searchParams={searchData}
          selectedColumns={toJS(columns)}
          onPaginationChange={setPagination}
          pagination={pagination}
          total={total}
          triggerUpdate={isUpdated.value || isCreated.value || isDeleted.value}
          emptyText={
            this.isShowHeader ? (
              'No Results Found'
            ) : (
              <div className="TableScheduledReportHeader-noResults">
                Scheduled Reports will appear here.
                <UserAccessLevelComponent requiredAccessLevel={[SCHEDULED_REPORTS_CREATE]}>
                  <span className="TableScheduledReportHeader-noResults--create">
                    Click <Button title="Schedule a Report" className="Button--link" onClick={handleCreate} /> to get
                    started.
                  </span>
                </UserAccessLevelComponent>
              </div>
            )
          }
          showHeader={this.isShowHeader}
          totalTitle={
            total ? (
              <div className="TableScheduledReportHeader">
                {total} Scheduled Reports
                <InfoTooltip className="TableScheduledReportHeader-info">
                  Scheduled Reports are company wide. Deleting or making modifications will affect them for all users
                  within your company.
                </InfoTooltip>
              </div>
            ) : null
          }
          withActions={this.isShowHeader}
          withFilter={this.isShowHeader}
        />
        <ScheduledReportDeleteModal
          isOpen={isDeleteModalOpen}
          report={deleteReport}
          onClose={this.handleCloseDeleteModal}
        />
      </UserAccessLevelComponent>
    );
  }
}

export default ScheduledReportsTable;
