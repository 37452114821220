import { SimpleField } from 'models/Fields';
import type { ISortColumn } from './ISortColumn';

class TableSort {
  field: SimpleField<string>;
  order: SimpleField<'ascend' | 'descend'>;

  constructor() {
    this.field = new SimpleField('');
    this.order = new SimpleField('ascend');
  }

  set = (sortColumn: ISortColumn) => {
    this.field.set(sortColumn.field);
    this.order.set(sortColumn.order);
  };
}

export default TableSort;
