import { observable } from 'mobx';
import ItemRegistry from 'models/EventRegistry/ItemRegistry';
import RegistryCode from 'models/EventRegistry/RegistryCode';
import type Event from 'models/Event';
import FilteredRegistry from 'models/EventRegistry/FilteredRegistry';
import { STORAGE_ITEMS } from 'config';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';
import FiltersOrder from 'models/EventRegistry/FiltersOrder';

interface IRegisterItems {
  [key: string]: RegistryCode;
}

export class EventsRegistryStore {
  @observable initialItems: ItemRegistry[] = [];
  @observable registerItems: IRegisterItems = {};
  @observable settings: FilteredRegistry;
  @observable devices: FilteredRegistry = null;
  filtersOrder: FiltersOrder;
  repositoryEvents: IRepository;
  repositoryEventsMetadata: IEntityRepository;

  constructor() {
    this.repositoryEvents = repositoryService.get('events');
    this.repositoryEventsMetadata = this.repositoryEvents.entity('metadata');
    this.filtersOrder = new FiltersOrder(this.repositoryEventsMetadata);
  }

  public fetch = async () => {
    const metadata = await this.repositoryEventsMetadata.get();

    await this.filtersOrder.fetch();

    if (metadata.items) {
      metadata.items.forEach((item) => {
        const itemRegistry = new ItemRegistry(item);

        this.registerItems[item.code] = this.registerItems[item.code] || new RegistryCode();

        if (item.assetId) {
          this.registerItems[item.code].asset[item.assetId] = itemRegistry;
        }

        if (item.accountId) {
          this.registerItems[item.code].account[item.accountId] = itemRegistry;
        }

        this.registerItems[item.code].default = itemRegistry;

        this.initialItems.push(itemRegistry);
      });
    }

    this.settings = new FilteredRegistry(
      this.initialItems,
      'settingsLabel',
      'defaultOn',
      STORAGE_ITEMS.wayPoints
    ).initialize();
    this.devices = new FilteredRegistry(
      this.initialItems,
      'filterLabel',
      null,
      STORAGE_ITEMS.devicesStatuses
    ).initialize();
  };

  public register = (event: Event, assetId: number, accountIdCP: number): Event => {
    if (this.registerItems.hasOwnProperty(event.eventStatusCode)) {
      const registryByCode = this.registerItems[event.eventStatusCode];
      let itemRegistry = registryByCode.default;

      if (registryByCode.asset.hasOwnProperty(assetId)) {
        itemRegistry = registryByCode.asset[assetId];
      } else if (registryByCode.account.hasOwnProperty(accountIdCP)) {
        itemRegistry = registryByCode.account[accountIdCP];
      }

      event.setMetadata(itemRegistry);
    } else {
      // send error message that we can't find event code in register
    }

    return event;
  };

  getColorById(id: number) {
    const color = this.registerItems[id]?.default?.attributes?.iconColor;
    return color && `#${color}`;
  }
}

export default new EventsRegistryStore();
