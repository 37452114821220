import { DATE_TIME_FORMATS } from 'config';
import TableDownload from 'models/Tables/TableDownload';
import timeStore from 'stores/TimeStore';
import { getFormattedTime } from 'utils';
import type FrequencyCell from '../Representation/FrequencyCell';
import type MaintenanceCompletedTable from '../Tables/CompletedTable';

class MaintenanceCompletedDownload extends TableDownload<MaintenanceCompletedTable> {
  private mapNameHeader = {
    taskName: 'Task Name',
    vehicleName: 'Vehicle Name',
    servicedOn: 'Serviced On',
    odoAtService: 'Odometer At Service',
    engHoursAtService: 'Engine Hours At Service',
    serviceType: 'Service Type',
    frequency: 'Frequency',
    markAsCompletedOn: 'Marked As Complete On',
    markAsCompletedBy: 'Marked As Complete By',
    modifiedOn: 'Modified On',
    modifiedBy: 'Modified By',
    totalCost: 'Total Cost',
  };

  private extendedFields = {
    frequency: {
      get(value: FrequencyCell) {
        return `${value.isRepeat ? 'Repeat' : 'One time'} ${value.isRepeat && value.text ? value.text : ''}`;
      },
    },
    servicedOn: {
      get(value: number) {
        return value ? getFormattedTime(value, DATE_TIME_FORMATS.adminVehicleSettings, timeStore.sessionTimezone) : '-';
      },
    },
    markAsCompletedOn: {
      get(value: number) {
        return value ? getFormattedTime(value, DATE_TIME_FORMATS.adminVehicleSettings, timeStore.sessionTimezone) : '-';
      },
    },
    modifiedOn: {
      get(value: number) {
        return value ? getFormattedTime(value, DATE_TIME_FORMATS.adminVehicleSettings, timeStore.sessionTimezone) : '-';
      },
    },
  };

  protected getFile = async (): Promise<Blob> => {
    const ReportFile = await import('../../Report/ReportDownload/ReportFile');
    const reportFile = new ReportFile.default(this.format.value, {
      title: 'Completed Maintenance',
      description: '',
      tables: [{ ...this.getData(), title: null }],
    });

    const file = await reportFile.create();

    return file;
  };

  protected get fileName() {
    return 'Completed Maintenance';
  }

  private getData = () => {
    const columnsIds = this.context.columns.filter((column) => column.isSelected).map((column) => column.value);
    const completedRows = [];

    this.context.source.forEach((item) => {
      if (item.children && item.children.length) {
        completedRows.push(item);
        item.children.forEach((child) => completedRows.push(child));
      } else {
        completedRows.push(item);
      }
    });

    const rows = completedRows.map((item) => {
      return columnsIds.map((column) => {
        if (this.extendedFields.hasOwnProperty(column)) {
          return this.extendedFields[column].get(item[column]);
        }
        return item[column];
      });
    });

    return {
      columns: columnsIds.map((columnId) => this.mapNameHeader[columnId]),
      rows,
      total: [],
    };
  };
}

export default MaintenanceCompletedDownload;
