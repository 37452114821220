import React, { useState } from 'react';
import isBoolean from 'lodash/isBoolean';

import Note from 'components/Note';

import './styles.scss';

interface IProps {
  disabled?: boolean;
  note: string;
  onUpdate(text: string): void;
}

const PanelNote: React.FC<IProps> = ({ disabled, note, onUpdate }) => {
  const [isNoteEditing, setIsNoteEditing] = useState(false);

  const handleNoteEditingToggle = (value: boolean) => {
    setIsNoteEditing(isBoolean(value) ? value : !isNoteEditing);
  };

  const handleUpdateNote = (text: string) => {
    onUpdate(text);
    setIsNoteEditing(false);
  };

  const handleNoteChange = () => {
    setIsNoteEditing(true);
  };

  return (
    <div className="PanelNote">
      <Note
        activeOnFocus
        countCharactersEntered
        disabled={disabled}
        hideControls={!isNoteEditing}
        isEditing
        note={note}
        onCancel={handleNoteEditingToggle}
        onChange={handleNoteChange}
        onDelete={handleUpdateNote}
        onEditNoteStateChange={handleNoteEditingToggle}
        onSave={handleUpdateNote}
        placeholder="Note"
      />
    </div>
  );
};

export default PanelNote;
