import { RouteReplaySpeedOption } from './IRouteReplay';
import { SimpleField, ListField } from '../Fields';

class RouteReplaySpeed {
  value: SimpleField<RouteReplaySpeedOption>;
  options: ListField<RouteReplaySpeedOption>;

  constructor(
    value = RouteReplaySpeedOption.X1,
    options = [RouteReplaySpeedOption.X1, RouteReplaySpeedOption.X2, RouteReplaySpeedOption.X3]
  ) {
    this.value = new SimpleField(value);
    this.options = new ListField(options);
  }

  change = () => {
    const optionsArray = this.options.toArray();
    const index = optionsArray.indexOf(this.value.value);
    const value = index + 1 === optionsArray.length ? RouteReplaySpeedOption.X1 : optionsArray[index + 1];
    this.value.set(value);
  };

  reset = () => {
    this.value.reset();
  };
}

export default RouteReplaySpeed;
