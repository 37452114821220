import React from 'react';
import classNames from 'classnames';

import Checkbox from 'components/Checkbox';
import Tooltip from 'components/Tooltip';
import { pluralize } from 'utils';

import { FilterIcon, Geozone2Icon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  colors?: Geozone.IGeozoneColor[];
  isDisabled?: boolean;
  onChange: (id: number) => void;
  onReset?: () => void;
  selectedColorIds?: number[];
}

const GeozoneColorsFilter: React.FC<IProps> = ({
  className,
  colors,
  isDisabled,
  onChange,
  onReset,
  selectedColorIds,
}) => {
  const appliedColorsCount = selectedColorIds.length;
  const filterTitle = appliedColorsCount ? pluralize(appliedColorsCount, 'Filter') : '';
  const tooltipCn = classNames('Tooltip--geozoneColorsFilter', {
    'Tooltip--disabled': Boolean(isDisabled),
    'Tooltip--active': Boolean(appliedColorsCount),
  });
  const cn = classNames('GeozoneColorsFilter', className);

  return (
    <Tooltip
      className={tooltipCn}
      position="bottomRight"
      Icon={FilterIcon}
      name={filterTitle}
      withReset
      onReset={onReset}
      forceClose
    >
      <div className={cn}>
        <ul className="GeozoneColorsFilter-list">
          {colors.map((color) => {
            const isChecked = selectedColorIds.includes(color.id);
            const iconCn = classNames('GeozoneColorsFilter-geozoneIcon', {
              [`GeozoneColorsFilter-geozoneIcon--${color.name.toLowerCase()}`]: Boolean(color.name),
            });

            return (
              <li className="GeozoneColorsFilter-item" key={color.id}>
                <div className="GeozoneColorsFilter-checkbox">
                  <Checkbox
                    value={color.id}
                    checked={isChecked}
                    onChange={onChange}
                    label={
                      <>
                        <Geozone2Icon className={iconCn} fill={color.hex} />
                        <div className="GeozoneColorsFilter-geozoneColor">{color.name}</div>
                      </>
                    }
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Tooltip>
  );
};

export default GeozoneColorsFilter;
