import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import SearchableSelect from 'components/Select/SearchableSelect';

import type { PersistenceStore } from 'stores';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';

interface IProps {
  persistenceStore?: PersistenceStore;
  value?: Select.ISelectOption;
  handleChange?: (option: Select.ISelectOption) => void;
  placeholder?: string;
  searchPlaceholder?: string;
  emptySearchResults?: string;
  isSearchable?: boolean;
  persistChange?: boolean;
  withAll?: boolean;
  optionValueKey?: string;
  withOnlyActive?: boolean;
  getAllOptions?: boolean;
}

@inject(({ persistenceStore }) => ({ persistenceStore }))
@observer
class SearchableGeoZoneSelect extends Component<IProps> {
  static defaultProps = {
    placeholder: 'Select Geozone',
    searchPlaceholder: 'Search Geozones',
    emptySearchResults: 'No Geozones Found',
    optionValueKey: 'id',
  };

  static allOption = { label: 'All Geozones', value: 'all', priority: -1 };

  repositorySearch: IEntityRepository;

  constructor(props) {
    super(props);
    if (props.persistChange && props.persistenceStore && props.persistenceStore.selectedGeoZone && !props.value) {
      this.props.handleChange(props.persistenceStore.selectedGeoZone);
    } else if (props.withAll && !props.value?.value) {
      this.props.handleChange(SearchableGeoZoneSelect.allOption);
    }

    this.repositorySearch = repositoryService.get('geozones').entity('ids');
  }

  getData = (props: { page: number; pageSize: number; filter: string }) => {
    const { withOnlyActive, getAllOptions } = this.props;

    let pageParams = {};

    const mainParams = {
      searchValue: props.filter || '',
      onlyActive: withOnlyActive,
      order: 'ASC',
      sortBy: 'name',
    };

    if (!getAllOptions) {
      pageParams = {
        page: props.page || 0,
        pageSize: props.pageSize || 50,
      };
    }

    return this.repositorySearch.get({ ...pageParams, ...mainParams }).then((data) => {
      const totalPages = data.totalPages;
      const options = data.items.map((item) => {
        const value = String(item[this.props.optionValueKey]);
        return {
          value,
          label: item.name || value,
        };
      });

      if (!props.filter && this.props.withAll && props.page === 0) {
        options.unshift(SearchableGeoZoneSelect.allOption);
      }

      return {
        totalPages,
        options,
      };
    });
  };

  handleChange = (option: Select.ISelectOption) => {
    this.props.handleChange(option);
    if (this.props.persistChange) {
      this.props.persistenceStore.updateSelectedGeoZone({ ...option });
    }
  };

  render() {
    return (
      <SearchableSelect
        {...this.props}
        getData={this.getData}
        handleChange={this.handleChange}
        sortOptions
        timeout={100}
      />
    );
  }
}

export default SearchableGeoZoneSelect;
