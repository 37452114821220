import React from 'react';
import classnames from 'classnames';

import CollapsibleButton from 'components/CollapsibleButton';

import './styles.scss';

interface IProps {
  isExpanded: boolean;
  onClick: () => void;
}

const AdminNavigationCollapsibleButton: React.FC<IProps> = ({ isExpanded, onClick }) => {
  const cn = classnames('AdminNavigationCollapsibleButton', {
    'AdminNavigationCollapsibleButton--collapsed': !isExpanded,
  });

  return (
    <div className={cn}>
      <CollapsibleButton
        isExpanded={isExpanded}
        onClick={onClick}
        className={`AdminNavigationCollapsibleButton-button`}
      />
    </div>
  );
};

export default AdminNavigationCollapsibleButton;
