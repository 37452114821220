import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { debounce } from 'lodash';

import type { UserStore } from 'stores';

import { KEY_TYPE } from 'config';

import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

import Modal from 'components/Modal';
import Button from 'components/Button';
import AccountListItem from './AccountListItem';

import { SearchIcon, SendingSpinnner } from 'assets';

import './styles.scss';

interface IProps {
  email?: string;
  password?: string;
  open: boolean;
  sending?: boolean;
  onClose?: () => void;
  onSubmit?: ({ email, password, accountId }: { email: string; password: string; accountId: string }) => void;
  userStore?: UserStore;
}

interface IState {
  loaded: boolean;
}

@inject(({ userStore }) => ({
  userStore,
}))
@observer
class SelectAccountModal extends Component<IProps, IState> {
  repositoryAccountsSearch: IEntityRepository;

  constructor(props) {
    super(props);

    this.debouncedGetData = debounce(props.userStore.details.getAccounts.bind(this), 200);

    this.state = {
      loaded: false,
    };
  }

  async componentDidMount() {
    window.addEventListener('keyup', this.listenEscKeyPress);
    this.setState({ loaded: false });
    await this.props.userStore.details.getAccounts();
    this.setState({ loaded: true });
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.listenEscKeyPress);
  }

  listenEscKeyPress = (e) => {
    if (e.key === KEY_TYPE.ESCAPE) {
      this.props?.onClose?.();
    }
  };

  async componentDidUpdate(prevProps) {
    if (prevProps.open === false && this.props.open === true) {
      this.setState({ loaded: false });
      await this.props.userStore.details.getAccounts();
      this.setState({ loaded: true });
    }
  }

  debouncedGetData = async () => void 0;

  handleSearchChange = async ({ target: { value: filter } }) => {
    this.props.userStore.details.filter.set(filter);
    this.setState({ loaded: false });
    await this.debouncedGetData();
    this.setState({ loaded: true });
  };

  handleClose = () => {
    this.props.userStore.details.filter.set('');
    this.props.onClose?.();
  };

  hasPasswordAndEmail(props) {
    return props.password && props.email;
  }

  get hasCredentials() {
    return this.hasPasswordAndEmail(this.props);
  }

  handleSubmit = async () => {
    try {
      if (this.hasCredentials) {
        await this.props.onSubmit({
          email: this.props.email,
          password: this.props.password,
          accountId: String(this.props.userStore.details.selectedAccount.accountId.value),
        });
        this.handleClose();
      } else {
        await this.props.userStore.switchAccount(String(this.props.userStore.details.selectedAccount.accountId.value));
        this.handleClose();
        window.location.reload();
      }
    } catch (_) {
      //
    }
  };

  handleSelect = (id: number) => {
    const {
      userStore: { details },
    } = this.props;

    const selected = details.accounts.value.find((item) => item.value.accountId.value === id)?.value;

    details.selectedAccount.set(selected.contract);
  };

  render() {
    const {
      open,
      sending,
      userStore: { details },
    } = this.props;

    const { loaded } = this.state;
    const isBlocked = !details.selectedAccount?.accountId.value;

    return (
      <Modal isModalOpen={open} onClose={this.handleClose} width={450}>
        <div className="SelectAccountModal-root">
          <div className="SelectAccountModal-header">
            <h3 className="SelectAccountModal-title">Select an Account</h3>
          </div>
          <div className="SelectAccountModal-body">
            <div className="SelectAccountModal-searchContainer">
              <div className="SelectAccountModal-searchIcon">
                <SearchIcon className="SelectAccountModal-searchIconImg" width={18} height={18} />
              </div>
              <input
                type="text"
                className="SelectAccountModal-searchInput"
                placeholder="Search"
                onChange={this.handleSearchChange}
                value={details.filter.value}
              />
            </div>
            <div className="SelectAccountModal-resultsList">
              {!loaded && (
                <span className="SelectAccountModal-resultsLoading">
                  <SendingSpinnner />
                </span>
              )}
              {loaded &&
                details.accounts.value.map((item) => {
                  return (
                    <AccountListItem
                      key={item.value.accountIdGts.value}
                      id={item.value.accountId.value}
                      type={item.value.type.value}
                      name={item.value.description.value}
                      disabled={item.value.disabled.value}
                      locked={item.value.locked.value}
                      current={item.value.current.value}
                      onSelect={this.handleSelect}
                      selected={details.selectedAccount?.accountId.value === item.value.accountId.value}
                    />
                  );
                })}
              {loaded && !details.accounts.value.length && (
                <span className="SelectAccountModal-resultsEmpty">No Results Found</span>
              )}
            </div>
          </div>
          <div className="SelectAccountModal-footer">
            <Button
              disabled={isBlocked}
              title={this.hasCredentials ? 'Log In' : 'Switch Accounts'}
              onClick={this.handleSubmit}
              inline
              className="SelectAccountModal-button"
              sending={sending}
              sent={Boolean(this.hasCredentials && this.props.userStore.jwToken)}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default SelectAccountModal;
