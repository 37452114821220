import { observable, reaction } from 'mobx';
import DashcamStatusModel, { DASHCAM_STATUS } from 'models/Dashcam/DashcamStatus';
import CamerasJournal from 'models/Dashcam/MediaRequestCheckout/CamerasJournal';
import RequestMedia from 'models/Dashcam/MediaRequestCheckout/RequestMedia';
import VehicleDetails from 'models/Dashcam/MediaRequestCheckout/VehicleDetails';
import { ToggleField } from 'models/Fields';
import { CAMERA_JOURNAL_STATE, CAMERA_TYPE } from 'config';
import { repositoryService } from 'services';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

export default class MediaRequestCheckout {
  isLoading: ToggleField;
  vehicle: VehicleDetails;
  dashcamStatus: DashcamStatusModel;
  camerasJournal: CamerasJournal;
  requestMedia: RequestMedia;
  repositoryMedia: IRepository;
  repositoryMediaCameras: IEntityRepository;
  @observable repositoryMediaRequest: IEntityRepository;

  initialize = () => {
    this.isLoading = new ToggleField(false);
    this.vehicle = new VehicleDetails();
    this.dashcamStatus = new DashcamStatusModel();
    this.camerasJournal = new CamerasJournal(this);
    this.requestMedia = new RequestMedia(this);
    this.repositoryMedia = repositoryService.get('media', 'v2.0');
    this.repositoryMediaCameras = this.repositoryMedia.entity('cameras');

    reaction(
      () => this.dashcamStatus.status.value,
      (status) => {
        if (status === DASHCAM_STATUS.OFFLINE) {
          this.requestMedia.timeSegment.startTime.set(Date.now());
          this.camerasJournal.customInterval.isSelected.toggle(true);
        }
      }
    );

    return this;
  };

  fetch = async (assetId) => {
    if (assetId !== this.vehicle.details.assetId) {
      this.isLoading.toggle(true);

      await this.vehicle.fetch(assetId);
      await this.camerasJournal.fetch();

      if (this.vehicle.details.camera.provider.value === CAMERA_TYPE.SMARTWITNESS) {
        await this.dashcamStatus.fetch(this.vehicle.details.camera.id);
        this.requestMedia.cameraType.setOptions(this.dashcamStatus.cameraDetails.states);
      } else {
        this.dashcamStatus.cameraDetails.isBusy.toggle(this.camerasJournal?.cameraDetails.isBusy.value);
        this.dashcamStatus.status.value =
          !this.camerasJournal.initialList.length || this.dashcamStatus.cameraDetails.isBusy.value
            ? DASHCAM_STATUS.OFFLINE
            : DASHCAM_STATUS.ONLINE;
      }

      this.isLoading.toggle(false);
    }
  };

  request = async () => {
    const cameraId = this.vehicle.details.camera.id;
    if (!cameraId) {
      return;
    }
    this.repositoryMediaRequest = this.repositoryMediaCameras.entity(cameraId).entity('request-media');

    await this.repositoryMediaRequest.create(this.requestBody);
  };

  get requestBody() {
    const outsideCamera = this.requestMedia.cameraType.getCamera(CAMERA_JOURNAL_STATE.OUT);
    const insideCamera = this.requestMedia.cameraType.getCamera(CAMERA_JOURNAL_STATE.IN);
    const isOutsideCameraActive = outsideCamera.isAvailable.value && outsideCamera.media.isActive.value;
    const isInsideCameraActive = insideCamera.isAvailable.value && insideCamera.media.isActive.value;
    const timeSegment = this.requestMedia.timeSegment;
    const clipName = this.requestMedia.clipName.value;

    return {
      cameraTypes: [...(isOutsideCameraActive ? ['OUTSIDE'] : []), ...(isInsideCameraActive ? ['INSIDE'] : [])],
      timestamp: timeSegment.startTime.value,
      duration: timeSegment.duration.active.value / 1000,
      clipTitle: clipName,
    };
  }

  findSelectedInterval = () => {
    return this.camerasJournal.dates.model[this.camerasJournal.dates.active]?.findSelectedInterval();
  };

  preSelectCameraJournalByInterval = (startTimestamp: number, endTimestamp: number) => {
    this.camerasJournal.selectByInterval(startTimestamp, endTimestamp);

    return this;
  };

  preSelectCameraJournalByTimestamp = (startTimestamp: number) => {
    this.camerasJournal.selectByTimestamp(startTimestamp);
    return this;
  };

  preSelectRequestMediaTimeSegment = (startTimestamp: number, endTimestamp: number) => {
    this.requestMedia.timeSegment.initialize(startTimestamp, endTimestamp);

    return this;
  };

  preSelectRequestMediaCameraType = (isSetInitial = true) => {
    const selectedSegment = this.findSelectedInterval();

    this.requestMedia.cameraType.setOptions(selectedSegment?.state || CAMERA_JOURNAL_STATE.IN_OUT);

    if (isSetInitial) {
      this.requestMedia.cameraType.setInitialType();
    }

    return this;
  };

  preSelectRequestMediaStartTime = (startTimestamp: number) => {
    this.requestMedia.timeSegment.startTime.set(startTimestamp);
    return this;
  };

  preSelectRequestMediaDuration = (duration: number) => {
    this.requestMedia.timeSegment.duration.active.set(duration);
  };
}
