/*global google*/
import React from 'react';
import { StreetViewPanorama } from 'react-google-maps';

interface IProps {
  visible: boolean;
  position: Locations.ILocation | google.maps.LatLng;
  pov: google.maps.StreetViewPov;
  showZoomControl?: boolean;
  showPanControl?: boolean;
  onCloseClick: () => void;
}

const StreetViewLayer: React.FC<IProps> = ({
  visible,
  position,
  pov,
  showZoomControl = true,
  showPanControl = true,
  onCloseClick,
}) => {
  return (
    <StreetViewPanorama
      position={position}
      pov={pov}
      visible={visible}
      onCloseClick={onCloseClick}
      options={{
        enableCloseButton: true,
        zoomControl: showZoomControl,
        panControl: showPanControl,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
        panControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
      }}
    />
  );
};

export default StreetViewLayer;
