import React, { FC, useState } from 'react';
import { Formik, Form, Field /*FormikProps*/ } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import classNames from 'classnames';

import Button from 'components/Button';
import Input from 'components/Input';
import StrengthProgress from 'components/StrengthProgress';

import { EyeCrossedIcon, EyeIcon, InfoIcon, ConfirmIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  initialValues?: any;
  onCancel?: () => void;
  onSubmit?: (currentPassword: string, newPassword: string) => Promise<void>;
}

const ChangePasswordFormSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Current password is required field'),
  newPassword: Yup.string()
    .required('New password is required field')
    .matches(/^\S*$/, 'New password is incorrect')
    .min(8, 'Seems a bit short...')
    .max(30, 'Please try a shorter password')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/\d/, 'Password must contain at least one digit')
    .matches(/[!@#$%^&*=(),.?":{}|<>-]/, 'Password must contain at least one special character'),
  confirmPassword: Yup.string().test('passwords-match', `New passwords don't match`, function (value) {
    // @ts-ignore
    return this.parent.newPassword === value;
  }),
});

const ChangePasswordForm: FC<IProps> = ({ className, initialValues, onCancel, onSubmit }) => {
  const cn = classNames('ChangePasswordForm', className);
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const currentPassword = get(initialValues, 'currentPassword', '');
  const newPassword = get(initialValues, 'newPassword', '');
  const confirmPassword = get(initialValues, 'confirmPassword', '');
  const handleSubmit = ({ currentPassword, newPassword }, { setFieldError, setSubmitting }) => {
    onSubmit(currentPassword, newPassword).catch((err) => {
      setFieldError('currentPassword', err.message);
      setSubmitting(false);
    });
  };
  const renderIcon = () => {
    return isPasswordVisible ? (
      <EyeIcon fill="#6B7A99" cursor="pointer" onClick={() => setPasswordVisible(false)} />
    ) : (
      <EyeCrossedIcon fill="#6B7A99" cursor="pointer" onClick={() => setPasswordVisible(true)} />
    );
  };

  return (
    <div className={cn}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ currentPassword, newPassword, confirmPassword }}
        validationSchema={ChangePasswordFormSchema}
        render={({ errors, touched, isValid, isSubmitting, values }) => {
          const isNewPasswordMatched =
            !errors.newPassword && !errors.confirmPassword && touched.confirmPassword && values.confirmPassword;

          return (
            <Form className="ChangePasswordForm-form">
              <div className="ChangePasswordForm-formContent">
                <div className="ChangePasswordForm-fields">
                  <div className="ChangePasswordForm-field ChangePasswordForm-field--currentPassword">
                    <p className="ChangePasswordForm-label ChangePasswordForm-label--currentPassword">
                      Current password
                    </p>
                    <div className="ChangePasswordForm-input ChangePasswordForm-input--currentPassword">
                      <Field
                        component={Input}
                        className={`ChangePasswordForm-input ChangePasswordForm-input--autofill`}
                        error={Boolean(errors.currentPassword && touched.currentPassword) ? errors.currentPassword : ''}
                        icon={renderIcon()}
                        iconPosition="right"
                        name="currentPassword"
                        type={isPasswordVisible ? 'text' : 'password'}
                      />
                    </div>
                  </div>
                  <div className="ChangePasswordForm-field ChangePasswordForm-field--newPassword">
                    <p className="ChangePasswordForm-label ChangePasswordForm-label--newPassword">New password</p>
                    <div className="ChangePasswordForm-input ChangePasswordForm-input--newPassword">
                      <Field
                        component={Input}
                        error={Boolean(errors.newPassword && touched.newPassword) ? errors.newPassword : ''}
                        icon={renderIcon()}
                        iconPosition="right"
                        name="newPassword"
                        type={isPasswordVisible ? 'text' : 'password'}
                      />
                    </div>
                    <div className="ChangePasswordForm-statusIcon ChangePasswordForm-statusIcon--newPassword">
                      {isNewPasswordMatched && <ConfirmIcon fill="#4CAF50" />}
                    </div>
                  </div>
                  <div className="ChangePasswordForm-field ChangePasswordForm-field--confirmPassword">
                    <p className="ChangePasswordForm-label ChangePasswordForm-label--confirmPassword">
                      Confirm new password
                    </p>
                    <div className="ChangePasswordForm-input ChangePasswordForm-input--confirmPassword">
                      <Field
                        component={Input}
                        error={Boolean(errors.confirmPassword && touched.confirmPassword) ? errors.confirmPassword : ''}
                        icon={renderIcon()}
                        iconPosition="right"
                        name="confirmPassword"
                        type={isPasswordVisible ? 'text' : 'password'}
                      />
                    </div>
                    <div className="ChangePasswordForm-statusIcon ChangePasswordForm-statusIcon--confirmPassword">
                      {isNewPasswordMatched && <ConfirmIcon fill="#4CAF50" />}
                      {Boolean(errors.confirmPassword && touched.confirmPassword) && <InfoIcon fill="#D92424" />}
                    </div>
                  </div>
                  <div className="ChangePasswordForm-passwordStrength">
                    <StrengthProgress password={values.newPassword} strengthText="Password Strength" />
                  </div>
                </div>
              </div>

              <div className="ChangePasswordForm-buttons">
                <div className="ChangePasswordForm-cancel">
                  <Button
                    className="Button--cancel Button--cancelColorLynch"
                    onClick={onCancel}
                    title="Cancel"
                    type="button"
                  />
                </div>
                <div className="ChangePasswordForm-submit">
                  <Button className="Button--apply" disabled={!isValid || isSubmitting} title="Save" type="submit" />
                </div>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
};

export default ChangePasswordForm;
