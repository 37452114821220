import React, { useMemo } from 'react';
import Button from 'components/Button';

import './styles.scss';

type TaskType = 'repeat' | 'one_time' | 'complete';

interface IProps {
  taskName: string;
  type: TaskType;
  sending: boolean;
  onClose: () => void;
  deleteTask: () => void;
}

const MaintenanceTaskDeleteModal: React.FC<IProps> = ({ type, taskName, onClose, deleteTask, sending }) => {
  const text = useMemo(() => {
    switch (type) {
      case 'repeat':
        return 'Deleting this task will permanently remove it from the system and all notes, attachments and alerts will be deleted. This task will no longer repeat.';
      case 'one_time':
        return 'Deleting this task will permanently remove it from the system and all notes, attachments and alerts will be deleted.';
      case 'complete':
        return 'Deleting this task will permanently remove it from the system and all notes and attachments will be deleted.';
      default:
        return '';
    }
  }, [type]);

  const handleDelete = async () => {
    await deleteTask();
    onClose();
  };

  return (
    <div className="MaintenanceTaskDeleteModal">
      <div className="MaintenanceTaskDeleteModal-header">
        <h3 className="MaintenanceTaskDeleteModal-title">{`Delete ${
          type === 'repeat' ? 'Repeating ' : ''
        }'${taskName}' Task?`}</h3>
      </div>
      <div className="MaintenanceTaskDeleteModal-content">
        <div className="MaintenanceTaskDeleteModal-contentText">{text}</div>
        <div className="MaintenanceTaskDeleteModal-contentQuestion">Do you want to continue?</div>
      </div>
      <div className="MaintenanceTaskDeleteModal-navigation">
        <Button
          className="MaintenanceTaskDeleteModal-navigationCancel Button--cancel"
          onClick={onClose}
          title="No, Go back"
          inline
        />
        <Button
          className="MaintenanceTaskDeleteModal-navigationSave Button--apply"
          onClick={handleDelete}
          sending={sending}
          title="Yes, Continue"
          inline
        />
      </div>
    </div>
  );
};

export default MaintenanceTaskDeleteModal;
