import { action, computed, observable, reaction } from 'mobx';
import { filter, find, groupBy, sortBy, debounce, isEqual, orderBy } from 'lodash';
import { getPastDay, getFormattedTime, getTodayStartDate, getTodayEndDate } from 'utils';

import { DATE_TIME_FORMATS } from 'config';
import APIStoreBase from '../../APIStoreBase';
import MediaManagerFilters from './Filters';
import { repositoryService } from 'services';
import MediaEventModel from '../../../models/Media';
import timeStore from 'stores/TimeStore';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import mediaAdapter from '../MediaAdapter/MediaAdapter';

export default class MediaManagerStore {
  @observable devicesList: Device.IServerDevice[] = [];
  @observable mediaItemsList: MediaEventModel[] = [];
  @observable hoveredMediaItemTimestamp: number = null;
  @observable activeMediaItemTimestamp: number = null;
  @observable activeMediaItemTopPosition: number = null;
  @observable filters: MediaManagerFilters = null;
  @observable currentPageNum: number = 0;
  @observable totalPagesNum: number = null;
  @observable mediaItemsRequestStatus: APIStoreBase = new APIStoreBase();
  @observable pollingInterval: number = null;
  isPolling: boolean = false;
  initialDate: Filters.IDateFilterOption;
  repositoryDevices: IRepository;
  repositoryMedia: IRepository;
  repositoryMediaCameras: IEntityRepository;
  repositoryMediaList: IEntityRepository;

  constructor() {
    this.debouncedGetMediaItems = debounce(this.debouncedGetMediaItems, 0);
    this.initialDate = find(this.getFilterOptions(), ['text', 'Last 30 days']).value;
    this.filters = new MediaManagerFilters({ initialDate: this.initialDate, mediaManager: this });
    this.repositoryDevices = repositoryService.get('devices');
    this.repositoryMedia = repositoryService.get('media', 'v2.0');
    this.repositoryMediaCameras = this.repositoryMedia.entity('cameras');
    this.repositoryMediaList = this.repositoryMedia.entity('list');
    this.repositoryDevices.listenTo('error:get', () => {
      this.devicesList = [];
    });

    reaction(
      () => ({
        deviceId: this.filters.selectedDeviceId,
        date: this.filters.date,
        selectedTypes: this.filters.selectedTypes,
      }),
      () => {
        this.resetMedia();
      },
      {
        name: 'Reset media items list and pagination on filters change',
      }
    );

    reaction(
      () => ({
        selectedDevice: this.filters.selectedDeviceId,
        date: this.filters.date,
        selectedTypes: this.filters.selectedTypes,
      }),
      () => {
        if (!this.filters.selectedDeviceId) {
          return;
        }

        return this.debouncedGetMediaItems();
      },
      {
        name: 'Get media items on filters change, but make only one debounced request on page reloading',
      }
    );

    reaction(
      () => this.currentPageNum,
      () => {
        if (!this.filters.selectedDeviceId) {
          return;
        }
        this.getMediaItems();
      },
      {
        name: 'Immediate get media items on scroll',
      }
    );
  }

  private get listOfMediaRequestParams() {
    const {
      selectedDeviceId,
      selectedCameraId,
      date: { from, to },
      selectedTypes,
    } = this.filters;

    const selectedTypesMap = selectedTypes.reduce((map, current) => {
      const [key, value] = current.split('/');
      map[key] = map[key] ? `${map[key]},${value}` : value === 'expiring' ? Boolean(value) : value;

      return map;
    }, {});

    return {
      deviceId: selectedDeviceId,
      selectedCameraId,
      from,
      to,
      page: this.currentPageNum.toString(),
      ...selectedTypesMap,
    };
  }

  get selectedDevice() {
    return this.devicesList.find((device) => device.deviceId === this.filters.selectedDeviceId);
  }

  @computed get filteredDevicesList() {
    const reducedDevicesList = this.devicesList.map(({ deviceId, displayName, camera }) => ({
      deviceId,
      displayName,
      cameraId: camera?.id,
    }));
    const sortedDeviceList = sortBy(reducedDevicesList, ({ displayName }) => displayName.toLowerCase());

    return filter(sortedDeviceList, ({ displayName }) =>
      displayName.toLowerCase().includes(this.filters.query.toLowerCase())
    );
  }

  @computed get mediaItemsMap() {
    const sortedListByTs = orderBy(this.mediaItemsList, ['eventTimestamp'], ['asc']);
    const groupedListByBatchId = [];

    sortedListByTs.forEach((sortedItem, _, list) => {
      if (!sortedItem.batchId) {
        groupedListByBatchId.push([sortedItem]);
      }

      const itemsWithSameBatchId = list.filter((item) => item.batchId === sortedItem.batchId);
      const isSortedItemGrouped = groupedListByBatchId.find((item) =>
        item.find((i) => i.batchId === sortedItem.batchId)
      );

      if (!isSortedItemGrouped) {
        groupedListByBatchId.push(itemsWithSameBatchId);
      }
    });

    return groupBy(groupedListByBatchId, (mediaEvent) =>
      getFormattedTime(mediaEvent[0].eventTimestamp, DATE_TIME_FORMATS.fullMonthComaDateYear, timeStore.sessionTimezone)
    );
  }

  @computed get eventsPollingTimestampRange() {
    const lastMediaItem = this.mediaItemsList[this.mediaItemsList.length - 1];
    const from = lastMediaItem ? lastMediaItem.eventTimestamp : this.listOfMediaRequestParams.from;
    return {
      from,
    };
  }

  @action getAllDevices = async () => {
    this.devicesList = await this.repositoryDevices.get({ mediaEnabled: true });
    // this.devicesList = await mediaAdapter.devices.getAll();
  };

  @action setPollingStatus = (status: boolean) => (this.isPolling = status);

  @action startPollingMediaItems = () => {
    this.pollingInterval = setInterval(async () => {
      // skip this iteration if previous one is not finished
      // or another page is being fetched
      if (this.isPolling || this.mediaItemsRequestStatus.loading || !this.filters.selectedDeviceId) {
        return;
      }
      try {
        this.setPollingStatus(true);
        let shouldUpdate = false;
        const pageSize = (Number(this.listOfMediaRequestParams.page) + 1) * 50;
        const requestParams = { ...this.listOfMediaRequestParams, page: '0', ...this.eventsPollingTimestampRange };
        const mediaManagerData = await mediaAdapter.items.getAll({
          ...this.listOfMediaRequestParams,
          from: this.eventsPollingTimestampRange.from,
          pageSize,
        });
        /*const mediaManagerData = await this.repositoryMediaList.entity(this.listOfMediaRequestParams.deviceId).get({
          ...this.listOfMediaRequestParams,
          from: this.eventsPollingTimestampRange.from,
          pageSize,
        });*/
        mediaManagerData.pageData.map(({ eventTimestamp, status }) => {
          const currentItem = find(this.mediaItemsList, { eventTimestamp });
          if (!currentItem || currentItem.status?.toLowerCase() !== status?.toLowerCase()) {
            shouldUpdate = true;
          }
        });
        const newParams = { ...this.listOfMediaRequestParams, page: '0', ...this.eventsPollingTimestampRange };
        const isParamsChanged = isEqual(requestParams, newParams);
        // should not update the list of media when request params is changed
        if (!isParamsChanged) {
          shouldUpdate = false;
        }

        if (shouldUpdate) {
          this.mediaItemsList = mediaManagerData.pageData.map((event) => new MediaEventModel(event));
        }
        this.setPollingStatus(false);
      } catch (err) {
        this.setPollingStatus(false);
      }
    }, 5000);
  };

  @action clearPollingInterval = () => {
    clearInterval(this.pollingInterval);
    this.pollingInterval = null;
  };

  @action getMediaItems = async () => {
    this.mediaItemsRequestStatus.reset().setLoading(true);

    try {
      /*const mediaManagerData = await this.repositoryMediaList
        .entity(this.listOfMediaRequestParams.deviceId)
        .get({ ...this.listOfMediaRequestParams });*/
      const mediaManagerData = await mediaAdapter.items.getAll(this.listOfMediaRequestParams);
      const currentPageNum = mediaManagerData.currentPage;
      const newMediaItems = mediaManagerData.pageData.map((event) => new MediaEventModel(event));

      this.mediaItemsList = currentPageNum === 0 ? newMediaItems : this.mediaItemsList.concat(newMediaItems);
      this.totalPagesNum = mediaManagerData.totalPages;
      this.mediaItemsRequestStatus.setSuccess(true);
    } catch (err) {
      this.mediaItemsRequestStatus.setError(err);
      this.mediaItemsRequestStatus.setSuccess(false);
      this.resetMedia();
    }

    this.mediaItemsRequestStatus.setLoading(false);
  };

  @action debouncedGetMediaItems = this.getMediaItems;

  @action setHoveredMediaItemTimestamp = (timestamp: number) => (this.hoveredMediaItemTimestamp = timestamp);

  @action setActiveMediaItemTimestamp = (timestamp: number) => (this.activeMediaItemTimestamp = timestamp);

  @action resetActiveMediaTimestamp = () => (this.activeMediaItemTimestamp = null);

  @action setActiveMediaItemTopPosition = (position: number) => (this.activeMediaItemTopPosition = position);

  @action resetActiveMediaTopPosition = () => (this.activeMediaItemTopPosition = null);

  @action setCurrentPage = (pageNum) => (this.currentPageNum = pageNum);

  @action deleteMedia = async (timestamp) => {
    try {
      await this.repositoryMediaCameras.entity(this.filters.selectedCameraId?.toString()).delete({ timestamp });
      await this.getMediaItems();
    } catch (e) {
      // silent
    }
  };

  @action resetDevicesListRequestStatus = () => {
    this.repositoryDevices.getState.reset();
  };

  @action resetMedia = () => {
    this.mediaItemsRequestStatus.reset();
    this.mediaItemsList = [];
    this.currentPageNum = 0;
    this.totalPagesNum = null;
  };

  @action resetMediaManagerStore = () => {
    this.resetDevicesListRequestStatus();
    this.resetMedia();
    this.filters.resetFilters(this.initialDate);
    this.devicesList = [];
    this.hoveredMediaItemTimestamp = null;

    return this;
  };

  getFilterOptions = (): Filters.IPredefinedDateFilterOption[] => {
    return [
      {
        text: 'Today',
        value: {
          from: getTodayStartDate(timeStore.sessionTimezone).valueOf(),
          to: getTodayEndDate(timeStore.sessionTimezone).valueOf(),
        },
      },
      {
        text: 'Yesterday',
        value: {
          from: getPastDay(1, getTodayStartDate(timeStore.sessionTimezone)),
          to: getPastDay(1, getTodayEndDate(timeStore.sessionTimezone)),
        },
      },
      {
        text: 'Last 7 days',
        value: {
          from: getPastDay(6, getTodayStartDate(timeStore.sessionTimezone)),
          to: getTodayEndDate(timeStore.sessionTimezone).valueOf(),
        },
      },
      {
        text: 'Last 30 days',
        value: {
          from: getPastDay(29, getTodayStartDate(timeStore.sessionTimezone)),
          to: getTodayEndDate(timeStore.sessionTimezone).valueOf(),
        },
      },
      {
        text: 'This month',
        value: {
          from: getTodayStartDate(timeStore.sessionTimezone)
            .startOf('month')
            .valueOf(),
          to: getTodayEndDate(timeStore.sessionTimezone).valueOf(),
        },
      },
    ];
  };
}
