import React from 'react';
import InputNumber, { InputNumberProps } from 'antd/lib/input-number';

export default function IntegerInput(props: InputNumberProps) {
  const handleChange = (value: string | number) => {
    if (Number.isInteger(Number(value))) {
      props.onChange?.(value);
    }
  };

  return <InputNumber {...props} onChange={handleChange} />;
}
