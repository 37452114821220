import { getPastDay, getTodayCustomDate, getTodayStartDate, getTodayEndDate } from 'utils';
import appPackage from '../../package.json';

export const APP_VERSION = appPackage.version;

export const isDevelop = Boolean(process.env.REACT_APP_ENV === 'develop' || process.env.NODE_ENV === 'development');

export const isPreproduction = Boolean(process.env.REACT_APP_ENV === 'preproduction');

export const isStaging = Boolean(process.env.REACT_APP_ENV === 'staging');

export const isProduction = isPreproduction || (!isDevelop && !isPreproduction && !isStaging);

// for Chart on Media Player Page
export const M_PER_SECOND_TO_MPH = 2.2369;
export const MI_TO_KM = 0.621371192; // sent by Chris
export const KM_TO_MI = 0.621371192; // sent by Chris
// export const MI_TO_KM = 0.630433; // used by 2.6

// this is step for one second for Draggable on Media Request on step 2
export const PIXEL_TO_SECOND = 3.6;

export const MS_IN_ONE_DAY = 86400000;

export const DEFAULT_TIMEZONE = 'US/Pacific';

export const ADMIN_NAV_SELECTION = 'admin_nav_selection';

const formattedYesterdayText = getTodayCustomDate({ hour: 0, minute: 0 }).subtract(1, 'days').format('MMM DD');

export const timeWithSecondsMask = {
  startedFromZero: [/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, /(a|p)/i, /m/i],
  startedFromOne: [/[0-1]/, /[0-2]/, ':', /[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, /(a|p)/i, /m/i],
};

export const MAP_CENTER = {
  lat: 38.6406812,
  lng: -106.1094301,
};

export const TIME_OPTIONS_ARRAY: Filters.ITimePickerOption[] = [
  { key: '12:00 am', value: '12:00 am', text: '12:00am' },
  { key: '12:30 am', value: '12:30 am', text: '12:30am' },
  { key: '01:00 am', value: '01:00 am', text: '01:00am' },
  { key: '01:30 am', value: '01:30 am', text: '01:30am' },
  { key: '02:00 am', value: '02:00 am', text: '02:00am' },
  { key: '02:30 am', value: '02:30 am', text: '02:30am' },
  { key: '03:00 am', value: '03:00 am', text: '03:00am' },
  { key: '03:30 am', value: '03:30 am', text: '03:30am' },
  { key: '04:00 am', value: '04:00 am', text: '04:00am' },
  { key: '04:30 am', value: '04:30 am', text: '04:30am' },
  { key: '05:00 am', value: '05:00 am', text: '05:00am' },
  { key: '05:30 am', value: '05:30 am', text: '05:30am' },
  { key: '06:00 am', value: '06:00 am', text: '06:00am' },
  { key: '06:30 am', value: '06:30 am', text: '06:30am' },
  { key: '07:00 am', value: '07:00 am', text: '07:00am' },
  { key: '07:30 am', value: '07:30 am', text: '07:30am' },
  { key: '08:00 am', value: '08:00 am', text: '08:00am' },
  { key: '08:30 am', value: '08:30 am', text: '08:30am' },
  { key: '09:00 am', value: '09:00 am', text: '09:00am' },
  { key: '09:30 am', value: '09:30 am', text: '09:30am' },
  { key: '10:00 am', value: '10:00 am', text: '10:00am' },
  { key: '10:30 am', value: '10:30 am', text: '10:30am' },
  { key: '11:00 am', value: '11:00 am', text: '11:00am' },
  { key: '11:30 am', value: '11:30 am', text: '11:30am' },

  { key: '12:00 pm', value: '12:00 pm', text: '12:00pm' },
  { key: '12:30 pm', value: '12:30 pm', text: '12:30pm' },
  { key: '01:00 pm', value: '01:00 pm', text: '01:00pm' },
  { key: '01:30 pm', value: '01:30 pm', text: '01:30pm' },
  { key: '02:00 pm', value: '02:00 pm', text: '02:00pm' },
  { key: '02:30 pm', value: '02:30 pm', text: '02:30pm' },
  { key: '03:00 pm', value: '03:00 pm', text: '03:00pm' },
  { key: '03:30 pm', value: '03:30 pm', text: '03:30pm' },
  { key: '04:00 pm', value: '04:00 pm', text: '04:00pm' },
  { key: '04:30 pm', value: '04:30 pm', text: '04:30pm' },
  { key: '05:00 pm', value: '05:00 pm', text: '05:00pm' },
  { key: '05:30 pm', value: '05:30 pm', text: '05:30pm' },
  { key: '06:00 pm', value: '06:00 pm', text: '06:00pm' },
  { key: '06:30 pm', value: '06:30 pm', text: '06:30pm' },
  { key: '07:00 pm', value: '07:00 pm', text: '07:00pm' },
  { key: '07:30 pm', value: '07:30 pm', text: '07:30pm' },
  { key: '08:00 pm', value: '08:00 pm', text: '08:00pm' },
  { key: '08:30 pm', value: '08:30 pm', text: '08:30pm' },
  { key: '09:00 pm', value: '09:00 pm', text: '09:00pm' },
  { key: '09:30 pm', value: '09:30 pm', text: '09:30pm' },
  { key: '10:00 pm', value: '10:00 pm', text: '10:00pm' },
  { key: '10:30 pm', value: '10:30 pm', text: '10:30pm' },
  { key: '11:00 pm', value: '11:00 pm', text: '11:00pm' },
  { key: '11:30 pm', value: '11:30 pm', text: '11:30pm' },
];

export const PREDEFINED_TIME_FILTER_OPTIONS: Filters.IPredefinedTimeFilterOption[] = [
  {
    name: 'Today',
    timeRange: '12a - 11:59p',
    value: {
      from: getTodayStartDate().valueOf(),
      to: getTodayEndDate().valueOf(),
    },
  },
  {
    name: 'This Morning',
    timeRange: '6a - 11:59a',
    value: {
      from: getTodayCustomDate({ hour: 6, minute: 0 }).valueOf(),
      to: getTodayEndDate().valueOf(),
    },
  },
  {
    name: 'This Afternoon',
    timeRange: '12p - 5:00p',
    value: {
      from: getTodayCustomDate({ hour: 12, minute: 0 }).valueOf(),
      to: getTodayCustomDate({ hour: 17, minute: 0 }).valueOf(),
    },
  },
  {
    name: 'Yesterday',
    timeRange: `${formattedYesterdayText} 12a - 11:59p`,
    value: {
      from: getTodayStartDate().subtract(1, 'days').valueOf(),
      to: getTodayEndDate().subtract(1, 'days').valueOf(),
    },
  },
  {
    name: 'Yesterday Morning',
    timeRange: `${formattedYesterdayText} 6a - 11:59a`,
    value: {
      from: getTodayCustomDate({ hour: 6, minute: 0 }).subtract(1, 'days').valueOf(),
      to: getTodayEndDate().subtract(1, 'days').valueOf(),
    },
  },
  {
    name: 'Yesterday Afternoon',
    timeRange: `${formattedYesterdayText} 12p - 5:00p`,
    value: {
      from: getTodayCustomDate({ hour: 12, minute: 0 }).subtract(1, 'days').valueOf(),
      to: getTodayCustomDate({ hour: 17, minute: 0 }).subtract(1, 'days').valueOf(),
    },
  },
];

export const PREDEFINED_DASH_CAMS_DATE_FILTER_OPTIONS: Filters.IPredefinedDateFilterOption[] = [
  {
    text: 'Today',
    value: {
      from: getTodayStartDate().valueOf(),
      to: getTodayEndDate().valueOf(),
    },
  },
  {
    text: 'Yesterday',
    value: {
      from: getPastDay(1),
      to: getPastDay(1, getTodayEndDate()),
    },
  },
  {
    text: 'Last 7 days',
    value: {
      from: getPastDay(6),
      to: getTodayEndDate().valueOf(),
    },
  },
  {
    text: 'Last 30 days',
    value: {
      from: getPastDay(29),
      to: getTodayEndDate().valueOf(),
    },
  },
  {
    text: 'This month',
    value: {
      from: getTodayStartDate().startOf('month').valueOf(),
      to: getTodayEndDate().valueOf(),
    },
  },
];

export enum DATE_TIME_FORMATS {
  adminVehicleSettings = 'MM/DD/YY hh:mm A',
  fullFormatDate = 'MM/DD/YYYY hh:mm A',
  dateAtTime = 'M/D/YY @ hh:mmA',
  FULL_DATE_AT_TIME = 'MM/DD/YYYY @ hh:mm A',
  dateFormat = 'YYYY MM DD hh:mm:ssa',
  dispatchDate = 'YYYY-MM-DD',
  dispatchFormatDate = 'dddd MMMM D, YYYY',
  fullHoursMinutesSecondsAm = 'hh:mm:ssa',
  fullMonthComaDateYear = 'MMMM D, YYYY',
  fullMonthComaDateYearHoursMinutesSecondsAm = 'MMMM D, YYYY hh:mm:ssa',
  fullMonthComaDateYearHoursMinutesSecondsSpaceAM = 'MMMM D, YYYY, h:mm:ss A',
  hoursMinutesAm = 'h:mm A',
  hoursMinutes24 = 'HH:mm',
  hoursMinutesSeconds24 = 'HH:mm:ss',
  hoursMinutesSecondsAm = 'h:mm:ss A',
  hoursMinutesSpaceAm = 'h:mm a',
  hoursFullMinutesSpaceAm = 'hh:mm a',
  maintSchedule = 'MMM DD, YYYY',
  eventsHistory = 'dddd, MMM DD, YYYY',
  mediaClipDate = 'dddd, MMMM D, YYYY',
  mediaClipVideo = 'MMM DD, YYYY @ h:mm:ss A',
  eventDateAtTime = 'M/D/YY hh:mmA',
  monthDatYear = 'M/D/YYYY',
  monthDatYearFull = 'MM/DD/YYYY',
  monthDatFullYear = 'MM/DD/YY',
  monthSlashDateSlashYearSpaceFullHourFullMinAm = 'M/D/YY hh:mma',
  timeCapitalAM = 'h:mmA',
  WEEK_DAY_MONTH_YEAR_HOURS_MINUTES_AM = 'dddd MMMM D, YYYY h:mmA',
  yearMonthDate = 'YYYY M D',
  monthDateYearTimeA = 'MMM DD, YYYY, hh:mmA',
  monthDate = 'MMM DD',
}

export const MEDIA_EVENTS_STATUS_TEXTS = {
  available: 'MEDIA_AVAILABLE',
  unavailable: 'MEDIA_UNAVAILABLE',
  requested: 'MEDIA_REQUESTED',
  queued: 'MEDIA_QUEUED',
};

export const USER_ROLES = {
  mediaViewOnly: 'prodash-media-view-only',
  media: 'prodash', // check if 'prodash' is in roleId
};

export const STORAGE_ITEMS = {
  mediaLibrarySearchQuery: 'media-library-search-query',
  wayPoints: 'waypoints',
  geozones: 'geozones',
  sessionTimezone: 'sessionTimezone',
  pinnedDevicesIds: 'pinned-devices-ids',
  pinnedGroupsIds: 'pinned-groups-ids',
  pinnedGeozonesIds: 'pinned-geozones-ids',
  devicesStatuses: 'devices-statuses',
  devicesAttributes: 'devices-attributes',
  mapSettings: 'map-settings',
  subNavigation: 'sub-navigation',
  reports: {
    filterOpenStatus: 'reports-filter-open-status',
    settings: 'reports-settings',
    columns: 'reports-columns',
    sortedColumn: 'reports-sorted-column',
    pagination: 'reports-pagination',
    pinnedReports: 'reports-pinned-items',
    collapsedGroups: 'reports-collapsed-groups',
    scheduled: {
      filter: 'reports-scheduled-filter',
      columns: 'reports-scheduled-columns',
      sortedColumn: 'reports-scheduled-sorted-column',
      pagination: 'reports-scheduled-pagination',
      searchInColumn: 'reports-scheduled-search-in-columns',
    },
  },
  alerts: {
    filterOpenStatus: 'alerts-filter-open-status',
    columns: 'alerts-columns',
    sortedColumn: 'alerts-sorted-column',
    pagination: 'alerts-pagination',
    searchInColumn: 'alerts-search-in-columns',
  },
  admin: {
    filterOpenStatus: 'admin-filter-open-status',
    vehicles: {
      columns: 'admin-vehicles-columns',
      sortedColumn: 'admin-vehicles-sorted-column',
      searchInColumn: 'admin-vehicles-search-in-column',
      pagination: 'admin-vehicles-pagination',
    },
    selectedVehicle: 'admin-selected-vehicle',
    groups: {
      columns: 'admin-groups-columns',
      sortedColumn: 'admin-groups-sorted-column',
      searchInColumn: 'admin-groups-search-in-column',
      pagination: 'admin-groups-pagination',
    },
    users: {
      columns: 'admin-users-columns',
      sortedColumn: 'admin-users-sorted-column',
      searchInColumn: 'admin-users-search-in-column',
      pagination: 'admin-users-pagination',
      selectedUser: {
        tabIndex: 'admin-users-selected-user-tab-index',
      },
    },
    people: {
      columns: 'admin-people-columns',
      sortedColumn: 'admin-people-sorted-column',
      searchInColumn: 'admin-people-search-in-column',
      pagination: 'admin-people-pagination',
      selectedPerson: {
        tabIndex: 'admin-people-selected-person-tab-index',
      },
    },
    roles: {
      columns: 'admin-roles-columns',
      sortedColumn: 'admin-roles-sorted-column',
      searchInColumn: 'admin-roles-search-in-column',
      pagination: 'admin-roles-pagination',
    },
    geozones: {
      columns: 'admin-geozones-columns',
      sortedColumn: 'admin-geozones-sorted-column',
      searchInColumn: 'admin-geozones-search-in-column',
      pagination: 'admin-geozones-pagination',
      selectedGeozone: {
        tabIndex: 'admin-geozones-selected-geozone-tab-index',
      },
    },
    drivers: {
      columns: 'admin-drivers-columns',
      sortedColumn: 'admin-drivers-sorted-column',
      searchInColumn: 'admin-drivers-search-in-column',
      pagination: 'admin-drivers-pagination',
    },
    dashcams: {
      columns: 'admin-dashcams-columns',
      sortedColumn: 'admin-dashcams-sorted-column',
      searchInColumn: 'admin-dashcams-search-in-column',
      pagination: 'admin-dashcams-pagination',
    },
    integrations: {
      filterOpenStatus: 'admin-integrations-filter-open-status',
      columns: 'admin-integrations-columns',
      sortedColumn: 'admin-integrations-sorted-column',
      searchInColumn: 'admin-integrations-search-in-column',
      pagination: 'admin-integrations-pagination',
    },
    maintenance: {
      upcoming: {
        filterOpenStatus: 'admin-maintenance-upcoming-filter-open-status',
        columns: 'admin-maintenance-upcoming-columns',
        sortedColumn: 'admin-maintenance-upcoming-sorted-column',
        searchInColumn: 'admin-maintenance-upcoming-search-in-column',
        pagination: 'admin-maintenance-upcoming-pagination',
      },
      completed: {
        filterOpenStatus: 'admin-maintenance-completed-filter-open-status',
        columns: 'admin-maintenance-completed-columns',
        sortedColumn: 'admin-maintenance-completed-sorted-column',
        searchInColumn: 'admin-maintenance-completed-search-in-column',
        pagination: 'admin-maintenance-completed-pagination',
      },
    },
    inspections: {
      performed: {
        filterOpenStatus: 'admin-inspections-performed-filter-open-status',
        columns: 'admin-inspections-performed-columns',
        sortedColumn: 'admin-inspections-performed-sorted-column',
        searchInColumn: 'admin-inspections-performed-search-in-column',
        pagination: 'admin-inspections-performed-pagination',
      },
      forms: {
        filterOpenStatus: 'admin-inspections-forms-filter-open-status',
        columns: 'admin-inspections-forms-columns',
        sortedColumn: 'admin-inspections-forms-sorted-column',
        searchInColumn: 'admin-inspections-forms-search-in-column',
        pagination: 'admin-inspections-forms-pagination',
      },
    },
  },
  panelMode: 'panel-mode',
  map: {
    geozones: {
      selectedColors: 'geozones-selected-colors',
    },
    timeline: {
      filterOpenStatus: 'timeline-filter-open-status',
      columns: 'map-timeline-columns',
      sortedColumn: 'map-timeline-sorted-column',
      searchInColumn: 'map-timeline-search-in-column',
      pagination: 'map-timeline-pagination',
      detailsHeight: 'map-timeline-details-height',
      tableMode: 'map-timeline-details-table-mode',
    },
  },
  maintenance: {
    upcoming: {
      filterOpenStatus: 'maintenance-upcoming-filter-open-status',
      columns: 'maintenance-upcoming-columns',
      sortedColumn: 'maintenance-upcoming-sorted-column',
      searchInColumn: 'maintenance-upcoming-search-in-column',
      pagination: 'maintenance-upcoming-pagination',
    },
    completed: {
      filterOpenStatus: 'maintenance-completed-filter-open-status',
      columns: 'maintenance-completed-columns',
      sortedColumn: 'maintenance-completed-sorted-column',
      searchInColumn: 'maintenance-completed-search-in-column',
      pagination: 'maintenance-completed-pagination',
    },
    applyTasks: {
      filterOpenStatus: 'maintenance-apply-tasks-filter-open-status',
      columns: 'maintenance-apply-tasks-columns',
      sortedColumn: 'maintenance-apply-tasks-sorted-column',
      searchInColumn: 'maintenance-apply-tasks-search-in-column',
      pagination: 'maintenance-apply-tasks-pagination',
    },
  },
  inspections: {
    performed: {
      filterOpenStatus: 'inspections-performed-filter-open-status',
      columns: 'inspections-performed-columns',
      sortedColumn: 'inspections-performed-sorted-column',
      searchInColumn: 'inspections-performed-search-in-column',
      pagination: 'inspections-performed-pagination',
    },
    forms: {
      filterOpenStatus: 'inspections-forms-filter-open-status',
      columns: 'inspections-forms-columns',
      sortedColumn: 'inspections-forms-sorted-column',
      searchInColumn: 'inspections-forms-search-in-column',
      pagination: 'inspections-forms-pagination',
    },
  },
};

export const LINKS = {
  directionFromHere: 'https://www.google.com/maps/dir/{latitude},{longitude}//@{latitude},{longitude},17z',
  directionTo: 'https://www.google.com/maps/dir//{latitude},{longitude}/@{latitude},{longitude},17z',
  showInGoogleMaps: 'https://www.google.com/maps/search/{latitude},{longitude}//@{latitude},{longitude},17z',
  portalDriverAdmin: 'https://portal.staging.clearpathgps.com/track/Track?page=driver.info',
  leaveFeedback: 'https://support.clearpathgps.com/hc/en-us/requests/new?ticket_form_id=360000572811',
  helpCenter: 'https://support.clearpathgps.com/hc/en-us',
  upgradeToPro:
    'https://www.clearpathgps.com/standard-vs-pro?utm_campaign=Customer%20Marketing%20%7C%20In-Platform%20Upsells&utm_source=upgrade%20to%20pro&utm_medium=cpgps-platform&utm_term=cpgps-button&utm_content=upgrade%20to%20pro',
  buyMoreTrackers:
    'https://www.clearpathgps.com/buy-more-trackers?utm_campaign=Customer%20Marketing%20%7C%20In-Platform%20Upsells&utm_source=buy%20more%20trackers&utm_medium=cpgps-platform&utm_term=cpgps-button&utm_content=buy%20more%20trackers',
  learnAboutDashcamAI: 'https://support.clearpathgps.com/hc/en-us/articles/10769853580820',
};

export const MAP_PARAMS = {
  geozonesBreakpoint: 15,
  defaultZoom: 16,
  vehicleDefaultZoom: 18,
  zoomInto: 21,
  DEFAULT_US: {
    LAT: 42,
    LNG: -98,
    ZOOM: 5,
  },
  US_BOUNDS: [
    { lat: 49.38, lng: -66.94 },
    { lat: 25.82, lng: -124.39 },
  ],
};

export enum MAP_FILTER_ATTRIBUTES {
  POWER_DISCONNECT = 'tracker-lost-power',
  HAS_DASHCAM = 'has-dashcam',
}

export enum ADMIN_VEHICLES_TABLE_COLUMNS {
  COORDINATES = 'coordinates',
  DESCRIPTION = 'description',
  VIN = 'vin',
  DEVICE_TYPE = 'deviceType',
  DRIVER_NAME = 'driverName',
  DRIVER_DESCRIPTION = 'driverDescription',
  EQUIPMENT_TYPE = 'equipmentType',
  GROUP = 'groups',
  IGNITION_STATE = 'ignitionState',
  IMEI = 'imei',
  UNIQUE_ID = 'uniqueId',
  LAST_COMMUNICATION = 'lastCommunication',
  MAKE = 'make',
  MODEL = 'model',
  NAME = 'name',
  ODOMETER = 'odometer',
  PLATE = 'plate',
  SUSPENDED = 'suspended',
  VEHICLE_ID = 'id',
  MPG = 'milesPerGallon',
  UPDATED_BY = 'updatedBy',
  LAST_UPDATE_TIME = 'lastUpdateTime',
  YEAR = 'vehicleYear',
}

export enum ADMIN_GROUPS_TABLE_COLUMNS {
  NAME = 'name',
  COUNT = 'count',
  CP = 'cp',
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',
  CREATION_TIME = 'creationTime',
  LAST_UPDATE_TIME = 'lastUpdateTime',
}

export enum ADMIN_USERS_TABLE_COLUMNS {
  USER_NAME = 'userName',
  LOGIN_EMAIL = 'loginEmail',
  JOB_TITLE = 'jobTitle',
  ROLE = 'role',
  LAST_LOGIN = 'lastLogin',
  ACTIVE_STATUS = 'activeStatus',
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',
  CREATION_TIME = 'creationTime',
  LAST_UPDATE_TIME = 'lastUpdateTime',
}

export enum ADMIN_DRIVERS_TABLE_COLUMNS {
  DRIVER_NAME = 'driverName',
  DRIVER_FOB_ID = 'driverFobId',
  TYPE = 'type',
  ASSIGNED_VEHICLE = 'assignedVehicle',
  EMAIL_ADDRESS = 'emailAddress',
  PHONE_NUMBER = 'phoneNumber',
  EMPLOYEE_ID = 'employeeId',
  LICENSE_TYPE = 'licenseType',
  LICENSE_STATE = 'licenseState',
  LICENSE_NUMBER = 'licenseNumber',
  LICENSE_EXPIRATION = 'licenseExpiresAt',
}

export enum ADMIN_DASHCAMS_TABLE_COLUMNS {
  NAME = 'name',
  UNIQUE_ID = 'uniqueId',
  DASHCAM_ID = 'dashcamId',
  SERIAL_NUMBER = 'serialNumber',
  VEHICLE_ID = 'vehicleId',
  VEHICLE_NAME = 'vehicleName',
  LAST_COMMUNICATION = 'lastCommunication',
  NOTES = 'NOTES',
  VEHICLE_DESCRIPTION = 'vehicleDescription',
  UPDATED_BY = 'updatedBy',
  LAST_UPDATE_TIME = 'lastUpdateTime',
}

export enum HISTORY_MODE {
  MOST_RECENT_ACTIVITY = 'mostRecentActivity',
  TIME_RANGE = 'timeRange',
  POINT_IN_TIME = 'pointInTime',
}

export enum KEY_TYPE {
  LEFT_ARROW = 'ArrowLeft',
  RIGHT_ARROW = 'ArrowRight',
  UP_ARROW = 'ArrowUp',
  DOWN_ARROW = 'ArrowDown',
  ESCAPE = 'Escape',
}

export enum EVENT_STATUS_TYPE {
  NONE = 'none',
  SPEED = 'speed',
  TIME_DIFF = 'timeDiff',
}

export enum SETTINGS_CATEGORY {
  EVENT = 'Events',
  TRACK_POINT = 'Track point',
}

export enum MAP_MARKERS {
  BASE = 20,
  HOVER = 22,
  ACTIVE = 21,
}

export enum USER_ROLES_IDS {
  ADMINISTRATOR = 4,
  MANAGER = 5,
  BASIC_USER = 6,
  MAP_ONLY = 2686,
  GROUP_MAP_ONLY = 2687,
  LIVE_MAP_ONLY = null,
}

export enum ADMIN_GEOZONES_TABLE_COLUMNS {
  NAME = 'name',
  CATEGORY = 'category',
  COLOR = 'color',
  SHAPE = 'shape',
  GROUP = 'group',
  DISPATCH_MARKER = 'dispatchMarker',
  ADDRESS_OVERRIDE = 'addressOverride',
  TRACK_ARRIVALS = 'trackArrivals',
  TRACK_DEPARTURES = 'trackDepartures',
  RADIUS = 'radius',
  CENTER = 'center',
  GEOZONE_ID = 'geozoneId',
  ACTIVE = 'active',
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',
  CREATION_TIME = 'creationTime',
  LAST_UPDATE_TIME = 'lastUpdateTime',
}

export const ERROR_MESSAGES = {
  GEOZONES: {
    ALREADY_EXIST: 'GeozoneCP already exists',
  },
};

export const UNITS = [
  {
    label: 'meters',
  },
  {
    label: 'miles',
  },
];

export const DEFAULT_GEOZONE_RADIUS = 25;

export const TIMEZONE_OPTIONS = [
  {
    key: 'us-hawaii',
    text: 'US/Hawaii',
    value: 'US/Hawaii',
  },
  {
    key: 'us-alaska',
    text: 'US/Alaska',
    value: 'US/Alaska',
  },
  {
    key: 'us-pacific',
    text: 'US/Pacific',
    value: 'US/Pacific',
  },
  {
    key: 'us-arizona',
    text: 'US/Arizona',
    value: 'US/Arizona',
  },
  {
    key: 'us-mountain',
    text: 'US/Mountain',
    value: 'US/Mountain',
  },
  {
    key: 'us-central',
    text: 'US/Central',
    value: 'US/Central',
  },
  {
    key: 'us-eastern',
    text: 'US/Eastern',
    value: 'US/Eastern',
  },
  {
    key: 'canada-pacific',
    text: 'Canada/Pacific',
    value: 'Canada/Pacific',
  },
  {
    key: 'canada-mountain',
    text: 'Canada/Mountain',
    value: 'Canada/Mountain',
  },
  {
    key: 'canada-central',
    text: 'Canada/Central',
    value: 'Canada/Central',
  },
  {
    key: 'canada-eastern',
    text: 'Canada/Eastern',
    value: 'Canada/Eastern',
  },
  {
    key: 'canada-atlantic',
    text: 'Canada/Atlantic',
    value: 'Canada/Atlantic',
  },
  {
    key: 'mexico-bajanorte',
    text: 'Mexico/BajaNorte',
    value: 'Mexico/BajaNorte',
  },
  {
    key: 'mexico-bajasur',
    text: 'Mexico/BajaSur',
    value: 'Mexico/BajaSur',
  },
  {
    key: 'mexico-general',
    text: 'Mexico/General',
    value: 'Mexico/General',
  },
];

export enum WEEKDAYS {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum ALERTS_TABLE_COLUMNS {
  RULE_ID = 'id',
  NAME = 'displayName',
  TYPE = 'type',
  RECIPIENTS = 'recipients',
  INTERVAL_RULE = 'cronOption',
  ACTIVE = 'active',
  ACTIONS = 'actions',
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',
  CREATION_TIME = 'creationTime',
  LAST_UPDATE_TIME = 'lastUpdateTime',
}

export enum ALERTS_APPLIES_TO {
  VEHICLE = 'vehicle',
  GROUP = 'group',
  DASHCAM = 'dashcam',
}

export enum ALERTS_PLACE_TYPE {
  GEOZONE = 'Geozone',
  CATEGORY = 'Category',
}

export enum ALERTS_MAINTENANCE_SUBTYPE {
  MILEAGE = 'Mileage',
  ENGINE_HOURS = 'EngineHours',
  DATE = 'Date',
}

export enum ALERT_WHEN {
  IGNITION_ON = 'IgnitionOn',
  MOVING = 'Moving',
}

export enum ALERTS_TYPE {
  ACCELERATION = 'Acceleration',
  AFTER_HOURS = 'After Hours',
  BRAKING = 'Braking',
  CORNERING = 'Cornering',
  EXCESSIVE_IDLING = 'Excessive Idling',
  FAULT_CODES = 'Fault Codes',
  GEOZONE = 'Geozone',
  IGNITION = 'Ignition',
  LOW_VEHICLE_BATTERY = 'Low Vehicle Battery',
  MAINTENANCE = 'Maintenance',
  MAINTENANCE_DUE = 'Maintenance Due',
  POWER_DISCONNECT_TRACKER = 'Power Disconnect',
  POWER_DISCONNECT_DASHCAM = 'Dashcam Offline',
  SPEEDS_OVER = 'Speeds Over',
  FUELING_EXCEPTION = 'Fuel Exception',
  CUSTOM = 'Custom',
}

export enum ALERT_MODAL_TYPE {
  CREATE = 'Create Alert',
  EDIT = 'Edit Alert',
}

export enum RECIPIENT_TYPE {
  EMAIL = 'email',
  TEXT = 'text',
}

export const ALERT_STEP_INDEX = {
  STEP_1: 0,
  STEP_2: 1,
  STEP_3: 2,
  STEP_4: 3,
};

export const TIME = {
  beggingOfTheDay: `12:00 am`,
  endOfTheDay: `11:59 pm`,
};

export const TIME24 = {
  beggingOfTheDay: '00:00',
  endOfTheDay: '23:59',
};

export enum DOWNLOAD_FORMAT {
  XLSX = 'xlsx',
  CSV = 'csv',
  HTML = 'html',
}

export enum SCHEDULED_REPORTS_TABLE_COLUMNS {
  NAME = 'description',
  TYPE = 'reportName',
  INTERVAL = 'interval',
  APPLIES = 'applies',
  RECIPIENTS = 'recipients',
  ACTIVE = 'active',
  ACTIONS = 'actions',
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',
  CREATION_TIME = 'creationTime',
  LAST_UPDATE_TIME = 'lastUpdateTime',
}

export enum SCHEDULED_REPORTS_TYPE_MODAL {
  CREATE = 'create',
  EDIT = 'edit',
  DUPLICATE = 'duplicate',
}

export enum TIMELINE_TABLE_COLUMNS {
  ADDRESS = 'address',
  COORDINATES = 'coordinates',
  DATE = 'date',
  DRIVER = 'driver',
  ENGINE_HOURS = 'engineHours',
  INDEX = 'index',
  ODOMETER_MILES = 'odometerMiles',
  STATUS = 'status',
  TIME = 'time',
  TIMESTAMP = 'timestamp',
  VEHICLE_SPEED = 'vehicleSpeed',
}

export enum WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS {
  CARD_ID = 'cardUniqueCode',
  NAME = 'name',
  DRIVER_NAME = 'driverName',
  VEHICLE_ASSOCIATION = 'assetId',
  FUEL_TYPE = 'fuelType',
  TANK_CAPACITY = 'tankCapacity',
}

export const OLD_EVENTS_SHOW_ERROR = [
  'Moving',
  'Speeding',
  'Idling',
  'Cornering',
  'Braking',
  'Acceleration',
  'Ignition On',
];

export enum DASHCAMS_SETTINGS_TYPES {
  TOGGLE = 'BOOLEAN',
  NUMBER = 'NUMERIC',
  ENUM = 'ENUM',
}

export enum CAMERA_JOURNAL_STATE {
  GUARD = 'GUARD',
  IN_OUT = 'IN_OUT',
  OUT = 'OUT',
  IN = 'IN',
}

export enum CAMERA_JOURNAL_LABEL {
  IN = 'Driver Facing',
  OUT = 'Road Facing',
}

export const CAMERA_PLAYER_EVENTS = {
  PLAY_START: 'play:start',
  PLAY_ONSTART: 'play:onstart',
};

export enum CAMERA_TYPE {
  XIRGO = 'owl',
  SMARTWITNESS = 'smartwitness',
}

export enum JOURNAL_ZOOM_LEVELS {
  LEVEL_1 = 1,
  LEVEL_2 = 2,
  LEVEL_3 = 3,
}

export enum PLAYER_ERROR_TYPE {
  IMAGE = 'error_image',
  VIDEO = 'error_video',
}

export const REPORTS_BETA_ITEMS = ['fueling_events'];

export const QB_INTEGRATION_SUCCESS_CONNECT_EVENT = 'qb-connected';

export const INTEGRATION_CUSTOMER_EXTERNAL_LINK = {
  SERVICE_TITAN: 'https://go.servicetitan.com/#/Customer/%integrationId',
  QUICK_BOOKS: 'https://app.sandbox.qbo.intuit.com/app/customerdetail?nameId=%integrationId',
};

export enum DASHCAM_MEDIA_SOURCE {
  VEHICLE = 'vehicle',
  GROUP = 'group',
}

export enum MEDIA_CLIP_STATUS {
  AVAILABLE = 'AVAILABLE',
  FAILED = 'FAILED',
  QUEUED = 'QUEUED',
  REQUESTED = 'REQUESTED',
  UNAVAILABLE = 'UNAVAILABLE',
}

export enum MEDIA_CLIP_TYPE {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export enum MEDIA_CLIPS_VIEW {
  MEDIA_LIBRARY = 'library',
  VEHICLE_MEDIA_PANEL = 'panel',
}

export enum MAINTENANCE_SEARCH_PARAMS {
  NAME = 'name',
  HISTORY_NAME = 'historyRecordName',
  ASSET_NAME = 'assetName',
  DUE_IN_DAYS = 'dueInDays',
  FREQUENCY = 'frequency',
  SERVICE_TYPE_NAME = 'serviceTypeName',
  ODOMETER_DUE_IN = 'odometerDueIn',
  ENG_HOURS_DUE_IN = 'engHoursDueIn',
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',
  CREATION_TIME = 'creationTime',
  LAST_UPDATE_TIME = 'lastUpdateTime',
  DUE_STATUSES = 'dueStatuses',
  CONFIG_TYPES = 'configTypes',
  LAST_PERFORMED_ODOMETER = 'lastPerformedOdometer',
  LAST_PERFORMED_ENGINE_HOURS = 'lastPerformedEngineHours',
  LAST_HISTORY_CREATED = 'lastHistoryCreated',
  LAST_HISTORY_UPDATED = 'lastHistoryUpdated',
  LAST_HISTORY_UPDATED_BY = 'lastHistoryUpdatedBy',
  LAST_HISTORY_SERVICE_TIME = 'lastHistoryServiceTime',
  LAST_HISTORY_CREATED_BY = 'lastHistoryCreatedBy',
  TOTAL_COST = 'totalCost',
  LAST_HISTORY_TOTAL_COST = 'lastHistoryTotalCost',
  DRIVER_NAME = 'driverName',
}

export enum MAINTENANCE_UPCOMING_TABLE_COLUMNS {
  TASK_NAME = 'taskName',
  VEHICLE_NAME = 'vehicleName',
  DUE_IN = 'dueIn',
  FREQUENCY = 'frequency',
  SERVICE_TYPE = 'serviceType',
  CURRENT_ODOMETER = 'currentOdometer',
  CURRENT_ENGINE_HOURS = 'currentEngineHours',
  DRIVER_NAME = 'driverName',
  CREATED_BY = 'createdBy',
  MODIFIED_BY = 'modifiedBy',
  CREATED_ON = 'createdOn',
  MODIFIED_ON = 'modifiedOn',
}

export enum MAINTENANCE_COMPLETED_TABLE_COLUMNS {
  TASK_NAME = 'taskName',
  VEHICLE_NAME = 'vehicleName',
  SERVICED_ON = 'servicedOn',
  FREQUENCY = 'frequency',
  ODO_AT_SERVICE = 'odoAtService',
  ENG_HOURS_AT_SERVICE = 'engHoursAtService',
  SERVICE_TYPE = 'serviceType',
  MARK_AS_COMPLETED_BY = 'markAsCompletedBy',
  MODIFIED_BY = 'modifiedBy',
  MARK_AS_COMPLETED_ON = 'markAsCompletedOn',
  MODIFIED_ON = 'modifiedOn',
  TOTAL_COST = 'totalCost',
}

export enum MAINTENANCE_DUE_STATUSES {
  OVERDUE = 'OVERDUE',
  DUE_SOON = 'DUE_SOON',
  NOT_DUE = 'NOT_DUE',
}

export enum MAINTENANCE_CONFIG_TYPES {
  BY_MILES = 'BY_MILES',
  BY_ENGINE_HOURS = 'BY_ENGINE_HOURS',
  BY_DATE = 'BY_DATE',
}

export enum MAINTENANCE_FREQUENCY_TYPES {
  ONE_TIME = 'ONE_TIME',
  REPEAT = 'REPEAT',
}

export enum INTERVAL_UNIT {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  WEEKS = 'WEEKS',
  YEARS = 'YEARS',
}

export enum FREQUENCY_TYPE {
  ONE_TIME = 'one_time',
  REPEAT = 'repeat',
}

export enum METRICS_SWITCH_TYPE {
  MILES = 'one_time_miles',
  HOURS = 'one_time_hours',
}

export const MAINTENANCE_INTERVAL_OPTIONS = [
  { value: INTERVAL_UNIT.DAYS, label: 'Day(s)' },
  { value: INTERVAL_UNIT.WEEKS, label: 'Week(s)' },
  { value: INTERVAL_UNIT.MONTHS, label: 'Month(s)' },
  { value: INTERVAL_UNIT.YEARS, label: 'Year(s)' },
];

export enum MAINTENANCE_VIEW {
  DEFAULT = 'default',
  ADMIN = 'admin',
}

export enum MAINTENANCE_APPLY_TASKS_TABLE_COLUMNS {
  REMOVE_TASK = 'removeTask',
  VEHICLE_NAME = 'vehicleName',
  LAST_SERVICE = 'lastService',
  CURRENT_ODOMETER = 'currentOdometer',
  CURRENT_ENGINE_HOURS = 'currentEngineHours',
  NEXT_SERVICE_DUE_IN_ON = 'nextServiceDueInOn',
  SERVICE_DUE_IN = 'serviceDueIn',
  CREATE_TASK = 'createTask',
}

export const MAINTENANCE_APPLY_TASKS_ROW_LOADING_ID = '-1';

export enum USER_DATA_STATUS {
  LOADING = 'loading',
  UPDATED = 'updated',
  STABLE = 'stable',
}

export const BUILD_VERSION = 'build-version';

export const USER_DATA_STATUS_STORAGE = 'user-data-status';

export enum MAINTENANCE_REQUESTS_TABLE_COLUMNS {
  REQUEST_NAME = 'requestName',
  VEHICLE_NAME = 'vehicleName',
  REQUEST_RECEIVED = 'requestReceived',
  REQUESTED_BY = 'requestedBy',
  SERVICE_TYPE = 'serviceType',
  NOTES = 'notes',
  ACTIONS = 'actions',
}

export enum NAVIGATION_ITEM {
  MAP = 'map',
  REPORTS = 'map',
  DASH_CAM_MEDIA = 'dash_cam_media',
  MAINTENANCE = 'maintenance',
  ALERTS = 'alerts',
  DISPATCH = 'dispatch',
  ADMIN = 'admin',
}

export enum INSPECTION_STATUSES {
  ABANDONED = 'ABANDONED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  PASSED = 'PASSED',
}

export enum INSPECTION_RESULTS {
  PASSED_WITH_DEFECTS = 'PASSED_WITH_DEFECTS',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  PASSED = 'PASSED',
  ABANDONED = 'ABANDONED',
}

export enum INSPECTIONS_VIEW {
  DEFAULT = 'default',
  ADMIN = 'admin',
}

export enum INSPECTIONS_SEARCH_PARAMS {
  ASSET_NAME = 'assetName',
  ADDRESS = 'address',
  CREATION_TIME = 'creationTime',
  ELAPSED_TIME = 'elapsedTime',
  ENGINE_HOURS = 'engineHours',
  NAME = 'name',
  ODOMETER_KM = 'odometerKm',
  PERFORMED_BY = 'performedBy',
  PERFORMED_ON = 'performedOn',
  STATUS = 'status',
  TEMPLATE_NAME = 'templateName',
  GROUPS = 'groups',
}

export enum INSPECTIONS_PERFORMED_TABLE_COLUMNS {
  START_DATE_TIME = 'startDateTime',
  VEHICLE = 'vehicle',
  RESULTS = 'results',
  GROUPS = 'groups',
  INSPECTION_FORM = 'inspectionForm',
  COMPLETION_DATE_TIME = 'completionDateTime',
  ELAPSED_TIME = 'elapsedTime',
  INSPECTION_BY = 'inspectionBy',
  LOCATION = 'location',
  ODOMETER_AT_INSPECTION = 'odometerAtInspection',
  ENGINE_HOURS_AT_INSPECTION = 'engineHoursAtInspection',
  ATTACHMENTS = 'attachments',
}

export enum INSPECTIONS_FORMS_TABLE_COLUMNS {
  INSPECTION_FORM = 'inspectionForm',
  APPLIES_TO = 'appliesTo',
  TOTAL_ITEMS = 'totalItems',
  TOTAL_REQUIRED_ITEMS = 'totalRequiredItems',
  ACTIVE = 'active',
}

export enum INSPECTION_ITEM_STATUSES {
  FAILED = 'FAILED',
  N_A = 'N_A',
  PASSED = 'PASSED',
}

export const MAINTENANCE_APPLY_TASK_CREATED_SUCCESSFULLY = 'maintenance:task:created:success:message';

export enum PEOPLE_ADMIN_SEARCH_PARAMS {
  CONTACT_NAME = 'contactName',
  CONTACT_NUMBER = 'contactPhone',
  JOB_TITLE = 'jobTitle',
  ADDRESS = 'address',
  PERMISSIONS = 'permissions',
  ASSIGNED_VEHICLE = 'assignedVehicle',
  EMPLOYEE_ID = 'employeeId',
  LAST_LOGIN = 'lastLogin',
  LAST_MOBILE_APP_LOGIN = 'lastMobileAppLogin',
  LAST_WEB_LOGIN = 'lastWebLogin',
  LOGIN_EMAIL = 'loginEmail',
  LICENCE_EXPIRATION = 'licenceExpiration',
  TIME_ZONE = 'timeZone',
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',
  CREATION_TIME = 'creationTime',
  LAST_UPDATE_TIME = 'lastUpdateTime',
}

export enum PEOPLE_ADMIN_TABLE_COLUMNS {
  ID = 'id',
  NAME = 'name',
  JOB_TITLE = 'jobTitle',
  CONTACT_NUMBER = 'contactPhone',
  USER_ACCESS = 'userAccess',
  USER_ROLE = 'userRole',
  PERMISSIONS = 'permissions',
  ASSIGNED_VEHICLE = 'assignedVehicle',
  EMPLOYEE_ID = 'employeeId',
  ADDRESS = 'address',
  TIME_ZONE = 'timeZone',
  LICENCE_EXPIRATION = 'licenceExpiration',
  FOB_ID = 'fodId',
  LAST_WEB_LOGIN = 'lastWebLogin',
  LAST_MOBILE_APP_LOGIN = 'lastMobileAppLogin',
  LOGIN_EMAIL = 'loginEmail',
  CREATED_BY = 'createdBy',
  CREATED_ON = 'createdOn',
  MODIFIED_BY = 'modifiedBy',
  MODIFIED_ON = 'modifiedOn',
}

export enum PERSON_LOGIN_CREDENTIALS {
  EDIT = 'edit',
  VALIDATE = 'validate',
}

export enum PEOPLE_ADMIN_PERMISSIONS {
  DRIVER = 'DRIVER',
  INSPECTIONS = 'INSPECTIONS',
  MEDIA = 'MEDIA',
  GROUP_RESTRICTIONS = 'GROUP_RESTRICTIONS',
}

export enum PEOPLE_ADMIN_ROLES {
  USER = 'USER',
  DRIVER = 'DRIVER',
  BOTH = 'BOTH',
}
