import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import type { PeopleAdmin } from 'stores/Admin/People';

import Button from 'components/Button';
import Modal from 'components/Modal';
import Input from 'components/Input';
import StrengthProgress from 'components/StrengthProgress';

import { EyeCrossedIcon, EyeIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  peopleAdmin?: PeopleAdmin;
  isOpen: boolean;
  onCancel: () => void;
  onSubmit?: () => void;
}

interface IState {
  isPasswordVisible: boolean;
}

const ChangePersonPasswordModalSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required field')
    .matches(/^\S*$/, 'Password is incorrect')
    .min(8, 'Seems a bit short...')
    .max(30, 'Please try a shorter password')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/\d/, 'Password must contain at least one digit')
    .matches(/[!@#$%^&*=(),.?":{}|<>-]/, 'Password must contain at least one special character'),
});

@inject(({ adminStore: { peopleAdmin } }) => ({
  peopleAdmin,
}))
@observer
class PeopleChangePasswordModal extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isPasswordVisible: false,
    };
  }

  togglePasswordVisible = () => {
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
  };

  renderIcon = () => {
    return this.state.isPasswordVisible ? (
      <EyeIcon fill="#6B7A99" cursor="pointer" onClick={this.togglePasswordVisible} />
    ) : (
      <EyeCrossedIcon fill="#6B7A99" cursor="pointer" onClick={this.togglePasswordVisible} />
    );
  };

  updatePersonPassword = async ({ password }) => {
    const {
      peopleAdmin: { item, updatePassword },
      onCancel,
    } = this.props;

    await updatePassword(item.value.id.value.toString(), password);

    onCancel();
  };

  render() {
    const {
      isOpen,
      onCancel,
      peopleAdmin: { item, repositoryPassword },
    } = this.props;

    return (
      <Modal className="Modal--changePersonPassword" isModalOpen={isOpen} onClose={onCancel}>
        <div className="ChangePersonPasswordModal">
          <p className="ChangePersonPasswordModal-title">Change User Password</p>
          <Formik
            onSubmit={this.updatePersonPassword}
            initialValues={{ password: '' }}
            validationSchema={ChangePersonPasswordModalSchema}
            render={({ errors, touched, isValid, isSubmitting, values }) => {
              return (
                <Form className="ChangePersonPasswordModal-form">
                  <div className="ChangePersonPasswordModal-formContent">
                    <div className="ChangePersonPasswordModal-fields">
                      <div className="ChangePersonPasswordModal-field ChangePersonPasswordModal-field--person">
                        <p className="ChangePersonPasswordModal-fieldLabel">User</p>
                        <p className="ChangePersonPasswordModal-fieldContent ChangePersonPasswordModal-fieldContent--person">
                          {item.value.details.name.value}
                        </p>
                      </div>
                      <div className="ChangePersonPasswordModal-field ChangePersonPasswordModal-field--password">
                        <p className="ChangePersonPasswordModal-fieldLabel">New Password</p>
                        <div className="ChangePersonPasswordModal-fieldContent">
                          <Field
                            component={Input}
                            error={Boolean(errors.password && touched.password) ? errors.password : ''}
                            icon={this.renderIcon()}
                            name="password"
                            type={this.state.isPasswordVisible ? 'text' : 'password'}
                          />
                        </div>
                      </div>
                      <div className="ChangePersonPasswordModal-field ChangePersonPasswordModal-field--strength">
                        <p className="ChangePersonPasswordModal-fieldLabel" />
                        <div className="ChangePersonPasswordModal-fieldContent">
                          <StrengthProgress strengthText="Password Strength" password={values.password} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ChangePersonPasswordModal-buttons">
                    <div className="ChangePersonPasswordModal-button ChangePersonPasswordModal-button--cancel">
                      <Button className="Button--cancel Button--cancelColorLynch" onClick={onCancel} title="Cancel" />
                    </div>
                    <div className="ChangePersonPasswordModal-button ChangePersonPasswordModal-button--submit">
                      <Button
                        className="Button--apply"
                        disabled={!isValid || isSubmitting}
                        sending={repositoryPassword.doState.loading}
                        title="Save"
                        type="submit"
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          />
        </div>
      </Modal>
    );
  }
}

export default PeopleChangePasswordModal;
