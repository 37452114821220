import { action, computed, observable, reaction } from 'mobx';
import moment from 'moment';
import { find, get, orderBy } from 'lodash';

import { repositoryService } from 'services';
import { getEndOfDayByDate, getStartOfDayByDate, getTimeInTimeZone } from 'utils';
import APIStoreBase from 'stores/APIStoreBase';
import timeStore from 'stores/TimeStore';
import dashCamsStore from 'stores/DashCams';
import MediaEventModel from 'models/Media';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import mediaAdapter from '../MediaAdapter/MediaAdapter';

export default class MediaPlayerStore {
  isPolling: boolean = false;

  @observable deviceId: string = '';
  @observable playing: boolean = false;
  @observable seekTime: number = 0;
  @observable progressSecond: number = 0;
  @observable currentEventTimestamp: number = 0;
  @observable listOfCurrentDayMediaEvents: any[] = [];
  @observable mediaObjects: any[] = [];

  @observable mediaEvent: MediaEventModel = new MediaEventModel();
  @observable pollingInterval: number = null;

  @observable eventDataRequestStatus: APIStoreBase = new APIStoreBase();
  @observable currentDayMediaEventsRequestStatus: APIStoreBase = new APIStoreBase();

  repositoryMedia: IRepository;
  repositoryMediaList: IEntityRepository;

  constructor() {
    this.repositoryMedia = repositoryService.get('media');
    this.repositoryMediaList = this.repositoryMedia.entity('list');

    reaction(
      () => {
        return this.currentEventTimestamp;
      },
      () => {
        this.resetVideoPlayer();

        if (this.currentEventTimestamp) {
          this.getEventData(this.currentEventTimestamp);
        }
      },
      {
        name: 'Update event data on change timestamp',
      }
    );
  }

  @computed get currentEventTimestampEnd() {
    const isVideo = this.mediaEvent.outsideCameraVideo || this.mediaEvent.insideCameraVideo;
    const timeToAdd = isVideo ? 20 : 1;
    return moment(this.currentEventTimestamp)
      .add(timeToAdd, 'seconds')
      .valueOf();
  }

  @computed get metaTime() {
    if (this.mediaEvent.listOfGPSEvents.length > 1) {
      const startTimestamp = get(this.mediaEvent.listOfGPSEvents, '[0].timestamp', 0);
      const endTimestamp = get(
        this.mediaEvent.listOfGPSEvents,
        `[${this.mediaEvent.listOfGPSEvents.length - 1}].timestamp`,
        0
      );
      const startTimeFormatted = getTimeInTimeZone(startTimestamp, timeStore.sessionTimezone).format('h:mm:ssa');
      const endTimeFormatted = getTimeInTimeZone(endTimestamp, timeStore.sessionTimezone).format('h:mm:ssa');
      return `${startTimeFormatted} - ${endTimeFormatted}`;
    } else {
      return getTimeInTimeZone(this.currentEventTimestamp, timeStore.sessionTimezone).format('h:mm:ssa');
    }
  }

  @computed get currentMarkerPosition() {
    if (!this.mediaEvent.listOfGPSEvents || !this.mediaEvent.listOfGPSEvents.length) {
      return this.mediaEvent.location;
    }

    const currentIndex =
      this.progressSecond < this.mediaEvent.listOfGPSEvents.length
        ? this.progressSecond
        : this.mediaEvent.listOfGPSEvents.length - 1;
    const eventData = this.mediaEvent.listOfGPSEvents[currentIndex];
    return {
      lat: eventData.latitude,
      lng: eventData.longitude,
    };
  }

  @computed get groupedListOfCurrentDayMediaEvents() {
    const sortedListByTs = orderBy(this.listOfCurrentDayMediaEvents, ['eventTimestamp'], ['asc']);
    const groupedListByBatchId = [];

    sortedListByTs.forEach((sortedItem, _, list) => {
      if (!sortedItem.batchId) {
        groupedListByBatchId.push([sortedItem]);
      }

      const itemsWithSameBatchId = list.filter((item) => item.batchId === sortedItem.batchId);
      const isSortedItemGrouped = groupedListByBatchId.find((item) =>
        item.find((i) => i.batchId === sortedItem.batchId)
      );

      if (!isSortedItemGrouped) {
        groupedListByBatchId.push(itemsWithSameBatchId);
      }
    });

    return groupedListByBatchId;
  }

  @action setDeviceId = (deviceId) => (this.deviceId = deviceId);

  @action setCurrentEventTimestamp = (timestamp) => (this.currentEventTimestamp = Number(timestamp));

  @action onPlay = () => (this.playing = true);
  @action onPause = () => (this.playing = false);
  @action setSeekTime = (seekTime) => (this.seekTime = seekTime);
  @action setProgress = (second) => (this.progressSecond = second);

  @action getEventData = async (time) => {
    // check if we have that event already loaded
    const mediaEvent = find(dashCamsStore.mediaManager.mediaItemsList, { eventTimestamp: time });
    if (mediaEvent) {
      this.mediaEvent = mediaEvent;
      return;
    }

    /*this.eventDataRequestStatus.reset().setLoading(true);
    try {
      const res: Media.IServerMediaEvent = await this.repositoryMedia.entity(this.deviceId).get({ timestamp: time });

      if (!res) {
        /!*setup success in case there are no media for the current timestamp but the status is OK*!/
        this.eventDataRequestStatus.setSuccess(true);
        return;
      }
      this.mediaEvent = new MediaEventModel(res);
      this.mediaObjects = res.mediaObject;

      this.eventDataRequestStatus.setSuccess(true);
    } catch (err) {
      this.eventDataRequestStatus.setError(err);
      this.eventDataRequestStatus.setSuccess(false);
      this.eventDataRequestStatus.setLoading(false);
    }

    this.eventDataRequestStatus.setLoading(false);*/
  };

  setPollingStatus = (status: boolean) => (this.isPolling = status);

  @action clearPollingInterval = () => {
    clearInterval(this.pollingInterval);
    this.pollingInterval = null;
  };

  @action startPollingTodayMediaItems = () => {
    this.pollingInterval = setInterval(async () => {
      // skip this iteration if previous one is not finished
      // or another page is being fetched
      if (this.isPolling || this.currentDayMediaEventsRequestStatus.loading) {
        return;
      }

      try {
        this.setPollingStatus(true);

        const from = getStartOfDayByDate(this.currentEventTimestamp, timeStore.sessionTimezone);
        const to = getEndOfDayByDate(this.currentEventTimestamp, timeStore.sessionTimezone);
        let shouldUpdate = false;
        const listOfMedia = await mediaAdapter.items.getAll({
          from,
          to,
          page: '0',
          selectedCameraId: dashCamsStore.mediaManager.filters.selectedCameraId,
        });

        // listOfMedia should be updated
        // only if statusCodeText of at least one event was changed
        listOfMedia.pageData.map(({ eventTimestamp, status }) => {
          const currentItem = find(this.listOfCurrentDayMediaEvents, { eventTimestamp });
          if (!currentItem || currentItem.status?.toLowerCase() !== status?.toLowerCase()) {
            shouldUpdate = true;
          }
        });

        if (shouldUpdate) {
          this.listOfCurrentDayMediaEvents = listOfMedia.pageData.map(
            (event: Media.IServerMediaEvent) => new MediaEventModel(event)
          );
        }

        this.currentDayMediaEventsRequestStatus.setSuccess(true);
        this.setPollingStatus(false);
      } catch (err) {
        this.setPollingStatus(false);
      }
    }, 5000);
  };

  @action getCurrentDayMediaEvents = async () => {
    const from = getStartOfDayByDate(this.currentEventTimestamp, timeStore.sessionTimezone);
    const to = getEndOfDayByDate(this.currentEventTimestamp, timeStore.sessionTimezone);

    try {
      const listOfMedia = await mediaAdapter.items.getAll({
        from,
        to,
        page: '0',
        selectedCameraId: dashCamsStore.mediaManager.filters.selectedCameraId,
      });
      this.listOfCurrentDayMediaEvents = listOfMedia.pageData.map(
        (event: Media.IServerMediaEvent) => new MediaEventModel(event)
      );
      this.currentDayMediaEventsRequestStatus.setSuccess(true);
    } catch (err) {
      this.listOfCurrentDayMediaEvents = [];
      this.currentDayMediaEventsRequestStatus.setError(err);
      this.currentDayMediaEventsRequestStatus.setSuccess(false);
    }

    this.currentDayMediaEventsRequestStatus.setLoading(false);
  };

  @action resetEventDataRequestStatus = () => {
    this.eventDataRequestStatus.reset();
  };

  @action resetCurrentDayMediaEvents = () => {
    this.currentDayMediaEventsRequestStatus.reset();
  };

  @action resetVideoPlayer = () => {
    this.mediaEvent = new MediaEventModel(null);
    this.playing = false;
    this.seekTime = 0;
    this.progressSecond = 0;
  };

  @action resetMediaPlayer = () => {
    this.resetEventDataRequestStatus();
    this.resetCurrentDayMediaEvents();
    this.resetVideoPlayer();
    this.clearPollingInterval();
    this.listOfCurrentDayMediaEvents = [];
    this.deviceId = '';
    this.currentEventTimestamp = 0;
  };
}
