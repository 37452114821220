import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import {
  MANAGEMENT_PRIMARY_NAV,
  PATHS,
  MAP_VEHICLE_PANEL_FUEL_LEVEL,
  WEB_MANAGEMENT_VEHICLE_FUEL_DETAILS_READ,
} from 'config';
import { UserAccessLevelComponent } from 'stores';
import { formatDecimalNumber } from 'utils';

import LevelBar from 'components/LevelBar';
import MPG from './MPG';

import './styles.scss';

interface IProps {
  details: any;
  showHistory: boolean;
}

const MapVehiclePanelVehicleAttributes: React.FC<IProps> = ({ details, showHistory }) => {
  const vehicleMake = details.vehicleMake ? details.vehicleMake : '';
  const vehicleModel = details.vehicleModel ? details.vehicleModel : '';
  const vehicleYear = details.vehicleYear ? details.vehicleYear : '';
  const fuelLevel =
    details.fuelLevel < 1 && details.fuelLevel > 0 ? 1 : details.fuelLevel > 100 ? 100 : Math.round(details.fuelLevel);
  const isNotShowMPG = !Boolean(details.milesPerGallon) && Boolean(details.tankCapacity);

  const data = [
    { title: 'YMM', value: `${vehicleYear} ${vehicleMake} ${vehicleModel}` },
    { title: 'Plate', value: details.licensePlate },
    { title: 'ODO', value: details.odometer && `${formatDecimalNumber(details.odometer, 1)} mi` },
    { title: 'CP #', value: details.deviceId },
    { title: 'Equip', value: details.equipmentType },
  ];

  const navigationLinkClassName = classNames('MapVehiclePanelVehicleAttributes-navigationLink', {
    'MapVehiclePanelVehicleAttributes-historyEnabled': showHistory,
    'MapVehiclePanelVehicleAttributes-navigationLink--disabled': !Boolean(details.deviceId),
  });

  return (
    <div className="MapVehiclePanelVehicleAttributes">
      <div className="MapVehiclePanelVehicleAttributes-values">
        <UserAccessLevelComponent requiredAccessLevel={[MAP_VEHICLE_PANEL_FUEL_LEVEL]}>
          {Boolean(fuelLevel) && (
            <div className="MapVehiclePanelVehicleAttributes-row" key="fuel">
              <div className="MapVehiclePanelVehicleAttributes-col MapVehiclePanelVehicleAttributes-col-title">
                Fuel
              </div>
              <div className="MapVehiclePanelVehicleAttributes-col MapVehiclePanelVehicleAttributes-col-value">
                <LevelBar value={fuelLevel} />
              </div>
            </div>
          )}
        </UserAccessLevelComponent>
        {isNotShowMPG ? null : (
          <UserAccessLevelComponent requiredAccessLevel={[WEB_MANAGEMENT_VEHICLE_FUEL_DETAILS_READ]}>
            <div className="MapVehiclePanelVehicleAttributes-row" key="fuel">
              <div className="MapVehiclePanelVehicleAttributes-col MapVehiclePanelVehicleAttributes-col-title">MPG</div>
              <div className="MapVehiclePanelVehicleAttributes-col MapVehiclePanelVehicleAttributes-col-value">
                <MPG tankCapacity={details.tankCapacity} mpg={details.milesPerGallon} />
              </div>
            </div>
          </UserAccessLevelComponent>
        )}
        {data.map(({ title, value }) => (
          <div className="MapVehiclePanelVehicleAttributes-row" key={title}>
            <div className="MapVehiclePanelVehicleAttributes-col MapVehiclePanelVehicleAttributes-col-title">
              {title}
            </div>
            <div className="MapVehiclePanelVehicleAttributes-col MapVehiclePanelVehicleAttributes-col-value">
              {value}
            </div>
          </div>
        ))}
      </div>
      <UserAccessLevelComponent requiredAccessLevel={[MANAGEMENT_PRIMARY_NAV]}>
        <div className="MapVehiclePanelVehicleAttributes-navigation">
          <Link
            to={`${PATHS.ADMIN.TABLES.VEHICLES.VEHICLE.replace(':id', details.deviceId)}?tab=vehicle-details`}
            className={navigationLinkClassName}
          >
            View/Edit Vehicle in Admin Page
          </Link>
        </div>
      </UserAccessLevelComponent>
    </div>
  );
};

export default MapVehiclePanelVehicleAttributes;
