import React, { useMemo } from 'react';
import { Observer } from 'mobx-react';
import { convertMillisecondsToFields } from 'utils';
import { RefreshIcon } from 'assets';

import './styles.scss';

interface IProps {
  isPlaying: boolean;
  isEnd: boolean;
  isLiveMode?: boolean;
  replay: () => void;
  timeLeft: number;
}

const MediaManagerPlayerCountDown: React.FC<IProps> = ({ isPlaying, isEnd, isLiveMode = false, replay, timeLeft }) => {
  const data = convertMillisecondsToFields(timeLeft);

  const time = useMemo(() => {
    const minutes = `0${data.minutes}`;
    const seconds = data.seconds < 10 ? `0${data.seconds}` : `${data.seconds}`;

    return `${minutes}:${seconds} `;
  }, [data.minutes, data.seconds]);

  return (
    <Observer
      render={() => (
        <div className="MediaManagerPlayerCountDown">
          {isPlaying && (
            <div className="MediaManagerPlayerCountDown-streaming">
              <i className="MediaManagerPlayerCountDown-streaming--icon" />
              <span className="MediaManagerPlayerCountDown-streaming--text">Streaming {time}</span>
            </div>
          )}
          {isEnd && (
            <div className="MediaManagerPlayerCountDown-restart" onClick={replay}>
              <div className="MediaManagerPlayerCountDown-restart--icon">
                <RefreshIcon />
              </div>
              <span className="MediaManagerPlayerCountDown-restart--text">
                {isLiveMode ? 'Continue Live Stream' : 'Restart Stream'}
              </span>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default MediaManagerPlayerCountDown;
