import { SimpleField } from 'models/Fields';

class AlertIDModel<T> {
  id: SimpleField<T>;
  name: SimpleField<string>;

  constructor(id: T, name: string = '') {
    this.id = new SimpleField(id);
    this.name = new SimpleField(name);
  }

  reset = () => {
    this.id.set(null);
    this.name.set('');
  };
}

export default AlertIDModel;
