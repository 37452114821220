import { observable } from 'mobx';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';
import { ALERTS_TYPE } from 'config';
import AlertWithIntervalModel from './AlertWithInterval';
import { SimpleField, ToggleField } from 'models/Fields';
import AlertIDModel from './AlertID';

class CustomAlertModel extends AlertWithIntervalModel {
  @observable statusCode: AlertIDModel<string>;
  @observable selector: SimpleField<string>;
  @observable isValidSelector: ToggleField;
  @observable queueNotificationEnabled: ToggleField;
  @observable emailNotificationEnabled: ToggleField;

  @observable emailSubject: SimpleField<string>;
  @observable emailText: SimpleField<string>;
  @observable smsText: SimpleField<string>;

  constructor(item) {
    super({
      ...item,
      isIntervalActive: true,
      interval: isNumber(item.interval) ? item.interval : 0,
    });

    this.statusCode = new AlertIDModel(item.statusCode || '0');
    this.selector = new SimpleField(item.selector || '');
    this.isValidSelector = new ToggleField(isBoolean(item.isValidSelector) ? item.isValidSelector : true);
    this.queueNotificationEnabled = new ToggleField(
      isBoolean(item.queueNotificationEnabled) ? item.queueNotificationEnabled : false
    );
    this.emailNotificationEnabled = new ToggleField(
      isBoolean(item.emailNotificationEnabled) ? item.emailNotificationEnabled : true
    );
    this.cronOption = new AlertIDModel(item.cronOption);
    this.intervalFields = ['days', 'hours', 'minutes'];
    this.emailSubject = new SimpleField(item.emailSubject);
    this.emailText = new SimpleField(item.emailText);
    this.smsText = new SimpleField(item.smsText);
    this.urlType = 'custom';
    this.type.reInitialize(ALERTS_TYPE.CUSTOM);
  }

  get model() {
    return {
      ...super.model,
      statusCode: this.statusCode.id.value,
      selector: this.selector.value,
      isValidSelector: this.isValidSelector.value,
      queueNotificationEnabled: this.queueNotificationEnabled.value,
      emailNotificationEnabled: this.emailNotificationEnabled.value,
      cronOption: this.cronOption.id.value,
      emailSubject: this.emailSubject.value,
      emailText: this.emailText.value,
      smsText: this.smsText.value,
    };
  }

  isUpdated(): boolean {
    return (
      super.isUpdated() ||
      this.statusCode.id.isUpdated ||
      this.selector.isUpdated ||
      this.queueNotificationEnabled.isUpdated ||
      this.emailNotificationEnabled.isUpdated ||
      this.cronOption.id.isUpdated ||
      this.emailSubject.isUpdated ||
      this.emailText.isUpdated ||
      this.smsText.isUpdated
    );
  }
}

export default CustomAlertModel;
