import React from 'react';
import { inject, observer } from 'mobx-react';
import { matchPath, RouteComponentProps, withRouter } from 'react-router-dom';
import classnames from 'classnames';

import { PATHS, WEB_REPORTS_SCHEDULED_REPORTS_READ } from 'config';

import { ReportsStore, RouterStore, validateAccessLevel } from 'stores';
import type { ReportGroup } from 'models';

import ReportListItem from 'components/Reports/ReportListItem';
import DraggableCard from 'components/DragableCard';
import SearchInput from 'components/SearchInput';
import Button from 'components/Button';

import { Collapse, List } from 'antd';
import { ChevronIcon, TimeEventIcon } from 'assets';

import './styles.scss';

interface IProps extends RouteComponentProps {
  reportsStore?: ReportsStore;
  routerStore?: RouterStore;
}

@inject(({ reportsStore, routerStore }) => ({
  reportsStore,
  routerStore,
}))
@observer
class ReportsList extends React.Component<IProps, null> {
  handlePinClick = (id: string) => {
    const {
      reportsStore: { pinReportById },
    } = this.props;

    pinReportById(id);
  };

  handleClick = (reportId: string) => {
    this.redirectToReportPage(reportId);
  };

  handleScheduledReportsButtonClick = () => {
    this.props.routerStore.history.push(PATHS.REPORTS.SCHEDULE);
  };

  handleCollapseChange = (ids: string[] | string) => {
    if (Array.isArray(ids)) {
      this.props.reportsStore.collapseReportsById(ids);
    }
  };

  redirectToReportPage = (id: string) => {
    const reportId = this.props.reportsStore.getReportSubGroupDefaultReport(id);
    this.props.routerStore.history.push(PATHS.REPORTS.REPORT.replace(':reportId', reportId).replace(':optionId', ''));
  };

  componentDidMount() {
    const {
      reportsStore: { getReportsList, list },
    } = this.props;

    if (!list.length) {
      getReportsList();
    }
  }

  componentWillUnmount() {
    const {
      reportsStore: { resetSearchQuery },
    } = this.props;

    resetSearchQuery();
  }

  render() {
    const {
      reportsStore: {
        listByGroups,
        activeGroups,
        pinnedList,
        reOrderPinnedList,
        getReportsListRequestStatus,
        searchQuery,
        setSearchQuery,
      },
      location: { pathname },
    } = this.props;

    if (getReportsListRequestStatus.success === false) {
      return null;
    }

    return (
      <div className="ReportsList">
        <div className="ReportsList-header">
          <p className="ReportsList-title">Reports</p>
        </div>
        {validateAccessLevel([WEB_REPORTS_SCHEDULED_REPORTS_READ]) && (
          <div className="ReportsList-scheduled">
            <Button
              title="Scheduled Reports"
              IconComponent={<TimeEventIcon width={17} height={17} />}
              className={classnames('ReportsList-scheduledButton', {
                'ReportsList-scheduledButton--active': matchPath(pathname, {
                  path: PATHS.REPORTS.SCHEDULE,
                }),
              })}
              onClick={this.handleScheduledReportsButtonClick}
            />
          </div>
        )}
        <div className="ReportsList-search">
          <SearchInput placeholder={`Search Reports`} value={searchQuery} onChange={setSearchQuery} />
        </div>
        <div className="ReportsList-content">
          {!listByGroups.length && <div className="ReportsList-noResults">No Results Found</div>}
          <Collapse
            activeKey={activeGroups}
            expandIcon={() => (
              <span className="anticon anticon-right ant-collapse-arrow">
                <ChevronIcon />
              </span>
            )}
            expandIconPosition={'left'}
            onChange={this.handleCollapseChange}
          >
            {pinnedList.length && (
              <Collapse.Panel header={`Pinned Reports`} key={`-0`} className={`ReportsList-group`}>
                <List
                  itemLayout="horizontal"
                  dataSource={pinnedList}
                  renderItem={(item, index) => (
                    <DraggableCard id={item.id} key={item.id} index={Number(index)} moveCard={reOrderPinnedList}>
                      <ReportListItem
                        id={item.id}
                        title={item.title}
                        pinned={item.pinned}
                        description={item.description}
                        onPinClick={this.handlePinClick}
                        onClick={this.handleClick}
                        virtual
                      />
                    </DraggableCard>
                  )}
                />
              </Collapse.Panel>
            )}
            {listByGroups.map((group: ReportGroup) => {
              return (
                <Collapse.Panel header={group.name} key={group.id} className={`ReportsList-group`}>
                  <List
                    itemLayout="horizontal"
                    dataSource={group.reports}
                    renderItem={(item: Report.IReport) => {
                      return (
                        <ReportListItem
                          id={item.id}
                          title={item.title}
                          pinned={item.pinned}
                          description={item.description}
                          onPinClick={this.handlePinClick}
                          onClick={this.handleClick}
                          deprecated={item.deprecated}
                        />
                      );
                    }}
                  />
                </Collapse.Panel>
              );
            })}
          </Collapse>
        </div>
      </div>
    );
  }
}

export default withRouter(ReportsList);
