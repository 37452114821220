import React, { Component, CSSProperties } from 'react';
import { inject, observer } from 'mobx-react';

import { REPORTS_STORE } from 'config';
import type { ReportsStore } from 'stores';

import './styles.scss';

interface IProps {
  index: number;
  reportsStore?: ReportsStore;
  style: CSSProperties;
}

@inject(REPORTS_STORE)
@observer
class ReportTableByGroupRowSummary extends Component<IProps> {
  render() {
    const {
      reportsStore: {
        reportTableGroup: { rows, headerKeys },
      },
      index,
      style,
    } = this.props;
    const items = rows[index][1];

    return (
      <div className="ReportTableByGroupRow ReportTableByGroupRowSummary" style={style}>
        {items.map((item, index) => {
          const id = headerKeys[index];
          return (
            <div key={index} className={`ReportTableByGroupRowSummary--${headerKeys[index]}`}>
              {id !== index ? item?.value : null}
            </div>
          );
        })}
      </div>
    );
  }
}

export default ReportTableByGroupRowSummary;
