import React from 'react';
import { Observer } from 'mobx-react';
import classnames from 'classnames';

import type InspectionApplyTo from 'models/Inspections/Models/InspectionApplyTo';
import type { ToggleField } from 'models/Fields';

import SearchableVehiclesSelect from 'containers/Select/SearchableVehiclesSelect';

import './styles.scss';

interface IProps {
  appliesTo: InspectionApplyTo;
  isError: ToggleField;
}

const InspectionFormPanelVehiclesSelect: React.FC<IProps> = ({ appliesTo, isError }) => {
  const changeVehicleSelect = (option, isAllSelected) => {
    appliesTo.select(option, isAllSelected);
  };

  return (
    <Observer
      render={() => {
        const isShowError = isError.value && !appliesTo.isValid;
        const cn = classnames('InspectionFormPanelVehiclesSelect', {
          'InspectionFormPanelVehiclesSelect--error': isShowError,
        });

        return (
          <div className={cn}>
            <SearchableVehiclesSelect
              multiple
              handleChange={changeVehicleSelect}
              valueSource="assetId"
              value={appliesTo.items.value.map((item) => ({
                value: item.value.id.value,
                label: item.value.name.value,
              }))}
              selectedAll={appliesTo.allAssets.value}
            />
            {isShowError && (
              <p className="InspectionFormPanelVehiclesSelect-errorMessage">At least one vehicle must be selected</p>
            )}
          </div>
        );
      }}
    />
  );
};

export default InspectionFormPanelVehiclesSelect;
