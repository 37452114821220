import React from 'react';
import { Observer } from 'mobx-react';

import type PersonPermissionsModel from 'models/Admin/People/PersonPermissionsModel';
import type PersonAccessModel from 'models/Admin/People/PersonAccessModel';
import { ACL, WEB_DASHCAM_BASIC_READ } from 'config';
import { validateAccessLevel } from 'stores';

import PersonPermissionsDriver from './PersonPermissionsDriver';
import PersonPermissionsVehicleInspector from './PersonPermissionsVehicleInspector';
import PersonPermissionsRestrictVehicleAccess from './PersonPermissionsRestrictVehicleAccess';
import PersonPermissionsMediaAccess from './PersonPermissionsMediaAccess';

import './styles.scss';

interface IProps {
  permissions: PersonPermissionsModel;
  access: PersonAccessModel;
  userPlan: string;
}

const PersonPermissions: React.FC<IProps> = ({ permissions, access, userPlan }) => {
  return (
    <Observer
      render={() => (
        <div className="PersonPermissions">
          <h4 className="PersonPermissions-title">Permission</h4>
          <div className="PersonPermissions-content">
            {validateAccessLevel([ACL.MANAGEMENT.DRIVERS.UPDATE]) && (
              <div className="PersonPermissions-contentDriver">
                <PersonPermissionsDriver
                  driver={permissions.driver}
                  restrictVehicleAccess={permissions.restrictVehicleAccess}
                />
              </div>
            )}
            {validateAccessLevel([WEB_DASHCAM_BASIC_READ]) && (
              <div className="PersonPermissions-contentMediaAccess">
                <PersonPermissionsMediaAccess mediaAccess={permissions.dashcamMediaAccess} />
              </div>
            )}
            {userPlan === 'pro' && (
              <div className="PersonPermissions-contentVehicleInspector">
                <PersonPermissionsVehicleInspector
                  vehicleInspector={permissions.vehicleInspector}
                  isMobileAccessActive={access.mobileAppAccess.active.value}
                />
              </div>
            )}
            <div className="PersonPermissions-contentRestrictVehicleAccess">
              <PersonPermissionsRestrictVehicleAccess
                restrictVehicleAccess={permissions.restrictVehicleAccess}
                driver={permissions.driver}
              />
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default PersonPermissions;
