import Group from 'models/Devices/Groups/Group';
import { ListField, ToggleField } from 'models/Fields';

class PersonPermissionsRestrictVehicleAccessModel {
  active: ToggleField;
  groups: ListField<Group>;

  constructor(groupRestrictions) {
    const groups = groupRestrictions || [];

    this.active = new ToggleField(Boolean(groups.length));
    this.groups = new ListField(groups.map((group) => new Group(group)));
  }

  get data() {
    return this.active.value ? this.groups.toArray().map((group) => group.groupId) : null;
  }

  get isUpdated() {
    return this.active.isUpdated || this.groups.isUpdated;
  }

  reset = () => {
    this.active.reset();
    this.groups.reset();
  };
}

export default PersonPermissionsRestrictVehicleAccessModel;
