import { computed } from 'mobx';
import { pickBy } from 'lodash';
import TableToggleFilter from 'models/Tables/TableToggleFilter';
import { MAINTENANCE_CONFIG_TYPES, MAINTENANCE_DUE_STATUSES, MAINTENANCE_SEARCH_PARAMS } from 'config';

export default class DueIn {
  list: TableToggleFilter[];

  constructor() {
    this.list = [
      { value: MAINTENANCE_DUE_STATUSES.OVERDUE, text: 'Overdue', param: MAINTENANCE_SEARCH_PARAMS.DUE_STATUSES },
      { value: MAINTENANCE_DUE_STATUSES.DUE_SOON, text: 'Due Soon', param: MAINTENANCE_SEARCH_PARAMS.DUE_STATUSES },
      { value: MAINTENANCE_DUE_STATUSES.NOT_DUE, text: 'Not Due', param: MAINTENANCE_SEARCH_PARAMS.DUE_STATUSES },
      { value: MAINTENANCE_CONFIG_TYPES.BY_MILES, text: 'By Miles', param: MAINTENANCE_SEARCH_PARAMS.CONFIG_TYPES },
      {
        value: MAINTENANCE_CONFIG_TYPES.BY_ENGINE_HOURS,
        text: 'By Engine Hours',
        param: MAINTENANCE_SEARCH_PARAMS.CONFIG_TYPES,
      },
      { value: MAINTENANCE_CONFIG_TYPES.BY_DATE, text: 'By Date', param: MAINTENANCE_SEARCH_PARAMS.CONFIG_TYPES },
    ].map((item) => new TableToggleFilter({ isActive: false, ...item }));
  }

  private getValues(filters: TableToggleFilter[]) {
    return filters.reduce((values, filter) => {
      values += values ? `,${filter.value}` : filter.value;

      return values;
    }, '');
  }

  @computed get active() {
    return this.list.filter((filter) => filter.isActive.value);
  }

  @computed get activeDueStatuses() {
    return this.active.filter((filter) => filter.param === MAINTENANCE_SEARCH_PARAMS.DUE_STATUSES);
  }

  @computed get activeConfigTypes() {
    return this.active.filter((filter) => filter.param === MAINTENANCE_SEARCH_PARAMS.CONFIG_TYPES);
  }

  @computed get params() {
    return pickBy(
      {
        [MAINTENANCE_SEARCH_PARAMS.DUE_STATUSES]: this.getValues(this.activeDueStatuses),
        [MAINTENANCE_SEARCH_PARAMS.CONFIG_TYPES]: this.getValues(this.activeConfigTypes),
      },
      (value) => Boolean(value)
    );
  }
}
