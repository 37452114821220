import React, { FC, useState, useRef, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { StreetViewPanorama } from 'react-google-maps';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { Popover } from 'antd';

import { PATHS, DASHCAM_REQUEST_MEDIA, KEY_TYPE } from 'config';

import { KeyboardShortcutsManager } from 'tools';
import { repositoryService } from 'services';

import Button from 'components/Button';
import Map from 'components/Map';
import MapTypeSwitcher from './MapTypeSwitcher';
import Marker from 'components/Map/Marker';
import StreetViewImage from 'components/Map/StreetViewImage';
import UserAccessLevelComponent from 'stores/acl/UserAccessLevelComponent';

import { GoIcon, CameraWithFlashIcon } from 'assets';

import './styles.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
  deviceId?: string;
  isCameraConnected?: boolean;
  location: {
    lat: number;
    lng: number;
  };
  mapType: string;
  setMapType: (type: string) => void;
  timestamp?: number;
}

const TABLE_BODY_SELECTOR = '.ReportPage-table .ant-table-body';

const fetchAsset = async (cpNumber: string) => {
  const repository = repositoryService
    .get('assets')
    .entity('cp-number')
    .entity(cpNumber);

  try {
    const res = await repository.get({ withDevices: true });
    return res;
  } catch (e) {
    return e;
  }
};

const ReportMapPopover: FC<IProps> = ({
  children,
  deviceId,
  isCameraConnected,
  location,
  mapType,
  setMapType,
  timestamp,
}) => {
  const mapRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [liveView, setLiveView] = useState(false);
  const [liveViewImageError, setLiveViewImageError] = useState(false);
  const [assetId, setAssetId] = useState(null);
  let hidePopover;
  const addTableScrollListener = useCallback(() => {
    const tableBodyList = Array.from(document.querySelectorAll(TABLE_BODY_SELECTOR));

    tableBodyList.map((tableBody) => tableBody.addEventListener('scroll', hidePopover));
  }, [hidePopover]);
  const removeTableScrollListener = useCallback(() => {
    const tableBodyList = Array.from(document.querySelectorAll(TABLE_BODY_SELECTOR));

    tableBodyList.map((tableBody) => tableBody.removeEventListener('scroll', hidePopover));
  }, [hidePopover]);
  hidePopover = useCallback(() => {
    setVisible(false);
    removeTableScrollListener();
  }, [removeTableScrollListener]);
  const handleVisibleChange = useCallback(
    (newVisible: boolean) => {
      if (newVisible) {
        addTableScrollListener();
      } else {
        removeTableScrollListener();
      }

      setVisible(newVisible);
    },
    [addTableScrollListener, removeTableScrollListener]
  );
  const toggleMapView = useCallback(() => setLiveView(!liveView), [liveView]);
  const handleLiveViewImageLoadingError = useCallback(() => setLiveViewImageError(true), []);
  const handleLiveViewImageLoadingSuccess = useCallback(() => setLiveViewImageError(false), []);
  const listenEscKeyPress = (e) => {
    if (e.key === KEY_TYPE.ESCAPE) {
      setVisible(false);
    }
  };
  const openMapInWindow = () =>
    window.open(
      `/web${PATHS.MAP.BASIC}?mapType=${mapType}&lat=${location.lat}&lng=${location.lng}&liveView=${liveView}`,
      'reportMap',
      'top=200,left=200,width=1000,height=730'
    );
  const showRequestMediaButton = deviceId && timestamp && isCameraConnected && !liveView;
  const openMapButtonDisabled = liveView && liveViewImageError;
  const mapCn = classNames('ReportMapPopover-map', {
    'ReportMapPopover-map--default': !liveView,
    'ReportMapPopover-map--liveView': liveView,
  });
  const openMapButtonCn = classNames('ReportMapPopover-openMapButton', {
    'ReportMapPopover-openMapButton--disabled': openMapButtonDisabled,
  });

  useEffect(() => {
    KeyboardShortcutsManager.get().add(listenEscKeyPress);

    return () => {
      KeyboardShortcutsManager.get().remove(listenEscKeyPress);
    };
  }, [listenEscKeyPress]);

  useEffect(() => {
    if (visible && isCameraConnected) {
      const fetchData = async () => {
        const data = await fetchAsset(deviceId);
        setAssetId(data.id);
      };

      fetchData().catch(noop);
    }
  }, [visible, deviceId, isCameraConnected]);

  return (
    <Popover
      visible={visible}
      onVisibleChange={handleVisibleChange}
      destroyTooltipOnHide
      overlayClassName="ReportMapPopover"
      content={
        <div className="ReportMapPopover-content">
          <Map
            center={location}
            containerElement={<div className={mapCn} style={{ height: '365px', width: '500px' }} />}
            googleMapURL={process.env.REACT_APP_GOOGLE_MAP_URL}
            loadingElement={<div style={{ height: `100%` }} />}
            map={mapRef}
            mapElement={<div style={{ height: `100%` }} />}
            mapTypeId={mapType}
            options={{
              fullscreenControl: false,
              rotateControl: false,
              streetViewControl: false,
              tilt: 0,
            }}
            zoom={16}
          >
            <div className="ReportMapPopover-controls">
              <div className="ReportMapPopover-typeSwitcher">
                <MapTypeSwitcher onChange={setMapType} value={mapType} />
              </div>
              <UserAccessLevelComponent requiredAccessLevel={[DASHCAM_REQUEST_MEDIA]}>
                {showRequestMediaButton && (
                  <div className="ReportMapPopover-requestMedia">
                    <Link
                      to={`${PATHS.DASHCAMS.MEDIA_REQUEST.STEP_1}?assetId=${assetId}&timestamp=${timestamp}`}
                      className="ReportMapPopover-requestMediaButton"
                    >
                      <CameraWithFlashIcon className="ReportMapPopover-requestMediaButtonIcon" />
                    </Link>
                  </div>
                )}
              </UserAccessLevelComponent>
              <div className="ReportMapPopover-openMap">
                <button className={openMapButtonCn} disabled={openMapButtonDisabled} onClick={openMapInWindow}>
                  <GoIcon className="ReportMapPopover-openMapButtonIcon" />
                </button>
              </div>
            </div>
            <Marker position={location} icon={null} />
            {!liveViewImageError && (
              <StreetViewPanorama
                options={{ enableCloseButton: false, disableDefaultUI: true }}
                position={location}
                visible={liveView}
              />
            )}
            {liveViewImageError && liveView && (
              <div className="ReportMapPopover-noLiveView">
                <p className="ReportMapPopover-noLiveViewMessage">Street View Unavailable</p>
              </div>
            )}
            <StreetViewImage
              className="ReportMapPopover-liveViewImage"
              heading={0}
              height={93}
              latitude={location.lat}
              longitude={location.lng}
              width={149}
              onAbort={handleLiveViewImageLoadingError}
              onError={handleLiveViewImageLoadingError}
              onLoad={handleLiveViewImageLoadingSuccess}
            />
          </Map>
          <div className="ReportMapPopover-viewToggle">
            <Button
              className="Button--link"
              onClick={toggleMapView}
              title={liveView ? 'See Map View' : 'See Street View'}
            />
          </div>
        </div>
      }
      trigger="click"
    >
      {children}
    </Popover>
  );
};

export default ReportMapPopover;
