import React, { useState } from 'react';
import { WEB_FUEL_CARDS_BASIC_DELETE } from 'config';
import validateAccessLevel from 'stores/acl/validator';
import type { IWEXCard } from 'interfaces/stores/Integrations/IWEXCardAssociations';
import Button from 'components/Button';
import Input from 'components/Input';
import ConfirmationModal from 'components/Admin/ConfirmationModal';

import './styles.scss';

interface IProps {
  fuelCard: IWEXCard;
}

const WEXFuelCardVehicleAdmin: React.FC<IProps> = ({ fuelCard }) => {
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);

  return (
    <li className="WEXFuelCardVehicleAdmin">
      <div className="WEXFuelCardVehicleAdmin-cardId">
        <span className="WEXFuelCardVehicleAdmin-label">Associated Card ID</span>
        <Input
          className="WEXFuelCardVehicleAdmin-input WEXFuelCardVehicleAdmin-input--shortFont"
          disabled
          value={fuelCard.cardUniqueCode}
        />
      </div>
      <div className="WEXFuelCardVehicleAdmin-cardName">
        <span className="WEXFuelCardVehicleAdmin-label">Card Name</span>
        <Input className="WEXFuelCardVehicleAdmin-input" disabled value={fuelCard.name} />
      </div>
      <div className="WEXFuelCardVehicleAdmin-accountName">
        <span className="WEXFuelCardVehicleAdmin-label">Account</span>
        <Input
          className="WEXFuelCardVehicleAdmin-input WEXFuelCardVehicleAdmin-input--shortWidth"
          disabled
          value={fuelCard.integration.accountName}
        />
      </div>
      {validateAccessLevel([WEB_FUEL_CARDS_BASIC_DELETE]) && (
        <div className="WEXFuelCardVehicleAdmin-action">
          <Button title="Remove Card Association" className="Button--link" onClick={() => setIsOpenRemoveModal(true)} />
        </div>
      )}
      <ConfirmationModal
        title="Remove Associated Card?"
        applyButtonTitle="Yes, Remove"
        cancelButtonStyles={{ border: 'none' }}
        contentStyles={{ backgroundColor: 'transparent', padding: '0 20px' }}
        isOpen={isOpenRemoveModal}
        onSubmit={fuelCard.removeCardAssociation}
        onCancel={() => setIsOpenRemoveModal(false)}
      >
        <div className="WEXFuelCardVehicleAdmin-RemoveModal--content">
          <p className="WEXFuelCardVehicleAdmin-RemoveModal--contentTop">
            Removing this card association will no longer attach fueling events with this vehicle and mark this card as
            unassociated.
          </p>
          <p className="WEXFuelCardVehicleAdmin-RemoveModal--contentBottom">Do you want to continue?</p>
        </div>
      </ConfirmationModal>
    </li>
  );
};

export default WEXFuelCardVehicleAdmin;
