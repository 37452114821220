import type { IScheduledReportAppliesTo, IScheduledReportAppliesToItem } from './IScheduledReport';
import ScheduledReportAppliesToItem from './ScheduledReportAppliesToItem';

class ScheduledReportAppliesTo implements IScheduledReportAppliesTo {
  group: IScheduledReportAppliesToItem;
  device: IScheduledReportAppliesToItem;

  constructor({ group = {}, device = {} }) {
    this.group = new ScheduledReportAppliesToItem(group);
    this.device = new ScheduledReportAppliesToItem(device);
  }

  set = (group, device) => {
    this.group.id.set(group.id);
    this.group.displayName.set(group.displayName);
    this.device.id.set(device.id);
    this.device.displayName.set(device.displayName);
  };

  getModel = () => {
    return {
      device: {
        displayName: this.device.displayName.value,
        id: this.device.id.value,
      },
      group: {
        displayName: this.group.displayName.value,
        id: this.group.id.value,
      },
    };
  };
}

export default ScheduledReportAppliesTo;
