import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Switch, Route, Redirect } from 'react-router-dom';
import classNames from 'classnames';

import { PATHS, WEB_MAP_TEXT_LOCATION_READ } from 'config';
import {
  DevicesStore,
  GoogleMapSearchStore,
  UserAccessLevelComponent,
  PersistenceStore,
  RouterStore,
  MapStore,
} from 'stores';
import { Group } from 'models';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

import type { SubNavUIStateStore as SubNavUIStateStoreClass } from 'components/SubNav/SubNavUIState.store';
import Collapsible from 'components/SubNav/Collapsible';
import GeozonesRoutes from './GeozonesRoutes';
import GroupsSelector from 'containers/Map/SubNav/GroupsSelector';
import LocationPanel from './LocationPanel';
import SelectedVehicleWithPanel from '../SelectedVehicleWithPanel';
import SubNavNavigation from './SubNavNavigation';
import VehiclesRoutes from './VehiclesRoutes';
import CustomersRoutes from './CustomersRoutes';
import CustomerPanel from './Customers/CustomerPanel';

import './styles.scss';

interface IProps {
  devicesStore?: DevicesStore;
  mapSearchStore?: GoogleMapSearchStore;
  subNavStore?: SubNavUIStateStoreClass;
  persistenceStore?: PersistenceStore;
  routerStore?: RouterStore;
  mapStore?: MapStore;
}

@inject(
  ({
    devicesMapStore: { devicesStore, mapSearchStore, mapStore },
    uiStateStore: { subNav },
    persistenceStore,
    routerStore,
  }) => ({
    devicesStore,
    mapSearchStore,
    mapStore,
    subNavStore: subNav,
    persistenceStore,
    routerStore,
  })
)
@observer
class SubNavContainer extends Component<IProps> {
  async componentDidMount() {
    const {
      devicesStore: { groups },
      persistenceStore: { updateSelectedGroup },
      routerStore: {
        history: { push },
      },
      mapStore: { recenterToFleet },
    } = this.props;

    await groups.fetch();

    if (groups.groupId.value && groups.groupId.value !== 'all') {
      const group = groups.findGroupById(groups.groupId.value);

      if (group) {
        groups.setSelected(group);
        updateSelectedGroup({ label: group.displayName, value: group.groupId });
      } else {
        const group = new Group({
          assetCount: null,
          description: 'All Vehicles',
          displayName: 'All Vehicles',
          groupId: 'all',
        });

        EventsBus.get().once(APP_EVENTS.DEVICES.GET, recenterToFleet);

        groups.setSelected(group);
        updateSelectedGroup({
          label: group.displayName,
          value: group.groupId,
        });

        push({ pathname: PATHS.MAP.VEHICLES });
      }
    }
  }

  render() {
    const {
      devicesStore: { showHistory },
      mapSearchStore: { searchResults },
      subNavStore: { isExpanded, setSubNavMenuState },
    } = this.props;
    const subNavCn = classNames('SubNav', {
      'SubNav--showHistory': showHistory,
    });

    return (
      <Collapsible isExpanded={isExpanded} onClick={setSubNavMenuState}>
        <div className="Collapsible-collapsed">
          <GroupsSelector short />
        </div>
        <div className="Collapsible-expanded">
          <div className={subNavCn}>
            <div className="SubNav-panel">
              <Switch>
                <Route exact path={PATHS.MAP.VEHICLE} component={SelectedVehicleWithPanel} />
                <Route exact path={PATHS.MAP.GROUP_VEHICLE} component={SelectedVehicleWithPanel} />
                <Route path={PATHS.MAP.CUSTOMER} component={CustomerPanel} />
              </Switch>
              <UserAccessLevelComponent requiredAccessLevel={[WEB_MAP_TEXT_LOCATION_READ]}>
                {searchResults?.latitude && searchResults?.longitude && (
                  <div className="SubNav-locationPanel">
                    <LocationPanel />
                  </div>
                )}
              </UserAccessLevelComponent>
            </div>
            <div className="SubNav-navigation">
              <Switch>
                <Route path={PATHS.MAP.VEHICLES_TIME_FILTER} render={null} />
                <Route path={PATHS.MAP.GROUPS_TIME_FILTER} render={null} />
                <Route path={PATHS.MAP.VEHICLE_PREDEFINED_TIME_FILTER} render={null} />
                <Route path={PATHS.MAP.VEHICLE_TIME_FILTER} render={null} />
                <Route path={PATHS.MAP.GEOZONE} render={null} />
                <Route component={SubNavNavigation} />
              </Switch>
            </div>
            <div className="SubNav-content">
              <Switch>
                <Redirect exact from={PATHS.MAP.INDEX} to={PATHS.MAP.VEHICLES} />
                <Route path={PATHS.MAP.VEHICLES} component={VehiclesRoutes} />
                <Route path={PATHS.MAP.GROUPS} component={VehiclesRoutes} />
                <Route path={PATHS.MAP.GEOZONES} component={GeozonesRoutes} />
                <Route path={PATHS.MAP.CUSTOMERS} component={CustomersRoutes} />
                <Redirect from="*" to={PATHS.NOT_FOUND} />
              </Switch>
            </div>
          </div>
        </div>
      </Collapsible>
    );
  }
}

export default SubNavContainer;
