import { computed, observable, action } from 'mobx';

import TableBase from '../../TableBase';
import type { IEditColumns } from 'models/Tables/IEditColumns';
import type { ISortColumn } from 'models/Tables/ISortColumn';

import { STORAGE_ITEMS, WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS } from 'config';
import type { IWEXCardTable } from 'interfaces/stores/Integrations/IWEXCardAssociations';

import type { IFuelTypes } from 'interfaces/stores/Assets/IFuelTypes';
import FuelTypes from 'models/Assets/FuelTypes/FuelTypes';
import FuelCards from 'models/Integrations/WEXCardAssociations/FuelCards';

const initialColumns: Array<IEditColumns<WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS>> = [
  { value: WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS.CARD_ID, isSelected: true },
  { value: WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS.NAME, isSelected: true },
  { value: WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS.DRIVER_NAME, isSelected: true },
  { value: WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS.VEHICLE_ASSOCIATION, isSelected: true },
  { value: WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS.FUEL_TYPE, isSelected: true },
  { value: WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS.TANK_CAPACITY, isSelected: true },
];

const initialSortedColumn: ISortColumn = {
  field: 'cardUniqueCode',
  order: 'ascend',
};

const storageNames = {
  columns: STORAGE_ITEMS.admin.integrations.columns,
  sortedColumn: STORAGE_ITEMS.admin.integrations.sortedColumn,
  pagination: STORAGE_ITEMS.admin.integrations.pagination,
  searchInColumn: STORAGE_ITEMS.admin.integrations.searchInColumn,
};

enum WEXCardsSearchMap {
  cardUniqueCode = 'cardUniqueCode',
  name = 'name',
  driverName = 'driverName',
}

interface IWEXCardsSearchParams {
  cardUniqueCode?: string;
  name?: string;
}

export class WEXCardAssociationsTableStore extends TableBase<IEditColumns<WEX_CARD_ASSOCIATIONS_TABLE_COLUMNS>> {
  @observable fuelCards: FuelCards;
  @observable fuelTypeOptions: IFuelTypes;

  constructor() {
    super({ columns: initialColumns, sortedColumn: initialSortedColumn, storageNames });

    this.fuelCards = new FuelCards();
    this.fuelTypeOptions = new FuelTypes();
  }

  @computed get wexCardsTableSource(): IWEXCardTable[] {
    return this.fuelCards.items.map(({ assetId, asset, cardUniqueCode, name, driverName, id, fuelDetails }) => ({
      assetId,
      asset,
      cardUniqueCode,
      name,
      driverName: driverName || '-',
      key: id,
      fuelDetails,
    }));
  }

  @computed get sortData() {
    return {
      sortBy: WEXCardsSearchMap[this.sortedColumn.field],
      order: this.sortedColumn.order?.replace('end', '').toUpperCase(),
    };
  }

  @computed get searchData() {
    return this.searchColumns.reduce(
      (acc, cur) => ({
        ...acc,
        [WEXCardsSearchMap[cur.column]]: cur.value,
      }),
      {}
    );
  }

  @action getAllWEXCards = async ({
    order,
    page,
    search,
    pageSize,
    sortBy,
    integrationId,
  }: {
    order?: string;
    page?: number;
    pageSize?: number;
    search?: IWEXCardsSearchParams;
    sortBy?: string;
    integrationId?: number;
  }) => {
    await this.fuelCards.fetch({ order, page, pageSize, sortBy, search, integrationId });
  };
}

export default new WEXCardAssociationsTableStore();
