import MediaManagerStore from './MediaManager';
import MediaPlayerStore from './MediaPlayer';
import MediaRequestStore from './MediaRequest';

export class DashCamsStore {
  mediaManager: MediaManagerStore;
  mediaPlayer: MediaPlayerStore;
  mediaRequest: MediaRequestStore;

  constructor() {
    this.mediaManager = new MediaManagerStore();
    this.mediaPlayer = new MediaPlayerStore();
    this.mediaRequest = new MediaRequestStore();
  }
}

export default new DashCamsStore();
