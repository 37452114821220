import type { IMediaDateOption } from 'interfaces/models/Dashcams/IMediaDateOption';
import { ToggleField } from 'models/Fields';

export default class MediaDateOptionProperty implements IMediaDateOption<{ [key: string]: boolean }> {
  action: boolean;
  isSelected: ToggleField;
  name: string;
  value: string;

  constructor({ action, name, isSelected, value }) {
    this.action = action;
    this.isSelected = new ToggleField(isSelected);
    this.name = name;
    this.value = value;
  }

  get params() {
    return { [this.value]: this.isSelected.value };
  }
}
