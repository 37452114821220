import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type { GeozonesStore } from 'stores';
import { repositoryService } from 'services';

import SearchableSelect from 'components/Select/SearchableSelect';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

import { ChevronIcon } from 'assets';

import './styles.scss';

interface IProps {
  geozonesStore?: GeozonesStore;
  onSelect: (option: string) => void;
}

interface IState {
  isOpen: boolean;
}

@inject(({ geozonesStore }) => ({
  geozonesStore,
}))
@observer
class MapGeozoneSelector extends Component<IProps, IState> {
  state = {
    isOpen: false,
  };

  repositorySearch: IEntityRepository;

  constructor(props) {
    super(props);

    this.repositorySearch = repositoryService.get('geozones').entity('ids');
  }

  getData = (props: { page: number; pageSize: number; filter: string }) => {
    const data = {
      page: props.page || 0,
      pageSize: props.pageSize || 50,
      searchValue: props.filter || '',
      onlyActive: true,
      order: 'ASC',
      sortBy: 'name',
    };

    return this.repositorySearch.get(data).then((data) => {
      const totalPages = data.totalPages;
      const options = data.items.map((item) => {
        const value = String(item.id);
        return {
          value,
          label: item.name || value,
        };
      });

      return {
        totalPages,
        options,
      };
    });
  };

  handleClick = () => {
    this.setState({
      isOpen: true,
    });
  };

  handleChange = (option: Select.ISelectOption) => {
    this.props.onSelect(option.value);

    this.setState({
      isOpen: false,
    });
  };

  onMenuClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  render() {
    const {
      geozonesStore: { selectedGeozone },
    } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="MapGeozoneSelector">
        {isOpen ? (
          <div className="MapGeozoneSelector-select">
            <SearchableSelect
              autoFocus
              getData={this.getData}
              handleChange={this.handleChange}
              isMenuOpen
              onMenuClose={this.onMenuClose}
              value={{ value: String(selectedGeozone?.id), label: selectedGeozone?.name }}
              sortOptions
            />
          </div>
        ) : (
          <div className="MapGeozoneSelector-container" onClick={this.handleClick}>
            <div className="MapGeozoneSelector-title">{selectedGeozone?.name}</div>
            <span className="MapGeozoneSelector-caret">
              <ChevronIcon className="MapGeozoneSelector-caretIcon" />
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default MapGeozoneSelector;
