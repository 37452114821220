import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';

import type ReportTableGroupHeader from 'models/Report/ReportTableGroup/ReportTableGroupHeader';

import './styles.scss';

interface IProps {
  header: ReportTableGroupHeader;
  toggle: (value: string) => void;
}

@observer
class ReportGroupedTableHeaderSorter extends Component<IProps> {
  handleClick = () => {
    const { toggle, header } = this.props;

    toggle(header.key);
  };

  render() {
    const { header } = this.props;

    const upIconClassName = classNames('ant-table-column-sorter-up', {
      active: header.sortDirection === 'ASC',
    });

    const downIconClassName = classNames('ant-table-column-sorter-down', {
      active: header.sortDirection === 'DESC',
    });

    return (
      <div className="ReportGroupedTableHeaderSorter" onClick={header.sortable ? this.handleClick : undefined}>
        <div className="ant-table-column-sorters">
          <span className="ant-table-column-title">{header.value}</span>
          {header.sortable && (
            <span className="ant-table-column-sorter ant-table-column-sorter-full">
              <span className="ant-table-column-sorter-inner">
                <CaretUpFilled className={upIconClassName} />
                <CaretDownFilled className={downIconClassName} />
              </span>
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default ReportGroupedTableHeaderSorter;
