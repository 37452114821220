import React from 'react';

import type { SimpleField } from 'models/Fields';

import AlertPreferencesOptionItem from '../AlertPreferencesOptionItem';

interface IProps {
  disabled?: boolean;
  type: Alerts.Modal.Options.Type;
  field: SimpleField<number>;
}

const AlertPreferencesOptionItemFabric: React.FC<IProps> = ({ type, field, ...props }) => {
  switch (type) {
    case 'weeks':
      return <AlertPreferencesOptionItem {...props} field={field} title="week(s)" min={0} max={52} />;
    case 'days':
      return <AlertPreferencesOptionItem {...props} field={field} title="day(s)" min={0} max={999} />;
    case 'hours':
      return <AlertPreferencesOptionItem {...props} field={field} title="hour(s)" min={0} max={999} />;
    case 'minutes':
      return <AlertPreferencesOptionItem {...props} field={field} title="minute(s)" min={0} max={999} />;
    case 'seconds':
      return <AlertPreferencesOptionItem {...props} field={field} title="second(s)" min={0} max={59} />;
    default:
      return null;
  }
};

export default AlertPreferencesOptionItemFabric;
