import { KM_TO_MI } from 'config';
import { SimpleField } from 'models/Fields';

class MaintenanceCompleteInfoModel {
  taskName: SimpleField<string>;
  vehicleName: string;
  currentOdometer: string;
  currentEngineHours: string;

  constructor({ taskName, vehicleName, currentOdometer, currentEngineHours }) {
    this.taskName = new SimpleField(taskName);
    this.vehicleName = vehicleName;
    this.currentOdometer = this.convertValue(currentOdometer);
    this.currentEngineHours = currentEngineHours;
  }

  private convertValue = (value: number) => {
    return value ? (value * KM_TO_MI).toFixed(1) : '0';
  };
}

export default MaintenanceCompleteInfoModel;
