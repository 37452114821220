// import Stomp, { Client } from 'stompjs';
import { Client, StompSubscription } from '@stomp/stompjs';
import find from 'lodash/find';
import { getBrowserInfo, getCookie } from 'utils';
// import { isDevelop } from 'config';
import { TOKEN_COOKIE_NAME } from 'config/cookie';

const clientAppInfo = getBrowserInfo();

class Websockets {
  ws: Client;
  headers: {
    'Client-App-Info': string;
  } = {
    'Client-App-Info': clientAppInfo,
  };
  subscriptions: StompSubscription[] = [];

  connectWS = (callback, errorCallback) => {
    if (this.isConnected) {
      return;
    }
    const token = getCookie(TOKEN_COOKIE_NAME);
    this.ws = new Client({
      brokerURL: `${process.env.REACT_APP_WS_HOST_DELIVERY}?Authorization=${token}`,
      connectHeaders: this.headers,
      /*debug: isDevelop
        ? (err) => {
            // tslint:disable-next-line:no-console
            console.log(err);
          }
        : () => void 0,*/
      reconnectDelay: 500,
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,
    });

    this.ws.onConnect = callback;
    this.ws.onStompError = errorCallback;

    this.ws.activate();
  };

  disconnectWS = (callback?) => {
    if (this.ws) {
      this.ws.reconnectDelay = 0;
      this.ws.deactivate().then(callback);
    }
  };

  subscribeTo = (path: string, callback) => {
    if (this.isConnected) {
      const subscription = this.ws.subscribe(path, callback, this.headers);
      this.subscriptions.push(subscription);
    }
  };

  unsubscribeFrom = (path: string) => {
    const subscription = find(this.subscriptions, { path });
    if (subscription) {
      this.ws.unsubscribe(subscription.id);
    }
  };

  unsubscribeFromAll = () => {
    this.subscriptions.forEach(({ id }) => this.ws.unsubscribe(id));
    this.subscriptions = [];
  };

  get isConnected() {
    return this.ws && this.ws.connected;
  }
}

export const websockets = new Websockets();
