import React, { FC } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'antd';

import type { Group } from 'models';
import AdminVehiclesGroupsFilter from 'components/Admin/VehiclesGroupsFilter';

import { CaretDownOutlined } from '@ant-design/icons';
import { VehicleGroupIcon } from 'assets';

import './styles.scss';

interface IProps {
  className?: string;
  cnModificator?: string;
  list: Group[];
  selectedGroup: Group;
  setSelectedGroup: (group: Group) => void;
  showTooltip?: boolean;
  vehiclesCounter?: number;
  withAll?: boolean;
  withAllCounter?: boolean;
}

const GroupsSelector: FC<IProps> = ({
  className,
  cnModificator,
  list,
  selectedGroup,
  setSelectedGroup,
  showTooltip,
  vehiclesCounter,
  withAll,
  withAllCounter,
}) => {
  const cn = classNames('GroupsSelector', className, {
    'GroupsSelector--short': withAllCounter,
  });
  const iconCn = classNames('GroupsSelector-iconImg', {
    'GroupsSelector-iconImg--active': Boolean(selectedGroup && selectedGroup.groupId !== 'all'),
  });
  const Icon = () => {
    return showTooltip ? (
      <Tooltip
        arrowPointAtCenter
        title="Group Name"
        placement="bottomLeft"
        color="white"
        overlayInnerStyle={{ color: 'black' }}
      >
        <VehicleGroupIcon className={iconCn} />
      </Tooltip>
    ) : (
      <VehicleGroupIcon className={iconCn} />
    );
  };

  const Trigger: FC<{ withAllCounter?: boolean }> = ({ withAllCounter }) => {
    return (
      <div className="GroupsSelector-trigger">
        <span className="GroupsSelector-title">
          {selectedGroup ? (
            selectedGroup.displayName
          ) : (
            <>
              <span className="GroupsSelector-titleText">All Vehicles</span>
              {withAllCounter && <span className="GroupsSelector-titleCount"> ({vehiclesCounter})</span>}
            </>
          )}
        </span>
        <CaretDownOutlined className="GroupsSelector-arrowImg" />
      </div>
    );
  };

  const handleChange = (group: Group) => {
    setSelectedGroup(group);
  };

  return (
    <div className={cn}>
      <div className="GroupsSelector-icon">
        <Icon />
      </div>
      <div className="GroupsSelector-dropdown">
        <AdminVehiclesGroupsFilter
          cnModificator={cnModificator}
          list={list}
          onChange={handleChange}
          selectedGroup={selectedGroup}
          Trigger={<Trigger withAllCounter={withAllCounter} />}
          withAll={withAll}
        />
      </div>
    </div>
  );
};

export default GroupsSelector;
