import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import SearchableSelect from 'components/Select/SearchableSelect';

import type { PersistenceStore } from 'stores';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';

interface IProps {
  persistenceStore?: PersistenceStore;
  value?: Select.ISelectOption;
  handleChange?: (option: Select.ISelectOption, system?: boolean) => void;
  placeholder?: string;
  searchPlaceholder?: string;
  emptySearchResults?: string;
  isSearchable?: boolean;
  persistChange?: boolean;
  disabled?: boolean;
  withAll?: boolean;
  withNone?: boolean;
  alignRight?: boolean;
  selectFirst?: boolean;
  timeout?: number;
  multiple?: boolean;
}

@inject(({ persistenceStore }) => ({ persistenceStore }))
@observer
class SearchableKeyFobSelect extends Component<IProps> {
  static defaultProps = {
    placeholder: 'Select',
    searchPlaceholder: 'Search',
    emptySearchResults: 'No Key Fob Found',
  };

  static allOption = { label: 'All Fobs', value: 'all' };
  static noneOption = { label: '-', value: 'none' };
  repositoryFobIds: IEntityRepository;

  constructor(props) {
    super(props);

    if (props.persistChange && props.persistenceStore.selectedKeyFob && !props.value) {
      this.props.handleChange(props.persistenceStore.selectedKeyFob);
    } else if (props.withAll && !props.value?.value) {
      this.props.handleChange(SearchableKeyFobSelect.allOption);
    }

    this.repositoryFobIds = repositoryService.get('drivers').entity('key-fob').entity('ids');
  }

  getData = (props: { page: number; pageSize: number; filter: string }) => {
    const data = {
      page: props.page,
      pageSize: props.pageSize,
      searchValue: props.filter,
    };

    return this.repositoryFobIds.get(data).then((data) => {
      const totalPages = data.totalPages;
      const options = data.items.map((item) => {
        return {
          value: item.id,
          label: item.keyFob,
          badge: item.user ? item.user.contactName : '',
        };
      });

      if (!props.filter && this.props.withAll && props.page === 0) {
        options.unshift(SearchableKeyFobSelect.allOption);
      }

      if (!props.filter && this.props.withNone && props.page === 0) {
        options.unshift(SearchableKeyFobSelect.noneOption);
      }

      if (this.isOptionNotInOptions(props, options, this.props.value, this.props.value?.value)) {
        this.updateOptions(options, this.props.value);
      }

      if (
        this.isOptionNotInOptions(props, options, this.props.persistChange, this.props.persistenceStore?.selectedKeyFob)
      ) {
        this.updateOptions(options, this.props.persistenceStore.selectedKeyFob);
      }

      if (this.props.selectFirst && options.length && !this.props.value?.value) {
        this.props.handleChange(options[0], true);
      }

      return {
        totalPages,
        options,
      };
    });
  };

  updateOptions = (options, option) => {
    options.push({ ...option });
  };

  isOptionNotInOptions = (props, options, option, optionValue) => {
    return (
      !props.filter && props.page === 0 && option && optionValue && !options.find((item) => item.value === optionValue)
    );
  };

  handleChange = (option: Select.ISelectOption) => {
    this.props.handleChange(option);
    if (this.props.persistChange) {
      this.props.persistenceStore.updateSelectedKeyFob({ ...option });
    }
  };

  render() {
    return (
      <SearchableSelect
        {...this.props}
        getData={this.getData}
        handleChange={this.handleChange}
        hasBadge
        alignRight={this.props.alignRight}
      />
    );
  }
}

export default SearchableKeyFobSelect;
