import React from 'react';
import { observer } from 'mobx-react';

import { Radio } from 'antd';
import type { ToggleField } from 'models/Fields';

import './styles.scss';

interface IProps {
  ignitionOn: ToggleField;
}

const AlertPreferencesIgnition: React.FC<IProps> = ({ ignitionOn }) => {
  const handleChangeType = (e) => {
    const value = e.target.value;
    if (['on', 'off'].includes(value)) {
      ignitionOn.toggle(value === 'on');
    }
  };

  return (
    <div className="AlertPreference-line">
      <div className="AlertPreference-label">Ignition</div>
      <div className="AlertPreference-value AlertPreferencesIgnition-value">
        <Radio.Group
          onChange={handleChangeType}
          value={ignitionOn.value ? 'on' : 'off'}
          className="AlertPreferencesIgnition-switch"
        >
          <Radio.Button value="on" className="AlertPreferencesIgnition-switchButton">
            On
          </Radio.Button>
          <Radio.Button value="off" className="AlertPreferencesIgnition-switchButton">
            Off
          </Radio.Button>
        </Radio.Group>
      </div>
    </div>
  );
};

export default observer(AlertPreferencesIgnition);
