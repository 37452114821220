import React from 'react';
import { Observer } from 'mobx-react';
import SearchableSelectAsync from 'components/Select/SearchableSelectAsync';
import type { IFuelDetailsId, IFuelDetailsIds, IFuelDetailsIdServer } from 'interfaces/stores/Assets/IFuelDetails';
import type { SimpleField } from 'models/Fields';

interface IProps {
  assetId: SimpleField<string>;
  asset: IFuelDetailsId;
  fuelDetails: IFuelDetailsIds;
  onChange?: (option: Select.ISelectOption) => void;
}

const WEXCardAssociationsVehicle: React.FC<IProps> = ({ assetId, asset, fuelDetails, onChange }) => {
  const handleChange = (option: Select.ISelectOption) => {
    assetId.set(option.value);

    if (onChange) {
      onChange(option);
    }
  };

  return (
    <Observer
      render={() => (
        <>
          <SearchableSelectAsync<IFuelDetailsId, IFuelDetailsIdServer>
            handleChange={handleChange}
            options={fuelDetails}
            keys={{ value: 'assetId', label: 'displayName' }}
            value={{ value: assetId.value, label: assetId.value === '0' ? 'None' : asset.displayName }}
            isGetOptionsOnMenuOpen
            hasBadgeExtended
          />
        </>
      )}
    />
  );
};

export default WEXCardAssociationsVehicle;
