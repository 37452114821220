import { DASHCAMS_SETTINGS_TYPES } from 'config';
import { DashcamSetting } from './DashcamSetting';
import { ListField, SimpleField } from '../Fields';
import type { IDashcamSettingsEnumField } from 'interfaces/models/Dashcams/IDashcamSettingsEnumField';

interface IValue {
  id: string;
  name: string;
}

export class DashcamSettingRadio
  extends DashcamSetting<SimpleField<string>>
  implements IDashcamSettingsEnumField<SimpleField<string>>
{
  allowableValues: ListField<IValue>;

  constructor(context, setting, level: number = 0) {
    super(context, { ...setting, level, type: DASHCAMS_SETTINGS_TYPES.ENUM });
    this.value = new SimpleField(setting.value);
    this.allowableValues = new ListField(setting.allowableValues);
  }

  get model() {
    return { ...super.model, value: this.value.value };
  }

  reset = () => {
    this.settings?.forEach((setting) => setting.reset?.());
    this.value.reset();
  };
}
