import React, { FC } from 'react';
import MapLabel from '../MapLabel';

interface IProps {
  label: string;
  position: Locations.ILocation | google.maps.LatLng;
}

const getPixelPositionOffset = (width, height) => ({
  y: -height / 2,
  x: -width / 2,
});

const GeozoneLabel: FC<IProps> = ({ label, position }) => (
  <MapLabel label={label} position={position} getPixelPositionOffset={getPixelPositionOffset} />
);

export default GeozoneLabel;
