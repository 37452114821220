import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { PERSON_LOGIN_CREDENTIALS } from 'config';

import { CautionIcon, LoaderGreyIcon, UserIcon } from 'assets';

import type { PeopleAdmin } from 'stores/Admin/People';
import type { UserStore } from 'stores';

import Note from 'components/Note';
import InputNumber from 'components/InputNumber';
import Toggle from 'components/Toggle';
import AdminField from 'components/Admin/AdminField';
import Button from 'components/Button';
import Input from 'components/Input/AntInput';
import InfoTooltip from 'components/InfoTooltip';
import StateSelect from 'containers/Select/StateSelect';
import PersonLoginCredentials from 'components/Admin/People/PersonLoginCredentials';
import PhoneNumberInput from 'components/PhoneNumberInput';

import './styles.scss';

interface IProps {
  peopleAdmin?: PeopleAdmin;
  userStore?: UserStore;
}

interface IState {
  isChangeEmailModalOpen: boolean;
}

@inject(({ adminStore: { peopleAdmin }, userStore }) => ({
  peopleAdmin,
  userStore,
}))
@observer
class AdminPersonalDetails extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isChangeEmailModalOpen: false,
    };
  }

  handleStateChange = (option: Select.ISelectOption) => {
    const {
      peopleAdmin: {
        item: {
          value: { details },
        },
      },
    } = this.props;

    details.address.state.set({ state: option.value, code: option.label });
  };

  handleAvatarUpdate = async (event) => {
    const {
      peopleAdmin: {
        item: {
          value: { details },
        },
      },
    } = this.props;

    await details.avatar.attach(event.target.files[0]);
    await details.avatar.upload();
    this.updateProfileImage();
  };

  updateProfileImage = () => {
    const {
      peopleAdmin: { item },
      userStore: { userData, setUserAvatar },
    } = this.props;
    const personId = item.value.id.value;
    const appUserId = Number(userData.id);

    if (personId === appUserId) {
      setUserAvatar({ url: item.value.details.avatar.model.source.value, type: item.value.details.avatar.model.type });
    }
  };

  openChangeEmailEditModal = () => {
    this.setState({ isChangeEmailModalOpen: true });
  };

  closeChangeEmailEditModal = () => {
    this.setState({ isChangeEmailModalOpen: false });
  };

  render(): React.ReactNode {
    const {
      peopleAdmin: {
        item: {
          value: { details },
        },
      },
    } = this.props;

    const { isChangeEmailModalOpen } = this.state;

    return (
      <div className="AdminPersonalDetails">
        <div className="AdminPersonalDetails-main">
          <div className="AdminPersonalDetails-mainPersonalDetails">
            <div className="AdminPersonalDetails-mainPersonalDetailsAvatar">
              {details.avatar.repository.createState.loading ? (
                <div className="AdminPersonalDetails-mainPersonalDetailsAvatarLoading">
                  <LoaderGreyIcon />
                </div>
              ) : details.avatar.model.url.value ? (
                <div className="AdminPersonalDetails-mainPersonalDetailsAvatarImage">
                  <div
                    style={{ backgroundImage: `url(${details.avatar.model.source.value})` }}
                    className="AdminPersonalDetails-mainPersonalDetailsAvatarImageContent"
                  />
                  <label
                    className="AdminPersonalDetails-mainPersonalDetailsAvatarImageEdit"
                    htmlFor="AdminPersonalDetails-addAvatar"
                  >
                    Edit
                  </label>
                </div>
              ) : (
                <div className="AdminPersonalDetails-mainPersonalDetailsAvatarUnknown">
                  <label className="AdminPersonalDetails-mainPersonalDetailsAvatarUnknownContent">
                    <UserIcon />
                  </label>
                  <label
                    className="AdminPersonalDetails-mainPersonalDetailsAvatarUnknownEdit"
                    htmlFor="AdminPersonalDetails-addAvatar"
                  >
                    Edit
                  </label>
                </div>
              )}
              <input
                accept="image/*, .heic"
                className="AdminPersonalDetails-mainPersonalDetailsAvatarAdd"
                id="AdminPersonalDetails-addAvatar"
                multiple={false}
                onChange={this.handleAvatarUpdate}
                type="file"
              />
            </div>
            <AdminField<string>
              field={details.name}
              title="Name"
              className="AdminPersonalDetails-mainPersonalDetailsName"
              showError={!Boolean(details.name.value)}
              errorMessage="Name is a required field"
            />
            <AdminField<string>
              field={details.jobTitle}
              title="Job Title"
              className="AdminPersonalDetails-mainPersonalDetailsJobTitle"
            />
            <div className="AdminPersonalDetails-mainPersonalDetailsEmployeeId">
              <p className="AdminPersonalDetails-mainLabel AdminPersonalDetails-mainPersonalDetailsEmployeeIdLabel">
                Employee ID
              </p>
              <div className="AdminPersonalDetails-mainValue AdminPersonalDetails-mainPersonalDetailsEmployeeIdInput">
                <InputNumber
                  value={details.employeeId.value?.toString()}
                  onChange={(value) => details.employeeId.set(Number(value))}
                  controls={false}
                />
              </div>
            </div>
            <div className="AdminPersonalDetails-mainPersonalDetailsActive">
              <p className="AdminPersonalDetails-mainLabel AdminPersonalDetails-mainPersonalDetailsActiveLabel">
                Active
              </p>
              <div className="AdminPersonalDetails-mainValue AdminPersonalDetails-mainPersonalDetailsActiveToggle">
                <Toggle checked={details.active.value} onChange={details.active.toggle} />
              </div>
            </div>
          </div>
          <div className="AdminPersonalDetails-mainAddress">
            <AdminField<string>
              field={details.address.street}
              title="Street Address"
              className="AdminPersonalDetails-mainAddressStreet"
            />
            <AdminField<string>
              field={details.address.line2}
              title="Line 2"
              className="AdminPersonalDetails-mainAddressLine2"
            />
            <AdminField<string>
              field={details.address.city}
              title="City"
              className="AdminPersonalDetails-mainAddressCity"
            />
            <div className="AdminPersonalDetails-mainAddressState">
              <p className="AdminPersonalDetails-mainLabel AdminPersonalDetails-mainAddressStateLabel">State</p>
              <div className="AdminPersonalDetails-mainValue AdminPersonalDetails-mainAddressStateInput">
                <StateSelect
                  value={{ value: details.address.state.value.code, label: details.address.state.value.state }}
                  handleChange={this.handleStateChange}
                  withEmpty
                />
              </div>
            </div>
            <div className="AdminPersonalDetails-mainAddressZipCode">
              <p className="AdminPersonalDetails-mainLabel AdminPersonalDetails-mainAddressZipCodeLabel">Zip Code</p>
              <div className="AdminPersonalDetails-mainValue AdminPersonalDetails-mainAddressZipCodeInput">
                <InputNumber
                  value={details.address.zipCode.value}
                  onChange={(value) => details.address.zipCode.set(value)}
                  controls={false}
                />
              </div>
            </div>
          </div>
          <div className="AdminPersonalDetails-mainContact">
            <p className="AdminPersonalDetails-mainContactLabel">Contact Phone</p>
            <div className="AdminPersonalDetails-mainContactValue">
              <PhoneNumberInput
                shouldBeTouched={false}
                isEmptyValid
                value={details.contacts.officePhone.value}
                onChange={details.contacts.officePhone.set}
                onError={(err) => details.contacts.officePhone.setIsValid(!err)}
              />
            </div>
          </div>
          <div className="AdminPersonalDetails-mainLoginCredentials">
            <p className="AdminPersonalDetails-mainLoginCredentialsHeader">Login Credentials</p>
            <div className="AdminPersonalDetails-mainLoginCredentialsContent">
              <div className="AdminPersonalDetails-mainLoginCredentialsEmail">
                <div className="AdminPersonalDetails-mainLoginCredentialsEmailHeader">
                  <p className="AdminPersonalDetails-mainLabel AdminPersonalDetails-mainLoginCredentialsEmailLabel">
                    Email Address
                  </p>
                  {details.contacts.email.state.value === PERSON_LOGIN_CREDENTIALS.EDIT && (
                    <Button className="Button--link" title="Edit" onClick={this.openChangeEmailEditModal} />
                  )}
                  {details.contacts.email.state.value === PERSON_LOGIN_CREDENTIALS.VALIDATE && (
                    <Button className="Button--link" title="Validate" onClick={this.openChangeEmailEditModal} />
                  )}
                </div>
                <div className="AdminPersonalDetails-mainValue AdminPersonalDetails-mainLoginCredentialsEmailInput">
                  <Input
                    disabled
                    value={
                      details.contacts.email.state.value === PERSON_LOGIN_CREDENTIALS.VALIDATE
                        ? details.contacts.email.newLogin.value
                        : details.contacts.email.login.value
                    }
                  />
                  {details.contacts.email.state.value === PERSON_LOGIN_CREDENTIALS.VALIDATE && (
                    <InfoTooltip
                      icon={<CautionIcon />}
                      className="AdminPersonalDetails-mainLoginCredentialsEmailInputCaution"
                    >
                      Email Address has not been validated and cannot be used to log in.
                    </InfoTooltip>
                  )}
                  <InfoTooltip>
                    If access is granted this email address will be used for login credentials, account recovery, and to
                    send alerts or other communications.
                  </InfoTooltip>
                </div>
              </div>
            </div>
            <PersonLoginCredentials
              isOpen={isChangeEmailModalOpen}
              onClose={this.closeChangeEmailEditModal}
              credentials={details.contacts.email}
              personName={details.name}
              editType="EMAIL"
            />
          </div>
        </div>
        <div className="AdminPersonalDetails-notes">
          <p className="AdminPersonalDetails-notesLabel">Notes</p>
          <div className="AdminPersonalDetails-notesNote">
            <Note
              countCharactersEntered
              hideControls
              isEditing
              note={details.notes.value}
              onChange={details.notes.set}
              placeholder="Note"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AdminPersonalDetails;
