class Driver {
  displayName: string;
  id: number;

  constructor(driver) {
    this.set(driver);
  }

  set = ({ displayName, id } = { displayName: null, id: null }) => {
    this.displayName = displayName;
    this.id = id;
  };
}

export default Driver;
