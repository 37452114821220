import React, { FC, useState, useCallback } from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { sortByAlphabet } from 'utils';
import type { Group } from 'models';
import SearchList from 'components/Filters/SearchList';
import SearchGroupsList from 'components/Filters/SearchList/GroupsList';
import Popup from 'components/Popup';

import './styles.scss';

interface IProps {
  cnModificator?: string;
  list: Group[];
  onChange: (group: Group) => void;
  selectedGroup?: Group;
  Trigger?: JSX.Element;
  withAll?: boolean;
}

const VehiclesTableGroupsFilter: FC<IProps> = ({
  cnModificator,
  list,
  Trigger = null,
  selectedGroup,
  onChange,
  withAll,
}) => {
  const cn = classNames('VehiclesTableGroupsFilter', {
    [`VehiclesTableGroupsFilter--${cnModificator}`]: Boolean(cnModificator),
  });
  const popupCn = classNames('VehiclesTableGroupsFilter-popupContent', {
    [`VehiclesTableGroupsFilter-popupContent--${cnModificator}`]: Boolean(cnModificator),
  });
  const [isOpen, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSearchChange = (value: string) => setSearchValue(value);
  const handleChange = useCallback(
    (value) => {
      onChange(value);
      handleClose();
    },
    [onChange]
  );
  const getFilteredList = useCallback(
    (list: Group[]) =>
      list
        .filter(({ description }) =>
          searchValue ? description.toLowerCase().includes(searchValue.toLowerCase()) : true
        )
        .sort((a, b) => sortByAlphabet(a.description.toLowerCase(), b.description.toLowerCase())),
    [searchValue]
  );
  const renderTrigger = useCallback(
    () =>
      Trigger ? (
        Trigger
      ) : (
        <div className="VehiclesTableGroupsFilter-trigger">
          <span className="VehiclesTableGroupsFilter-title">
            {selectedGroup ? selectedGroup.displayName : 'All Vehicles'}
          </span>
          <CaretDownOutlined className="VehiclesTableGroupsFilter-arrowImg" />
        </div>
      ),
    [Trigger, selectedGroup]
  );

  return (
    <div className={cn}>
      <Popup isOpen={isOpen} onOpen={handleOpen} triggerElement={renderTrigger()} placement="bottom">
        <div className={popupCn}>
          <div className="VehiclesTableGroupsFilter-list">
            <SearchList
              list={getFilteredList(list)}
              noResultsMessage="No Groups Found"
              onSearchChange={handleSearchChange}
              placeholder="Search Groups"
              searchValue={searchValue}
            >
              <SearchGroupsList
                list={getFilteredList(list)}
                onChange={handleChange}
                selectedItemId={selectedGroup && selectedGroup.groupId}
                withAll={withAll}
              />
            </SearchList>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default VehiclesTableGroupsFilter;
