import React, { Component, CSSProperties } from 'react';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';

import { REPORTS_STORE } from 'config';
import type { ReportsStore } from 'stores';

import ReportTableByGroupRowEventCell from './ReportTableByGroupRowEventCell';

import './styles.scss';

interface IProps {
  index: number;
  reportsStore?: ReportsStore;
  style: CSSProperties;

  isSummary: boolean;
}

@inject(REPORTS_STORE)
@observer
class ReportTableByGroupRowEvent extends Component<IProps> {
  render() {
    const {
      reportsStore: {
        reportTableGroup: { rows, headerKeys, filteredHeadersWidth },
      },
      isSummary,
      index,
      style,
    } = this.props;
    const cells = rows[index][1];
    const styles = rows[index][2] || [];

    return (
      <div
        className={classnames(
          'ReportTableByGroupRow ReportTableByGroupRowEvent',
          {
            ReportTableByGroupRowEventSummary: isSummary,
            'ReportTableByGroupRowEvent--even': !(index % 2),
          },
          styles.map((className) => `ReportTableByGroupRowEvent--${className}`)
        )}
        style={{ ...style, width: filteredHeadersWidth }}
      >
        {cells.map((item, index) => (
          <ReportTableByGroupRowEventCell
            className={`ReportTableByGroupRowEvent--${headerKeys[index]}`}
            item={item}
            key={index}
          />
        ))}
      </div>
    );
  }
}

export default ReportTableByGroupRowEvent;
