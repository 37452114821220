import { action, observable } from 'mobx';
import ReportPreferences from 'models/Report/ReportPreferences';
import timeStore from 'stores/TimeStore';

export enum ReportType {
  byGroup = 'byGroup',
  byVehicle = 'byVehicle',
  noSelection = 'noSelection',
}

export enum ReportFor {
  geozone = 'geozone',
  tag = 'tag',
}

export class Report {
  id: string = '';
  title: string = '';
  description: string = '';
  groupId: string = '';
  deprecated: string | null;
  @observable pinned?: boolean = false;

  byGroup: Report.IServerReportByGroup | null = null;

  byVehicle: Report.IServerReportByVehicle | null = null;

  noSelection: Report.IServerReportNoSelection | null = null;

  @observable
  selectedType: ReportType = ReportType.noSelection;
  reportPreferences: ReportPreferences;

  constructor({
    id,
    title,
    description = '',
    pinned = false,
    groupId = '',
    reports,
    deprecated = null,
  }: Report.IReport) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.pinned = pinned;
    this.deprecated = deprecated;
    this.groupId = groupId;
    this.reportPreferences = new ReportPreferences({ timeZone: timeStore.sessionTimezone });

    if (reports.noSelection) {
      this.noSelection = reports.noSelection;
    }
    if (reports.byVehicle) {
      this.byVehicle = reports.byVehicle;
    }
    if (reports.byGroup) {
      this.byGroup = reports.byGroup;
    }

    if (reports.byGroup) {
      this.selectedType = ReportType.byGroup;
    } else if (reports.byVehicle) {
      this.selectedType = ReportType.byVehicle;
    } else if (reports.noSelection) {
      this.selectedType = ReportType.noSelection;
    }
  }

  get selectedReport() {
    if (this.selectedType === ReportType.byGroup) {
      return this.byGroup;
    } else if (this.selectedType === ReportType.byVehicle) {
      return this.byVehicle;
    } else {
      return this.noSelection;
    }
  }

  selectedTypeId = (id: string) => {
    const selectedReport = this.selectedReport;
    if (selectedReport.reportId) {
      return selectedReport.reportId;
    } else if (selectedReport.options && selectedReport.options.length) {
      const report = this.getReportById(id);
      if (report && 'reportId' in report) {
        return report.reportId;
      } else {
        return selectedReport.options[0].report.reportId;
      }
    }
    return null;
  };

  static createIdFromName(name: string): string {
    return name.replace(/\s+/g, '_').toLowerCase();
  }

  settings(id: string) {
    const report = this.getReportById(id);
    return (report && 'report' in report && report.report) || report;
  }

  @action setPin = (value: boolean) => {
    this.pinned = value;
  };

  getReportById(id: string): any {
    if (this.byGroup) {
      if (this.byGroup.reportId === id) {
        return this.byGroup;
      } else if (this.byGroup.options) {
        const report = this.byGroup.options.find((item) => item.report && item.report.reportId === id);
        if (report) {
          return report;
        }
      }
    }
    if (this.byVehicle) {
      if (this.byVehicle.reportId === id) {
        return this.byVehicle;
      } else if (this.byVehicle.options) {
        const report = this.byVehicle.options.find((item) => item.report && item.report.reportId === id);
        if (report) {
          return report;
        }
      }
    }
    if (this.noSelection && this.noSelection.reportId === id) {
      return this.noSelection;
    }
    return null;
  }

  getReportTypeById(id: string): ReportType | null {
    if (this.byGroup) {
      if (this.byGroup.reportId === id) {
        return ReportType.byGroup;
      } else if (this.byGroup.options) {
        const report = this.byGroup.options.find((item) => item.report && item.report.reportId === id);
        if (report) {
          return ReportType.byGroup;
        }
      }
    }
    if (this.byVehicle) {
      if (this.byVehicle.reportId === id) {
        return ReportType.byVehicle;
      } else if (this.byVehicle.options) {
        const report = this.byVehicle.options.find((item) => item.report && item.report.reportId === id);
        if (report) {
          return ReportType.byVehicle;
        }
      }
    }
    if (this.noSelection && this.noSelection.reportId === id) {
      return ReportType.noSelection;
    }
    return null;
  }
}

export default Report;
