import { INSPECTIONS_VIEW } from 'config';
import { SimpleField } from 'models/Fields';
import InspectionsPerformed from 'models/Inspections/InspectionsPerformed';
import InspectionsForms from 'models/Inspections/InspectionsForms';

export class Inspections {
  view: SimpleField<INSPECTIONS_VIEW>;
  performed: InspectionsPerformed;
  forms: InspectionsForms;

  constructor() {
    this.view = new SimpleField(INSPECTIONS_VIEW.DEFAULT);
    this.performed = new InspectionsPerformed(this);
    this.forms = new InspectionsForms(this);
  }
}

export default new Inspections();
