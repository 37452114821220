import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { PATHS } from 'config';

import { formatDecimalNumber, getFormattedTime } from 'utils';

import type { CustomerEstimate } from 'stores/Customers/CustomerEstimate';

interface IProps {
  estimate: CustomerEstimate;
  customerId: string;
  limited?: boolean
}

const CustomerEstimateItem: React.FC<IProps> = ({ estimate, customerId, limited }) => {

  const link = React.useMemo(() => {
    return `${PATHS.MAP.CUSTOMER_ESTIMATE.replace(':customerId', customerId).replace(':estimateId', String(estimate.id))}`;
  }, [customerId, estimate.id]);

  return (
    <div
      className={classNames('CustomerEstimates-ListItem', {
        'CustomerEstimates-ListItem--limited': limited,
      })}
    >
      <div><Link to={link}>{estimate.id}</Link></div>
      <div>{getFormattedTime(estimate.transactionDate, 'MM/DD/YYYY')}</div>
      {!limited && <div>${formatDecimalNumber(estimate.totalAmount, 2)}</div>}
    </div>
  );
};

export default CustomerEstimateItem;
