import React, { FC } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import type { SimpleField } from 'models/Fields';
import type { IDashcamSettingsEnumField } from 'interfaces/models/Dashcams/IDashcamSettingsEnumField';

import InfoTooltip from 'components/InfoTooltip';
import RadioGroup from 'components/RadioGroup';

import './styles.scss';


interface IProps {
  item: IDashcamSettingsEnumField<SimpleField<string>>;
  disabled?: boolean;
}

const getRadios = (list: Array<{ id: string; name: string }>, name) => {
  return list.map((item) => ({ id: item.id, value: item.id, name, label: item.name }));
};

const CameraSettingsItemRadio: FC<IProps> = ({item, disabled}) => {
  return (<div className={classnames('CameraSettingsItem CameraSettingsItemRadio', {
    'CameraSettingsItemRadio--disabled CameraSettingsItem--disabled': disabled,
  })}>
    <div className="CameraSettingsItem-info CameraSettingsItemRadio-info">
      <div className="CameraSettingsItem-name CameraSettingsItemRadio-name">{item.name}</div>
      {item.description && <InfoTooltip className="CameraSettingsItem-infoTooltip CameraSettingsItemRadio-infoTooltip">{item.description}</InfoTooltip>}
    </div>
    <div className="CameraSettingsItem-action CameraSettingsItemRadio-action">
      <RadioGroup
        disabled={disabled}
        className="CameraSettingsItemRadio-group"
        checkedValue={item.value.value}
        onChange={item.value.set}
        radios={getRadios(item.allowableValues.toArray(), item.id)}
        tabs
        size="xsmall"
      />
    </div>
  </div>);
};

export default observer(CameraSettingsItemRadio);
