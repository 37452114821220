import { ALERTS_APPLIES_TO } from 'config';
import { SimpleField } from 'models/Fields';
import AlertIDModel from './AlertID';

class AlertMetadata {
  asset: AlertIDModel<string>;
  assetGroup: AlertIDModel<string>;
  dashcamId: AlertIDModel<string>;
  appliesTo: SimpleField<ALERTS_APPLIES_TO>;

  constructor({ asset, assetGroup, device, appliesTo }) {
    asset = asset || { id: '', name: '' };
    assetGroup = assetGroup || { id: '', description: '' };
    device = device || { id: '', name: '' };

    this.dashcamId = new AlertIDModel(device.id ? device.id : 'all', device.name ? device.name : 'All Daschcams');
    this.asset = new AlertIDModel(asset.id ? asset.id.toString() : null, asset.name);
    this.assetGroup = new AlertIDModel(
      this.convertGroup(assetGroup).id.toString(),
      this.convertGroup(assetGroup).description
    );
    this.appliesTo = new SimpleField(
      asset.id ? this.getDefaultAppliesTo(asset.id) : appliesTo ? appliesTo : ALERTS_APPLIES_TO.GROUP
    );
  }

  get model() {
    const assetId = this.asset.id.value === 'all' ? null : parseInt(this.asset.id.value, 10);
    const assetGroupId = this.assetGroup.id.value === 'all' ? null : parseInt(this.assetGroup.id.value, 10);
    const dashcamId = this.dashcamId.id.value === 'all' ? null : parseInt(this.dashcamId.id.value, 10);

    return {
      asset: {
        id: assetId,
        name: this.asset.name.value,
      },
      assetGroup: {
        id: assetGroupId,
        description: this.assetGroup.name.value,
      },
      device: {
        id: dashcamId,
        name: this.dashcamId.name.value,
      },
    };
  }

  private convertGroup = ({ id, description }) => {
    return {
      id: !id ? 'all' : id,
      description: !id ? 'All Vehicles' : description,
    };
  };

  private getDefaultAppliesTo = (assetId) => {
    return assetId ? ALERTS_APPLIES_TO.VEHICLE : ALERTS_APPLIES_TO.GROUP;
  };
}

export default AlertMetadata;
