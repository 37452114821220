import React, { FC } from 'react';
import classNames from 'classnames';

import { Table } from 'antd';

import './styles.scss';

interface IProps {
  className?: string;
  rows: Array<{ [key: string]: string }>;
  rowProps?: { rowToMergeIndex: number; columnsNumber: number };
}

const TableSummary: FC<IProps> = ({ className, rows, rowProps }) => {
  const cn = classNames('TableSummary', className);

  return (
    <>
      {Boolean(rows) &&
        rows.map((row, i) => (
          <Table.Summary.Row className={cn} key={i}>
            {Object.keys(row).map((key, j) => (
              <Table.Summary.Cell
                className={`TableSummary-cell TableSummary-cell--${key}`}
                index={j}
                key={j}
                colSpan={rowProps?.rowToMergeIndex === i ? rowProps?.columnsNumber : undefined}
              >
                {row[key]}
              </Table.Summary.Cell>
            ))}
          </Table.Summary.Row>
        ))}
    </>
  );
};

export const TS = Table.Summary;

export default TableSummary;
