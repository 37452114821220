import { computed, observable } from 'mobx';
import classNames from 'classnames';
import { getFormattedTime, getTimeFromTimestampDiff, getTimestampForTimezone, sortByAlphabet } from 'utils';
import { DATE_TIME_FORMATS } from 'config';
import ReportTableColumns from 'models/Report/ReportTableColumns';
import TableFilter from 'models/Tables/TableFilter';
import { mapValues, isNumber } from 'lodash';

const DATE_TIME_KEYS = [
  ['startdatetime', DATE_TIME_FORMATS.adminVehicleSettings],
  ['enddatetime', DATE_TIME_FORMATS.adminVehicleSettings],
  ['stopdatetime', DATE_TIME_FORMATS.adminVehicleSettings],
  ['datetime', DATE_TIME_FORMATS.adminVehicleSettings],
  ['date', DATE_TIME_FORMATS.monthDatYearFull],
  ['servicelasttime', DATE_TIME_FORMATS.monthDatFullYear],
  ['servicenexttime', DATE_TIME_FORMATS.monthDatFullYear],
  ['time', DATE_TIME_FORMATS.hoursFullMinutesSpaceAm],
];

const TIME_KEYS = ['drivingelapse', 'stopelapse', 'usageelapse', 'elapsesec', 'idleelapse'];
const AGE_KEYS = ['checkinage', 'devicelastgpsage', 'checkinage'];

const INTEGER_KEYS = [
  'enginehours',
  'odometer',
  'distancetovehicle',
  'gallonspurchased',
  'odometerwex',
  'totalcost',
  'pricepergallon',
  'index',
  'odomdelta',
  'statuscount0xf11a',
  'statuscount0xf116',
  'statuscount0xf930',
  'statuscount0xf937',
  'statuscount0xf960',
  'statuscountper1000xf11a',
  'statuscountper1000xf930',
  'statuscountper1000xf937',
  'statuscountper1000xf960',
  'enginerpm',
  'speed',
  'count',
  'servicenexttimedays',
  'elapsesechobbs',
  'vbatteryvolts',
  'serviceinterval',
  'serviceinterval2',
  'servicelast',
  'servicelast2',
  'servicenext',
  'servicenext2',
  'serviceremaining',
  'serviceremaining2',
  'startodometer',
  'stopodometer',
];

const MEDIA_REQUEST_REPORTS_LIST = [
  'EventDetail',
  'EventDetail_Group',
  'EventDetailProPack',
  'EventDetailProPack_Group',
];

class ReportTable {
  reportId: string = '';
  header: any = [];
  body: any[] = [];
  title: string = '';
  editColumns: ReportTableColumns;
  totals: any = {};
  limit: number = 2000;
  timeZone: string = '';
  mapLinks: Report.IReportMapLink[];
  coloredColumns: string[];
  entityId: string = '';
  isPartial: boolean = false;
  @observable filter: TableFilter;

  constructor(
    reportId,
    header,
    body,
    title = '',
    totals,
    timeZone,
    mapLinks,
    defaultHeaders,
    entityId,
    isPartial = false
  ) {
    this.setReportId(reportId);
    this.setHeaders(header);
    this.setBody(body);
    this.setColoredColumns(body);
    this.setTitle(title);
    this.setTotals(totals);
    this.timeZone = timeZone;
    this.mapLinks = this.setMapLinks(mapLinks);
    this.editColumns = new ReportTableColumns({ key: `report-storage-${reportId}`, columns: header, defaultHeaders });
    this.filter = new TableFilter(this.editColumns.read() || []);
    this.entityId = entityId;
    this.isPartial = isPartial;
  }

  static setTableValues(obj) {
    return Object.entries(obj).reduce((acc, [key, entry]) => {
      return {
        ...acc,
        // @ts-ignore
        [String(key).toLowerCase()]: entry,
      };
    }, {});
  }

  private isSummaryInTableBody = () => {
    return this.body.some((row) => !Boolean(row.index.value));
  };

  get visibleColumnNames() {
    return this.editColumns.editColumnsList
      .filter(({ isSelected }) => isSelected)
      .map(({ value }) => value.toLowerCase());
  }

  setReportId = (reportId) => {
    this.reportId = reportId;
  };

  setTitle = (title) => {
    this.title = title;
  };

  setHeaders = (header) => {
    this.header = Object.entries(header).map(([key, value]) => [String(key).toLowerCase(), value]);
  };

  setColoredColumns = (body) => {
    this.coloredColumns = Object.keys(
      body.reduce((acc, curr) => {
        return {
          ...acc,
          ...Object.entries<{ color?: string; foreground?: string }>(curr).reduce<Record<string, boolean>>(
            (acc, [key, entry]) => {
              return {
                ...acc,
                ...((entry.color || entry.foreground) && { [String(key).toLowerCase()]: true }),
              };
            },
            {}
          ),
        };
      }, {})
    );
  };

  setBody = (body) => {
    this.body = body.map((item) => ReportTable.setTableValues(item));
  };

  setTotals = (totals = {}) => (this.totals = ReportTable.setTableValues(totals));

  setMapLinks = (mapLinks = []) =>
    (this.mapLinks = mapLinks.map((mapLink) => mapValues(mapLink, (value) => value.toLowerCase())));

  getCalculateFilteredSummary = () => {
    const result = {};

    const filteredBody = this.body.filter((item) => {
      return this.filter.search.value.every((i) =>
        item[i.value.column].value.toLowerCase().includes(i.value.value.toLowerCase())
      );
    });

    Object.keys(this.totals)
      .filter((key) => this.visibleColumnNames.includes(key))
      .sort((a, b) => {
        return this.visibleColumnNames.indexOf(a) - this.visibleColumnNames.indexOf(b);
      })
      .forEach((key) => {
        if (TIME_KEYS.includes(key)) {
          const timeDuration = filteredBody.reduce(
            (prevValue, currentValue) => prevValue + currentValue[key].sortValue,
            0
          );

          result[key] = getTimeFromTimestampDiff(timeDuration);
        } else if (INTEGER_KEYS.includes(key) && key !== 'index') {
          const total = filteredBody.reduce((prevValue, currentValue) => {
            const value = isNumber(currentValue[key].sortValue)
              ? currentValue[key].sortValue
              : Number(currentValue[key].value);
            return prevValue + value;
          }, 0);

          result[key] = total ? total.toFixed(1) : '';
        } else {
          result[key] = '';
        }
      });

    return result;
  };

  @computed
  get columns() {
    return this.header
      .map(([key, value], index) => {
        const sorter = (a, b) => {
          if (a[key].sortValue && b[key].sortValue) {
            return a[key].sortValue > b[key].sortValue ? 1 : -1;
          }
          return sortByAlphabet(a[key].value, b[key].value);
        };

        const sortOptions = this.isSummaryInTableBody()
          ? {}
          : {
              defaultSortOrder: index === 0 ? 'ascend' : undefined,
              sortDirections: ['ascend', 'descend', 'ascend'],
              sorter,
            };

        return {
          title: value,
          dataIndex: key,
          ...sortOptions,
        };
      })
      .sort((a, b) => this.visibleColumnNames.indexOf(a.dataIndex) - this.visibleColumnNames.indexOf(b.dataIndex))
      .filter(({ dataIndex }) => {
        return this.visibleColumnNames.includes(dataIndex);
      })
      .map((column) => {
        column.render = ({ value }, row) => {
          const cellCn = classNames(`ant-table-cell--${column.dataIndex}`, {
            'ant-table-cell--summary': !Boolean(row.index.value),
          });

          return {
            children: value,
            props: {
              className: cellCn,
            },
          };
        };

        return column;
      });
  }

  @computed
  get dataSource() {
    const items = this.body.map((item, i) => {
      INTEGER_KEYS.forEach((key) => {
        if (item[key]?.value !== undefined) {
          const tempKey = `_${key}`;
          let tempValue = item[key].value;
          if (typeof tempValue === 'string') {
            // tempValue = tempValue.replace(/,/g, '');
            tempValue = tempValue.replace(/[^-.\d]/g, '');
          }
          item[tempKey] = {
            ...item[key],
            value: isNaN(tempValue) ? -Infinity : tempValue === '' ? -Infinity : Number(tempValue),
          };
        }
      });

      DATE_TIME_KEYS.forEach(([key, format]) => {
        if (item[key]?.value !== undefined) {
          const tempKey = `_${key}`;
          const value = item[key].value;
          item[tempKey] = {
            ...item[key],
            value:
              isNaN(value) && value !== ''
                ? Number(getTimestampForTimezone(item[key].value, format, this.timeZone))
                : Number(item[key].value),
          };

          item[key].value = value !== '' ? getFormattedTime(item[tempKey].value, format, this.timeZone) : '';
        }
      });

      AGE_KEYS.forEach((key) => {
        if (item[key]?.value !== undefined) {
          const tempKey = `_${key}`;
          if (item[key]?.value) {
            const values = /(\d+)d (\d+)h (\d+)m/.exec(item[key].value);
            if (values && values.length === 4) {
              const [, ...ageValues] = values;
              item[tempKey] = {
                ...item[key],
                value: ageValues
                  .map((s) => {
                    const n = Number(s);
                    return !isNaN(n) ? n : 0;
                  })
                  .reverse()
                  .reduce((acc, val, i) => acc + val * Math.pow(60, i + 1), 0),
              };
            } else {
              item[tempKey] = { ...item[key] };
            }
          } else {
            item[tempKey] = { ...item[key] };
          }
        }
      });

      return { ...item, key: `${item.index.value}_${i}` };
    });

    return items;
  }

  @computed get totalValues() {
    const isSearch = this.filter.search.value.some((item) => item.value.value.length);

    if (isSearch) {
      return this.getCalculateFilteredSummary();
    } else {
      return Object.keys(this.totals)
        .filter((key) => this.visibleColumnNames.includes(key))
        .sort((a, b) => {
          return this.visibleColumnNames.indexOf(a) - this.visibleColumnNames.indexOf(b);
        })
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: this.totals[key].value,
          };
        }, {});
    }
  }

  @computed
  get total() {
    return this.dataSource.length;
  }

  @computed
  get hasLimitError() {
    return this.total >= this.limit;
  }

  @computed get requestMediaAllowed() {
    return MEDIA_REQUEST_REPORTS_LIST.includes(this.reportId);
  }
}

export default ReportTable;
