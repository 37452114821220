import { action, computed, observable } from 'mobx';

import { dateToNumber, getFormattedTime, secondsToTimeString, timeToNumber } from 'utils';
import { reduceDecimals } from 'utils/numbers';
import { getTextWidth, hasEntries, objectKeysToLowerCase } from './Report.helper';

import {
  DATE_TIME_KEYS,
  DATE_TIME_KEYS_VALUES,
  NUMBER_KEYS,
  TIME_KEYS,
  REPORT_SUMMARY_ORIGINAL_FORMATTING_KEYS,
  COLUMN_WIDTH_BY_KEY,
} from './Report.constants';

import TableFilter from 'models/Tables/TableFilter';

import ReportTable from './ReportTable';
import ReportTableColumns from './ReportTableColumns';
import ReportTableGroupHeader from './ReportTableGroup/ReportTableGroupHeader';
import ReportTableGroupVehicle from './ReportTableGroup/ReportTableGroupVehicle';

class ReportTableGroup {
  reportId: string;
  @observable.shallow tables: ReportTable[] = [];
  title: string = '';
  disposer: any;
  disposerFilter: any;
  timeZone: string = '';
  singleVehicle: boolean = false;

  @observable editColumns: ReportTableColumns;
  filter: TableFilter;
  tableStructure: ReportTable;
  mapLinks: Report.IReportMapLink[];
  defaultHeaders: string[];
  headers: ReportTableGroupHeader[] = [];
  @observable.shallow vehicles: ReportTableGroupVehicle[] = [];

  @observable currentTopIndex: number = 0;
  @observable scrollLeft: number = 0;

  constructor(reportId, data, title, timeZone, excludeInactive, mapLinks, defaultHeaders, singleVehicle = false) {
    this.setTitle(title);
    this.timeZone = timeZone;
    this.editColumns = new ReportTableColumns({
      key: `report-storage-${reportId}`,
      columns: data[0].Header,
      defaultHeaders,
    });
    this.singleVehicle = singleVehicle;

    if (data && data.length) {
      this.reportId = reportId;
      this.mapLinks = mapLinks || [];
      this.defaultHeaders = this.editColumns.defaultHeaders;
      const [firstTable] = data;
      this.tableStructure = new ReportTable(
        reportId,
        firstTable.Header,
        firstTable.Body,
        '',
        firstTable.Totals,
        timeZone,
        mapLinks,
        this.editColumns.defaultHeaders,
        ''
      );

      const visibleHeaders = this.editColumns.visibleColumnsList.map((item) => item.value.toLowerCase());

      this.headers = Object.entries(firstTable.Header).map(([key, value]) => {
        const keyLowered = key.toLowerCase();
        return new ReportTableGroupHeader({
          key: keyLowered,
          value,
          width: COLUMN_WIDTH_BY_KEY[keyLowered] || getTextWidth(value) * 1.25,
          maxText: '',
          visible: visibleHeaders.includes(keyLowered),
          searchable: true,
          search: '',
          sortable: true,
          valueType: NUMBER_KEYS.includes(keyLowered)
            ? 'number'
            : TIME_KEYS.includes(keyLowered)
            ? 'time'
            : DATE_TIME_KEYS_VALUES.includes(keyLowered)
            ? 'datetime'
            : 'string',
          sortDirection: 'NONE',
        });
      });

      this.vehicles = data
        .filter((item) => (excludeInactive ? item.Body.length : true))
        .map((item) => {
          const totals = hasEntries(item.Totals) ? objectKeysToLowerCase(item.Totals) : undefined;
          const { vehicle, sortable } = this.getReportTableGroupVehicle(item, totals);
          this.disableSorting(sortable);
          return vehicle;
        });
      this.updateHeaders();
    }
    this.filter = new TableFilter(this.editColumns.read() || []);
  }

  private getReportTableGroupVehicle(vehicle, totals: { [p: string]: { value: string; sortValue: string } }) {
    let hasSubTotalsInBody = false;
    const items = vehicle.Body.map((line) => {
      return this.headers.reduce((acc, curr) => {
        line = objectKeysToLowerCase(line);
        const key = (curr.key ?? '').toLowerCase();
        let value = line[key].value;
        if (value?.length > curr.maxText.length) {
          curr.maxText = value;
        }

        if (key === 'index' && value === '') {
          hasSubTotalsInBody = true;
        }

        DATE_TIME_KEYS.forEach(([id, format]) => {
          if (key === id) {
            if (!isNaN(value)) {
              value = getFormattedTime(Number(value), format, this.timeZone);
            }
          }
        });

        const type = key === 'maintenancename' ? 'link' : line[key].type || 'string';

        const event = {
          value,
          sortValue: line[key].sortValue,
          type,
          mapLink: undefined,
          maintenanceId: undefined,
          color: line[key].color || line[key].foreground,
        };
        if (this.mapLinksMap[key]) {
          const { lon: lonKey, lat: latKey } = this.mapLinksMap[key];
          if (lonKey && latKey) {
            const lon = Number(line[lonKey].value);
            const lat = Number(line[latKey].value);
            if (lon && lat) {
              event.mapLink = {
                deviceId: line.deviceid?.value || vehicle.Definition?.EntityId,
                timestamp: (line.timestamp?.value || 0) * 1000,
                lon,
                lat,
              };
            }
          }
        } else if (type === 'link') {
          event.maintenanceId = line.maintenancehistoryid?.value;
        }

        return [...acc, event];
      }, []);
    });
    return {
      vehicle: new ReportTableGroupVehicle({
        id: vehicle.Definition.EntityId,
        displayName: vehicle.Definition.EntityDisplayName,
        description: vehicle.Definition.EntityDesc,
        open: this.singleVehicle,
        formats: this.headers.reduce((acc, curr) => {
          const key = (curr.key ?? '').toLowerCase();
          const format = TIME_KEYS.includes(key)
            ? 'time'
            : totals?.[key]?.sortValue !== undefined || NUMBER_KEYS.includes(key)
            ? 'number'
            : DATE_TIME_KEYS_VALUES.includes(key)
            ? 'datetime'
            : 'string';
          return [...acc, format];
        }, []),
        totals: totals
          ? this.headers.reduce((acc, curr) => {
              const key = (curr.key ?? '').toLowerCase();
              const value = key !== 'index' ? totals[key]?.value || '' : '';
              if (value?.length > curr.maxText.length) {
                curr.maxText = value;
              }
              const total = {
                value,
              };
              return [...acc, total];
            }, [])
          : undefined,
        items,
      }),
      sortable: !hasSubTotalsInBody,
    };
  }

  updateHeaders = () => {
    this.headers.forEach((item) => {
      const width = getTextWidth(item.maxText) * 1.25;
      if (width > item.width) {
        item.width = width;
      }
    });
  };

  @action
  updateHeadersVisibility = (columns) => {
    columns.forEach((column) => {
      const header = this.headers.find((header) => header.key === column.value.toLowerCase());
      header?.updateVisible?.(column.isSelected);
    });
  };

  @computed
  get filteredHeaders() {
    return this.headers.filter((header) => header.visible);
  }

  @computed
  get headerIndexesMap(): Record<string, number> {
    return this.headers.reduce((acc, curr, index) => {
      return {
        ...acc,
        [curr.key]: index,
      };
    }, {});
  }

  @computed
  get sortedHeaders(): ReportTableGroupHeader[] {
    return this.editColumns.editColumnsList
      .filter((column) => column.isSelected)
      .reduce((acc, column) => {
        return [...acc, this.headers[this.headerIndexesMap[column.value.toLowerCase()]] ?? undefined];
      }, [])
      .filter(Boolean);
  }

  @computed
  get indexesFilteredHeaders() {
    return this.editColumns.editColumnsList
      .filter((column) => column.isSelected)
      .reduce((acc: number[], column) => {
        return [...acc, this.headerIndexesMap[column.value.toLowerCase()] ?? -1];
      }, [])
      .filter((column) => column !== -1);
  }

  @computed
  get idKeyIndex() {
    return this.getHeaderIndexByKey('index');
  }

  getHeaderIndexByKey(value: string) {
    return this.headers.findIndex(({ key }) => key === value);
  }

  @computed
  get indexesSortableHeaders() {
    const indexesSortableHeaders = this.headers
      .map((header, index) =>
        header.sortable && header.sortDirection !== 'NONE'
          ? {
              direction: header.sortDirection,
              type: header.valueType,
              updated: header.updated * -1,
              index,
            }
          : undefined
      )
      .filter((item) => item !== undefined);

    if (indexesSortableHeaders.length) {
      indexesSortableHeaders.sort((a, b) => (a.updated > b.updated ? -1 : a.updated < b.updated ? 1 : 0));
    }

    const indexKeyPosition = this.idKeyIndex;

    if (indexesSortableHeaders.findIndex(({ index }) => index === indexKeyPosition) === -1) {
      const header = this.headers[indexKeyPosition];
      indexesSortableHeaders.push({
        direction: 'ASC',
        type: header.valueType,
        updated: header.updated * -1,
        index: indexKeyPosition,
      });
    }

    return indexesSortableHeaders;
  }

  @computed
  get sortableHeaders() {
    return this.indexesSortableHeaders.map((item) => {
      return {
        ...item,
        key: this.headers[item.index].key,
      };
    });
  }

  @computed
  get allRows() {
    const indexesSortableHeaders = this.indexesSortableHeaders;
    const headerWidth = this.sortedHeaders.reduce((acc, curr) => acc + curr.width, 0);

    return this.vehicles
      .reduce((acc, vehicle) => {
        return [
          ...acc,
          [
            'vehicle',
            [
              vehicle.id,
              vehicle.open,
              vehicle.displayName,
              vehicle.description,
              headerWidth,
              vehicle.items.length >= 2000,
              vehicle.totalsIndexes,
            ],
          ],
          ...(vehicle.open || this.hasEnabledFilter || this.singleVehicle ? vehicle.items : [])
            .sort((a, b) => {
              if (!a[this.idKeyIndex].value || !b[this.idKeyIndex].value) {
                return 0;
              }
              if (indexesSortableHeaders.length) {
                for (const sorting of indexesSortableHeaders) {
                  const m = sorting.direction === 'DESC' ? -1 : 1;
                  let left = a[sorting.index].sortValue || a[sorting.index].value;
                  let right = b[sorting.index].sortValue || b[sorting.index].value;

                  if (sorting.type === 'number') {
                    left = Number(left);
                    right = Number(right);
                  }

                  if (left > right) {
                    return 1 * m;
                  } else if (left < right) {
                    return -1 * m;
                  }
                }
              }
              return 0;
            })
            .map((line) => {
              const type = !line[this.idKeyIndex].value ? 'event-summary' : 'event';
              return [type, this.indexesFilteredHeaders.map((index) => line[index]), !vehicle.open];
            }),
          (vehicle.open || this.hasEnabledFilter) && vehicle.totals
            ? ['summary', this.indexesFilteredHeaders.map((index) => vehicle.totals[index]), !vehicle.open]
            : [],
        ];
      }, [])
      .filter((item) => item.length);
  }

  @computed get hasEnabledFilter() {
    return this.sortedHeaders.some((item) => item.searchable && item.search);
  }

  @computed
  get rows() {
    const filters = this.sortedHeaders.reduce((acc, curr, index) => {
      if (curr.searchable && curr.search) {
        const value = {
          index,
          type: curr.valueType,
          subType: '',
          value: curr.search,
          gte: undefined,
          lte: undefined,
        };

        if (curr.valueType === 'number' || curr.valueType === 'time' || curr.valueType === 'datetime') {
          const matchTwo = curr.search.match(/([<>])(.*).?and.?([<>])(.*)/);
          if (matchTwo?.length === 5) {
            if (matchTwo[1] === '>' && matchTwo[3] === '<') {
              if (curr.valueType === 'time') {
                value.gte = timeToNumber(matchTwo[2]);
                value.lte = timeToNumber(matchTwo[4]);
              } else if (curr.valueType === 'datetime') {
                value.gte = dateToNumber(matchTwo[2]);
                value.lte = dateToNumber(matchTwo[4]);
              } else {
                value.gte = Number(matchTwo[2]);
                value.lte = Number(matchTwo[4]);
              }
            }
          } else {
            const matched = curr.search.match(/([<>])(.*)/);
            if (matched?.length === 3) {
              if (matched[1] === '>') {
                if (curr.valueType === 'time') {
                  value.gte = timeToNumber(matched[2]);
                } else if (curr.valueType === 'datetime') {
                  value.gte = dateToNumber(matched[2]);
                } else {
                  value.gte = Number(matched[2]);
                }
              } else if (matched[1] === '<') {
                if (curr.valueType === 'time') {
                  value.lte = timeToNumber(matched[2]);
                } else if (curr.valueType === 'datetime') {
                  value.lte = dateToNumber(matched[2]);
                } else {
                  value.lte = Number(matched[2]);
                }
              }
            }
          }
        }
        acc.push(value);
      }
      return acc;
    }, []);

    const rows = this.allRows;
    const hasFilters = filters.length;

    if (hasFilters) {
      const summaries = {
        initial: [],
        partial: [],
        total: [],
      };
      const hasEntries = {
        vehicle: false,
        partial: false,
      };
      const indexesToExclude = [];
      let summaryFormat = [];
      let previousVehicleIndex = -1;

      let filteredRows = rows.map(([type, item, hidden], index) => {
        if (type === 'vehicle') {
          if (!hasEntries.vehicle) {
            if (Array.isArray(rows[previousVehicleIndex]) && previousVehicleIndex + 1 !== index) {
              indexesToExclude.push(previousVehicleIndex);
            }
          }
          previousVehicleIndex = index;
          hasEntries.vehicle = false;
          hasEntries.partial = false;

          if (item[6] || REPORT_SUMMARY_ORIGINAL_FORMATTING_KEYS[this.reportId]) {
            const originalKeys = REPORT_SUMMARY_ORIGINAL_FORMATTING_KEYS[this.reportId] || [];
            summaryFormat = this.sortedHeaders
              .filter((item) => item.visible)
              .map((item) =>
                item.valueType !== 'string' && item.key !== 'index'
                  ? item.valueType
                  : originalKeys.includes(item.key)
                  ? 'original'
                  : undefined
              );

            const summaryTypes = ['initial', 'partial', 'total'];
            summaryTypes.forEach((key) => {
              summaries[key] = summaryFormat.map((item) =>
                ['number', 'time'].includes(item) ? 0 : item ? '' : undefined
              );
            });
          }
          return this.singleVehicle ? undefined : [type, item, hidden];
        } else if (type === 'event' || type === 'event-hidden') {
          const result =
            filters.filter((filter) => {
              if (filter.type === 'number' || filter.type === 'time') {
                if (filter.gte && filter.lte) {
                  return (
                    Number(item[filter.index].sortValue) >= filter.gte &&
                    Number(item[filter.index].sortValue) <= filter.lte
                  );
                }
                if (filter.gte) {
                  return Number(item[filter.index].sortValue) >= filter.gte;
                } else if (filter.lte) {
                  return Number(item[filter.index].sortValue) <= filter.lte;
                }
              } else if (filter.type === 'datetime') {
                if (filter.gte && filter.lte) {
                  return (
                    Number(item[filter.index].sortValue) >= filter.gte &&
                    Number(item[filter.index].sortValue) <= filter.lte
                  );
                }
                if (filter.gte) {
                  return Number(item[filter.index].sortValue) >= filter.gte;
                } else if (filter.lte) {
                  return Number(item[filter.index].sortValue) <= filter.lte;
                }
              }
              return item[filter.index].value.toLowerCase?.().includes(filter.value.toLowerCase());
            }).length === filters.length;

          if (result) {
            if (summaryFormat.length) {
              ['partial', 'total'].forEach((key) => {
                summaries[key].forEach((_, index) => {
                  if (summaryFormat[index] === 'number' || summaryFormat[index] === 'time') {
                    if (item[index]?.sortValue) {
                      summaries[key][index] += item[index].sortValue;
                    }
                  } else if (summaryFormat[index]) {
                    if (item[index]?.value) {
                      summaries[key][index] = item[index].value;
                    }
                  }
                });
              });
            }

            hasEntries.vehicle = true;
            hasEntries.partial = true;
            return [type, item, hidden];
          }
          return undefined;
        } else if (type === 'event-summary') {
          const isPreviousEventSummary = rows[index - 1]?.[0] === 'event-summary';
          if (hasEntries.partial || (hasEntries.vehicle && isPreviousEventSummary)) {
            hasEntries.partial = false;
            const result = [
              type,
              summaries[isPreviousEventSummary ? 'total' : 'partial'].map((value, index) => {
                if (summaryFormat[index] === 'number') {
                  return { value: reduceDecimals(value) };
                } else if (summaryFormat[index] === 'time') {
                  return { value: secondsToTimeString(value) };
                } else if (summaryFormat[index] === 'original') {
                  return { value: item[index].value };
                }
                return { value };
              }),
              hidden,
            ];
            summaries.partial = [...summaries.initial];
            return result;
          }
          return undefined;
        } else if (type === 'summary') {
          if (hasEntries.vehicle) {
            const result = [
              type,
              summaries.total.map((value, index) => {
                if (summaryFormat[index] === 'number') {
                  return { value: reduceDecimals(value) };
                } else if (summaryFormat[index] === 'time') {
                  return { value: secondsToTimeString(value) };
                }
                return { value };
              }),
              hidden,
            ];
            summaries.partial = [...summaries.initial];
            return result;
          }
          return undefined;
        }
        return [type, item, hidden];
      });
      filteredRows = filteredRows.filter(
        (item, index) => Boolean(item) && !indexesToExclude.includes(index) && !item[2]
      );
      const latestFilteredRow = filteredRows[filteredRows.length - 1];
      if (latestFilteredRow) {
        const [type] = latestFilteredRow;
        if (type === 'vehicle') {
          filteredRows.pop();
        }
      }
      return filteredRows;
    }

    return this.addStyles(
      rows.filter((item) => !item[2] && ((item[0] !== 'vehicle' && this.singleVehicle) || !this.singleVehicle))
    );
  }

  private addStyles(rows: any[]) {
    if (['VirtualTimecard_Group', 'VirtualTimecard'].includes(this.reportId)) {
      let previousStartDate;
      rows = rows.map(([type, event]) => {
        if (type === 'event') {
          const styles = [];
          const currentStartDate = getFormattedTime(
            event[this.headerIndexesMap?.startdatetime].sortValue * 1000,
            'dd',
            this.timeZone
          );
          if (previousStartDate !== currentStartDate) {
            if (previousStartDate !== undefined) {
              styles.push('border-top');
            }
            previousStartDate = currentStartDate;
          }

          return [type, event, styles];
        } else if (type === 'vehicle') {
          previousStartDate = undefined;
          return [type, event];
        }
        return [type, event];
      });
    }
    return rows;
  }

  @computed
  get vehicleRowIndexes() {
    return this.rows
      .reduce((acc, [type], index) => {
        if (type === 'vehicle') {
          return [...acc, index];
        }
        return acc;
      }, [])
      .reverse();
  }

  @computed
  get vehicleRowIndexesCount() {
    return this.vehicleRowIndexes.length;
  }

  @action updateCurrentTopIndex(value: number) {
    if (this.currentTopIndex !== value) {
      this.currentTopIndex = value;
    }
  }

  @action updateScrollLeft(value: number) {
    if (this.scrollLeft !== value) {
      this.scrollLeft = value;
    }
  }

  addItem = (item) => {
    const subtitle = `${item.Definition.EntityDesc}|${item.Definition.EntityDisplayName}`;
    this.tables.push(
      new ReportTable(
        this.reportId,
        item.Header,
        item.Body,
        subtitle,
        item.Totals,
        this.timeZone,
        this.mapLinks,
        this.defaultHeaders,
        item.EntityId
      )
    );

    const totals = hasEntries(item.Totals) ? objectKeysToLowerCase(item.Totals) : undefined;
    const { vehicle: reportTableGroupVehicle, sortable } = this.getReportTableGroupVehicle(item, totals);
    this.disableSorting(sortable);

    this.vehicles.splice(
      this.vehicles.reduce(
        (acc, curr, index) => (reportTableGroupVehicle.displayName > curr.displayName ? index + 1 : acc),
        0
      ),
      0,
      reportTableGroupVehicle
    );

    this.updateHeaders();
  };

  private disableSorting(sortable: boolean) {
    if (!sortable) {
      this.headers.map((header) => header.updateSortable(false));
    }
  }

  @action
  toggleVehicleOpen = (id: string) => {
    const vehicle = this.vehicles.find((vehicle) => vehicle.id === id);
    vehicle.toggleOpen?.();
  };

  @action
  toggleAllVehicleOpen = () => {
    const value = this.isAllVehiclesClosed;
    this.vehicles.forEach((vehicle) => {
      vehicle.updateOpen?.(value);
    });
  };

  @computed
  get isAllVehiclesClosed() {
    return !this.vehicles.some((vehicle) => vehicle.open);
  }

  @computed
  get rowsCount() {
    return this.rows.length;
  }

  setTitle = (title) => {
    this.title = title;
  };

  @computed
  get headerKeys() {
    return this.sortedHeaders.map((header) => header.key);
  }

  @computed
  get filteredHeadersWidth() {
    return this.sortedHeaders.reduce((acc, { width }) => acc + width, 0);
  }

  @action
  clearHeaderSearches = () => {
    this.headers.forEach((header) => {
      if (header.search !== '') {
        header.updateSearch('');
      }
    });
  };

  @computed
  get mapLinksMap() {
    return this.mapLinks.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.link.toLowerCase()]: {
          lon: curr.lon.toLowerCase(),
          lat: curr.lat.toLowerCase(),
        },
      };
    }, {});
  }

  @action
  toggleSortDirectionByHeaderKey = (key: string) => {
    const index = this.getHeaderIndexByKey(key);
    if (index !== -1) {
      this.headers[index].toggleSortDirection?.();
    }
  };

  @action
  validateActiveSorting = () => {
    const activeSort = this.headers.findIndex(({ sortDirection }) => sortDirection !== 'NONE');
    if (activeSort === -1) {
      this.headers[this.idKeyIndex].updateSortDirection?.('ASC');
    }
  };
}

export default ReportTableGroup;
