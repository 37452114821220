import { ListField, ToggleField } from 'models/Fields';
import InspectionItem from './InspectionItem';

class InspectionFormItems {
  list: ListField<InspectionItem>;
  requireAllPassFail: ToggleField;
  requireAllNotes: ToggleField;
  requireAllPhotos: ToggleField;

  constructor(items = []) {
    this.list = new ListField(items.map((item) => new InspectionItem(item, this)));
    this.requireAllPassFail = new ToggleField(false);
    this.requireAllNotes = new ToggleField(false);
    this.requireAllPhotos = new ToggleField(false);
  }

  addItem = () => {
    this.list.add(
      new InspectionItem(
        {
          id: null,
          name: '',
          description: '',
          requireNote: this.requireAllNotes.value,
          requirePhoto: this.requireAllPhotos.value,
          requirePassFail: this.requireAllPassFail.value,
          status: null,
          notes: '',
          attachments: [],
        },
        this
      )
    );
  };

  findItemById = (id: number) => this.list.value.find((item) => item.value.id === id);

  replaceItems = (items) => {
    this.list.replace(items.map((item) => new InspectionItem(item, this)));
  };

  removeItem = (index: number) => {
    this.list.remove(this.list.value[index]);
  };

  toggleRequireAllPassFail = () => {
    this.requireAllPassFail.toggle();
    this.list.value.forEach((item) => item.value.requirePassFail.toggle(this.requireAllPassFail.value));
  };

  toggleRequireAllNotes = () => {
    this.requireAllNotes.toggle();
    this.list.value.forEach((item) => item.value.requireNote.toggle(this.requireAllNotes.value));
  };

  toggleRequireAllPhotos = () => {
    this.requireAllPhotos.toggle();
    this.list.value.forEach((item) => item.value.requirePhoto.toggle(this.requireAllPhotos.value));
  };
}

export default InspectionFormItems;
