import React, { FC } from 'react';
import classNames from 'classnames';
import { Resizable } from 'react-resizable';

import './styles.scss';

interface IProps {
  className?: string;
  height: number;
  maxHeight?: number;
  minHeight?: number;
  onResize: (height: number) => void;
}

const TimelineDetailsTitle: FC<IProps> = ({ className, height, onResize, minHeight, maxHeight }) => {
  const cn = classNames('TimelineDetailsTitle', className);
  const resize = (_, { size }) => {
    onResize(size.height);
  };

  return (
    <div className={cn}>
      <Resizable
        width={Infinity}
        height={height}
        onResize={resize}
        handle={() => (
          <div className="TimelineDetailsTitle-resizeHandler">
            <div className="TimelineDetailsTitle-resizeHandlerIcon" />
          </div>
        )}
        minConstraints={[Infinity, minHeight]}
        maxConstraints={[Infinity, maxHeight]}
        axis="y"
        resizeHandles={['n']}
      >
        <div />
      </Resizable>
    </div>
  );
};

export default TimelineDetailsTitle;
