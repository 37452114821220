import { SimpleField, ToggleField } from '../Fields';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

class AssetTrackerDetails {
  odometer: SimpleField<number>;
  engHours: SimpleField<number>;
  avgEngineHoursPerDay: SimpleField<number>;
  avgKmPerDay: SimpleField<number>;
  repository: IEntityRepository;
  isFetched: ToggleField;

  constructor(repository, details = { odometer: 0, engHours: 0, avgEngineHoursPerDay: 0, avgKmPerDay: 0 }, isUpdated) {
    this.odometer = new SimpleField(details.odometer);
    this.engHours = new SimpleField(details.engHours);
    this.avgEngineHoursPerDay = new SimpleField(details.avgEngineHoursPerDay);
    this.avgKmPerDay = new SimpleField(details.avgKmPerDay);
    this.repository = repository;
    this.isFetched = new ToggleField(isUpdated);
  }

  update = (
    { reportedOdometer, reportedEngineHours, avgEngineHoursPerDay, avgKmPerDay } = {
      reportedOdometer: 0,
      reportedEngineHours: 0,
      avgEngineHoursPerDay: 0,
      avgKmPerDay: 0,
    }
  ) => {
    this.isFetched.toggle(true);
    this.odometer.set(reportedOdometer);
    this.engHours.set(reportedEngineHours);
    this.avgEngineHoursPerDay.set(avgEngineHoursPerDay);
    this.avgKmPerDay.set(avgKmPerDay);
  };

  fetch = async () => {
    if (!this.isFetched.value) {
      const response = await this.repository.get({
        includeAssetGroupFields: false,
        includeDashcamFields: false,
        includeDriverFields: false,
      });

      this.update(response);
    }
  };

  get data() {
    return {
      odometer: this.odometer.value,
      engHours: this.engHours.value,
      avgEngineHoursPerDay: this.avgEngineHoursPerDay.value,
      avgKmPerDay: this.avgKmPerDay.value,
    };
  }
}

export default AssetTrackerDetails;
