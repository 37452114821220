import React from 'react';
import classNames from 'classnames';

import ChevronButton from 'components/ChevronButton';

import './styles.scss';

interface IProps {
  isExpanded: boolean;
  onClick: () => void;
}

const Collapsible: React.FC<IProps> = ({ isExpanded, onClick, children }) => {
  const cn = 'Collapsible';
  const cnRoot = classNames(`${cn}`, {
    [`${cn}--expanded`]: isExpanded,
    [`${cn}--collapsed`]: !isExpanded,
  });
  const cnContainer = classNames(`${cn}-container`, {
    [`${cn}-container--expanded`]: isExpanded,
    [`${cn}-container--collapsed`]: !isExpanded,
  });

  return (
    <div className={cnRoot}>
      <div className={cnContainer}>
        <div className="Collapsible-content">{children}</div>
        <div className="Collapsible-button">
          <ChevronButton isExpanded={isExpanded} onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

export default Collapsible;
