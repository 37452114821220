import { observable, action } from 'mobx';
import type { ItemRegistry } from 'models';
import type FilteredRegistry from './FilteredRegistry';
import type { IFilterItem } from './IFilterItems';

class FilterItemRegistry implements IFilterItem {
  @observable items: ItemRegistry[] = [];
  @observable isChecked: boolean = false;
  context: FilteredRegistry;

  constructor(isChecked = false, context) {
    this.isChecked = isChecked;
    this.context = context;
  }

  @action setIsChecked = (value: boolean) => {
    this.isChecked = value;
  };

  @action toggle = () => {
    this.isChecked = !this.isChecked;
    this.context.onChecked();
  };
}

export default FilterItemRegistry;
