import React, { FC, CSSProperties, useState, useEffect } from 'react';
import classNames from 'classnames';

import Button from 'components/Button';
import Modal from 'components/Modal';

import './styles.scss';

interface IProps {
  cancelButtonStyles?: CSSProperties;
  applyButtonStyles?: CSSProperties;
  applyButtonTitle?: string;
  cancelButtonTitle?: string;
  className?: string;
  contentStyles?: CSSProperties;
  iconTitle?: React.ReactNode;
  isOpen: boolean;
  isSubmitDisabled?: boolean;
  onCancel?: () => void;
  onOpen?: () => void;
  onSubmit?: () => void;
  popupClassName?: string;
  title?: string;
  withoutCloseIcon?: boolean;
  closeOutside?: boolean;
}

const ConfirmationModal: FC<IProps> = ({
  applyButtonTitle = 'Save',
  cancelButtonTitle = 'Cancel',
  cancelButtonStyles,
  applyButtonStyles,
  children,
  className,
  contentStyles,
  iconTitle,
  isOpen,
  isSubmitDisabled,
  onCancel,
  onOpen,
  onSubmit,
  popupClassName = '',
  title,
  withoutCloseIcon = false,
  closeOutside = true,
}) => {
  const modalCn = classNames('Modal--confirmationModal', popupClassName, {
    'Modal--withoutCloseIcon': withoutCloseIcon,
  });
  const cn = classNames('ConfirmationModal', className);

  const [sending, setSending] = useState(false);

  const handleSubmit = () => {
    setSending(true);
    onSubmit();
  };

  useEffect(() => {
    return () => {
      if (sending) {
        setSending(false);
      }
    };
  });

  return (
    <Modal className={modalCn} isModalOpen={isOpen} onClose={onCancel} onOpen={onOpen} closeOutside={closeOutside}>
      <div className={cn}>
        <p className="ConfirmationModal-title">
          {iconTitle ? iconTitle : null}
          {title}
        </p>
        <div className="ConfirmationModal-content" style={contentStyles}>
          {children}
        </div>
        <div className="ConfirmationModal-buttons">
          <div className="ConfirmationModal-cancel">
            <Button
              className="Button--cancel Button--cancelColorLynch"
              styles={cancelButtonStyles}
              title={cancelButtonTitle}
              onClick={onCancel}
            />
          </div>
          <div className="ConfirmationModal-submit">
            <Button
              disabled={isSubmitDisabled}
              className="Button--apply"
              styles={applyButtonStyles}
              title={applyButtonTitle}
              onClick={handleSubmit}
              sending={sending}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
