import React from 'react';
import { Observer } from 'mobx-react';
import classnames from 'classnames';

import type PersonPermissionsDriverModel from 'models/Admin/People/PersonPermissions/PersonPermissionsDriverModel';
import type PersonPermissionsRestrictVehicleAccessModel from 'models/Admin/People/PersonPermissions/PersonPermissionsRestrictVehicleAccessModel';
import { PersonPermissionsDriverError } from 'interfaces/models/IPeopleAdmin/IPerson';

import StateSelect from 'containers/Select/StateSelect';
import InfoTooltip from 'components/InfoTooltip';
import Toggle from 'components/Toggle';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input/AntInput';
import CalendarWithInput from 'components/CalendarWithInput';
import SearchableVehicleSelect from 'containers/Select/SearchableVehicleSelect';
import SearchableKeyFobSelect from 'containers/Select/SearchableKeyFobSelect';
import Notification from 'components/Notification';
import Button from 'components/Button';

import './styles.scss';

interface IProps {
  driver: PersonPermissionsDriverModel;
  restrictVehicleAccess: PersonPermissionsRestrictVehicleAccessModel;
}

const PersonPermissionsDriver: React.FC<IProps> = ({ driver, restrictVehicleAccess }) => {
  const changeState = (option: Select.ISelectOption) => {
    driver.licenseState.set({ state: option.value, code: option.label });
  };

  const changeVehicle = ({ value, label }) => {
    driver.assignedVehicle.id.set(value === 'none' ? '' : value);
    driver.assignedVehicle.name.set(label === 'None' ? '' : label);
    driver.errorField.set(null);
  };

  const removeVehicle = () => {
    driver.assignedVehicle.id.set('');
    driver.assignedVehicle.name.set('');
    driver.errorField.set(null);
  };

  const changeKeyFob = ({ value, label }) => {
    driver.keyFob.set({ keyFob: label, id: value });
  };

  const removeKeyFob = () => {
    driver.keyFob.set({ keyFob: '-', id: 'none' });
  };

  const toggleRestrictUserAccess = () => {
    driver.restrictUserAccess.toggle();

    if (driver.restrictUserAccess.value) {
      restrictVehicleAccess.active.toggle(false);
      restrictVehicleAccess.groups.replace([]);
    }
  };

  return (
    <Observer
      render={() => {
        const isAssignVehicleError = driver.errorField.value === PersonPermissionsDriverError.AssignVehicle;
        const cnAssignVehicle = classnames('PersonPermissionsDriver-contentDetailsVehicleValueSelect', {
          'PersonPermissionsDriver-contentDetailsVehicleValueSelect--error': isAssignVehicleError,
        });

        return (
          <div className="PersonPermissionsDriver">
            <div className="PersonPermissionsDriver-activation">
              <Toggle checked={driver.active.value} onChange={() => driver.active.toggle()} />
              <p className="PersonPermissionsDriver-activationName">Driver</p>
              <InfoTooltip>
                When enabled, this user will be able to be assigned to Vehicles and will be allowed to inspect the
                Vehicle they are assigned to if they have Mobile App Access.
              </InfoTooltip>
            </div>
            {driver.active.value && (
              <div className="PersonPermissionsDriver-content">
                <div className="PersonPermissionsDriver-contentLicense">
                  <div className="PersonPermissionsDriver-contentLicenseType">
                    <p className="PersonPermissionsDriver-contentLicenseTypeTitle">License Type</p>
                    <Input
                      placeholder="License Type"
                      value={driver.licenseType.value}
                      onChange={(e) => driver.licenseType.set(e.target.value)}
                    />
                  </div>
                  <div className="PersonPermissionsDriver-contentLicenseState">
                    <p className="PersonPermissionsDriver-contentLicenseStateTitle">License State</p>
                    <StateSelect
                      value={{ value: driver.licenseState.value.code, label: driver.licenseState.value.state }}
                      handleChange={changeState}
                      withEmpty
                    />
                  </div>
                  <div className="PersonPermissionsDriver-contentLicenseNumber">
                    <p className="PersonPermissionsDriver-contentLicenseNumberTitle">License Number</p>
                    <Input
                      placeholder="License Number"
                      value={driver.licenseNumber.value}
                      onChange={(e) => driver.licenseNumber.set(e.target.value)}
                    />
                  </div>
                  <div className="PersonPermissionsDriver-contentLicenseExpiration">
                    <p className="PersonPermissionsDriver-contentLicenseExpirationTitle">License Expiration</p>
                    <CalendarWithInput
                      onlyFuture
                      isInputEditable={false}
                      value={driver.licenseExpiration.value}
                      onSubmit={(value) => driver.licenseExpiration.set(value)}
                    />
                  </div>
                </div>
                <div className="PersonPermissionsDriver-contentDetails">
                  <div className="PersonPermissionsDriver-contentDetailsVehicle">
                    <p className="PersonPermissionsDriver-contentDetailsVehicleTitle">Current Assigned Vehicle</p>
                    <div className="PersonPermissionsDriver-contentDetailsVehicleValue">
                      <div className={cnAssignVehicle}>
                        <SearchableVehicleSelect
                          handleChange={changeVehicle}
                          withNone
                          placeholder="No Vehicle Assigned"
                          valueSource="assetId"
                          value={{
                            value: driver.assignedVehicle.id.value?.toString(),
                            label: driver.assignedVehicle.name.value
                              ? driver.assignedVehicle.name.value
                              : driver.assignedVehicle.id.value,
                          }}
                        />
                        {isAssignVehicleError && (
                          <p className="PersonPermissionsDriver-contentDetailsVehicleValueSelectError">
                            User does not have access to assigned Vehicle
                          </p>
                        )}
                      </div>
                      <Button
                        title="Remove"
                        className="Button--link PersonPermissionsDriver-contentDetailsVehicleValueRemove"
                        disabled={driver.assignedVehicle.id.value === 'none' || !Boolean(driver.assignedVehicle.id.value)}
                        onClick={removeVehicle}
                      />
                    </div>
                  </div>
                  <div className="PersonPermissionsDriver-contentDetailsFob">
                    <p className="PersonPermissionsDriver-contentDetailsFobTitle">Fob assignment</p>
                    <div className="PersonPermissionsDriver-contentDetailsFobValue">
                      <div className="PersonPermissionsDriver-contentDetailsFobValueSelect">
                        <SearchableKeyFobSelect
                          handleChange={changeKeyFob}
                          withNone
                          value={{
                            value: driver.keyFob.value.id,
                            label: driver.keyFob.value.keyFob,
                          }}
                        />
                      </div>
                      <Button
                        title="Remove"
                        className="Button--link PersonPermissionsDriver-contentDetailsFobValueRemove"
                        disabled={driver.keyFob.value.id === 'none' || driver.keyFob.value.id === ''}
                        onClick={removeKeyFob}
                      />
                    </div>
                  </div>
                </div>
                <div className="PersonPermissionsDriver-contentRestrict">
                  <Checkbox
                    label="Restrict User Access to only currently assigned Vehicle"
                    checked={driver.restrictUserAccess.value}
                    onChange={toggleRestrictUserAccess}
                  />
                </div>
                {driver.isShowErrorPopup.value && (
                  <Notification
                    type="error"
                    title="Save failed"
                    text="Errors on the page must be fixed before Saving"
                    onClose={() => driver.isShowErrorPopup.toggle(false)}
                  />
                )}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default PersonPermissionsDriver;
