import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';
import { DATE_TIME_FORMATS } from 'config';
import { getFormattedTime, getNowTimestampForTimezone } from 'utils';
import timeStore from 'stores/TimeStore';
import moment from 'moment';
import { ClockIcon, Vehicle2Icon } from 'assets';

import type MaintenanceConfig from 'models/Maintenances/Models/MaintenanceConfig';

import './styles.scss';

type MomentDuration = 'month' | 'week' | 'day' | 'year';

interface IProps {
  currentOdometer: number;
  currentEngineHours: number;
  odometer: MaintenanceConfig;
  engHours: MaintenanceConfig;
  serviceTime: MaintenanceConfig;
}

const TaskToApplyNextService: React.FC<IProps> = ({
  currentEngineHours,
  currentOdometer,
  odometer,
  engHours,
  serviceTime,
}) => {
  useEffect(() => {
    if (odometer.interval.value) {
      const nextOdometer = Number(odometer.last.value) + Number(odometer.interval.value) - currentOdometer;
      odometer.next.set(nextOdometer.toString());
    }

    if (engHours.interval.value) {
      const nextEngineHours = Number(engHours.last.value) + Number(engHours.interval.value) - currentEngineHours;
      engHours.next.set(nextEngineHours.toString());
    }

    if (serviceTime.interval.value) {
      const nextServiceTime = moment(Number(serviceTime.last.value))
        .add(Number(serviceTime.interval.value), serviceTime.intervalUnit.value.toLowerCase() as MomentDuration)
        .valueOf();
      serviceTime.next.set(nextServiceTime.toString());
    }
  }, []);

  return (
    <Observer
      render={() => {
        const odometerValueCn = classNames('TaskToApplyNextService-odometerValue', {
          'TaskToApplyNextService-odometerValue--negative':
            Boolean(odometer.interval.value) && Number(odometer.next.value) < 0,
        });

        const engHoursValueCn = classNames('TaskToApplyNextService-engHoursValue', {
          'TaskToApplyNextService-engHoursValue--negative':
            Boolean(engHours.interval.value) && Number(engHours.next.value) < 0,
        });

        const serviceTimeCn = classNames('TaskToApplyNextService-serviceTimeValue', {
          'TaskToApplyNextService-serviceTimeValue--negative':
            Boolean(serviceTime.interval.value) &&
            getNowTimestampForTimezone(timeStore.sessionTimezone) > Number(serviceTime.next.value),
        });

        return (
          <div className="TaskToApplyNextService">
            {Boolean(odometer.interval.value) && (
              <div className="TaskToApplyNextService-odometer">
                <div className="TaskToApplyNextService-odometerIcon">
                  <Vehicle2Icon />
                </div>
                <div className={odometerValueCn}>
                  {Number(odometer.next.value)?.toLocaleString('en-US', {
                    maximumFractionDigits: 0,
                  })}{' '}
                  mi
                </div>
              </div>
            )}
            {Boolean(engHours.interval.value) && (
              <div className="TaskToApplyNextService-engHours">
                <div className="TaskToApplyNextService-engHoursIcon">
                  <Vehicle2Icon />
                </div>
                <div className={engHoursValueCn}>
                  {Number(engHours.next.value)?.toLocaleString('en-US', { maximumFractionDigits: 1 })} hrs
                </div>
              </div>
            )}
            {Boolean(serviceTime.interval.value) && (
              <div className="TaskToApplyNextService-serviceTime">
                <div className="TaskToApplyNextService-serviceTimeIcon">
                  <ClockIcon />
                </div>
                <div className={serviceTimeCn}>
                  {getFormattedTime(
                    Number(serviceTime.next.value),
                    DATE_TIME_FORMATS.monthDatYearFull,
                    timeStore.sessionTimezone
                  )}
                </div>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default TaskToApplyNextService;
