import React, { Component } from 'react';
import classNames from 'classnames';
import { sortBy } from 'lodash';
import OutsideClickHandler from 'react-outside-click-handler';

import {
  isValid24ClockTime,
  isValidTime,
  normalizeTime,
  parseTime,
  validateFirst3Digits,
  validateFirst4Digits,
} from 'utils';
import { KeyboardShortcutsManager } from 'tools';
import { minutesInterval, timeInSeconds } from './minutesInterval';

import TimePickerItem from './TimePickerItem';
import TimePickerListItem from './TimePickerListItem';

import './styles.scss';

interface IState {
  open: boolean;
  time: TimePickerItem[];
  filter: string;
  selected: string;
}

interface IProps {
  className?: string;
  value?: string;
  after?: string;
  before?: string;
  onSelect: (value: string) => void;
  onFocus?: () => void;
  disabled?: boolean;
  placeholder?: string;
  type?: 'input';
}

const TIME = minutesInterval(30).map(([, , , order, time]) => new TimePickerItem(time, order));

class TimePicker extends Component<IProps, IState> {
  private readonly rootElement: React.RefObject<HTMLInputElement>;
  private readonly inputElement: React.RefObject<HTMLInputElement>;
  private readonly listElement: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.rootElement = React.createRef();
    this.inputElement = React.createRef();
    this.listElement = React.createRef();
  }

  state = {
    open: false,
    time: TIME,
    filter: '',
    selected: '',
  };

  getOptions = (filter: string = '') => {
    const { value, after, before } = this.props;
    let options = [...TIME];

    filter = normalizeTime(filter);

    options.forEach((item) => {
      item.active = false;
    });

    if (isValidTime(filter)) {
      if (!options.find((time) => time.value === filter)) {
        const order = timeInSeconds(...parseTime(filter));
        options = sortBy([...options, new TimePickerItem(filter, order)], ['order']);
      }
    }

    if (value && !options.find((option) => option.value === value)) {
      const order = timeInSeconds(...parseTime(value));
      options = sortBy([...options, new TimePickerItem(value, order)], ['order']);
    }

    let filterValue = filter;
    if (isValid24ClockTime(filterValue)) {
      filterValue = normalizeTime(filterValue);
    }

    if (filterValue) {
      const option = options.find((item) => item.value.startsWith(filterValue));
      if (option) {
        option.active = true;
      }
    } else if (value) {
      const option = options.find((item) => item.value.startsWith(value));
      if (option) {
        option.active = true;
      }
    }

    const afterOrder = after ? timeInSeconds(...parseTime(after)) : undefined;
    const beforeOrder = before ? timeInSeconds(...parseTime(before)) : undefined;

    return afterOrder || beforeOrder
      ? options.filter(
          (item) => (!afterOrder || afterOrder <= item.order) && (!beforeOrder || beforeOrder >= item.order)
        )
      : options;
  };

  get Options() {
    return this.state.time.map(
      ({ value, active }): JSX.Element => {
        return <TimePickerListItem key={value} value={value} selected={active} onSelect={this.handleSelect} />;
      }
    );
  }

  get selected() {
    const item = this.state.time.find((item) => item.active);
    return item ? item.value : '';
  }

  get placeholder() {
    return this.props.value || this.props.placeholder || `11:59 am`;
  }

  get value() {
    const { filter, open } = this.state;
    return filter || open ? filter : this.props.value;
  }

  handleSelect = (selected: string) => {
    this.props.onSelect(selected);
    this.setState({
      open: false,
      filter: '',
    });
  };

  handleChange = (e) => {
    const { selectionStart } = e.target;
    let changed = -1;
    let { value } = e.target;
    if (value.length === 3) {
      value = validateFirst3Digits(value);
    } else if (value.length === 4) {
      value = validateFirst4Digits(value);
    }

    [
      [6, 4],
      [5, 3],
      [3, 1],
      [2, 0],
    ].forEach(([position, replaceAt]) => {
      if (selectionStart === position) {
        if (!isValidTime(value) && isValidTime(this.state.filter)) {
          const num = Number(value[selectionStart - 1]);
          if (!isNaN(num)) {
            const nextValue = this.state.filter.replace(new RegExp(`(.{${replaceAt}})(.)`), `$1${num}`);
            if (isValidTime(nextValue)) {
              value = nextValue;
              changed = selectionStart;
            }
          }
        }
      }
    });

    this.setState(
      {
        filter: value,
        time: this.getOptions(value),
      },
      () => {
        if (changed !== -1) {
          const element = this.inputElement.current;
          if (element) {
            element.selectionStart = element.selectionEnd = changed - 1;
            element.focus();
          }
        }
      }
    );
  };

  handleFocus = () => {
    this.setState({
      open: true,
      time: this.getOptions(),
    });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  onOutsideClick = () => {
    if (this.state.open === false) {
      return;
    }
    this.setState({
      open: false,
      filter: '',
    });
  };

  componentDidUpdate(_, prevState) {
    this.scrollToActiveElement();

    if (prevState.open !== this.state.open) {
      this.state.open ? KeyboardShortcutsManager.get().deactivate() : KeyboardShortcutsManager.get().activate();
    }
  }

  scrollToActiveElement() {
    const node = this.listElement.current;
    if (node) {
      const activeElement: HTMLElement = node.querySelector(`.${TimePickerListItem.activeClassName}`);
      if (activeElement !== null) {
        node.scrollTop = activeElement.offsetTop - 256 / 2;
      }
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      const selected = this.selected;
      this.setState({
        open: false,
        filter: '',
      });
      if (selected) {
        this.props.onSelect(selected);
      }
      if (this.inputElement.current) {
        this.inputElement.current.blur();
      }
    } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      this.openSiblingValue(e.key === 'ArrowDown');
    }
  };

  openSiblingValue = (next: boolean = true) => {
    const time = [...this.state.time];
    const activeIndex = time.findIndex((item) => item.active);

    let nextIndex = activeIndex + (next ? 1 : -1);
    if (nextIndex < 0) {
      nextIndex = time.length - 1;
    } else if (nextIndex >= time.length) {
      nextIndex = 0;
    }
    if (activeIndex !== -1) {
      time[activeIndex].active = false;
    }
    time[nextIndex].active = true;
    this.setState(
      {
        time,
        filter: time[nextIndex].value,
      },
      () => {
        setTimeout(() => {
          const element = this.inputElement.current;
          if (element) {
            element.selectionStart = element.selectionEnd = element.value.length - 3;
            element.focus();
          }
        }, 0);
      }
    );
  };

  render() {
    const { className, disabled, type } = this.props;
    const cn = classNames('TimePicker', className, {
      [`TimePicker--${type}`]: Boolean(type),
    });

    return (
      <div ref={this.rootElement} className={cn}>
        <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
          <input
            autoComplete="hidden"
            onKeyDown={this.handleKeyDown}
            placeholder={this.placeholder}
            value={this.value}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            className="ant-input TimePicker-input"
            ref={this.inputElement}
            disabled={disabled}
            data-lpignore="true"
          />
          {this.state.open ? (
            <div className="TimePicker-list" ref={this.listElement}>
              {this.Options}
            </div>
          ) : null}
        </OutsideClickHandler>
      </div>
    );
  }
}

export default TimePicker;
