import * as turf from '@turf/helpers';
// tslint:disable-next-line:no-duplicate-imports
import type { Feature, Point, Units, Coord, Position } from '@turf/helpers';
import buffer from '@turf/buffer';
import circle from '@turf/circle';
import turfBearing from '@turf/bearing';
import distance from '@turf/distance';
import destination from '@turf/destination';
import bboxPolygon from '@turf/bbox-polygon';
import bbox from '@turf/bbox';
import midpoint from '@turf/midpoint';
import centerOfMass from '@turf/center-of-mass';
import center from '@turf/center';

export const getPoint = (position: Locations.ILocation): Feature<Point> => turf.point([position.lng, position.lat]);

export const pathToCoords = (path) => path.map(({ lat, lng }) => ({ lat: lat(), lng: lng() }));

export const lineToCorridor = (line: Locations.ILocation[], width: number): Locations.ILocation[] => {
  const coordsForTurf = line?.map(({ lat, lng }) => [lng, lat]) || [];
  const lineString = turf.lineString(coordsForTurf);

  const corridor = buffer(lineString, width / 1000);
  return corridor.geometry.coordinates[0].map((arr) => {
    return { lat: Number(arr[1]), lng: Number(arr[0]) };
  });
};

export const getCenterOfGeozone = (polygon: Locations.ILocation[]): Locations.ILocation => {
  const turfCoords: Position[] = polygon.map(({ lat, lng }): Position => [lng, lat]);
  const turfCenter =
    polygon.length < 3 ? center(turf.points(turfCoords)) : centerOfMass(turf.polygon([[...turfCoords, turfCoords[0]]]));
  const [lng, lat] = turfCenter.geometry.coordinates;
  return { lat, lng };
};

export const getPolygonFromPoint = (center: Locations.ILocation, radius: number = 25) => {
  const coordsForTurf = getPoint(center);
  const polygon = bboxPolygon(bbox(circle(coordsForTurf, radius, { steps: 64, units: 'meters' })));
  return polygon.geometry.coordinates[0].slice(1, 5).map(([lng, lat]) => ({ lat, lng }));
};

export const circleToPolygon = (center: Locations.ILocation, radius: number): Locations.ILocation[] => {
  const coordsForTurf = getPoint(center);
  const turfCircle = circle(coordsForTurf, radius / 1000);
  return turfCircle.geometry.coordinates[0].map((arr) => ({ lat: Number(arr[1]), lng: Number(arr[0]) }));
};

export const getMidPoint = (start: Locations.ILocation, end: Locations.ILocation) => {
  const point1 = getPoint(start);
  const point2 = getPoint(end);
  const middlePoint = midpoint(point1, point2);
  const [lng, lat] = middlePoint.geometry.coordinates;

  return { lat, lng };
};

export const geozoneOptions = (color, isHovered = false) => ({
  fillColor: color || '#00cc00',
  fillOpacity: isHovered ? 0.3 : 0.2,
  strokeColor: color || '#00cc00',
  strokeOpacity: isHovered ? 1 : 0.5,
  strokeWeight: isHovered ? 4 : 2,
  geodesic: true,
  zIndex: 10,
});

export const getBearing = (point1: Coord, point2: Coord): number => {
  return turfBearing(point1, point2);
};

export const getDistance = (from: Coord, to: Coord, units: Units = 'meters'): number => {
  const options: { units?: Units } = { units };
  return distance(from, to, options);
};

export const getDestination = (
  point: Coord,
  radius: number,
  bearing: number,
  units: Units = 'meters'
): Feature<Point, { [name: string]: any }> => {
  const options: { units?: Units } = { units };
  return destination(point, radius, bearing, options);
};

export const getLatLng = ({ lat, lng }): Locations.ILocation => ({
  lat: lat(),
  lng: lng(),
});

export const latLngToServerCoordinates = (latLng: Locations.ILocation[]): Geozone.IServerGeozoneCoordinate[] =>
  latLng.map(({ lat, lng }, index) => ({ index, latitude: lat, longitude: lng }));
/*

export const turfPointToLatLng = (point: Point) => ({
  lat: point.geometry.coordinates[1],
  lng: point.geometry.coordinates[0],
});
*/
