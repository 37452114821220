import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import type { DevicesStore, MapStore } from 'stores';
import { DATE_TIME_FORMATS, PATHS, PREDEFINED_TIME_FILTER_OPTIONS } from 'config';
import { getBeginningOfTheDay, getEndOfTheDay, getFormattedTime, getTimeInTimeZone, isToday } from 'utils';

import TimeTitle from 'components/TimeTitle';
import PredefinedTimeFilter from 'components/SubNav/PredefinedTimeFilter';
import TimeHintTooltip from 'components/SubNav/TimeHintTooltip';

import './styles.scss';

interface IMatchParams {
  groupId: string;
  vehicleId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  devicesStore: DevicesStore;
  mapStore: MapStore;
  className?: string;
}

@inject(({ devicesMapStore: { devicesStore, mapStore } }) => ({
  devicesStore,
  mapStore,
}))
@observer
class SelectedVehiclePredefinedTimeFilter extends Component<IProps> {
  private get paths() {
    const {
      location: { pathname },
      match: {
        params: { vehicleId, groupId },
      },
    } = this.props;

    const isGroupRoute = pathname.includes('/map/groups');
    const pathToBack = isGroupRoute
      ? PATHS.MAP.GROUP_VEHICLE.replace(':groupId', groupId).replace(':vehicleId', vehicleId)
      : PATHS.MAP.VEHICLE.replace(':vehicleId', vehicleId);
    const pathToTimeFilter = isGroupRoute
      ? PATHS.MAP.GROUP_VEHICLE_TIME_FILTER.replace(':groupId', groupId).replace(':vehicleId', vehicleId)
      : PATHS.MAP.VEHICLE_TIME_FILTER.replace(':vehicleId', vehicleId);

    return {
      pathToBack,
      pathToTimeFilter,
    };
  }

  handleApplyTimeRange = async ({ from, to }) => {
    const {
      devicesStore: {
        filters: { applyDate },
      },
      history: { push },
    } = this.props;

    await applyDate({ from, to });

    push(this.paths.pathToBack);
  };

  get mostRecentEvent() {
    const {
      devicesStore: { selectedDevice },
      mapStore: { timezone },
    } = this.props;

    if (!selectedDevice || !selectedDevice.latestEvent) {
      return undefined;
    }

    const eventTime = getTimeInTimeZone(selectedDevice.latestEvent.timestamp, timezone);
    const from = getBeginningOfTheDay(eventTime);
    const to = getEndOfTheDay(eventTime);

    return {
      from,
      to,
    };
  }

  get predefinedTimeFilters() {
    const {
      mapStore: { timezone },
    } = this.props;

    const mostRecentEvent = this.mostRecentEvent;
    return [
      mostRecentEvent && !isToday(mostRecentEvent.from, timezone)
        ? {
            name: 'Most Recent Activity',
            timeRange: `${getFormattedTime(mostRecentEvent.from, DATE_TIME_FORMATS.monthDate, timezone)} 12a - 11:59p`,
            value: { from: mostRecentEvent.from, to: mostRecentEvent.to },
          }
        : undefined,
      ...PREDEFINED_TIME_FILTER_OPTIONS,
    ].filter((item) => item);
  }

  render() {
    const {
      className,
      devicesStore: {
        filters: { date },
      },
      mapStore: { timezone },
    } = this.props;
    const cn = classNames('SelectedVehiclePredefinedTimeFilter', className);

    return (
      <div className={cn}>
        <div className="SelectedVehiclePredefinedTimeFilter-header">
          <NavLink className="SelectedVehiclePredefinedTimeFilter-backButton" to={this.paths.pathToBack} />
          <div className="SelectedVehiclePredefinedTimeFilter-title">Select a date range</div>
          <div className="SelectedVehiclePredefinedTimeFilter-tooltip">
            <TimeHintTooltip />
          </div>
        </div>
        <div className="SelectedVehiclePredefinedTimeFilter-appliedTime">
          <TimeTitle date={{ ...date, timezone }} />
        </div>
        <div className="SelectedVehiclePredefinedTimeFilter-filters">
          <div className="SelectedVehiclePredefinedTimeFilter-filtersList">
            <PredefinedTimeFilter filters={this.predefinedTimeFilters} setTimeRange={this.handleApplyTimeRange} />
          </div>
          <NavLink className="SelectedVehiclePredefinedTimeFilter-customRange" to={this.paths.pathToTimeFilter}>
            Custom range...
          </NavLink>
        </div>
      </div>
    );
  }
}

export default withRouter(SelectedVehiclePredefinedTimeFilter);
