import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { CloseIcon, AddIcon } from 'assets';

import type { VehiclesAdmin } from 'stores/Admin/Vehicles';

import SearchableGroupSelect from 'containers/Select/SearchableGroupSelect';
import Popup from 'components/Popup';

import './styles.scss';

interface IProps {
  className?: string;
  vehiclesAdmin?: VehiclesAdmin;
  disabled?: boolean;
  onChange?: (field: { name: string; value: string | number | any }) => void;
}

interface IState {
  isFilterOpen: boolean;
}

const AddGroup = (
  <div className="GroupMembership-groupItem GroupMembership-groupItem--add">
    <AddIcon />
  </div>
);

@inject(({ adminStore: { vehiclesAdmin } }) => ({
  vehiclesAdmin,
}))
@observer
class GroupMembership extends Component<IProps, IState> {
  public static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.state = { isFilterOpen: false };
  }

  componentDidMount() {
    const { groupsList, getAllGroups } = this.props.vehiclesAdmin;

    if (!groupsList.length) {
      getAllGroups();
    }
  }

  handleAssignGroup = (group) => {
    const {
      vehiclesAdmin: {
        selectedVehicle: { assignGroup },
      },
      onChange,
    } = this.props;

    assignGroup(group);
    onChange({ name: 'groups', value: group.groupId });
    this.handleOpenFilter(false);
  };

  handleUnassignGroup = (groupId) => {
    const {
      vehiclesAdmin: {
        selectedVehicle: { unassignGroup },
      },
      onChange,
    } = this.props;

    unassignGroup(groupId);
    onChange({ name: 'groups', value: groupId });
  };

  handleOpenFilter = (isFilterOpen) => {
    this.setState({ isFilterOpen });
  };

  handleCloseFilter = () => {
    this.setState({ isFilterOpen: false });
  };

  render() {
    const {
      disabled,
      vehiclesAdmin: {
        selectedVehicle: { groups },
      },
    } = this.props;
    const { isFilterOpen } = this.state;
    return (
      <div className="GroupMembership">
        <div className="GroupMembership-container">
          <div className="GroupMembership-groupItem GroupMembership-groupItem--all">All Vehicles</div>
          {groups.map(({ description, groupId }, index) => (
            <div key={`${groupId}_${index}`} className="GroupMembership-groupItem">
              <span className="GroupMembership-groupItemName">{description}</span>
              {!disabled && (
                <div className="GroupMembership-unassignGroup" onClick={() => this.handleUnassignGroup(groupId)}>
                  <CloseIcon />
                </div>
              )}
            </div>
          ))}
          {!disabled && (
            <Popup
              isOpen={isFilterOpen}
              onVisibleChange={this.handleOpenFilter}
              placement="bottomLeft"
              triggerElement={isFilterOpen ? null : AddGroup}
              className="Popup-content--groupSelector"
            >
              {isFilterOpen ? (
                <SearchableGroupSelect
                  handleChange={this.handleAssignGroup}
                  isMenuOpen
                  handleMenuClose={this.handleCloseFilter}
                  menuShouldBlockScroll={Boolean(false)}
                />
              ) : (
                <></>
              )}
            </Popup>
          )}
        </div>
      </div>
    );
  }
}

export default GroupMembership;
