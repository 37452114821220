import { action, observable, computed, reaction } from 'mobx';
import { filter, orderBy, get } from 'lodash';
import moment from 'moment';

import APIStoreBase from './APIStoreBase';
import { repositoryService } from 'services';
import { Driver, Dispatch, Vehicle } from 'models';
import { getWeekBeforeYesterday, getYesterdayDate } from 'utils';
import { DATE_TIME_FORMATS } from 'config';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';

export class DispatchStore {
  @observable driversList: Driver[] = [];
  @observable dispatchesList: Dispatch[] = [];
  @observable vehiclesList: Vehicle[] = [];
  @observable dispatchesMode: 'current' | 'historical' = 'current';
  @observable sendDispatchRequestStatus: APIStoreBase = new APIStoreBase();
  @observable updateDriverRequestStatus: APIStoreBase = new APIStoreBase();
  repositoryDispatch: IRepository;
  repositoryDrivers: IRepository;
  repositoryDevices: IRepository;
  repositoryDispatchOrder: IEntityRepository;
  repositoryDispatchStatus: IEntityRepository;
  repositoryDispatchDispatch: IEntityRepository;

  constructor() {
    reaction(
      () => this.sendDispatchRequestStatus.success,
      (status) => {
        if (status) {
          this.getAllDispatches();
        }
      },
      { name: 'Update dispatches list on create dispatch' }
    );

    reaction(
      () => this.dispatchesMode,
      () => this.getAllDispatches()
    );

    this.repositoryDispatch = repositoryService.get('dispatch');
    this.repositoryDrivers = repositoryService.get('drivers');
    this.repositoryDevices = repositoryService.get('devices');
    this.repositoryDispatchOrder = this.repositoryDispatch.entity('order');
    this.repositoryDispatchStatus = this.repositoryDispatch.entity('status');
    this.repositoryDispatchDispatch = this.repositoryDispatch.entity('dispatch');

    this.repositoryDispatch.listenTo('error:get', () => this.resetDispatchesList());
    this.repositoryDrivers.listenTo('error:get', () => this.resetDriversList());
    this.repositoryDevices.listenTo('error:get', () => this.resetVehiclesList());
  }

  @computed get filteredDriversList() {
    const alphabetizedDriversList = orderBy(this.driversList, ({ driverName }: Driver) => driverName.toLowerCase(), [
      'asc',
    ]);

    return alphabetizedDriversList.filter(({ driverName }: Driver) => {
      return Boolean(driverName);
    });
  }

  @computed get filteredVehiclesList() {
    const alphabetizedVehiclesList = orderBy(this.vehiclesList, ({ name }: Vehicle) => name.toLowerCase(), ['asc']);

    return alphabetizedVehiclesList.filter(({ name }: Vehicle) => Boolean(name));
  }

  @computed get tableFormatDispatches() {
    const res = [];

    // const drivers = ['Driver', 'Driver 2'];
    const drivers = [];
    this.dispatchesList.forEach(({ driverId }) => drivers.indexOf(driverId) === -1 && drivers.push(driverId));
    // this.dispatchesList.map(({ driverId }) => drivers.push(driverId));

    drivers.forEach((driverId) => {
      const uniqDates = [];
      const driverDispatches = filter(this.dispatchesList, { driverId });
      const driverName = get(driverDispatches, '[0].driverName', driverId);
      driverDispatches.map(
        ({ dispatchDate }) => uniqDates.indexOf(dispatchDate) === -1 && uniqDates.push(dispatchDate)
      );
      const dates = uniqDates.map((dispatchDate) => {
        const dateDispatches = filter(driverDispatches, { dispatchDate });
        const dispatches = dateDispatches.map((dispatch) => ({
          order: dispatch.orderNumber,
          address: dispatch.destination.address,
          notes: dispatch.dispatchNotes,
          fieldNotes: dispatch.fieldNotes,
          status: dispatch.status,
          dispatchId: dispatch.dispatchId,
          completeTime: moment(`24/12/2019 ${dispatch.completeTime}`, 'DD/MM/YYYY hh:mm:ss').format('h:mm a'), // TODO: remove when response is changed
          // completeTime: moment(dispatch.completeTime).format('h:mm a'),
        }));
        const sortedDispatchesByOrder = orderBy(dispatches, ['status', 'order'], ['desc', 'asc']);
        return {
          date: dispatchDate,
          dispatches: sortedDispatchesByOrder,
        };
      });

      return res.push({
        driverId,
        driverName,
        dates,
      });
    });

    return res;
  }

  @action toggleDispatchesMode = (value) => (this.dispatchesMode = value);

  @action deleteDispatch = async (dispatchId) => {
    try {
      await this.repositoryDispatchDispatch.entity(dispatchId).delete();
      await this.getAllDispatches();
    } catch (error) {
      throw new Error(error);
    }
  };
  @action markCompleted = async (dispatchId, isCompleted) => {
    await this.repositoryDispatchStatus.entity(dispatchId).create({ status: isCompleted ? '' : 'completed' });
    await this.getAllDispatches();
  };

  @action getAllDrivers = async () => {
    const serverDriversList = await this.repositoryDrivers.get();
    this.driversList = serverDriversList.map((serverDriver) => new Driver(serverDriver));
  };

  @action getAllDispatches = async () => {
    let fromDate;
    let toDate;
    if (this.dispatchesMode === 'historical') {
      toDate = getYesterdayDate(DATE_TIME_FORMATS.dispatchDate);
      fromDate = getWeekBeforeYesterday(DATE_TIME_FORMATS.dispatchDate);
    }

    const serverDispatchesList = await this.repositoryDispatch.get({ fromDate, toDate });
    this.dispatchesList = serverDispatchesList.map((serverDispatch) => new Dispatch(serverDispatch));
  };

  @action getDispatch = async (dispatchId: string) => {
    const dispatch = await this.repositoryDispatchDispatch.entity(dispatchId).get();

    return new Dispatch(dispatch);
  };

  @action getAllVehicles = async () => {
    const serverVehiclesList = await this.repositoryDevices.get();

    this.vehiclesList = serverVehiclesList.map((serverVehicle) => new Vehicle(serverVehicle));
  };

  @action sendDispatch = async (method: 'POST' | 'PUT', values: Dispatch.ISendDispatchData) => {
    this.sendDispatchRequestStatus.reset().setLoading(true);

    try {
      if (method === 'POST') {
        await this.repositoryDispatch.create({ ...values });
      } else {
        await this.repositoryDispatch.put({ ...values });
      }

      this.sendDispatchRequestStatus.setSuccess(true);
    } catch (error) {
      this.sendDispatchRequestStatus.setError(error);
    }

    this.sendDispatchRequestStatus.setLoading(false);
  };

  @action moveDispatch = async (values: Dispatch.IMoveDispatchData) => {
    await this.repositoryDispatchOrder.create({ ...values });
    await this.getAllDispatches();
  };

  @action resetDriversList = () => (this.driversList = []);

  @action resetDispatchesList = () => (this.dispatchesList = []);

  @action resetVehiclesList = () => (this.vehiclesList = []);

  @action reset = () => {
    this.resetDriversList();
    this.resetDispatchesList();
    this.repositoryDrivers.getState.reset();
    this.repositoryDispatch.getState.reset();
  };
}

export default new DispatchStore();
