import React, { FC } from 'react';
import classNames from 'classnames';

import { ArrowIcon, PlusIcon, MinusIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  disabledLeft?: boolean;
  disabledRight?: boolean;
  disabledZoomIn?: boolean;
  disabledZoomOut?: boolean;
  onGoLeft?: () => void;
  onGoRight?: () => void;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
}

const JournalBarControls: FC<IProps> = ({
  disabledLeft,
  disabledRight,
  disabledZoomIn,
  disabledZoomOut,
  className,
  onGoLeft,
  onGoRight,
  onZoomIn,
  onZoomOut,
}) => {
  const cn = classNames('JournalBarControls', className);
  const goLeftCn = classNames('JournalBarControls-control JournalBarControls-control--left', {
    'JournalBarControls-control--disabled': disabledLeft,
  });
  const goRightCn = classNames('JournalBarControls-control JournalBarControls-control--right', {
    'JournalBarControls-control--disabled': disabledRight,
  });
  const zoomInCn = classNames('JournalBarControls-control JournalBarControls-control--zoomIn', {
    'JournalBarControls-control--disabled': disabledZoomIn,
  });
  const zoomOutCn = classNames('JournalBarControls-control JournalBarControls-control--zoomOut', {
    'JournalBarControls-control--disabled': disabledZoomOut,
  });

  return (
    <div className={cn}>
      <div className="JournalBarControls-list">
        <div className={goLeftCn} onClick={onGoLeft}>
          <ArrowIcon className="JournalBarControls-controlIcon" />
        </div>
        <div className={zoomOutCn} onClick={onZoomOut}>
          <MinusIcon className="JournalBarControls-controlIcon" />
        </div>
        <div className={zoomInCn} onClick={onZoomIn}>
          <PlusIcon className="JournalBarControls-controlIcon" />
        </div>
        <div className={goRightCn} onClick={onGoRight}>
          <ArrowIcon className="JournalBarControls-controlIcon" />
        </div>
      </div>
    </div>
  );
};

export default JournalBarControls;
