import React, { FC, useState } from 'react';
import { CalendarIcon } from 'assets';

import { getFormattedTime } from 'utils';
import { DATE_TIME_FORMATS } from 'config';

import Calendar from 'components/Calendar';

import './styles.scss';

interface IProps {
  onSubmit?: (serviceTimeValue: number) => void;
  onFocus?: (type: 'from' | 'to') => void;
  value?: number;
  isOpen?: boolean;
  timezone?: string;
  type?: 'from' | 'to';
}

const getDateValues = (value: number) =>
  getFormattedTime(value, 'YYYY/M/D')
    .split('/')
    .map((val: string) => Number(val));

const getFromMonth = (serviceTimeValue?: number, previousYears: number = 0) => {
  const [year, month] = getDateValues(serviceTimeValue);
  return new Date(year - previousYears, month - 1);
};

const getDisabledDays = (serviceTimeValue?: number) => {
  const [year, month, day] = getDateValues(serviceTimeValue);

  return [
    {
      before: new Date(year, month + 1, 0),
      after: new Date(year, month - 1, day),
    },
    {
      after: new Date(year, month - 1, day),
      before: new Date(year + 11, 12, 1),
    },
  ];
};

const HistoryToolBarCalendarInput: FC<IProps> = ({
  onSubmit,
  value,
  timezone,
  onFocus = () => null,
  isOpen = false,
  type,
}) => {
  const [selected] = useState(() => (value ? value : Date.now()));

  const fromMonth = getFromMonth(selected, 10);
  const disabledDays = getDisabledDays(Date.now());
  const formattedLastServiceValue = value ? getFormattedTime(value, DATE_TIME_FORMATS.eventsHistory, timezone) : '';

  const handleDateSelect = (value: Date) => {
    onSubmit(value.getTime());
  };
  const handleFocus = () => {
    onFocus(type);
  };

  return (
    <div className="HistoryToolBarCalendarInput-container">
      <div className="HistoryToolBarCalendarInput-modalInput">
        <div onClick={handleFocus} className="HistoryToolBarCalendarInput-modalInputContent">
          <span className="HistoryToolBarCalendarInput-modalInputIcon">
            <CalendarIcon fill="#6B7A99" width={16} />
          </span>
          <span className="HistoryToolBarCalendarInput-modalInputText">{formattedLastServiceValue}</span>
        </div>
      </div>
      {isOpen && false ? (
        <div className="HistoryToolBarCalendarInput-item">
          <Calendar
            from={new Date(value)}
            fromMonth={fromMonth}
            disabledDays={disabledDays}
            onClick={handleDateSelect}
            withMonthYearForm
            ignoreNull
          />
        </div>
      ) : null}
    </div>
  );
};

export default HistoryToolBarCalendarInput;
