import DueInCellRow from './DueInCellRow';
import type MaintenanceAccountConfig from '../MaintenanceAccountConfig';
import type MaintenanceEngineTracker from '../Models/Trackers/Engine';
import type MaintenanceOdometerTracker from '../Models/Trackers/Odometer';
import type MaintenanceServiceTracker from '../Models/Trackers/Service';
import type MaintenanceAsset from '../Models/MaintenanceAsset';

export default class DueInCell {
  asset: MaintenanceAsset;
  accountConfig: MaintenanceAccountConfig;
  rows: DueInCellRow[];

  constructor({
    asset,
    accountConfig,
    odometer,
    service,
    engine,
  }: {
    asset: MaintenanceAsset;
    accountConfig: MaintenanceAccountConfig;
    odometer: MaintenanceOdometerTracker;
    service: MaintenanceServiceTracker;
    engine: MaintenanceEngineTracker;
  }) {
    this.asset = asset;
    this.accountConfig = accountConfig;
    this.rows = [
      {
        config: odometer.config,
        dueInDays: odometer.dueInDays,
        format: 'text',
        value: 'odo',
      },
      {
        config: engine.config,
        dueInDays: engine.hoursDueInDays,
        format: 'text',
        value: 'engHours',
      },
      {
        config: service.config,
        dueInDays: service.timeDueInDays,
        format: 'date',
        value: 'time',
      },
    ].map((item) => new DueInCellRow({ dueSoon: accountConfig.dueSoon, ...item }));
  }

  private getAverageDueInDaysColor(dueInDays: number) {
    if (dueInDays < 0) {
      return 'red';
    } else if (dueInDays >= 0 && dueInDays < this.accountConfig.dueSoon.value) {
      return 'orange';
    } else {
      return '';
    }
  }

  get averageDueInDays() {
    const visibleOdoOrEngHours = this.rows.find((row) => row.visible && row.value !== 'time');
    const value = Math.floor(visibleOdoOrEngHours?.dueInDays);

    return visibleOdoOrEngHours
      ? {
          value,
          color: this.getAverageDueInDaysColor(value),
        }
      : { value: 0, color: '' };
  }
}
