import React, { FC } from 'react';
import classNames from 'classnames';

import LinkForm from '../Form';
import Modal from 'components/Modal';
import type { ICreateLinkValues } from 'components/ClearshareLink/Form';

import './styles.scss';

interface IProps {
  className?: string;
  currentMapBounds: google.maps.LatLngBounds;
  isOpen: boolean;
  onClose: (dirty?: boolean) => void;
  onSubmit: (values: ICreateLinkValues) => void;
  timeZone: string;
}

const CreateLinkModal: FC<IProps> = ({ className, currentMapBounds, isOpen, onClose, onSubmit, timeZone }) => {
  const cn = classNames('CreateLinkModal', className);

  return (
    <Modal
      className="Modal--createClearshareLink"
      closeOutside={false}
      isModalOpen={isOpen}
      onClose={onClose}
      withoutCloseIcon
    >
      <div className={cn}>
        <p className="CreateLinkModal-title">Create ClearShare Link</p>
        <div className="CreateLinkModal-form">
          <LinkForm currentMapBounds={currentMapBounds} onCancel={onClose} onSubmit={onSubmit} timeZone={timeZone} />
        </div>
      </div>
    </Modal>
  );
};

export default CreateLinkModal;
