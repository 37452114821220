import type { CAMERA_JOURNAL_STATE } from 'config';

class JournalItem {
  state: CAMERA_JOURNAL_STATE;
  endTimestamp: number;
  startTimestamp: number;

  constructor(item) {
    this.state = item.state;
    this.endTimestamp = item.endTimestamp;
    this.startTimestamp = item.startTimestamp;
  }
}

export default JournalItem;
