import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import type { ColumnsType } from 'antd/es/table';

import validateAccessLevel from 'stores/acl/validator';
import { TIMELINE_TABLE_COLUMNS, STORAGE_ITEMS, PATHS, WEB_TIMELINE_READ } from 'config';
import {
  getJSONItemFromStorage,
  getTodayStartDate,
  isItemVisible,
  scrollToSelectedItem,
  setJSONItemToStorage,
  sortByAlphabet,
} from 'utils';
import type { DevicesStore, PersistenceStore, RouterStore, MapStore, TimeStore } from 'stores';
import type { ITableEvent } from 'models/Devices/Device';

import Button from 'components/Button';
import CustomTable from 'components/Table/CustomTable';
import Icon from 'components/Icon';
import InfoTooltip from 'components/InfoTooltip';
import MapButton from 'components/Map/MapButton';
import TimelineDetailsDrawer from 'components/Map/TimelineDetails';

import { DetailsIcon } from 'assets';
import './styles.scss';

interface IProps {
  className?: string;
  devicesStore?: DevicesStore;
  mapStore?: MapStore;
  persistenceStore?: PersistenceStore;
  routerStore?: RouterStore;
  timeStore?: TimeStore;
}

interface IState {
  isFilterOpen: boolean;
  tableMode: UI.panelMode;
}

const editColumnsMap = {
  [TIMELINE_TABLE_COLUMNS.INDEX]: {
    text: '#',
    isDisabled: true,
  },
  [TIMELINE_TABLE_COLUMNS.DATE]: {
    text: 'Date',
    isDisabled: false,
  },
  [TIMELINE_TABLE_COLUMNS.TIME]: {
    text: 'Time',
    isDisabled: false,
  },
  [TIMELINE_TABLE_COLUMNS.STATUS]: {
    text: 'Status',
    isDisabled: false,
  },
  [TIMELINE_TABLE_COLUMNS.COORDINATES]: {
    text: 'Lat/Long',
    isDisabled: false,
  },
  [TIMELINE_TABLE_COLUMNS.VEHICLE_SPEED]: {
    text: 'Vehicle Speed mph',
    isDisabled: false,
  },
  [TIMELINE_TABLE_COLUMNS.ENGINE_HOURS]: {
    text: 'Engine Hours',
    isDisabled: false,
  },
  [TIMELINE_TABLE_COLUMNS.ODOMETER_MILES]: {
    text: 'Odometer Miles',
    isDisabled: false,
  },
  [TIMELINE_TABLE_COLUMNS.ADDRESS]: {
    text: 'Address',
    isDisabled: false,
  },
  [TIMELINE_TABLE_COLUMNS.DRIVER]: {
    text: 'Driver',
    isDisabled: false,
  },
};

const limitMessage =
  'The details for this vehicle exceeds the limit of 6000 lines. Reduce the time period to load a complete data set.';

@inject(({ devicesMapStore: { devicesStore, mapStore }, timeStore, persistenceStore, routerStore }) => ({
  devicesStore,
  mapStore,
  persistenceStore,
  routerStore,
  timeStore,
}))
@observer
class TimelineDetails extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      isFilterOpen: getJSONItemFromStorage(STORAGE_ITEMS.map.timeline.filterOpenStatus, true),
      tableMode: getJSONItemFromStorage(STORAGE_ITEMS.map.timeline.tableMode, 'full'),
    };
  }

  get drawerHeight() {
    const windowHeight = window.innerHeight;

    return {
      min: windowHeight * 0.15,
      max: windowHeight * 0.75,
    };
  }

  get drawerTableHeaderHeight() {
    const { isFilterOpen } = this.state;

    return isFilterOpen ? 132 : 102;
  }

  get tableBodyHeight() {
    const {
      devicesStore: {
        timelineDetailsHeight,
        selectedDevice: { eventsLimitExceed },
      },
    } = this.props;
    const summaryRowHeight = 39;

    return `${timelineDetailsHeight - this.drawerTableHeaderHeight - (eventsLimitExceed ? summaryRowHeight : 0)}px`;
  }

  get selectedEventTimestamp() {
    const {
      devicesStore: {
        selectedDevice: { events },
      },
    } = this.props;

    return events.find((event) => event.isSelected)?.timestamp.toString();
  }

  get isLatestEventVisible() {
    const tbody = document.querySelector('.ant-table-body');
    const latestRow = tbody.querySelector('.ant-table-row--latest');

    return isItemVisible(tbody, latestRow);
  }

  getColumns = (): ColumnsType<ITableEvent> => {
    const {
      devicesStore: {
        selectedDevice: {
          columns,
          sortedColumn: { field, order },
        },
        showHistory,
      },
    } = this.props;

    const allColumns = [
      {
        title: '#',
        dataIndex: TIMELINE_TABLE_COLUMNS.INDEX,
        defaultSortOrder: field === TIMELINE_TABLE_COLUMNS.INDEX ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => a[TIMELINE_TABLE_COLUMNS.INDEX] - b[TIMELINE_TABLE_COLUMNS.INDEX],
        render: (_, { index }) => (
          <Link
            to="#"
            className={showHistory ? 'ant-table-cell-link ant-table-cell-link--history' : 'ant-table-cell-link'}
            onClick={() => this.setSelectedEvent(index)}
          >
            {index}
          </Link>
        ),
        width: 80,
        hiddenSearch: true,
      },
      {
        title: 'Date',
        dataIndex: TIMELINE_TABLE_COLUMNS.DATE,
        defaultSortOrder: field === TIMELINE_TABLE_COLUMNS.DATE ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => a[TIMELINE_TABLE_COLUMNS.TIMESTAMP] - b[TIMELINE_TABLE_COLUMNS.TIMESTAMP],
        width: 180,
      },
      {
        title: 'Time',
        dataIndex: TIMELINE_TABLE_COLUMNS.TIME,
        defaultSortOrder: field === TIMELINE_TABLE_COLUMNS.TIME ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => a[TIMELINE_TABLE_COLUMNS.TIMESTAMP] - b[TIMELINE_TABLE_COLUMNS.TIMESTAMP],
        width: 180,
      },
      {
        title: 'Status',
        dataIndex: TIMELINE_TABLE_COLUMNS.STATUS,
        defaultSortOrder: field === TIMELINE_TABLE_COLUMNS.STATUS ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => sortByAlphabet(a[TIMELINE_TABLE_COLUMNS.STATUS], b[TIMELINE_TABLE_COLUMNS.STATUS]),
        width: 180,
      },
      {
        title: 'Lat/Long',
        dataIndex: TIMELINE_TABLE_COLUMNS.COORDINATES,
        defaultSortOrder: field === TIMELINE_TABLE_COLUMNS.COORDINATES ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => sortByAlphabet(a[TIMELINE_TABLE_COLUMNS.COORDINATES], b[TIMELINE_TABLE_COLUMNS.COORDINATES]),
        width: 220,
      },
      {
        title: 'Vehicle Speed mph',
        dataIndex: TIMELINE_TABLE_COLUMNS.VEHICLE_SPEED,
        defaultSortOrder: field === TIMELINE_TABLE_COLUMNS.VEHICLE_SPEED ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => a[TIMELINE_TABLE_COLUMNS.VEHICLE_SPEED] - b[TIMELINE_TABLE_COLUMNS.VEHICLE_SPEED],
        width: 180,
      },
      {
        title: 'Engine Hours',
        dataIndex: TIMELINE_TABLE_COLUMNS.ENGINE_HOURS,
        defaultSortOrder: field === TIMELINE_TABLE_COLUMNS.ENGINE_HOURS ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => a[TIMELINE_TABLE_COLUMNS.ENGINE_HOURS] - b[TIMELINE_TABLE_COLUMNS.ENGINE_HOURS],
        width: 180,
        render: (hours) => (hours ? `${hours.toLocaleString('en-US', { maximumFractionDigits: 0 })} hrs` : '-'),
      },
      {
        title: 'Odometer Miles',
        dataIndex: TIMELINE_TABLE_COLUMNS.ODOMETER_MILES,
        defaultSortOrder: field === TIMELINE_TABLE_COLUMNS.ODOMETER_MILES ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => a[TIMELINE_TABLE_COLUMNS.ODOMETER_MILES] - b[TIMELINE_TABLE_COLUMNS.ODOMETER_MILES],
        width: 180,
        render: (odometer) => (odometer ? `${odometer.toLocaleString('en-US', { maximumFractionDigits: 1 })} mi` : '-'),
      },
      {
        title: 'Address',
        dataIndex: TIMELINE_TABLE_COLUMNS.ADDRESS,
        defaultSortOrder: field === TIMELINE_TABLE_COLUMNS.ADDRESS ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => sortByAlphabet(a[TIMELINE_TABLE_COLUMNS.ADDRESS], b[TIMELINE_TABLE_COLUMNS.ADDRESS]),
        width: 400,
        render: (_, { address, index }) => (
          <Link
            to="#"
            className={showHistory ? 'ant-table-cell-link ant-table-cell-link--history' : 'ant-table-cell-link'}
            onClick={() => this.setSelectedEvent(index)}
          >
            {address}
          </Link>
        ),
      },
      {
        title: 'Driver',
        dataIndex: TIMELINE_TABLE_COLUMNS.DRIVER,
        defaultSortOrder: field === TIMELINE_TABLE_COLUMNS.DRIVER ? order : undefined,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => sortByAlphabet(a[TIMELINE_TABLE_COLUMNS.DRIVER], b[TIMELINE_TABLE_COLUMNS.DRIVER]),
        width: 180,
      },
    ];

    const filteredColumns = [];

    columns.forEach((column) => {
      if (column.isSelected) {
        filteredColumns.push(allColumns.find((col) => col.dataIndex === column.value));
      }
    });

    return filteredColumns;
  };

  handleOpenFilter = (isFilterOpen: boolean) => {
    this.setState({ isFilterOpen });
    setJSONItemToStorage(STORAGE_ITEMS.map.timeline.filterOpenStatus, isFilterOpen);
  };

  toggleTableMode = (tableMode: UI.panelMode) => {
    this.setState({ tableMode });
    setJSONItemToStorage(STORAGE_ITEMS.map.timeline.tableMode, tableMode);
  };

  setSelectedEvent = (index: number) => {
    const {
      devicesStore: {
        setSelectedEventIndex,
        selectedDevice: { events },
      },
      mapStore: { mapZoom, zoomIntoTrackPoint },
    } = this.props;
    const eventIndex = index - 1;
    const selectedEvent = events[eventIndex];

    setSelectedEventIndex(eventIndex);
    selectedEvent?.showTrackPointCard(true);
    zoomIntoTrackPoint(mapZoom);
  };

  setHoveredEvent = (row: ITableEvent) => {
    const {
      devicesStore: {
        selectedDevice: { events },
      },
    } = this.props;
    const selectedEvent = events[row.index - 1];

    selectedEvent?.toggleHovered();
  };

  handleClickOnRecentEventButton = () => {
    const {
      devicesStore: { setGoToRecentEvent },
    } = this.props;
    const tbody = document.querySelector('.ant-table-body');
    const latestRow = tbody.querySelector('.ant-table-row--latest');

    scrollToSelectedItem(tbody, latestRow, true);
    setGoToRecentEvent(false);
  };

  handleCloseTimelineDetails = () => {
    const {
      devicesStore: { showTimelineDetails },
    } = this.props;

    showTimelineDetails.toggle(false);
  };

  openEventDetailsReport = () => {
    const {
      devicesStore: {
        selectedDevice: {
          data: { shortName, deviceId },
        },
      },
      persistenceStore: { selectedTimeRange },
      timeStore: { sessionTimezone },
    } = this.props;

    const currentTime = new Date().getTime();
    const from = selectedTimeRange?.from || getTodayStartDate(sessionTimezone).valueOf();
    const to = selectedTimeRange?.to || currentTime;

    window.open(
      `${window.location.origin}/web${PATHS.REPORTS.EVENT_DETAILS}?deviceId[0]=${deviceId}&deviceId[1]=${shortName}&from=${from}&to=${to}`,
      '_blank'
    );
  };

  setRowClassName = (record: ITableEvent) => {
    const {
      devicesStore: {
        selectedDevice: { events },
      },
    } = this.props;

    return classNames('', {
      'ant-table-row--selected': record.isSelected,
      'ant-table-row--latest': record.index === events.length,
    });
  };

  render() {
    const {
      className,
      devicesStore: {
        goToRecentEvent,
        setTimelineDetailsHeight,
        showTimelineDetails,
        timelineDetailsHeight,
        selectedDevice: {
          columns,
          deviceEventsRequestStatus,
          pagination,
          searchColumns,
          setColumns,
          setPagination,
          setSearchColumn,
          setSortedColumn,
          tableSource,
          eventsLimitExceed,
          hasActiveSearch,
        },
      },
    } = this.props;
    const { isFilterOpen, tableMode } = this.state;
    const cn = classNames('TimelineDetails', className, { 'TimelineDetails--withOpenFilters': isFilterOpen });
    const goToRecentCn = classNames('TimelineDetails-goToRecentEvent', {
      'TimelineDetails-goToRecentEvent--withOpenFilters': isFilterOpen,
    });

    return (
      <div className={cn}>
        <TimelineDetailsDrawer
          height={timelineDetailsHeight}
          maxHeight={this.drawerHeight.max}
          minHeight={this.drawerHeight.min}
          onResize={setTimelineDetailsHeight}
          visible={showTimelineDetails.value}
        >
          <>
            {showTimelineDetails.value && (
              <div className="TimelineDetails-hideButton">
                <MapButton
                  className="MapButton--timelineDetails"
                  iconComponent={<Icon Icon={DetailsIcon} size={16} />}
                  onClick={this.handleCloseTimelineDetails}
                  title="Hide Details"
                />
              </div>
            )}
            {goToRecentEvent && !this.isLatestEventVisible && (
              <div className={goToRecentCn}>
                <Button
                  className="Button--apply Button--medium  Button--rounded"
                  onClick={this.handleClickOnRecentEventButton}
                  title="Go To Recent Event"
                />
              </div>
            )}
            <div className="TimelineDetails-table">
              <CustomTable<TIMELINE_TABLE_COLUMNS, ITableEvent>
                actionsList={
                  validateAccessLevel([WEB_TIMELINE_READ])
                    ? [{ text: 'View Full Report', onClick: this.openEventDetailsReport }]
                    : []
                }
                className="CustomTable--timeline"
                columns={this.getColumns()}
                dataSource={tableSource}
                editColumnsMap={editColumnsMap}
                emptyText={hasActiveSearch ? 'No results found' : 'No activity'}
                getData={() => true}
                isFilterActive={isFilterOpen}
                loading={deviceEventsRequestStatus.loading}
                onColumnsChange={setColumns}
                onColumnSort={setSortedColumn}
                onColumnsSearch={setSearchColumn}
                onFilterOpen={this.handleOpenFilter}
                onPaginationChange={setPagination}
                onTableModeChange={this.toggleTableMode}
                pagination={pagination}
                searchColumns={searchColumns}
                selectedColumns={toJS(columns)}
                tableMode={tableMode}
                tableHeight={this.tableBodyHeight}
                withActions
                withPagination={false}
                rowClassName={this.setRowClassName}
                selectedId={this.selectedEventTimestamp}
                onRowMouseEnter={this.setHoveredEvent}
                onRowMouseLeave={this.setHoveredEvent}
                summary={
                  eventsLimitExceed && [
                    {
                      limitMessage,
                    },
                  ]
                }
                summaryRowProps={{ rowToMergeIndex: 0, columnsNumber: this.getColumns().length }}
                summaryFixed
                totalTitle={
                  eventsLimitExceed && <InfoTooltip className="InfoTooltip--warning">{limitMessage}</InfoTooltip>
                }
              />
            </div>
          </>
        </TimelineDetailsDrawer>
      </div>
    );
  }
}

export default TimelineDetails;
