import { SimpleField } from '../Fields';

class Location {
  address: SimpleField<string>;
  lat: SimpleField<number>;
  lng: SimpleField<number>;

  constructor({ address, lat, lng }) {
    this.address = new SimpleField<string>(address);
    this.lat = new SimpleField<number>(lat);
    this.lng = new SimpleField<number>(lng);
  }
}

export default Location;
