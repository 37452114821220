import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import classNames from 'classnames';

import { stripHtml } from 'utils';
import { KeyboardShortcutsManager } from 'tools';

import OutsideClickHandler from 'react-outside-click-handler';
import Button from 'components/Button';

import './styles.scss';

interface IProps {
  className?: string;
  disabled?: boolean;
  hideControls?: boolean;
  isEditing: boolean;
  isFocused?: boolean;
  activeOnFocus?: boolean;
  note: string;
  onCancel?: (value?: boolean) => void;
  onChange?: (note: string) => void;
  onDelete?: (note: string) => void;
  onEditNoteStateChange?: (isNoteEditing?: boolean) => void;
  onSave?: (note: string) => void;
  placeholder?: string;
  countCharactersEntered?: boolean;
  maxLength?: number;
}

const setFocus = (element) => {
  element.selectionStart = element.selectionEnd = element.value.length;
  element.focus();
};

const Note: FC<IProps> = ({
  disabled = false,
  className,
  hideControls = false,
  isEditing,
  isFocused = false,
  activeOnFocus = false,
  note = '',
  onCancel = () => true,
  onChange = () => true,
  onDelete = () => true,
  onEditNoteStateChange = () => true,
  onSave = () => true,
  placeholder = 'Add a note...',
  countCharactersEntered = false,
  maxLength = 500,
}) => {
  const cn = classNames('Note', className, { 'Note--disabled': disabled });
  const textAreaRef = useRef(null);
  const [noteValue, setNoteValue] = useState(note);
  const [focused, setFocused] = useState(false);
  const cnCancel = classNames(['Button--link', 'Button--cancel'], className, {
    'Button--cancelActive': noteValue !== note,
  });
  const cnSave = classNames(['Button--link', 'Button--save'], {
    'Button--saveActive': noteValue !== note,
  });
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setNoteValue(event.target.value);
      onChange(event.target.value);
    },
    [setNoteValue, onChange]
  );
  const handleSaveNote = useCallback(() => {
    onSave(noteValue);
    setFocused(false);
  }, [noteValue, onSave]);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleOutsideClick = () => {
    setFocused(false);
  };

  const handleCancelNote = useCallback(() => {
    onCancel(false);
    setNoteValue(note);
    setFocused(false);
  }, [noteValue, onCancel]);

  const handleDeleteNote = useCallback(() => {
    onDelete('');
    setNoteValue('');
    setFocused(false);
  }, [onDelete]);
  const handleSaveNoteOnEnterPress = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.keyCode === 13 && !event.shiftKey) {
        handleSaveNote();
      }
    },
    [handleSaveNote]
  );
  const handleEditNoteState = useCallback(() => {
    onEditNoteStateChange();
  }, [onEditNoteStateChange]);

  useEffect(() => {
    setNoteValue(note);

    if (isEditing) {
      if (isFocused) {
        const textArea = textAreaRef.current;
        setFocus(textArea);
      }
    }
  }, [isEditing, isFocused, note, setNoteValue]);

  useEffect(() => {
    focused ? KeyboardShortcutsManager.get().deactivate() : KeyboardShortcutsManager.get().activate();
  }, [focused]);

  const charactersCounter = countCharactersEntered ? noteValue.length : maxLength - noteValue.length;

  return (
    <div className={cn}>
      {Boolean(note && !isEditing) && (
        <p className="Note-text" onClick={handleEditNoteState}>
          {stripHtml(note)}
        </p>
      )}
      {isEditing && (
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
          <div className="Note-edit">
            <div className="Note-field">
              <textarea
                disabled={disabled}
                ref={textAreaRef}
                name="device-note"
                className="ant-input Note-textarea"
                maxLength={maxLength}
                placeholder={placeholder}
                onChange={handleChange}
                onKeyDown={handleSaveNoteOnEnterPress}
                value={stripHtml(noteValue)}
                onFocus={handleFocus}
              />
              <span className="Note-textareaCounter">
                {charactersCounter}/{maxLength}
              </span>
            </div>
            {(!hideControls || (focused && activeOnFocus)) && (
              <div className="Note-buttons">
                <div className="Note-buttonsLeft">
                  <div className="Note-button Note-button--delete" onClick={handleDeleteNote}>
                    <Button title="Delete note" className="Button--link Button--delete" />
                  </div>
                </div>
                <div className="Note-buttonsLeft">
                  <div className="Note-button Note-button--cancel" onClick={handleCancelNote}>
                    <Button title="Cancel" className={cnCancel} />
                  </div>
                  <div className="Note-button Note-button--save" onClick={handleSaveNote}>
                    <Button title="Save" className={cnSave} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default Note;
