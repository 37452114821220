import { action, computed, observable } from 'mobx';
import filter from 'lodash/filter';
import moment from 'moment';

import timeStore from 'stores/TimeStore';
import { getFormattedTime, getTodayStartDate } from 'utils';
import { DATE_TIME_FORMATS, KM_TO_MI, MI_TO_KM } from 'config';
import APIStoreBase from '../stores/APIStoreBase';
import type Repository from 'services/RepositoryService/Repository';
import type Driver from './Driver';
import { repositoryService } from 'services';
import type EntityRepository from 'services/RepositoryService/EntityRepository';
import type { IFuelDetailsId } from '../interfaces/stores/Assets/IFuelDetails';
import FuelDetailsId from './Assets/FuelDetails/FuelDetailsId';
import FuelCards from './Integrations/WEXCardAssociations/FuelCards';
import { SimpleField, ListField, TimeField } from './Fields';
import InspectionTemplate from './Inspections/Models/InspectionTemplate';
import { LoggerInfo } from './General';

export class Vehicle {
  activationDate: string;
  uniqueId: string;
  assetId: number;
  @observable description: string = '';
  @observable driverId: string = '';
  @observable driverName: string = '';
  @observable driverDescription: string = '';
  @observable coordinates: { latitude: string; longitude: string } = { latitude: '', longitude: '' };
  @observable deviceType: string = '';
  @observable equipmentType: string = '';
  @observable groups: Vehicle.IVehicleMembershipGroup[] = [];
  @observable id: string = '';
  @observable ignitionState: string = '';
  @observable imei: string = '';
  @observable lastEventTimestamp: number = null;
  @observable lastMaintenance1: number = 0;
  @observable lastMaintenance2: number = 0;
  @observable licenseExpiration: number = null;
  @observable licenseExpirationMonth: string = 'blank';
  @observable licenseExpirationYear: string = 'blank';
  @observable licenseState: string = '';
  @observable maintenanceInterval1: number = 0;
  @observable maintenanceInterval2: number = 0;
  @observable maintenanceNotes: string = '';
  @observable make: string = '';
  @observable maximumSpeed: number = null;
  @observable model: string = '';
  @observable name: string = '';
  @observable note: string = '';
  @observable odometer: number = 0;
  @observable plate: string = '';
  @observable suspended: boolean = false;
  @observable vin: string = '';
  @observable engHoursMaintInterval: number = 0;
  @observable engineHours: number = 0;
  @observable lastEngHoursMaint: number = 0;
  @observable lastServiceTime: number = 0;
  @observable nextServiceTime: number = 0;
  @observable diagnosticFaultCodes: string = '';
  @observable camera: {
    activation: string;
    name: string;
    serialNumber: string;
    uniqueId: string;
    dashcamId: string;
    id: number;
  } = null;
  @observable vehicleYear: string = '';
  @observable milesPerGallon: number = 0;
  loggerInfo: LoggerInfo;
  fuelDetails: IFuelDetailsId;
  fuelCards: FuelCards;
  avgEngineHoursPerDay: SimpleField<number>;
  avgMileagePerDay: SimpleField<number>;
  inspectionTemplates: ListField<InspectionTemplate>;
  resetMpgTime: TimeField;
  resetAduTime: TimeField;

  @observable saveFieldsRequestStatus: APIStoreBase = new APIStoreBase();
  repositoryVehicles: Repository;
  repositoryDashcams: Repository;
  repositoryGroups: Repository;
  repositoryAssets: Repository;
  repositoryGroupsAssignDevice: EntityRepository;
  repositoryGroupsUnAssignDevice: EntityRepository;
  repositoryAssetResetMpg: EntityRepository;
  repositoryAssetResetAdu: EntityRepository;

  constructor({
    camera,
    creationDate,
    description,
    deviceId,
    lastMaintenance1,
    lastMaintenance2,
    maintenanceInterval1,
    maintenanceInterval2,
    deviceType,
    faultCodes,
    fuelType,
    displayName,
    driverId,
    driverName,
    driverDescription,
    equipmentType,
    groups,
    lastServiceTime,
    nextServiceTime,
    ignitionState,
    imeiNumber,
    lastEventTimestamp,
    lastValidLatitude,
    lastValidLongitude,
    licenseExpiration,
    licenseState,
    engHoursMaintInterval,
    reportedEngineHours,
    lastEngHoursMaint,
    licensePlate,
    maintenanceNotes,
    maximumSpeed,
    notes,
    reportedOdometer,
    suspended,
    uniqueId,
    vehicleID,
    vehicleMake,
    vehicleModel,
    vehicleYear,
    tankCapacity,
    assetId,
    milesPerGallon,
    createdBy,
    updatedBy,
    creationTime,
    lastUpdateTime,
    avgEngineHoursPerDay,
    avgKmPerDay,
    inspectionTemplates,
    mpgResetTimestamp,
    aduResetTimestamp,
  }: Vehicle.IServerVehicle) {
    this.assetId = assetId;
    this.activationDate = getFormattedTime(
      creationDate,
      DATE_TIME_FORMATS.adminVehicleSettings,
      timeStore.userTimezone
    );
    this.description = description;
    this.diagnosticFaultCodes = faultCodes;
    this.driverId = driverId;
    this.driverName = driverName;
    this.driverDescription = driverDescription;
    this.equipmentType = equipmentType;
    this.groups = groups;
    this.id = deviceId;
    this.lastMaintenance1 = lastMaintenance1 * MI_TO_KM;
    this.lastMaintenance2 = lastMaintenance2 * MI_TO_KM;
    this.maintenanceInterval1 = maintenanceInterval1 * MI_TO_KM;
    this.maintenanceInterval2 = maintenanceInterval2 * MI_TO_KM;
    this.maintenanceNotes = maintenanceNotes;
    this.engHoursMaintInterval = engHoursMaintInterval;
    this.engineHours = reportedEngineHours;
    this.lastEngHoursMaint = lastEngHoursMaint;
    this.make = vehicleMake;
    this.maximumSpeed = Number((maximumSpeed * MI_TO_KM).toFixed(1));
    this.model = vehicleModel;
    this.name = displayName || '';
    this.note = notes;
    this.odometer = reportedOdometer * MI_TO_KM;
    this.plate = licensePlate;
    this.vin = vehicleID;
    this.lastServiceTime = lastServiceTime;
    this.nextServiceTime = nextServiceTime;
    this.licenseExpiration = licenseExpiration;
    this.licenseExpirationMonth = this.licenseExpiration
      ? getFormattedTime(this.licenseExpiration, 'M - MMM')
      : 'blank';
    this.licenseExpirationYear = this.licenseExpiration ? getFormattedTime(this.licenseExpiration, 'YYYY') : 'blank';
    this.licenseState = licenseState;
    this.suspended = suspended;
    this.lastEventTimestamp = lastEventTimestamp;
    this.imei = imeiNumber;
    this.ignitionState = ignitionState;
    this.deviceType = deviceType;
    this.coordinates = {
      latitude: lastValidLatitude.toString(),
      longitude: lastValidLongitude.toString(),
    };
    this.uniqueId = uniqueId;
    this.camera = camera;
    this.vehicleYear = vehicleYear || '';
    this.milesPerGallon = milesPerGallon;
    this.loggerInfo = new LoggerInfo(
      { createdBy, updatedBy, creationTime, lastUpdateTime },
      DATE_TIME_FORMATS.adminVehicleSettings
    );
    this.repositoryVehicles = repositoryService.get('vehicles');
    this.repositoryDashcams = repositoryService.get('dashcams');
    this.repositoryGroups = repositoryService.get('groups');
    this.repositoryAssets = repositoryService.get('assets');
    this.repositoryGroupsAssignDevice = this.repositoryGroups.entity('assign').entity('device');
    this.repositoryGroupsUnAssignDevice = this.repositoryGroups.entity('unassign').entity('device');
    this.repositoryAssetResetMpg = this.repositoryAssets.entity(String(this.assetId)).entity('reset').entity('mpg');
    this.repositoryAssetResetAdu = this.repositoryAssets.entity(String(this.assetId)).entity('reset').entity('adu');

    this.fuelDetails = new FuelDetailsId({
      assetId,
      cpNumber: deviceId,
      displayName,
      fuelType,
      id: deviceId,
      tankCapacity,
    });
    this.fuelCards = new FuelCards();
    this.avgEngineHoursPerDay = new SimpleField(avgEngineHoursPerDay);
    this.avgMileagePerDay = new SimpleField(Number((avgKmPerDay * KM_TO_MI).toFixed(1)));
    this.inspectionTemplates = new ListField<InspectionTemplate>(
      inspectionTemplates?.map((template) => new InspectionTemplate(template))
    );
    this.resetMpgTime = new TimeField(mpgResetTimestamp || getTodayStartDate(timeStore.sessionTimezone).valueOf());
    this.resetAduTime = new TimeField(aduResetTimestamp || getTodayStartDate(timeStore.sessionTimezone).valueOf());
  }

  // "type=obdii mil=1 dtc=U0073,P0AC4,P25A2,P25C9,U0073,U0293,U0293,P1E00"
  reformatFaultCodes = (str: string): any => {
    const resObj = {};
    str.split(' ').forEach((item) => {
      if (item) {
        const split = item.split('=');
        const key = split[0];
        if (split[1]) {
          resObj[key] = split[1].split(',');
        }
      }
    });
    return resObj;
  };

  @computed get lastCommunication() {
    return this.lastEventTimestamp
      ? getFormattedTime(this.lastEventTimestamp, DATE_TIME_FORMATS.adminVehicleSettings, timeStore.userTimezone)
      : '-';
  }

  @computed get diagnosticFaultCodesList() {
    const codesList = this.reformatFaultCodes(this.diagnosticFaultCodes);
    return codesList?.dtc || [];
  }

  @computed get status() {
    return this.suspended ? 'Suspended' : 'Active';
  }

  @action clearDiagnosticFaultCodes = async () => {
    const data = [{ key: 'faultCodes', value: '' }];

    await this.repositoryVehicles.patch(this.getRequestData(data));
    this.diagnosticFaultCodes = '';
  };

  @action updateMaximumSpeed = (speed) => {
    this.maximumSpeed = speed;
  };
  @action updateName = (name) => (this.name = name);
  @action updateDescription = (description) => (this.description = description);
  @action updateEquipmentType = (equipmentType) => (this.equipmentType = equipmentType);
  @action updateVIN = (vin) => (this.vin = vin);
  @action updateMake = (make) => (this.make = make);
  @action updateModel = (model) => (this.model = model);
  @action updatePlate = (plate) => (this.plate = plate);
  @action updateVehicleNote = (note) => (this.note = note);
  @action updateMaintenanceNotes = (note) => (this.maintenanceNotes = note);
  @action updateLicenseExpirationMonth = (licenseExpirationMonth) =>
    (this.licenseExpirationMonth = licenseExpirationMonth);
  @action updateLicenseExpirationYear = (licenseExpirationYear) => (this.licenseExpirationYear = licenseExpirationYear);
  @action updateCameraName = (name) => (this.camera.name = name);
  @action updateVehicleYear = (year) => (this.vehicleYear = year);
  @action updateLicenseState = (state) => (this.licenseState = state);

  @action unassignGroup = async (groupIdToRemove: string, withCall?: boolean) => {
    if (withCall) {
      await this.repositoryGroupsUnAssignDevice.put({ deviceID: this.id, groupID: groupIdToRemove });
    }
    this.groups = filter(this.groups, ({ groupId }) => groupId !== groupIdToRemove);
  };

  @action assignGroup = async (groupToAdd: Select.ISelectOption, withCall?: boolean) => {
    const groupId = groupToAdd?.value;
    const isAlreadyAssigned = this.groups.some(({ groupId: id }) => groupId === id);
    if (!isAlreadyAssigned) {
      const formattedGroup: Vehicle.IVehicleMembershipGroup = {
        groupId: groupToAdd?.value,
        displayName: groupToAdd?.label,
        description: groupToAdd?.label,
      };
      if (withCall) {
        await this.repositoryGroupsAssignDevice.put({ deviceID: this.id, groupID: formattedGroup.groupId });
      }
      this.groups.push(formattedGroup);
    }
  };

  @action saveAssignedVehiclesToGroup = async (assignedGroupsIds: string[]) => {
    for (const groupId of assignedGroupsIds) {
      await this.repositoryGroupsAssignDevice.put({ deviceIds: [this.id], groupID: groupId });
    }
  };

  @action saveUnassignedVehiclesToGroup = async (unassignedGroupsIds: string[]) => {
    for (const groupId of unassignedGroupsIds) {
      await this.repositoryGroupsUnAssignDevice.put({ deviceIds: [this.id], groupID: groupId });
    }
  };

  @action.bound
  async updateNextServiceTime(value: number) {
    await this.repositoryVehicles.patch(this.getRequestData([{ key: 'nextServiceTime', value }]));
    this.nextServiceTime = value;
  }

  @action.bound
  async updateLastServiceTime(value: number) {
    await this.repositoryVehicles.patch(this.getRequestData([{ key: 'lastServiceTime', value }]));
    this.lastServiceTime = value;
  }

  @action.bound
  async updateLastAndNextServiceTime(lastServiceTime: number, nextServiceTime: number) {
    await this.repositoryVehicles.patch(
      this.getRequestData([
        { key: 'lastServiceTime', value: lastServiceTime },
        { key: 'nextServiceTime', value: nextServiceTime },
      ])
    );
    this.lastServiceTime = lastServiceTime;
    this.nextServiceTime = nextServiceTime;
  }

  @action.bound
  async updateMaintenanceByMileage1(lastMaintenance1: number, maintenanceInterval1: number) {
    const interval = !isNaN(maintenanceInterval1) && {
      key: 'maintenanceInterval1',
      value: maintenanceInterval1 / MI_TO_KM,
    };
    const data = [{ key: 'lastMaintenance1', value: lastMaintenance1 / MI_TO_KM }];
    if (interval) {
      data.push(interval);
    }

    await this.repositoryVehicles.patch(this.getRequestData(data));

    this.lastMaintenance1 = lastMaintenance1;
    if (interval) {
      this.maintenanceInterval1 = maintenanceInterval1;
    }
  }

  // updateMaintenanceByMileageInterval1
  @action.bound
  async updateMaintenanceByMileageInterval1(maintenanceInterval1: number) {
    const data = [{ key: 'maintenanceInterval1', value: maintenanceInterval1 / MI_TO_KM }];
    await this.repositoryVehicles.patch(this.getRequestData(data));
    this.maintenanceInterval1 = maintenanceInterval1;
  }

  // updateMaintenanceByMileageInterval2
  @action.bound
  async updateMaintenanceByMileageInterval2(maintenanceInterval2: number) {
    const data = [{ key: 'maintenanceInterval2', value: maintenanceInterval2 / MI_TO_KM }];
    await this.repositoryVehicles.patch(this.getRequestData(data));
    this.maintenanceInterval2 = maintenanceInterval2;
  }

  // updateMaintenanceByEngHoursInterval
  @action.bound
  async updateMaintenanceByEngHoursInterval(engHoursMaintInterval: number) {
    const data = [{ key: 'engHoursMaintInterval', value: engHoursMaintInterval }];
    await this.repositoryVehicles.patch(this.getRequestData(data));
    this.engHoursMaintInterval = engHoursMaintInterval;
  }

  @action.bound
  async updateMaintenanceByMileage2(lastMaintenance2: number, maintenanceInterval2: number) {
    const interval = !isNaN(maintenanceInterval2) && {
      key: 'maintenanceInterval2',
      value: maintenanceInterval2 / MI_TO_KM,
    };
    const data = [{ key: 'lastMaintenance2', value: lastMaintenance2 / MI_TO_KM }];
    if (interval) {
      data.push(interval);
    }
    await this.repositoryVehicles.patch(this.getRequestData(data));
    this.lastMaintenance2 = lastMaintenance2;
    if (interval) {
      this.maintenanceInterval2 = maintenanceInterval2;
    }
  }

  // lastEngHoursMaint
  // engHoursMaintInterval
  @action.bound
  async updateMaintenanceByEngHours(lastEngHoursMaint: number, engHoursMaintInterval: number) {
    const interval = !isNaN(engHoursMaintInterval) && { key: 'engHoursMaintInterval', value: engHoursMaintInterval };
    const data = [{ key: 'lastEngHoursMaint', value: lastEngHoursMaint }];
    if (interval) {
      data.push(interval);
    }
    await this.repositoryVehicles.patch(this.getRequestData(data));
    this.lastEngHoursMaint = lastEngHoursMaint;
    if (interval) {
      this.engHoursMaintInterval = engHoursMaintInterval;
    }
  }

  @action updateDriverId = (driverId: string) => {
    this.driverId = driverId;
  };

  @action updateDriver = (driver: Driver) => {
    this.driverId = driver.driverId;
    this.driverName = driver.driverName;
  };

  @action updateDriverById = ({ id, name }) => {
    this.driverId = id;
    this.driverName = name;
  };

  @action removeDriver = async () => {
    this.driverId = '';
    this.driverName = '';
  };

  @action updateOdometer = async (value) => {
    const data = [{ key: 'reportedOdometer', value: value / MI_TO_KM }];
    await this.repositoryVehicles.patch(this.getRequestData(data));
    this.odometer = value;
    this.repositoryVehicles.patchState.reset();
  };

  @action updateEngineHours = async (value) => {
    const data = [{ key: 'reportedEngineHours', value }];
    await this.repositoryVehicles.patch(this.getRequestData(data));
    this.engineHours = value;
    this.repositoryVehicles.patchState.reset();
  };

  @computed get licenseExpirationValue() {
    const year = this.licenseExpirationYear;
    const monthValue = Number(this.licenseExpirationMonth.replace(/\D+/g, ''));
    const month = monthValue < 10 ? `0${monthValue}` : `${monthValue}`;
    const isMonthAndYearValid = year !== 'blank' && month !== '00';

    return month && year && isMonthAndYearValid ? moment(`${month}-13-${year}`, 'MM-DD-YYYY').unix() * 1000 : 0;
  }

  getFieldValue(field: string) {
    let value;
    switch (field) {
      case 'displayName':
        value = this.name;
        break;
      case 'description':
        value = this.description;
        break;
      case 'vehicleID':
        value = this.vin;
        break;
      case 'vehicleMake':
        value = this.make;
        break;
      case 'vehicleModel':
        value = this.model;
        break;
      case 'vehicleYear':
        value = this.vehicleYear;
        break;
      case 'equipmentType':
        value = this.equipmentType;
        break;
      case 'licensePlate':
        value = this.plate;
        break;
      case 'licenseExpiration':
        value = this.licenseExpirationValue;
        break;
      case 'licenseState':
        value = this.licenseState;
        break;
      case 'notes':
        value = this.note;
        break;
      case 'maximumSpeed':
        value = this.maximumSpeed / MI_TO_KM;
        break;
      case 'camera.name':
        value = this.camera?.name;
        break;
      case 'driverId':
        value = this.driverId.replace(/\|(.+)?/, '');
        break;
      case 'driverName':
        value = this.driverName;
        break;
      case 'maintenanceNotes':
        value = this.maintenanceNotes;
        break;
      case 'fuelType':
        value = this.fuelDetails.fuelType.value;
        break;
      case 'tankCapacity':
        value = this.fuelDetails.tankCapacity.value;
        break;
    }
    return value;
  }

  getRequestData = (fields) => {
    return {
      deviceId: this.id,
      ...{
        ...fields.reduce((prev, next) => {
          return {
            ...prev,
            [next.key]: next.value,
          };
        }, {}),
      },
    };
  };

  @action saveField = async (key: string) => {
    return this.saveFields([key]);
  };

  @action saveFields = async (keys: string[]) => {
    const items = keys
      .map((key) => {
        return {
          key,
          value: this.getFieldValue(key),
        };
      })
      .filter((item) => {
        if (/driverId|notes/i.test(item.key)) {
          return item;
        }

        return item.hasOwnProperty('value');
      });

    if (items.length) {
      this.saveFieldsRequestStatus.reset().setLoading(true);

      try {
        await this.repositoryVehicles.patch(this.getRequestData(items));
        this.saveFieldsRequestStatus.setSuccess(true);
      } catch (error) {
        this.saveFieldsRequestStatus.setError(error.message || 'Something went wrong');
        this.saveFieldsRequestStatus.setLoading(false);
        throw new Error(error.message);
      }

      this.saveFieldsRequestStatus.setLoading(false);
    } else {
      return Promise.reject(`Unknown field`);
    }
  };

  @action updateDashcam = async () => {
    const item = await this.repositoryDashcams.patch({
      name: this.camera.name,
      uniqueId: this.camera.uniqueId,
      vehicleId: this.id,
    });

    if (item) {
      this.camera.name = item.name;
    }
  };

  @action resetMpg = async () => {
    try {
      await this.repositoryAssetResetMpg.delete({ id: this.assetId, timestamp: this.resetMpgTime.value });
    } catch (e) {
      return e;
    }
  };

  @action resetAdu = async () => {
    try {
      await this.repositoryAssetResetAdu.delete({ id: this.assetId, timestamp: this.resetAduTime.value });
    } catch (e) {
      return e;
    }
  };
}

export default Vehicle;
