import React, { FC } from 'react';

import ConfirmationModal from 'components/Admin/ConfirmationModal';

import './styles.scss';

interface IProps {
  className?: string;
  geozoneName: string;
  isDisabled?: boolean;
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

const CreateGeozoneConfirmationModal: FC<IProps> = ({ isDisabled, isOpen, onCancel, onSubmit, geozoneName }) => {
  return (
    <ConfirmationModal
      isSubmitDisabled={isDisabled}
      applyButtonTitle="Yes, Continue"
      cancelButtonStyles={{ border: 'none' }}
      cancelButtonTitle="No, Cancel"
      className="ConfirmationModal--createGeozoneConfirm"
      contentStyles={{ backgroundColor: '#fff' }}
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={onSubmit}
      title="Continue with Duplicate Geozone Name?"
      withoutCloseIcon
    >
      <div className="CreateGeozoneConfirmationModal">
        <p className="CreateGeozoneConfirmationModal-text">
          A Geozone named `{geozoneName}` already exists.
          <br />
          <br />
          Do you want to continue?
        </p>
      </div>
    </ConfirmationModal>
  );
};

export default CreateGeozoneConfirmationModal;
