import type ItemRegistry from './ItemRegistry';

interface IRegistryId {
  [key: string]: ItemRegistry;
}

class RegistryCode {
  asset: IRegistryId = {};
  account: IRegistryId = {};
  default: ItemRegistry;
}

export default RegistryCode;
