import React, { useCallback, useEffect } from 'react';
import { Observer } from 'mobx-react';
import { InputNumber, Radio, Space } from 'antd';
import isNil from 'lodash/isNil';

import type { SimpleField } from 'models/Fields';

import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';

import './styles.scss';

interface IProps {
  subType: SimpleField<Alerts.Modal.Maintenance.Type>;
  dueValue: SimpleField<number>;
}

const AlertPreferencesMaintenanceDue: React.FC<IProps> = ({ subType, dueValue }) => {
  const handleChangeType = (e) => {
    let type = e.target.value;
    if (type === 'MILEAGE') {
      type = 'MILEAGE_1';
    }

    if (dueValue.value !== 0) {
      setDueValue(type);
    }

    subType.set(type);
  };

  const handleChangeValue = (value) => {
    dueValue.set(value);
  };

  const handleChangeRadio = (e) => {
    const value = e.target.value;
    if (value === 1) {
      setDueValue(subType.value);
    } else {
      dueValue.set(0);
    }
  };

  const handleMaintenanceNumberChange = (value) => {
    subType.set(value.value);
  };

  const getRadioType = useCallback(() => {
    return subType.value === 'MILEAGE_1' || subType.value === 'MILEAGE_2' ? 'MILEAGE' : subType.value;
  }, [subType.value]);

  const getDueText = useCallback(() => {
    const radioType = getRadioType();

    return radioType === 'MILEAGE'
      ? 'miles before due'
      : radioType === 'ENGINE_HOURS'
      ? 'engine hours before due'
      : 'days before due';
  }, [getRadioType]);

  useEffect(() => {
    if (isNil(dueValue.value)) {
      const defaultValue =
        subType.value === 'MILEAGE_1' || subType.value === 'MILEAGE_2'
          ? 500
          : subType.value === 'ENGINE_HOURS'
          ? 20
          : 30;

      dueValue.set(defaultValue);
    }
  }, []);

  const setDueValue = (type) => {
    if (type === 'MILEAGE_1' || type === 'MILEAGE_2') {
      dueValue.set(500);
    } else if (type === 'ENGINE_HOURS') {
      dueValue.set(20);
    } else if (type === 'DATE') {
      dueValue.set(30);
    }
  };

  return (
    <Observer
      render={() => (
        <div className="AlertPreference-line">
          <div className="AlertPreference-label AlertPreference-label--top">alert when</div>
          <div className="AlertPreference-value AlertPreferencesMaintenanceDue-value">
            <div className="AlertPreferencesMaintenanceDue-line">
              <Radio.Group
                onChange={handleChangeType}
                value={getRadioType()}
                className="AlertPreferencesMaintenanceDue-switch"
              >
                <Radio.Button value="MILEAGE" className="AlertPreferencesMaintenanceDue-switchButton">
                  Mileage
                </Radio.Button>
                <Radio.Button value="ENGINE_HOURS" className="AlertPreferencesMaintenanceDue-switchButton">
                  Engine Hours
                </Radio.Button>
                <Radio.Button value="DATE" className="AlertPreferencesMaintenanceDue-switchButton">
                  Date
                </Radio.Button>
              </Radio.Group>
            </div>
            {getRadioType() === 'MILEAGE' && (
              <div className="AlertPreferencesMaintenanceDue-line AlertPreferencesMaintenanceDue-line-mileage">
                <SearchableStaticSelect
                  isSearchable={Boolean(false)}
                  handleChange={handleMaintenanceNumberChange}
                  value={
                    subType.value === 'MILEAGE_1'
                      ? {
                          value: 'MILEAGE_1',
                          label: 'Maintenance #1',
                        }
                      : { value: 'MILEAGE_2', label: 'Maintenance #2' }
                  }
                  values={[
                    { value: 'MILEAGE_1', label: 'Maintenance #1' },
                    { value: 'MILEAGE_2', label: 'Maintenance #2' },
                  ]}
                />
              </div>
            )}
            <div className="AlertPreferencesMaintenanceDue-line">
              <Radio.Group onChange={handleChangeRadio} value={dueValue.value === 0 ? 0 : 1}>
                <Space direction="vertical">
                  <Radio value={0} className="AlertPreferencesMaintenanceDue-isDue">
                    <span className="AlertPreferencesMaintenanceDue-isDue--text">is due</span>
                  </Radio>
                  <Radio value={1}>
                    <InputNumber
                      value={dueValue.value}
                      onChange={handleChangeValue}
                      className="AlertPreferencesMaintenanceDue-numberValue"
                    />
                    <span className="AlertPreferencesMaintenanceDue-dueText">{getDueText()}</span>
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default AlertPreferencesMaintenanceDue;
