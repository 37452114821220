import React, { Component } from 'react';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import type { DevicesStore } from 'stores';
import debounce from 'lodash/debounce';

import {
  getFormattedTime,
  getNowTimestampForTimezone,
  getTimeFromDayAndTime,
  getTimeInTimeZone,
  isToday,
  isSameDay,
} from 'utils';
import { KeyboardShortcutsManager } from 'tools';
import { DATE_TIME_FORMATS, HISTORY_MODE, TIME } from 'config';

import TimePicker from 'components/TimeRange/TimePicker';
import Calendar from 'components/Calendar';
import HistoryToolBarCalendarInput from 'components/HistoryToolBarCalendar/HistoryToolBarCalendarInput';
import InfoTooltip from 'components/InfoTooltip';
import Button from 'components/Button';

import './styles.scss';

interface IProps {
  from: number;
  to?: number;
  timezone?: string;
  fromMonth?: number;
  onApplyDate: (date: Filters.IDateFilterOption, manual: boolean) => void;
  lastEvent: number;
  firstEvent: number;
  deviceId: string;
  mode: string;
  devicesStore?: DevicesStore;
}

interface IState {
  focused: boolean;
  dirty: boolean;
  modified: boolean;
  dateFrom: number;
  dateTo: number;

  timeFrom: string;
  timeTo: string;
  focusedOn: 'from' | 'to';
}

@inject(({ devicesMapStore: { devicesStore } }) => ({ devicesStore }))
@observer
class HistoryToolBarCalendar extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    const {
      devicesStore: {
        history: { activeMode },
      },
    } = props;

    const isRecentActivity = activeMode.id === HISTORY_MODE.MOST_RECENT_ACTIVITY;

    this.debouncedApplyDate = debounce(this.applyDate.bind(this), 60);

    const lastEvent = this.getLastEventValue(props.lastEvent, props.timezone);
    const dateFrom = isRecentActivity && lastEvent ? lastEvent : props.from ? props.from : undefined;

    this.state = {
      modified: false,
      dirty: false,
      focused: false,
      dateFrom,
      dateTo: isRecentActivity && lastEvent ? lastEvent : props.to,
      timeFrom:
        props.firstEvent &&
        dateFrom &&
        HistoryToolBarCalendar.isFirstEventBeforeDate(props.firstEvent, dateFrom, TIME.beggingOfTheDay, props.timezone)
          ? HistoryToolBarCalendar.timeFromFirstEvent(
              props.firstEvent,
              DATE_TIME_FORMATS.hoursFullMinutesSpaceAm,
              props.timezone
            )
          : TIME.beggingOfTheDay,
      timeTo:
        props.to && activeMode.id === HISTORY_MODE.TIME_RANGE && !isToday(props.to, props.timezone)
          ? TIME.endOfTheDay
          : HistoryToolBarCalendar.currentTimeTo(props.timezone),
      focusedOn: null,
    };
  }

  lastApply = {
    dateTo: null,
    timeTo: null,
    dateFrom: null,
    timeFrom: null,
  };

  static isFirstEventBeforeDate = (firstEvent: number, dateValue: number, timeString: string, timezone: string) => {
    if (firstEvent && isSameDay(dateValue, firstEvent, timezone)) {
      const firstEventTimeToMinute =
        getTimeInTimeZone(firstEvent, timezone)
          .endOf('minute')
          .valueOf() - 1000;
      const selectedTime = getTimeFromDayAndTime(dateValue, timeString, timezone);
      return firstEventTimeToMinute > selectedTime;
    }
    return false;
  };

  isFirstEventBeforeDate = () => {
    const { dateTo, timeTo } = this.state;
    const { timezone, firstEvent } = this.props;
    return HistoryToolBarCalendar.isFirstEventBeforeDate(firstEvent, dateTo, timeTo, timezone);
  };

  debouncedApplyDate = () => void 0;

  private applyDate = () => {
    const { dateTo, timeFrom, timeTo, dateFrom, dirty } = this.state;

    const { onApplyDate, timezone } = this.props;

    if (this.isFirstEventBeforeDate()) {
      return this.setState(
        {
          timeTo: this.timeFromFirstEvent,
        },
        this.applyDate
      );
    }

    let from = null;

    const to = getTimeFromDayAndTime(dateTo, timeTo, timezone);

    if (this.props.from) {
      from = getTimeFromDayAndTime(dateFrom, timeFrom, timezone);
    }

    if (!this.state.modified || !this.state.focused) {
      if (from <= to || !this.props.from) {
        onApplyDate({ from, to }, dirty);
        if (dirty) {
          this.onChangeRecentActivityToTimeRange();
        }
        this.lastApply = {
          dateTo,
          timeTo,
          dateFrom,
          timeFrom,
        };
      }
    }
  };

  getLastEventValue(lastEvent, timezone) {
    return lastEvent ? getTimeInTimeZone(lastEvent, timezone).valueOf() : 0;
  }

  getIsLastEventToday(lastEvent, timezone) {
    return isToday(this.getLastEventValue(lastEvent, timezone), timezone);
  }

  get isPointInTime() {
    const {
      devicesStore: {
        history: { activeMode },
      },
    } = this.props;

    return activeMode.id && activeMode.id === HISTORY_MODE.POINT_IN_TIME;
  }

  setTimeForPointInTime = () => {
    const {
      devicesStore: {
        history: { activeMode },
      },
    } = this.props;

    if (activeMode.id && activeMode.id === HISTORY_MODE.POINT_IN_TIME && !this.state.dirty) {
      this.setState(
        {
          dateTo: this.currentDateTo,
          timeTo: this.currentTimeTo,
        },
        this.applyDate
      );
    }
  };

  setTimeForMostRecentActivity = () => {
    const {
      devicesStore: {
        history: { activeMode },
      },
      lastEvent,
      from,
      timezone,
    } = this.props;

    if (activeMode.id && activeMode.id === HISTORY_MODE.MOST_RECENT_ACTIVITY) {
      const lastEventValue = this.getLastEventValue(lastEvent, timezone);

      this.setState(
        {
          dateFrom: lastEventValue ? lastEventValue : from,
          dateTo: this.currentDateTo,
          timeFrom: TIME.beggingOfTheDay,
          timeTo: this.currentTimeTo,
          dirty: false,
        },
        this.applyDate
      );
    }
  };

  componentDidMount() {
    this.setTimeForPointInTime();
    this.setTimeForMostRecentActivity();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      devicesStore: {
        history: { activeMode },
      },
      lastEvent,
      from,
      timezone,
    } = this.props;
    const isModeChanged = prevProps.mode && prevProps.mode !== this.props.mode;
    const isDeviceIdChanged = prevProps.deviceId && prevProps.deviceId !== this.props.deviceId;
    const prevTimezone = prevProps.timezone;

    if (isModeChanged || isDeviceIdChanged) {
      if (activeMode.id) {
        if (this.props.deviceId) {
          this.setTimeForMostRecentActivity();
        } else {
          this.setTimeForPointInTime();
        }
      }
    }
    if (isModeChanged) {
      this.setTimeForPointInTime();
      if (!this.state.dateFrom && from) {
        this.setState({
          dateFrom: lastEvent ? lastEvent : from,
        });
      }
    }
    if (isDeviceIdChanged) {
      this.debouncedApplyDate();
    }

    if (prevState.focused !== this.state.focused) {
      this.state.focused ? KeyboardShortcutsManager.get().deactivate() : KeyboardShortcutsManager.get().activate();
    }

    if (timezone !== prevTimezone) {
      this.applyDate();
    }
  }

  onChangeRecentActivityToTimeRange = () => {
    const {
      devicesStore: {
        history: { setMode, activeMode },
      },
    } = this.props;

    if (activeMode.id === HISTORY_MODE.MOST_RECENT_ACTIVITY) {
      setMode(HISTORY_MODE.TIME_RANGE);
    }
  };

  onSelectFrom = (timeFrom) => {
    this.onChangeRecentActivityToTimeRange();
    this.setState({ timeFrom, dirty: true, modified: true }, this.applyDate.bind(this));
  };

  onSelectTo = (timeTo) => {
    this.onChangeRecentActivityToTimeRange();
    this.setState({ timeTo, dirty: true, modified: true }, this.applyDate.bind(this));
  };

  handleFocusToTime = () => {
    this.handleFocus('to');
  };
  handleFocusFromTime = () => {
    this.handleFocus('from');
  };

  handleFocus = (type: 'from' | 'to') => {
    this.setState({
      focused: true,
      focusedOn: type,
    });
  };

  handleBlur = () => {
    this.setState({
      focused: false,
      focusedOn: null,
    });
  };

  handleOutsideClick = () => {
    this.handleCancelClick();
  };

  handleCalendarClick = (selectedDate) => {
    const { firstEvent, timezone } = this.props;

    if (selectedDate.from && selectedDate.to) {
      this.setState(
        {
          dateTo: moment(selectedDate.to).valueOf(),
          dateFrom: moment(selectedDate.from).valueOf(),
          dirty: true,
          modified: true,
          timeFrom: isSameDay(selectedDate.from, firstEvent, timezone) ? this.timeFromFirstEvent : TIME.beggingOfTheDay,
          timeTo: !isToday(selectedDate.to, this.props.timezone) ? TIME.endOfTheDay : this.currentTimeTo,
        },
        this.applyDate.bind(this)
      );
    } else {
      this.setState(
        {
          dateTo: moment(selectedDate).valueOf(),
          dirty: true,
          modified: true,
        },
        this.applyDate.bind(this)
      );
    }
  };

  handleApplyClick = () => {
    const isModified = this.state.modified;
    this.setState(
      {
        focused: false,
        modified: false,
      },
      () => {
        if (isModified) {
          this.applyDate();
        }
      }
    );
  };
  handleCancelClick = () => {
    const {
      devicesStore: {
        history: { activeMode },
      },
    } = this.props;

    const isRecentActivity = activeMode.id === HISTORY_MODE.MOST_RECENT_ACTIVITY;

    const lastEvent = this.getLastEventValue(this.props.lastEvent, this.props.timezone);

    this.setState({
      focused: false,
      modified: false,
      dateFrom: this.lastApply.dateFrom
        ? this.lastApply.dateFrom
        : isRecentActivity && lastEvent
        ? lastEvent
        : this.props.from
        ? this.props.from
        : undefined,
      dateTo: this.lastApply.dateTo ? this.lastApply.dateTo : isRecentActivity && lastEvent ? lastEvent : this.props.to,
      timeFrom: this.lastApply.timeFrom ? this.lastApply.timeFrom : TIME.beggingOfTheDay,
      timeTo: this.lastApply.timeTo ? this.lastApply.timeTo : this.currentTimeTo,
    });
  };

  static timeFromFirstEvent(firstEvent: number, format: string, timezone: string) {
    return firstEvent ? getFormattedTime(firstEvent, format, timezone) : null;
  }

  get timeFromFirstEvent() {
    return HistoryToolBarCalendar.timeFromFirstEvent(
      this.props.firstEvent,
      DATE_TIME_FORMATS.hoursFullMinutesSpaceAm,
      this.props.timezone
    );
  }

  static currentTimeTo(timezone: string) {
    return getFormattedTime(Date.now(), DATE_TIME_FORMATS.hoursFullMinutesSpaceAm, timezone);
  }

  get currentTimeTo() {
    return HistoryToolBarCalendar.currentTimeTo(this.props.timezone);
  }

  get currentDateTo() {
    return getNowTimestampForTimezone(this.props.timezone);
  }

  get withRange() {
    return Boolean(this.props.from);
  }

  get selectedToDate() {
    return getTimeInTimeZone(this.state.dateTo, this.props.timezone).toDate();
  }

  get hasWrongTimeSelection() {
    if (!this.props.from) {
      return false;
    }
    const { dateFrom, timeFrom, dateTo, timeTo } = this.state;
    const { timezone } = this.props;

    return getTimeFromDayAndTime(dateFrom, timeFrom, timezone) > getTimeFromDayAndTime(dateTo, timeTo, timezone);
  }

  get selectedFromDate() {
    return getTimeInTimeZone(this.state.dateFrom, this.props.timezone).toDate();
  }

  get fromMonth() {
    const { timezone, firstEvent, deviceId, fromMonth, from } = this.props;
    const firstEventTimestamp = deviceId && getFormattedTime(firstEvent, DATE_TIME_FORMATS.monthDatYearFull, timezone);
    if (firstEvent) {
      const d = new Date(firstEventTimestamp);
      d.setMonth(d.getMonth() - (from ? 1 : 0));
      return d;
    } else if (fromMonth) {
      return new Date(fromMonth);
    }
    return new Date(2013, 1);
  }

  render() {
    const { from, timezone, firstEvent, deviceId, fromMonth } = this.props;
    const { focused, dateFrom, timeFrom, dateTo, timeTo } = this.state;

    const fromText = 'From';
    const toText = from ? 'To' : 'Date/Time';

    const firstEventTimestamp = deviceId && getFormattedTime(firstEvent, DATE_TIME_FORMATS.monthDatYearFull, timezone);

    const bodyCN = classNames('HistoryToolBarCalendar-Body', {
      'HistoryToolBarCalendar-Body--double': Boolean(from),
    });

    const contentCN = classNames('HistoryToolBarCalendar-Content', {
      'HistoryToolBarCalendar-Content--open': focused,
    });

    const actionsCN = classNames('HistoryToolBarCalendar-Actions', {
      'HistoryToolBarCalendar-Actions--open': focused,
    });

    const dayPickerCN = classNames('HistoryToolBarCalendar-DayPiker', {
      'HistoryToolBarCalendar-DayPiker--double': !!from,
    });

    return (
      <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <div className={bodyCN}>
          <div className={contentCN}>
            <div className={actionsCN}>
              {from && (
                <div className="HistoryToolBarCalendar-SelectorBody HistoryToolBarCalendar-SelectorFrom">
                  <div className="HistoryToolBarCalendar-SelectorTitle">{fromText}</div>
                  <div className="HistoryToolBarCalendar-SelectorDate">
                    <HistoryToolBarCalendarInput
                      type="from"
                      value={dateFrom}
                      onFocus={this.handleFocus}
                      isOpen={focused}
                      timezone={timezone}
                    />
                  </div>
                  <div className="HistoryToolBarCalendar-SelectorTime">
                    <TimePicker
                      onSelect={this.onSelectFrom}
                      value={timeFrom}
                      after={isSameDay(dateFrom, firstEvent, timezone) && this.timeFromFirstEvent}
                      before={isToday(dateFrom, timezone) && this.currentTimeTo}
                      onFocus={this.handleFocusFromTime}
                    />
                  </div>
                </div>
              )}
              <div className="HistoryToolBarCalendar-SelectorBody HistoryToolBarCalendar-SelectorFrom">
                <div className="HistoryToolBarCalendar-SelectorTitle">{toText}</div>
                <div className="HistoryToolBarCalendar-SelectorDate">
                  <HistoryToolBarCalendarInput
                    type="to"
                    value={dateTo}
                    onFocus={this.handleFocus}
                    isOpen={focused}
                    timezone={timezone}
                  />
                </div>
                <div className="HistoryToolBarCalendar-SelectorTime">
                  <TimePicker
                    onSelect={this.onSelectTo}
                    value={timeTo}
                    after={
                      isSameDay(dateTo, firstEvent, timezone) &&
                      (this.withRange || this.isPointInTime) &&
                      this.timeFromFirstEvent
                    }
                    before={isToday(dateTo, timezone) && this.currentTimeTo}
                    onFocus={this.handleFocusToTime}
                  />
                </div>
              </div>
              {this.hasWrongTimeSelection && (
                <InfoTooltip className="HistoryToolBarCalendar-Warning" placement="bottomRight">
                  <div className="HistoryToolBarCalendar-Warning-content">
                    <p>Times must be in sequential order</p>
                  </div>
                </InfoTooltip>
              )}
            </div>
            {focused ? (
              <div className={dayPickerCN}>
                <Calendar
                  fromMonth={this.fromMonth}
                  toMonth={new Date()}
                  disabledDays={[{ before: new Date(firstEvent ? firstEventTimestamp : fromMonth), after: new Date() }]}
                  onClick={this.handleCalendarClick}
                  selectedDate={!this.withRange && this.selectedToDate}
                  numberOfMonths={from ? 2 : 1}
                  withMonthYearForm
                  withRange={this.withRange}
                  from={getTimeInTimeZone(dateFrom, timezone).toDate()}
                  to={this.selectedToDate}
                  month={this.state.focusedOn === 'from' ? this.selectedFromDate : this.selectedToDate}
                  withoutOutside
                />
              </div>
            ) : null}

            {focused ? (
              <div className="HistoryToolBarCalendar-Control">
                <Button
                  onClick={this.handleCancelClick}
                  title="Cancel"
                  className="HistoryToolBarCalendar-ControlButton"
                />
                <Button
                  onClick={this.handleApplyClick}
                  title="Apply"
                  className="HistoryToolBarCalendar-ControlButton HistoryToolBarCalendar-ControlButton--active"
                  disabled={this.hasWrongTimeSelection}
                />
              </div>
            ) : null}
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}

export default HistoryToolBarCalendar;
