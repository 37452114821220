import React from 'react';
import { Observer } from 'mobx-react';
import type { ToggleField } from 'models/Fields';
import Input from 'components/Input';

interface IProps {
  title: string;
  value: string;
  isEdit: ToggleField;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

const MaintenanceCompletedTaskPanelSummaryInput: React.FC<IProps> = ({
  title,
  value,
  isEdit,
  onChange = () => true,
  disabled = false,
}) => {
  return (
    <Observer
      render={() => (
        <div className="MaintenanceCompletedTaskPanelContent-SummaryLine">
          <div className="MaintenanceCompletedTaskPanelContent-SummaryLineLabel">{title}</div>
          <div className="MaintenanceCompletedTaskPanelContent-SummaryLineValue">
            {isEdit.value ? (
              <div>
                <Input value={value} disabled={disabled} onChange={(e) => onChange(e.currentTarget.value)} />
              </div>
            ) : (
              <div>{value ? value : '-'}</div>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default MaintenanceCompletedTaskPanelSummaryInput;
