import { action, observable, reaction } from 'mobx';
import { debounce } from 'lodash';

import { repositoryService } from 'services';

import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';

class ClosestVehiclesStore {
  @observable vehicles: any[];
  @observable loading: boolean = true;
  @observable latestRequestTime: number = 0;
  @observable private latitude: string = null;
  @observable private longitude: string = null;
  @observable private timestamp: number = null;
  private static instance: ClosestVehiclesStore;
  private repository: IRepository;
  private repositoryDistance: IEntityRepository;
  private excludedDeviceIds: string[] = [];

  private constructor() {
    this.vehicles = [];
    this.repository = repositoryService.get('vehicles');
    this.repositoryDistance = this.repository.entity('nearest').entity('distance');

    reaction(
      () => this.latitude || this.longitude || this.timestamp,
      () => this.fetch()
    );
  }

  public static get(): ClosestVehiclesStore {
    if (!ClosestVehiclesStore.instance) {
      ClosestVehiclesStore.instance = new ClosestVehiclesStore();
    }

    return ClosestVehiclesStore.instance;
  }

  @action fetch = debounce(async (indicateLoading: boolean = false) => {
    const requestTime = Date.now();
    this.latestRequestTime = requestTime;
    if (indicateLoading) {
      this.loading = true;
    }

    const data = await this.repositoryDistance.get({
      latitude: this.latitude,
      longitude: this.longitude,
      timestamp: this.timestamp,
      includeInactive: true,
    });

    if (requestTime === this.latestRequestTime) {
      this.vehicles = data.filter(({ deviceId }) => !this.excludedDeviceIds.includes(deviceId));
      this.loading = false;
    }
  }, 60);

  setExcludedDeviceIds = (excludedDeviceIds: string[]) => {
    this.excludedDeviceIds = excludedDeviceIds;
    return this;
  };

  @action setLocation = ({ latitude, longitude, timestamp }) => {
    this.latitude = latitude;
    this.longitude = longitude;
    this.timestamp = timestamp;
    return this;
  };

  @action reset = () => {
    this.vehicles = [];
  };
}

export default ClosestVehiclesStore;
