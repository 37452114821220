import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter, matchPath } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';
import { debounce } from 'lodash';

import { PATHS, REPORTS_STORE } from 'config';

import type { ReportsStore } from 'stores';

import ReportsList from 'containers/Reports/ReportsList';
import ReportPreferences from 'containers/Reports/ReportPreferences';

import ReportsNavigationCollapsibleButton from 'components/Reports/ReportsNavigationCollapsibleButton';

import './styles.scss';

interface IProps extends RouteComponentProps {
  reportsStore?: ReportsStore;
}

interface IState {
  hasVerticalScrollbar: boolean;
}

@inject(REPORTS_STORE)
@observer
class ReportsNavigation extends React.Component<IProps, IState> {
  date: number = 0;
  contentRef: React.RefObject<any>;
  observer: MutationObserver;

  state = {
    hasVerticalScrollbar: false,
  };

  constructor(props) {
    super(props);
    this.date = Date.now();
    this.contentRef = React.createRef();
    this.validateScroll = debounce(this.validateScroll.bind(this), 16);
    this.observer = new MutationObserver(this.validateScroll);
  }

  toggle = () => {
    const {
      reportsStore: {
        subNavigation: { toggle },
      },
    } = this.props;

    toggle();
  };

  handleCLick = () => {
    this.toggle();
  };

  validateScroll() {
    const div = this.contentRef.current;
    if (div) {
      const hasVerticalScrollbar = div.scrollHeight > div.clientHeight;
      if (hasVerticalScrollbar !== this.state.hasVerticalScrollbar) {
        this.setState({
          hasVerticalScrollbar,
        });
      }
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.validateScroll);
    this.observer.observe(this.contentRef.current, { attributes: false, childList: true, subtree: false });
    this.validateScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.validateScroll);
    this.observer.disconnect();
  }

  render() {
    const {
      reportsStore: {
        subNavigation: { expanded },
        selectedReport,
      },
      location: { pathname },
    } = this.props;

    const { hasVerticalScrollbar } = this.state;

    const cn = classnames('ReportsNavigation', {
      'ReportsNavigation--expanded': expanded,
      'ReportsNavigation--collapsed': !expanded,
      'ReportsNavigation--withFilter': matchPath(pathname, {
        path: PATHS.REPORTS.REPORT,
      }),
      'ReportsNavigation--withScroll': hasVerticalScrollbar,
      'ReportsNavigation--withSelectedReport': selectedReport,
    });

    return (
      <div className={cn}>
        <div className="ReportsNavigation-content" ref={this.contentRef}>
          <Switch>
            <Route path={PATHS.REPORTS.INDEX} exact component={ReportsList} />
            <Route path={PATHS.REPORTS.SCHEDULE} component={ReportsList} />
            <Route path={PATHS.REPORTS.REPORT} component={ReportPreferences} />
          </Switch>
        </div>
        <ReportsNavigationCollapsibleButton isExpanded={expanded} onClick={this.handleCLick} />
      </div>
    );
  }
}

export default withRouter(ReportsNavigation);
