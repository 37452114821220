import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const DispatchLocation = ({ location, isCompleted, isFirst }) => {
  const containerCN = classNames('DispatchLocation-container', {
    'DispatchLocation-container--completed': isCompleted,
    'DispatchLocation-container--first': isFirst,
  });

  return (
    <div className="DispatchLocation">
      <div className={containerCN}>
        <span>{location}</span>
      </div>
    </div>
  );
};

export default DispatchLocation;
