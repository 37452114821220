import React  from 'react';
import classNames from 'classnames';

import SecondaryPanelEstimateItem from './SecondaryPanelEstimateItem';
import FinancialDataAccessError from 'components/FinancialDataAccessError';

import './styles.scss';

interface IProps {
  limited: boolean;
  items: Array<{
    name: string;
    quantity: number;
    totalPrice: number;
    unitPrice: number;
  }>
}

const SecondaryPanelEstimateCostsItems: React.FC<IProps> = ({ items, limited }) => {
  return <div className="SecondaryPanelEstimateCostsItems">
    <div className="SecondaryPanelEstimateCostsItems-header">
      <div>Item</div>
      <div>Qty</div>
      <div>Unit Price</div>
      <div>Total</div>
    </div>
    <div
      className={classNames('SecondaryPanelEstimateCostsItems-body', {
        'SecondaryPanelEstimateCostsItems-body--limited': limited,
      })}
    >
      <div
        className={classNames('SecondaryPanelEstimateCostsItems-list', {
          'SecondaryPanelEstimateCostsItems-list--limited': limited,
        })}
      >
        {items.map((item, index) => <SecondaryPanelEstimateItem key={index} {...item} limited={limited}/>)}
      </div>
      {limited && <FinancialDataAccessError className="SecondaryPanelEstimateCostsItems-column--limited" />}
      {limited && <FinancialDataAccessError className="SecondaryPanelEstimateCostsItems-column--limited" />}
    </div>
  </div>;
};

export default SecondaryPanelEstimateCostsItems;
