import { ToggleField } from 'models/Fields';
import ReportPreferenceItem from 'models/Report/ReportPreferenceItem';
import type { IScheduledReportParameters } from './IScheduledReport';

class ScheduledReportParameters implements IScheduledReportParameters {
  report: ReportPreferenceItem;
  driver: ReportPreferenceItem;
  tag: ReportPreferenceItem;
  hideInactive: ToggleField;
  geozone: ReportPreferenceItem;
  option: ReportPreferenceItem;

  constructor({ report = {}, driver = {}, tag = {}, hideInactive = true, geozone = {}, option = {} }) {
    this.report = new ReportPreferenceItem(report);
    this.driver = new ReportPreferenceItem(driver);
    this.tag = new ReportPreferenceItem(tag);
    this.hideInactive = new ToggleField(hideInactive);
    this.geozone = new ReportPreferenceItem(geozone);
    this.option = new ReportPreferenceItem(option);
  }

  reset = () => {
    this.report.reset();
    this.driver.reset();
    this.tag.reset();
    this.hideInactive.reset();
    this.geozone.reset();
    this.option.reset();
  };

  getModel = () => {
    return {
      driver: this.driver.model,
      report: this.report.model,
      tag: this.tag.model,
      hideInactive: this.hideInactive.value,
      geozone: this.geozone.model,
      option: this.option.model,
    };
  };
}

export default ScheduledReportParameters;
