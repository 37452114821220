import { action, computed, observable } from 'mobx';
import { uniqBy, sortBy } from 'lodash';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import MediaGroup from './MediaGroup';
import type { ISelectOptions } from 'interfaces/models/Select/ISelect';
import { repositoryService } from 'services';
import { SimpleField } from 'models/Fields';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';

export interface IServerGroup {
  id: string;
  assetGroupId: number;
  cpNumber: string;
  displayName: string;
}

export default class MediaGroupList implements ISelectOptions<MediaGroup, IServerGroup> {
  @observable items: MediaGroup[];
  @observable selectedAssetGroupId: SimpleField<string>;
  @observable totalPages: number;
  repository: IEntityRepository;
  repositoryId: IRepository;

  constructor() {
    this.items = [];
    this.selectedAssetGroupId = new SimpleField(null);
    this.repository = repositoryService.get('groups').entity('ids');
    this.repositoryId = repositoryService.get('asset-groups');
  }

  init = (assetGroupId) => {
    if (assetGroupId) {
      this.selectedAssetGroupId.set(assetGroupId);
    }
  };

  @computed get selected() {
    return this.items.find((option) => option.assetGroupId === this.selectedAssetGroupId.value);
  }

  @computed get params() {
    return this.selectedAssetGroupId && { assetGroupId: this.selectedAssetGroupId.value };
  }

  validateSelectedInResponse = async (options) => {
    if (this.selectedAssetGroupId && !options.find(({ assetGroupId }) => assetGroupId === this.selectedAssetGroupId)) {
      // find missed option
    }
    return options;
  };

  @action fetch = async (data, { clear, sortId, sortLabel }) => {
    try {
      const response = await this.repository.get(data);
      const options = response?.items.filter((item) => Boolean(item.assetGroupId)).map((item) => new MediaGroup(item));
      const nextOptions = await this.validateSelectedInResponse(clear ? [...options] : [...this.items, ...options]);
      const uniqueOptions = uniqBy(nextOptions, sortId);
      const sortOptions = sortBy(uniqueOptions, [(option) => option[sortLabel].toLowerCase()]);

      this.items = sortOptions;
      this.totalPages = response?.totalPages;
      this.addDefaultOption();
    } catch (e) {
      return e;
    }
  };

  @action addDefaultOption = () => {
    if (!this.items.find(({ id }) => id === '')) {
      this.items.unshift({
        ...new MediaGroup({ displayName: 'All Vehicles', assetGroupId: '', id: '', description: 'All Vehicles' }),
      });
    }
  };

  @action addOption = async (option) => {
    const currentOption = this.getOptionById(option.value.toString());

    if (!currentOption) {
      const response = await this.repositoryId.entity(option.value).get();
      this.items.push(
        new MediaGroup({
          assetGroupId: response.id,
          displayName: response.description,
          description: response.description,
          id: '',
        })
      );
    }
  };

  getOptionById = (assetGroupId: string): MediaGroup => {
    return this.items.find((item) => item.assetGroupId === assetGroupId);
  };
}
