import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import { Prompt, RouteComponentProps } from 'react-router-dom';

import type { GeozonesAdmin } from 'stores/Admin/Geozones';
import { ADMIN_GEOZONES_DELETE, PATHS } from 'config';
import type { PersistenceStore, RouterStore } from 'stores';
import { validateGeozoneName } from 'utils';
import validateAccessLevel from 'stores/acl/validator';

import AdminGeozoneDetails from '../AdminGeozoneDetails';
import AdminGeozoneSettings from '../GeozoneSettings';
import Button from 'components/Button';
import BackButton from 'components/BackButton';
import ErrorNotification from 'components/ErrorNotification';
import SearchableGeoZoneSelect from 'containers/Select/SearchableGeoZoneSelect';
import TabsMenu from 'components/TabsMenu';
import TableActions from 'components/Table/Actions';
import EditGeozone from '../EditGeozone';
import DeleteGeozoneModal from '../DeleteGeozone';

import './styles.scss';

const tabs = [{ name: 'Map/Zone' }, { name: 'Settings' }];

interface IMatchParams {
  id: string;
}

export interface IChangeParams {
  name: string;
  value: string | number | boolean | Geozone.IGeozoneCoordinates;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  className?: string;
  geozonesAdmin?: GeozonesAdmin;
  persistenceStore?: PersistenceStore;
  routerStore?: RouterStore;
}

interface IGeozoneData {
  color: Geozone.IGeozoneColor;
  groupIdGts: string;
  isActive: boolean;
  name: string;
  reverseGeocode: boolean;
  speedLimit: number;
  tag: string;
  type: string;
  hideOnMaps: boolean;
  departureZone: boolean;
  arrivalZone: boolean;
  notes: string;
  coordinates: Geozone.IGeozoneCoordinates;
  radius: number;
  units: string;
  dispatchMarkerAddress: string;
  dispatchMarkerLatitude: number;
  dispatchMarkerLongitude: number;
}

interface IGeozoneUpdatedFieldsState {
  color: boolean;
  groupIdGts: boolean;
  isActive: boolean;
  name: boolean;
  reverseGeocode: boolean;
  speedLimit: boolean;
  tag: boolean;
  type: boolean;
  hideOnMaps: boolean;
  departureZone: boolean;
  arrivalZone: boolean;
  notes: boolean;
  coordinates: boolean;
  radius: boolean;
  units: boolean;
  dispatchMarkerAddress: boolean;
  dispatchMarkerLatitude: boolean;
  dispatchMarkerLongitude: boolean;
}

interface IState {
  isSent: boolean;
  geozoneInitialData: IGeozoneData;
  geozoneFieldsState: IGeozoneUpdatedFieldsState;
  isDeleteModalOpen: boolean;
  isDeleteGeozone: boolean;
}

const geozoneInitialFieldsState = {
  color: false,
  groupIdGts: false,
  isActive: false,
  name: false,
  reverseGeocode: false,
  speedLimit: false,
  tag: false,
  type: false,
  hideOnMaps: false,
  departureZone: false,
  arrivalZone: false,
  notes: false,
  coordinates: false,
  radius: false,
  units: false,
  dispatchMarkerAddress: false,
  dispatchMarkerLatitude: false,
  dispatchMarkerLongitude: false,
};

@inject(({ adminStore: { geozonesAdmin }, persistenceStore, routerStore }) => ({
  geozonesAdmin,
  persistenceStore,
  routerStore,
}))
@observer
class AdminSelectedGeozone extends Component<IProps, IState> {
  newTagWatcherDisposer: () => void;

  state = {
    isSent: false,
    geozoneInitialData: {
      color: null,
      groupIdGts: '',
      isActive: false,
      name: '',
      reverseGeocode: false,
      speedLimit: 0,
      tag: '',
      type: '',
      hideOnMaps: false,
      arrivalZone: false,
      departureZone: false,
      notes: '',
      coordinates: [],
      radius: 0,
      units: 'meters',
      dispatchMarkerAddress: '',
      dispatchMarkerLatitude: 0,
      dispatchMarkerLongitude: 0,
    },
    geozoneFieldsState: {
      color: false,
      groupIdGts: false,
      isActive: false,
      name: false,
      reverseGeocode: false,
      speedLimit: false,
      tag: false,
      type: false,
      hideOnMaps: false,
      arrivalZone: false,
      departureZone: false,
      notes: false,
      coordinates: false,
      radius: false,
      units: false,
      dispatchMarkerAddress: false,
      dispatchMarkerLatitude: false,
      dispatchMarkerLongitude: false,
    },
    isDeleteModalOpen: false,
    isDeleteGeozone: false,
  };

  onUnload = (e) => {
    if (this.isGeozoneUpdated()) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  componentDidMount() {
    this.setGeozone();
    this.createNewTagWatcher();
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentDidUpdate(prevProps, prevState) {
    const isNewGeozone = prevProps.match.params.id !== this.geozoneId;
    const isGeozoneUpdated = this.state.isSent && prevState.isSent !== this.state.isSent;

    if (isNewGeozone || isGeozoneUpdated) {
      this.setGeozone();
    }
  }

  componentWillUnmount() {
    const {
      geozonesAdmin: { resetSelectedGeozone },
    } = this.props;

    resetSelectedGeozone();
    this.newTagWatcherDisposer();
    window.removeEventListener('beforeunload', this.onUnload);
  }

  get geozoneId() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    return id;
  }

  createNewTagWatcher = () => {
    this.newTagWatcherDisposer = reaction(
      () => {
        const {
          geozonesAdmin: { selectedGeozone },
        } = this.props;

        return selectedGeozone?.tag;
      },
      (tag) => {
        this.setState({
          ...this.state,
          geozoneInitialData: {
            ...this.state.geozoneInitialData,
            tag,
          },
          geozoneFieldsState: {
            ...this.state.geozoneFieldsState,
            tag: false,
          },
        });
      },
      {
        name: 'Update initial geozone tag on creating a new one',
      }
    );
  };

  setInitialGeozoneData = () => {
    const {
      geozonesAdmin: { selectedGeozone },
    } = this.props;

    this.setState({
      ...this.state,
      geozoneInitialData: {
        name: selectedGeozone?.name,
        type: selectedGeozone?.type,
        color: selectedGeozone?.color,
        tag: selectedGeozone?.tag,
        groupIdGts: selectedGeozone?.groupIdGts,
        isActive: selectedGeozone?.isActive,
        speedLimit: selectedGeozone?.speedLimit,
        reverseGeocode: selectedGeozone?.reverseGeocode,
        hideOnMaps: selectedGeozone?.hideOnMaps,
        arrivalZone: selectedGeozone?.arrivalZone,
        departureZone: selectedGeozone?.departureZone,
        notes: selectedGeozone?.notes,
        coordinates: selectedGeozone?.coordinates,
        radius: selectedGeozone?.radius,
        units: selectedGeozone?.units,
        dispatchMarkerAddress: selectedGeozone?.dispatchMarkerAddress,
        dispatchMarkerLatitude: selectedGeozone?.dispatchMarkerLatitude,
        dispatchMarkerLongitude: selectedGeozone?.dispatchMarkerLongitude,
      },
    });
  };

  setInitialUpdatedFields = () => {
    this.setState({ ...this.state, geozoneFieldsState: geozoneInitialFieldsState });
  };

  setGeozone = async () => {
    const {
      geozonesAdmin: { getGeozone },
      history: { push },
    } = this.props;

    try {
      await getGeozone(this.geozoneId);
      this.setInitialGeozoneData();
      this.setInitialUpdatedFields();
    } catch (e) {
      push({ pathname: PATHS.NOT_FOUND, state: { from: PATHS.ADMIN.TABLES.GEOZONES.INDEX } });
    }
  };

  setSuccessSend = () => {
    this.setState({ isSent: true });
    setTimeout(() => {
      this.setState({ isSent: false });
    }, 2000);
  };

  handleGeozoneDataChange = <T extends IChangeParams>(field: T | T[]) => {
    const { geozoneInitialData } = this.state;
    if (field instanceof Array) {
      const newFields = field.reduce(
        (acc, { name, value }) => ({ ...acc, [name]: geozoneInitialData[name] !== value }),
        {}
      );
      this.setState((prevState) => ({
        ...prevState,
        geozoneFieldsState: {
          ...prevState.geozoneFieldsState,
          ...newFields,
        },
      }));
    } else {
      const initialFieldValue = geozoneInitialData[field.name];
      this.setState((prevState) => ({
        ...prevState,
        geozoneFieldsState: {
          ...prevState.geozoneFieldsState,
          [field.name]: initialFieldValue !== field.value,
        },
      }));
    }
  };

  private checkBooleanChanges = (field, curFieldName) => {
    const { geozoneInitialData } = this.state;
    const {
      geozonesAdmin: { selectedGeozone },
    } = this.props;

    if (selectedGeozone && field === curFieldName) {
      return geozoneInitialData[curFieldName] !== selectedGeozone[curFieldName];
    }
  };

  isGeozoneUpdated = () => {
    const { geozoneFieldsState, geozoneInitialData } = this.state;
    const {
      geozonesAdmin: { selectedGeozone },
    } = this.props;

    return Object.keys(geozoneFieldsState).some((field) => {
      const fieldValue = geozoneFieldsState[field];

      if (field === 'name' && fieldValue) {
        return validateGeozoneName(selectedGeozone?.name);
      }

      if (field === 'color') {
        return geozoneInitialData.color?.id !== selectedGeozone?.color.id;
      }

      this.checkBooleanChanges(field, 'isActive');
      this.checkBooleanChanges(field, 'reverseGeocode');
      this.checkBooleanChanges(field, 'hideOnMaps');
      this.checkBooleanChanges(field, 'arrivalZone');
      this.checkBooleanChanges(field, 'departureZone');

      return fieldValue;
    });
  };

  get fieldsToUpdate() {
    const { geozoneFieldsState } = this.state;

    return Object.keys(geozoneFieldsState).filter((field) => Boolean(geozoneFieldsState[field]));
  }

  handleClickOnCloseError = () => {
    const {
      geozonesAdmin: { selectedGeozone },
    } = this.props;

    selectedGeozone.repositoryGeozone.patchState.setError(null);
  };

  handleClickOnCancel = () => {
    const {
      geozonesAdmin: {
        selectedGeozone: {
          setGeozoneActive,
          setGeozoneArrival,
          setGeozoneColor,
          setGeozoneDeparture,
          setGeozoneGroupId,
          setGeozoneHideOnMaps,
          setGeozoneName,
          setGeozoneNotes,
          setGeozoneOverride,
          setGeozoneShape,
          setGeozoneSpeedLimit,
          setGeozoneTag,
          setCoordinates,
          setRadius,
          setUnits,
          setDispatchMarkerAddress,
          setDispatchMarkerLatitude,
          setDispatchMarkerLongitude,
        },
      },
    } = this.props;
    const {
      geozoneInitialData: {
        arrivalZone,
        color,
        departureZone,
        groupIdGts,
        hideOnMaps,
        isActive,
        name,
        notes,
        reverseGeocode,
        speedLimit,
        tag,
        type,
        coordinates,
        radius,
        units,
        dispatchMarkerAddress,
        dispatchMarkerLatitude,
        dispatchMarkerLongitude,
      },
    } = this.state;

    setGeozoneActive(isActive);
    setGeozoneArrival(arrivalZone);
    setGeozoneColor(color);
    setGeozoneDeparture(departureZone);
    setGeozoneGroupId(groupIdGts);
    setGeozoneHideOnMaps(hideOnMaps);
    setGeozoneName(name);
    setGeozoneNotes(notes);
    setGeozoneOverride(reverseGeocode);
    setGeozoneShape(type);
    setGeozoneSpeedLimit(speedLimit);
    setGeozoneTag(tag);
    setCoordinates(coordinates);
    setUnits(units);
    setRadius(radius);
    setDispatchMarkerAddress(dispatchMarkerAddress);
    setDispatchMarkerLatitude(dispatchMarkerLatitude);
    setDispatchMarkerLongitude(dispatchMarkerLongitude);

    this.setInitialUpdatedFields();
    this.handleClickOnCloseError();
  };

  handleClickOnSave = async () => {
    const {
      geozonesAdmin: {
        selectedGeozone: { updateGeozone },
      },
    } = this.props;

    try {
      await updateGeozone(this.fieldsToUpdate);
      this.setSuccessSend();
      this.setInitialGeozoneData();
      this.setInitialUpdatedFields();
    } catch (e) {
      return;
    }
  };

  handleGeozoneChange = ({ value }) => {
    const {
      history: { push },
    } = this.props;

    push(PATHS.ADMIN.TABLES.GEOZONES.GEOZONE.replace(':id', value));
  };

  openDeleteModal = () => {
    this.setState({ isDeleteModalOpen: true });
  };

  closeDeleteModal = () => {
    this.setState({ isDeleteModalOpen: false });
  };

  handleDeleteGeozone = () => {
    const {
      routerStore: {
        history: { push },
      },
    } = this.props;

    this.setState({ isDeleteGeozone: true });
    this.closeDeleteModal();
    push(PATHS.ADMIN.TABLES.GEOZONES.INDEX);
  };

  render() {
    const {
      isSent,
      geozoneInitialData: { name, isActive },
      isDeleteModalOpen,
      isDeleteGeozone,
    } = this.state;
    const {
      geozonesAdmin: { selectedGeozone, selectedGeozoneTabIndex, setSelectedGeozoneTabIndex },
    } = this.props;
    const isError = selectedGeozone?.repositoryGeozone.patchState.error;
    const isSending = selectedGeozone?.repositoryGeozone.patchState.loading;
    const disabled = !this.isGeozoneUpdated() || isSending;
    const cancelDisabled = Boolean(selectedGeozone?.name.trim().length) && (!this.isGeozoneUpdated() || isSending);

    return (
      Boolean(selectedGeozone) && (
        <div className="AdminSelectedGeozone">
          <div className="AdminSelectedGeozone-container">
            <div className="AdminSelectedGeozone-header">
              <div className="AdminSelectedGeozone-backButton">
                <BackButton link={PATHS.ADMIN.TABLES.GEOZONES.INDEX}>Back to Geozone List</BackButton>
              </div>
              <div className="AdminSelectedGeozone-selectGeozone">
                <span className="AdminSelectedGeozone-selectGeozoneCopy">View/Edit Geozone</span>
                <div className="AdminSelectedGeozone-selectGeozoneDropdown">
                  <SearchableGeoZoneSelect
                    handleChange={this.handleGeozoneChange}
                    value={{ label: name, value: this.geozoneId }}
                    key={name}
                  />
                </div>
              </div>
            </div>
            <div className="AdminSelectedGeozone-content">
              {isError && (
                <div className="AdminSelectedGeozone-errorNotification">
                  <ErrorNotification name={name} onClose={this.handleClickOnCloseError} />
                </div>
              )}
              <div className="AdminSelectedGeozone-changesButtons">
                <div className="AdminSelectedGeozone-changeButton AdminSelectedGeozone-changeButton--more">
                  <TableActions
                    items={[
                      {
                        text: 'View on Map',
                        link: PATHS.MAP.GEOZONE.replace(':geozoneId', String(selectedGeozone.id)),
                        hidden: Boolean(selectedGeozone.hideOnMaps || !isActive),
                      },
                      validateAccessLevel([ADMIN_GEOZONES_DELETE]) && {
                        text: 'Delete Geozone',
                        onClick: this.openDeleteModal,
                      },
                    ].filter((item) => item)}
                    type="page"
                  />
                </div>
                <div className="AdminSelectedGeozone-changeButton AdminSelectedGeozone-changeButton--cancel">
                  <Button
                    className="Button--cancel Button--cancelColorLynch"
                    disabled={cancelDisabled}
                    inline
                    onClick={this.handleClickOnCancel}
                    title="Cancel"
                  />
                </div>
                <div className="AdminSelectedGeozone-changeButton AdminSelectedGeozone-changeButton--save">
                  <Button
                    className="Button--apply"
                    disabled={disabled}
                    inline
                    onClick={this.handleClickOnSave}
                    title="Save"
                    sending={isSending}
                    sent={isSent}
                  />
                </div>
              </div>
              <TabsMenu
                className="AdminSelectedGeozone-tabsMenu"
                selectedTab={selectedGeozoneTabIndex}
                onSelect={setSelectedGeozoneTabIndex}
                tabs={tabs}
              >
                <AdminGeozoneDetails onChange={this.handleGeozoneDataChange} />
                <AdminGeozoneSettings onChange={this.handleGeozoneDataChange} />
              </TabsMenu>
            </div>
          </div>
          <DeleteGeozoneModal
            geozoneId={selectedGeozone && String(selectedGeozone.id)}
            geozoneName={selectedGeozone?.name}
            isOpen={isDeleteModalOpen}
            onCancel={this.closeDeleteModal}
            onSuccess={this.handleDeleteGeozone}
          />
          <Prompt
            when={!isDeleteGeozone && this.isGeozoneUpdated()}
            message="Changes that you made may not be saved."
          />
          <EditGeozone onSubmit={this.setSuccessSend} />
        </div>
      )
    );
  }
}

export default AdminSelectedGeozone;
