import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import './styles.scss';

interface IProps {
  date?: string | Date;
  format?: string;
}

const DateField: React.FC<IProps> = ({ date, format }) => {
  const cn = classNames('DateField');
  const dateText = date ? moment(date).format(format) : 'No date';

  return (
    <div className={cn}>
      <p className="DateField-text">{dateText}</p>
    </div>
  );
};

DateField.defaultProps = {
  date: new Date()
    .toJSON()
    .slice(0, 10)
    .replace(/-/g, '/'), // current date
  format: 'M/D/YYYY',
};

export default DateField;
