import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Modal from 'components/Modal';
import SessionExpiration from 'components/SessionExpiration';

import { PATHS } from 'config';
import type { RouterStore, UserStore } from 'stores';

import './styles.scss';

interface IProps {
  className?: string;
  userStore?: UserStore;
  routerStore?: RouterStore;
}

@inject(({ userStore, routerStore }) => ({
  userStore,
  routerStore,
}))
@observer
class SessionExpired extends Component<IProps> {
  handleClick = () => {
    const {
      routerStore: { push },
    } = this.props;

    push(PATHS.AUTH.LOGIN);
  };

  render() {
    return (
      <Modal
        className="SessionExpired Modal--sessionExpired Modal--withoutCloseIcon"
        isModalOpen
        onClose={this.handleClick}
      >
        <SessionExpiration onClick={this.handleClick} />
      </Modal>
    );
  }
}

export default SessionExpired;
