import React, { FC } from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  active?: boolean;
  className?: string;
  disabled?: boolean;
  icon: React.ReactElement;
  iconText?: string;
  onClick?: () => void;
  selected?: boolean;
  type?: string;
  small?: boolean;
}

const HeaderIcon: FC<IProps> = ({ active, className, disabled, iconText, icon, onClick, selected, type, small }) => {
  const cn = classNames('HeaderIcon', className, {
    'HeaderIcon--active': active,
    'HeaderIcon--selected': selected,
    'HeaderIcon--disabled': disabled,
    [`HeaderIcon--${type}`]: type,
    'HeaderIcon--small': small,
  });

  return (
    <button className={cn} onClick={onClick}>
      <div className="HeaderIcon-icon">{icon}</div>
      <p className="HeaderIcon-text">{iconText}</p>
    </button>
  );
};

export default HeaderIcon;
