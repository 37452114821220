import { action, observable, computed } from 'mobx';
import defer from 'lodash/defer';
import Asset from 'models/Assets/Asset';
import EventsBus from 'services/EventsBus/eventsBus';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import type Repository from 'services/RepositoryService/Repository';
import { APP_EVENTS } from 'services/EventsBus/appEvents';
import { ListField, SimpleField, ToggleField } from 'models/Fields';
import { LoggerInfo } from 'models/General';
import { repositoryService } from 'services';
import { DATE_TIME_FORMATS } from 'config';

export class Group {
  @observable description: string;
  @observable displayName: string;
  @observable deviceCount: number;
  @observable groupId: string;
  loggerInfo: LoggerInfo;
  @observable assetGroupId: number;
  assets: ListField<Asset>;
  searchAssetQuery: SimpleField<any>;
  isFetchedAssetTrackDetails: ToggleField;
  isSelectAllProcessing: ToggleField;
  repositoryGroups: Repository;
  repositoryAssets: Repository;
  repositoryAssetsIds: IEntityRepository;

  constructor({
    description = '',
    assetCount = null,
    groupId = null,
    id = null,
    createdBy = '',
    updatedBy = '',
    creationTime = 0,
    lastUpdateTime = 0,
    assetGroupId = null,
  }: Group.IServerGroup) {
    this.groupId = groupId || id;
    this.description = description || '';
    this.displayName = this.description;
    this.deviceCount = assetCount;
    this.loggerInfo = new LoggerInfo(
      { createdBy, updatedBy, creationTime, lastUpdateTime },
      DATE_TIME_FORMATS.adminVehicleSettings
    );
    this.assetGroupId = assetGroupId;
    this.assets = new ListField([]);
    this.searchAssetQuery = new SimpleField('');
    this.isFetchedAssetTrackDetails = new ToggleField(false);
    this.isSelectAllProcessing = new ToggleField(false);
    this.repositoryGroups = repositoryService.get('groups');
    this.repositoryAssets = repositoryService.get('assets');
    this.repositoryAssetsIds = this.repositoryAssets.entity('ids');
  }

  static getIdFromName(name: string) {
    return name
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9\-]+/g, '');
  }

  fetchAssets = async () => {
    this.assets.reset();
    const response = await this.repositoryAssetsIds.get({
      assetGroupId: this.groupId === 'all' ? undefined : this.assetGroupId,
      includeInactive: true,
      sortBy: 'name',
    });

    if (response) {
      const assets = response.items
        .filter(({ displayName }) => Boolean(displayName))
        .map(({ displayName, id }) => new Asset({ name: displayName, id }));
      this.assets.replace(assets);
      this.isFetchedAssetTrackDetails.toggle(false);
      EventsBus.get().trigger(APP_EVENTS.GROUPS.GROUP.ASSETS.CHANGED, this.assets);
    }
  };

  fetchAssetsTrackDetails = async () => {
    if (!this.isFetchedAssetTrackDetails.value) {
      EventsBus.get().trigger(APP_EVENTS.MAINTENANCE.APPLY_TASKS.TASK.LOADING);

      const response = await this.repositoryAssets.get({
        assetGroupId: this.groupId === 'all' ? undefined : this.assetGroupId,
        includeAssetGroupFields: false,
        includeDashcamFields: false,
        includeDriverFields: false,
        includeInactive: true,
      });

      response.items.forEach((item) => {
        const asset = this.assets.value.find((asset) => Number(asset.value.id.value) === item.id);

        if (asset) {
          asset.value.trackerDetails.update(item);
        }
      });

      this.isFetchedAssetTrackDetails.toggle(true);
    }
  };

  selectAllAssets = async () => {
    const assets = [];

    this.isSelectAllProcessing.toggle(true);
    EventsBus.get().trigger(APP_EVENTS.MAINTENANCE.APPLY_TASKS.TASK.LOADING);

    defer(async () => {
      await this.fetchAssetsTrackDetails();

      for (const asset of this.assets.toArray()) {
        await asset.trackerDetails.fetch();

        assets.push({
          ...asset.data,
          ...asset.trackerDetails.data,
        });
      }

      this.assets.replace(assets.map((asset) => new Asset({ ...asset, selected: true })));

      EventsBus.get().trigger(APP_EVENTS.MAINTENANCE.APPLY_TASKS.ALL.ADDED, assets);
      EventsBus.get().trigger(APP_EVENTS.MAINTENANCE.APPLY_TASKS.SORT);
      this.isSelectAllProcessing.toggle(false);
    });
  };

  deselectAllAssets = () => {
    this.isSelectAllProcessing.toggle(true);

    for (const asset of this.assets.toArray()) {
      asset.toggle(false);
    }

    this.isSelectAllProcessing.toggle(false);
  };

  get allAssetsSelected() {
    return this.assets.toArray().every((asset) => asset.selected.value);
  }

  get selectedAssetsCount() {
    return this.assets.toArray().filter((asset) => asset.selected.value).length;
  }

  @computed get filteredAssets() {
    return this.assets.toArray().filter((asset) => asset.name.value.includes(this.searchAssetQuery.value));
  }

  @action updateDisplayName = (displayName: string) => (this.displayName = displayName);

  @action updateDescription = (description: string) => (this.description = description);

  @action save = async () => {
    await this.repositoryGroups.put({
      groupId: this.groupId,
      description: this.description,
      displayName: this.displayName || this.description,
    });
  };
}

export default Group;
