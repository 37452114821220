import React from 'react';

import { ALERTS_TYPE } from 'config';

import AlertTileAcceleration from '../AlertTileAcceleration';
import AlertTileAfterHours from '../AlertTileAfterHours';
import AlertTileBraking from '../AlertTileBraking';
import AlertTileCornering from '../AlertTileCornering';
import AlertTileAfterExcessiveIdling from '../AlertTileExcessiveIdling';
import AlertTileAfterFaultCodes from '../AlertTileFaultCodes';
import AlertTileAfterGeozone from '../AlertTileGeozone';
import AlertTileIgnition from '../AlertTileIgnition';
import AlertTileLowVehicleBattery from '../AlertTileLowVehicleBattery';
import AlertTileMaintenanceDue from '../AlertTileMaintenanceDue';
import AlertTilePowerDisconnectTracker from '../AlertTilePowerDisconnectTracker';
import AlertTilePowerDisconnectDashCam from '../AlertTilePowerDisconnectDashCam';
import AlertTileSpeedsOver from '../AlertTileSpeedsOver';
import AlertTileCustom from '../AlertTileCustom';
import AlertTileFuelingException from '../AlertTileFuelingException';
import AlertTileMaintenance from '../AlertTileMaintenance';

interface IProps {
  type: ALERTS_TYPE;
  isActive?: boolean;
  requiredPro?: boolean;
  onClick?: () => void;
  isProAcc?: boolean;
}

const AlertTileFabric: React.FC<IProps> = ({ type, ...props }) => {
  switch (type) {
    case ALERTS_TYPE.ACCELERATION:
      return <AlertTileAcceleration {...props} />;
    case ALERTS_TYPE.AFTER_HOURS:
      return <AlertTileAfterHours {...props} />;
    case ALERTS_TYPE.BRAKING:
      return <AlertTileBraking {...props} />;
    case ALERTS_TYPE.CORNERING:
      return <AlertTileCornering {...props} />;
    case ALERTS_TYPE.EXCESSIVE_IDLING:
      return <AlertTileAfterExcessiveIdling {...props} />;
    case ALERTS_TYPE.FAULT_CODES:
      return <AlertTileAfterFaultCodes {...props} />;
    case ALERTS_TYPE.GEOZONE:
      return <AlertTileAfterGeozone {...props} />;
    case ALERTS_TYPE.IGNITION:
      return <AlertTileIgnition {...props} />;
    case ALERTS_TYPE.LOW_VEHICLE_BATTERY:
      return <AlertTileLowVehicleBattery {...props} />;
    case ALERTS_TYPE.MAINTENANCE:
      return <AlertTileMaintenance {...props} />;
    case ALERTS_TYPE.MAINTENANCE_DUE:
      return <AlertTileMaintenanceDue {...props} />;
    case ALERTS_TYPE.POWER_DISCONNECT_TRACKER:
      return <AlertTilePowerDisconnectTracker {...props} />;
    case ALERTS_TYPE.POWER_DISCONNECT_DASHCAM:
      return <AlertTilePowerDisconnectDashCam {...props} />;
    case ALERTS_TYPE.SPEEDS_OVER:
      return <AlertTileSpeedsOver {...props} />;
    case ALERTS_TYPE.FUELING_EXCEPTION:
      return <AlertTileFuelingException {...props} />;
    case ALERTS_TYPE.CUSTOM:
      return <AlertTileCustom {...props} />;
    default:
      return null;
  }
};

export default AlertTileFabric;
