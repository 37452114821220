import React, { useCallback } from 'react';
import { Input } from 'antd';
import type { FieldProps } from 'formik';
import classNames from 'classnames';

import './styles.scss';

const { TextArea: TextAreaAnt } = Input;

interface IProps extends Partial<FieldProps> {
  className?: string;
  counter?: boolean | string;
  error?: string;
  label?: string;
  maxLength?: number;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  placeholder?: string;
  value?: string;
}

const TextArea: React.FC<IProps> = ({
  className,
  counter,
  error,
  field,
  label,
  maxLength,
  onBlur,
  onChange,
  placeholder,
  value,
}) => {
  const cn = classNames('TextArea', className, {
    'TextArea--error': Boolean(error),
    'TextArea--withCounter': Boolean(counter),
  });
  const handleChange = useCallback(
    (event: React.FormEvent<HTMLTextAreaElement>) => {
      onChange(event.currentTarget.value);
    },
    [onChange]
  );
  const handlers = field ? field : { value, onChange: handleChange, onBlur };

  return (
    <div className={cn}>
      {Boolean(label) && <p className="TextArea-label">{label}</p>}
      <div className="TextArea-inputField">
        <TextAreaAnt className="TextArea-input" maxLength={maxLength} placeholder={placeholder} {...handlers} />
        {Boolean(counter) && (
          <span className="TextArea-counter">
            {handlers.value.length}/{maxLength}
          </span>
        )}
      </div>
      {Boolean(error) && <p className="TextArea-error">{error}</p>}
    </div>
  );
};

export default TextArea;
