import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import { formatDecimalNumber, getFormattedTime } from 'utils';

import { WEB_FINANCE_DETAILS_READ } from 'config';

import type { CustomersStore } from 'stores';
import validateAccessLevel from 'stores/acl/validator';

import SecondaryPanelAttributesRow from '../../../SecondaryPanelAttributes/SecondaryPanelAttributesRow';
import SecondaryPanelAttributesCol from '../../../SecondaryPanelAttributes/SecondaryPanelAttributesCol';
import FinancialDataAccessError from 'components/FinancialDataAccessError';

import './styles.scss'

interface IProps {
  customersStore?: CustomersStore;
}


@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class SecondaryPanelInvoiceDetails extends Component<IProps> {

  get hasAccess () {
    return validateAccessLevel([WEB_FINANCE_DETAILS_READ]);
  }

  render() {
    const customer = this.props.customersStore.selectedCustomer;
    const invoice = customer.selectedInvoice;

    const hasAccess = this.hasAccess;

    return <div className="SecondaryPanelInvoiceDetails">

      <div className="SecondaryPanelInvoiceDetailsPrice">
        <div className="SecondaryPanelInvoiceDetailsPrice-item">
          <div className="SecondaryPanelInvoiceDetailsPrice-header">Total</div>
          {hasAccess && (
            <div
              className="SecondaryPanelInvoiceDetailsPrice-value"
            >${formatDecimalNumber(invoice.totalAmount, 2)}</div>
          )}
          {!hasAccess && <FinancialDataAccessError className="SecondaryPanelInvoiceDetailsPrice-value--limited" />}
        </div>
        <div className="SecondaryPanelInvoiceDetailsPrice-item">
          <div className="SecondaryPanelInvoiceDetailsPrice-header">Balance</div>
          {hasAccess && (
            <div
              className="SecondaryPanelInvoiceDetailsPrice-value"
            >${formatDecimalNumber(invoice.totalAmount - invoice.balance, 2)}</div>
          )}
          {!hasAccess && <FinancialDataAccessError className="SecondaryPanelInvoiceDetailsPrice-value--limited" />}
        </div>
        <div className="SecondaryPanelInvoiceDetailsPrice-item">
          <div className="SecondaryPanelInvoiceDetailsPrice-header">Overdue</div>
          {hasAccess && (
            <div
              className={classNames('SecondaryPanelInvoiceDetailsPrice-value', {
                'SecondaryPanelInvoiceDetailsPrice-value--red': (invoice.balance > 0),
              })}
            >${formatDecimalNumber(invoice.balance, 2)}</div>
          )}
          {!hasAccess && <FinancialDataAccessError className="SecondaryPanelInvoiceDetailsPrice-value--limited" />}
        </div>
      </div>

      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Date</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{getFormattedTime(invoice.dueDate, 'MM/DD/YYYY')}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>
      <SecondaryPanelAttributesRow>
        <SecondaryPanelAttributesCol role={'title'}>Status</SecondaryPanelAttributesCol>
        <SecondaryPanelAttributesCol role={'value'}>{invoice.status || ''}</SecondaryPanelAttributesCol>
      </SecondaryPanelAttributesRow>
    </div>;
  }
}

export default SecondaryPanelInvoiceDetails;
