import React from 'react';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import type MaintenanceConfig from 'models/Maintenances/Models/MaintenanceConfig';
import type MaintenanceAsset from 'models/Maintenances/Models/MaintenanceAsset';
import type MaintenanceModalDueEveryUI from 'models/Maintenances/UI/MaintenanceModalUI/MaintenanceModalDueEveryUI';

import MaintenancePreferenceDueEveryLastPerformed from './MaintenancePreferenceDueEveryLastPerformed';
import MaintenancePreferenceDueEveryMetrics from './MaintenancePreferenceDueEveryMetrics';
import MaintenancePreferenceDueEveryDate from './MaintenancePreferenceDueEveryDate';

import './styles.scss';

interface IProps {
  odometer: MaintenanceConfig;
  engHours: MaintenanceConfig;
  serviceTime: MaintenanceConfig;
  asset: MaintenanceAsset;
  ui: MaintenanceModalDueEveryUI;
  isLastPerformed: boolean;
  onEditLastPerformed: () => void;
  showOverlay?: boolean;
  showLastPerformed?: boolean;
  showNext?: boolean;
}

const MaintenancePreferenceDueEvery: React.FC<IProps> = ({
  odometer,
  engHours,
  serviceTime,
  asset,
  ui,
  isLastPerformed,
  onEditLastPerformed,
  showOverlay = false,
  showLastPerformed = true,
  showNext = true,
}) => {
  return (
    <Observer
      render={() => {
        const overlayCn = classNames('MaintenancePreferenceDueEvery-contentOverlay', {
          'MaintenancePreferenceDueEvery-contentOverlay--visible':
            showOverlay || asset.details.repositoryId?.getState.loading,
        });
        const contentLineCn = classNames('MaintenancePreferenceDueEvery-contentLine', {
          'MaintenancePreferenceDueEvery-contentLine--noNext': !showNext,
        });

        return (
          <div className="MaintenancePreference-line MaintenancePreferenceDueEvery">
            <div className="MaintenancePreference-label MaintenancePreference-label--top MaintenancePreferenceDueEvery-label">
              Due every
            </div>
            <div className="MaintenancePreference-value MaintenancePreferenceDueEvery-content">
              {showLastPerformed && (
                <MaintenancePreferenceDueEveryLastPerformed
                  ui={ui}
                  odometer={odometer}
                  engHours={engHours}
                  asset={asset}
                  serviceTime={serviceTime}
                  isLastPerformed={isLastPerformed}
                  onEditLastPerformed={onEditLastPerformed}
                />
              )}
              <div className="MaintenancePreferenceDueEvery-contentBody">
                <div className={contentLineCn}>
                  <MaintenancePreferenceDueEveryMetrics
                    ui={ui}
                    odometer={odometer}
                    engHours={engHours}
                    asset={asset}
                    showNext={showNext}
                  />
                </div>
                <div className="MaintenancePreferenceDueEvery-contentLine">
                  <MaintenancePreferenceDueEveryDate ui={ui} serviceTime={serviceTime} showNext={showNext} />
                </div>
                <div className="MaintenancePreferenceDueEvery-contentError">
                  {ui.isError.value && (
                    <span className="MaintenancePreferenceDueEvery-contentErrorText">
                      At least one option must be selected
                    </span>
                  )}
                </div>
              </div>
              <div className={overlayCn} />
            </div>
          </div>
        );
      }}
    />
  );
};

export default MaintenancePreferenceDueEvery;
