import React from 'react';

import './styles.scss';

interface IProps {
  item: {
    renderIcon: (className?: string, isActive?: boolean) => JSX.Element;
    title: string;
    imposterLink?: string;
  };
}

const NavigationImposterItem: React.FC<IProps> = ({ item: { renderIcon, title, imposterLink } }) => {
  return (
    <div className="NavigationItem">
      <a href={imposterLink} target="_blank" className="NavigationItem-link">
        <div className="NavigationItem-content">
          {renderIcon('NavigationItem-icon')}
          <p className="NavigationItem-title">{title}</p>
        </div>
      </a>
    </div>
  );
};

export default NavigationImposterItem;
