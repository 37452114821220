import React from 'react';
import { observer } from 'mobx-react';
import { InputNumber } from 'antd';

import type { SimpleField } from 'models/Fields';

import InfoTooltip from 'components/InfoTooltip';

import './styles.scss';

interface IProps {
  idleMinutes: SimpleField<number>;
}

const AlertPreferencesIdle: React.FC<IProps> = ({ idleMinutes }) => {
  const handleChangeType = (value) => {
    const roundValue = Math.round(value / 6) * 6;
    const minutes = roundValue < 6 ? 6 : roundValue;

    idleMinutes.set(minutes);
  };

  return (
    <div className="AlertPreference-line">
      <div className="AlertPreference-label">Idles for</div>
      <div className="AlertPreference-value AlertPreferencesIdle-value">
        <div className="AlertPreferencesIdle-input">
          <InputNumber value={idleMinutes.value} min={6} max={996} step={6} onChange={handleChangeType} />
        </div>
        <div className="AlertPreferencesIdle-title">minutes</div>
        <div className="AlertPreferencesIdle-tooltip">
          <InfoTooltip>
            By default, idle events are sent every 6 minutes, alert must be on a six minute increment.
          </InfoTooltip>
        </div>
      </div>
    </div>
  );
};

export default observer(AlertPreferencesIdle);
