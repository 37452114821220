/*global google*/
import React, { FC, useMemo, useRef, useState, useEffect } from 'react';

import { MAP_PARAMS } from 'config';

import type { IBoundsOffset, ISearchResults } from 'stores';

import CustomMap from '../index';

interface IProps {
  bounds?: Locations.ILocation[];
  boundsOffset?: IBoundsOffset;
  center?: google.maps.LatLng | Locations.ILocation;
  centerOffsetX?: number;
  centerOffsetY?: number;
  zoom?: number;
  setStreetViewOpened?: (status: boolean) => void;
  setMapHeading?: (value: number) => void;
  locked?: boolean;
  onClick?: (e: google.maps.MouseEvent | google.maps.IconMouseEvent) => void;
  onPlacesChange?: (location: ISearchResults) => void;
}

const SimpleMap: FC<IProps> = ({ children, bounds, ...props }) => {
  const [isGoogleAvailbale, setGoogle] = useState(undefined);
  const ref = useRef(null);

  const mapBounds = useMemo(() => {
    if (isGoogleAvailbale) {
      const LatLngBounds = new google.maps.LatLngBounds();
      (bounds.length ? bounds : MAP_PARAMS.US_BOUNDS).forEach(({ lat, lng }) => {
        LatLngBounds.extend({ lat, lng });
      });
      return LatLngBounds;
    }
    return null;
  }, [bounds.length, isGoogleAvailbale]);

  useEffect(() => {
    const id = setInterval(() => {
      if (window.google) {
        setGoogle(true);
        clearInterval(id);
      }
    }, 100);

    return () => clearInterval(id);
  }, []);

  const id = setInterval(() => {
    if (window.google) {
      setGoogle(true);
      clearInterval(id);
    }
  }, 100);

  return (
    <CustomMap
      containerElement={<div style={{ height: `100%`, width: '100%' }} />}
      googleMapURL={process.env.REACT_APP_GOOGLE_MAP_URL}
      loadingElement={<div style={{ height: `100%` }} />}
      map={ref}
      mapElement={<div style={{ height: `100%` }} />}
      {...props}
      bounds={mapBounds}
      clickableIcons={false}
    >
      {children}
    </CustomMap>
  );
};

export default SimpleMap;
