import { action, computed, observable } from 'mobx';
import { getStorageItem, setStorageItem } from 'utils';
import { merge, uniqBy } from 'lodash';

import type { IEditColumns } from 'models/Tables/IEditColumns';

export interface IEditColumn {
  value: string;
  isSelected: boolean;
}

export class ReportTableColumns {
  private readonly key: string = '';
  @observable columns: { [key: string]: string } = {};
  @observable editColumnsList: IEditColumn[] = [];
  defaultHeaders: string[] = [];

  constructor({ key, columns, defaultHeaders }) {
    this.key = key;
    this.columns = columns;
    this.defaultHeaders = defaultHeaders;
    this.editColumnsList = this.setInitialEditColumnsList(columns);
  }

  @computed get editColumnsMap() {
    return Object.keys(this.columns).reduce((columnsMap, columnName) => {
      columnsMap[columnName] = {
        text: this.columns[columnName],
        isDisabled: false,
      };

      return columnsMap;
    }, {});
  }

  @computed get visibleColumnsList() {
    return this.editColumnsList.filter((column) => column.isSelected);
  }

  @action setVisibleEditColumns = (columns: Array<IEditColumns<string>>) => {
    this.editColumnsList = columns;
    this.save(columns);
  };

  setInitialEditColumnsList = (columns: { [key: string]: string }) => {
    const storedColumns = this.read() || [];
    const availableColumns = Object.keys(columns).reduce((columnsList, columnName) => {
      columnsList.push({
        value: columnName,
        isSelected: this.defaultHeaders.length ? this.defaultHeaders.includes(columnName) : true,
      });

      return columnsList;
    }, []);
    const columnsList = storedColumns.filter((item) => {
      return availableColumns.find((col) => col.value === item.value);
    });

    return uniqBy(merge(availableColumns, columnsList), 'value');
  };

  read() {
    return JSON.parse(getStorageItem(this.key) || null);
  }

  save(data) {
    setStorageItem(this.key, JSON.stringify(data));
  }
}

export default ReportTableColumns;
