import { CAMERA_JOURNAL_STATE, MEDIA_CLIPS_VIEW } from 'config';
import DashcamStatusModel, { DASHCAM_STATUS } from 'models/Dashcam/DashcamStatus';
import PlayerViewModel from 'models/Dashcam/MediaRequestCheckout/Player/Player';
import RequestMediaCamerasType from 'models/Dashcam/MediaRequestCheckout/RequestMedia/RequestMediaCamerasType';
import RequestMediaTimeSegment from 'models/Dashcam/MediaRequestCheckout/RequestMedia/RequestMediaTimeSegment';
import CameraModel from 'models/Dashcam/MediaRequestCheckout/VehicleDetails/CameraModel';
import { ToggleField } from 'models/Fields';
import type Device from './Device';
import MediaLibrary from 'models/Dashcam/MediaLibrary';

class DeviceMedia {
  cameraModel: CameraModel;
  dashcamStatus: DashcamStatusModel;
  timeSegment: RequestMediaTimeSegment;
  cameraType: RequestMediaCamerasType;
  player: PlayerViewModel;
  isInitialize: ToggleField;
  context: Device;
  library: MediaLibrary;

  constructor(context) {
    this.context = context;
    this.isInitialize = new ToggleField(false);
    this.dashcamStatus = new DashcamStatusModel();
    this.cameraType = new RequestMediaCamerasType();
    this.library = new MediaLibrary(MEDIA_CLIPS_VIEW.VEHICLE_MEDIA_PANEL);
  }

  initialize = () => {
    this.cameraModel = new CameraModel(this.context.details.camera);
    this.timeSegment = new RequestMediaTimeSegment(this.cameraModel.provider);
    this.player = new PlayerViewModel(this.cameraType, this.timeSegment, this.cameraModel, { liveMode: true });
    this.isInitialize.toggle(true);
  };

  reset = () => {
    this.player.state = null;
    this.player.video.url.set(null);
    this.player.onStart();
    this.player.isLoading.toggle(true);
  };

  fetch = async (play: boolean = true) => {
    if (!this.cameraModel || !this.cameraModel.id) {
      return;
    }

    await this.dashcamStatus.fetch(this.cameraModel.id);

    this.setCameraOption();

    if (!this.isCameraUnavailable && play) {
      this.player.play();
    }
  };

  get isCameraUnavailable() {
    return this.dashcamStatus.status.value === DASHCAM_STATUS.OFFLINE || this.dashcamStatus.cameraDetails.isBusy.value;
  }

  destroy = () => {
    this.library.destroy();
  };

  setCameraOption = () => {
    const cameraTypes = this.dashcamStatus.cameraDetails.availableCameraTypes.toArray();
    let state = null;

    if (cameraTypes.includes('outside') && cameraTypes.includes('inside')) {
      state = CAMERA_JOURNAL_STATE.IN_OUT;
    } else if (cameraTypes.includes('outside')) {
      state = CAMERA_JOURNAL_STATE.OUT;
    } else if (cameraTypes.includes('inside')) {
      state = CAMERA_JOURNAL_STATE.IN;
    }

    this.cameraType.setOptions(state);
    this.cameraType.setInitialType();
  };
}

export default DeviceMedia;
