import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Tooltip } from 'antd';

import { MAP_TRACKPOINTS_AND_EVENTS_CHECKBOXES } from 'config';
import { UserAccessLevelComponent } from 'stores';

import 'react-tabs/style/react-tabs.scss';
import './styles.scss';

interface ITab {
  name: string;
  icon?: React.ReactNode;
}

interface IProps {
  tabs: ITab[];
  children: React.ReactChild[];
  className?: string;
  additionalContent?: React.ReactNode;
  selectedTab?: number;
  onSelect?: (tab: number) => void;
  disabledTab?: { disabled: boolean; tooltipText: string; index: number };
  rounded?: boolean;
}

const TabsMenu: React.FC<IProps> = ({
  tabs,
  children,
  className,
  disabledTab = {},
  onSelect,
  additionalContent,
  selectedTab = 0,
  rounded,
}) => {
  const cn = classNames('TabsMenu', className, { 'TabsMenu--rounded': rounded });
  const [selectedIndex, setTab] = useState(selectedTab);
  const handleTabSelect = (tabIndex) => {
    if (tabIndex === disabledTab.index && disabledTab.disabled) {
      return;
    }

    setTab(tabIndex);
    if (onSelect) {
      onSelect(tabIndex);
    }
  };

  useEffect(() => {
    setTab(selectedTab);
  }, [selectedTab]);

  return (
    <Tabs
      className={cn}
      selectedTabClassName="TabsMenu-tab--selected"
      selectedIndex={selectedIndex}
      onSelect={handleTabSelect}
    >
      <div className="TabsMenu-content">
        <UserAccessLevelComponent requiredAccessLevel={[MAP_TRACKPOINTS_AND_EVENTS_CHECKBOXES]}>
          <TabList className="TabsMenu-list">
            {tabs.map(({ name, icon }, index) => {
              const isTabDisabled = Boolean(disabledTab.disabled && disabledTab.index === index);
              const tabCn = classNames('TabsMenu-tab', `TabsMenu-tab--${name.toLowerCase()}`, {
                'TabsMenu-tab--disabled': isTabDisabled,
              });

              return (
                <Tab key={name} className={tabCn}>
                  {Boolean(icon) && <div className="TabsMenu-listIcon">{icon}</div>}
                  <span>{name}</span>
                  {isTabDisabled && (
                    <Tooltip
                      arrowPointAtCenter
                      color="white"
                      overlayInnerStyle={{ color: '#2B2E41' }}
                      placement="bottomRight"
                      title={disabledTab.tooltipText}
                    >
                      <div className="TabsMenu-tabTooltip TabsMenu-tabTooltip--disabled" />
                    </Tooltip>
                  )}
                </Tab>
              );
            })}
          </TabList>
        </UserAccessLevelComponent>

        {Boolean(additionalContent) && <div className="TabsMenu-additionalContent">{additionalContent}</div>}

        {children.map((child, key) => (
          <TabPanel key={key} className="TabsMenu-content">
            {child}
          </TabPanel>
        ))}
      </div>
    </Tabs>
  );
};

export default TabsMenu;
