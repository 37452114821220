import React, { FC } from 'react';
import './styles.scss';
import Button from '../Button';

interface IProps {
  onClick: () => void;
}

const SessionExpiration: FC<IProps> = ({ onClick }) => {
  return (
    <div className="SessionExpiration">
      <div className="SessionExpiration-container">
        <p className="SessionExpiration-title">Session Expired</p>
        <p className="SessionExpiration-text">Your session has expired. Please sign in to continue.</p>
        <div className="SessionExpiration-buttonContainer">
          <Button className="SessionExpiration-button" title="Return to Sign In" onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

export default SessionExpiration;
