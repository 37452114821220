import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import pluralize from 'pluralize';

import type { GroupsAdmin } from 'stores/Admin/Groups';

import SearchFilter from 'components/Filters/SearchFilter';
import GroupTableVehiclesRemove from 'components/Admin/GroupTableVehicles/GroupTableVehiclesButton/GroupTableVehiclesRemove';

import './styles.scss';

interface IProps {
  groupsAdmin?: GroupsAdmin;
  onRemove?: () => void;
}

interface IState {
  isFiltersOpen: boolean;
}

@inject(({ adminStore: { groupsAdmin } }) => ({
  groupsAdmin,
}))
@observer
class GroupTableVehiclesHeader extends Component<IProps, IState> {
  get formattedCount() {
    const {
      groupsAdmin: { filteredSelectedGroupVehiclesList },
    } = this.props;

    const numberOfDevices = filteredSelectedGroupVehiclesList.length;
    return pluralize(`Vehicles`, numberOfDevices, true);
  }

  handleRemove = () => {
    const {
      groupsAdmin: { unAssignAllVehiclesFromSelectedGroup },
      onRemove,
    } = this.props;

    unAssignAllVehiclesFromSelectedGroup();
    onRemove();
  };

  render() {
    const {
      groupsAdmin: { searchQueryGroupVehicles, updateSearchQueryGroupVehicles },
    } = this.props;

    return (
      <div className="GroupTableVehiclesHeader">
        <div className="GroupTableVehiclesHeader-title">
          <div className="GroupTableVehiclesHeader-left">
            <div className="GroupTableVehiclesHeader-search">
              <SearchFilter
                className="SearchFilter--groupTableVehicles"
                inline
                onChange={updateSearchQueryGroupVehicles}
                placeholder="Search Vehicle"
                value={searchQueryGroupVehicles}
              />
              <span className="GroupTableVehiclesHeader-count">{this.formattedCount}</span>
            </div>
          </div>
          <div className="GroupTableVehiclesHeader--right GroupTableVehiclesHeader--action">
            <GroupTableVehiclesRemove
              text={`Remove all`}
              onClick={this.handleRemove}
              disabled={Boolean(searchQueryGroupVehicles.length)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default GroupTableVehiclesHeader;
