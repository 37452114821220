import React  from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  role: 'title' | 'value'
}

const SecondaryPanelAttributesCol: React.FC<IProps> = ({ children, role}) => {
  return <div className={classNames('SecondaryPanelAttributesCol', 'SecondaryPanelAttributesCol-' + role)}>{children}</div>;
};

export default SecondaryPanelAttributesCol;
