import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import get from 'lodash/get';

import type { DevicesStore, MapStore } from 'stores';
import { PATHS } from 'config';
import { isToday } from 'utils';

import CustomTimeFilterWithRange from 'components/SubNav/CustomTimeFilterWithRange';

interface IMatchParams {
  vehicleId: string;
  groupId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  devicesStore?: DevicesStore;
  mapStore?: MapStore;
}

@inject(({ devicesMapStore: { devicesStore, mapStore } }) => ({
  devicesStore,
  mapStore,
}))
@observer
class SelectedVehicleCustomTimeFilter extends Component<IProps> {
  private get paths() {
    const {
      match: {
        params: { groupId, vehicleId },
      },
    } = this.props;
    const pathToApply = groupId
      ? PATHS.MAP.GROUP_VEHICLE.replace(':groupId', groupId).replace(':vehicleId', vehicleId)
      : PATHS.MAP.VEHICLE.replace(':vehicleId', vehicleId);
    const pathToBack = groupId
      ? PATHS.MAP.GROUP_VEHICLE_PREDEFINED_TIME_FILTER.replace(':groupId', groupId).replace(':vehicleId', vehicleId)
      : PATHS.MAP.VEHICLE_PREDEFINED_TIME_FILTER.replace(':vehicleId', vehicleId);

    return {
      pathToApply,
      pathToBack,
    };
  }

  handleApplyDate = async ({ from, to }) => {
    const {
      devicesStore: {
        filters: { applyDate },
      },
      history: { push },
    } = this.props;

    await applyDate({ from, to });

    push(this.paths.pathToApply);
  };

  render() {
    const {
      devicesStore: {
        filters: { date },
        selectedDevice,
      },
      mapStore: { timezone },
    } = this.props;
    const latestEventTimestamp = get(selectedDevice, 'latestEvent.timestamp', 0);

    return (
      <Fragment>
        <CustomTimeFilterWithRange
          className="CustomTimeFilterWithRange--withExtraHeight"
          date={date}
          isToday={isToday(latestEventTimestamp)}
          onApplyDate={this.handleApplyDate}
          pathToBack={this.paths.pathToBack}
          timezone={timezone}
        />
      </Fragment>
    );
  }
}

export default withRouter(SelectedVehicleCustomTimeFilter);
