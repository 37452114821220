import React, { ReactElement } from 'react';
import classNames from 'classnames';

import './styles.scss';

interface IProps {
  className?: string;
  hoverText?: string;
  icon?: string;
  disabled?: boolean;
  iconComponent?: ReactElement;
  onClick: () => void;
  title?: string;
}

const MapButton: React.FC<IProps> = ({
  title,
  icon,
  onClick,
  disabled = false,
  iconComponent,
  className,
  hoverText,
}) => {
  const cn = classNames('MapButton', className, {
    'MapButton--disabled': disabled,
  });

  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <div className={cn} onClick={handleClick}>
      <div className="MapButton-button">
        {Boolean(iconComponent) && iconComponent}
        {Boolean(icon) && <img className="MapButton-icon" src={icon} alt="layers" />}
        {Boolean(title) && <p className="MapButton-title">{title}</p>}
      </div>
      {Boolean(hoverText) && <div className="MapButton-hoverText">{hoverText}</div>}
    </div>
  );
};

export default MapButton;
