import React from 'react';

import type { ToggleField } from 'models/Fields';

import AlertPreferenceFinalizeSimpleLine from '../AlertPreferenceFinalizeSimpleLine';

interface IProps {
  label: string;
  ignitionOn: ToggleField;
}

const AlertPreferenceFinalizeIgnitionLine: React.FC<IProps> = ({ label, ignitionOn }) => {
  const value = `Ignition ${ignitionOn.value ? 'On' : 'Off'} `;

  return <AlertPreferenceFinalizeSimpleLine label={label} value={value} />;
};

export default AlertPreferenceFinalizeIgnitionLine;
