import { action, observable, computed } from 'mobx';
import type IRepository from 'interfaces/services/RepositoryService/IRepository';
import type IEntityRepository from 'interfaces/services/RepositoryService/IEntityRepository';
import { repositoryService } from 'services';
import { find } from 'lodash';
import { SimpleField } from 'models/Fields';

import Group from './Group';
import EventsBus from 'services/EventsBus/eventsBus';
import { APP_EVENTS } from 'services/EventsBus/appEvents';

const allVehiclesOption = { value: 'all', label: 'All Vehicles' };
const allVehiclesGroup = new Group({ assetGroupId: null, description: 'All Vehicles', groupId: 'all' });

class Groups {
  private static instance: Groups;
  repository: IRepository;
  repositoryGroup: IEntityRepository;
  @observable items: Group[];
  @observable selected: Group;
  @observable info: Group;
  @observable groupId: SimpleField<string>;

  private constructor() {
    this.items = [];
    this.info = null;
    this.selected = null;
    this.groupId = new SimpleField('');

    this.repository = repositoryService.get('groups');
    this.repositoryGroup = null;

    this.repository.listenTo('error:get', () => {
      this.items = [];
    });
    EventsBus.get().on(APP_EVENTS.GROUPS.UPDATED, () => this.fetch(true));
  }

  static get = (): Groups => {
    if (!Groups.instance) {
      Groups.instance = new Groups();
    }

    return Groups.instance;
  };

  @computed get selectValues() {
    return this.items.map((group) => ({ value: group.groupId, label: group.description }));
  }

  @computed get selectedValue() {
    if (this.selected) {
      return { value: this.selected.groupId, label: this.selected.description };
    } else {
      return allVehiclesOption;
    }
  }

  @action fetch = async (force = false, withAll?: boolean) => {
    if (this.items.length && !force) {
      return;
    }

    const serverGroups = await this.repository.get();
    const groups = serverGroups.items.map((serverGroup) => new Group(serverGroup));

    if (withAll) {
      this.items = [allVehiclesGroup, ...groups];
      this.setSelected(this.findGroupById('all'), true);
    } else {
      this.items = groups;
    }
  };

  @action getGroupInfo = async (groupId: string): Promise<void> => {
    this.repositoryGroup = this.repository.entity(groupId);

    try {
      this.info = await this.repositoryGroup.get({ withDevices: false, withInactiveDevices: false });
    } catch (err) {
      this.info = null;
    }
  };

  @action setSelected = (group: Group, fetchAssets?: boolean) => {
    if (group) {
      this.selected = group;
      this.groupId.set(group.groupId);
      if (fetchAssets) {
        this.selected.fetchAssets();
      }
    } else {
      this.selected = null;
      this.groupId.set('');
    }
  };

  @action reset = () => {
    this.items = [];
    this.info = null;
    this.selected = null;
    this.groupId.reset();
    this.repository.getState.reset();
  };

  findGroupById = (groupId: string) => find(this.items, { groupId });
}

export default Groups;
