import { SimpleField } from 'models/Fields';
import type { CAMERA_TYPE } from 'config';

class CameraModel {
  id: string;
  serialNumber: string;
  provider: SimpleField<CAMERA_TYPE>;
  name: string;
  activation: string;
  uniqueId: string;

  constructor(camera = {}) {
    this.provider = new SimpleField(null);
    this.model = camera;
  }

  set model(camera) {
    this.id = camera?.id;
    this.serialNumber = camera?.serialNumber;
    this.name = camera?.name;
    this.activation = camera?.activation;
    this.uniqueId = camera?.uniqueId;
    this.provider.set(camera?.provider);
  }
}

export default CameraModel;
