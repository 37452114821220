import React from 'react';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';
import { parse } from 'query-string';
import { get } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { PATHS, REPORTS_BETA_ITEMS, SCHEDULED_REPORTS_TYPE_MODAL } from 'config';

import type {
  DevicesStore,
  MapStore,
  PersistenceStore,
  ReportsStore,
  RouterStore,
  TimeStore,
  timezoneNames,
  ScheduledReportsStore,
} from 'stores';

import ScheduledReportModal from '../ScheduledReports/ScheduledReportModal';
import CloseButton from 'components/Button/CloseButton';
import VehicleAndGroupSwitcher from 'components/Reports/VehicleAndGroupSwitcher';
import TimeRange from 'components/TimeRange';
import ReportOptionSelector from 'components/Reports/ReportOptionSelector';
import ReportSubTypeOptionSelector from '../ReportSubTypeOptionSelector';
import GroupIdSelector from 'components/Reports/GroupIdSelector';
import SearchableDriverSelect from 'containers/Select/SearchableDriverSelect';
import SearchableGeoZoneSelect from 'containers/Select/SearchableGeoZoneSelect';
import SearchableGeoZoneTagSelect from '../../Select/SearchableGeoZoneTagSelect';
import GeozoneTagRadioSwitcher from 'components/Reports/GeozoneTagRadioSwitcher';
import SearchableReportOptionSelect from 'containers/Select/SearchableReportSelect';
import SearchableServiceTypeSelect from 'containers/Select/SearchableServiceTypeSelect';
import ReportRunReport from 'components/Reports/ReportRunReport';

import './styles.scss';

interface IMatchParams {
  reportId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  reportsStore?: ReportsStore;
  scheduledReportsStore?: ScheduledReportsStore;
  routerStore?: RouterStore;
  devicesStore?: DevicesStore;
  mapStore?: MapStore;
  timeStore?: TimeStore;
  persistenceStore?: PersistenceStore;
}

interface IState {
  loaded: boolean;
  deviceId: [string, string] | null;
  groupId: [string, string] | null;
  from: number | null;
  to: number | null;
  option: [string, string] | null;
  geozoneId: [string, string] | null;
  driverId: [string, string] | null;
  tagId: [string, string] | null;
  geozoneTagSwitcherValue: string;
  timeZone: timezoneNames;
  maintenanceServiceType: [string, string] | null;
}

interface IQueryParams {
  deviceId?: [string, string];
  from?: string;
  to?: string;
}

const IGNORED_EXCLUDE = [
  'DigitalInputDetailReport_Group',
  'PeriodicServiceOdom',
  'PeriodicServiceHours',
  'DeviceFaultCodes',
  'DeviceInformation',
  'IOSummaryCount',
  'DigitalInputSummaryReport',
  'DistanceTraveled',
  'IgnitionSummaryReport',
  'ExcessiveLastCheckIn',
  'EventCountSpeeding',
  'VehicleDriverBehavior',
  'VehicleDriverBehaviorPer100',
  'DriverEventDetail',
  'DriverEventDetailSafety',
  'DriverBehaviorReport',
  'LastMediaSummary',
  'IdleSummary',
  'CompletedMaintenanceOrderByDate_Group',
];

const EXCLUDE_ALL_DRIVER = ['DriverEventDetailSafety', 'DriverEventDetail'];

@inject(
  ({
    reportsStore,
    scheduledReportsStore,
    routerStore,
    devicesMapStore: { devicesStore, mapStore },
    timeStore,
    persistenceStore,
  }) => ({
    reportsStore,
    scheduledReportsStore,
    routerStore,
    devicesStore,
    mapStore,
    timeStore,
    persistenceStore,
  })
)
@observer
class ReportPreferences extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      groupId: null,
      ...this.initialPreferences,
      option: null,
      geozoneId: null,
      driverId: null,
      tagId: null,
      geozoneTagSwitcherValue: 'geozone',
      timeZone: this.props.timeStore.sessionTimezone,
      maintenanceServiceType: null,
    };
  }

  get initialPreferences() {
    const {
      history: {
        location: { search },
      },
    } = this.props;

    const { deviceId, from, to } = parse(search, { arrayFormat: 'index' }) as IQueryParams;

    return { deviceId, from: Number(from), to: Number(to) };
  }

  setReport = (remove: boolean = false): Promise<void> => {
    const {
      reportsStore: { setSelectedReportId },
      match: {
        params: { reportId },
      },
    } = this.props;

    return setSelectedReportId(remove ? '' : reportId).then(() => {
      this.setParameters();
    });
  };

  setParameters = () => {
    if (this.settings && !this.state.loaded) {
      const parameters = (this.settings.parameters || []).reduce(
        (acc, parameter) => {
          let value = this.state[parameter] || '';
          if (this.deviceId && parameter === 'deviceId') {
            value = [this.deviceId.value, this.deviceId.label];
          }

          return {
            ...acc,
            [parameter]: value,
          };
        },
        { loaded: true }
      );
      this.setState(parameters);
    }
  };

  setSelectedVehicle() {
    const {
      reportsStore: { setSelectedVehicle, vehiclesList },
    } = this.props;
    const { deviceId } = this.state;
    const vehicle = vehiclesList.find(({ id }) => id === deviceId?.[0]);

    if (vehicle) {
      setSelectedVehicle(vehicle);
    }
  }

  onUpdateFromAndTo = (from: number, to: number) => {
    const {
      persistenceStore: { updateSelectedTimeRange },
    } = this.props;

    updateSelectedTimeRange(from, to);
    this.setState((prevState) => {
      return {
        ...prevState,
        from: Math.round(from / 1000),
        to: Math.round(to / 1000),
      };
    });
  };

  onUpdateDeviceId = ({ value, label }) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        deviceId: [value, label],
      };
    }, this.setSelectedVehicle);
  };

  onUpdateDriverId = ({ value, label }) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        driverId: [value, label],
      };
    });
  };

  onUpdateGroupId = ({ value, label }) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        groupId: [value, label],
      };
    });
  };

  onUpdateGeoZoneId = ({ value, label }) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        geozoneId: [value, label],
        tagId: ['', ''],
      };
    });
  };

  onUpdateTagId = ({ value, label }) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        tagId: [value, label],
        geozoneId: ['all', 'All Geozones'],
      };
    });
  };

  onUpdateOption = (option: [string, string]) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        option,
      };
    });
  };

  onUpdateMaintenanceServiceType = ({ value, label }) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        maintenanceServiceType: [value, label],
      };
    });
  };

  get timezone() {
    return this.props.timeStore.sessionTimezone;
  }

  getStateParameters = (requiredParams: string[] = []) => {
    return [
      ...Object.entries(this.state).map(([key, value]) => {
        const isRequired = [...requiredParams].includes(key);
        if (!['loaded'].includes(key) && value !== null && isRequired) {
          if (key === 'tagId' && value[0] === 'all') {
            value = ['', value[1]];
          }
          if (key === 'driverId') {
            value = value[0].split('|')[0];
          }
          return [key, value];
        }
        return null;
      }),
      ['timeZone', this.timezone],
    ].filter((item) => item);
  };

  handleRunReportClick = () => {
    const {
      reportsStore: { loadReportTable, setTimeRange },
    } = this.props;
    const { from, to } = this.state;
    loadReportTable(
      this.props.reportsStore.selectedReportId,
      this.getStateParameters(this.settings.parameters),
      this.settings.asyncEnabled
    );
    setTimeRange({ from: from * 1000, to: to ? to * 1000 : null });
  };

  redirectToIndexReportPage = () => {
    this.props.routerStore.history.push(PATHS.REPORTS.INDEX);
  };

  redirectToReportPageById = (id: string, optionId: string = '') => {
    void this.props.reportsStore.setSelectedReportId(id);
    this.props.routerStore.history.push(PATHS.REPORTS.REPORT.replace(':reportId', id).replace(':optionId', optionId));
  };

  async componentDidMount() {
    const {
      reportsStore: { getReportsList, list },
    } = this.props;

    if (!list.length) {
      await getReportsList();
    }

    this.setReport().then(() => {
      const initialDeviceId = this.initialPreferences.deviceId;

      if (initialDeviceId) {
        const {
          persistenceStore: { updateSelectedVehicle },
        } = this.props;

        updateSelectedVehicle({ value: initialDeviceId[0], label: initialDeviceId[1] });
        this.handleRunReportClick();
      }
    });
  }

  componentWillUnmount() {
    this.setReport(true);
  }

  handleCloseClick = () => {
    this.setReport(true);
    this.redirectToIndexReportPage();
  };

  openScheduledReportModal = () => {
    const { scheduledReportsStore, reportsStore } = this.props;

    if (this.state.deviceId) {
      reportsStore.selectedReport.reportPreferences.device.set(this.state.deviceId[0], this.state.deviceId[1]);
    }

    if (this.state.groupId) {
      reportsStore.selectedReport.reportPreferences.group.set(this.state.groupId[0], this.state.groupId[1]);
    }

    if (this.state.driverId) {
      reportsStore.selectedReport.reportPreferences.driver.id.set(this.state.driverId[0]);
      reportsStore.selectedReport.reportPreferences.driver.displayName.set(this.state.driverId[1]);
    }

    if (this.state.geozoneId) {
      reportsStore.selectedReport.reportPreferences.geozone.id.set(this.state.geozoneId[0]);
      reportsStore.selectedReport.reportPreferences.geozone.displayName.set(this.state.geozoneId[1]);
    }

    if (this.state.option) {
      reportsStore.selectedReport.reportPreferences.option.id.set(this.state.option[0]);
      reportsStore.selectedReport.reportPreferences.option.displayName.set(this.state.option[1]);
    }

    if (this.state.tagId) {
      reportsStore.selectedReport.reportPreferences.tag.id.set(this.state.tagId[0]);
      reportsStore.selectedReport.reportPreferences.tag.displayName.set(this.state.tagId[1]);
    }

    if (this.state.maintenanceServiceType) {
      reportsStore.selectedReport.reportPreferences.maintenanceServiceType.id.set(this.state.maintenanceServiceType[0]);
      reportsStore.selectedReport.reportPreferences.maintenanceServiceType.displayName.set(
        this.state.maintenanceServiceType[1]
      );
    }

    scheduledReportsStore.create();
  };

  handleCloseScheduledModal = () => {
    const { scheduledReportsStore } = this.props;
    scheduledReportsStore.setItem(null);
  };

  get reportId() {
    const {
      match: {
        params: { reportId },
      },
      reportsStore: { selectedReportId },
    } = this.props;

    return selectedReportId || reportId;
  }

  get selectedGroup() {
    const {
      reportsStore: { selectedReport },
    } = this.props;

    return selectedReport;
  }

  get settings(): Report.IServerReportByVehicle | Report.IServerReportByGroup {
    return (
      this.selectedGroup &&
      (this.selectedGroup.settings(this.reportId) as Report.IServerReportByVehicle | Report.IServerReportByGroup)
    );
  }

  get selectedTypeId() {
    return this.selectedGroup && this.selectedGroup.selectedTypeId(this.reportId);
  }

  get hasTimeRange() {
    return (
      this.settings.parameters && this.settings.parameters.includes('from') && this.settings.parameters.includes('to')
    );
  }

  get hasGroupSelection() {
    return this.settings.parameters && this.settings.parameters.includes('groupId');
  }

  get hasOptions() {
    return this.settings.parameters && this.settings.parameters.includes('option');
  }

  get hasGeoZoneId() {
    return this.settings.parameters && this.settings.parameters.includes('geozoneId');
  }

  get hasGeoZoneTagId() {
    return this.settings.parameters && this.settings.parameters.includes('tagId');
  }

  get hasDriverId() {
    return this.settings.parameters && this.settings.parameters.includes('driverId');
  }

  get hasSubTypeOptions() {
    return !this.selectedGroup.selectedReport.parameters && this.selectedGroup.selectedReport.options?.length;
  }

  get hasVehicleAndGroupReports() {
    return this.selectedGroup.byVehicle !== null && this.selectedGroup.byGroup !== null;
  }

  get hasMaintenanceServiceType() {
    return this.settings.parameters && this.settings.parameters.includes('maintenanceServiceType');
  }

  get time() {
    const {
      persistenceStore: { selectedTimeRange },
      reportsStore: { timeRange },
    } = this.props;
    const { from, to } = this.initialPreferences;

    if (from && to) {
      return { from, to };
    }

    if (selectedTimeRange?.from && selectedTimeRange?.to) {
      return {
        from: selectedTimeRange.from,
        to: selectedTimeRange.to,
      };
    }

    return {
      from: timeRange.from,
      to: timeRange.to,
    };
  }

  get noSelectionReport() {
    return (
      !this.hasTimeRange &&
      !this.hasGroupSelection &&
      !this.hasOptions &&
      !this.hasGeoZoneId &&
      !this.hasGeoZoneTagId &&
      !this.hasSubTypeOptions &&
      !this.hasVehicleAndGroupReports &&
      !this.hasMaintenanceServiceType
    );
  }

  get disableRun() {
    const ALLOW_NULL = ['to'];

    return !!(
      this.settings.parameters &&
      this.settings.parameters
        .filter((key) => !ALLOW_NULL.includes(key))
        .map((key: string) => {
          return !this.state[key];
        })
        .filter((item) => item).length
    );
  }

  get lastEvent() {
    const {
      reportsStore: { getLatestEventsByDeviceId },
    } = this.props;
    const { deviceId } = this.state;

    const event = getLatestEventsByDeviceId(deviceId?.[0]);
    if (event) {
      return new Date(event.latestEvent);
    }
    return undefined;
  }

  get groupId() {
    const { groupId } = this.state;

    return groupId ? { value: groupId[0], label: groupId[1] } : undefined;
  }

  get driverId() {
    const { driverId } = this.state;

    return driverId ? { value: driverId[0], label: driverId[1] } : undefined;
  }

  get geozoneId() {
    const { geozoneId } = this.state;

    return geozoneId ? { value: geozoneId[0], label: geozoneId[1] } : undefined;
  }

  get maintenanceServiceType() {
    const { maintenanceServiceType } = this.state;

    return maintenanceServiceType ? { value: maintenanceServiceType[0], label: maintenanceServiceType[1] } : undefined;
  }

  get geozoneTagId() {
    const { tagId } = this.state;

    return tagId ? { value: tagId[0], label: tagId[1] } : undefined;
  }

  get deviceId() {
    const { deviceId } = this.state;

    return deviceId ? { value: deviceId[0], label: deviceId[1] } : undefined;
  }

  get firstOption() {
    return get(this.state, ['option', 0], get(this.props, ['match', 'params', 'optionId'], undefined));
  }

  get geozoneTagValue() {
    return this.state.geozoneTagSwitcherValue;
  }

  render() {
    const {
      reportsStore: { selectedReport, excludeInactive, setExcludeInactive },
      scheduledReportsStore: { item },
    } = this.props;

    if (!selectedReport || !this.settings) {
      return null;
    }

    const reportId = this.reportId;

    return (
      <div className="ReportPreferences">
        <div className="ReportPreferences-header">
          <div className="ReportPreferences-close">
            <CloseButton bgColor onClick={this.handleCloseClick} />
          </div>
          <div className="ReportPreferences-title">
            {selectedReport.title}
            {REPORTS_BETA_ITEMS.includes(selectedReport.id) && (
              <span className="ReportPreferences-header--beta">Beta</span>
            )}
          </div>
          <div className="ReportPreferences-description">Report Settings</div>
        </div>
        <div className="ReportPreferences-body">
          <div className="ReportPreferences-itemTitle">Source</div>

          {this.hasVehicleAndGroupReports ? (
            <VehicleAndGroupSwitcher
              byGroup={this.selectedGroup.byGroup}
              byVehicle={this.selectedGroup.byVehicle}
              excludeInactive={excludeInactive}
              hasExclude={!IGNORED_EXCLUDE.includes(reportId)}
              onExcludeChange={setExcludeInactive}
              onGroupChange={this.onUpdateGroupId}
              onVehicleChange={this.onUpdateDeviceId}
              redirectToReportPageById={this.redirectToReportPageById}
              selectedGroup={this.groupId}
              selectedType={selectedReport.selectedType}
              selectedVehicle={this.deviceId}
              selectFirst
            >
              {this.hasSubTypeOptions ? (
                <>
                  <div className="ReportPreferences-label">{this.selectedGroup.selectedReport.optionsHeader}</div>
                  <ReportSubTypeOptionSelector
                    options={this.selectedGroup.selectedReport.options}
                    selectedId={reportId}
                  />
                </>
              ) : null}
            </VehicleAndGroupSwitcher>
          ) : null}

          {!this.hasVehicleAndGroupReports && this.hasGroupSelection ? (
            <div className="ReportPreferences-row">
              <div className="ReportPreferences-label">Group</div>
              <GroupIdSelector
                excludeInactive={excludeInactive}
                hasExclude={!IGNORED_EXCLUDE.includes(reportId)}
                onExcludeChange={setExcludeInactive}
                onGroupChange={this.onUpdateGroupId}
                value={this.groupId}
              />
            </div>
          ) : null}

          {this.noSelectionReport && (
            <div className="ReportPreferences-row">
              <div className="ReportPreferences-label">Drivers</div>
              <SearchableDriverSelect disabled value={{ value: 'all', label: 'All Drivers' }} />
            </div>
          )}

          {this.hasDriverId && (
            <div className="ReportPreferences-row">
              <div className="ReportPreferences-label">Driver</div>
              <SearchableDriverSelect
                value={this.driverId}
                handleChange={this.onUpdateDriverId}
                persistChange
                noAll={EXCLUDE_ALL_DRIVER.includes(reportId)}
                valueSource="driverFobId"
              />
            </div>
          )}

          {!this.hasVehicleAndGroupReports && this.hasSubTypeOptions ? (
            <div className="ReportPreferences-row">
              <div className="ReportPreferences-label">{this.selectedGroup.selectedReport.optionsHeader}</div>
              <ReportSubTypeOptionSelector options={this.selectedGroup.selectedReport.options} selectedId={reportId} />
            </div>
          ) : null}

          {['EventSpeedPosted_Group', 'EventSpeedPosted'].includes(reportId) &&
          selectedReport.selectedType &&
          selectedReport[selectedReport.selectedType] ? (
            <div className="ReportPreferences-row">
              <div className="ReportPreferences-label">EXCEEDING</div>
              <ReportOptionSelector
                key={`report-exceeding-option-${reportId}`}
                options={selectedReport[selectedReport.selectedType].options}
                skipInitialSet
                onSelect={([key]) => {
                  this.redirectToReportPageById(
                    reportId === 'EventSpeedPosted' ? 'EventSpeedOption' : 'EventSpeedOption_Group',
                    key
                  );
                }}
                value={'Posted Speed'}
              />
            </div>
          ) : null}

          {[
            'GeozoneReport',
            'GeozoneDeparture',
            'GeozoneDriving',
            'GeozoneReport_Group',
            'GeozoneDeparture_Group',
            'GeozoneDriving_Group',
          ].includes(reportId) && (
            <div className="ReportPreferences-row">
              <div className="ReportPreferences-label">For</div>
              <GeozoneTagRadioSwitcher
                onChange={(geozoneTagSwitcherValue) => {
                  this.setState({ geozoneTagSwitcherValue });
                }}
                checkedValue={this.geozoneTagValue}
                GeozoneSelect={
                  <SearchableGeoZoneSelect
                    handleChange={this.onUpdateGeoZoneId}
                    value={this.geozoneId}
                    optionValueKey={'idGts'}
                    withAll
                    persistChange
                  />
                }
                TagSelect={
                  <SearchableGeoZoneTagSelect
                    handleChange={this.onUpdateTagId}
                    value={this.geozoneTagId}
                    withAll
                    persistChange
                  />
                }
              />
            </div>
          )}

          {this.hasOptions ? (
            <div
              className={classnames('ReportPreferences-row', {
                'ReportPreferences-row--hidden': this.settings.options.length < 2,
              })}
            >
              <div className="ReportPreferences-label">{this.settings.optionsHeader} </div>
              <div className="ReportPreferences-select">
                <SearchableReportOptionSelect
                  key={`report-option-${reportId}`}
                  options={this.settings.options}
                  onChange={this.onUpdateOption}
                  value={this.firstOption}
                  onRedirect={this.redirectToReportPageById}
                />
              </div>
            </div>
          ) : null}
          {[
            'CompletedMaintenance',
            'CompletedMaintenanceOrderByDate_Group',
            'CompletedMaintenanceGroupByVehicle_Group',
            'UpcomingMaintenance',
            'UpcomingMaintenance_Group',
          ].includes(reportId) && (
            <div className="ReportPreferences-row">
              <div className="ReportPreferences-label">Service Type</div>
              <div>
                <SearchableServiceTypeSelect
                  handleChange={this.onUpdateMaintenanceServiceType}
                  value={this.maintenanceServiceType}
                  withAll
                  withOnlyActive
                />
              </div>
            </div>
          )}

          {this.hasTimeRange ? (
            <TimeRange
              date={this.time}
              onChange={this.onUpdateFromAndTo}
              disableLastEvent={this.hasGroupSelection || !this.lastEvent}
              lastEvent={this.lastEvent}
              timeZone={this.timezone}
              quickLinks={
                [
                  'CompletedMaintenance',
                  'CompletedMaintenanceOrderByDate_Group',
                  'CompletedMaintenanceGroupByVehicle_Group',
                ].includes(reportId)
                  ? ['this-month', 'this-week', 'last-month']
                  : undefined
              }
            />
          ) : null}
        </div>
        <div className="ReportPreferences-footer">
          <ReportRunReport
            onClick={this.handleRunReportClick}
            disabledRun={this.disableRun}
            onScheduledClick={this.openScheduledReportModal}
          />
        </div>
        <ScheduledReportModal
          isOpen={Boolean(item)}
          onClose={this.handleCloseScheduledModal}
          title="Create Scheduled Report"
          type={SCHEDULED_REPORTS_TYPE_MODAL.CREATE}
          scheduledReport={item}
          locked
        />
      </div>
    );
  }
}

const HOC = (Component) => {
  return (props: IProps) => <Component {...props} key={props.match.params.reportId} />;
};

export default withRouter(HOC(ReportPreferences));
