import { reaction } from 'mobx';
import { get } from 'lodash';

import { webSocketService } from 'services';
import { WS_ROUTES } from 'config';
import type { DevicesStore } from 'stores';
import { replaceParams, removeCookie, tokenManager } from 'utils';
import type WS from 'services/Websocket/WebSocket';
import { WS_OPTIONS } from 'services/Websocket/WebSocketConfig';
import { TOKEN_COOKIE_NAME } from 'config/cookie';
import EventsBus from 'services/EventsBus/eventsBus';

class DevicesDelivery {
  websocket: WS;
  pollingLatestEventsInterval: number = null;
  context: DevicesStore;

  constructor(context: DevicesStore) {
    this.context = context;
    this.websocket = webSocketService.get('delivery', WS_OPTIONS.DELIVERY, this.fallbackStrategy);

    EventsBus.get().on('ws:connect:delivery', this.onConnectWS);
    EventsBus.get().on('ws:error:delivery', this.onErrorWS);

    reaction(
      () => this.context.showHistory,
      (showHistory) => this.onChangeHistory(showHistory)
    );

    reaction(
      () => this.context.groups.groupId.value,
      () => this.onChangeGroup()
    );
  }

  onConnectWS = async (res) => {
    const userName = get(res, 'headers["user-name"]', null);

    if (!userName) {
      return;
    }

    this.websocket.on(this.path, this.context.onEventsDelivery);
  };

  onErrorWS = async (error) => {
    if (error?.body.includes('401')) {
      removeCookie(TOKEN_COOKIE_NAME);
      await tokenManager.refresh((data) => this.websocket.updateUrl(data.token).connect());
    }
  };

  onChangeHistory = (showHistory: boolean) => {
    if (showHistory) {
      this.websocket.disconnect();
    } else {
      this.websocket.connect();
    }
  };

  onChangeGroup = () => {
    this.websocket.reset();
    this.websocket.on(this.path, this.context.onEventsDelivery);
  };

  get fallbackStrategy() {
    return {
      callback: async () => {
        if (this.context.selectedDeviceId) {
          await this.context.selectedDevice?.getEvents(true);
        } else {
          await this.context.getDevices?.(false);
        }
      },
      polling: {
        repeat: true,
        reconnect: true,
      },
    };
  }

  get path() {
    const groupId = this.context.groups.groupId.value;

    if (groupId && groupId !== 'all') {
      return replaceParams(WS_ROUTES.group, { accountId: this.context.accountId, groupId });
    }

    return WS_ROUTES.all;
  }
}

export default DevicesDelivery;
