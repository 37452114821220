import React, { useState, useEffect } from 'react';
import { Observer } from 'mobx-react';
import moment from 'moment';

import { getFormattedTime } from 'utils';
import { DATE_TIME_FORMATS, INTERVAL_UNIT, MAINTENANCE_INTERVAL_OPTIONS } from 'config';
import classnames from 'classnames';
import timeStore from 'stores/TimeStore';
import type { ToggleField } from 'models/Fields';
import type MaintenanceConfig from 'models/Maintenances/Models/MaintenanceConfig';
import type MaintenanceCompleteValuesModel from 'models/Maintenances/Models/CompleteTask/MaintenanceCompleteValuesModel';

import Checkbox from 'components/Checkbox';
import InfoTooltip from 'components/InfoTooltip';
import Button from 'components/Button';
import InputNumber from 'components/InputNumber';
import SearchableStaticSelect from 'components/Select/SearchableStaticSelect';

import './styles.scss';

type MomentDuration = 'month' | 'week' | 'day' | 'year';

interface IProps {
  odometer: MaintenanceConfig;
  engHours: MaintenanceConfig;
  serviceTime: MaintenanceConfig;
  isCompleted: ToggleField;
  values: MaintenanceCompleteValuesModel;
}

const MaintenanceMarkAsCompleteRepeatService: React.FC<IProps> = ({
  odometer,
  engHours,
  serviceTime,
  values,
  isCompleted,
}) => {
  const [isEditIntervals, setIsEditIntervals] = useState(false);

  const checkRepeatEveryCheckbox = () => {
    setIsEditIntervals(false);
    isCompleted.toggle();
  };

  const changeIntervalUnit = ({ value }) => {
    const timestamp = Number(serviceTime.last.value);
    const time = moment(timestamp)
      .add(Number(serviceTime.interval.value), value.toLowerCase() as MomentDuration)
      .valueOf();
    serviceTime.intervalUnit.set(value as INTERVAL_UNIT);
    serviceTime.next.set(time.toString());
  };

  const onChangeOdometerInterval = (value) => {
    const interval = value ? value.toString().replace(/\,|\./gi, '') : '0';

    odometer.interval.set(interval);
    odometer.next.set((values.odometer.value + Number(interval)).toFixed(1));
  };

  const onChangeEngHoursInterval = (value) => {
    const interval = value ? value.toString().replace(/\,|\./gi, '') : '0';

    engHours.interval.set(interval);
    engHours.next.set((values.engHours.value + Number(interval)).toFixed(1));
  };

  const onChangeServiceTimeInterval = (value) => {
    const interval = value ? value.toString().replace(/\,|\./gi, '') : '0';

    const timestamp = Number(serviceTime.last.value);
    const time = moment(timestamp)
      .add(Number(interval), serviceTime.intervalUnit.value.toLowerCase() as MomentDuration)
      .valueOf();
    serviceTime.interval.set(interval);
    serviceTime.next.set(time.toString());
  };

  useEffect(() => {
    if (odometer.interval.value) {
      odometer.next.set((Number(odometer.interval.value) + values.odometer.value).toFixed(1));
    }

    if (engHours.interval.value) {
      engHours.next.set((Number(engHours.interval.value) + values.engHours.value).toFixed(1));
    }
  }, []);

  return (
    <Observer
      render={() => {
        const cnIntervals = classnames('MaintenanceMarkAsCompleteRepeatService-intervals', {
          'MaintenanceMarkAsCompleteRepeatService-intervals--disabled': isCompleted.value,
        });

        return (
          <div className="MaintenancePreference-line MaintenanceMarkAsCompleteRepeatService">
            <div className="MaintenancePreference-label MaintenancePreference-label--top MaintenanceMarkAsCompleteRepeatService-label">
              Repeat Service
            </div>
            <div className="MaintenancePreference-value MaintenanceMarkAsCompleteRepeatService-value">
              <div className="MaintenanceMarkAsCompleteRepeatService-checkbox">
                <Checkbox checked={!isCompleted.value} onChange={checkRepeatEveryCheckbox} />
                <label className="MaintenanceMarkAsCompleteRepeatService-checkboxText">Repeat Every</label>
                <InfoTooltip>
                  This maintenance task will be due again based on the interval information below. If the box is
                  unchecked, the task will not be scheduled again and any alerts associated will be deleted
                </InfoTooltip>
              </div>
              <div className={cnIntervals}>
                {isEditIntervals ? (
                  <div className="MaintenanceMarkAsCompleteRepeatService-intervalsEdit">
                    {odometer.interval.value && (
                      <div className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBox">
                        <div className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBoxInterval">
                          <InputNumber
                            value={Number(odometer.interval.value)?.toLocaleString('en-US', {
                              maximumFractionDigits: 0,
                            })}
                            className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBoxIntervalInput"
                            onChange={onChangeOdometerInterval}
                            min="1"
                            controls={false}
                          />
                          <label>miles</label>
                        </div>
                        <div className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBoxNext">
                          <span className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBoxNextLabel">Next</span>
                          <span className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBoxNextValue">
                            {Number(odometer.next.value)?.toLocaleString('en-US', { maximumFractionDigits: 0 })}
                          </span>
                        </div>
                      </div>
                    )}
                    {engHours.interval.value && !odometer.interval.value && (
                      <div className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBox">
                        <div className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBoxInterval">
                          <InputNumber
                            value={Number(engHours.interval.value)?.toLocaleString('en-US', {
                              maximumFractionDigits: 1,
                            })}
                            className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBoxIntervalInput"
                            onChange={onChangeEngHoursInterval}
                            min="1"
                            controls={false}
                          />
                          <label>hours</label>
                        </div>
                        <div className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBoxNext">
                          <span className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBoxNextLabel">Next</span>
                          <span className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBoxNextValue">
                            {Number(engHours.next.value)?.toLocaleString('en-US', { maximumFractionDigits: 1 })}
                          </span>
                        </div>
                      </div>
                    )}
                    {serviceTime.interval.value && (
                      <div className="MaintenanceMarkAsCompleteRepeatService-intervalsEditWrapper">
                        <p className="MaintenanceMarkAsCompleteRepeatService-intervalsEditWrapperOr">or</p>
                        <div className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBox">
                          <div className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBoxInterval">
                            <InputNumber
                              value={serviceTime.interval.value}
                              className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBoxIntervalDate"
                              onChange={onChangeServiceTimeInterval}
                              min="1"
                              controls={false}
                            />
                            <div className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBoxIntervalSelect">
                              <SearchableStaticSelect
                                isSearchable={false}
                                value={{
                                  label: '',
                                  value: serviceTime.intervalUnit.value,
                                }}
                                values={MAINTENANCE_INTERVAL_OPTIONS}
                                handleChange={changeIntervalUnit}
                              />
                            </div>
                          </div>
                          <div className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBoxNext">
                            <span className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBoxNextLabel">
                              Next
                            </span>
                            <span className="MaintenanceMarkAsCompleteRepeatService-intervalsEditBoxNextValue">
                              {getFormattedTime(
                                Number(serviceTime.next.value),
                                DATE_TIME_FORMATS.monthDatYearFull,
                                timeStore.sessionTimezone
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="MaintenanceMarkAsCompleteRepeatService-intervalsView">
                    <div className="MaintenanceMarkAsCompleteRepeatService-intervalsViewContent">
                      {odometer.interval.value && (
                        <div className="MaintenanceMarkAsCompleteRepeatService-intervalsViewContentBox">
                          <span className="MaintenanceMarkAsCompleteRepeatService-intervalsViewContentBoxInterval">
                            {Number(odometer.interval.value)?.toLocaleString('en-US', { maximumFractionDigits: 0 })} mi
                          </span>
                          <span className="MaintenanceMarkAsCompleteRepeatService-intervalsViewContentBoxNext">
                            {Number(odometer.next.value)?.toLocaleString('en-US', { maximumFractionDigits: 0 })} mi
                          </span>
                        </div>
                      )}
                      {engHours.interval.value && !odometer.interval.value && (
                        <div className="MaintenanceMarkAsCompleteRepeatService-intervalsViewContentBox">
                          <span className="MaintenanceMarkAsCompleteRepeatService-intervalsViewContentBoxInterval">
                            {Number(engHours.interval.value)?.toLocaleString('en-US', { maximumFractionDigits: 1 })} hrs
                          </span>
                          <span className="MaintenanceMarkAsCompleteRepeatService-intervalsViewContentBoxNext">
                            {Number(engHours.next.value)?.toLocaleString('en-US', { maximumFractionDigits: 1 })} hrs
                          </span>
                        </div>
                      )}
                      {serviceTime.interval.value && (
                        <div className="MaintenanceMarkAsCompleteRepeatService-intervalsViewContentWrapper">
                          <p className="MaintenanceMarkAsCompleteRepeatService-intervalsViewContentWrapperOr">or</p>
                          <div className="MaintenanceMarkAsCompleteRepeatService-intervalsViewContentBox">
                            <span className="MaintenanceMarkAsCompleteRepeatService-intervalsViewContentBoxInterval">
                              {Number(serviceTime.interval.value).toFixed(0)} {serviceTime.intervalUnit.value}
                            </span>
                            <span className="MaintenanceMarkAsCompleteRepeatService-intervalsViewContentBoxNext">
                              {getFormattedTime(
                                Number(serviceTime.next.value),
                                DATE_TIME_FORMATS.monthDatYearFull,
                                timeStore.sessionTimezone
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <Button
                      title="Edit intervals"
                      className="Button--link"
                      onClick={() => setIsEditIntervals(true)}
                      disabled={isCompleted.value}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default MaintenanceMarkAsCompleteRepeatService;
