import React, { FC } from 'react';

import Label from 'components/Label';
import ColorPickerIcon from 'components/ColorPicker/ColorPickerIcon/ColorPickerIcon';

import './styles.scss';

interface IProps {
  color: Geozone.IGeozoneColor;
  dispatchMarker: string;
  group: string;
  tag: string;
}

const MapGeozonePanelDetails: FC<IProps> = ({ color, dispatchMarker, group, tag }) => {
  return (
    <div className="MapGeozonePanelDetails">
      <div className="MapGeozonePanelDetails-content">
        <div className="MapGeozonePanelDetails-section">
          <div className="MapGeozonePanelDetails-sectionLabel">
            <Label text="Category" size="large" />
          </div>
          <div className="MapGeozonePanelDetails-sectionContent">{tag}</div>
        </div>
        <div className="MapGeozonePanelDetails-section MapGeozonePanelDetails-section--color">
          <div className="MapGeozonePanelDetails-sectionLabel">
            <Label text="Color" size="large" />
          </div>
          <div className="MapGeozonePanelDetails-sectionContent">
            <ColorPickerIcon color={color} size={16} />
          </div>
        </div>
        <div className="MapGeozonePanelDetails-section MapGeozonePanelDetails-section--marker">
          <div className="MapGeozonePanelDetails-sectionLabel">
            <Label text="Dispatch Marker" size="large" />
          </div>
          <div className="MapGeozonePanelDetails-sectionContent">{dispatchMarker}</div>
        </div>
        <div className="MapGeozonePanelDetails-section MapGeozonePanelDetails-section--group">
          <div className="MapGeozonePanelDetails-sectionLabel">
            <Label text="Group" size="large" />
          </div>
          <div className="MapGeozonePanelDetails-sectionContent">{group}</div>
        </div>
      </div>
    </div>
  );
};

export default MapGeozonePanelDetails;
