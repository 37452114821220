import React from 'react';
import Button from 'components/Button';

export const IntegrationsConnectingQBError = () => {
  const handleCLick = () => {
    window.close();
  };

  return (<div className="IntegrationsConnecting-Error">
    <div className="IntegrationsConnecting-ErrorDescription">
      Something went wrong. Please try again.
    </div>
    <div className="IntegrationsConnecting-ErrorActions">
      <Button title="Close" className="Integration-ItemActionButton" inline onClick={handleCLick} />
    </div>
  </div>);
};

export default IntegrationsConnectingQBError;
