import React from 'react';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';

import type {
  DevicesStore,
  MapStore,
  PersistenceStore,
  ReportsStore,
  RouterStore,
  TimeStore,
  ScheduledReportsStore,
} from 'stores';

import VehicleAndGroupSwitcher from 'components/Reports/VehicleAndGroupSwitcher';
import ReportOptionSelector from 'components/Reports/ReportOptionSelector';
import ReportSubTypeOptionSelector from 'containers/Reports/ReportSubTypeOptionSelector';
import GroupIdSelector from 'components/Reports/GroupIdSelector';
import SearchableDriverSelect from 'containers/Select/SearchableDriverSelect';
import SearchableGeoZoneSelect from 'containers/Select/SearchableGeoZoneSelect';
import SearchableGeoZoneTagSelect from 'containers/Select/SearchableGeoZoneTagSelect';
import GeozoneTagRadioSwitcher from 'components/Reports/GeozoneTagRadioSwitcher';
import SearchableReportOptionSelect from 'containers/Select/SearchableReportSelect';
import SearchableServiceTypeSelect from 'containers/Select/SearchableServiceTypeSelect';

import './styles.scss';

interface IProps {
  reportId: string;
  reportsStore?: ReportsStore;
  scheduledReportsStore?: ScheduledReportsStore;
  routerStore?: RouterStore;
  devicesStore?: DevicesStore;
  mapStore?: MapStore;
  timeStore?: TimeStore;
  persistenceStore?: PersistenceStore;
}

interface IState {
  loaded: boolean;
  geozoneTagSwitcherValue: string;
}

const IGNORED_EXCLUDE = [
  'DigitalInputDetailReport_Group',
  'PeriodicServiceOdom',
  'PeriodicServiceHours',
  'DeviceFaultCodes',
  'DeviceInformation',
  'IOSummaryCount',
  'DigitalInputSummaryReport',
  'DistanceTraveled',
  'IgnitionSummaryReport',
  'ExcessiveLastCheckIn',
  'EventCountSpeeding',
  'VehicleDriverBehavior',
  'VehicleDriverBehaviorPer100',
  'DriverEventDetail',
  'DriverEventDetailSafety',
  'DriverBehaviorReport',
  'LastMediaSummary',
  'IdleSummary',
];

const EXCLUDE_ALL_DRIVER = ['DriverEventDetailSafety', 'DriverEventDetail'];

@inject(
  ({
    reportsStore,
    scheduledReportsStore,
    routerStore,
    devicesMapStore: { devicesStore, mapStore },
    timeStore,
    persistenceStore,
  }) => ({
    reportsStore,
    scheduledReportsStore,
    routerStore,
    devicesStore,
    mapStore,
    timeStore,
    persistenceStore,
  })
)
@observer
class ScheduledReportPreferences extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    const {
      scheduledReportsStore: {
        item: { selectedForOption },
      },
    } = props;

    this.state = {
      loaded: false,
      geozoneTagSwitcherValue: selectedForOption,
    };
  }

  componentDidMount() {
    this.setReport();
  }

  componentWillUnmount(): void {
    const {
      scheduledReportsStore: { item },
    } = this.props;

    item?.setSelectedTypeOption(null);
  }

  setReport = () => {
    const {
      reportsStore: { setSelectedReportId },
      reportId,
    } = this.props;

    setSelectedReportId(reportId).then(() => {
      this.setParameters();
    });
  };

  setParameters = () => {
    if (this.settings && !this.state.loaded) {
      const parameters = (this.settings.parameters || []).reduce(
        (acc, parameter) => {
          let value = this.state[parameter] || '';
          if (this.device && parameter === 'deviceId') {
            value = [this.device.value, this.device.label];
          }

          return {
            ...acc,
            [parameter]: value,
          };
        },
        { loaded: true }
      );

      this.setState(parameters);
    }
  };

  setSelectedVehicle() {
    const {
      reportsStore: {
        setSelectedVehicle,
        vehiclesList,
        selectedReport: {
          reportPreferences: { device },
        },
      },
    } = this.props;

    const vehicle = vehiclesList.find(({ id }) => id === device.id.value);

    if (vehicle) {
      setSelectedVehicle(vehicle);
    }
  }

  onUpdateFromAndTo = (from: number, to: number) => {
    const {
      reportsStore: {
        selectedReport: { reportPreferences },
      },
    } = this.props;

    reportPreferences.from.set(Math.round(from / 1000));
    reportPreferences.to.set(Math.round(to / 1000));
  };

  onUpdateDeviceId = ({ value, label }) => {
    const {
      reportsStore: {
        selectedReport: {
          reportPreferences: { device, group },
        },
      },
    } = this.props;

    device.id.set(value);
    device.displayName.set(label);
    group.id.set('');
    group.displayName.set('');
    this.setSelectedVehicle();
  };

  onUpdateDriverId = ({ value, label }) => {
    const {
      reportsStore: {
        selectedReport: {
          reportPreferences: { driver },
        },
      },
    } = this.props;

    driver.id.set(value);
    driver.displayName.set(label);
  };

  onUpdateGroupId = ({ value, label }) => {
    const {
      reportsStore: {
        selectedReport: {
          reportPreferences: { group, device },
        },
      },
    } = this.props;

    group.id.set(value);
    group.displayName.set(label);
    device.id.set('');
    device.displayName.set('');
  };

  onUpdateGeoZoneId = ({ value, label }) => {
    const {
      reportsStore: {
        selectedReport: {
          reportPreferences: { geozone, tag },
        },
      },
    } = this.props;

    geozone.id.set(value);
    geozone.displayName.set(label);
    tag.id.set('');
    tag.displayName.set('');
  };

  onUpdateTagId = ({ value, label }) => {
    const {
      reportsStore: {
        selectedReport: {
          reportPreferences: { geozone, tag },
        },
      },
    } = this.props;

    tag.id.set(value);
    tag.displayName.set(label);
    geozone.id.set('');
    geozone.displayName.set('');
  };

  onUpdateOption = (option: [string, string]) => {
    const {
      reportsStore: {
        selectedReport: { reportPreferences },
      },
    } = this.props;

    reportPreferences.optionId = '';
    reportPreferences.option.id.set(option[0]);
    reportPreferences.option.displayName.set(option[1]);
  };

  onUpdateMaintenanceServiceType = ({ value, label }) => {
    const {
      reportsStore: {
        selectedReport: {
          reportPreferences: { maintenanceServiceType },
        },
      },
    } = this.props;

    maintenanceServiceType.id.set(value);
    maintenanceServiceType.displayName.set(label);
  };

  redirectToReportPageById = async (id: string, optionId: string = '') => {
    const {
      reportsStore: {
        setSelectedReportId,
        selectedReport: { reportPreferences },
      },
    } = this.props;

    if (optionId) {
      reportPreferences.optionId = optionId;
    }

    await setSelectedReportId(id);
  };

  switchVehicleAndGroup = async (id: string) => {
    const {
      reportsStore: { selectedReport, setSelectedReportId },
      scheduledReportsStore: {
        item: { setSelectedTypeOption },
      },
    } = this.props;

    await setSelectedReportId(id);

    setSelectedTypeOption(selectedReport.getReportTypeById(id));
  };

  get reportId() {
    return this.props.reportId;
  }

  get selectedGroup() {
    const {
      reportsStore: { selectedReport },
    } = this.props;

    return selectedReport;
  }

  get settings(): Report.IServerReportByVehicle | Report.IServerReportByGroup {
    return (
      this.selectedGroup &&
      (this.selectedGroup.settings(this.reportId) as Report.IServerReportByVehicle | Report.IServerReportByGroup)
    );
  }

  get selectedTypeId() {
    return this.selectedGroup && this.selectedGroup.selectedTypeId(this.reportId);
  }

  get hasTimeRange() {
    return (
      this.settings.parameters && this.settings.parameters.includes('from') && this.settings.parameters.includes('to')
    );
  }

  get hasGroupSelection() {
    return this.settings.parameters && this.settings.parameters.includes('groupId');
  }

  get hasOptions() {
    return this.settings.parameters && this.settings.parameters.includes('option');
  }

  get hasGeoZoneId() {
    return this.settings.parameters && this.settings.parameters.includes('geozoneId');
  }

  get hasGeoZoneTagId() {
    return this.settings.parameters && this.settings.parameters.includes('tagId');
  }

  get hasDriverId() {
    return this.settings.parameters && this.settings.parameters.includes('driverId');
  }

  get hasSubTypeOptions() {
    return !this.selectedGroup.selectedReport.parameters && this.selectedGroup.selectedReport.options?.length;
  }

  get hasVehicleAndGroupReports() {
    return this.selectedGroup.byVehicle !== null && this.selectedGroup.byGroup !== null;
  }

  get hasMaintenanceServiceType() {
    return this.settings.parameters && this.settings.parameters.includes('maintenanceServiceType');
  }

  get noSelectionReport() {
    return (
      !this.hasTimeRange &&
      !this.hasGroupSelection &&
      !this.hasOptions &&
      !this.hasGeoZoneId &&
      !this.hasGeoZoneTagId &&
      !this.hasSubTypeOptions &&
      !this.hasVehicleAndGroupReports &&
      !this.hasMaintenanceServiceType
    );
  }

  get group() {
    const {
      reportsStore: {
        selectedReport: {
          reportPreferences: { group },
        },
      },
    } = this.props;

    return { value: group.id.value, label: group.displayName.value };
  }

  get driver() {
    const {
      reportsStore: {
        selectedReport: {
          reportPreferences: { driver },
        },
      },
    } = this.props;

    return { value: driver.id.value, label: driver.displayName.value };
  }

  get geozone() {
    const {
      reportsStore: {
        selectedReport: {
          reportPreferences: { geozone },
        },
      },
    } = this.props;

    return { value: geozone.id.value, label: geozone.displayName.value };
  }

  get geozoneTagId() {
    const {
      reportsStore: {
        selectedReport: {
          reportPreferences: { tag },
        },
      },
    } = this.props;

    return { value: tag.id.value, label: tag.displayName.value };
  }

  get device() {
    const {
      reportsStore: {
        selectedReport: {
          reportPreferences: { device },
        },
      },
    } = this.props;

    return { value: device.id.value, label: device.displayName.value };
  }

  get firstOption() {
    const {
      reportsStore: {
        selectedReport: {
          reportPreferences: { option, optionId },
        },
      },
    } = this.props;

    return optionId || option.id.value;
  }

  get geozoneTagValue() {
    return this.state.geozoneTagSwitcherValue;
  }

  get maintenanceServiceType() {
    const {
      reportsStore: {
        selectedReport: {
          reportPreferences: { maintenanceServiceType },
        },
      },
    } = this.props;

    return { value: maintenanceServiceType.id.value, label: maintenanceServiceType.displayName.value };
  }

  render() {
    const {
      reportsStore: {
        selectedReport,
        selectedReport: { selectedType },
        excludeInactive,
        setExcludeInactive,
      },
      reportId,
      scheduledReportsStore: { item },
    } = this.props;

    if (!selectedReport || !this.settings) {
      return null;
    }

    return (
      <div className="ScheduledReportPreferences">
        <div className="ScheduledReportPreferences-body">
          {this.hasVehicleAndGroupReports ? (
            <div className="ScheduledReportPreferences-switcher">
              <div className="ScheduledReportPreferences-switcher--label">by</div>
              <VehicleAndGroupSwitcher
                radioName="scheduledMode"
                byGroup={this.selectedGroup.byGroup}
                byVehicle={this.selectedGroup.byVehicle}
                excludeInactive={excludeInactive}
                hasExclude={!IGNORED_EXCLUDE.includes(this.reportId)}
                onExcludeChange={setExcludeInactive}
                onGroupChange={this.onUpdateGroupId}
                onVehicleChange={this.onUpdateDeviceId}
                redirectToReportPageById={this.switchVehicleAndGroup}
                selectedGroup={this.group}
                selectedType={item?.selectedTypeOption || selectedType}
                selectedVehicle={this.device}
                selectFirst
              >
                {this.hasSubTypeOptions ? (
                  <>
                    <div className="ScheduledReportPreferences-label">
                      {this.selectedGroup.selectedReport.optionsHeader}
                    </div>
                    <ReportSubTypeOptionSelector
                      options={this.selectedGroup.selectedReport.options}
                      selectedId={reportId}
                      onChange={this.redirectToReportPageById}
                    />
                  </>
                ) : null}
              </VehicleAndGroupSwitcher>
            </div>
          ) : null}

          {!this.hasVehicleAndGroupReports && this.hasGroupSelection ? (
            <div className="ScheduledReportPreferences-row">
              <div className="ScheduledReportPreferences-label ScheduledReportPreferences-labelTop">Group</div>
              <div className="ScheduledReportPreferences-select">
                <GroupIdSelector
                  excludeInactive={excludeInactive}
                  hasExclude={!IGNORED_EXCLUDE.includes(this.reportId)}
                  onExcludeChange={setExcludeInactive}
                  onGroupChange={this.onUpdateGroupId}
                  value={this.group}
                />
              </div>
            </div>
          ) : null}

          {this.noSelectionReport && (
            <div className="ScheduledReportPreferences-row">
              <div className="ScheduledReportPreferences-label">Drivers</div>
              <SearchableDriverSelect disabled value={{ value: 'all', label: 'All Drivers' }} />
            </div>
          )}

          {this.hasDriverId && (
            <div className="ScheduledReportPreferences-row">
              <div className="ScheduledReportPreferences-label">Driver</div>
              <SearchableDriverSelect
                value={this.driver}
                handleChange={this.onUpdateDriverId}
                persistChange
                noAll={EXCLUDE_ALL_DRIVER.includes(this.reportId)}
                selectFirst
                valueSource="driverFobId"
              />
            </div>
          )}

          {!this.hasVehicleAndGroupReports && this.hasSubTypeOptions ? (
            <div className="ScheduledReportPreferences-row">
              <div className="ScheduledReportPreferences-label">{this.selectedGroup.selectedReport.optionsHeader}</div>
              <ReportSubTypeOptionSelector
                options={this.selectedGroup.selectedReport.options}
                selectedId={this.reportId}
                onChange={this.redirectToReportPageById}
              />
            </div>
          ) : null}

          {['EventSpeedPosted_Group', 'EventSpeedPosted'].includes(reportId) &&
          selectedReport.selectedType &&
          selectedReport[selectedReport.selectedType] ? (
            <div className="ScheduledReportPreferences-row">
              <div className="ScheduledReportPreferences-label">Exceeding</div>
              <ReportOptionSelector
                options={selectedReport[selectedReport.selectedType].options}
                skipInitialSet
                onSelect={([key]) => {
                  this.redirectToReportPageById(
                    reportId === 'EventSpeedPosted' ? 'EventSpeedOption' : 'EventSpeedOption_Group',
                    key
                  );
                }}
                value={'Posted Speed'}
              />
            </div>
          ) : null}

          {[
            'GeozoneReport',
            'GeozoneDeparture',
            'GeozoneDriving',
            'GeozoneReport_Group',
            'GeozoneDeparture_Group',
            'GeozoneDriving_Group',
          ].includes(reportId) && (
            <div className="ScheduledReportPreferences-row">
              <div className="ScheduledReportPreferences-label ScheduledReportPreferences-labelTop">For</div>
              <GeozoneTagRadioSwitcher
                onChange={(geozoneTagSwitcherValue) => {
                  this.setState({ geozoneTagSwitcherValue });
                }}
                radioName="scheduledGeozoneMode"
                checkedValue={this.geozoneTagValue}
                GeozoneSelect={
                  <SearchableGeoZoneSelect
                    handleChange={this.onUpdateGeoZoneId}
                    value={this.geozone}
                    optionValueKey={'idGts'}
                    withAll
                    persistChange
                  />
                }
                TagSelect={
                  <SearchableGeoZoneTagSelect
                    handleChange={this.onUpdateTagId}
                    value={this.geozoneTagId}
                    withAll
                    persistChange
                    disableCreate
                  />
                }
              />
            </div>
          )}

          {this.hasOptions ? (
            <div
              className={classnames('ScheduledReportPreferences-row', {
                'ScheduledReportPreferences-row--hidden': this.settings.options.length < 2,
              })}
            >
              <div className="ScheduledReportPreferences-label">{this.settings.optionsHeader} </div>
              <div className="ScheduledReportPreferences-select">
                <SearchableReportOptionSelect
                  options={this.settings.options}
                  onChange={this.onUpdateOption}
                  value={this.firstOption}
                  onRedirect={this.redirectToReportPageById}
                />
              </div>
            </div>
          ) : null}

          {[
            'CompletedMaintenance',
            'CompletedMaintenanceOrderByDate_Group',
            'CompletedMaintenanceGroupByVehicle_Group',
            'UpcomingMaintenance',
            'UpcomingMaintenance_Group',
          ].includes(reportId) && (
            <div className="ReportPreferences-row">
              <div className="ReportPreferences-label">Service Type</div>
              <div>
                <SearchableServiceTypeSelect
                  handleChange={this.onUpdateMaintenanceServiceType}
                  value={this.maintenanceServiceType}
                  withAll
                  withOnlyActive
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const HOC = (Component) => {
  return (props: IProps) => <Component {...props} key={props.reportId} />;
};

export default HOC(ScheduledReportPreferences);
