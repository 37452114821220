import { MediaDeviceAdapter } from './MediaDeviceAdapter';
import { MediaItemsAdapter } from './MediaItemsAdapter';

export class MediaAdapter {
  devices: MediaDeviceAdapter;
  items: MediaItemsAdapter;
  // ids: { [string]: number };

  constructor() {
    this.devices = new MediaDeviceAdapter(this);
    this.items = new MediaItemsAdapter(this);
  }
}

export default new MediaAdapter();
