import React from 'react';
import { observer } from 'mobx-react';

import { Radio } from 'antd';

import type AlertPlace from 'models/Alerts/AlertPlace';
import type { SimpleField } from 'models/Fields';

import SearchableGeoZoneSelect from 'containers/Select/SearchableGeoZoneSelect';
import SearchableGeoZoneTagSelect from 'containers/Select/SearchableGeoZoneTagSelect';

import './styles.scss';

interface IProps {
  action: SimpleField<Alerts.Modal.GeoZone.Action>;
  place: AlertPlace;
}

const AlertPreferencesGeoZone: React.FC<IProps> = ({ action, place }) => {
  const handleChangeAction = (e) => {
    action.set(e.target.value);
  };

  const handleChangeType = (e) => {
    place.type.set(e.target.value);
    place.id.set(null);
    place.name.set('');
  };

  const handleChangeValue = (value) => {
    place.id.set(value.value);
    place.name.set(value.label);
  };

  return (
    <div className="AlertPreference-line">
      <div className="AlertPreference-label AlertPreference-label--top">Geozone</div>
      <div className="AlertPreference-value AlertPreferencesGeoZone-value">
        <div className="AlertPreferencesGeoZone-line">
          <Radio.Group onChange={handleChangeAction} value={action.value} className="AlertPreferencesGeoZone-switch">
            <Radio.Button value="ARRIVE" className="AlertPreferencesGeoZone-switchButton">
              Enters
            </Radio.Button>
            <Radio.Button value="DEPART" className="AlertPreferencesGeoZone-switchButton">
              Exits
            </Radio.Button>
            <Radio.Button value="ARRIVE_DEPART" className="AlertPreferencesGeoZone-switchButton">
              Both
            </Radio.Button>
          </Radio.Group>
        </div>
        <div className="AlertPreferencesGeoZone-line AlertPreferencesGeoZone-select">
          <Radio.Group onChange={handleChangeType} value={place.type.value} className="AlertPreferencesGeoZone-switch">
            <Radio.Button value="GEOZONE" className="AlertPreferencesGeoZone-switchButton">
              Geozone
            </Radio.Button>
            <Radio.Button value="CATEGORY" className="AlertPreferencesGeoZone-switchButton">
              Category
            </Radio.Button>
          </Radio.Group>
          {place.type.value === 'GEOZONE' ? (
            <SearchableGeoZoneSelect
              optionValueKey={'idGts'}
              value={{ value: place.id.value, label: place.name.value }}
              withAll
              persistChange
              withOnlyActive
              // getAllOptions
              handleChange={handleChangeValue}
            />
          ) : (
            <SearchableGeoZoneTagSelect
              selectFirst
              persistChange
              disableCreate
              value={{ value: place.id.value, label: place.name.value }}
              handleChange={handleChangeValue}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(AlertPreferencesGeoZone);
