import RequestMediaType from '../MediaRequestCheckout/RequestMedia/RequestMediaType';
import { CAMERA_JOURNAL_LABEL, CAMERA_JOURNAL_STATE } from 'config';
import { ToggleField } from 'models/Fields';

class MediaCameraType {
  label: string;
  value: string;
  isAvailable: ToggleField;
  media: RequestMediaType;

  constructor(cameraState: CAMERA_JOURNAL_STATE) {
    this.label = CAMERA_JOURNAL_LABEL[cameraState];
    this.value = cameraState;
    this.isAvailable = new ToggleField(false);
    this.media = new RequestMediaType();
  }
}

export default MediaCameraType;
