import React, { Component, createRef } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
// @ts-ignore
import AutoSizer from 'react-virtualized-auto-sizer';
import debounce from 'lodash/debounce';

import type { CustomersStore } from 'stores';
import { PATHS } from 'config';

import CustomersListItem from '../CustomersListItem';
import CustomersListSearch from '../CustomersListSearch';

import './styles.scss';

interface IProps extends  RouteComponentProps{
  customersStore: CustomersStore;
}


@inject(({ customersStore }) => ({
  customersStore,
}))
@observer
class CustomersList extends Component<IProps> {
  private readonly listRef: React.RefObject<HTMLDivElement>;
  getMoreItems:() => Promise<void>

  constructor(props) {
    super(props);
    this.listRef = createRef();
    this.getMoreItems = debounce(this._getMoreItems, 100);
  }

  _getMoreItems = () => {
    return this.props.customersStore.loadMoreCustomers(0, true);
  }

  componentDidMount(): void {
    const {
      customersStore: { loadMoreCustomers },
    } = this.props;

    loadMoreCustomers(0).catch(_ => {
      //
    });
  }

  handleClick = (id: string) => {
    const {
      history: { push },
    } = this.props;
    push(PATHS.MAP.CUSTOMER.replace(':customerId', id));
  }

  Row = ({ index, style }) => {
    const item = this.props.customersStore.customers[index];

    if (!this.isItemLoaded(index)) {
      return <div  className="CustomersList-ListItem" style={style} />
    }

    return <CustomersListItem
      customer={item}
      onClick={this.handleClick}
      style={style}
    />
  };

  isItemLoaded = index => !!this.props.customersStore.customers[index];

  loadMoreItems = (startIndex) => {
    return this.props.customersStore.loadMoreCustomers(Math.floor(startIndex / 100))
  }

  render() {
    const {
      customersStore: {
        total,
        repositoryEntityCustomers,
      },
    } = this.props;

    return <div>
      <CustomersListSearch
        getMoreItems={this.getMoreItems}
      />
      <div>
        <div className="CustomersList-List" ref={this.listRef}>
          {
            repositoryEntityCustomers.getState.success && total === 0 &&
              (<div className="CustomersList--noResults">No results found</div>)
          }
          {total > 0 && <AutoSizer>
            {({height, width}) => (
              <InfiniteLoader
                isItemLoaded={this.isItemLoaded}
                itemCount={total}
                loadMoreItems={this.loadMoreItems}
              >
                {({ onItemsRendered, ref }) => (
                  <List
                    className="List"
                    height={height}
                    itemCount={total}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    itemSize={53}
                    width={width}
                  >
                    {this.Row}
                  </List>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>}
        </div>
      </div>
    </div>;
  }
}

export default CustomersList;
