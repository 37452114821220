import React, { useCallback, useEffect, useState } from 'react';

import { repositoryService } from 'services';

interface IProps {
  alt?: string;
  className?: string;
  heading: number;
  latitude: number;
  longitude: number;
  width: number;
  height: number;
  return_error_code?: boolean;
  source?: string;
  onAbort: () => void;
  onError: () => void;
  onLoad: () => void;
}
const convertBlobToBase = (blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result.toString())
    };
    reader.readAsDataURL(blob);
  })
}

const StreetViewImage: React.FC<IProps> = (
  {
    alt = '',
    className= '',
    heading,
    latitude,
    longitude,
    width,
    height,
    return_error_code = true,
    source = 'default',
    onAbort,
    onError,
    onLoad,
  }
) => {
  const [finished, setFinished] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

 const handleAbort = useCallback(() => {
    setFinished(true);
    onAbort();
  }, [onAbort])

  const handleError = useCallback(() => {
    setFinished(true);
    onError();
  }, [setFinished, onError]);

  const handleLoad = useCallback(() => {
    setFinished(true);
    onLoad();
  }, [onLoad])

  useEffect(() => {
    const repositoryMapStreetView = repositoryService.get('maps').entity('streetview');
    setFinished(false);
    setImageSrc(null);

    repositoryMapStreetView.get({
      fov: 80,
      heading,
      location: `${latitude},${longitude}`,
      pitch: 0,
      return_error_code,
      size: `${width}x${height}`,
      source,
    }, {
      responseType: 'blob',
    })
      .then(convertBlobToBase)
      .then((data) => {
        setFinished(true);
        setImageSrc(data)
      })
      .catch(handleError)
  }, [heading, latitude, longitude, width, height, setImageSrc, setFinished, handleError]);

  return finished && (
    <img
      className={className}
      src={imageSrc}
      onAbort={handleAbort}
      onError={handleError}
      onLoad={handleLoad}
      alt={alt}
    />
  );
};

export default StreetViewImage;
